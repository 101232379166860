import React, {Component} from 'react'
import {SIDEBAR_WIDTH_EXPANDED, SIDEBAR_WIDTH_UNEXPANDED} from '../../Constants'
import {connect} from 'react-redux'
import Sales from './Sales.jsx'
import {loadAllUserActive} from '../../Api'
import {browserHistory} from 'react-router'
import {loadMakeAndModel, setSaleFilters, setPageNumberSales, setKeyword} from '../../actions'
import {SUCCESSFUL_CODE} from '../../../common/Constants'

class ContainerSale extends Component{
  constructor(){
    super();
    this.state = {
      users: null,
      saleFilters: {}
    }
  }

  componentWillMount(){
    const {makes, loadMakeAndModel} = this.props;
    loadAllUserActive().then( response => {
      const {responseCode, data, responseMessage} = response
      if(responseCode === SUCCESSFUL_CODE)
        this.setState({users:data.users})
      else
        console.error(responseMessage)
    })

    if(!makes)
      loadMakeAndModel().then(response => {});
  }

  componentDidMount () {
    const location = this.props.location || {}
    let {isDeleted} = location.state || {};
    let {showSideBar} = this.props;
    if (showSideBar) {
      let container = window.document.getElementById("container");
      container.className += ' container-collapse';
    }
    if(isDeleted){
      browserHistory.push({
      pathname: `/main/sale`,
      state:{isDeleted: false}
      })
    }
  }

  componentWillUnmount(){
    this.props.setKeyword("");
  }

  render() {
    const {height, showSideBar, email, makes, models, setSaleFilters, saleFilters, setPageNumberSales, pageNumberSale, keyword} = this.props;
    const {users} = this.state;
    const location = this.props.location || {};
    let {isDeleted} = location.state || {};
    let left;
    if(showSideBar){
      left = SIDEBAR_WIDTH_EXPANDED;
    } else{
      left = SIDEBAR_WIDTH_UNEXPANDED;
    }

    if(!users || !makes)
      return null;

    return(
      <div id="container" className='container-component' style={{height: height}}>
        {
          (this.props.children) ?
            <div>
              {React.cloneElement(this.props.children, {height: height, left: left, users: users})}
            </div> :
            <Sales left={left} height={height} users={users} email={email} isDeleted={isDeleted} makes={makes}
                   models={models} setSaleFilters={setSaleFilters} saleFilters={saleFilters} pageNumberSale={pageNumberSale}
                   setPageNumberSales={setPageNumberSales} keyword={keyword} {...this.props}/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    email:state.email,
    makes:state.makes,
    models:state.models,
    saleFilters: state.saleFilters,
    pageNumberSale: state.pageNumberSale,
    keyword: state.keyword
  }
};

export default connect(mapStateToProps,{loadMakeAndModel, setSaleFilters, setPageNumberSales, setKeyword})(ContainerSale)