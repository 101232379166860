import React from 'react'
import PropTypes from 'prop-types'
import {pathServer} from '../../common/Constants'
import {ReactSVG} from 'react-svg'

export const DPMessageDelete = (props) => {
  const {error, onChangePassword, title, onDelete, isEmptyPassword, subMessage} = props;
  return (
    <div className="panel-body-delete">
      <div className="text-confirmation">
        <label className="title-confirmation">{title}</label>
        {
          subMessage ?
            <label className="title-sub-message">{subMessage}</label> : null
        }
      </div>
      <div>
        { error ? <span data-test='message-error-modal-delete' id={'messageErrorModalDelete'} className="message-error">{error}</span> : null}
        <div className="container-new-password">
          <div className="label-select-status">Confirmation Password</div>
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_open_padlock.svg"} className="icon-padlock" beforeInjection={ svg => svg.classList.add('icon-open-padlock')}/>
          <input type="password"
                 className="input-password"
                 data-lpignore="true"
                 autoComplete="new-password"
                 onChange={(evt) => onChangePassword("passwordToDelete",evt)} />
          { isEmptyPassword ? <span className="message-error">Please provide password</span> : null}
        </div>
        <button onClick={onDelete} className="btn-save-custom" style={{float:"left"}}>Delete</button>
      </div>
      <div className="container-icon-sub-title">
        <div className="container-icon-exclamation">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                    className="icon-error"/>
        </div>
        <div className="container-sub-title">
          <p className="sub-title-coming-soon">
            Create / Change confirmation password in <span>Settings</span>
          </p>
        </div>
      </div>
    </div>
  )
}

DPMessageDelete.propTypes = {
  title: PropTypes.string,
  onChangePassword: PropTypes.func,
  error: PropTypes.string,
  onDelete: PropTypes.func,
  isEmptyPassword: PropTypes.bool,
  subMessage: PropTypes.string
}