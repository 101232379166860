import React from 'react'
import { pathServer } from '../../common/Constants'

import './DPMessage.scss'


export const DPMessageTypes = {
  WARNING: 'warning'
}

function getConfig(type){
  switch (type){
    case DPMessageTypes.WARNING:
      return ({
        className: 'warning'
      })
  }
}

const DPMessage = ({text, type = 'warning'}) => {

  const config = getConfig(type)

  return (
    <div className="container-message-info">
      <div className={`message-info ${config.className}`}>
        <img src={pathServer.PATH_IMG + "inverted.png"} className="icon-info"/>
        <label>{text}</label>
      </div>
    </div>
  )
}

export default DPMessage