import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {
  saleStatus, ATTRIBUTE_CODE_MAKE_ID, ATTRIBUTE_CODE_MODEL_ID,
  ATTRIBUTE_CODE_CAR_YEAR, saleStatusValue
} from '../../Constants'
import {Popover, PopoverSearchable} from '../util/ComponentUtils'
import Moment from 'moment'
import DPKeyboardDatePicker from '../../common/DPKeyboardDatePicker'
import {getAnchorEl} from '../util/Utils'
import {pathServer} from '../../../common/Constants'

const CheckBox = ({value, onChange, id, isSelected, text, style}) => {
  return (
    <div className="filter-sale-field-checkbox" style={style ? {marginRight: 0} : null}>
      <label key={id} className="container-check-form">
        <input type="checkbox"
               checked={value}
               onChange={(evt) => onChange(id,evt)}/>
        <span className="checkmark-form" style={isSelected ? {border: "1.4px #3B86FF solid"} : null}/>
        {
          value ? <i className="fa fa-check i-check"/> : null
        }
      </label>
      <span className="span-text">{text}</span>
    </div>
  )
};

const DropDownFilter = ({title, selectedFilter, id, text, style, handlePopover, value, showPopover, disabled}) => {
  return (
    <div className="filter-sale-field" style={style ? {marginRight: 0} : null}>
      <label className="title-field">{title}</label>
      <div id={id} className="field-assigned" onClick={!disabled ? (evt) => handlePopover(id, evt) : null}
            style={disabled ? {opacity: 0.4, cursor: "no-drop"} : null}>
        {
          value !== "" ?
              <label style={{color: "#979797", cursor: disabled ? "no-drop" : "pointer"}}>{value}</label> :
              <label style={disabled ? {cursor: "no-drop"} : null}>{text}</label>
        }
        {selectedFilter === id && showPopover?
            <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                      className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
            <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                      className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
        }
      </div>
    </div>
  )
}

const DateFilter = ({title, handleChangeDate, id, value}) => {
  return(
    <div className="filter-sale-field">
      <label className="title-field">{title}</label>
      <div id="assignedSalesmanId" className="salesman-assigned">
        <DPKeyboardDatePicker
          id={id}
          name={id}
          className={'sale-date'}
          value={value ? Moment(new Date(value), 'MM-DD-YYYY') : null}
          onChange={(date) => handleChangeDate(id, date)}
        />
      </div>
    </div>
  )
}

function getYearsFrom(yearNow) {
  let years = [];
  for (let i = 1980; i < yearNow + 1; i++) {
    years.push({id: i, displayValue: i.toString(), name: i.toString()})
  }
  return years
}

const optionStatus = [
  //{id: saleStatus.STARTED, displayValue: saleStatusValue[saleStatus.STARTED], name: saleStatusValue[saleStatus.STARTED]},
  {id: saleStatus.IN_PROGRESS, displayValue: saleStatusValue[saleStatus.IN_PROGRESS], name: saleStatusValue[saleStatus.IN_PROGRESS]},
  {id: saleStatus.COMPLETED, displayValue: saleStatusValue[saleStatus.COMPLETED], name: saleStatusValue[saleStatus.COMPLETED]},
  {id: saleStatus.CANCELLED, displayValue: saleStatusValue[saleStatus.CANCELLED], name: saleStatusValue[saleStatus.CANCELLED]}
]

class SaleFilter extends Component{
  constructor(){
    super();
    this.renderDropDownFilter   = this.renderDropDownFilter.bind(this);
    this.showMoreFilters        = this.showMoreFilters.bind(this);
    this.onChange               = this.onChange.bind(this);
    this.onHandleChange         = this.onHandleChange.bind(this);
    this.handleChangeDate       = this.handleChangeDate.bind(this);
    this.handlePopover          = this.handlePopover.bind(this);
    this.onChangeSelect         = this.onChangeSelect.bind(this);
    this.onChangeDropDown       = this.onChangeDropDown.bind(this);
    this.closePopover           = this.closePopover.bind(this);
    this.resetFilters           = this.resetFilters.bind(this);
    this.search                 = this.search.bind(this);
    this.setInitialValues       = this.setInitialValues.bind(this);
    this.state = {
      showPopover: false,
      selectedFilter: null,
      isLoading: false,
      selectOpened: false,
      showPopoverSerchable: false,
      options: {},
      anchorEl: null,
      makeValue: "",
      modelValue: "",
      yearValue: "",
      statusValue: "",
      creationDateFrom: null,
      disabledName: false,
      disabledCar: false
    };
  }

  componentWillMount() {
    const {saleFilters} = this.props;
    this.setInitialValues(saleFilters);

  }

  setInitialValues(saleFilters){
    let {makeValue, modelValue, yearValue, statusValue, disabledName, disabledCar} = this.state;
    const {makes,models} = this.props
    let foundValue;
    if(saleFilters.makeId){
      foundValue = makes.find(op => {return op.id.toString() === saleFilters.makeId.toString()});
      makeValue = foundValue.displayValue
    }
    if(saleFilters.modelId){
      foundValue = models.find(op => {return op.id === saleFilters.modelId});
      modelValue = foundValue.displayValue
    }
    if(saleFilters.year){
      const today = new Date();
      const options = getYearsFrom(today.getFullYear());
      foundValue = options.find(op => {return op.id === saleFilters.year});
      yearValue = foundValue.displayValue
    }
    if(saleFilters.status){
      foundValue = optionStatus.find(op => {return op.id === saleFilters.status});
      statusValue = foundValue.displayValue
    }
    if(saleFilters.hasNotPurchaser){
      disabledName = true
    }
    if(saleFilters.hasNotCar){
      disabledCar = true
    }
    this.setState({saleFilters, makeValue, modelValue, yearValue, statusValue, disabledName, disabledCar});
  }

  renderDropDownFilter(type, event){
    event.preventDefault();
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target, 2);
    this.setState({showPopover: true, selectedFilter: type, anchorEl: anchorEl})
  }

  showMoreFilters(){
    this.props.showMoreFilters();
  }

  resetFilters(){
    this.setState({makeValue:"", modelValue:"", yearValue: "", statusValue: ""})
    this.props.resetFilters();
  }

  onChange(type, e){
    let {saleFilters} = this.state;
    saleFilters[type] = e.target.value;
    this.props.updateQuery(saleFilters);
  }

  onHandleChange(type, event) {
    let {saleFilters, disabledName, disabledCar} = this.state;
    if (event.target.checked) {
      saleFilters[type] = true
    } else {
      saleFilters[type] = false
    }
    if(type === "hasNotPurchaser"){
      disabledName = saleFilters[type]
    } else {
      disabledCar = saleFilters[type]
    }
    this.setState({disabledName, disabledCar});
    this.props.updateQuery(saleFilters);
  }

  handleChangeDate(id, newDate) {
    let {saleFilters} = this.state;
    if(id === "creationDateFrom" || id === "updateDateFrom"){
      saleFilters[id] = new Date(Moment(newDate).format("MM-DD-YYYY")).getTime();
    } else{
      saleFilters[id] = new Date(Moment(newDate).format("MM-DD-YYYY 23:59:59")).getTime();
    }

    this.props.updateQuery(saleFilters);
  }

  handlePopover(id, evt){
    let {makes, models} = this.props;
    let {showPopover, saleFilters, foundValue, options} = this.state;
    evt.preventDefault();
    const target = evt.currentTarget;
    let anchorEl = getAnchorEl(target, 2);
    if(id !== "status")
      anchorEl.y = anchorEl.y - 36;

    if(id === ATTRIBUTE_CODE_MAKE_ID){
      options = makes;
      showPopover = true;
    } else if(id === ATTRIBUTE_CODE_MODEL_ID){
      if(saleFilters.makeId){
        options = models.filter(model => { return model.makeId === saleFilters.makeId});
        showPopover = true;
      } else{
        showPopover = false
      }
    } else if(id === ATTRIBUTE_CODE_CAR_YEAR){
      const today = new Date();
      options = getYearsFrom(today.getFullYear());
      showPopover = true;
    } else{
      showPopover = true;
      options = optionStatus;
    }

    this.setState({anchorEl: anchorEl, showPopover, selectedFilter: id, options, foundValue}, () => {
      if(id === ATTRIBUTE_CODE_MAKE_ID || id === ATTRIBUTE_CODE_MODEL_ID || id === ATTRIBUTE_CODE_CAR_YEAR){
        const element =  document.getElementById(id);
        if(element && showPopover)
          element.style.display = "none";
      }
    });
  }

  onChangeDropDown(e,id){
    let {showPopover, selectedFilter} = this.state;
    const element =  document.getElementById(selectedFilter);
    if(element)
      element.style.display = "none";
    selectedFilter = id;
    showPopover = !showPopover;
    this.setState({showPopover,anchorEl: e.currentTarget,selectedFilter}, () => {
      if(id === ATTRIBUTE_CODE_MAKE_ID || id === ATTRIBUTE_CODE_MODEL_ID || id === ATTRIBUTE_CODE_CAR_YEAR){
        const element =  document.getElementById(id);
        if(element)
          element.style.display = "flex";
      }
    });
  }

  onChangeSelect(id,index, optionId) {
    let {saleFilters, makeValue, options, foundValue, modelValue, yearValue, statusValue} = this.state;
    if(id === ATTRIBUTE_CODE_MAKE_ID) {
      saleFilters[id] = optionId;
      saleFilters.modelId = "";
      foundValue = options.find(op => {return op.id.toString() === saleFilters.makeId.toString()});
      makeValue = foundValue.displayValue
    } else if(id === ATTRIBUTE_CODE_MODEL_ID){
      saleFilters[id] = optionId;
      foundValue = options.find(op => {return op.id.toString() === saleFilters.modelId.toString()});
      modelValue = foundValue.displayValue
    } else if(id === ATTRIBUTE_CODE_CAR_YEAR){
      saleFilters[id] = optionId;
      foundValue = options.find(op => {return op.id === saleFilters.year});
      yearValue = foundValue.displayValue
    } else{
      saleFilters[id] = optionId;
      foundValue = options.find(op => {return op.id === saleFilters.status});
      statusValue = foundValue.displayValue
    }
    this.setState({showPopover:false, makeValue, foundValue, modelValue, yearValue, statusValue}, () => {
      if(id === ATTRIBUTE_CODE_MAKE_ID || id === ATTRIBUTE_CODE_MODEL_ID || id === ATTRIBUTE_CODE_CAR_YEAR){
        const element =  document.getElementById(id);
        if(element)
          element.style.display = "flex";
      }
    })
    this.props.updateQuery(saleFilters);
  }

  closePopover(){
    this.setState({showPopover: false, selectedFilter: null});
  }

  renderStatus(){
    let {statusValue} = this.state;
    let options = [];

    optionStatus.map((order, index) => {
      if(order.id === statusValue){
        options.push(
            <li key={index} className="item-selected" onClick={() => this.onChangeSelect("status",index,order.id)}>
              {order.displayValue}
            </li>
        )
      } else {
        options.push(
            <li key={index} className="change-submenu-item" onClick={() => this.onChangeSelect("status",index,order.id)}>
              {order.displayValue}
            </li>
        )
      }
    });

    return (
        <div className="popover-general-message">
          <div className="popover-general-message-sub">
            <u>
              {options}
            </u>
          </div>
        </div>
    )
  }

  search(){
    let {saleFilters} = this.state;
    this.props.searchSales(saleFilters)
  }

  render() {
    const {saleFilters, selectedFilter, showPopover, anchorEl, options, foundValue, makeValue,
    modelValue, yearValue, statusValue, disabledName, disabledCar} = this.state;

    const childStatus = this.renderStatus();
    return (
        <div className="content-filters">
          <div className="first-row-filter">
            <div className="filter-sale-field">
              <label className="title-field">Purchaser</label>
              <input id="purchaserName" className="form-control form-sale" value={saleFilters.purchaserName}
                     placeholder="Type name here"
                     onChange={(evt) => this.onChange("purchaserName", evt)}
                     style={{color: "#D7DAE2 !important", fontSize: 13, opacity: disabledName ? 0.4 : 1}}
                     disabled={disabledName}/>
            </div>
            <CheckBox value={saleFilters.hasNotPurchaser} onChange={this.onHandleChange} id={"hasNotPurchaser"} isSelected={true} text={"Not purchaser yet"}/>
            <DropDownFilter title="Car Make" selectedFilter={selectedFilter} id="makeId" text={"Select Car Make"} handlePopover={this.handlePopover}
                            showPopover={showPopover} value={makeValue} disabled={disabledCar}/>
            <DropDownFilter title="Car Model" selectedFilter={selectedFilter} id="modelId" text={"Select Car Model"} handlePopover={this.handlePopover}
                            showPopover={showPopover} value={modelValue} disabled={disabledCar}/>
            <DropDownFilter title="Car Year" selectedFilter={selectedFilter} id="year" text={"Select Car Year"} handlePopover={this.handlePopover}
                            showPopover={showPopover} value={yearValue} disabled={disabledCar}/>
            <CheckBox value={saleFilters.hasNotCar} onChange={this.onHandleChange} id={"hasNotCar"} isSelected={true} text={"Not car yet"} style={true}/>
          </div>
          <div className="second-row-filter">
            <DateFilter title="Creation Date (from)" handleChangeDate={this.handleChangeDate} id={"creationDateFrom"} value={saleFilters.creationDateFrom}/>
            <DateFilter title="Creation Date (To)" handleChangeDate={this.handleChangeDate} id={"creationDateTo"} value={saleFilters.creationDateTo}/>
            <DateFilter title="Update Date (from)" handleChangeDate={this.handleChangeDate} id={"updateDateFrom"} value={saleFilters.updateDateFrom}/>
            <DateFilter title="Update Date (To)" handleChangeDate={this.handleChangeDate} id={"updateDateTo"} value={saleFilters.updateDateTo}/>
            <DropDownFilter title="Status" selectedFilter={selectedFilter} id={"status"} text={"All"} style={true} handlePopover={this.handlePopover}
                            showPopover={showPopover} value={statusValue}/>
          </div>
          <div className="third-row-filter">
            <button className="btn-filter" onClick={this.search}>
              Filter
            </button>
            <button className="btn-clear" onClick={this.resetFilters}>
              Clear filters
            </button>
          </div>
          {
            showPopover && (selectedFilter === "status") ?
              <Popover anchorEl={anchorEl}
                       child={childStatus}
                       classPopover="popover-box-message"
                       handleRequestClose={this.closePopover}/> : null
          }
          {
            showPopover && (selectedFilter === ATTRIBUTE_CODE_MAKE_ID || selectedFilter === ATTRIBUTE_CODE_MODEL_ID || selectedFilter === ATTRIBUTE_CODE_CAR_YEAR) ?
              <PopoverSearchable id={selectedFilter} anchorEl={anchorEl} options={options} classPopover=""
                                 onChangeDropDown={this.onChangeDropDown} onChange={this.onChangeSelect} foundValue={foundValue}/> : null
          }
        </div>
    );
  }
}

export default SaleFilter;