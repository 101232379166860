import React, {useState} from 'react'
import {cloneDeep} from 'lodash'
import DPDropzone from '../../../common/DPDropzone'
import {ReactSVG} from 'react-svg'
import {DPInput} from '../../../common/DPInput'
import {EXT_IMG, EXT_OTHER_IMG, EXT_PNG, pathServer} from '../../../../common/Constants'

const KEY_BACKGROUND_MAIN = "backgroundMain";
const MAX_SIZE_TITLE =  45

const DPHomeBanner = (props) => {
  const {generalSetting, onChange, files, fields, onChangeFile} = props
  const {banner} = generalSetting
  const {backgroundMain, slogan} = banner
  const {url} = backgroundMain

  const [imgMain, setImgMain] = useState(null);

  const onDrop = (file, field) => {
    let generalSettingNew = cloneDeep(generalSetting);
    if (field === KEY_BACKGROUND_MAIN) {
      setImgMain(URL.createObjectURL(file[0]))
      files.push(file[0]);
      fields.push(field);
      generalSettingNew.banner[field].url = URL.createObjectURL(file[0]);
      onChangeFile(generalSettingNew, files, fields);
      onChange(generalSettingNew)
    }

  }

  const onChangeSlogan = (evt) => {
    let generalSettingNew = cloneDeep(generalSetting);
    generalSettingNew.banner.slogan = evt.currentTarget.value
    onChange(generalSettingNew)
  }

  const removeImage = () => {
    let generalSettingNew = cloneDeep(generalSetting);
    const index = fields.indexOf(KEY_BACKGROUND_MAIN);
    files.splice(index, 1);
    fields.splice(index, 1);

    generalSettingNew.banner[KEY_BACKGROUND_MAIN].url = "";
    setImgMain(null)
    onChangeFile(generalSettingNew, files, fields)
  }

  return(
    <div className="container-home-banner">
      <div className="container-background">
        <span className="title-background">Background image</span>
        {
          url ?
            <img className="background-image" src={url}/>:
            <DPDropzone
              onDrop={(file) => onDrop(file, KEY_BACKGROUND_MAIN)}
              extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
              dataTestInput={'dealer-media'}
            >
              <>
                <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_cloud_upload_arrow.svg`}
                          className="customer-image-upload"
                          beforeInjection={svg => svg.classList.add('cloud-upload')}/>
                <label className="label-upload-logo">Upload</label>
              </>
            </DPDropzone>
        }
        <div className="container-button">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="content-delete-icon"
                    beforeInjection={ svg => svg.classList.add('delete-icon')} onClick={removeImage}/>
        </div>
      </div>
      <DPInput className="input-slogan" title="Site slogan" value={slogan} onChange={onChangeSlogan} maxLength={MAX_SIZE_TITLE}
               rightElement={<label className={`label-size right ${slogan.length === MAX_SIZE_TITLE ? 'error-color' : ''}`}>{`${slogan.length}/${MAX_SIZE_TITLE}`}</label>}
      />
    </div>
  )
}
export default DPHomeBanner