import React, {useContext} from 'react'
import {ReactSVG} from 'react-svg'
import {pathServer, WIDTH_VIEW_MOBILE} from '../../../../../common/Constants'
import {DashboardContext} from '../../Dashboard'

const Theme = props => {
  const {templateId, templateSelected, catalogUrl,dealerName, onClickTheme} = useContext(DashboardContext)
  const isMobileView = window.innerWidth <= WIDTH_VIEW_MOBILE
  const classnameStyle = isMobileView ?  'btn-theme-disable' : 'btn-theme'
  return(
    <React.Fragment>
      <div className="photo-container">
        {
          !templateSelected ?
            <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_default_theme.svg`} className="theme-photo"/> :
            <img src={pathServer.PATH_IMG + templateSelected} className="theme-photo" alt=""/>
        }
      </div>
      <div className="theme-description">
        <label className="title-theme">{dealerName} Public website</label>
        {
          templateId !== "" && <a id={catalogUrl} href={catalogUrl} target="_blank" className="title-page">{catalogUrl}</a>
        }
        <button className={classnameStyle} onClick={isMobileView ? null : onClickTheme}>
         Configure Theme
        </button>
      </div>
    </React.Fragment>
  )
}

export default Theme