import React from 'react'
import InputElement from 'react-input-mask'

export const InputValue = ({placeholder, name, onChange, onBlur, errorMessage, errorEmail, errorEmailDuplicate, title, isRequired, value, testId}) => {
  return(
    <div className="flex-colR1">
      <div className="item">
        <div>
          <span style={{fontSize: 13, fontWeight: 400, fontFamily: "'Open Sans', sans-serif"}}>{title}</span>
          {isRequired ? <span className="label-error" style={{fontWeight: 200}}>*</span>: null}
        </div>
        <input type="text" className="form-control" placeholder={placeholder}
               onChange={(e) => onChange(name,e.target.value)}
               value={value ? value : null}
               onBlur={ onBlur ? (e) => onBlur(name, isRequired, e.target.value) : () =>{}}
               style={{fontSize: 13}}
               data-testid={testId || ''}
        />
        { errorMessage ?<span className="error-message">{errorMessage}</span>: null}
          {/*(errorEmail)?<span className="error-message"> Email is not valid.</span>:*/}
          {/*((errorEmailDuplicate)?<span className="error-message">User’s email already exists.</span>:null)}*/}
      </div>
    </div>
  )
};

export const InputMask = ({placeholder, name, onChange, onBlur, errorMessage, title, isRequired, value, testId}) => {
  return(
    <div className="flex-colR1">
      <div className="item">
        <div>
          <span style={{fontSize: 13, fontWeight: 400, fontFamily: "'Open Sans', sans-serif"}}>{title}</span>
          {isRequired ? <span className="label-error" style={{fontWeight: 200}}>*</span>: null}
        </div>
        <InputElement type="text" className="form-control"
                      mask="(999)999-9999"
                      placeholder={placeholder}
                      alwaysShowMask={false}
                      maskChar=""
                      name="phoneNumber"
                      readOnly={false}
                      onChange={(e) => onChange(name, e.target.value)}
                      onBlur={ onBlur ? (e) => onBlur(name, isRequired, e.target.value) : () => {}}
                      value={value ? value : null}
                      style={{fontSize: 13}}
                      testId={testId}
        />
        {(errorMessage)?<span className="error-message">Fill the field correctly.</span>:null}
      </div>
    </div>
  )
};