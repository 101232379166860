import React, { useEffect, useState } from 'react'
import DPTable from '../../common/DPTable'
import DPPage from '../../common/DPPage'
import {getQuantityGroupByFlagApi, loadFeatureFlags, saveFeatureFlag} from '../../Api'
import {pathServer, SUCCESSFUL_CODE} from "../../../common/Constants";
import DPButton from "../../common/DPButton";
import {ReactSVG} from "react-svg";
import {Modal} from "react-bootstrap";
import {browserHistory} from "react-router";
import {DPPagination} from "../../../common/DPPagination";
import {DEALERS_BY_PAGE, DEALERS_BY_PAGE_BY_DEFAULT, siteColorAdmin} from "../../Constants";

require('./Flags.scss')


const ModalAddFeatureFlag = ({showModal = false, setShowModal, saveFlag}) => {
  const [flagName, setFlagName] = useState("")

  const saveAddNewFlag = () => {
    saveFlag(flagName, "GLOBAL", true)
    setShowModal(false)
  }

  return (
      <Modal show={showModal} className={`modal-new-sale modal-edit-template`} backdropClassName="backdrop-dealer">
        <Modal.Body>
          <div className="panel-container">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                      beforeInjection={ svg => svg.classList.add("icon-close")} onClick={() => setShowModal(false)}/>
            <div className="panel-container-body">
              <div className={'templates-header'}>
                <h3>{'Add New Feature Flag'}</h3>
              </div>

              <div className="container-button">
                <input className={'form-control'}
                       value={flagName}
                       placeholder={'Name your flag'}
                       onChange={(evt) => setFlagName(evt.target.value)}/>

                <DPButton className={"button-save"}
                          width={'120px'}
                          onClick={saveAddNewFlag}
                          data-test={'btn-create-template'}>
                  {'Save'}
                </DPButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  )
}

const Flags = ({}) => {

  const [flags, setFlags] = useState([])
  const [showModalAddFeatureFlag, setShowModalAddFeatureFlag] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsByPageQuantity, setItemsByPageQuantity] = useState(DEALERS_BY_PAGE_BY_DEFAULT)
  const [quantityDealers, setQuantityDealers] = useState(0)
  const [doRefresh, setDoRefresh] = useState(false)

  useEffect(() => {
    (async () => {
      const {responseCode, data} = await getQuantityGroupByFlagApi()
      if (responseCode === SUCCESSFUL_CODE) {
        setFlags(data.featureFlags)
      }
    })()

  }, [doRefresh])

  const saveFlag = async (flagName, dealerId, active) => {
    const featureFlagSave = {dealerId, flagName, active}
    const {responseCode, responseMessage} = await saveFeatureFlag(featureFlagSave)
    if (responseCode === SUCCESSFUL_CODE) {
      setDoRefresh(!doRefresh)
    }
    else {
      console.error(responseMessage)
    }
  }

  const goToFeatureFlag = (ftFlag) => {
    browserHistory.push({
      pathname: `/main/superusers/flags/edit/${ftFlag.flagName}`,
      state: {flagName: ftFlag.flagName}
    });
  }

  const totalPages = quantityDealers ? Math.ceil(quantityDealers / itemsByPageQuantity) : 1

  return (
      <>
        <DPPage
            title="Feature flag table"
            showContentHeader={true}
            className={'dp-page-flagsList'}
            leftHeader={
              <>
                <div className="dealer-button-add">
                  <ReactSVG
                      src={pathServer.PATH_IMG + "icon/ic_circle.svg"}
                      beforeInjection={svg => svg.classList.add("icon-add-svg")}
                      className="icon-add-dealer"
                      onClick={() => setShowModalAddFeatureFlag(true)}
                  />
                </div>
              </>
            }
            rightHeader={
              <DPPagination
                  optionsQuantityByPage={DEALERS_BY_PAGE}
                  selectedItemsByPage={itemsByPageQuantity}
                  handleChangeItemsByPage={() => {}}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={() => {}}
                  siteColor={siteColorAdmin}
              />
            }
        >
          <DPTable
              isLoading={false}
              data={flags}
              config={
                {
                  columns: [
                    {id: 'flagName', label: 'Flag Name'},
                    {id: 'dealersOnFeature', label: 'Dealers on Feature'},
                  ]
                }
              }
              renderColumn={
                (flag, columnConf) => {
                  const {flagName, dealersOnFeature} = flag
                  switch (columnConf.id) {
                    case 'flagName':
                      return flagName
                    case 'dealersOnFeature':
                      return dealersOnFeature
                  }
                }
              }
              onRowClick={goToFeatureFlag}
          />
        </DPPage>

        {
            showModalAddFeatureFlag &&
            <ModalAddFeatureFlag
                showModal={showModalAddFeatureFlag}
                setShowModal={setShowModalAddFeatureFlag}
                saveFlag={saveFlag}
            />
        }
      </>
  )
}

export default Flags
