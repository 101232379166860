import React, {Component} from 'react'
import {DEALER_ICON, INVENTORY_DELETE_PASSWORD_CODE, optionHours, TAB_DEALER} from '../../Constants'
import {connect} from 'react-redux'
import {browserHistory} from 'react-router'
import {ReactSVG} from 'react-svg'
import {
  loadAccountApi,
  searchTemplateApi,
  loadDealer,
  saveLocationApi,
  dealerSetup,
  saveDealerConfiguration,
  updateSetupDealersWithImage,
  sendEmailVerification
} from '../../Api'
import {findIndex, isEmpty} from 'lodash'
import {setMenuId} from '../../actions'
import {Modal} from 'react-bootstrap'
import {
  BoydDealer,
  DEFAULT_VALUE_FILE_DEALER_ICON,
  fieldsRequiredUpdate,
  validInputsNotRequired
} from '../superusers/dealers'
import InputRange from 'react-input-range'
import {getAnchorEl, validateError} from '../util/Utils'
import {BodyLocation, fieldsRequired, locationDefault} from '../setting/locationSetting/SettingLocationModal.jsx'
import SaleTechLayout from './components/layout/SaleTechLayout'
import AdminLayout from './components/layout/AdminLayout'
import {checkValidEmail, checkValidPhone, checkValidZipCode} from '../../../common/ValidationUtil'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import LocationUpdate from '../setting/locationSetting/LocationUpdate'
import {addOpeningHourToLocation, updateLocationOpeningHourOption, deleteOpeningHourAtIndex} from '../setting/locationSetting/SettingLocationModal.jsx'


const DEFAULT_COUNTRY = "US"

function showMenuSearch(display){
  let menuSearchContent = document.getElementById("menuSearchContent");
  if(menuSearchContent)
    menuSearchContent.style.display = display;
}

function getRoles(roles) {
  let rolToString = '-';
  if(roles.length > 0)
    rolToString = roles.map(function(rol){return rol.name;}).join(' - ');

  return rolToString;
}

function getDataInitial() {
  return {
    firstName:"Johnny",
    lastName:"Apolinario",
    image:pathServer.PATH_IMG+"user.png",
    rol:"Seller",
    email:"johnny.apolinariob@gmail.com",
    phoneNumber:"(224)098-987"
  }
}

const ModalSetup = ({isLoading, show, lastCompletedStep, save, onHide, dealer, anchorEl, closePopoverSelect, onDrop, file, onChange, statusPopoverValue, statePopoverValue,
                    dealerName, hasChange, errors, deleteFile, showPopoverDropDown, selectPopoverDropDown, selectOptionPopover, showPopoverDropDownModal, updateSetup,
                      showPopoverModal, disabled, location, inputChange, renderObject, saveAndUpdateLocation,
                      renderHours, onChangePassword, isEmptyPassword, isEmptyConfirmPassword, notMatchPassword, saveDeletePassword, fileDealerIcon,
                      selectObject, selectHour, addOpeningHour, selectOptionInOpeningHour, deleteOpeningHour}) => {
  let child = null;
  switch (lastCompletedStep) {
    case 0:
      child = <BoydDealer save={save} dealer={dealer} anchorEl={anchorEl} closePopoverSelect={closePopoverSelect} onDrop={onDrop} file={file} onChange={onChange}
                          statePopoverValue={statePopoverValue} dealerName={dealerName} hasChange={hasChange} errors={errors} statusPopoverValue={statusPopoverValue}
                          deleteFile={deleteFile} showPopoverDropDown={showPopoverDropDown} selectPopoverDropDown={selectPopoverDropDown}
                          selectOptionPopover={selectOptionPopover} showPopoverDropDownModal={showPopoverDropDownModal} source={"dashboard"} updateSetup={updateSetup}
                          fileDealerIcon={fileDealerIcon}
      />;
      break;
    case 1:
      child =
        <LocationUpdate
          location={location}
          inputChange={inputChange}
          selectObject={selectObject}
          selectHour={selectHour}
          addOpeningHour={addOpeningHour}
          selectOptionInOpeningHour={selectOptionInOpeningHour}
          deleteOpeningHour={deleteOpeningHour}
          saveAndUpdateLocation={saveAndUpdateLocation}
          isNew={true}
          errors={errors}
        />

      break;
    case 2:
      child = <BodyPassword onChangePassword={onChangePassword} isEmptyPassword={isEmptyPassword} isEmptyConfirmPassword={isEmptyConfirmPassword}
                            notMatchPassword={notMatchPassword} saveDeletePassword={saveDeletePassword}/>;
      break;
    case 3:
      child = <SetupComplete onHide={onHide}/>;
      break;
  }

  return (
      <Modal show={show} className="modal-setup-dealer">
        <Modal.Body>
          {
            isLoading
              ? <div className="loader-container">
                <div className="loader"/>
              </div>
              : <React.Fragment>
                <div className="panel-header-setup">
                  <div className="panel-slice">
                    <label className="label-header">Setup your dealer</label>
                    <div className="slider-container">
                      <InputRange
                        maxValue={9}
                        minValue={0}
                        step={3}
                        value={{min: 0, max: lastCompletedStep !== 0 ? (lastCompletedStep * 3) : 1}}
                      />
                    </div>
                    <div className="slider-value">
                      <label>{lastCompletedStep}/3 Completed</label>
                    </div>
                  </div>
                </div>
                {
                  child
                }
              </React.Fragment>
          }
        </Modal.Body>
      </Modal>
  )
};

const BodyPassword = ({onChangePassword, isEmptyPassword, isEmptyConfirmPassword, notMatchPassword, saveDeletePassword}) => {
  return(
    <div className="panel-body">
      <div className="panel-header-body">
        <label className="title">Set Confirmation Password for inventory</label>
      </div>
      <div className="panel-content-body">
        <div className="panel-left">
          <img src={pathServer.PATH_IMG + 'delete-password.png'} />
        </div>
        <div className="panel-right">
          <div className="container-body-panel">
            <label className="subtitle">
              Every time you try too delete a inventory car, for security you'll be ask for a Confirmation Password to complete the action. Please choose a password
              and set it up below.
            </label>
          </div>
          <div className="container-password">
            {notMatchPassword ? <span className="message-general-error" style={{top:10}}>Password not match Confirm Password</span> : null}
            <div className="container-new-password">
              <div className="label-select-status">Password</div>
              <input id="newPasswordDelete" type="password" className="input-password" onChange={(evt) => onChangePassword("password",evt)} />
              { isEmptyPassword ? <span className="message-error">Please provide password</span> : null}
            </div>
            <div className="container-new-password">
              <div className="label-select-status">Confirm Password</div>
              <input id="confirmPasswordDelete" type="password" className="input-password" onChange={(evt) => onChangePassword("confirmPassword",evt)}/>
              { isEmptyConfirmPassword ? <span className="message-error">Please provide confirm password</span> : null}
            </div>
            <div className="container-btn-create">
              <button className="btn-create-password" onClick={saveDeletePassword}>Complete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const SetupComplete = ({onHide}) => {
  return(
      <div className="panel-complete">
        <div className="container-image">
          <img src={pathServer.PATH_IMG+"successfully-image.png"}/>
        </div>
        <div className="title-complete">
          <label>
            Good Job!
          </label>
        </div>
        <div className="sub-title-complete">
          <label>
            Keep your dealer updated, we'll help you out.
          </label>
        </div>
        <div className="container-btn-complete">
          <button className="btn-complete" onClick={onHide}>
            Finish
          </button>
        </div>
      </div>
  )
};

const DashboardNotAssigned = () => (
  <div className="dashboard-error">
    <header>
      <span>Contact your dealer manager to have a dashboard assigned.</span>
    </header>
    <section>
      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_warning.svg"}
                className="icon-warning"/>
      <h3>You have no dashboard assigned</h3>
      <p>We're sorry, your user does not have a dashboard assigner.</p>
      <p>Please contact the admin to add a dashboard.</p>
    </section>
  </div>
)

export const DashboardContext = React.createContext()

class Dashboard extends Component {

  constructor() {
    super();
    this.state = {
      user:null,
      templates: null,
      isLoading:false,
      isSetupComplete: false,
      isAccountOwner: false,
      isVerified: false,
      isEmailSent: false,
      file:[],
      anchorEl: null,
      errors: {},
      dealerName: null,
      dealerUpdate: null,
      newLocation: null,
      statusPopoverValue: null,
      statePopoverValue: null,
      location: locationDefault(),
      disabled: [],
      showPopoverStatus: false,
      showPopoverState: false,
      showPopoverHour: false,
      keyHour: '',
      indexHour: '',
      isLoadingModal: false,
      lastCompletedStep: 0,
      isEmptyPassword:false,
      isEmptyConfirmPassword:false,
      password:"",
      confirmPassword:"",
      notMatchPassword:false,
      showBar: true,
      showOptionSelected: false,
      showPopoverSelected: null,
      domainUrl: "",
      hasAdminLayout: false,
      quickActions: [],
      reports: [],
      dashboards: [],
      dashboardData: {},
      fileDealerIcon: DEFAULT_VALUE_FILE_DEALER_ICON
    }
  }

  UNSAFE_componentWillMount(){
    this.search();
    this.loadUsers();
    this.loadDealer();
  }

  /*componentWillReceiveProps(next_props) {
    //--this.setState({ user: next_props });
  }*/

  loadUsers = () => {
    this.setState({isLoading:true});
    loadAccountApi().then((response) => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {user, dashboard: dashboards , quickActions, hasAdminLayout} = data
        const {roles: userRoles, accountOwner: isAccountOwner, verify: isVerified } = user
        const roles = getRoles(userRoles);
        const dashboardData = {
          user,
          roles,
          dashboards,
          quickActions: quickActions?.quickActions,
          reports: isEmpty(dashboards) ? null : dashboards[0].reports,
          showUserExtraInfo: !hasAdminLayout
        }

        this.setState({
          user,
          isLoading:false,
          isAccountOwner,
          isVerified,
          hasAdminLayout,
          dashboards,
          dashboardData,
        })

      }else{
        console.log("error response user logging", responseMessage);
        this.setState({user:getDataInitial(),isLoading:false});
      }
    })
  }

  componentDidMount() {
    let {showSideBar} = this.props;
    if (showSideBar) {
      let container = document.getElementById("container");
      container.className += ' container-collapse';
    }
    // showMenuSearch("none");
  }

  componentWillUnmount(){
    showMenuSearch("block");
  }

  search = () => {
    searchTemplateApi("").then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({templates: data.templates})
      }else{
        console.error("error ==>", responseMessage)
      }
    })
  }

  customize(id){
    this.props.setMenuId("setting");
    browserHistory.push(`/main/setting/template/${id}`);
  }

  loadDealer = async () => {
    const {isSuperuser} = this.props;
    if(!isSuperuser){
      const response = await loadDealer()
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        let dealer = data.dealer;
        const dealerName = dealer.name;
        const catalogUrl = data.catalogUrl;
        this.setState({isSetupComplete: dealer.setupComplete, dealerUpdate: dealer, dealerName, lastCompletedStep: dealer.lastCompletedStep,
          statePopoverValue: dealer.state, domainUrl: dealer.domainUrl, catalogUrl})
      }
      else {
        console.error(responseMessage);
      }
    }
  }

  onDrop = (updatedFile, elementType = '') => {
    const fileDealerIcon = elementType === DEALER_ICON ? updatedFile : [...this.state.fileDealerIcon]
    const file = isEmpty(elementType) ? updatedFile : [...this.state.file]

    this.setState({file, fileDealerIcon});
  }

  onHide = () => {
    this.setState({isSetupComplete: true});
  }

  updateData = async () => {
    const {dealerUpdate, file, errors, fileDealerIcon} = this.state;
    const error = this.getError(errors, dealerUpdate, TAB_DEALER);
    const hasError = validateError(error);
    let dealerToUpdate = dealerUpdate;

    if(!isEmpty(dealerUpdate.zipCode)){
      dealerToUpdate.zip = dealerUpdate.zipCode
    }
    if(!hasError){
      const body = {
        files: file,
        dealer: dealerToUpdate,
        fileDealerIcon,
      };
      this.setState({isLoadingModal: true, showModalPopover: false, hasChange: false});

      const response = await updateSetupDealersWithImage(dealerUpdate.id,body)
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE) {
        this.updateSetup(false,1);
        this.setState({isLoadingModal: false});
      }
      else {
        console.error(responseMessage);
        this.setState({isLoadingModal: false});
      }
    } else {
      this.setState({errors: error});
    }
  }

  getError(errors, object, type){
    let value;
    fieldsRequiredUpdate.forEach(field => {
      const {key, type} = field;
      if (type === "required") {
        value = object[key];
        if(key === "phoneNumber"){
          errors[key] = !checkValidPhone(value);
        }else if(key === "email"){
          errors[key] = isEmpty(value);
          if(!errors[key]){
            errors[key] = validInputsNotRequired(object[key], checkValidEmail);
          }
        } else {
          errors[key] = isEmpty(value);
        }
      }
    });
    return errors
  }

  onChangeValue = (key, type, event) => {
    let {dealerUpdate, errors, errorsLocation, newLocation} = this.state;
    if(type === "update"){
      dealerUpdate[key] = event.target.value;
      errors[key] = false;
    } else {
      newLocation[key] = event.target.value;
      errorsLocation[key] = false;
    }

    this.setState({dealerUpdate: dealerUpdate, errors: errors, newLocation: newLocation, errorsLocation: errorsLocation});
  }

  showPopoverDropDownModal = (id, event) => {
    event.preventDefault();
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target,2);
    this.setState({anchorEl: anchorEl, showPopoverDropDown: true, selectPopoverDropDown: id})
  }

  selectOptionPopover = (type, value, displayValue, object) => {
    let {dealerUpdate, statusPopoverValue, statePopoverValue} = this.state;
    dealerUpdate[type] = value;
    if(type === "status")
      statusPopoverValue = displayValue;
    else
      statePopoverValue = displayValue;
    this.setState({dealerUpdate: dealerUpdate, statusPopoverValue: statusPopoverValue, statePopoverValue: statePopoverValue, showPopoverDropDown: false,
      selectPopoverDropDown: null});
  }

  deleteFile = (elementType = '') => {
    const dealerUpdate = {
      ...this.state.dealerUpdate,
      logoUrl: isEmpty(elementType) ? '' : this.state.dealerUpdate['logoUrl'],
      siteIconUrl: elementType === DEALER_ICON ? '' : this.state.dealerUpdate['siteIconUrl'],
    }

    const file = isEmpty(elementType)
      ? []
      : [...this.state.file]
    const fileDealerIcon = elementType === DEALER_ICON
      ? DEFAULT_VALUE_FILE_DEALER_ICON
      : [...this.state.fileDealerIcon]

    this.setState({file, dealerUpdate, hasChange: true, fileDealerIcon});
  }

  closePopoverSelect = () => {
    this.setState({showPopoverDropDown: false, selectPopoverDropDown: null})
  }

  showPopover = (id,event) => {
    event.preventDefault();
    let {keyHour, indexHour, showPopoverDropDown, selectPopoverDropDown} = this.state;
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target,2);
    if(id === "statusLocation"){
      selectPopoverDropDown = id
    } else if(id === "selectState") {
      selectPopoverDropDown = id
    } else{
      selectPopoverDropDown = "hour";
      indexHour = id.substring(0,1);
      keyHour = id.substring(1,(id.length))
    }
    this.setState({anchorEl: anchorEl, indexHour, keyHour, selectPopoverDropDown: selectPopoverDropDown, showPopoverDropDown: !showPopoverDropDown})
  }

  inputChange = (key, event) => {
    let {location} = this.state;
    let value = event.target.value;
    if (key === "phoneNumber" || key === "email") {
      location.info[key] = value;
    } else {
      location[key] = value;
    }
    this.setState(location);
  }

  renderObject = (object,type) => {
    let options = [];
    object.map((item, index) => {
      options.push(
          <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectObject(item.id,type)}}>
            <label className="option-label">{item.displayValue}</label>
          </div>
      )
    });
    return (
        <div className="popover-body-status">
          <div id="" className="options-status">{options}</div>
        </div>
    )
  }

  selectObject = (object,type) => {
    let {location} = this.state;
    location[type] = object;
    this.setState({location, showPopoverDropDown: false, selectPopoverDropDown: null})
  }

  saveLocation = () => {
    const {location, errors} = this.state;
    const error = this.getErrorLocation(errors, location);
    const hasError = validateError(error);

    if (!hasError) {
      location.info = JSON.stringify(location.info);
      const addressForGeoCoord = `${location.address}, ${location.city}, ${location.state} ${location.zip}, ${isEmpty(location.country) ? DEFAULT_COUNTRY : location.country}`
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode( { 'address': addressForGeoCoord }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const latitude = results[0].geometry.location.lat();
          const longitude = results[0].geometry.location.lng();

          location['latitude'] = latitude.toString()
          location['longitude'] = longitude.toString()

          this.setState({isLoadingModal: true});
          saveLocationApi(location).then(({responseCode}) => {
            if (responseCode === SUCCESSFUL_CODE) {
              this.updateSetup(false, 2);
              this.setState({isLoadingModal: false});
            } else {
              this.setState({isLoadingModal: false});
            }
          });
        }
        else {
          const geoCoordinateError = {}
          geoCoordinateError['geocoord'] = 'Error on Geo Coordinates'
          this.setState({errors: geoCoordinateError});
        }
      });
    } else {
      this.setState({errors: error});
    }
  }

  renderHours = () => {
    let options = [];
    let {indexHour, keyHour} = this.state;
    optionHours.forEach((hour, index) => {
      if(indexHour !== "0"){
        if(index !== 0 || keyHour !== 'to'){
          options.push(
              <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectHour(hour)}}>
                <label className="option-label" style={index === 0 ? {color: "#FF6565"} : null}>{hour}</label>
              </div>
          );
        }
      } else {
        if(index !== 0){
          options.push(
              <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectHour(hour)}}>
                <label className="option-label">{hour}</label>
              </div>
          );
        }
      }
    });
    return (
        <div className="popover-body-status">
          <div id="" className="options-status">
            {options}
          </div>
        </div>
    )
  }

  selectHour = (hour, key, index) => {
    const {location} = this.state
    const {info} = location
    const {hoursOfOperation = []} = info
    hoursOfOperation[index][key] = hour
    this.setState({location})
  }


  getErrorLocation(errors, location) {
    let value;
    fieldsRequired.forEach(field => {
      const {key, type} = field;
      if (type === "required") {
        if (key === "phoneNumber") {
          value = location.info[key];
        } else {
          value = location[key];
        }
        if (key === "phoneNumber") {
          errors[key] = !checkValidPhone(value);
        } else {
          errors[key] = isEmpty(value);
        }
      } else {
        switch (key) {
          case "email":
            errors[key] = validInputsNotRequired(location.info[key], checkValidEmail);
            break;
          case "zip":
            errors[key] = validInputsNotRequired(location[key], checkValidZipCode);
            break;
        }
      }
    });

    return errors
  }

  onChangePassword = (field, evt) => {
    this.setState({[field]:evt.target.value,isEmptyPassword:false,isEmptyConfirmPassword:false,notMatchPassword:false});
  }

  saveDeletePassword = () => {
    let {password, confirmPassword} = this.state;
    if (password === "") {
      this.setState({isEmptyPassword: true});
    } else if (confirmPassword === "") {
      this.setState({isEmptyConfirmPassword: true});
    } else if(password !== confirmPassword){
      this.setState({notMatchPassword: true});
    }else{
      this.setState({isLoadingModal: true});
      saveDealerConfiguration({code:INVENTORY_DELETE_PASSWORD_CODE,value:password}).then(response => {
        this.updateSetup(true,3);
        this.setState({isLoadingModal: false});
      }).catch(error => {
        console.error(error);
      });
    }
  }

  updateSetup = async (setupComplete, lastCompletedStep) => {
    const response = await dealerSetup(setupComplete, lastCompletedStep)
    const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE) {
          this.setState({errors: {}, lastCompletedStep: lastCompletedStep})
      } else {
          console.error(responseMessage)
      }
  }

  goToTheme = () => {
    this.props.setMenuId("setting");
    browserHistory.push(`/main/setting/template`);
  }

  resendVerificationEmail = () => {
    sendEmailVerification()
      .then(response => {
        if (response.ok) {this.setState({isEmailSent : true})}
    }).catch (
      error =>  {
     console.error(error)
    })
  }
  addOpeningHour = () => {
    const {location} = this.state
    this.setState({location: addOpeningHourToLocation(location)})
  }

  selectOptionInOpeningHour = (value, key, index) => {
    const {location} = this.state
    this.setState({location: updateLocationOpeningHourOption(location, value, key, index)})
  }

  deleteOpeningHour = (index) => {
    const {location} = this.state
    this.setState({location: deleteOpeningHourAtIndex(location, index)})
  }

  render() {
    const {user, templates, isLoading, isSetupComplete, isAccountOwner, dealerUpdate, file, dealerName, anchorEl, showPopoverDropDown,
      selectPopoverDropDown, errors, statusPopoverValue, statePopoverValue, disabled, location, showPopoverStatus, showPopoverState,
      lastCompletedStep, isLoadingModal, isEmptyPassword, isEmptyConfirmPassword, notMatchPassword, catalogUrl, hasAdminLayout, dashboards,dashboardData,
      fileDealerIcon, isVerified, isEmailSent
    } = this.state;
    const {height, templateId, showPopoverHour} = this.props;
    let indexTemplate = -1;
    let templateSelected

    if (templates){
      indexTemplate = findIndex(templates,(template) => {return template.id === templateId});
      if (indexTemplate !== -1)
        templateSelected = templates[indexTemplate].code;
    }

    if(!user || !dealerUpdate)
      return null;

    let layout

    if(hasAdminLayout) {
      const adminDashboardData = {
        ...dashboardData,
        templateSelected: templateSelected && `${templateSelected}.jpg`,
        onClickTheme: this.goToTheme,
        dealerName: dealerName,
        catalogUrl: catalogUrl
      }
      layout = (
        <DashboardContext.Provider value={adminDashboardData}>
          <AdminLayout />
        </DashboardContext.Provider>
      )
    }else if(isEmpty(dashboards))
      layout = <DashboardNotAssigned />
    else {
      layout = (
        <DashboardContext.Provider value={dashboardData}>
          <SaleTechLayout/>
        </DashboardContext.Provider>
      )
    }

    return (
      <div id="container" className="container-component" style={{height:height}}>
        <div className="dashboard-header" >
          <div className="title-dashboard">
            <h2>Dashboard</h2>
          </div>
        </div>
        {
          (!isVerified && !isEmailSent) &&
            <div className="message-bar-container message-alert ">
              <span>You haven’t verified your email. Please check your inbox and follow the link provided.</span>
              <span className="button" onClick={this.resendVerificationEmail}>Resend verification email</span>
            </div>
        }
        {
          (!isVerified && isEmailSent) &&
          <div className="message-bar-container">
            <span>Verification email sent</span>
          </div>
        }
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> : layout
        }
        {
          !isSetupComplete && isAccountOwner ?
            <ModalSetup isLoading={isLoadingModal} show={!isSetupComplete} lastCompletedStep={lastCompletedStep} dealer={dealerUpdate} file={file} onDrop={this.onDrop}
                        dealerName={dealerName} hasChange={true} save={this.updateData} onChange={this.onChangeValue} anchorEl={anchorEl} showPopoverDropDown={showPopoverDropDown}
                        selectPopoverDropDown={selectPopoverDropDown} deleteFile={this.deleteFile} errors={errors} closePopoverSelect={this.closePopoverSelect}
                        selectOptionPopover={this.selectOptionPopover} showPopoverDropDownModal={this.showPopoverDropDownModal} statePopoverValue={statePopoverValue}
                        statusPopoverValue={statusPopoverValue} showPopoverModal={this.showPopover} disabled={disabled} location={location}
                        showPopoverState={showPopoverState} showPopoverStatus={showPopoverStatus} inputChange={this.inputChange} renderObject={this.renderObject}
                        saveAndUpdateLocation={this.saveLocation} showPopoverHour={showPopoverHour} renderHours={this.renderHours} updateSetup={this.updateSetup}
                        onChangePassword={this.onChangePassword} isEmptyPassword={isEmptyPassword} isEmptyConfirmPassword={isEmptyConfirmPassword}
                        notMatchPassword={notMatchPassword} saveDeletePassword={this.saveDeletePassword} onHide={this.onHide}
                        fileDealerIcon={fileDealerIcon}
                        selectObject={this.selectObject}
                        selectHour={this.selectHour}
                        addOpeningHour={this.addOpeningHour}
                        selectOptionInOpeningHour = {this.selectOptionInOpeningHour}
                        deleteOpeningHour = {this.deleteOpeningHour}
            /> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    templateId: state.templateId,
    isSuperuser: state.isSuperuser
  }
};

export default connect(mapStateToProps,{setMenuId})(Dashboard)