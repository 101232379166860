import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {
  pathServer,
  PAGE_HOME_ID,
  SECTION_FINANCE_STEPS_DESCRIPTION,
  PAGE_FINANCE_ID,
  SECTION_CONTACT_US_ID,
  SECTION_FINANCE_ID,
  SECTION_INVENTORY_ID,
  SECTION_LOCATION_ID,
  SECTION_FINANCE_STEPS_TITLE,
  TEMPLATE_CODE_MODERN_VIEW_3,
  TEMPLATE_CODE_MODERN_VIEW_2,
  TEMPLATE_CODE_MODERN_VIEW,
  TEMPLATE_CODE_EASY_NAVIGATION_C, TEMPLATE_CODE_EASY_NAVIGATION_B, TEMPLATE_CODE_EASY_NAVIGATION,
} from '../../../../common/Constants'
import {findIndex, cloneDeep} from 'lodash'

const KEY_SECTION_TITLE       = "title";
const KEY_SECTION_DES         = "description";
const KEY_SECTION_BUTTON_CHECK= "showButton";

export default class SettingHome extends Component{

  constructor(){
    super();
    this.handleMenu             = this.handleMenu.bind(this);
    this.handleCheck            = this.handleCheck.bind(this);
    this.state = {
      selectedSection: '',
      financeSteps: {}
    }

  };

  componentWillMount(){
    const {pages,selectedPage} = this.props;
    let pagesCopy = cloneDeep(pages);
    const indexHomePage = findIndex(pagesCopy, function(page) { return page.id === PAGE_HOME_ID});
    const indexOfSec = findIndex(pagesCopy[indexHomePage].sections, function(sec) { return sec.id === SECTION_FINANCE_ID});
    let steps = pagesCopy[selectedPage].sections[indexOfSec].steps
    let financeSteps = {};
    steps.map((step) => {
      financeSteps[step.id] = false
    });
    this.setState({financeSteps: financeSteps})
  }

  componentDidUpdate(){
  }

  renderSectionByPage(id,section, templateCode){
    let {financeSteps} = this.state;
    let sec = null;
    if(id === SECTION_FINANCE_ID){
      sec = (
        <div className="body-sec-details">
          <div className="row-sec-info">
            <div className="row-sec-details">
              <label className="label-sec">TITLE</label>
              <input className="form-control" value={section.title} onChange={(e) => this.onChangeInput(KEY_SECTION_TITLE,e)}/>
            </div>
            {
              templateCode !== TEMPLATE_CODE_MODERN_VIEW && templateCode !== TEMPLATE_CODE_MODERN_VIEW_2 && templateCode !== TEMPLATE_CODE_MODERN_VIEW_3?
                <div className="row-sec-details">
                  <label className="label-sec">DESCRIPTION</label>
                  <input className="form-control" value={section.description} onChange={(e) => this.onChangeInput(KEY_SECTION_DES,e)}/>
                </div> : null
            }
            <div className="row-sec-details">
              <label className="label-sec">Apply Now Tab</label>
              <div className="sec-switch">
                <label className="switch" onChange={() => this.handleCheck(KEY_SECTION_BUTTON_CHECK)}>
                  <input type="checkbox" checked={section[KEY_SECTION_BUTTON_CHECK]} />
                  <span className={section[KEY_SECTION_BUTTON_CHECK] ? "slider round" : "slider round off"} />
                  {
                    section[KEY_SECTION_BUTTON_CHECK] ?
                      <span className="check-label-on">ON</span> :
                      <span className="check-label-off">OFF</span>
                  }
                </label>
              </div>
            </div>
          </div>
          <div className="row-step">
            <label className="label-sec">STEPS</label>
            {
              section.steps.map((step,index) => {
                return (
                  <div key={index} className="btn-message-dp">
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_menu.svg"} className="icon-menu-content" beforeInjection={ svg => svg.classList.add("icon-menu")}/>
                    <ReactSVG src={step.icon} className="icon-step-content" beforeInjection={ svg => svg.classList.add("icon-step")}/>
                    <div className="col-sec">
                      <div className="panel-left">
                        <label className="title-sec">Title</label>
                        <input className="form-control input-steps" value={step.displayValue} onChange={(e) => this.onChangeInput(SECTION_FINANCE_STEPS_TITLE,e,step.id)}
                               style={{pointerEvents: financeSteps[step.id] ? "" : "none"}}/>
                        {
                          templateCode === TEMPLATE_CODE_MODERN_VIEW || templateCode === TEMPLATE_CODE_MODERN_VIEW_2 || templateCode === TEMPLATE_CODE_MODERN_VIEW_3?
                            <div>
                              <label className="description-sec">Description</label>
                              <textarea id='content' className="comments-edit" placeholder='Type here' rows={4}
                                        value={step.description}
                                        onChange={(e) => this.onChangeInput(SECTION_FINANCE_STEPS_DESCRIPTION,e,step.id)}
                                        style={{pointerEvents: financeSteps[step.id] ? "" : "none"}}/>
                            </div> : null
                        }
                      </div>
                      <div className="panel-right">
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_eraser.svg"} className="icon-action-content" beforeInjection={ svg => svg.classList.add("icon-action")}
                                  onClick={this.cleanSteps.bind(this,step.id)}/>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"} className="icon-action-edit" beforeInjection={ svg => svg.classList.add("icon-action")}
                                  onClick={this.enableStep.bind(this,step.id)}/>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    }
    if(id === SECTION_CONTACT_US_ID){
      sec = (
        <div className="body-sec-details">
          <div className="row-sec-info">
            <div className="row-sec-details">
              <label className="label-sec">TITLE</label>
              <input className="form-control" value={section.title} onChange={(e) => this.onChangeInput(KEY_SECTION_TITLE,e)}/>
            </div>
          </div>
        </div>
      )
    }
    if(id === SECTION_INVENTORY_ID){
      sec = (
        <div className="body-sec-details">
          <div className="row-sec-info">
            <div className="row-sec-details">
              <label className="label-sec">TITLE</label>
              <input className="form-control" value={section.title} onChange={(e) => this.onChangeInput(KEY_SECTION_TITLE,e)}/>
            </div>
          </div>
        </div>
      )
    }
    if(id === SECTION_LOCATION_ID){
      sec = (
        <div className="body-sec-details">
          <div className="row-sec-info">
            <div className="row-sec-details">
              <label className="label-sec">TITLE</label>
              <input className="form-control" value={section.title} onChange={(e) => this.onChangeInput(KEY_SECTION_TITLE,e)}/>
            </div>
          </div>
        </div>
      )
    }

    return sec;
  }

  enableStep(key){
    let {financeSteps} = this.state;
    financeSteps[key] = true;
    this.setState({financeSteps: financeSteps})
  }

  cleanSteps(key){
    const {pages, onChange,selectedPage} = this.props;
    let pagesNew = cloneDeep(pages);
    let pageHome = pagesNew[selectedPage];
    const section = pageHome.sections.find(sec => { return sec.id === PAGE_FINANCE_ID});
    const step = section.steps.find(sec => { return sec.id === key});
    step.displayValue = "";
    step.description = "";

    onChange(pagesNew);
  }

  cleanValues(key){
    const {pages, onChange,selectedPage} = this.props;
    let pagesNew = cloneDeep(pages);
    let pageHome = pagesNew[selectedPage];
    const section = pageHome.sections.find(sec => { return sec.id === key});
    section.title = "";

    if (key === SECTION_FINANCE_ID){
      section.description = "";
      section.steps.forEach((step) => {
        step.displayValue = "";
      });
    }
    onChange(pagesNew);
  }

  renderSection(page,id, templateCode){
    let view;
    let that = this;
    if(id === ""){
      const {selectedSection} = this.state;
      view = (
        <div className="body-main">
          <div className="">
            <div className="title-sec">
              <labe>Manage Sections</labe>
            </div>
            {
              page.sections.map((section,index) => {
                return(
                  <div key={index} className="row-sec">
                    <div className={(section.id === selectedSection) ? "btn-message-dp border-sec":"btn-message-dp"}
                    >
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_menu.svg"} className="icon-menu-content" beforeInjection={ svg => svg.classList.add("icon-menu")}/>
                      <div className="col-sec">
                        <label className="label-sec">{section.displayValue}</label>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_eraser.svg"} className="icon-action-content-delete" beforeInjection={ svg => svg.classList.add("icon-action")}
                                  onClick={this.cleanValues.bind(this,section.id)}/>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"} className="icon-action-content" beforeInjection={ svg => svg.classList.add("icon-action")}
                                  onClick={(event) => that.handleMenu(event,"selectedSection",section.id)}/>
                      </div>
                    </div>
                    {(section.id === selectedSection) ? this.renderSectionByPage(selectedSection,section, templateCode) : null}
                  </div>
                )
              })
            }
          </div>
        </div>
      );

    }

    return view;
  }

  handleCheck(key){
    const {pages,selectedPage,onChange} = this.props;
    //event.preventDefault();
    let pagesNew = cloneDeep(pages);
    let value;
    if(key === KEY_SECTION_BUTTON_CHECK){
      const {selectedSection} = this.state;
      const indexOfSec = findIndex(pagesNew[selectedPage].sections, function(sec) { return sec.id === selectedSection});
      value = pagesNew[selectedPage].sections[indexOfSec][key];
      pagesNew[selectedPage].sections[indexOfSec][key] = !value;
    }
    onChange(pagesNew);
  }

  onChangeInput = (type,event,idSubSec) => {
    const {pages,selectedPage,onChange} = this.props;
    const {selectedSection} = this.state;
    let pagesNew = cloneDeep(pages);
    const indexOfSec = findIndex(pagesNew[selectedPage].sections, function(sec) { return sec.id === selectedSection});

    if(type === KEY_SECTION_TITLE || type === KEY_SECTION_DES){
      pagesNew[selectedPage].sections[indexOfSec][type] = event.target.value;
    }
    if(type === SECTION_FINANCE_STEPS_TITLE){
      const indexStep = findIndex(pagesNew[selectedPage].sections[indexOfSec].steps, function(sec) { return sec.id === idSubSec});
      pagesNew[selectedPage].sections[indexOfSec].steps[indexStep].displayValue = event.target.value;
    }
    if(type === SECTION_FINANCE_STEPS_DESCRIPTION){
      const indexStep = findIndex(pagesNew[selectedPage].sections[indexOfSec].steps, function(sec) { return sec.id === idSubSec});
      pagesNew[selectedPage].sections[indexOfSec].steps[indexStep].description = event.target.value;
    }

    onChange(pagesNew);
  }

  handleMenu(event,key,value){
    event.preventDefault();
    let {templateId, templateCode} = this.props;
    let selected = this.state[key];
    if(selected !== value)
      selected = value;
    else
      selected = "";
    let el;
    if(value === SECTION_FINANCE_ID){
      el = document.getElementById("layoutFinance");
    }
    if(value === SECTION_CONTACT_US_ID){
      if(templateCode === TEMPLATE_CODE_EASY_NAVIGATION || templateCode === TEMPLATE_CODE_EASY_NAVIGATION_B || templateCode === TEMPLATE_CODE_MODERN_VIEW_2)
        el = document.getElementById("contactMain");
      if(templateCode === TEMPLATE_CODE_MODERN_VIEW || templateCode === TEMPLATE_CODE_MODERN_VIEW_3 || templateCode === TEMPLATE_CODE_EASY_NAVIGATION_C)
        el = document.getElementById("contact5");
    }
    if(value === SECTION_INVENTORY_ID){
      if(templateCode === TEMPLATE_CODE_EASY_NAVIGATION || templateCode === TEMPLATE_CODE_EASY_NAVIGATION_B || templateCode === TEMPLATE_CODE_EASY_NAVIGATION_C)
        el = document.getElementById("layoutCarContent");
      if(templateCode === TEMPLATE_CODE_MODERN_VIEW || templateCode === TEMPLATE_CODE_MODERN_VIEW_2 || templateCode === TEMPLATE_CODE_MODERN_VIEW_3)
        el = document.getElementById("carCarousel");
    }
    if(value === SECTION_LOCATION_ID){
      if(templateCode === TEMPLATE_CODE_EASY_NAVIGATION || templateCode === TEMPLATE_CODE_EASY_NAVIGATION_B || templateCode === TEMPLATE_CODE_EASY_NAVIGATION_C)
        el = document.getElementById("locationMain");
      if(templateCode === TEMPLATE_CODE_MODERN_VIEW || templateCode === TEMPLATE_CODE_MODERN_VIEW_2 || templateCode === TEMPLATE_CODE_MODERN_VIEW_3)
        el = document.getElementById("location5");
    }
    if(el)
      el.scrollIntoView();
    this.setState({[key]:selected})
  }

  render(){
    const {pages,selectedPage, templateCode} = this.props;
    const page = pages[selectedPage];
    return(
      this.renderSection(page,"", templateCode)
    )
  }
}