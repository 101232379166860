import React from 'react'
import PropTypes from 'prop-types'
import SaleSettingColumn from './SaleSettingColumn'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'
import {
  loadDealerSaleStaticFiles,
  deleteDealerSaleStaticFile,
  updateDealerSaleStaticFile,
  createDealerSaleStaticFile,
  updateDealerSaleStaticFileList, loadAccessories, updateAccessory, deleteAccessory, updateReorderAccessories,
  updateDealerConfigurationValues,
  deleteDealerConfiguration,
  loadDealerConfigurationByCodes,
} from '../../../Api'
import isEmpty from 'lodash/isEmpty'
import {PROPERTY, TITLES, initialErrorMessagesObject} from './SaleSettingUpdateForm'
import DragDrop from '../../dragdrop/DragDrop.jsx'
import {
  deleteChecklist,
  loadChecklist,
  loadSaleConfigurations, saveAccessory,
  saveChecklist,
  updateChecklist,
  updateReorderChecklist
} from '../../../Api'
import isArray from 'lodash/isArray'
import {
  DEALER_DOCUMENTATION_FEE_CODE,
  DEALER_DOCUMENTATION_FEE_DEFAULT_VALUE,
  SALES_REGISTRATION_FEES_CODE,
  SALES_REGISTRATION_FEES_TITLE,
  SALES_REGISTRATION_FEES_DEFAULT_VALUE,
  SALES_NON_TAXABLE_STATE_INSPECTION_CODE,
  SALES_NON_TAXABLE_STATE_INSPECTION_TITLE,
  SALES_NON_TAXABLE_STATE_INSPECTION_DEFAULT_VALUE,
  SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE,
  SALES_NON_TAXABLE_TEMPORARY_PERMIT_TITLE,
  SALES_NON_TAXABLE_TEMPORARY_PERMIT_DEFAULT_VALUE,
  SALES_NON_TAXABLE_OTHERS_CODE,
  SALES_NON_TAXABLE_TITLING_FEE_CODE,
  SALES_NON_TAXABLE_TITLING_FEE_TITLE,
  SALES_NON_TAXABLE_TITLING_FEE_DEFAULT_VALUE
} from '../../../Constants'
import isString from 'lodash/isString'
import ModalConfirmDelete from '../../util/ModalConfirmDelete'
import {SUCCESSFUL_CODE} from "../../../../common/Constants";
import SettingUser from "../userSetting/SettingUser";


const OTHER_FEE = {
  ID: 'otherFeeID-',
  CODE: 'otherFeeCode-',
}

const SECTIONS = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
}

export const COLUMNS = {
  FILES: 'files',
  CHECKLIST: 'checklist',
  ACCESSORY: 'accessory',
  MORE_SETTINGS: 'moreSettings',
  REG_FEES_AND_OTHERS: 'registrationFeesAndOthers',
}

// Please don't modify this structure info
// Because SaleSetting component works with
const initialData = {
  elements: {}, // Info to be updated
  sections: {
    [SECTIONS.REQUIRED]: {
      id: SECTIONS.REQUIRED,
      title: 'Required',
      elementIds: [], // Info to be updated
    },
    [SECTIONS.OPTIONAL]: {
      id: SECTIONS.OPTIONAL,
      title: 'Optional',
      elementIds: [], // Info to be updated
    }
  },
  columns: {
    [COLUMNS.FILES]: {
      id: COLUMNS.FILES,
      title: 'Files',
      sectionIds: [SECTIONS.REQUIRED, SECTIONS.OPTIONAL],
    },
    [COLUMNS.CHECKLIST]: {
      id: COLUMNS.CHECKLIST,
      title: 'Checklist',
      sectionIds: [SECTIONS.REQUIRED, SECTIONS.OPTIONAL],
    },
    [COLUMNS.ACCESSORY]: {
      id: COLUMNS.ACCESSORY,
      title: 'Accessories',
      sectionIds: [SECTIONS.REQUIRED]
    },
    [COLUMNS.MORE_SETTINGS]: {
      id: COLUMNS.MORE_SETTINGS,
      title: 'More settings',
      sectionIds: [SECTIONS.REQUIRED],
    },
    [COLUMNS.REG_FEES_AND_OTHERS]: {
      id: COLUMNS.REG_FEES_AND_OTHERS,
      title: 'Registration fee and other non-taxable items',
      sectionIds: [SECTIONS.REQUIRED],
    },
  },
  columnOrder: [COLUMNS.FILES, COLUMNS.CHECKLIST, COLUMNS.ACCESSORY, COLUMNS.MORE_SETTINGS, COLUMNS.REG_FEES_AND_OTHERS]
}


function getInitialData(columnName){
  const columnsWithJustRequiredSection = [
    COLUMNS.ACCESSORY,
    COLUMNS.MORE_SETTINGS,
    COLUMNS.REG_FEES_AND_OTHERS,
  ]
  return {
    elements: {}, // Info to be updated
    sections: (!columnsWithJustRequiredSection.includes(columnName)) ? {
      [SECTIONS.REQUIRED]: {
        id: SECTIONS.REQUIRED,
        title: 'Required',
        elementIds: [], // Info to be updated
      },
      [SECTIONS.OPTIONAL]: {
        id: SECTIONS.OPTIONAL,
        title: 'Optional',
        elementIds: [], // Info to be updated
      }
    } : {
      [SECTIONS.REQUIRED]: {
        id: SECTIONS.REQUIRED,
        title: 'Required',
        elementIds: [], // Info to be updated
      }
    },
    columns: {
      [COLUMNS.FILES]: {
        id: COLUMNS.FILES,
        title: 'Files',
        sectionIds: [SECTIONS.REQUIRED, SECTIONS.OPTIONAL],
      },
      [COLUMNS.CHECKLIST]: {
        id: COLUMNS.CHECKLIST,
        title: 'Checklist',
        sectionIds: [SECTIONS.REQUIRED, SECTIONS.OPTIONAL],
      },
      [COLUMNS.ACCESSORY]: {
        id: COLUMNS.ACCESSORY,
        title: 'Accessories',
        sectionIds: [SECTIONS.REQUIRED]
      },
      [COLUMNS.MORE_SETTINGS]: {
        id: COLUMNS.MORE_SETTINGS,
        title: 'More settings',
        sectionIds: [SECTIONS.REQUIRED],
      },
      [COLUMNS.REG_FEES_AND_OTHERS]: {
        id: COLUMNS.REG_FEES_AND_OTHERS,
        title: 'Registration fee and other non-taxable items',
        sectionIds: [SECTIONS.REQUIRED],
      },
    },
    columnOrder: [COLUMNS.FILES, COLUMNS.CHECKLIST, COLUMNS.ACCESSORY, COLUMNS.MORE_SETTINGS, COLUMNS.REG_FEES_AND_OTHERS]
  }
}

class SaleSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: null,
      columnOrder: [COLUMNS.FILES, COLUMNS.CHECKLIST, COLUMNS.ACCESSORY, COLUMNS.MORE_SETTINGS, COLUMNS.REG_FEES_AND_OTHERS],
      type: null,
      showDeletionModal: false,   // Flag to determine if modal is shown after delete icon is clicked on RegFeeAndOthers Column
      currentElementIdToDelete: null,   // Element ID that will be deleted on RegFeeAndOthers Column
      currentColumnNameToDelete: null,  // Column of element that will be deleted on RegFeeAndOthers Column
    }
  }

  componentDidMount() {
    this.setState({isLoading: true});
    loadSaleConfigurations().then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {files,checklist,accessories, dealerConfigurations } = data;
        if (response && checklist)
          this.formatDBData(getInitialData(COLUMNS.CHECKLIST), checklist, COLUMNS.CHECKLIST);

        if (response && files)
          this.formatDBData(getInitialData(COLUMNS.FILES), files, COLUMNS.FILES);

        if (response && accessories)
          this.formatDBData(getInitialData(COLUMNS.ACCESSORY), accessories, COLUMNS.ACCESSORY);

        let newDealerConfiguration = dealerConfigurations.filter(dealerConfig => { return dealerConfig.code === DEALER_DOCUMENTATION_FEE_CODE})
        // Implemented in case there is no record for dealer documentation fee.
        if (isArray(newDealerConfiguration) && !newDealerConfiguration.length) {
          newDealerConfiguration = [
            {
              id: DEALER_DOCUMENTATION_FEE_CODE,
              code: DEALER_DOCUMENTATION_FEE_CODE,
              value: DEALER_DOCUMENTATION_FEE_DEFAULT_VALUE,
            }
          ]
        }

        this.formatDBData(getInitialData(COLUMNS.MORE_SETTINGS), newDealerConfiguration, COLUMNS.MORE_SETTINGS);

        let newRegistrationFeesAndOthers = this.formatConfigurationsForFeesAndOthersSection(dealerConfigurations);
        this.formatDBData(getInitialData(COLUMNS.REG_FEES_AND_OTHERS), newRegistrationFeesAndOthers, COLUMNS.REG_FEES_AND_OTHERS);
      }else{
        console.log(responseMessage)
        this.setState({isLoading: false})
      }
    })
  }

  // Formats data to show items on Registration Fee and Other non-taxable items Column
  formatConfigurationsForFeesAndOthersSection(dealerConfigurations) {
    let registrationFeesAndOthers = dealerConfigurations && dealerConfigurations.filter(el => el.code !== DEALER_DOCUMENTATION_FEE_CODE)
    let newRegistrationFeesAndOthers = []

    // Implemented in case there is no record for registration fees and others non-taxable items.
    if (isArray(registrationFeesAndOthers)) {
      if (!registrationFeesAndOthers.length) {
        newRegistrationFeesAndOthers = [
          {
            id: SALES_REGISTRATION_FEES_CODE,
            code: SALES_REGISTRATION_FEES_CODE,
            description: SALES_REGISTRATION_FEES_TITLE,
            value: SALES_REGISTRATION_FEES_DEFAULT_VALUE,
          },
          {
            id: SALES_NON_TAXABLE_STATE_INSPECTION_CODE,
            code: SALES_NON_TAXABLE_STATE_INSPECTION_CODE,
            description: SALES_NON_TAXABLE_STATE_INSPECTION_TITLE,
            value: SALES_NON_TAXABLE_STATE_INSPECTION_DEFAULT_VALUE,
          },
          {
            id: SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE,
            code: SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE,
            description: SALES_NON_TAXABLE_TEMPORARY_PERMIT_TITLE,
            value: SALES_NON_TAXABLE_TEMPORARY_PERMIT_DEFAULT_VALUE,
          },
          {
            id: SALES_NON_TAXABLE_TITLING_FEE_CODE,
            code: SALES_NON_TAXABLE_TITLING_FEE_CODE,
            description: SALES_NON_TAXABLE_TITLING_FEE_TITLE,
            value: SALES_NON_TAXABLE_TITLING_FEE_DEFAULT_VALUE
          }
        ]
      } else {
        const currentRegistrationFee = registrationFeesAndOthers.find(el => el.code === SALES_REGISTRATION_FEES_CODE)
        const currentStateInspection = registrationFeesAndOthers.find(el => el.code === SALES_NON_TAXABLE_STATE_INSPECTION_CODE)
        const currentTemporaryPermit = registrationFeesAndOthers.find(el => el.code === SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE)
        const currentOtherFess = registrationFeesAndOthers.find(el => el.code === SALES_NON_TAXABLE_OTHERS_CODE)
        const currentTitlingFee = registrationFeesAndOthers.find(el => el.code === SALES_NON_TAXABLE_TITLING_FEE_CODE)

        if (currentOtherFess) {
          const otherFees = isString(currentOtherFess.value)
            && !isEmpty(currentOtherFess.value)
            && JSON.parse(currentOtherFess.value)

          if (isArray(otherFees) && otherFees.length) {
            const newOtherFees = otherFees.map((el, index) => ({
              ...el,
              value: el.value ? el.value.toString() : "0",
              id: `${OTHER_FEE.ID}${index}-${currentOtherFess.id}`,
            }))

            newRegistrationFeesAndOthers = newOtherFees
          }
        }

        newRegistrationFeesAndOthers = [
          {
            id: currentRegistrationFee ? currentRegistrationFee.id : SALES_REGISTRATION_FEES_CODE,
            code: currentRegistrationFee ? currentRegistrationFee.code : SALES_REGISTRATION_FEES_CODE,
            description: currentRegistrationFee && currentRegistrationFee.description
              ? currentRegistrationFee.description
              : SALES_REGISTRATION_FEES_TITLE,
            value: currentRegistrationFee ? currentRegistrationFee.value : SALES_REGISTRATION_FEES_DEFAULT_VALUE,
          },
          {
            id: currentStateInspection ? currentStateInspection.id : SALES_NON_TAXABLE_STATE_INSPECTION_CODE,
            code: currentStateInspection ? currentStateInspection.code : SALES_NON_TAXABLE_STATE_INSPECTION_CODE,
            description: currentStateInspection && currentStateInspection.description
              ? currentStateInspection.description
              : SALES_NON_TAXABLE_STATE_INSPECTION_TITLE,
            value: currentStateInspection ? currentStateInspection.value : SALES_NON_TAXABLE_STATE_INSPECTION_DEFAULT_VALUE,
          },
          {
            id: currentTemporaryPermit ? currentTemporaryPermit.id : SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE,
            code: currentTemporaryPermit ? currentTemporaryPermit.code : SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE,
            description: currentTemporaryPermit && currentTemporaryPermit.description
              ? currentTemporaryPermit.description
              : SALES_NON_TAXABLE_TEMPORARY_PERMIT_TITLE,
            value: currentTemporaryPermit ? currentTemporaryPermit.value : SALES_NON_TAXABLE_TEMPORARY_PERMIT_DEFAULT_VALUE,
          },
          {
            id: currentTitlingFee?.id ?? SALES_NON_TAXABLE_TITLING_FEE_CODE,
            code: currentTitlingFee?.code ?? SALES_NON_TAXABLE_TITLING_FEE_CODE,
            description: currentTitlingFee?.description ?? SALES_NON_TAXABLE_TITLING_FEE_TITLE,
            value: currentTitlingFee?.value ?? SALES_NON_TAXABLE_TITLING_FEE_DEFAULT_VALUE,
          },
          ...newRegistrationFeesAndOthers,
        ]
      }
    }
    return newRegistrationFeesAndOthers;
  }

// Hits API to get all dealer sale static files
  loadSaleStaticFiles(currentData, columnName) {
    const {isLoading} = this.state

    if (!isLoading) {
      this.setState({isLoading: true})
    }
    loadDealerSaleStaticFiles()
      .then(response => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.formatDBData(currentData, data.files, columnName)
        } else {
          console.log(responseMessage)
          this.setState({isLoading: false})
        }
      })
  }

  loadChecklist(currentData, columnName) {
    const {isLoading} = this.state

    if (!isLoading) {
      this.setState({isLoading: true})
    }
    loadChecklist()
      .then(response => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE && data.listCheckList) {
          this.formatDBData(currentData, data.listCheckList, columnName)
        }else{
          console.log(responseMessage)
          this.setState({isLoading: false})
        }
      })
  }

  loadAccessories(currentData, columnName){
    const {isLoading} = this.state

    if (!isLoading) {
      this.setState({isLoading: true})
    }
    loadAccessories().then(response => {
      const {responseCode, responseMessage,data} = response
      if (responseCode === SUCCESSFUL_CODE && data.accessories) {
        this.formatDBData(currentData, data.accessories, columnName)
      } else {
        console.error("error:", responseMessage);
        this.setState({isLoading: false})
      }
    })
  }

  // Loads info to populate Registration Fee and other non-taxable items column
  loadFeesAndOthers(currentData, columnName) {
    const {isLoading} = this.state
    const codesToFind = `${SALES_REGISTRATION_FEES_CODE},${SALES_NON_TAXABLE_STATE_INSPECTION_CODE},${SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE},${SALES_NON_TAXABLE_OTHERS_CODE},${SALES_NON_TAXABLE_TITLING_FEE_CODE}`

    if (!isLoading) {
      this.setState({isLoading: true})
    }

    loadDealerConfigurationByCodes(codesToFind)
      .then(response => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE && data?.dealerConfigurations) {
          let newRegistrationFeesAndOthers = this.formatConfigurationsForFeesAndOthersSection(data.dealerConfigurations);
          this.formatDBData(currentData, newRegistrationFeesAndOthers, columnName)
        } else {
          console.error(responseMessage);
          this.setState({selectedStatus: []});
        }
    });

  }

  // Formats data from DB into a useful way to manipulate on this component
  formatDBData(currentData, dataFromDB, columnName) {
    // Formats elements from DB
    const dataFromDBIntoArray = isArray(dataFromDB) ? dataFromDB : [dataFromDB]
    const keys = dataFromDBIntoArray.map(element => element.id)
    const formattedElements = keys.reduce((result, key) => {
      return {
        ...result,
        [key]: dataFromDBIntoArray.find(el => el.id === key)
      }
    }, {})
    // Updating data
    this.updateDataFromState(currentData, formattedElements, columnName)
  }

  // Update 'data' property on State
  updateDataFromState(currentData, newElements, columnName) {
    let newData = {...currentData};
    //let {generalColumns} = this.state;
    const dataFromState = {...this.state.data}
    const columnsWithJustRequiredSection = [
      COLUMNS.ACCESSORY,
      COLUMNS.MORE_SETTINGS,
      COLUMNS.REG_FEES_AND_OTHERS,
    ]
    newData = {
      ...newData,
      elements: newElements,
      sections: (!columnsWithJustRequiredSection.includes(columnName)) ? {
        [SECTIONS.REQUIRED]: {
          ...newData.sections[SECTIONS.REQUIRED],
          elementIds: this.getElementIds(newElements, columnName)['requiredElementIds'],
        },
        [SECTIONS.OPTIONAL]: {
          ...newData.sections[SECTIONS.OPTIONAL],
          elementIds: this.getElementIds(newElements, columnName)['optionalElementIds'],
        }
      } : {
        [SECTIONS.REQUIRED]: {
          ...newData.sections[SECTIONS.REQUIRED],
          elementIds: this.getElementsIdsOneSection(newElements),
        }
      }
    }
    this.setState(
      {
        data: {
          ...dataFromState,
          [columnName]: newData,
        },
        //[keyState]: newData,
        isLoading: false,
      })
  }

  // Filters element based on `optional` column and gets list of Ids
  getElementIds(formattedElements, columnName) {
    const keyElement = columnName === COLUMNS.FILES ? "optional" : "required";
    const expectedValue =  columnName !== COLUMNS.FILES;
    const requiredElements =
      sortBy(values(formattedElements).filter(el => el[keyElement] === expectedValue), el => el.order)
    const optionalElements =
      sortBy(values(formattedElements).filter(el => el[keyElement] === !expectedValue), el => el.order)
    const requiredElementIds = requiredElements.map(el => el.id)
    const optionalElementIds = optionalElements.map(el => el.id)

    return {
      requiredElementIds,
      optionalElementIds
    }
  }

  getElementsIdsOneSection(formattedElements){
    return sortBy(values(formattedElements),el => el.order).map(el => el.id)
  }

  // Goes up an element
  // indexOnSection: index of the element on the sorted 'sectionIds' property
  goUp = (sectionId, indexOnSection, columnName) =>  {
    const currentData = {...this.state.data[columnName]}
    const elements = {...this.state.data[columnName].elements}
    let currentElement = null   // Element which 'up' button was clicked
    let upElement = null  // Element that is up of the currentElement

    if (sectionId === SECTIONS.REQUIRED && indexOnSection === 0) {
      return
    }

    if (sectionId === SECTIONS.REQUIRED && indexOnSection > 0) {
      const currentElementId = currentData.sections[SECTIONS.REQUIRED].elementIds[indexOnSection]
      const upElementId = currentData.sections[SECTIONS.REQUIRED].elementIds[indexOnSection - 1]

      currentElement = {
        ...elements[currentElementId],
        order: elements[upElementId]['order']
      }
      upElement = {
        ...elements[upElementId],
        order: elements[currentElementId]['order']
      }
    }

    if (sectionId === SECTIONS.OPTIONAL && indexOnSection === 0) {
      const currentElementId = currentData.sections[SECTIONS.OPTIONAL].elementIds[indexOnSection]
      const propName = columnName === COLUMNS.FILES ? "optional" : "required";
      currentElement = {
        ...elements[currentElementId],
        [propName]: columnName !== COLUMNS.FILES,
      }
    }

    if (sectionId === SECTIONS.OPTIONAL && indexOnSection > 0) {
      const currentElementId = currentData.sections[SECTIONS.OPTIONAL].elementIds[indexOnSection]
      const upElementId = currentData.sections[SECTIONS.OPTIONAL].elementIds[indexOnSection - 1]

      currentElement = {
        ...elements[currentElementId],
        order: elements[upElementId]['order']
      }
      upElement = {
        ...elements[upElementId],
        order: elements[currentElementId]['order']
      }
    }
    // Hits API
    if (columnName === COLUMNS.FILES) {
      this.updateDealerSaleStaticFileAfterMovements(currentElement, upElement, currentData, columnName)
    }

    if (columnName === COLUMNS.CHECKLIST) {
      this.updateDealerChecklistAfterMovements(currentElement, upElement, currentData, columnName)
    }

    if (columnName === COLUMNS.ACCESSORY) {
      this.updateDealerAccessoryAfterMovements(currentElement, upElement, currentData, columnName)
    }

  }

  // Goes down an element
  // indexOnSection: index of the element on the sorted 'sectionIds' property
  goDown = (sectionId, indexOnSection, columnName) => {
    const currentData = {...this.state.data[columnName]}
    const numElementsOnOptionalSection = this.getNumberElementsBasedOnSection(SECTIONS.OPTIONAL, columnName)
    const numElementsOnRequiredSection = this.getNumberElementsBasedOnSection(SECTIONS.REQUIRED, columnName)
    const elements = {...this.state.data[columnName].elements}
    let currentElement = null   // Element which 'down' button was clicked
    let downElement = null  // Element that is down of the currentElement

    if (sectionId === SECTIONS.REQUIRED && indexOnSection < (numElementsOnRequiredSection - 1)) {
      const currentElementId = currentData.sections[SECTIONS.REQUIRED].elementIds[indexOnSection]
      const downElementId = currentData.sections[SECTIONS.REQUIRED].elementIds[indexOnSection + 1]

      currentElement = {
        ...elements[currentElementId],
        order: elements[downElementId]['order']
      }
      downElement = {
        ...elements[downElementId],
        order: elements[currentElementId]['order']
      }
    }

    if (sectionId === SECTIONS.REQUIRED && indexOnSection === (numElementsOnRequiredSection - 1)) {
      if(columnName !== COLUMNS.ACCESSORY){
        const currentElementId = currentData.sections[SECTIONS.REQUIRED].elementIds[indexOnSection]
        const propName = columnName === COLUMNS.FILES ? "optional" : "required";
        currentElement = {
          ...elements[currentElementId],
          [propName]: columnName === COLUMNS.FILES,
        }
      }else{
        return null;
      }

    }

    if (sectionId === SECTIONS.OPTIONAL && indexOnSection < (numElementsOnOptionalSection - 1)) {
      const currentElementId = currentData.sections[SECTIONS.OPTIONAL].elementIds[indexOnSection]
      const downElementId = currentData.sections[SECTIONS.OPTIONAL].elementIds[indexOnSection + 1]

      currentElement = {
        ...elements[currentElementId],
        order: elements[downElementId]['order']
      }
      downElement = {
        ...elements[downElementId],
        order: elements[currentElementId]['order']
      }
    }

    if (sectionId === SECTIONS.OPTIONAL && indexOnSection === (numElementsOnOptionalSection - 1)) {
      return
    }
    // Hits API
    if (columnName === COLUMNS.FILES) {
      this.updateDealerSaleStaticFileAfterMovements(currentElement, downElement, currentData, columnName)
    }

    if (columnName === COLUMNS.CHECKLIST) {
      this.updateDealerChecklistAfterMovements(currentElement, downElement, currentData, columnName)
    }

    if (columnName === COLUMNS.ACCESSORY) {
      this.updateDealerAccessoryAfterMovements(currentElement, downElement, currentData, columnName)
    }
  }

  // Updates at least one dealer sale static file on DB
  // Could be two if the other one exists. And always updates on State
  updateDealerSaleStaticFileAfterMovements(currentElement, otherElement, currentData, columnName) {
    let data = { items: [currentElement]};
    const {isLoading} = this.state;
    if(otherElement)
      data.items.push(otherElement);
    if (!isLoading)
      this.setState({isLoading: true});
    updateDealerSaleStaticFileList(data).then(response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE) {
        this.loadSaleStaticFiles(currentData, columnName)
      } else {
        console.error("error:", responseMessage);
        this.setState({isLoading: false});
      }
    })
  }

  updateDealerChecklistAfterMovements(currentElement, otherElement, currentData, columnName) {
    let data = {items: [{id: currentElement.id , order: currentElement.order, required: currentElement.required}]};
    const {isLoading} = this.state;
    if(otherElement)
      data.items.push({id: otherElement.id, order: otherElement.order, required: otherElement.required});
    if (!isLoading)
      this.setState({isLoading: true});
    updateReorderChecklist(data).then(response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.loadChecklist(currentData, columnName)
      }else{
        console.error("error:", responseMessage);
        this.setState({isLoading: false})
      }
    })
  }

  updateDealerAccessoryAfterMovements(currentElement, otherElement, currentData, columnName) {
    let data = {items: [{id: currentElement.id , order: currentElement.order}]};
    const {isLoading} = this.state;
    if(otherElement)
      data.items.push({id: otherElement.id, order: otherElement.order});
    if (!isLoading)
      this.setState({isLoading: true});
    updateReorderAccessories(data).then(response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE) {
        this.loadAccessories(currentData, columnName)
      } else {
        console.error("error:", responseMessage);
        this.setState({isLoading: false})
      }
    })
  }

  // Gets number of elements on specific section
  getNumberElementsBasedOnSection(sectionId, columnName) {
    return (
      this.state.data[columnName]
      && this.state.data[columnName].sections
      && this.state.data[columnName].sections[sectionId]
      && this.state.data[columnName].sections[sectionId].elementIds
      && this.state.data[columnName].sections[sectionId].elementIds.length
    )
  }

  // Deletes element on DB and also updates on State
  deleteElement = async (elementId, columnName) => {
    const currentData = {...this.state.data[columnName]}
    const {isLoading} = this.state;

    const deleteDealerSettingElement = async (id) =>  {
      const {responseCode, responseMessage} = await deleteDealerConfiguration(id)
      if (responseCode === SUCCESSFUL_CODE) {
        this.loadFeesAndOthers(currentData, columnName)
      } else {
        console.error("error:", responseMessage);
        this.setState({isLoading: false})
      }
    }

    // Hits API
    if (!isLoading)
      this.setState({isLoading: true, type: columnName});
    if(columnName === COLUMNS.FILES){
      deleteDealerSaleStaticFile(elementId)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadSaleStaticFiles(currentData, columnName)
          } else {
            console.log(responseMessage)
            this.setState({isLoading: false})
          }
        })
    }else if(columnName === COLUMNS.CHECKLIST){
      deleteChecklist(elementId)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadChecklist(currentData, columnName)
          }else {
            console.error("error:", responseMessage);
            this.setState({isLoading: false})
          }
      })
    }else if(columnName === COLUMNS.ACCESSORY){
      deleteAccessory(elementId, false).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE) {
          this.loadAccessories(currentData, columnName)
        } else {
          console.error("error:", responseMessage);
          this.setState({isLoading: false})
        }
      })
    }

    if (columnName === COLUMNS.REG_FEES_AND_OTHERS) {
      if (elementId.substring(0, 11) === OTHER_FEE.ID) {
        const realId = elementId.split('-')[2]
        let updatedElements = currentData['elements']
        delete updatedElements[elementId]

        const otherFeesToSave = this.formatOtherFeesToBeSaved(updatedElements)

        if (otherFeesToSave) {
          updateDealerConfigurationValues(otherFeesToSave)
            .then(response => {
              const {responseCode, responseMessage} = response
              if (responseCode === SUCCESSFUL_CODE) {
                this.loadFeesAndOthers(currentData, columnName)
              } else {
                console.error("error:", responseMessage);
                this.setState({isLoading: false})
              }
            })
        } else {
          await deleteDealerSettingElement(realId);
        }
      } else {
        await deleteDealerSettingElement(elementId);
      }
    }
  }

  // Updates element on DB and also updates on State
  editElement = (updatedElement, columnName) => {
    const currentData = {...this.state.data[columnName]}
    const {isLoading} = this.state;

    // Hits API
    if (!isLoading)
      this.setState({isLoading: true, type: columnName});
    if(columnName === COLUMNS.FILES){
      updateDealerSaleStaticFile(updatedElement)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadSaleStaticFiles(currentData, columnName)
          } else {
            console.log(responseMessage)
            this.setState({isLoading: false})
          }
        })
    }else if(columnName === COLUMNS.CHECKLIST){
      const updateItem = {description: updatedElement.description, required: updatedElement.required};
      updateChecklist(updatedElement.id, updateItem)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadChecklist(currentData, columnName)
          }else {
            console.error("error:", responseMessage);
            this.setState({isLoading: false})
          }
      })
    }else if(columnName === COLUMNS.ACCESSORY){
      const updateItem = {unitPrice: parseFloat(updatedElement.unitPrice), name: updatedElement.name};
      updateAccessory(updatedElement.id,updateItem).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE) {
          this.loadAccessories(currentData, columnName)
        } else {
          console.error("error:", responseMessage);
          this.setState({isLoading: false})
        }
      })
    }

    if (columnName === COLUMNS.MORE_SETTINGS) {
      const updatedInfo = {
        dealerConfiguration: [{
          code: updatedElement.code,
          value: updatedElement.value
        }]
      }
      updateDealerConfigurationValues(updatedInfo)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.formatDBData(currentData, updatedElement, columnName)
          } else {
            console.error("error:", responseMessage);
            this.setState({isLoading: false})
          }
        })
    }

    if (columnName === COLUMNS.REG_FEES_AND_OTHERS) {
      const updatedInfo = {
        dealerConfiguration: [{
          code: updatedElement.code,
          value: !updatedElement.value || (isString(updatedElement.value) && isEmpty(updatedElement.value)) ? "0"
            : isString(updatedElement.value) ? updatedElement.value
            : parseFloat(updatedElement.value).toFixed(2).toString(),
          description: updatedElement.description || ""
        }]
      }

      let otherFeesToSave = null

      if (updatedElement.id.substring(0, 11) === OTHER_FEE.ID) {
        let updatedElements = currentData['elements']
        updatedElements = {
          ...updatedElements,
          [updatedElement.id]: {
            ...updatedElements[updatedElement.id],
            value: !updatedElement.value || (isString(updatedElement.value) && isEmpty(updatedElement.value)) ? "0"
              : isString(updatedElement.value) ? updatedElement.value
              : parseFloat(updatedElement.value).toFixed(2).toString(),
            description: updatedElement.description || ""
          }
        }

        otherFeesToSave = this.formatOtherFeesToBeSaved(updatedElements)
      }

      updateDealerConfigurationValues(otherFeesToSave ? otherFeesToSave : updatedInfo)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadFeesAndOthers(currentData, columnName)
          } else {
          console.error("error:", responseMessage);
          this.setState({isLoading: false})
          }
        })
    }
  }

  generateRandomId = () => {
    const randomNum = Math.random() * Date.now();
    return Math.floor(randomNum).toString(36);
  }

  // Add element on DB and also updates on State
  addElement = (newElement, columnName) => {
    const currentData = {...this.state.data[columnName]}
    const {isLoading} = this.state;
    // These lines tend to get the last element on Optional Section first.
    // If no exists, it searches on Required Section then.
    // If it doesn't exist either, a temporary object is created to use its order
    const numElementsOnOptionalSection = this.getNumberElementsBasedOnSection(SECTIONS.OPTIONAL, columnName)
    const numElementsOnRequiredSection = this.getNumberElementsBasedOnSection(SECTIONS.REQUIRED, columnName)
    const lastElementId = numElementsOnOptionalSection > 0
      ? currentData.sections[SECTIONS.OPTIONAL].elementIds[numElementsOnOptionalSection - 1]
      : numElementsOnRequiredSection > 0
        ? currentData.sections[SECTIONS.REQUIRED].elementIds[numElementsOnRequiredSection - 1]
        : null
    const lastElementOnSections = lastElementId
      ? currentData.elements[lastElementId]
      : {order: 0}  // If there is no elements on both sections, a temporary object is created to use its order
    // Formatting element to be created on DB
    // All new elements are assigned to Optional section by default
    const propName = columnName === COLUMNS.FILES ? "optional" : "required";
    const elementToBeCreated = {
      ...newElement,
      [propName]: columnName === COLUMNS.FILES,
      order: lastElementOnSections['order'] + 1
    }
    // Hits API
    if (!isLoading)
      this.setState({isLoading: true, type: columnName});
    if(columnName === COLUMNS.FILES){
      createDealerSaleStaticFile(elementToBeCreated)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadSaleStaticFiles(currentData, columnName)
          } else {
            console.log(responseMessage)
            this.setState({isLoading: false})
          }
        })
    }else if(columnName === COLUMNS.CHECKLIST){
      const itemBeCreated = {description: elementToBeCreated.description, required: elementToBeCreated.required, order: elementToBeCreated.order};
      saveChecklist(itemBeCreated).
      then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE) {
          this.loadChecklist(currentData, columnName)
        }else{
          console.error("error:", responseMessage);
          this.setState({isLoading: false})
        }
      })
    }else if(columnName === COLUMNS.ACCESSORY){
      const itemBeCreated = {unitPrice: parseFloat(elementToBeCreated.unitPrice), name: elementToBeCreated.name, order: elementToBeCreated.order}
      saveAccessory(itemBeCreated).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE) {
          this.loadAccessories(currentData,columnName)
        }else {
          console.error("error:", responseMessage);
          this.setState({isLoading: false})
        }
      })
    }

    if (columnName === COLUMNS.REG_FEES_AND_OTHERS) {
      let updatedElements = currentData['elements']
      const otherFeesList = Object.values(updatedElements).filter(el => el.id.substring(0, 11) === OTHER_FEE.ID)
      updatedElements = {
        ...updatedElements,
        [`${OTHER_FEE.ID}newItem`]: {
          id: `${OTHER_FEE.ID}newItem`,
          code: `${OTHER_FEE.CODE}${this.generateRandomId()}`,
          value: !newElement.value || (isString(newElement.value) && isEmpty(newElement.value)) ? "0"
            : isString(newElement.value) ? newElement.value
            : parseFloat(newElement.value).toFixed(2).toString(),
          description: newElement.description || ""
        }
      }

      let otherFeesToSave = this.formatOtherFeesToBeSaved(updatedElements)

      updateDealerConfigurationValues(otherFeesToSave)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadFeesAndOthers(currentData, columnName)
          } else {
            console.error("error:", responseMessage);
            this.setState({isLoading: false})
          }
        })
    }
  }

  // Used only on Update Form to validate entered info
  validateElement = (currentElement, columnName) => {
    const allElements = {...this.state.data[columnName].elements}
    let updatedErrorMessages = {...initialErrorMessagesObject}
    let isValid = true
    if(columnName === COLUMNS.FILES){
      // Verify there is no spaces
      if (currentElement[PROPERTY.CODE].indexOf(' ') >= 0) {
        updatedErrorMessages[PROPERTY.CODE] = `${TITLES[PROPERTY.CODE]} has spaces`
        isValid = false
      }

      // Verify if current code is not equal to the other codes
      if (allElements
        && values(allElements)
          .find(el => {
            return (
              currentElement['id'] !== el['id']
              && currentElement[PROPERTY.CODE] === el[PROPERTY.CODE]
            )
          })) {
        updatedErrorMessages[PROPERTY.CODE] = `${TITLES[PROPERTY.CODE]} already exists. Please enter a new one.`
        isValid = false
      }

      // Verify current code is empty
      if (isEmpty(currentElement[PROPERTY.CODE])) {
        updatedErrorMessages[PROPERTY.CODE] = `${TITLES[PROPERTY.CODE]} is a required field`
        isValid = false
      }

      // Verify current name is empty
      if (isEmpty(currentElement[PROPERTY.NAME])) {
        updatedErrorMessages[PROPERTY.NAME] = `${TITLES[PROPERTY.NAME]} is a required field`
        isValid = false
      }
    }else if(columnName === COLUMNS.CHECKLIST){
      // Verify current description is empty
      if (isEmpty(currentElement[PROPERTY.DESCRIPTION])) {
        updatedErrorMessages[PROPERTY.DESCRIPTION] = `${TITLES[PROPERTY.DESCRIPTION]} is a required field`
        isValid = false
      }
    } else if(columnName === COLUMNS.ACCESSORY){
      if (isEmpty(currentElement[PROPERTY.NAME_ACCESSORY])) {
        updatedErrorMessages[PROPERTY.NAME_ACCESSORY] = `${TITLES[PROPERTY.NAME_ACCESSORY]} is a required field`;
        isValid = false
      }

      if(!currentElement[PROPERTY.PRICE]){
        updatedErrorMessages[PROPERTY.PRICE] = `${TITLES[PROPERTY.PRICE]} is a required field`;
        isValid = false
      }

    }
    return (
      {
        isValid,
        errorMessages: updatedErrorMessages
      }
    )
  }

  // Enables drag and drop functionality at the end
  onDragEnd = (result, columnName) => {
    const currentData = {...this.state.data[columnName]}
    const {destination, source} = result;
    const {isLoading} = this.state;
    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId
      && destination.index === source.index) {
      return
    }

    const currentElements = currentData && currentData.elements
    let requiredElementIds = columnName !== COLUMNS.ACCESSORY ?  this.getElementIds(currentElements, columnName)['requiredElementIds'] : this.getElementsIdsOneSection(currentElements, columnName);
    let optionalElementIds = this.getElementIds(currentElements, columnName)['optionalElementIds']
    let movedElementId = ''

    // Drag section
    // Removes drag element from source section.
    if (source.droppableId === SECTIONS.REQUIRED) {
      movedElementId = requiredElementIds[source.index]
      requiredElementIds.splice(source.index, 1)
    }

    if (source.droppableId === SECTIONS.OPTIONAL) {
      movedElementId = optionalElementIds[source.index]
      optionalElementIds.splice(source.index, 1)
    }
    // End - Drag section

    // Drop section
    // Insert drag element into destination section
    if (destination.droppableId === SECTIONS.REQUIRED) {
      requiredElementIds.splice(destination.index, 0, movedElementId)
    }

    if (destination.droppableId === SECTIONS.OPTIONAL) {
      optionalElementIds.splice(destination.index, 0, movedElementId)
    }
    // End - Drop section

    const elementIds = requiredElementIds.concat(optionalElementIds)
    const formattedElements = elementIds.reduce((result, key, index) => {
      const element = currentElements[key]
      const propName = columnName === COLUMNS.FILES ? 'optional' : 'required'
      const propValue = columnName === COLUMNS.FILES
        ? destination.droppableId === SECTIONS.OPTIONAL
        : destination.droppableId === SECTIONS.REQUIRED

      return [
        ...result,
        {
          id: element.id,
          order: index + 1,
          [propName]: element['id'] !== movedElementId
            ? element[propName]
            : propValue
        }
      ]
    }, [])

    if (!isLoading)
      this.setState({isLoading: true, type: columnName});
    if (columnName === COLUMNS.FILES) {
      updateDealerSaleStaticFileList({items: formattedElements})
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadSaleStaticFiles(currentData, columnName)
          } else {
            console.log(responseMessage)
            this.setState({isLoading: false})
          }
        })
    } else if (columnName === COLUMNS.CHECKLIST) {
      updateReorderChecklist({items: formattedElements})
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadChecklist(currentData, columnName)
          }else{
            console.error("error:", responseMessage);
            this.setState({isLoading: false})
          }
      })
    } else if (columnName === COLUMNS.ACCESSORY) {
      updateReorderAccessories({items: formattedElements})
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.loadAccessories(currentData, columnName)
          } else {
            console.error("error:", responseMessage);
            this.setState({isLoading: false})
          }
      })
    }
  }

  // Lets show modal to confirm fee's deletion
  onShowDeletionModal(id, columnName) {
    this.setState(
      {
        showDeletionModal: true,
        currentElementIdToDelete: id,
        currentColumnNameToDelete: columnName,
      }
    )
  }

  // Used when we delete or edit other fees on Registration Fee and Other non-taxable items
  formatOtherFeesToBeSaved(updatedElements) {
    const otherFees = Object.values(updatedElements).filter(el => el.id.substring(0, 11) === OTHER_FEE.ID)
    let otherFeesToSave = null

    if (otherFees.length) {
      const formattedOtherFees = otherFees.map(el => (
        {
          description: el.description,
          value: el.value,
          code: el.code
        }
      ))

      otherFeesToSave = {
        dealerConfiguration: [{
          code: SALES_NON_TAXABLE_OTHERS_CODE,
          value: JSON.stringify(formattedOtherFees),
        }]
      }
    }

    return otherFeesToSave
  }

  render() {
    const {height} = this.props;
    const {isLoading, data, columnOrder, type} = this.state;
    if (!data || !data.files || !data.checklist || !data.accessory || !data[COLUMNS.MORE_SETTINGS]
      || !data[COLUMNS.REG_FEES_AND_OTHERS]) {
      return null;
    }

    return (
      <React.Fragment>
        {
          data && data.files && data.checklist && data[COLUMNS.MORE_SETTINGS]
          && data[COLUMNS.REG_FEES_AND_OTHERS]
          && columnOrder && columnOrder.map(columnId => {
            const column = data[columnId].columns[columnId]
            const sections =
              column
              && column.sectionIds
              && column.sectionIds.map(sectionId => data[columnId].sections[sectionId])
            return (
              <DragDrop
                onDragEnd={(result) => this.onDragEnd(result, columnId)}
                sections={[]}
                reorder={() => {}}
                move={() => {}}
                key={columnId}
              >
                <SaleSettingColumn
                  key={columnId}
                  height={height}
                  isLoading={isLoading && (type === columnId)}
                  column={column}
                  sections={sections}
                  elements={data[columnId].elements}
                  goUp={this.goUp}
                  goDown={this.goDown}
                  deleteElement={this.deleteElement}
                  editElement={this.editElement}
                  addElement={this.addElement}
                  validateElement={this.validateElement}
                  classColumn={
                    ([COLUMNS.ACCESSORY, COLUMNS.REG_FEES_AND_OTHERS].includes(column.id))
                      ? 'setting-column-accessory'
                      : null
                  }
                  handleShowDeleteModal={(id, columnName) => this.onShowDeletionModal(id, columnName)}
                />
              </DragDrop>
            )
          })
        }
        {
          this.state.showDeletionModal
            ? <ModalConfirmDelete
              show={this.state.showDeletionModal}
              showButtonDelete={true}
              onSubmit={() => {
                this.setState({showDeletionModal: false})
                this.deleteElement(this.state.currentElementIdToDelete, this.state.currentColumnNameToDelete)
              }}
              onHide={() => this.setState({showDeletionModal: false})}
              styleModal="modal-delete"
              classButtonDelete="btn-delete"
              buttonLabelDelete="Yes, delete"
              message={"Do you want to delete this item permanently?"}
              subMessage={'This action is irreversible'}
              isBodyCustom={true}
              styleRight="panel-right-delete-fee"
              containerDeleteButtonClassName={'container-fee-delete-button'}
            />
            : null
        }
      </React.Fragment>
    )
  }
}

SaleSetting.propTypes = {
  height: PropTypes.number,
}

export default SaleSetting
