import React, {Component} from 'react'
import {
  FORM_APPLICANT_CODE_ADMIN,
  INDIVIDUAL_CREDIT_DISPLAY_VALUE,
  INDIVIDUAL_CREDIT_NAME,
  ATTRIBUTE_CUSTOMER_ADDRESS_PREVIOUS,
  ATTRIBUTE_CUSTOMER_APT_PREVIOUS,
  ATTRIBUTE_CUSTOMER_CITY_PREVIOUS,
  ATTRIBUTE_CUSTOMER_STATE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_ZIP_CODE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS,
  ATTRIBUTE_CUSTOMER_MONTHLY_RENT_PREVIOUS,
  ATTRIBUTE_CUSTOMER_ADDRESS_ANOTHER,
  ATTRIBUTE_CUSTOMER_APT_ANOTHER,
  ATTRIBUTE_CUSTOMER_CITY_ANOTHER,
  ATTRIBUTE_CUSTOMER_STATE_ANOTHER,
  ATTRIBUTE_CUSTOMER_ZIP_CODE_ANOTHER,
  ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER,
  ATTRIBUTE_CUSTOMER_YEAR_ANOTHER,
  ATTRIBUTE_CUSTOMER_MONTH_ANOTHER,
  ATTRIBUTE_CUSTOMER_MONTHLY_RENT_ANOTHER,
  validateMonths,
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_PREVIOUS,
  ATTRIBUTE_CUSTOMER_JOB_TITLE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS,
  ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_ANOTHER,
  ATTRIBUTE_CUSTOMER_JOB_TITLE_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_ANOTHER,
  ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_ANOTHER,
  SUBSECTION,
  ATTRIBUTES_FOR_SECOND_EMPLOYMENT,
  ATTRIBUTES_FOR_THIRD_EMPLOYMENT,
  ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_THIRD,
} from '../../Constants'
import {
  ATTRIBUTE_APPLICANT_TYPE,
  ATTRIBUTE_EMPLOYER_YEAR,
  ATTRIBUTE_EMPLOYER_MONTH,
  ATTRIBUTE_APPLICANT_RELATION,
  ATTRIBUTE_RESIDENCE_YEAR,
  ATTRIBUTE_RESIDENCE_MONTH,
  ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS,
  ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS,
  ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS,
  ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS,
  VALIDATION_EMAIL,
  VALIDATION_PHONE,
  VALIDATION_REQUIRED, SUCCESSFUL_CODE,
} from '../../../common/Constants'
import {browserHistory} from 'react-router'
import {
  deleteApplicant,
  loadFinanceFormByCode,
  loadFormByCode,
  saveNewApplicant,
  updateApplicant,
  updateStateFinances
} from '../../Api'
import {loadFinances} from '../../actions'
import {connect} from 'react-redux'
import HeaderViewApplicant from './HeaderViewApplicant.jsx'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import NavigationHeader from '../util/NavigationHeader.jsx'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import ModalConfirmDelete from '../util/ModalConfirmDelete'
import {DPMessageBar} from '../../common/DPMessageBar'
import isEmpty from 'lodash/isEmpty'
import {getApplicantType} from './HeaderViewApplicant'
import {checkValidEmail, checkValidPhone} from '../../../common/ValidationUtil'

export function getErrors(values, attributes, showEmploymentSubSections) {
  let errors = [];
  const attributeResidentYear = attributes.find(x => x.name === ATTRIBUTE_RESIDENCE_YEAR);
  const attributeResidentMonth = attributes.find(x => x.name === ATTRIBUTE_RESIDENCE_MONTH);
  const attributeEmployerYear = attributes.find(x => x.name === ATTRIBUTE_EMPLOYER_YEAR);
  const attributeEmployerMonth = attributes.find(x => x.name === ATTRIBUTE_EMPLOYER_MONTH);
  const attributeEmployerYearSecond = attributes.find(x => x.name === ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_SECOND);
  const attributeEmployerMonthSecond = attributes.find(x => x.name === ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_SECOND);
  const attributeEmployerYearThird = attributes.find(x => x.name === ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_THIRD);
  const attributeEmployerMonthThird = attributes.find(x => x.name === ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_THIRD);

  values.forEach((value, index) => {
    if(index === 0){
      const attributesCode = getAttributesResident(validateMonths.applicantType)
      deleteKeys(attributesCode, value, attributes)
    } else{
      const attributesCode = getAttributesResident(validateMonths.applicantTypeJoin)
      deleteKeys(attributesCode, value, attributes)
    }
    const valueResidentYear = value[attributeResidentYear.id]
    const valueResidentMonth = value[attributeResidentMonth.id]
    const monthsResident = calculateMonths(valueResidentYear, valueResidentMonth)
    if(monthsResident >= 24){
      const attributesCode = getAttributesResident(validateMonths.firstResident)
      deleteKeys(attributesCode, value, attributes)
    } else {
      const attributeResidentYearPrevious = attributes.find(x => x.name === ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS)
      const attributeResidentMonthPrevious = attributes.find(x => x.name === ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS)
      const valueResidentYearPrevious = value[attributeResidentYearPrevious.id]
      const valueResidentMonthPrevious = value[attributeResidentMonthPrevious.id]
      const monthsResidentPrevious = calculateMonths(valueResidentYearPrevious, valueResidentMonthPrevious)
      if(monthsResident + monthsResidentPrevious >= 24){
        const attributesCode = getAttributesResident(validateMonths.secondResident)
        deleteKeys(attributesCode, value, attributes)
      }
    }
    const valueEmployerYear = value[attributeEmployerYear.id]
    const valueEmployerMonth = value[attributeEmployerMonth.id]
    const valueEmployerYearSecond = value[attributeEmployerYearSecond.id]
    const valueEmployerMonthSecond = value[attributeEmployerMonthSecond.id]
    const valueEmployerYearThird = value[attributeEmployerYearThird.id]
    const valueEmployerMonthThird = value[attributeEmployerMonthThird.id]
    const monthsEmployer = calculateMonths(valueEmployerYear, valueEmployerMonth)
    const monthsEmployerSecond = calculateMonths(valueEmployerYearSecond, valueEmployerMonthSecond)
    const monthsEmployerThird = calculateMonths(valueEmployerYearThird, valueEmployerMonthThird)
    if((monthsEmployer + monthsEmployerSecond + monthsEmployerThird) >= 24){
      const attributesCode = getAttributesResident(validateMonths.firstEmployer)
      deleteKeys(attributesCode, value, attributes)
    } else {
      const attributeEmployerYearPrevious = attributes.find(x => x.name === ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS)
      const attributeEmployerMonthPrevious = attributes.find(x => x.name === ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS)
      const valueEmployerYearPrevious = value[attributeEmployerYearPrevious.id]
      const valueEmployerMonthPrevious = value[attributeEmployerMonthPrevious.id]
      const monthsEmployerPrevious = calculateMonths(valueEmployerYearPrevious, valueEmployerMonthPrevious)
      if(monthsEmployer + monthsEmployerSecond + monthsEmployerThird + monthsEmployerPrevious >= 24){
        const attributesCode = getAttributesResident(validateMonths.secondEmployer)
        deleteKeys(attributesCode, value, attributes)
      }
    }

    if (!showEmploymentSubSections[index][SUBSECTION.SECOND]) {
      const attributesCode = ATTRIBUTES_FOR_SECOND_EMPLOYMENT
      deleteKeys(attributesCode, value, attributes)
    }

    if (!showEmploymentSubSections[index][SUBSECTION.THIRD]) {
      const attributesCode = ATTRIBUTES_FOR_THIRD_EMPLOYMENT
      deleteKeys(attributesCode, value, attributes)
    }

    const error = validateInput(value, attributes, index);
    if(!isEmpty(error))
      errors.push(error)
  })

  return errors
}

function calculateMonths(years, months) {
  return Number(years) * 12 + Number(months);
}

function deleteKeys(attributesCode, value, attributes){
  attributesCode.forEach(key => {
    const attribute = attributes.find(x => x.name === key)
    if(!isEmpty(attribute))
      delete value[attribute.id]
  })
}

function getAttributesResident(type){
  let attributesCode = []
  switch (type) {
    case validateMonths.applicantType:
      attributesCode = [ATTRIBUTE_APPLICANT_RELATION]
      break;
    case validateMonths.applicantTypeJoin:
      attributesCode = [ATTRIBUTE_APPLICANT_TYPE]
      break;
    case validateMonths.firstResident:
      attributesCode = [ATTRIBUTE_CUSTOMER_ADDRESS_PREVIOUS,ATTRIBUTE_CUSTOMER_APT_PREVIOUS,ATTRIBUTE_CUSTOMER_CITY_PREVIOUS,ATTRIBUTE_CUSTOMER_STATE_PREVIOUS,
        ATTRIBUTE_CUSTOMER_ZIP_CODE_PREVIOUS,ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS,ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS,ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS,
        ATTRIBUTE_CUSTOMER_MONTHLY_RENT_PREVIOUS,ATTRIBUTE_CUSTOMER_ADDRESS_ANOTHER,ATTRIBUTE_CUSTOMER_APT_ANOTHER,ATTRIBUTE_CUSTOMER_CITY_ANOTHER,
        ATTRIBUTE_CUSTOMER_STATE_ANOTHER,ATTRIBUTE_CUSTOMER_ZIP_CODE_ANOTHER,ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER,ATTRIBUTE_CUSTOMER_YEAR_ANOTHER,
        ATTRIBUTE_CUSTOMER_MONTH_ANOTHER,ATTRIBUTE_CUSTOMER_MONTHLY_RENT_ANOTHER];
      break;
    case validateMonths.secondResident:
      attributesCode = [ATTRIBUTE_CUSTOMER_ADDRESS_ANOTHER,ATTRIBUTE_CUSTOMER_APT_ANOTHER,ATTRIBUTE_CUSTOMER_CITY_ANOTHER,
        ATTRIBUTE_CUSTOMER_STATE_ANOTHER,ATTRIBUTE_CUSTOMER_ZIP_CODE_ANOTHER,ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER,ATTRIBUTE_CUSTOMER_YEAR_ANOTHER,
        ATTRIBUTE_CUSTOMER_MONTH_ANOTHER,ATTRIBUTE_CUSTOMER_MONTHLY_RENT_ANOTHER];
      break;
    case validateMonths.firstEmployer:
      attributesCode = [ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_PREVIOUS,ATTRIBUTE_CUSTOMER_JOB_TITLE_PREVIOUS,ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_PREVIOUS,
        ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_PREVIOUS,ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_PREVIOUS,ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_PREVIOUS,
        ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_PREVIOUS,ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS,ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS,
        ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS,ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_PREVIOUS,ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_ANOTHER,
        ATTRIBUTE_CUSTOMER_JOB_TITLE_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_ANOTHER,
        ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_ANOTHER,
        ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_ANOTHER,
        ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_ANOTHER];
      break;
    case validateMonths.secondEmployer:
      attributesCode = [ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_ANOTHER,ATTRIBUTE_CUSTOMER_JOB_TITLE_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_ANOTHER,
        ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER,
        ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER,ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_ANOTHER,
        ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_ANOTHER,ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_ANOTHER];
      break;
  }

  return attributesCode
}

function validateInput(value, attributes, index) {
  const error = {};
  for(let key in value){
    const attribute = attributes.find(x => x.id === key)
    if(attribute && attribute.validation.length > 0){
      attribute.validation.forEach(validation => {
        switch (validation.type) {
          case VALIDATION_REQUIRED:
            if(isEmpty(value[key])){
              error[attribute.name] =  validation.type
            }
            break;
          case VALIDATION_EMAIL:
            if(value[key] !== ""){
              const errorEmail = checkValidEmail(value[key])
              if(!errorEmail)
                error[attribute.name] =  validation.type
            }
            break;
          case VALIDATION_PHONE:
            if(value[key] !== ""){
              const errorPhone = checkValidPhone(value[key])
              if(!errorPhone)
                error[attribute.name] =  validation.type
            }
            break;
        }
      })
    }
  }
  if(!isEmpty(error))
    error.index = index
  return error
}

class FinanceEdit extends Component {

  constructor() {
    super();

    this.state = {
      message: "Are you sure you want to save these changes?",
      showModal: false,
      isLoading: false,
      dataFinance: null,
      financeToSave: null,
      isNewFinanceApplication: false,
      errors: []
    }
  };

  getFinanceDataInformation(response,type){
    const attributes = get(response, ['form', 'formAttributes', 'attributes'], [])
    const values = get(response, ['values'], [])
    let newValues = []
    if(type === "new"){
      values.firstName = "";
      values.lastName = "";
      newValues.push(values)
    } else{
      newValues = [...values]
    }
    const attributeApplicantType = find(attributes, el => el.name === ATTRIBUTE_APPLICANT_TYPE);
    const sortedValues = orderBy(newValues, [attributeApplicantType.id], ['desc']);
    return {...response, values: sortedValues,}
  }

  updateDataFinanceFormFetch(dataFinance, users){
    this.setState({dataFinance: dataFinance, isLoading: false, users: users});
  }

  loadFinanceById(users){
    loadFinanceFormByCode(FORM_APPLICANT_CODE_ADMIN, this.props.params.id).then(response => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const dataFinance = this.getFinanceDataInformation(data,"update")
        this.updateDataFinanceFormFetch(dataFinance, users)
      }else{
        this.setState({isLoading:false})
        console.error(responseMessage)
      }
    });
  }

  createNewFinance(users){
    const {dealerId,loggedUserId } = this.props
    loadFormByCode("formAdminFinance", "LEAD").then(response => {
      const {responseCode, data} = response
      const dataFinance = responseCode === SUCCESSFUL_CODE
        ? this.getFinanceDataInformation(data,"new")
        : {}
      dataFinance.finance = {
        dealerId: dealerId,
        read: false,
        active: true,
        status: "new",
        salesmanId: loggedUserId,
        applicant: "",
        cosigners: [],
        financeType: INDIVIDUAL_CREDIT_DISPLAY_VALUE
      }
      this.updateDataFinanceFormFetch(dataFinance, users)
    })
    this.setState({isNewFinanceApplication: true})
  }

  componentWillMount() {
    this.setState({isLoading: true});
    this.props.loadFinances().then((response) => {
      const users = response.users.filter(function (user) {return user.active;});
      if(this.props.params.id !== 'new') {
        this.loadFinanceById(users)
      }else {
        this.createNewFinance(users)
      }
    });
  }

  onChangeTab = (id) => {
    if (id === "id") {
      browserHistory.push({pathname: "/main/message/finance", state: {showMessage: false}});
    } else if (id === "sent") {
      browserHistory.push({pathname: "/main/message/sent", state: {showMessage: true}});
    } else {
      browserHistory.push({pathname: "/main/message", state: {showMessage: true}});
    }
  }

  editSalesMan = (applicant) => {
    if(this.state.isNewFinanceApplication){
      const dataFinance = {...this.state.dataFinance}
      dataFinance.finance = applicant
      this.setState({dataFinance})
    }else {
      updateStateFinances({updateFinances: [{...applicant}]}).then(({ responseCode, responseMessage }) => {
        if (responseCode !== SUCCESSFUL_CODE){
          console.error(responseMessage);
        }
      })
    }
  }

  formatNewFinanceToSave(financeToSave){
    const newValues = [...financeToSave.values].map(el => {
      if (!el.objectId) {
        return {
          ...el,
          objectId: get(el, ['leadId'], '')
        }
      } else {
        return el
      }
    })

    if (financeToSave.financeType === INDIVIDUAL_CREDIT_DISPLAY_VALUE && financeToSave.values.length > 1) {
      const leadsToDelete = newValues.slice(1).map(el => (
        {
          leadId: get(el, ['leadId'], ''),
          customerId: get(el, ['customerId'], ''),
        }
      ))
      return {
        ...financeToSave,
        values: newValues.slice(0, 1),
        leadsToDelete,
      }
    }else {
      return {
        ...financeToSave,
        values: newValues
      }
    }
  }

  saveNewFinanceApplication = () =>{
    const {financeToSave, dataFinance} = this.state;
    const finance = this.formatNewFinanceToSave(financeToSave)
    const applicant = {...dataFinance.finance}
    const typeOfApplicant = getApplicantType(dataFinance);
    applicant.financeType = typeOfApplicant;
    this.setState(
      {
        isLoading: true,
        showModal: false,
      }
    )

    if(!finance.financeId){
      finance.financeId = ""
    }

    saveNewApplicant(finance, applicant).then(response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({isLoading: false})
        browserHistory.push("/main/message/finance")
      }else{
        console.log(responseMessage)
        this.setState({ isLoading: false })
      }
    })
  }

  saveApplicant = () => {
    const financeToSave = this.formatNewFinanceToSave(this.state.financeToSave)
    this.setState({ isLoading: true, showModal: false })

    // Hitting API
    updateApplicant(financeToSave).then(response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.onLoadFinanceFormInfo()
      }else{
        console.error(responseMessage)
        this.setState({ isLoading: false })
      }
    })
  }

  openModal = (finance) => {
    const {dataFinance} = this.state;
    const attributes = dataFinance.form.formAttributes.attributes;
    const newValues = JSON.parse(JSON.stringify(finance.values))
    const errors = getErrors(newValues, attributes, dataFinance['showEmploymentSubSections']);
    if(errors.length > 0)
      this.setState({errors: errors, financeToSave: finance})
    else
      this.setState({showModal: true, financeToSave: finance})
  }

  handleModalChange = () => {
    this.setState({showModal: false})
  }

  onHandleModalDelete(applicantToDelete) {
    this.setState(
      {
        showModalDelete: true,
        applicantToDelete,
      }
    )
  }

  onLoadFinanceFormInfo() {
    const { dataFinance } = this.state
    const id = get(dataFinance, ['finance','id'], '')
    const isLoading = false

    loadFinanceFormByCode(FORM_APPLICANT_CODE_ADMIN, id)
      .then(response => {
        const {responseCode, responseMessage, data} = response
        if(responseCode === SUCCESSFUL_CODE){
          const attributes = get(data, ['form', 'formAttributes', 'attributes'], [])
          const values = get(data, ['values'], [])
          const attributeApplicantType = find(attributes, el => el.name === ATTRIBUTE_APPLICANT_TYPE);

          const sortedValues = orderBy(values, [attributeApplicantType.id], ['desc']);
          const dataFinance = {
            ...data,
            values: sortedValues,
          }

          this.setState(
            {
              showModalDelete: false,
              showSaveBar: true,
              dataFinance,
              isLoading,
            }
          )
        }else{
          console.error(responseMessage)
          this.setState({ showModalDelete: false, isLoading })
        }
      })
  }

  onDeleteApplicant = () => {
    let applicantToDelete = {...this.state.applicantToDelete}
    let newDataFinance = {...this.state.dataFinance}
    let newValues = [...newDataFinance.values]

    this.setState({ isLoading: true, showModalDelete: false })

    // This condition lets change Type of application on main applicant
    if (newValues.length === 2) {
      const attributes = get(newDataFinance, ['form', 'formAttributes', 'attributes'], [])
      const applicantType = attributes.find(el => el.name === ATTRIBUTE_APPLICANT_TYPE)
      const attributeValuesForApplicantType = get(applicantType, ['attributeValues'], [])
      const individualCreditAttributeValue =
        attributeValuesForApplicantType.find(el => el.name === INDIVIDUAL_CREDIT_NAME)

      newValues[0] = {
        ...newValues[0],
        [applicantType.id]: [individualCreditAttributeValue.id]
      }
    }

    // Temporarily delete an element inside NewValues
    newValues.splice(applicantToDelete.index, 1)
    newDataFinance = {
      ...newDataFinance,
      values: newValues,
    }

    // First condition: To delete temporal co-applicant
    if (!applicantToDelete.leadId && !applicantToDelete.customerId) {
      this.setState(
        {
          dataFinance: newDataFinance,
          isLoading: false,
        }
      )
    } else {
      delete applicantToDelete.index

      // Hitting API just in case it was an existing co-applicant (saved on DB)
      deleteApplicant(applicantToDelete)
        .then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE){
            this.setState(
              {
                dataFinance: newDataFinance,
                isLoading: false,
              }
            )
          }else{
            console.error(responseMessage)
            this.setState({ showModalDelete: false, isLoading: false })
          }
        })
    }
  }

  updateDataFinance(dataFinance) {
    this.setState({ dataFinance })
  }

  render() {
    const {showModal, dataFinance, users, isLoading, message, showSaveBar, isNewFinanceApplication, errors, showModalDelete} = this.state;
    const {height} = this.props;

    return (
      <div>
        <NavigationHeader name={isNewFinanceApplication ? "New Finance Application":"Finance Detail"} addButton={false} tab="finance"  onChangeTab={this.onChangeTab}/>
        <div className="message-body" style={{height: height}}>
          {
            showSaveBar
              ? <DPMessageBar
                label={'Congratulations for saving the finance applicant'}
                icon={true}
                onClick={() => this.setState({ showSaveBar: false })}
              />
              : null
          }
          {
            isLoading ?
              <div className="loader-container" style={{height: height}}>
                <div className="loader"/>
              </div>
              :
              <div className="container-all-applicant" style={{maxHeight: (height - 80), marginTop: showSaveBar ? '25px' : '0px'}}>
                <HeaderViewApplicant dataFinance={dataFinance} users={users} onChange={this.editSalesMan}
                                     openModal={this.openModal} height={(height - 80)}
                                     onHandleModalDelete={(applicantToDelete) => this.onHandleModalDelete(applicantToDelete)}
                                     onUpdateDataFinance={(dataFinance) => this.updateDataFinance(dataFinance)}
                                     isNewFinanceApplication={isNewFinanceApplication} errors={errors}
                />
              </div>
          }
        </div>
        {
          showModal ?
            <GenericModalConfirm show={showModal}
                                 message={message}
                                 save={isNewFinanceApplication ? this.saveNewFinanceApplication : this.saveApplicant}
                                 onHide={this.handleModalChange}
                                 showButtonCancel={true}
                                 buttonLabelSave="Save"
                                 classButtonSave="btn-save"
            /> : null
        }

        {
          showModalDelete
            ? <ModalConfirmDelete
              show={showModalDelete}
              showButtonDelete={true}
              onSubmit={this.onDeleteApplicant}
              onHide={() => this.setState({ showModalDelete: false })}
              classButtonDelete="btn-delete"
              buttonLabelDelete="Yes, delete"
              message={"Do you want to delete this item permanently?"}
              subMessage={"This action is irreversible"}
              styleModal="modal-delete"
              styleRight="panel-right-delete-user"
              isSale={true}
            />
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggedUserId: state.userId,
    dealerId: state.dealerId
  }
};

export default connect(mapStateToProps, {loadFinances})(FinanceEdit)