import React from 'react'
import {findIndex} from 'lodash'
import isEmpty from 'lodash/isEmpty'

const ListPermission =  React.forwardRef(({ permissions = [], selectedPermissions= [], handleCheck, parentPermission = null, disabledDashboardOptions }, ref) => {
  return (
    <div className="col100 permission-container">
      {
        permissions.map(permission => {
          const indexFound = findIndex(selectedPermissions, (per) => { return per === permission.id})
          const isDisabled = disabledDashboardOptions && disabledDashboardOptions.includes(permission.code)
          return(
            <div key={permission.id} className="content-check" style={parentPermission ? {marginLeft: 20} : {}}>
              <label className="container-check">
                <input id={permission.id} type="checkbox" disabled={isDisabled} checked={indexFound !== -1} onChange={() => handleCheck(permission)}/>
                <span className="checkmark" style={{border: isDisabled? "1.4px #ccc solid" : "1.4px #4695d4 solid"}}/>
                {
                  indexFound !== -1 ? <i className="fa fa-check i-check"/> : null
                }
              </label>
              <label className="label-check-image-main" >{permission.name}</label>
              {
                !isEmpty(permission.subPermissions) ?
                  <ListPermission permissions={permission.subPermissions}
                                  selectedPermissions={selectedPermissions}
                                  parentPermission={permission}
                                  handleCheck={handleCheck}
                  /> : null
              }
            </div>
          )
        })
      }
    </div>

  )
})

export default ListPermission
