import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import classnames from 'classnames'
import values from 'lodash/values'
import {getBoundingClientRectForElement} from '../Utils'

export const PLACEMENTS = {
  BOTTOM: 'bottom',
};

const separation = 2;

/** Attaches a Popover to the document.body and positions the Popover at the props.anchorEl */
class DPOverlayInnerContent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      placement: {}
    }
  }

  componentDidMount() {
    const { props } = this;
    if (props.show) {
      this.computePlacement()
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('mousedown', this.observeDocumentClick);
    const container = window.document.getElementById("container");
    if(container)
      container.addEventListener('scroll', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('mousedown', this.observeDocumentClick);
    const container = window.document.getElementById("container");
    if(container)
      container.removeEventListener('scroll', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { props: currentProps } = this;
    if (!prevProps.show && currentProps.show) {
      /** get new positioning when the popover toggles to "show=true" */
      this.computePlacement()
    }
  }

  /** Observes clicks on the document and decides whether or not to run the onHide callback hook */
  observeDocumentClick = (e) => {
    const { props } = this;
    const anchorElDomElement = ReactDOM.findDOMNode(props.anchorEl);

    if (props.show && isFunction(props.onHide) && !this.popover.contains(e.target) && !anchorElDomElement.contains(e.target)) {
      props.onHide(e)
    }
  };

  /** During resize, styles must be re-computed */
  handleResize = () => {
    const { props } = this;
    if (!props.show) {
      return
    }
    this.computePlacement()
  };

  /** Definition to open upward */
  attachTopStyles() {
    const { anchorEl } = this.props;
    const triggerRect = getBoundingClientRectForElement(anchorEl);
    const leftDefault = triggerRect.left + window.pageXOffset;

    return {
      styles: {
        top: triggerRect.top + window.pageYOffset - separation,
        left: leftDefault,
        width: triggerRect.width
      },
      className: ''
    }
  }

  /** Definition to open downward */
  attachBottomStyles() {
    const { anchorEl } = this.props;
    const triggerRect = getBoundingClientRectForElement(anchorEl);
    const leftDefault = triggerRect.left + window.pageXOffset;

    return {
      styles: {
        top: triggerRect.top + window.pageYOffset + triggerRect.height + separation,
        left: leftDefault,
        width: triggerRect.width
      },
      className: ''
    }
  }

  /** Updates the styles for the popover */
  computePlacement = () => {
    const { props } = this;
    const triggerRect = getBoundingClientRectForElement(this.props.anchorEl);
    let returnedObject = {};
    let placementClass;
    if(props.triggerYToHide){
      this.handlePopover(triggerRect,props.triggerYToHide)
    }
    switch(props.placement) {
      case PLACEMENTS.BOTTOM:
      default:
        if (window.innerHeight < triggerRect.bottom + props.popoverHeight + 2) {
          returnedObject = this.attachTopStyles();
          placementClass = 'dp-overlay-top'
        } else {
          returnedObject = this.attachBottomStyles();
          placementClass = 'dp-overlay-bottom'
        }
        break

    }

    const placement = {
      styles: {
        ...returnedObject.styles,
        width: ( props.fullWidth ? triggerRect.width : null ),
        maxWidth: ( props.customMaxWidth ? `${props.customMaxWidth}px` : null ),
        minWidth: ( props.customMinWidth ?  `${props.customMinWidth}px` : null )
      },
      className: placementClass + ' ' + returnedObject.className
    };

    this.setState({ placement })
  };

  handlePopover(triggerRect, triggerYToHide){
    if(triggerYToHide){
      const overlay = document.getElementById("dp-overlay");
      const triggerYToHidePopover = (window.innerWidth <= 1260) ? triggerYToHide - 6 : triggerYToHide;
      if(overlay && triggerRect.y <= triggerYToHidePopover){
        overlay.style.display = "none"
      }else if(overlay && triggerRect.y > triggerYToHidePopover){
        overlay.style.display = "block"
      }
    }
  }

  render() {
    const { props, state } = this;

    if (!props.show) {
      return null
    }

    return (
      <div className={classnames('dp-overlay-new', props.className, state.placement.className)}
           style={state.placement.styles}
           ref={input => this.popover = input}
      >
        <div className={'dp-scroll-frame'}>
          {props.children}
        </div>
      </div>
    )
  }
}

DPOverlayInnerContent.propTypes = {
  show: PropTypes.bool.isRequired,
  /** The trigger element to attach the Overlay to */
  anchorEl: PropTypes.any,
  onHide: PropTypes.func,
  /** The direction to open the Overlay from the trigger OR stacked on top */
  placement: PropTypes.oneOf(values(PLACEMENTS)),
  /** Decorate the outer-most Overlay div with a class */
  className: PropTypes.string,
  /** Set overlay width to be width of trigger */
  fullWidth: PropTypes.bool,
  customMaxWidth: PropTypes.number,
  customMinWidth: PropTypes.number,
  popoverHeight: PropTypes.number,
  triggerYToHide: PropTypes.number
};

DPOverlayInnerContent.defaultProps = {
  placement: PLACEMENTS.BOTTOM,
  show: false,
  fullWidth: false,
  popoverHeight: 157
};

export default DPOverlayInnerContent