/**
 * Created by Johnny Apolinario on 06/07/2018.
 */
import React, {Component} from 'react'
import {
  SIDEBAR_WIDTH_EXPANDED, SIDEBAR_WIDTH_UNEXPANDED, MENU_SETTING_PERMISSION_ID,
  ROLES_SETTING_PERMISSION_ID, USERS_SETTING_PERMISSION_ID
} from '../../Constants.js'
import WrapperSidebar from './WrapperSidebar.jsx'
import ModalSearch from './ModalSearch.jsx'
import {connect} from 'react-redux'
import {browserHistory} from 'react-router'
import {setKeyword} from '../../actions'
import {logoutApi} from '../../Api.js'
import {DPPillBox} from '../util/ComponentUtils'
import UserHasPermission from '../UserHasPermission'
import {
  pathServer,
  SUCCESSFUL_CODE,
  WIDTH_VIEW_1020,
  WIDTH_VIEW_MOBILE,
  WIDTH_VIEW_TABLET
} from '../../../common/Constants'
import DPButtonIcon from '../../common/DPButtonIcon'

class Wrapper extends Component {
  constructor() {
    super();
    this.state = {
      showUserMenu:"",
      showResultSearch:false,
      filter:"",
      user:{
        firstName:"Johnny",
        middleName:"A.",
        image:pathServer.PATH_IMG+"user.png"
      }
    }
  }

  componentDidMount(){
    $(document).bind('click', this.clickDocument);
    const {keywordParameter} = this.props
    if(keywordParameter && keywordParameter !== "") {
      this.props.setKeyword(keywordParameter)
    }
    this.props.handleSearch(keywordParameter)
  }

  componentWillUnmount(){
    $(document).unbind('click', this.clickDocument);
  }

  clickDocument = (e) => {
    let userIcon = window.document.getElementById("userIcon");
    let userIconImg = window.document.getElementById("userImg");
    if (e.target !== userIcon && e.target !== userIconImg)
      this.setState({showUserMenu:''});
    //plus.has(e.target).length
  }

  onClick = (e) => {
    //e.preventDefault();
    let {showUserMenu} = this.state;
    let showUserMenuNew = "";
    if(showUserMenu === "")
      showUserMenuNew = "open";

    this.setState({showUserMenu:showUserMenuNew})
  }

  handleSearch = (evt) => {
    const {path} = this.props;
    if(evt.target.value !== ""){
      if(path === "dashboard"){
        this.setState({showResultSearch:true, filter:evt.target.value});
      }else{
        this.props.setKeyword(evt.target.value);
        this.props.handleSearch(evt.target.value)
      }
    }else{
      this.props.setKeyword("");
      this.props.handleSearch("");
      this.setState({showResultSearch:false,filter:""})
    }
  }

  render() {
    let {showUserMenu,showResultSearch,filter} = this.state;
    let {showSideBar, path, width, height, keyword, firstName, lastName, dealerName, isSuperuser,urlImage,
      isAccountOwner, selectedInvoice, hideSearchSection = false} = this.props;
    if(showSideBar && width > WIDTH_VIEW_TABLET){
      width = width - SIDEBAR_WIDTH_EXPANDED;
    } else{
      width = width - SIDEBAR_WIDTH_UNEXPANDED;
    }

    return (
      <div>
        <div className="wrapper-header" style={{width:width}}>
          {
            !hideSearchSection
              ? <div id="menuSearchContent" className="menu-search">
                  <a>
                    <i className="fa fa-search"/>
                  </a>
                  <input
                      id="menu-search"
                      value={keyword}
                      onChange={this.handleSearch}
                      autoComplete="off"
                      type="search"
                      placeholder="Search customers, autos, dealer, etc..."
                  />
                </div>
              : null
          }
          <div className="menu-user" style={{marginLeft:5}}>
            <ul className="menu-user-u" style={!isSuperuser ? {padding:"5px 0 0"} : {padding:"5px 0 0"}}>
              <li className={"dropdown "+showUserMenu}>
                <div className="menu-user-a" style={!isSuperuser ? {borderLeft:"2px #f3f3f5 solid"} : {borderLeft:"none"}}>
                  {
                    isAccountOwner && width > WIDTH_VIEW_MOBILE ?
                      <DPPillBox displayValue='ACCOUNT OWNER' stylePill={true}/> : null
                  }
                  {
                    isSuperuser && width > WIDTH_VIEW_MOBILE ?
                        <DPPillBox displayValue='SUPERUSER' stylePill={true}/> : null
                  }
                  <span id="userName" className="user-name">{`${firstName} ${lastName}`}</span>
                  <a onClick={this.onClick}>
                    <i id="userIcon" className="fa fa-chevron-down"/>
                  </a>
                  <img id="userImg" src={urlImage ? urlImage : `${pathServer.PATH_IMG}user.png`} className="icon-user-profile" onClick={this.onClick}/>
                </div>
                <ul className="dropdown-menu extended logout ">
                  <div className={'container-pill-box'}>
                    {
                      isAccountOwner && width <= WIDTH_VIEW_MOBILE ?
                        <DPPillBox displayValue='ACCOUNT OWNER' stylePill={true}/> : null
                    }
                    {
                      isSuperuser && width <= WIDTH_VIEW_MOBILE ?
                        <DPPillBox displayValue='SUPERUSER' stylePill={true}/> : null
                    }
                  </div>
                  <li onClick={this.profile} className="dropdown-menu-option">
                      <label className="dropdown-menu-option-label">Personal information</label>
                  </li>
                  {/*{*/}
                  {/*  !isSuperuser*/}
                  {/*  && <li onClick={this.goToSubscriptionDashboard} className="dropdown-menu-option">*/}
                  {/*    <label className="dropdown-menu-option-label">Subscriptions</label>*/}
                  {/*  </li>*/}
                  {/*}*/}
                  {
                    !isSuperuser && width > (WIDTH_VIEW_TABLET - SIDEBAR_WIDTH_UNEXPANDED) ?
                      <UserHasPermission permissionId={MENU_SETTING_PERMISSION_ID} permissionIds={[MENU_SETTING_PERMISSION_ID, ROLES_SETTING_PERMISSION_ID, USERS_SETTING_PERMISSION_ID]}>
                        <li onClick={this.settings}  className="dropdown-menu-option-second">
                          <label className="dropdown-menu-option-label">Settings</label>
                        </li>
                      </UserHasPermission> : null
                  }
                  {/*<li className="dropdown-menu-option-second">*/}
                  {/*  <label className="dropdown-menu-option-label">Help</label>*/}
                  {/*</li>*/}
                  <li onClick={this.logOut}  className="dropdown-menu-option-sign-out">
                    <label className="dropdown-menu-option-label">Sign out</label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {
            !isSuperuser ?
              <div className="menu-dealer-content">
                <div className="menu-dealer">
                  <span id="dealerName" className="dealer-name">{dealerName}</span>
                </div>
              </div> : null
          }
          {/*<div className="menu-notification">
            <img src={pathServer.PATH_IMG+"notification-icon.png"} />
          </div>*/}
          {
            selectedInvoice && !showSideBar && window.innerWidth > WIDTH_VIEW_1020 && (isAccountOwner || isSuperuser)
            && <div className="menu-pay">
              <DPButtonIcon
                background={'#4AD991'}
                btnSaveText={"Pay current bill"}
                iconSendPath={'ic_receipt_24px.svg'}
                textColor={'#FFF'}
                onClick={this.props.handleShowModal}
              />
            </div>
          }
        </div>
        <WrapperSidebar showSideBar={showSideBar} path={path} height={height}/>
        {
          showResultSearch ? <ModalSearch filter={filter} showSideBar={showSideBar}/> : null
        }
      </div>
    )
  }

  logOut = (e) => {
    e.preventDefault();
    logoutApi().then(response => {
      const {responseCode, responseMessage} = response
      if(responseCode === SUCCESSFUL_CODE){
        window.location.href = "/login"
      }else{
        console.log('error ==> ', responseMessage)
      }
    } )
  }

  profile = (e) => {
    browserHistory.push({pathname:`/main/profile`});
  }

  settings = (e) => {
    browserHistory.push({pathname:"/main/setting"});
  }

  goToSubscriptionDashboard = (e) => {
    browserHistory.push({pathname:"/main/subscriptions"});
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    keyword:state.keyword,
    firstName: state.firstName,
    lastName: state.lastName,
    dealerName: state.dealerName,
    isSuperuser: state.isSuperuser,
    isAccountOwner: state.isAccountOwner,
    urlImage: state.urlImage
  }
};

export default connect(mapStateToProps,{setKeyword})(Wrapper)
