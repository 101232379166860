import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FileEmpty, TitleHeader} from './SaleEdit'
import {ReactSVG} from 'react-svg'
import {loadStaticsFiles, uploadFileSales, downloadSaleStaticFiles} from '../../Api'
import DPDropzone from '../../common/DPDropzone.js'
import {DPSpinner} from '../../common/DPSpinner'
import {
  EXT_IMG,
  EXT_OTHER_IMG,
  EXT_PDF,
  EXT_PNG,
  EXT_WORD,
  pathServer,
  SUCCESSFUL_CODE
} from '../../../common/Constants'

class SaleFiles extends Component{
  constructor(){
  super();
    this.state = {
      saleFiles: [],
      isLoading: false
    }
  }

  componentDidMount() {
    const {saleId} = this.props;
    this.setState({isLoading: true})
    this.loadSaleFiles(saleId);
  }

  loadSaleFiles(saleId){
    loadStaticsFiles(saleId).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {staticFiles: {saleStaticFiles}} = data;
        this.setState({saleFiles: saleStaticFiles, isLoading:false})
      }else{
        console.error(responseMessage);
        this.setState({isLoading:false})
      }
    })
  }

  onDrop(code,files) {
    const {saleId} = this.props;
    const data = {files:files};
    this.setState({isLoading:true});
    uploadFileSales(saleId,code,data).then(response => {
      const {responseCode, responseMessage} = response
      if(responseCode === SUCCESSFUL_CODE){
        this.loadSaleFiles(saleId);
      }else{
        console.error(responseMessage);
        this.setState({isLoading:null})
      }
    })
  }

  downloadFile = (code, evt) => {
    evt.stopPropagation();
    const {saleId} = this.props;
    window.location.href = downloadSaleStaticFiles(saleId, code);
  }

  // Gets hovered and not-hovered sections elements based on fileId
  getHoveredAndNotHoveredElements(fileId) {
    const hoveredTagElementId = `container-icons-uploaded-file-hovered-${fileId}`;
    const notHoveredTagElementId = `container-icons-uploaded-file-not-hovered-${fileId}`;
    let hoveredTagElement = document.getElementById(hoveredTagElementId);
    let notHoveredTagElement = document.getElementById(notHoveredTagElementId);

    return (
      {
        hoveredTagElement,
        notHoveredTagElement,
      }
    )
  }


  // Shows a section with icons and hides check icon
  onHandleMouseOver = (fileId) => {
    let { hoveredTagElement, notHoveredTagElement } = this.getHoveredAndNotHoveredElements(fileId)

    if (hoveredTagElement) {
      hoveredTagElement.style.visibility = 'visible'
    }

    if (notHoveredTagElement) {
      notHoveredTagElement.style.display = 'none'
    }
  }

  // Hides a section with icons and displays check icon
  onHandleMouseOut = (fileId) => {
    let { hoveredTagElement, notHoveredTagElement } = this.getHoveredAndNotHoveredElements(fileId)

    if (hoveredTagElement) {
      hoveredTagElement.style.visibility = 'hidden'
    }

    if (notHoveredTagElement) {
      notHoveredTagElement.style.display = 'flex'
    }
  }

  render() {
    const {dealerFiles, existFiles, isComplete,goToSaleSetting, isCancelledSale} = this.props;
    const {saleFiles, isLoading} = this.state;
    if(!saleFiles)
      return null;
    return(
      <div className="panel-sale-files" style={{border: !existFiles && !isComplete ?  "1px solid #EAA734" : "1px solid #D7DAE2"}}>
        <TitleHeader
          classTitle="title-files" classIcon="icon-files" classIconSvg="icon-files-svg" icon="icon/ic_paper_clip.svg" selectOption={false} title="Files"
          // TODO This is hidden temporarily when we decide to use Sale Reload Files Pages
          // mainDivOnClick={this.props.showReloadFilesPage}
          // rightElement={
          //   <div className="right-element">
          //     <div className="container-icon">
          //       <ReactSVG
          //         src={`${pathServer.PATH_IMG}icon/left-arrow-sign.svg`}
          //         beforeInjection={ svg => svg.classList.add('icon-left-arrow-svg')}
          //       />
          //     </div>
          //   </div>
          // }
        />
        {
          isLoading ?
            <DPSpinner classContainer="loader-container" />
            :
            <div className="container-files">
              <div className={'files-body' + (dealerFiles && dealerFiles.length === 0 ? " files-body-empty": "")}
                   data-test={'sale-files-body'}>
                {
                  dealerFiles && dealerFiles.length > 0 ?
                    dealerFiles.map((file, index) => {
                      const fileFound = saleFiles.find( saleFile =>  { return saleFile.code === file.code});
                      return(
                        <div
                          id={`file-body-option-${index}`}
                          key={`file${index}`}
                          className={index % 2 === 0 ? "file-body-option-color" : "file-body-option"}
                          onMouseOver={fileFound ? () => this.onHandleMouseOver(file.id) : null}
                          onMouseOut={fileFound ? () => this.onHandleMouseOut(file.id) : null}
                        >
                          <div className="container-label-files" data-test={`sale-label-file-${index}`}>
                            <span>{file.name}</span>
                            {
                              !file.optional ?
                                <p className="check-no-optional">*</p> : null
                            }
                          </div>

                          {/* Section - Div's selector class has visibility property sets as hidden by default */}
                          <div
                            id={`container-icons-uploaded-file-hovered-${file.id}`}
                            className="container-icon-sale-hovered">
                            <div>
                              <DPDropzone
                                onDrop={(files) => this.onDrop(file.code, files)}
                                extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG},.${EXT_PDF},.${EXT_WORD}`}
                                dataTestInput={`file-input-${index}`}
                              >
                                <img
                                  className="cloud-image"
                                  src={`${pathServer.PATH_IMG}cloud-upload-arrow.png`}
                                />
                              </DPDropzone>
                            </div>
                            <ReactSVG
                              src={pathServer.PATH_IMG + "icon/download-tray.svg"}
                              className="icon-download-file"
                              beforeInjection={ svg => svg.classList.add("icon-download")}
                              onClick={(evt) => this.downloadFile(file.code, evt)}
                            />
                          </div>
                          {/* End section */}

                          {
                            fileFound ?
                              <div
                                id={`container-icons-uploaded-file-not-hovered-${file.id}`}
                                className="container-icon-sale-not-hovered">
                                <ReactSVG
                                  src={pathServer.PATH_IMG + "icon/ic_check_normal.svg"}
                                  beforeInjection={ svg => svg.classList.add("icon-check")}
                                />
                              </div>
                              : null
                          }

                          {
                            !fileFound && !isCancelledSale
                              ? <div className="container-icon-sale-not-hovered">
                                <div>
                                  <DPDropzone
                                    onDrop={(files) => this.onDrop(file.code, files)}
                                    extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG},.${EXT_PDF},.${EXT_WORD}`}
                                    dataTestInput={`load-file-${index}`}
                                  >
                                    <img
                                      className="cloud-image"
                                      src={`${pathServer.PATH_IMG}cloud-upload-arrow.png`}
                                    />
                                  </DPDropzone>
                                </div>
                              </div>
                              : null
                          }
                        </div>
                      )
                    }) :
                    <FileEmpty title={'No items on files yet'} onClick={goToSaleSetting}/>
                }
              </div>
              {
                dealerFiles && dealerFiles.length > 0 ?
                  <div className="container-no-optional-items">
                    <label className="no-optional-items">*Required items</label>
                  </div> : null
              }
            </div>
        }
      </div>
    )
  }

};

SaleFiles.defaultProps = {
  showReloadFilesPage: null,
}

SaleFiles.propTypes = {
  showReloadFilesPage: PropTypes.func,
}

export default SaleFiles
