import React, {Component} from 'react'
import {
  deleteApplicant,
  loadFinanceById,
  loadFinanceFormByCode,
  loadFormByCode,
  saveFinance,
  updateApplicant,
  updateStateFinances
} from '../../Api'
import HeaderViewApplicant from '../finance/HeaderViewApplicant.jsx'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import {cloneDeep} from 'lodash'
import Loader from '../../../common/UI/Loader.jsx'
import {
  ENTITY_TYPE_CUSTOMER,
  financeStatus,
  FORM_APPLICANT_CODE_ADMIN,
  INDIVIDUAL_CREDIT_DISPLAY_VALUE,
  INDIVIDUAL_CREDIT_NAME,
  JOIN_CREDIT_DISPLAY_VALUE,
  JOIN_CREDIT_NAME
} from '../../Constants'
import {ATTRIBUTE_APPLICANT_TYPE, SUCCESSFUL_CODE} from '../../../common/Constants'
import get from 'lodash/get'
import ModalConfirmDelete from '../util/ModalConfirmDelete'
import {DPMessageBar} from '../../common/DPMessageBar'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import isArray from 'lodash/isArray'
import {getErrors} from '../finance/FinanceEdit'

const valuesToPopulate = [{key :"address", value: "", id: ""}, {key :"email", value: "", id: ""}, {key :"firstName", value: "", id: ""},
  {key :"lastName", value: "", id: ""}, {key :"middleName", value: "", id: ""},{key :"phoneNumber", value: "", id: ""},{key: "dobmdy", value: "", id: ""},
  {key: "ssn", value: "", id: ""}, {key: "secondPhone", value: "", id: ""}, {key: "city", value: "", id: ""}, {key: "state", value: "", id: ""},
  {key: "zipCode", value: "", id: ""}];

function getInitialFinance(isIndividualCreditType = true) {
  const financeType = isIndividualCreditType
    ? INDIVIDUAL_CREDIT_DISPLAY_VALUE
    : JOIN_CREDIT_DISPLAY_VALUE

  return (
    {
      status: financeStatus.NEW,
      financeType,
      read: false,
      active: true,
      dealerId: "",
      objectType: ENTITY_TYPE_CUSTOMER
    }
  )
}

function getMessage(applicant,id){
  let message = "";
  if(id === financeStatus.NEW){
    message = `New Finance Application`
  }else{
    message = `${applicant} finance application`
  }
  return message;
}

export default class FinanceView extends Component {
  constructor() {
    super();

    this.state= {
      dataFinance: null,
      widthPage: window.innerWidth,
      errors: []
    }
  }

  populateCustomerData(customer, form, values){
    let valuesFinancePopulate = cloneDeep(valuesToPopulate);
    const {formAttributes} = form;
    const {attributes} = formAttributes;
    const valuesCopy = cloneDeep(values);
    valuesFinancePopulate.forEach( valuePopulate => {
      const {key} = valuePopulate;
      if(customer[key]){
        valuePopulate.value = customer[key]
      }
      if(key === "phoneNumber"){
        valuePopulate.key = "customer.cellPhone";
      }else if( key !== "firstName" && key !== "lastName"){
        valuePopulate.key = `customer.${key}`
      }
    });

    valuesFinancePopulate.forEach( valuePopulate => {
      const attributeFound = attributes.find( attribute => { return attribute.name === valuePopulate.key});
      if(attributeFound){
        valuesCopy[attributeFound.id] = valuePopulate.value;
      }
    });
    return valuesCopy
  }

  componentDidMount() {
    const {id, saleId} = this.props.params;
    const {customer, applicant} = this.props.location.state || {};
    this.setApplicantName(id,applicant);
    this.setState({isLoading: true});
    if(id === "new"){
      loadFormByCode(FORM_APPLICANT_CODE_ADMIN,"new").then(response => {
        const {responseCode, responseMessage, data} = response
        if(responseCode === SUCCESSFUL_CODE){
          const {form, values: originalValues} = data;
          let valuesFinance = {}
          let values
          let customerId
          let finance

          // When customer has only one element
          if (!isArray(customer)) {
            valuesFinance = this.populateCustomerData(customer, form, originalValues);
            valuesFinance.customerId = customer.id;
            valuesFinance.objectId = customer.id;

            const applicantTypeAttribute = form.formAttributes.attributes.find(el => el.name === ATTRIBUTE_APPLICANT_TYPE)
            const individualCreditTypeAttributeValue = applicantTypeAttribute.attributeValues.find(el => el.name === INDIVIDUAL_CREDIT_NAME)
            valuesFinance[applicantTypeAttribute.id] = [individualCreditTypeAttributeValue.id]

            values = [valuesFinance];
            customerId = customer.id
            finance = getInitialFinance(true)
          }
          else {
            valuesFinance = customer.map(el => this.populateCustomerData(el, form, originalValues))
            customer.forEach((el, index) => {
              valuesFinance[index].objectId = el.id
              valuesFinance[index].customerId = el.id
            })

            const applicantTypeAttribute = form.formAttributes.attributes.find(el => el.name === ATTRIBUTE_APPLICANT_TYPE)
            const joinCreditTypeAttributeValue = applicantTypeAttribute.attributeValues.find(el => el.name === JOIN_CREDIT_NAME)
            valuesFinance[0][applicantTypeAttribute.id] = [joinCreditTypeAttributeValue.id]

            values = valuesFinance
            customerId = customer[0].id
            finance = getInitialFinance(false)
          }

          const dataFinance = {customerId, finance, form, values};

          this.setState(
            {
              dataFinance,
              saleId,
              customer,
              isLoading: false
            }
          );
        }else{
          this.setState({isLoading: false})
          console.error(responseMessage);
        }
      })
    }
    else {
      this.onLoadExistingFinanceFormInfo()
    }
  }

  onLoadExistingFinanceFormInfo(financeInfo = null, showSaveBar = false) {
    const {id, saleId} = this.props.params;
    const {loadFinanceApp} = this.props.location.state;
    loadFinanceFormByCode(FORM_APPLICANT_CODE_ADMIN, financeInfo ? financeInfo.id : id)
      .then(response => {
        const { responseCode, responseMessage, data } = response
        if(responseCode === SUCCESSFUL_CODE){
          if (financeInfo !== null ) {
            loadFinanceApp(financeInfo)
          }

          const attributes = data?.form?.formAttributes?.attributes ?? [];
          const values = data?.values ?? []
          const attributeApplicantType = find(attributes, el => el.name === ATTRIBUTE_APPLICANT_TYPE);
          const sortedValues = orderBy(values, [attributeApplicantType.id], ['desc']);
          const dataFinance = {
            ...data,
            values: sortedValues,
          }
          this.setState(
            {
              dataFinance,
              saleId,
              showSaveBar,
              isLoading: false,
              financeToSave: null,
            }
          )
        } else {
          this.setState({ isLoading: false });
          console.error(responseMessage);
        }
      })
  }

  setApplicantName = (id,applicant) => {
    const {showFinanceView} = this.props;
    if(applicant){
      const message =  getMessage(applicant,id);
      showFinanceView(message);
    }else{
      loadFinanceById(id).then(({responseCode, data}) => {
        if (responseCode === SUCCESSFUL_CODE){
          const {applicant, id} = data.finance;
          const message =  getMessage(applicant,id);
          showFinanceView(message);
        }else {
          const message =  getMessage("",id);
          showFinanceView(message);
        }
      });
    }
  };

  updateFinanceObj = (financeObj) => {
    this.setState({financeObj: financeObj});
  }

  editSalesMan(applicant) {
    let financesUpdate = [];
    financesUpdate.push(applicant);
    updateStateFinances({updateFinances: financesUpdate}).then(({responseCode, responseMessage}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
      }
    })
  }

  saveApplicant = () => {
    let {financeToSave,customer,saleId} = this.state;
    const {id} = this.props.params;

    let newValues = [...financeToSave.values]
    newValues = newValues.map(el => {
      if (!el.objectId) {
        return {
          ...el,
          objectId: get(el, ['leadId'], '')
        }
      } else {
        return el
      }
    })

    financeToSave = {
      ...financeToSave,
      values: newValues,
    }

    // This section formats financeToSave before sends to API
    if (financeToSave.financeType === INDIVIDUAL_CREDIT_DISPLAY_VALUE) {
      if (financeToSave.values.length > 1) {
        const leadsToDelete = newValues.slice(1).map(el => (
          {
            leadId: get(el, ['leadId'], ''),
            customerId: get(el, ['customerId'], ''),
          }
        ))
        financeToSave = {
          ...financeToSave,
          values: newValues.slice(0, 1),
          leadsToDelete,
        }
      }
    }

    if (financeToSave.values.length === 1 ) {
      financeToSave.finance.financeType = INDIVIDUAL_CREDIT_DISPLAY_VALUE
    } else {
      financeToSave.finance.financeType = JOIN_CREDIT_DISPLAY_VALUE
    }

    this.setState(
      {
        isLoading: true,
        showModal: false,
      }
    )

    if(id === "new"){
      const tempFinanceToSave = {
        finance: financeToSave.finance,
        values: newValues,
        formCode: FORM_APPLICANT_CODE_ADMIN
      }

      saveFinance(tempFinanceToSave)
        .then(({responseCode, responseMessage, data}) => {
          if (responseCode === SUCCESSFUL_CODE) {
            const { financeId } = data
            const applicant = `${newValues[0].firstName} ${newValues[0].lastName}`
            const applicantInfo= { id: financeId, applicant }
            this.onLoadExistingFinanceFormInfo(applicantInfo, true)
          } else {
            console.error(responseMessage)
            this.setState({isLoading: false})
          }
      })
    }
    else {
      const tempFinanceToUpdate = {
        ...financeToSave,
      }
      const applicant = `${newValues[0].firstName} ${newValues[0].lastName}`
      const applicantInfo= { id: financeToSave.financeId, applicant }

      updateApplicant(tempFinanceToUpdate).then(({responseCode, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          this.onLoadExistingFinanceFormInfo(applicantInfo, true)
        } else {
          console.error(responseMessage)
        }
      })
    }
  }

  openModal = (finance,applicant) => {
    const {dataFinance} = this.state;
    finance.finance = applicant;
    finance.values = dataFinance.values;
    const attributes = dataFinance.form.formAttributes.attributes;
    const newValues = JSON.parse(JSON.stringify(finance.values))
    const errors = getErrors(newValues, attributes, dataFinance.showEmploymentSubSections);
    if(errors.length > 0)
      this.setState({errors: errors, financeToSave: finance})
    else
      this.setState({showModal: true, financeToSave: finance})
  }

  handleModalChange = () => {
    this.setState({showModal: false})
  }

  updateDataFinance(dataFinance) {
    this.setState({ dataFinance })
  }

  onHandleModalDelete(applicantToDelete) {
    this.setState(
      {
        showModalDelete: true,
        applicantToDelete,
      }
    )
  }

  onDeleteApplicant() {
    let applicantToDelete = {...this.state.applicantToDelete}
    let newDataFinance = {...this.state.dataFinance}
    let newValues = [...newDataFinance.values]

    this.setState({ isLoading: true, showModalDelete: false })

    // This condition lets change Type of application on main applicant
    if (newValues.length === 2) {
      const attributes = get(newDataFinance, ['form', 'formAttributes', 'attributes'], [])
      const applicantType = attributes.find(el => el.name === ATTRIBUTE_APPLICANT_TYPE)
      const attributeValuesForApplicantType = get(applicantType, ['attributeValues'], [])
      const individualCreditAttributeValue =
        attributeValuesForApplicantType.find(el => el.name === INDIVIDUAL_CREDIT_NAME)

      newValues[0] = {
        ...newValues[0],
        [applicantType.id]: [individualCreditAttributeValue.id]
      }

      newDataFinance.financeType = INDIVIDUAL_CREDIT_NAME
    }

    // Temporarily delete an element inside NewValues
    newValues.splice(applicantToDelete.index, 1)
    newDataFinance = {
      ...newDataFinance,
      values: newValues,
    }

    // First condition: To delete temporal co-applicant
    if ((!applicantToDelete.leadId && !applicantToDelete.customerId)
      || (applicantToDelete.customerId && !applicantToDelete.financeId && newDataFinance.finance.status === 'new')) {
      this.setState(
        {
          dataFinance: newDataFinance,
          isLoading: false,
        }
      )
    }
    else {
      delete applicantToDelete.index

      // Hitting API just in case it was an existing co-applicant (saved on DB)
      deleteApplicant(applicantToDelete)
        .then(({responseCode, responseMessage}) => {
          if (responseCode === SUCCESSFUL_CODE) {
            this.setState(
              {
                dataFinance: newDataFinance,
                isLoading: false,
              }
            )
          } else {
            console.error(responseMessage)
            this.setState({showModalDelete: false, isLoading: false})
          }
        })
    }
  }

  render() {
    const {users, goToSaleBoard, salesmanId} = this.props;
    const {dataFinance, showModal, isLoading, showSaveBar, showModalDelete, errors} = this.state;
    const {id} = this.props.params;
    const {isNewFinanceApplication = false} = this.props.location.state;

    return(
      <div className="container-center">
        {isLoading ? <Loader/> : null}
        <div
          className="panel-sale-finance"
          style={
            {
              height:"auto",
              marginTop: showSaveBar || isNewFinanceApplication
                ? '25px'
                : '0px'
            }
          }
        >
          {
            showSaveBar
              ? <DPMessageBar
                label={'Congratulations for saving the finance applicant'}
                icon={true}
                onClick={() => this.setState({showSaveBar: false})}
              />
              : null
          }
          {
            isNewFinanceApplication
              ? <DPMessageBar
                label={'This is a new application. Make sure to click on <b>Save</b> to keep your changes'}
                containerClassName='message-new-finance'
              />
              : null
          }
          {
            dataFinance && users ?
              <HeaderViewApplicant dataFinance={dataFinance} users={users} onChange={this.editSalesMan} salesmanId={salesmanId}
                                   openModal={this.openModal} isViewFromSaleEdit={true} goToSaleBoard={goToSaleBoard} financeId={id}
                                   onHandleModalDelete={(applicantToDelete) => this.onHandleModalDelete(applicantToDelete)}
                                   onUpdateDataFinance={(dataFinance) => this.updateDataFinance(dataFinance)} errors={errors}
              /> : null
          }
          {
            showModal ?
              <GenericModalConfirm show={showModal}
                                   message={"Are you sure you want to save these changes?"}
                                   save={this.saveApplicant}
                                   onHide={this.handleModalChange}
                                   showButtonCancel={true}
                                   buttonLabelSave="Save"
                                   classButtonSave="btn-save"
              /> : null
          }

          {
            showModalDelete
              ? <ModalConfirmDelete
                show={showModalDelete}
                showButtonDelete={true}
                onSubmit={() => this.onDeleteApplicant()}
                onHide={() => this.setState({ showModalDelete: false })}
                classButtonDelete="btn-delete"
                buttonLabelDelete="Yes, delete"
                message={"Do you want to delete this item permanently?"}
                subMessage={"This action is irreversible"}
                styleModal="modal-delete"
                styleRight="panel-right-delete-user"
                isSale={true}
              />
              : null
          }
        </div>
      </div>
    )
  }
}