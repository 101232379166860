import React from 'react'
import DPIcon, {Icons} from '../../../../common/DPIcon'
import GDGButton, {BUTTON_TYPE} from '../GDGButton'
import {EmptyFunc, pathServer} from '../../../../../common/Constants'
import PropTypes from 'prop-types'
require('./ConfirmSent.scss')

const ConfirmSent = (props) => {
  const {text, title, subtitle, btnText, btnHoverText, phoneNumber,email, onClickButton} = props
  return(
    <div className="container-confirm-sent">
      <img className="logo-godealergo" src={pathServer.PATH_IMG + "godealergo.png"} alt=""/>
      <div className="container-text">
        <DPIcon icon={Icons.CHECK}/>
        <span className="text">{text}</span>
      </div>
      <span className="title">{title}</span>
      <span className="subtitle">{subtitle}</span>
      <GDGButton
        type={BUTTON_TYPE.GRAY}
        text={btnText}
        hoverText={btnHoverText}
        classNames="btn-back-to"
        onClick={onClickButton}
      />
      <span className="phone-number">{phoneNumber}</span>
      <span className="email">{email}</span>
    </div>
  )
}

ConfirmSent.defaultProps = {
  text: 'Demo Request Sent',
  title: 'A member of our team will reach out to you soon',
  subtitle: 'Thank you',
  btnText: 'Back to GoDealerGo',
  btnHoverText: 'Back to GoDealerGo',
  phoneNumber: '801-494-9191',
  email: 'info@godealergo.com',
  onClickButton: EmptyFunc,
}

ConfirmSent.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btnText: PropTypes.string,
  btnHoverText: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  onClickButton: PropTypes.func,
}
export default ConfirmSent