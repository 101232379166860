import React from 'react'
import {FULL_BALANCE, NEW_BALANCE} from '../../../Constants'
import RadioButton from 'material-ui/RadioButton'
import {DPDynamicNumberInput} from '../../../common/DPDynamicNumberInput'

const radioButtonLabelStyle = {
  marginLeft: -10,
  color: "#787878",
  lineHeight: "18px",
  fontSize: "13px",
  fontWeight: "normal",
  fontFamily: "inherit"
}

const radioButtonStyle = {
  fill: "#3B86FF",
  width: "18px",
  height: "18px"
}



export const PaymentAmountContainer = (props) => {
  const {selectedAmountOption, outstandingBalance, selectedAmountOptionHandler} = props
  return(
    <div className="payment-amount-container">
      <div id="full-amount-payment">
        <RadioButton checked={selectedAmountOption === FULL_BALANCE}
                     labelPosition="right"
                     labelStyle={radioButtonLabelStyle}
                     label={`Pay outstanding balance ($${outstandingBalance})`}
                     onCheck={() => selectedAmountOptionHandler(FULL_BALANCE)}
                     iconStyle={radioButtonStyle}
        />
      </div>
      {/*<div className="new-payment-amount-container">
        <div className="new-payment-amount-radio">
          <RadioButton checked={selectedAmountOption === NEW_BALANCE}
                       labelPosition="right"
                       labelStyle={radioButtonLabelStyle}
                       label="Pay other amount"
                       onCheck={() => selectedAmountOptionHandler(NEW_BALANCE)}
                       iconStyle={radioButtonStyle}
          />
        </div>
        <DPDynamicNumberInput
          className={'new-payment-amount'}
          title=""
          id={'inputPrice'}
          value={amount}
          onChange={(evt) => updateValue(evt.target.value)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={selectedAmountOption === FULL_BALANCE}
          withSymbol={true}
          classWrapperSymbol={'dollar-prefix'}
          styleInput={{paddingLeft: "20px", fontSize: "13px"}}
          errorMessage=""
        />
      </div>
      */}
    </div>
  )
}
