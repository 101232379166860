import React, {Component, useState, useEffect} from 'react'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../../../common/Constants'
import SummaryInfoLayout from './SummaryInfoLayout'
import {getTeamMembersQuantity} from '../../DashboardUpdatePlan'
import sortBy from 'lodash/sortBy'


const AdvancedFeatureItem = (props) => {
  return (
    <div className={'plan-advanced-feature-item'}>
      <div>{props.advancedFeatureText}</div>
      {
        props.included
          ? <ReactSVG
            src={pathServer.PATH_IMG + 'icon/ic_check_normal.svg'}
            beforeInjection={svg => svg.classList.add('icon-check')}
            className={'icon-check'}
          />
          : <div style={{fontSize: '35px', marginLeft: 'auto'}}>-</div>
      }
    </div>
  )
}

const PackageSection = (props) => {
  const {packageInfo, handleClickSelect, features, inventoryItemFeatures, individualFeatures, selectedInventoryItem} = props
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    setShowMore(false)
  }, [])

  return (
    <section id="graphs">

      <div className={'plan-header-container'}>
        <div className={'plan-header-info'}>
          <div className={'plan-header-title'}>{packageInfo && packageInfo.description}</div>
          <div className={'plan-header-price'}>{`${packageInfo && packageInfo.price} / month`}</div>
        </div>
        <div className={'button-container'}>
          {
            !selectedInventoryItem
              ? <button
                className="select-plan-btn"
                onClick={() => handleClickSelect(packageInfo)}
              >
                Select
              </button>
              : <div className={'selected-current-plan'}>Current</div>
          }
        </div>
      </div>

      <div className={'plan-basic-features-container'}>
        <div className={'plan-basic-feature-item'}>
          <b>{getTeamMembersQuantity(packageInfo.teamMembersQuantity)}</b> Team members
        </div>
        <div className={'plan-basic-feature-item'}>
          <b>{getTeamMembersQuantity(packageInfo.listingsQuantity, 'Until')}</b> Listings
        </div>

        {
          features &&
          sortBy(features, ['position']).map(feature => {
            const hasThisFeature = inventoryItemFeatures && inventoryItemFeatures.find(
              el => el.inventoryItemId === packageInfo.id && el.featureId === feature.id)

            return (
              hasThisFeature
                ? <div
                  key={`basic-feature-item-${feature.id}`}
                  className={'plan-basic-feature-item'}
                >
                  {feature.name}
                </div>
                : null
            )
          })
        }
      </div>

      {
        showMore &&
        <div className={'plan-advanced-features-container'}>
          <div className={'plan-advanced-feature-title'}>Features</div>
          {
            individualFeatures &&
            sortBy(individualFeatures, ['position']).map(individualFeature => {
              const hasThisFeature = inventoryItemFeatures && inventoryItemFeatures.find(
                el => el.inventoryItemId === packageInfo.id && el.featureId === individualFeature.id)

              return (
                <AdvancedFeatureItem
                  key={`individual-feature-${individualFeature.id}`}
                  advancedFeatureText={individualFeature.name}
                  included={hasThisFeature}
                />
              )
            })
          }
        </div>
      }

      <div
        className={'show-option'}
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? 'Show less' : 'Show more'}
      </div>

    </section>
  )
}

const UpdatePlanMobileLayout = (props) => {
  const {inventoryItems, features, inventoryItemFeatures, individualFeatures, selectedInventoryItems,
    order, paymentMethodMain, nextInventoryItem, setNextInventoryItem
  } = props
  const [showPaymentInfo, setShowPaymentInfo] = useState(false)

  useEffect(() => {
    setShowPaymentInfo(false)
  }, [])

  return (
    <React.Fragment>
      <main id="update-plan-mobile-layout">
        {
          !showPaymentInfo &&
          <React.Fragment>
            {
              inventoryItems &&
              inventoryItems.map(el => {
                const {id} = el
                const selectedInventoryItem = selectedInventoryItems.find(x => { return x.inventoryItemId === id })

                return (
                  <PackageSection
                    key={`package-section-${el.id}`}
                    handleClickSelect={setNextInventoryItem}
                    packageInfo={el}
                    features={features}
                    inventoryItemFeatures={inventoryItemFeatures}
                    individualFeatures={individualFeatures}
                    selectedInventoryItem={selectedInventoryItem}
                  />
                )
              })
            }
          </React.Fragment>
        }

        {
          showPaymentInfo &&
          <SummaryInfoLayout
            order={order}
            paymentMethodMain={paymentMethodMain}
            nextInventoryItem={nextInventoryItem}
          />
        }

      </main>

      <div className="dashboard-footer">
        {
          !showPaymentInfo
            ? <React.Fragment>
              <div style={{fontSize: '15px'}}><b>Check out</b></div>
              <div
                onClick={() => setShowPaymentInfo(!showPaymentInfo)}
                style={{display: 'flex', marginLeft: 'auto'}}
              >
                <div style={{fontSize: '13px', color: '#4AD991', height: '13px', marginRight: '10px'}}>
                  Tier 3 select
                </div>
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'}
                  beforeInjection={svg => svg.classList.add('icon-arrow-next')}
                  className={'icon-arrow-next'}
                />
              </div>
            </React.Fragment>
            : <div
              onClick={() => setShowPaymentInfo(!showPaymentInfo)}
              style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
                beforeInjection={svg => svg.classList.add('icon-arrow-back')}
                className={'icon-arrow-back'}
              />
              <div style={{fontSize: '15px', marginLeft: '10px'}}><b>Select plan</b></div>
            </div>
        }
      </div>
    </React.Fragment>
  )
}

export default UpdatePlanMobileLayout