import React, {Component} from 'react'
import {connect} from 'react-redux'

class FinanceContainer extends Component {

  constructor() {
    super();
  }

  componentDidMount() {
    let {showSideBar} = this.props;
    if (showSideBar) {
      let container = window.document.getElementById("container");
      container.className += ' container-collapse';
    }
  }

  render() {
    //React.cloneElement()
    const {height, search} = this.props;
    return (
      <div style={{height:height}}>
        {
          React.cloneElement(this.props.children, {search: search,height:height})
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar
  }
};

export default connect(mapStateToProps)(FinanceContainer)