import React, {Component} from 'react'
import NavigationHeader from '../util/NavigationHeader.jsx'
import {
  RESPONSE_MESSAGE_ACTIONS,
  MESSAGE_UNDELETE_ACTIONS,
  SHOW_RESPONSE_MESSAGE,
  SORT_MESSAGES,
  NUMBER_PAGES_SHOWN,
  CUSTOMERS_BY_PAGE,
} from '../../Constants'
import {browserHistory} from 'react-router'
import {deleteUndeleteResponseMessageApi, loadResponseMessageApi, updateStateResponseMessage} from '../../Api'
import {ReactSVG} from 'react-svg'
import {filter, sortBy} from 'lodash/collection'
import ReactToPrint from 'react-to-print'
import {connect} from 'react-redux'
import Moment from 'moment'
import {Popover} from '../util/ComponentUtils'
import {onSortMessage, textWithoutHTML} from '../util/Utils'
import {getPosition} from '../../../common/Util'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'

class MessageSent extends Component {
  componentRef;
  constructor() {
    super();
    this.onShowSelected = this.onShowSelected.bind(this);
    this.onChangeDropDownForm = this.onChangeDropDownForm.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.onChangeCheckAll = this.onChangeCheckAll.bind(this);
    this.onChangeCheck = this.onChangeCheck.bind(this);
    this.renderCheckBox = this.renderCheckBox.bind(this);
    this.buildPages = this.buildPages.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onChangeReplay = this.onChangeReplay.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.onDeleteMessage = this.onDeleteMessage.bind(this);
    this.renderMessagesPane = this.renderMessagesPane.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.state = {
      responseMessageAll: [],
      messages: [],
      responseMessage: [],
      totalPages: 10,
      numberPagesShown: NUMBER_PAGES_SHOWN,
      currentPage: 1,
      selectedAll: false,
      idsSelected: [],
      responses: [],
      cont: 10,
      rowName: [],
      messageList: [],
      responseMessageSelected: {},
      showOldResponse: {},
      countMessageSelected: null,
      showMessageReplay: {},
      showDropDownUndelete: false,
      dropDownSelect: ["Delete", "Sent Messages", "Sort By", "Undelete"],
      dropDownChangeStates: RESPONSE_MESSAGE_ACTIONS,
      dropDownUndelete: {
        render: false,
        values: MESSAGE_UNDELETE_ACTIONS,
      },
      dropDownShowMessages: SHOW_RESPONSE_MESSAGE,
      dropDownSortMessages: SORT_MESSAGES,
      typeReply: "",
      showModal: false,
      formsPages: [],
      showOriginalSize: true,
      showPane: false,
      responseData:{
        id:null,
        dealerId:'',
        listRecipients:'',
        listMessages:'',
        reference:'',
        status:false,
        lastName:'',
      },
      showPopover: false,
      filterSelected: null
    }
  }

  componentWillMount() {
    let {responseMessage} = this.state;
    this.loadResponseMessage(responseMessage);
  }


  loadResponseMessage(responseMessage){
    loadResponseMessageApi().then(({responseCode, responseMessage: responseMessageRM, data}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessageRM)
        return
      }
      responseMessage = data.responses.reverse(); //Reverse to show the latest sent messages first
      this.setState({responseMessage: responseMessage, responseMessageAll: responseMessage, showPane:false, responseMessageSelected:{}},()=>{
        this.onShowSelected();
      })
    });
  }
  componentWillReceiveProps(nextProps){
    const {search} = nextProps;
    this.findByKeyWord(search);
  }

  findByKeyWord(word){
    if(word && word !== ""){
      this.filterMessage(word);
    } else{
      this.onShowSelected();
    }
  }

  filterMessage(word) {
    let {responseMessage} = this.state;
    let messagesEdit = responseMessage;
    let newMessages = [];
    messagesEdit.find((responseMessage) => {
      let dateString = Moment(responseMessage.createDate).format('LLL').toLowerCase() + " " + Moment(responseMessage.createDate).format('L');
      if(responseMessage.listRecipients.toLowerCase().indexOf(word.toLowerCase()) !== -1 || responseMessage.text.toLowerCase().indexOf(word.toLowerCase()) !== -1 || dateString.indexOf(word.toLowerCase()) !== -1){
        newMessages.push(responseMessage);
      }
    });
    this.buildPages(newMessages); // Send to build page by showing
  }

  buildPages(data) {
    let totalPages;
    let formsPages = [];
    let {responseMessageSelected} = this.state;
    if (data.length !== 0) {
      let page = [];
      totalPages = data.length / NUMBER_PAGES_SHOWN;
      if (data.length % NUMBER_PAGES_SHOWN !== 0)
        totalPages++;
      totalPages = Math.floor(totalPages);
      data.map((item, index) => {
        page.push(item);
        if ((index !== 0 && (index + 1) % NUMBER_PAGES_SHOWN === 0) || (index === (data.length - 1))) {
          formsPages.push(page);
          page = [];
        }
      });
    } else {
      totalPages = 0;
    }
    this.setState({formsPages: formsPages, totalPages: totalPages, responseMessageSelected: responseMessageSelected});
  }

  handlePagination(number) {
    this.setState({currentPage: number})
  }

  onChangeDropDownForm(dropDown) {
    let filterSelected = dropDown;
    let target = document.getElementById(dropDown);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };

    this.setState({showPopover:true, anchorEl: anchorEl, filterSelected: filterSelected})
  }

  onSelectDropDown(indexArraySelected, value) {
    let {dropDownSelect} = this.state;
    dropDownSelect[indexArraySelected] = value;
    this.setState({dropDownSelect: dropDownSelect, showPopover: false, filterSelected: null});
  }

  onShowSelected() {
    let {responseMessage, dropDownSelect, dropDownShowMessages, dropDownSortMessages, showDropDownUndelete} = this.state;
    let messagesEdit = responseMessage;
    let newMessages = [];
    let that = this;

    dropDownShowMessages.forEach((show,index) => {
      if(show.value === dropDownSelect[1]){
        (index === 1) ? showDropDownUndelete = true : showDropDownUndelete = false;
        messagesEdit.forEach((message) => { //Iterate for messages
          if ((message.status && index === 0) || (index === 1 && !message.status)) { //Show message in accordance with option and messages states
            newMessages.push(message);
          }
        });
      }
    });

    dropDownSortMessages.forEach((sort, index) => {
      if(sort.value === dropDownSelect[2]){
        newMessages = onSortMessage(newMessages, sort.id);
      }
    });

    this.setState({selectedAll: false, idsSelected: [], countMessageSelected: null, showDropDownUndelete: showDropDownUndelete, responseMessage: messagesEdit});
    this.buildPages(newMessages); // Send to build page by showing
  }

  onChangeCheckAll() {
    let {formsPages, currentPage, idsSelected, selectedAll} = this.state;
    let countMessageSelected = 0;
    if (!selectedAll) {
      formsPages[currentPage - 1].forEach(message => {
        idsSelected.push(message.id);
        countMessageSelected++;
      });
    } else {
      idsSelected = [];
    }
    this.setState({selectedAll: !this.state.selectedAll, idsSelected: idsSelected, countMessageSelected: countMessageSelected})
  }

  onChangeCheck(id) {
    let {idsSelected} = this.state;
    if (idsSelected.indexOf(id) === -1) {
      idsSelected.push(id);
    } else {
      idsSelected.splice(idsSelected.indexOf(id),1);
    }
    let countMessageSelected = idsSelected.length;
    this.setState({idsSelected: idsSelected, countMessageSelected: countMessageSelected});
  }

  renderCheckBox(value, onChange, id, isSelected, styleCheck) {
    return (
      <label key={id} className="container-check"  style={styleCheck ? styleCheck:null}>
        <input type="checkbox"
               checked={value}
               onChange={onChange}/>
        <span className="checkmark" style={isSelected ? {border: "1.4px #4695d4 solid"} : null}/>
        {
          value ? <i className="fa fa-check i-check"/> : null
        }
      </label>
    )
  }

  onDeleteMessage(message) {
    let {responseMessage} = this.state;
    deleteUndeleteResponseMessageApi({messageIds:[message.id],active:false}).then((response) => {
      if (response.responseCode === SUCCESSFUL_CODE) {
        console.log("response",response);
        this.loadResponseMessage(responseMessage);
      }
    });
  }

  updateResponseMessage() {
    let {responseMessage, responseMessageAll, idsSelected, showDropDownUndelete, responseMessageSelected, showPane} = this.state;
    if (idsSelected && responseMessageSelected){
      idsSelected.forEach((row) => {
        if(row===responseMessageSelected.id){
          showPane = false;
        }
      });
    }
    let listResponseMessages = filter(responseMessage, function (response) {
      return idsSelected.indexOf(response.id) >= 0
    });
    if (listResponseMessages) {
      if(showDropDownUndelete){
        listResponseMessages = filter(responseMessageAll, function (response) {
          return idsSelected.indexOf(response.id) >= 0
        });
        listResponseMessages.forEach(response => {
          response.status = true;
        });
      }else{
        listResponseMessages.forEach(response => {
          response.status = false;
        });
      }
    }
    updateStateResponseMessage({updateResponseMessage: listResponseMessages}).then((response) => {
      if (response.responseCode === SUCCESSFUL_CODE) {
        this.setState({responseMessage:responseMessage, idsSelected:[], countMessageSelected:null, showPane: showPane},()=>{
          this.onShowSelected()
        })
      }
    })
  }

  onChangeTab(id) {
    if (id==="id") {
      browserHistory.push({pathname: "/main/message/finance", state: {showMessage: false}});
    } else if(id==="sent"){
      this.setState({showPane: false});
      browserHistory.push({pathname: "/main/message/sent", state: {showMessage: true}});
    } else{
      browserHistory.push({pathname: "/main/message", state: {showMessage: true}});
    }
  }

  onChangeMessage(message, index) {
    let messageEdit = message;
    this.setState({responseMessageSelected: messageEdit, indexRowSelected: index, showPane: true});
  }

  onChangeReplay(id, type) {
    let {showMessageReplay} = this.state;
    if(showMessageReplay[id]){
      showMessageReplay[id] = !showMessageReplay[id];
    }else{
      showMessageReplay[id] = true
    }
    this.setState({showMessageReplay: showMessageReplay, typeReply: type})
  }

  closePopover(dropDown) {
    let dropDownClose = this.state[dropDown];
    dropDownClose.render = false;
    this.setState({[dropDown]: dropDownClose, showPopover: false, filterSelected: null})
  }

  onResize(){
    let {showOriginalSize} = this.state;
    let idMessageLeft = window.document.getElementsByClassName("message-left");
    let idMessageDetails = window.document.getElementsByClassName("message-details");
    let contentReply = window.document.getElementById("content-reply");
    let editorContainer = window.document.getElementsByClassName("DraftEditor-editorContainer");
    let btnMessageReplay = window.document.getElementsByClassName("btn-message-replay");
    let btnMessageForward = window.document.getElementsByClassName("btn-message-forward");
    if(!showOriginalSize){
      idMessageLeft[0].style.width = "60%";
      idMessageDetails[0].style.width = "40%";
      idMessageDetails[0].style.overflowX = "hidden";
      if(contentReply){
        contentReply.style.height = "225px"
      }
      if(editorContainer[0]){
        editorContainer[0].style.height = "170px"
      }
      if(btnMessageReplay && btnMessageReplay[0]){
        btnMessageReplay[0].style.width = "30%";
      }
      if(btnMessageForward && btnMessageForward[0]){
        btnMessageForward[0].style.width = "35%";
      }
    }else{
      idMessageLeft[0].style.width = "26%";
      idMessageLeft[0].style.position = "relative";
      idMessageDetails[0].style.width = "100%";
      idMessageDetails[0].style.backgroundColor = "white";
      if(contentReply){
        contentReply.style.height = "270px"
      }
      if(editorContainer[0]){
        editorContainer[0].style.height = "210px"
      }
      if(btnMessageReplay && btnMessageReplay[0]){
        btnMessageReplay[0].style.width = "17%";
      }
      if(btnMessageForward && btnMessageForward[0]){
        btnMessageForward[0].style.width = "20%";
      }
    }
    this.setState({showOriginalSize:!showOriginalSize})
  }

  renderMessagesPane(messageSelected) {
    return (
      <div>
        <div className="row-message" id="row-message-detail-last">
          <div className="last-message-conversation" id="last-message-talk">
            <div >
            </div>
            <label className="label-message-repair-last" id="label-message-last-talk">
              {textWithoutHTML(messageSelected.text)}
            </label>
          </div>
        </div>
      </div>

    );
  }

  renderAction(id){
    const{dropDownChangeStates,dropDownShowMessages,dropDownSortMessages,dropDownSelect} = this.state;
    let indexSelected = 0;
    let values = [];
    let option = [];
    let selectedItem = "";

    switch (id){
      case 'selectedStates':
        indexSelected = 0;
        values = dropDownChangeStates;
        selectedItem = dropDownSelect[0];
        break;
      case 'selectedShow':
        indexSelected = 1;
        values = dropDownShowMessages;
        selectedItem = dropDownSelect[1];
        break;
      case 'selectedSort':
        indexSelected = 2;
        values = dropDownSortMessages;
        selectedItem = dropDownSelect[2];
        break;
    }

    values.forEach((action,index) => {
      if(action.value === selectedItem){
        option.push(
          <li key={index} className="item-selected"
              onClick={() => this.onSelectDropDown(indexSelected,action.value)}>
            {action.value}
          </li>
        )
      } else{
        option.push(
          <li key={index} className="change-submenu-item"
              onClick={() => this.onSelectDropDown(indexSelected,action.value)}>
            {action.value}
          </li>
        )
      }

    });

    let dataPopover = "";

    if (id === 'selectedStates'){
      dataPopover = <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {option}
          </u>
        </div>
      </div>
    } else {
      dataPopover = <div className="popover-general-message">
        <div className="popover-general-message-sub">
        <u onClick={() => this.onShowSelected()}>
      {option}
    </u>
    </div>
    </div>
    }

    return(
      dataPopover
    )
  }

  handleRequestClose() {
    this.setState({showPopover: false,filterSelected: null});
  }

  closePanel = () => this.setState({ showPane: false, responseMessageSelected: {}})

  render() {
    const {totalPages, currentPage, selectedAll, idsSelected, responseMessageSelected, countMessageSelected, formsPages,dropDownSelect,
      showDropDownUndelete, showPane, showOriginalSize,numberPagesShown, showPopover, filterSelected, anchorEl} = this.state;
    const {height, newFinanceApplicationsCounter, unreadMessagesCounter} = this.props;
    let that = this;
    let itemDropDownReplace = 0;
    if (showDropDownUndelete) {
      itemDropDownReplace = 3;
    }
    const now = Moment(new Date(),"DD/MM/YYYY");
    const messages = responseMessageSelected.messages;

    let childMessage = [];
    if(filterSelected === 'selectedStates'){
      childMessage = this.renderAction('selectedStates');
    } else if(filterSelected === 'selectedShow'){
      childMessage = this.renderAction('selectedShow');
    } else if(filterSelected === 'selectedSort'){
      childMessage = this.renderAction('selectedSort');
    }

    return (
      <div className="sent-messages-component">
        <NavigationHeader name="Sent"
                          addButton={false}
                          tab="sent"
                          onChangeTab={this.onChangeTab}
                          options={CUSTOMERS_BY_PAGE}
                          anchorEl={false}
                          itemsByPageQuantity={numberPagesShown}
                          handlePageChange={this.handlePagination}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          showItemPerPage={false}
                          newFinanceAppsCounter={newFinanceApplicationsCounter}
                          unreadMessagesCounter={unreadMessagesCounter}
        />

        <div className="message-body">
          <div className="message-container" id="message-container-finance" style={{minHeight: height}}>
            <div id="message-left" className="message-left" style={formsPages.length === 0 ?{width: "100%"}:{width: "100%"}}>
              <div className="row-message header-message-left" id="header-left-message">
                {
                  countMessageSelected ?
                    <div className="btn-container-dp btn-container-message" style={{left:23}}>
                      <p className="btn-message-selected">
                        {countMessageSelected}
                      </p>
                    </div> : null
                }
                <div className="btn-container-dp" style={{paddingLeft: 70}}>
                  {
                    (countMessageSelected > 0) &&
                    <button className="btn-message-apply" onClick={() => this.updateResponseMessage()}
                            style={showDropDownUndelete ? {
                              width: 120,
                              backgroundColor: "#FF6565"
                            } : {backgroundColor: "#FF6565", width: 90}}>
                      {
                        showDropDownUndelete ? "Undelete" :
                          <span>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="icon-delete"
                                  beforeInjection={svg => svg.classList.add("icon-delete-svg")}/>
                        Delete
                      </span>
                      }
                    </button>
                  }
                </div>
                <div id={(showPane)? "":"container-dp-show-messages"} className="btn-container-dp status-message">
                  <div id= "selectedShow" className="btn-general-message-dp" onClick={this.onChangeDropDownForm.bind(this, "selectedShow")} style={{width: 160}}>
                    {
                      dropDownSelect[1] !== "" ? <span>{dropDownSelect[1]}</span> : <span>All Messages</span>
                    }
                    {
                      filterSelected === "selectedShow" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                    }
                  </div>
                </div>
                <div id={(showPane)? "":"container-dp-sort-messages"} className="btn-container-dp sort-message">
                  <div id= "selectedSort" className="btn-general-message-dp" onClick={this.onChangeDropDownForm.bind(this,"selectedSort")} style={{width: 140}}>
                    {
                      dropDownSelect[2] !== "" ? <span>{dropDownSelect[2]}</span> : <span>Sort By</span>
                    }
                    {
                      filterSelected === "selectedSort" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                    }
                  </div>
                </div>
              </div>
              <div className="table-message" id="data-message-table">
                <table className="table">
                  <thead className="thread-class">
                  <tr>
                    <th className="head-check-box" id="check-head-message">
                      {
                        this.renderCheckBox(selectedAll, this.onChangeCheckAll.bind(this), "selectedAll", true,null)
                      }
                    </th>
                    <th className="th-20">To</th>
                    <th className="th-60" style={{paddingRight:11}}>Summary</th>
                    <th className={(!showPane)?"th-15":"th-10"}>Date</th>
                  </tr>
                  </thead>
                  <tbody className="tbody-class">
                  {
                    formsPages.length !== 0 ?
                      formsPages[currentPage - 1].map((message, index) => {
                        let createDate = Moment(new Date(message.createDate),"DD/MM/YYYY");
                        let dateFormat = "";
                        if(createDate.isSame(now,"day") && createDate.isSame(now,"month") && createDate.isSame(now,"year")){
                          dateFormat = Moment(new Date(message.createDate)).format("hh:mm A")
                        }else if (createDate.isSame(now,"month") && createDate.isSame(now,"year")){
                          let dateMonth = Moment(message.createDate).format('ll');
                          dateFormat = dateMonth.split(",")[0];
                        }else{
                          dateFormat = Moment(new Date(message.createDate)).format("L")
                        }
                        let style = {};
                        let selectedMessage = false;
                        if (message.id === responseMessageSelected.id) {
                          style.borderTop = "1px solid #EDF1FF";
                          style.borderBottom = "1px solid #EDF1FF";
                          style.borderRight = "1px solid white";
                          style.backgroundColor = "#EDF1FF";
                          style.fontWeight = "bold";
                        }else{
                          style.borderTop = "none";
                          style.borderBottom = "none";
                          style.borderRight = "1px solid rgb(238, 238, 242)";
                          style.backgroundColor = "transparent";
                          style.fontWeight = "normal";
                        }
                        if(idsSelected.indexOf(message.id) >= 0){
                          selectedMessage = true
                        }
                        return (
                          <tr key={index} className="row-message-table rowCostumer" style={style}>
                            <td className="row-icon-message">
                              <div className="icon-message" onClick={() => this.onChangeMessage(message, index)}>
                                <i id="messages-envelope-o" className="fa fa-paper-plane"/>
                              </div>
                              {
                                that.renderCheckBox(selectedMessage, that.onChangeCheck.bind(that, message.id), message.id,selectedMessage,{marginBottom:22} )
                              }
                            </td>
                            <td onClick={() => this.onChangeMessage(message, index)}>{message.listRecipients}</td>
                            <td onClick={() => this.onChangeMessage(message, index)} className={(!showPane)?"subject-text":"subject-text2"}>{textWithoutHTML(message.text ? message.text:"")}</td>
                            <td className={(showPane)?"col-dateFormat-message":""}
                                onClick={() => this.onChangeMessage(message, index)}>{dateFormat}</td>
                          </tr>
                        )
                      }):<tr/>
                  }
                  </tbody>
                </table>
                {
                  formsPages.length === 0 ? <div className="container-no-messages">
                    <div className="container-icon-no-messages">
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_info_24px.svg"} beforeInjection={ svg => svg.classList.add("icon-info")}/>
                    </div>

                    <div className="container-notify-no-messages">
                      <span className="notify-no-messages">No messages yet</span>
                    </div>
                  </div>: null
                }
              </div>
            </div>
            {
              (responseMessageSelected && showPane) ?
                <div id="message-details" className="message-details" ref={el => (this.componentRef = el)}>
                  <div className="message-details-header" style={{height: "auto"}}>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_x.svg"}
                              className="icon-delete-content" beforeInjection={ svg => svg.classList.add('icon-delete')} onClick={this.closePanel}/>
                    <div className="row-message header-message-detail" id="header-message-panel">
                      <div className="dot-message-user">
                        <label id="initial-name">{responseMessageSelected.listRecipients.replace(/\W*(\w)\w*/g, '$1').substring(0,2).toUpperCase()}</label>
                      </div>
                      <div className="col45">
                        <label className="label-message-user-sent" style={{width: (showOriginalSize)?"155px":"500px"}}>{responseMessageSelected.listRecipients}</label>
                        <label className="label-message-email">{responseMessageSelected.from}</label>
                      </div>
                      <div className="date">
                        <label className="label-message-date">
                          {Moment(responseMessageSelected.createDate).format('lll')}
                        </label>
                        <div className="icon-expand">
                          {
                            showOriginalSize ?
                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_zoom_out.svg"}
                                      beforeInjection={ svg => svg.classList.add("ic-icon-expand")} onClick={()=>this.onResize()} />
                                      :
                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_zoom_in.svg"}
                                        beforeInjection={ svg => svg.classList.add("ic-icon-zoom-in")} onClick={()=>this.onResize()} />
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row-message header-title-message" id="header-message-title-name">
                      <div className="title" id="title-name">
                        <h3 className="name-message-selected">{messages.length > 0 ? responseMessageSelected.messages[0].title:textWithoutHTML(responseMessageSelected.subject)}</h3>
                      </div>
                      <div className="options" id="options-fa-icons">
                        <div className="ic-delete">
                          <i id="id-fa-icon" className="fa fa-trash" onClick={()=>this.onDeleteMessage(responseMessageSelected)}/>
                        </div>
                        <div className="ic-print">
                          <ReactToPrint
                            trigger={() => <i id="id-fa-icon" className="fa fa-print" onClick='#'/>}
                            content={() => this.componentRef}
                            copyStyles={true}
                            pageStyle=".message-details {float: left; width: 100% !important; padding: 0 30px 30px;}"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.renderMessagesPane(responseMessageSelected)}
                </div>: null
            }
          </div>
        </div>
        {
          showPopover && filterSelected !== null ?
            <Popover anchorEl={anchorEl}
                     child={childMessage}
                     classPopover="popover-box-message"
                     handleRequestClose={this.handleRequestClose}/> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    newFinanceApplicationsCounter: state.newFinanceApplicationsCounter,
    unreadMessagesCounter: state.unreadMessagesCounter,
  }
};
export default connect(mapStateToProps, {})(MessageSent)