// @ts-ignore
import React, {memo, useState} from 'react'
// @ts-ignore
import {DPInput} from '../../../../admin/common/DPInput'
// @ts-ignore
import {DPInputElement} from '../../../../admin/common/DPInputElement'
// @ts-ignore
import DPButton from '../../../../admin/common/DPButton'
// @ts-ignore
import {cellphoneMask, optionState} from '../../../../common/Constants'
// @ts-ignore
import classnames from 'classnames'
import OpeningHour from "./OpeningHour";
// @ts-ignore
import GoogleMaps from '../../../../common/GoogleMaps'
import DPSelectDropDown from "../../../common/DPSelectionDropDown";
// @ts-ignore
import isEmpty from "lodash/isEmpty";
import {HourOperation, Location} from '../../../../common/models/Location'
require('./LocationUpdate.scss')

const MemoGoogleMaps = memo(GoogleMaps)

const  getErrorMessage = (name: string) =>  name + ' is required'

const getInvalidMessage = (name: string) => 'Please enter a valid ' + name

const getErrorText = (name: string, value: string) => {
  return isEmpty(value)
    ? getErrorMessage(name)
    : getInvalidMessage(name)
}

interface LocationProps {
  location: Location
  inputChange: (key: string, event: any) => void
  selectObject: (object: string,type: string) => void
  selectHour: (hour: string, key: string, index: number) => void
  addOpeningHour: () => void
  selectOptionInOpeningHour: (value: string, key: string, index: number) => void
  deleteOpeningHour: (index: number) => void
  saveAndUpdateLocation: () => void
  isNew: boolean
  errors: any
}

const LocationUpdate = (props: LocationProps) => {
  const {location, inputChange, selectObject, selectHour, addOpeningHour,
    selectOptionInOpeningHour, deleteOpeningHour, saveAndUpdateLocation, isNew, errors} = props

  const {name, address, city, zip, info, state} = location
  const {phoneNumber, email, hoursOfOperation= [] } = info
  const title = isNew ? 'New Location' : 'Editing location'

  return (
    <div className="update-location-container">
      <div className="left-box">
        <h3 className="general-title">{title}</h3>
        <span className="location-name">{name}</span>
        <div className="container-inputs">
          <DPInput
            title="Location name"
            value={name}
            className="name-input"
            onChange={(evt: any) => inputChange('name', evt)}
            errorMessage={errors["name"] ? getErrorMessage("Name") : null}
          />
          <DPInput
            title="Address"
            value={address}
            className="address-input"
            onChange={(evt: any) => inputChange('address', evt)}
            errorMessage={errors["address"] ? getErrorMessage("Address") : null}
          />
          <DPInput
            title="City"
            value={city}
            onChange={(evt: any) => inputChange('city', evt)}
            className="city-input"
            errorMessage={errors["city"] ? getErrorMessage("City") : null}
          />
          <DPSelectDropDown
            options={optionState}
            value={state}
            onClick={(id: string) => selectObject(id, 'state')}
            heightOfSelect={47}
            id="from-dropdown"
            label="State"
            errorMessage={errors["state"] ? getErrorMessage("State") : ""}
          />
          <DPInput
            title="ZIP Code"
            maxLength={5}
            value={zip}
            onChange={(evt: any) => inputChange('zip', evt)}
            className="zip-code-input"
            errorMessage={errors["zip"] ? getErrorText("zip code", zip) : null}
          />
          <DPInputElement
            title="Phone number"
            value={phoneNumber}
            mask={cellphoneMask}
            className="phone-number-input"
            onChange={(evt: any) => inputChange('phoneNumber', evt)}
            errorMessage={errors["phoneNumber"] ? getErrorText("Phone number", phoneNumber) : null}
          />
          <DPInput
            title="Email Address"
            value={email}
            onChange={(evt: any) => inputChange('email', evt)}
            errorMessage={errors["email"] ? getInvalidMessage("email address") : null}
          />
        </div>
      </div>

      <div className="right-box">
        <DPButton
          width='160px'
          heigth='47px'
          className="btn-save"
          onClick={saveAndUpdateLocation}
        >
          Save changes
        </DPButton>
        <div className="container-map">
          <MemoGoogleMaps
            locations={[location]}
            locationId={location.locationId ?? location.temporalId }
          />
        </div>

      </div>
      <div className="bottom-box">
      <span className="title-hours">
        Opening hours
      </span>
        {
          hoursOfOperation.map((item: HourOperation, index: number) => {
            return(
              <OpeningHour
                key={index}
                index={index}
                hourOperation={item}
                selectHour={selectHour}
                selectOption={selectOptionInOpeningHour}
                deleteOpeningHour={deleteOpeningHour}
              />
            )
          })
        }
      <DPButton
        width='306px'
        className="btn-new"
        onClick={addOpeningHour}
      >
        New time
      </DPButton>
    </div>
  </div>
)
}

export default LocationUpdate