import React, {useState} from 'react'
import {ReactSVG} from 'react-svg'
import PropTypes from 'prop-types'
import DPTabNavigationItem from './DPTabNavigationItem'
import {pathServer} from '../../../common/Constants'

require('./DPNavigationBarItem.scss')

const DPNavigationBarItem = (props) => {
  const {title, id, index, tabSelected, setTypeLink, tabs} = props
  const [showBody, setShowBody] = useState(false)

  return (
    <div id={id} key={id} className='container-navigation-item'>
      <div className="navigation-box" style={showBody ? {borderBottom: "none", borderRadius: '5px 5px 0 0'} : {}}
           onClick={() => {setShowBody(!showBody)}}>
        <div className="title-container">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_menu.svg"} className="icon-menu-content"
                    beforeInjection={ svg => svg.classList.add('icon-menu-svg')}/>
          <span className="title">{title}</span>
          <ReactSVG src={pathServer.PATH_IMG + `icon/${showBody ? 'ic_expand_less' : 'ic_expand_more'}.svg`} className="icon-arrow-content"
                    beforeInjection={ svg => svg.classList.add('icon-arrow')}/>
        </div>
      </div>
      {
        showBody &&
        <DPTabNavigationItem tabs={tabs}
                             tabSelected={tabSelected}
                             setTypeLink={setTypeLink}
                             index={index}
        />
      }

    </div>
  )
};

DPNavigationBarItem.defaultProps = {
  title: '',
  id: '',
  tabs: []
}

DPNavigationBarItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      classNameHeader:  PropTypes.string,
      className: PropTypes.string,
      component: PropTypes.object,
      optionsLink: PropTypes.array,
      value: PropTypes.string
    })
  )
}

export default DPNavigationBarItem