import React from 'react'
import {Modal} from 'react-bootstrap'
import ElementTargetTask from '../form/ElementTargetTask.jsx'
import ElementDragDropTask from '../form/ElementDragDropTask.jsx'
import {findIndex, cloneDeep} from 'lodash'

const {
  Component
} = React;

class ModalUploadImage extends Component {

  constructor(){
    super();
    this.state = {
      images: [],
      imagesInitial: [],
    }
  }
  componentWillReceiveProps(nextProps){
    let images = cloneDeep(nextProps.images);
    this.setState({images: nextProps.images, imagesInitial: images});
  }


  cancel = () => {
    this.setState({images: this.state.imagesInitial},()=>{
      this.props.onHide(this.state.imagesInitial);
    });
  }

  save = () => {
    this.props.onHide(this.state.images);
  }

  handleDropElement = (id, item, indexF) => {
    let {images} = this.state;
    let image = images[indexF];
    let indexI = findIndex(images, function (v) {
      return v.id === item.id
    });

    images[indexF] = item;
    images[indexI] = image;
    images.forEach((item, index) => {
      item.imageOrder = (index + 1);
    });
    this.setState({images: images})
  }

  render(){
    let {images,imagesInitial} = this.state;
    let statusList = [];
    if (images.length > 0) {
      images.forEach((image) => {
        statusList.push(image.id);
      });
    }

    return (
      <div className="modal fade"  >
        <Modal show={this.props.show} onHide={this.cancel} style={{width:'800px !important'}}>
          <Modal.Header closeButton>
            <Modal.Title>Order Images</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
            {images.map((image, index) => {
              const value = (
                <div style={{paddingTop:5}}>
                  <img src={image.url} width="130px" height="130px"/>
                </div>
              );
              return(
                <ElementTargetTask key={index}
                                   statusList={statusList}
                                   status={image.id}
                                   styleLine={{height: 140}}
                                   onDrop={(item) => this.handleDropElement(image.id, item, index)}>
                  <ElementDragDropTask status={image.id}
                                       form={image}
                                       value={value}
                  />
                </ElementTargetTask>
              )
            })}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.cancel}>Cancel</button>
            <button className="btn btn-primary" onClick={this.save}>Save</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const Container = (props) => {
  return(
    <ModalUploadImage>
      <ModalUploadImage {...props}/>
    </ModalUploadImage>
  )
};
export default Container;