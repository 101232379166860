import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {
  siteColorAdmin,
  SUPERUSERS_BY_PAGE,
  SUPERUSERS_BY_PAGE_BY_DEFAULT
} from '../../../Constants'
import {DPModalInfo} from '../../util/ComponentUtils'
import {loadSuperusers, updateSuperuserStatus} from '../../../Api'
import {cloneDeep, debounce} from 'lodash'
import ModalConfirmDeactivate from '../../util/ModalConfirmDeactivate.jsx'
import Moment from 'moment'
import DPSelectionDropDown from '../../../common/DPSelectionDropDown'
import DPIcon, {Icons} from '../../../common/DPIcon'
import DPTable, {DPAvatarImage, DPTableActions} from '../../../common/DPTable'
import AddEditModal from './AddEditModal'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'
import {DPPagination} from '../../../../common/DPPagination'
import DPPage from '../../../common/DPPage'

import './index.scss'

const ModalInfoSuperuser = ({superuser, showModalEdit}) => {
  const date = Moment(superuser.createDate).format("MMMM DD, YYYY");
  return(
      <div className="superuser-modal-body-container">
        <div className="superuser-modal-image">
          <img className="photo-superuser-img-circle" src={superuser.image ? superuser.image : pathServer.PATH_IMG + "ic_account_circle.png"}/>
        </div>
        <div className="superuser-modal-text">
          <div className="superuser-name">
            <label className="title">{superuser.firstName} {superuser.lastName}</label>
          </div>
          <div className="superuser-detail">
            <label className="subtitle">{superuser && superuser.email !== "" ? superuser.email : getMessageNotField("email")}</label>
            <label className="subtitle">{superuser && superuser.phoneNumber !== "" ? superuser.phoneNumber : getMessageNotField("phone")}</label>
            <label className="subtitle">{superuser && superuser.address !== "" ? superuser.address : getMessageNotField("address")}</label>
            <label className="subtitle">{`Super User since ${date}`}</label>
            <label className="subtitle-description">{superuser && superuser.description !== "" ? superuser.description : getMessageNotField("description")}</label>
          </div>
          <div className="superuser-modal-button">
            <button className="btn-edit" onClick={(evt) => showModalEdit(superuser,evt)}>
              Edit
            </button>
          </div>
        </div>
      </div>
  )
};

function getMessageNotField(field) {
  return <p style={{fontStyle:"italic", margin:0}}>{`No ${field} yet`}</p>
}

function getMessage(active){
  return active ?
    <div>
      <label>Do you want to deactivate this superuser?</label>
      <span>This action will block access and credentials until activated again.</span>
    </div>
    :
    <div>
      <label>Do you want to activate this superuser?</label>
      <span>This action will allow access and credentials to this user.</span>
    </div>
}

class SuperUsers extends Component{
  constructor(){
    super();
    this.changeKeyword = debounce(this.loadSuperusers,500);
    this.state = {
      showOptions: false,
      itemsByPageQuantity: SUPERUSERS_BY_PAGE_BY_DEFAULT,
      currentPage: 1,
      superusers: [],
      isLoading: false,
      indexUserSelected: null,
      anchorEl: null,
      query:{},
      quantitySuperusers: 0,
      currentLetter:'',
      showAddSuperuser: false,
      showInactive: false,
      showModalActive: false,
      statusUserSelected : null,
      userSelected: null,
      showModalInfo: false,
      showModalEdit: false,
      isLoadingEdit : false,
      keyword: null
    };
  }

  componentDidMount() {
    this.loadSuperusers();
  }

  static getDerivedStateFromProps(props, state) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props.keyword !== state.keyword) {
      return {
        keyword: props.keyword,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidUpdate(prevProps, prevState){
    const {keyword, isLoading} = this.state
    if (prevProps.keyword !== keyword && isLoading === false) {
      this.changeKeyword();
    }
  }

  loadSuperusers = () => {
    this.setState({isLoading: true})
    const {itemsByPageQuantity, keyword, showInactive} = this.state
    loadSuperusers(0, itemsByPageQuantity, keyword, showInactive).then( response => {
      const {responseCode, data} = response;
      if(responseCode === SUCCESSFUL_CODE){
        const {superusers, quantitySuperusers} = data
        this.setState({
          superusers,
          quantitySuperusers,
          showModalActive: false,
          isLoading: false,
          currentPage:1
        })
      }else{
        this.setState({showModalActive: false, isLoading: false});
      }
    })
  }

  handlePageChange = (nextPage,totalPages) => {
    let {itemsByPageQuantity,query, quantitySuperusers, showInactive} = this.state;
    if(nextPage >= 0){
      let currentPage = nextPage;
      if(currentPage > totalPages) {
        currentPage = 1;
        query.from = 0;
      }else if(currentPage === 0){
        currentPage = totalPages;
        query.from = itemsByPageQuantity*(totalPages - 1);
      }else{
        if(quantitySuperusers !== 0)
          query.from = itemsByPageQuantity*(currentPage - 1);
      }
      query.size = itemsByPageQuantity;
      this.setState({isLoading: true});
      loadSuperusers(query.from, query.size, "",showInactive).then( (response)=>{
        const {responseCode, data} = response
        if(responseCode === SUCCESSFUL_CODE){
          const {superusers, quantitySuperusers} = data
          this.setState({superusers,quantitySuperusers , currentPage, isLoading: false})
        }
      });
    }
  }

  handleItemsByPage = (itemsByPageQuantity) => {
    let {showInactive} = this.state;
    this.setState({isLoading: true});
    loadSuperusers(0, itemsByPageQuantity, "", showInactive).then( (response)=> {
      const {responseCode, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {superusers, quantitySuperusers} = data
        this.setState({showOptions:false,superusers,quantitySuperusers ,itemsByPageQuantity, currentPage: 1, isLoading: false})
      }
    });
  }

  showAddSuperUserPopover = () => {
    let {showAddSuperuser} = this.state;
    this.setState({showAddSuperuser: !showAddSuperuser});
  }

  showModalInfo = (superuser,event) => {
    event.stopPropagation();
    this.setState({showModalInfo:true, userSelected: superuser})
  }

  closeModalInfo = () => {
    this.setState({showModalInfo:false})
  }

  showInactive = (evt) => {
    const {itemsByPageQuantity} = this.state;
    let {showInactive} = this.state;
    if(!showInactive){
      showInactive = !showInactive;
    } else {
      showInactive = !showInactive;
    }

    this.setState({isLoading: true});
    loadSuperusers(0,itemsByPageQuantity,"",showInactive).then((response) => {
      const {responseCode, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {superusers, quantitySuperusers} = data
        this.setState({showInactive, superusers, quantitySuperusers, isLoading: false, currentPage: 1});
      }else{
        console.log(error);
        this.setState({isLoading: false});
      }
    })
  }

  showModalActive = (user,event) => {
    event.stopPropagation();
    this.setState({showModalActive: true,userSelected:user, showModalInfo: false})
  }

  hideModal = () => {
    this.setState({showModalActive: false, showModalEdit: false})
  }

  changeStatusSuperuser(){
    const {userSelected} = this.state;
    updateSuperuserStatus(userSelected.id,!userSelected.active).then(response =>{
      const {responseCode} = response;
      if(responseCode === SUCCESSFUL_CODE) {
        this.loadSuperusers()
      }
    })
  }

  showModalEdit = (user,event) => {
    event.stopPropagation();
    const userSelected = cloneDeep(user);
    this.setState({showModalEdit:true, showModalInfo: false, userSelected})
  }

  render() {
    const {height, left} = this.props;
    const {itemsByPageQuantity, currentPage, superusers,
      quantitySuperusers, showAddSuperuser, isLoading,
      showInactive,  showModalActive, userSelected, showModalInfo, showModalEdit} = this.state;
    const totalPages = quantitySuperusers ? Math.ceil(quantitySuperusers/itemsByPageQuantity) : 1;
    return (
      <>
        <DPPage
          className='superusers-page'
          title='Super Users'
          leftHeader={
          <>
            <DPSelectionDropDown
              style={{width: 160}}
              placeholder={'Sort by'}
              id={'superusers-sort-by'}
              options={[]}
              value={''}
              onClick={id => console.log('clicking')}
            />

            <div className="superusers-button-add">
              {
                !showAddSuperuser ?
                  <ReactSVG onClick={this.showAddSuperUserPopover} src={pathServer.PATH_IMG + "icon/ic_circle.svg"} beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                                    className="icon-add-superuser" data-testid={'icon-add-superuser'}/> :
                  <div onClick={this.showAddSuperUserPopover} className="close-popover-superuser" style={{backgroundColor:"#ff6564"}}>
                    <span>x</span></div>
              }
            </div>
          </>
          }
          rightHeader={
          <>
            <button id='inactive' className={showInactive ? "active":"inactive"} onClick={this.showInactive}>
              {/*<ReactSVG key='inactive' className='icon-inactive' id="imgInactive" src={pathServer.PATH_IMG + "icon/ic_user_inactive.svg"} />*/}
              {/*refactor*/}
              <DPIcon icon={Icons.INACTIVE_USER} className='icon-inactive' iconClasses={showInactive ? "svg-inactive" : null}/>
              {!showInactive ?
                <label >Show Inactives </label>:
                <label >Hide Inactives</label>}
            </button>

            {/*<DPButtonIcon*/}
            {/*  background={ showInactive ? '#ff6565': null}*/}
            {/*  iconSendPath={'ic_user_inactive.svg'}*/}
            {/*  btnSaveText={!showInactive ? 'Show Inactives' : 'Hide Inactives'}*/}
            {/*  textColor={ !showInactive ? '#ff6565' :'white'}*/}
            {/*  borderColor='#d7dae2'*/}
            {/*  onClick={this.showInactive}*/}
            {/*/>*/}

            <DPPagination
              optionsQuantityByPage={SUPERUSERS_BY_PAGE}
              selectedItemsByPage={itemsByPageQuantity}
              handleChangeItemsByPage={this.handleItemsByPage}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={this.handlePageChange}
              siteColor={siteColorAdmin}
            />

          </>
          }
        >
          <DPTable
            isLoading={isLoading}
            data={superusers}
            config={
              {
                columns: [
                  {id: 'photo', label: 'Photo', style: { width: '10%'}},
                  {id: 'username', label: 'Username', style: { width: '15%'}},
                  {id: 'email', label: 'Email', style: { width: '20%'}},
                  {id: 'phone', label: 'Phone number', style: { width: '12%'}},
                  {id: 'badge'},
                  {id: 'actions'}
                ]
              }
            }
            onRowClick={(user,event) => this.showModalInfo(user, event)}
            renderColumn={
              (user,columnConf) => {
                const {firstName, lastName, email, phoneNumber, image, active} = user;
                switch (columnConf.id) {
                  case 'photo':
                    return (
                      <DPAvatarImage image={image}/>
                    )
                  case 'username':
                    return `${firstName} ${lastName}`
                  case 'email':
                    return email
                  case 'phone':
                    return phoneNumber
                  case 'badge':
                    return !active ? <DPIcon icon={Icons.INACTIVE_BADGE}/> : null
                  case 'actions':
                    return (
                      <DPTableActions>
                        <DPIcon
                          icon={Icons.EDIT}
                          onClick={(event) => this.showModalEdit(user, event)}
                        />
                        {!active ? (
                          <DPIcon
                            icon={Icons.ACTIVE_USER}
                            onClick={(event) => this.showModalActive(user, event)}
                          />
                        ) : (
                          <DPIcon
                            icon={Icons.INACTIVE_USER}
                            onClick={(event) => this.showModalActive(user, event)}
                          />
                        )}
                        {!active ? (
                          <DPIcon
                            icon={Icons.TRASH}
                          />) : null
                        }
                      </DPTableActions>
                    )
                }
              }
            }
          />
        </DPPage>
        {
          showModalInfo ?
              <DPModalInfo showModal={showModalInfo} closeModalInfo={this.closeModalInfo}
                           body={<ModalInfoSuperuser superuser={userSelected} showModalEdit={this.showModalEdit}/>} />:
              null
        }
        {
          showModalActive ?
              <ModalConfirmDeactivate show={showModalActive}
                                      onSubmit={this.changeStatusSuperuser.bind(this,userSelected)}
                                      onHide={this.hideModal}
                                      showButton={true}
                                      bodyMessage={getMessage(userSelected.active)}
                                      isBodyCustom={true}
                                      classButtonDelete="btn-delete"
                                      buttonLabelDelete={ userSelected.active ? "Yes, deactivate." : "Yes, activate."}
                                      viewMobile={false}
                                      iconQuestion={ !userSelected.active ? "icon/ic_question_warning_activate.svg" : null}
                                      showAnotherMessage = {userSelected.active}
                                      anotherMessage = "Delete superuser permanently"
              /> : null
        }
        {
          showModalEdit || showAddSuperuser ?
                <AddEditModal
                  selectedUser={showModalEdit ? userSelected : undefined}
                  onSaveUser={ () => {
                    this.loadSuperusers()
                    this.setState({showModalEdit: false, showAddSuperuser: false, showModalActive: false})
                  }}

                  show={showModalEdit  || showAddSuperuser}

                  onHideModal={()=> this.setState({showModalEdit: false, showAddSuperuser: false, showModalActive: false})}
                />
               : null
        }
      </>
    );
  }
}

export default SuperUsers;
