import React from 'react'
import PropTypes from 'prop-types'
import {pathServer} from '../../../common/Constants'
import {ReactSVG} from 'react-svg'
import {DPInput} from '../DPInput'
import {Popover} from '../../newComponents/util/ComponentUtils'
import {PopoverIconsBody} from '../../newComponents/setting/template/ThemeSettingContainer'
import {
  FOOTER_ICON_NAMES,
} from '../../Constants'
import get from 'lodash/get'

require('./DPFooterItem.scss')
/****These props must come from props  ****/
const defaultValues = [{title: 'Sales: 801-380-9427', content: '801-380-9427'}, {title: 'Service: 801-380-9427', content: '801-380-9427' }]

const MAX_SIZE_TITLE = 30

const DPFooterItem2 = ({id, columnIdSelected, indexIdSelected, addItem, onChangeInput, onChangeInputItem, onChangeInputLinkItem, showPopUpOver, showPopUp, anchorEl, onChangeInputPopUp, setTypeLink, deleteItem, ...props}) => {

  const selectIcon = (icon, event, index) => {
    event.preventDefault();
    let type = "text"
    if(icon === "ic_phone.svg"){
      type = "phone"
    }else if(icon === "ic_email.svg"){
      type = "email"
    }else if(icon === "ic_link.svg"){
      type = "link"
    }
    setTypeLink(columnIdSelected, indexIdSelected, type, icon)
  }

  const {text, subText, column = {}} = props
  const items = get(column,['items'],[])
  return(
    <div key={id} className="container-footer-item">
      <DPInput
        className='container-input-title'
        name={'title'}
        id='title-column'
        title={text}
        onChange={(e) => onChangeInput(id, 'title', e)}
        value={column.title ?? ''}
        placeholder={'Type a column title here'}
        maxLength={MAX_SIZE_TITLE}
        rightElement={<label className={`label-size right ${(column.title ?? '').length === MAX_SIZE_TITLE ? 'error-color' : ''}`}>{`${(column.title ?? '').length}/${MAX_SIZE_TITLE}`}</label>}
        disable={props.isLoadingSpinnerOnMainPage}
      />
      <div className="body-footer-items">
        {
          items.map((item, index) => {
            return(
              <div key={index} className="container-line">
                <div className="row-value">
                  <DPInput
                    className='section-value'
                    id={id}
                    title={`${subText} - line ${index + 1}`}
                    onChange={(e) => onChangeInputItem(id, index, e)}
                    value={item.value || ""}
                    maxLength={MAX_SIZE_TITLE}
                    rightElement={<label className={`label-size right ${(item.value ?? '').length === MAX_SIZE_TITLE ? 'error-color' : ''}`}>{`${(item.value ?? '').length}/${MAX_SIZE_TITLE}`}</label>}
                    disable={props.isLoadingSpinnerOnMainPage}
                  />
                  <div className="container-select-icon">
                    <div id={`${id}-${index}`}  className="section-icon" onClick={props.isLoadingSpinnerOnMainPage ? null : () => showPopUpOver(id, index)}>
                      <ReactSVG src={pathServer.PATH_IMG+"icon/" + item?.icon} className="icon-menu-content" beforeInjection={ svg => svg.classList.add('icon-menu')}/>
                      <i className="fa fa-chevron-down" />
                    </div>
                  </div>
                  <div className="container-delete">
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="content-delete-icon"
                              beforeInjection={ svg => svg.classList.add('delete-icon')} onClick={props.isLoadingSpinnerOnMainPage ? null : ()=> deleteItem(id,index)}/>
                  </div>
                </div>
                {
                  item.link.type !== "text" &&
                    <div className="container-link-value">
                      <div className="link-value-icon">
                        <ReactSVG src={pathServer.PATH_IMG+"icon/" + item?.icon} className="icon-menu-content" beforeInjection={ svg => svg.classList.add('icon-menu')}/>
                      </div>
                      <DPInput
                        className="link-value-input"
                        id={id}
                        onChange={(e) => onChangeInputLinkItem(id, index, e)}
                        value={item.link.value || ""}
                        disable={item.value === ''}
                      />
                    </div>
                }
                {
                  showPopUp &&
                    <Popover anchorEl={anchorEl}
                             width="148"
                             child={<PopoverIconsBody icons={FOOTER_ICON_NAMES}
                                                      selectIcon={selectIcon}
                                                      classname="footer-icons-container"
                                                      index={index}
                             />}
                             classPopover="popover-box-category"
                             handleRequestClose={() => showPopUpOver(id, index)}
                    />
                }
              </div>
            )
          })
        }
      </div>
      { items.length < 3 && <span className="add-line" onClick={()=> addItem(id)}>Add a line</span>}
    </div>
  )
}

DPFooterItem2.defaultPropTypes = {
  text: '',
  title: ''
}

DPFooterItem2.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string
}

export default DPFooterItem2;