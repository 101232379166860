import React, {useState, useRef} from 'react'
import NavigationBar from "../components/navigationBar/NavigationBar";
import IntroBannerSection from "../components/introBannerSection/IntroBannerSection";
import FooterSection from "../components/footer/FooterSection";
import {pathServer} from "../../../../common/Constants";
import GDGButton, {BUTTON_TYPE} from '../components/GDGButton'
import SplitSection from '../components/SplitSection'

require('../homepage.scss')

export default function WebsiteBuilder() {
  window.scrollTo(0, 0)
  const navigationBar = useRef();

  const demoRequest = () => {
    if(navigationBar && navigationBar.current)
      navigationBar.current.handleRequestDemo()
  }

  return (
    <div id='gdg-home-page' className='website-builder inventory-management'>
      <NavigationBar ref={navigationBar}/>
      <IntroBannerSection
        blueText={'INVENTORY MANAGEMENT'}
        title={"Inventory tracking remastered"}
        description={"We work hard so you don't have to. We integrate with major social media and " +
          "content platforms so you can manage all within GoDealerGo. Simply log into your " +
          "accounts and our integrations will push content, descriptions, pricing, updates " +
          "and more automatically."}
        imgUrl={pathServer.PATH_IMG + '/newHomePage/inventory-management-intro.png'}
      />

      <SplitSection
        className={'inventory-management-application after-intro-section-padding extra-padding'}
        leftSection={
          <div>
            <p className={'title-font'} dangerouslySetInnerHTML={{
              __html: 'You’ve got the power'
            }}/>
            <p className={'regular-font'} dangerouslySetInnerHTML={{
              __html: 'Your settings dictate what inventory is posted on different platforms. Add ' +
                'the visuals and the information and we’ll make sure your inventory is ' +
                'viewed in all major platforms so your vehicles get the most visits.'
            }}/>
            <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
          </div>
        }
        rightSection={
          <div>
            <img alt={'img'} src={pathServer.PATH_IMG + '/newHomePage/inventory-management-product.png'}/>
          </div>
        }

      />

      <FooterSection/>

    </div>
  )
}
