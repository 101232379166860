import React, {Component} from 'react'
import {
  APPLICANTS_ACTIONS,
  MESSAGE_UNDELETE_ACTIONS_FINANCE,
  SHOW_APPLICANTS,
  SORT_APPLICANTS,
  NUMBER_PAGES_SHOWN,
  CUSTOMERS_BY_PAGE
} from '../../Constants'
import {browserHistory} from 'react-router'
import {updateStateFinances} from '../../Api'
import {ReactSVG} from 'react-svg'
import {filter, sortBy} from 'lodash/collection'
import {loadFinances} from '../../actions'
import {connect} from 'react-redux'
import Moment from 'moment'
import find from 'lodash/find.js'
import NavigationHeader from '../util/NavigationHeader.jsx'
import {Popover} from '../util/ComponentUtils'
import {getDisplayValue} from '../sale/SaleFinances'
import {getPosition} from '../../../common/Util'
import { pathServer, SUCCESSFUL_CODE } from '../../../common/Constants'

function drawIconApplicant(status) {
  switch (status) {
    case 'New':
      return (<div className="icon-message">
        <i className="fa fa-envelope"/>
      </div>);
    case 'In Progress':
      return (<div className="icon-message">
        <i className="fa fa-clock"/>
      </div>);
    case 'Processed':
      return (<div className="dot-message">
        <i className="fa fa-check i-check"/>
      </div>);
    case 'Bad Lead':
      return (<div className="icon-message">
        <i className="fa fa-pause"/>
      </div>);
    default:
      return (<div className="icon-message">
        <i className="fa fa-phone"/>
      </div>);
  }
}

const CheckBox = ({value, onChange, id, isSelected, styleCheck}) => {
  return (
    <label key={id} className="container-check" style={styleCheck ? styleCheck : null}>
      <input type="checkbox"
             checked={value}
             onChange={(evt) => onChange(id,evt)}/>
      <span className="checkmark" style={isSelected ? {border: "1.4px #4695d4 solid"} : null}/>
      {
        value ? <i className="fa fa-check i-check"/> : null
      }
    </label>
  )
}

class Finance extends Component {

  constructor() {
    super();

    this.state = {
      imageTrash: pathServer.PATH_IMG + "deleteCustomer.png",
      imageView: pathServer.PATH_IMG + "viewCustomer.png",
      totalPages: 10,
      numberPagesShown: NUMBER_PAGES_SHOWN,
      currentPage: 1,
      selectedAll: false,
      idsSelected: [],
      financesAll: [],
      finances: [],
      financeSelected: null,
      countFinanceSelected: null,
      showDropDownUndelete: false,
      dropDownSelect: ["deleted", "all", "newest", "undelete"],
      showViewApplicant: false,
      dropDownChangeStates: APPLICANTS_ACTIONS,
      dropDownUndelete: {
        render: false,
        values: MESSAGE_UNDELETE_ACTIONS_FINANCE,
      },
      dropDownShowApplicants: SHOW_APPLICANTS,
      dropDownSortApplicants: SORT_APPLICANTS,
      showModal: false,
      formsPages: [],
      showOriginalSize: true,
      finance: {},
      isLoading: false,
      showPopover: false,
      filterSelected: null
    }
  }

  componentDidMount() {
    this.setState({isLoading: true});
    this.props.loadFinances().then((response) => {
      const finances = response.finances.reverse();  //Reverse to show the latest finances first
      const users = response.users;
      this.setState({finances: finances, financesAll: finances, users: users, isLoading: false}, () => {
        this.onShowSelected();
      })
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      this.findByKeyWord(this.props.search);
    }
  }

  findByKeyWord(word) {
    if (word && word !== "") {
      this.filterFinances(word);
    } else {
      this.onShowSelected();
    }
  }

  filterFinances(word) {
    let {finances} = this.state;
    let financesEdit = finances;
    let newFinances = [];

    financesEdit.find((finance) => {
      let dateString = Moment(finance.createDate).format('LLL').toLowerCase() + " " + Moment(finance.createDate).format('L');
      let salesman = (finance.salesman) ? finance.salesman.firstName.toLowerCase() + " " + finance.salesman.lastName.toLowerCase() : "";
      if (finance.applicant.toLowerCase().indexOf(word.toLowerCase()) !== -1 || finance.financeType.toLowerCase().indexOf(word.toLowerCase()) !== -1 || dateString.indexOf(word.toLowerCase()) !== -1 || salesman.indexOf(word.toLowerCase()) !== -1) {
        newFinances.push(finance);
      }
    });
    this.buildPages(newFinances); // Send to build page by showing
  }

  buildPages = (data) => {
    let totalPages;
    let formsPages = [];
    if (data.length !== 0) {
      let page = [];
      totalPages = data.length / NUMBER_PAGES_SHOWN;
      if (data.length % NUMBER_PAGES_SHOWN !== 0)
        totalPages++;
      totalPages = Math.floor(totalPages);
      data.map((item, index) => {
        page.push(item);
        if ((index !== 0 && (index + 1) % NUMBER_PAGES_SHOWN === 0) || (index === (data.length - 1))) {
          formsPages.push(page);
          page = [];
        }
      });
    } else {
      totalPages = 0;
    }
    this.setState({formsPages: formsPages, totalPages: totalPages});
  }

  handlePagination = (number) => {
    this.setState({currentPage: number})
  }

  onChangeDropDownForm = (dropDown) => {
    let filterSelected = dropDown;
    let target = document.getElementById(dropDown);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };

    this.setState({showPopover:true, anchorEl: anchorEl, filterSelected: filterSelected})
  }

  onSelectDropDown(indexArraySelected, value) {
    let {dropDownSelect, currentPage} = this.state;
    if(dropDownSelect[indexArraySelected] !== value){
      currentPage = 1
    }
    dropDownSelect[indexArraySelected] = value;
    this.setState({dropDownSelect: dropDownSelect, showPopover: false, filterSelected: null, currentPage: currentPage});
  }

  onShowSelected = () => {
    let {finances, dropDownSelect, dropDownShowApplicants, dropDownSortApplicants, showDropDownUndelete} = this.state;
    let financesEdit = finances;
    let newFinances = [];
    let that = this;

    dropDownShowApplicants.forEach((show,index) => {
      if(show.id === dropDownSelect[1]){
        (index === 6) ? showDropDownUndelete = true : showDropDownUndelete = false;
        financesEdit.forEach((finance) => { //Iterate for messages
          if ((finance.active && (index === 0 ||
            (index === 1 && (finance.status === "New" || finance.status === 'new')) ||
            (index === 2 && (finance.status === "In Progress" || finances.status === 'inProgress')) ||
            (index === 3 && (finance.status === "Processed" || finance.status === 'processed')) ||
            (index === 4 && (finance.status === "Bad Lead" || finance.status === 'bad')) ||
            (index === 5 && (finance.status === "Review Later" || finance.status === 'review'))))
            || (index === 6 && !(finance.active))) { //Show finance in accordance with option and finances states
            newFinances.push(finance);
          }
        });
      }
    });

    dropDownSortApplicants.forEach((sort, index) => {
      if (sort.id === dropDownSelect[2]) {
        newFinances = that.onSortFinance(newFinances, index);
      }
    });

    this.setState({selectedAll: false,idsSelected: [],countFinanceSelected: null,showDropDownUndelete: showDropDownUndelete});
    this.buildPages(newFinances); // Send to build page by showing
  }

  onChangeCheckAll = () => {
    let {formsPages, currentPage, idsSelected, selectedAll} = this.state;
    let countFinanceSelected = 0;
    if (!selectedAll) {
      formsPages[currentPage - 1].forEach(finance => {
        idsSelected.push(finance.id);
        countFinanceSelected++;
      });
    } else {
      idsSelected = [];
    }
    this.setState({
      selectedAll: !this.state.selectedAll,
      idsSelected: idsSelected,
      countFinanceSelected: countFinanceSelected
    })
  }
  onChangeCheck = (id, event) =>  {
    event.stopPropagation();
    let {idsSelected} = this.state;
    if (idsSelected.indexOf(id) === -1) {
      idsSelected.push(id);
    } else {
      idsSelected.splice(idsSelected.indexOf(id), 1);
    }
    let countFinanceSelected = idsSelected.length;
    this.setState({idsSelected: idsSelected, countFinanceSelected: countFinanceSelected});
  }

  onDeleteFinance = (finance) => {
    let {finances} = this.state;
    let financesEdit = finances;
    financesEdit[(finance.id - 1)].active = false;
    this.setState({finances: financesEdit}, () => {
      this.onShowSelected();
    });
  }

  updateFinances = () => {
    let {finances, financesAll, dropDownSelect, idsSelected, showDropDownUndelete} = this.state;
    let listFinances = filter(finances, function (finance) {
      return idsSelected.indexOf(finance.id) >= 0
    });
    if (listFinances) {
      if (showDropDownUndelete) {
        listFinances = filter(financesAll, function (finance) {
          return idsSelected.indexOf(finance.id) >= 0
        });
        listFinances.forEach(finance => {
          finance.active = true;
        });
      } else {
        if (dropDownSelect[0] === "deleted") {
          listFinances.forEach(finance => {
            finance.active = false;
          });
        } else {
          const newStatus = APPLICANTS_ACTIONS.find(x => x.id === dropDownSelect[0])
          listFinances.forEach(finance => {
            finance.status = newStatus.value
          });
        }
      }
    }
    updateStateFinances({updateFinances: listFinances}).then(({ responseCode, responseMessage }) => {
      if(responseCode === SUCCESSFUL_CODE){
        this.setState({finances: this.props.finances, idsSelected: [], countFinanceSelected: null}, () => {
          this.onShowSelected()
        })
      }else
        console.error(responseMessage)
    });
  }

  onSortFinance = (newFinances, index) => {
    let dataSort = sortBy(newFinances, [function (o) {
      return o[(index === 0) ? "id" : "createDate"];
    }]);
    if (index === 1) {
      dataSort.reverse();
    }
    return dataSort;
  }

  onChangeTab = (id) => {
    if (id === "id") {
      browserHistory.push({pathname: "/main/message/finance", state: {showMessage: false}});
    } else if (id === "sent") {
      browserHistory.push({pathname: "/main/message/sent", state: {showMessage: true}});
    } else {
      browserHistory.push({pathname: "/main/message", state: {showMessage: true}});
    }
  }

  onChangeViewApplicant = (applicant) => {
    let {finances} = this.state;
    let financeEdit = finances;
    let financesUpdate = [];
    financeEdit.forEach(function (row) {
      if (row.id === applicant.id) {
        row.status = applicant.status;
        row.salesmanId = applicant.salesmanId;
        row.active = applicant.active;
      }
    });
    financesUpdate.push(applicant);
    updateStateFinances({updateFinances: financesUpdate}).then(({ responseCode, responseMessage }) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({financeSelected: applicant, finances: financeEdit}, () => {
          this.onShowSelected()
        })
      }else
        console.error(responseMessage)
    });
  }

  viewApplicant = (finance,evt) => {
    evt.stopPropagation();
    browserHistory.push({pathname: `/main/message/finance/${finance.id}`});
  }

  closePopover = (dropDown) => {
    let dropDownClose = this.state[dropDown];
    dropDownClose.render = false;
    this.setState({[dropDown]: dropDownClose})
  }

  addNewFinanceApplication(){
    browserHistory.push({pathname:'/main/message/finance/new'})
  }

  renderAction(id){
    const{dropDownChangeStates,dropDownShowApplicants,dropDownSortApplicants,dropDownSelect,showDropDownUndelete} = this.state;
    let indexSelected = 0;
    let values = [];
    let option = [];
    let selectedItem = "";

    switch (id){
      case 'selectedStates':
        indexSelected = 0;
        if(showDropDownUndelete){
          values = MESSAGE_UNDELETE_ACTIONS_FINANCE
        } else {
          values = dropDownChangeStates;
        }
        selectedItem = dropDownSelect[0];
        break;
      case 'selectedShow':
        indexSelected = 1;
        values = dropDownShowApplicants;
        selectedItem = dropDownSelect[1];
        break;
      case 'selectedSort':
        indexSelected = 2;
        values = dropDownSortApplicants;
        selectedItem = dropDownSelect[2];
        break;
    }

    values.forEach((action,index) => {
      if(action.id === selectedItem){
        option.push(
          <li key={index} className="item-selected"
              onClick={() => this.onSelectDropDown(indexSelected,action.id)}>
            {action.value}
          </li>
        )
      } else{
        option.push(
          <li key={index} className="change-submenu-item"
              onClick={() => this.onSelectDropDown(indexSelected,action.id)}>
            {action.value}
          </li>
        )
      }

    });

    let dataPopover = "";

    if (id === 'selectedStates'){
      dataPopover = <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {option}
          </u>
        </div>
      </div>
    } else {
      dataPopover = <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u onClick={() => this.onShowSelected()}>
            {option}
          </u>
        </div>
      </div>
    }

    return(
      dataPopover
    )
  }

  handleRequestClose = () => {
    this.setState({showPopover: false,filterSelected: null});
  }

  render() {
    const {
      totalPages, currentPage, selectedAll, idsSelected,countFinanceSelected, formsPages, dropDownSelect, showDropDownUndelete,
      users, isLoading, showOptions, numberPagesShown, showPopover, filterSelected, anchorEl} = this.state;
    const {height, newFinanceApplicationsCounter, unreadMessagesCounter} = this.props;
    let that = this;
    let itemDropDownReplace = 0;
    let selectedApply =  APPLICANTS_ACTIONS.find(x => x.id === dropDownSelect[itemDropDownReplace])
    if (showDropDownUndelete) {
      itemDropDownReplace = 3;
      selectedApply =  MESSAGE_UNDELETE_ACTIONS_FINANCE.find(x => x.id === dropDownSelect[itemDropDownReplace])
    }

    let childMessage = [];
    if(filterSelected === 'selectedStates'){
      childMessage = this.renderAction('selectedStates');
    } else if(filterSelected === 'selectedShow'){
      childMessage = this.renderAction('selectedShow');
    } else if(filterSelected === 'selectedSort'){
      childMessage = this.renderAction('selectedSort');
    }

    const selectedStatus =  SHOW_APPLICANTS.find(x => x.id === dropDownSelect[1])
    const selectedSort =  SORT_APPLICANTS.find(x => x.id === dropDownSelect[2])

    return (
      <div className="finance-wrapper">
        <NavigationHeader name="Finance Applications"
                          addButton={true}
                          add={this.addNewFinanceApplication}
                          tab="finance"
                          onChangeTab={this.onChangeTab}
                          primaryColor={'#A4AFB7'}
                          showOptions={showOptions}
                          options={CUSTOMERS_BY_PAGE}
                          anchorEl={false}
                          itemsByPageQuantity={numberPagesShown}
                          handlePageChange={this.handlePagination}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          showItemPerPage={false}
                          newFinanceAppsCounter={newFinanceApplicationsCounter}
                          unreadMessagesCounter={unreadMessagesCounter}
        />
        <div className="message-body">
          {
            <div className="message-container" style={{minHeight: height}}>
              <div id="message-left" className="message-left"
                   style={formsPages.length === 0 ? {width: "100%"} : {width: "100%"}}>
                <div className="row-message header-message-left">
                  {
                    countFinanceSelected ?
                      <div className="btn-container-dp btn-container-message" style={{left: 23}}>
                        <p className="btn-message-selected">
                          {countFinanceSelected}
                        </p>
                      </div> : null
                  }
                  <div className="btn-container-dp-message" style={{paddingLeft: 70}}>
                    <div id= "selectedStates" className="btn-general-message-dp" onClick={!showDropDownUndelete ? this.onChangeDropDownForm.bind(this,"selectedStates") : ()=> {}} style={{width: 120}}>
                      {
                        selectedApply ?
                          <span>{selectedApply.value}</span> :
                          <span>Delete</span>
                      }
                      {
                        filterSelected === "selectedStates" ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                      }
                    </div>
                  </div>
                  <div className="btn-container-dp">
                    <button className="btn-message-apply" onClick={() => this.updateFinances()}>
                      Apply
                    </button>
                  </div>
                  <div className="container-dp-show-finance">
                    <div id= "selectedShow" className="btn-general-message-dp" onClick={this.onChangeDropDownForm.bind(this,"selectedShow")} style={{width: 186}}>
                      {
                        selectedStatus ? <span>{selectedStatus.value}</span> : <span>All Applicants</span>
                      }
                      {
                        filterSelected === "selectedShow" ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                      }
                    </div>
                  </div>
                  <div className="container-dp-sort-finance">
                    <div id= "selectedSort" className="btn-general-message-dp" onClick={this.onChangeDropDownForm.bind(this,"selectedSort")} style={{width: 150}}>
                      {
                        selectedSort !== "" ? <span>{selectedSort.value}</span> : <span>Sort By</span>
                      }
                      {
                        filterSelected === "selectedSort" ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                      }
                    </div>
                  </div>
                </div>
                {
                  isLoading ?
                    <div className="loader-container">
                      <div className="loader"/>
                    </div> :
                    <div id="table-finance" className="table-message">
                      <table className="table">
                        <thead className="thread-class">
                        <tr>
                          <th id="check-head-message" className="head-check-box">
                            <CheckBox value={selectedAll} isSelected={true} onChange={this.onChangeCheckAll} id={"selectedAll"} styleCheck={null}/>
                          </th>
                          <th className="th-20">Applicant</th>
                          <th className="th-20">Type of Application</th>
                          <th className="th-20">Salesman</th>
                          <th className="th-20">Status</th>
                          <th className="th-10">Date</th>
                        </tr>
                        </thead>
                        <tbody className="tbody-class">
                        {
                          formsPages.length !== 0?
                            formsPages[currentPage-1].map((finance, index) => {
                              let salesman = "";
                              if (finance.salesmanId) {
                                let userFound = find(users, function (user) {
                                  return user.id === finance.salesmanId
                                });
                                if(userFound)
                                  salesman = userFound.firstName + " " + userFound.lastName;
                              }
                              let createDate = new Date(finance.createDate);
                              let mm = (createDate.getMonth() + 1).toString(); // getMonth() is zero-based
                              let dd = createDate.getDate().toString();
                              let yyyy = createDate.getFullYear();
                              let dateFormat = (mm[1] ? mm : "0" + mm[0]) + '/' + (dd[1] ? dd : "0" + dd[0]) + '/' + (yyyy);
                              let style = {};
                              let selectedFinance = false;
                              if (finance.read)
                                style.backgroundColor = "#f4fdfa";
                              if (finance.id === selectedFinance.id) {
                                style.borderTop = "1px #eeeef2 solid";
                                style.borderBottom = "1px #eeeef2 solid";
                              }
                              if (idsSelected.indexOf(finance.id) >= 0) {
                                selectedFinance = true
                              }
                              return (
                                <tr key={index} className="row-message-table rowCostumer"
                                      style={style} onClick={(evt) => this.viewApplicant(finance,evt)}
                                >
                                  <td className="row-table-finance" onClick={ (evt) => evt.stopPropagation()}>
                                    {drawIconApplicant(finance.status)}
                                    <CheckBox styleCheck={{marginBottom:22}} id={finance.id} onChange={this.onChangeCheck} isSelected={selectedFinance} value={selectedFinance}/>
                                  </td>
                                  <td>{finance.applicant}</td>
                                  <td>{finance.financeType}</td>
                                  <td>{salesman}</td>
                                  <td>{getDisplayValue(finance.status)}</td>
                                  <td>{dateFormat}</td>
                                </tr>
                              )
                            }) : <tr/>
                        }
                        </tbody>
                      </table>
                      {
                        formsPages.length === 0 ?
                          <div className="container-no-messages">
                            <div className="container-icon-no-messages">
                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_info_24px.svg"} beforeInjection={ svg => svg.classList.add("icon-info")}/>
                            </div>

                            <div className="container-notify-no-messages">
                              <span className="notify-no-messages">No applicants yet</span>
                            </div>
                          </div> : null
                      }
                    </div>
                }
              </div>
            </div>
          }
        </div>
        {
          showPopover && filterSelected !== null ?
            <Popover anchorEl={anchorEl}
                     child={childMessage}
                     classPopover="popover-box-message"
                     handleRequestClose={this.handleRequestClose}/> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    finances: state.finances,
    newFinanceApplicationsCounter: state.newFinanceApplicationsCounter,
    unreadMessagesCounter: state.unreadMessagesCounter,
  }
};

export default connect(mapStateToProps, {loadFinances})(Finance)