import React from 'react'
import DPActivatedItem from '../../../common/themes/DPActivatedItem'
import {
  pathServer,
  EmptyFunc,
  SECTION_FINANCE_ID,
  SECTION_INVENTORY_ID,
  SECTION_TESTIMONIAL_ID, PAGE_FINANCE_ID,
} from '../../../../common/Constants'
import {ReactSVG} from 'react-svg'
import {cloneDeep} from 'lodash'

const sections = {
  [SECTION_INVENTORY_ID]:[
    {
      id: "finance",
      displayValue: "Financing",
      title: "Get a finance",
      showSection: true,
    },
    /*{
      id: "contact",
      displayValue: "Contact",
      title: "Get in touch with us",
      showSection: true
    }*/
    ],
  [SECTION_FINANCE_ID]:[
    {
      id: "contact",
      displayValue: "Contact",
      title: "Get in touch with us",
      showSection: true
    },
    {
      id: SECTION_TESTIMONIAL_ID,
      displayValue: "Testimonies",
      title: "What our customers say",
      showSection: true
    }
    ]
}

const getItems = (type,pages) => {
  const page = pages.find(x => x.id === type)
  if(!page.sections)
    page.sections = sections[type]

  return  page.sections
}

const SettingPage = ({pagesItems, onClick, message, icon, containerClass, pages, onChangePages, typePage}) => {
 const sectionItems = getItems(typePage, pages)

  const handleCheck = (typePage, sectionId) => {
    const pagesNew = cloneDeep(pages);
    let financePage = pagesNew.find(x => x.id === PAGE_FINANCE_ID)
    const page = pagesNew.find(x => x.id === typePage)
    if(!page.sections){
      page.sections = sections[typePage]
    }
    const section = page.sections.find(x => x.id === sectionId)
    if(section){
      if(section.id !== SECTION_FINANCE_ID || financePage.isActive){
        section.showSection = !section.showSection
      }
      onChangePages(pagesNew)
    }
  }

  return (
    <div className={`container-page-setting ${containerClass ? containerClass : ''}`}>
      <div className="container-icon-message">
        <ReactSVG src={`${pathServer.PATH_IMG}icon/${icon}`} className="content-icon-setting"
                  beforeInjection={ svg => svg.classList.add('icon-setting-page')}/>
        <span className='page-message'>{message}</span>
      </div>
      <hr className='separator'/>
      {
        sectionItems.map((item, index) => {
          return(
            <DPActivatedItem id={index}
                             key={`activated-item-${index}`}
                             showIcon={true}
                             title={item.displayValue}
                             active={item.showSection}
                             onClickItem={EmptyFunc}
                             handleCheck={() => handleCheck(typePage, item.id )}
                             href={`${item.id}Section`}
            />
          )
        })
      }
    </div>
  )
}

export default SettingPage