import React from 'react'

require('./DPLoader.scss')

const DPLoader = ({show}) =>
  show ? (
    <div className="loader-container" data-testid={'dp-spinner'}>
      <div className="loader"/>
    </div>
  ) : null

export default DPLoader