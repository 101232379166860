import React from 'react'
import PropTypes from 'prop-types'
import {DPInput} from '../../../common/DPInput'
import {DPDynamicNumberInput} from '../../../common/DPDynamicNumberInput'
import {PROPERTY, TITLES} from './SaleSettingUpdateForm'


class SaleSettingUpdateFormBodyForFees extends React.Component {
  render() {
    const { element, errorMessages } = this.props
    return (
      <React.Fragment>
        <DPInput
          className='form-field'
          title={TITLES[PROPERTY.FEE_DESCRIPTION]}
          value={element.description}
          onChange={evt => this.props.onChange(PROPERTY.FEE_DESCRIPTION, evt.target.value)}
          errorMessage={errorMessages[PROPERTY.FEE_DESCRIPTION]}
        />
        <DPDynamicNumberInput
          className={'form-field'}
          title={TITLES[PROPERTY.FEE_COST]}
          id={'inputPrice'}
          value={element.value}
          onChange={(evt, modelValue, viewValue) => this.props.onChangePrice(PROPERTY.FEE_COST, modelValue,viewValue,evt)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={false}
          withSymbol={true}
          classWrapperSymbol={'dollar-prefix'}
          styleInput={{paddingLeft: "20px"}}
          errorMessage={errorMessages[PROPERTY.FEE_COST]}
        />
      </React.Fragment>
    )
  }
}

SaleSettingUpdateFormBodyForFees.propTypes = {
  element: PropTypes.object.isRequired,
  errorMessages: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangePrice: PropTypes.func.isRequired,
}

export default SaleSettingUpdateFormBodyForFees