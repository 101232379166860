import React, {Component} from 'react'
import {
  FORM_APPLICANT_CODE_ADMIN, MESSAGE_CAR_ALREADY_SALE,
  MESSAGE_SAVE_CHANGES, MESSAGE_WITHOUT_FINANCE
} from '../../Constants'
import {ReactSVG} from 'react-svg'
import {
  startSale,
  loadFinanceFormByCode, loadDealerConfigurationByCode, cancelSale
} from '../../Api'
import {browserHistory} from 'react-router'
import CustomerHeader from './CustomerHeader.jsx'
import Loader from '../../../common/UI/Loader.jsx'
import {clone, isEmpty} from 'lodash'
import {connect} from 'react-redux'
import {loadMakeAndModel, setCarStatusOptions} from '../../actions'
import CustomerSaleAgreement from './CustomerSaleAgreement.jsx'
import {loadUsers} from '../../actions'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import CustomerSaleFiles from './CustomerSaleFiles.jsx'
import CustomerTaxExemption from './CustomerTaxExemption.jsx'
import HeaderViewApplicant from '../finance/HeaderViewApplicant.jsx'
import Moment from 'moment/moment'
import CustomerCheckList from './CustomerCheckList.jsx'
import {
  TAB_PAYMENT,
  TAB_FINANCE,
  TAB_CASH,
  TAB_AGREEMENT,
  TAB_FILES,
  TAB_TAX_EXEMPTION,
  TAB_CHECKLIST,
  SALE_CANCEL_REASON
} from '../../Constants.js'
import Checkbox from 'material-ui/Checkbox'
import CarSearchPage from './CarSearchPage.jsx'
import {elementCheck, elementUnCheck} from '../../../common/ComponentUtils'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'

const salesTabs = [
  {
    id: "payment",
    title: "Payment Method",
    index: 0
  },
  {
    id: "cash",
    title: "Choose a Car",
    index: 1
  },
  {
    id: "agreement",
    title: "Agreement",
    index: 0
  },
  {
    id: "files",
    title: "Files",
    index: 1
  },
  {
    id: "taxExemption",
    title: "Tax Exemption ",
    index: 2
  },
  {
    id: "checklist",
    title: "Check List",
    index: 3
  }
];

const titleFinance = {id : "finance",
  title:"Finance Application",
  index:1};

const titlesTab = [{id:"payment", title: "> Sale Process"},
  {id: "finance", title: "> Sale Process"},
  {id: "cash", title: "> Sale Process"},
  {id: "agreement", title: "> Sale > Fill Purchase Agreement"},
  {id: "files", title: "> Sale > Upload sale files"},
  {id: "taxExemption", title: "> Sale > Fill Tax Exemption (optional)"},
  {id: "checklist", title: "> Sale > Close sale"}];

const FinanceItems =({showFinance, finances, isLoading, viewApplicant, dataFinance, users, onChange, openModal, isViewFromSales, onClick, isSaleCash}) => {
  return (
    <div className="table-form-setting">
      {
        !showFinance ?
          (finances && finances.length !== 0) ?
            <table className="table">
              <thead className="background-white">
              <tr className="title-forms-list">
                <th className="title-finance">Applicant</th>
                <th className="title-assigned">Type of Application</th>
                <th className="title-status">Status</th>
                <th className="title-date">Date</th>
              </tr>
              </thead>
              <tbody className="tbody-user">
              {
                isLoading ?
                  <div className="loader-container">
                    <div className="loader"/>
                  </div> :
                  finances.map((finance, index) => {
                    const {status,financeId,financeType} = finance;
                    const dateApplied = finance.createDate;
                    const financeApplication = `${finance.firstName} ${finance.lastName}`;
                    return (
                      <tr key={index + 'tr'} className="tr-sale"
                          onClick={() => viewApplicant(financeId)}>
                        <td className="body-finance">{financeApplication}</td>
                        <td className="body-assigned-user">{financeType}</td>
                        <td className="body-status">{status}</td>
                        <td className="body-date">{Moment(dateApplied).format('MM/DD/YYYY')}</td>
                      </tr>
                    )
                  })
              }
              </tbody>
            </table> :
            <div className="container-message-info">
              <div className="message-info">
                <img src={pathServer.PATH_IMG + "inverted.png"} className= "icon-info"/>
                <label>{MESSAGE_WITHOUT_FINANCE}</label>
              </div>
            </div>

          :
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div>
            :
            <div className="container-all-applicant">
              <HeaderViewApplicant dataFinance={dataFinance} users={users} onChange={onChange}
                                   openModal={openModal} isViewFromSales={isViewFromSales}/>
              <div className="button-sale-next" onClick={() => onClick(TAB_CASH, isSaleCash)}>
                Next
              </div>
            </div>
      }
    </div>
  )
};

const BodyReason = ({stylePanelRight, onHide, message, childReason, isRequired, save, labelRequired, buttonLabelSave}) => {
  return(
    <div className="panel-right-cancel-sale" style={stylePanelRight}>
      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-cancel-sale"
                onClick={onHide}/>
      <div>
        <div className="label-cancel">
          <label>{message}</label>
        </div>
        <div>
          {childReason}
        </div>
      </div>
      <div>
        { isRequired ?
          <label className="label-required">{labelRequired}</label> : null
        }
        <button onClick={save} className="btn-save-custom" style={{float:"right"}}>{buttonLabelSave}</button>
      </div>
    </div>
  )
};

class CustomerSale extends Component {

  constructor() {
    super();

    this.state = {
      isLoading: false,
      query: {from: 0, size: 8, keyword: "", status: ["Inspect/Repair", "Hold", "Front Line Ready", "Acquired", "Coming Soon"]},
      currentTab: TAB_PAYMENT,
      itemsByPageQuantity: 8,
      currentPage: 1,
      isSearching: false,
      showOptions: false,
      errorMessage: "",
      showButtonSaveContinue: false,
      carSelected: {},
      showModal: false,
      renderTabs: [],
      tabSelected: 0,
      financeIdSelected: null,
      hasChange: false,
      showModalSaveChangesConfirm: false,
      tabClicked: null,
      showModalCancel: false,
      optionsCancelSale: null,
      reasonSelected: null,
      isRequired: false,
      otherReason: null,
      existReason:false,
      statusSale:null,
      salesmanId: null
    }
  }

  componentWillMount() {
    const {users,email} = this.props;

    this.props.loadMakeAndModel().then(response => {
    }).catch(error => {
      console.error(error)
    });
    this.props.loadUsers().then(response => {
    }).catch(error => {
      console.error(error)
    });

    loadDealerConfigurationByCode(SALE_CANCEL_REASON).then(response => {
      const {responseCode, responseMessage, data} = response;
      if(responseCode === SUCCESSFUL_CODE){
        const {dealerConfiguration} = data
        const values = JSON.parse(dealerConfiguration.value);
        let optionsCancelSale = [];
        values.forEach((reason,index) => {
          optionsCancelSale.push({index:index,value:reason});
          if(index === values.length - 1){
            optionsCancelSale.push({index:index + 1,value:"Other reason"});
          }
        });
        const existReason = (optionsCancelSale.length > 0);
        this.setState({optionsCancelSale: optionsCancelSale, existReason});
      }else {
        console.error(responseMessage);
        this.setState({optionsCancelSale:[]});
      }
    })

    const {saleId, customerId, tab} = this.props.params;
    const {carSelected, finances, name, isSaleCash, financeIdSelected, statusSale, salesmanId} = this.props.location.state || {};
    const renderTabs = this.getTabs(saleId,isSaleCash);

    let tabSelected = renderTabs.filter(renderTab => renderTab.id === tab);
    let state = {saleId, customerId, renderTabs, finances, customerName: name, currentTab: tab, isSaleCash: isSaleCash,tabSelected: tabSelected[0].index,
      financeIdSelected: financeIdSelected, statusSale: statusSale, salesmanId: salesmanId};
    if (saleId !== "new") {
      state.carSelected = carSelected;
      if (statusSale === "cancelled")
        state.showCompleteCancelSale = false;
      else
        state.showCompleteCancelSale = true;
    }
    this.setState(state);
  }

  // It belongs to this clase...
  goBack = () => {
    let {currentTab, tabSelected, renderTabs, customerId, saleId, customerName, finances, showFinance, hasChange, statusSale} = this.state;
    let  showCompleteCancelSale = false;
    let state = {};
    let nextCurrentTab = {};

    if (currentTab === TAB_AGREEMENT ) {
      this.setState({arrowType: "back"});
      if (hasChange){
        state = { currentTab: currentTab};
        this.setState({showModalSaveChangesConfirm: true});
      }
      else
        this.goToCustomer();
    } else {
      let previousTab = renderTabs.find(tab => tab.id === currentTab);
      if (showFinance && previousTab.id === TAB_FINANCE) {
        nextCurrentTab = renderTabs.find(tab => tab.index === previousTab.index);
      } else {
        nextCurrentTab = renderTabs.find(tab => tab.index === previousTab.index - 1);
      }
      if (saleId !== "new") {
        showCompleteCancelSale = true;
        if (currentTab === TAB_TAX_EXEMPTION) {
          this.setState({arrowType: "back"});
          if (hasChange){
            state = { currentTab: currentTab};
            this.setState({showModalSaveChangesConfirm: true});
          }
        }
        if (!hasChange)
          state = {
            showCompleteCancelSale,
            currentTab: nextCurrentTab.id,
            tabSelected: tabSelected - 1
          };
      } else {
        state = {currentTab: nextCurrentTab.id};
        if (previousTab.id === TAB_FINANCE) {
          if (showFinance) {
            state.showFinance = false;
            state.isSaleCash = false;
          } else {
            renderTabs = renderTabs.filter(x => x.id !== TAB_FINANCE);
            renderTabs[1].index = 1;
            state.tabSelected = tabSelected - 1;
            state.isSaleCash = true;
          }
        } else {
          state.tabSelected = tabSelected - 1;
          state.isSaleCash = false;
        }
        state.renderTabs = renderTabs;
      }
    }

    this.setState(state, () => {
      browserHistory.push({
        pathname: `/main/customer/edit/${customerId}/sales/${saleId}/${state.currentTab}`,
        state: {name: customerName, finances: finances, isSaleCash: state.isSaleCash, statusSale: statusSale}
      });
    })
  }

  // It belongs to this class
  goNext = () => {
    let {currentTab, tabSelected, renderTabs, customerId, saleId, customerName, finances, showFinance, hasChange, statusSale} = this.state;
    let  showCompleteCancelSale = false;
    let state = {};
    if (currentTab === TAB_CHECKLIST)
      this.goToCustomer();
    else {
      let previousTab = renderTabs.find(tab => tab.id === currentTab);
      let nextCurrentTab = renderTabs.find(tab => tab.index === previousTab.index + 1);
      if (saleId !== "new") {
        showCompleteCancelSale = true;
        if (currentTab === TAB_AGREEMENT || currentTab === TAB_TAX_EXEMPTION) {
          this.setState({arrowType: "next"});
          if (hasChange){
            state = {currentTab: currentTab};
            this.setState({showModalSaveChangesConfirm: true})
          }
        } else {
          showCompleteCancelSale = true;
        }
        if (!hasChange)
          state = {
            showCompleteCancelSale,
            currentTab: nextCurrentTab.id,
            tabSelected: tabSelected + 1
          };
      }
    }
    this.setState(state, () => {
      browserHistory.push({
        pathname: `/main/customer/edit/${customerId}/sales/${saleId}/${state.currentTab}`,
        state: {name: customerName, finances: finances, isSaleCash: state.isSaleCash, statusSale: statusSale}
      });
    })
  }

  // It belongs to this class
  startSale = (customerId, car, currentTab) => {
    const {customerName,isSaleCash, financeIdSelected, statusSale, salesmanId} = this.state;
    let data = {customers: [{customerId: customerId, principal: true, creditScore: 0}],
                carId: car.id,
                salesmanId: salesmanId};
    if(!isEmpty(financeIdSelected)){
      data["financeId"] = financeIdSelected
    }
    startSale(data).then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const renderTabs = this.getTabs(data.message,isSaleCash);
        this.setState({isLoading: false, currentTab: currentTab,showButtonSaveContinue:true,
          saleId: response.data.message, carSelected:car, tabSelected: 0, renderTabs: renderTabs, showCompleteCancelSale: true}, () => {
          browserHistory.push({pathname: `/main/customer/edit/${customerId}/sales/${data.message}/${currentTab}`,
            state:{name: customerName, statusSale: statusSale}});
        });
      }else{
        this.setState({isLoading: false, responseMessage});
        setTimeout(() => {
          this.setState({errorMessage: ""});
        }, 1500);
      }
    })
  }

  // Only used on CarSearchPage
  // But it has to remain on this class
  confirmStartSale = (customerId, car) => {
    this.setState({carSelected: car});
    if (car["sales"].length > 0) {
      this.setState({showModal: true});
    } else {
      this.setState({isLoading: true});
      this.startSale(customerId, car, TAB_AGREEMENT)
    }
  }

  // It belongs to this class.
  saveAndContinue = () => {
    const {currentTab} = this.state;
    if (currentTab === TAB_AGREEMENT) {
      if (this.saleAgreement) {
        this.saleAgreement.saveAndContinue()
      }
    } else {
      if (this.taxExemption) {
        this.taxExemption.saveAndContinue()
      }
    }
    this.setState({hasChange: false, showModalSaveChangesConfirm: false})
  }

  // It belongs to this class.
  saveAndBack() {
    const {currentTab} = this.state;
    if (currentTab === TAB_AGREEMENT) {
      if (this.saleAgreement) {
        this.saleAgreement.saveAndBack()
      }
    } else {
      if (this.taxExemption) {
        this.taxExemption.saveAndBack()
      }
    }
    this.setState({hasChange: false, showModalSaveChangesConfirm: false})
  }

  // It belongs to this class.
  printPDF() {
    const {currentTab} = this.state;
    if (currentTab === TAB_AGREEMENT) {
      if (this.saleAgreement) {
        this.saleAgreement.printPDF()
      }
    } else {
      if (this.taxExemption) {
        this.taxExemption.printPDF()
      }
    }
  }

  // It belongs to this class.
  saveSale = () => {
    const {currentTab, tabClicked} = this.state;
    if (currentTab === TAB_AGREEMENT) {
      if (this.saleAgreement) {
        this.saleAgreement.saveSale("")
      }
    } else {
      if (this.taxExemption) {
        this.taxExemption.saveSale("")
      }
    }
    this.setState({hasChange: false, showModalSaveChangesConfirm: false}, () => this.goToTab(tabClicked));
  }

  // It belongs to this class.
  showSuccessfulMessage = () => {
    this.setState({successfulMessage: "Saved changes successfully!"});
    setTimeout(() => {
      this.setState({successfulMessage: ""});
    }, 1500);
  }

  // It belongs to this class.
  closeModal = () => {
    this.setState({showModal: false})
  }

  // It belongs to this class.
  confirmContinue = () => {
    const {carSelected} = this.state;
    const {customerId} = this.props.params;
    this.setState({showModal: false, isLoading: true});
    this.startSale(customerId, carSelected, TAB_AGREEMENT)
  }

  // It belongs to this class.
  getTabs(saleId, isSaleCash) {
    let renderTabs = [];
    if (saleId !== "new") {
      salesTabs.forEach((tab, index) => {
        if (index > 1) {
          renderTabs.push(tab);
        }
      });
    } else {
      salesTabs.forEach((tab, index) => {
        if (index < 2) {
          if (!isSaleCash && index === 1) {
            renderTabs.push(titleFinance);
            tab.index = 2
          }
          renderTabs.push(tab);
        }
      });
    }
    return renderTabs;
  }

  // It belongs to this class.
  goToSaleFiles = () => {
    const {arrowType} = this.state;
    const {renderTabs, customerId, saleId, customerName, statusSale} = this.state;
    let {currentTab} = this.state;
    let previousTab = renderTabs.find(tab => tab.id === currentTab);
    let adding =  arrowType === "back" ? -1 : 1;
    let nextCurrentTab = renderTabs.find(tab => tab.index === previousTab.index + adding);
    currentTab = nextCurrentTab.id;
    this.setState({currentTab: currentTab, tabSelected: 1}, () => {
      browserHistory.push({
        pathname: `/main/customer/edit/${customerId}/sales/${saleId}/${currentTab}`,
        state: {name: customerName, statusSale: statusSale}
      });
    });
  }

  // It belongs to this class.
  goToCheckList = (currentTab) => {
    const {tabSelected, customerId, saleId, customerName, statusSale} = this.state;
    this.setState({currentTab: currentTab, tabSelected: (tabSelected + 1)}, () => {
      browserHistory.push({
        pathname: `/main/customer/edit/${customerId}/sales/${saleId}/${currentTab}`,
        state: {name: customerName, statusSale: statusSale}
      });
    })
  }

  // It belongs to this class.
  goToNext = () => {
    const {tabSelected, renderTabs, customerId, saleId, customerName, statusSale} = this.state;
    let {currentTab} = this.state;
    let previousTab = renderTabs.find(tab => tab.id === currentTab);
    let nextCurrentTab = renderTabs.find(tab => tab.index === previousTab.index + 1);
    currentTab = nextCurrentTab.id;
    this.setState({
      currentTab: currentTab,
      tabSelected: tabSelected + 1,
      showButtonSaveContinue: true,
      showButtonNext: false
    }, () => {
      browserHistory.push({
        pathname: `/main/customer/edit/${customerId}/sales/${saleId}/${currentTab}`,
        state: {name: customerName, statusSale: statusSale}
      });
    })
  }

  // It belongs to this class.
  handleTabChange(currentTab) {
    const {tabSelected, customerId, customerName, finances, statusSale} = this.state;
    let state = {currentTab: currentTab, tabSelected: tabSelected + 1, isSaleCash: false};
    const {renderTabs} = this.state;
    renderTabs[1].index = 2;
    let renderTabsNew = clone(renderTabs);
    renderTabsNew.splice(1, 0, {id: titleFinance.id, title: titleFinance.title, index: titleFinance.index});
    state.renderTabs = renderTabsNew;
    this.setState(state, () => {
      browserHistory.push({
        pathname: `/main/customer/edit/${customerId}/sales/new/${currentTab}`,
        state: {name: customerName, finances: finances, isSaleCash: false, statusSale: statusSale}
      });
    });
  }

  // It belongs to this class.
  handleCashSelect = (currentTab, isSaleCash) => {
    const {query,tabSelected, customerId, customerName, finances, financeIdSelected, statusSale} = this.state;
    this.setState({currentTab:currentTab,tabSelected: tabSelected + 1, isSaleCash: isSaleCash, showFinance: false}, () => {
      browserHistory.push({pathname: `/main/customer/edit/${customerId}/sales/new/${currentTab}`,
        state:{name: customerName, finances:finances, isSaleCash: isSaleCash, financeIdSelected: financeIdSelected, statusSale: statusSale}});
      // this.search(query,false);
    });
  }

  // It belongs to this class.
  viewApplicant = (id) => {
    this.setState({showFinance: true, isLoading: true});
    loadFinanceFormByCode(FORM_APPLICANT_CODE_ADMIN, id).then(response => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        this.setState({dataFinance: data, isLoading: false, financeIdSelected: id});
      }else {
        console.error(responseMessage);
        this.setState({dataFinance: null, isLoading: false, financeIdSelected: null});
      }
    })
  }

  // It belongs to this class.
  goToTab = (tabSelected) => {
    const {renderTabs, customerId, customerName, finances, isSaleCash, saleId, hasChange, currentTab, statusSale} = this.state;
    let {showCompleteCancelSale} = this.state;
    let nextCurrentTab = renderTabs.find(tab => tab.id === tabSelected);
    if (hasChange && (currentTab === TAB_AGREEMENT || currentTab === TAB_TAX_EXEMPTION)) {
      this.setState({showModalSaveChangesConfirm: true, tabClicked: tabSelected, arrowType: "select"});
    } else {
      showCompleteCancelSale = true;
    }
    if (!hasChange) {
      this.setState({currentTab: tabSelected, tabSelected: nextCurrentTab.index, isSaleCash: isSaleCash, showCompleteCancelSale}, () => {
        browserHistory.push({
          pathname: `/main/customer/edit/${customerId}/sales/${saleId}/${tabSelected}`,
          state: {name: customerName, finances: finances, isSaleCash: isSaleCash, statusSale: statusSale}
        });
      });
    }
  }

  // Only used on CarSearchPage
  // It has to be here (We have to think about it)
  addNewCar = () => {
    const {customerId, customerName, isSaleCash, finances, financeIdSelected, statusSale} = this.state;
    browserHistory.push({pathname: `/main/inventory/new`,
      state:{customerId:customerId, customerName: customerName, isSaleCash: isSaleCash, finances: finances, financeIdSelected: financeIdSelected, statusSale: statusSale}})
  }

  // It belongs to this class
  handleChanges = (hasChange) => {
    this.setState({hasChange: hasChange})
  }

  // It belongs to this class
  confirmSaveChanges = () => {
    const {arrowType} = this.state;
    if (arrowType === "next")
      this.saveAndContinue();
    else if ((arrowType === "back"))
      this.saveAndBack();
    else
      this.saveSale();
  }

  // It belongs to this class
  handleArrowTypeFunction() {
    const {arrowType, tabClicked} = this.state;
    let handleArrowType = null;
    switch (arrowType) {
      case "next":
        handleArrowType = this.goNext();
        break;
      case "back":
        handleArrowType = this.goBack();
        break;
      case "select":
        handleArrowType = this.goToTab(tabClicked);
        break;
    }
    return handleArrowType
  }

  // It belongs to this class
  dontConfirmSaveChanges = () => {
    const {arrowType} = this.state;
    this.setState({showModalSaveChangesConfirm: false, hasChange: false}, () => this.handleArrowTypeFunction())
  }

  // It belongs to this class
  closeModalConfirmSaveChanges = () => {
    this.setState({showModalSaveChangesConfirm: false});
  }

  // It belongs to this class
  goToCustomer = () => {
    browserHistory.push({pathname: `/main/customer/edit/${this.props.params.customerId}`,
    state:{isSale: "customerCars"}});
  }

  // It belongs to this class
  showModalCancelSale = () => {
    this.setState({showModalCancel: true, reasonSelected: null});
  }

  // It belongs to this class
  closeModalCancelSale = () => {
    this.setState({showModalCancel: false, reasonSelected: null});
  }

  // It belongs to this class
  handleCheck(index, checked, evt){
    const reasonSelected = (checked === -1) ? evt.target.id : null;
    const indexSelected = index;
    this.setState({reasonSelected, indexSelected, isRequired: false});
  }

  // It belongs to this class
  onChangeInput(e){
    const otherReason = e.target.value;
    this.setState({otherReason});
  }

  // It belongs to this class
  onBlurInput(e){
    const otherReason = e.target.value;
    this.setState({otherReason});
  }

  // It belongs to this class
  renderReason(){
    const {optionsCancelSale, reasonSelected, otherReason, indexSelected} = this.state;
    let options = [];

    if(optionsCancelSale && optionsCancelSale.length > 0){
      optionsCancelSale.forEach(reason => {
        const checked = (reason.value === reasonSelected) ? 1 : -1;
        const disabled = (indexSelected !== optionsCancelSale.length - 1 || checked === -1);
        options.push(
          <div key={reason.index} className="content-check">
            <Checkbox label={reason.value} checked={(checked !== -1)} id={reason.value}
                      onCheck={(evt) => this.handleCheck(reason.index, checked, evt)}
                      labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                      iconStyle={{borderColor: "#777", marginRight: 8}}
                      checkedIcon={elementCheck('icon/ic_check_box.svg')} uncheckedIcon={elementUnCheck('icon/ic_uncheck_box.svg')}
                      name={reason.value} />
            {
              reason.index === optionsCancelSale.length - 1 ?
                <div>
                  <textarea type="text" className="form-control" value={otherReason} style={{marginBottom: 10, resize:"none"}}
                            onChange={(e) => this.onChangeInput(e)}
                            onBlur={(e) => this.onBlurInput(e)}
                            disabled={disabled}
                            rows="2"/>
                </div> : null
            }
          </div>
        );
      });

    } else {
      options.push(
        <div>
          <label>Reason</label>
          <textarea type="text" className="form-control" value={otherReason} style={{marginBottom: 10, resize:"none"}}
                    onChange={(e) => this.onChangeInput(e)}
                    onBlur={(e) => this.onBlurInput(e)}
                    rows="3"/>
        </div>
      )
    }

    return(
      <div>
          {options}
      </div>
    )
  }

  // It belongs to this class
  confirmCancelSale = () => {
    const {optionsCancelSale, reasonSelected, indexSelected, otherReason} = this.state;
    if(indexSelected === optionsCancelSale.length - 1 || optionsCancelSale.length === 0){
      this.cancelSale(otherReason);
    } else {
      this.cancelSale(reasonSelected);
    }
  }

  // It belongs to this class
  cancelSale = (reason) => {
    const {saleId} = this.state;
    if(isEmpty(reason.trim())){
      this.setState({isRequired:true});
    } else {
      const reasonSaleCancel = {reason:reason};
      cancelSale(saleId,reasonSaleCancel).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({showModalCancel: false, reasonSelected: null, indexSelected: null});
          this.goToCustomer();
        }else{
          console.error(responseMessage)
        }
      })
    }
  }

  render() {
    let child = null;
    const {height, left, makes, models, users} = this.props;
    const {customerId} = this.props.params;
    const { isLoading, currentTab,  errorMessage, carSelected, saleId, successfulMessage, showModal, renderTabs, tabSelected, showFinance, dataFinance,
      finances, customerName, isSaleCash, showCompleteCancelSale, showModalSaveChangesConfirm, showModalCancel, isRequired, existReason} = this.state;

    switch (currentTab) {
      case TAB_PAYMENT:
        child = (
          <div className="sales-icons-container">
            <div className="sales-icon-container" onClick={() => this.handleTabChange(TAB_FINANCE)}>
              <ReactSVG src={pathServer.PATH_IMG + "icon/getting_finance.svg"}
                        className="icon-sales-content"
                        beforeInjection={ svg => svg.classList.add('icon-sales')}/>
              <div className="sales-titles">Getting Finance</div>
            </div>
            <div className="sales-icon-container" onClick={() => this.handleCashSelect(TAB_CASH, true)}>
              <ReactSVG src={pathServer.PATH_IMG + "icon/cash.svg"} className="icon-sales-content"
                        beforeInjection={ svg => svg.classList.add('icon-sales')}/>
              <div className="sales-titles">Cash</div>
            </div>
          </div>
        );
        break;
      case TAB_FINANCE:
        child = <FinanceItems showFinance={showFinance} isLoading={isLoading} viewApplicant={this.viewApplicant} finances={finances} dataFinance={dataFinance}
                              users={users} onChange={null} openModal={null} isViewFromSales={true} onClick={this.handleCashSelect} isSaleCash={isSaleCash}/>;
        break;
      case TAB_CASH:
        if (!isLoading) {
          child =
              <CarSearchPage
                  startSale={(selectedCar) => this.confirmStartSale(customerId, selectedCar)}
                  errorMessage={errorMessage}
                  addNewCar={this.addNewCar}
                  confirmContinue={this.confirmContinue}
                  closeModal={this.closeModal}
                  showModal={showModal}
              />;
        }
        break;
      case TAB_AGREEMENT:
        child =
          <CustomerSaleAgreement ref={el => this.saleAgreement = el} saleId={saleId} carSelected={carSelected}
                                 makes={makes} models={models} users={users}
                                 customerId={this.props.params.customerId}
                                 showSuccessfulMessage={this.showSuccessfulMessage}
                                 goToSaleFiles={this.goToSaleFiles}
                                 handleChanges={this.handleChanges} goToCustomer={this.goToCustomer}/>;
        break;
      case TAB_FILES:
        child = <CustomerSaleFiles saleId={saleId}/>;
        break;
      case TAB_TAX_EXEMPTION:
        child = <CustomerTaxExemption ref={el => this.taxExemption = el} saleId={saleId}
                                      customerId={this.props.params.customerId}
                                      goToSaleFiles={this.goToSaleFiles}
                                      showSuccessfulMessage={this.showSuccessfulMessage}
                                      goToCheckList={this.goToCheckList} handleChanges={this.handleChanges}/>;
        break;
      case TAB_CHECKLIST:
        child = <CustomerCheckList saleId={saleId}/>;
        break;
    }

    const titleTab = titlesTab.filter(title => title.id === currentTab);
    let title = titleTab[0].title;
    if (customerName) {
      title = `${customerName} ${title}`
    }
    const childReason = this.renderReason();

    return (
      <div className="sales-content">
        {isLoading ? <Loader/> : null}
        <CustomerHeader
          title={title} left={left} successfulMessage={successfulMessage} hideButtonNew={true}
          goToNext={this.goToNext} showCompleteCancelSale={showCompleteCancelSale} cancelSale={this.showModalCancelSale}/>
        <div id="salesContainer" className="sales-container" style={{display: "block"}}>
          <div className="sales-header">
            <div className="titles-container">
              {
                (saleId !== "new") ?
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_back.svg"}
                            className="sales-back-icon-container"
                            beforeInjection={ svg => svg.classList.add('icon-sales-back-icon')} onClick={this.goBack}/> : null
              }
              {
                renderTabs && renderTabs.length > 0 ?
                  renderTabs.map((tab, index) => {
                    let clickable = false;
                    if (saleId !== "new")
                      clickable = true;
                    return (
                      <div key={`sale-tab-${index}`}
                           className={!clickable ? "sales-title" : "sales-title-clickable"}
                           style={(index === renderTabs.length - 1) ? {marginRight: 0} : null}
                           onClick={clickable ? () => this.goToTab(tab.id) : null}>
                        <label
                          className={(index === renderTabs.length - 1) ? "label-title-sales-end" : "label-title-sales"}
                          style={(tabSelected === index) ? {color: "#2194F1"} : {color: "#787878"}}>
                          {tab.title}
                        </label>
                        {
                          index !== renderTabs.length - 1 ?
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_dots.svg"}
                                      className="dots-icon-container"
                                      beforeInjection={ svg => svg.classList.add('dots-icon')}/>
                            : null
                        }
                      </div>
                    )
                  }) : null
              }
              {
                (saleId !== "new" && currentTab !== TAB_CHECKLIST) ?
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_next.svg"}
                            className="sales-back-icon-container"
                            beforeInjection={ svg => svg.classList.add('icon-sales-back-icon')} onClick={this.goNext}/> : null
              }
            </div>
          </div>
          <div className={tabSelected === 0 ? "sales-body item-center" : "sales-body"}
               style={{minHeight: height}}>
            {child}
          </div>
        </div>
        {
          showModal ?
            <GenericModalConfirm show={showModal}
                                 onHide={this.closeModal}
                                 save={this.confirmContinue}
                                 message={MESSAGE_CAR_ALREADY_SALE}
                                 showButtonCancel={true}
                                 buttonLabelSave={"Continue anyway"}
                                 buttonLabelCancel={"Cancel"}
                                 showCustomIcon={true}
                                 info={carSelected.sales}
                                 stylePanelRight={{padding: "60px 0"}}
            /> : null
        }
        {
          showModalSaveChangesConfirm ?
            <GenericModalConfirm show={showModalSaveChangesConfirm}
                                 onHide={this.closeModalConfirmSaveChanges}
                                 cancel={this.dontConfirmSaveChanges}
                                 save={this.confirmSaveChanges}
                                 message={MESSAGE_SAVE_CHANGES}
                                 showButtonCancel={true}
                                 buttonLabelSave={"Save"}
                                 buttonLabelCancel={"Don't"}
                                 showCustomIcon={true}
            /> : null
        }
        {
          showModalCancel ?
            <GenericModalConfirm show={showModalCancel}
                                 showCustomIcon={true}
                                 onHide={this.closeModalCancelSale}>
              <BodyReason stylePanelRight={existReason ? {padding: "30px 20px"} : {padding: "55px 20px"}} childReason={childReason}
                          message={"Why are you canceling this Sale?"} isRequired={isRequired} onHide={this.closeModalCancelSale}
                          save={this.confirmCancelSale} buttonLabelSave={"Cancel Sale"} labelRequired={"Reason is required"} />
            </GenericModalConfirm>
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    makes: state.makes,
    models: state.models,
    attributeValuesToCarStatus: state.attributeValuesToCarStatus,
    users: state.users,
    email: state.email
  }
};

export default connect(mapStateToProps, {loadMakeAndModel, setCarStatusOptions, loadUsers})(CustomerSale)