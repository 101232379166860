import React from 'react'
import {DPInput} from '../../../../common/DPInput'

const ExternalUrlContent = ({value, ...props}) => {
  const {index, type, onChangeTabNavigation} = props
  return (
    <DPInput
      value={value}
      onChange={(evt)=> onChangeTabNavigation(index, type, evt.target.value)}
      placeholder={'Paste your URL here'}
    />
  )
}

export default ExternalUrlContent