import React, {useState} from 'react'
import {testTransaction} from '../../../Api'
import PropTypes from 'prop-types'
import PaymentModal from '../../setting/billing/PaymentModal'
import {FULL_BALANCE} from '../../../Constants'
import {SUCCESSFUL_CODE} from '../../../../common/Constants'
//https://developer.authorize.net/api/reference/features/acceptjs.html

const noPaddingRight = {
  paddingRight: 0
}



const AcceptJsForm = (props) => {
  const [message, setMessage] = useState({})
  const [amount, setAmount] = useState(99.99) //this will come from the selected subscription
  const [isLoading, setLoading] = useState(false)

  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [outstandingBalance, setOutstandingBalance] = useState(119.50)
  const [otherAmount, setOtherAmount] = useState("")
  const [selectedAmountOption, setSelectedAmountOption] = useState(FULL_BALANCE)
  const [errorMessage, setErrorMessage] = useState({})


  function togglePaymentModal() {
    setShowPaymentModal(!showPaymentModal)
  }

  function selectedAmountOptionHandler(amountOption){
    if(selectedAmountOption !== amountOption) {
      setSelectedAmountOption(amountOption)
    }
  }


  // const authData = {
  //   clientKey : '5h982Age32RnvBGFsxQLX9YC9s7RyrTw6A6R2TC6tPYsfyCk65X2QfWq49s6f62h',
  //   apiLoginID: '9hTt6A7D2'
  // }
  const authData = {
    clientKey: props.config.clientKey,
    apiLoginID: props.config.apiLoginId
  }

  // console.log('authData',authData)

  // 8sn4GGz7s4J7XT28 // transaction key

  function paymentTransaction(token, amount) {
    const data = {
      refId: "123abc", transactionRequest :{amount: amount, payment :{token: token}}
    }
    setLoading(true)
    testTransaction(data).then(response => {
      if (response.responseCode === SUCCESSFUL_CODE) {
        setLoading(false)
        setMessage({
          message: response?.data.transactionResponse?.code === "Ok" ? response?.data.transactionResponse?.description : response?.data.transactionResponse?.message,
          status: response?.data.transactionResponse?.code
        })
        setTimeout(() => {
          setMessage({})
        }, 3000)
      } else {
        console.error(response.responseMessage)
        setLoading(false)
      }
    })
  }

  const handleSubmit = e => {
    e.preventDefault();

    const formData = new FormData(e.target)
    const cardHolder = formData.get('card-holder')
    const cardNumber = formData.get('card-number')
    // const cardCode = formData.get('card-code')
    const cvv = formData.get('cvv')
    const expDate = formData.get('exp-date')
    const zip = formData.get('zip')

    console.log("cardHolder :", cardHolder)
    console.log("cardNumber :", cardNumber)
    // console.log("cardCode :", cardCode)
    console.log("cvv :", cvv)
    console.log("expYear :", expDate.slice(0,2))
    console.log("expMonth :", expDate.slice(2, expDate.length))
    console.log("zip :", zip)

    const cardData = {
      cardNumber,
      month: expDate.slice(2, expDate.length),
      year: expDate.slice(0,2),
      cardCode: cvv
    }

    const secureData = {
      authData: authData,
      cardData: cardData
    }

    console.log('secure data', secureData)

    Accept.dispatchData(secureData, (response) => {
      if(!!response.opaqueData){
        paymentTransaction(response.opaqueData.dataValue, amount)
      }else {
        response.messages.message.map(message => {
          setMessage({message: message.text, status: "Error"})
        })
        setTimeout(() => {setMessage({})}, 3000)
      }
    })
  }

  return (
    <>
      <button className="btn-save" onClick={togglePaymentModal}>Pay</button>
      <PaymentModal show={showPaymentModal}
                    outstandingBalance={outstandingBalance}
                    otherAmount={otherAmount}
                    setOtherAmount={setOtherAmount}
                    selectedAmountOption={selectedAmountOption}
                    selectedAmountOptionHandler={selectedAmountOptionHandler}
                    paymentHandler={handleSubmit}
                    errorMessage={errorMessage}
                    onClick={togglePaymentModal}/>



  {/*<div className={'payment-modal open-new-credit-card'}>*/}
      {/*  <form onSubmit={handleSubmit}>*/}
      {/*    <div className="form-group">*/}
      {/*      <label htmlFor="card-holder">Card Holder name</label>*/}
      {/*      <input type="text" className="form-control" id="card-holder" placeholder="Name on the card" name="card-holder" required/>*/}
      {/*    </div>*/}
      {/*    <div className="form-row">*/}
      {/*      <div className="form-group col-md-6">*/}
      {/*        <label htmlFor="card-number">Card number</label>*/}
      {/*        <input type="text" className="form-control" id="card-number" name="card-number" required/>*/}
      {/*      </div>*/}
      {/*      <div className="form-group col-md-3">*/}
      {/*        <label htmlFor="exp-date">Expiration date</label>*/}
      {/*        <input type="text" className="form-control" id="exp-date" name="exp-date" required/>*/}
      {/*      </div>*/}
      {/*      <div className="form-group col-md-3" style={noPaddingRight}>*/}
      {/*        <label htmlFor="cvv">CVV</label>*/}
      {/*        <input type="text" className="form-control" id="cvv" name="cvv" required/>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="form-row">*/}
      {/*      <div className="form-group col-md-10">*/}
      {/*        <label htmlFor="address">Billing address</label>*/}
      {/*        <input type="text" className="form-control" id="address" name="address" />*/}
      {/*      </div>*/}
      {/*      <div className="form-group col-md-2" style={noPaddingRight}>*/}
      {/*        <label htmlFor="zip">Zip code</label>*/}
      {/*        <input type="text" className="form-control" id="zip" name="zip" required/>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="form-row">*/}
      {/*      /!*<div className="content-check">*!/*/}
      {/*      /!*  <DPCheckbox checked={true} id="save-card-check" onCheck={() => {}} />*!/*/}
      {/*      /!*  <div className="container-label">*!/*/}
      {/*      /!*    <span className="check-description">Save card</span>*!/*/}
      {/*      /!*  </div>*!/*/}
      {/*      /!*</div>*!/*/}
      {/*      <button type="submit" className="btn-save">Pay</button>*/}
      {/*    </div>*/}
      {/*  </form>*/}
      {/*</div>*/}


      {/*<form onSubmit={handleSubmit} className="test-payment-form">*/}
      {/*  <h3>Payment form</h3>*/}
      {/*  <p>Subscription amount: {amount}</p>*/}
      {/*  <input type="text" id="card-holder" name="card-holder" placeholder="Card holder"/>*/}
      {/*  <input type="text" id="card-number" name="card-number" placeholder="Card number"/>*/}
      {/*  <input type="text" id="card-code" name="card-code" placeholder="Card code"/>*/}
      {/*  <input type="text" id="exp-date" name="exp-date" placeholder="Expiration date" maxLength={4} minLength={4}/>*/}
      {/*  <input type="text" id="zip" name="zip" placeholder="Zip number"/>*/}
      {/*  <button type="submit">Pay now</button>*/}
      {/*  {*/}
      {/*    !!message && <p className={`message ${message.status === "Error" ? 'error' : 'successful'}`}>{message.message}</p>*/}
      {/*  }*/}
      {/*</form>*/}
    </>
  )
}

AcceptJsForm.defaultProps = {
  classButton: "",
  config: {apiLoginId: '', clientKey: ''}
}

AcceptJsForm.propTypes = {
  classButton: PropTypes.string,
  config: PropTypes.shape({
    apiLoginId: PropTypes.string,
    clientKey: PropTypes.string
  })
}


export default AcceptJsForm