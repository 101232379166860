import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

const StyledDragAndDrop = styled.div`
    width: ${(props) => (props.width)};
    height: ${(props) => (props.heigth)};
`;

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : "rgb(255, 255, 255)",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "rgb(255, 255, 255)",
});


const DPDragAndDrop = React.forwardRef(({children, listData, onReorder, ...props}, ref) => {
    const [items, setItems] = useState([]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );
       setItems(newItems);
       onReorder(newItems);
    };

    useEffect( () =>{
        if( listData.length > 0) {
            setItems(listData)
        }
    },[]);

    return (
        <StyledDragAndDrop {...props} ref={ref}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {items.map((item, index) => (
                                <Draggable key={"item" + item.id} draggableId={"item" + item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {children(item)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </StyledDragAndDrop>
    )
});



DPDragAndDrop.displayName = 'DPDragAndDrop';

DPDragAndDrop.defaultProps = {
    onReorder: () => {},
    listData : [],
    children : null,
};

DPDragAndDrop.propTypes = {
    listData : PropTypes.array.required,
    onReorder : PropTypes.func,
    children: PropTypes.elementType,
};

export default DPDragAndDrop;