import React from 'react'
import PropTypes from 'prop-types'
import SaleSettingColumnSectionElement from './SaleSettingColumnSectionElement'
import DPDroppable from '../../dragdrop/Droppable.jsx'
import {COLUMNS} from './SaleSetting'


function getSectionHeader(columnName, section) {
  if ([COLUMNS.FILES, COLUMNS.CHECKLIST].includes(columnName)) {
    return <div className='section-header'>{section.title}</div>
  }

  if (columnName === COLUMNS.ACCESSORY) {
    return (
      <div className='section-header-2'>
        <span className="title-1">Accessory name</span>
        <span className="title-2">Unit price</span>
      </div>
    )
  }

  if (columnName === COLUMNS.REG_FEES_AND_OTHERS) {
    return (
      <div className='section-header-reg-fees'>
        <span className="title-1">Item</span>
        <span className="title-2">Price</span>
      </div>
    )
  }

  return null
}

class SaleSettingColumnSection extends React.Component {
  render() {
    const { section, isLastSection, elements, goUp, goDown, deleteElement, editElement, validateElement,  columnName, updateElementInState,
      handleShowPopover, handleShowDeleteModal } = this.props
    return (
      <div key={section.id} className='column-section'>
        { getSectionHeader(columnName, section) }

        <DPDroppable
          droppableId={section.id}
          className={'section-body'}
          droppableStyle={null}
          draggingOverStyle={null}
        >
          {
            elements.map((element, index) => {
              return (
                <SaleSettingColumnSectionElement
                  key={element.id}
                  index={index}
                  element={element}
                  sectionId={section.id}
                  goUp={goUp}
                  goDown={goDown}
                  deleteElement={deleteElement}
                  editElement={editElement}
                  validateElement={validateElement}
                  columnName={columnName}
                  handleShowPopover={handleShowPopover}
                  handleShowDeleteModal={handleShowDeleteModal}
                />
              )
            })
          }
        </DPDroppable>

        {/* We don't want a break line for last section of the column */}
        {
          !isLastSection
            ? <div className='section-footer'>
              <hr className='simple-line'/>
            </div>
            : null
        }
      </div>
    )
  }
}

SaleSettingColumnSection.propTypes = {
  section: PropTypes.object,
  isLastSection: PropTypes.bool,
  elements: PropTypes.array,
  goUp: PropTypes.func,
  goDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElement: PropTypes.func,
  handleShowDeleteModal: PropTypes.func,
}

export default SaleSettingColumnSection