import React from 'react'
import ReactDOM from 'react-dom'
//@ts-ignore
import Admin from './admin'

ReactDOM.render(
  <React.StrictMode>
    <Admin/>
  </React.StrictMode>,
  document.getElementById('root')
)
