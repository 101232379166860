import React, {Component} from 'react'
import {connect} from 'react-redux'
import {ReactSVG} from 'react-svg'
import {changePasswordApi} from '../../Api'
import {browserHistory} from 'react-router'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
require('./ChangePassword.scss')

class ChangePassword extends Component {

  constructor() {
    super();
    this.state = {
      password:"",
      confirmPassword:"",
      isEmptyPassword:false,
      isEmptyConfirmPassword:false,
      matchPassword:false,
      meetRequirements: true,
      errorMessage:null,
      requirements: [false,false,false,false]
    }
  }

  handleChange = (e) => {
    let valueRequirements = [false,false,false,false];
    let password = e.target.value;
    if (password.match(/[A-Z]/g)){
      valueRequirements[0] = true;
    }
    if (password.length >= 8){
      valueRequirements[1] = true;
    }
    if (password.match(/[^a-zA-Z\d]/g)){
      valueRequirements[2] = true;
    }
    if (password.match(/[0-9]/g)){
      valueRequirements[3] = true;
    }
    this.setState({requirements: valueRequirements, password: password, isEmptyPassword: false, matchPassword: false});
  }

  handleConfirmPassword = (e) => {
    let confirmPassword = e.target.value;
    this.setState({confirmPassword:confirmPassword, isEmptyConfirmPassword: false, matchPassword: false});
  }

  changePassword = () => {
    let {userId} = this.props.params;
    let {password, confirmPassword, requirements} = this.state;
    if (password === "") {
      this.setState({isEmptyPassword: true, meetRequirements: true, isEmptyConfirmPassword: false, matchPassword: false});
    } else if (requirements.toString() !== "true,true,true,true") {
      this.setState({isEmptyPassword: false, meetRequirements: false, isEmptyConfirmPassword: false, matchPassword: false});
    } else if (confirmPassword === "") {
      this.setState({isEmptyPassword: false, meetRequirements: true, isEmptyConfirmPassword: true, matchPassword: false});
    } else if(password !== confirmPassword){
      this.setState({isEmptyPassword: false, meetRequirements: true, isEmptyConfirmPassword: false, matchPassword: true});
    }else{
      let data = {userId:userId,password:password,confirmPassword:confirmPassword};
      changePasswordApi(data).then(({responseCode,responseMessage}) =>{
        if (responseCode === SUCCESSFUL_CODE) {
          browserHistory.push({pathname: '/login', state: {changePassword: "changePassword"}});
        } else {
          console.error("error change password", responseMessage)
          this.setState({errorMessage: responseMessage})
        }
      });
    }
  }

  render() {
    let {password, confirmPassword, isEmptyPassword, meetRequirements, requirements, isEmptyConfirmPassword, matchPassword} = this.state;
    let color = !this.state.requirements[0]?"rgb(136,136,136)":"green";
    return (
      <div className="container-change-password">
        <div id="id-form-content" className="form-content form-change-password">
          <img className="logo-godealergo" src={pathServer.PATH_IMG + "godealergo.png"} alt=""/>
          <div className="id-form-content-reset">
            New password
          </div>
          <div className="id-form-content-email">
            {matchPassword ? <span className="message-general-error">Password not match Confirm Password</span> : null}
            {meetRequirements? null:<span className="message-general-error">Password not meet Requirements</span>}
            <div className="new-password-recover">
              <label>Set a new Password</label>
              <div className="input-new-password-recover">
                <input type="password" placeholder="Type here" value={password} onChange={this.handleChange}/>
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="img-password-recover" style={{display: (requirements.toString() === "true,true,true,true") ? "initial" : "none",
                  fill: "rgb(48, 161, 20)"}}/>
              </div>
              { isEmptyPassword ? <span className="message-error">Please provide password</span> : null}
            </div>
            <div className="confirm-password-recover">
              <label>Confirm Password</label>
              <div className="input-new-password-recover">
                <input type="password" placeholder="Type here" value={confirmPassword} onChange={this.handleConfirmPassword}/>
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="img-password-recover" style={{display: (password !== "" && password === confirmPassword) ? "initial" : "none",
                  fill: "rgb(48, 161, 20)"}}/>
              </div>
              { isEmptyConfirmPassword ? <span className="message-error">Please provide confirm password</span> : null}
            </div>
            <div className="requirements-box">
              <div className="p-review-check">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="icon-arrow-green" style={{fill: !this.state.requirements[0]?"rgb(136,136,136)":"#4AD991"}}/>
                A capital letter</div>
              <div className="p-review-check">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="icon-arrow-green" style={{fill: !this.state.requirements[1]?"rgb(136,136,136)":"#4AD991"}}/>
                At least 8 characters</div>
              <div className="p-review-check">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="icon-arrow-green" style={{fill: !this.state.requirements[2]?"rgb(136,136,136)":"#4AD991"}}/>
                A symbol (!%^&*)</div>
              <div className="p-review-check">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="icon-arrow-green" style={{fill: !this.state.requirements[3]?"rgb(136,136,136)":"#4AD991"}}/>
                A number</div>
            </div>
          </div>
          <div className="form-btn-sign-in">
            <button id="id-btn-login" className="btn-login" onClick={this.changePassword}>Reset</button>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state,ownProps) => {
  return {
    dealerId:state.dealerId
  }
};

export default connect(mapStateToProps)(ChangePassword)