import React, {Component} from 'react'
import {CUSTOMERS_BY_PAGE, siteColorAdmin} from '../../Constants'
import {ReactSVG} from 'react-svg'
import DPButton from '../../common/DPButton'
import {pathServer, WIDTH_VIEW_MOBILE} from '../../../common/Constants'
import {DPPagination} from '../../../common/DPPagination'
import isEmpty from 'lodash/isEmpty'
import {getPosition} from '../../../common/Util'

export default class CustomerHeader extends Component {

  constructor(){
    super();
    this.state = {
      showOptions:false,
      itemsByPageQuantity:16,
      currentPage:1,
      showPopover:false,
      filterSelected: null
    }
  }

  renderDropDownFilter(type){
    let target = document.getElementById(type);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };
    this.setState({showPopover: true, filterSelected: type, anchorEl: anchorEl})
  }

  render() {
    let {title, action,left,showButtonAdd,save,showButtonNew,isEdit,actionBack,hasChange, showCompleteCancelSale,
      cancelSale, nameCustomer, showIconAddCustomer, addCustomer, hasButtonImport, hasButtonExport,
      handleButtonImport, handleButtonExport, showSortOption, optionsListBtn, handleBtnOption, filterSelected} = this.props;
    return(
      <div className="container-header" style={left ? {left:left} : {}}>
        {
          showIconAddCustomer &&
          <div className="customer-button">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"} svgClassName="icon-add-svg"
                      className="icon-add-task" onClick = {addCustomer}/>
          </div>
        }
        {
          isEdit ?
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_back.svg"} beforeInjection={ svg => svg.classList.add('icon-back')} onClick={actionBack}
                      className="arrow-back"/> : <div className="arrow-back-edit"/>
        }
        <div className="customer-title" style={isEdit ? {paddingBottom: 7, paddingLeft: 15} : null}>
          {title}
          {
            isEdit &&
            <div className="arrow-right">
              <i style={{color: "#898989",padding: "0 5px",fontSize: "0.9em"}} className="fa fa-chevron-right fa-lg"/>
              {showButtonAdd ? "New Customer"  : nameCustomer}
            </div>
          }
        </div>
        {
          showButtonNew ?
              <div className="customer-button">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"}
                          beforeInjection={svg => svg.classList.add('icon-add-svg')}
                        className="icon-add-task" onClick={action}/>
            </div> : null
        }
        {
          showButtonAdd ?
            <div className="container-button-save">
              <button onClick={save} className="btn-save" disabled={false}
                      style={hasChange ? {backgroundColor:"#4ad991"}:{backgroundColor:"#A4AFB7"}}>
                Save
              </button>
            </div> : null
        }
        {
          showSortOption &&
            <div className="btn-container-dp-user">
              {
                /*
               <div id="orderUser" className="btn-general-message-dp-customer" onClick={this.renderDropDownFilter.bind(this,"orderUser")}>
                <span>{(listName.displayValue === "Sort By") ? ALPHABETICAL_ORDER.ASC : listName.displayValue}</span>
                {
                  (filterSelected === "orderUser") ?
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                }
              </div>
                 */
              }
            </div>
        }
        {
          optionsListBtn && !isEmpty(optionsListBtn) &&
            <div className="container-list-btn">
              {
                optionsListBtn.map((op, idx) => {
                  return <div key={idx} className="export-inventory">
                    <DPButton className="button-white" width="100%" onClick={() => handleBtnOption(op.key)} data-test={`import-customer`}>{op.value}</DPButton>
                  </div>
                })
              }
            </div>
        }
        {
          showCompleteCancelSale ?
            <div className="container-button-next">
              <div className="container-label-reject">
                <button onClick={cancelSale} className="btn-cancel-sale">Cancel Sale</button>
              </div>
              <div className="box-button">
                <button className="btn-done" >Complete Sale</button>
              </div>
            </div> : null
        }
        { hasButtonImport && window.innerWidth > WIDTH_VIEW_MOBILE && (
          <div className="customer-import">
            <DPButton className="button-white" width="100%" onClick={handleButtonImport} data-test={`import-customer`}>Import Customer</DPButton>
          </div>
        )}
        { hasButtonExport && window.innerWidth > WIDTH_VIEW_MOBILE && (
          <div className="customer-export">
            <DPButton className="button-blue" width="100%" onClick={handleButtonExport} data-test={`export-customer`}>Export Customer</DPButton>
          </div>
        )}
        {
          !isEdit && (
              <div className="customer-pagination">
                <DPPagination
                  optionsQuantityByPage={CUSTOMERS_BY_PAGE}
                  selectedItemsByPage={this.props.itemsByPageQuantity}
                  handleChangeItemsByPage={this.props.handleItemsByPage}
                  currentPage={this.props.currentPage}
                  totalPages={this.props.totalPages}
                  handlePageChange={this.props.handlePageChange}
                  siteColor={siteColorAdmin}
                />
              </div>

          )
        }

      </div>
    )
  }

}