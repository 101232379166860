import React, {Component} from 'react'
import {DragSource, DropTarget} from 'react-dnd'
import flow from 'lodash/flow'
import FormSearchAttribute from './FormSearchAttribute.jsx'
import {componentWillAppendToBody} from 'react-append-to-body'
import {ResizableBox} from 'react-resizable'
import {pathServer} from '../../../../common/Constants'

function MyComponent({ children }) {
  return <div className="menu-popover-attribute">{children}</div>;
}

const AppendedMyComponent = componentWillAppendToBody(MyComponent);

const AttributeItemSource = {
  beginDrag(props){
    return {
      attribute: props.attributeInfo[0],
      indexRow: props.indexRow,
      indexCol: props.indexCol,
      indexSection: props.index,
      indexSubSection: props.indexSubSection
    }
  },
  endDrag(props,monitor,component) {
    //return props.handleDrop(props.attributeInfo[0])
  }

};

const AttributeItemTarget = {

  drop(props, monitor, component){
    const indexSection = monitor.getItem().indexSection;
    const dragIndexRow = monitor.getItem().indexRow;
    const dragIndexCol = monitor.getItem().indexCol;
    const indexSubSection = monitor.getItem().indexSubSection;
    const hoverIndexRow = props.indexRow;
    const hoverIndexCol = props.indexCol;

    //Don't replace items with themselves
    if(dragIndexRow === hoverIndexRow && dragIndexCol === hoverIndexCol){
      return
    }
    props.moveAttribute(dragIndexRow, dragIndexCol, hoverIndexRow, hoverIndexCol, indexSection, indexSubSection);
    monitor.getItem().indexRow = hoverIndexRow;
    monitor.getItem().indexCol = hoverIndexCol;
  }

};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: "white",
  cursor: "move"
}

const opacity = "opacity: 0.3";

class AttributeItem extends Component {

  constructor(props){
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperAttributeRef = this.setWrapperAttributeRef.bind(this);
    this.setPopoverAttributeRef = this.setPopoverAttributeRef.bind(this);
    this.onResize = this.onResize.bind(this);
    this.setDivRef = this.setDivRef.bind(this);
    this.popoverAttributeRef = [];
    this.state={
      width: 330,
      height: 40,
      widthScale: 300,
      maxWidth: 0
    };
    this._boxContent = []
  }

  componentWillMount() {

  }

  setDivRef(indexCol,div) {
    this._boxContent[indexCol] = div;
  }

  componentDidMount() {
    let {column,rowWidth,indexCol,quantityColumns} = this.props;
    this.setWidth(rowWidth,column,indexCol);
    this.setMaxWidth(quantityColumns,rowWidth);
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    let {column,rowWidth,indexCol,quantityColumns} = nextProps;
    this.setWidth(rowWidth,column,indexCol);
    this.setMaxWidth(quantityColumns,rowWidth)
  }

  setMaxWidth(quantityColumns,rowWidth){
    let {widthScale} = this.state;
    let maxWidth = rowWidth;
    switch (quantityColumns) {
      case 1 : maxWidth = rowWidth - widthScale + 2; break;
      case 2 : maxWidth = rowWidth - widthScale - 23; break;
      case 3 : maxWidth = rowWidth - (2 * widthScale) + 23; break;
    }
    this.setState({maxWidth:maxWidth})
  }

  setWidth(rowWidth, column, indexCol){
    let widthScale = (rowWidth / 4);
    let classSection = column.col ? column.col : column.width;
    let _boxContent = this._boxContent[indexCol].getBoundingClientRect().width;
    switch (classSection) {
      case "col100" :this.setState({width:_boxContent - 20,widthScale:widthScale - 40}); break;
      case "col50" : this.setState({width:_boxContent - 20,widthScale:widthScale}); break;
      case "colR80":
      case "col75" : this.setState({width:_boxContent - 20,widthScale:widthScale + 35}); break;
      case "col25":
      case "colR25":
      case "colR20":
      case "col33": this.setState({width:_boxContent - 20,widthScale:widthScale + 35}); break;
      default :this.setState({width:_boxContent - 20,widthScale:widthScale + 35}); break;
    }
  }


  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  onChangePopoverAttribute = (id, indexSecSelected, indexRowSelected, indexColumnSelected,countRow,indexSubSection, e) => {
    e.stopPropagation()
    document.getElementById("root").style = opacity;
    this.props.onChangePopoverAttribute(id, indexSecSelected, indexRowSelected, indexColumnSelected,countRow,indexSubSection)
  }

  setWrapperAttributeRef(node) {
    this.wrapperAttributeRef = node;
  }

  setPopoverAttributeRef(attributeId, node) {
    this.popoverAttributeRef[attributeId] = node;
  }

  handleClickOutside(event) {
    event.preventDefault();
    if (this.wrapperAttributeRef
      && !this.wrapperAttributeRef.contains(event.target)
      && this.popoverAttributeRef
      && this.popoverAttributeRef[this.wrapperAttributeRef.id]
      && !this.popoverAttributeRef[this.wrapperAttributeRef.id].contains(event.target)) {
      this.props.onClosePopoverAttribute(this.wrapperAttributeRef.id)
    }
  };

  /*onResize(event, {element, size, handle}) {
    console.log("here####")
    let {isResizable} = true;
    if(isResizable){
      this.setState({width: size.width, height: size.height});
    }
  };*/

  onResize(width, classColumn, event, {element, size, handle}) {
    //event.stopPropagation();
    event.preventDefault();
    let {indexRow, indexCol, index, indexSubSection, quantityColumns} = this.props;
    if(size.width < width){
      switch (classColumn) {
        case "col100": this.props.changeWidth(indexRow,indexCol,index,indexSubSection,"col75"); break;
        case "colR80":
        case "col75" : this.props.changeWidth(indexRow,indexCol,index,indexSubSection,"col50"); break;
        case "colR50" :
        case "col50" : this.props.changeWidth(indexRow,indexCol,index,indexSubSection,"col25"); break;
        default: break;
      }
    }else{
      switch (classColumn) {
        case "colR80" :
        case "col75" : this.props.changeWidth(indexRow,indexCol,index,indexSubSection,(quantityColumns === 1) ? "col100": "col75"); break;
        case "col50" : if(quantityColumns === 1 || quantityColumns === 2) this.props.changeWidth(indexRow,indexCol,index,indexSubSection,"col75"); break;
        case "col33" : if(quantityColumns === 1 || quantityColumns === 2) this.props.changeWidth(indexRow,indexCol,index,indexSubSection,"col50"); break;
        case "col25" :
        case "colR25" :
        case "colR20" : this.props.changeWidth(indexRow,indexCol,index,indexSubSection,"col33"); break;
        default: break;
      }
    }

  };

  Item(attributeInfo,onDeleteAttributeSelected,index,indexRow,indexCol,indexSubSection,classSection,countRow,showPopoverAttribute,column,onDeleteAttributeNotSelected)  {
    let that = this;
    let {connectDragSource, connectDropTarget} = this.props;
    return(
      <div className="box-attribute-content">
        {
          attributeInfo.length > 0 ?
            connectDragSource(connectDropTarget(
              <div className={(attributeInfo[0].inputType==="LABEL")?"title-attribute-selected":"box-attribute-selected"}>
                <label>
                  {(attributeInfo[0].displayValue !== "")
                    ? ((attributeInfo[0].inputType && attributeInfo[0].inputType.toUpperCase() === "PARAGRAPH")
                      ? attributeInfo[0].displayValue.trim().split('\n')[0]
                      : attributeInfo[0].displayValue)
                    : attributeInfo[0].name}
                </label>
                <img className="icon-option icon-waste" src={pathServer.PATH_IMG + "waste-can.png"}
                     onClick={() => onDeleteAttributeSelected(attributeInfo[0].id, index, indexRow, indexCol,indexSubSection,classSection)}/>
                <img className="icon-option"
                     ref={that.setPopoverAttributeRef.bind(this,attributeInfo[0].id)}
                     onClick={(e) => this.onChangePopoverAttribute(attributeInfo[0].id, index, indexRow, indexCol,countRow,indexSubSection, e)}
                     src={pathServer.PATH_IMG + "ic_tune_24px.png"}/>
              </div>))
            :
            connectDragSource(connectDropTarget(
              <div className="box-attribute"
                   ref={that.setPopoverAttributeRef.bind(this,column.status)}
                   style={showPopoverAttribute[column.status] ? {border: "1px #62adf4 solid"} : null}
                   onClick={(e) => this.onChangePopoverAttribute(column.status, index, indexRow, indexCol,countRow,indexSubSection, e)}>
              <img className="icon-option icon-waste" src={pathServer.PATH_IMG + "waste-can.png"}
                   onClick={(e) => {e.stopPropagation(); onDeleteAttributeNotSelected(index, indexRow, indexCol,indexSubSection)}}/>
            </div>))
        }
      </div>
    )
  };

  render(){
    let {isDragging, indexCol, column, index, indexRow, indexSubSection, countRow, attributeInfo, showPopoverAttribute, form,
      onSelectAttribute, viewNewAttribute, onDeleteAttributeNotSelected, onDeleteAttributeSelected,dataSourceGroups,
      attributesSelected,allAttributesEdit,isResizable,rowWidth,quantityColumns} = this.props;
    const opacity = isDragging ? 0 : 1;
    let classSection = column.col ? column.col : column.width;
    let {width, height, widthScale, maxWidth} = this.state;
    let that = this;

    return <div key={indexCol} className={column.col ? column.col + " col-attribute-space" : column.width + " col-attribute-space"} style={{opacity}} ref={this.setDivRef.bind(this,indexCol)}>
        {
          isResizable ?
            <ResizableBox className="box" width={width} height={height}
                          onResize={this.onResize.bind(this,width,classSection)}
                          //onResizeStop={this.onResize.bind(this,width,classSection)}
                          minConstraints={[widthScale - 20, height]}
                          resizeHandles={['e']}
                          maxConstraints={[maxWidth, height]}
                          draggableOpts={{grid: [widthScale,widthScale]}} >
              {
                that.Item(attributeInfo,onDeleteAttributeSelected,index,indexRow,indexCol,indexSubSection,classSection,countRow,showPopoverAttribute,column,onDeleteAttributeNotSelected)
              }
            </ResizableBox> : that.Item(attributeInfo,onDeleteAttributeSelected,index,indexRow,indexCol,indexSubSection,classSection,countRow,showPopoverAttribute,column,onDeleteAttributeNotSelected)

        }
        {
          showPopoverAttribute[column.status] ?
            <AppendedMyComponent>
              <div id={column.status}
                   ref={that.setWrapperAttributeRef}>
                <FormSearchAttribute form={form}
                                     attributeInfo={attributeInfo}
                                     attributesSelected={attributesSelected}
                                     dataSourceGroups={dataSourceGroups}
                                     attributeByRow={3}
                                     indexSubSection={indexSubSection}
                                     onSelectAttribute={onSelectAttribute}
                                     viewNewAttribute={viewNewAttribute}
                                     allAttributesEdit={allAttributesEdit}/>
              </div>
            </AppendedMyComponent> : null
        }
      </div>
  }

}

export default flow(
  DragSource("AttributeItem", AttributeItemSource, collect),
  DropTarget("AttributeItem", AttributeItemTarget, (connect) => ({
    connectDropTarget: connect.dropTarget()
  }))
)(AttributeItem);