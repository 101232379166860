import React, {useEffect, useState} from 'react'
import {unstable_batchedUpdates} from 'react-dom'
import {ReactSVG} from 'react-svg'
import {browserHistory} from 'react-router'
//@ts-ignore
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
//@ts-ignore
import {loadReviewConfigurations} from '../../Api'
//@ts-ignore
import {FACEBOOK_KEY, GOOGLE_KEY, LOGO, GET_SOCIAL_NETWORK_PAGE} from '../../Constants'
//@ts-ignore
import {MESSAGES, SOCIAL_NETWORK_PREVIEW_CONFIGURATION} from './ReviewsTabSetting'
//@ts-ignore
import Loader from '../../../common/UI/Loader'


const barSearchHeight = window.innerWidth > 768 ? 60 : 40

interface ReviewPageProps {
  height: number
  location: any
}
const ReviewPagePreview = (props : ReviewPageProps) => {
  const {height} = props
  const [reviewPageTitle, setReviewPageTitle] = useState("")
  const [showButtonFacebook, setShowButtonFacebook] = useState(true)
  const [showButtonGoogle, setShowButtonGoogle] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const location = props.location || {}
  const {query} = location
  const {dealerId, userId} = query

  let { showBack } = location.state || {};

  console.log('height --->', height)

  const goBackToEdition = () => {
    browserHistory.push({pathname: `/main/reviews/dashboard/review`, state: {overlayActive: ''}});
  }

  useEffect(
    //@ts-ignore
    async () => {
      setIsLoading(true)
      const {responseCode, responseMessage, data} = await loadReviewConfigurations(dealerId)
      if (responseCode === SUCCESSFUL_CODE) {
        const reviewConfigurations = data?.reviewDealerConfigurations ?? []
        //@ts-ignore
        const reviewPageConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_PAGE)?.value ?? ''
        //@ts-ignore
        const reviewPageShowFacebook = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[FACEBOOK_KEY])?.value ?? ''
        //@ts-ignore
        const reviewPageShowGoogle = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[GOOGLE_KEY])?.value ?? ''
        unstable_batchedUpdates( () => {
          setShowButtonFacebook(reviewPageShowFacebook === "true")
          setShowButtonGoogle(reviewPageShowGoogle === 'true')
          setReviewPageTitle(reviewPageConfiguration)
          setIsLoading(false)
        })
      } else {
        setIsLoading(false)
        console.error(responseMessage)
      }
    }, []
  )

const onClickButton = async (socialNetwork: string) => {
  window.open(`/${GET_SOCIAL_NETWORK_PAGE}?dealerId=${dealerId}&userId=${userId}&socialNetwork=${socialNetwork}`, '_blank')
}

  return(
    <div className={'account-confirmation review-container'}>
      {isLoading ? <Loader className="loader-marketing"/> : null}
      <div className={'header'}>
        <img className={'logo'} src={`${pathServer.PATH_IMG}godealergo.png`} alt={'logo'} />
        {
          showBack && <a className={'link-back'} onClick={goBackToEdition}>Back to edition</a>
        }
      </div>
      <div className={'review-body'} style={height > 0 ? {height: height - barSearchHeight} : {}}>
        <div className={'review-title'}>
          <h1>{reviewPageTitle}</h1>
          <label>Please select an option</label>
        </div>

        <div className={'review-marketing'}>
          {
            showButtonFacebook &&
            <ReactSVG className={'icon-facebook'}
                      src={`${pathServer.PATH_IMG}logo_facebook.svg`}
                      beforeInjection={svg => svg.classList.add('icon-facebook-svg')}
                      onClick={() => onClickButton(FACEBOOK_KEY)}
            />
          }
          {
            showButtonGoogle &&
            <ReactSVG className={'icon-google'}
                      src={`${pathServer.PATH_IMG}icon/google_logo_color.svg`}
                      beforeInjection={ svg => svg.classList.add('icon-google-svg')}
                      onClick={() => onClickButton(GOOGLE_KEY)}
            />
          }
        </div>

        <div className={'review-footer'}>
          <label>Having trouble?</label>
          <a className={'link-contact-us'}>Contact us</a>
        </div>
      </div>
    </div>
  )
}

export default ReviewPagePreview
