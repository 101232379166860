import React from 'react'
import PropTypes from 'prop-types'
import {ReactSVG} from 'react-svg'
import SaleSettingColumnSection from './SaleSettingColumnSection'
import SaleSettingUpdateForm, {PROPERTY} from './SaleSettingUpdateForm'
import {Popover} from '../../util/ComponentUtils'
import {COLUMNS} from './SaleSetting'
import {DPSpinner} from '../../../common/DPSpinner'
import {pathServer} from '../../../../common/Constants'
import {getPosition} from '../../../../common/Util'

class SaleSettingColumn extends React.Component {
  constructor(props) {
    super(props);
    this.handleShowPopover = this.handleShowPopover.bind(this)
    this.state = {
      showUpdateForm: false,
      anchorEl: {},
      showPopover: false,
    }
  }

  // It lets show informational popover
  handleShowPopover(elementId, message = null) {
    const target = document.getElementById(elementId)
    const pointTo = getPosition(target)
    let anchorEl = {
      x: pointTo.x - 135,
      y: pointTo.y - 105,
      offsetWidth: 330,
    }

    if (elementId === 'questionDealerDocFee') {
      anchorEl = {
        x: pointTo.x - 35,
        y: pointTo.y - 855,
        offsetWidth: 330,
      }
    }

    this.setState(
      {
        messageOnPopover: message,
        showPopover: true,
        anchorEl: anchorEl
      }
    )
  }

  render() {
    const {
      height, column, sections, elements, goUp, goDown,
      deleteElement, editElement, addElement, isLoading,
      validateElement, classColumn, handleShowDeleteModal
    } = this.props
    const { messageOnPopover } = this.state
    return (
      <div key={column['columnId']} className="container-setting-column">
        <div
          className={classColumn ? classColumn : 'setting-column'}
          style={{minHeight: height}}
        >
          {
            isLoading
              ? <DPSpinner/>
              : <React.Fragment>
                <div className='column-header'>
                  <div className='header-title'>
                    <span className='title-desc'>
                      {column.title}
                      <ReactSVG
                        id='iconInfoID'
                        src={pathServer.PATH_IMG + 'icon/icon.svg'}
                        beforeInjection={svg => svg.classList.add('icon-info')}
                        onClick={() => this.handleShowPopover('iconInfoID')}
                        wrapper='span'
                      />
                    </span>
                  </div>
                  <div className='header-options'>
                    { column && column.id !== COLUMNS.MORE_SETTINGS
                      ? <ReactSVG
                        src={pathServer.PATH_IMG + 'icon/union-43.svg'}
                        beforeInjection={svg => svg.classList.add('add-option')}
                        onClick={() => this.setState({showUpdateForm: true})}
                      />
                      : null
                    }
                  </div>
                </div>

                {/* Displaying all sections from this column */}
                {
                  sections.map((section, indexSection) => {
                    const sectionElements = section.elementIds.map(elementId => elements[elementId])
                    const isLastSection = indexSection === (sections.length - 1)
                    return (
                      <SaleSettingColumnSection
                        key={section.id}
                        isLastSection={isLastSection}
                        section={section}
                        elements={sectionElements}
                        goUp={goUp}
                        goDown={goDown}
                        deleteElement={deleteElement}
                        editElement={editElement}
                        validateElement={validateElement}
                        columnName={column.id}
                        handleShowPopover={this.handleShowPopover}
                        handleShowDeleteModal={handleShowDeleteModal}
                      />
                    )
                  })
                }

                {/* Displaying form to add a new element */}
                {
                  this.state.showUpdateForm
                    ? <SaleSettingUpdateForm
                      element={
                        {
                          [PROPERTY.NAME]: '',
                          [PROPERTY.CODE]: '',
                        }
                      }
                      closeForm={() => this.setState({showUpdateForm: false})}
                      updateElement={addElement}
                      validateElement={validateElement}
                      columnName={column.id}
                    />
                    : null
                }

                {/* Displaying info popover */}
                {
                  this.state.showPopover
                    ? <Popover
                      anchorEl={this.state.anchorEl}
                      child={
                        messageOnPopover
                          ? <div>{messageOnPopover}</div>
                          : column.id === COLUMNS.FILES
                            ? <div>Here you’ll set the needed files to complete a sale. Drag them to top to make them
                              required.</div>
                            : column.id === COLUMNS.CHECKLIST
                              ? <div>Help the salesmen to make sure they have completed everything before completing a sale.
                                Drag them to top to make them required.</div>
                              : column.id === COLUMNS.ACCESSORY
                                ? <div>Help the salesmen to sell extra accessories easily by listing them.</div>
                                : <div>Here you will set other configurations
                                  related to the Sale Process.</div>
                      }
                      classPopover='sale-setting-column-title-popover'
                      handleRequestClose={() => this.setState({showPopover: false})}
                    />
                    : null
                }
              </React.Fragment>
          }
        </div>
      </div>

    )
  }
}

SaleSettingColumn.propTypes = {
  height: PropTypes.number,
  column: PropTypes.object,
  sections: PropTypes.array,
  elements: PropTypes.object,
  goUp: PropTypes.func,
  goDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElement: PropTypes.func,
  addElement: PropTypes.func,
  className: PropTypes.object,
  handleShowDeleteModal: PropTypes.func,
  validateElement: PropTypes.func,
}

export default SaleSettingColumn