import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import {ReactSVG} from 'react-svg'
import {EXT_IMG, EXT_OTHER_IMG, EXT_PNG, pathServer} from '../../../common/Constants'
import Dropzone from 'react-dropzone'
import DPCarousel from './DPCarousel'
import {useReactToPrint} from 'react-to-print'
import {deleteItemArray} from '../../newComponents/util/Utils'
import DPButton from '../DPButton'
import {useScreenSize} from '../../../common/hooks/useScreenSize'
import DPAlertMessage from '../../../common/genericComponents/DPAlertMessage'
import DPButtonText from '../../../common/DPButtonText'
require('./DPModalPreview.scss')

const customStyles = {
    overlay: {
        zIndex: 100,
        background: 'rgba(35, 35, 35, 0.93)',
    },
    content: {
        top: '0',
        left: '0',
        right: "0",
        position: 'absolute',
        padding: '0',
        width: '100%',
        border: 'none',
        background: 'transparent',
        bottom: "0",
        outline: "none",
        overflow: "hidden",
        borderRadius: 0,
    }
};

const StyledModal = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 42px 70px 1fr;
    .user-modal-header{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 100px 1fr;
        padding: 1% 50px;
    } 
    b{
       color:white;
       font-weight: 400;
    }
    .user-modal-body{
 
    }
    h6{
        margin:0;
        font-size:12px;
        color:white;
        text-align:center;
    }
    .options-back{
        padding-top:16px;
        display:grid;
        grid-gap: 10px;
        grid-template-columns: 20px 20px auto;
        z-index: 200;
        h6{
            padding-top:2px;
        }
        
    }
    .options-paginator{
        padding-top:16px;
        width: 100px;
        display: flex;
        justify-content: center;
    }
    .options-edit{
        grid-gap: 12px;
        display: flex;
        justify-content: end;
        svg{
            fill: #FFFFFF;
        }
    }
    svg{
        width: 12px;
        height: auto;
        //fill: #FFFFFF;
        &:hover{
            cursor:pointer;
        }
    }
    .icon-dropzone{
        width: 12px;
       &:hover{
            cursor:pointer;
        }
    }
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
`;

const pageStyle = `
  @page {
    left: 0;
    top: 0;
    bottom: 0;
    size: 220mm 240mm;
    position: absolute;
    // size: auto;
    overflow: none;
    margin: 20mm;
  }
`;

const DPModalPreview = React.forwardRef(({showRecipe, closeModal, onDropPhoto, receiptsImagesPrev, receipts,deleteReceipt, onHide, ...props}, ref) => {
    const [index, setIndex] = useState(0);
    const [receiptsImages, setReceipts] = useState([]);
    const [hasDelete, setHasDelete] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const { width } = useScreenSize();

    const receiptSelected = receiptsImages.find( (r,i) => i === (index));

    const componentRef = useRef();
    const onDrop = (file) => {
        const imageUrl = URL.createObjectURL(file[0])
        setReceipts([...receiptsImages, {path: file[0].path, url: imageUrl ,name: file[0].name, file: file[0]}])
        onDropPhoto(file[0])
    };

    const downloadImage = () => {
        if(receiptsImages.length > 0) {
            const receiptSelected = receiptsImages.find( (r,i) => i === (index));
            if(receiptSelected.name){
                const link = document.createElement('a');
                link.href = receiptSelected.url;
                link.download = receiptSelected.name;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else{
                alert('You need to save first the image to get download image');
            }

        } else{
            alert('You need to load some receipts to download image');
        }

    };

    const decreaseIndex = () => {
        const newIndex = index - 1;
        setIndex(newIndex);
    };

    const onDeleteReceipt = () => {
        const receiptSelected = receiptsImages.find( (r,i) => i === (index));
        if(receiptSelected.id){
                const answer = window.confirm( 'Are you sure to delete the receipt selected?');
                if(answer){
                    const newReceipts= deleteItemArray(receiptSelected.id,receiptsImages);
                    index !== 0 && decreaseIndex();
                    setReceipts(newReceipts);
                    setHasDelete(true);
                    deleteReceipt(receiptSelected.id,receiptsImages.length);
                }
        }
        else {
            alert('You need to save first the image to delete');
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
        pageStyle: pageStyle
    });

    const handleHide = () => {
        if(existsReceiptToSave){
            setShowAlertMessage(true);
        }else {
            onHide();
        }
    }

    useEffect(()=> {
        if(receiptsImagesPrev.length > 0){
            setReceipts(receiptsImagesPrev);
        }
    },[receiptsImagesPrev]);

    useEffect(() => {
        if(receipts.length > 0){
            setReceipts([...receipts])
        }
    }, [receipts]);

    const existsReceiptToSave = receiptsImages.some( receipt => !receipt.id);

    return (
        <Modal isOpen={showRecipe} style={customStyles} contentLabel="New Messages"
               aria={{labelledby: "heading", describedby: "full_description"}}
               ariaHideApp={false} backdrop="static">
            <StyledModal {...props} ref={ref}>
                <DPAlertMessage
                  textComponent={'Review the images and save them before leaving this page.'}
                  showIcon={false}
                  style={{borderRadius: 0, justifyContent: 'center', fontSize: width > 500 ? '13px' : '12px'}}
                />
                <div className="user-modal-header modal-receipt-header">
                    <div className="options-back">
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_back.svg"}
                                  beforeInjection={svg => svg.classList.add('icon-back')} onClick={handleHide}/>
                        {receiptSelected ?
                            <>
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_insert_photo_24px.svg"}
                                          beforeInjection={svg => svg.classList.add('icon-image')}/>
                                <b>{receiptSelected.name}</b>
                            </>
                        : ''}
                    </div>
                    <div className="options-paginator">
                        <h6>
                            {receiptsImages.length > 0 && (index + 1) + " / " + receiptsImages.length}
                        </h6>
                    </div>
                    <div className="options-edit">
                        <div/>
                        {
                          receiptSelected?.id &&
                          <div>
                              <ReactSVG src={pathServer.PATH_IMG + "icon/download-tray.svg"} onClick={downloadImage}
                                        beforeInjection={svg => svg.classList.add("icon-info")}
                                        className="header-download-icon"/>
                          </div>
                        }
                        <div>
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_print.svg"} onClick={handlePrint}
                                      beforeInjection={svg => svg.classList.add("icon-info")}
                                      className="header-download-icon"/>
                        </div>
                        {
                            receiptSelected?.id &&
                          <div>
                              <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"} onClick={onDeleteReceipt}
                                        beforeInjection={svg => svg.classList.add("icon-info")}
                                        className="header-download-icon"/>
                          </div>
                        }
                        <div>
                            <Dropzone id="dropZone" onDrop={onDrop}
                                      accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`} multiple={true}>
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()} className="dropzone-container">
                                        <input {...getInputProps()} />
                                        <img src={pathServer.PATH_IMG + "ic_add.png"} className="icon-dropzone"/>
                                    </div>
                                )}
                            </Dropzone>

                        </div>
                        <DPButton
                          disabled={!existsReceiptToSave}
                          className="btn-save"
                          width={width > 500 ? '67px' : '60px'}
                          style={ !existsReceiptToSave ? {backgroundColor: '#a4afb7'} : {}}
                          onClick={() => closeModal(receiptsImages)}>Save</DPButton>
                    </div>
                </div>
                {
                    showAlertMessage &&
                  <div className="container-alert">
                      <DPAlertMessage
                        textComponent={
                            <div style={{display: 'flex', fontSize: '13px', alignItems: 'center'}}>
                                Do you want to leave without saving?
                                <DPButtonText textButton="YES" textColor={'#FFF'} fontSizeText="13px" onClick={() => { onHide()
                                setReceipts([])}}/>
                                <span>/</span>
                                <DPButtonText textButton="NO" textColor={'#FFF'} fontSizeText="13px" onClick={() => setShowAlertMessage(false)}/>
                            </div>}
                      />
                  </div>

                }
                <div className="user-modal-body">
                    <DPCarousel updateIndex={(index) => setIndex(index)}
                                hasDelete={hasDelete}
                                reloadDelete={()=>setHasDelete(false)}
                                className="receipts-images"
                                iconNext={'keyboard_arrow_right.svg'}
                                iconBack={'keyboard_arrow_left.svg'}
                    >
                        {
                            receiptsImages.map((r, i) => {
                                return (
                                    <div key={index + i} ref={ i === index ? componentRef : null } className="container-image">
                                        <img alt="" className="image-object-fit-no-pointer" src={r.url ? r.url : pathServer.PATH_IMG + "receipts3.png"}/>
                                    </div>
                                )
                            })
                        }
                    </DPCarousel>
                </div>
            </StyledModal>
        </Modal>
    )
})
;


DPModalPreview.displayName = 'DPModalPreview';

DPModalPreview.defaultProps = {
    showRecipe: false,
    closeModal: () => {},
    onDropPhoto: () => {},
    receipts: [],
    deleteReceipt: () => {},
    receiptsImagesPrev: [],
};

DPModalPreview.propTypes = {
    showRecipe: PropTypes.bool,
    closeModal: PropTypes.func,
    onDropPhoto: PropTypes.func,
    receipts: PropTypes.array,
    deleteReceipt: PropTypes.func,
    receiptsImagesPrev : PropTypes.array,
};

export default DPModalPreview;