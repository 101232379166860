import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  ATTRIBUTE_CUSTOMER_EMAIL,
  ROWS_IMPORT_BY_PAGE_BY_DEFAULT,
  ATTRIBUTE_CUSTOMER_CELLPHONE,
  ATTRIBUTE_CODE_CAR_YEAR,
  ATTRIBUTE_CODE_SUGGESTED_PRICE,
  ATTRIBUTE_CUSTOMER_STATE,
  siteColorAdmin, ATTRIBUTE_CUSTOMER_DOBMDY, dateFormats
} from '../../Constants'
import {
  pathServer,
  optionState,
  VALIDATION_VIN,
  TIME_FIVE_SECONDS, SUCCESSFUL_CODE
} from '../../../common/Constants'
import {ReactSVG} from 'react-svg'
import {Modal} from 'react-bootstrap'
import {DPPill} from './ComponentUtils'
import {DPPopover} from '../../../common/DPPopover'
import {importCSVFile, loadFormByCode} from '../../Api'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
import {checkValidPhone, checkValidVIN, checkValidEmail} from '../../../common/ValidationUtil'
import {getAnchorEl, isFloat, isYear} from './Utils'
import {DPPagination} from '../../../common/DPPagination'

export const CONSTANTS_IMPORT = {
  DROPDOWN: 'dropDown',
  ERROR: 'error',
  IMPORTED: 'imported',
  CODE: 'car.',
  VIN: 'vin',
  LOADING: 'loading'
};

function getMessageUpdate(rowKey, entityType) {
  return `According to the ${getDisplayRowKey(rowKey)}, this ${entityType} is already in the system, it was updated.`
}

function getDisplayRowKey(rowKey) {
  switch (rowKey) {
    case CONSTANTS_IMPORT.VIN : return 'VIN Number'
    case ATTRIBUTE_CUSTOMER_EMAIL : return 'Email'
    default : return ''
  }
}

const IMPORT_STATUS = {
  INSERT: 'insert',
  UPDATED: 'updated',
  ERROR: 'error',
  TOTAL: 'total'
};

export const HeaderOptions = ({columnsToAssign,headerSelected,onClickPopover,indexColumn}) => {

  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {
            columnsToAssign.map((column,index) => {
              let className = "change-submenu-item"
              if(!isEmpty(headerSelected)){
                className = (column.id === headerSelected.id) ?  "item-selected" : "change-submenu-item";
              }
              return(
                <li key={index} className={className} onClick={() => onClickPopover(column.id, indexColumn)} data-test={column.id}>
                  <span className="user-name">{column.displayValue}</span>
                  {
                    column && column.required ?
                      <span className="label-error">*</span> : null
                  }

                </li>
              )
            })
          }
        </u>
      </div>
    </div>
  )
};

function validateDropDown(headerSelected, columns){
  let error = false;
  columns.forEach(column => {
    const found = headerSelected.filter(x => x.id === column.id);
    if(found.length === 0 && !error){
      error = true
    }
  })

  return error
}

function validateDuplicateDropDown(columns) {
  let errorDuplicate = false;
  columns.forEach(column => {
    if(column.id !== ""){
      const foundColumn = columns.filter(x => x.id === column.id)
      if(foundColumn.length > 1){
        errorDuplicate = true
      }
    }
  })

  return errorDuplicate
}

function getMessageError(error,columns) {
  let message = "Import failed because of data issues";
  let attributes = "";
  let foundFirstError = false;
  Object.keys(error).forEach(key => {
    if(key === "infoAttributeValues"){
      error[key].forEach(info => {
        const code = columns.filter(column => column.id === info.code);
        if(!foundFirstError){
          attributes = attributes.concat(code[0].displayValue);
          foundFirstError = true;
        }else{
          attributes = attributes.concat(", ", code[0].displayValue);
        }

      })
    } else{
      if(!isEmpty(error[key]) && key !== "id"){
        const code = columns.filter(column => column.id === key);
        if(!foundFirstError){
          attributes = attributes.concat(code[0].displayValue);
          foundFirstError = true;
        }else{
          attributes = attributes.concat(" ,", code[0].displayValue);
        }
      }
    }
  })

  message = message.concat("(",attributes,")")

  return message
}

function sortList(columnsToAssign, key) {
  columnsToAssign.sort((a,b) => {
    if(a[key] > b[key]) return 1;
    else if (a[key] < b[key]) return -1;
    return 0
  })

  return columnsToAssign
}

const FIELD_TO_SORT = {
  VALUE: 'displayValue',
  INDEX: 'index'
};

function validateErrors(dataRow, columns, rowKey){
  let existError = false;
  const attributesWithError = [];
  dataRow.data.forEach((data, index) => {
    if(columns[index].required){
      if(!data || data && isEmpty(data.trim())){
        existError = true;
        attributesWithError.push(columns[index].id)
      } else if(columns[index].id === rowKey){
        if(columns[index].id === ATTRIBUTE_CUSTOMER_EMAIL){
          const existErrorEmail = !checkValidEmail(data)
          if(existErrorEmail){
            existError = true;
            attributesWithError.push(columns[index].id)
          }
        } else if(columns[index].id === VALIDATION_VIN){
          const existErrorVin = !checkValidVIN(data)
          if(existErrorVin){
            existError = true;
            attributesWithError.push(columns[index].id)
          }
        }
      } else if(columns[index].id === ATTRIBUTE_CUSTOMER_CELLPHONE){
        const existErrorPhone = !checkValidPhone(data);
        if(existErrorPhone){
          existError = true;
          attributesWithError.push(columns[index].id)
        }
      } else if(columns[index].id === ATTRIBUTE_CODE_CAR_YEAR){
        const existErrorYear = !isYear(data);
        if(existErrorYear){
          existError = true;
          attributesWithError.push(columns[index].id)
        }
      } else if(columns[index].id === ATTRIBUTE_CODE_SUGGESTED_PRICE){
        const existErrorPrice = !isFloat(data);
        if(existErrorPrice){
          existError = true;
          attributesWithError.push(columns[index].id)
        }
      }
    }
  })

  return {existError: existError, attributesWithError: attributesWithError}
}

function getAttributesWithError(rowKey, attributes) {
  let attributesWithError = ""
  let foundFirstError = false;
  rowKey.forEach(key => {
    const attribute = attributes.find(x => x.name === key)
    if(!foundFirstError){
      attributesWithError = attributesWithError.concat(attribute.displayValue);
    } else{
      attributesWithError = attributesWithError.concat(" ,", attribute.displayValue);
    }
  })

  return attributesWithError
}

function getStateCode(data) {
  let stateCode = ""
  const state = optionState.find(x => x.displayValue.toUpperCase() === data.toUpperCase())
  if(!isEmpty(state))
    stateCode = state.id
  else
    stateCode = data

  return stateCode
}

function getDisplayValueCustomByCode(code, displayValue) {
  let value = displayValue
  if (code === ATTRIBUTE_CUSTOMER_DOBMDY)
    value += `(${dateFormats.MM_DD_YYYY})`

  return value
}

export default class ModalViewDataImport extends Component {

  constructor(){
    super();
    this.state = {
      columnsToAssign: [],
      showModalData: false,
      files: null,
      showMessage: false,
      showPopover: false,
      showMessageImport: false,
      errorDropDown: false,
      totalRows: [],
      totalRowsFiltered: [],
      nameFile: null,
      rows: [],
      columns: [],
      errorDuplicateDropDown: false,
      errorObjects: [],
      saveObjects: [],
      headerSelected: [],
      currentPage: 1,
      updateObjects: [],
      filterType: null,
      totalColumnsToAssign: [],
      rowsKeyIndex: []
    }
  }

  componentDidMount(){
    const {totalRows, columns, formCodeToImport, rowsNumberToShow} = this.props;
    const rowsImport = rowsNumberToShow ? rowsNumberToShow : ROWS_IMPORT_BY_PAGE_BY_DEFAULT;
    const totalPages = totalRows.length > 0 ? Math.ceil(totalRows.length / rowsImport) : 1;
    const newTotalRows = [...totalRows]
    const rows = newTotalRows.splice(0,rowsImport);
    let columnsToAssignRequired = [];
    let columnsToAssignNotRequired = [];
    loadFormByCode(formCodeToImport,"new").then(response => {
      const {responseCode, data, responseMessage} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {form} = data
        const {formAttributes: {attributes}} = form
        let found = false;
        attributes.forEach(item => {
          if(item.id !== "id" && item.displayValue !== ""){
            if(item.validation && item.validation.length > 0){
              item.validation.forEach(itemValidation => {
                if(itemValidation.type === "required"){
                  //update display message to date
                  columnsToAssignRequired.push({id: item.name, displayValue: getDisplayValueCustomByCode(item.name, item.displayValue), required: true})
                  found = true;
                }
              })
              if(!found){
                columnsToAssignNotRequired.push({id: item.name, displayValue: item.displayValue, required: false})
              }
            } else{
              columnsToAssignNotRequired.push({id: item.name, displayValue: item.displayValue, required: false})
            }
          }
          found = false;
        })
        columnsToAssignRequired = sortList(columnsToAssignRequired, FIELD_TO_SORT.VALUE)
        columnsToAssignNotRequired = sortList(columnsToAssignNotRequired, FIELD_TO_SORT.VALUE)
        const columnsToAssign = [...columnsToAssignRequired,...columnsToAssignNotRequired].map( (item, index) => { return {...item, index}})
        this.setState({totalRows, columns, rows, type: CONSTANTS_IMPORT.LOADING, numberRows: totalRows.length,
          totalPages, columnsToAssign, totalColumnsToAssign: columnsToAssign, rowsNumberToShow: rowsImport, attributes});
      }else{
        console.error(responseMessage)
      }
    })
  }

  handleRequestClose = () => {
    this.setState({showMessage: false, showPopover: false, showMessageImport: false});
  }

  closeModalView = () => {
    this.setState({files: null, showMessage: false, showPopover: false, showMessageImport: false,
      errorDropDown: false, totalRows: [], totalRowsFiltered: [], nameFile: null, rows: [], columns: [],
      errorDuplicateDropDown: false, errorObjects: [], saveObjects: [], filterType: null}, () => {
      this.props.onHide();
    });
  }

  handlePageChange = (nextPage, totalPages) => {
    const {totalRows, totalRowsFiltered, filterType, rowsNumberToShow} = this.state;
    let currentPage = nextPage;
    let from;
    let newTotalRows = [];
    if(isEmpty(filterType)){
      newTotalRows = [...totalRows]
    } else {
      newTotalRows = [...totalRowsFiltered]
    }
    if (totalPages > 1) {
      if (currentPage > totalPages) {
        currentPage = 1;
        from = 0;
      } else if (currentPage === 0) {
        currentPage = totalPages;
        from = rowsNumberToShow * (totalPages - 1);
      } else {
        from = rowsNumberToShow * (currentPage - 1);
      }
      const rows = newTotalRows.splice(from,rowsNumberToShow)
      this.setState({currentPage: currentPage, from, rows: rows})
    }
  }

  renderDropDownFilter = (indexColumn, type, message, event) =>{
    event.preventDefault();
    const target = event.currentTarget;
    const panel = document.getElementById("panelMiddle")
    let anchorEl = {};
    let childError;
    if(type === CONSTANTS_IMPORT.DROPDOWN){
      anchorEl = getAnchorEl(target, 2);
      anchorEl.x = anchorEl.x - panel.scrollLeft;
    } else{
      anchorEl = getAnchorEl(target, -27);
      anchorEl.x = anchorEl.x + 30;
      anchorEl.y = anchorEl.y - panel.scrollTop;
      childError = <label>{message}</label>
    }

    this.setState({showPopover: true, anchorEl: anchorEl, indexColumn, typePopover: type, childError: childError})
  }

  onClickPopover = (id,indexColumn) => {
    let {headerSelected, columnsToAssign, columns, totalColumnsToAssign} = this.state;

    let newColumnsToAssign = [...columnsToAssign];
    let newElement = {}
    const idToRecover = columns[indexColumn].id;
    let newHeaderSelected = [...headerSelected];

    newColumnsToAssign.forEach(column => {
      if (column.id === id){
        newElement = column;
      }
    });

    newColumnsToAssign = newColumnsToAssign.filter(x => x.id !== id)

    if(columns[indexColumn].value !== '-'){
      const listColumnsToAssign = [...totalColumnsToAssign];
      const newColumn = listColumnsToAssign.find(x => x.id === idToRecover);
      newColumnsToAssign.push(newColumn);
      const indexElement = newHeaderSelected.findIndex(x => x.id === idToRecover);
      if(indexElement !== -1){
        newHeaderSelected.splice(indexElement,1);
      }
    }

    columns[indexColumn].value = newElement.displayValue;
    columns[indexColumn].id = newElement.id;
    columns[indexColumn].required = newElement.required;

    newHeaderSelected.push(newElement);

    newColumnsToAssign = sortList(newColumnsToAssign,FIELD_TO_SORT.INDEX);

    this.setState({headerSelected: newHeaderSelected, showPopover: false, columns, columnsToAssign: newColumnsToAssign})
  }

  importData = async () => {
    const {headerSelected, totalRows, columns, totalColumnsToAssign, rowsNumberToShow, attributes} = this.state;
    const columnsToAssign = [...totalColumnsToAssign];
    const columnRequired = columnsToAssign.filter(x => x.required === true);
    const errorDropDown = validateDropDown(headerSelected, columnRequired);
    const errorDuplicateDropDown = validateDuplicateDropDown(columns);

    const bodyUpload = {};
    const bodyRows = [];
    let indexId = 0;
    const rowsKeyIndex = [];
    let valueKeyIsEmpty;
    const {importKey,entityType,rowKey,formCodeToImport} = this.props;
    if(!errorDropDown && !errorDuplicateDropDown){
      totalRows.forEach((row,indexRow) => {
        const body = {};
        const infoAttribute = [];
        const dataRow = row[0];
        valueKeyIsEmpty = false;
        const errors = validateErrors(dataRow, columns, rowKey)
        if(!errors.existError){
          dataRow.data.forEach((data, index) => {
            const indexHeader = columns[index].id.indexOf(`${entityType}.`);
            if(indexHeader === 0){
              if(columns[index].id === ATTRIBUTE_CUSTOMER_STATE){
                const stateCode = getStateCode(data)
                infoAttribute.push({id:columns[index].id, value: stateCode ? stateCode : ''})
              } else{
                infoAttribute.push({id:columns[index].id, value: data ? data : ''})
              }
            } else{
              body[columns[index].id] = data ? data : ''
            }
          })
          body.infoAttribute = infoAttribute;
          bodyRows.push(body);
        } else{
          rowsKeyIndex.push({indexRow:indexRow, attributesWithError:errors.attributesWithError})
        }
      })
      indexId = findIndex(totalRows[0][0].data, (data,index) => { return columns[index].id === rowKey })

      bodyUpload[importKey] = bodyRows;
      bodyUpload.updateExisting = true;
      bodyUpload.formCode = formCodeToImport ? formCodeToImport : null;
      this.setState({isLoading: true})
      const {responseCode, responseMessage, data} = await importCSVFile(bodyUpload,importKey)
        if (responseCode === SUCCESSFUL_CODE) {
          const {errorObjects, saveObjects, updateObjects} = data;

          const newTotalRows = [];
          if ((errorObjects && errorObjects.length > 0) || rowsKeyIndex.length > 0) {
            totalRows.forEach((row, indexRow) => {
              const dataRow = row[0];
              const rowsFiltered = [];
              const rowError = errorObjects.find(error => (error.id === dataRow.data[indexId] || isEmpty(dataRow.data[indexId])));
              const rowKeyIndexFound = findIndex(rowsKeyIndex, (x) => {
                return x.indexRow === indexRow
              });
              if (isEmpty(rowError) && rowKeyIndexFound === -1) {
                const update = updateObjects.find(vin => vin === dataRow.data[indexId]);
                if (isEmpty(update)) {
                  rowsFiltered.push({
                    index: dataRow.index,
                    data: dataRow.data,
                    message: "",
                    status: IMPORT_STATUS.INSERT
                  })
                } else {
                  rowsFiltered.push({
                    index: dataRow.index,
                    data: dataRow.data,
                    message: getMessageUpdate(rowKey, entityType),
                    status: IMPORT_STATUS.UPDATED
                  })
                }
              } else {
                //todo: row is empty
                let messageError = '';
                if (rowKeyIndexFound !== -1)
                  messageError = `Import failed because of data issues(${getAttributesWithError(rowsKeyIndex[rowKeyIndexFound].attributesWithError, attributes)})`;
                else
                  messageError = getMessageError(rowError, columnsToAssign);

                rowsFiltered.push({
                  index: dataRow.index,
                  data: dataRow.data,
                  message: messageError,
                  status: IMPORT_STATUS.ERROR
                })
              }
              newTotalRows.push(rowsFiltered)
            })
          } else {
            totalRows.forEach(row => {
              const rowsFiltered = [];
              const dataRow = row[0];
              const update = updateObjects.find(vin => vin === dataRow.data[indexId]);
              if (isEmpty(update)) {
                rowsFiltered.push({index: dataRow.index, data: dataRow.data, message: "", status: IMPORT_STATUS.INSERT})
              } else {
                rowsFiltered.push({
                  index: dataRow.index,
                  data: dataRow.data,
                  message: getMessageUpdate(rowKey, entityType),
                  status: IMPORT_STATUS.UPDATED
                })
              }
              newTotalRows.push(rowsFiltered)
            })
          }

          const copyTotalRows = [...newTotalRows];
          const rows = copyTotalRows.splice(0, rowsNumberToShow);

          this.setState({
            type: CONSTANTS_IMPORT.IMPORTED,
            errorObjects,
            saveObjects,
            isLoading: false,
            totalRows: newTotalRows,
            rows: rows,
            updateObjects,
            rowsKeyIndex: rowsKeyIndex
          })
        } else {
          console.error('error->', responseMessage)
          this.setState({isLoading: false})
      }
      /*
      importCSVFile(bodyUpload,importKey).then(response => {
        const {errorObjects, saveObjects, updateObjects} = response.data;

        const newTotalRows = [];
        if((errorObjects && errorObjects.length > 0) || rowsKeyIndex.length > 0){
          totalRows.forEach((row,indexRow) => {
            const dataRow = row[0];
            const rowsFiltered = [];
            const rowError = errorObjects.find(error => (error.id === dataRow.data[indexId] || isEmpty(dataRow.data[indexId])));
            const rowKeyIndexFound = findIndex(rowsKeyIndex,(x) => { return x.indexRow === indexRow });
            if(isEmpty(rowError) && rowKeyIndexFound === -1){
              const update = updateObjects.find(vin => vin === dataRow.data[indexId]);
              if(isEmpty(update)){
                rowsFiltered.push({index: dataRow.index, data: dataRow.data, message: "", status: IMPORT_STATUS.INSERT})
              } else{
                rowsFiltered.push({index: dataRow.index, data: dataRow.data, message: getMessageUpdate(rowKey,entityType), status: IMPORT_STATUS.UPDATED})
              }
            } else {
              //todo: row is empty
              let messageError = '';
              if(rowKeyIndexFound !== -1)
                messageError = `Import failed because of data issues(${getAttributesWithError(rowsKeyIndex[rowKeyIndexFound].attributesWithError, attributes)})`;
              else
                messageError = getMessageError(rowError, columnsToAssign);

              rowsFiltered.push({index: dataRow.index, data: dataRow.data, message: messageError, status: IMPORT_STATUS.ERROR})
            }
            newTotalRows.push(rowsFiltered)
          })
        } else {
          totalRows.forEach(row => {
            const rowsFiltered = [];
            const dataRow = row[0];
            const update = updateObjects.find(vin => vin === dataRow.data[indexId]);
            if(isEmpty(update)){
              rowsFiltered.push({index: dataRow.index, data: dataRow.data, message: "", status: IMPORT_STATUS.INSERT})
            } else{
              rowsFiltered.push({index: dataRow.index, data: dataRow.data, message: getMessageUpdate(rowKey,entityType), status: IMPORT_STATUS.UPDATED})
            }
            newTotalRows.push(rowsFiltered)
          })
        }

        const copyTotalRows = [...newTotalRows];
        const rows = copyTotalRows.splice(0,rowsNumberToShow);

        this.setState({type: CONSTANTS_IMPORT.IMPORTED, errorObjects, saveObjects, isLoading: false, totalRows: newTotalRows, rows: rows, updateObjects,
          rowsKeyIndex: rowsKeyIndex})
      }).catch(error => {
        console.error(error)
        this.setState({isLoading: false})
      })
      */

    } else {
      this.setState({errorDropDown: errorDropDown, errorDuplicateDropDown: errorDuplicateDropDown}, () => {
        setTimeout( () => {
          this.setState({errorDropDown:false, errorDuplicateDropDown: false});
        }, TIME_FIVE_SECONDS);
      })
    }
  }

  showMessageImport = (evt) => {
    evt.stopPropagation();
    let pointTo = getAnchorEl(evt.currentTarget,0);
    let anchorEl = {
      x: pointTo.x + 20,
      y: pointTo.y - 65,
      offsetWidth: 300
    };
    this.setState({showMessageImport: true, anchorEl})
  }

  filterRowsError = (type) => {
    const {totalRows, filterType, totalRowsFiltered, rowsNumberToShow} = this.state;
    let newTotalRowsFiltered = []
    let rows = [];
    if(filterType !== type && type !== IMPORT_STATUS.TOTAL){
      newTotalRowsFiltered = totalRows.filter(row => row[0].status === type);
    } else if(type === IMPORT_STATUS.TOTAL){
      newTotalRowsFiltered = totalRows;
    } else{
      newTotalRowsFiltered = totalRowsFiltered;
    }
    const totalPages = newTotalRowsFiltered.length > 0 ? Math.ceil(newTotalRowsFiltered.length / rowsNumberToShow) : 1;
    const newTotalRows = [...newTotalRowsFiltered];
    rows = newTotalRows.splice(0,rowsNumberToShow);
    this.setState({rows: rows, totalRowsFiltered: newTotalRowsFiltered, totalPages: totalPages, filterType: type})
  }

  handleDeleteItem = (evt) => {
    evt.stopPropagation();
    const {headerSelected, columns} = this.state;
    let {columnsToAssign} = this.state
    const idSelected = evt.target.id
    let newColumns = [...columns];
    let newHeaderSelected = [...headerSelected];

    const newHeader = newHeaderSelected.find(x => x.id === idSelected);
    columnsToAssign.push(newHeader);
    const indexElement = newHeaderSelected.findIndex(x => x.id === idSelected);
    if(indexElement !== -1){
      newHeaderSelected.splice(indexElement,1);
    }

    const indexColumn = newColumns.findIndex(x => x.id === idSelected);

    newColumns[indexColumn].value = "-"
    newColumns[indexColumn].id = "";
    newColumns[indexColumn].required = "";

    columnsToAssign = sortList(columnsToAssign,FIELD_TO_SORT.INDEX);

    this.setState({columns: newColumns, columnsToAssign, headerSelected: newHeaderSelected})
  }

  render() {
    const {show, child, nameFile} = this.props;
    const {isLoading, rows, showMessage, anchorEl, columns, totalPages, currentPage, showPopover, columnsToAssign,
      headerSelected, indexColumn, type, errorDropDown, errorObjects, saveObjects, numberRows, updateObjects,
      typePopover, errorDuplicateDropDown, rowsKeyIndex} = this.state;
    let numberRow = "";
    const importedSuccess = saveObjects.length;
    const importedError = errorObjects.length + rowsKeyIndex.length;
    const importedUpdated = updateObjects.length;
    let errorMessage = ""
    if(errorDropDown){
      errorMessage = "Please select all required fields."
    } else if(errorDuplicateDropDown){
      errorMessage = "Some of the selected fields are duplicated."
    }
    const numberColumn = columns.length;

    return (
      <Modal show={show} className="modal-data-import" backdropClassName="backdrop-dealer">
        <Modal.Body>
          {
            isLoading ?
              <div className="loader-container-inventory" style={{position: 'absolute'}}>
                <div className="loader-inventory"/>
              </div> : null
          }
          <div className="panel-container">
            {
              !isLoading ?
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                          beforeInjection={ svg => svg.classList.add('icon-close')} onClick={this.closeModalView}/> : null
            }

            <div className="panel-container-body">
              <div className="panel-top">
                <label>{nameFile}</label>
              </div>
              <div id={"panelMiddle"} className="panel-middle">
                <div className="panel-container-headers">
                  {
                    errorDropDown || errorDuplicateDropDown ?
                      <div className="container-message-info" style={{paddingLeft: 25, position: "relative", width: "calc(100% - 25px)"}} data-test={`message-info`}>
                        <div className="message-info" style={{border: "1px solid #F3A200", padding: 5}}>
                          <img src={pathServer.PATH_IMG + "inverted.png"} className= "icon-info"/>
                          <label style={{fontWeight:500}}>{errorMessage}</label>
                        </div>
                      </div> : null
                  }
                  {
                    columns && columns.length > 0 ?
                      <div className="container-headers" style={errorDropDown || errorDuplicateDropDown ? {paddingTop: 10} : null}>
                        {
                          columns.map((column) => {
                            const {index, id, value, required} = column;
                            return(
                              type !== CONSTANTS_IMPORT.IMPORTED ?
                                <div key={`header-column-${index}`} className="header-select-assigned" data-test={`header-column-${index}`}
                                     onClick={(evt) => this.renderDropDownFilter(index, CONSTANTS_IMPORT.DROPDOWN, "", evt)}>
                                  {
                                    value !== "-" ?
                                      <DPPill id={index} index={id} classContainer="pill-container-inventory"
                                              handleDeleteItem={this.handleDeleteItem}
                                              displayValue={value} name="inventory" required={required}/> : null
                                  }
                                  {showPopover && indexColumn === index ?
                                    <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                                    <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                                  }
                                </div> :
                                <div key={`header-column-${index}`} className="header-select-assigned-view">
                                  <label className="header-label">{value}</label>
                                </div>
                            )
                          })
                        }
                      </div>
                      : null
                  }
                </div>
                <div id={"panelContainerRows"} className="panel-container-rows">
                  {
                    rows && rows.length > 0 ?
                      <div id={"panelRows"} className="panel-rows">
                        {
                          rows.map((row) => {
                            const dataRow = row[0];
                            const number = dataRow.index;
                            const message = dataRow.message;
                            let svgClass = "icon-check-success-svg";
                            let backGroundColorRow = "#E9F5EE";
                            let icon = "icon/ic_check.svg";
                            //--let statusRow = IMPORT_STATUS.INSERT;
                            let statusRow = dataRow.status;

                            if(number < 10){
                              numberRow = `0${number.toString()}`;
                            } else{
                              numberRow = number.toString()
                            }

                            if(!statusRow){
                              statusRow = IMPORT_STATUS.INSERT;
                            }else if(statusRow === IMPORT_STATUS.ERROR){
                              icon = "icon/ic_close.svg";
                              svgClass = "icon-check-svg";
                              statusRow = IMPORT_STATUS.ERROR;
                              backGroundColorRow = "#FDF0F0";
                            }else if(statusRow === IMPORT_STATUS.UPDATED){
                              icon = "icon/ic_updated.svg";
                              svgClass = "icon-updated-svg";
                              statusRow = IMPORT_STATUS.UPDATED;
                              backGroundColorRow = "#D6ECFD";
                            }

                            /*
                            errorObjects.forEach(error => {
                              const {id} = error;
                              dataRow.data.forEach((data, index) => {
                                if(id === data){
                                  icon = "icon/ic_close.svg";
                                  svgClass = "icon-check-svg";
                                  statusRow = IMPORT_STATUS.ERROR
                                  backGroundColorRow = "#FDF0F0"
                                }
                              })
                            })
                            updateObjects.forEach(id => {
                              dataRow.data.forEach((data, index) => {
                                if(id === data){
                                  icon = "icon/ic_updated.svg";
                                  svgClass = "icon-updated-svg";
                                  statusRow = IMPORT_STATUS.UPDATED
                                  backGroundColorRow = "#D6ECFD"
                                }
                              })
                            })
                            */
                            return(
                              <div key={`content-row-${numberRow}`} className="rows">
                                <div id={`row-${numberRow}`} className="container-number-row"
                                     style={numberRows === number ? {borderBottom: "1px solid #D7DAE2"} : null}>
                                  <label>{numberRow}</label>
                                  {
                                    type === CONSTANTS_IMPORT.IMPORTED ?
                                      <div className={statusRow === IMPORT_STATUS.INSERT ? "container-icon-import" : "container-icon-import-message"}>
                                        <ReactSVG src={`${pathServer.PATH_IMG}${icon}`} id={`icon-row-${numberRow}`}
                                                  className="icon-check" beforeInjection={ svg => svg.classList.add(svgClass)}/>
                                        <div id={`message-${numberRow}`} className={statusRow === IMPORT_STATUS.ERROR ? "message-error-import" : "message-updated-import"}>
                                          <label>{message}</label>
                                        </div>
                                      </div>
                                      : null
                                  }
                                </div>
                                {
                                  dataRow.data.map((data, index) => {
                                    return(
                                      <div key={index} className="container-data-row"
                                           style={type === CONSTANTS_IMPORT.IMPORTED ? {backgroundColor: backGroundColorRow,
                                               borderBottom: numberRows === number ? "1px solid #D7DAE2" : null,
                                             borderRight: index === numberColumn - 1 ? "1px solid #D7DAE2" : null} :
                                             {borderBottom: numberRows === number ? "1px solid #D7DAE2" : null,
                                               borderRight: index === numberColumn - 1 ? "1px solid #D7DAE2" : null}}>
                                        <label>{data}</label>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })
                        }
                      </div> : null
                  }
                </div>
              </div>
              <div className="panel-bottom">
                <DPPagination
                  showItemPerPage={false}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={this.handlePageChange}
                  siteColor={siteColorAdmin}
                />
                <div className="container-icon">
                  <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_question_mark.svg`} className={"icon-help"}
                            beforeInjection={svg => svg.classList.add("icon-help-svg")} onClick={(evt) => {this.showMessageImport(evt)}}/>
                  <label className={"help"}>Help</label>
                </div>
                {
                  type === CONSTANTS_IMPORT.IMPORTED ?
                    <div className="container-results">
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_updated.svg`} data-test={`updated`}
                                className="icon-check" beforeInjection={ svg => svg.classList.add("icon-updated-svg")}
                                onClick={() => this.filterRowsError(IMPORT_STATUS.UPDATED)}/>
                      <label>{importedUpdated}</label>
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_check.svg`} data-test={`insert`}
                                className="icon-check" beforeInjection={ svg => svg.classList.add("icon-check-success-svg")}
                                onClick={() => this.filterRowsError(IMPORT_STATUS.INSERT)}/>
                      <label>{importedSuccess}</label>
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_close.svg`} data-test={`error`}
                                className="icon-check" beforeInjection={ svg => svg.classList.add("icon-check-svg")}
                                onClick={() => this.filterRowsError(IMPORT_STATUS.ERROR)}/>
                      <label>{importedError}</label>
                      <label className={"label-imported"} onClick={() => this.filterRowsError(IMPORT_STATUS.TOTAL)} data-test={`total`}>
                        {`Imported ${importedSuccess} / ${numberRows}`}
                      </label>
                    </div> : null
                }
                <div className="container-button-done" >
                  {
                    type !== CONSTANTS_IMPORT.IMPORTED ?
                      <button className="btn-import" onClick={this.importData} data-test={`btn-import`}>
                        Import
                      </button> :
                      <button className="btn-done" onClick={this.closeModalView} data-test={`btn-done`}>
                        Done
                      </button>
                  }
                </div>
              </div>
            </div>
          </div>
          {/*
            showMessage ?
              <DPPopover anchorEl={anchorEl}
                         classNameWrapper={"popover-box-message popover-csv"}
                         handleRequestClose={this.handleRequestClose}>
                {child}
              </DPPopover> : null*/
          }
          { showPopover && typePopover === CONSTANTS_IMPORT.DROPDOWN ?
            <DPPopover anchorEl={anchorEl}
                       classNameWrapper="popover-box-message"
                       handleRequestClose={this.handleRequestClose}>
              <HeaderOptions columnsToAssign ={columnsToAssign} headerSelected={headerSelected[indexColumn]} onClickPopover={this.onClickPopover}
                             indexColumn={indexColumn}/>
            </DPPopover> : null
          }
        </Modal.Body>
      </Modal>
    )
  }

}

ModalViewDataImport.defaultProps = {
  show: false,
  rowsNumberToShow: ROWS_IMPORT_BY_PAGE_BY_DEFAULT
}

ModalViewDataImport.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  totalRows: PropTypes.array,
  nameFile: PropTypes.string,
  child: PropTypes.any,
  columns: PropTypes.array,
  totalPages: PropTypes.number,
  formCodeToImport: PropTypes.string,
  rowsNumberToShow: PropTypes.number,
  importKey: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  rowKey: PropTypes.string.isRequired,
}