import React from 'react'
import LocationSettingList from '../../setting/locationSetting/LocationSettingList'
import {connect} from 'react-redux'

const ContainerGeneralReviewSetting = (props) => {
  const {showSideBar} = props
  const paddingLeft = showSideBar ? '200px' : '50px'

  return(
    <div id="containerSettings" className="general-container-settings" style={{paddingLeft: paddingLeft}}>
      <div className="container-review-settings">
        <div className="first-row">
          <LocationSettingList/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
  }
};
export default connect(mapStateToProps,{})(ContainerGeneralReviewSetting)