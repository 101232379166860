import { IndexRoute, Route } from 'react-router'
import React from 'react'
// @ts-ignore
import HomePage from '../newComponents/homePage'
// @ts-ignore
import AppMarketing from '../newComponents/homePage/AppMarketing'
// @ts-ignore
import ContactUs from '../newComponents/homePage/components/contactUs/ContactUs'
// @ts-ignore
import NewLogin from '../newComponents/homePage/components/NewLogin'
// @ts-ignore
import CustomerManagement from '../newComponents/homePage/products/CustomerManagement'
// @ts-ignore
import Reviews from '../newComponents/homePage/products/Reviews'
// @ts-ignore
import TodoTeams from '../newComponents/homePage/products/TodoTeams'
// @ts-ignore
import WebsiteBuilder from '../newComponents/homePage/products/WebsiteBuilder'
// @ts-ignore
import SalesProcess from '../newComponents/homePage/products/SalesProcess'
// @ts-ignore
import InventoryManagement from '../newComponents/homePage/products/InventoryManagement'
// @ts-ignore
import RequestDemoPage from '../newComponents/homePage/components/demoRequest/RequestDemoPage'
// @ts-ignore
import Pricing from '../newComponents/homePage/components/Pricing'
// @ts-ignore
import ReviewPagePreview from '../newComponents/review/ReviewPagePreview'
// @ts-ignore
import PrivacyPolicy from '../newComponents/marketingPage/PrivacyPolicy'
// @ts-ignore
import Unsubscribe from '../newComponents/marketingPage/Unsubscribe'
// @ts-ignore
import ChangePassword from '../newComponents/register/ChangePassword.jsx'
// @ts-ignore
import AccountConfirmation from '../AccountConfirmation'

const Routes = (
  <>
    <Route path="/privacy" component={PrivacyPolicy}/>
    <Route path="/webhooks" component={PrivacyPolicy}/>
    <Route path="/reviewPage" component={ReviewPagePreview}/>
    <Route path="/reviewPagePreview" component={ReviewPagePreview}/>
    <Route path="/products">
      <Route path="customerManagement" component={CustomerManagement}/>
      <Route path="reviews" component={Reviews}/>
      <Route path="todoTeams" component={TodoTeams}/>
      <Route path="websiteBuilder" component={WebsiteBuilder}/>
      <Route path="salesProcess" component={SalesProcess}/>
      <Route path="inventoryManagement" component={InventoryManagement}/>
      <IndexRoute component={CustomerManagement}/>
    </Route>
    <Route path="/marketing" component={AppMarketing}>
      <Route path="contactus" component={ContactUs}/>
      <Route path="requestDemo" component={RequestDemoPage}/>
      {/*
      <Route path="pricing" component={Pricing}/>
      <IndexRoute component={Pricing}/>
      */}
    </Route>
    <Route path="/unsubscribe/:email" component={Unsubscribe}/>
    <Route path="/login" component={NewLogin}/>
    <Route path="/changePassword/:userId" component={ChangePassword}/>
    <Route path="accountConfirmation/:userId(/:verify)" component={AccountConfirmation}/>
    <Route path="/" component={HomePage}/>
  </>
)

export default Routes
