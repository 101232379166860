import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import Dropzone from 'react-dropzone'
import {loadStaticsFiles, uploadFileSales} from '../../Api'
import {isEmpty} from 'lodash'
import Loader from '../../../common/UI/Loader.jsx'
import {EXT_PDF, pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'

const SaleFile = ({id,displayValue, url,type, onDrop, goToPagePrint,isLoading}) => {
  const textButton = !isEmpty(url) ? "Change" : "Upload";
  return (
    <div className="sale-file">
      <div className="container-image-file">
        {
          isLoading === id ?
            <div className="loader-container">
              <div className="loader"/>
            </div> :
            !isEmpty(url) ?
              <img className="image-sale-file" src={type === EXT_PDF ? `${pathServer.PATH_IMG}pdfModel.jpg` : url}/> :
              <Dropzone onDrop={(file) => onDrop(id, file)} accept=".jpeg,.jpg,.png,.pdf">
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className="dropzone-container dropzone-file">
                    <input {...getInputProps()} />
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_file_upload.svg"} className="icon-sales-file"
                              beforeInjection={ svg => svg.classList.add('icon-upload-file')}/>
                  </div>
                )}
              </Dropzone>
        }
      </div>
      <div className="container-type-buttons">
        <div className="container-type">
          <label>{displayValue}</label>
        </div>
        <div className="container-buttons">
          <Dropzone onDrop={(file) => onDrop(id,file)} accept=".jpeg,.jpg,.png,.pdf">
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="dropzone-container dropzone-file">
                <input {...getInputProps()} />
                <button className="upload">
                  {
                    !isEmpty(url) ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_attach_file.svg"} className="icon-attach-file"
                                beforeInjection={ svg => svg.classList.add('icon-attach')}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_file_upload.svg"} className="icon-upload-file"
                                beforeInjection={ svg => svg.classList.add('icon-upload')}/>
                  }
                  {textButton}
                </button>
              </div>
            )}
          </Dropzone>
          <button className={!isEmpty(url) ? "print" : "print-disabled"} onClick={() => goToPagePrint(id)} disabled={isEmpty(url)}>Print</button>
        </div>
      </div>
    </div>
  )
};

export function getTypeUrl(url) {
  const arrayUrl = url.split(".");
  return arrayUrl[arrayUrl.length-1];
}

export default class CustomerSaleFiles extends Component {
  constructor() {
    super();
    this.state = {
      files:{
        titleApplication : {displayValue:"Utah Title Application",isLoading:false},
        titleExtension : {displayValue:"Title Extension",isLoading:false},
        temporaryPermit : {displayValue:"Fill Temporary Permit (If applicable)",isLoading:false},
        tradeInDocument : {displayValue:"Trade In Documents (If applicable)",isLoading:false},
        driverLicense : {displayValue:"Customer ID",isLoading:false},
        buyerAgreement : {displayValue:"Buyer Agreement",isLoading:false},
      },
      isLoading:false,
      isLoadingAll: false
    };
    this.onDrop           = this.onDrop.bind(this);
    this.loadStaticFiles  =  this.loadStaticFiles.bind(this);
    this.goToPagePrint    =  this.goToPagePrint.bind(this);
  }

  componentWillMount() {
    const {saleId} = this.props;
    this.loadStaticFiles(saleId);
  }

  loadStaticFiles(saleId){
    let {files} = this.state;
    this.setState({isLoadingAll:true});
    loadStaticsFiles(saleId).then( (response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {staticFiles: {saleStaticFiles}} = data;
        if (saleStaticFiles.length > 0 ){
          saleStaticFiles.forEach( (staticFile) => {
            files[staticFile.code].url  = staticFile.url;
            files[staticFile.code].type = getTypeUrl(staticFile.url);
            this.setState({files});
          })
        }
        this.setState({isLoadingAll:false});
      } else {
        console.error(responseMessage);
        this.setState({isLoadingAll:false});
      }
    })
  }

  onDrop(code,file) {
    const {saleId} = this.props;
    const data = {files:file};
    this.setState({isLoading:code});
    uploadFileSales(saleId,code,data).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {files} = this.state;
        const {url} = data;
        const newFiles = {...files};
        newFiles[code].url = url;
        newFiles[code].type = getTypeUrl(url);
        this.setState({files:newFiles,isLoading:null})
      }else{
        console.error(responseMessage);
        this.setState({isLoading:null})
      }
    })
  }

  goToPagePrint(staticSaleId) {
    const files = this.state;
    window.open(files.files[staticSaleId].url, "_blank")
  }

  render() {
    const {files, isLoading,isLoadingAll} = this.state;
    const {titleApplication,titleExtension,temporaryPermit,tradeInDocument,driverLicense,buyerAgreement} = files;
    return (
      <div className="container-sale-files">
        {
          isLoadingAll ?
            <Loader/> :
            <div>
              <div className="first-row-sale-files">
                <SaleFile id={'titleApplication'} displayValue={titleApplication.displayValue} onDrop={this.onDrop}
                          url={titleApplication.url} type={titleApplication.type}
                          goToPagePrint={this.goToPagePrint} isLoading={isLoading}/>
                <SaleFile id={'titleExtension'} displayValue={titleExtension.displayValue} onDrop={this.onDrop}
                          url={titleExtension.url} type={titleExtension.type}
                          goToPagePrint={this.goToPagePrint} isLoading={isLoading}/>
                <SaleFile id={'temporaryPermit'} displayValue={temporaryPermit.displayValue} onDrop={this.onDrop}
                          url={temporaryPermit.url} type={temporaryPermit.type}
                          goToPagePrint={this.goToPagePrint} isLoading={isLoading}/>
                <SaleFile id={'tradeInDocument'} displayValue={tradeInDocument.displayValue} onDrop={this.onDrop}
                          url={tradeInDocument.url} type={tradeInDocument.type}
                          goToPagePrint={this.goToPagePrint} isLoading={isLoading}/>
              </div>
              <div className="second-row-sale-files">
                <SaleFile id={'driverLicense'} displayValue={driverLicense.displayValue} onDrop={this.onDrop}
                          url={driverLicense.url} type={driverLicense.type}
                          goToPagePrint={this.goToPagePrint} isLoading={isLoading}/>
                <SaleFile id={'buyerAgreement'} displayValue={buyerAgreement.displayValue} onDrop={this.onDrop}
                          url={buyerAgreement.url} type={buyerAgreement.type}
                          goToPagePrint={this.goToPagePrint} isLoading={isLoading}/>
              </div>
            </div>
        }
        </div>
    )
  }
}