import {ReactSVG} from 'react-svg'
import DPButton from './DPButton'
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {pathServer} from '../../common/Constants'

require('./DPButtonIcon.scss')

const DPButtonIcon = (props) => {
  const [isHovering, setIsHovering] = useState(false)
  const {buttonClass, background, iconSendPath, iconSendPath1, iconSendPath2, btnSaveText, width, height, onClick, textColor, textColorHover, borderColor, boxShadow, hoverText, disabled} =  props
  return(
    <DPButton className={classnames( 'button-icon', buttonClass)} width={width} heigth={height} onClick={onClick}
              style={{background: background, color: textColor, border: borderColor ? `1px solid ${borderColor}` : "none" ,
              display: 'flex', boxShadow: boxShadow ? boxShadow : "none"}}
              onMouseLeave={hoverText ? () => setIsHovering(false) : null}
              onMouseEnter={hoverText ? () => setIsHovering(true) : null}
              disabled={disabled}>
      <div className="description-button-container">
        <div className="description-no-hover">
          {
            iconSendPath && !isHovering &&
            <ReactSVG
              src={`${pathServer.PATH_IMG}icon/${iconSendPath}`}
              className={'icon-class'}
            />
          }
          {
            iconSendPath1 && !isHovering &&
            <ReactSVG
              src={`${pathServer.PATH_IMG}icon/${iconSendPath1}`}
              className={'icon-class'}
            />
          }
          <span style={{zIndex: '4'}}>{isHovering && hoverText ? <span style={{color: textColorHover || textColor}}>{hoverText}</span> : btnSaveText}</span>
        </div>
        <div className="description-hover">
          {
            iconSendPath2 && isHovering &&
            <ReactSVG
              src={`${pathServer.PATH_IMG}icon/${iconSendPath2}`}
              className={'icon-class-arrow'}
            />
          }
        </div>
      </div>
    </DPButton>
)
};

DPButtonIcon.defaultProps = {
  background: '#FFF',
  textColor: '#2194F1',
  width: 'auto',
  height: 'auto',
  btnSaveText: 'save',
  disabled: false,
  hoverText: ''
}

DPButtonIcon.propTypes = {
  background: PropTypes.string,
  textColor: PropTypes.string,
  textColorHover: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  iconSendPath: PropTypes.string,
  buttonClass: PropTypes.string,
  hoverText: PropTypes.string,
  disabled: PropTypes.bool,
}

export default DPButtonIcon
