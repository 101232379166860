import DPButtonLoading from "../../common/DPButtonLoading"
import classnames from "classnames"
import React from "react"
import './TestimonialsUpdateButton.scss'

export default function TestimonialsUpdateButton({
                                                   description,
                                                   subDescription,
                                                   errorFlag,
                                                   currentDate,
                                                   buttonClass,
                                                   handleDownloadBtn,
                                                   isLoadingBtnSave,
                                                   isBtnSaved
                                                 }) {
  return (
    <div className="recommendation-component-container">
      <div className="information-recommendation">
        <span className="description">{description}</span>
        <span>
            <span className="sub-description">{subDescription}</span>
            <span className="detail">{!errorFlag ? currentDate : ""}</span>
        </span>
      </div>
      <div className="button-recommendation">
        <DPButtonLoading buttonClass={classnames('button-loading', buttonClass)} width="67px" height='32px'
                         onClick={handleDownloadBtn} isLoading={isLoadingBtnSave}
                         isSaved={isBtnSaved} isDisable={false} btnSaveText={'Refresh'}
                         btnLoadingText={'Refreshing ...'} btnSavedText={'Refreshed'} hoverText={'Refresh now'}/>
      </div>
    </div>
  )
}