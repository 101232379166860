import React from 'react'
import PropTypes from 'prop-types'
import {pathServer} from '../../common/Constants'
import {ReactSVG} from 'react-svg'

export const DPWarning = (props) => {
  const {message} = props;
  return (
      <div className="container-icon-body-title">
        <div className="container-icon-exclamation">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"}
                    beforeInjection={svg => svg.classList.add("svg-icon-error")}
                    className="icon-error"/>
        </div>
        <div className="container-message">
          <label className="title-message">{message}</label>
        </div>
      </div>
  )
}

DPWarning.propTypes = {
  message: PropTypes.string,
}