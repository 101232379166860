import React from 'react'
import {clone} from 'lodash'
import {DropTarget} from 'react-dnd'
import PropTypes from 'prop-types'
import createClass from 'create-react-class'
import {moveElement} from '../util/Utils'

const formControlTarget = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem());
  }
};

const controlTargetType = (props) => { return moveElement(props.statusList,props.status)};

let ElementTargetTask = createClass({

  propTypes: {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    onDrop: PropTypes.func.isRequired
  },


  render(){
    const { isOver, canDrop, connectDropTarget,styleLine,styleBorder,labelDrop,showLabelDrop} = this.props;
    const isActive = isOver && canDrop;

    let newStyle = clone(styleLine);

    if (isActive) {
      newStyle.border = styleBorder ? styleBorder : '1px dashed #777';
      newStyle.padding = 0;
    }else if (canDrop) {
      //newStyle.minHeight = '1px';
      //console.log("canDrop");
    }


    return connectDropTarget(
      <div style={newStyle}>
        {
          (isActive && showLabelDrop) ?
            <div className="label-drop">
              <label>{labelDrop}</label>
            </div> : null
        }
        {this.props.children}
      </div>
    );
  }

});


ElementTargetTask = (DropTarget)(controlTargetType, formControlTarget,  (connect, monitor) =>{
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
})(ElementTargetTask) || ElementTargetTask;

export default ElementTargetTask