import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {
  colors,
  SALE_CANCEL_REASON, SALES_BY_PAGE, SALES_BY_PAGE_BY_DEFAULT, saleStatus, saleStatusValue,
  siteColorAdmin
} from '../../Constants'
import {browserHistory} from 'react-router'
import {calculateHeightDifference, getAnchorEl} from '../util/Utils'
import {Popover} from '../util/ComponentUtils'
import Moment from 'moment'
import {Modal} from 'react-bootstrap'
import {
  loadDealerConfigurationByCode,
  startSale,
  cancelSale,
  deleteSale,
  loadDealerSales,
  updateSalesmanId,
} from '../../Api'
import Checkbox from 'material-ui/Checkbox'
import {debounce, isEmpty} from 'lodash'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import ModalConfirmDelete from '../util/ModalConfirmDelete.jsx'
import SaleFilter from './SaleFilter.jsx'
import {DPMessageBar} from '../../common/DPMessageBar'
import {DPMessageDelete} from '../../common/DPMessageDelete'
import {getPosition} from '../../../common/Util'
import {elementCheck, elementUnCheck} from '../../../common/ComponentUtils'
import {
  pathServer, SUCCESSFUL_CODE,
  WIDTH_VIEW_MOBILE,
  WIDTH_VIEW_TABLET
} from '../../../common/Constants'
import {DPPagination} from '../../../common/DPPagination'

export function getColorByStatus(status) {
  switch (status) {
    case saleStatus.STARTED :
      return colors.ORANGE;
    case saleStatus.IN_PROGRESS :
      return colors.ORANGE;
    case saleStatus.COMPLETED :
      return colors.GREEN;
    case saleStatus.CANCELLED :
      return colors.RED;
  }
}

const Header = ({left, itemsByPageQuantity, handleItemsByPage, handlePageChange,
                  currentPage, totalPages}) => {
  return(
    <div className='container-header' style={{left:left}}>
      <label className='title'>Sales</label>
      <div className="sales-pagination">
        <DPPagination
          optionsQuantityByPage={SALES_BY_PAGE}
          selectedItemsByPage={itemsByPageQuantity}
          handleChangeItemsByPage={handleItemsByPage}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          siteColor={siteColorAdmin}
        />
      </div>
    </div>
  )
};

export const CosignerChild = ({cosigners}) => {
  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub-cosigner">
        <label className="cosigner-title">Co-signers</label>
        {
          cosigners.map((cosigner, index) => {
            const {name,email,phoneNumber,image} = cosigner;
            return(
              <div key={index} className="sale-cosigner">
                <div>
                  <img className="image-user" src={image ? image : pathServer.PATH_IMG + "ic_account_circle.png"}/>
                </div>
                <div className="cosigner-info">
                  <span style={{fontWeight: "700"}}>{name}</span>
                  <span>{email}</span>
                  <span>{phoneNumber}</span>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

const NOT_SELECTED = 'Not selected';

export const ModalNewSale = ({show, onHide, user, showPopover, renderDropDownFilter, child, anchorEl, handleRequestClose, goToSale}) => {
  const userFirstName = getFirstText(user.firstName);
  const userLastName = getFirstText(user.lastName);
  return(
      <Modal show={show} className="sale-modal-container modal-new-sale" backdropClassName="backdrop-dealer">
        <Modal.Body>
          <div className="panel-container">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                      beforeInjection={ svg => svg.classList.add("icon-close")} onClick={onHide}/>
            <div className="panel-container-body">
              <div className="container-title">
                <label className="title">Welcome to the sale process</label>
              </div>
              <div className="container-step">
                <div className="panel-left">
                  <div className="container-title-step">
                    <label className="number">1.</label>
                    <label className="title-step">Select a salesman who will be on charge of this sale.</label>
                  </div>
                  <div className="container-user">
                    <div id="userSelected" className="user-select-assigned" onClick={(evt) => renderDropDownFilter("userSelected", evt)}>
                      <img className="user-img" src={user.image ? user.image : `${pathServer.PATH_IMG}avatar.png`} />
                        <label>{`${userFirstName} ${userLastName}`}</label>
                        {showPopover ?
                          <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                          <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                        }
                    </div>
                  </div>
                </div>
                <div className="panel-center">
                  <div className="container-title-step">
                    <label className="number">2.</label>
                    <label className="title-step">Make sure to complete the information on the sale dashboard.</label>
                  </div>
                </div>
                <div className="panel-right">
                  <div className="container-title-step">
                    <label className="number">3.</label>
                    <label className="title-step">Click on complete sale to finish your sale.</label>
                  </div>
                </div>
              </div>
              <div className="container-button">
                <button className="button-save" onClick={() => goToSale("new")} data-test={'btn-create-sale'}>
                  Got it, start sale
                </button>
              </div>
            </div>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_cash_sale_background.svg"} className="icon-cash"
                      beforeInjection={ svg => svg.classList.add("icon-cash-svg")} onClick={onHide}/>
            { showPopover  ? <Popover anchorEl={anchorEl}
                                      child={child}
                                      classPopover="popover-box-message popover-users"
                                      handleRequestClose={handleRequestClose}/> : null
            }
          </div>
        </Modal.Body>
      </Modal>
  )
};

export const BodyOptions = ({users,userSelected,onClickPopover, type}) => {
  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {
            users.map((user,index) => {
              let className = "change-submenu-item"
              if(userSelected !== null){
                className = (user.id === userSelected.id) ? "item-selected" : "change-submenu-item";
              }
              return(
                <li
                  key={index}
                  className={className}
                  onClick={(evt) => onClickPopover(user.id, type, evt)}
                >
                  <img
                    className={
                      isEmpty(user.image)
                        ? 'salesman-not-image'
                        : 'salesman-image'
                    }
                    src={
                      user.image
                        ? user.image
                        : pathServer.PATH_IMG + 'avatar.png'
                    }
                  />
                  <span className="user-name">{user.firstName} {user.lastName}</span>
                </li>
              )
            })
          }
        </u>
      </div>
    </div>
  )
};

export const BodyReason = ({stylePanelRight, onHide, message, childReason, isRequired, save, labelRequired, buttonLabelSave, deleteSale, buttonDelete}) => {
  return(
      <div className="panel-right-cancel-sale" style={stylePanelRight}>
        <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_close.svg`} className="icon-close-cancel-sale"
                  onClick={onHide} beforeInjection={ svg => svg.classList.add("icon-close-svg")}/>
        <div>
          <div className="label-cancel">
            <label>{message}</label>
          </div>
          <div>
            {childReason}
          </div>
        </div>
        <div>
          { isRequired ?
              <div>
                <label className="label-required">{labelRequired}</label>
              </div>
              : null
          }
          <div>
            <label className="label-delete">Sale will be saved as "Canceled Sale" and you'll be able to view it anytime.</label>
            <label className="label-delete" style={{marginTop: 0}}>
              You can also <b>Delete Permanently</b> so it won't be on the Sales list, this action is irreversible.
            </label>
          </div>
          <div>
            <button onClick={(evt) => deleteSale()} className="btn-delete-sale">{buttonDelete}</button>
            <button onClick={save} className="btn-save-custom" style={{float:"right"}}>{buttonLabelSave}</button>
          </div>
        </div>
      </div>
  )
};

export function renderReason(optionsCancelSale, reasonSelected, otherReason, indexSelected, onChangeInput, handleCheckReason, onBlurInput){
  let options = [];

  if(optionsCancelSale && optionsCancelSale.length > 0){
    optionsCancelSale.forEach(reason => {
      const checked = (reason.value === reasonSelected) ? 1 : -1;
      const disabled = (indexSelected !== optionsCancelSale.length - 1 || checked === -1);
      options.push(
          <div key={reason.index} className="content-check">
            <Checkbox label={reason.value} checked={(checked !== -1)} id={reason.value}
                      onCheck={(evt) => handleCheckReason(reason.index, checked, evt)}
                      labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400", fontFamily: "'Open Sans', sans-serif"}}
                      iconStyle={{borderColor: "#777", marginRight: 8}}
                      checkedIcon={elementCheck()} uncheckedIcon={elementUnCheck()}
                      name={reason.value} />
            {
              reason.index === optionsCancelSale.length - 1 ?
                  <div>
                    <label className="label-other-reason">Other Reason</label>
                    <textarea type="text" className="form-control" value={otherReason}
                              style={{marginBottom: 10, resize:"none", fontFamily: "'Open Sans', sans-serif", color: "#787878", fontSize: 13, fontWeight: 400}}
                              onChange={(e) => onChangeInput(e)}
                              onBlur={(e) => onBlurInput(e)}
                              disabled={disabled}
                              rows="2"
                              placeholder="Type here"/>
                  </div> : null
            }
          </div>
      );
    });
  } else {
    options.push(
        <div key="otherReason">
          <label>Reason</label>
          <textarea type="text" className="form-control" value={otherReason} style={{marginBottom: 10, resize:"none"}}
                    onChange={(e) => onChangeInput(e)}
                    onBlur={(e) => onBlurInput(e)}
                    rows="3"/>
        </div>
    )
  }

  return(
      <div>
        {options}
      </div>
  )
}

export function getFirstText(text) {
  let firstText = "";
  if(text){
    const listText = text.split(" ");
    firstText = listText[0];
  }
  return firstText
}

function getNumberFilters(saleFilters) {
  let query = {...saleFilters};
  let numFilters = 0;
  for(let key in query) {
    if (key === "hasNotPurchaser" && query[key]) {
      delete query["purchaserName"];
    }
    if(key === "hasNotCar" && query[key]){
      delete query["makeId"]
      delete query["modelId"]
      delete query["year"]
    }
    if(key === "size" || key === "from" || key === "salesmanId"){
      delete query[key]
    }
    if (key === "keyword" && query[key]) {
      delete query.keyword;
    }
  }

  for(let key in query) {
    if (query[key] !== '' && query[key] !== undefined && query[key] !== null && key !== "hasNotPurchaser" && key !== "hasNotCar") {
      numFilters += 1
    } else if(query[key]){
      numFilters += 1
    }
  }
  return numFilters
}

function getWidthOfMessageNotInventory(sales) {
  if(sales && sales.length > 0){
    sales.forEach((sale,index) => {
      const carName = window.document.getElementById(`car-name-${index}`);
      const carMessage = window.document.getElementById(`car-message-${index}`);
      if (carName && carMessage) {
        const width = carName.offsetWidth;
        carMessage.style.width = `calc(100% - ${width + 0.5}px)`
      }
    });
  }
};

export const TITLE_DELETE_SALE = "Please Provide the confirmation password to delete this Sale";

class Sales extends Component{
  constructor(){
    super();
    this.changeKeyword          = debounce(this.searchSales,500);
    this.state = {
      heightDifference: 100,
      listName: {
        id: null,
        displayValue: "Sort By"
      },
      showOptions: false,
      itemsByPageQuantity: SALES_BY_PAGE_BY_DEFAULT,
      currentPage: 1,
      totalPages: 1,
      showPopover: false,
      selectedFilter: null,
      sales: null,
      selectedCosigners: null,
      showPopoverSalesMan: false,
      widthPage: window.innerWidth,
      isLoading: false,
      salesQuantity : 0,
      showModalNewSale: false,
      userSelected: null,
      isDeleted: false,
      showModalCancel: false,
      optionsCancelSale: null,
      reasonSelected: null,
      isRequired: false,
      otherReason: null,
      existReason:false,
      selectOpened: false,
      showFilters: false,
      saleFilters: {},
      userSelectedNew: {},
      numFilters: 0,
      isEmptyPassword: false,
      error: null,
      passwordToDelete: null,
      showPopoverSelectSalesman: {}
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    const {saleFilters , users, isDeleted,keyword, location, email} = this.props;

    if(location.pathname.includes('new')){
      const selectedUser = users.find(user => user.email === email)
      this.setState({ showModalNewSale: true, userSelectedNew: selectedUser})
    }

    if(keyword && keyword !== "")
      this.searchSales(saleFilters,keyword);
    else
      this.searchSales(saleFilters);

    loadDealerConfigurationByCode(SALE_CANCEL_REASON).then(response => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {dealerConfiguration} = data;
        let optionsCancelSale = [];
        if(!isEmpty(dealerConfiguration)){
          const values = JSON.parse(dealerConfiguration.value);
          values.forEach((reason,index) => {
            optionsCancelSale.push({index:index,value:reason});
            if(index === values.length - 1){
              optionsCancelSale.push({index:index + 1,value:"Other reason"});
            }
          });
        }
        const existReason = (optionsCancelSale.length > 0);
        this.setState({optionsCancelSale: optionsCancelSale, existReason});
      }else{
        console.error(responseMessage);
        this.setState({optionsCancelSale:[]});
      }
    })
    this.handleHeightDifference();
    if(isDeleted){
      setTimeout(() => {
        this.setState({isDeleted: false});
      }, 3000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    const {sales} = this.state;
    getWidthOfMessageNotInventory(sales)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {keyword} = nextProps

    const {saleFilters} = this.props
    if(this.props.keyword !== keyword){
      this.changeKeyword(saleFilters, keyword);
    }
  }

  handleResize = () => {
    this.setState({widthPage: window.innerWidth}, () => {
      this.handleHeightDifference();
    });
  }

  goToSale = (saleId) => {
    const {userSelectedNew, saleFilters, currentPage} = this.state;
    let {setSaleFilters, setPageNumberSales} = this.props
    let data = {};
    setSaleFilters(saleFilters);
    setPageNumberSales(currentPage);
    if(saleId === "new"){
      data = {salesmanId: userSelectedNew.id};
    }
    startSale(data).then((response) => {
      const {responseCode, responseMessage} = response
      if(responseCode === SUCCESSFUL_CODE){
        const id = response.responseMessage;
        browserHistory.push(`/main/sale/${id}`);
      }else{
        console.error(responseMessage)
      }
    })
  }

  handleHeightDifference = () => {
    const { widthPage } = this.state;
    let heightDifference = calculateHeightDifference(56,32);
    if( widthPage <= WIDTH_VIEW_TABLET && widthPage > WIDTH_VIEW_MOBILE){
      heightDifference = calculateHeightDifference(56,20);
    }else if(widthPage <= WIDTH_VIEW_MOBILE){
      heightDifference = calculateHeightDifference(56,10);
    }
    this.setState({heightDifference})
  }

  renderDropDownFilter = (type, event) => {
    event.preventDefault();
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target, 2);
    this.setState({showPopover: true, selectedFilter: type, anchorEl: anchorEl})
  }

  handlePageChange = (nextPage, totalPages) => {
    let {itemsByPageQuantity, salesQuantity, saleFilters} = this.state;
    let {setSaleFilters, setPageNumberSales} = this.props;
    const query = {};
    if (nextPage >= 0) {
      let currentPage = nextPage;
      if (currentPage > totalPages) {
        currentPage = 1;
        query.from = 0;
      } else if (currentPage === 0) {
        currentPage = totalPages;
        query.from = itemsByPageQuantity * (totalPages - 1);
      } else {
        if (salesQuantity !== 0)
          query.from = itemsByPageQuantity * (currentPage - 1);
      }
      query.size = itemsByPageQuantity;
      this.setState({currentPage: currentPage, query, isLoading: true}, () => {
        saleFilters.from = query.from
        saleFilters.size = query.size
        loadDealerSales(saleFilters).then(response => {
          const {responseCode, responseMessage, data} = response
          if (responseCode === SUCCESSFUL_CODE){
            const {sales,salesQuantity} = data;
            setSaleFilters(saleFilters);
            setPageNumberSales(currentPage);
            this.setState({sales,isLoading: false,salesQuantity, saleFilters})
          }else{
            console.error("error ==>", responseMessage);
            this.setState({isLoading: false})
          }
        })
      })
    }
  }

  handleItemsByPage = (itemsByPageQuantity) => {
    //todo If we have more than two parameters for the endpoint then create let query = {} in the state.
    let {saleFilters} = this.state;
    let {setSaleFilters, setPageNumberSales} = this.props;
    this.setState({isLoading: true, itemsByPageQuantity: itemsByPageQuantity, showOptions: false, currentPage: 1}, () => {
      saleFilters.from = 0
      saleFilters.size = itemsByPageQuantity
      this.setState({isLoading: true});
      loadDealerSales(saleFilters).then( response => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE){
          const {sales,salesQuantity} = data;
          setSaleFilters(saleFilters);
          setPageNumberSales(1);
          this.setState({sales, salesQuantity, isLoading: false, saleFilters})
        }else {
          console.error("error ==>", responseMessage);
        }
      })
    });
  }

  handleRequestClose = () => {
    this.setState({ showOptions: false, showPopover:false, showPopoverCosigner:false, selectedFilter: ""})
  }

  handleRequestOpen = (event) => {
    this.setState({
      showOptions: true,
      anchorEl: {offsetWidth: event.currentTarget.offsetWidth, offsetHeight: event.currentTarget.offsetHeight}
    })
  }

  handleSelect = (sale, evt) => {
    evt.stopPropagation();
    let {setSaleFilters, setPageNumberSales} = this.props
    const {saleFilters, currentPage} = this.state;
    setSaleFilters(saleFilters);
    setPageNumberSales(currentPage);
    browserHistory.push({
      pathname: `/main/sale/${sale.saleId}`,
      state: {sale: sale}
    });
  }

  handleSelectSaleId = (id, type ,evt) => {
    evt.stopPropagation();
    let {showModalDelete, showModalCancel} = this.state;
    if(type === "imgCancel"){
      showModalCancel = true
    } else{
      showModalDelete = true
    }
    this.setState({selectedSaleId:id, showModalDelete, showModalCancel})
  }

  handleShowCosigner = (cosigners, evt) => {
    evt.stopPropagation();
    let pointTo = getPosition(evt.currentTarget);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: 216
    };
    this.setState({showPopoverCosigner: true, selectedCosigners: cosigners, anchorEl:anchorEl})
  }

  showModalNewSale = () => {
    const {users, email} = this.props;
    const userSelected = users.find(user => user.email === email);
    this.setState({showModalNewSale: true, showPopover: false, userSelectedNew: userSelected})
  }

  closePopoverNewSale = () => {
    this.setState({showModalNewSale: false})
  }

  onClickPopover = (id, type) => {
    const {users, setSaleFilters} = this.props;
    let {userSelected, saleFilters, userSelectedNew} = this.state;

    if(type === "salesmanId"){
      users.forEach(user => {
        if (user.id === id){
          userSelected = user;
        }
      });

      if(userSelected.id !== ""){
        saleFilters.salesmanId = userSelected.id
      }

      this.setState({isLoading: true});
      loadDealerSales(saleFilters).then((response) => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE){
          const {sales,salesQuantity} = data;
          setSaleFilters(saleFilters);
          this.setState(
            {
              userSelected: userSelected,
              showPopover: false,
              selectedFilter: "",
              saleFilters,
              salesQuantity,
              isLoading: false,
              sales,
            }
          )
        }else{
          console.error(responseMessage)
        }
      })
    } else {
      users.forEach(user => {
        if (user.id === id){
          userSelectedNew = user;
        }
      });

      this.setState({userSelectedNew: userSelectedNew, showPopover: false, selectedFilter: ""})
    }
  }

  showMoreFilters = () => {
    let {showFilters, saleFilters} = this.state;
    let numFilters = getNumberFilters(saleFilters)
    this.setState({showFilters: !showFilters, numFilters});
  }

  handleCheckReason = (index, checked, evt) => {
    const reasonSelected = (checked === -1) ? evt.target.id : null;
    const indexSelected = index;
    this.setState({reasonSelected, indexSelected, isRequired: false});
  }

  confirmCancelSale = () => {
    const {optionsCancelSale, reasonSelected, indexSelected, otherReason} = this.state;
    if(indexSelected === optionsCancelSale.length - 1 || optionsCancelSale.length === 0){
      this.cancelSale(otherReason);
    } else {
      this.cancelSale(reasonSelected);
    }
  }

  cancelSale = (reason) => {
    const {selectedSaleId, saleFilters} = this.state;
    const {keyword} = this.props;
    if(isEmpty(reason) || isEmpty(reason.trim())){
      this.setState({isRequired:true});
    } else {
      const reasonSaleCancel = {reason:reason};
      this.setState({isLoadingModal: true});
      cancelSale(selectedSaleId,reasonSaleCancel).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({showModalCancel: false, reasonSelected: null, indexSelected: null, isLoadingModal: false});
          this.searchSales(saleFilters, keyword);
        }else{
          console.error(responseMessage)
          this.setState({isLoadingModal:false});
        }
      })
    }
  }

  deleteSale = () => {
    const {selectedSaleId, saleFilters, passwordToDelete} = this.state;
    const {keyword} = this.props;
    if(selectedSaleId && passwordToDelete && !isEmpty(passwordToDelete)){
      this.setState({isLoadingModal: true});
      deleteSale(selectedSaleId, passwordToDelete).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({reasonSelected: null, indexSelected: null, isLoadingModal: false, showModalDelete: false, isDeleted: true,
            isEmptyPassword: false, error: null, passwordToDelete: null}, () => {
            setTimeout(() => {
              this.setState({isDeleted: false});
            }, 3000);
          });
          this.searchSales(saleFilters, keyword);
        }else{
          console.error(responseMessage);
          this.setState({isLoadingModal:false, error:error.message});
        }
      })
    } else{
      this.setState({isEmptyPassword:true})
    }
  }

  closeModalCancelSale = () => {
    this.setState({showModalCancel: false, showModalDelete: false, reasonSelected: null, indexSelected: null,
    error: null, isEmptyPassword: false, passwordToDelete: null, otherReason: null})
  }

  showModalDelete = () => {
    this.setState({showModalCancel: false, showModalDelete: true})
  }

  onChangeInput = (e) => {
    const otherReason = e.target.value;
    this.setState({otherReason});
  }

  onBlurInput = (e) => {
    const otherReason = e.target.value;
    this.setState({otherReason});
  }

  updateQuery = (saleFilters) => {
    this.setState({saleFilters: saleFilters})
  }

  searchSales = (saleFilters, keyword = null) => {
    const {users, pageNumberSale, setPageNumberSales, setSaleFilters} = this.props;
    let {userSelected, currentPage, itemsByPageQuantity} = this.state;

    if(saleFilters.salesmanId){
      users.forEach(user => {
        if (user.id === saleFilters.salesmanId){
          userSelected = user;
        }
      });
    }

    if(pageNumberSale !== undefined){
      currentPage = pageNumberSale
    }

    if(saleFilters.size){
      itemsByPageQuantity = saleFilters.size
    }

    const numFilters = getNumberFilters(saleFilters);

    if(keyword && keyword !== ""){
      saleFilters.keyword = keyword
    }

    if(saleFilters.keyword && (!keyword || keyword === "")){
      delete saleFilters.keyword
    }

    this.setState({isLoading: true});
    loadDealerSales(saleFilters).then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {sales,salesQuantity} = data;
        const totalPages = salesQuantity ? Math.ceil(salesQuantity / itemsByPageQuantity) : 1;
        if(currentPage > totalPages){
          currentPage = totalPages;
          saleFilters.from = 0;
          setSaleFilters(saleFilters);
          setPageNumberSales(currentPage);
          this.searchSales(saleFilters);
        }
        this.setState({showPopover: false, selectedFilter: "", saleFilters, sales, salesQuantity, isLoading: false, userSelected,
          currentPage, itemsByPageQuantity, numFilters});
      }else {
        console.error(responseMessage)
      }
    })
  }

  resetFilters = () => {
    let {setSaleFilters, setPageNumberSales} = this.props;
    let query = {};
    this.setState({isLoading: true});
    loadDealerSales(query).then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {sales,salesQuantity} = data;
        setSaleFilters(query);
        setPageNumberSales(1);
        this.setState({saleFilters: query, showPopover: false, selectedFilter: "", sales, salesQuantity, isLoading: false,
        currentPage: 1, userSelected: null, numFilters: 0});
      }else{
        console.error(responseMessage)
      }
    })
  }

  onChangePassword = (field,evt) => {
    this.setState({[field]:evt.target.value,isEmptyPassword:false,error:null});
  }

  renderDropDownSalesmen = (type, saleId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const {selectOpened, showPopoverSelectSalesman} = this.state
    const target = event.currentTarget;
    const anchorEl = getAnchorEl(target, 2);

    const newSelectOpened = {
      ...selectOpened,
      [saleId]: type
    }

    const newShowPopoverSelectSalesman = {
      ...showPopoverSelectSalesman,
      [saleId]: true
    }

    this.setState({showPopoverSelectSalesman: newShowPopoverSelectSalesman, selectOpened: newSelectOpened, anchorEl: anchorEl})
  }

  onClosePopoverSalesmen = (saleId, evt) =>{
    const {selectOpened, showPopoverSelectSalesman} = this.state
    const newSelectOpened = {
      ...selectOpened,
      [saleId]: ''
    }

    const newShowPopoverSelectSalesman = {
      ...showPopoverSelectSalesman,
      [saleId]: false
    }

    this.setState({showPopoverSelectSalesman: newShowPopoverSelectSalesman, selectOpened: newSelectOpened},
      evt.stopPropagation()
      );
  }

  onClickPopoverSalesmen = (id, type, saleId, evt) => {
    evt.stopPropagation();
    let {users} = this.props;
    let selectedSalesman;

    users.forEach(user => {
      if (user.id === id){
        selectedSalesman = user;
      }
    });

    const {selectOpened, showPopoverSelectSalesman} = this.state
    const newSelectOpened = {
      ...selectOpened,
      [saleId]: ''
    }

    const newShowPopoverSelectSalesman = {
      ...showPopoverSelectSalesman,
      [saleId]: false
    }

    const data = {salesmanId: selectedSalesman.id};
    this.setState({showPopoverSelectSalesman: newShowPopoverSelectSalesman});
    updateSalesmanId(saleId,data).then(response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({selectOpened: newSelectOpened});
      }else{
        console.error(responseMessage);
        this.setState({showPopoverSelectSalesman: newShowPopoverSelectSalesman});
      }
    })


    let {userSelected, saleFilters} = this.state;
    if(userSelected && userSelected.id !== ""){
      saleFilters.salesmanId = userSelected.id
    }
    loadDealerSales(saleFilters).then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {sales,salesQuantity} = data;
        this.setState(
          {
            salesQuantity,
            sales,
          }
        )
      }else{
        console.error(responseMessage)
      }
    })
  }

  render() {
    const {height, left, users, makes, models} = this.props;
    const {heightDifference, showPopover, selectedFilter, anchorEl, showOptions, itemsByPageQuantity, currentPage,
      isLoading, sales, widthPage, selectedCosigners,showPopoverCosigner, showModalNewSale, userSelected,
      isDeleted, salesQuantity, showModalCancel, isLoadingModal, existReason, isRequired, showModalDelete,
      optionsCancelSale, reasonSelected, otherReason, indexSelected, showFilters, saleFilters, userSelectedNew, numFilters,
      isEmptyPassword, error, selectOpened, showPopoverSelectSalesman} = this.state;

    if(!sales && !makes) return null;

    const totalPages = salesQuantity ? Math.ceil(salesQuantity / itemsByPageQuantity) : 1;
    const childOptions = <CosignerChild cosigners={selectedCosigners} />;
    const childReason = renderReason(optionsCancelSale, reasonSelected, otherReason, indexSelected, this.onChangeInput, this.handleCheckReason, this.onBlurInput);

    const messageBodyCustom = <DPMessageDelete isEmptyPassword={isEmptyPassword} title={TITLE_DELETE_SALE}
                                               onDelete={this.deleteSale} subMessage={"This action is irreversible"}
                                               onChangePassword={this.onChangePassword} error={error}/>;

    return (
      <div className="sale-content" style={{height:height - 30}}>
        <Header left={left}
                currentPage={currentPage}
                totalPages={totalPages}
                itemsByPageQuantity={itemsByPageQuantity}
                handleItemsByPage={this.handleItemsByPage}
                handlePageChange={this.handlePageChange}
        />
        {
          isDeleted ?
              <DPMessageBar label={"You have deleted the sale successfully."} containerClassName={`message-bar-sale`}/> : null
        }
        <div className="container-center" style={{padding: isDeleted ? "114px 25px 0 25px" : "80px 25px 0 25px"}}>
          <div className={ isLoading ? "sales-box container-loading" :  "sales-box"} style={{minHeight: (height - heightDifference)}}>
            {
              isLoading ?
                <div className="loader-container">
                  <div className="loader"/>
                </div> :
                <div className="container-sales">
                  <div className="sales-filter">
                    <div className="left" >
                      <button className="button-add" onClick={this.showModalNewSale} data-test={'btn-start-sale'}>
                        Start Sale
                      </button>
                    </div>
                    <div className="btn-container-dp-user">
                      <div id="salesmanId" className="salesman-assigned" onClick={(evt) => this.renderDropDownFilter("salesmanId", evt)}>
                        {
                          userSelected ?
                            <img className="salesman-img" src={userSelected.image ? userSelected.image : pathServer.PATH_IMG+"avatar.png"}/> : null
                        }
                        {
                          userSelected ?
                              <label>{`${getFirstText(userSelected.firstName)} ${getFirstText(userSelected.lastName)}`}</label> :
                              <label>Select Salesman</label>
                        }
                        {selectedFilter === "salesmanId" ?
                            <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                                      className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                            <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                                      className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                        }
                      </div>
                    </div>
                    <div className="btn-container-dp-user">
                      <button className={!showFilters ? "btn-filters" : "btn-filters-background"} onClick={this.showMoreFilters}>
                        <ReactSVG src={`${pathServer.PATH_IMG}/icon/ic_more_filters.svg`} className="ic-more-filter"
                                  beforeInjection={ svg => svg.classList.add(!showFilters ? "ic-more-filter-svg" : "ic-more-filter-svg-background")}/>
                        {
                          numFilters > 0 && !showFilters ?
                            <label className="label-btn-filter">{`More Filters `}
                              <label className="label-num-filters">{` (${numFilters})`}</label>
                            </label> :
                            <label className={!showFilters ? "label-btn-filter" : "label-btn-filter-background"}>More Filters</label>
                        }
                      </button>
                    </div>
                    {
                      showFilters ?
                        <SaleFilter saleFilters={saleFilters} makes={makes} models={models} showMoreFilters={this.showMoreFilters}
                                    updateQuery={this.updateQuery} resetFilters={this.resetFilters} searchSales={this.searchSales}/>
                        : null
                    }
                  </div>
                  {
                    sales && sales.length === 0 ?
                      <div className="message-not-found">
                        <h3> No sales matched your search </h3>
                      </div> :
                      <div className="table-customer">
                        <table className="table">
                          <thead style={{backgroundColor: "#EFEFEF"}}>
                          <tr>
                            <th className="th-photo">Photo</th>
                            <th className="th-user">Main Purchaser</th>
                            <th className="th-phone th-car">Vehicle</th>
                            <th className="th-phone">Creation date</th>
                            <th className="th-phone">Last Update</th>
                            <th className="th-phone">Seller</th>
                            <th className="th-phone th-status">Status</th>
                            <th className="th-actions"/>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            sales && sales.map((sale, i) => {
                              const {saleId,firstName,lastName,image, carName,createDate, modifiedDate,saleType,status,cosigners,
                                salesManFirstName,salesManLastName,salesManImage, isCarTemporary, salesManId} = sale;
                              const selectedSalesman = users.find(el => el.id === salesManId)
                              const name = firstName ? `${firstName} ${lastName}` : NOT_SELECTED;
                              const firstNameSaleMan = getFirstText(salesManFirstName);
                              const lastNameSaleMan = getFirstText(salesManLastName);
                              const salesManName = `${firstNameSaleMan} ${lastNameSaleMan}`;
                              return (
                                  <tr key={i + 'tr'} className="tr-customer"
                                      onClick={(evt) => this.handleSelect(sale,evt)}
                                      data-test={`row-sale-${i}`}>
                                    <td className="td-image">
                                      {
                                        image ?
                                            <img className={"image-user"}
                                                 src={image}/> :
                                            <div className="user-box">
                                              <img className={"image-user-default"}
                                                   src={pathServer.PATH_IMG + "ic_user_group.png"}/>
                                            </div>
                                      }
                                    </td>
                                    <td className="td-name" style={name === NOT_SELECTED ? {color: '#D7DAE2'} : null}>
                                      {name}
                                      {
                                        cosigners.length > 0 ?
                                            <span id={`cosigner${i}`} style={{color: '#2194F1', paddingLeft: 3}}
                                                  onClick={(evt) => this.handleShowCosigner(cosigners, evt)}>
                                    {`(${cosigners.length})`}
                                  </span> : null
                                      }
                                    </td>
                                    <td  className="td-car" style={!carName ? {color: '#D7DAE2'} : null}>
                                      {carName ? isCarTemporary ?
                                        <div className="container-car-name-message">
                                          <span className="car-name" id={`car-name-${i}`}>{carName}</span>
                                          <div className="container-car-temporary" id={`car-message-${i}`}>
                                            <span className="car-temporary">Not in inventory yet</span>
                                            <span className="car-temporary-floating">Not in inventory yet</span>
                                          </div>
                                        </div> :
                                          <span>{carName}</span> :
                                        NOT_SELECTED}
                                    </td>
                                    <td className="td-phone">
                                      <span>{Moment(createDate).format('MM/DD/YYYY')}</span>
                                      <span style={{marginLeft:10}}>{Moment(createDate).format('HH:mm')}</span>
                                    </td>
                                    <td className="td-phone">
                                      <span>{Moment(modifiedDate).format('MM/DD/YYYY')}</span>
                                      <span style={{marginLeft:10}}>{Moment(modifiedDate).format('HH:mm')}</span>
                                    </td>
                                    <td className="td-phone">
                                      {
                                        (/*status !== saleStatus.STARTED &&*/ status !== saleStatus.IN_PROGRESS) &&
                                          <div
                                            id="salesMan"
                                            className="btn-general-message-dp-customer"
                                          >
                                            {
                                              salesManImage
                                                ? <img
                                                  className="image-user"
                                                  src={salesManImage}
                                                />
                                                : <div className="user-box">
                                                  <img className={'image-user-default'}
                                                       src={pathServer.PATH_IMG + 'ic_user_group.png'}/>
                                                </div>
                                            }
                                            <span>{salesManName}</span>
                                          </div>
                                      }

                                      {
                                        (/*status === saleStatus.STARTED ||*/ status === saleStatus.IN_PROGRESS) &&
                                          <div className="container-salesman">
                                            <button
                                              id="assignedSalesmanId"
                                              className="salesman-assigned"
                                              onClick={(evt) =>
                                                this.renderDropDownSalesmen('assignedSalesmanId', saleId, evt)
                                              }
                                              aria-readonly={false}
                                              disabled={false}
                                            >
                                              <img
                                                className="salesman-img"
                                                src={
                                                  (selectedSalesman && selectedSalesman.image)
                                                    ? selectedSalesman.image
                                                    : `${pathServer.PATH_IMG}avatar.png`
                                                }
                                              />
                                              <label>
                                                {getFirstText(selectedSalesman?.firstName ?? "")} {getFirstText(selectedSalesman?.lastName ?? "")}
                                              </label>
                                              {
                                                selectOpened
                                                && selectOpened[saleId]
                                                && selectOpened[saleId] === "assignedSalesmanId"
                                                  ? <ReactSVG
                                                    src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                                                    className="icon-arrow-content"
                                                    beforeInjection={svg => svg.classList.add('icon-arrow-expand')}
                                                  />
                                                  : <ReactSVG
                                                    src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                                                    className="icon-arrow-content"
                                                    beforeInjection={svg => svg.classList.add('icon-arrow-expand')}
                                                  />
                                              }
                                            </button>
                                            {
                                              showPopoverSelectSalesman
                                              && showPopoverSelectSalesman[saleId]
                                                ? <Popover
                                                  anchorEl={anchorEl}
                                                  child=
                                                    {
                                                      <BodyOptions
                                                        users={users}
                                                        userSelected={selectedSalesman}
                                                        onClickPopover={(id, type, evt) => {
                                                          this.onClickPopoverSalesmen(id, type, saleId, evt)
                                                        }}
                                                      />
                                                    }
                                                  classPopover="popover-box-message popover-users"
                                                  handleRequestClose={(evt) => this.onClosePopoverSalesmen(saleId, evt)}
                                                />
                                                : null
                                            }
                                          </div>
                                      }
                                    </td>
                                    <td className="td-phone" style={{color: getColorByStatus(status)}}>
                                      {saleStatusValue[status]}
                                    </td>
                                    <td className="td-phone">
                                      {
                                        widthPage > WIDTH_VIEW_TABLET ?
                                            <div className="customer-actions">
                                    <span>
                                      {
                                        status !== saleStatus.CANCELLED ?
                                            <ReactSVG key={`${i}cancel`} id="imgCancel"
                                                      src={`${pathServer.PATH_IMG}/icon/ic_close2.svg`}
                                                      className="ic-cancel-sale"
                                                      beforeInjection={ svg => svg.classList.add("ic-cancel-sale-svg")}
                                                      onClick={(evt) => this.handleSelectSaleId(saleId, "imgCancel", evt)}/> :
                                            null
                                      }
                                      <ReactSVG key={i + 'trash'} id="imgTrash" src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                               className="img-delete"
                                               beforeInjection={ svg => svg.classList.add('img-delete')}
                                               onClick={(evt) => this.handleSelectSaleId(saleId,"imgTrash", evt)}/>
                                    </span>
                                            </div> : null
                                      }
                                    </td>
                                  </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                  }
                </div>
            }
          </div>
        </div>
        { showPopoverCosigner && selectedCosigners !== null ?
          <Popover anchorEl={anchorEl}
                   child={childOptions}
                   classPopover="popover-box-message"
                   handleRequestClose={this.handleRequestClose}
                   width="auto"/> : null
        }
        {
          showModalNewSale ?
            <ModalNewSale show={showModalNewSale} onHide={this.closePopoverNewSale} showPopover={showPopover} user={userSelectedNew}
                          renderDropDownFilter={this.renderDropDownFilter}
                          child={<BodyOptions users ={users} userSelected={userSelectedNew} onClickPopover={this.onClickPopover}
                                              type={"userSelected"}/>}
                          anchorEl={anchorEl} handleRequestClose={this.handleRequestClose} goToSale={this.goToSale}/> :
            null
        }
        {
          showModalCancel ?
              <GenericModalConfirm show={showModalCancel}
                                   showCustomIcon={true}
                                   onHide={this.closeModalCancelSale}
                                   modalCancelSale={"modal-cancel-sale"}
                                   isLoading={isLoadingModal}>
                <BodyReason stylePanelRight={existReason ? {padding: "30px 20px"} : {padding: "55px 20px"}} childReason={childReason}
                            message={"Why are you canceling this Sale?"} isRequired={isRequired} onHide={this.closeModalCancelSale}
                            save={this.confirmCancelSale} buttonLabelSave={"Cancel Sale"} labelRequired={"Reason is required"}
                            buttonDelete="Delete Sale" deleteSale={this.showModalDelete} />
              </GenericModalConfirm>
                : null
        }
        {
          showModalDelete ?
              <ModalConfirmDelete show={showModalDelete}
                                  showButtonDelete={false}
                                  onSubmit={this.deleteSale}
                                  onHide={this.closeModalCancelSale}
                                  classButtonDelete="btn-delete"
                                  buttonLabelDelete="Yes, delete"
                                  styleModal="modal-delete-sale"
                                  styleRight="panel-right-delete"
                                  isSale={true}
                                  body={messageBodyCustom}
                                  styleLeft="panel-left-delete"/> : null
        }
        { showPopover && selectedFilter === "salesmanId" ?
            <Popover anchorEl={anchorEl}
                     child={<BodyOptions users ={users} userSelected={userSelected} onClickPopover={this.onClickPopover}
                                         type={"salesmanId"}/>}
                     classPopover="popover-box-message popover-users"
                     handleRequestClose={this.handleRequestClose}/> : null
        }
      </div>
    );
  }
}

export default Sales;
