import React from 'react'
import Review from './Review'
import {filterReviewsList} from './ReviewPage'

const totalPaddingContainer = 30
const tabHeaderHeight = 35.7

const Reviews = ({id,height, reviews, archiveReview, updateCommentReply, handleCheckTag, isLoading, pageId, getTestimonials, setIsLoading}) => {
  const reviewsListToDisplay = filterReviewsList(id, reviews)
  return(
    <div className="container-reviews-social-networks" >
      {
        isLoading?
          <div className="loader-container">
            <div className="loader"/>
          </div> :
          <div className="box-reviews" style={{maxHeight: height - totalPaddingContainer - tabHeaderHeight , overflow: 'auto'}}>
            <div className="container-box-review">
              {
                reviewsListToDisplay.length > 0 && reviewsListToDisplay.map((review, index) => {
                  return(
                    <Review key={`review-${index}`} review={review} archiveReview={archiveReview} typeReviews={id}
                            updateCommentReply={updateCommentReply} handleCheckTag={handleCheckTag} pageId={pageId}
                            getTestimonials={getTestimonials} setIsLoading={setIsLoading}/>
                  )
                })
              }
            </div>
          </div>
      }
    </div>
  )
}

export default Reviews