import React from 'react'
import {DropTarget} from 'react-dnd'
import InventoryPhotoDraggable from './InventoryPhotoDraggable.jsx'

const {
  Component
} = React;

var photoDrop = {
  drop: function(props, monitor, component) {
    var item = monitor.getItem();
    var targetOrder = props.release.order;
    if (item.currentOrder !== targetOrder) {
      props.onHover(item.currentOrder, targetOrder);
      item.currentOrder = targetOrder;
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isHoverOver: monitor.isOver()
  };
}

class InventoryPhotoDropTarget extends Component {

  constructor() {
    super();
  }

  render() {
    const {release,onClick,onDragEvent,isDragging,connectDropTarget,width,height,search,edit,active,query,attributeValuesToCarStatus, updateCarsAfterChangeStatus, handleUpdateToInspectRepair} = this.props;

    let order = 1;

    return (connectDropTarget(
      <div id="inventoryPhotoDropTarget" className="col-sm-3  car-image-row">
        <InventoryPhotoDraggable currentOrder={order}
                                 release={release}
                                 onClick={onClick}
                                 opacity={isDragging ? 0.4 : 1}
                                 onDragEvent={onDragEvent}
                                 width={width}
                                 height={height}
                                 search={search}
                                 edit={edit}
                                 delete={this.props.delete}
                                 deactivate={this.props.deactivate}
                                 activate={this.props.activate}
                                 //active={active}
                                 query={query}
                                 attributeValuesToCarStatus={attributeValuesToCarStatus}
                                 updateCarsAfterChangeStatus={updateCarsAfterChangeStatus}
                                 handleUpdateToInspectRepair={handleUpdateToInspectRepair}
        />
      </div>));
  }

}

export default DropTarget('inventoryPhoto', photoDrop, collect)(InventoryPhotoDropTarget);