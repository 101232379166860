import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReviewContainer from './ReviewContainer.jsx'
import ReviewItem from './ReviewItem.js'
import ExternalComponent from './ExternalComponent.js'
import {Modal} from 'react-bootstrap'
import {DPToggle} from '../../common/DPToggle'
import {TitleHeader} from '../sale/SaleEdit'
import {
  ATTRIBUTE_CUSTOMER_ADDRESS,
  ATTRIBUTE_CUSTOMER_CELLPHONE,
  ATTRIBUTE_CUSTOMER_CITY,
  ATTRIBUTE_CUSTOMER_EMAIL,
  ATTRIBUTE_CUSTOMER_FIRST_NAME,
  ATTRIBUTE_CUSTOMER_LAST_NAME,
  ATTRIBUTE_CUSTOMER_MIDDLE_NAME,
  ATTRIBUTE_CUSTOMER_SECOND_PHONE,
  ATTRIBUTE_CUSTOMER_STATE,
  ATTRIBUTE_CUSTOMER_ZIP_CODE,
  CODE_NUMBER_PHONE_EEUU,
  CUSTOMERS_BY_PAGE_BY_DEFAULT,
  ENTTIY_TYPE,
  NEW_CUSTOMER_REVIEW_FORM,
  FACEBOOK_KEY,
  GOOGLE_KEY,
} from '../../Constants'
import {ReactSVG} from 'react-svg'
import DPButton from '../../common/DPButton'
import {DPInput} from '../../common/DPInput'
import {browserHistory} from 'react-router'
import {
  endReview,
  loadCustomerById,
  loadCustomersApi,
  loadFormByCode,
  loadSaleConfigurations,
  processFormApi, saveDealerConfiguration,
} from '../../Api'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import {DPInputElement} from '../../common/DPInputElement'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import {DPCustomPill} from '../util/ComponentUtils'
import {checkValidEmail, checkValidPhone} from '../../../common/ValidationUtil'
import uniqBy from 'lodash/uniqBy'
import ReviewsSection from './ReviewsSection'
import {debounce} from 'lodash'
import ReviewPage from './ReviewPage'

require('./ReviewsTabSetting.scss')

export const MESSAGES = {
  REVIEW_REQUEST: 'review.request.default.message',
  REVIEW_PAGE: 'review.page.default.message',
}

export const showFacebook = 'review.page.facebook.preview'
export const showGoogle = 'review.page.google.preview'

export const SOCIAL_NETWORK_PREVIEW_CONFIGURATION = {
  [FACEBOOK_KEY]: 'review.page.facebook.preview',
  [GOOGLE_KEY]: 'review.page.google.preview'
}

const DEFAULT_MESSAGE = {
  REVIEW_REQUEST: '',
  REVIEW_PAGE: '',
}

const TOGGLE = {
  SMS: 'sms',
  EMAIL: 'email',
}

const CUSTOMER_INFO = {
  NAME: ATTRIBUTE_CUSTOMER_FIRST_NAME,
  MIDDLE_NAME: ATTRIBUTE_CUSTOMER_MIDDLE_NAME,
  LAST_NAME: ATTRIBUTE_CUSTOMER_LAST_NAME,
  PHONE_NUMBER: ATTRIBUTE_CUSTOMER_CELLPHONE,
  SECOND_PHONE_NUMBER: ATTRIBUTE_CUSTOMER_SECOND_PHONE,
  EMAIL: ATTRIBUTE_CUSTOMER_EMAIL,
  ADDRESS: ATTRIBUTE_CUSTOMER_ADDRESS,
  CITY: ATTRIBUTE_CUSTOMER_CITY,
  STATE: ATTRIBUTE_CUSTOMER_STATE,
  ZIPCODE: ATTRIBUTE_CUSTOMER_ZIP_CODE,
  CONTACT_STATUS: 'customer.contactStatus',
}

const CUSTOMER_TEXT_SEARCHING = 'customerTextForSearching'

const DEFAULTS = {
  CUSTOMER_INFO: {
    [CUSTOMER_INFO.NAME]: '',
    [CUSTOMER_INFO.LAST_NAME]: '',
    [CUSTOMER_INFO.MIDDLE_NAME]: '',
    [CUSTOMER_INFO.PHONE_NUMBER]: '',
    [CUSTOMER_INFO.SECOND_PHONE_NUMBER]: '',
    [CUSTOMER_INFO.EMAIL]: '',
    [CUSTOMER_INFO.ADDRESS]: '',
    [CUSTOMER_INFO.CITY]: '',
    [CUSTOMER_INFO.STATE]: '',
    [CUSTOMER_INFO.ZIPCODE]: '',
  },
  COINCIDENCES: {
    [CUSTOMER_INFO.NAME]: [],
    [CUSTOMER_INFO.LAST_NAME]: [],
    [CUSTOMER_INFO.EMAIL]: [],
    [CUSTOMER_INFO.PHONE_NUMBER]: [],
  },
  REQUIRED_FIELDS: {
    [CUSTOMER_INFO.NAME]: true,
    [CUSTOMER_INFO.LAST_NAME]: true,
    [CUSTOMER_INFO.MIDDLE_NAME]: false,
    [CUSTOMER_INFO.PHONE_NUMBER]: true,
    [CUSTOMER_INFO.SECOND_PHONE_NUMBER]: false,
    [CUSTOMER_INFO.EMAIL]: false,
    [CUSTOMER_INFO.ADDRESS]: false,
    [CUSTOMER_INFO.CITY]: false,
    [CUSTOMER_INFO.STATE]: false,
    [CUSTOMER_INFO.ZIPCODE]: false,
    [CUSTOMER_INFO.CONTACT_STATUS]: false,
  },
  VALIDATION_FIELDS: {
    [CUSTOMER_INFO.PHONE_NUMBER]: checkValidPhone,
    [CUSTOMER_INFO.EMAIL]: checkValidEmail,
  },
  VALIDATION_ERRORS: {
    REQUIRED_FIELD: {
      [CUSTOMER_INFO.PHONE_NUMBER]: 'Phone number is required',
      [CUSTOMER_INFO.NAME]: 'Name is required',
      [CUSTOMER_INFO.LAST_NAME]: 'Last name is required',
    },
    [CUSTOMER_INFO.EMAIL]: 'Invalid Email',
    [CUSTOMER_INFO.PHONE_NUMBER]: 'Invalid phone number',
  }
}

function getReviewsConfiguration(reviewConfigurations) {
  const reviewRequestConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_REQUEST)
  const reviewPageConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_PAGE)
  const reviewPageShowFacebook = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[FACEBOOK_KEY])
  const reviewPageShowGoogle = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[GOOGLE_KEY])
  return {reviewRequestConfiguration, reviewPageConfiguration, reviewPageShowFacebook, reviewPageShowGoogle}
}

function getUpdatedValuesIds(attributesList){
  const updatedValuesIds = {}
  updatedValuesIds[CUSTOMER_INFO.NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.NAME), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.MIDDLE_NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.MIDDLE_NAME), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.LAST_NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.LAST_NAME), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.PHONE_NUMBER] = get(attributesList.find(el => el.name === CUSTOMER_INFO.PHONE_NUMBER), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.SECOND_PHONE_NUMBER] = get(attributesList.find(el => el.name === CUSTOMER_INFO.SECOND_PHONE_NUMBER), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.EMAIL] = get(attributesList.find(el => el.name === CUSTOMER_INFO.EMAIL), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.ADDRESS] = get(attributesList.find(el => el.name === CUSTOMER_INFO.ADDRESS), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.CITY] = get(attributesList.find(el => el.name === CUSTOMER_INFO.CITY), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.STATE] = get(attributesList.find(el => el.name === CUSTOMER_INFO.STATE), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.ZIPCODE] = get(attributesList.find(el => el.name === CUSTOMER_INFO.ZIPCODE), ['id'], '')
  updatedValuesIds[CUSTOMER_INFO.CONTACT_STATUS] = get(attributesList.find(el => el.name === CUSTOMER_INFO.CONTACT_STATUS), ['id'], '')
  return updatedValuesIds
}

class ReviewsTabSetting extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      showBackground: true,
      overlayActive: 'request',
      showSendMailModal: false,
      [TOGGLE.SMS]: true,
      [TOGGLE.EMAIL]: false,
      showSecondDialogBody: false,
      showReviewPage: false, /**** delete that later****/
      customerInfo: DEFAULTS.CUSTOMER_INFO,
      customerValuesIds: {
        [CUSTOMER_INFO.NAME]: '',
        [CUSTOMER_INFO.LAST_NAME]: '',
        [CUSTOMER_INFO.MIDDLE_NAME]: '',
        [CUSTOMER_INFO.PHONE_NUMBER]: '',
        [CUSTOMER_INFO.SECOND_PHONE_NUMBER]: '',
        [CUSTOMER_INFO.EMAIL]: '',
        [CUSTOMER_INFO.ADDRESS]: '',
        [CUSTOMER_INFO.CITY]: '',
        [CUSTOMER_INFO.STATE]: '',
        [CUSTOMER_INFO.ZIPCODE]: '',
      },
      originalValues: {},
      contactStatusList: [],
      [CUSTOMER_TEXT_SEARCHING]: '',
      customers: [],
      selectedCustomers: [],
      showCompletedSentModal: false,
      [MESSAGES.REVIEW_PAGE]: '',
      [MESSAGES.REVIEW_REQUEST]: DEFAULT_MESSAGE.REVIEW_REQUEST,
      coincidences: DEFAULTS.COINCIDENCES,
      areValidAllRequiredFields: true,
      validationErrors: [],
      isDisabled: {
        [MESSAGES.REVIEW_REQUEST]: true,
        [MESSAGES.REVIEW_PAGE]: true,
        requestMessageTemporal: true,
        pageMessageTemporal: true,
      },
      existingCustomer: undefined,
      isLoading: true,
      requestMessageTemporal: '',
      pageMessageTemporal: '',
      enableEmailToggle: false,
      existingCustomersByEmailAndPhone: [],
      isLoadingBtnSave: false,
      isBtnSaved: false,
      isLoadingBtnSavePrev: false,
      isBtnSavedPrev: false,
      showPreviewFacebook: true,
      showPreviewGoogle: true,
    }
    this.debouncedHandleCoincidences = debounce(this.handleCoincidences, 500)
  }

  componentDidMount = async () => {
    const {location} = this.props;
    if (location.state) {
      if (isEmpty(location.state?.overlayActive)) {
        this.setState({ isLoading: true })
        const {responseCode, responseMessage, data } = await loadSaleConfigurations()
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
          this.setState({ showSendMailModal: false, isLoading: false })
          return
        }

        const reviewConfigurations = data.reviewConfigurations ?? []
          /*
        const reviewRequestConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_REQUEST)
        const reviewPageConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_PAGE)
        const reviewPageShowFacebook = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[FACEBOOK_KEY])
        const reviewPageShowGoogle = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[GOOGLE_KEY])

           */
        const {reviewRequestConfiguration, reviewPageConfiguration, reviewPageShowFacebook, reviewPageShowGoogle} =  getReviewsConfiguration(reviewConfigurations)
        const reviewRequestConfigurationValue = get(reviewRequestConfiguration, ['value'], '')
        const reviewPageConfigurationValue = get(reviewPageConfiguration, ['value'], '')
        const reviewPageShowFacebookValue = get(reviewPageShowFacebook, ['value'], '')
        const reviewPageShowGoogleValue = get(reviewPageShowGoogle, ['value'], '')
        let overlayActive = ''

        if (isEmpty(reviewRequestConfigurationValue)) {
          overlayActive = 'request'
        } else if (isEmpty(reviewPageConfigurationValue)) {
          overlayActive = 'page'
        } else {
          overlayActive = 'noReviewsYet'
        }
        const state = {
          reviewConfigurations,
          [MESSAGES.REVIEW_REQUEST]: reviewRequestConfigurationValue,
          [MESSAGES.REVIEW_PAGE]: reviewPageConfigurationValue,
          isLoading: false,
          requestMessageTemporal: reviewRequestConfigurationValue,
          pageMessageTemporal: reviewPageConfigurationValue,
          showPreviewFacebook: reviewPageShowFacebookValue === 'true',
          showPreviewGoogle: reviewPageShowGoogleValue === 'true',
          overlayActive,
        }
        const {responseCode: responseCodeCustomer, responseMessage: responseMessageCustomer, data: dataCustomer} = await loadCustomersApi('', 0, CUSTOMERS_BY_PAGE_BY_DEFAULT)
        if (responseCodeCustomer !== SUCCESSFUL_CODE) {
          console.error(responseMessageCustomer)
          this.setState(state)
          return
        }
        state.customers = dataCustomer.customers ?? [];
        const {responseCode: responseCodeForm, responseMessage: responseMessageForm, data: dataForm} = await loadFormByCode(NEW_CUSTOMER_REVIEW_FORM, 'new')
        if (responseCodeForm !== SUCCESSFUL_CODE) {
          console.error(responseMessageForm)
          this.setState(state)
          return
        }
        const {form, values} = dataForm
        const attributesList = form.formAttributes.attributes
        /*
        let updatedValuesIds = {}
        updatedValuesIds[CUSTOMER_INFO.NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.NAME), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.MIDDLE_NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.MIDDLE_NAME), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.LAST_NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.LAST_NAME), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.PHONE_NUMBER] = get(attributesList.find(el => el.name === CUSTOMER_INFO.PHONE_NUMBER), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.SECOND_PHONE_NUMBER] = get(attributesList.find(el => el.name === CUSTOMER_INFO.SECOND_PHONE_NUMBER), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.EMAIL] = get(attributesList.find(el => el.name === CUSTOMER_INFO.EMAIL), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.ADDRESS] = get(attributesList.find(el => el.name === CUSTOMER_INFO.ADDRESS), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.CITY] = get(attributesList.find(el => el.name === CUSTOMER_INFO.CITY), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.STATE] = get(attributesList.find(el => el.name === CUSTOMER_INFO.STATE), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.ZIPCODE] = get(attributesList.find(el => el.name === CUSTOMER_INFO.ZIPCODE), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.CONTACT_STATUS] = get(attributesList.find(el => el.name === CUSTOMER_INFO.CONTACT_STATUS), ['id'], '')
         */
        const updatedValuesIds = getUpdatedValuesIds(attributesList)

        const contactStatusList = get(attributesList.find(el => el.name === CUSTOMER_INFO.CONTACT_STATUS), ['attributeValues'], [])

        this.setState({
          ...state,
          customerValuesIds: updatedValuesIds,
          customerInfo: {
            ...this.state.customerInfo,
            [CUSTOMER_INFO.NAME]: values[updatedValuesIds[CUSTOMER_INFO.NAME]],
            [CUSTOMER_INFO.MIDDLE_NAME]: values[updatedValuesIds[CUSTOMER_INFO.MIDDLE_NAME]],
            [CUSTOMER_INFO.LAST_NAME]: values[updatedValuesIds[CUSTOMER_INFO.LAST_NAME]],
            [CUSTOMER_INFO.PHONE_NUMBER]: values[updatedValuesIds[CUSTOMER_INFO.PHONE_NUMBER]],
            [CUSTOMER_INFO.SECOND_PHONE_NUMBER]: values[updatedValuesIds[CUSTOMER_INFO.SECOND_PHONE_NUMBER]],
            [CUSTOMER_INFO.EMAIL]: values[updatedValuesIds[CUSTOMER_INFO.EMAIL]],
            [CUSTOMER_INFO.ADDRESS]: values[updatedValuesIds[CUSTOMER_INFO.ADDRESS]],
            [CUSTOMER_INFO.CITY]: values[updatedValuesIds[CUSTOMER_INFO.CITY]],
            [CUSTOMER_INFO.STATE]: values[updatedValuesIds[CUSTOMER_INFO.STATE]],
            [CUSTOMER_INFO.ZIPCODE]: values[updatedValuesIds[CUSTOMER_INFO.ZIPCODE]],
            [CUSTOMER_INFO.CONTACT_STATUS]: values[updatedValuesIds[CUSTOMER_INFO.CONTACT_STATUS]].toString(),
          },
          originalValues: values,
          contactStatusList,
        })
      } else {
        this.setState({ isLoading: true })
        const {responseCode, responseMessage, data} = await loadSaleConfigurations()
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
          this.setState({ isLoading: false })
          return
        }

        const reviewConfigurations = data?.reviewConfigurations?? []
        /*
        const reviewRequestConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_REQUEST)
        const reviewPageConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_PAGE)
        const reviewPageShowFacebook = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[FACEBOOK_KEY])
        const reviewPageShowGoogle = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[GOOGLE_KEY])
         */
        const {reviewRequestConfiguration, reviewPageConfiguration, reviewPageShowFacebook, reviewPageShowGoogle} =  getReviewsConfiguration(reviewConfigurations)
        const reviewPageShowFacebookValue = get(reviewPageShowFacebook, ['value'], '')
        const reviewPageShowGoogleValue = get(reviewPageShowGoogle, ['value'], '')
        const state = {
          reviewConfigurations,
          [MESSAGES.REVIEW_REQUEST]: get(reviewRequestConfiguration, ['value'], ''),
          [MESSAGES.REVIEW_PAGE]: get(reviewPageConfiguration, ['value'], ''),
          isLoading: false,
          showPreviewFacebook: reviewPageShowFacebookValue === 'true',
          showPreviewGoogle: reviewPageShowGoogleValue === 'true',
        }
        const {responseCode: responseCodeCustomer, responseMessage: responseMessageCustomer, data: dataCustomer} = await loadCustomersApi('', 0, CUSTOMERS_BY_PAGE_BY_DEFAULT)
        if (responseCodeCustomer !== SUCCESSFUL_CODE) {
          console.error(responseMessageCustomer)
          this.setState(state)
          return
        }

        state.customers = dataCustomer.customers ?? []
        const {responseCode: responseCodeForm, responseMessage: responseMessageForm, data: dataForm} = await loadFormByCode(NEW_CUSTOMER_REVIEW_FORM, 'new')
        if (responseCodeForm !== SUCCESSFUL_CODE) {
          console.error(responseMessageForm)
          this.setState(state)
          return
        }
        const {form, values} = dataForm
        const attributesList = form.formAttributes.attributes
        /*
        let updatedValuesIds = {}
        updatedValuesIds[CUSTOMER_INFO.NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.NAME), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.MIDDLE_NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.MIDDLE_NAME), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.LAST_NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.LAST_NAME), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.PHONE_NUMBER] = get(attributesList.find(el => el.name === CUSTOMER_INFO.PHONE_NUMBER), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.SECOND_PHONE_NUMBER] = get(attributesList.find(el => el.name === CUSTOMER_INFO.SECOND_PHONE_NUMBER), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.EMAIL] = get(attributesList.find(el => el.name === CUSTOMER_INFO.EMAIL), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.ADDRESS] = get(attributesList.find(el => el.name === CUSTOMER_INFO.ADDRESS), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.CITY] = get(attributesList.find(el => el.name === CUSTOMER_INFO.CITY), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.STATE] = get(attributesList.find(el => el.name === CUSTOMER_INFO.STATE), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.ZIPCODE] = get(attributesList.find(el => el.name === CUSTOMER_INFO.ZIPCODE), ['id'], '')
        updatedValuesIds[CUSTOMER_INFO.CONTACT_STATUS] = get(attributesList.find(el => el.name === CUSTOMER_INFO.CONTACT_STATUS), ['id'], '')
         */
        const updatedValuesIds = getUpdatedValuesIds(attributesList)
        const contactStatusList = get(attributesList.find(el => el.name === CUSTOMER_INFO.CONTACT_STATUS), ['attributeValues'], [])

        this.setState({
          ...state,
          customerValuesIds: updatedValuesIds,
          customerInfo: {
            ...this.state.customerInfo,
            [CUSTOMER_INFO.NAME]: values[updatedValuesIds[CUSTOMER_INFO.NAME]],
            [CUSTOMER_INFO.MIDDLE_NAME]: values[updatedValuesIds[CUSTOMER_INFO.MIDDLE_NAME]],
            [CUSTOMER_INFO.LAST_NAME]: values[updatedValuesIds[CUSTOMER_INFO.LAST_NAME]],
            [CUSTOMER_INFO.PHONE_NUMBER]: values[updatedValuesIds[CUSTOMER_INFO.PHONE_NUMBER]],
            [CUSTOMER_INFO.SECOND_PHONE_NUMBER]: values[updatedValuesIds[CUSTOMER_INFO.SECOND_PHONE_NUMBER]],
            [CUSTOMER_INFO.EMAIL]: values[updatedValuesIds[CUSTOMER_INFO.EMAIL]],
            [CUSTOMER_INFO.ADDRESS]: values[updatedValuesIds[CUSTOMER_INFO.ADDRESS]],
            [CUSTOMER_INFO.CITY]: values[updatedValuesIds[CUSTOMER_INFO.CITY]],
            [CUSTOMER_INFO.STATE]: values[updatedValuesIds[CUSTOMER_INFO.STATE]],
            [CUSTOMER_INFO.ZIPCODE]: values[updatedValuesIds[CUSTOMER_INFO.ZIPCODE]],
            [CUSTOMER_INFO.CONTACT_STATUS]: values[updatedValuesIds[CUSTOMER_INFO.CONTACT_STATUS]].toString(),
          },
          originalValues: values,
          contactStatusList,
        })
      }
    } else {
      this.setState({ isLoading: true })
      const {responseCode, responseMessage, data} = await loadSaleConfigurations()
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        this.setState({ isLoading: false })
        return
      }

      const reviewConfigurations = data.reviewConfigurations ?? []
      /*
      const reviewRequestConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_REQUEST)
      const reviewPageConfiguration = reviewConfigurations.find(el => el.code === MESSAGES.REVIEW_PAGE)
      const reviewPageShowFacebook = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[FACEBOOK_KEY])
      const reviewPageShowGoogle = reviewConfigurations.find(el => el.code === SOCIAL_NETWORK_PREVIEW_CONFIGURATION[GOOGLE_KEY])
       */
      const {reviewRequestConfiguration, reviewPageConfiguration, reviewPageShowFacebook, reviewPageShowGoogle} =  getReviewsConfiguration(reviewConfigurations)
      const reviewRequestConfigurationValue = get(reviewRequestConfiguration, ['value'], '')
      const reviewPageConfigurationValue = get(reviewPageConfiguration, ['value'], '')
      const reviewPageShowFacebookValue = get(reviewPageShowFacebook, ['value'], '')
      const reviewPageShowGoogleValue = get(reviewPageShowGoogle, ['value'], '')
      let overlayActive = ''

      if (isEmpty(reviewRequestConfigurationValue)) {
        overlayActive = 'request'
      } else if (isEmpty(reviewPageConfigurationValue)) {
        overlayActive = 'page'
      } else {
        overlayActive = 'noReviewsYet'
      }

      const state = {
        reviewConfigurations,
        [MESSAGES.REVIEW_REQUEST]: reviewRequestConfigurationValue,
        [MESSAGES.REVIEW_PAGE]: reviewPageConfigurationValue,
        isLoading: false,
        overlayActive,
        requestMessageTemporal: reviewRequestConfigurationValue,
        pageMessageTemporal: reviewPageConfigurationValue,
        showPreviewFacebook: reviewPageShowFacebookValue === 'true',
        showPreviewGoogle: reviewPageShowGoogleValue === 'true',
      }
      const {responseCode: responseCodeCustomer, responseMessage: responseMessageCustomer, data: dataCustomer} = await loadCustomersApi('', 0, CUSTOMERS_BY_PAGE_BY_DEFAULT)
      if (responseCodeCustomer !== SUCCESSFUL_CODE) {
        console.error(responseMessageCustomer)
        this.setState(state)
        return
      }

      state.customers = dataCustomer.customers ?? []
      const {responseCode: responseCodeForm, responseMessage: responseMessageForm, data: dataForm} = await loadFormByCode(NEW_CUSTOMER_REVIEW_FORM, 'new')
      if (responseCodeForm !== SUCCESSFUL_CODE) {
        console.error(responseMessageForm)
        this.setState(state)
        return
      }
      const {form, values} = dataForm
      const attributesList = form.formAttributes.attributes
      /*
      let updatedValuesIds = {}
      updatedValuesIds[CUSTOMER_INFO.NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.NAME), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.MIDDLE_NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.MIDDLE_NAME), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.LAST_NAME] = get(attributesList.find(el => el.name === CUSTOMER_INFO.LAST_NAME), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.PHONE_NUMBER] = get(attributesList.find(el => el.name === CUSTOMER_INFO.PHONE_NUMBER), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.SECOND_PHONE_NUMBER] = get(attributesList.find(el => el.name === CUSTOMER_INFO.SECOND_PHONE_NUMBER), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.EMAIL] = get(attributesList.find(el => el.name === CUSTOMER_INFO.EMAIL), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.ADDRESS] = get(attributesList.find(el => el.name === CUSTOMER_INFO.ADDRESS), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.CITY] = get(attributesList.find(el => el.name === CUSTOMER_INFO.CITY), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.STATE] = get(attributesList.find(el => el.name === CUSTOMER_INFO.STATE), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.ZIPCODE] = get(attributesList.find(el => el.name === CUSTOMER_INFO.ZIPCODE), ['id'], '')
      updatedValuesIds[CUSTOMER_INFO.CONTACT_STATUS] = get(attributesList.find(el => el.name === CUSTOMER_INFO.CONTACT_STATUS), ['id'], '')
       */
      const updatedValuesIds = getUpdatedValuesIds(attributesList)
      const contactStatusList = get(attributesList.find(el => el.name === CUSTOMER_INFO.CONTACT_STATUS), ['attributeValues'], [])

      this.setState({
        ...state,
        customerValuesIds: updatedValuesIds,
        customerInfo: {
          ...this.state.customerInfo,
          [CUSTOMER_INFO.NAME]: values[updatedValuesIds[CUSTOMER_INFO.NAME]],
          [CUSTOMER_INFO.MIDDLE_NAME]: values[updatedValuesIds[CUSTOMER_INFO.MIDDLE_NAME]],
          [CUSTOMER_INFO.LAST_NAME]: values[updatedValuesIds[CUSTOMER_INFO.LAST_NAME]],
          [CUSTOMER_INFO.PHONE_NUMBER]: values[updatedValuesIds[CUSTOMER_INFO.PHONE_NUMBER]],
          [CUSTOMER_INFO.SECOND_PHONE_NUMBER]: values[updatedValuesIds[CUSTOMER_INFO.SECOND_PHONE_NUMBER]],
          [CUSTOMER_INFO.EMAIL]: values[updatedValuesIds[CUSTOMER_INFO.EMAIL]],
          [CUSTOMER_INFO.ADDRESS]: values[updatedValuesIds[CUSTOMER_INFO.ADDRESS]],
          [CUSTOMER_INFO.CITY]: values[updatedValuesIds[CUSTOMER_INFO.CITY]],
          [CUSTOMER_INFO.STATE]: values[updatedValuesIds[CUSTOMER_INFO.STATE]],
          [CUSTOMER_INFO.ZIPCODE]: values[updatedValuesIds[CUSTOMER_INFO.ZIPCODE]],
          [CUSTOMER_INFO.CONTACT_STATUS]: values[updatedValuesIds[CUSTOMER_INFO.CONTACT_STATUS]].toString(),
        },
        originalValues: values,
        contactStatusList,
      })
    }
  }

  handleReview = (id) => {

    let overlayActive = id
    if(id ==='page' && !isEmpty(this.state[MESSAGES.REVIEW_PAGE])){
      overlayActive = 'noReviewsYet'
    }
    this.setState({overlayActive: overlayActive})
    //this.props.setSideBarOnBottom(false)
  }

  handleSkip = () => {
    this.setState({overlayActive: ''})
    //this.props.setSideBarOnBottom(false)
  }

  handleShowSendMailModal = () => {
    this.setState({ showSendMailModal: true })
  }

  handleCloseSendMailModal = () => {
    this.setState({
      showSendMailModal: false,
      showSecondDialogBody: false,
      customerInfo: DEFAULTS.CUSTOMER_INFO,
      coincidences: DEFAULTS.COINCIDENCES,
      areValidAllRequiredFields: true,
      validationErrors: [],
      existingCustomer: undefined,
      selectedCustomers: [],
      [CUSTOMER_TEXT_SEARCHING]: '',
      showCustomerList: false,
      [TOGGLE.EMAIL]: false,
      enableEmailToggle: false,
    })
  }

  handleToggle = (toggleType) => {
    this.setState(prevState => {
      return {
        [toggleType]: !prevState[toggleType]
      }
    })
  }

  selectCustomerAndHideVerticalList = async (e, currentCustomer) => {
    e.stopPropagation()
    e.preventDefault()

    const {responseCode, data} = await loadCustomersApi('', 0, CUSTOMERS_BY_PAGE_BY_DEFAULT)
    if (responseCode === SUCCESSFUL_CODE) {
      const customers = data?.customers ?? []

      const {selectedCustomers} = this.state
      let updatedSelectedCustomers = [...selectedCustomers]

      if (currentCustomer.id) {
        updatedSelectedCustomers = updatedSelectedCustomers.concat([currentCustomer])
      }

      const enableEmailToggle = !!updatedSelectedCustomers.find(el => !isEmpty(el.email))

      if (this.state.showCustomerList) {
        this.setState({
          showCustomerList: false,
          selectedCustomers: updatedSelectedCustomers,
          [CUSTOMER_TEXT_SEARCHING]: '',
          customers,
          [TOGGLE.EMAIL]: enableEmailToggle,
          enableEmailToggle,
        })
      }
    }
  }

  handleDeletePill = (e, index) => {
    e.stopPropagation()
    e.preventDefault()
    const {selectedCustomers} = this.state
    let updatedSelectedCustomers = [...selectedCustomers]

    updatedSelectedCustomers.splice(index, 1)
    const enableEmailToggle = !!updatedSelectedCustomers.find(el => !isEmpty(el.email))
    this.setState({
      selectedCustomers: updatedSelectedCustomers,
      [TOGGLE.EMAIL]: enableEmailToggle,
      enableEmailToggle,
    })
  }

  showOrHideVerticalList = () => {
    this.setState(prevState => {
      return (
        { showCustomerList: !prevState.showCustomerList}
      )
    },
      () => {
      this.state.showCustomerList && this.textInput.current.focus()
      }
    )
  }

  showSecondDialogBody = () => {
    this.setState({ showSecondDialogBody: true})
  }

  showFirstDialogBody = () => {
    this.setState({ showSecondDialogBody: false, customerInfo: DEFAULTS.CUSTOMER_INFO,
      coincidences: DEFAULTS.COINCIDENCES, areValidAllRequiredFields: true, validationErrors: [], existingCustomer: undefined})
  }

  getPills = () => {
    return(
      this.state.selectedCustomers.map((el, index) => {
        const displayValue = `${el.firstName} ${el.lastName}`
        return (
          <DPCustomPill
            key={el.id}
            id={el.id}
            index={index}
            handleDeleteItem={(e) => this.handleDeletePill(e, index)}
            displayValue={displayValue}
            classContainer='pill-container'
            classDisplayValue='pill-label'
            classDeleteIcon='pill-delete-icon'
          />
        )
      })
    )
  }

  handleCheck = async (value, socialNetwork) => {
    const key = socialNetwork === FACEBOOK_KEY ? "showPreviewFacebook" : "showPreviewGoogle"
    this.setState({[key]: value})
    const {responseCode, responseMessage} = await saveDealerConfiguration({ code: SOCIAL_NETWORK_PREVIEW_CONFIGURATION[socialNetwork], value: JSON.stringify(value)})
    if (responseCode !== SUCCESSFUL_CODE) {
      console.log(responseMessage)
    }
  }

  goToPreview = () => {
    const {showPreviewFacebook, showPreviewGoogle} = this.state
    const { dealerId, userId } = this.props
    browserHistory.push(
      { pathname: `/reviewPagePreview`,
        state: { textToDisplay: this.state[MESSAGES.REVIEW_PAGE], showButtonFacebook: showPreviewFacebook, showButtonGoogle: showPreviewGoogle, showBack: true},
        query: { dealerId: dealerId, userId: userId }
      },
    );
  }

  handleChange = (customerInfoKey, value) => {
    this.setState((prevState) => {
      let updateCustomerInfo = {...prevState.customerInfo}
      updateCustomerInfo[customerInfoKey] = value
      return (
        { customerInfo: updateCustomerInfo }
      )
    })
  }

  handleChangeWithValidation = (customerInfoKey, value) => {
    this.handleChange(customerInfoKey, value)
    this.debouncedHandleCoincidences(customerInfoKey, value)
  }

  handleChangeOnTextArea = (key, value) => {
    this.setState((prevState) => {
      return ({ [key]: value,
        isDisabled: {
          ...prevState.isDisabled,
          [key]: false,
        }
      })
    })
  }

  handleChangeInGeneral = async (key, value) => {
    this.setState((prevState) => {
      return ({ [key]: value,})
      },
      () => this.loadCustomersByValue(value)
    )
  }

  loadCustomersByValue = async (value) => {
    const {responseCode, responseMessage, data} = await loadCustomersApi(value, 0, CUSTOMERS_BY_PAGE_BY_DEFAULT)
    if (responseCode === SUCCESSFUL_CODE) {
      const customers = data?.customers ?? []

      this.setState((prevState) => {
        return ({ customers, })
      })
    }
    else {
      console.error(responseMessage)
    }
  }

  addNewCustomer = async () => {
    if (!this.areValidAllRequiredFields()) {
      this.setState({ areValidAllRequiredFields: false })
      if (this.state.areValidAllRequiredFields) setTimeout(
        () => this.setState({ areValidAllRequiredFields: true, validationErrors: [] }),
        10000
      );
      return
    } else {
      this.setState({ areValidAllRequiredFields: true, validationErrors: [] })
    }

    const {existingCustomer, contactStatusList} = this.state
    const existingCustomerId = existingCustomer && existingCustomer.id
    let updatedValues = {...this.state.originalValues}
    const {customerInfo, customerValuesIds} = this.state
    const customerContactStus = get(contactStatusList.find(el => el.code === 'customer.contactStatus.customer'), ['id'], '')

    updatedValues = {
      ...updatedValues,
      [customerValuesIds[CUSTOMER_INFO.NAME]]: customerInfo[CUSTOMER_INFO.NAME],
      [customerValuesIds[CUSTOMER_INFO.MIDDLE_NAME]]: customerInfo[CUSTOMER_INFO.MIDDLE_NAME],
      [customerValuesIds[CUSTOMER_INFO.LAST_NAME]]: customerInfo[CUSTOMER_INFO.LAST_NAME],
      [customerValuesIds[CUSTOMER_INFO.PHONE_NUMBER]]: customerInfo[CUSTOMER_INFO.PHONE_NUMBER],
      [customerValuesIds[CUSTOMER_INFO.SECOND_PHONE_NUMBER]]: customerInfo[CUSTOMER_INFO.SECOND_PHONE_NUMBER],
      [customerValuesIds[CUSTOMER_INFO.EMAIL]]: customerInfo[CUSTOMER_INFO.EMAIL],
      [customerValuesIds[CUSTOMER_INFO.ADDRESS]]: customerInfo[CUSTOMER_INFO.ADDRESS],
      [customerValuesIds[CUSTOMER_INFO.CITY]]: customerInfo[CUSTOMER_INFO.CITY],
      [customerValuesIds[CUSTOMER_INFO.STATE]]: customerInfo[CUSTOMER_INFO.STATE],
      [customerValuesIds[CUSTOMER_INFO.ZIPCODE]]: customerInfo[CUSTOMER_INFO.ZIPCODE],
      [customerValuesIds[CUSTOMER_INFO.CONTACT_STATUS]]: [customerContactStus],
    }

    let requestForm = {
      formCode: NEW_CUSTOMER_REVIEW_FORM,
      values: updatedValues,
      sourceType: ENTTIY_TYPE.CUSTOMER_REVIEW,
      objectId: existingCustomerId,
    }

    const {responseCode, responseMessage, data} = await processFormApi({ requestForms: [requestForm] })
    if (responseCode === SUCCESSFUL_CODE) {
      const newCustomerId = data[0] ?? 'NoCodeID'
      const response = await loadCustomerById(newCustomerId)
      const {responseCode} = response

      if (responseCode !== SUCCESSFUL_CODE) {
        this.setState({
          showSecondDialogBody: false,
          customerInfo: DEFAULTS.CUSTOMER_INFO,
          coincidences: DEFAULTS.COINCIDENCES,
          areValidAllRequiredFields: true,
          validationErrors: [],
          existingCustomer: undefined,
        })
        return
      }

      const newCustomerInfo = get(response, ['data', 'customer'], {})
      const formattedCustomerInfo = {
        address: get(newCustomerInfo, ['address', 'attributeValue'], ''),
        city: get(newCustomerInfo, ['city', 'attributeValue'], ''),
        email: get(newCustomerInfo, ['email', 'attributeValue'], ''),
        firstName: get(newCustomerInfo, ['firstName'], ''),
        id: get(newCustomerInfo, ['id'], ''),
        image: get(newCustomerInfo, ['imageUrl'], ''),
        lastName: get(newCustomerInfo, ['lastName'], ''),
        middleName: get(newCustomerInfo, ['middleName', 'attributeValue'], ''),
        phone: get(newCustomerInfo, ['cellPhone', 'attributeValue'], ''),
        secondPhone: get(newCustomerInfo, ['secondPhone', 'attributeValue'], ''),
        state: get(newCustomerInfo, ['state', 'attributeValue'], ''),
        zip: get(newCustomerInfo, ['zipCode', 'attributeValue'], ''),
      }

      const { selectedCustomers } = this.state
      let updatedSelectedCustomers = [...selectedCustomers]
      updatedSelectedCustomers = updatedSelectedCustomers.concat([formattedCustomerInfo])
      const enableEmailToggle = !!updatedSelectedCustomers.find(el => !isEmpty(el.email))

      this.setState({
        showSecondDialogBody: false,
        selectedCustomers: updatedSelectedCustomers,
        customerInfo: DEFAULTS.CUSTOMER_INFO,
        coincidences: DEFAULTS.COINCIDENCES,
        areValidAllRequiredFields: true,
        validationErrors: [],
        existingCustomer: undefined,
        [TOGGLE.EMAIL]: enableEmailToggle,
        enableEmailToggle,
      })
    } else {
      console.error(responseMessage)
    }
  }

  onClickSendButton = async () => {
    const {selectedCustomers} = this.state
    const emails = selectedCustomers.map(el => el.email)
    const phoneNumbers = selectedCustomers.map(el => `${CODE_NUMBER_PHONE_EEUU}${el.phone}`)
    const message = `${this.state[MESSAGES.REVIEW_REQUEST]} <br>{link}`
    let data = {};
    if (this.state[TOGGLE.SMS]) {
      data.sms = {
        message,
        phoneNumbers,
      }
    }
    if (this.state[TOGGLE.EMAIL] && emails.length > 0) {
      data.email = {
        message,
        emails,
      }
    }

    const {responseCode, responseMessage} = await endReview(data)
    if (responseCode === SUCCESSFUL_CODE) {
      this.setState({ showCompletedSentModal: true })
      setTimeout(
        () => this.setState({ showSendMailModal: false, showCompletedSentModal: false, selectedCustomers: [] }),
        4200
      )
    } else {
      console.log(responseMessage)
    }
  }

  handleSaveReviewRequestMessageBtn = async () => {
    const { requestMessageTemporal } =  this.state
    this.setState({isLoadingBtnSave: true})
    const {responseCode, responseMessage} = await saveDealerConfiguration({ code: MESSAGES.REVIEW_REQUEST, value: requestMessageTemporal })
    if (responseCode === SUCCESSFUL_CODE) {
      setTimeout(() => {
          this.setState((prevState) => {
            return ({
              isDisabled: {
                ...prevState.isDisabled,
                [MESSAGES.REVIEW_REQUEST]: true,
                requestMessageTemporal: true,
              },
              isLoadingBtnSave: false,
              isBtnSaved: true,
              [MESSAGES.REVIEW_REQUEST]: requestMessageTemporal
            })
          }, () => {
            setTimeout(() => {
              this.setState({isBtnSaved: false})
            }, 1000)
          })
        }
        , 1000)
      this.handleReview('page')
    } else {
    console.error(responseMessage)
    }
  }

  handleSaveReviewRequestPageBtn = async () => {
    const { pageMessageTemporal } = this.state
    this.setState({isLoadingBtnSavePrev: true})
    const {responseCode, responseMessage} = await saveDealerConfiguration({ code: MESSAGES.REVIEW_PAGE, value: pageMessageTemporal })
    if (responseCode === SUCCESSFUL_CODE) {
      setTimeout(() => {
          this.setState((prevState) => {
            return ({
              isDisabled: {
                ...prevState.isDisabled,
                [MESSAGES.REVIEW_PAGE]: true,
                pageMessageTemporal: true,
              },
              isLoadingBtnSavePrev: false,
              isBtnSavedPrev: true,
              [MESSAGES.REVIEW_PAGE]: pageMessageTemporal,
            })
          }, () => {
            setTimeout(() => {
              this.setState({isBtnSavedPrev: false})
            }, 1000)
          })
        }
        , 1000)

      this.handleReview('noReviewsYet')
    } else {
      console.error(responseMessage)
    }
  }

  handleCoincidences = async (customerInfoKey, value) => {
    const clearCoincidences = (value.length < 3) || (DEFAULTS.VALIDATION_FIELDS[customerInfoKey] && !DEFAULTS.VALIDATION_FIELDS[customerInfoKey](value))
    let customers = [];
    if (!isEmpty(value) && !clearCoincidences) {
      const {responseCode, data} = await loadCustomersApi(value, 0, CUSTOMERS_BY_PAGE_BY_DEFAULT)
      if (responseCode === SUCCESSFUL_CODE) {
        customers = data?.customers ?? []
      }
    }

    this.setState((prevState) => {
      const coincidences = {
        ...prevState.coincidences,
        [customerInfoKey]: customers,
      }

      const temporalList = [].concat(coincidences[CUSTOMER_INFO.NAME],
        coincidences[CUSTOMER_INFO.LAST_NAME],
        coincidences[CUSTOMER_INFO.PHONE_NUMBER],
        coincidences[CUSTOMER_INFO.EMAIL]
      )

      const uniqueTemporalList = uniqBy(temporalList, 'id')
      const existingCustomer = uniqueTemporalList.find(el => el.firstName === prevState.customerInfo[CUSTOMER_INFO.NAME]
        && el.lastName === prevState.customerInfo[CUSTOMER_INFO.LAST_NAME]
        && el.phone === prevState.customerInfo[CUSTOMER_INFO.PHONE_NUMBER]
        && el.email === prevState.customerInfo[CUSTOMER_INFO.EMAIL]
      )

      const existingCustomersByEmailAndPhone = coincidences[CUSTOMER_INFO.PHONE_NUMBER].filter(cp =>
        coincidences[CUSTOMER_INFO.EMAIL].some(ce => ce.id === cp.id)
      )

      return ({
        coincidences,
        existingCustomer,
        existingCustomersByEmailAndPhone,
      })
    })
  }

  handleSelectCoincidence = (selectedCustomer) => {
    const {selectedCustomers} = this.state
    let updatedSelectedCustomers = [...selectedCustomers]
    updatedSelectedCustomers = updatedSelectedCustomers.concat([selectedCustomer])

    this.setState({
      showSecondDialogBody: false,
      selectedCustomers: updatedSelectedCustomers,
      customerInfo: DEFAULTS.CUSTOMER_INFO,
      coincidences: DEFAULTS.COINCIDENCES,
      existingCustomer: undefined,
    })
  }

  areValidAllRequiredFields = () => {
    let allValidFields = true
    this.setState({ areValidAllRequiredFields: true, validationErrors: [] })
    const { customerInfo } = this.state

    for (const property in DEFAULTS.REQUIRED_FIELDS) {
      if (DEFAULTS.REQUIRED_FIELDS[property] || DEFAULTS.VALIDATION_FIELDS[property]) {

        if (DEFAULTS.REQUIRED_FIELDS[property] && isEmpty(customerInfo[property])) {
          allValidFields = false;

            this.setState(prevState=>{
              return {
                validationErrors: [...prevState.validationErrors, DEFAULTS.VALIDATION_ERRORS.REQUIRED_FIELD[property]]
              }
            })

        }

        if (DEFAULTS.VALIDATION_FIELDS[property]
          && !isEmpty(this.state.customerInfo[property])
          && !DEFAULTS.VALIDATION_FIELDS[property](this.state.customerInfo[property])){
          allValidFields = false

          this.setState(prevState=>{
            return { validationErrors: [...prevState.validationErrors, DEFAULTS.VALIDATION_ERRORS[property]] }
        })
        }
      }
    }

    return allValidFields
  }

  render() {
    const {height, showSideBar} = this.props
    const {overlayActive, selectedCustomers, showSendMailModal, showCompletedSentModal, showSecondDialogBody, showCustomerList, customers,
      customerInfo, contactStatusList, coincidences, areValidAllRequiredFields, validationErrors, existingCustomer, isLoading, requestMessageTemporal, pageMessageTemporal,
      enableEmailToggle, existingCustomersByEmailAndPhone, isLoadingBtnSave, isBtnSaved, isLoadingBtnSavePrev, isBtnSavedPrev, showPreviewGoogle, showPreviewFacebook} = this.state
    const isDisabledSentBtn = (selectedCustomers.length < 1)
      || (selectedCustomers.length < 1 || (!this.state[TOGGLE.SMS] && !this.state[TOGGLE.EMAIL]))
    const totalCoincidenceByEmail = coincidences[CUSTOMER_INFO.EMAIL] ? coincidences[CUSTOMER_INFO.EMAIL].length : 0
    const totalCoincidenceByPhone = coincidences[CUSTOMER_INFO.PHONE_NUMBER] ? coincidences[CUSTOMER_INFO.PHONE_NUMBER].length : 0;
    const totalCoincidences = totalCoincidenceByEmail + totalCoincidenceByPhone
    const existCoincidences = totalCoincidences > 0
    const totalCoincidenceByPhoneAndEmail =  existingCustomersByEmailAndPhone ? existingCustomersByEmailAndPhone.length : 0;
    const paddingLeft = showSideBar ? '200px' : '50px'
    return (
      <React.Fragment>
        <div className={'container-reviews'} style={{minHeight: height, paddingLeft: paddingLeft}} >
          <div className="container-reviews-body" style={{height: height, backgroundColor: isLoading ? '#FFF' : '#f3f4f8'}}>
            {
              isLoading ?
                <div className="loader-container">
                  <div className="loader"/>
                </div> :
                <>
                  {/**** First column ****/}
                  <div className={'column-reviews'} style={overlayActive === '' ? {overflow: 'auto'} : {}}>
                    {/**** First dialog ****/}
                    <ReviewContainer
                      id='request'
                      handleReview={this.handleReview}
                      showOverlay={isEmpty(this.state[MESSAGES.REVIEW_REQUEST]) && overlayActive ==='request'}
                      bodyComponent={
                        <ReviewItem
                          title={'Review request'}
                          messageText={'Every time you complete a sale you’ll be able to send a review request message to your clients, set the default message below.'}
                          secondMessageText={'Sale process system needed'}
                          rowsTextArea={5}
                          textAreaMaxLength={200}
                          iconSendPath={'ic_send_24px.svg'}
                          prevFunction={this.handleShowSendMailModal}
                          textAreaValue={requestMessageTemporal}
                          handleChangeOnTextArea={(e) => this.handleChangeOnTextArea('requestMessageTemporal', e.target.value)}
                          handleSaveBtn={this.handleSaveReviewRequestMessageBtn}
                          classNameSaveBtn={this.state.isDisabled.requestMessageTemporal ? 'btn-save-disabled' : 'btn-save'}
                          isLoadingBtnSave={isLoadingBtnSave}
                          isBtnSaved={isBtnSaved}
                          isDisable={this.state.isDisabled.requestMessageTemporal}
                          btnSaveText={'Save'}
                          btnLoadingText={'Saving ...'}
                          btnSavedText={'Saved'}
                        />
                      }
                      externalContent={
                        <ExternalComponent
                          idNext={'page'}
                          title={'Hey! Welcome'}
                          text={'Here you can customize the message that your clients will receive when you send a Review Request'}
                          handleNext={this.handleReview}
                          handleSkip={this.handleSkip}
                        />
                      }
                    />
                    {/**** Second dialog ****/}
                    <ReviewContainer
                      id='page'
                      handleReview={this.handleReview}
                      showOverlay={isEmpty(this.state[MESSAGES.REVIEW_PAGE]) && overlayActive ==='page'}
                      bodyComponent={
                        <ReviewItem
                          title={'Review page'}
                          messageText={'Set up the page your clients will see when they click on your reviews link.'}
                          rowsTextArea={2}
                          textAreaMaxLength={100}
                          btnSendText={'Preview'}
                          textAreaMessage={'Title page message'}
                          showSocialReviews={true}
                          prevFunction={this.goToPreview}
                          //textAreaValue={this.state[MESSAGES.REVIEW_PAGE]}
                          textAreaValue={pageMessageTemporal}
                          handleChangeOnTextArea={(e) => this.handleChangeOnTextArea('pageMessageTemporal', e.target.value)}
                          handleSaveBtn={this.handleSaveReviewRequestPageBtn}
                          classNameSaveBtn={this.state.isDisabled.pageMessageTemporal ? 'btn-save-disabled' : 'btn-save'}
                          isLoadingBtnSave={isLoadingBtnSavePrev}
                          isBtnSaved={isBtnSavedPrev}
                          isDisable={this.state.isDisabled.pageMessageTemporal}
                          btnSaveText={'Save'}
                          btnLoadingText={'Saving ...'}
                          btnSavedText={'Saved'}
                          handleCheck={this.handleCheck}
                          showPreviewFacebook={showPreviewFacebook}
                          showPreviewGoogle={showPreviewGoogle}
                        />
                      }
                      externalContent={
                        <ExternalComponent
                          idNext={'noReviewsYet'}
                          title={'What will my clients see?'}
                          text={'Once your clients click on the Review Request link, they’ll see this page to choose where they want you leave a review.'}
                          handleNext={this.handleReview}
                          handleSkip={this.handleSkip}
                        />
                      }
                    />
                  </div>
                  {/**** Second column ****/}
                  <ReviewsSection
                    handleReview={this.handleReview}
                    handleShowSendMailModal={this.handleShowSendMailModal}
                    height={height}
                    overlayActive={overlayActive}
                    reviewPage={this.state[MESSAGES.REVIEW_PAGE]}
                    reviewRequest={this.state[MESSAGES.REVIEW_REQUEST]}
                  />
                </>
            }

          </div>
          {
            showSendMailModal &&
            <Modal
              show={showSendMailModal}
              className={!showCompletedSentModal ? 'send-mail-modal-container' : 'completed-send-mail-modal-container'}
            >
              <Modal.Body>
                {
                  !showCompletedSentModal
                  && <ReactSVG
                    src={`${pathServer.PATH_IMG}icon/ic_close_circle_red.svg`}
                    className="close-modal-icon"
                    onClick={this.handleCloseSendMailModal}
                  />
                }

                <div className={showSecondDialogBody ? 'second-dialog-body-container' : 'body-container'}>
                  {
                    !showCompletedSentModal
                      ? !showSecondDialogBody
                        ? <React.Fragment> {/* First dialog body */}
                        <div style={{minHeight: '28px', fontSize: '21px', marginBottom: '9px'}}>
                          <b>Send review request</b>
                        </div>
                        <div style={{minHeight: '18px', fontSize: '13px', marginBottom: '9px'}}>
                          Send to <span style={{color: '#A4AFB7'}}>(Type customer name, email or phone number)</span>
                        </div>

                        <div className='customer-list-container'>
                          <div
                            className='horizontal-list-container'
                            onClick={this.showOrHideVerticalList}
                          >
                            {
                              selectedCustomers.length > 0
                                ? this.getPills()
                                : null
                            }

                            {
                              showCustomerList
                              && <input
                                type={'text'}
                                className='custom-input-text'
                                ref={this.textInput}
                                value={this.state[CUSTOMER_TEXT_SEARCHING]}
                                onChange={(e) => this.handleChangeInGeneral(CUSTOMER_TEXT_SEARCHING, e.target.value)}
                                size={this.state[CUSTOMER_TEXT_SEARCHING].length}
                              />
                            }

                          </div>

                          {
                            showCustomerList
                            && <div className='vertical-list-content'>
                              {
                                customers.map((el, index)=> {
                                  return (
                                    <div
                                      key={index}
                                      className='customer-item'
                                      onClick={(e) => this.selectCustomerAndHideVerticalList(e, el)}
                                    >
                                      {`${el.firstName} ${el.lastName} `}
                                      <span className='additional-text'>{`${el.phone} ${el.email ? '| ' : ''}${el.email}`}</span>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          }

                        </div>

                        <div className='requests-container'>
                          <div className='panel-sms-container'>
                            <div className='panel-sms'>
                              <TitleHeader
                                classTitle="title-general-messages"
                                classIcon="icon-check"
                                classIconSvg="icon-sms-svg"
                                icon="icon/ic_cellphone.svg"
                                title="Review request (SMS)"
                                selectOption={false}
                                rightElement={
                                  <DPToggle
                                    handleCheck={() => this.handleToggle(TOGGLE.SMS)}
                                    isChecked={this.state[TOGGLE.SMS]}
                                    id={"sms"}
                                  />
                                }
                              />
                              <div
                                style={{display: 'flex', flexDirection: 'column', padding: '10px 17px', fontSize: '13px'}}>
                                <div>{this.state[MESSAGES.REVIEW_REQUEST]}</div>
                              </div>
                            </div>
                          </div>

                          <div className='panel-email-container'>
                            <div className='panel-email'>
                              <TitleHeader
                                classTitle="title-general-messages"
                                classIcon="icon-check"
                                classIconSvg="icon-email-svg"
                                icon="icon/ic_sent_email.svg"
                                title="Review request (Email)"
                                selectOption={false}
                                rightElement={
                                  <DPToggle
                                    handleCheck={enableEmailToggle ? () => this.handleToggle(TOGGLE.EMAIL) : null}
                                    isChecked={this.state[TOGGLE.EMAIL]}
                                    id={"email"}
                                  />
                                }
                              />
                              <div
                                style={{display: 'flex', flexDirection: 'column', padding: '10px 17px', fontSize: '13px'}}>
                                <div>{this.state[MESSAGES.REVIEW_REQUEST]}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={'container-buttons'}>
                          <div
                            className={'btn-add-new-customer'}
                            onClick={this.showSecondDialogBody}
                          >
                            <ReactSVG
                              src={`${pathServer.PATH_IMG}icon/ic_add_user.svg`}
                              className={'icon-add-new-customer'}
                            />
                            Add new customer
                          </div>
                          <DPButton
                            className={isDisabledSentBtn ? 'btn-disabled-send' : "btn-send"}
                            disabled={isDisabledSentBtn}
                            onClick={this.onClickSendButton}
                          >
                            Send
                          </DPButton>
                        </div>
                      </React.Fragment>

                        : <React.Fragment> {/* Second dialog body */}
                        <div className='add-customer-title'>
                          <ReactSVG
                            src={`${pathServer.PATH_IMG}icon/left-arrow-sign.svg`}
                            className={'add-customer-back-button'}
                            beforeInjection={svg => svg.classList.add('left-icon-svg')}
                            onClick={this.showFirstDialogBody}
                          />
                          <b>New customer</b>
                        </div>

                        {
                          existCoincidences
                            ? <div className='warning-message'>There is {totalCoincidences} coincidences with the data provided.</div>
                            : null
                        }

                        {
                          !areValidAllRequiredFields && <div className='error-message'>{validationErrors.join(', ')}.</div>
                        }

                        <div className='add-customer-container-controls'>
                          {/* First row*/}
                          <div className='row-container-controls'>
                            <DPInput
                              title={'Name'}
                              id={'new-customer-name'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.NAME]}
                              onChange={(e) => this.handleChangeWithValidation(CUSTOMER_INFO.NAME, e.target.value)}
                              isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.NAME]}
                              className='control-add-new-customer'
                            />
                            <DPInput
                              title={'Middle name'}
                              id={'new-customer-middle-name'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.MIDDLE_NAME]}
                              onChange={(e) => this.handleChange(CUSTOMER_INFO.MIDDLE_NAME, e.target.value)}
                              className='control-add-new-customer'
                            />
                            <DPInput
                              title={'Last name'}
                              id={'new-customer-last-name'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.LAST_NAME]}
                              onChange={(e) => this.handleChangeWithValidation(CUSTOMER_INFO.LAST_NAME, e.target.value)}
                              className='control-add-new-customer'
                              isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.LAST_NAME]}
                            />
                          </div>

                          {/* Second row*/}
                          <div className='row-container-controls'>
                            <DPInputElement
                              title={'Phone number'}
                              type="text"
                              className="control-add-new-customer"
                              mask="(999)999-9999"
                              placeholder="(###)###-####"
                              alwaysShowMask={false}
                              maskChar=""
                              name="phoneNumber"
                              readOnly={false}
                              onChange={(e) => this.handleChangeWithValidation(CUSTOMER_INFO.PHONE_NUMBER, e.target.value)}
                              isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.PHONE_NUMBER]}
                            />
                            <DPInput
                              title={'Second phone number'}
                              id={'new-customer-second-phone'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.SECOND_PHONE_NUMBER]}
                              onChange={(e) => this.handleChange(CUSTOMER_INFO.SECOND_PHONE_NUMBER, e.target.value)}
                              className='control-add-new-customer'
                            />
                            <DPInput
                              title={'Email'}
                              id={'new-customer-email'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.EMAIL]}
                              onChange={(e) => this.handleChangeWithValidation(CUSTOMER_INFO.EMAIL, e.target.value)}
                              isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.EMAIL]}
                              className='control-add-new-customer'
                            />
                          </div>

                          {/* Third row*/}
                          <div className='row-container-controls'>
                            <DPInput
                              title={'Address'}
                              id={'new-customer-address'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.ADDRESS]}
                              onChange={(e) => this.handleChange(CUSTOMER_INFO.ADDRESS, e.target.value)}
                              isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.ADDRESS]}
                              className='control-add-new-customer'
                            />
                            <DPInput
                              title={'City'}
                              id={'new-customer-city'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.CITY]}
                              onChange={(e) => this.handleChange(CUSTOMER_INFO.CITY, e.target.value)}
                              isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.CITY]}
                              className='control-enhanced-add-new-customer'
                            />
                            <DPInput
                              title={'State'}
                              id={'new-customer-state'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.STATE]}
                              onChange={(e) => this.handleChange(CUSTOMER_INFO.STATE, e.target.value)}
                              isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.STATE]}
                              className='control-enhanced-add-new-customer'
                            />
                            <DPInput
                              title={'Zip code'}
                              id={'new-customer-zip-code'}
                              type={'text'}
                              value={customerInfo[CUSTOMER_INFO.ZIPCODE]}
                              onChange={(e) => this.handleChange(CUSTOMER_INFO.ZIPCODE, e.target.value)}
                              isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.ZIPCODE]}
                              className='control-enhanced-add-new-customer'
                            />
                          </div>
                          {/* Fourth row*/}
                          {/*<div className='row-container-controls'>*/}
                            {/*<DPInput*/}
                            {/*  title={'Zip code'}*/}
                            {/*  id={'new-customer-zip-code'}*/}
                            {/*  type={'text'}*/}
                            {/*  value={customerInfo[CUSTOMER_INFO.ZIPCODE]}*/}
                            {/*  onChange={(e) => this.handleChange(CUSTOMER_INFO.ZIPCODE, e.target.value)}*/}
                            {/*  isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.ZIPCODE]}*/}
                            {/*  className='control-add-new-customer'*/}
                            {/*/>*/}
                            {/*<DPSelectdropdown*/}
                            {/*  className={'control-add-new-customer'}*/}
                            {/*  labelStyle={{color: '#787878', fontSize: 10, fontWeight: 'initial'}}*/}
                            {/*  label={'Contact Status'}*/}
                            {/*  id={'contactStatus'}*/}
                            {/*  options={contactStatusList}*/}
                            {/*  value={customerInfo[CUSTOMER_INFO.CONTACT_STATUS]}*/}
                            {/*  onClick={(id) => this.handleChange(CUSTOMER_INFO.CONTACT_STATUS, id)}*/}
                            {/*  isRequired={DEFAULTS.REQUIRED_FIELDS[CUSTOMER_INFO.CONTACT_STATUS]}*/}
                            {/*/>*/}
                          {/*</div>*/}
                        </div>

                        {
                          existCoincidences
                            ? <div className='coincidences-container'>
                              <div className='coincidences-title'>
                                <b>Customers found</b>
                              </div>

                              <div className='coincidences-list'>

                                {
                                  totalCoincidenceByPhoneAndEmail
                                    ? existingCustomersByEmailAndPhone.map((el) => {
                                      return (
                                        <div
                                          key={el.id}
                                          className='coincidence-row'
                                        >
                                          <div className='full-name'>{`${el.firstName} ${el.middleName} ${el.lastName}`}</div>
                                          <div className='email'><b>{el.email}</b></div>
                                          <div className='phone-number'><b>{el.phone}</b></div>
                                          <div
                                            className='option'
                                            onClick={() => this.handleSelectCoincidence(el)}
                                          >
                                            Select this customer
                                          </div>
                                        </div>
                                      )})
                                    : null
                                }

                                {
                                  totalCoincidenceByEmail
                                    ? coincidences[CUSTOMER_INFO.EMAIL].map((el) => {
                                      const existCustomerOnOtherList = existingCustomersByEmailAndPhone.find(o => o.id === el.id)
                                      if (existCustomerOnOtherList) return null

                                      return (
                                        <div
                                          key={el.id}
                                          className='coincidence-row'
                                        >
                                          <div className='full-name'>{`${el.firstName} ${el.middleName} ${el.lastName}`}</div>
                                          <div className='email'><b>{el.email}</b></div>
                                          <div className='phone-number'>{el.phone}</div>
                                          <div
                                            className='option'
                                            onClick={() => this.handleSelectCoincidence(el)}
                                          >
                                            Select this customer
                                          </div>
                                        </div>
                                      )})
                                    : null
                                }

                                {
                                  totalCoincidenceByPhone
                                    ? coincidences[CUSTOMER_INFO.PHONE_NUMBER].map((el)=> {
                                      const existCustomerOnOtherList = existingCustomersByEmailAndPhone.find(o => o.id === el.id)
                                      if (existCustomerOnOtherList) return null

                                      return (
                                        <div
                                          key={el.id}
                                          className='coincidence-row'
                                        >
                                          <div className='full-name'>{`${el.firstName} ${el.middleName} ${el.lastName}`}</div>
                                          <div className='email'>{el.email}</div>
                                          <div className='phone-number'><b>{el.phone}</b></div>
                                          <div
                                            className='option'
                                            onClick={() => this.handleSelectCoincidence(el)}
                                          >
                                            Select this customer
                                          </div>
                                        </div>
                                      )})
                                    : null
                                }

                              </div>
                            </div>
                            : null
                        }

                        <div className={'container-buttons'}>
                          <div
                            className='btn-cancel-dialog'
                            onClick={this.showFirstDialogBody}
                          >
                            Cancel
                          </div>
                          <DPButton
                            className="btn-add-customer"
                            onClick={this.addNewCustomer}
                          >
                            {
                              existingCustomer
                                ? 'Update anyway'
                                : existCoincidences
                                  ? 'Add anyway'
                                  : 'Add customer'
                            }
                          </DPButton>
                        </div>
                      </React.Fragment>
                      : <div className='gif-container'>
                        <img src={`${pathServer.PATH_IMG}email-icon-animation.gif`}/>
                      </div>
                  }
                </div>

              </Modal.Body>
            </Modal>
          }
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    userRoles:state.userRoles,
    dealerId: state.dealerId,
    userId: state.userId,
    keyword:state.keyword,
  }
};

export default connect(mapStateToProps)(ReviewsTabSetting)
