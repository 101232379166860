import React from 'react'
import PropTypes from 'prop-types'

export const DPSpinner = (props) => {
  const { classContainer} = props;
  return (
    <div className={classContainer} data-testid='dp-spinner'>
      <div className='loader'/>
    </div>
  )
};

DPSpinner.defaultProps = {
  classContainer: 'container-adjust-huener'
};

DPSpinner.propTypes = {
  classContainer: PropTypes.string
};