
export interface Location {
  address: string
  address2: string
  city: string
  configurations: LocationConfiguration[]
  country: string
  dealerId: string
  info: LocationInfo
  locationId: string
  name: string
  state: string
  status: string
  zip: string
  temporalId? : string
}

export interface LocationConfiguration {
  code: string
  createDate: number
  dealerId: string
  locationId: string
  modifiedDate: number
  name: string
  value: string
}

export interface LocationInfo {
  phoneNumber: string
  email: string,
  hoursOfOperation : HourOperation[]
}

export interface HourOperation {
  days: string[]
  optionSelected: string
  format24: boolean
  from: string
  to: string
}

export const LOCATION_ZIP = 'zip'
export const LOCATION_STATE = 'state'
export const LOCATION_CITY = 'city'
export const LOCATION_ADDRESS = 'address'
export const LOCATION_NAME = 'name'