import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {TitleHeader} from './SaleEdit.jsx'
import Loader from '../../../common/UI/Loader.jsx'
import isEmpty from 'lodash/isEmpty'
import {pathServer} from '../../../common/Constants'

export default class SaleBalance extends Component{
  constructor() {
    super();

    this.state = {
      isLoading: false,
      balance: {}
    }
  }

  componentWillMount() {
    const {balance, saleCar} = this.props;
    this.setState({balance, saleCar});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {balance, saleCar} = nextProps;
    this.setState({balance, saleCar});
  }

  render(){
    const {isLoading, saleCar, balance} = this.state;
    const {readOnly,showBalanceDetail} = this.props;
    const iconLeft = !readOnly ? 'ic_edit-pencil.svg' : 'eye-view.svg';
    return (
      <div className="panel-sale-balance">
        {isLoading ? <Loader/> : null}
        <TitleHeader classTitle="title-balance" classIcon="icon-trade-ins" classIconSvg="icon-trade-ins-svg"
                     icon="icon/ic_shopping_store_tags.svg" selectOption={false} title="Balance"
                     rightElement={
                       <div className="right-element">
                         <div className="container-icon">
                           <ReactSVG src={`${pathServer.PATH_IMG}icon/${iconLeft}`} beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                                     className={!readOnly ? "icon-add-trade_in" : "icon-view-trade_in"} onClick={() => showBalanceDetail(readOnly)}
                                     data-test='balance-btn'/>
                         </div>
                       </div>
                     }
        />
        {
          !isEmpty(saleCar) ?
            <div className="balance-body">
              <div className="balance-body-accessories">
                  <div key={`row-price`} className="tool-body-option-color">
                      <span className="accessory-title">Price</span>
                      <span className="total-accessory">{`+$${balance.car}`}</span>
                  </div>
                  <div key={`row-accessory`} className="tool-body-option">
                      <span className="accessory-title">Accessories + services</span>
                      <span className="total-accessory">{`+$${balance.accessoryAndServices}`}</span>
                  </div>
                  <div key={`row-rebates`} className="tool-body-option-color">
                      <span className="accessory-title">Rebates</span>
                      <span className="total-accessory">{`-$${balance.rebates}`}</span>
                  </div>
                  <div key={`row-trade-in`} className="tool-body-option">
                      <span className="accessory-title">Trade-in and credits</span>
                      <span className="total-accessory">{`-$${balance.tradeIn}`}</span>
                  </div>
                  <div key={`row-state-tax`} className="tool-body-option-color">
                      <span className="accessory-title">State Tax</span>
                      <span className="total-accessory">{`+$${balance.stateTax}`}</span>
                  </div>
                  <div key={`row-registration`} className="tool-body-option">
                      <span className="accessory-title">Registration Fees</span>
                      <span className="total-accessory">{`+$${balance.registrationFees}`}</span>
                  </div>
              </div>
              <div className="balance-total">
                <span className="total">{`$${balance.subTotal}`}</span>
                <span className="label-total">Balance Due:</span>
              </div>
            </div>
            :
            <div className="not-balance">
              <div className="container-balance">
                <div className="content-image-balance">
                  <img className="balance-image" src={`${pathServer.PATH_IMG}icon/ic_balance.svg`}/>
                </div>
                <div className="content-label">
                  <span className="not-balance-text">Select / Add a car first</span>
                </div>
              </div>
            </div>
        }
      </div>
    )
  }
}