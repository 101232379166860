import React, {useEffect} from 'react'
import {SIDEBAR_WIDTH_EXPANDED, SIDEBAR_WIDTH_UNEXPANDED} from '../../Constants'
import {connect} from 'react-redux'

const Container = ({height, showSideBar, keyword, children}) => {

  useEffect(() => {
    const container = window.document.getElementById("container")
    if (!container) {
      return
    }

    if (showSideBar) {
      container.className += ' container-collapse'
    } else {
      container.className = 'container-component'
    }
  }, [showSideBar])

  const left = showSideBar ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH_UNEXPANDED

  return (
    <div id="container" className='container-component' style={{height}}>
      {
        children ?
          <div>
            {React.cloneElement(children, {height: height - 130, left, keyword})}
          </div>
          :
          null
      }
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    showSideBar: state.showSideBar,
    keyword: state.keyword
  }
}

export default connect(mapStateToProps)(Container)