import React from 'react'
import {clone} from 'lodash'
import {DropTarget} from 'react-dnd'
import PropTypes from 'prop-types'
import createClass from 'create-react-class'
import {moveElement} from '../util/Utils'

const formControlTarget = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem());
  },

  hover(props,monitor,component){
    const hoverIndex = props.index;
    props.onDrop(monitor.getItem(),hoverIndex);
    monitor.getItem().index = hoverIndex;
  }
};


const controlTargetType = (props) => { return moveElement(props.statusList,props.status)};

let ElementTarget = createClass({

  propTypes: {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool.isRequired,
    onDrop: PropTypes.func.isRequired
  },


  render(){
    const { isOver, canDrop, connectDropTarget,styleLine,labelDrop,showLabelDrop} = this.props;
    let newStyle = clone(styleLine);
    if (canDrop) {
      //newStyle.border = "1px solid #777";
      //newStyle.margin = "0 10px 10px 0"
      //--newStyle.minHeight = height ? height+"px" :'1px';
    }else {
      //
    }

    return connectDropTarget(
      <div style={newStyle}>
        {
          (isOver && showLabelDrop) ?
            <div className="label-drop">
              <label>{labelDrop}</label>
            </div> : null
        }
        {this.props.children}
      </div>
    );
  }

});


ElementTarget = (DropTarget)(controlTargetType, formControlTarget,  (connect, monitor) =>{
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
})(ElementTarget) || ElementTarget;

export default ElementTarget