import React from 'react'
import {pathServer} from '../../../../../../common/Constants'
import {ReactSVG} from 'react-svg'
import {browserHistory} from 'react-router'

const actionLink = ({quickAction}) => {

  const onClickLink = () => {
    browserHistory.push({pathname: quickAction.link})
  }

  return(
    <div className="action-link" onClick={onClickLink}>
      <ReactSVG src={pathServer.PATH_IMG + quickAction.icon }
                beforeInjection={ svg => svg.classList.add('ic-action')}
                className="action-icon"/>
      <label>{quickAction.name}</label>
      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_add.svg`}
                className="action-icon-add"/>
    </div>
  )
}

export default actionLink