import DPTabs from '../../common/themes/DPTabs'
import React, {useState, useEffect} from 'react'
import Reviews from './Reviews'
import {FACEBOOK_KEY, GOOGLE_KEY, LOGO} from '../../Constants'
import ReviewsFilters from './ReviewsFilters'
import {unstable_batchedUpdates} from 'react-dom'
import {isEmpty} from 'lodash'
import Moment from 'moment'
import {loadCredentialByType} from '../../Api'
import {useDispatch, useSelector} from 'react-redux'
import {setTestimonialsInStore} from '../../actions'
import {getRatingInNumber} from './Review'
import {SUCCESSFUL_CODE} from '../../../common/Constants'

export const filterReviewsList = (filter, generalReviewList) => {
  let reviewList = []
  if( filter === 'allReviews'){
    reviewList = generalReviewList?.filter( review =>  review.archive === false || !review.archive )
  }else if(filter === 'needResponse'){
    reviewList = generalReviewList?.filter(review =>  (review.archive === false || !review.archive) && isEmpty(review.comments))
  }else if(filter === 'archive'){
    reviewList = generalReviewList?.filter( review =>  review.archive === true)
  }
  return reviewList
}

const maxQuantityFacebookReviews = 1000

const getPageId = async () => {
  const {responseCode, data}= await loadCredentialByType(LOGO.FACEBOOK)
  if(responseCode === SUCCESSFUL_CODE){
    const {credential} = data
    return credential?.pageId !== "" ? credential?.pageId : null
  }else{
    return null
  }
}

const ReviewPage = ({height}) => {

  const testimonials = useSelector( (state) => state.testimonials)
  const search = useSelector( (state) => state.keyword)
  const dispatch = useDispatch()

  const [checkGoogle, setCheckGoogle] = useState(true);
  const [checkFacebook, setCheckFacebook] = useState(true);
  const [starRangeFrom, setStarRangeFrom] = useState('1');
  const [starRangeTo, setStarRangeTo] = useState('5');
  const [facebookOptionsSelected, setFacebookOptionsSelected] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [reviews, setReviews] = useState(testimonials);
  const [reviewsFiltered, setReviewsFiltered] = useState(testimonials);
  const [from, setFrom] = useState( Moment('1990-01-01'))
  const [to, setTo] = useState( Moment())
  const [isLoading, setIsLoading] = useState(false)
  const [pageId, setPageId] = useState(null)
  const filterReviews = (reviews, filters) => {
    const {starRangeFrom, starRangeTo, facebookOptionsSelected, tagsSelected, fromDate, toDate, checkGoogle, checkFacebook} = filters
    let reviewsUpdated = []
    /*if(tagsSelected && tagsSelected.length > 0 ){
      reviewsUpdated = reviews.filter(review => { return review.tags.some( tag => tagsSelected.includes(tag))});
    }*/

    if(!checkGoogle && !checkFacebook){
      return []
    }

    if(checkGoogle && checkFacebook){
      reviewsUpdated = [...reviews]
    }

    if(checkGoogle && !checkFacebook){
      reviewsUpdated = [...reviews].filter( review => review.socialNetwork === GOOGLE_KEY)
    }

    if(checkFacebook && !checkGoogle){
      reviewsUpdated = [...reviews].filter( review => review.socialNetwork === FACEBOOK_KEY)
    }

    if (search !== null) {
      reviewsUpdated = reviewsUpdated.filter(review => {
        return review.userName.toLowerCase().includes(search.toLowerCase())
      })
    }

    if(fromDate && toDate){
      //reviewsUpdated = reviewsUpdated.filter( review => new Date(review.createPostDate ?? review.date) >= new Date(fromDate) && new Date(review.createPostDate ?? review.date) <=  new Date(toDate))
      reviewsUpdated = reviewsUpdated.filter( review => {
        return ((review.createPostDate ?? review.date) >= new Date(fromDate).getTime()) && ((review.createPostDate ?? review.date) <=  new Date(toDate).getTime())
      })
    }

    if(checkFacebook && facebookOptionsSelected && facebookOptionsSelected.length  > 0){
      const reviewsGoogle = reviewsUpdated.filter(review => review.socialNetwork === GOOGLE_KEY)
      reviewsUpdated = reviewsUpdated.filter(review => facebookOptionsSelected.includes(review.recommendationType))
      reviewsUpdated = [...reviewsUpdated, ...reviewsGoogle]
    }

    if(checkGoogle && starRangeFrom && starRangeTo){
      const reviewsFacebook = reviewsUpdated.filter(review => review.socialNetwork === FACEBOOK_KEY)
      reviewsUpdated = reviewsUpdated.filter(review => review.socialNetwork === GOOGLE_KEY && (getRatingInNumber(review.recommendationType) >= parseInt(starRangeFrom) && getRatingInNumber(review.recommendationType) <= parseInt(starRangeTo)))
      reviewsUpdated = [...reviewsUpdated, ...reviewsFacebook]
    }

    return reviewsUpdated
  }
  /*** UPDATE AND LOAD TESTIMONIALS ***/
  const getTestimonials = async () => {
    dispatch(setTestimonialsInStore(0, maxQuantityFacebookReviews, true , setIsLoading, search))
  }

  const updatePageId = async () => {
    const pageId =  await getPageId()
    setPageId(pageId)
  }

  useEffect(() => {
    setReviews(testimonials)
  }, [testimonials])

  useEffect(() => {
    const filters = {starRangeFrom, starRangeTo, facebookOptionsSelected: facebookOptionsSelected, tagsSelected: tagsSelected, fromDate: from, toDate: to, checkGoogle,checkFacebook}
    const reviewsUpdated = filterReviews(reviews, filters)
    setReviewsFiltered([...reviewsUpdated])
  },[search])

  useEffect( () => {
    updatePageId()
    return () => console.log('unmounting page...')
  }, []);

  /**** FILTER FUNCTIONS ****/

  const setOptionValue = (optionSelectedId, idOptionValue) => {
     const fromStar = idOptionValue === 'starRangeFrom' ? optionSelectedId :  starRangeFrom
     const toStar = idOptionValue === 'starRangeTo' ? optionSelectedId :  starRangeTo
     const setFunction = idOptionValue === 'starRangeFrom' ? setStarRangeFrom : setStarRangeTo
     let reviewsUpdated = [...reviews]
     const filters = {starRangeFrom: fromStar, starRangeTo: toStar, facebookOptionsSelected: facebookOptionsSelected, tagsSelected: tagsSelected, fromDate: from, toDate: to, checkGoogle,checkFacebook}
     reviewsUpdated = filterReviews(reviewsUpdated, filters)
     unstable_batchedUpdates(() => {
       setFunction(optionSelectedId)
       setReviewsFiltered([...reviewsUpdated])
     })
  }

  const handleOptionOnArray = (optionId) => {
    const indexOption = facebookOptionsSelected.findIndex(option => option === optionId)
    if(indexOption !== -1){
      facebookOptionsSelected.splice(indexOption, 1);
    }else{
      facebookOptionsSelected.push(optionId)
    }

    let reviewsUpdated = [...reviews]
    const filters = {starRangeFrom: starRangeFrom, starRangeTo: starRangeTo, facebookOptionsSelected: facebookOptionsSelected, tagsSelected: tagsSelected, fromDate: from, toDate: to, checkGoogle, checkFacebook}
    reviewsUpdated = filterReviews(reviewsUpdated, filters)

    unstable_batchedUpdates(() => {
      setFacebookOptionsSelected([...facebookOptionsSelected])
      setReviewsFiltered([...reviewsUpdated])
    })
  }

  const setDate = (value, id) => {
    const setFunction = id === 'from' ? setFrom : setTo
    const fromDate = id === 'from' ? value: from
    const toDate = id === 'to' ? value: to
    let reviewsUpdated = [...reviews]
    const filters = {starRangeFrom: starRangeFrom, starRangeTo: starRangeTo, facebookOptionsSelected: facebookOptionsSelected, tagsSelected: tagsSelected, fromDate: fromDate, toDate: toDate, checkFacebook, checkGoogle}
    reviewsUpdated = filterReviews(reviewsUpdated, filters)
    unstable_batchedUpdates(() => {
      setFunction(value)
      setReviewsFiltered([...reviewsUpdated])
    })
  }

  /**** REVIEWS FUNCTIONS ****/
  const archiveReview = (reviewId) => {
    const indexOption = reviews.findIndex(option => option.id === reviewId)
    if (indexOption !== -1){
      reviews[indexOption].archive = !reviews[indexOption].archive
      setReviews([...reviews])
    }
  }

  const updateCommentReply = (reviewId, text) => {
    let reviewFound = reviews.find( element => element.id === reviewId)
    if(reviewFound)
      reviewFound.commentReply = text

    setReviews([...reviews])
  }

  const handleCheckTag = (optionId, reviewId) => {
    const indexFound = reviews.findIndex(review => review.id === reviewId)
    if(indexFound !== -1){
      const indexTag = reviews[indexFound].tags.findIndex(tag => tag === optionId)
      if(indexTag !== -1){
        reviews[indexFound].tags.splice(indexTag, 1)
      }else{
        reviews[indexFound].tags.push(optionId)
      }
      setReviews([...reviews])
    }
  }

  /*
  const setAllTags = () => {
    let tagListUpdated = []
    if(tagsSelected.length < tagOptions.length){
      tagListUpdated = tagOptions.map(tag => tag.id)
    }
    let reviewsUpdated = [...reviews]
    const filters = {starRangeFrom: starRangeFrom, starRangeTo: starRangeTo, facebookOptionsSelected: facebookOptionsSelected, tagsSelected: tagListUpdated, fromDate: from, toDate: to}
    reviewsUpdated = filterReviews(reviewsUpdated, filters)
    unstable_batchedUpdates(() => {
      setTagsSelected(tagListUpdated)
      setReviewsFiltered([...reviewsUpdated])
    })
  }
   */

  const resetFilters = () => {
    unstable_batchedUpdates(() => {
      setStarRangeFrom('1')
      setStarRangeTo('5')
      if (isEmpty(search)) {
        setReviewsFiltered([...reviews])
      } else {
        setReviewsFiltered(reviews.filter(review => {
          return review.userName.toLowerCase().includes(search.toLowerCase())
        }))
      }
      setTagsSelected([])
      setFacebookOptionsSelected([])
      setFrom(Moment('1990-01-01'))
      setTo(Moment())
      setCheckFacebook(true)
      setCheckGoogle(true)
    })
  }

  const handleCheckFacebook = (value) => {
    setCheckFacebook(value)
    const filters = {starRangeFrom: starRangeFrom, starRangeTo: starRangeTo, facebookOptionsSelected: facebookOptionsSelected, fromDate: from, toDate: to, checkGoogle: checkGoogle, checkFacebook: value}
    setReviewsFiltered(filterReviews([...reviews], filters))
  }

  const handleCheckGoogle = (value) => {
    setCheckGoogle(value)
    const filters = {starRangeFrom: starRangeFrom, starRangeTo: starRangeTo, facebookOptionsSelected: facebookOptionsSelected, fromDate: from, toDate: to, checkGoogle: value, checkFacebook: checkFacebook}
    setReviewsFiltered(filterReviews([...reviews], filters))
  }

  const tabs = [
    {key: 'allReviews', title : `All reviews (${filterReviewsList('allReviews', reviewsFiltered).length})`, classNameHeader: "tab-header", className: "", component: <Reviews key='allReviews' id='allReviews' height={height} reviews={reviewsFiltered} archiveReview={archiveReview} updateCommentReply={updateCommentReply} handleCheckTag={handleCheckTag} isLoading={isLoading} pageId={pageId} getTestimonials={getTestimonials} setIsLoading={setIsLoading}/> },
    {key: 'needResponse', title : `Need response (${filterReviewsList('needResponse', reviewsFiltered).length})`, classNameHeader: "tab-header", className: "", component: <Reviews key='needResponse' id='needResponse' height={height} reviews={reviewsFiltered} archiveReview={archiveReview} updateCommentReply={updateCommentReply} handleCheckTag={handleCheckTag} isLoading={isLoading} pageId={pageId} getTestimonials={getTestimonials} setIsLoading={setIsLoading}/> },
    {key: 'archive', title : `Archive (${filterReviewsList('archive', reviewsFiltered).length})`, classNameHeader: "tab-header", className: "", component: <Reviews key='archive' height={height} id='archive' reviews={reviewsFiltered} archiveReview={archiveReview} updateCommentReply={updateCommentReply} handleCheckTag={handleCheckTag} isLoading={isLoading} pageId={pageId} getTestimonials={getTestimonials} setIsLoading={setIsLoading}/> }
  ]

  return(
    <div className="container-general-review-page">
      <div className="container-review-page" style={{zIndex: '99', height: '100%', backgroundColor: '#FFF'}}>
        <DPTabs
          tabs={tabs}
          classNameTabActive={'reviews-tab-active'}
        />
      </div>
      <div className="container-box-filters">
        <div className='top-div'/>
        <ReviewsFilters checkFacebook={checkFacebook}
                        checkGoogle={checkGoogle}
                        facebookOptionsSelected={facebookOptionsSelected}
                        setCheckFacebook={handleCheckFacebook}
                        setCheckGoogle={handleCheckGoogle}
                        handleOptionOnArray={handleOptionOnArray}
                        setOptionValue={setOptionValue}
                        starRangeFrom={starRangeFrom}
                        starRangeTo={starRangeTo}
                        tagsSelected={tagsSelected}
                        from={from}
                        to={to}
                        setDate={setDate}
                        resetFilters={resetFilters}
        />
      </div>
    </div>
  )
}

export default ReviewPage