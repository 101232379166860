import React, {Component} from 'react'
import {
  MESSAGE_WITHOUT_FINANCE,
  MESSAGE_WITHOUT_SALES_PURCHASED,
  FORM_CUSTOMER,
  financeStatus
} from '../../Constants'
import {ReactSVG} from 'react-svg'
import {
  loadSaleCarByCustomerId,
  loadDealerSales,
  startSale,
  loadFormByCode,
  loadFinanceByCustomerId,
  processFormApi,
  saveCustomersImage,
  updateFinanceStatus
} from '../../Api'
import Moment from 'moment/moment'
import {browserHistory} from 'react-router'
import isEmpty from 'lodash/isEmpty'
import CustomerHeader from './CustomerHeader.jsx'
import Dropzone from 'react-dropzone'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import {ModalSuccessfullyUpdated} from '../util/ComponentUtils'
import {getDisplayValue} from '../sale/SaleFinances'
import {BodyOptions, ModalNewSale, getColorByStatus} from '../sale/Sales'
import {connect} from 'react-redux'
import {setPageNumberSales, setSaleFilters, setMenuId, loadAllActiveUsers} from '../../actions'
import {parseObjectForm, parseValues, setErrors, validate} from '../../../common/Util'
import {addOption, FormRender} from './CustomerNew.jsx'
import {isArray} from 'lodash'
import {DPSpinner} from '../../common/DPSpinner'
import ModalConfirmDelete from '../util/ModalConfirmDelete'
import {getAnchorEl, getFormatNumber} from '../util/Utils'
import {
  pathServer,
  CAR_IMAGE_HEIGHT_PERCENT,
  EXT_PNG,
  EXT_OTHER_IMG,
  EXT_IMG,
  WIDTH_VIEW_TABLET, WIDTH_VIEW_MOBILE, SUCCESSFUL_CODE
} from '../../../common/Constants'
import get from 'lodash/get'

const DEFAULT_IMAGE_URL = 'https://dummyimage.com/240X180/e4e6ed/7b7b80.jpg&text=No+Image+Found';
const NO_CAR_SELECTED = 'No car selected';

export const HeaderSectionCustomer = ({id, isOpen, accordionToggle, title, showMessage, message}) => {
  return (
    <div className="container-title">
      <div className="toggle-circle" onClick={() => accordionToggle(id)}>
        {
          !isOpen ?
            <div>
              <div className="icon-toggle-horizontal-line"/>
              <div className="icon-toggle-vertical-line"/>
            </div>
            :
            <div className="icon-toggle-horizontal-line"/>
        }
      </div>
      <h5 className="label-title-customer">{title}</h5>
      {
        showMessage ?
          <span className="label-message-to-save">{message}</span> : null
      }
    </div>
  )
};

function setColorStatus(status) {
  let color;
  if (status) {
    switch (status) {
      case "Processed" :
        color = "#4AD991";
        break;
      case "In progress" :
        color = "#2194F1";
        break;
      case "Approved":
        color = "#4AD991";
        break;
      case "New":
        color = "#005294";
        break;
      case "Review Later":
        color = "#F3A200";
        break;
      case "Bad Lead":
        color = "#FF6565";
        break;
      default:
        color = "#b8bdc2"
    }
  }
  return color
}

function disableDivsOnTabletMobile (widthPage) {
  let  contentFinances = window.document.getElementById("content-finances");
  let  contentSales = window.document.getElementById("content-cars");
  if(contentFinances || contentSales){
    if(contentFinances){
      if(widthPage > WIDTH_VIEW_TABLET){
        contentFinances.style.pointerEvents = "auto";
      }else{
        contentFinances.style.pointerEvents = "none";
      }
    }if(contentSales){
      if(widthPage > WIDTH_VIEW_TABLET){
        contentSales.style.pointerEvents = "auto";
      }else{
        contentSales.style.pointerEvents = "none";
      }
    }
  }
}

const VIEW_CUSTOMER_CAR_FOUR = 1024;
const VIEW_CUSTOMER_CAR_THREE = 768;
const VIEW_CUSTOMER_CAR_TWO = 500;
const WIDTH_DEFAULT = 177;

const sectionForms = {
  CUSTOMER_INFO: 'Section-0'
}

class CustomerInfo extends Component {
  constructor(props) {
    super(props);
    this.customerRef         = React.createRef();

    this.state = {
      isOpen: false,
      section: {
        customerInformation: true,
        financeApplications: false,
        customerCars: false
      },
      isLoading: false,
      file:[],
      errors:{},
      hasChange : false,
      showModalSuccessfullyUpdated : false,
      fullName: "",
      cars:[],
      heightImage: null,
      widthPage: window.innerWidth,
      showModal:false,
      goBack:false,
      // users: null,  // List of users to display on New Sale modal
      showModalNewSale: false,
      showPopover: false,
      userSelectedNew: {},
      selectedFilter: null,
      anchorEl: {},
      userSelected: null,
      saleFilters: {},
      sales: null,
      salesQuantity : 0,
      showOptions: false,
      currentPage: 1,
      selectedFinanceId: null,
      showModalDelete: false
    }
  }

  componentDidMount = async() => {
    const {params, location, users, loadAllActiveUsers, saleFilters} = this.props;
    const {id} = params;
    const {isSale} = location.state || {};
    const {section} = this.state;
    if(isSale)
      section[isSale] = true;

    this.setState({isLoading: true});
    if(!users){
      loadAllActiveUsers()
    }

    try {
      const { responseCode, data} = await loadFormByCode(FORM_CUSTOMER, id)
      let newState = {}

      if(responseCode === SUCCESSFUL_CODE){
        const {form, values} = data
        const formBase = form.formAttributes;
        const sectionsForm = formBase.sections;
        const attributes = formBase.attributes;
        addOption(attributes)
        const customerForms = parseObjectForm(sectionsForm);
        const customer = parseValues(formBase.attributes,sectionsForm);
        const imageUrl = (values["image"] !== "") ? values["image"] : pathServer.PATH_IMG + "ic_account_circle.png";
        const firstName = values["firstName"]
        const lastName = values["lastName"];
        const fullName = firstName + " " + lastName;

        newState = {formBase, sectionsForm, attributes, values, imageUrl, customerForms, customer, fullName}
      }

      const heightImage = this.calcHeightImage();
      const responseLFBCI = await loadFinanceByCustomerId(id)
      if (responseLFBCI.responseCode === SUCCESSFUL_CODE) {
        const {finances} = get(responseLFBCI, ['data'], [])
        const response = await loadSaleCarByCustomerId(id)
        const {sales} = response.data

        this.setState({...newState, isLoading: false,heightImage, finances, cars: sales, saleFilters, customerId: id})
      }
    } catch (e) {
      console.error(e)
      this.setState({isLoading: false});
    }
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate() {
    disableDivsOnTabletMobile(window.innerWidth);
  }

  accordionToggle = (id) => {
    let {section} = this.state;
    section[id] = !section[id];
    if(id === "customerCars" && section[id]){
      const heightImage = this.calcHeightImage()
      this.setState({heightImage: heightImage,section: section});
    }else{
      this.setState({section: section});
    }
  }

  save = async () => {
    const {customer, file, values, attributes, customerId} = this.state;

    const errors = {}
    for (let section in customer) {
      for (let key in customer[section]) {
        customer[section][key] = values[key];
      }
    }
    const ref = this.refs[sectionForms.CUSTOMER_INFO];
    errors[sectionForms.CUSTOMER_INFO] = {...ref.state.errors};
    setErrors(attributes, customer[sectionForms.CUSTOMER_INFO], errors[sectionForms.CUSTOMER_INFO]);
    let hasError = validate(errors);

    if (!hasError) {
      let requestForm = {formCode:FORM_CUSTOMER,
                         values: values,
                         objectId:customerId};

      const firstName = values["firstName"]
      const lastName = values["lastName"];
      const fullName = firstName + " " + lastName
      this.setState({isLoading: true});

      const responsePFA = await processFormApi({requestForms: [requestForm]})
      if (responsePFA.responseCode === SUCCESSFUL_CODE) {
        const customerId = responsePFA.data
        if(file.length){
          let data = {files:file};
          const responseSCI = await saveCustomersImage(customerId[0], data)
          if (responseSCI.responseCode === SUCCESSFUL_CODE) {
            this.setState({isLoading: false, showModal: false, fullName, showModalSuccessfullyUpdated: true, hasChange:false});
          }
          else {
            this.setState({isLoading: false, showModal: false});
            console.error(responseSCI.responseMessage);
          }
        } else{
          this.setState({isLoading: false, showModal: false, fullName, showModalSuccessfullyUpdated: true, hasChange:false});
        }
      }
      else {
        this.setState({isLoading: false, showModal: false});
        console.error(responsePFA.responseMessage)
      }
    } else {
      this.setState({errors});
    }
  }

  viewApplicant = (id) => {
    browserHistory.push({pathname: `/main/message/finance/${id}`});
  }

  add = () => {
    browserHistory.push({pathname: `/main/customer/new`});
  }

  onChangeDate = (name, event, date) => {
    let {customer} = this.state;
    customer[name].attributeValue = Moment(date).format("YYYY-MM-DD");
    this.setState({customer,hasChange:true})
  }

  backValidate = () => {
    const {customer, hasChange, values, attributes} = this.state;
    const errors = {}
    for (let section in customer) {
      for (let key in customer[section]) {
        customer[section][key] = values[key];
      }
    }

    const ref = this.refs[sectionForms.CUSTOMER_INFO]
    errors[sectionForms.CUSTOMER_INFO] = {...ref.state.errors};
    setErrors(attributes, customer[sectionForms.CUSTOMER_INFO], errors[sectionForms.CUSTOMER_INFO]);
    let hasError = validate(errors);

    if(hasChange){
      if(!hasError)
        this.setState({showModal: true, goBack:true});
      else
        this.setState({errors});
    }else{
      browserHistory.push({pathname: `/main/customer`});
    }
  }

  onDrop = (file) => {
    const imageUrl = URL.createObjectURL(file[0]);
    this.setState({file,imageUrl,hasChange:true});
  }

  handleModalChange = () => {
    const {goBack} = this.state;
    if(!goBack)
      this.setState({showModalSuccessfullyUpdated:false, showModal:false});
    else
      browserHistory.push({pathname: `/main/customer`});
  }

  editSales = (car) => {
    const {saleId} = car;

    this.props.setMenuId("sale");
    browserHistory.push({
      pathname: `/main/sale/${saleId}`,
      state: { sourcePage: 'customerPage'}
    });
  }

  handleResize = () => {
    const heightImage = this.calcHeightImage();
    this.setState({heightImage: heightImage, widthPage: window.innerWidth});
  }

  calcHeightImage(){
    let widthPage = window.innerWidth;
    let width;
    let heightImage;
    const element = window.document.getElementById("bodySettingCustomer");
    if(element){
      if(widthPage > VIEW_CUSTOMER_CAR_FOUR){
        width = (element.offsetWidth - WIDTH_DEFAULT) / 4;
        heightImage = width * CAR_IMAGE_HEIGHT_PERCENT;
      }else if(widthPage < (VIEW_CUSTOMER_CAR_FOUR + 1) && widthPage > VIEW_CUSTOMER_CAR_THREE ){
        width = (element.offsetWidth - WIDTH_DEFAULT) / 3;
        heightImage = width * CAR_IMAGE_HEIGHT_PERCENT;
      }else if(widthPage < (VIEW_CUSTOMER_CAR_THREE + 1) && widthPage > VIEW_CUSTOMER_CAR_TWO ){
        width = (element.offsetWidth - WIDTH_DEFAULT) / 2;
        heightImage = width * CAR_IMAGE_HEIGHT_PERCENT;
      }else if(widthPage < (VIEW_CUSTOMER_CAR_TWO + 1) ){
        width = (element.offsetWidth - WIDTH_DEFAULT);
        heightImage = width * CAR_IMAGE_HEIGHT_PERCENT;
      }
    }
    return heightImage
  }

  showModalNewSale = () => {
    // const { users } = this.state
    const { users, email } = this.props;
    const userSelected = users.find(user => user.email === email);

    this.setState(
      {
        showModalNewSale: true,
        showPopover: false,
        userSelectedNew: userSelected,
    })
  }

  closePopoverNewSale = () => {
    this.setState({ showModalNewSale: false })
  }

  renderDropDownFilter = (type, event) => {
    event.preventDefault();
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target, 2);

    this.setState(
      {
        showPopover: true,
        selectedFilter: type,
        anchorEl: anchorEl
      }
    )
  }

  onClickPopover = (id, type) => {
    // const { users } = this.state
    const { users, setSaleFilters } = this.props;
    let { userSelected, saleFilters, userSelectedNew} = this.state;

    if (type === "salesmanId") {
      users.forEach(user => {
        if (user.id === id){
          userSelected = { ...user };
        }
      });

      if(userSelected.id !== ""){
        saleFilters.salesmanId = userSelected.id
      }

      this.setState({isLoading: true});
      loadDealerSales(saleFilters).then((response) => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE){
          const {sales,salesQuantity} = data;
          setSaleFilters(saleFilters);
          this.setState({userSelected: userSelected, showPopover: false, selectedFilter: "", saleFilters, sales, salesQuantity,
            isLoading: false})
        } else {
          console.error(responseMessage)
          this.setState({isLoading: false});
        }
      })
    } else {
      users.forEach(user => {
        if (user.id === id){
          userSelectedNew = { ...user };
        }
      });

      this.setState({userSelectedNew: userSelectedNew, showPopover: false, selectedFilter: ""})
    }
  }

  handleRequestClose = () => {
    this.setState({showOptions: false, showPopover:false, showPopoverCosigner:false, selectedFilter: ""})
  }

  goToSale = (saleId) => {
    const {userSelectedNew, saleFilters, currentPage, customerId} = this.state;
    let {setSaleFilters, setPageNumberSales} = this.props
    let data = {};
    setSaleFilters(saleFilters);
    setPageNumberSales(currentPage);
    this.props.setMenuId("sale");
    if(saleId === "new"){
      data = {
        salesmanId: userSelectedNew.id,
        customers: [{ customerId: customerId, principal: true, order: 1 }]
      };
    }
    startSale(data).then((response) => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        browserHistory.push({
          pathname: `/main/sale/${responseMessage}`,
          state: { sourcePage: 'customerPage'}
        });
      } else {
        console.error(responseMessage)
      }
    })
  }

  onFormChange = (data) => {
    let {values, attributes} = this.state;
    for(let key in values) {
      if(isArray(values[key])) {
        if(typeof data[key] !== 'undefined' && values[key]) {
          values[key].length = 0;
          values[key].push(data[key]);
        }
      }else{
        if (typeof data[key] !== 'undefined') {
          values[key] = data[key];
        }
      }
    }

    this.setState({values:values, attributes: attributes, hasChange:true});
  }

  handleSelectFinanceId = (financeId, evt) => {
    evt.stopPropagation();
    this.setState({selectedFinanceId: financeId, showModalDelete: true})
  }

  closeModal = () => {
    this.setState({showModalDelete: false, selectedFinanceId: null})
  }

  deleteFinance = async() => {
    const {selectedFinanceId, customerId} = this.state;
    const responseUFS = await updateFinanceStatus(selectedFinanceId, financeStatus.DELETED)
    if (responseUFS.responseCode !== SUCCESSFUL_CODE) {
      console.error(responseUFS.responseMessage)
      this.setState({showModalDelete: false})
      return
    }

    const response = await loadFinanceByCustomerId(customerId)
    if (response.responseCode === SUCCESSFUL_CODE) {
      const {finances} = get(response, ['data'], [])
      this.setState({finances, showModalDelete: false})
    } else {
      console.error(response.responseMessage)
      this.setState({showModalDelete: false})
    }
  }

  render() {
    const {height, left, users} = this.props;
    const {section, customer, finances, isLoading, cars, hasChange, showModalSuccessfullyUpdated,
      fullName, heightImage,widthPage, showModal, showModalNewSale, showPopover, userSelectedNew, anchorEl,
      attributes, customerForms, values, imageUrl, showModalDelete} = this.state;
    if (!customerForms) return null;

    const financesArray = (customer && finances) ? finances : [];

    return (
      <div className="customer-content">
        {isLoading ?
          <DPSpinner classContainer={"loader-container"}/>: null}
        <CustomerHeader title="Customer" left={left} classNameButton="button-add" value="+" action={this.add}
                        isEdit={true} actionBack={this.backValidate} nameCustomer={fullName  ? fullName : ""}
        />
        <div id="bodySettingCustomer" className="body-setting-customer">
          <div className="setting-container" style={{display: "block", minHeight: height}}>
            <div className="header-customer-info">
              <div className="header-left">
                <div className="container-customer-image">
                  <Dropzone id="dropZone" onDrop={this.onDrop} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
                    {({getRootProps, getInputProps}) => (
                      <div {...getRootProps()} className="dropzone-container">
                        <input {...getInputProps()} />
                        <img src={imageUrl} className="customer-image"/>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"} className="img-customer"/>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <label className="customer-name">{`${fullName}`}</label>
              </div>
              <div className="container-edit-button-save-header">
                <button onClick={this.save} className="btn-save-header" disabled={!hasChange}
                        style={hasChange ? {backgroundColor: "#4ad991"} : {backgroundColor: "#A4AFB7"}}>Save
                </button>
              </div>
            </div>
            <div className="customer-info">
              <HeaderSectionCustomer id="customerInformation" isOpen={section["customerInformation"]}
                                     title="Customer Information" accordionToggle={this.accordionToggle}/>
              {
                section["customerInformation"] ?
                  <div className="body-customer-info">
                    <FormRender ref={sectionForms.CUSTOMER_INFO}
                                section={customerForms[sectionForms.CUSTOMER_INFO]}
                                attributes={attributes}
                                data={values}
                                onChange={this.onFormChange}/>
                    <div className="container-edit-button-save">
                      <button onClick={this.save} className="btn-save" disabled={!hasChange}
                              style={hasChange ? {backgroundColor: "#4ad991"} : {backgroundColor: "#A4AFB7"}}>Save
                      </button>
                    </div>
                  </div> : null
              }
            </div>
            {
              financesArray.length === 0 && widthPage < WIDTH_VIEW_MOBILE ? null :
              <div className="customer-finance-applications">
                <HeaderSectionCustomer id="financeApplications" isOpen={section["financeApplications"]}
                                       title="Finance Applications" accordionToggle={this.accordionToggle}/>
                {
                  section["financeApplications"] ?
                    <div  id="content-finances" className="table-form-setting">
                      {
                        financesArray.length !== 0 ?
                          <table className="table">
                            <thead className="background-white">
                            <tr className="title-forms-list">
                              <th className="title-finance">Finance Application</th>
                              <th className="title-date">Date applied</th>
                              <th className="title-status">Status</th>
                              <th id="titleAssigned" className="title-assigned">Assigned to</th>
                              <th className="th-actions"/>
                            </tr>
                            </thead>
                            <tbody className="tbody-user">
                            {
                              isLoading ?
                                <div className="loader-container">
                                  <div className="loader"/>
                                </div> :
                                (financesArray.length !== 0 ?
                                  (
                                    financesArray.map((finance, index) => {
                                      const status = getDisplayValue(finance.status);
                                      const assignedUser = finance.userFirstName ? (`${finance.userFirstName} ${finance.userLastName}`) : '';
                                      const dateApplied = finance.createDate;
                                      const financeApplication = `${finance.firstName} ${finance.lastName}`;
                                      let color = setColorStatus(status);
                                      const {financeId} = finance;
                                      return (
                                        <tr key={index + 'tr'}
                                            className="tr-customer-info rowUser"
                                            onClick={() => this.viewApplicant(financeId)}>
                                          <td className="body-finance">{financeApplication}</td>
                                          <td className="body-date">{Moment(dateApplied).format('MM/DD/YYYY')}</td>
                                          <td className="body-status" style={{color: color}}>{status}</td>
                                          <td id="bodyAssignedUser" className="body-assigned-user">{assignedUser}</td>
                                          <td className="customer-actions">
                                            <ReactSVG key={index + 'trash'} id="imgTrash" src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                                      className="icon-delete"
                                                      beforeInjection={ svg => svg.classList.add('icon-delete-svg')}
                                                      onClick={(evt) => this.handleSelectFinanceId(financeId, evt)}/>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  )
                                  :
                                  null)
                            }
                            </tbody>
                          </table> :
                          <div className="container-message-info">
                            <div className="message-info">
                              <img src={pathServer.PATH_IMG + "inverted.png"} className= "icon-info"/>
                              <label>{MESSAGE_WITHOUT_FINANCE}</label>
                            </div>
                          </div>
                      }
                    </div> : null
                }
              </div>
            }
            {
              cars.length === 0 && widthPage < WIDTH_VIEW_MOBILE ? null :
                <div className="customer-cars">
                  <HeaderSectionCustomer id="customerCars" isOpen={section["customerCars"]} title="Purchased and Sales"
                                         accordionToggle={this.accordionToggle}/>
                  {
                    section["customerCars"] ?
                      cars.length > 0 ?
                        <div id="content-cars" className="container-cars">
                          {
                            cars.map((car, index) => {
                              const {soldDate,status} = car;
                              let soldDateValue = null;
                              if(soldDate !== 0){
                                soldDateValue = <label className="soled">{`Sold ${Moment(car.soldDate).format('MM/DD/YYYY')}`}</label>
                              }else{
                                soldDateValue = <label className="sale-in-process" style={{color: getColorByStatus(status)}}>{isEmpty(status) ? `Sale started` : `Sale ${status}`}</label>
                              }
                              const carTitle =
                                !car.year && !car.makeName && !car.modelName
                                  ? NO_CAR_SELECTED
                                  : `${car.year} ${car.makeName} ${car.modelName}`
                              return (
                                <div key={"customerCarImage" + index} className="container-car"
                                     style={{height: heightImage + 55}}>
                                  <div className="head-customer-car">
                                    <div className="content-left">
                                      <label className="make-model-year">{carTitle}</label>
                                      {soldDateValue}
                                    </div>
                                    <div className="content-right">
                                      <label className={car.suggestedPrice === 0 ? 'zero-price' : ''}>{`$${car.suggestedPrice === 0 ? '-' : getFormatNumber(car.suggestedPrice)}`}</label>
                                    </div>
                                  </div>
                                  <div
                                    className="container-car-image"
                                    style={{height: heightImage}}
                                    onClick={() => this.editSales(car)}
                                  >
                                    { carTitle === NO_CAR_SELECTED
                                      ? <ReactSVG
                                        src={`${pathServer.PATH_IMG}icon/ic_inventory.svg`}
                                        beforeInjection={ svg => svg.classList.add('icon-car-image-svg')}
                                        className="icon-car-image"
                                      />
                                      : <img
                                        src={!isEmpty(car.mainImage) ? car.mainImage : DEFAULT_IMAGE_URL}
                                      />
                                    }
                                  </div>
                                </div>
                              )
                            })
                          }
                          {
                            widthPage > WIDTH_VIEW_MOBILE &&
                            <div className="container-car container-new-sale" style={{height: heightImage + 55}}>
                              <button className="btn-primary" onClick={this.showModalNewSale}>
                                Start new sale</button>
                            </div>
                          }
                        </div> :
                        <div className="container-message-info">
                          <div className="message-info">
                            <img src={pathServer.PATH_IMG + "inverted.png"} className= "icon-info"/>
                            <label>{MESSAGE_WITHOUT_SALES_PURCHASED}</label>
                          </div>
                          <div className="btn-new-sale">
                            <button className="btn-primary" onClick={this.showModalNewSale} disabled={ widthPage <= WIDTH_VIEW_TABLET}>
                              Start new sale </button>
                          </div>
                        </div>
                      : null
                  }
                </div>
            }

          </div>
        </div>
        {
          showModalSuccessfullyUpdated ?
            <ModalSuccessfullyUpdated show={showModalSuccessfullyUpdated}
                                      message={"Successfully Updated"}
                                      onHide={this.handleModalChange}
            /> : null
        }
        {
          showModal ?
            <GenericModalConfirm show={showModal}
                                 message={"Are you sure you want to save these changes?"}
                                 save={this.save}
                                 onHide={this.handleModalChange}
                                 showButtonCancel={true}
                                 buttonLabelSave="Save"
                                 classButtonSave="btn-save"
            /> : null
        }
        {
          showModalNewSale ?
            <ModalNewSale show={showModalNewSale} onHide={this.closePopoverNewSale} showPopover={showPopover} user={userSelectedNew}
                          renderDropDownFilter={this.renderDropDownFilter}
                          child={<BodyOptions users={users} userSelected={userSelectedNew} onClickPopover={this.onClickPopover}
                                              type={"userSelected"}/>}
                          anchorEl={anchorEl} handleRequestClose={this.handleRequestClose} goToSale={this.goToSale}/> :
            null
        }
        {
          showModalDelete ?
            <ModalConfirmDelete show={showModalDelete}
                                showButtonDelete={true}
                                onSubmit={this.deleteFinance}
                                onHide={this.closeModal}
                                classButtonDelete="btn-delete"
                                buttonLabelDelete="Yes, Delete"
                                message={"Do you want to delete this Finance?"}
                                styleModal="modal-delete"
                                styleRight="panel-right-delete-user"/> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.email,
    saleFilters: state.saleFilters,
    users: state.activeUsers,
  }
};

export default connect(mapStateToProps,{setSaleFilters, setPageNumberSales, setMenuId, loadAllActiveUsers})(CustomerInfo)
