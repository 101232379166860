import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {FormInputTypes, carStatus, ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR} from '../../Constants'
import {ReactSVG} from 'react-svg'
import {connect} from 'react-redux'
import {searchInventory} from '../../actions'
import {pathServer} from '../../../common/Constants'

class ModalCarSearch extends Component {

  constructor() {
    super();
    this.setCarData           = this.setCarData.bind(this);
    this.findByKeyWord        = this.findByKeyWord.bind(this);
    this.state = {
      cars:[],
      keyword:null
    }
  }

  componentWillMount(){
    this.setCarData(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.setCarData(nextProps);
  }

  setCarData(props){
    const {cars,makes,models,locations} = props;
    let carsData = [];
    let makeFound;
    let modelFound;
    let locationFound;
    if(cars && makes) {
      const carsFiltered = cars.filter(car => { return car["car.status"] && car["car.status"].attributeValue !== carStatus.SOLD && car["car.status"].attributeValue !== carStatus.ARCHIVED})
      carsFiltered.forEach(car => {
        makeFound = makes.find(make => {
          return make.id === car.makeId
        });
        modelFound = models.find(model => {
          return model.id === car.modelId
        });
        locationFound = locations.find(location => {
          return location.locationId === car.locationId
        });

        carsData.push({
          id: car.id,
          vin: car.vin,
          makeName: makeFound.name,
          modelName: modelFound.name,
          year: car.year,
          description: car.description,
          mileage:car.mileage,
          mainImage: car.mainImage,
          locationName: locationFound.name,
          exteriorColor: car[ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR] ? car[ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR].attributeValue : ''
        })
      });
    }

    this.setState({cars:carsData,carsAll:carsData});
  }

  findByKeyWord(evt){
    const keyword = evt.target.value;
    const {searchInventory} = this.props

    searchInventory({keyword: keyword, active: true}).then(response =>{
      this.setState({carOptions:response.cars});
    })
  }

  render() {
    const {show,onHide,handleCarSelect} = this.props;
    const {cars} = this.state;

    return (
      <div className="container-modal-car-search">
        <Modal show={show} className="modal-car-search" backdropClassName="backdrop-car">
          <Modal.Body>
            <div className="car-header">
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_search.svg"} className="car-search" beforeInjection={ svg => svg.classList.add('ic-car-search')}/>
              <input id="carSearch" className="" onChange={this.findByKeyWord}
                     placeholder="Search by year, make, model or VIN Number..."/>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon" onClick={onHide}/>
            </div>
            <div className="car-body">
              <div className="filter-car">
                {
                  cars.map((car,index) => {
                    return(
                      <div key={index} className="car-row-search">
                        <div className="car-image">
                          <img src={car.mainImage ? car.mainImage : FormInputTypes.DEFAULT_IMAGE_URL}
                               onClick={(evt) => handleCarSelect(car)}/>
                        </div>
                        <div className="car-info">
                          <label className="label-title">{`${car.year} ${car.makeName} ${car.modelName}`}</label>
                          <label className="label-mil">{` vin: ${car.vin}`}</label>
                          <label className="label-mil">{car.exteriorColor ? car.exteriorColor : "No colour specified"}</label>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default connect(null, {searchInventory})(ModalCarSearch)