import React, {Component} from 'react'
import FormSettingList from './FormSettingList.jsx'


export default class Forms extends Component {

  constructor() {
    super();
    this.onClickChangeMultiSelect = this.onClickChangeMultiSelect.bind(this);
    this.onChangeSetting = this.onChangeSetting.bind(this);
    this.onChangeMenu = this.onChangeMenu.bind(this);
    this.searchForm = this.searchForm.bind(this);
    this.state = {
      checkSetting: false,
      showMultiSelectForm: false,
      form: null,
      menuTabSelected: "forms",
      exp:"",
      idModified: null
    }
  }

  searchForm(exp) {
    this.setState({exp: exp})
  }

  onChangeSetting(type) {
    const value = this.state[type];
    this.setState({[type]: !value})
  }

  onClickChangeMultiSelect() {
    let {showMultiSelectForm} = this.state;
    showMultiSelectForm = !showMultiSelectForm;
    this.setState({showMultiSelectForm: showMultiSelectForm})
  }

  onChangeMenu(form) {
    this.setState({form:form,menuTabSelected:"edit"})
  }

  render() {
    const {exp} = this.state;
    return (
      (this.props.children) ? this.props.children :
      <FormSettingList exp={exp} onChange={this.onChangeMenu} searchForm={this.searchForm}/>
    )
  }
}