import React, {useCallback, useState} from 'react'
import Dropzone from 'react-dropzone'
import {
  EXT_IMG,
  EXT_OTHER_IMG,
  EXT_PNG,
  optionState,
  pathServer,
  SUCCESSFUL_CODE
} from '../../../../../common/Constants'
import {ReactSVG} from 'react-svg'
import {
  dealerStatuses,
  fieldsRequiredUpdate,
  getErrorMessage,
  getMessageInvalid,
  validInputsNotRequired
} from '../index'
import {validateError} from '../../../util/Utils'
import InputElement from 'react-input-mask'
import {ModalSuccessfullyUpdated} from '../../../util/ComponentUtils'
import PropTypes from 'prop-types'
import DPSelectionDropDown from '../../../../common/DPSelectionDropDown'
import defaultTo from 'lodash/defaultTo'
import isEmpty from 'lodash/isEmpty'
import {updateDealersWithImage} from '../../../../Api'
import {checkValidEmail, checkValidPhone} from '../../../../../common/ValidationUtil'
import {unstable_batchedUpdates} from 'react-dom'
import {ERROR_DUPLICATE_CODE} from '../../../../Constants'


function getError(errors, object) {
  let value
  fieldsRequiredUpdate.forEach(field => {
    const {key, type} = field
    if (type === "required") {
      value = object[key]
      if (key === "phoneNumber") {
        errors[key] = !checkValidPhone(value)
      } else if (key === "email") {
        errors[key] = isEmpty(value)
        if (!errors[key]) {
          errors[key] = validInputsNotRequired(object[key], checkValidEmail)
        }
      } else {
        errors[key] = isEmpty(value)
      }
    }
  })
  return {...errors}
}

export const BodyDealer = ({
                             dbDealer,
                             setIsLoading
                           }) => {

  const [dealer, setDealer] = useState({...dbDealer})
  const [hasChanged, setHasChanged] = useState(false)
  const [file, setFile] = useState([])
  const [errors, setErrors] = useState({})
  const [errorDuplicate, setErrorDuplicate] = useState({})
  const [isUpdateSuccess, setUpdateSuccess] = useState(false)

  const updateValue = useCallback((propName, value) => {
    setDealer({...dealer, [propName]: value})
    setHasChanged(true)
  }, [dealer])

  const onDrop = useCallback((file) => {
    setFile(file)
    setHasChanged(true)
  }, [])

  const deleteFile = useCallback(() => {
    setDealer({...dealer, logoUrl: ''})
    setHasChanged(true)
    setFile([])
  }, [dealer])


  const updateDealer = async () => {
    const error = getError(errors, dealer)
    const hasError = validateError(error)
    let dealerToUpdate = {...dealer}
    // errorDuplicate = {}
    if (!isEmpty(dealer.zipCode)) {
      dealerToUpdate.zip = dealer.zipCode
    }
    if (!hasError) {
      const body = {
        files: file,
        dealer: dealerToUpdate
      }
      setIsLoading(true)

      const response = await updateDealersWithImage(dealer.id, body)
      if (response.responseCode === SUCCESSFUL_CODE) {
        unstable_batchedUpdates(() => {
          setIsLoading(false)
          setErrorDuplicate({})
          setUpdateSuccess(true)
        })
      }
      else {
        if (response.data.code === ERROR_DUPLICATE_CODE) {
          setErrorDuplicate({[response.data.field]: response.responseMessage})
        }
        setIsLoading(false)
      }
    } else {
      setErrors(error)
    }
  }

  return (
    <div className="panel-bottom">
      <div className="panel-left-edit">
        <label>Logo</label>
        <div>
          <div className="logo-dealer-content">
            {
              dealer.logoUrl ?
                <img src={dealer.logoUrl} className="img-dealer"/>
                :
                file.length > 0 ?
                  <img src={URL.createObjectURL(file[0])} className="img-dealer"/> :
                  <Dropzone onDrop={onDrop} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
                    {({getRootProps, getInputProps}) => (
                      <div {...getRootProps()} className="dropzone-container">
                        <input {...getInputProps()} />
                        <ReactSVG
                          src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"}
                          className="img-customer"
                        />
                      </div>
                    )}
                  </Dropzone>
            }
          </div>
          <div className="container-icon-image">
            <Dropzone onDrop={(file) => onDrop(file)} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
              {({getRootProps, getInputProps}) => (
                <div {...getRootProps()} className="dropzone-container">
                  <input {...getInputProps()} />
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_file_upload.svg"} className="icon-image-content"
                            beforeInjection={svg => svg.classList.add("icon-image")}/>
                </div>
              )}
            </Dropzone>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="icon-image-content"
                      beforeInjection={svg => svg.classList.add("icon-image")} onClick={deleteFile}/>
          </div>
        </div>
      </div>
      <div className="panel-right-edit">
        <div className="first-row">
          <div className="row-edit">
            <div className="colR25">
              <div style={{display: "flex"}}>
                <label>Dealer name</label>
                <span className="label-error">*</span>
              </div>
              <div className="">
                <input
                  type="text"
                  data-testid="input-dealer-name"
                  className={errors.name ? 'form-control finance-input has-error' : 'form-control finance-input'}
                  id="name"
                  value={defaultTo(dealer.name, '')}
                  onChange={(evt) => updateValue('name', evt.target.value)}
                />
                {errors.name ?
                  <p className="help-block">{getErrorMessage("Name")}</p> :
                  errorDuplicate.name ?
                    <p className="help-block">{errorDuplicate.name}</p> : null}
              </div>
            </div>
            <div className="colR25">
              <div style={{display: "flex"}}>
                <label>Dealer number</label>
              </div>
              <div className="">
                <input
                  type="text"
                  data-testid="input-dealer-number"
                  className={errors.name ? 'form-control finance-input has-error' : 'form-control finance-input'}
                  id="dealerNumber"
                  value={defaultTo(dealer.dealerNumber, '')}
                  onChange={(evt) => updateValue('dealerNumber', evt.target.value)}
                />
                {errors.name ?
                  <p className="help-block">{getErrorMessage("Name")}</p> :
                  errorDuplicate.name ?
                    <p className="help-block">{errorDuplicate.name}</p> : null}
              </div>
            </div>
            <DPSelectionDropDown
              label="Status"
              id="status"
              testId="dealer-status"
              options={dealerStatuses}
              value={dealer.status ? 'active' : 'inactive'}
              onClick={(value) => updateValue('status', value === 'active')}
            />
            <DPSelectionDropDown
              label="State"
              id="state"
              testId="dealer-state"
              options={optionState}
              value={dealer.state}
              onClick={(value) => updateValue('state', value)}
            />
          </div>
          <div className="row-edit">
            <div className="colR25">
              <div style={{display: "flex"}}>
                <label>Address</label>
                <span className="label-error">*</span>
              </div>
              <div className="">
                <input
                  type="text"
                  className={errors.address1 ? 'form-control finance-input has-error' : 'form-control finance-input'}
                  id="address1"
                  data-testid="input-dealer-address"
                  value={defaultTo(dealer.address1, '')}
                  onChange={(evt) => updateValue('address1', evt.target.value)}
                />
                {errors.address1 ?
                  <p className="help-block">{getErrorMessage("Address")}</p> : null}
              </div>
            </div>
            <div className="colR25">
              <label>Address 2</label>
              <input
                type="text"
                className="form-control"
                id="address2"
                data-testid="input-dealer-address2"
                value={defaultTo(dealer.address2, '')}
                onChange={(evt) => updateValue('address2', evt.target.value)}
              />
            </div>
            <div className="colR25">
              <label>City</label>
              <input
                type="text"
                data-testid="input-dealer-city"
                className="form-control"
                id="city"
                value={defaultTo(dealer.city, '')}
                onChange={(evt) => updateValue('city', evt.target.value)}
              />
            </div>
            <div className="colR25">
              <label>Zip</label>
              <input
                type="text"
                className="form-control"
                id="zipCode"
                data-testid="input-dealer-zip"
                value={defaultTo(dealer.zipCode, '')}
                maxLength={5}
                onChange={(evt) => updateValue('zipCode', evt.target.value)}
              />
            </div>
          </div>
          <div className="row-edit">
            <div className="container-subdomain">
              <div style={{display: "flex"}}>
                <label>Subdomain</label>
                <span className="label-error">*</span>
              </div>
              <div className="">
                <input
                  type="text"
                  className={errors.domainUrl ? 'form-control finance-input has-error' : 'form-control finance-input'}
                  id="domainUrl"
                  data-testid="input-dealer-subdomain"
                  value={defaultTo(dealer.domainUrl, '')}
                  onChange={(evt) => updateValue('domainUrl', evt.target.value)}
                />
                {
                  errors.domainUrl ?
                    <p className="help-block">{getErrorMessage("Subdomain")}</p> :
                    errorDuplicate.domainUrl ?
                      <p className="help-block">{errorDuplicate.domainUrl}</p> : null
                }
              </div>
            </div>
          </div>
        </div>
        <div className="second-row">
          <div className="container-title-contact">
            <label className="title-contact">Dealer contact information</label>
          </div>
          <div className="colR25">
            <div style={{display: "flex"}}>
              <label>Phone number</label>
              <span className="label-error">*</span>
            </div>
            <div className="">
              <InputElement
                type="text"
                className={errors.phoneNumber ? 'form-control finance-input has-error' : 'form-control finance-input'}
                id="phone"
                data-testid="input-dealer-phone"
                value={defaultTo(dealer.phoneNumber, '')}
                onChange={(evt) => updateValue('phoneNumber', evt.target.value)}
                mask="(999) 999-9999"
              />
              {errors.phoneNumber ?
                <p className="help-block">{getErrorMessage("Phone number")}</p> : null}
            </div>
          </div>
          <div className="colR50">
            <div style={{display: "flex"}}>
              <label>Email</label>
              <span className="label-error">*</span>
            </div>
            <div className="">
              <input
                type="text"
                className={errors.email ? 'form-control finance-input has-error' : 'form-control finance-input'}
                id="email"
                value={defaultTo(dealer.email, '')}
                data-testid="input-dealer-email"
                onChange={(evt) => updateValue('email', evt.target.value)}
              />
              {errors.email ?
                <p className="help-block">{getMessageInvalid("Email")}</p> : null}
            </div>
          </div>
          <div className="container-button">
            <button
              onClick={updateDealer} className="btn-save" disabled={!hasChanged} data-testid="button-save-general-information"
              style={hasChanged ? {backgroundColor: "#4ad991"} : {backgroundColor: "#A4AFB7"}}>
              Save
            </button>
          </div>
        </div>
      </div>
      {
        isUpdateSuccess ?
          <ModalSuccessfullyUpdated
            show={isUpdateSuccess}
            message={"Successfully Updated"}
            onHide={() => setUpdateSuccess(false)}
          /> : null
      }
    </div>
  )
}

BodyDealer.propTypes = {
  dbDealer: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired
}