import React from 'react'
import PropTypes from 'prop-types'
import {FormInputTypes, financeStatusValue} from '../../Constants'
import {ReactSVG} from 'react-svg'
import {Modal} from 'react-bootstrap'
import Moment from 'moment'
import {TitleHeader} from './SaleEdit.jsx'
import {DPWarning} from '../../common/DPWarning'
import {Popover} from '../util/ComponentUtils'
import {findIndex} from 'lodash'
import Checkbox from 'material-ui/Checkbox'
import {capitalFirstLetter} from '../util/Utils'
import {getDisplayValue, setColorStatus} from './SaleFinances'
import {DPDynamicNumberInput} from '../../common/DPDynamicNumberInput'
import {DPToggle} from '../../common/DPToggle'
import {elementCheck, elementUnCheck} from '../../../common/ComponentUtils'
import {pathServer} from '../../../common/Constants'

const Message = (props) => {
  const {customers, url} = props
  return(
    <div className="content-message">
      <label className="message">
        Thank you <label className="name">{customers}</label>, for getting your car with us.
      </label>
      <label className="message">
        Please rate our service at <label className="url">{url}</label>
      </label>
    </div>
  )
}

export const ModalEndSale = (props) => {
  const {show, onHide, isLoading, carInfo, balanceInfo, tradeInInfo, financeInfo, finalComments, financeSelected,
    handleCheckFinance, selectCar, tradeInSelected, endCompletion, onChange, showMessageMethod, showMessage,
    handleRequestClose, anchorEl, editCheckFromFinancier, changeCheckFromFinancier, showEditCheck, balanceDue, updateCheckFromFinancier,
    handleCheckSection, sentSMS, sentEmail, customers, url} = props;
  const {vin, make, model, year, image} = carInfo
  const {carPrice, cashPayment, tradeInTotal, rebates, checkFromFinancier} = balanceInfo
  const child = <label>Mark those Trade-in that you want to add to the inventory.You'll be ask to complete the car information later.</label>
  return (
      <Modal show={show} className="modal-new-sale modal-start" backdropClassName="backdrop-dealer">
        <Modal.Body>
          {
            isLoading ?
                <div className="loader-container" style={{position: 'absolute'}}>
                  <div className="loader"/>
                </div> : null
          }
          <div className="panel-container">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                      beforeInjection={ svg => svg.classList.add('icon-close')} onClick={onHide}/>
            <div className="panel-container-body">
              <div className="container-panel">
                <div className="panel-top">
                  <div className="panel-car">
                    <TitleHeader classTitle="title-general" classIcon="icon-check" classIconSvg="icon-check-svg"
                                 icon="icon/ic_shopping_store_tags.svg" title="Final Price" selectOption={false}/>
                    <div className="car-body">
                      <div className="container-icon-image">
                        <div className="selected-car-image">
                          <img
                              src={(image === '' || !image) ? FormInputTypes.DEFAULT_IMAGE_URL : image}
                              className='image-object-contain'
                              style={{borderRadius: '4px'}}
                          />
                        </div>
                        <div className="selected-car-info">
                          <p className="make-model-year-car">
                            {`${capitalFirstLetter(make)} ${capitalFirstLetter(model)} ${year}`}
                          </p>
                          <p className="vin">
                            {`VIN: ${vin}`}
                          </p>
                        </div>
                      </div>
                      <div className="container-balance">
                        <div className="balance-body-accessories">
                          <div className="tool-body-option-color">
                            <span className="accessory-title">Total Payment Due (Total)</span>
                            <span className="total-accessory">{`$${carPrice}`}</span>
                          </div>
                          <div className="tool-body-option">
                            <span className="accessory-title">Cash payment</span>
                            <span className="total-accessory">{`$${cashPayment}`}</span>
                          </div>
                          <div className="tool-body-option-color">
                            <span className="accessory-title">Trade-In</span>
                            <span className="total-accessory">{`$${tradeInTotal}`}</span>
                          </div>
                          <div className="tool-body-option">
                            <span className="accessory-title">Rebates</span>
                            <span className="total-accessory">{`$${rebates}`}</span>
                          </div>
                          <div className="tool-body-option-color">
                            <span className="accessory-title">Check from finance institution</span>
                            {
                              !editCheckFromFinancier ?
                                <div className="container-check-value">
                                  <div className={"container-icon"}>
                                    <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_edit-pencil.svg`} className="icon-edit-check"
                                              beforeInjection={ svg => svg.classList.add('icon-edit-check-svg')} onClick={showEditCheck}
                                              data-test={'edit-check-from-finance-institution-btn'}/>
                                  </div>
                                  <span className="total-check">{`$${checkFromFinancier}`}</span>
                                </div>
                                 :
                                <div className="container-check-value-input">
                                  <div className={"container-icon"}>
                                    <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_check-circle.svg`} className="icon-edit-check"
                                              beforeInjection={ svg => svg.classList.add('icon-edit-check-svg')} onClick={updateCheckFromFinancier}
                                              data-test={'update-check-from-finance-institution-btn'}/>
                                  </div>
                                  <DPDynamicNumberInput className={"input-check"} id={"checkFromFinancier"} integer={8} fraction={2} thousand={true}
                                                        value={checkFromFinancier} separator={'.'} placeHolder={'0.00'} withSymbol={true}
                                                        onChange={(evt, modelValue, viewValue) => changeCheckFromFinancier("checkFromFinancier", modelValue, viewValue, evt)}
                                                        dataTest={'check-from-finance-institution-input'}/>
                                </div>
                            }
                          </div>
                        </div>
                        <div className="balance-total">
                          <span className="total">{`$${balanceDue}`}</span>
                          <span className="label-total">Balance due:</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-finance">
                    <TitleHeader classTitle="title-general" classIcon="icon-finance" classIconSvg="icon-finance-svg"
                                 icon="icon/ic_edit_document.svg" title="Final finance application(s)" selectOption={false}/>
                    <div className="container-financing">
                      {
                        financeInfo.length > 0 ?
                            <div className="container-finances">
                              <div className="header-finances">
                                <span className="title-1" />
                                <div className="container-titles-header">
                                  <span className="title-2">Application name</span>
                                  <span className="title-3">Last Update</span>
                                  <span className="title-4">Status</span>
                                </div>
                              </div>
                              <div className="body-purchaser-finances" data-test={"body-purchaser-finances"}>
                                {
                                  financeInfo.map((finance,index) => {
                                    const {financeId, applicant, modifiedDate, status} = finance;
                                    let checked = false;
                                    const indexId = findIndex(financeSelected, (idSelected) => idSelected === financeId)
                                    if(indexId !== -1) {
                                      checked = true
                                    }
                                    return (
                                        <div key={`finance-${index}`} className={ index % 2 === 0 ? "row-finance" : "row-finance-color"} >
                                          <div className="content-check">
                                            <Checkbox checked={checked} id={index}
                                                      onCheck={() => {handleCheckFinance(financeId)}}
                                                      checkedIcon={elementCheck()}
                                                      uncheckedIcon={elementUnCheck()}
                                                      iconStyle={{borderColor: "#777", marginRight: 2}}
                                                      disabled={false}
                                                      className="checkbox"
                                                      required={true}
                                                      name={"name"}
                                            />
                                          </div>
                                          <div className="container-titles-row">
                                            <span className="title-2">{applicant}</span>
                                            <span className="title-3">{Moment(modifiedDate).format('MM/DD/YYYY HH:mm')}</span>
                                            <span className="title-4" style={{color: setColorStatus(financeStatusValue[status])}}>{getDisplayValue(status)}</span>
                                          </div>
                                        </div>
                                    )
                                  })
                                }
                              </div>
                            </div> :
                            <div className="content-warning">
                              <DPWarning message={"There are no finance applications related to purchaser(s)."}/>
                            </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="panel-bottom">
                  <div className="panel-bottom-left">
                    <div className="panel-trade-in">
                      <TitleHeader classTitle="title-general" classIcon="icon-trade-ins" classIconSvg="icon-trade-ins-svg"
                                   icon="icon/ic_trade_in_symbol.svg" title="Trade-In" selectOption={false}
                                   rightElement={
                                     <div className="right-element">
                                       <div className="container-icon">
                                         <ReactSVG id="userSelected" src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"}
                                                   beforeInjection={svg => svg.classList.add("svg-icon-error")}
                                                   className="icon-error" onClick={(evt) => {showMessageMethod(evt)}}/>
                                       </div>
                                     </div>
                                   }/>
                      {
                        tradeInInfo && tradeInInfo.length > 0 ?
                          <div className="trade-ins-body">
                            {
                              tradeInInfo.map((data, index) => {
                                const {id, make, model, tradeIn, year} = data;
                                const className = (index % 2 === 0) ? "content-check" : "content-check-color";
                                let isItemChecked = false;
                                const indexId = findIndex(tradeInSelected, (idSelected) => idSelected === id)
                                if(indexId !== -1){
                                  isItemChecked = true
                                }
                                return(
                                  <div key={`row-${index}`} className={className}>
                                    <Checkbox checked={isItemChecked} id={index}
                                              onCheck={() => {selectCar(data)}}
                                              checkedIcon={elementCheck()}
                                              uncheckedIcon={elementUnCheck()}
                                              iconStyle={{borderColor: "#777", marginRight: 2}}
                                              disabled={false}
                                              className="checkbox"
                                              required={true}
                                              name={"name"}
                                    />
                                    <div className="container-label">
                                      <p className="check-description">{`${make} ${model} ${year}`}</p>
                                      <p className="check-price">{`$${tradeIn}`}</p>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                          :
                          <div className="trade-ins-body">
                            <div className="content-warning">
                              <DPWarning message={"There are not Trade-ins added to the sale."}/>
                            </div>
                          </div>
                      }
                    </div>
                    <div className="panel-comments">
                      <TitleHeader classTitle="title-general" classIcon="icon-check" classIconSvg="icon-check-svg"
                                   icon="icon/ic_chat_speech_balloon.svg" title="Final comments" selectOption={false}/>
                      <div className="content-comments">
                        <textarea id='content' className="comments-edit" placeholder='Type here' rows={10}
                                  value={finalComments}
                                  onChange={(e) => onChange(e)}/>
                      </div>
                    </div>
                  </div>
                  <div className="panel-bottom-right">
                    <div className="panel-SMS">
                      <TitleHeader classTitle="title-general-messages" classIcon="icon-check" classIconSvg="icon-sms-svg"
                                   icon="icon/ic_cellphone.svg" title="Review request (SMS)" selectOption={false}
                                   rightElement={
                                     <DPToggle handleCheck={handleCheckSection} isChecked={sentSMS} id={"sms"} testId={'toggle-sms'}/>
                                   } />
                      <Message customers={customers} url={url}/>
                    </div>
                    <div className="panel-email">
                      <TitleHeader classTitle="title-general-messages" classIcon="icon-check" classIconSvg="icon-email-svg"
                                   icon="icon/ic_sent_email.svg" title="Review request (Email)" selectOption={false}
                                   rightElement={
                                     <DPToggle handleCheck={handleCheckSection} isChecked={sentEmail} id={"email"} testId={'toggle-email'}/>
                                   }/>
                      <Message customers={customers} url={url}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-button-done">
                <button className="btn-done" onClick={endCompletion} data-test={'btn-sale-done'}>
                  Done
                </button>
              </div>
            </div>
          </div>
          {
            showMessage ?
                <Popover anchorEl={anchorEl}
                         child={child}
                         classPopover="popover-box-message popover-trade-in"
                         handleRequestClose={handleRequestClose}/> : null
          }
        </Modal.Body>
      </Modal>
  )
}

ModalEndSale.defaultProps = {
  show: false,
  onHide: null,
  finalComments: "",
  isLoading: false,
  financeSelected: [],
  tradeInSelected: [],
  showMessage: false,
  editCheckFromFinancier: false
}

ModalEndSale.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  finalComments: PropTypes.string,
  isLoading: PropTypes.bool,
  carInfo: PropTypes.any,
  tradeInInfo: PropTypes.array,
  financeInfo: PropTypes.array,
  balanceInfo: PropTypes.any,
  financeSelected: PropTypes.array,
  handleCheckFinance: PropTypes.func,
  selectCar: PropTypes.func,
  tradeInSelected: PropTypes.array,
  endCompletion: PropTypes.func,
  onChange: PropTypes.func,
  showMessageMethod: PropTypes.func,
  showMessage: PropTypes.bool,
  handleRequestClose: PropTypes.func,
  anchorEl: PropTypes.any,
  editCheckFromFinancier: PropTypes.bool,
  changeCheckFromFinancier: PropTypes.func,
  showEditCheck: PropTypes.func,
  balanceDue: PropTypes.string,
  updateCheckFromFinancier: PropTypes.func,
  handleCheckSection: PropTypes.func,
  sentSMS: PropTypes.bool,
  sentEmail: PropTypes.bool,
  customers: PropTypes.string,
  url: PropTypes.string
}