import React, {Component} from 'react'
import {browserHistory} from 'react-router'
import ContainerHeader from '../util/ContainerHeader.jsx'
import InventoryCar from './InventoryCar.jsx'
import {connect} from 'react-redux'
import find from 'lodash/find'
import {loadCarByVin} from '../../Api'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import {
  SIDEBAR_WIDTH_EXPANDED,
  SIDEBAR_WIDTH_UNEXPANDED,
  TAB_CASH
} from '../../Constants'
import {setCarFilters, setCarStatusOptions} from '../../actions'
import {isEmpty} from 'lodash'
import {setTaskFilters, setMenuId, loadMakeAndModelAll} from '../../actions'
import {getRepairsWithCosts} from '../../Utils'
import ModalInfo from '../util/ModalInfo'
import {ReactSVG} from 'react-svg'
import {getPosition} from '../../../common/Util'
import { pathServer, SUCCESSFUL_CODE } from '../../../common/Constants'

class InventoryEdit extends Component {
  constructor() {
    super();
    this.state = {
      showList:false,
      car:null,
      costs: [],
      documents:[],
      showModal:false,
      message:"Are you sure you want to save these changes?",
      hasChange:false,
      hasChangeCar:false,
      hasChangeImage:false,
      hasChangeDocument:false,
      hasChangeCost:false,
      openTaskReceipt:false,
      hasLinkToTask:false,
      goBack:false,
      customerId:null,
      customerName:null,
      isSaleCash:false,
      finances:null,
      showFinance: null,
      financeIdSelected: null,
      carFilters: {},
      indexCost:0,
      showModalFBMPInfo: false,
    }

  }

  componentDidMount = async () => {
    try {
      let {vin} = this.props.params;
      const {customerId, customerName, isSaleCash, finances, financeIdSelected, carFilters, saleId,customers,salesmanId} = this.props.location.state || {};
      if(vin !== 'new'){
        await this.loadCar(vin,carFilters)
      } else {
        this.setState({ carFilters,customerId: customerId, customerName: customerName, isSaleCash: isSaleCash,
          finances: finances, financeIdSelected: financeIdSelected, saleId: saleId, customers:customers, salesmanId:salesmanId})
      }
      const {makesAll, loadMakeAndModelAll} = this.props;
      if(!makesAll)
        await loadMakeAndModelAll();
    } catch (e) {

    }
  }

  componentWillReceiveProps = async (nextProps) => {
    const {vin} = nextProps.params
    const {customerId, customerName, isSaleCash, finances, financeIdSelected, carFilters} = nextProps.location.state || {};
    if(vin !== 'new'){
      await this.loadCar(vin,carFilters)
    } else {
      this.setState({ carFilters,customerId: customerId, customerName: customerName, isSaleCash: isSaleCash, finances: finances, financeIdSelected: financeIdSelected})
    }
  }

  loadCar = async (vin, carFilters) => {
      const {responseCode, responseMessage, data} = await loadCarByVin(vin)
      if (responseCode === SUCCESSFUL_CODE){
        const costs = getRepairsWithCosts(data.costs);
        this.setState({car:data.car, carFilters: carFilters ? carFilters : {},
          documents: data.documents, costs: costs, isProcessingPost: data.isProcessingPost})
      }else{
        console.error(responseMessage)
      }
  }

  back = () => {
    const {hasChangeCar,hasChangeImage,hasChangeCost,hasChangeDocument,saleId} = this.state;
    if(!saleId)
      browserHistory.push({pathname: "/main/inventory",state:{hasChange:(hasChangeCar || hasChangeImage || hasChangeCost || hasChangeDocument)}});
    else
      browserHistory.push({pathname: `/main/sale/${saleId}`});
  }

  backValidate = () => {
    const {hasChangeCar,hasChangeImage,hasChangeCost,hasChangeDocument} = this.state;
    if(hasChangeCar || hasChangeImage || hasChangeCost || hasChangeDocument){
      this.inventoryCar.validationField(true);
      setTimeout(function () {
        if(this.inventoryCar.state.showModalSave){
          this.setState({showModal:true,goBack:true});
        } else {
          this.setState({showModal:false,goBack:false});
        }
      }.bind(this), 200);
    }else{
      this.redirectPage();
    }
  }

  save = () => {
    this.setState({showModal:false},async () => {
      if(!this.inventoryCar.state.hasError)
        await this.inventoryCar.validationForms();
      //this.back();
    });
  }

  handleModalChange = () => {
    const {goBack} = this.state;
    if(!goBack)
      this.setState({showModal:false});
    else{
      this.redirectPage();
    }
  }

  redirectPage = () => {
    const {hasChangeImage,hasChangeCost,hasChangeCar, customerId, customerName,
      isSaleCash, finances, financeIdSelected, saleId, hasChangeDocument} = this.state;
    if(isEmpty(customerId) && isEmpty(saleId)){
      browserHistory.push({pathname: "/main/inventory",state:{hasChange:(hasChangeCar || hasChangeImage || hasChangeCost || hasChangeDocument)}});
    } else if(!isEmpty(customerId)){
      browserHistory.push({pathname: `/main/customer/edit/${customerId}/sales/new/${TAB_CASH}`,
        state:{name: customerName, isSaleCash: isSaleCash, finances: finances, showFinance: false, financeIdSelected: financeIdSelected}});
    } else {
      browserHistory.push({pathname: `/main/sale/${saleId}`});
    }
  }

  handleUpdateData = (id, newValue = true) => {
    this.setState({[id]:newValue})
  }

  validationModal = () => {
    this.inventoryCar.validationField();

    setTimeout(function () {
      if(this.inventoryCar.state.showModalSave){
        this.setState({showModal:true});
      } else {
        const el = document.getElementById('container');
        if(this.inventoryCar.state.errorCarInformation){
          el.scrollTo(0,0)
        } else if(this.inventoryCar.state.errorCarPricing){
          const sectionPricing = document.getElementById('containerPricing');
          const pointTo = getPosition(sectionPricing);
          if(!this.inventoryCar.state.section["carPricing"]){
            this.inventoryCar.accordionToggle("carPricing");
          }
          el.scrollTo(pointTo.x,pointTo.y)
        }
        this.setState({showModal:false});
      }
    }.bind(this), 200);

  }

  handleIndexToCost = (index) => {
    this.setState({indexCost: index})
  }

  handleModalFBMPInfo = () => {
    this.setState((prevState) =>
      ({showModalFBMPInfo: !prevState.showModalFBMPInfo})
    )
  }

  render() {
    let {vin} = this.props.params;
    let {locations, makesAll, modelsAll,showSideBar, attributeValuesToCarStatus, setCarFilters,setCarStatusOptions,setTaskFilters,setRepairCostFromInventory,taskFilter, setMenuId} = this.props;
    const {car,showModal,message,hasChangeCar,hasChangeImage, hasChangeCost, hasLinkToTask, openTaskReceipt, customerId, customerName, financeIdSelected, carFilters,costs,
      saleId,customers,salesmanId,hasChangeDocument,documents, showModalFBMPInfo, isProcessingPost} = this.state;

    let data = "";
    if(!makesAll || (vin !== "new" && !car)) return null;

    if(vin !== "new" && car){
      data += car.year.toString() + ' ';
      let makeFound = find(makesAll,function (make) {return make.id === car.makeId});
      data += makeFound.displayValue + " ";
      let modelFound = find(modelsAll,function (model) {return model.id === car.modelId});
      data += modelFound.displayValue + " ";
    }

    let left;
    if(showSideBar){
      left = SIDEBAR_WIDTH_EXPANDED;
    } else{
      left = SIDEBAR_WIDTH_UNEXPANDED;
    }

    return (
      <div>
        <ContainerHeader style={{marginLeft: "0"}}
                         ref={el => this.containerHeader = el}
                         title="Inventory"
                         carFilters={carFilters}
                         setCarFilters={setCarFilters}
                         isEdit={true}
                         data={data}
                         actionBack={this.backValidate}
                         save={this.validationModal}
                         hasChange={(hasChangeCar || hasChangeImage || hasChangeCost || hasChangeDocument) && !isProcessingPost}
                         left={left}
                         customerId={customerId}
                         id={"editHeader"}
                         showIconAddcar={false}
                         confirmText={isProcessingPost ? 'Publishing...' : 'Save'}
        />
        <InventoryCar vin={vin}
                      car={car}
                      costs={costs}
                      documents={documents}
                      ref={el => this.inventoryCar = el}
                      handleUpdateData={this.handleUpdateData}
                      hasChangeCar={hasChangeCar}
                      hasChangeImage={hasChangeImage}
                      hasChangeDocument={hasChangeDocument}
                      hasChangeCost={hasChangeCost}
                      hasLinkToTask={hasLinkToTask}
                      handleIndexToCost={this.handleIndexToCost}
                      openTaskReceipt={openTaskReceipt}
                      locations={locations}
                      back={this.back}
                      makes={makesAll}
                      models={modelsAll}
                      isEdit={true}
                      hasChange={(hasChangeCar || hasChangeImage || hasChangeCost || hasChangeDocument)}
                      save={this.validationModal}
                      attributeValuesToCarStatus={attributeValuesToCarStatus}
                      carFilters={carFilters}
                      setCarFilters={setCarFilters}
                      setCarStatusOptions={setCarStatusOptions}
                      customerId={customerId}
                      customerName={customerName}
                      financeIdSelected={financeIdSelected}
                      setTaskFilters={setTaskFilters}
                      taskFilter={taskFilter}
                      setRepairCostFromInventory={setRepairCostFromInventory}
                      saleId={saleId}
                      customers={customers}
                      salesmanId={salesmanId}
                      setMenuId={setMenuId}
                      handleShowFBMPInfo={this.handleModalFBMPInfo}
        />
        {
          showModal ?
            <GenericModalConfirm show={showModal}
                                 message={message}
                                 save={this.save}
                                 onHide={this.handleModalChange}
                                 showButtonCancel={true}
                                 buttonLabelSave="Save"
                                 classButtonSave="btn-save"
            /> : null
        }

        {
          showModalFBMPInfo
            ? <ModalInfo
              show={showModalFBMPInfo}
              onHide={this.handleModalFBMPInfo}
              classModal={'fbmp-modal-info'}
              >
              <React.Fragment>
                <ReactSVG
                  src={`${pathServer.PATH_IMG}logo_facebook_marketplace.svg`}
                  beforeInjection={svg => svg.classList.add('selected-marketing-icon-svg')}
                />
                <span className={'fbmp-info-comment'}>To be able to activate the Facebook Market Place Integration is needed to fill out the following fields:</span>
                <div className={'fbmp-info-detail-container'}>
                  <div>{'1. VIN number'}
                    <br/>{'2. Year'}
                    <br/>{'3. Make'}
                    <br/>{'4. Model'}
                    <br/>{'5. Exterior Color'}
                    <br/>{'6. Body Style'}
                    <br/>{'7. Drivetrain'}
                  </div>
                  <div>{'8. Transmission'}
                    <br/>{'9. Fuel Type'}
                    <br/>{'10. Mileage (Over 500 miles)'}
                    <br/>{'11. Description (Max characters: 100)'}
                    <br/>{'12. Images (At least two)'}
                    <br/>{'13. Condition'}
                    <br/>{'14. Suggested price'}
                  </div>
                </div>
              </React.Fragment>
            </ModalInfo>
            : null
        }
      </div>
    )
  }
} 

const mapStateToProps = (state, ownProps) => {
  return {
    cars:state.cars,
    locations:state.locations,
    makesAll:state.makesAll,
    modelsAll:state.modelsAll,
    showSideBar:state.showSideBar,
    attributeValuesToCarStatus: state.attributeValuesToCarStatus,
    taskFilter:state.taskFilter
  }
};

export default connect(mapStateToProps, {setCarFilters,setCarStatusOptions,setTaskFilters,setMenuId,loadMakeAndModelAll})(InventoryEdit)
