import React from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../common/Constants'


const ModalInfo = ({show, onHide, classModal, children}) => {
  return (
      <div className="container-modal-car">
        <Modal show={show} className={classModal ? `modal-info ${classModal}` : 'modal-info'} backdropClassName="backdrop-car">
          <Modal.Body>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"}
                      className="icon-close-content"
                      beforeInjection={ svg => svg.classList.add("icon-close")}
                      onClick={onHide}/>
            {children}
          </Modal.Body>
        </Modal>
      </div>
  )
}

export default ModalInfo;