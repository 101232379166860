import React from 'react'
import {DPInput} from '../../../../../common/DPInput'
import {DPToggle} from '../../../../../common/DPToggle'
import {
  LINK_TO_PAGE,
  LINK_TO_URL
} from '../../../../../Constants'
import LinkContent from '../../Tabs/LinkContent'
import ExternalUrlContent from '../../Tabs/ExternalUrlContent'
import DPTabs from '../../../../../common/themes/DPTabs'
import {cloneDeep} from 'lodash'
import {SECTION_INVENTORY_ID, PAGE_HOME_ID} from '../../../../../../common/Constants'

const MAX_SIZE_SITE_TITLE = 45
const MAX_SIZE_BUTTON_LABEL = 25

const InventorySection = (props) => {
  const {pages, onChangePages, index} = props
  const homePage = pages.find(x => x.id === PAGE_HOME_ID)
  const inventorySection = homePage.sections.find(x => x.id === SECTION_INVENTORY_ID)
  const {title, buttonText = "", showButton, link = {}} = inventorySection
  const {linkToPage, linkToUrl} = link

  const onChangeTabNavigation = (index, type, value) => {
    const pagesNew = cloneDeep(pages)
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const inventorySection = homePage.sections.find(x => x.id === SECTION_INVENTORY_ID)
    inventorySection.link = {type: type, [type]: value}
    onChangePages(pagesNew)
  }

  const tabs = [
    {key: LINK_TO_PAGE, title : 'Link to page', classNameHeader: "header-link", className: "label-link", component: <LinkContent value={linkToPage ? linkToPage : 'home'} type={'linkToPage'} onChangeTabNavigation={onChangeTabNavigation} />},
    {key: LINK_TO_URL, title : 'External URL', classNameHeader: "header-link", className: "label-link", component: <ExternalUrlContent value={linkToUrl ? linkToUrl : ''} type={'linkToUrl'} onChangeTabNavigation={onChangeTabNavigation} />}
  ]

  const onChangeValueJson = (evt, field) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const inventorySection = homePage.sections.find(x => x.id === SECTION_INVENTORY_ID)
    inventorySection[field] = evt.currentTarget.value
    onChangePages(pagesNew)
  }

  const handleShowButton = () => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const inventorySection = homePage.sections.find(x => x.id === SECTION_INVENTORY_ID)
    inventorySection.showButton = inventorySection.showButton ? !inventorySection.showButton : true
    onChangePages(pagesNew)
  }

  const setLinkType = (tab) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const inventorySection = homePage.sections.find(x => x.id === SECTION_INVENTORY_ID)
    if(inventorySection.link){
      inventorySection.link.type = tab
    }else{
      inventorySection.link = { type: tab , [tab] :''}
    }
    onChangePages(pagesNew)
  }

  return(
    <div className="container-home-inventory">
      <div className="row-title">
        <DPInput
          className='f'
          id='title'
          title='Section title'
          rightElement={<label className={`label-size right ${title.length >= MAX_SIZE_SITE_TITLE ? 'error-color' : ''}`}>{`${title.length}/${MAX_SIZE_SITE_TITLE}`}</label>}
          value={title}
          onChange={(e) => onChangeValueJson(e, 'title')}
          maxLength={MAX_SIZE_SITE_TITLE}
        />
      </div>
      <div className="row-toggle">
        <div className="label-toggle">Show button</div>
        <DPToggle isChecked={!!showButton} handleCheck={handleShowButton}/>
      </div>
      {
        !!showButton &&
          <div className="row-navigation">
            <span className="label-btn">Button Link</span>
            <DPTabs tabs={tabs}
                    classNameContainer=""
                    classNameTabActive="link-active"
                    otherFunction={setLinkType}
                    checkboxIncluded={true}
                    tabActive={link ? link.type : null}
            />
            <DPInput
              className='button-text'
              id='buttonText'
              title='Button text'
              value={buttonText ? buttonText : ""}
              maxLength={MAX_SIZE_BUTTON_LABEL}
              rightElement={<label className={`label-size right ${buttonText.length >= MAX_SIZE_BUTTON_LABEL ? 'error-color' : ''}`}>{`${buttonText.length}/${MAX_SIZE_BUTTON_LABEL}`}</label>}
              onChange={(e) => onChangeValueJson(e, 'buttonText')}
            />
          </div>
      }
    </div>
  )
}

export default InventorySection