import React, {useState, memo, useCallback} from 'react'
import {
  ATTRIBUTE_CUSTOMER_EMAIL,
  entityTypes,
  FORM_CUSTOMER_REVIEW,
  importKey,
  ROWS_IMPORT_BY_PAGE_BY_DEFAULT
} from '../../Constants'
import {browserHistory} from 'react-router'
import DPButtonIcon from '../../common/DPButtonIcon'
import ModalUploadFile from '../util/ModalUploadFile'
import ModalViewDataImport from '../util/ModalViewDataImport'
import {cloneDeep} from 'lodash'
import {unstable_batchedUpdates} from 'react-dom'
import {pathServer} from '../../../common/Constants'
import {ReactSVG} from 'react-svg'

require('./CustomerPanel.scss')

const MemoModalViewDataImport = memo(ModalViewDataImport)

const CustomerPanel = (props) => {

  const [showModalImport, setHandleImport] = useState(false);
  const [showModalData, setShowModalData] = useState(false);
  const [totalRows, setTotalRows] = useState([]);
  const [nameFile, setNameFile] = useState("");
  const [columns, setColumns] = useState([]);
  const [totalPages, setTotalPages] = useState(0);


  const handleShowModalData = (totalRows, columns, nameFile) => {

    unstable_batchedUpdates(() =>{
      setHandleImport(false);
      setTotalRows(cloneDeep(totalRows));
      setNameFile(nameFile);
      setColumns(cloneDeep(columns));
      setShowModalData(true);
    })

  }

  const action = () => {
    browserHistory.push('/main/customer/new')
  }

  const closeModalData = useCallback(() => {
    setShowModalData(false)
    browserHistory.push({pathname: "/main/customer"});
  }, [showModalData]);

  const {title1, title2, text, textButton, gifPath, helpText, linkHelpText, setCustomerManually} = props

  return (
    <div className="section-box-customer">
      <div className="section-right-customer">
        <div className="great-container-customer">
          <div className="customer-import-container">
            <label className="title-description1">{title1 ?? ''}</label>
            <label className="title-description2">{title2 ?? ''}</label>
            <p className="text-description">{text ?? ''}</p>
            <div className="container-buttons">
              <DPButtonIcon
                width={'180px'}
                background={'#2194F1'}
                btnSaveText={textButton}
                iconSendPath={'file_import_icon.svg'}
                borderColor={'transparent'}
                textColor={'#FFF'}
                buttonClass={'button-icon'}
                hoverText={'Import now'}
                onClick={() => setHandleImport(!showModalImport)}
              />

              <DPButtonIcon
                width={'200px'}
                background={'#FFF'}
                btnSaveText={'Add customers manually'}
                borderColor={'#c5d1de'}
                textColor={'#2194F1'}
                textColorHover={'#FFF'}
                buttonClass={'button-icon'}
                hoverText={'Add customer now'}
                onClick={() => setCustomerManually()}
              />

            </div>
            <div className="container-help-us">
              <label className='help-text-description'>{helpText}</label>
              <label className='link-help-text-description'>{linkHelpText}</label>
            </div>
          </div>
          <div className="gif-container">
            <img src={`${pathServer.PATH_IMG}${gifPath}`}/>
          </div>
        </div>
      </div>
      {showModalImport ?
        <ModalUploadFile show={showModalImport} onHide={() => setHandleImport(!showModalImport)}
                         showModalData={handleShowModalData} title={"Import customers"}/> : null
      }
      {
        showModalData ?
          <MemoModalViewDataImport show={showModalData}
                                   onHide={closeModalData}
                                   totalRows={[...totalRows]}
                                   rowsNumberToShow={ROWS_IMPORT_BY_PAGE_BY_DEFAULT}
                                   nameFile={nameFile}
                                   columns={columns}
                                   totalPages={totalPages}
                                   formCodeToImport={FORM_CUSTOMER_REVIEW}
                                   importKey={importKey.CUSTOMER}
                                   entityType={entityTypes.CUSTOMER}
                                   rowKey={ATTRIBUTE_CUSTOMER_EMAIL}
          />
          : null
      }
    </div>
  )
}

export default CustomerPanel