import React, {Component} from 'react'
import {
  MESSAGE_ACTIONS,
  MESSAGE_UNDELETE_ACTIONS,
  SHOW_MESSAGES,
  SORT_MESSAGES,
  NUMBER_PAGES_SHOWN,
  CUSTOMERS_BY_PAGE,
  DEFAULT_SYSTEM_EMAIL_SUPPORT
} from '../../Constants'
import {browserHistory} from 'react-router'
import {deleteUndeleteMessageApi, replyMessagesApi, updateStateMessages} from '../../Api'
import {ReactSVG} from 'react-svg'
import {filter, sortBy} from 'lodash/collection'
import ReactToPrint from 'react-to-print'
import {loadMessages} from '../../actions'
import MessagesModal from './MessagesModal.jsx'
import {connect} from 'react-redux'
import MessageReply from './MessageReply.jsx'
import Moment from 'moment'
import {setKeyword} from '../../actions'
import {onSortMessage, setPlaceHolder, textWithoutHTML} from '../util/Utils'
import NavigationHeader from '../util/NavigationHeader.jsx'
import {Popover} from '../util/ComponentUtils'
import {getPosition} from '../../../common/Util'
import {pathServer} from '../../../common/Constants'

class Message extends Component {
  componentRef;
  constructor() {
    super();
    this.onChangeDropDownForm = this.onChangeDropDownForm.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.onChangeCheckAll = this.onChangeCheckAll.bind(this);
    this.onChangeCheck = this.onChangeCheck.bind(this);
    this.renderCheckBox = this.renderCheckBox.bind(this);
    this.buildPages = this.buildPages.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onChangeReplay = this.onChangeReplay.bind(this);
    this.updateMessages = this.updateMessages.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.onDeleteMessage = this.onDeleteMessage.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.viewNewMessages = this.viewNewMessages.bind(this);
    this.replyEmail = this.replyEmail.bind(this);
    this.renderMessagesPane = this.renderMessagesPane.bind(this);
    this.onShowSelected = this.onShowSelected.bind(this);
    this.showOldResponse = this.showOldResponse.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.state = {
      totalPages: 10,
      numberPagesShown: NUMBER_PAGES_SHOWN,
      currentPage: 1,
      selectedAll: false,
      idsSelected: [],
      messagesAll: [],
      messages: [],
      responses: [],
      cont: 10,
      rowName: [],
      messageList: [],
      messageSelected: {},
      showOldResponse: {},
      countMessageSelected: null,
      showMessageReplay: {},
      showDropDownUndelete: false,
      dropDownSelect: ["Delete", "All Messages", "Sort By", "Undelete"],
      dropDownChangeStates: MESSAGE_ACTIONS,
      dropDownUndelete: {
        render: false,
        values: MESSAGE_UNDELETE_ACTIONS,
      },
      dropDownShowMessages: SHOW_MESSAGES,
      dropDownSortMessages: SORT_MESSAGES,
      typeReply: "",
      showModal: false,
      formsPages: [],
      showOriginalSize: true,
      showPane: false,
      responseData:{
        id:null,
        dealerId:'',
        listRecipients:'',
        listMessages:'',
        reference:'',
        status:false,
        lastName:'',
      },
      showOptions:false,
      selectedAction:false,
      selectedShow:false,
      selectedSort:false,
      filterSelected:null,
      showPopover:false,
      options:[],
      showButtonAddMessage: false
    }
  }

  componentWillMount() {
    let {messages} = this.state;
    this.loadMessages(messages);
  }

  componentDidMount() {
    setPlaceHolder("menu-search","Search From, Subject or Date");
  }

  loadMessages(messages){
    this.props.loadMessages().then((response) => {
      messages = response.messages; //Reverse to show the latest messages first
      if(messages && messages.length > 0){
        messages.reverse();
      }
      this.setState({messages: messages,messagesAll:messages,showPane:false,messageSelected:null},()=>{
        this.onShowSelected();
      })
    });
  }

  componentWillReceiveProps(nextProps){
    const {search} = nextProps;
    this.findByKeyWord(search);
  }

  componentWillUnmount(){
    //this.props.setMenuId("");
    this.props.setKeyword("");
  }

  findByKeyWord(word){
    if(word && word !== ""){
      this.filterMessage(word);
    } else{
      this.onShowSelected();
    }
  }

  filterMessage(word) {
    let {messages} = this.state;
    let messagesEdit = messages;
    let newMessages = [];
    messagesEdit.find((message) => {
      let dateString = Moment(message.createDate).format('LLL').toLowerCase() + " " + Moment(message.createDate).format('L');
      if(message.name.toLowerCase().indexOf(word.toLowerCase()) !== -1 || message.email.toLowerCase().indexOf(word.toLowerCase()) !== -1 || message.text.toLowerCase().indexOf(word.toLowerCase()) !== -1 || dateString.indexOf(word.toLowerCase()) !== -1){
        newMessages.push(message);
      }
    });
    this.buildPages(newMessages); // Send to build page by showing
  }

  buildPages(data) {
    let totalPages;
    let formsPages = [];
    if (data.length !== 0) {
      let page = [];
      totalPages = data.length / NUMBER_PAGES_SHOWN;
      if (data.length % NUMBER_PAGES_SHOWN !== 0)
        totalPages++;
      totalPages = Math.floor(totalPages);
      data.map((item, index) => {
        page.push(item);
        if ((index !== 0 && (index + 1) % NUMBER_PAGES_SHOWN === 0) || (index === (data.length - 1))) {
          formsPages.push(page);
          page = [];
        }
      });
    } else {
      totalPages = 0;
    }
    this.setState({formsPages: formsPages, totalPages: totalPages});
  }

  getRowName(message){
    let responseMessages = message.responseMessages;
    let from = message.email;
    let count = 1;
    responseMessages.forEach((row)=> {
      if (row.status){
        count = count + 1;
      }
    });
    if(count > 1){
      from = from + " ("+ count +")";
    }
    return from;
  }

  handlePagination(number) {
    this.setState({currentPage: number})
  }

  onChangeDropDownForm(dropDown) {
    let filterSelected = dropDown;
    let target = document.getElementById(dropDown);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };

    this.setState({showPopover:true, anchorEl: anchorEl, filterSelected: filterSelected})
  }

  onSelectDropDown(indexArraySelected, value) {
    let {dropDownSelect} = this.state;
    dropDownSelect[indexArraySelected] = value;
    this.setState({dropDownSelect: dropDownSelect, showPopover:false, filterSelected: null});
  }

  onShowSelected() {
    let {messages, dropDownSelect, dropDownShowMessages, dropDownSortMessages, showDropDownUndelete} = this.state;
    let messagesEdit = messages;
    let newMessages = [];
    let that = this;

    dropDownShowMessages.forEach((show,index) => {
      if(show.value === dropDownSelect[1]){
        (index === 3) ? showDropDownUndelete = true : showDropDownUndelete = false;
        messagesEdit.forEach((message) => { //Iterate for messages
          if (((message.active) && ((index === 0) ||
            (index === 2 && (message.read)) || (index === 1 && (!message.read))))
            || ((index === 3) && !(message.active))) { //Show message in accordance with option and messages states
            newMessages.push(message);
          }
        });
      }
    });

    dropDownSortMessages.forEach((sort, index) => {
      if(sort.value === dropDownSelect[2]){
        newMessages = onSortMessage(newMessages, sort.id);
      }
    });

    this.setState({selectedAll: false, idsSelected: [], countMessageSelected: null, showDropDownUndelete: showDropDownUndelete, messages: messagesEdit});
    this.buildPages(newMessages); // Send to build page by showing
  }

  onChangeCheckAll() {
    let {formsPages, currentPage, idsSelected, selectedAll} = this.state;
    let countMessageSelected = 0;
    if (!selectedAll) {
      formsPages[currentPage - 1].forEach(message => {
        idsSelected.push(message.id);
        countMessageSelected++;
      });
    } else {
      idsSelected = [];
    }
    this.setState({selectedAll: !this.state.selectedAll, idsSelected: idsSelected, countMessageSelected: countMessageSelected})
  }

  onChangeCheck(id) {
    let {idsSelected} = this.state;
    if (idsSelected.indexOf(id) === -1) {
      idsSelected.push(id);
    } else {
      idsSelected.splice(idsSelected.indexOf(id),1);
    }
    let countMessageSelected = idsSelected.length;
    this.setState({idsSelected: idsSelected, countMessageSelected: countMessageSelected});
  }

  renderCheckBox(value, onChange, id, isSelected,styleCheck) {
    return (
      <label key={id} className="container-check" style={styleCheck ? styleCheck:null}>
        <input type="checkbox"
               checked={value}
               onChange={onChange}/>
        <span className="checkmark" style={isSelected ? {border: "1.4px #4695d4 solid"} : null}/>
        {
          value ? <i className="fa fa-check i-check"/> : null
        }
      </label>
    )
  }

  onDeleteMessage(message) {
    let {messages} = this.state;
    deleteUndeleteMessageApi({messageIds:[message.id],active:false}).then(({ responseCode }) => {
      if (responseCode === 200){
        this.loadMessages(messages);
      }
    });
    /*messagesEdit[(index - 1)].active = false;
    this.setState({messages: messagesEdit}, () => {
      this.onShowSelected();
    });*/
  }

  async updateMessages() {
    try {
      let {messages, messagesAll, dropDownSelect, idsSelected, showDropDownUndelete,messageSelected} = this.state;
      let listMessages = filter(messages, function (message) {
        return idsSelected.indexOf(message.id) >= 0
      });
      if (listMessages.length > 0) {
        if(showDropDownUndelete){
          listMessages = filter(messagesAll, function (message) {
            return idsSelected.indexOf(message.id) >= 0
          });
          listMessages.forEach(message => {
            message.active = true;
          });
        }else{
          if(dropDownSelect[0] === "Delete"){
            listMessages.forEach(message => {
              message.active = false;
            });
          }else if(dropDownSelect[0] === "Read") {
            listMessages.forEach(message => {
              message.read = true;
            });
          }else if(dropDownSelect[0] === "Unread"){
            listMessages.forEach(message => {
              message.read = false;
            });
          }
        }

        await updateStateMessages({updateMessages: listMessages})
        let state = {}
        if (messageSelected && idsSelected.indexOf(messageSelected.id) >= 0) {
          state = {messageSelected: null, showPane: false}
        }
        await this.props.loadMessages()
        this.onShowSelected()
        this.setState({messages: this.props.messages, idsSelected: [], countMessagesSelected: null, ...state})
      }
    } catch (error) { console.error(error) }
  }

  onChangeTab(id) {
    if (id==="id") {
      browserHistory.push({pathname: "/main/message/finance", state: {showMessage: false}});
    } else if(id==="sent"){
      browserHistory.push({pathname: "/main/message/sent", state: {showMessage: true}});
    //} else if(id==="chat-box"){
    //  browserHistory.push({pathname: "/main/message/chat-box", state: {showMessage: true}});
    } else{
      this.setState({showPane: false});
      browserHistory.push({pathname: "/main/message", state: {showMessage: true}});
    }
  }

  onChangeMessage(message, index) {
    let {messages, messageList} = this.state;
    let messageEdit = messages;
    let messageAux={};
    if (!message.read){
      message.read =  true;
    }
    messageEdit.forEach(function(row) {
      if (row.id === message.id) {
        row.read = message.read;
        messageAux = row;
      }
    });

    messageList.push(messageAux);
    this.setState({messageSelected: messageAux, indexRowSelected: index});
    updateStateMessages({updateMessages: messageList}).then(({ responseCode }) => {
      if(responseCode === 200 ){
        this.setState({messages: messageEdit, showPane: true},async ()=>{
          // Call it to update unread message counter
          try {
            await this.props.loadMessages()
          } catch (e) { console.error(error) }

          this.onShowSelected()
        })
      }
    });
  }

  onChangeReplay(id, type) {
    let {showMessageReplay} = this.state;
    if(showMessageReplay[id]){
      showMessageReplay[id] = !showMessageReplay[id];
    }else{
      showMessageReplay[id] = true
    }
    this.setState({showMessageReplay: showMessageReplay, typeReply: type})
  }

  closePopover(dropDown) {
    let dropDownClose = this.state[dropDown];
    dropDownClose.render = false;
    this.setState({[dropDown]: dropDownClose})
  }

  handleHideModal() {
    this.setState({showModal: false});
  }

  viewNewMessages() {
    this.setState({showModal: true})
  }

  onResize(){
    let {showOriginalSize} = this.state;
    let idMessageLeft = window.document.getElementsByClassName("message-left");
    let idMessageDetails = window.document.getElementsByClassName("message-details");
    let contentReply = window.document.getElementById("content-reply");
    let editorContainer = window.document.getElementsByClassName("DraftEditor-editorContainer");
    let btnMessageReplay = window.document.getElementsByClassName("btn-message-replay");
    let btnMessageForward = window.document.getElementsByClassName("btn-message-forward");
    if(!showOriginalSize){
      idMessageLeft[0].style.width = "60%";
      idMessageDetails[0].style.width = "40%";
      idMessageDetails[0].style.overflowX = "hidden";
      if(contentReply){
        contentReply.style.height = "225px"
      }
      if(editorContainer[0]){
        editorContainer[0].style.height = "170px"
      }
      if(btnMessageReplay && btnMessageReplay[0]){
        btnMessageReplay[0].style.width = "30%";
      }
      if(btnMessageForward && btnMessageForward[0]){
        btnMessageForward[0].style.width = "35%";
      }
    }else{
      idMessageLeft[0].style.width = "26%";
      idMessageLeft[0].style.position = "relative";
      idMessageDetails[0].style.width = "100%";
      idMessageDetails[0].style.backgroundColor = "white";
      if(contentReply){
        contentReply.style.height = "270px"
      }
      if(editorContainer[0]){
        editorContainer[0].style.height = "210px"
      }
      if(btnMessageReplay && btnMessageReplay[0]){
        btnMessageReplay[0].style.width = "17%";
      }
      if(btnMessageForward && btnMessageForward[0]){
        btnMessageForward[0].style.width = "20%";
      }
    }
    this.setState({showOriginalSize:!showOriginalSize})
  }

  replyEmail(message, files){
    let {messageSelected,messages, showMessageReplay} = this.state;
    message.referenceMessageId =  messageSelected.id;
    //message.from =  messageSelected.email;
    message.from = DEFAULT_SYSTEM_EMAIL_SUPPORT
    message.subject = "Re:" + messageSelected.email;
    replyMessagesApi(message,files).then(({ responseCode }) => {
      if (responseCode === 200){
        this.loadMessages(messages);
        showMessageReplay[messageSelected.id] = false;
        this.setState({messages: messages, messageDisplay:"Your message has been sent", showMessageReplay: showMessageReplay});
        setTimeout(() => {
          this.setState({messageDisplay: null});
        },3500);
      }
    })
  }

  showOldResponse(id){
    let {showOldResponse} = this.state;
    if(showOldResponse[id])
      delete showOldResponse[id];
    else
      showOldResponse[id] = true;
    this.setState({showOldResponse: showOldResponse})
  }

  renderMessagesPane(messageSelected) {
    let {messages, showOldResponse} = this.state;
    let responseMessagesUndelete = [];
    let count = 0;
    messages.forEach((response) => {
      if(response.id === messageSelected.id){
        messageSelected = response;
      }
    });
    let responseMessages = messageSelected.responseMessages;
    responseMessages.forEach((row)=> {
      if (row.status){
        count = count + 1;
        responseMessagesUndelete.push(row);
      }
    });

    let reverse = responseMessagesUndelete.reverse();
    const printMessageOld = reverse.map((rm, index) => {
      return (
        <div >
          {
            <div key={index} className="row-message" id="row-message-detail-last">
              <div className="last-message-conversation" id="last-message-talk">
                <div className="dot-message-user-little" id="dot-message-little-talk">
                  <label className="initialNames">{messageSelected.name.replace(/\W*(\w)\w*/g, '$1').substring(0, 2).toUpperCase()}</label>
                </div>
                <label className="label-message-repair-last" id="label-message-last-talk">
                  {textWithoutHTML(rm.text)}
                </label>
              </div>
            </div>
          }
        </div>
      );
    });
    {
      if(count === 0){
        return (
          <div className="row-message" id="row-message-detail">
            <label className="label-message-repair"  id="tag-message-detail">
              {textWithoutHTML( messageSelected.text  ?  messageSelected.text : "No subject")}
            </label>
          </div>
        )
      }else if (count === 1) {
        return (
          <div className="row-message-detail-next">
            <div className="row-message" id="row-message-detail-last">
              <div className="last-message-conversation" id="last-message-talk">
                <div className="dot-message-user-little" id="dot-message-little-talk">
                  <label className="initialNames">{messageSelected.name.replace(/\W*(\w)\w*/g, '$1').substring(0, 2).toUpperCase()}</label>
                </div>
                <label className="label-message-repair-last" id="label-message-last-talk">
                  {textWithoutHTML(messageSelected.text)}
                </label>
              </div>
            </div>
            <div className="row-message" id="row-message-detail-preview">
              <div className="container-label-message-detail"  id="preview-message-talk">
                <div className="dot-message-user-little" id="dot-message-preview-talk">
                  <label className="initialNames">{messageSelected.name.replace(/\W*(\w)\w*/g, '$1').substring(0, 2).toUpperCase()}</label>
                </div>
                <label className="label-message-repair" id="label-message-preview-talk">
                  {textWithoutHTML(responseMessagesUndelete[0].text)}
                </label>
              </div>
            </div>
          </div>
        );
      }else if (count === 2 || count === 3) {
        return (
          <div className="row-message-detail-next">
            <div className="row-message" id="row-message-detail-last">
              <div className="last-message-conversation" id="last-message-talk">
                <div className="dot-message-user-little" id="dot-message-little-talk">
                  <label className="initialNames">{messageSelected.name.replace(/\W*(\w)\w*/g, '$1').substring(0, 2).toUpperCase()}</label>
                </div>
                <label className="label-message-repair-last" id="label-message-last-talk">
                  {textWithoutHTML(messageSelected.text)}
                </label>
              </div>
            </div>

            {printMessageOld}
          </div>
        )
      } else {
        return (
          <div>
            {
              showOldResponse[messageSelected.id] ? <div>
                {printMessageOld}
              </div> : <div className="row-message" id="row-message-count">
                <div className="last-message-conversation">
                  <div id="dot-greater-four" className="dot-message-user-little" onClick={this.showOldResponse(messageSelected.id)}>
                    <label className="initialNames">{count - 3}+</label>
                  </div>
                </div>
              </div>
            }
          </div>
        );
      }
    }
  }

  renderAction(id){
    const{dropDownChangeStates,dropDownShowMessages,dropDownSortMessages,dropDownSelect} = this.state;
    let indexSelected = 0;
    let values = [];
    let option = [];
    let selectedItem = "";

    switch (id){
      case 'selectedStates':
        indexSelected = 0;
        values = dropDownChangeStates;
        selectedItem = dropDownSelect[0];
        break;
      case 'selectedShow':
        indexSelected = 1;
        values = dropDownShowMessages;
        selectedItem = dropDownSelect[1];
        break;
      case 'selectedSort':
        indexSelected = 2;
        values = dropDownSortMessages;
        selectedItem = dropDownSelect[2];
        break;
    }

    values.forEach((action,index) => {
      if(action.value === selectedItem){
        option.push(
          <li key={index} className="item-selected"
              onClick={() => this.onSelectDropDown(indexSelected,action.value)}>
            {action.value}
          </li>
        )
      } else{
        option.push(
          <li key={index} className="change-submenu-item"
              onClick={() => this.onSelectDropDown(indexSelected,action.value)}>
            {action.value}
          </li>
        )
      }

    });

    let dataPopover = "";

    if (id === 'selectedStates'){
      dataPopover = <div className="popover-general-message">
                      <div className="popover-general-message-sub">
                        <u>
                          {option}
                        </u>
                      </div>
                    </div>
    } else {
      dataPopover = <div className="popover-general-message">
                      <div className="popover-general-message-sub">
                        <u onClick={() => this.onShowSelected()}>
                          {option}
                        </u>
                      </div>
                    </div>
    }

    return(
      dataPopover
    )
  }

  handleRequestClose() {
    this.setState({showPopover: false,filterSelected: null});
  }

  closePanel = () => this.setState({ showPane: false, messageSelected: {}})

  render() {
    const {totalPages, currentPage, selectedAll, idsSelected, messageSelected, countMessageSelected, formsPages, showMessageReplay,dropDownSelect, showDropDownUndelete,
      typeReply, showPane, messageDisplay, showOriginalSize,showOptions,numberPagesShown,filterSelected,showPopover,anchorEl, showButtonAddMessage} = this.state;
    const {newFinanceApplicationsCounter, unreadMessagesCounter, dealerName} = this.props;
    let that = this;
    let itemDropDownReplace = 0;
    const now = Moment(new Date(),"DD/MM/YYYY");

    let childMessage = [];
    if(filterSelected === 'selectedStates'){
      childMessage = this.renderAction('selectedStates');
    } else if(filterSelected === 'selectedShow'){
      childMessage = this.renderAction('selectedShow');
    } else if(filterSelected === 'selectedSort'){
      childMessage = this.renderAction('selectedSort');
    }

    const isWritingMessage = !!messageSelected && !!showMessageReplay[messageSelected.id]

    return (
      <div>
        <NavigationHeader name="Messages"
                          addButton={false}
                          add={() => this.viewNewMessages()}
                          tab="inbox"
                          onChangeTab={this.onChangeTab}
                          showOptions={showOptions}
                          options={CUSTOMERS_BY_PAGE}
                          anchorEl={false}
                          itemsByPageQuantity={numberPagesShown}
                          handlePageChange={this.handlePagination}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          showItemPerPage={false}
                          newFinanceAppsCounter={newFinanceApplicationsCounter}
                          unreadMessagesCounter={unreadMessagesCounter}
        />
        <div className="message-body">
          <div className="message-container" id="message-container-finance">
            <div id="message-left" className="message-left" style={showPane ?{width: "70%"}:{width: "100%"}}>
              <div className="row-message header-message-left" id="header-left-message">
                {
                  showButtonAddMessage ?
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"} svgClassName="icon-add-svg"
                              className="icon-add-task" onClick={ () => this.viewNewMessages()} /> : null
                }
                {
                  countMessageSelected ?
                    <div className="btn-container-dp btn-container-message">

                      <p className="btn-message-selected">
                        {countMessageSelected}
                      </p>
                    </div> : null
                }
                <div className="btn-container-dp-message" style={!showButtonAddMessage ? {paddingLeft: 110} : null}>
                  <div id= "selectedStates" className="btn-general-message-dp" onClick={!showDropDownUndelete ? this.onChangeDropDownForm.bind(this,"selectedStates") : ()=>{}} style={{width: 100}}>
                    {
                      showDropDownUndelete ? <span>Undelete</span> : dropDownSelect[itemDropDownReplace] !== "" ? <span>{dropDownSelect[itemDropDownReplace]}</span> : <span>Delete</span>
                    }
                    {
                      filterSelected === "selectedStates" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                    }
                  </div>
                </div>
                <div className="btn-container-dp">
                  <button className={`btn-message-apply${idsSelected.length === 0 ? '-disabled': ''}`}
                          onClick={this.updateMessages}
                          disabled={idsSelected.length === 0}>
                    Apply
                  </button>
                </div>
                <div id={(showPane)? "":"container-dp-show-messages"} className="btn-container-dp status-message">
                  <div id= "selectedShow" className="btn-general-message-dp" onClick={this.onChangeDropDownForm.bind(this, "selectedShow")} style={{width: 160}}>
                    {
                      dropDownSelect[1] !== "" ? <span>{dropDownSelect[1]}</span> : <span>All Messages</span>
                    }
                    {
                      filterSelected === "selectedShow" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                    }
                  </div>
                </div>
                <div id={(showPane)? "":"container-dp-sort-messages"} className="btn-container-dp sort-message">
                  <div id= "selectedSort" className="btn-general-message-dp" onClick={this.onChangeDropDownForm.bind(this,"selectedSort")} style={{width: 140}}>
                    {
                      dropDownSelect[2] !== "" ? <span>{dropDownSelect[2]}</span> : <span>Sort By</span>
                    }
                    {
                      filterSelected === "selectedSort" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                    }
                  </div>
                </div>
              </div>
              <div className="table-message" id="data-message-table">
                <table className="table">
                  <thead className="thread-class">
                  <tr>
                    <th className="head-check-box" id="check-head-message">
                      {
                        this.renderCheckBox(selectedAll, this.onChangeCheckAll.bind(this), "selectedAll", true,{marginLeft:82})
                      }
                    </th>
                    <th className={(!showPane)?"th-20":"th-33"}>From</th>
                    <th className="th-40">Summary</th>
                    <th className="th-10">Phone Number</th>
                    <th className={(!showPane)?"th-10":"th-15 col-dateFormat-message"}>Date</th>
                  </tr>
                  </thead>
                  <tbody className="tbody-class" >
                  {
                    formsPages.length !== 0 ?
                      formsPages[currentPage - 1].map((message, index) => {
                        let createDate = Moment(new Date(message.createDate),"DD/MM/YYYY");
                        let dateFormat = "";
                        if(createDate.isSame(now,"day") && createDate.isSame(now,"month") && createDate.isSame(now,"year")){
                          dateFormat = Moment(new Date(message.createDate)).format("hh:mm A")
                        }else if (createDate.isSame(now,"month") && createDate.isSame(now,"year")){
                          let dateMonth = Moment(message.createDate).format('ll');
                          dateFormat = dateMonth.split(",")[0];
                        }else{
                          dateFormat = Moment(new Date(message.createDate)).format("L")
                        }
                        let style = {};
                        let selectedMessage = false;
                        if (messageSelected && message.id === messageSelected.id) {
                          style.borderTop = "1px solid #EDF1FF";
                          style.borderBottom = "1px solid #EDF1FF";
                          style.borderRight = "1px solid white";
                          style.backgroundColor = "#EDF1FF";
                          style.fontWeight = "bold";
                        }else{
                          style.borderTop = "none";
                          style.borderBottom = "none";
                          style.borderRight = "1px solid rgb(238, 238, 242)";
                          style.backgroundColor = "transparent";
                          style.fontWeight = "normal";
                        }
                        if(idsSelected.indexOf(message.id) >= 0){
                          selectedMessage = true
                        }
                        return (
                          <tr key={index} className="row-table-message rowCostumer" style={style}>
                            <td className="row-icon-message">
                              {
                                message.read ?
                                  <div className="dot-message" onClick={() => this.onChangeMessage(message, index)}>
                                    <i className="fa fa-check i-check"/>
                                  </div> :
                                  <div className="unread-icon" onClick={() => this.onChangeMessage(message, index)}>
                                    <i id="messages-envelope-o" className="fa fa-envelope-o"/>
                                  </div>
                              }
                              {
                                that.renderCheckBox(selectedMessage, that.onChangeCheck.bind(that, message.id), message.id,selectedMessage,{marginLeft:36} )
                              }
                            </td>
                            <td onClick={() => this.onChangeMessage(message, index)}>{this.getRowName(message)}</td>
                            <td onClick={() => this.onChangeMessage(message, index)}  className={(!showPane)?"subject-text":"subject-text2"}>
                              <span className="summary-description">{message.text}</span></td>
                            <td onClick={() => this.onChangeMessage(message, index)}>{message.phoneNumber}</td>
                            <td className={(showPane)?"col-dateFormat-message":""}
                                onClick={() => this.onChangeMessage(message, index)}>{dateFormat}</td>
                          </tr>
                        )
                      }):<tr/>
                  }
                  </tbody>
                </table>
                {
                  formsPages.length === 0 ? <div className="container-no-messages">
                    <div className="container-icon-no-messages">
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_info_24px.svg"} beforeInjection={ svg => svg.classList.add("icon-info")}/>
                    </div>

                    <div className="container-notify-no-messages">
                      <span className="notify-no-messages">No messages yet</span>
                    </div>
                  </div>: null
                }
              </div>
            </div>
            {
              (messageSelected && showPane) ?
                <div id="message-details" className="message-details">
                  <div ref={el => (this.componentRef = el)}>
                    <div className="message-details-header">
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_x.svg"}
                                className="icon-delete-content" beforeInjection={ svg => svg.classList.add('icon-delete')} onClick={this.closePanel}/>
                      <div className="row-message header-message-detail" id="header-message-panel">
                        <div className="dot-message-user">
                          <label id="initial-name">{messageSelected.name.replace(/\W*(\w)\w*/g, '$1').substring(0,2).toUpperCase()}</label>
                        </div>
                        <div className="col45">
                          <label className="label-message-user">{messageSelected.name}</label>
                          <label className="label-message-email">{messageSelected.email}</label>
                        </div>
                        <div className="date">
                          <label className="label-message-date">
                            {Moment(messageSelected.createDate).format('lll')}
                          </label>
                          <div className="icon-expand">
                            {
                              showOriginalSize ?
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_zoom_out.svg"}
                                          beforeInjection={ svg => svg.classList.add("ic-icon-expand")} onClick={()=>this.onResize()} />
                                :
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_zoom_in.svg"}
                                          beforeInjection={ svg => svg.classList.add("ic-icon-zoom-in")} onClick={()=>this.onResize()} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row-message header-title-message" id="header-message-title-name">
                        <div className="title" id="title-name">
                          <h3 className="name-message-selected">{messageSelected.title}</h3>
                        </div>
                        <div className="options" id="options-fa-icons">
                          <div className="ic-delete">
                            <i id="id-fa-icon" className="fa fa-trash" onClick={()=>this.onDeleteMessage(messageSelected)}/>
                          </div>
                          <div className="ic-print">
                            <ReactToPrint
                              trigger={() => <i id="id-fa-icon" className="fa fa-print"/>}
                              content={() => this.componentRef}
                              copyStyles={true}
                              pageStyle=".message-details {float: left; width: 100% !important; padding: 0 30px 30px;}"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="message-wrapper" style={{height: isWritingMessage ? "30%":"68%", maxHeight: isWritingMessage ? "180px":"400px"}}>
                      {this.renderMessagesPane(messageSelected)}
                    </div>
                  </div>
                  {
                    showMessageReplay[messageSelected.id] ?
                      <MessageReply newMessage={false} messageDisplay={messageDisplay} showOriginalSize={showOriginalSize} sendEmail={this.replyEmail} typeReply={typeReply} messageSelected={messageSelected}/>:
                      <div className="row-message options-reply-message">
                        <div>
                          <button
                            className="btn-message-replay"
                            onClick={() => {
                              const hasCarInfo = messageSelected.text.includes('About <b>')
                              const subject = hasCarInfo ? messageSelected.text.substring(9, messageSelected.text.indexOf('-')) : 'Replying message'
                              window.open(`mailto:${messageSelected.email}?subject=${subject}`)
                            }}
                          >
                            <ReactSVG beforeInjection={ svg => svg.classList.add("icon-reply")} src={pathServer.PATH_IMG + "icon/ic_reply.svg"}/> <span>Reply</span>
                          </button>
                          <button
                            className="btn-message-forward"
                            onClick={() => {
                              window.open(`mailto:${messageSelected.email}?subject=Forwarding message&body=${messageSelected.text}`)
                            }}
                          >
                            <ReactSVG beforeInjection={ svg => svg.classList.add("icon-forward")} src={pathServer.PATH_IMG + "icon/ic_forward.svg"} /> <span>Forward</span>
                          </button>
                        </div>
                      </div>
                  }
                </div> : null
            }
          </div>
        </div>
        {
          (this.state.showModal) ?
            <MessagesModal show={this.state.showModal}
                           onHide={this.handleHideModal.bind(this)}
                           sendEmail={this.replyEmail}
                           container={this}
                           dealerName={dealerName}/> : null
        }
        {
          showPopover && filterSelected !== null ?
            <Popover anchorEl={anchorEl}
                     child={childMessage}
                     classPopover="popover-box-message"
                     handleRequestClose={this.handleRequestClose}/> : null
        }
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    messages: state.messages,
    newFinanceApplicationsCounter: state.newFinanceApplicationsCounter,
    unreadMessagesCounter: state.unreadMessagesCounter,
    dealerName: state.dealerName
  }
};
export default connect(mapStateToProps, {loadMessages,setKeyword})(Message)
