import React, {Component} from 'react'
import {ENTITY_TYPES, INPUT_TYPES} from '../../../Constants.js'
import Outside from '../../util/Outside.jsx'
import {getErrorMessage} from '../../superusers/dealers'

function getInputClass(errors, key){
  let className = 'form-control';
  if(errors){
    if (errors[key])
      return "form-control has-error"
  }

  return className;
};

export default class AttributeProperties extends Component {

  constructor() {
    super();
    this.onChangeSetting      = this.onChangeSetting.bind(this);
    this.onChangeDropDownForm = this.onChangeDropDownForm.bind(this);
    this.handleChange         = this.handleChange.bind(this);
    this.state = {
      checkSettingOne: true,
      checkSettingTwo: true,
      showCommonFields: false,
      showAttributeOptions: false,
      nameAttributeOptions: '',
      attribute:{
        code:"",
        name:"",
        displayValue:"",
        typeAttribute:"",
        entityTypes:[]
      },
      dropDownTypeAttribute :{
        render:false,
        values: INPUT_TYPES
      },
      dropDownAudience :{
        render:false,
        values: ENTITY_TYPES
      },
      menuTabSelected: 'attributes'
    }
  }

  componentDidMount() {
    const {attribute} = this.props;
    if(attribute){
      this.setState({attribute:{...attribute}})
    }
  }

  componentWillReceiveProps(nextProps){
    const {attribute} = nextProps;
    if(attribute){
      this.setState({attribute:{...attribute}})
    }
  }

  onChangeDropDownForm(dropDown){
    let dropDownForm = this.state[dropDown];
    dropDownForm.render = !dropDownForm.render;
    this.setState({[dropDown]:dropDownForm})
  }

  closePopover(dropDown) {
    let dropDownClose = this.state[dropDown];
    dropDownClose.render = false;
    this.setState({[dropDown]: dropDownClose})
  }

  onSelectDropDown = (dropDown,value) => {
    let {attribute} = this.state;
    const indexFound = attribute.entityTypes.findIndex(entityType => { return entityType === value });
    if(indexFound !== -1)
      attribute.entityTypes.splice(indexFound,1);
    else
      attribute.entityTypes.push(value);

    this.setState({attribute:attribute},()=>{
      this.props.updateAttribute(attribute);
      //--this.onChangeDropDownForm(dropDown)
    })
  }

  handleChange(event) {
    if(this.props.deleteErrors)
      this.props.deleteErrors();
    let {attribute} = this.state;
    let attributeEdit = attribute;
    attributeEdit[event.target.name] = event.target.value;
    this.setState({attribute:attributeEdit});
    this.props.updateAttribute(attributeEdit);
  }

  onChangeSetting(type) {
    const value = this.state[type];
    this.setState({[type]: !value})
  }

  render(){
    const {attribute,dropDownAudience} = this.state;
    const {errors} = this.props;
    return(
      <div className="form-property">
        <div className="row-form">
          <div className="attribute-field">
            <span className="text-attribute-option">ATTRIBUTE NAME</span>
            <div className="form-input">
              <input className={ getInputClass(errors,"name")}
                     name="name"
                     placeholder="New attribute"
                     onChange={this.handleChange}
                     value={attribute.name}/>
              {
                errors && errors["name"]
                  ? <p className="help-block">{getErrorMessage("Name")}</p>
                  : null
              }
            </div>
          </div>
          <div className="attribute-field">
            <span className="text-attribute-option">DISPLAY VALUE</span>
            <div className="form-input">
              <input className={ getInputClass(errors,"displayValue")}
                     name="displayValue"
                     placeholder=""
                     onChange={(this.props.attribute.typeAttribute==="PARAGRAPH") ? null : this.handleChange}
                     style={{backgroundColor:(this.props.attribute.typeAttribute==="PARAGRAPH")?"#f3f3f3":"rgb(255, 255, 255)"}}
                     value={(this.props.attribute.typeAttribute==="PARAGRAPH")?"":attribute.displayValue} />
              {
                errors && errors["displayValue"]
                  ? <p className="help-block">{getErrorMessage("Display value")}</p>
                  : null
              }
            </div>
          </div>

        </div>
        <div className="row-form">
          <div className="attribute-field">
            <span className="text-attribute-option">ATTRIBUTE CODE</span>
            <div className="form-input">
              <input className={getInputClass(errors,"code")}
                     name="code"
                     placeholder=""
                     onChange={this.handleChange}
                     value={attribute.code}/>
              {
                errors && errors["code"]
                  ? <p className="help-block">{getErrorMessage("Code")}</p>
                  : null
              }
            </div>
          </div>
          <div className="attribute-field">
            <span className="text-attribute-option">AUDIENCE</span>
            <div className="btn-form-dp" style={{position:"relative"}}>
              <a className="btn-basic btn-inventory-dp-new" onClick={() => this.onChangeDropDownForm("dropDownAudience")}>
                {
                  attribute.entityTypes.length !== 0 ? <span>{attribute.entityTypes.join(",")}</span> : <span>Select</span>
                }
                {
                  (dropDownAudience.render) ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />
                }
              </a>
              {
                dropDownAudience.render ?
                  <Outside onClose={this.closePopover.bind(this, "dropDownAudience")}>
                    <div id="id-popover-inventory-dp-new" className="popover-inventory-dp-new">
                      <div className="popover-inventory-dp-sub">
                        <u>
                          {
                            dropDownAudience.values.map((op,index) => {
                              return(
                                <li key={index} className="change-submenu-item" onClick={() => this.onSelectDropDown("dropDownAudience",op.value)}>
                                  {op.value}
                                  {
                                    (attribute.entityTypes.filter(x => x === op.value).length > 0) ? <span /> : null
                                  }
                                </li>
                              )
                            })
                          }
                        </u>
                      </div>
                    </div>
                  </Outside>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

}