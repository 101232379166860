import React, {Component} from 'react'
import {siteColorAdmin, STATUS_DELETE, USERS_BY_PAGE} from '../../../Constants'
import {sortBy} from 'lodash/collection'
import {updateStatusAttributeApi} from '../../../Api.js'
import {Popover} from '../../util/ComponentUtils'
import {ReactSVG} from 'react-svg'
import {getPosition} from '../../../../common/Util'
import {
  imageCheckBoxList, imageDate,
  imageDynamicNumber, imageExpand, imageList, imageParagraph,
  imagePasswordHidden,
  imageSingleLine, imageSquare,
  imageTextArea,
  pathServer,
  SUCCESSFUL_CODE
} from '../../../../common/Constants'
import {DPPagination} from '../../../../common/DPPagination'

export default class AttributesList extends Component {

  constructor() {
    super();

    this.state = {
      attributeData:null,
      selectedAll: false,
      idsSelected: {},
      listName: {
        id: null,
        displayValue: "Sort By"
      },
      countMessageSelected: null,
      messageSelected: false,
      showMessageReplay: false,
      showDropDown: false,
      keyword:"",
      foundValue:null,
      optionsOrder:[
        {
          id: "id",
          displayValue: "Clear All"
        },
        {
          id:"name",
          displayValue:"Name"
        },
        {
          id:"code",
          displayValue:"Code"
        },
        {
          id:"typeAttribute",
          displayValue:"Type"
        },
        {
          id:"entityTypes",
          displayValue:"Audience"
        }],
      showOptions:false,
      anchorEl:"",
      itemsByPageQuantity:10,
      currentPage:1,
      editedItemPage:null
    }
  }

  renderCheckBox = (value, onChange, id, isSelected) => {
    return (
      <label key={id} className="container-check">
        <input type="checkbox"
               checked={value}
               onChange={onChange}/>
        <span className="checkmark" style={isSelected ? {border: "1.4px #4695d4 solid"} : null}/>
        {
          value ? <i className="fa fa-check i-check"/> : null
        }
      </label>
    )
  }

  componentWillMount() {
    let {attributeData,lastEditedItemPage} = this.props;
    this.setState({attributeData:attributeData,currentPage:lastEditedItemPage})
  }

  componentWillReceiveProps(nextProps) {
    let {attributeData} = nextProps;
    this.setState({attributeData:attributeData})
  }

  componentDidUpdate() {
    let {selectedAttribute} = this.props;
    if(selectedAttribute){
      const element = document.getElementById(selectedAttribute);
      if(element){
        element.scrollIntoView({behavior:"smooth", block:"center"});
        element.classList.add("row-costumer-hover");
        setTimeout(()=>{
          element.classList.remove("row-costumer-hover");
          this.props.setSelectedAttribute(null);
        }, 4000)
      }
    }
  }

  onChangeCheckAll = () => {
    const {formsPages, currentPage, idsSelected, selectedAll} = this.state;
    let countMessageSelected = 0;
    if (!selectedAll) {
      formsPages[currentPage - 1].forEach(form => {
        idsSelected[form.id] = true;
        countMessageSelected++;
      });
    } else {
      formsPages[currentPage - 1].forEach(form => {
        delete idsSelected[form.id];
      });
    }
    this.setState({selectedAll: !this.state.selectedAll, idsSelected: idsSelected, countMessageSelected: countMessageSelected})
  }

  closeHeaderFilter = () => {
    this.setState({showDropDown: false})
  }

  onChangeCheck(id) {
    let {idsSelected} = this.state;
    if (!idsSelected[id]) {
      idsSelected[id] = true;
    } else {
      delete idsSelected[id];
    }
    let countMessageSelected = 0;
    for (let key in idsSelected) {
      countMessageSelected++;
    }
    this.setState({idsSelected: idsSelected, countMessageSelected: countMessageSelected});
  }

  changeInputKeyword = (evt) => {
    this.setState({keyword:evt.target.value})
  }

  onChangeDropDown(id){
    let {showDropDown} = this.state;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };
    this.setState({showDropDown:!showDropDown, anchorEl: anchorEl})
  }

  onChangeSort(op){
    let attributeSort = sortBy(this.state.attributeData, [function(o) { return o[op.id]; }]);
    this.setState({attributeData:attributeSort, listName: op, showDropDown: false});
  }

  updateStatus = (attributeId) => {
    updateStatusAttributeApi(attributeId,STATUS_DELETE).then(({responseCode})=>{
      if (responseCode === SUCCESSFUL_CODE) {
        this.props.search("")
      }
    })
  }

  showIconAttribute = (type) => {
    switch (type) {
      case 'DATE':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageDate} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      case 'SELECT':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageExpand} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      case 'MULTI_SELECT':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageSquare} id="icon-attribute" className="form-field-img " alt=""/></span>
          </div>
        </td>);
      case 'PARAGRAPH':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageParagraph} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      case 'CHECKBOX':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageCheckBoxList} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      case 'LIST':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageList} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      case 'PASSWORD':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imagePasswordHidden} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      case 'SINGLE_TEXT':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageSingleLine} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      case 'DYNAMIC_NUMBER':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageDynamicNumber} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      case 'TEXT_AREA':
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span><img src={imageTextArea} id="icon-attribute" className="form-field-img" alt=""/></span>
          </div>
        </td>);
      default:
        return (<td className="attribute-col-detail-type">
          <div className="icon-message-attribute">
            <span/>
          </div>
        </td>);
    }
  }

  handleRequestOpen = (event) => {
    this.setState({showOptions:true,anchorEl:{offsetWidth:event.currentTarget.offsetWidth,offsetHeight:event.currentTarget.offsetHeight}})
  }

  handleRequestClose = () => {
    this.setState({showOptions:false,showPopoverStatus:false,roleSelected:"-1", showDropDown: false})
  }

  handleItemsByPage = (itemsByPageQuantity) => {
    this.setState({itemsByPageQuantity:itemsByPageQuantity,showOptions:false,currentPage:1},() => {
    })
  }

  handlePageChange = (nextPage,totalPages) => {
    let currentPage = nextPage;
    if(currentPage > totalPages) {
      currentPage = 1;
    }else if(currentPage === 0){
      currentPage = totalPages;
    }
    this.setState({currentPage:currentPage});
  }

  renderOrder(){
    let {optionsOrder,listName} = this.state;
    let options = [];

    optionsOrder.map((op, index) => {
      if(op.displayValue === listName.displayValue){
        options.push(
          <li key={index} name={op.id} className="item-selected" onClick={() => this.onChangeSort(op)}>
            {op.displayValue}
          </li>
        )
      } else {
        options.push(
          <li key={index} name={op.id} className="change-submenu-item" onClick={() => this.onChangeSort(op)}>
            {op.displayValue}
          </li>
        )
      }
    });

    return(
      <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {options}
          </u>
        </div>
      </div>
    )
  }

  render() {
    const {keyword, showDropDown, attributeData,listName,showOptions,anchorEl,itemsByPageQuantity,currentPage} = this.state;

    let totalPages = attributeData ? Math.ceil(attributeData.length/itemsByPageQuantity) : 1;
    let firstIndex = (this.state.itemsByPageQuantity ) * (this.state.currentPage - 1);
    let lastIndex = firstIndex + (this.state.itemsByPageQuantity );
    if(!attributeData) return null;
    let {onChange, search, onEdit, isLoading} = this.props;
    const childOrder = this.renderOrder();
    return (
      <div className="setting-container" style={{display: "block"}}>
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> : null
        }
        <div className="setting-form-header-header">
          <div className="row-message" style={{position: "relative"}}>
            <div className="form-setting-button" style={{width: "8%", paddingLeft: "30px"}}>
              <div onClick={() => onChange("new")}
                   className="format-setting-button-add">
                +
              </div>
            </div>
            <div className="btn-container-dp btn-container-attribute">
              <input className="form-control" placeholder="Keyword" onKeyUp={search.bind(this,keyword)} onKeyDown={this.changeInputKeyword}/>
            </div>
            <div className="btn-dp-form" style={{position: 'relative', marginRight:20}}>
              <div id="sortBy" className="btn-general-message-dp" onClick={this.onChangeDropDown.bind(this,"sortBy")} style={{width:130}}>
                <span>{(listName.displayValue==="Sort By" || listName.displayValue==="Clear All") ?
                      "Sort By": listName.displayValue}</span>
                {
                  (showDropDown) ?
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                }
              </div>
            </div>
            <div className="inventory-pagination" style={{position: "absolute",right: "10px", padding: "14px 10px 14px", fontSize:"12px", height: "100%"}}>
              <DPPagination
                optionsQuantityByPage={USERS_BY_PAGE}
                selectedItemsByPage={itemsByPageQuantity}
                handleChangeItemsByPage={this.handleItemsByPage}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={this.handlePageChange}
                siteColor={siteColorAdmin}
              />
            </div>
          </div>
        </div>
        <div className="table-form-setting">
          <table className="table">
            <thead style={{backgroundColor: "#EFEFEF"}}>
            <tr>
              <th className="attribute-col-type">Type</th>
              <th className="attribute-col-name">Name</th>
              <th className="attribute-col-code">CODE</th>
              <th className="attribute-col-audience">Audience</th>
              <th className="attribute-col-response">Response type</th>
              <th className="attribute-col-icons"/>
            </tr>
            </thead>
            <tbody>
            {
              attributeData.length !== 0 ?
                attributeData.slice(firstIndex,lastIndex).map((attribute, index) => {
                  let audiences = attribute.entityTypes.join(",");
                  return (
                    <tr id={attribute.code} key={index + 'tr'} className="rowCostumer row-attribute-setting">
                      {this.showIconAttribute(attribute.typeAttribute)}
                      <td key={index + 'name'}>{attribute.name}</td>
                      <td key={index + 'code'}>{attribute.code}</td>
                      <td key={index + 'audience'}>{audiences}</td>
                      <td key={index + 'type'}>{attribute.typeAttribute}</td>
                      <td className="attribute-col-detail-icons" key={index + 'td'}>
                        <div className="options">
                          <i style={{color: "#4ad991"}} className="fa fa-pencil fa-lg"
                             title="Edit" onClick={() => onEdit(attribute,currentPage)}/>
                          <i style={{color: "#d9534f"}} className="fa fa-trash fa-lg" onClick={this.updateStatus.bind(this ,attribute.id)}/>
                        </div>
                      </td>
                    </tr>
                  )
                }) : null
            }
            </tbody>
          </table>
        </div>
        {
          showDropDown ?
            <Popover anchorEl={anchorEl}
                     child={childOrder}
                     classPopover="popover-box-message"
                     handleRequestClose={this.handleRequestClose}/> : null
        }
      </div>
    )
  }
}