import React, {useState} from 'react'
import {findIndex} from 'lodash'
import classnames from 'classnames'
import {DPPopover} from './DPPopover'
import {ReactSVG} from 'react-svg'
import PropTypes from 'prop-types'
import DPCheckboxIcon from './DPCheckboxIcon'
import {EmptyFunc, pathServer} from './Constants'

require('./DPSelectMultipleDropDown.scss')

export const RenderBody = ({options, selectedOptionsId = [], onCheck}) =>  {

  let optionsToDisplay = [];
  options.forEach((option, index) => {
    const checked = findIndex(selectedOptionsId, (selectedOptionId) => {
      return selectedOptionId === option.id
    });
    optionsToDisplay.push(
      <div key={option.id} className={"content-check"}>
        <DPCheckboxIcon  id={`location-${index}`} label={option.name ?? option.displayValue} checked={(checked !== -1)} onCheck={() => onCheck(option.id)} iconColor={option.iconColor ?? null}/>
      </div>
    )
  });

  return (
    <div className="popover-body-locations">
      <div id="" className="options-locations">
        {optionsToDisplay}
      </div>
    </div>
  )
}

export const getPosition = (id, heightSelect= 34) => {
  const target = document.getElementById(id);
  return {
    x: target.offsetLeft,
    y: target.offsetTop + heightSelect,
    offsetWidth: target.offsetWidth
  }
}

const DPSelectMultipleDropDown = ({ displayValue, displayValueStyle, isRequired, selectStyle, textStyle,
                                    errorMessage, style, id, disabled, className, placeholder, options, selectedOptionsId}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});

  const handlePopover = (id,event) => {
    event.preventDefault()
    event.stopPropagation()

    const anchorEl = getPosition(id);

    setShowPopover(!showPopover)
    setAnchorEl(anchorEl)
  }
  const selectBorder = showPopover ? {borderRadius: '4px 4px 0 0'} : {}
  return(
    <div style={style} className={classnames(['dp-multiple-select-dropdown', className])}>
      <div>
        {
          displayValue && <label className="display-value" style={displayValueStyle}>{displayValue}</label>
        }
        {
          isRequired && <span className="label-error">*</span>
        }
      </div>
      <div style={{selectStyle, ...selectBorder}}
           className={errorMessage ? "select has-error" : disabled ? "select select-disabled" : "select"}
           id={id}
           onClick={!disabled ? (event) => handlePopover(id, event) : null}>
        <div className="container-label">
          <label className="select-text" style={textStyle}>
            {placeholder}
          </label>
        </div>
        <div className="container-icon" style={ {transform: showPopover ? 'rotate(180deg)' : 'none'}}>
          <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                    className='icon-star-review' />
        </div>
      </div>
      {
        errorMessage
          ? <p className="help-block">{errorMessage}</p>
          : null
      }
      {
        showPopover &&
        <DPPopover
          anchorEl={anchorEl}
          classNameWrapper="popover-for-select"
          handleRequestClose={() => setShowPopover(false)}>
          {<RenderBody options={options}  selectedOptionsId={selectedOptionsId}/>}
        </DPPopover>
      }
    </div>
  )
}

DPSelectMultipleDropDown.defaultProps = {
  onClick: EmptyFunc,
  placeholder: 'Select one',
  isRequired: false,
  disabled: false,
  displayValueStyle: null
}

DPSelectMultipleDropDown.propTypes = {
  className: PropTypes.string,
  displayValue: PropTypes.string,
  displayValueStyle: PropTypes.object,
  isRequired: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  selectedOptionsId: PropTypes.array,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  selectStyle: PropTypes.object,
  textStyle: PropTypes.object
}

export default DPSelectMultipleDropDown