import React from 'react'
import PropTypes from 'prop-types'
import {pathServer} from '../../common/Constants'
import {ReactSVG} from 'react-svg'

export const DPMessageBar = (props) => {
  const {containerClassName, label, icon, onClick} = props;
  return (
      <div className={`message-bar ${containerClassName}`}>
          <label dangerouslySetInnerHTML={{ __html: label }}></label>
          {
              icon ?
                  <ReactSVG
                      src={`${pathServer.PATH_IMG}icon/ic_close2.svg`}
                      beforeInjection={ svg => svg.classList.add('icon-close-svg')}
                      className="icon-close"
                      onClick={onClick}
                  /> : null
          }

      </div>
  )
}

DPMessageBar.defaultProps = {
  icon: false,
  onClick: null,
  containerClassName: '',
  label: '',
}

DPMessageBar.propTypes = {
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  icon: PropTypes.bool,
  onClick: PropTypes.func
}