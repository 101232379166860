import React, {Component} from 'react'
import {pathServer} from '../../../common/Constants'

export default class PageOnBuild extends Component{

  render(){
    const {height} = this.props;
    return(
      <div className="setting-container" style={{minHeight:height}}>
        <div className="page-on-build">
          <img src={`${pathServer.PATH_IMG}page-on-build.png`} />
        </div>
      </div>
    )
  }
}