import React, {Component} from 'react'

require('./ReviewContainer.scss')

class ReviewContainer extends Component {
  constructor(props) {
    super(props);
  }

  handleSkip = () => {
    const {handleReview, id } = this.props
    handleReview('')
  }

  render(){
    const {id, externalContent, bodyComponent, showOverlay} = this.props
    return(
      <React.Fragment>
        {
          showOverlay
          && <div className='overlap-background'/>
        }
        <div className={'review-module-container'}>
          <div className={'box-container'} style={showOverlay ? {zIndex: '101'} : {} }>
            <div className={'box-body'}>
              { bodyComponent ?? null}
            </div>
          </div>
          {
            showOverlay && externalContent
          }
        </div>
      </React.Fragment>
    )
  }
}

export  default ReviewContainer