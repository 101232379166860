import React, {Component} from 'react'
import AttributesList from './AttributesList.jsx'
import AttributeSetting from './AttributeSetting.jsx'
import {searchAttributes} from '../../../Api.js'
import {SUCCESSFUL_CODE} from "../../../../common/Constants";


export default class Attributes extends Component{

  constructor(){
    super();
    this.state = {
      checkSetting: false,
      showMultiSelectForm : false,
      attribute:null,
      typeEntity:"",
      attributeData: [],
      menuTabSelected:'attributes',
      selectedAttribute:null,
      lastEditedItemPage:1,
      isLoading: false
    }
  }

  componentWillMount(){
    this.search("")
  }

  search = (keyword, extraState = {}) => {
    this.setState({isLoading: true})
    searchAttributes([keyword,""]).then(({responseCode, data}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        this.setState({isLoading: false});
      }
      this.setState({...extraState, attributeData: data.attributes, isLoading: false});
    })
  }

  onChangeSetting = (type) => {
    const value = this.state[type];
    this.setState({[type]:!value})
  }

  onClickChangeMultiSelect = () => {
    let {showMultiSelectForm} = this.state;
    showMultiSelectForm = !showMultiSelectForm;
    this.setState({showMultiSelectForm: showMultiSelectForm})
  }

  onChangeMenu = (menuTabSelected) => {
    this.setState({menuTabSelected})
  }

  onEdit = (attribute,lastEditedItemPage) => {
    this.setState({
      attribute:attribute
      ,menuTabSelected:"edit"
      ,selectedAttribute:attribute.code
      ,lastEditedItemPage:lastEditedItemPage})
  }

  setSelectedAttribute = (code) => {
    this.setState({selectedAttribute:code});
  }

  onClose = () => {
    this.setState({menuTabSelected:"attributes",attribute:null,typeEntity:""},()=>{
      this.search("",{ selectedAttribute:this.state.selectedAttribute});
    })
  }

  render(){

    const {attributeData,attribute,typeEntity,menuTabSelected,selectedAttribute,lastEditedItemPage, isLoading} = this.state;

    return(
      (menuTabSelected === 'new' || menuTabSelected === 'edit') ?
        <AttributeSetting
          onChange={this.onChangeMenu}
          onClose={this.onClose}
          attribute={attribute}
          typeEntity={typeEntity}
          setSelectedAttribute={this.setSelectedAttribute}/>
        :
        <AttributesList
          attributeData={attributeData}
          onChange={this.onChangeMenu}
          search={this.search}
          onEdit={this.onEdit}
          lastEditedItemPage={lastEditedItemPage}
          selectedAttribute={selectedAttribute}
          setSelectedAttribute={this.setSelectedAttribute}
          isLoading={isLoading}/>
    )
  }
}