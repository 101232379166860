import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import Loader from '../../../common/UI/Loader'
import UpdatePlanLayout from './components/layout/UpdatePlanLayout'
import UpdatePlanMobileLayout from './components/layout/UpdatePlanMobileLayout'
import {loadLastOrder, loadSubscriptions} from '../../Api'
import {unstable_batchedUpdates} from 'react-dom'
import { SUCCESSFUL_CODE } from '../../../common/Constants'

export function getTeamMembersQuantity(teamMembersQuantity, prevWord = '') {
  return teamMembersQuantity > 0 ? `${prevWord} ${teamMembersQuantity}` : 'Unlimited'
}

const DashboardUpdatePlan = (props) => {
  const showSideBar = useSelector((state) => state.showSideBar)

  const [inventoryItems, setInventoryItems] = useState(undefined)
  const [features, setFeatures] = useState(undefined)
  const [individualFeatures, setIndividualFeatures] = useState(undefined)
  const [inventoryItemFeatures, setInventoryItemFeatures] = useState(undefined)
  const [selectedInventoryItems, setSelectedInventoryItems] = useState(undefined)
  const [order, setOrder] = useState(null)
  const [currentInventoryItemId, setCurrentInventoryItemId] = useState(null)
  const [paymentMethodMain, setPaymentMethodMain] = useState(null)
  const [nextInventoryItem, setNextInventoryItem] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadData = async () => {
      const {responseCode: responseCodeOrder, responseMessage: responseMessageOrder, data: dataOrder } = await loadLastOrder()
      let order = null
      let paymentMethod = null
      let subscriptions = []
      if (responseCodeOrder === SUCCESSFUL_CODE) {
        order = dataOrder.order
        paymentMethod = dataOrder.paymentMethod
        subscriptions = dataOrder.subscriptions
      } else {
        console.error(responseMessageOrder)
      }

      const {responseCode, responseMessage, data } = await loadSubscriptions()
      let _inventoryItems
      let _features
      let _inventoryItemFeatures
      let _individualFeatures
      let _selectedInventoryItems
      if (responseCode === SUCCESSFUL_CODE){
        //setCurrentInventoryItemId(subscriptions.length > 0 ? subscriptions[0].planId : "")
        _features = data.features.filter(x => { return !x.canPurchaseAlone})
        _individualFeatures = data.features.filter(x => { return x.canPurchaseAlone})
        _inventoryItems = data.inventoryItems
        _inventoryItemFeatures = data.inventoryItemFeatures
        _selectedInventoryItems = data.selectedInventoryItems
      } else {
        console.error(responseMessage)
      }

      unstable_batchedUpdates(() => {
        setOrder(order)
        setPaymentMethodMain(paymentMethod)
        setCurrentInventoryItemId(subscriptions.length > 0 ? subscriptions[0].planId : "")
        setInventoryItems(_inventoryItems)
        setFeatures(_features)
        setIndividualFeatures(_individualFeatures)
        setInventoryItemFeatures(_inventoryItemFeatures)
        setSelectedInventoryItems(_selectedInventoryItems)
        setNextInventoryItem(null)
        setIsLoading(false)
      })
    }

    if (showSideBar) {
      let container = window.document.getElementById("container");
      container.className += ' container-collapse';
    }

    loadData().then()
  }, [setInventoryItems])

  const defaultProps = {
    inventoryItems,
    selectedInventoryItems,
    features,
    inventoryItemFeatures,
    individualFeatures,
    order,
    paymentMethodMain,
    nextInventoryItem,
    setNextInventoryItem,
    currentInventoryItemId
  }

  return (
    <div id="container" className="container-component" style={{height: props.height}}>
      {isLoading ? <Loader/> : null}

      <div className="dashboard-header" >
        <div className="title-dashboard">
          <h2 className={'title-text-info'}>Dashboard > Subscriptions > Update Plan</h2>
        </div>
      </div>

      {
        window.innerWidth <= 412
          ? <UpdatePlanMobileLayout {...defaultProps}/>
          : <UpdatePlanLayout {...defaultProps}/>

      }

    </div>
  )
}

export default DashboardUpdatePlan