import React, {Component} from 'react'
import {InputTypes, mediaTypes} from '../../../Constants'
import {Carousel, CarouselItem} from 'react-bootstrap'
import {chunk, isEmpty} from 'lodash'
import {ReactSVG} from 'react-svg'
import LayoutFinanceViewForm from '../../../../common/LayoutFinanceViewForm.jsx'
import ReactPlayer from 'react-player'
import {getBaseTemplateCode, getHoverButton, getMainImageURL} from '../../../../common/Util'
import LayoutBanner from '../../../../common/template/LayoutBanner'
import LayoutFinance from '../../../../common/template/LayoutFinance'
import {loadFormByCode} from '../../../Api'
import {DEFAULT_THEME_ID} from './SettingStyle'
import {getSiteColor, getClassContainerEasyNavigation} from '../../../../common/Util'
import {
  BOX_BACKGROUND_COLOR, BOX_BORDER_COLOR, BUTTON_TEXT_COLOR, NAVBAR_BACKGROUND_COLOR, NAVBAR_TEXT_COLOR, PRIMARY_COLOR,
  SECONDARY_COLOR, SITE_BACKGROUND_COLOR, TEXT_COLOR, DARK_THEME, SECTION_FINANCE_ID, PAGE_INVENTORY_ID,
  SECTION_TESTIMONIAL_ID, SECTION_CONTACT_US_ID, PAGE_ABOUT_US_ID, PAGE_FINANCE_ID, PAGE_GENERAL_SETTING, PAGE_HOME_ID,
  pathServer, SECTION_INVENTORY_ID, SECTION_LOCATION_ID, CAROUSEL_INTERVAL, CAR_IMAGE_HEIGHT_PERCENT,
  TEMPLATE_CODE_MODERN_VIEW_3, TEMPLATE_CODE_MODERN_VIEW_2, TEMPLATE_CODE_MODERN_VIEW, TEMPLATE_CODE_EASY_NAVIGATION_C,
  TEMPLATE_CODE_EASY_NAVIGATION_B, TEMPLATE_CODE_EASY_NAVIGATION, CURRENT_THEME, LIGHT_THEME, CONTROL_BACKGROUND_COLOR, EmptyFunc
} from '../../../../common/Constants'
import {LIST_FONT_FAMILY} from './SettingStyle'
import LayoutHeader from '../../../../common/template/LayoutHeader'
import LayoutFooter from '../../../../common/template/LayoutFooter'
import LayoutLocation from '../../../../common/template/LayoutLocation'
import LayoutGetInTouch from '../../../../common/template/LayoutGetInTouch'
import DPCustomerTestimonial from '../../../../common/DPCustomerTestimonial'
import LayoutAboutUsGoal from '../../../../common/template/LayoutAboutUsGoal'
import { Slider} from '../../../../common/ComponentUtils'
import LayoutCar from '../../../../common/template/LayoutCar'
import LayoutInventory from '../../../../common/template/LayoutInventory'
import get from 'lodash/get'
import DPBackToTop from '../../../../common/DPBackToTop'
// import DPCarousel from '../../../../common/DPCarousel'
import DPCarousel from '../../../../common/genericComponents/DPCarousel'
import {LayoutCustomerHorizontalTestimonials} from '../../../../common/LayoutCustomerHorizontalTestimonials'
import {ARROWS_BOTTOM, HORIZONTAL_CAROUSEL, SEGMENTS_BAR} from '../../../../common/genericComponents/DPCarousel'

const testimonials = [
  {
    userName: 'Johnny Apolinario',
    userPicture: pathServer.PATH_IMG + 'image2.png',
    content: 'I live in Colorado and found a car that was in great condition and exceeded my expectations through FB marketplace! 👍',
    styleContainer: 'carousel-content-0',
    styleImage: 'carousel-content-image-0',
    styleText: 'carousel-content-text-0',
    socialNetwork: 'Facebook',
  },
  {
    userName: 'Ronaldo Medrano',
    userPicture: pathServer.PATH_IMG + 'image3.png',
    content: 'I bought my first car here and they made things so easy and they are honest right from the start, definitely recommended!',
    styleContainer: 'carousel-content-1',
    styleImage: 'carousel-content-image-1',
    styleText: 'carousel-content-text-1',
    socialNetwork: 'Facebook',
  },
  {
    userName: 'Roger Aponte',
    userPicture: pathServer.PATH_IMG + 'image4.png',
    content: 'I bought my first car here and they made things so easy and they are honest right from the start, definitely recommended!',
    styleContainer: 'carousel-content-2',
    styleImage: 'carousel-content-image-2',
    styleText: 'carousel-content-text-2',
    socialNetwork: 'Facebook',
  },
  {
    userName: 'Richard Palomino',
    userPicture: '',
    content: 'I bought my first car here and they made things so easy and they are honest right from the start, definitely recommended!',
    styleContainer: 'carousel-content-3',
    styleImage: 'carousel-content-image-3',
    styleText: 'carousel-content-text-3',
    socialNetwork: 'Facebook',
  },
  {
    userName: 'Julio Camargo',
    userPicture: '',
    content: 'I bought my first car here and they made things so easy and they are honest right from the start, definitely recommended!',
    styleContainer: 'carousel-content-4',
    styleImage: 'carousel-content-image-4',
    styleText: 'carousel-content-text-4',
    socialNetwork: 'Facebook',
  },
  {
    userName: 'Denins Aguilar',
    userPicture: '',
    content: 'I bought my first car here and they made things so easy and they are honest right from the start, definitely recommended!',
    styleContainer: 'carousel-content-5',
    styleImage: 'carousel-content-image-5',
    styleText: 'carousel-content-text-5',
    socialNetwork: 'Facebook',
  }
]

const commonCustomerInfo = {
  experience: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est eopksio laborum.\n' +
    '                    Sed ut perspiciatis unde omnis istpoe natus error sit voluptatem accusantium doloremque eopsloi laudantium, totam rem aperiam, eaque ipsa quae',
  styleContainer: '',
  styleImage: '',
  styleText: ''
}

const customers = [
  {
    id: '0',
    name: 'Carlos Bravo',
    image: pathServer.PATH_IMG + 'image4.png',
    ...commonCustomerInfo
  },
  {
    id: '1',
    name: 'Lisset Bolaños',
    image: pathServer.PATH_IMG + 'image3.png',
    ...commonCustomerInfo
  },
  {
    id: '2',
    name: 'Gustavo Chinarro',
    image: pathServer.PATH_IMG + 'image2.png',
    ...commonCustomerInfo
  },
  {
    id: '3',
    name: 'Maria Fasanando',
    image: pathServer.PATH_IMG + 'image3.png',
    ...commonCustomerInfo
  },
  {
    id: '4',
    name: 'Jhonatan Cahuana',
    image: pathServer.PATH_IMG + 'image4.png',
    ...commonCustomerInfo
  },
  {
    id: '5',
    name: 'Diego Álvarez',
    image: pathServer.PATH_IMG + 'image2.png',
    ...commonCustomerInfo
  }
]

const LayoutDealerCarousel = ({primaryColor, dealerMedias, changeVideo, videoUrl, indexVideoSelected}) => {
  const carousel = window.document.getElementById("layoutDealerCarousel");
  let carouselHeight = null;
  if (carousel) {
    carouselHeight = (carousel.offsetWidth / 2) * CAR_IMAGE_HEIGHT_PERCENT;
  } else {
    carouselHeight = "260px";
  }
  const listStyles = [
    {
      styleContainer: 'carousel-content-0',
      styleImage: 'carousel-content-image-0',
      styleText: 'carousel-content-text-0'
    },
    {
      styleContainer: 'carousel-content-1',
      styleImage: 'carousel-content-image-1',
      styleText: 'carousel-content-text-1'
    },
    {
      styleContainer: 'carousel-content-2',
      styleImage: 'carousel-content-image-2',
      styleText: 'carousel-content-text-2'
    },
    {
      styleContainer: 'carousel-content-3',
      styleImage: 'carousel-content-image-3',
      styleText: 'carousel-content-text-3'
    },
    {
      styleContainer: 'carousel-content-4',
      styleImage: 'carousel-content-image-4',
      styleText: 'carousel-content-text-4'
    },
  ];

  if (listStyles.length === dealerMedias.length || dealerMedias.length > listStyles.length) {
    listStyles.forEach((style, index) => {
      dealerMedias[index].styleContainer = style.styleContainer;
      dealerMedias[index].styleImage = style.styleImage;
      dealerMedias[index].styleText = style.styleText;
    });
  }

  if (dealerMedias.length < listStyles.length) {
    dealerMedias.forEach((customer, index) => {
      customer.styleContainer = listStyles[index].styleContainer;
      customer.styleImage = listStyles[index].styleImage;
      customer.styleText = listStyles[index].styleText;
    })
  }

  if (dealerMedias.length === 1) {
    dealerMedias[0].styleContainer = "only-one-image";
  }

  let indexVideo = -1;

  return (
    <div id="layoutDealerCarousel" className="layout-dealer-carousel"
         style={{height: carouselHeight ? carouselHeight : null}}>
      {
        dealerMedias.length !== 0 ?
          dealerMedias.map((dealerImage, index) => {
            if (dealerImage.mediaType === mediaTypes.VIDEO)
              indexVideo += 1;
            return (
              (dealerImage.styleContainer && dealerImage.styleContainer !== '') ?
                <div key={index} className={dealerImage.styleContainer}
                     onClick={() => changeVideo(dealerImage, indexVideo)}>
                  <div className="content-image">
                    {
                      (dealerImage.mediaType === mediaTypes.IMAGE) ?
                        <img id={'image' + index} src={dealerImage.url}/> :
                        (videoUrl && indexVideo === indexVideoSelected) ?
                          <img key={index} src={videoUrl} className="current-image"/> :
                          <ReactPlayer id={'image' + index}
                                       url={dealerImage.url}
                                       width="100%"
                                       height="100%"
                                       light={true}
                                       playing={false}/>
                    }
                  </div>
                </div> : null
            )
          })
          : null
      }
      <div id="nextDP" className="content-next-back content-back"
           style={{display: dealerMedias.length < 3 ? "none" : "flex"}}>
        <div id="semiDownDP" className="semi-circle-back" style={{border: "1px solid " + primaryColor}}>
          <a>
            <i id="iconDownDP" className="fa fa-chevron-right" style={{color: primaryColor}}/>
          </a>
        </div>
      </div>
      <div id="backDP" className="content-next-back content-next"
           style={{display: dealerMedias.length < 3 ? "none" : "flex"}}>
        <div id="semiUpDP" className="semi-circle-next" style={{border: "1px solid " + primaryColor}}>
          <a>
            <i id="iconUpDP" className="fa fa-chevron-right" style={{color: primaryColor}}/>
          </a>
        </div>
      </div>
    </div>
  )
};

const LayoutDealerCarousel5 = ({dealerMedias, mediaSelected, indexVideoSelected, videoUrl, changeVideo}) => {

  mediaSelected = mediaSelected ? mediaSelected : dealerMedias[0];
  let indexVideo5 = -1;
  let listIndexVideos = [];
  return (
    <div className="layout-dealer-carousel">
      <div id="carImageSelected" className="car-image-selected" style={!mediaSelected ? {width: '100%'} : {}}>
        {
          mediaSelected ?
            (mediaSelected.mediaType === mediaTypes.IMAGE) ?
              <img src={mediaSelected.url}/> :
              <ReactPlayer url={mediaSelected.url}
                           width="100%"
                           height="100%"
                           controls={true}
                           playing={false}/>
            :
            <img src={InputTypes.DEFAULT_IMAGE_URL}/>

        }
      </div>
      <div id="carImageGallery5" className="car-image-gallery">
        {
          dealerMedias.map((dealerMedia, index) => {
            if (dealerMedia.mediaType === mediaTypes.VIDEO) {
              indexVideo5 += 1;
              listIndexVideos[index] = indexVideo5;
            }
            return (
              <div key={index} className="content-image-media-5"
                   onClick={() => changeVideo(dealerMedia, listIndexVideos[index])}>
                {
                  dealerMedia.mediaType === mediaTypes.IMAGE ?
                    <img id={'image' + index} src={dealerMedia.url}/> :
                    (videoUrl && (indexVideoSelected === indexVideo5)) ?
                      <img src={videoUrl}/> :
                      <ReactPlayer id="videoThumbnail"
                                   url={dealerMedia.url}
                                   width="100%"
                                   height="100%"
                                   light={true}
                                   playing={false}/>

                }
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

const LayoutCustomerTestimonial = ({ primaryColor }) => {

  const listStyles = [
    {
      styleContainer: 'carousel-content-0',
      styleImage: 'carousel-content-image-0',
      styleText: 'carousel-content-text-0'
    },
    {
      styleContainer: 'carousel-content-1',
      styleImage: 'carousel-content-image-1',
      styleText: 'carousel-content-text-1'
    },
    {
      styleContainer: 'carousel-content-2',
      styleImage: 'carousel-content-image-2',
      styleText: 'carousel-content-text-2'
    },
    {
      styleContainer: 'carousel-content-3',
      styleImage: 'carousel-content-image-3',
      styleText: 'carousel-content-text-3'
    },
    {
      styleContainer: 'carousel-content-4',
      styleImage: 'carousel-content-image-4',
      styleText: 'carousel-content-text-4'
    },
    {
      styleContainer: 'carousel-content-5',
      styleImage: 'carousel-content-image-5',
      styleText: 'carousel-content-text-5'
    }
  ];

  if (listStyles.length === customers.length || customers.length > listStyles.length) {
    listStyles.forEach((style, index) => {
      customers[index].styleContainer = style.styleContainer;
      customers[index].styleImage = style.styleImage;
      customers[index].styleText = style.styleText;
    })
  }

  if (customers.length < listStyles.length) {
    customers.forEach((customer, index) => {
      customer.styleContainer = listStyles[index].styleContainer;
      customer.styleImage = listStyles[index].styleImage;
      customer.styleText = listStyles[index].styleText;
    })
  }
  return (
    <div className="layout-customer-testimonial">
      {
        customers.length !== 0 ?
          customers.map((customer, index) => {
            return (
              customer.styleContainer !== '' ?
                <div key={index} className={customer.styleContainer}>
                  <div className={customer.styleText}>
                    <div className={customer.styleImage}>
                      <img src={customer.image} alt=""/>
                    </div>
                    <div>
                      <p className="label-experience">
                        {customer.experience}
                      </p>
                    </div>
                    <div>
                      <h4>{customer.name}</h4>
                    </div>
                    <div className="carousel-content-social-network">
                      <a><i className="fa fa-facebook icon-facebook"/></a>
                      <a><i className="fa fa-instagram icon-instagram"/></a>
                      <a><i className="	fa fa-twitter icon-twitter"/></a>
                    </div>
                  </div>
                </div> : null)
          })
          : null
      }
      <div id="upDown" className="content-up-down">
        <div id="semiUp" className="semi-circle-up" style={{border: "1px solid " + primaryColor}}>
          <a>
            <i id="iconUpArrow" className="fa fa-chevron-up up" style={{color: primaryColor}}/>
          </a>
        </div>
        <div id="semiDown" className="semi-circle-down" style={{border: "1px solid " + primaryColor}}>
          <a>
            <i id="iconDownArrow" className="fa fa-chevron-up down" style={{color: primaryColor}}/>
          </a>
        </div>
      </div>
    </div>
  )
};

const LayoutCarItemCarousel = ({car, primaryColor, indexCar}) => {
  const img = getMainImageURL(car);
  let miles = Math.round(car.mileage / 1000);
  let trans = car["car.transmission"] ? car["car.transmission"].attributeValue : "";
  let trimCar = car["car.trim"] ? car["car.trim"].attributeValue : "";
  return (
    <div id={"carItem" + indexCar} className='car-item'>
      <div className="container-car-image">
        <img src={img}/>
      </div>
      <div id={"carItemDetails" + indexCar} className="car-item-details">
        <div className="row-details">
          <h5>{car.makeName + ' ' + car.modelName + ' ' + car.year}</h5>
        </div>
        <div className="row-details">
          <div className="btn-car-content">
            <div className="btn-car-info">
              {trimCar}
            </div>
          </div>
          <div className="btn-car-content">
            <div className="btn-car-info">
              {trans}
            </div>
          </div>
          <div className="btn-car-content">
            <div className="btn-car-info">
              {miles + " M"}
            </div>
          </div>
        </div>
        <div className="row-details" style={{padding: 0}}>
          <div className="btn-content-details">
            <a id={"btnDetails" + indexCar} className="btn btn-details" style={{color: primaryColor}}>DETAILS</a>
          </div>
        </div>
      </div>
    </div>
  )
};

function getCarCarousel5(cars, carsByCarouselItem, primaryColor) {
  const carGroups = chunk(cars, carsByCarouselItem);
  return carGroups.map((carGroup, groupIndex) => (
    <CarouselItem key={"carouselItem" + groupIndex}>
      <div className="row-carousel">
        {
          carGroup.map((car, indexCar) => (
            <div key={indexCar} className={"col-car" + carsByCarouselItem}>
              <LayoutCarItemCarousel car={car} primaryColor={primaryColor} indexCar={indexCar}/>
            </div>
          ))
        }
      </div>
    </CarouselItem>
  ))
}

const LayoutCarCarousel = ({cars, carsByCarouselItem, primaryColor, siteColor = {}}) => {
  let carouselItems = getCarCarousel5(cars, carsByCarouselItem, primaryColor);
  const prevIcon = (
    <div id="scLef" className="semi-circle-carousel sc-left" style={{borderColor: primaryColor}}>
      <i id="iconLeft" className="fa fa-angle-left" style={{color: primaryColor}}/>
    </div>
  );
  const nextIcon = (
    <div id="scRight" className="semi-circle-carousel sc-right" style={{borderColor: primaryColor}}>
      <i id="iconRight" className="fa fa-angle-right" style={{color: primaryColor}}/>
    </div>
  );
  return (
    <div id="carCarousel" className="car-carousel" style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
      <Carousel id="carCarouselBody"
                className="carousel-fade"
                interval={CAROUSEL_INTERVAL}
                prevIcon={prevIcon}
                nextIcon={nextIcon}>
        {carouselItems}
      </Carousel>
    </div>
  )
};

function calculateHeightToImage(quantity) {
  let carImageSelected = document.getElementById("carImageSelected");
  const carImageGallery = document.getElementById("carImageGallery5");
  const contentImage = document.getElementsByClassName("content-image-media-5");

  if (carImageSelected)
    carImageSelected.style.height = carImageSelected.offsetWidth * CAR_IMAGE_HEIGHT_PERCENT + "px";

  if (carImageGallery) {
    const heightGallery = (carImageGallery.offsetWidth / quantity) * CAR_IMAGE_HEIGHT_PERCENT + "px";
    for (let i = 0; i < contentImage.length; i++) {
      contentImage[i].style.height = heightGallery;
    }
  }
}

const getBackgroundImage = template => {
  const backgroundImages = {
    [TEMPLATE_CODE_EASY_NAVIGATION]: 'mountains-nature.jpg',
    [TEMPLATE_CODE_EASY_NAVIGATION_B]: 'snow-landscape.jpg',
    [TEMPLATE_CODE_EASY_NAVIGATION_C]: 'natural-forest.jpg',
    [TEMPLATE_CODE_MODERN_VIEW]: 'desert_template5.jpg',
    [TEMPLATE_CODE_MODERN_VIEW_2]: 'cloudy-landscape.jpg',
    [TEMPLATE_CODE_MODERN_VIEW_3]: 'mountains-forest.jpg',
  }
  return `${pathServer.PATH_IMG}${backgroundImages[template]}`
}

function setFontFamily(typography) {
  const fontId = typography?.title || "openSans"
  const fontSelected = LIST_FONT_FAMILY.find(x => x.id === fontId);
  const fontFamily = isEmpty(fontSelected) ? "'Open Sans', sans-serif" : fontSelected.displayValue

  const templateViewPrevious = document.getElementById("templateViewPrevious");
  if (templateViewPrevious)
    templateViewPrevious.style.fontFamily = fontFamily
}

export default class TemplateViewPreview extends Component {

  componentDidMount() {
    this.handleHover();
    const {generalSetting} = this.props;
    const siteColor = getSiteColor(generalSetting)
  }

  componentDidUpdate() {
    const {generalSetting} = this.props;
    const siteColor = getSiteColor(generalSetting)
    this.handleHover();
    calculateHeightToImage(2);
    setFontFamily(generalSetting.typography)
  }

  handleHover() {
    const {generalSetting, menuSelected, view, cars} = this.props;
    const siteColor = getSiteColor(generalSetting)
    const {
      [PRIMARY_COLOR]: primaryColor, [BUTTON_TEXT_COLOR]: buttonTextColor, [NAVBAR_TEXT_COLOR]: navigationBarText,
      [NAVBAR_BACKGROUND_COLOR]: menuColor
    } = siteColor

    if (view === PAGE_HOME_ID || view === PAGE_GENERAL_SETTING) {
      this.upDownHover("semiUp", "iconUp", primaryColor, buttonTextColor);
      this.upDownHover("semiDown", "iconDown", primaryColor, buttonTextColor);

      const btnSearch = document.getElementById("btnSearch");
      const btnSearchAdvanced = document.getElementById("btnSearchAdvanced");
      this.handleHoverBanner($(btnSearch), primaryColor);
      this.handleHoverBanner($(btnSearchAdvanced), primaryColor);

      const menu = generalSetting.header.menu;
      menu.forEach(m => {
        let idMenu = window.document.getElementById(m.id);
        if (idMenu) {
          if (m.id === menuSelected) {
            idMenu.style.color = primaryColor;
            idMenu.style.border = "1px solid " + primaryColor;
            idMenu.style.borderRadius = "20px";
          } else {
            let el = $(idMenu);
            idMenu.style.color = navigationBarText;
            idMenu.style.border = "0px";
            el.hover(function () {
              el.css("color", primaryColor);
            }, function () {
              el.css("color", navigationBarText);
            });
          }
        }

        let idMenuFooter = window.document.getElementById(m.id + "footer");
        if (m.id === menuSelected) {
          idMenuFooter.style.color = primaryColor;
        } else {
          let el = $(idMenuFooter);
          el.hover(function () {
            el.css("color", primaryColor);
          }, function () {
            el.css("color", menuColor);
          });
        }
      });
    } else if (view === PAGE_FINANCE_ID) {
      //todo
    } else if (view === PAGE_ABOUT_US_ID) {
      this.handleHoverAboutUs(cars, menuColor, primaryColor);
    }
    let menuLocation = document.getElementById("menuLocation5");
    if (menuLocation)
      menuLocation.style.border = "1px " + navigationBarText + " solid"
  }

  handleHoverAboutUs(cars, menuColor, primaryColor) {
    let navAboutUs = document.getElementById("aboutUs");
    navAboutUs.style.color = primaryColor;

    let navAboutSection = document.getElementById("menu-header-li");

    let el = $(navAboutSection);
    el.hover(function () {
      el.css("color", primaryColor);
    }, function () {
      el.css("color", menuColor);
    });

    let carItemDetails;
    let btnDetails;
    cars.forEach((car, index) => {
      let carItem = document.getElementById("carItem" + index);
      let elItem = $(carItem);
      elItem.hover(function () {
        carItemDetails = document.getElementById("carItemDetails" + index);
        carItemDetails.style.display = "flex";
        carItemDetails.style.backgroundColor = primaryColor;

        btnDetails = window.document.getElementById("btnDetails" + index);
        btnDetails.style.color = primaryColor;
      }, function () {
        carItemDetails = document.getElementById("carItemDetails" + index);
        carItemDetails.style.display = "none"
      });
    });
  }

  upDownHover(idSemi, idIcon, primaryColor, buttonTextColor) {
    let semiUpDown = document.getElementById(idSemi);
    let elUpDown = $(semiUpDown);
    let icon;
    elUpDown.hover(function () {
      elUpDown.css("background-color", primaryColor);
      elUpDown.css("border", `1px solid ${primaryColor}`);
      icon = document.getElementById(idIcon);
      icon.style.color = buttonTextColor;
    }, function () {
      elUpDown.css("background-color", primaryColor);
      elUpDown.css("border", `1px solid ${primaryColor}`);
      icon = document.getElementById(idIcon);
      icon.style.color = buttonTextColor;
    });
  }

  handleHoverBanner(el, primaryColor) {
    el.hover(function () {
      el.css("color", "#ffffff");
      el.css("background-color", primaryColor);
    }, function () {
      el.css("color", primaryColor);
      el.css("background-color", "#ffffff");
    });
  }

  getView(
    page, locations, cars, generalSetting, view, menuSelected, dealerMedias, changeVideo, indexVideoSelected, videoUrl,
    mediaSelected, sectionContactUs, templateCode, siteColor, sectionFinance, pages, dealerName
  ) {
    let viewSec
    const { currentTheme = DEFAULT_THEME_ID, footer, socialMedia, site = {}, banner, header } = generalSetting
    const { maxHeight: maxHeightLogo, logo: siteLogo } = site
    const { backgroundFinance, backgroundMain: backgroundMainBanner } = banner
    const { menu } = header
    const { primary: primaryColor, secondary: secondaryColor,
      [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
      [CONTROL_BACKGROUND_COLOR] : controlBackgroundColor,
      [SITE_BACKGROUND_COLOR]: backgroundColor,
      [TEXT_COLOR]: textColor
    } = siteColor

    const imgBackground = backgroundFinance ? backgroundFinance?.url : pathServer.PATH_IMG + 'landscape.jpg'
    const backgroundMain = backgroundMainBanner?.url !== '' ? backgroundMainBanner.url : getBackgroundImage(templateCode)

    const classTemplateContainer = getClassContainerEasyNavigation(templateCode, 'layout-inventory-container')
    const sectionTestimonials = page.sections.find(sec => sec.id === SECTION_TESTIMONIAL_ID)
    const homePage = pages.find(page => page.id === PAGE_HOME_ID)
    const testimonialSectionHome = homePage.sections.find(sec => sec.id === SECTION_TESTIMONIAL_ID)
    const hideTestimonialSection = !!testimonialSectionHome.hideSection
    const logo = siteLogo !== '' ? siteLogo : pathServer.PATH_IMG + 'upload-logo.png'
    const financeSectionHome = homePage.sections.find(sec => sec.id === SECTION_FINANCE_ID)
    const hideFinanceSection = !!financeSectionHome.hideSection

    const sectionInventory = page.sections.find(sec => sec.id === SECTION_INVENTORY_ID)
    const sectionLocation = page.sections.find(sec => sec.id === SECTION_LOCATION_ID)

    const customBackground = currentTheme === LIGHT_THEME ? "#FFFFFF" : "#162335" //temporal until the colors in the setting style are defined
    const customBackgroundContrast = currentTheme === LIGHT_THEME ? "#F5F6FA" : "#0B1524" //temporal until the colors in the setting style are defined

    const layoutAdminBanner = (
      <LayoutBanner
        slogan={generalSetting.banner.slogan}
        backgroundMain={templateCode === TEMPLATE_CODE_EASY_NAVIGATION_C ? '' : backgroundMain}
        templateCode={templateCode}
        siteColor={templateCode === TEMPLATE_CODE_EASY_NAVIGATION_B ? { ...siteColor, boxBackground: siteColor.siteBackground } : siteColor}
        isAdminTemplate
      />
    )

    const leftSlider = (
      <Slider
        contentId={'backT'}
        semiId={'semiUp'}
        iconId={'iconUpArrow'}
        semiClass={'semi-circle-up'}
        iconClass={'up'}
        buttonTextColor={siteColor[BUTTON_TEXT_COLOR]}
        primaryColor={siteColor[PRIMARY_COLOR]}
      />
    )

    const rightSlider = (
      <Slider
        contentId={'nextT'}
        semiId={'semiDown'}
        iconId={'iconDownArrow'}
        semiClass={'semi-circle-down'}
        iconClass={'down'}
        buttonTextColor={siteColor[BUTTON_TEXT_COLOR]}
        primaryColor={siteColor[PRIMARY_COLOR]}
      />
    )

    const layoutHeader = (menuSelected) => (
      <LayoutHeader
        menu={menu}
        logo={logo}
        maxHeight={maxHeightLogo}
        locations={locations}
        locationId={locations[0]?.locationId}
        siteColor={siteColor}
        baseTemplateCode={getBaseTemplateCode(templateCode)}
        menuSelected={menuSelected}
        isAdminTemplate
      />
    )

    const layoutCar = (
      <LayoutCar
        carsInformation={cars}
        sectionInventory={sectionInventory}
        siteColor={siteColor}
        templateCode={templateCode}
        isAdminTemplate={true}
      />
    )

    const layoutFinance = (currTheme, imgBackground) => (
      <LayoutFinance
        section={sectionFinance}
        siteColor={siteColor}
        templateCode={templateCode}
        currentTheme={currTheme}
        src={imgBackground}
        isAdminTemplate={true}
      />
    )

    const layoutLocation = (
      <LayoutLocation
        sectionLocation={sectionLocation}
        siteColor={siteColor}
        locations={locations}
        selectedLocationId={locations[0]?.locationId}
        templateCode={templateCode}
        baseTemplateCode={getBaseTemplateCode(templateCode)}
        isAdminTemplate={true}
      />
    )

    const layoutGetInTouch = (
      <LayoutGetInTouch
        templateCode={templateCode}
        title={sectionContactUs.title}
        siteColor={siteColor}
        section={sectionContactUs}
        readOnly
      />
    )

    const layoutFooter = (
      <LayoutFooter
        siteColor={siteColor}
        backgroundMain={backgroundMain}
        footer={footer}
        socialMedia={socialMedia ? socialMedia : {}}
        templateCode={templateCode}
        isAdmin={true}
      />
    )

    const layoutBackToTop = (
      <DPBackToTop
        siteColor={siteColor}
        templateCode={templateCode}
        isAdmin={true}
      />
    )

    const layoutFinanceViewForm = (borderColor, marginB) => (
      <LayoutFinanceViewForm
        primaryColor={primaryColor}
        formView={page.formView}
        readOnly
        borderColor={borderColor}
        loadFormByCodeAdmin={loadFormByCode}
        siteColor={siteColor}
        styleWrapper={marginB ? { marginBottom: marginB } : {}}
        templateCode={templateCode}
      />
    )

    const ItemDealerMedia = ({item}) => {
      return(
        <img className="dealer-image-item" src={item.url || pathServer.PATH_IMG +`carImageComingSoon.jpg` }/>
      )
    }

    const ItemTestimonial = ({item}) => {
      return (
        <div className={"section-testimonial"} style={{backgroundColor: customBackgroundContrast}}>
          <div className="row-customer">
            <div className="testimonial-content-5 item-a" style={{color: textColor}}>
              {item.content}
            </div>
            <div className="col-image item-b">
              <div className="box-image">
                <div className="content-image">
                  <img
                    src={(item.userPicture !== "") ? item.userPicture : `${pathServer.PATH_IMG}ic_account_circle.png`}
                    onError={(e)=>{e.target.src = `${pathServer.PATH_IMG}ic_account_circle.png`}}
                  />
                </div>
              </div>
            </div>
            <div className="item-c">
              <div className="container-label-name">
                <label className="label-name" style={{color: textColor}}>
                  {item.userName ? item.userName : "User name unavailable"}
                </label>
                {
                  (item.socialNetwork) ?
                    <div className="container-label-social-network">
                      <label className="label-social" style={{color: textColor}}>
                        {`From ${item.socialNetwork? item.socialNetwork : "From Facebook"}`}
                      </label>
                    </div>
                    : null
                }
              </div>

            </div>
          </div>
        </div>
      )
    }

    switch (view) {
      case PAGE_HOME_ID :
      case PAGE_GENERAL_SETTING :
        switch (templateCode) {
          case TEMPLATE_CODE_EASY_NAVIGATION :
            viewSec = (
              <div className="view-previous-home">
                <div className="header-content">
                  <img id="background" className="background" src={backgroundMain}
                       style={{zIndex: 100, position: "relative"}}/>
                  <div id="carSeparator1" className="car-separator1" style={{backgroundColor: primaryColor}}/>
                  <div id="contentSeparator2" className="content-separator2">
                    <div id="carSeparator2" className="car-separator2" style={{backgroundColor: secondaryColor}}/>
                  </div>
                  {layoutHeader('home')}
                </div>
                <div className="body-content-previous" style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  backgroundColor: siteColor[SITE_BACKGROUND_COLOR] ? siteColor[SITE_BACKGROUND_COLOR] : '#f4f4f4'
                }}>
                  <section id='bannerSection'>
                    {layoutAdminBanner}
                  </section>
                  <section id={'vehiclesSection'} style={{display: 'inline-block'}}>
                    {layoutCar}
                  </section>
                  <div id={'financeSection'}>
                    {!hideFinanceSection && layoutFinance(currentTheme, imgBackground)}
                  </div>
                  <section id={'locationSection'}>
                    {layoutLocation}
                  </section>
                  <section id={'getInTouch'} style={{display: 'inline-block', width: '100%'}}>
                    <div
                      id="contactMain"
                      className="lay-get-in-touch"
                      style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}
                    >
                      {layoutGetInTouch}
                      {!hideTestimonialSection ?
                        <DPCustomerTestimonial
                          testimonials={testimonials}
                          handlePopover={EmptyFunc}
                          sliderLeft={leftSlider}
                          sliderRight={rightSlider}
                          widthLayoutContainer={1500}
                          showPopover={false}
                          positionPopoverX={10}
                          positionPopoverY={10}
                          offsetWidth={{}}
                          displayValue={''}
                          handleRequestClose={EmptyFunc}
                          id={'testimonialSection'}
                          title={sectionTestimonials?.title ?? ''}
                          siteColor={siteColor}
                        /> : null
                      }
                    </div>
                  </section>
                  <section id='footerSection' style={{display: 'flex', width: '100%', position: 'relative'}}>
                    {layoutFooter}
                    {layoutBackToTop}
                  </section>
                </div>
              </div>
            );
            break;
          case TEMPLATE_CODE_EASY_NAVIGATION_B:
            const newSiteColor = {...siteColor, boxBackground: siteColor.siteBackground}
            viewSec = (
              <div className="view-previous-home view-previous-home-b">
                <div className="header-content">
                  <img id="background" className="background" src={backgroundMain}/>
                  <div id="carSeparator1" className="car-separator1" style={{backgroundColor: primaryColor}}/>
                  <div id="contentSeparator2" className="content-separator2">
                    <div id="carSeparator2" className="car-separator2" style={{backgroundColor: secondaryColor}}/>
                  </div>
                  {layoutHeader('home')}
                </div>
                <div className="body-content-previous" style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  backgroundColor: newSiteColor[SITE_BACKGROUND_COLOR] ? newSiteColor[SITE_BACKGROUND_COLOR] : '#f4f4f4'
                }}>
                  <section id='bannerSection'>
                    {layoutAdminBanner}
                  </section>
                  <section id='vehiclesSection' style={{display: 'inline-block'}}>
                    {layoutCar}
                  </section>
                  <section id='locationSection'>
                    {layoutLocation}
                  </section>
                  <div id='financeSection'>
                    {!hideFinanceSection && layoutFinance(currentTheme, imgBackground)}
                  </div>
                  <div
                    id="contactMain"
                    className="lay-get-in-touch"
                    style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}
                  >
                    {layoutGetInTouch}
                    {!hideTestimonialSection ?
                      <DPCustomerTestimonial
                        testimonials={testimonials}
                        handlePopover={EmptyFunc}
                        sliderLeft={leftSlider}
                        sliderRight={rightSlider}
                        widthLayoutContainer={1500}
                        showPopover={false}
                        positionPopoverX={10}
                        positionPopoverY={10}
                        offsetWidth={{}}
                        displayValue={''}
                        handleRequestClose={EmptyFunc}
                        id={'testimonialSection'}
                        title={sectionTestimonials?.title ?? ''}
                        siteColor={siteColor}
                      />:null
                    }
                  </div>
                  <section id='footerSection' style={{display: 'flex', width: '100%', position: 'relative'}}>
                    {layoutFooter}
                    {layoutBackToTop}
                  </section>
                </div>
              </div>
            );
            break;
          case TEMPLATE_CODE_EASY_NAVIGATION_C:
            viewSec = (
              <div className="view-previous-home">
                <div className="header-content">
                  <img id="background" className="background" src={backgroundMain}
                       style={{position: 'relative', zIndex: 100}}/>
                  <div id="carSeparator1" className="car-separator1" style={{backgroundColor: primaryColor}}/>
                  <div id="contentSeparator2" className="content-separator2">
                    <div id="carSeparator2" className="car-separator2" style={{backgroundColor: secondaryColor}}/>
                  </div>
                  {layoutHeader('home')}
                </div>
                <div className="body-content-previous" style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  backgroundColor: siteColor[SITE_BACKGROUND_COLOR] ? siteColor[SITE_BACKGROUND_COLOR] : '#f4f4f4'
                }}>
                  <section id='bannerSection'>
                    {layoutAdminBanner}
                  </section>
                  <section id='vehiclesSection' style={{display: 'inline-block'}}>
                    {layoutCar}
                  </section>
                  <div id='financeSection'>
                    {!hideFinanceSection && layoutFinance(currentTheme, imgBackground)}
                  </div>
                  <section id='locationSection'>
                    {layoutLocation}
                  </section>
                  <section id='testimonialSection' style={{display: 'inline-block', width: '100%'}}>
                    {!hideTestimonialSection ?
                      <LayoutCustomerHorizontalTestimonials
                        testimonials={testimonials}
                        primaryColor={primaryColor}
                        hideTitle={false}
                        heightCurrentBar={12}
                        heightTotalBar={3}
                        siteColor={siteColor}
                        title={sectionTestimonials?.title ?? ''}
                        currentTheme={generalSetting[CURRENT_THEME] || LIGHT_THEME}
                      /> : null
                    }
                  </section>
                  <section id='containerGetInTouch' style={{display: 'inline-block', width: '100%'}}>
                    <div id="contact5" className='layout-get-in-touch' style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                      {layoutGetInTouch}
                    </div>
                  </section>
                  <section id='footerSection' style={{display: 'flex', width: '100%', position: 'relative'}}>
                    {layoutFooter}
                    {layoutBackToTop}
                  </section>
                </div>
              </div>
            );
            break;
          case TEMPLATE_CODE_MODERN_VIEW :
            viewSec = (
              <div className="view-previous-home">
                <div className="layout-container-5">
                  {layoutHeader('home')}
                  <section id='bannerSection' style={{display: 'flex', width: '100%'}}>
                    {layoutAdminBanner}
                  </section>
                  <section id='vehiclesSection' style={{display: 'flex', width: '100%'}}>
                    {layoutCar}
                  </section>
                  <section id='financeSection' style={{display: 'flex', width: '100%'}}>
                    {!hideFinanceSection && layoutFinance()}
                  </section>
                  <section id='locationSection' style={{display: 'flex', width: '100%'}}>
                    {layoutLocation}
                  </section>
                  <section id='testimonialSection' style={{width: '100%'}}>
                    {!hideTestimonialSection ?
                      <LayoutCustomerHorizontalTestimonials
                        testimonials={testimonials}
                        primaryColor={primaryColor}
                        hideTitle={false}
                        heightCurrentBar={12}
                        heightTotalBar={3}
                        siteColor={siteColor}
                        title={sectionTestimonials?.title ?? ''}
                        currentTheme={generalSetting[CURRENT_THEME] || LIGHT_THEME}
                      /> : null
                    }
                  </section>
                  <section id='containerGetInTouch' style={{display: 'flex', width: '100%'}}>
                    <div id="contact5" className='layout-get-in-touch' style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                      {layoutGetInTouch}
                    </div>
                  </section>
                  <section id='footerSection' style={{display: 'flex', width: '100%', position: 'relative'}}>
                    {layoutFooter}
                    {layoutBackToTop}
                  </section>
                </div>
              </div>
            );
            break;
          case TEMPLATE_CODE_MODERN_VIEW_2:
            viewSec = (
              <div className="view-previous-home">
                <div className="layout-container-5">
                  {layoutHeader('home')}
                  <section id='bannerSection'>
                    {layoutAdminBanner}
                  </section>
                  <section id='vehiclesSection' style={{display: 'flex', width: '100%'}}>
                    {layoutCar}
                  </section>
                  <section id='financeSection' style={{display: 'flex', width: '100%'}}>
                    {!hideFinanceSection && layoutFinance()}
                  </section>
                  <section id='locationSection' style={{display: 'flex', width: '100%'}}>
                    {layoutLocation}
                  </section>
                  <div
                    id="contactMain"
                    className="lay-get-in-touch"
                    style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}
                  >
                    {layoutGetInTouch}
                    {!hideTestimonialSection ?
                      <DPCustomerTestimonial
                        testimonials={testimonials}
                        handlePopover={EmptyFunc}
                        sliderLeft={leftSlider}
                        sliderRight={rightSlider}
                        widthLayoutContainer={1500}
                        showPopover={false}
                        positionPopoverX={10}
                        positionPopoverY={10}
                        offsetWidth={{}}
                        displayValue={''}
                        handleRequestClose={EmptyFunc}
                        id={'testimonialSection'}
                        title={sectionTestimonials?.title ?? ''}
                        siteColor={siteColor}
                      /> : null
                    }
                  </div>
                  <section id="footerSection" style={{display: 'flex', width: '100%', position: 'relative'}}>
                    {layoutFooter}
                    {layoutBackToTop}
                  </section>
                </div>
              </div>
            );
            break;
          case TEMPLATE_CODE_MODERN_VIEW_3:
            viewSec = (
              <div className="view-previous-home">
                <div className="layout-container-5">
                  {layoutHeader('home')}
                  <section id='bannerSection'>
                    {layoutAdminBanner}
                  </section>
                  <section id='vehiclesSection' style={{display: 'flex', width: '100%'}}>
                    {layoutCar}
                  </section>
                  <section id='financeSection' style={{display: 'flex', width: '100%'}}>
                    {!hideFinanceSection && layoutFinance()}
                  </section>
                  <section id='locationSection' style={{display: 'flex', width: '100%'}}>
                    {layoutLocation}
                  </section>
                  <section id='testimonialSection' style={{width: '100%'}}>
                    {!hideTestimonialSection ?
                      <LayoutCustomerHorizontalTestimonials
                        testimonials={testimonials}
                        primaryColor={primaryColor}
                        hideTitle={false}
                        heightCurrentBar={12}
                        heightTotalBar={3}
                        siteColor={siteColor}
                        title={sectionTestimonials?.title ?? ''}
                        currentTheme={generalSetting[CURRENT_THEME] || LIGHT_THEME}
                      /> : null
                    }
                  </section>
                  <section id='containerGetInTouch' style={{display: 'flex', width: '100%'}}>
                    <div id="contact5" className='layout-get-in-touch' style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                      {layoutGetInTouch}
                    </div>
                  </section>
                  <section id='footerSection' style={{display: 'flex', width: '100%', position: 'relative'}}>
                    {layoutFooter}
                    {layoutBackToTop}
                  </section>
                </div>
              </div>
            );
            break;
        }
        break;
      case PAGE_FINANCE_ID :
        const sectionContact = page.sections.find(sec => sec.id === SECTION_CONTACT_US_ID);
        const sectionTestimonies = page.sections.find(sec => sec.id === SECTION_TESTIMONIAL_ID);
        const showContactSection = sectionContact ? sectionContact.showSection : true;
        const showTestimonialSection = sectionTestimonies ? sectionTestimonies.showSection : true;
        const marginBottom = (showContactSection || (showTestimonialSection && templateCode !== TEMPLATE_CODE_EASY_NAVIGATION_C)) ? 0 : 180
        switch (templateCode) {
          case TEMPLATE_CODE_EASY_NAVIGATION :
          case TEMPLATE_CODE_EASY_NAVIGATION_B:
          case TEMPLATE_CODE_EASY_NAVIGATION_C:
            viewSec = (
              <div className="view-previous-finance" style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                <div className="header-content">
                  <img
                    id="background"
                    className="background"
                    src={backgroundMain}
                  />
                  {layoutHeader('finance')}
                  {
                    templateCode === TEMPLATE_CODE_EASY_NAVIGATION &&
                    <div className="content-separator">
                      <div className="car-separator1" style={{backgroundColor: primaryColor}}/>
                      <div className="content-separator2">
                        <div className="car-separator2" style={{backgroundColor: secondaryColor}}/>
                      </div>
                    </div>
                  }
                </div>
                {layoutFinanceViewForm('#D9D9D9', marginBottom)}
                {
                  (showContactSection || showTestimonialSection) ?
                    (templateCode === TEMPLATE_CODE_EASY_NAVIGATION_C) ?
                      <>
                        {
                          showContactSection &&
                          <section id='contactSection'
                                   style={{display: 'inline-block', width: '100%', paddingTop: 130}}>
                            <div id="contact5" className='layout-get-in-touch' style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                              {layoutGetInTouch}
                            </div>
                          </section>
                        }
                        {
                          showTestimonialSection &&
                          <section id='testimonialSection' style={{display: 'inline-block', width: '100%'}}>
                            <LayoutCustomerHorizontalTestimonials
                              testimonials={testimonials}
                              primaryColor={primaryColor}
                              hideTitle={false}
                              heightCurrentBar={12}
                              heightTotalBar={3}
                              siteColor={siteColor}
                              title={sectionTestimonials?.title ?? ''}
                              currentTheme={generalSetting[CURRENT_THEME] || LIGHT_THEME}
                            />
                          </section>
                        }
                      </> :
                      <section id='contactSection' style={{display: 'inline-block', width: '100%'}}>
                        <div
                          id="contactMain"
                          className="lay-get-in-touch"
                          style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}
                        >
                          {showContactSection && layoutGetInTouch}
                          {
                            showTestimonialSection &&
                            <DPCustomerTestimonial
                              testimonials={testimonials}
                              handlePopover={() => {}}
                              sliderLeft={leftSlider}
                              sliderRight={rightSlider}
                              widthLayoutContainer={1500}
                              showPopover={false}
                              positionPopoverX={10}
                              positionPopoverY={10}
                              offsetWidth={{}}
                              displayValue={''}
                              handleRequestClose={() => {}}
                              id={'testimonialSection'}
                              title={sectionTestimonials?.title ?? ''}
                              siteColor={siteColor}
                            />
                          }
                        </div>
                      </section>
                    : null
                }
                <section id='footerSection' style={{display: 'flex', width: '100%', position: 'relative'}}>
                  {layoutFooter}
                  {layoutBackToTop}
                </section>
              </div>
            );
            break;
          case TEMPLATE_CODE_MODERN_VIEW :
          case TEMPLATE_CODE_MODERN_VIEW_2:
          case TEMPLATE_CODE_MODERN_VIEW_3:
            viewSec = (
              <div className="layout-finance-container">
                {layoutHeader('finance')}
                <div className="finance-form-5" style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                  {layoutFinanceViewForm('#e3e3e3')}
                </div>
                {
                  (showContactSection || showTestimonialSection) &&
                  (templateCode === TEMPLATE_CODE_MODERN_VIEW_2) ?
                    <section id='contactSection' style={{display: 'inline-block', width: '100%'}}>
                      <div
                        id="contactMain"
                        className="lay-get-in-touch"
                        style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}
                      >
                        {layoutGetInTouch}

                        <DPCustomerTestimonial
                          testimonials={testimonials}
                          handlePopover={() => {}}
                          sliderLeft={leftSlider}
                          sliderRight={rightSlider}
                          widthLayoutContainer={1500}
                          showPopover={false}
                          positionPopoverX={10}
                          positionPopoverY={10}
                          offsetWidth={{}}
                          displayValue={''}
                          handleRequestClose={() => {}}
                          id={'testimonialSection'}
                          title={sectionTestimonials?.title ?? ''}
                          siteColor={siteColor}
                        />
                      </div>
                    </section> :
                    <>
                      {
                        showContactSection &&
                        <section id='contactSection' style={{display: 'flex', width: '100%'}}>
                          <div id="contact5" className='layout-get-in-touch' style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                            {layoutGetInTouch}
                          </div>
                        </section>
                      }
                      {
                        showTestimonialSection &&
                        <section id='testimonialSection' style={{width: '100%'}}>
                          <LayoutCustomerHorizontalTestimonials
                            testimonials={testimonials}
                            primaryColor={primaryColor}
                            hideTitle={false}
                            heightCurrentBar={12}
                            heightTotalBar={3}
                            siteColor={siteColor}
                            title={sectionTestimonials?.title ?? ''}
                            currentTheme={generalSetting[CURRENT_THEME] || LIGHT_THEME}
                          />
                        </section>
                      }
                    </>
                }
                <section id='footerSection' style={{display: 'flex', width: '100%', position: 'relative'}}>
                  {layoutFooter}
                  {layoutBackToTop}
                </section>
              </div>
            );
            break;
        }
        break;
      case  PAGE_ABOUT_US_ID :
        const {showCustomerTestimonials, showDealerHours, sections} = page;
        const sectionTestimonial = sections.find(sec => sec.id === SECTION_TESTIMONIAL_ID);
        const {showSection} = sectionTestimonial
        switch (templateCode) {
          case TEMPLATE_CODE_EASY_NAVIGATION :
          case TEMPLATE_CODE_EASY_NAVIGATION_B:
          case TEMPLATE_CODE_EASY_NAVIGATION_C:
            viewSec = (
              <div className="view-previous-about-us" style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                {layoutHeader('aboutUs')}
                <div className="header-content">
                  <img id="background" className="background" src={backgroundMain}/>
                  <div className="layout-about">
                    <LayoutAboutUsGoal
                      currentTheme={currentTheme}
                      page={page}
                      siteColor={siteColor}
                      showDealerHours={showDealerHours}
                      hoursOfOperation={null}
                      templateCode={templateCode}
                      isAdminTemplate
                    />
                  </div>
                </div>
                {
                  templateCode === TEMPLATE_CODE_EASY_NAVIGATION &&
                  <div className="content-separator">
                    <div className="car-separator1" style={{backgroundColor: primaryColor}}/>
                    <div className="content-separator2">
                      <div className="car-separator2" style={{backgroundColor: secondaryColor}}/>
                    </div>
                  </div>
                }
                {/*<div className="layout-about">
                  <LayoutAboutUsGoal
                    currentTheme={currentTheme}
                    page={page}
                    siteColor={siteColor}
                    showDealerHours={showDealerHours}
                    hoursOfOperation={null}
                    templateCode={templateCode}
                    isAdminTemplate
                  />
                  <div className="about-testimonial">
                    {showSection && <LayoutCustomerTestimonial primaryColor={primaryColor}/>}
                    <LayoutDealerCarousel
                      primaryColor={primaryColor}
                      withOutTestimonials={showCustomerTestimonials}
                      dealerMedias={dealerMedias}
                      changeVideo={changeVideo}
                      videoUrl={videoUrl}
                      indexVideoSelected={indexVideoSelected}/>
                  </div>
                </div>*/}

                {/*<LayoutCarCarousel*/}
                {/*  cars={cars}*/}
                {/*  carsByCarouselItem={4}*/}
                {/*  primaryColor={primaryColor}*/}
                {/*  siteColor={siteColor}*/}
                {/*/>*/}

                {/*<DPCarousel*/}
                {/*  cars={cars}*/}
                {/*  carsByCarouselItem={4}*/}
                {/*  primaryColor={primaryColor}*/}
                {/*  siteColor={siteColor}*/}
                {/*  isAdmin={true}*/}
                {/*/>*/}

                <div className="carousel-dealer-images-container" style={{backgroundColor: customBackground}}>
                  <DPCarousel
                    barSelectedPageColor={primaryColor}
                    direction={HORIZONTAL_CAROUSEL}
                    itemList={dealerMedias}
                    ItemComponent={({item}) => <ItemDealerMedia item={item}/>}
                    numberOfItemsPerPage={4}
                    numberOfItemsToShift={2}
                    barType={SEGMENTS_BAR}
                    width={"100%"}
                    height={"17vw"}
                    customisedArrowPosition={ARROWS_BOTTOM}
                    arrowContainerBackgroundColor={customBackgroundContrast}
                  />
                </div>

                {
                  showSection &&
                  <div id="testimonialHeader" className="testimonial-header" style={{backgroundColor: customBackground}}>
                    <h1 style={{color: siteColor[TEXT_COLOR]}}>{"What people say about us"}</h1>
                    <p style={{color: "#809FB8"}}>{"See why they recommend us"}</p>
                  </div>
                }
                {
                  showSection &&
                  <div className={"testimonials-container"} style={{backgroundColor: customBackground}}>
                    <DPCarousel
                      barSelectedPageColor={primaryColor}
                      direction={HORIZONTAL_CAROUSEL}
                      itemList={testimonials}
                      ItemComponent={({item}) => <ItemTestimonial item={item}/>}
                      numberOfItemsPerPage={4}
                      numberOfItemsToShift={2}
                      barType={SEGMENTS_BAR}
                      width={"100%"}
                      height={"17vw"}
                      customisedArrowPosition={ARROWS_BOTTOM}
                      arrowContainerBackgroundColor={customBackgroundContrast}
                    />
                  </div>
                }


                <section id="footerSection" style={{display: 'flex', width: '100%', position: 'relative'}}>
                  {layoutFooter}
                  {layoutBackToTop}
                </section>
              </div>
            );
            break;
          case TEMPLATE_CODE_MODERN_VIEW :
          case TEMPLATE_CODE_MODERN_VIEW_2:
          case TEMPLATE_CODE_MODERN_VIEW_3:
            const newSiteColor = {...siteColor, boxBackground: siteColor.siteBackground, siteBackground: siteColor.boxBackground}
            viewSec = (
              <div className="view-previous-about-us">
                {layoutHeader('aboutUs')}
                <div
                  className="layout-about layout-about-5"
                  style={{
                    backgroundColor: siteColor[SITE_BACKGROUND_COLOR],
                    paddingTop: '90px',
                    position: 'unset'
                  }}
                >
                  <div
                    className="layout-about-box"
                    style={{
                      backgroundColor: siteColor[BOX_BACKGROUND_COLOR],
                      border: `1px solid ${siteColor[BOX_BORDER_COLOR]}`
                    }}
                  >
                    <div id="mediasTestimonials" className="dealer-medias-testimonials">
                      <LayoutDealerCarousel5
                        dealerMedias={dealerMedias}
                        indexVideoSelected={indexVideoSelected}
                        videoUrl={videoUrl}
                        mediaSelected={mediaSelected}
                        changeVideo={changeVideo}
                      />
                      {/*{showSection &&
                        <LayoutCustomerHorizontalTestimonials
                          testimonials={testimonials}
                          primaryColor={primaryColor}
                          hideTitle={false}
                          heightCurrentBar={12}
                          heightTotalBar={3}
                          siteColor={newSiteColor}
                          title={sectionTestimonials?.title ?? ''}
                          currentTheme={generalSetting[CURRENT_THEME] || LIGHT_THEME}
                        />
                      }*/}
                    </div>
                    <LayoutAboutUsGoal
                      currentTheme={currentTheme}
                      siteColor={siteColor}
                      page={page}
                      showDealerHours={showDealerHours}
                      templateCode={templateCode}
                      hoursOfOperation={null}
                      isAdminTemplate
                    />
                  </div>
                </div>
                <section id="footerSection" style={{display: 'flex', width: '100%', position: 'relative'}}>
                  {layoutFooter}
                  {layoutBackToTop}
                </section>
              </div>
            );
            break;
        }
        break;
      case PAGE_INVENTORY_ID :
        const financingSection = page.sections.find(sec => sec.id === SECTION_FINANCE_ID);
        const showFinancingSection = financingSection ? financingSection.showSection : true;
        switch (templateCode) {
          case TEMPLATE_CODE_EASY_NAVIGATION :
          case TEMPLATE_CODE_EASY_NAVIGATION_B:
          case TEMPLATE_CODE_EASY_NAVIGATION_C:
            viewSec = (
              <div className="view-previous-vehicles" style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                <div
                  className={`${classTemplateContainer} ${currentTheme === DARK_THEME ? 'layout-inventory-container-dark' : ''}`}>
                  {/*todo: remove following style object, this is a hack*/}
                  <div className="header-content" style={{position: 'absolute'}}>
                    <img id="background" className="background" src={backgroundMain} style={{height: "800px"}}/>
                    {layoutHeader('inventory')}
                    <div id="separatorHeader" className="separator-header"/>
                    {
                      templateCode === TEMPLATE_CODE_EASY_NAVIGATION &&
                      <>
                        <div id="carSeparator1" className="car-separator1" style={{backgroundColor: primaryColor}}/>
                        <div id="contentSeparator2" className="content-separator2">
                          <div id="carSeparator2" className="car-separator2" style={{backgroundColor: secondaryColor}}/>
                        </div>
                      </>
                    }
                  </div>
                  <div>
                    <div className="car-separator1" style={{backgroundColor: primaryColor}}/>
                    <div className="content-separator2">
                      <div className="car-separator2" style={{backgroundColor: secondaryColor}}/>
                    </div>
                  </div>
                  <LayoutInventory
                    carsInformation={cars}
                    makes={[{id: 'Make', name: 'Make', displayValue: 'Make'}]}
                    models={[{id: 'Model', name: 'Model', displayValue: 'Model', makeId: 'Make'}]}
                    information={get(footer, ['information'], {})}
                    siteColor={siteColor}
                    templateCode={templateCode}
                    currentTheme={currentTheme}
                    dealerId={dealerName}
                    isAdmin={true}
                  />
                  <div className='container-sections' style={{
                    width: "100%",
                    left: 0,
                    top: 0,
                    backgroundColor: siteColor[SITE_BACKGROUND_COLOR] ? siteColor[SITE_BACKGROUND_COLOR] : '#f4f4f4'
                  }}>
                    <div id='contentBannerCar' className={`container-banner-car`}
                         style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                    </div>
                    <div className={`content-box-get-in-touch-${currentTheme ? currentTheme : ''}`}>
                    </div>
                    {
                      !!showFinancingSection &&
                      <section id={'financeSection'} style={{display: 'flex', width: '100%'}}>
                        {layoutFinance(currentTheme, imgBackground)}
                      </section>
                    }
                    <section id='footerSection' style={{display: 'flex', width: '100%', position: 'relative'}}>
                      {layoutFooter}
                      {layoutBackToTop}
                    </section>
                  </div>
                </div>
              </div>
            )
            break;
          case TEMPLATE_CODE_MODERN_VIEW :
          case TEMPLATE_CODE_MODERN_VIEW_2 :
          case TEMPLATE_CODE_MODERN_VIEW_3 :
            viewSec = (
              <div className="view-previous-vehicles">
                {layoutHeader('inventory')}
                <LayoutInventory
                  carsInformation={cars}
                  makes={[{id: 'Make', name: 'Make', displayValue: 'Make'}]}
                  models={[{id: 'Model', name: 'Model', displayValue: 'Model', makeId: 'Make'}]}
                  information={get(footer, ['information'], {})}
                  siteColor={siteColor}
                  templateCode={templateCode}
                  currentTheme={currentTheme}
                  dealerId={dealerName}
                  isAdmin={true}
                />
                {
                  !!showFinancingSection &&
                  <section id='financeSection' style={{
                    display: 'flex',
                    width: '100%',
                    paddingTop: '30px',
                    backgroundColor: siteColor[SITE_BACKGROUND_COLOR]
                  }}>
                    {layoutFinance()}
                  </section>
                }
                <section id="footerSection" style={{display: 'flex', width: '100%', position: 'relative'}}>
                  {layoutFooter}
                  {layoutBackToTop}
                </section>
              </div>
            )
            break;
        }
        break;
    }
    return viewSec;
  }

  render() {
    const {
      page, locations, cars, generalSetting, view, menuSelected, dealerMedias, changeVideo, indexVideoSelected,
      videoUrl, mediaSelected, sectionContactUs, templateCode, sectionFinance, pages, dealerName
    } = this.props;
    const siteColor = getSiteColor(generalSetting)

    return (
      <div id={'templateViewPrevious'}>
        {this.getView(
          page, locations, cars, generalSetting, view, menuSelected, dealerMedias, changeVideo, indexVideoSelected,
          videoUrl, mediaSelected, sectionContactUs, templateCode, siteColor, sectionFinance, pages, dealerName
        )}
      </div>
    )
  }
}
