import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import {parseCSVToJSON} from './CsvUploaderUtil'
import {ReactSVG} from 'react-svg'
import {getAnchorEl} from './Utils'
import {EXT_CSV, EXT_XLSX, pathServer} from '../../../common/Constants'

export default class ModalUploadFile extends Component {

  constructor() {
    super();

    this.state = {
      files: null,
      errors: null,
      nameFile: null,
      header: [],
      rows: [],
      activeHeader: -1,
      showMessage: false,
      anchorEl: {},
      showModalData: false,
      showPopover: false,
      headerSelected: [],
      isLoading: false
    }
  }

  onDrop = (file) => {
    let {files} = this.state;
    if (!files) {
      const nameFile = file[0].name
      parseCSVToJSON(file[0], true, (data) => {
        const columns = [];
        let numberColumns = data.header.length;
        let indexRow = 0;
        const totalRows = data.rows.map((row, index) => {
          indexRow += 1;
          const dataList = [];
          return Object.keys(row).map((name,index) => {
            dataList.push(row[name]);
            return {index: indexRow,data: dataList}
          });
        });
        let i = 0;
        while (i < numberColumns){
          columns.push({index: i, id: "", value: "-"});
          i += 1;
        }
        this.props.showModalData(totalRows,columns, nameFile);
      })
    }
  }

  cancel = () => {
    this.setState({errors: null});
    this.props.onHide();
  }

  showMessage = (evt) => {
    evt.stopPropagation();
    let pointTo = getAnchorEl(evt.currentTarget,0);
    let anchorEl = {
      x: pointTo.x + 20,
      y: pointTo.y - 45,
      offsetWidth: 300
    };
    this.setState({showMessage: true, anchorEl})
  }

  handleRequestClose = () => {
    this.setState({showMessage: false, showPopover: false, showMessageImport: false});
  }

  render() {
    const {show,title} = this.props;
    const child = <label>In this section you can add the expenses derived from having the car ready to sell.
      Any added expense on Task Repair related to this car will also appear in this section once Task Repair is completed.</label>

    return (
      <div className="container-modal-car-deactivate">
        <Modal show={show} className={'modal-deactivate modal-import'} backdropClassName="backdrop-car">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                    beforeInjection={ svg => svg.classList.add("icon-close")} onClick={this.cancel}/>
          <Modal.Body>
            <div className="panel-body-csv">
              <div className="panel-top">
                <label>{title}</label>
              </div>
              <div className="panel-middle">
                <div className="panel-icon">
                  <Dropzone id="dropZone" onDrop={this.onDrop} multiple={false} accept={`.${EXT_CSV},.${EXT_XLSX}`}>
                    {({getRootProps, getInputProps}) => (
                      <div {...getRootProps()} className="dropzone-content dropzone-container" data-test={`upload-file-image`}>
                        <input {...getInputProps()} />
                        <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_upload_file.svg`} className="img-customer"/>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="panel-text">
                  <div className="upload-text">
                    Drag and drop your file or
                    <Dropzone id="dropZone" onDrop={this.onDrop} multiple={false} accept={`.${EXT_CSV},.${EXT_XLSX}`}>
                      {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="dropzone-content dropzone-container">
                          <input {...getInputProps()} data-test={`upload-file-link`}/>
                          <label>click here</label>
                        </div>
                      )}
                    </Dropzone> to open your files
                  </div>
                </div>
              </div>
              <div className="panel-down">
                <div className="container-icon" style={{paddingTop: 1}}>
                  <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_question_mark.svg`} className={"icon-help"}
                            beforeInjection={svg => svg.classList.add("icon-help-svg")} onClick={(evt) => {this.showMessage(evt)}}/>
                </div>
                <label>
                  Help
                </label>
                <div className="container-icon" style={{paddingTop: 5}}>
                  <ReactSVG src={`${pathServer.PATH_IMG}icon/download-tray.svg`} className={"icon-error"}
                            beforeInjection={svg => svg.classList.add("svg-icon-download")} />
                </div>
                <a style={{padding: 0}} href='/assets/template_customer.csv' download="template-customer">
                  Download template file
                </a>
              </div>
            </div>
            {/*
              showMessage ?
                <DPPopover anchorEl={anchorEl}
                           classNameWrapper={"popover-box-message popover-csv"}
                           handleRequestClose={this.handleRequestClose}>
                  {child}
                </DPPopover> : null*/
            }
          </Modal.Body>
        </Modal>
      </div>
    )
  }

}