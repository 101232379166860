import React from 'react'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../../common/Constants'

const Receipt = React.forwardRef(({invoiceId}, ref) => {
  const billedTo = {
    name: 'Josh Romeo',
    address: '356 W 618 S St. Jordan, Utah. 34879 ',
    phoneNumber: '+1 80976854',
    email: 'Josh.rom@godealergo.com',
    paymentDate: '03/31/2021'
  }
  const transactionInformation = {
    status: 'Approved',
    name: 'Maldonado, Federico',
    amountPaid: '$228.92',
    date: '03/31/2021'
  }
  const cardInformation = {
    cardType: 'VISA',
    cardNumber: 'XXXX-XXXX-XXXX-9087'
  }
  const paymentIds = {
    gdgCode: 'G-42908',
    transactionCode: 'A8907-3322'
  }
  return(
    <div className="container-receipt-modal">
      <div className="container-top">
        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} onClick={() => handleShowPaymentMethod()}
                  beforeInjection={svg => svg.classList.add("add-icon")}
                  className="add-icon"/>
        <span>Transaction receipt</span>
      </div>
      <div className="container-bottom">
        <div className="sub-container-top">
          <div className="first-box-receipt">
            <div className="box-left">
              <span className="title-section">Billed to</span>
              <div className="container-info">
                <span className="info-text  ">{billedTo.name}</span>
                <span className="info-text  ">{billedTo.address}</span>
                <span className="info-text  ">{billedTo.phoneNumber}</span>
                <span className="info-text  ">{billedTo.email}</span>
              </div>
            </div>
            <div className="box-right">
              <span className="title-section">Payment date</span>
              <span className="info-text">03/31/2021</span>
            </div>
          </div>
          <div className="second-box-receipt">
            <div className="container-title-section">
              <div>
                <h6 className="title-line-section">Transaction information</h6>
              </div>
              <div className='line-right'/>
            </div>
            <div className="box-transaction-info">
              <div className="info-transaction">
                <span className="label-info-item">Status</span>
                <span className="info approved">{transactionInformation.status}</span>
              </div>
              <div className="info-transaction">
                <span className="label-info-item">Name</span>
                <span className="info">{transactionInformation.name}</span>
              </div>
              <div className="info-transaction">
                <span className="label-info-item">Amount Paid</span>
                <span className="info">{transactionInformation.amountPaid}</span>
              </div>
              <div className="info-transaction">
                <span className="label-info-item">Date</span>
                <span className="info">{transactionInformation.date}</span>
              </div>
            </div>
          </div>
          <div className="third-box-receipt">
            <div className="container-title-section">
              <div>
                <h6 className="title-line-section">Card information</h6>
              </div>
              <div className='line-right'/>
            </div>
            <div className="box-transaction-info">
              <div className="info-transaction">
                <span className="label-info-item">Card Type</span>
                <span className="info">{cardInformation.cardType}</span>
              </div>
              <div className="info-transaction">
                <span className="label-info-item">Card Number</span>
                <span className="info">{cardInformation.cardNumber}</span>
              </div>
            </div>
          </div>
          <div className="fourth-box-receipt">
            <div className="container-title-section">
              <div>
                <h6 className="title-line-section">Payment ID’s</h6>
              </div>
              <span className='line-right'/>
            </div>
            <div className="box-transaction-info">
              <div className="info-transaction">
                <span className="label-info-item">GDG Code</span>
                <span className="info">{paymentIds.gdgCode}</span>
              </div>
              <div className="info-transaction">
                <span className="label-info-item">Transaction Code</span>
                <span className="info">{paymentIds.transactionCode}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-container-bottom">
          <span className="text-left">Total paid</span>
          <span className="text-right">$228.92</span>
        </div>
        <div className="container-icons-receipt">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_print_gray.svg"} onClick={() => {}}
                    beforeInjection={svg => svg.classList.add("print-icon")}
                    className="container-print-icon"/>
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_download_gray.svg"} onClick={() => {}}
                    beforeInjection={svg => svg.classList.add("download-icon")}
                    className="container-download-icon"/>
        </div>
      </div>
    </div>
  )
})

export default Receipt