import React from 'react'
require('./ExternalComponent.scss')

const ExternalComponent = ({idNext, title, text, prevButtonText, nextButtonText, handleNext, handleSkip}) => {
  return(
    <div className='external-content'>
      <div className={'container-title'}>
        <b>{ title ?? 'Hey! Welcome'}</b>
      </div>
      <div className={'text-container'}>
        {text ?? ''}
      </div>
      <div className={'container-buttons'}>
        <div className={'container-btn'} onClick={() => handleSkip('')}>
          {prevButtonText ?? 'Skip'}
        </div>
        <div className={'container-btn'} onClick={() => handleNext(idNext)}>
          <b>{nextButtonText ?? 'Next'}</b>
        </div>
      </div>
    </div>
  )

}

export default ExternalComponent