import React, {Component} from 'react'
import TasksList from './TasksList.jsx'
import {connect} from 'react-redux'
import {loadAllActiveUsers, loadUsers} from '../../actions'
import TasksListUpdated from './TasksListUpdated'

class TaskContainer extends Component {

  constructor() {
    super();
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    /*
    let {showSideBar} = this.props;
    if (showSideBar) {
      let container = window.document.getElementById("container");
      if(container)
        container.className += ' container-collapse';
    }
     */
    const {activeUsers, loadAllActiveUsers} = this.props;

    if(!activeUsers)
      loadAllActiveUsers(this.setIsLoading)
    else
      this.setState({isLoading: false})

  }

  setIsLoading = (value) => {
    this.setState({isLoading: value})
  }

  render() {
    let {height,search,users, activeUsers} = this.props;
    const {isLoading} = this.state
    if(!activeUsers)
      return null;

    const isPathRepair = !!this.props.params.vin
    return (
      <div id="container" className="container-component" style={{height:height}}>
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> :
            (this.props.children) ?
            this.props.children :
            <TasksListUpdated search={search} showRepair={isPathRepair} height={height}/>
        }
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    activeUsers: state.activeUsers,
  }
};


export default connect(mapStateToProps,{loadUsers, loadAllActiveUsers})(TaskContainer)