import React, {Component} from 'react'
import {findTemplateApi, loadLocationsApi, saveTemplateAndDealerMedia, loadSEOByMetadataTypes} from '../../../Api'
import TemplateViewPreview from './TemplateViewPreview.jsx'
import {
  metadataTypes
} from '../../../Constants'
import {
  PAGE_FINANCE_ID,
  PAGE_GENERAL_SETTING,
  PAGE_HOME_ID,
  PAGE_ABOUT_US_ID,
  SECTION_CONTACT_US_ID,
  SECTION_FINANCE_ID,
  defaultDealerImages,
  pathServer, cover,
  SUCCESSFUL_CODE
} from '../../../../common/Constants'
import {findIndex, isEmpty} from 'lodash'
import SettingHome from './SettingHome.jsx'
import {browserHistory} from 'react-router'
import GeneralSetting from './GeneralSetting.jsx'
import SettingFinance from './SettingFinance.jsx'
import SettingAboutUs from './SettingAboutUs.jsx'
import {setMenuId} from '../../../actions'
import {connect} from 'react-redux'
import {DPSpinner} from '../../../common/DPSpinner'
import ThemeSettingContainer from './ThemeSettingContainer'
import get from 'lodash/get'
import {getPosition} from '../../../../common/Util'

const LOCATIONS_SAMPLE = [
  {
    locationId: "1",
    city: "Orem",
    state: "UT",
    address: "1111 Street Ln St",
    country: "US",
    zip: "84097",
    info: "{\"phoneNumber\":\"(111) 111-1111\",\"email\":\"orem.dealers@gmail.com\",\"hoursOfOperation\":[{\"displayValue\":\"Mon-Fri\",\"from\":\"9:00\",\"to\":\"17:00\"},{\"displayValue\":\"Saturday\",\"from\":\"9:00\",\"to\":\"15:00\"},{\"displayValue\":\"Sunday\",\"from\":\"Closed\",\"to\":\"15:00\"}]}"
  },
  {
    locationId: "2",
    city: "Provo",
    state: "UT",
    address: "115 East 900 South",
    country: "US",
    zip: "84097",
    info: "{\"phoneNumber\":\"(111) 111-1111\",\"email\":\"provo.dealers@gmail.com\",\"hoursOfOperation\":[{\"displayValue\":\"Mon-Fri\",\"from\":\"9:00\",\"to\":\"17:00\"},{\"displayValue\":\"Saturday\",\"from\":\"9:00\",\"to\":\"15:00\"},{\"displayValue\":\"Sunday\",\"from\":\"Closed\",\"to\":\"15:00\"}]}"
  }
];

const cars = [
  {
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2018 Hyundai Elantra.jpg"
  },
  {
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelName":"Model",
    "modelId":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2017 Audi Q7.jpg"
  },{
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2004 Mazda RX8 Gray.jpg"
  },
  {
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"}
  },
  {
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2011 Toyota 4 Runner 4x4 Silver.jpg"
  },
  {
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2012 Nissan Altima S Maroon.jpg"
  },
  {
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"}
  },
  {
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2008 BMW 3 Series Red.jpg"
  },
  {
    "mileage":12000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9800,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"}
  },
  {
    "mileage":13000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9900,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2010 Toyota Corolla S White.jpg"
  },
  {
    "mileage":11000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2010,
    "suggestedPrice":9000,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2010 Mazda 3 S Green.jpg"
  },
  {
    "mileage":14000,
    "car.transmission":{attributeValue:"Automatic"},
    "car.trim":{attributeValue:"Clean"},
    "car.title":{attributeValue:"Title"},
    "makeId":"Make",
    "makeName":"Make",
    "modelId":"Model",
    "modelName":"Model",
    "year":2012,
    "suggestedPrice":11000,
    "description":"Make Model description",
    "car.status":{attributeValue:"Front Line Ready"},
    "mainImage":pathServer.PATH_IMG+"2012 Nissan Pathfinder S White.jpg"
  },
];

class Template extends Component{

  constructor(){
    super();
    this.handlePopover          = this.handlePopover.bind(this);
    this.handlePage             = this.handlePage.bind(this);
    this.handleRequestClose     = this.handleRequestClose.bind(this);
    this.uploadMediaFiles       = this.uploadMediaFiles.bind(this);
    this.changeVideo            = this.changeVideo.bind(this);
    this.goBack                 = this.goBack.bind(this);
    this.save                   = this.save.bind(this);
    this.close                  = this.close.bind(this);

    this.state = {
      template:null,
      pages:null,
      generalSetting:null,
      selectedPage:0,
      selectedSection:"",
      selectedSecSub:"",
      files:[],
      fields:[],
      isSuccess:false,
      showPopover:false,
      showGeneralSetting:true,
      setting:PAGE_GENERAL_SETTING,
      dealerMedias : [],
      filesToDealerMedia :[],
      videoUrl : null,
      indexVideoSelected : null,
      isLoading : false,
      mediaSelected : null,
      colorOptionIsOpen: false,
      colorPreviousSelected: "",
      selectedColor: "",
      dealerMediasToDelete: [],
      allLocations: LOCATIONS_SAMPLE,
      metadataType: {}
    }
  }

  componentDidMount(){
    if(this.props.params.id) {
      this.loadTemplate(this.props.params.id);
    }
  }

  componentWillUnmount(){
    const {setMenuId} = this.props;
    setMenuId("");
  }

  loadTemplate(id){
    loadLocationsApi("active")
      .then(({responseCode, data}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          this.setState({allLocations: isEmpty(data.locations) ? LOCATIONS_SAMPLE : data.locations})}})

    findTemplateApi(id).then((response) => {
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {template, dealerMedias, dealerSiteIconUrl} = data;
        const contentJson = JSON.parse(template.content);
        const pages = contentJson.pages;
        const generalSetting = contentJson.generalSetting;
        this.setState({template: template,pages:pages,generalSetting:generalSetting,dealerMedias:dealerMedias, dealerSiteIconUrl});
      }else{
        this.setState({errorMessage: true});
        setTimeout(()=> {
          this.setState({errorMessage: false});
        }, 2500);
      }
    })

    loadSEOByMetadataTypes(`${metadataTypes.TITLE},${metadataTypes.DESCRIPTION}`).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        const {dataSEO} = data
        const title = dataSEO?.find(d => { return d.metadataType === metadataTypes.TITLE})
        const description = dataSEO?.find(d => { return d.metadataType === metadataTypes.DESCRIPTION})
        this.setState({metadataType:{[metadataTypes.TITLE]: title, [metadataTypes.DESCRIPTION]: description}})
      } else {
        console.log(responseMessage)
      }
    })
  }

  onChange = (pages) => {
    this.setState({pages:pages})
  }

  onChangeGeneralSetting = (generalSetting) => {
    this.setState({generalSetting:generalSetting})
  }

  onChangeFile = (generalSetting,files,fields) => {
    this.setState({generalSetting:generalSetting,files:files,fields:fields})
  }

  onChangeSection = (selectedSection) => {
    this.setState({selectedSection:selectedSection})
  }

  goBack(){
    this.setState({selectedSection:""});
  }

  uploadMediaFiles(dealerMedias,files,dealerMediasToDelete){
    this.setState({dealerMedias:dealerMedias,filesToDealerMedia:files,dealerMediasToDelete:dealerMediasToDelete})
  }

  changeColorOptionIsOpen = (colorPreviousSelected, selectedColor) => {
    let {colorOptionIsOpen} = this.state;
    this.setState({colorOptionIsOpen: !colorOptionIsOpen, colorPreviousSelected: colorPreviousSelected, selectedColor: selectedColor})
  }

  changeVideo(media,index){
    let {videoUrl,indexVideoSelected} = this.state;
    if(media.mediaType === "video"){
      indexVideoSelected = index;
      const playerPreview = document.getElementsByClassName("react-player__preview");
      if(playerPreview && playerPreview[index]){
        const backgroundImage = playerPreview[index].style.backgroundImage;
        const split = backgroundImage.split('"');
        videoUrl = split[1];
      }
    }else{
      videoUrl = null;
      indexVideoSelected = null;
    }
    this.setState({mediaSelected:media,videoUrl:videoUrl,indexVideoSelected:indexVideoSelected})
  }

  save(){
    let {template,pages,files,fields,generalSetting,filesToDealerMedia,dealerMedias,colorOptionIsOpen,colorPreviousSelected,selectedColor,
      dealerMediasToDelete, dealerSiteIconUrl} = this.state;

    this.setState({isLoading:true});
    if(colorOptionIsOpen) {
      generalSetting.color[selectedColor] = colorPreviousSelected
    }

    // Includes site_icon_url from Dealers table as default icon when it wasn't selected an icon
    if (isEmpty(generalSetting.site.icon) && !isEmpty(dealerSiteIconUrl)) {
      generalSetting.site.icon = dealerSiteIconUrl
    }

    const content = {pages:pages,generalSetting:generalSetting};
    template.content = JSON.stringify(content);

    let dealerMediasRequest = [];
    dealerMedias.forEach(dealerMedia => {
      if((dealerMedia.mediaType === "video") && dealerMedia.isNew && (dealerMedia.isNew === true))
        dealerMediasRequest.push({url: dealerMedia.url, mediaType: 'video', order:dealerMedia.order})
    });

    let data = {
      templateId:template.id,
      content:JSON.stringify(content),
      files:files,
      fields:fields,
      filesToDealerMedia:filesToDealerMedia
    };

    if(dealerMediasRequest.length > 0)
      data.dealerMedias = JSON.stringify(dealerMediasRequest);

    let dealerMediasUpdate = [];
    if(dealerMediasToDelete.length > 0){
      data.dealerMediasToDelete = JSON.stringify(dealerMediasToDelete);
      dealerMedias.forEach(dealerMedia => {
        if(dealerMedia.id)
          dealerMediasUpdate.push({id:dealerMedia.id, url:dealerMedia.url, mediaType:dealerMedia.mediaType,order:dealerMedia.order})
      });
    }

    if(dealerMediasUpdate.length > 0)
      data.dealerMediasUpdate = JSON.stringify(dealerMediasUpdate);

    saveTemplateAndDealerMedia(data).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({isSuccess:true,isLoading: false});
        setTimeout(()=> {
          let template = data.template;
          const contentJson = JSON.parse(template.content);
          const pages = contentJson.pages;
          const generalSetting = contentJson.generalSetting;
          const dealerMedias = data.dealerMedias;
          this.setState({ dealerMedias : dealerMedias,template: template , pages : pages, generalSetting : generalSetting,isSuccess:false,
            showGeneralSetting:true, filesToDealerMedia:[],dealerMediasToDelete:[]});
        }, 3000);
      }else{
        console.error(responseMessage);
        this.setState({isSuccess:false,isLoading: false});
      }
    })

  }

  close(){
    browserHistory.push(`/main/setting/template`);
  }

  handlePopover(event){
    event.preventDefault();
    const {pages} = this.state;
    let options = [];

    pages.forEach((page) => {
      if(page.id === PAGE_HOME_ID ||  page.id === PAGE_FINANCE_ID || page.id === PAGE_ABOUT_US_ID )
        options.push({id:page.id,value:page.displayValue})
    });

    let pointTo = getPosition(event.currentTarget);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 25,
      offsetWidth: event.currentTarget.offsetWidth
    };
    this.setState({open: true, positionPopoverX: pointTo.x,positionPopoverY: pointTo.y,options:options, anchorEl: anchorEl});
  }

  handlePage(index,id){
    const {pages} = this.state;
    let showGeneralSetting = true;
    let selectedPage = findIndex( pages, (page) =>{return  page.id === id});
    if(selectedPage === -1)
      selectedPage = 0;
    if(index !== null)
      showGeneralSetting = false;
    else
      selectedPage = 0;
    this.setState({selectedPage,showGeneralSetting,open:false,setting:id,selectedSection:""});
  }

  renderPopover(onSelect){
    const {options, anchorEl, showGeneralSetting, pages, selectedPage} = this.state;

    let left = 0;
    let top  = 0;
    let offsetWidth = 0;
    let values = [];

    if(anchorEl){
      top  = anchorEl.y;
      left = anchorEl.x;
      offsetWidth = anchorEl.offsetWidth;
    }

    const popover = {
      zIndex: 200,
      left: left,
      top: top,
      backgroundColor: "#fff",
      transition: "0.8s",
      position: "absolute",
      width: offsetWidth,
      marginBottom: 0
    };

    values.push(
      <li key="99" className={showGeneralSetting ? "item-selected" :"change-submenu-item"} onClick={(event) => onSelect(null,"generalSetting")}>
        General
      </li>
    );

    options.forEach((option,index) => {
      if(option.value === pages[selectedPage].displayValue && !showGeneralSetting){
        values.push(
          <li key={index} className="item-selected" onClick={(event) => onSelect(index,option.id)}>
            {option.value}
          </li>
        )
      } else {
        values.push(
          <li key={index} className="change-submenu-item" onClick={(event) => onSelect(index,option.id)}>
            {option.value}
          </li>
        )
      }
    });

    return(
      <div id="generalOption" style={popover} className="popover-box-message">
        <div style={cover} onClick={() => this.handleRequestClose()}/>
        <div className="popover-general-message">
          <div className="popover-general-message-sub">
            <u>
              {values}
            </u>
          </div>
        </div>
      </div>
    )
  }

  handleRequestClose(){
    this.setState({open: false});
  }

  renderSetting(id){
    let {pages,selectedPage,selectedSection,files,fields,generalSetting,template,dealerMedias,filesToDealerMedia,dealerMediasToDelete, dealerSiteIconUrl} = this.state;
    let view;
    if(id === PAGE_GENERAL_SETTING){
      view = (
        <GeneralSetting  pages={pages}
                         generalSetting={generalSetting}
                         selectedPage={selectedPage}
                         selectedSection={selectedSection}
                         onChange={this.onChangeGeneralSetting}
                         onChangeFile={this.onChangeFile}
                         onChangeSection={this.onChangeSection}
                         menuSelected=""
                         files={files}
                         fields={fields}
                         templateId={template.id}
                         changeColorOptionIsOpen={this.changeColorOptionIsOpen}
                         templateCode={template.code}
                         dealerSiteIconUrl={dealerSiteIconUrl}
        />
      )
    }else if(id === PAGE_HOME_ID){
      view = (
        <SettingHome pages={pages}
                     selectedPage={selectedPage}
                     menuSelected=""
                     onChange={this.onChange}
                     templateId={template.id}
                     templateCode={template.code}/>
      )
    }else if(id === PAGE_FINANCE_ID){
      view = (
        <SettingFinance  pages={pages}
                         selectedPage={selectedPage}
                         onChange={this.onChange}/>
      )
    }else if(id === PAGE_ABOUT_US_ID){
      view = (
        <SettingAboutUs pages = {pages}
                        selectedPage={selectedPage}
                        onChange={this.onChange}
                        filesToDealerMedia={filesToDealerMedia}
                        dealerMedias = {dealerMedias}
                        dealerMediasToDelete={dealerMediasToDelete}
                        uploadMediaFiles={this.uploadMediaFiles}/>
      )
    }

    return view;
  }

  selectPage = (page) => {
    if(page.typeKey === 'page'){
      const {pages} = this.state
      const pageSelectedId = (page.id === 'vehicles') ? 'inventory' : page.id
      const indexPage =  pages.findIndex(x => x.id === pageSelectedId)
      if(indexPage >= 0){
        this.setState({selectedPage: indexPage, setting: pages[indexPage].id})
      }else{
        this.setState({selectedPage: 0, setting: pages[0].id})
      }
    }
  }

  render(){
    let {template,pages,selectedPage,selectedSection,isSuccess,generalSetting,showGeneralSetting,open,setting,dealerMedias,
      videoUrl,indexVideoSelected,isLoading,mediaSelected, files, fields, filesToDealerMedia, dealerMediasToDelete, allLocations, metadataType} = this.state;

    if(!template) return null;
    let page = pages[selectedPage];

    let headerRight = null;
    let activeBack  = false;
    if(selectedSection !== ""){
      activeBack = true;
      headerRight = (
        <div className="header-tab">
          <label>{`${generalSetting.displayValue} > ${generalSetting[selectedSection].displayValue}`}</label>
        </div>
      )
    }
    const homePage = pages.find(pag => { return pag.id === PAGE_HOME_ID});
    const sectionContactUs = homePage.sections.find(sec => { return sec.id === SECTION_CONTACT_US_ID});
    const sectionFinance   = homePage.sections.find(sec => { return sec.id === SECTION_FINANCE_ID});
    const {height, dealerName} = this.props

    return(
      <div className="setting-container-template" style={{height: height + 23}}>
        {isLoading ? <DPSpinner classContainer={"loader-container"}/> : null}
        {
          isSuccess ?
            <div className="showMessage alert alert-success message-template">
              <h2>Success!</h2>
              <p>Your information has been saved</p>
            </div> : null
        }
        <ThemeSettingContainer
          onChange={this.onChangeGeneralSetting}
          generalSetting={generalSetting}
          save={this.save}
          close={this.close}
          onChangeFile={this.onChangeFile}
          onChangePages={this.onChange}
          pages={pages}
          uploadMediaFiles={this.uploadMediaFiles}
          files={files}
          fields={fields}
          dealerMedias={dealerMedias}
          filesToDealerMedia={filesToDealerMedia}
          dealerMediasToDelete={dealerMediasToDelete}
          selectPage={this.selectPage}
          templateCode={template.code}
          dealerName={dealerName}
          metadataType={metadataType}
          isLoadingSpinnerOnMainPage={isLoading || isSuccess}
        />
        <div className="template-view">
          <TemplateViewPreview page={page} cars={cars} locations={allLocations} templateCode={template.code}
                               generalSetting={generalSetting} view={setting} dealerMedias={dealerMedias.length > 0 ? dealerMedias : defaultDealerImages }
                               changeVideo={this.changeVideo} indexVideoSelected={indexVideoSelected}
                               videoUrl={videoUrl} mediaSelected={mediaSelected} sectionContactUs={sectionContactUs}
                               sectionFinance={sectionFinance} pages={pages} dealerName={dealerName}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerName: state.dealerName
  }
}

export default connect(mapStateToProps,{setMenuId})(Template)