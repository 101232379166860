import React, {Component} from 'react'
import AttributesOptions from './AttributesOptions.jsx'
import AttributeProperties from './AttributeProperties.jsx'
import {findAttributeApi, saveAttributeApi} from '../../../Api.js'
import AttributeHeader from './AttributeHeader.jsx'
import {validateError} from '../../util/Utils'
import {isEmpty} from 'lodash'
import {COPY_ACTION, InputTypes, typeAttribute, UPDATE_ACTION} from '../../../Constants'
import {getShadowStyle} from './AttributesOptions'
import {
  imageCheckBoxList, imageDate,
  imageDynamicNumber, imageExpand, imageList, imageParagraph,
  imagePasswordHidden,
  imageSingleLine, imageSquare,
  imageTextArea,
  SUCCESSFUL_CODE
} from '../../../../common/Constants'

const fieldsAttributeRequired = [{key: "name", type: "required"},
  {key: "code", type: "required"},
  {key: "displayValue", type: "required"}];

function isMultipleOption(type){
  let isMultipleOption = false;
  switch (type) {
    case InputTypes.SELECT:
    case InputTypes.MULTI_SELECT:
    case InputTypes.CHECK_BOX:
    case InputTypes.LIST:
      isMultipleOption = true;
      break;
  }
  return isMultipleOption
}

function getError(errors, object, type, attribute) {
  let value;
  if(type === 'ATTRIBUTE'){
    fieldsAttributeRequired.forEach(field => {
      const {key, type} = field;
      if (type === "required") {
        value = object[key];
        switch (key) {
          case "code":
          case "name":
          case "displayValue":  errors[key] = (isEmpty(value) || value === "")
        }
      }
    });
  }else if(type === 'ATTRIBUTE_VALUE'){
    errors["multipleAttributeValue"] = isMultipleOption(attribute.typeAttribute) && (!object || (object.length === 0))
  }

  return errors
}

export const AttributeType = ({isTypeAttributeMultiple,bShadow,viewAttributeOptions,inputType,src,displayValue}) => {
  return(
    <div className="form-setting-fields"
         onClick={isTypeAttributeMultiple ? () => viewAttributeOptions(inputType): null}
         style={{boxShadow: bShadow, backgroundColor: isTypeAttributeMultiple ? "white":"transparent"}}>
      <span><img src={src} className="form-field-img" alt=""/></span>
      <div style={{display: "inline"}}>{displayValue}</div>
    </div>
  )
};

export default class AttributeSetting extends Component {

  constructor() {
    super();
    this.onChangeSetting      = this.onChangeSetting.bind(this);
    this.onChangeMenu         = this.onChangeMenu.bind(this);
    this.onChangeDropDownForm = this.onChangeDropDownForm.bind(this);
    this.onSelectDropDown     = this.onSelectDropDown.bind(this);
    this.save                 = this.save.bind(this);
    this.updateAttribute      = this.updateAttribute.bind(this);
    this.updateAttributeType  = this.updateAttributeType.bind(this);
    this.caughtTextSettings   = this.caughtTextSettings.bind(this);
    this.state = {
      showCommonFields: false,
      showAttributeOptions: false,
      nameAttributeOptions: '',
      editAttribute:false,
      attribute:{
        code:"",
        name:"",
        displayValue:"",
        typeAttribute:"",
        entityTypes:[],
        values:[]
      },
      menuTabSelected: 'attributes',
      bShadow: ["0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0"],
      typeAttributeMultiple: 2,
      textParagraph: "",
      errors: {},
      isLoading: false
    }
  }

  componentWillMount(){
    let {attribute} = this.props;
    if(attribute){
      this.setState({isLoading:true})
      findAttributeApi(attribute.id).then((response) => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE) {
          let attributeResponse = {...data.attribute};
          attributeResponse.entityTypes = [...attribute.entityTypes];
          attributeResponse.values = [...data.attributeValues];
          this.setState({attribute:attributeResponse,editAttribute:true, isLoading:false});
        } else {
          console.error(responseMessage);
          this.setState({isLoading:false})
        }
      })
    }
  }

  onChangeSetting(type) {
    const value = this.state[type];
    this.setState({[type]: !value})
  }

  onChangeMenu(menuTabSelected) {
    this.setState({menuTabSelected})
  }

  onChangeDropDownForm(dropDown){
    let dropDownForm = this.state[dropDown];
    dropDownForm.render = !dropDownForm.render;
    this.setState({[dropDown]:dropDownForm})
  }

  onSelectDropDown(dropDown,type,value){
    let {attribute} = this.state;
    attribute[type] = value;
    let that = this;
    this.setState({attribute:attribute},()=>{
      that.onChangeDropDownForm(dropDown)
    })
  }

  viewCommonFields(type){
    let {bShadow, errors} = this.state;
    let updateErrors = {...errors};
    updateErrors.typeAttribute = false;
    this.setState({errors: updateErrors});
    let selectShadowArray = getShadowStyle(type);
    let typeAttributeMultiple = 2;
    for (let i = 0; i < 10; i++) {
      bShadow[i] = "0 0 0";
    }
    bShadow[selectShadowArray] = "0 3px 6px";
    if(selectShadowArray===1 || selectShadowArray===2 || selectShadowArray===4 || selectShadowArray===5 )
      typeAttributeMultiple = 1;
    else if(selectShadowArray===0 || selectShadowArray===3 || selectShadowArray===6 || selectShadowArray===7 || selectShadowArray===8 || selectShadowArray===9)
      typeAttributeMultiple = 0;
    this.setState({showCommonFields:!this.state.showCommonFields, typeAttributeMultiple: typeAttributeMultiple})
  }

  viewAttributeOptions = (type) =>{
    let {errors} = this.state;
    let updateErrors = {...errors};
    updateErrors.typeAttribute = false;
    this.setState({errors: updateErrors});
    this.updateAttributeType(type);
    this.setState({showAttributeOptions:!this.state.showAttributeOptions, nameAttributeOptions:type})
  };

  updateAttributeType(type){
    const attributeUpdate = {...this.state.attribute};
    attributeUpdate.typeAttribute = type;
    this.setState({attribute:attributeUpdate})
  }

  updateAttribute(attribute){
    this.setState({attribute:attribute});
  }

  caughtTextSettings(text){
    this.setState({textParagraph: text});
  }

  getAttributeValues = (attributeValues,type) =>{
    const {attribute} = this.state;
    let options = [];
    switch (type) {
      case InputTypes.DATE:
      case InputTypes.PARAGRAPH:
      case InputTypes.PASSWORD:
      case InputTypes.SINGLE_TEXT:
      case InputTypes.TEXT_AREA:
      case InputTypes.DYNAMIC_NUMBER:
      case InputTypes.NUMBER:
        if(!attributeValues || attributeValues.length === 0)
          options = [{code: attribute.code , name: attribute.code, displayValue: attribute.name, order: 1}];
        break;
      case InputTypes.SELECT:
      case InputTypes.MULTI_SELECT:
      case InputTypes.CHECK_BOX:
      case InputTypes.LIST:
        options =  attributeValues;
        break;
    }
    return options
  };

  save(evt,attributeValues){
    let {attribute, editAttribute, nameAttributeOptions, textParagraph, errors} = this.state;
    let error = getError(errors, attribute, 'ATTRIBUTE');
    let errorAttributeAndAttributeValues = getError(error,attributeValues, 'ATTRIBUTE_VALUE',attribute);
    const hasError = validateError(errorAttributeAndAttributeValues)
    if(!hasError && !isEmpty(attribute.typeAttribute)){
      let sendRequest = {
        attribute:attribute,
        entityTypes:attribute.entityTypes,
        options: this.getAttributeValues(attributeValues, attribute.typeAttribute)
      };
      if(nameAttributeOptions.toUpperCase()==="PARAGRAPH")
        sendRequest.attribute.displayValue = textParagraph;

      saveAttributeApi(sendRequest,(editAttribute) ? UPDATE_ACTION : attribute.id ? COPY_ACTION : null).then(({responseCode}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          this.props.setSelectedAttribute(attribute.code);
          this.props.onClose();
        }
      })
    }else{
      if(isEmpty(attribute.typeAttribute))
        error.typeAttribute = true;
      this.setState({errors: error})
    }
  }

  deleteErrors = () =>{
    this.setState({errors: {}})
  };

  render() {
    const {attribute, showCommonFields, showAttributeOptions, typeAttributeMultiple, errors, isLoading, bShadow} = this.state;
    const {onClose} = this.props;

    return (
      <div>
        {
          !showAttributeOptions && (attribute.values && attribute.values.length === 0) ?
            <div className="setting-container" style={{height: "650px", display: 'block', position: "relative"}}>
              <AttributeHeader onClose={onClose} onSave={this.save} left={true}/>
              <div className="container-add-attribute">
                <div className="info-attribute">
                  <AttributeProperties attribute={attribute} updateAttribute={this.updateAttribute} errors={errors} deleteErrors={this.deleteErrors}/>
                  <div className="attribute-content">
                    <h4>Attribute content</h4>
                    {
                      errors && errors["typeAttribute"]
                        ? <p className="help-block" style={{marginBottom: "10px"}}>Please select an attribute type.</p>
                        : null
                    }
                    <div className="select-attribute" onClick={() => this.viewCommonFields(attribute.typeAttribute)} style={{borderColor: errors && errors["typeAttribute"] ? "#FF6565": "#c1c9cd"}}>
                      {
                        showCommonFields ? "Selecting..." : "Select Attribute Type"
                      }
                    </div>
                  </div>
                </div>
                {
                  showCommonFields ?
                    <div className="attribute-common-field">
                      <div className="header-common">
                        <div className="title">
                          Common Fields
                        </div>
                        <div className="icon">
                          <i className="fa fa-chevron-right" style={{color:"#818182"}} onClick={() => this.viewCommonFields(attribute.typeAttribute)}/>
                        </div>
                      </div>
                      <div>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.DATE}
                                       bShadow={bShadow[0]}
                                       src={imageDate}
                                       displayValue={'Date'}/>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple > 0}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.CHECKBOX}
                                       bShadow={bShadow[1]}
                                       src={imageExpand}
                                       displayValue={'Choose Options'}/>
                      </div>
                      <div>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple > 0}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.MULTI_SELECT}
                                       bShadow={bShadow[2]}
                                       src={imageSquare}
                                       displayValue={'Multi Select'}/>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.PARAGRAPH}
                                       bShadow={bShadow[3]}
                                       src={imageParagraph}
                                       displayValue={'Paragraph'}/>
                      </div>
                      <div>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple > 0}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.SELECT}
                                       bShadow={bShadow[4]}
                                       src={imageCheckBoxList}
                                       displayValue={'Options'}/>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple > 0}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.LIST}
                                       bShadow={bShadow[5]}
                                       src={imageList}
                                       displayValue={'List'}/>
                      </div>
                      <div>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.PASSWORD}
                                       bShadow={bShadow[6]}
                                       src={imagePasswordHidden}
                                       displayValue={'Password/Hidden'}/>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.SINGLE_TEXT}
                                       bShadow={bShadow[7]}
                                       src={imageSingleLine}
                                       displayValue={'Single Text'}/>
                      </div>
                      <div>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.TEXT_AREA}
                                       bShadow={bShadow[8]}
                                       src={imageTextArea}
                                       displayValue={'Text Area'}/>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.DYNAMIC_NUMBER}
                                       bShadow={bShadow[9]}
                                       src={imageDynamicNumber}
                                       displayValue={'Dynamic number'}/>
                      </div>
                      <div>
                        <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                                       viewAttributeOptions={this.viewAttributeOptions}
                                       inputType={InputTypes.NUMBER}
                                       bShadow={bShadow[10]}
                                       src={imageDynamicNumber}
                                       displayValue={'Number'}/>
                      </div>
                    </div> : null
                }
              </div>
            </div> : <AttributesOptions attribute={attribute}
                                        onSave = {this.save}
                                        caughtTextSettings = {this.caughtTextSettings}
                                        textParagraph = {attribute.displayValue}
                                        onClose = {onClose}
                                        updateAttribute={this.updateAttribute}
                                        updateAttributeType={this.updateAttributeType}
                                        viewType={attribute.id ? attribute.typeAttribute : this.state.nameAttributeOptions}
                                        errors = {errors}
                                        deleteErrors={this.deleteErrors}
            />
        }
      </div>
    )
  }
}