import React, {useRef, useState} from 'react'
import NavigationBar from "../components/navigationBar/NavigationBar";
import IntroBannerSection from "../components/introBannerSection/IntroBannerSection";
import FooterSection from "../components/footer/FooterSection";
import {pathServer} from "../../../../common/Constants";
import GDGButton, {BUTTON_TYPE} from '../components/GDGButton'
import SplitSection from '../components/SplitSection'

require('../homepage.scss')
require('./saleProcess.scss')

export default function SalesProcess() {
  window.scrollTo(0, 0)
  const navigationBar = useRef();

  const demoRequest = () => {
    if(navigationBar && navigationBar.current)
      navigationBar.current.handleRequestDemo()
  }

  return (
    <div id='gdg-home-page' className='sales-process'>
      <NavigationBar ref={navigationBar}/>
      <IntroBannerSection
        blueText={'SALES PROCESS'}
        title={"The easiest way to complete a sale"}
        description={"Say goodbye to our old ways of processing a sale. With our end-to-end sales " +
          "platform you will never look back. From finance to DMV paperwork, we've got all " +
          "the solutions you will need to start and close on a vehicle from with our platform."}
        imgUrl={pathServer.PATH_IMG + '/newHomePage/sale-process-intro.png'}
      />

      <SplitSection
        className={'sale-process-application after-intro-section-padding'}
        leftSection={
          <div>
            <p className={'title-font'} dangerouslySetInnerHTML={{
              __html: 'Unlock the power of sales automation'
            }}/>
            <p className={'regular-font'} dangerouslySetInnerHTML={{
              __html: 'Start a new sale with a finance application or within the customer view and print' +
                ' or e-sign all documents needed. Once the approval is received, complete the sale checklist' +
                ' and have your customers complete documentation needed to allow them to drive away with their dream vehicle.'
            }}/>
            <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
          </div>
        }
        rightSection={
          <div>
            <img alt={'img'} src={pathServer.PATH_IMG + '/newHomePage/sale-process-animation.gif'}/>
          </div>
        }

      />

      <FooterSection/>
    </div>
  )
}
