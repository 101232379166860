import AcceptUIForm from './AcceptUIForm'
import AcceptJsForm from './AcceptJsForm'
import React, { useEffect, useState } from 'react'
import { loadScripts } from '../../util/Utils'


const AuthorizeNet = ({config}) => {

  const [isScriptLoaded, setScriptLoaded] = useState(false)
  useEffect(()=>{
      loadScripts([config.scriptUrl]).then( _ => setScriptLoaded(true))
  },[])

  if(!isScriptLoaded)
    return null

  return (
    <>
      {
        config.formType === 'acceptUi' ? <AcceptUIForm config={config}/> : <AcceptJsForm config={config}/>
      }
    </>
  )
}

export default AuthorizeNet
