import React from 'react'
import {ReactSVG} from 'react-svg'
import {pathServer} from './Constants'

require('./DPRatingBarCount.scss')

const DPRatingBarCount = (props) => {
  const {text,completed} = props
  const progressValue = (completed).toString()

  return(
    <div className="container-rating-bar">
      <div className="number-row">
        <label className="number">{text}</label>
      </div>
      <div className="icon-star-container">
        <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_star_review.svg`} className='icon-star-progress'
                  beforeInjection={ svg => { svg.setAttribute('style', `fill: #D7DAE2`); }} />
      </div>
      <div className="progress-bar-container">
        <progress
          id="file"
          max="100"
          value={progressValue}
          className='progress-bar-container-main'
          color={'#A4AFB7'}
        >{`${progressValue}%`}
        </progress>
      </div>
      <div className="number-progress-container">
        <label className="number-progress">00</label>
      </div>

    </div>
  )
}

export default DPRatingBarCount