import React from 'react'
import {DPInput} from '../../../../../common/DPInput'
import {PAGE_HOME_ID, SECTION_TESTIMONIAL_ID} from '../../../../../../common/Constants'
import {cloneDeep} from 'lodash'

const testimoniesDefault = {
  id: SECTION_TESTIMONIAL_ID,
  displayValue: "Testimonies",
  title: "WHAT OUR CUSTOMER SAY"
}

const MAX_SIZE_SITE_TITLE = 45

const TestimoniesSection = (props) => {

  const {pages, onChangePages} = props
  const homePage = pages.find(x => x.id === PAGE_HOME_ID)
  const testimonialSection = homePage.sections.find(x => x.id === SECTION_TESTIMONIAL_ID)
  const title = testimonialSection ? testimonialSection.title : testimoniesDefault.title
  if(!testimonialSection)
    homePage.sections.push(testimoniesDefault)

  const onChangeTitle = (evt) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const testimonialSection = homePage.sections.find(x => x.id === SECTION_TESTIMONIAL_ID)
    testimonialSection.title = evt.currentTarget.value
    onChangePages(pagesNew)
  }

  return(
    <div className="container-home-testimonies">
      <div className="row-title">
        <DPInput
          id='title'
          title='Section title'
          rightElement={<label className={`label-size right ${title.length === MAX_SIZE_SITE_TITLE ? 'error-color' : ''}`}>{`${title.length}/${MAX_SIZE_SITE_TITLE}`}</label>}
          value={title}
          onChange={onChangeTitle}
          maxLength={MAX_SIZE_SITE_TITLE}
        />
      </div>
    </div>
  )
}

export default TestimoniesSection