import React, {Component} from 'react'
import {InputTypes} from '../../../Constants'
import AttributeProperties from './AttributeProperties.jsx'
import AttributeHeader from './AttributeHeader.jsx'
import Editor from './AttributeEditor.jsx'
import {AttributeType} from './AttributeSetting'
import {
  imageCheckBoxList, imageDate,
  imageDynamicNumber, imageExpand, imageList, imageParagraph,
  imagePasswordHidden,
  imageSingleLine, imageSquare,
  imageTextArea,
  pathServer
} from '../../../../common/Constants'

export function getShadowStyle(type){
  switch (type) {
    case InputTypes.DATE:
      return (0);
    case InputTypes.SELECT:
      return (1);
    case InputTypes.MULTI_SELECT:
      return (2);
    case InputTypes.PARAGRAPH:
      return (3);
    case InputTypes.CHECKBOX:
      return (4);
    case InputTypes.LIST:
      return (5);
    case InputTypes.PASSWORD:
      return (6);
    case InputTypes.SINGLE_TEXT:
      return (7);
    case InputTypes.TEXT_AREA:
      return (8);
    case InputTypes.DYNAMIC_NUMBER:
      return (9);
    case InputTypes.NUMBER:
      return (10);
    default:
      return (11);
  }
}

function getTypeAttributeMultiple(bShadow,type){
  let selectShadowArray = getShadowStyle(type);
  let typeAttributeMultiple = 0;
  const bShadowCurrent = [...bShadow]
  for (let i = 0; i < 10; i++) {
    bShadowCurrent[i] = "0 0 0";
  }
  bShadowCurrent[selectShadowArray] = "0 3px 6px";
  if(selectShadowArray === 1 || selectShadowArray === 2 || selectShadowArray === 4 || selectShadowArray === 5 )
    typeAttributeMultiple = 1;
  else if(selectShadowArray === 11)
    typeAttributeMultiple = 2;

  return {bShadowCurrent,typeAttributeMultiple}
}

export default class AttributesOptions extends Component {

  constructor() {
    super();
    this.onChangeDropDownForm = this.onChangeDropDownForm.bind(this);
    this.onChangeSetting = this.onChangeSetting.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addRow = this.addRow.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
    this.onMoveDown = this.onMoveDown.bind(this);
    this.onUp = this.onUp.bind(this);
    this.onDown = this.onDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.caughtText = this.caughtText.bind(this);
    this.state = {
      showCommonFields: false,
      optionsCompatibility: false,
      viewTypeOptions: "no",
      valueFormatDate: "MM/DD/YYYY",
      arrayValueFormatDate: [
        {
          format: "MM/DD/YYYY",
          status: true
        },
        {
          format: "DD/MM/YYYY",
          status: false
        },
        {
          format: "YYYY/MM/DD",
          status: false
        },

      ],
      formDropDown: {},
      attributesValues: [],
      bShadow: ["0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0"],
      typeAttributeMultiple: 0
    }
  }

  componentDidMount(){
    let {viewType,attribute,deleteErrors} = this.props;
    const {bShadow} = this.state
    const {bShadowCurrent,typeAttributeMultiple} = getTypeAttributeMultiple(bShadow,viewType);
    const state = {bShadow:bShadowCurrent,typeAttributeMultiple};
    if(attribute && attribute.values.length > 0)
      state.attributesValues = [...attribute.values];
    this.setState(state);
    if(deleteErrors)
      deleteErrors()
  }

  onChangeDropDownForm(type) {
    let {formDropDown} = this.state;
    if (formDropDown[type])
      delete formDropDown[type];
    else
      formDropDown[type] = true;

    this.setState({formDropDown: formDropDown})
  }

  onChangeSetting(type) {
    const value = this.state[type];
    this.setState({[type]: !value})
  }

  onChange(evt) {
    let newContent = evt.editor.getData();
    this.setState({content: newContent})
  }

  viewCommonFields() {
    this.setState({showCommonFields: !this.state.showCommonFields})
  }

  viewAttributeOptions(type) {
    const {bShadow} = this.state;
    const {bShadowCurrent,typeAttributeMultiple} = getTypeAttributeMultiple(bShadow,type);
    this.props.updateAttributeType(type);
    this.setState({viewTypeOptions: type, showCommonFields: false, typeAttributeMultiple: typeAttributeMultiple, bShadow: bShadowCurrent})
  }

  renderSwitch() {
    let {viewTypeOptions} = this.state;
    switch (viewTypeOptions) {
      case 'Date':
        return (
          <div className="check-option">
            <img src={imageDate} className="form-field-img" alt=""/>
            <span>Date </span>
          </div>);
      case 'Choose Options':
        return (<div className="tittle-option">
          <img src={imageExpand} className="form-field-img" alt=""/>
          <span>Choose Options</span>
        </div>);
      case 'Multi Select':
        return (<div className="check-option">
          <img src={imageSquare} className="form-field-img" alt=""/>
          <span>Multi Select</span>
        </div>);
      case 'Paragraph':
        return (<div className="check-option">
          <img src={imageParagraph} className="form-field-img" alt=""/>
          <span>Paragraph </span>
        </div>);
      case 'Options':
        return (<div className="tittle-list">
          <span><img src={imageCheckBoxList} className="form-field-img" alt=""/></span>
          <div style={{display: "inline"}}>Options</div>
        </div>);
      case 'List':
        return (<div className="tittle-list">
          <span><img src={imageList} className="form-field-img" alt=""/></span>
          <div style={{display: "inline"}}>List</div>
        </div>);
      case 'Password/Hidden':
        return (<div className="check-option">
          <img src={imagePasswordHidden} className="form-field-img" alt=""/>
          <span>Password/Hidden</span>
        </div>);
      case 'Single Text':
        return (<div className="tittle-list">
          <span><img src={imageSingleLine} className="form-field-img" alt=""/></span>
          <div style={{display: "inline"}}>Single Text</div>
        </div>);
      case 'Text Area':
        return (<div className="check-option">
          <img src={imageTextArea} className="form-field-img" alt=""  style={{width: "28px", height: "16px", marginTop: "13px"}}/>
          <span>Text Area</span>
        </div>);
      case 'Dynamic number':
        return (<div className="tittle-list">
          <span><img src={imageDynamicNumber} className="form-field-img" alt=""/></span>
          <div style={{display: "inline"}}>Dynamic number</div>
        </div>);
      default:
        return null;
    }
  }

  caughtText(editorState){
    let textParagraph = editorState.getCurrentContent().getPlainText();
    this.props.caughtTextSettings(textParagraph);
  }

  renderCommonFields() {
    let {formDropDown, viewTypeOptions} = this.state;
    switch (viewTypeOptions) {
      case 'Date':
        return (
          <table style={{marginBottom: "30px"}}>
            <tbody>
            <tr>
              <td style={{width: "60px"}}>
                <span>Format</span>
              </td>
              <td style={{width: "160px", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
                <div style={{border: "none", fontSize: "16px", width: "100%"}}>{this.state.valueFormatDate}</div>
              </td>
            </tr>
            </tbody>

          </table>
        );
      case 'Paragraph':
        return (<div className="row-form" style={{paddingBottom: "30px"}}>
          <Editor caughtText={this.caughtText} textParagraph={this.props.textParagraph}/>
        </div>);
      case 'Multi Select':
        return (null);
      case 'List':
        return (<div className="tittle-list">
          <div style={{display: "inline", marginLeft: 2}}>Labels</div>
          <span><img src={imageList} className="form-field-img" alt=""/></span>
        </div>);
      case 'Options':
        return (null);
      case 'Single Text':
        return (
          <table style={{marginBottom: "30px", marginLeft: "20px", width: "100%"}}>
            <tbody>
            <tr>
              <td style={{width: "80px", paddingLeft: "14px"}}>
                <span>Single Text</span>
              </td>
              <td style={{width: "75%", height: "35px", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
                <div style={{border: "none", fontSize: "16px", width: "100%", placeholder:"Single Text"}} />
              </td>
            </tr>
            </tbody>
          </table>
        );
      case 'Password/Hidden':
        return (<table style={{marginBottom: "30px", marginLeft: "20px"}}>
          <tbody>
          <tr>
            <td style={{width: "66px", paddingLeft: "14px"}}>
              <span>Password</span>
            </td>
            <td style={{width: "160px", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
              <div style={{border: "none", fontSize: "16px", width: "100%"}}>**************</div>
            </td>
          </tr>
          </tbody>
        </table>);
      case 'Text Area':
        return (<table style={{marginBottom: "30px", marginLeft: "20px", height:"45px"}}>
          <tbody>
          <tr>
            <td style={{width: "64px", paddingLeft: "14px", verticalAlign:"initial"}}>
            </td>
          </tr>
          </tbody>
        </table>);
      case 'Dynamic number':
        return (<table style={{marginBottom: "30px", marginLeft: "20px"}}>
          <tbody>
          <tr>
            <td style={{width: "60px", paddingLeft: "14px"}}>
              <span>Dynamic</span>
            </td>
            <td style={{width: "160px", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
              <div style={{border: "none", fontSize: "16px", width: "100%"}}>Number</div>
            </td>
          </tr>
          </tbody>
        </table>);
      default:
        return null;
    }
  }

  handleChange(e, index) {
    let attributesValuesEdit = [...this.state.attributesValues];
    const value = e.target.value;
    const property = e.target.name;
    attributesValuesEdit[index][property] = value;
    this.setState({attributesValues: attributesValuesEdit})
  }

  onMoveUp(e) {
    if (e.target.name > 0) {
      let {attributesValues} = this.state;
      let start_index = parseInt(e.target.name, 10);
      let attributesValuesActual = attributesValues[start_index];
      let attributesValuesBefore = attributesValues[start_index - 1];
      attributesValues.splice((start_index - 1), 2, attributesValuesActual, attributesValuesBefore);
      this.setState({attributesValues: attributesValues});
    }
  }

  onMoveDown(e) {
    let {attributesValues} = this.state;
    if (e.target.name < (attributesValues.length - 1)) {
      let start_index = parseInt(e.target.name, 10);
      let attributesValuesActual = attributesValues[start_index];
      let attributesValuesAfter = attributesValues[(start_index + 1)];
      attributesValues.splice(start_index, 2, attributesValuesAfter, attributesValuesActual);
      this.setState({attributesValues: attributesValues});
    }
  }

  renderRow() {
    let {attributesValues } = this.state;
    return (
      attributesValues.map((av, index) =>
        <tbody key={`tbody-${index}`}>
        <tr key={"tr" + index} style={{verticalAlign: "middle"}} className="rowCostumer">
          <td style={{borderTop: 0, width: "1%", paddingRight: "10px"}}>
            <div style={{display:"inline-grid"}}>
              <img name={index} className="icon-option" alt=""
                   style={{width: "15px", height: "15px", paddingTop: "0px", cursor: "pointer"}}
                   src={pathServer.PATH_IMG + "arrow3.png"} onClick={this.onMoveUp}/>
              <img name={index} className="icon-option" alt=""
                   style={{width: "15px", height: "15px", paddingTop: "0px", cursor: "pointer"}}
                   src={pathServer.PATH_IMG + "arrow2.png"} onClick={this.onMoveDown}/>
            </div>
          </td>
          <td style={{width: "40%", borderTop: 0, padding: "5px 1% 5px 0"}}>
            <div className="form-input-content" style={{width: "100%"}}>
              <div className="form-input">
                <input key={index} className="attribute-control" style={{height: "40px", borderRadius:"4px"}} name="code"
                       value={attributesValues[index].code} placeholder={"Code"}
                       onChange={(e) => this.handleChange(e, index)}/>
              </div>
            </div>
          </td>
          <td style={{width: "35%", borderTop: 0, padding: "5px 1% 5px 0"}}>
            <div className="form-input-content" style={{width: "100%", paddingRight: "0px"}}>
              <div className="form-input">
                <input className="attribute-control" style={{height: "40px", borderRadius:"4px"}} name="name"
                       value={attributesValues[index].name} placeholder={"Name"}
                       onChange={(e) => this.handleChange(e, index)}/>
              </div>
            </div>
          </td>
          <td style={{width: "20%", borderTop: 0, padding: "5px 1% 5px 0"}}>
            <div className="form-input-content" style={{width: "100%", paddingRight: "0px"}}>
              <div className="form-input">
                <input className="attribute-control" style={{height: "40px", borderRadius:"4px"}} name="displayValue"
                       value={attributesValues[index].displayValue} placeholder={"Display Value"}
                       onChange={(e) => this.handleChange(e, index)}/>
              </div>
            </div>
          </td>
          <td style={{paddingLeft: "0px", borderTop: 0}}>
            <div className="ic-delete">
              <i id="id-fa-icon" className="fa fa-trash" onClick={this.onDeleteRow}/>
            </div>
          </td>
        </tr>
        </tbody>
      )
    )
  }

  onUp() {
    for (let i = 0; i < 3; i++) {
      if (this.state.arrayValueFormatDate[i].status && i > 0) {
        this.state.valueFormatDate = this.state.arrayValueFormatDate[i - 1].format;
        this.state.arrayValueFormatDate[i - 1].status = true;
        this.state.arrayValueFormatDate[i].status = false;
        this.setState({valueFormatDate: this.state.arrayValueFormatDate[i - 1].format});
        i = 3;
      }
    }
  }

  onDown() {
    for (let i = 0; i < 3; i++) {
      if (this.state.arrayValueFormatDate[i].status && i < 2) {
        this.state.valueFormatDate = this.state.arrayValueFormatDate[i + 1].format;
        this.state.arrayValueFormatDate[i + 1].status = true;
        this.state.arrayValueFormatDate[i].status = false;
        this.setState({valueFormatDate: this.state.arrayValueFormatDate[i + 1].format});
        i = 3;
      }
    }
  }

  onDeleteRow(e, index) {
    let {attributesValues} = this.state;
    let start_index = parseInt(index);
    attributesValues.splice(start_index, 1);
    this.setState({attributesValues: attributesValues});
  }

  addRow() {
    let {attributesValues} = this.state;
    attributesValues.push({code: "", name: "", displayValue: "", order : attributesValues.length + 1});
    this.setState({attributesValues: attributesValues});
  }

  render() {
    let {showCommonFields, viewTypeOptions, optionsCompatibility, typeAttributeMultiple, attributesValues, bShadow} = this.state;
    let {onClose, onSave, attribute, updateAttribute, errors, viewType} = this.props;
    {
      optionsCompatibility = viewType === InputTypes.CHECKBOX || viewType === InputTypes.MULTI_SELECT || viewType === InputTypes.LIST || viewType === InputTypes.SELECT;
    }
    return (
      <div className="container-add-attribute" style={{height: "auto", display: 'block', position: "relative"}}>
        <AttributeHeader onClose={onClose} onSave={(evt) => onSave(evt,attributesValues)} left={false}/>
        {showCommonFields ?
          <div className="info-attribute">
            <AttributeProperties attribute={attribute} updateAttribute={updateAttribute}/>
            <div className="attribute-content">
              <h4>Attribute content</h4>
              <div className="form-property" style={{borderBottom: 0}}>
                <div id="border-attribute-option" className="row-form" style={{width: "70%"}}>
                  {this.renderSwitch()}
                  <div className="icon-options" id="img-attrib-options" onClick={() => this.viewCommonFields()}/>
                </div>
                <div id="border-attribute-info" className="row-form" style={{width: "28%"}}>
                  <img className="icon-option" alt=""
                       style={{width: "30px", height: "30px", marginTop: "5px", cursor: "pointer"}}
                       src={pathServer.PATH_IMG + "inverted.png"}/>
                  <span style={{padding: "10px 0px 0px 5px"}}>Add info text box</span>
                </div>
              </div>
              <div style={{fontSize: " 11px", paddingBottom: "30px"}}>
                {
                  this.renderCommonFields()
                }
              </div>
              <table className="table" cellSpacing="0" cellPadding="0">
                {this.renderRow()}
              </table>
              {
                optionsCompatibility ?
                  <div className="row-form" style={{paddingBottom: "30px"}}>
                    {
                      errors && errors["multipleAttributeValue"]
                        ? <p className="help-block">You must add at least one attribute value.</p>
                        : null
                    }
                    <div className="form-setting-button" style={{padding: "0px 0px"}}>
                      <button className="format-square-setting-button-add" onClick={this.addRow}>+</button>
                    </div>
                  </div>
                  : null
              }
            </div>
          </div>
          :
          <div className="info-attribute2">
            <AttributeProperties attribute={attribute} updateAttribute={updateAttribute}/>
            <div className="attribute-content">
              <div className="row-form">
                <div className="form-header-left">
                  <h4>Attribute content</h4>
                </div>
              </div>

              <div className="form-property" style={{paddingTop: "60px", borderBottom: 0}}>
                <div id="border-attribute-option" className="row-form">
                  {this.renderSwitch()}
                  <div className="icon-options" id="img-attrib-options" onClick={() => this.viewCommonFields()}/>
                </div>
                <div id="border-attribute-info" className="row-form">
                  <img className="icon-option" alt=""
                       style={{width: "30px", height: "32px", marginTop: "5px", cursor: "pointer"}}
                       src={pathServer.PATH_IMG + "inverted.png"}/>
                  <span style={{padding: "10px 0px 0px 5px"}}>Add info text box</span>
                </div>
              </div>
              <div style={{fontSize: " 11px", paddingBottom: "12px"}}>
                {
                  this.renderCommonFields()
                }
              </div>
              <table className="table" cellSpacing="0" cellPadding="0">
                {this.renderRow()}
              </table>
              {
                optionsCompatibility ?
                  <div className="row-form" style={{paddingBottom: "60px"}}>
                    {
                      errors && errors["multipleAttributeValue"]
                        ? <p className="help-block" style={{marginBottom: "10px"}}>You must add at least one attribute value.</p>
                        : null
                    }
                    <div className="form-setting-button" style={{padding: "0px 0px"}}>
                      <button className="format-square-setting-button-add" onClick={this.addRow}>+</button>
                    </div>
                  </div>
                  : null
              }
            </div>
          </div>
        }
        {
          showCommonFields ? <div className="attribute-common-field">
            <div className="header-common">
              <div className="title">
                Common Fields
              </div>
              <div className="icon">
                <i className="fa fa-chevron-right" style={{color: "#818182"}} onClick={() => this.viewCommonFields()}/>
              </div>
            </div>
            <div>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.DATE}
                             bShadow={bShadow[0]}
                             src={imageDate}
                             displayValue={'Date'}/>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple > 0}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.SELECT}
                             bShadow={bShadow[1]}
                             src={imageExpand}
                             displayValue={'Choose Options'}/>
            </div>
            <div>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple > 0}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.MULTI_SELECT}
                             bShadow={bShadow[2]}
                             src={imageSquare}
                             displayValue={'Multi Select'}/>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.PARAGRAPH}
                             bShadow={bShadow[3]}
                             src={imageParagraph}
                             displayValue={'Paragraph'}/>
            </div>
            <div>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple > 0}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.CHECKBOX}
                             bShadow={bShadow[4]}
                             src={imageCheckBoxList}
                             displayValue={'Options'}/>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple > 0}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.LIST}
                             bShadow={bShadow[5]}
                             src={imageList}
                             displayValue={'List'}/>
            </div>
            <div>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.PASSWORD}
                             bShadow={bShadow[6]}
                             src={imagePasswordHidden}
                             displayValue={'Password/Hidden'}/>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.SINGLE_TEXT}
                             bShadow={bShadow[7]}
                             src={imageSingleLine}
                             displayValue={'Single Text'}/>
            </div>
            <div>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.TEXT_AREA}
                             bShadow={bShadow[8]}
                             src={imageTextArea}
                             displayValue={'Text Area'}/>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.DYNAMIC_NUMBER}
                             bShadow={bShadow[9]}
                             src={imageDynamicNumber}
                             displayValue={'Dynamic number'}/>
            </div>
            <div>
              <AttributeType isTypeAttributeMultiple={typeAttributeMultiple === 0 || typeAttributeMultiple === 2}
                             viewAttributeOptions={this.viewAttributeOptions}
                             inputType={InputTypes.NUMBER}
                             bShadow={bShadow[10]}
                             src={imageDynamicNumber}
                             displayValue={'Number'}/>
            </div>
          </div> : null
        }
      </div>
    )
  }
}