import React, {useEffect, useState} from 'react'
import {browserHistory} from 'react-router'
import {connect} from 'react-redux'
import {setMenuId, setShowSideBar} from '../../../actions'
import {ReactSVG} from 'react-svg'
import {updatePopoverLeft} from '../../../Utils'
import {
  MENU_ITEM_DASHBOARD_ID,
  MENU_ITEM_SETTING_ID,
  pathServer,
  WIDTH_VIEW_MOBILE, WIDTH_VIEW_TABLET
} from '../../../../common/Constants'


const MENU_REVIEWS = [
  {id:"dashboard", permissionId: "", name:"Home", imageURL:pathServer.PATH_IMG+"icon/ic_dashboard_chat.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_dashboard_chat_selected.svg"},
  {id:"customer", permissionId: "can_access_customer_menu", name:"Customers",imageURL:pathServer.PATH_IMG+"icon/ic_customer.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_customer_selected.svg"},
];

const WrapperSidebar = ({ setShowSideBar, showSideBar, setMenuId, height, menuId, path, ...props}) => {
  const [widthPage, setWidthPage] = useState(window.innerWidth)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [formMenu, setFormMenu] = useState(MENU_REVIEWS)

  const onSelectMenu = (menuId) => {
    setMenuId(menuId)
    setShowMobileMenu(false)
    setShowSideBar(false)
    if(menuId === "customer"){
      browserHistory.push({pathname:"/main/"+menuId, state: { sourceId: 'review' }})
    } else{
      browserHistory.push({pathname:"/reviews/"+menuId})
    }
  }

  const onChangeSidebar = (e) => {
    let menuSidebar = window.document.getElementById("menuSidebar")
    let container = window.document.getElementById("container")
    let widthPage = window.innerWidth;
    if(widthPage <= WIDTH_VIEW_MOBILE){
      setShowMobileMenu(!showMobileMenu)
    }

    if(widthPage > WIDTH_VIEW_TABLET){
      if(e.target === menuSidebar){
        if(container){
          if(container.className === 'container-component' && !showSideBar){
            container.className += ' container-collapse'
            updatePopoverLeft("popoverBox",75)
          } else {
            container.className = 'container-component'
            updatePopoverLeft("popoverBox",-75)
          }
        }

      }
    }

    setShowSideBar(!showSideBar)
  }

  const handleResize = () => {
    // let container = window.document.getElementById("container");
    // let showMobileMenu = true;
    // if(window.innerWidth <= WIDTH_VIEW_TABLET){
    //   container.className = 'container-component';
    //   this.props.setShowSideBar(false);
    // }
    // if(window.innerWidth <= WIDTH_VIEW_MOBILE){
    //   showMobileMenu = false
    // }
    // this.setState({widthPage: window.innerWidth, showMobileMenu: showMobileMenu})
  }

  useEffect(() => {
    if(path !== ""){
      const paths = path.split("/")
      if(paths[2])
        setMenuId(paths[2])
      else
        setMenuId(MENU_ITEM_DASHBOARD_ID)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])

  return(
    <div id="sidebar" className={showSideBar ? "sidebar-collapse":"sidebar-collapse hide-sidebar"}
         style={(widthPage > WIDTH_VIEW_MOBILE || showMobileMenu) ? {height:height} : null}>
      <div className="left-navigation">
        <div className={showSideBar ? "menu-bar-collapse":"menu-bar-collapse hide-menu-bar"}>
          {
            showSideBar ?
              <div>
                <a id="menuSidebar1" className="menu-sidebar-1" onClick={(e) => onChangeSidebar(e)}>
                  <i id="menuSidebar" className="fa fa-times" />
                </a>
                <div className="logo-container">
                  <img src={pathServer.PATH_IMG+"icon/ic_dealer_selected.svg"}
                       className='logo-dealer'/>
                </div>
              </div> :
              <a onClick={(e) => onChangeSidebar(e)}>
                <i id="menuSidebar" className="fa fa-bars" />
              </a>
          }
        </div>
        <ul id="sidebar-menu" className="sidebar-menu" aria-multiselectable="true" >
          {
            formMenu.map((menu, index) => {
              return (
                <div key={index}>
                  <li id={`sidebar-${menu.id}`}
                      data-test={`sidebar-${menu.id}`}
                      onClick={() => onSelectMenu(menu.id)}
                      className={(menuId === menu.id) ? 'sidebar-icon-selected' : ''}>
                    <ReactSVG src={(menuId === menu.id) ? menu.imageURLSelected : menu.imageURL} className='icon-container'
                              beforeInjection={ svg => svg.classList.add('icon-menu')} />
                    {
                      showSideBar && <label id="menuLabelId" className="label-menu">{menu.name}</label>
                    }
                  </li>
                </div>
              )
            })
          }
        </ul>
        {
          <li className={ `sidebar-review-menu-setting ${(menuId === MENU_ITEM_SETTING_ID) ? "sidebar-icon-selected" : ""} ${showSideBar ? "setting-collapse" : "setting-hide"}`}
              onClick={() => onSelectMenu(MENU_ITEM_SETTING_ID)} data-test={'sidebar-menu-setting'}>
            <ReactSVG src={(menuId === MENU_ITEM_SETTING_ID) ? pathServer.PATH_IMG+"icon/ic_setting_selected.svg" : pathServer.PATH_IMG+"icon/ic_setting.svg"}
                      className='icon-container' beforeInjection={ svg => svg.classList.add('icon-menu')}/>
            { showSideBar && <label className="label-menu">Settings</label>}
          </li>
        }
      </div>

      {/*{
        props.showSideBarOnBottom
        && <div
          style={{
            display: 'block',
            position: 'fixed',
            zIndex: 100,
            paddingTop: '100px',
            left: 0,
            top: 0,
            width: '50px',
            height: '100%',
            overflow: 'auto',
            backgroundColor: 'rgba(33,148,241, 0.5)',
          }}
        />
      }*/}

    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    menuId:state.menuId,
    //showSideBarOnBottom: state.showSideBarOnBottom,
  }
}

export default connect(mapStateToProps,{setShowSideBar, setMenuId})(WrapperSidebar)