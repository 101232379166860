import DPButton from './DPButton'
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

require('./DPButtonLoading.scss')

const DPButtonLoading = (props) => {
  const [isHovering, setIsHovering] = useState(false)
  const {buttonClass, background, iconSendPath, width, height, onClick, textColor, borderColor, boxShadow, isLoading, isSaved, isDisable,
        btnSaveText, btnLoadingText, btnSavedText, hoverText} =  props
  const textButton = isLoading ? btnLoadingText : (isSaved ? btnSavedText : btnSaveText)
  const styleIsLoading = {background: '#FFF' , borderColor:'#CCC', color:'#2194F1'}
  const styleIsSaved = {background:'#4AD991', borderColor:'#4AD991', color: '#FFF'}
  const styleHover = {backgroundColor: '#005294', borderColor: '#005294', color: '#FFF'}
  const styleButton = isLoading ? styleIsLoading : isSaved ? styleIsSaved : {}
  const isDisabledButton = isDisable ||  isLoading ? true : !!isSaved

  const handleClick = () => {
    onClick()
    setIsHovering(false)
  }
  return(
    <DPButton className={classnames( 'button-loading', buttonClass)} width={width} heigth={height} onClick={handleClick}
              style={ isHovering ? styleHover : styleButton} disabled={isDisabledButton}
              onMouseLeave={hoverText ? () => setIsHovering(false) : null}
              onMouseEnter={hoverText ? () => setIsHovering(true) : null}>
      <>
        <div className="loading-content" style={{display:'flex'}}>
          {
            isLoading &&
            <div className="loader-container">
              <div className="loader"/>
            </div>
          }
          <span>{isHovering && hoverText ? hoverText : textButton}</span>

        </div>
      </>
    </DPButton>
  )
};

DPButtonLoading.defaultProps = {
  background: '#FFF',
  textColor: '#2194F1',
  width: 'auto',
  height: 'auto',
  btnSaveText:'Save',
  btnLoadingText:'Saving...',
  btnSavedText:'Saved'
}

DPButtonLoading.propTypes = {
  background: PropTypes.string,
  textColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  iconSendPath: PropTypes.string,
  buttonClass: PropTypes.string
}

export default DPButtonLoading