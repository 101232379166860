import React, {Component} from 'react'
import {browserHistory} from 'react-router'
import {
  MENU_SETTING_PERMISSION_ID, REVIEWS_DASHBOARD_PERMISSION_CODE,
  ROLES_SETTING_PERMISSION_ID,
  USERS_SETTING_PERMISSION_ID,
} from '../../Constants'
import {connect} from 'react-redux'
import {setKeyword, setMenuId, setShowSideBar} from '../../actions'
import {ReactSVG} from 'react-svg'
import UserHasPermission from '../UserHasPermission.jsx'
import Badge from 'material-ui/Badge'
import {
  pathServer,
  WIDTH_VIEW_MOBILE,
  WIDTH_VIEW_TABLET
} from '../../../common/Constants'

function updatePopoverLeft(id,left) {
  let popoverBox = window.document.getElementById(id);
  if(popoverBox){
    const leftList = popoverBox.style.left.toString().split("px");
    popoverBox.style.left = (parseInt(leftList[0]) + left) + "px";
  }
}

const WIDTH_TASK_HEADER = 1612;
const MENU_MOBILE_VIEW = ["dashboard", "task", "inventory", "customer"]
const MENU_USER = [
  {id:"dashboard", permissionId: "", name:"Home", imageURL:pathServer.PATH_IMG+"icon/ic_dashboard.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_dashboard_selected.svg"},
  {id:"task", permissionId: "can_access_task_menu", name:"Tasks",imageURL:pathServer.PATH_IMG+"icon/ic_task.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_task_selected.svg"},
  {id:"inventory", permissionId: "can_access_inventory_menu", name:"Inventory",imageURL:pathServer.PATH_IMG+"icon/ic_inventory.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_inventory_selected.svg"},
  {id:"message", permissionId: "can_access_messages_menu", name:"Messages",imageURL:pathServer.PATH_IMG+"icon/ic_message.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_message_selected.svg"},
  {id:"customer", permissionId: "can_access_customer_menu", name:"Customers",imageURL:pathServer.PATH_IMG+"icon/ic_customer.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_customer_selected.svg"},
  {id:"reviews", permissionId: REVIEWS_DASHBOARD_PERMISSION_CODE, name:"Reviews", imageURL:pathServer.PATH_IMG+"icon/ic_dashboard_chat.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_dashboard_chat_selected.svg"},
  {id:"marketing", permissionId: "can_access_marketing_menu", name:"Marketing",imageURL:pathServer.PATH_IMG+"icon/ic_marketing.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_marketing_selected.svg"},
  {id:"sale", permissionId: "can_access_new_sales_menu", name:"Sale",imageURL:pathServer.PATH_IMG+"icon/ic_sale.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_sale_selected.svg"}
];

const MENU_SUPERUSER = [
  {id:"dashboard",name:"Home", imageURL:pathServer.PATH_IMG+"icon/ic_dashboard.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_dashboard_selected.svg"},
  {id:"superusers/dealers",name:"Dealers",imageURL:pathServer.PATH_IMG+"icon/ic_dealer.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_dealer_selected.svg"},
  {id:"superusers/users",name:"Superusers",imageURL:pathServer.PATH_IMG+"icon/ic_superuser.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_superuser_selected.svg"},
  {id:"superusers/webhookData",name:"Webhook Data", imageURL:pathServer.PATH_IMG+"icon/ic_marketing.svg", imageURLSelected:pathServer.PATH_IMG+"icon/ic_marketing_selected.svg"},
  {id:"superusers/plans",name:"PlansAndPackages",imageURL:pathServer.PATH_IMG+"icon/ic_plans.svg",imageURLSelected:pathServer.PATH_IMG+"icon/ic_plans_selected.svg"}
];

class WrapperSidebar extends Component{

  constructor(){
    super();
    this.state = {
      selectedMenuId: "dashboard",
      widthPage: window.innerWidth,
      showMobileMenu: false,
      isSuperuser: false,
      formMenu :[]
    };
  }

  componentDidMount(){
    const {path,isSuperuser, userRoles = {} } = this.props;
    let state = {};
    let paths
    if(path !== ""){
      if(!isSuperuser){
        paths = path.split("/");
        state.selectedMenuId = paths[0];
      } else {
        paths = path.split("/", 2);
        state.selectedMenuId = `${paths[0]}${paths[1] ? `/${paths[1]}` : ''}`
      }
    }

    if(isSuperuser){
      state.formMenu = MENU_SUPERUSER
      if(Object.keys(userRoles).includes('superuser_marketing_role')){
        state.formMenu = state.formMenu.filter( menuItem => menuItem.id === 'superusers/webhookData' )
      }

    } else {
      state.formMenu = MENU_USER;
    }

    this.setState(state)
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onSelectMenu = (menuId) => {
    const {isSuperuser, setShowSideBar, setMenuId, setKeyword} = this.props
    this.setState({selectedMenuId:menuId, showMobileMenu:false});
    setShowSideBar(false);
    setMenuId(menuId);
    // Added updatedPath variable to let go to Settings/Inventory when Settings icon is clicked
    const updatedPath = menuId === "setting" ? "setting/inventory" : menuId
    if (isSuperuser)
      setKeyword('')
    browserHistory.push({pathname: `/main/${updatedPath}`});
  }

  onHandle = (typeId) => {
    switch(typeId){
      case 1 :
        browserHistory.push({pathname:"/main/voucher", state:{typeId:typeId}});
        //this.active(type);
        break;
      default :
        //this.active(type);
    }
  }

  active = (type) => {
    this.setState({[type]:"active"});
  }

  onChangeSidebar = (e) => {
    e.preventDefault();
    let {showSideBar} = this.props;
    let {showMobileMenu} = this.state;
    let container = window.document.getElementById("container");
    let modalSearch = window.document.getElementById("modalSearch");
    let widthPage = window.innerWidth;
    if(widthPage <= WIDTH_VIEW_MOBILE){
      this.setState({showMobileMenu: !showMobileMenu})
    }

    if(widthPage > WIDTH_VIEW_TABLET){
      let left;
      if(e.target.id === 'menuSidebar'){
        if(container?.className === 'container-component' && !showSideBar){
          container.className += ' container-collapse';
          if(modalSearch)
            modalSearch.className += ' modal-collapse';

          updatePopoverLeft("popoverBox",75);
          left = 150;
        } else {
          if (container)
            container.className = 'container-component';

          if(modalSearch)
            modalSearch.className = 'modal-search';

          updatePopoverLeft("popoverBox",-75);
          left = -150;
        }

        const card = document.getElementById('taskSectionHeader');
        if(card){
          const compStyles = window.getComputedStyle(card);
          const leftString = compStyles.getPropertyValue('left');
          const styleLeft = parseInt(leftString.split('px')[0]);
          if(card){
            const carLeft = styleLeft + left;
            card.style.left = `${carLeft}px`
          }

          const bodyTask = document.getElementById('bodyTask');
          if(bodyTask && bodyTask.offsetWidth > WIDTH_TASK_HEADER){
            const taskSectionBody = document.getElementById('taskSectionBody');
            const widthHeader = taskSectionBody.offsetWidth - left;
            card.style.width = `${widthHeader}px`;
          }
        }
      }
    }

    this.props.setShowSideBar(!showSideBar);
  }

  handleResize = () => {
    let container = window.document.getElementById("container");
    let showMobileMenu = true;
    if(window.innerWidth <= WIDTH_VIEW_TABLET){
      container.className = 'container-component';
      this.props.setShowSideBar(false);
    }
    if(window.innerWidth <= WIDTH_VIEW_MOBILE){
      showMobileMenu = false
    }
    this.setState({widthPage: window.innerWidth, showMobileMenu: showMobileMenu})
  }

  render(){
    let {selectedMenuId, widthPage, showMobileMenu, formMenu} = this.state;
    const {showSideBar,height,menuId,logoUrl,isSuperuser}  = this.props;

    if(formMenu.length === 0)
      return null

    const isMobileView = widthPage <= WIDTH_VIEW_MOBILE
    if(isMobileView)
      formMenu = formMenu.filter(menu => MENU_MOBILE_VIEW.includes(menu.id))

    if(menuId)
      selectedMenuId = menuId;

    return(
      <div id="sidebar" className={showSideBar ? "sidebar-collapse":"sidebar-collapse hide-sidebar"}
           style={(widthPage > WIDTH_VIEW_MOBILE || showMobileMenu) ? {height:height} : null}>
        <div className="left-navigation">
          <div className={showSideBar ? "menu-bar-collapse":"menu-bar-collapse hide-menu-bar"}>
            {
              showSideBar ?
                <div>
                  <a id="menuSidebar1" className="menu-sidebar-1" onClick={this.onChangeSidebar}>
                    <i id="menuSidebar" className="fa fa-times" />
                  </a>
                  <div className="logo-container">
                    <img src={(logoUrl) ? logoUrl : pathServer.PATH_IMG+"icon/ic_dealer_selected.svg"}
                             className='logo-dealer'/>
                  </div>
                </div> :
                <a onClick={this.onChangeSidebar}>
                  <i id="menuSidebar" className="fa fa-bars" />
                </a>
            }
          </div>
          <ul id="sidebar-menu" className="sidebar-menu" aria-multiselectable="true" >
            {
              formMenu.map((menu, index) => {
                return (
                  <div key={index} style={{width:"inherit"}}>
                    <UserHasPermission permissionId={menu.permissionId}>
                      <li id={`sidebar-${menu.id}`}
                          data-test={`sidebar-${menu.id}`}
                          onClick={() => this.onSelectMenu(menu.id)}
                          className={(selectedMenuId === menu.id) ? 'sidebar-icon-selected' : ''}>

                        { menu.id === "message" && this.props.newFinanceApplicationsCounter > 0
                          ? <Badge className={'custom-badge'} variant="dot" badgeContent=''>
                            <ReactSVG src={(selectedMenuId === menu.id) ? menu.imageURLSelected : menu.imageURL} className='icon-container'
                                      beforeInjection={ svg => svg.classList.add('icon-menu')}
                            />
                          </Badge>
                          : <ReactSVG src={(selectedMenuId === menu.id) ? menu.imageURLSelected : menu.imageURL} className='icon-container'
                                      beforeInjection={ svg => svg.classList.add('icon-menu')}
                          />
                        }

                        {
                          showSideBar && <label id="menuLabelId" className="label-menu">{menu.name}</label>
                        }
                      </li>
                    </UserHasPermission>
                  </div>
                )
              })
            }
            {
              !isSuperuser &&
                  <UserHasPermission permissionId={MENU_SETTING_PERMISSION_ID} permissionIds={[MENU_SETTING_PERMISSION_ID, ROLES_SETTING_PERMISSION_ID, USERS_SETTING_PERMISSION_ID]}>
                    {
                      showSideBar ?
                        <div className="container-message-btn-setting">
                          {
                            <li id='sidebar-more-options' className={(selectedMenuId === "setting") ? "sidebar-menu-setting sidebar-icon-selected " :"sidebar-menu-setting "}>
                              <label className="label-menu" style={{width: '155px'}}>
                                More options are available on desktop-web version
                              </label>
                              <ReactSVG src={pathServer.PATH_IMG+"icon/ic_help.svg"}
                                  className='icon-container' beforeInjection={ svg => svg.classList.add('icon-menu')}/>
                            </li>
                          }
                          {
                            !isMobileView && <li className={(selectedMenuId === "setting") ? "sidebar-menu-setting sidebar-icon-selected setting-collapse" :"sidebar-menu-setting setting-collapse"}
                                onClick={() => this.onSelectMenu("setting")} data-test={'sidebar-menu-setting'}>
                              <ReactSVG src={(selectedMenuId === "setting") ? pathServer.PATH_IMG+"icon/ic_setting_selected.svg" : pathServer.PATH_IMG+"icon/ic_setting.svg"}
                                        className='icon-container' beforeInjection={ svg => svg.classList.add('icon-menu')}/>
                              <label className="label-menu">Settings</label>
                            </li>
                          }
                        </div>
                        :(
                          (widthPage > WIDTH_VIEW_MOBILE) ?
                            <div className="container-message-btn-setting">
                            <li id='sidebar-more-options' className={(selectedMenuId === "setting") ? "sidebar-menu-setting sidebar-icon-selected setting-hide" :"sidebar-menu-setting setting-hide"}
                            >
                              <ReactSVG src={pathServer.PATH_IMG+"icon/ic_help.svg"}
                                        className='icon-container' beforeInjection={ svg => svg.classList.add('icon-menu')}/>
                            </li>
                              <li className={(selectedMenuId === "setting") ? "sidebar-menu-setting sidebar-icon-selected setting-hide" :"sidebar-menu-setting setting-hide"}
                                onClick={() => this.onSelectMenu("setting")} data-test={'sidebar-menu-setting'}>
                              <ReactSVG src={(selectedMenuId === "setting") ? pathServer.PATH_IMG+"icon/ic_setting_selected.svg" : pathServer.PATH_IMG+"icon/ic_setting.svg"}
                                        className='icon-container' beforeInjection={ svg => svg.classList.add('icon-menu')}/>
                            </li> </div>: null

                        )
                    }
                  </UserHasPermission>
            }
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    menuId:state.menuId,
    logoUrl:state.logoUrl,
    isSuperuser: state.isSuperuser,
    newFinanceApplicationsCounter: state.newFinanceApplicationsCounter,
    userRoles: state.userRoles
  }
};

export default connect(mapStateToProps,{setShowSideBar,setMenuId, setKeyword})(WrapperSidebar)
