import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import { siteColorAdmin, CUSTOMERS_BY_PAGE_BY_DEFAULT, colors, ratingScore } from '../../Constants'
import Moment from 'moment'
import {cloneDeep, debounce, findIndex, isEmpty, orderBy} from 'lodash'
import {TitleHeader} from './SaleEdit.jsx'
import {loadCustomersApi, updateSale, loadCustomersBySaleId, updateCreditScore} from '../../Api'
import {Modal} from 'react-bootstrap'
import ElementTarget from '../form/ElementTarget.jsx'
import ElementDragDrop from '../form/ElementDragDrop.jsx'
import ModalConfirmDelete from '../util/ModalConfirmDelete.jsx'
import DragDropContext from '../util/DragDropContext'
import {browserHistory} from 'react-router'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import {DPPagination} from '../../../common/DPPagination'


export const ContentUser = ({user, showInfo, notShowIcon,index, handleOrderPurchaser, readOnly}) => {
  const name = getName(user.firstName, user.lastName, user.middleName)
  return(
      <div key={`user${index}`}
           className={"container-image-purchaser" + (readOnly ? " container-image-purchaser-readonly" : "")}
           onClick={() => !readOnly ? showInfo(user) : null}
           id={"imageTarget"+index}>
        {
          !notShowIcon && !readOnly ?
            <div className="container-icon">
              <div className="icon-arrow-content">
                <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                          className="icon-arrow" beforeInjection={ svg => svg.classList.add('icon-arrow-svg')}
                          onClick={(evt) => !readOnly ? handleOrderPurchaser(user,"up", evt) : null}/>
                <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                          className="icon-arrow" beforeInjection={ svg => svg.classList.add('icon-arrow-svg')}
                          onClick={(evt) => !readOnly ? handleOrderPurchaser(user,"down", evt) : null}/>
              </div>
            </div> : null
        }
        <div className="container-icon-purchaser">
          <img src={user.image ? user.image : `${pathServer.PATH_IMG}user.png`} className="icon-purchaser"/>
        </div>
        <div className="container-info-purchaser">
          <div className="content-name-purchaser">
            <label className="name-purchaser">{name}</label>
            {
              !notShowIcon && !readOnly ?
                <i className="fa fa-chevron-right icon-right" /> : null
            }
          </div>
          <label className="email-purchaser">{!isEmpty(user.email) ? user.email : getMessageNotField("email")}</label>
          <label className="phone-purchaser">{!isEmpty(user.phoneNumber) ? user.phoneNumber : getMessageNotField("phone number")}</label>
          <div className="content-credit-score">
            <label className="title-credit-score">{(user.creditScore !== undefined) ? `CREDIT SCORE: ${user.creditScore}` : getMessageNotField("credit score")}</label>
          </div>
        </div>
      </div>
  )
};

const TitlePurchaser = ({name, onClick}) => {
  return(
    <div className="title-purchaser">
      <div className="container-icon-left" onClick={onClick}>
        <i className="fa fa-chevron-left icon-left"/>
      </div>
      <label className="name-purchaser-selected">{name}</label>
    </div>
  )
};

const barCredit = [
  {id:"0", style: {borderRadius:"10px 0 0 10px", backgroundColor: colors.YELLOW}},
  {id:"1", style: {backgroundColor: colors.RED}},
  {id:"2", style: {backgroundColor: colors.LIGHT_GREEN}},
  {id:"3", style: {borderRadius:"0 10px 10px 0", marginRight:0, backgroundColor: colors.DARK_GREEN}}
];

const BodyPurchaser = ({customer, editCredit, onClick, onChange, close, save, isLoading, showModalDelete}) => {
  const date = Moment(new Date(customer.createDate)).format('MMMM YYYY');
  const category = customer.principal ? "Principal" : "Co-signer"
  const pointCreditColor = customer.creditScore <= 0 ? colors.YELLOW : customer.creditScore <= 599 ? colors.RED : customer.creditScore <= 720 ? colors.LIGHT_GREEN : customer.creditScore <= 850 ? colors.DARK_GREEN : colors.YELLOW;
  const descriptionScore = customer.creditScore <= 0 ? ratingScore.NO_CREDIT : customer.creditScore <= 599 ? ratingScore.BAD_CREDIT : customer.creditScore <= 720 ? ratingScore.GOOD_CREDIT : customer.creditScore <= 850 ? ratingScore.EXCELLENT_CREDIT : ratingScore.NO_CREDIT;
  const name = getName(customer.firstName, customer.lastName, customer.middleName)
  return(
    <div className="body-purchaser">
      <div className="container-purchaser-selected">
        <div className="purchaser-info">
          <div className="purchaser-info-icon">
            <img src={customer.image ? customer.image : `${pathServer.PATH_IMG}user.png`} className="icon-purchaser-selected"/>
          </div>
          <div className="customer-info-text">
            <label className="purchaser-name">{name}</label>
            <label className="purchaser-category">{category}</label>
            <div className="bar-purchaser" />
            <label className="purchaser-email">{!isEmpty(customer.email) ? customer.email : getMessageNotField("email")}</label>
            <label className="purchaser-phone">{!isEmpty(customer.phoneNumber) ? customer.phoneNumber : getMessageNotField("phoneNumber")}</label>
            <label className="purchaser-address">{!isEmpty(customer.address) ? customer.address : getMessageNotField("address")}</label>
            <label className="purchaser-date">{`Customer since ${date}`}</label>
          </div>
        </div>
        <div className="customer-credit-score">
          <div className="container-title-credit-score">
            <label className="credit-score">{`Credit Score`}</label>
            {
              !editCredit ?
                  <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_edit-pencil.svg`} className="edit-pencil"
                            onClick={onClick}/> :
                  <div className="container-icon-credit-score">
                    <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_close.svg`} className="icon-close-credit-score"
                              onClick={close}/>
                    <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_check.svg`} className="icon-edit-credit-score"
                              onClick={() => save(customer)}/>
                  </div>
            }
          </div>
          {
            !editCredit ?
                isLoading ?
                  <div className="loader-container" style={{position: 'absolute'}}>
                    <div className="loader"/>
                  </div> :
                  <label className="number-credit-score">{(customer.creditScore !== undefined) ? `${customer.creditScore}` : getMessageNotField("credit score")}</label> :
                <input id="creditScore" className="form-control input-left" value={customer.creditScore}
                       onChange={(evt) => onChange("creditScore", evt)}
                       style={{width: "50%", color: "#787878 !important", fontSize: 21, marginBottom: 6}}/>

          }
          <div className="bar-credit-score">
            {
              barCredit.map((bar, index) =>
                <div key={bar.id}
                     className="bar-general"
                     style={{backgroundColor: pointCreditColor/*...bar.style, "opacity": index === pointCredit ? 1 : 0.5*/}} />
              )
            }
          </div>
          <label className="credit-rating">{(customer.creditScore !== undefined) ? descriptionScore : ratingScore.NO_CREDIT }</label>
        </div>
      </div>
      <img src={`${pathServer.PATH_IMG}/deleteCustomer.png`} className="icon-trash-customer"
           onClick={showModalDelete}/>
    </div>
  )
};

function getMessageNotField(field) {
  return <p style={{color: "#D7DAE2", marginBottom:0, fontWeight:400}}>{`No ${field} yet`}</p>
}

const ModalSelectPurchaser = ({show, onHide, keyword, handleSearch, customers, handleSelect, showCheck,handleCheck,
                                selectedCustomers,saveCosigners,hasChangeCosigner,isLoading, purchaserId,
                                handlePageChange, totalPages, currentPage,goToCustomer,emptyCustomer}) => {
  return(
    <Modal show={show} className={'sale-modal-container modal-new-sale modal-edit-sale' + (emptyCustomer ? ' modal-empty':'')} backdropClassName="backdrop-dealer">
      <Modal.Body>
        {
          isLoading ?
            <div className="loader-container" style={{position: 'absolute'}}>
              <div className="loader"/>
            </div> : null
        }
        <div className="panel-container" style={isLoading ? {opacity: 0.4} : null}>
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                    beforeInjection={ svg => svg.classList.add('icon-close')} onClick={onHide}/>
          <div className="panel-container-body">
            {
              !emptyCustomer ?
                <>
                  <div className="container-header">
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_search.svg"}
                              className="icon-search-content" beforeInjection={ svg => svg.classList.add('icon-search')}/>
                    <input id="search" className='input-search'
                           value={keyword}
                           onChange={handleSearch}
                           placeholder="Search by Name, Phone, Email"
                    />
                   <div className="container-header-right-options">
                     <DPPagination
                       showItemPerPage={false}
                       currentPage={currentPage}
                       totalPages={totalPages}
                       handlePageChange={handlePageChange}
                       siteColor={siteColorAdmin}
                     />

                     {
                       !showCheck ?
                         null :
                         <div className={hasChangeCosigner ? 'cosigner-check' : 'cosigner-check cosigner-check-disabled'}>
                           <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_check.svg`} className='icon-check'
                                     beforeInjection={ svg => svg.classList.add("icon-check-svg-completed")}
                                     onClick={hasChangeCosigner ? saveCosigners : null} />
                         </div>
                     }
                   </div>

                  </div>
                  <div className="container-body">
                    <div className="table-customer table-customer-modal">
                      <table className="table">
                        <thead className={'table-header'} style={{backgroundColor: "#EFEFEF"}}>
                        <tr>
                          {showCheck && (
                            <th className="th-check">
                              <div className="check-header">
                                {selectedCustomers.length > 0 && <span>{selectedCustomers.length }</span>}
                              </div>
                            </th>
                          )}
                          <th className={showCheck ? "th-photo th-photo-check": "th-photo"}>Photo</th>
                          <th className="th-user">User name</th>
                          <th className="th-email">Email</th>
                          <th className="th-phone">Phone number</th>
                        </tr>
                        </thead>
                        <tbody className={'table-body'}>
                        {
                          customers.map((customer, index) => {
                            const {id,firstName,lastName,image,email,phone,middleName} = customer;
                            const indexFound = findIndex(selectedCustomers, (selectedCustomer) => { return selectedCustomer.customerId === customer.id});
                            const name = getName(firstName, lastName, middleName)
                            let foundPurchaser = false;
                            if(purchaserId === id)
                              foundPurchaser = true
                            return (
                              <tr key={`tr-${index}`} className="tr-customer" onClick={() => !showCheck ? handleSelect(customer) : null}
                                  style={foundPurchaser || showCheck ? {cursor: "default"} : null} data-test={`tr-sale-select-customer-${index}`}>
                                {showCheck && (
                                  <td className="td-check">
                                    <div key={`customer${index}`} className="content-check">
                                      <label className="container-check">
                                        <input type="checkbox" checked={indexFound !== -1} onChange={() => handleCheck(customer)} disabled={foundPurchaser}/>
                                        <span className="checkmark" style={{border: "1.4px #3B86FF solid", opacity: foundPurchaser ? 0.3 : 1}}/>
                                        {
                                          indexFound !== -1 ? <i className="fa fa-check i-check"/> : null
                                        }
                                      </label>
                                    </div>
                                  </td>
                                )
                                }
                                <td className={showCheck ? "td-image td-image-check": "td-image"}>
                                  {
                                    image ?
                                      <img className={"image-user"} src={image}/> :
                                      <div className="user-box-general">
                                        <img className={"image-user-default"} style={foundPurchaser ? {opacity:0.3} : null}
                                             src={pathServer.PATH_IMG + "ic_user_group.png"}/>
                                      </div>
                                  }
                                </td>
                                <td className="td-user" style={foundPurchaser ? {opacity:0.3} : null}>{name}</td>
                                <td className="td-email" style={foundPurchaser ? {opacity:0.3} : null}>{email}</td>
                                <td className="td-phone" style={foundPurchaser ? {opacity:0.3} : null}>{phone}</td>
                              </tr>
                            )
                          })
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </> :
                <div className={'customer-list-empty'}>
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_customer_selected.svg"}
                            className="icon-customer-empty-content" beforeInjection={ svg => svg.classList.add('icon-customer-empty')}
                  />
                  <label>Customer list is empty</label>
                  <span>Please <a onClick={goToCustomer}>create a new customer</a> in order to continue the sale</span>
                </div>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
};

function getName(firstName, lastName, middleName) {
  let name = ""
  if(middleName === ""){
    name = `${firstName} ${lastName}`
  } else{
    name = `${firstName} ${middleName} ${lastName}`
  }
  return name
}

const sectionsInitial = [
    {id: "principal", value:"Main Purchaser", principal: true, className: "container-purchase-principal", classNameTitle: "title-purchase-principal"},
    {id:"coSigners", value:"Co-signers", principal: false, className: "container-co-purchaser", classNameTitle: "title-co-purchaser"}];

const styleDrag = {
  borderRadius : 5,
  border : "1px #A4AFB7 dashed",
  position: "relative"
};

const styleTarget = {
  width: "100%",
  height:"100%",
  alignItems:"center",
  justifyContent:"center",
  borderRadius : 5
};

const styleBorder = "1px #2194F1 dashed";

class SalePurchaser extends Component{
  constructor(){
    super();
    this.addCustomer          = this.addCustomer.bind(this);
    this.addCoCustomer        = this.addCoCustomer.bind(this);
    this.showInfoCustomer     = this.showInfoCustomer.bind(this);
    this.handleEditCredit     = this.handleEditCredit.bind(this);
    this.onChangeCreditScore  = this.onChangeCreditScore.bind(this);
    this.closeInfoCustomer    = this.closeInfoCustomer.bind(this);
    this.handleSearch         = this.handleSearch.bind(this);
    this.onHide               = this.onHide.bind(this);
    this.handleSelectPurchaserMain = this.handleSelectPurchaserMain.bind(this);
    this.handleCheck          = this.handleCheck.bind(this);
    this.saveCosigners        = this.saveCosigners.bind(this);
    this.loadCustomers        = this.loadCustomers.bind(this);
    this.changeKeyword        = debounce(this.loadCustomers,500);
    this.loadPurchasers       = this.loadPurchasers.bind(this);
    this.updateItemStyle      = this.updateItemStyle.bind(this);
    this.handleDropElement    = this.handleDropElement.bind(this);
    this.handleOrderPurchaser = this.handleOrderPurchaser.bind(this);
    this.updatechangeKeywordSections       = this.updateSections.bind(this);
    this.updateOrder          = this.updateOrder.bind(this);
    this.saveCreditScore      = this.saveCreditScore.bind(this);
    this.closeEditCredit      = this.closeEditCredit.bind(this);
    this.showModalDelete      = this.showModalDelete.bind(this);
    this.deletePurchaser      = this.deletePurchaser.bind(this);
    this.state = {
      selectOpened: "",
      paymentMethodSelected: "cash",
      selectCustomer: false,
      selectCoCustomer: false,
      isLoading: false,
      saleCar: null,
      saleChecklist: null,
      checklist: null,
      dealerFiles: null,
      saleNotes: [],
      showModalCancel: false,
      existReason: false,
      isRequired: false,
      optionsCancelSale: null,
      reasonSelected: null,
      isLoadingModal: false,
      sale: {},
      purchaser:{},
      cosigners:[],
      showInfoCustomer: false,
      customerSelected: {},
      editCredit: false,
      showModalPurchaser: false,
      customers: [],
      keyword: '',
      selectedPurchaserMain: {principal:true, order: 1},
      showCheck:false,
      selectedCustomers:[],
      hasChangeCosigner:false,
      heightImageGallery: null,
      isUpdateOrderCustomers: false,
      sections: sectionsInitial,
      customersSale: [],
      creditScore: null,
      purchaserId: null,
      showModalDelete: false,
      from: 0,
      currentPage: 1
    };
  }

  async componentWillMount() {
    const {selectCustomer, selectCoCustomer, saleId, salesmanId, customersSale, existPurchaser, isComplete} = this.props;
    let {sections, purchaserId} = this.state;
    let  selectedCustomers = [];

    const {responseCode, responseMessage, data} = await loadCustomersApi()
    if (responseCode === SUCCESSFUL_CODE) {
      let customersFiltered = [...data.customers];
      let orderCustomer = 1;
      const customersQuantity = data.customersQuantity;
      const totalPages = customersQuantity ? Math.ceil(customersQuantity / CUSTOMERS_BY_PAGE_BY_DEFAULT) : 1;
      if(customersSale.length > 0){
        const userPrincipal = customersSale.filter(customer => { return customer.principal === true});
        purchaserId = userPrincipal[0].id
        sections.forEach((section) => {
          const users = customersSale.filter(customer => { return customer.principal === section.principal});
          users.forEach(user => {
            if(user.order === ""){
              user.order = orderCustomer
            }
            orderCustomer += 1
          })
          if(section.principal === false){
            selectedCustomers = users.map(user => {return {customerId: user.id, creditScore: user.creditScore, order: user.order}})
          }
          section.customers = users;
        });
      }
      this.setState({customers: customersFiltered, selectCustomer, selectCoCustomer, saleId, salesmanId,
        selectedCustomers, customersSale: customersSale, sections, purchaserId, existPurchaser,
        isComplete,totalPages, emptyCustomer: customersFiltered.length === 0})
    }
    else {
      console.error(responseMessage);
      this.setState({selectCoCustomer, selectCustomer,saleId,salesmanId,selectedCustomers, customersSale: customersSale, sections,
        purchaserId, existPurchaser, isComplete})
    }
  }

  addCustomer(){
    this.setState({showModalPurchaser:true, showCheck:false});
  }

  addCoCustomer(){
    this.setState({showModalPurchaser:true, showCheck:true});
  }

  showInfoCustomer(customer){
    this.setState({showInfoCustomer: true, customerSelected: customer})
  }

  handleEditCredit(){
    const {customerSelected} = this.state;
    this.setState({editCredit:true, creditScore: customerSelected.creditScore})
  }

  onChangeCreditScore(name, e){
    let {customerSelected} = this.state;
    customerSelected[name] = e.target.value;
    this.setState({customerSelected})
  }

  closeInfoCustomer(){
    this.setState({showInfoCustomer: false, customerSelected: {}, editCredit: false})
  }

  handleSearch(evt){
    evt.stopPropagation();
    const keyword = evt.target.value;
    this.setState({keyword, currentPage: 1});

    this.changeKeyword(keyword);
  }

  async loadCustomers(keyword, from, currentPage){
    let page = 1;
    if(currentPage){
      page = currentPage
    }
    const {responseCode, responseMessage, data} = await loadCustomersApi(keyword, from)
    if (responseCode === SUCCESSFUL_CODE) {
      let customersFiltered = [...data.customers];
      const customersQuantity = data.customersQuantity;
      const totalPages = customersQuantity ? Math.ceil(customersQuantity / CUSTOMERS_BY_PAGE_BY_DEFAULT) : 1;
      this.setState({customers: customersFiltered, totalPages: totalPages, currentPage: page})
    }
    else {
      console.error(responseMessage);
    }
  }

  onHide(){
    const {customersSale} = this.state;
    let selectedCustomers = [];
    if(customersSale.length > 1){
      const customersCosigners = customersSale.filter(customer => customer.order !== 1)
      selectedCustomers = customersCosigners.map(cosigner => {return {customerId: cosigner.id, creditScore: cosigner.creditScore, order: cosigner.order}});
    }
    this.loadCustomers("", 0, 1);
    this.setState({showModalPurchaser: false, selectedCustomers: selectedCustomers, keyword: "", hasChangeCosigner: false});
  }

  handleSelectPurchaserMain(customer){
    const {selectedPurchaserMain,saleId,salesmanId} = this.state;
    const selectedPurchaserMainToUpdate = {...selectedPurchaserMain,customerId: customer.id};
    this.setState({isLoading: true, purchaserId: customer.id, keyword: ""});
    updateSale(saleId,{customers:[selectedPurchaserMainToUpdate],salesmanId:salesmanId}).then(({responseCode, responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.loadPurchasers(saleId, "purchaser");
        this.loadCustomers("",0,1);
      }else{
        console.error(responseMessage);
        this.setState({showModalPurchaser:false,isLoading:false});
      }
    })
  }

  handleCheck(customer){
    const {selectedCustomers} = this.state;
    const selectedCustomersToUpdate = [...selectedCustomers];
    const indexFound = findIndex(selectedCustomersToUpdate, (cus) => { return cus.customerId === customer.id});
    if(indexFound !== -1){
      selectedCustomersToUpdate.splice(indexFound,1);
      selectedCustomersToUpdate.forEach(customer => {
        if(customer.order > indexFound + 2){
          customer.order -= 1
        }
      });
    }else{
      selectedCustomersToUpdate.push({customerId: customer.id, creditScore: customer.creditScore, order: selectedCustomersToUpdate.length + 2});
    }
    this.setState({selectedCustomers: selectedCustomersToUpdate, hasChangeCosigner:true})
  }

  saveCosigners(evt){
    evt.stopPropagation();
    const {selectedCustomers, customersSale} = this.state;
    let userPrincipal = customersSale.filter(customer => customer.principal === true);
    const {saleId,salesmanId} = this.state;
    let customersToUpdate = selectedCustomers.map(customer => {return {customerId: customer.customerId, principal: false, creditScore: customer.creditScore, order: customer.order}});
    customersToUpdate.push({customerId: userPrincipal[0].id, principal:true, creditScore: userPrincipal[0].creditScore, order: 1});
    this.setState({isLoading: true, selectCoCustomer: true, keyword: "", hasChangeCosigner: false});
    updateSale(saleId,{customers:customersToUpdate,salesmanId:salesmanId}).then(({responseCode, responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.loadPurchasers(saleId, "cosigner");
        this.loadCustomers("", 0, 1);
      }else{
        console.error(responseMessage);
        this.setState({showModalPurchaser: false,hasChangeCosigner: false,isLoading:false});
      }
    })
  }

  loadPurchasers(saleId, type){
    let {selectCustomer, hasChangeCosigner} = this.state;
    const {updatePurchaserAndCosigners,} = this.props;
    if(type === "purchaser"){
      selectCustomer = true
    } else{
      hasChangeCosigner = false;
    }
    loadCustomersBySaleId(saleId).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        updatePurchaserAndCosigners(data.customers);
        this.setState({customersSale: data.customers, showModalPurchaser: false, selectCustomer , hasChangeCosigner, isLoading:false,
          showModalDelete:false, showInfoCustomer: false}, () => {
          this.updateSections(data.customers);
        })
      }else{
        console.error(responseMessage)
      }
    })
  }

  updateSections(customers){
    let sections = sectionsInitial;
    let selectedCustomers = [];
    let user;
    sections.forEach((section) => {
      user = customers.filter(customer => { return customer.principal === section.principal});
      section.customers = user;
    });
    if(customers.length > 1){
      const customersCosigners = customers.filter(customer => customer.order !== 1)
      selectedCustomers = customersCosigners.map(cosigner => {return {customerId: cosigner.id, creditScore: cosigner.creditScore, order: cosigner.order}});
    }
    this.setState({sections, selectedCustomers: selectedCustomers})
  }

  handleDropElement(item, indexSection, indexTarget){
    let {sections} = this.state;
    let sectionsNew = cloneDeep(sections);
    let customersNew;
    let principal = item.principal;
    let order = item.order;
    let indexSectionOrigin;
    let indexTargetOrigin;

    if(order === 1){
      indexSectionOrigin = 0;
      indexTargetOrigin = 0;
    } else{
      indexSectionOrigin = 1;
      indexTargetOrigin = order - 2;
    }

    customersNew = sections[indexSection].customers[indexTarget]
    item.order = customersNew.order;
    item.principal = customersNew.principal;
    customersNew.principal = principal;
    customersNew.order = order;

    sectionsNew[indexSection].customers[indexTarget] = item
    sectionsNew[indexSectionOrigin].customers[indexTargetOrigin] = customersNew

    this.updateOrder(sectionsNew, customersNew, item);
  }

  updateItemStyle(){
    const {sections} = this.state;
    for(let i = 0; i < sections.length; i++){
      for(let j = 0; j < sections[i].customers.length; j++){
        let element = document.getElementById("user"+j);
        let content = document.getElementById("contentUser"+i);
        if(element){
          element.style.display = "flex";
          content.style.borderRadius = "0";
          content.style.border = "0";
        }
      }
    }
  }

  handleOrderPurchaser(user, type, evt){
    evt.stopPropagation();
    let {sections, customersSale} = this.state;
    let principal = user.principal;
    let newCustomer;
    if(customersSale.length >= 2){
      if(principal){
        newCustomer = sections[1].customers[0];
        newCustomer.principal = true;
        newCustomer.order = 1
        if(type === "up"){
          user.order = customersSale.length - 2
          sections[1].customers.splice(0,1);
          sections[1].customers.map((customer) => {
            customer.order = customer.order - 1
          });
          sections[1].customers.splice(customersSale.length - 2,0,user)
        } else{
          user.order = 2;
          user.principal = false;
          sections[1].customers.splice(0,1);
          sections[1].customers.splice(0,0,user)
        }
        sections[0].customers.splice(0,1);
        sections[0].customers.push(newCustomer);
      } else{
        const index = user.order
        if(type === "up"){
          if(user.order === 2){
            newCustomer = sections[0].customers[0];
            newCustomer.principal = false;
            newCustomer.order = 2;
            user.principal = true;
            user.order = 1;
            sections[1].customers.splice(0,1);
            sections[1].customers.splice(0,0,newCustomer)
            sections[0].customers.splice(0,1);
            sections[0].customers.push(user);
          } else{
            newCustomer = sections[1].customers[index - 3];
            newCustomer.order = newCustomer.order + 1;
            user.order = user.order - 1;
            sections[1].customers.splice(index - 3, 1);
            sections[1].customers.splice(index - 2, 0,newCustomer)
          }
        } else{
          if(user.order === customersSale.length){
            newCustomer = sections[0].customers[0];
            newCustomer.principal = false;
            newCustomer.order = index;
            user.order = 1;
            user.principal = true;
            sections[0].customers.splice(0,1);
            sections[0].customers.push(user);
            sections[1].customers.splice(index - 2, 1);
            sections[1].customers.splice(index - 2, 0, newCustomer);
          } else{
            newCustomer = sections[1].customers[index - 1];
            newCustomer.order = index;
            user.order = index + 1;
            sections[1].customers.splice(index - 1, 1);
            sections[1].customers.splice(index - 2, 0, newCustomer);
          }
        }
      }
    }

    this.updateOrder(sections, newCustomer, user)
  }

  updateOrder(sections, customerInsert, customerDelete){
    const {saleId, salesmanId} = this.state;
    let {customers, selectedCustomers} = this.state;
    let customersSaleNew = [];
    let index = -1 ;
    let userDelete;
    let userInsert;
    sections.forEach((section) => {
      section.customers.forEach(customer => {
        customersSaleNew.push(customer)
      })
    });
    let customersToUpdate = [];
    customersSaleNew.forEach(customer => {
      customersToUpdate.push({customerId: customer.id, principal: customer.principal, creditScore: customer.creditScore, order: customer.order})
    })
    if(customerInsert.principal === true){
      index = customers.findIndex(customer => customer.id === customerInsert.id);
      userDelete = customerInsert;
      userInsert = customerDelete;
    } else if(customerDelete.principal === true){
      index = customers.findIndex(customer => customer.id === customerDelete.id);
      userDelete = customerDelete;
      userInsert = customerInsert
    }
    this.setState({sections, customersSale: customersSaleNew});
    updateSale(saleId,{customers:customersToUpdate,salesmanId:salesmanId}).then(response => {
      if (response.responseCode === SUCCESSFUL_CODE){
        if(index !== -1){
          selectedCustomers = selectedCustomers.filter(customer => {return customer.customerId !== userDelete.id});
          selectedCustomers.push({customerId: userInsert.id, order: userInsert.order});
          this.setState({purchaserId: userDelete.id, selectedCustomers});
        }
      }else{
        console.error(response.responseMessage);
      }
    })
  }

  closeEditCredit(){
    const {creditScore, customerSelected} = this.state;
    customerSelected.creditScore = creditScore
    this.setState({editCredit:false, customerSelected})
  }

  saveCreditScore(customerSelected){
    const {saleId, sections} = this.state
    let customersSaleNew = [];

    sections.forEach((section) => {
      section.customers.forEach(customer => {
        if(customer.id === customerSelected.id) {
          customer.creditScore = customerSelected.creditScore
        }
        customersSaleNew.push(customer)
      })
    });

    updateCreditScore(saleId, customerSelected.id, customerSelected.creditScore).then(({responseCode, responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({sections, customersSale: customersSaleNew, editCredit:false});
      }else{
        console.error(responseMessage);
      }
    })
  }

  showModalDelete(){
    const {showModalDelete} = this.state;
    this.setState({showModalDelete: !showModalDelete})
  }

  deletePurchaser(){
    const {customerSelected, saleId, salesmanId} = this.state;
    let {customersSale, selectCoCustomer, selectCustomer, purchaserId} = this.state
    let customersToUpdate = [];
    let data = {}

    customersSale = customersSale.filter(customer => {return customer.id !== customerSelected.id})

    if(customersSale.length >= 1){
      if(customerSelected.principal){
        customersSale.forEach(customer => {
          if(customer.order === 2){
            customer.principal = true;
            purchaserId = customer.id
          }
          customersToUpdate.push({customerId: customer.id, principal: customer.principal, creditScore: customer.creditScore, order: customer.order - 1})
        })
      } else{
        customersSale.forEach(customer => {
          if(customer.order > customerSelected.order){
            customer.order = customer.order - 1;
          }
          customersToUpdate.push({customerId: customer.id, principal: customer.principal, creditScore: customer.creditScore, order: customer.order})
        })
      }
      if(customersSale.length === 1){
        selectCoCustomer = false
      }
      data = {customers:customersToUpdate,salesmanId:salesmanId}
    } else {
      selectCustomer = false
      data = {salesmanId:salesmanId}
      purchaserId = null
    }

    this.setState({isLoading: true, selectCustomer, selectCoCustomer, purchaserId});
    updateSale(saleId,data).then(response => {
      if (response.responseCode === SUCCESSFUL_CODE){
        this.loadPurchasers(saleId, "delete");
      }else{
        console.error(response.responseMessage);
        this.setState({showModalDelete: false,isLoading:false});
      }
    })
  }

  handlePageChange = (nextPage, totalPages) => {
    const {keyword} = this.state;
    let currentPage = nextPage;
    let from;

    if (totalPages > 1) {
      if (currentPage > totalPages) {
        currentPage = 1;
        from = 0;
      } else if (currentPage === 0) {
        currentPage = totalPages;
        from = CUSTOMERS_BY_PAGE_BY_DEFAULT * (totalPages - 1);
      } else {
        from = CUSTOMERS_BY_PAGE_BY_DEFAULT * (currentPage - 1);
      }

      this.setState({currentPage: currentPage, from}, () => {
        this.loadCustomers(keyword, from, currentPage);
      })
    }

  }

  goToCustomer = () => {
    const {setMenuId} = this.props;
    setMenuId("customer");
    browserHistory.push({pathname: `/main/customer/new`});
  }

  render() {
    const {selectCustomer,selectCoCustomer, showInfoCustomer, customerSelected, editCredit,
      showModalPurchaser,customers,keyword,showCheck,selectedCustomers,hasChangeCosigner,isLoading, sections, customersSale,
      isLoadingCredit, purchaserId, showModalDelete, totalPages, currentPage, emptyCustomer} = this.state;

    const {existPurchaser, isComplete, readOnly} = this.props

    let listIdCustomers = [];
    customersSale.forEach(customer => {
      listIdCustomers.push(customer.id);
    });

    return (
      <div className="panel-sale-purchaser" style={{border: !existPurchaser && !isComplete ?  "1px solid #EAA734" : "1px solid #D7DAE2"}}>
        {
          !showInfoCustomer ?
            <div>
              <TitleHeader classTitle="title-purchaser" classIcon="icon-check" classIconSvg="icon-check-svg" classIconSvgCompleted="icon-check-svg-completed"
                           icon="icon/ic_check.svg" onclick={this.addCoCustomer} selectOption={selectCustomer} title="Purchasers" titleRight="Add Co-signer"
                           classTitleRight={!readOnly ? "add-co-purchaser" : "add-co-purchaser-readonly"} classButton="btn-add-co-purchaser" readOnly={readOnly}/>
              {
                isEmpty(customersSale) ?
                  <div className="body-purchaser">
                    <div className="container-purchaser-image">
                      <img className={'icon-purchaser-image'} src={`${pathServer.PATH_IMG}/customer_not_selected.png`} />
                      <label>Customer not selected yet</label>
                      {
                        !readOnly &&
                        <div className="container-button-add-purchaser">
                          <button
                            className="btn-add-purchaser"
                            onClick={this.addCustomer}
                            disabled={readOnly}
                            data-test={'btn-sale-add-purchaser'}
                          >
                            Add Purchaser
                          </button>
                        </div>
                      }
                    </div>
                  </div> :
                  <div className="body-content-purchaser">
                    {
                      sections.map((section, index) => {
                        const {customers, className, value, classNameTitle} = section;
                        const customersOrdered = orderBy(customers, ['order'], ['asc'])
                        const indexSection = index;
                        let renderSection = false
                        if(customersOrdered && customersOrdered.length > 0){
                          renderSection = true
                        }
                         if(renderSection){
                           return(
                             <div key={`section${index}`} className={className} style={{borderBottom: selectCoCustomer && index === 0 ? "1px solid #D7DAE2" : null}}>
                               <div className={classNameTitle}>
                                 <label>{value}</label>
                               </div>
                               {
                                 customersOrdered.map((customer, index) => {
                                   const value = <ContentUser key={`contentUser${indexSection}`} user={customer} showInfo={this.showInfoCustomer} index={index}
                                                              handleOrderPurchaser={this.handleOrderPurchaser} readOnly={readOnly}/>
                                   return(
                                     <div key={`target${indexSection}${index}`}>
                                       {
                                         !readOnly ?
                                           <ElementTarget statusList={listIdCustomers}
                                                          status={customer.id}
                                                          styleLine={styleTarget}
                                                          styleBorder={styleBorder}
                                                          labelDrop=""
                                                          showLabelDrop={true}
                                                          index={index}
                                                          onDrop={(item) => {
                                                            this.handleDropElement(item, indexSection, index)
                                                          }}>
                                             <ElementDragDrop key={index}
                                                              status={customer.id}
                                                              form={customer}
                                                              style={{}}
                                                              labelDrag=" "
                                                              styleDrag={styleDrag}
                                                              classDrag="container-total"
                                                              updateItemStyle={this.updateItemStyle}
                                                              value={value}/>
                                           </ElementTarget> :
                                           value
                                       }
                                     </div>
                                   )
                                 })
                               }
                             </div>
                           )
                         } else{
                           return null
                         }
                      })
                    }
                  </div>
              }
            </div>
            :
            <div>
              <TitlePurchaser name={`${customerSelected.firstName} ${customerSelected.lastName}`} onClick={this.closeInfoCustomer}/>
              <BodyPurchaser customer={customerSelected} editCredit={editCredit} onClick={this.handleEditCredit}
                onChange={this.onChangeCreditScore} close={this.closeEditCredit} save={this.saveCreditScore} isLoading={isLoadingCredit}
                             showModalDelete={this.showModalDelete}/>
            </div>
        }
        {
          showModalPurchaser ?
            <ModalSelectPurchaser show={showModalPurchaser}
                                  onHide={this.onHide}
                                  keyword={keyword}
                                  handleSearch={this.handleSearch}
                                  customers={customers}
                                  handleSelect={this.handleSelectPurchaserMain}
                                  showCheck={showCheck}
                                  selectedCustomers={selectedCustomers}
                                  handleCheck={this.handleCheck}
                                  saveCosigners={this.saveCosigners}
                                  hasChangeCosigner={hasChangeCosigner}
                                  isLoading={isLoading}
                                  purchaserId={purchaserId}
                                  handlePageChange={this.handlePageChange}
                                  totalPages={totalPages}
                                  currentPage={currentPage}
                                  goToCustomer={this.goToCustomer}
                                  emptyCustomer={emptyCustomer}
            /> : null
        }
        {
          showModalDelete ?
              <ModalConfirmDelete show={showModalDelete}
                                  showButtonDelete={true}
                                  onSubmit={this.deletePurchaser}
                                  onHide={this.showModalDelete}
                                  classButtonDelete="btn-delete"
                                  buttonLabelDelete="Yes, delete"
                                  message={"Do you want to delete this purchaser?"}
                                  styleModal="modal-delete-purchaser"
                                  styleRight="panel-right-delete-user"
                                  isLoading={isLoading} /> : null
        }
      </div>
    );
  }
}

const Container = (props) => {
  return(
    <DragDropContext>
      <SalePurchaser {...props}/>
    </DragDropContext>
  )
};

export default Container
