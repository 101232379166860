import React, {Component} from 'react'
import Modal from 'react-modal'
import ModalNewAttributeProperties from './ModalNewAttributeProperties.jsx'
import {saveAttributeApi} from '../../../Api.js'
import Editor from '../attribute/AttributeEditor.jsx'
import {sortBy} from 'lodash/collection'
import {cloneDeep} from 'lodash'
import {
  imageCheckBoxList, imageDate,
  imageDynamicNumber, imageExpand,
  imageLabel, imageList, imageParagraph, imagePasswordHidden,
  imageSingleLine, imageSquare,
  imageTextArea,
  pathServer,
  SUCCESSFUL_CODE
} from '../../../../common/Constants'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0',
    width: '65.5%',
    height: '398px',
    margin: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    borderRadius: '10px',
    boxShadow: '0 5px 20px 0',
    maxHeight: "400px"
  }
};


export default class ModalAttribute extends Component {

  constructor() {
    super();
    this.onChangeDropDownForm = this.onChangeDropDownForm.bind(this);
    this.return = this.return.bind(this);
    this.save = this.save.bind(this);
    this.addRow = this.addRow.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
    this.onMoveDown = this.onMoveDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.viewCommonFields = this.viewCommonFields.bind(this);
    this.viewAttributeOptions = this.viewAttributeOptions.bind(this);
    this.updateAttributeType  = this.updateAttributeType.bind(this);
    this.updateAttribute  = this.updateAttribute.bind(this);
    this.modifyType           = this.modifyType.bind(this);
    this.caughtText = this.caughtText.bind(this);
    ModalAttribute.bShadowArray = ModalAttribute.bShadowArray.bind(this);
    this.state = {
      viewTypeOptions: "no",
      bShadow: ["0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0", "0 0 0"],
      optionsCompatibility: false,
      formDropDown: {},
      showCommonFields: true,
      attributesValues: [],
      optionsDelete:[],
      attribute: {
        code: "",
        name: "",
        displayValue: "",
        typeAttribute: "",
        entityTypes: [],
        values: [],
        backgroundColor: "rgb(128, 128, 128, 0.75)",
      },
      attributeCopy:null,
      typeAttributeMultiple: 2,
      textParagraph: "",
      changeText: false,
    };
  }

  componentWillMount() {
    let {attributeEdit, form, allAttributesEdit} = this.props;
    let {attribute} = this.state;
    attribute.entityTypes = (form) ? [form.entityType] : [];
    if(attributeEdit){
      attributeEdit.backgroundColor = attribute.backgroundColor;
      attributeEdit.entityTypes = [form.entityType];
      let findAttribute = allAttributesEdit.find(x => x.id === attributeEdit.id) || {};
      findAttribute.entityTypes = [form.entityType];
      attributeEdit.attributeValues = findAttribute.attributeValues;
      let attributeValues = (attributeEdit.attributeValues) ? sortBy(attributeEdit.attributeValues, [function (o) {
        return o.order;
      }]): [];
      this.setState({attribute:attributeEdit, attributesValues:attributeValues, attributeCopy:cloneDeep(findAttribute)});
    }else{
      this.setState({attribute:attribute});
    }
    //if (attributeEdit){
      this.viewCommonFields((attributeEdit) ? (attributeEdit.typeAttribute ? attributeEdit.typeAttribute : attributeEdit.inputType) : "");
    //}

    document.getElementsByClassName('wrapper-header').item(0).style.position = "inherit";
    //document.getElementsByClassName('container-header').item(0).style.position = "inherit";
    document.getElementsByTagName('Body').item(0).style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.getElementById("root").style = "opacity: 1.0"
  }

  onChangeDropDownForm(type) {
    let {formDropDown} = this.state;
    if (formDropDown[type])
      delete formDropDown[type];
    else
      formDropDown[type] = true;
    this.setState({formDropDown: formDropDown})
  }

  return() {
    document.getElementsByTagName('Body').item(0).style.overflow = "auto";
    this.props.onHide(true, null);
  }

  updateAttribute(attribute){
    this.setState({attribute:attribute, attributeCopy: attribute});
  }

  save() {
    let {attribute, attributeCopy, attributesValues,optionsDelete, textParagraph, viewTypeOptions,changeText} = this.state;
    let {attributeEdit, indexSubSection} = this.props;
    if(attribute.code && attribute.code.includes('.')){
      let sendRequest = {
        attribute: (attributeEdit) ? attributeCopy : attribute,
        entityTypes: (attributeEdit) ? attributeCopy.entityTypes : attribute.entityTypes,
        options: attributesValues.filter(x=> !x.id),
        optionsDelete:optionsDelete
      };
      if(viewTypeOptions.toLowerCase()==="PARAGRAPH".toLowerCase()){
        (changeText)?(sendRequest.attribute.displayValue=textParagraph):(sendRequest.attribute.displayValue=attributeEdit.displayValue)
        this.setState({changeText:false});
      }

      saveAttributeApi(sendRequest, attributeEdit ? "update":null).then((response) => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE) {
          document.getElementsByTagName('Body').item(0).style.overflow = "auto";
          attribute.displayValue = sendRequest.attribute.displayValue;
          const attributeToUpdate = {...attribute};
          this.props.onHide(false, attributeToUpdate, attributeToUpdate.entityTypes,indexSubSection);
        } else {
          console.error(responseMessage)
        }
      });
    }else{
      const attributeToUpdate = {...attribute};
      this.props.saveAttributeOnlyForm(attributeToUpdate, indexSubSection);
    }
  }

  updateAttributeType(type){
    let {attribute, attributeCopy} = this.state;
    attribute.typeAttribute = this.modifyType(type);
    attributeCopy.typeAttribute = this.modifyType(type);
    this.setState({showCommonFields: !this.state.showCommonFields, attribute:attribute, attributeCopy: attributeCopy})
  }

  viewAttributeOptions(type) {
    this.updateAttributeType(type)
    this.setState({viewTypeOptions: type});
  }

  modifyType(type){
    switch (type) {
      case 'Date':
        return ('DATE');
      case 'Choose Options':
        return ('SELECT');
      case 'Multi Select':
        return ('MULTI_SELECT');
      case 'Paragraph':
        return ('PARAGRAPH');
      case 'Options':
        return ('CHECKBOX');
      case 'List':
        return ('LIST');
      case 'Password/Hidden':
        return ('PASSWORD');
      case 'Single Text':
        return ('SINGLE_TEXT');
      case 'Dynamic number':
        return ('DYNAMIC_NUMBER');
      case 'Text Area':
        return ('TEXT_AREA');
      case 'Label':
        return ('LABEL');
      default:
        return ('');
    }
  }

  viewCommonFields(type) {
    let {bShadow} = this.state;
    let selectShadowArray = ModalAttribute.bShadowArray(type);
    let typeAttributeMultiple = 2;
    for (let i = 0; i < 11; i++) {
      bShadow[i] = "0 0 0";
    }
    bShadow[selectShadowArray] = "0 3px 6px";
    if(selectShadowArray===1 || selectShadowArray===3 || selectShadowArray===5 || selectShadowArray===4)
      typeAttributeMultiple = 1;
    else if(selectShadowArray===0 || selectShadowArray===2 || selectShadowArray===6 || selectShadowArray===7 || selectShadowArray===8 || selectShadowArray===9)
      typeAttributeMultiple = 0;
    this.setState({showCommonFields: !this.state.showCommonFields, typeAttributeMultiple: typeAttributeMultiple});
  }

  static bShadowArray(type) {
    switch (type.toLowerCase()) {
      case 'date':
        return (2);
      case 'select':
        return (1);
      case 'multi_select':
        return (3);
      case 'paragraph':
        return (7);
      case 'checkbox':
        return (5);
      case 'list':
        return (4);
      case 'password':
        return (0);
      case 'single_text':
        return (8);
      case 'dynamic_number':
        return (6);
      case 'text_area':
        return (9);
      case 'label':
        return (10);
      default:
        return (11);
    }
  }

  renderSwitch() {
    let {viewTypeOptions} = this.state;
    switch (viewTypeOptions) {
      case 'Password/Hidden':
        return (<div className="check-option view-type-option">
          <div>
            <img src={imagePasswordHidden} className="form-field-img" alt=""/>
            <span>Password/Hidden</span>
          </div>
        </div>);
      case 'Choose Options':
        return (<div className="tittle-option view-type-option">
          <div>
            <img src={imageExpand} className="form-field-img" alt=""/>
            <span>Choose Options</span>
          </div>
        </div>);
      case 'Date':
        return (
          <div className="check-option view-type-option">
            <div>
              <img src={imageDate} className="form-field-img" alt=""/>
              <span>Date </span>
            </div>
          </div>);
      case 'Multi Select':
        return (<div className="check-option view-type-option">
          <div>
            <img src={imageSquare} className="form-field-img" alt=""/>
            <span>Multi Select</span>
          </div>
        </div>);
      case 'List':
        return (<div className="tittle-list view-type-option">
          <div>
            <span><img src={imageList} className="form-field-img" alt=""/></span>
            <div style={{display: "inline"}}>List</div>
          </div>
        </div>);
      case 'Options':
        return (<div className="tittle-list view-type-option">
          <div>
            <span><img src={imageCheckBoxList} className="form-field-img" alt=""/></span>
            <div style={{display: "inline"}}>Options</div>
          </div>
        </div>);
      case 'Dynamic number':
        return (<div className="check-option view-type-option">
          <div>
            <img src={imageDynamicNumber} className="form-field-img" alt=""/>
            <span>Dynamic number</span>
          </div>
        </div>);
      case 'Paragraph':
        return (<div className="check-option view-type-option">
          <div>
            <img src={imageParagraph} className="form-field-img" alt=""/>
            <span>Paragraph </span>
          </div>
        </div>);
      case 'Single Text':
        return (<div className="tittle-list view-type-option">
          <div>
            <span><img src={imageSingleLine} className="form-field-img" alt=""/></span>
            <div style={{display: "inline"}}>Single Text</div>
          </div>
        </div>);
      case 'Text Area':
        return (<div className="check-option view-type-option">
          <div>
            <img src={imageTextArea} className="form-field-img" alt=""/>
            <span>Text Area</span>
          </div>
        </div>);
      case 'Label':
        return (<div className="check-option view-type-option">
          <div>
            <img src={imageLabel} className="form-field-img" alt=""/>
            <span>Label</span>
          </div>
        </div>);
      default:
        return null;
    }
  }

  handleChange(e, index) {
    let {attributesValues} = this.state;
    let attributesValuesEdit = attributesValues;
    if (e.target.name === "label") {
      attributesValuesEdit[index].name = e.target.value;
    } else if (e.target.name === "valueText") {
      attributesValuesEdit[index].displayValue = e.target.value;
    } else {
      attributesValuesEdit[index].code = e.target.value;
    }
    attributesValuesEdit[index].order = index;
    this.setState({attributesValues: attributesValuesEdit})
  }

  updateOrder(attributeValues){
    return attributeValues.map((av, index) => {
      av.order = index;
      return av;
    })
  }

  onMoveUp(e) {
    if (e.target.name > 0) {
      let {attributesValues} = this.state;
      let start_index = parseInt(e.target.name, 10);
      let attributesValuesActual = attributesValues[start_index];
      let attributesValuesBefore = attributesValues[start_index - 1];
      attributesValues.splice((start_index - 1), 2, attributesValuesActual, attributesValuesBefore);
      attributesValues = this.updateOrder(attributesValues);
      this.setState({attributesValues: attributesValues});
    }
  }

  onMoveDown(e) {
    let {attributesValues} = this.state;
    if (e.target.name < (attributesValues.length - 1)) {
      let start_index = parseInt(e.target.name, 10);
      let attributesValuesActual = attributesValues[start_index];
      let attributesValuesAfter = attributesValues[(start_index + 1)];
      attributesValues.splice(start_index, 2, attributesValuesAfter, attributesValuesActual);
      attributesValues = this.updateOrder(attributesValues);
      this.setState({attributesValues: attributesValues});
    }
  }

  caughtText(editorState){
    let textParagraph = editorState.getCurrentContent().getPlainText();
    this.setState({textParagraph: textParagraph, changeText:true});
  }

  renderRow() {
    let {attributesValues} = this.state;
    return (
      attributesValues.map((av, index) =>
        <tbody key={"tb" + index} style={{borderTop: "none"}}>
        <tr key={"tr" + index} style={{verticalAlign: "middle"}} className="rowCostumer">
          <td style={{paddingTop: "10px", border: "none"}}>
            <div style={{float: "right", display: "inline-grid"}}>
              <img name={index} className="icon-option" alt=""
                   style={{width: "15px", height: "15px", paddingTop: "0px", cursor: "pointer"}}
                   src={pathServer.PATH_IMG + "arrow3.png"} onClick={this.onMoveUp}/>
              <img name={index} className="icon-option" alt=""
                   style={{width: "15px", height: "15px", paddingTop: "0px", cursor: "pointer"}}
                   src={pathServer.PATH_IMG + "arrow2.png"} onClick={this.onMoveDown}/>
            </div>
          </td>
          <td rowSpan="2" style={{width: "30%"}}>
            <div className="form-input-content" style={{width: "100%"}}>
              <div className="form-input">
                <input key={index} className="attribute-control" name="label" value={attributesValues[index].name}
                       onChange={(e) => this.handleChange(e, index)}/>
              </div>
            </div>
          </td>
          <td rowSpan="2" style={{width: "30%"}}>
            <div className="form-input-content" style={{width: "100%", paddingRight: "0px"}}>
              <div className="form-input">
                <input className="attribute-control" name="valueText" value={attributesValues[index].displayValue}
                       onChange={(e) => this.handleChange(e, index)}/>
              </div>
            </div>
          </td>
          <td rowSpan="2" style={{width: "30%"}}>
            <div className="form-input-content" style={{width: "100%", paddingRight: "0px"}}>
              <div className="form-input">
                <input className="attribute-control" name="calcValue" value={attributesValues[index].code}
                       onChange={(e) => this.handleChange(e, index)}/>
              </div>
            </div>
          </td>
          <td style={{paddingRight: "30px", border: "none"}} rowSpan="2">
            <div className="ic-delete" style={{marginTop: "8px"}}>
              <i id="id-fa-icon" className="fa fa-trash" onClick={this.onDeleteRow.bind(this,index)}/>
            </div>
          </td>
        </tr>
        </tbody>
      )
    )
  }

  renderCommonFields() {
    let {viewTypeOptions} = this.state;
    let {attributeEdit} = this.props;
    switch (viewTypeOptions) {
      case 'Password/Hidden':
        return (<table style={{marginBottom: "30px", marginLeft: "20px", height: "46px"}}>
          <tbody>
          <tr>
            <td style={{width: "65px", verticalAlign: "initial",paddingLeft: "14px"}}>
              <span>Password</span>
            </td>
            <td style={{width: "160px", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
              <div style={{border: "none", fontSize: "16px", width: "100%"}}>**************</div>
            </td>
          </tr>
          </tbody>
        </table>);
      case 'Choose Options':
        return (null);
      case 'Date':
        return (
          <table style={{marginBottom: "30px", marginLeft: "20px", height: "46px"}}>
            <tbody>
            <tr>
              <td style={{width: "60px", paddingLeft: "14px", verticalAlign: "initial"}}>
                <span>Format</span>
              </td>
              <td style={{width: "160px", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
                <div style={{border: "none", fontSize: "16px", width: "100%"}}>MM/DD/YYYY</div>
              </td>
            </tr>
            </tbody>
          </table>
        );
      case 'Multi Select':
        return (null);
      case 'List':
        return (null);
      case 'Options':
        return (null);
      case 'Dynamic number':
        return (<table style={{marginBottom: "30px", marginLeft: "20px", height:"45px"}}>
          <tbody>
          <tr>
            <td style={{width: "64px", paddingLeft: "14px", verticalAlign:"initial"}}>
              <span>Dynamic</span>
            </td>
            <td style={{width: "160px", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
              <div style={{border: "none", fontSize: "16px", width: "100%"}}>Number</div>
            </td>
          </tr>
          </tbody>
        </table>);
      case 'Paragraph':
        return (<div className="row-form" style={{paddingLeft: "30px", paddingBottom: "30px"}}>
          <Editor caughtText={this.caughtText} textParagraph={(attributeEdit)?attributeEdit.displayValue:""}/>
        </div>);
      case 'Single Text':
        return (<table style={{marginBottom: "30px", marginLeft: "20px", width: "100%"}}>
          <tbody>
          <tr>
            <td style={{width: "80px", paddingLeft: "14px"}}>
              <span>Single Text</span>
            </td>
            <td style={{width: "63%", height: "35px", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
              <div style={{border: "none", fontSize: "16px", width: "100%", placeholder:"Single Text"}} />
            </td>
          </tr>
          </tbody>
        </table>);
      case 'Text Area':
        return (<table style={{marginBottom: "30px", marginLeft: "20px", height:"45px"}}>
          <tbody>
          <tr>
            <td style={{width: "64px", paddingLeft: "14px", verticalAlign:"initial"}}>
            </td>
          </tr>
          </tbody>
        </table>);
      case 'Label':
        return (<table style={{marginBottom: "19px", marginLeft: "20px", height: "46px", width: "95%"}}>
          <tbody>
          <tr>
            <td style={{width: "65px", verticalAlign: "initial",paddingLeft: "14px"}}>
              <span>Label</span>
            </td>
            <td style={{width: "100%", display: "flex", border: "2px solid #F3F3F3", borderRadius: "5px"}}>
              <textarea style={{border: "none", height: "53px", fontSize: "16px", width: "100%", overflowY: "hidden"}}></textarea>
            </td>
          </tr>
          </tbody>
        </table>);
      default:
        return null;
    }
  }

  onDeleteRow(index) {
    let {attributesValues,optionsDelete} = this.state;
    let start_index = parseInt(index);
    let attributeValueDelete = attributesValues[index];
    if(attributeValueDelete.id){
      optionsDelete.push(attributeValueDelete.id)
    }
    attributesValues.splice(start_index, 1);
    attributesValues = this.updateOrder(attributesValues);
    this.setState({attributesValues: attributesValues,optionsDelete:optionsDelete});
  }

  addRow() {
    let {attributesValues} = this.state;
    attributesValues.push({name: "", displayValue: "", code: "", order: attributesValues.length});
    this.setState({attributesValues: attributesValues});
  }

  render() {
    let {attribute, showCommonFields, viewTypeOptions, optionsCompatibility, typeAttributeMultiple} = this.state;
    let {form, attributeEdit} = this.props;
    {
      optionsCompatibility = viewTypeOptions === "Choose Options" || viewTypeOptions === "List" ||
        viewTypeOptions === "Options" || viewTypeOptions === "Multi Select";
    }

    return <div>
      <Modal isOpen={true} style={customStyles} contentLabel="New Attribute"
             aria={{labelledby: "heading", describedby: "full_description"}}
             ariaHideApp={false} backdrop="static">
        <div className="container-add-attribute" style={{height: (showCommonFields)?("auto"):("396px"), display: 'grid', position: "relative", boxShadow: "none"}}>
          <div>
            <div className="form-header-left" style={{paddingTop: "7px"}}>
              <h6>{!attributeEdit ? "New Attribute": "Edit Attribute"}</h6>
            </div>
            <div className="form-header-right">
              <div className="form-button-header" style={{paddingTop: "2px", paddingRight: "0px", paddingLeft: "0px"}}>
                <img src={pathServer.PATH_IMG + "ic_close.png"} style={{width: "40px"}}
                     className="icon-close" alt=""
                     onClick={this.return}/>
              </div>
              <div className="form-button-header" style={{paddingTop: "1px", paddingRight: "0px", paddingLeft: "1px"}}>
                <img src={pathServer.PATH_IMG + "ic_save.png"} style={{width: "29px"}}
                     className="icon-save" alt=""
                     onClick={this.save}/>
              </div>
            </div>
          </div>
          <div className="info-attribute"
               style={{width: "100%", paddingTop: "20px", height: "15%", borderBottom: "1px #f1f1f1 solid"}}>
            <ModalNewAttributeProperties attribute={attribute} form={form} disabled={!!attributeEdit} updateAttribute={this.updateAttribute}/>
            <div className="attribute-content" style={{marginTop: "-12px"}}>
              <h6>Attribute content</h6>
            </div>
          </div>
          {showCommonFields ?
            <div>
              <div className="form-property" style={{paddingBottom: "10px"}}>
                <div id="border-attribute-option" className="row-form"
                     style={{display: "-webkit-box", width: "70%", marginTop: "-20px"}}>
                  {this.renderSwitch()}
                  <div className="icon-options" id="img-attrib-options" style={{width: "9%", marginTop: "10px"}}
                       onClick={() => this.viewCommonFields(attribute.typeAttribute)}/>
                </div>
                <div id="border-attribute-info" className="row-form"
                     style={{width: "28%", marginTop: "-20px", paddingTop: "4px", marginLeft: "7px"}}>
                  <div style={{marginTop: "1px"}}>
                    <img className="icon-option" alt=""
                         style={{width: "30px", height: "32px", cursor: "pointer"}}
                         src={pathServer.PATH_IMG + "inverted.png"}/>
                    <span style={{fontSize: "11px"}}>Add info text box</span>
                  </div>

                </div>
              </div>
              <div style={{fontSize: " 11px", paddingBottom: "30px"}}>
                {
                  this.renderCommonFields()
                }
              </div>
              {
                optionsCompatibility ?
                  <table className="table" cellSpacing="0" cellPadding="0">
                    <thead>
                    <tr style={{fontSize: "11px"}}>
                      <th className="col-md-1" style={{border:"none"}}/>
                      <th id="tags-attribute-option" className="col-md-3">Label</th>
                      <th id="tags-attribute-option" className="col-md-3">Value</th>
                      <th id="tags-attribute-option" className="col-md-3">Calc value</th>
                      <th className="col-md-1" style={{border: "none"}}/>
                    </tr>
                    </thead>
                    {this.renderRow()}
                  </table>
                  :
                  null
              }
              {
                optionsCompatibility ?
                  <div className="rowCustomer" style={{paddingBottom: "15px"}}>
                    <div className="form-setting-button" style={{paddingLeft: "30px", paddingTop:"0px"}}>
                      <button className="format-square-setting-button-add" onClick={this.addRow}>+</button>
                    </div>
                  </div>
                  :
                  null
              }
            </div>
            :
            <div className="form-create-attribute" style={{height: "60%", fontSize: "10px"}}>
              <div>
                <div id="Password/Hidden" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[0], height: "35px", paddingTop: "6px", width: "32.66%", marginRight: "7px",
                       backgroundColor: (typeAttributeMultiple===0 || typeAttributeMultiple===2)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple===0 || typeAttributeMultiple===2)? () => this.viewAttributeOptions("Password/Hidden"): () => {}}>
                  <span><img src={imagePasswordHidden} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Password/Hidden</div>
                </div>
                <div id="Choose Options" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[1], height: "35px", paddingTop: "9px", width: "32.66%", marginRight: "7px",
                       backgroundColor: (typeAttributeMultiple>0)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple>0)? () => this.viewAttributeOptions("Choose Options"): () => {}}>
                  <span><img src={imageExpand} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Choose Options</div>
                </div>
                <div id="Date" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[2], height: "35px", paddingTop: "6px", width: "32.66%", marginRight: "0px",
                       backgroundColor: (typeAttributeMultiple===0 || typeAttributeMultiple===2)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple===0 || typeAttributeMultiple===2)? () => this.viewAttributeOptions("Date"): () => {}}>
                  <span><img src={imageDate} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Date</div>
                </div>
              </div>
              <div>
                <div id="Multi Select" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[3], height: "35px", paddingTop: "6px", width: "32.66%", marginRight: "7px",
                       backgroundColor: (typeAttributeMultiple>0)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple>0)? () => this.viewAttributeOptions("Multi Select") : () => {}}>
                  <span><img src={imageSquare} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Multi Select</div>
                </div>
                <div id="List" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[4], height: "35px", paddingTop: "9px", width: "32.66%", marginRight: "7px",
                       backgroundColor: (typeAttributeMultiple>0)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple>0)? () => this.viewAttributeOptions("List") : () => {}}>
                  <span><img src={imageList} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>List</div>
                </div>
                <div id="Options" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[5], height: "35px", paddingTop: "6px", width: "32.66%", marginRight: "0px",
                       backgroundColor: (typeAttributeMultiple>0)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple>0)? () => this.viewAttributeOptions("Options"): () => {}}>
                  <span><img src={imageCheckBoxList} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Options</div>
                </div>
              </div>
              <div>
                <div id="Dynamic number" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[6], height: "35px", paddingTop: "6px", width: "32.66%", marginRight: "7px",
                       backgroundColor: (typeAttributeMultiple===0 || typeAttributeMultiple===2)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple===0 || typeAttributeMultiple===2)? () => this.viewAttributeOptions("Dynamic number") : () => {}}>
                  <span><img src={imageDynamicNumber} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Dynamic number</div>
                </div>
                <div id="Paragraph" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[7], height: "35px", paddingTop: "6px", width: "32.66%", marginRight: "7px",
                       backgroundColor: (typeAttributeMultiple===0 || typeAttributeMultiple===2)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple===0 || typeAttributeMultiple===2)? () => this.viewAttributeOptions("Paragraph") : () => {}}>
                  <span><img src={imageParagraph} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Paragraph</div>
                </div>
                <div id="Single Text" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[8], height: "35px", paddingTop: "9px", width: "32.66%", marginRight: "0px",
                       backgroundColor: (typeAttributeMultiple===0 || typeAttributeMultiple===2)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple===0 || typeAttributeMultiple===2)? () => this.viewAttributeOptions("Single Text"): () => {}}>
                  <span><img src={imageSingleLine} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Single Text</div>
                </div>
              </div>
              <div>
                <div id="Text Area" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[9], height: "35px", paddingTop: "6px", width: "32.66%", marginRight: "7px",
                       backgroundColor: (typeAttributeMultiple===0 || typeAttributeMultiple===2)? "white":"#F5F6FA"}}
                     onClick={(typeAttributeMultiple===0 || typeAttributeMultiple===2)? () => this.viewAttributeOptions("Text Area"): () => {}}>
                  <span><img src={imageTextArea} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Text Area</div>
                </div>
                <div className="form-create-setting-fields"
                     style={{boxShadow: "0 0 0", height: "35px", paddingTop: "6px", width: "32.66%", marginRight: "7px",
                       backgroundColor: "white", border:"none"}}/>
                <div id="Label" className="form-create-setting-fields"
                     style={{boxShadow: this.state.bShadow[10], height: "35px", paddingTop: "9px", width: "32.66%", marginRight: "0px",
                       backgroundColor: (typeAttributeMultiple===0 || typeAttributeMultiple===2)? "white":"#F5F6FA"}}
                     onClick={ () => this.viewAttributeOptions("Label")}>
                  <span><img src={imageLabel} className="form-field-img" alt=""/></span>
                  <div style={{display: "inline"}}>Label</div>
                </div>
              </div>
            </div>}
        </div>
      </Modal>
    </div>;
  }
}