import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {getWidthColumn} from '../../util/Utils'

export default class ModalForm extends Component {
  constructor() {
    super();
    this.return = this.return.bind(this);

    this.state = {
      form: null
    };
  }

  componentWillMount() {
    let formDataString = JSON.stringify(this.props.form).replace(/(\\n)/gi, ' ').replace(/(\\\")/gi, '\"').replace(/(\"\{)/gi, '\{').replace(/(\}\")/gi, '\}');
    let form = JSON.parse(formDataString);
    if (form) {
      this.setState({form: form});
    }
  }

  return() {
    this.setState({form: null});
    this.props.onHide(true);
  }

  renderSection(section, sectionIndex) {
    if(Array.isArray(section.sectionSubs)){
      return(<div key={sectionIndex} className="section-box">
        <div className="section-title"><span>{section.header.title}</span></div>
        {section.sectionSubs.map((subSection, index) => {
          return(<div>
            <div className="subsection-title"><span>{subSection.title}</span></div>
            {subSection.rows.map((row, rowIndex) => {
              const colWidth = getWidthColumn(row.columns ? row.columns.length : 1);
              return (<div key={rowIndex} className="row section-row">
                {
                  row.columns.map((column, colIndex) => {
                    let attributeData = this.state.form.formAttributes.attributes.filter(attribute => attribute.id === column.status);
                    return (
                      <div key={colIndex}
                           className={colWidth + " col-attribute-space"}>
                        <div>
                          {
                            attributeData.length > 0 ?
                              <div className="form-input">
                                <input className="form-control attribute-name" name="name"
                                       value={(attributeData[0].displayValue !== "") ? attributeData[0].displayValue : attributeData[0].name}/>
                              </div>
                              : null
                          }
                        </div>

                      </div>);
                  })
                }
              </div>);
            })}
          </div>);
        })}
      </div>);

    } else {
      return (<div key={sectionIndex} className="section-box">
        <div className="section-title"><span>{section.header.title}</span></div>
        {(section.sectionSubs.rows) && (section.sectionSubs.rows.length > 0) ?
          section.sectionSubs.rows.map((row, rowIndex) => {
            const colWidth = getWidthColumn(row.columns ? row.columns.length : 1);
            return (<div key={rowIndex} className="row section-row">
              {
                row.columns.map((column, colIndex) => {
                  let attributeData = this.state.form.formAttributes.attributes.filter(attribute => attribute.id === column.status);
                  return (
                    <div key={colIndex}
                         className={colWidth + " col-attribute-space"}>
                      <div>
                        {
                          attributeData.length > 0 ?
                            <div className="form-input">
                              <input className="form-control attribute-name" name="name"
                                     value={(attributeData[0].displayValue !== "") ? attributeData[0].displayValue : attributeData[0].name}/>
                            </div>
                            : null
                        }
                      </div>

                    </div>);
                })
              }
            </div>);
          }) : null}
      </div>);
    }
  }

  renderFormBasicData() {
    return (<div>
        <div className="row">
          <div className="form-input-content col-sm-4">
            <span>Form name</span>
            <div className="form-input">
              <input className="form-control" name="name" value={this.state.form.name}/>
            </div>
          </div>
          <div className="form-input-content col-sm-4">
            <span>Form code</span>
            <div className="form-input">
              <input className="form-control" name="name" value={this.state.form.code}/>
            </div>
          </div>
          <div className="form-input-content col-sm-4">
            <span>Render Type</span>
            <div className="form-input">
              <input className="form-control" name="name" value={this.state.form.renderType}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-input-content col-sm-4">
            <span>Entity Type</span>
            <div className="form-input">
              <input className="form-control" name="name" value={this.state.form.entityType}/>
            </div>
          </div>
        </div>

      </div>
    );
  }

  render() {
    let {form} = this.state;
    return (
      <div>
        <Modal show={true} className="form-modal-fade">
          <div className="form-modal">
            <Modal.Header className="modal-header">
              <div className="row">
                <div className="col-sm-11"><Modal.Title
                  id="contained-modal-title-md">{this.state.form.name}</Modal.Title></div>
                <div className="col-sm-1 text-right">
                  <button className="btn btn-form-preview-close" onClick={this.return}>X</button>
                </div>
              </div>
            </Modal.Header>

            <Modal.Header className="modal-header">
              {this.renderFormBasicData()}
            </Modal.Header>
            <Modal.Body>
              <div style={{paddingTop: 0}} className="panel-body">
                {this.state.form.formAttributes.sections.map((section, index) => {
                  return this.renderSection(section, index);
                })}
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    )
  }

}