import React from 'react'
import isEmpty from 'lodash/isEmpty'
import {ReactSVG} from 'react-svg'
import {FormInputTypes} from '../../Constants'
import {capitalFirstLetter, getFormatNumber} from '../util/Utils'
import {PAGES, TitleHeader} from './SaleEdit'
import {DPSpinner} from '../../common/DPSpinner'
import {getServerUrl} from '../../Api'
import {pathServer} from '../../../common/Constants'

function getMessageCar(isSold, active) {
  let message = ''
  if(isSold)
    message = 'Sold'
  else if (active)
    message = 'Available'
  else
    message = 'Not available'

  return message
}

 export default function SaleCar({saleCar, setPage, existCar, isCarSold, isComplete, readOnly,
                                   showModalAddCarToInventory, isLoadingCarBox}) {

  const priceToDisplay = `$${getFormatNumber((saleCar && saleCar.suggestedPrice) || 0)}`;
  const mileageToDisplay = `${Math.round(Math.round((((saleCar && saleCar.mileage) || 0) / 1000) * 100) / 100)}k miles`;
  const priceAndMileageToDisplay = `${priceToDisplay} | ${mileageToDisplay}`;
  const handleClickForCarOnInventory =
    saleCar && !saleCar.temporary
      ? () => window.open(`${getServerUrl()}main/inventory/${saleCar.vin}`, '_blank')
      : null

  return (
    <div className="panel-sale-car" style={{border: (!existCar || !isCarSold) && !isComplete ?  "1px solid #EAA734" : "1px solid #D7DAE2"}}>
      <TitleHeader
        classTitle="title-car"
        classIcon="icon-check"
        classIconSvg="icon-check-svg"
        classIconSvgCompleted={'icon-check-svg-completed'}
        icon="icon/ic_check.svg"
        onclick={null}
        selectOption={!isEmpty(saleCar)}
        title="Vehicle"
      />
      <div className="body-car">
        {
          isLoadingCarBox ?
            <DPSpinner/>:
            <div className="body-car-section">
              {
                !readOnly && (
                  <div className="container-option-car">
                    <div
                      className={`car-inventory ${isEmpty(saleCar) ? '' : 'after-selected-car'}`}
                      onClick={() => setPage(PAGES.SELECT_CAR)}
                      data-test={'select-from-inventory'}
                    >
                      <label>Select from inventory</label>
                    </div>
                    <div
                      className="car-not-inventory"
                      onClick={() => setPage(PAGES.NOT_IN_INVENTORY_YET)}
                    >
                      <label>Not in inventory yet</label>
                    </div>
                  </div>
                )
              }
              <div className={`container-car-image ${isEmpty(saleCar) ? '' : 'after-selected-car'}`}>
                {
                  isEmpty(saleCar) ?
                    <div className="container-icon-image-not-selected">
                      <ReactSVG
                        src={`${pathServer.PATH_IMG}icon/car_not_selected.svg`}
                        beforeInjection={ svg => svg.classList.add('icon-car-image-svg')}
                        className="icon-car-image"
                      />
                      <label className="car-not-selected"> Car not selected yet </label>
                    </div>
                    :
                    <div className="container-icon-image">
                      <div className='selected-car-image'>
                        <img
                          src={(saleCar.mainImage === '' || !saleCar.mainImage) ? FormInputTypes.DEFAULT_IMAGE_URL : saleCar.mainImage}
                          className={saleCar && !saleCar.temporary ? 'image-object-contain-pointer' : 'image-object-contain'}
                          style={{borderRadius: '4px'}}
                          onClick={handleClickForCarOnInventory}
                        />
                      </div>
                      <div className={'selected-car-info'}>
                        <p
                          className={saleCar && !saleCar.temporary ? 'make-model-year-car-pointer' : 'make-model-year-car'}
                          onClick={handleClickForCarOnInventory}
                        >
                          {`${capitalFirstLetter(saleCar.makeId)} ${capitalFirstLetter(saleCar.modelId)} ${saleCar.year}`}
                        </p>
                        <p className={saleCar.active ? 'active-car' : 'not-available'}>
                          {getMessageCar(saleCar.isSold, saleCar.active)}
                        </p>
                        <p className={'price-mileage-car'}>
                          {priceAndMileageToDisplay}
                        </p>
                        {
                          saleCar.temporary ?
                            <div className="container-title-button">
                              <p className="car-temporary">
                                Not in inventory yet
                              </p>
                              <div className="container-button-edit" onClick={() => setPage(PAGES.NOT_IN_INVENTORY_YET)}>
                                <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_edit-pencil.svg`} beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                                          className={"icon-edit-temporary-car"} onClick={() => setPage(PAGES.NOT_IN_INVENTORY_YET)}/>
                                <div className="title-suggestion">
                                  <span>Edit car information</span>
                                </div>
                              </div>
                              <div className="container-button-add" onClick={showModalAddCarToInventory}>
                                <ReactSVG id="iconAddSVG" src={`${pathServer.PATH_IMG}icon/ic_add.svg`} beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                                          className="icon-add-car-to-inventory" />
                                <div className="title-suggestion">
                                  <span>Add to inventory</span>
                                </div>
                              </div>
                            </div>
                            : null
                        }
                      </div>
                    </div>
                }
              </div>
            </div>
        }
      </div>
    </div>
  );
}