import React, {useState} from 'react'
import {DPInput} from '../../../../../common/DPInput'
import {DPToggle} from '../../../../../common/DPToggle'
import {
  ABOUT_US_ICON_NAMES,
  LINK_TO_PAGE,
  LINK_TO_URL,
} from '../../../../../Constants'
import LinkContent from '../../Tabs/LinkContent'
import ExternalUrlContent from '../../Tabs/ExternalUrlContent'
import DPTabs from '../../../../../common/themes/DPTabs'
import {ReactSVG} from 'react-svg'
import {DPTextarea} from '../../../../../common/DPTextarea'
import {cloneDeep, isEmpty} from 'lodash'
import {DPPopover} from '../../../../../../common/DPPopover'
import {PopoverIconsBody} from '../../ThemeSettingContainer'
import {getPositionById, units} from '../../../../util/Utils'
import {
  pathServer,
  PAGE_HOME_ID,
  SECTION_FINANCE_ID,
  TEMPLATE_CODE_MODERN_VIEW_3,
  TEMPLATE_CODE_MODERN_VIEW_2,
  TEMPLATE_CODE_MODERN_VIEW,
  EXT_IMG,
  EXT_PNG, EXT_OTHER_IMG,
} from '../../../../../../common/Constants'
import DPDropzone from "../../../../../common/DPDropzone";
import DPCheckbox from "../../../../../common/DPCheckbox";

const MAX_SIZE_SITE_TITLE = 45
const MAX_SIZE_CAR_BODY = 150
const MAX_SIZE_TEXT_BUTTON = 25
const MAX_SIZE_DESCRIPTION = 50

const FinanceCard = ({card, indexCard, onDrop, removeImage, onClick, onChangeCardInput, handleShowIcon, templateCode}) => {
  const {imageUrl, icon, description, id, showIcon, displayValue} = card
  return (
   <div className="financed-car">
     <div className="car-title">{`Card ${units(indexCard + 1)}`}</div>
     {/*todo ==> commented because backend need implemented */}
     <div className="container-background">
       {
         templateCode === TEMPLATE_CODE_MODERN_VIEW ||
         templateCode === TEMPLATE_CODE_MODERN_VIEW_2 ||
         templateCode === TEMPLATE_CODE_MODERN_VIEW_3 ?
           <>
             <span className="title-background">Background image</span>
             {
               isEmpty(imageUrl) || imageUrl === "" ?
                 <DPDropzone
                   onDrop={(file) => onDrop(file, indexCard)}
                   extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                   dataTestInput={'dealer-media'}
                 >
                   <>
                     <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_cloud_upload_arrow.svg`}
                               className="customer-image-upload"
                               beforeInjection={svg => svg.classList.add('cloud-upload')}/>
                     <label className="label-upload-logo">Upload</label>
                   </>
                 </DPDropzone>
                 : <img className="background-image" src={imageUrl}/>
             }
             <div className="container-button">
               <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="content-delete-icon"
                         onClick={() => removeImage(indexCard) } beforeInjection={ svg => svg.classList.add('delete-icon')}/>
               <DPDropzone divClassName={"drop-reset"} onDrop={(file) => onDrop(file, indexCard)} extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                           dataTestInput={'dealer-media'}>
                 <ReactSVG src={pathServer.PATH_IMG + "icon/ic_reset.svg"} className="icon-update" beforeInjection={ svg => svg.classList.add('update-icon')}/>
               </DPDropzone>
             </div>
           </>:null
       }
     </div>
     <div className="row-toggle">
       <div className="label-toggle">Icon</div>
       <DPToggle id={`cardToggle${indexCard}`} isChecked={!!showIcon} handleCheck={() => handleShowIcon(indexCard)}/>
     </div>
     <div className="row-icon">
       <div className="container-icon">
         <div id={`card-${indexCard}`} className="container-select">
           <span>Icon</span>
           <div id={id} className="section-icon" onClick={() => onClick(card)}>
             <ReactSVG src={icon} className="icon-menu-content" beforeInjection={ svg => svg.classList.add('icon-menu')}/>
             <i className="fa fa-chevron-down" />
           </div>
         </div>
       </div>
       <div className="container-title">
         <DPInput
           id='displayValue'
           title='Car title'
           rightElement={<label className={`label-size right ${displayValue.length === MAX_SIZE_SITE_TITLE ? 'error-color' : ''}`}>{`${displayValue.length}/${MAX_SIZE_SITE_TITLE}`}</label>}
           value={displayValue}
           onChange={(evt) => onChangeCardInput(indexCard, evt)}
           maxLength={MAX_SIZE_SITE_TITLE}
         />
       </div>
     </div>
     {
       templateCode === TEMPLATE_CODE_MODERN_VIEW ||
       templateCode === TEMPLATE_CODE_MODERN_VIEW_2 ||
       templateCode === TEMPLATE_CODE_MODERN_VIEW_3 ?
       <div className="row-body">
         <DPTextarea
           id='description'
           label='Car body'
           maxLength={MAX_SIZE_CAR_BODY}
           rightElement={<label className={`label-size right ${description.length === MAX_SIZE_CAR_BODY ? 'error-color' : ''}`}>{`${description.length}/${MAX_SIZE_CAR_BODY}`}</label>}
           rows={4}
           styleTextarea={{resize:"none"}}
           value={description}
           onChange={(evt) => onChangeCardInput(indexCard, evt)}
         />
       </div>:null
     }


   </div>
  )
}

const FinancingSection = (props) => {

  const {templateCode, generalSetting} = props
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [files, setFiles] = useState([])
  const [fields, setFields] = useState([])

  const {pages, onChangePages, onChangeFile} = props
  const homePage = pages.find(x => x.id === PAGE_HOME_ID)
  const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
  const {title, buttonText, showButton, link = {}, steps, description} = financeSection
  const {linkToPage, linkToUrl} = link

  const onChangeTabNavigation = (index, type, value) => {
    const pagesNew = cloneDeep(pages)
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    financeSection.link = {type: type, [type]: value}
    onChangePages(pagesNew)
  }

  const tabs = [
    {key: LINK_TO_PAGE, title : 'Link to page', classNameHeader: "header-link", className: "label-link", component: <LinkContent value={linkToPage ? linkToPage : 'home'} type={'linkToPage'} onChangeTabNavigation={onChangeTabNavigation}/>},
    {key: LINK_TO_URL, title : 'External URL', classNameHeader: "header-link", className: "label-link", component: <ExternalUrlContent value={linkToUrl ? linkToUrl : ''} type={'linkToUrl'} onChangeTabNavigation={onChangeTabNavigation}/>}
  ]

  const onChangeTitle = (evt) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    financeSection.title = evt.currentTarget.value
    onChangePages(pagesNew)
  }

  const onChangeDescription = (evt) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    financeSection.description = evt.currentTarget.value
    onChangePages(pagesNew)
  }

  const setLinkType = (tab) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    if(financeSection.link){
      financeSection.link.type = tab
    }else{
      financeSection.link = { type: tab, [tab] :''}
    }
    onChangePages(pagesNew)
  }

  const handleShowButton = () => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
      financeSection.showButton = financeSection.showButton ? !financeSection.showButton : true
    onChangePages(pagesNew)
  }

  const onChangeButtonText = (evt) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    financeSection.buttonText = evt.currentTarget.value
    onChangePages(pagesNew)
  }

  const onDrop = (file,index) => {
    let pagesNew = cloneDeep(pages)
    let generalSettingNew = cloneDeep(generalSetting)
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    const {steps} = financeSection
    const currentCard = steps[index]
    files.push(file[0])
    fields.push(`card-${index}-image`)
    setFiles(files)
    setFields(fields)
    currentCard.imageUrl = URL.createObjectURL(file[0])
    onChangePages(pagesNew)
    onChangeFile(generalSettingNew, files, fields)
  }

  const removeImage = (index) => {
    let pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    const {steps} = financeSection
    const currentCard = steps[index]
    files.splice(index, 1);
    fields.splice(index, 1);
    currentCard.imageUrl = "";
    onChangePages(pagesNew);
  }

  const selectOption = (value) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    const {steps} = financeSection
    const cardFound = steps.find(x => x.id === selectedOption.id)
    if(cardFound){
      cardFound.icon = `${pathServer.PATH_IMG}icon/${value}`
      onChangePages(pagesNew)
    }
    setShowPopover(false)
  }

  const openPopover = (value) => {
    setShowPopover(true)
    setSelectedOption(value)
    const pointTo =  getPositionById(value.id)

    setAnchorEl({x: pointTo.x, y: pointTo.y + 34})
  }

  const closePopover = () => {
    setShowPopover(false)
  }

  const onChangeCardInput = (indexCard, evt) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    financeSection.steps[indexCard][evt.currentTarget.id] = evt.currentTarget.value
    onChangePages(pagesNew)
  }

  const handleShowIcon = (indexCard) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const financeSection = homePage.sections.find(x => x.id === SECTION_FINANCE_ID)
    financeSection.steps[indexCard].showIcon = financeSection.steps[indexCard].showIcon ? !financeSection.steps[indexCard].showIcon : true
    onChangePages(pagesNew)
  }

  const textButton = buttonText ? buttonText : ''

  return(
    <div className="container-home-financing">
      <div className="row-title">
        <DPInput
          id='title'
          title='Section title'
          rightElement={<label className={`label-size right ${title.length === MAX_SIZE_SITE_TITLE ? 'error-color' : ''}`}>{`${title.length}/${MAX_SIZE_SITE_TITLE}`}</label>}
          value={title}
          onChange={onChangeTitle}
          maxLength={MAX_SIZE_SITE_TITLE}
        />
        <DPTextarea
          id='description'
          label='Section description'
          containerClassName={'finance_description'}
          maxLength={MAX_SIZE_DESCRIPTION}
          onChange={onChangeDescription}
          rightElement={<label
            className={`label-size correct ${description.length === MAX_SIZE_DESCRIPTION ? 'error-color' : ''}`}>{`${description.length}/${MAX_SIZE_DESCRIPTION}`}</label>}
          rows={4}
          styleTextarea={{resize: 'none'}}
          value={description}
        />
      </div>
      <div className="row-cars">
        {
          steps && steps.map((card, index) => {
            return <FinanceCard key={`finance-car-${index}`} card={card} indexCard={index} onDrop={onDrop} removeImage={removeImage} onClick={openPopover} onChangeCardInput={onChangeCardInput} handleShowIcon={handleShowIcon} templateCode ={templateCode}/>
          })
        }
        {
          showPopover &&
          <DPPopover id='popoverLink' width={200} anchorEl={anchorEl} classNameWrapper='popover-link-item' handleRequestClose={closePopover}>
            <PopoverIconsBody icons={ABOUT_US_ICON_NAMES}
                              selectIcon={selectOption}
                              classname="about-us-icons-container"
            />
          </DPPopover>
        }
      </div>
      <div className="row-toggle">
        <div className="label-toggle">Show button</div>
        <DPToggle isChecked={!!showButton} handleCheck={handleShowButton}/>
      </div>
      {
        !!showButton &&
          <div className="row-navigation">
            <DPTabs tabs={tabs}
                    classNameContainer=""
                    classNameTabActive="link-active"
                    otherFunction={setLinkType}
                    checkboxIncluded={true}
                    tabActive={link ? link.type : null}
            />
            <DPInput
              className='button-text'
              id='buttonText'
              title='Button text'
              value={textButton}
              onChange={onChangeButtonText}
              rightElement={<label className={`label-size right ${textButton.length === MAX_SIZE_TEXT_BUTTON ? 'error-color' : ''}`}>{`${textButton.length}/${MAX_SIZE_TEXT_BUTTON}`}</label>}
              maxLength={MAX_SIZE_TEXT_BUTTON}
            />
          </div>
      }
    </div>
  )
}

export default FinancingSection