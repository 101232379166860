import React, {Component, useState} from 'react'
import {unstable_batchedUpdates} from 'react-dom'
import {browserHistory} from 'react-router'
import {
  loadTemplatesMain,
  saveTemplateApi,
  searchTemplateApi,
  setAsMainTemplate,
  updateTemplateStatus
} from '../../../Api'
import {siteColorAdmin, USERS_BY_PAGE} from '../../../Constants'
import {connect} from 'react-redux'
import {setTemplateId} from '../../../actions'
import {DPSpinner} from '../../../common/DPSpinner'
import {ReactSVG} from 'react-svg'
import {DPPillBox} from '../../util/ComponentUtils'
import Moment from 'moment'
import {Modal} from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import ModalConfirmDelete from '../../util/ModalConfirmDelete'
import DPButton from '../../../common/DPButton'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'
import {DPPagination} from '../../../../common/DPPagination'

const modalsType = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  COPY: 'COPY'
}

const BodyTemplate = ({templatesMain, selectedTemplate, setSelectedTemplate}) => {

  return (
    <>
      {
        templatesMain.map((template, index) => {
          return(
            <div key={`template-main-${index}`} className="template-box">
              <div className={(selectedTemplate && selectedTemplate.code === template.code) ? 'col-image template-selected' : 'col-image'}
                   onClick={(evt) => setSelectedTemplate(template)}>
                <img src={`${pathServer.PATH_IMG}${template.code}.jpg`}/>
              </div>
            </div>
          )
        })
      }
    </>
  )
}

const getActionName = (modalType) => {
  let name = 'Next'
  if (modalType === modalsType.NEW || modalType === modalsType.COPY) {
    name = 'Create'
  } else if (modalType === modalsType.EDIT) {
    name = 'Save'
  }
  return name;
}

const getDisabled = (modalType, selectedTemplate, name, isSelected) => {
  let disabled = false
  if (modalType === modalsType.NEW ) {
    if (!selectedTemplate || (isSelected && isEmpty(name))) {
      disabled = true
    }
  } else if ((modalType === modalsType.EDIT || modalType === modalsType.COPY) && isEmpty(name)) {
    disabled = true
  }
  return disabled;
}

//Select a template and name your theme
const ModalTemplate = ({show,templatesMain,onHide,onFinished,templateTitle,wrapperClass,modalType,templateToUpdate,dealerId}) => {

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [title, setTitle] = useState(templateTitle);
  const [classModal, setClassModal] = useState(wrapperClass);
  const [name, setName] = useState(templateToUpdate?.name);
  const [isSelected, setIsSelected] = useState(false);

  const onSave = async(template) => {
    const templateToInsert = {...pick(template, ['code', 'content', 'dealerId', 'active']), name: name, order: 1}
    onFinished(templateToInsert);
  }

  const onAction = async () => {
    if (modalType === modalsType.NEW) {
      if (isSelected)
        await onSave(selectedTemplate)
      else {
        unstable_batchedUpdates(() => {
          setIsSelected(true)
          setTitle('Name your theme')
          setClassModal('modal-edit-template')
        });
      }
    } else if (modalType === modalsType.EDIT) {
      const templateToSave = {...pick(templateToUpdate, ['id','code', 'content', 'order', 'createdBy', 'createDate']),
        dealerId: dealerId, name: name, active: true}
      onFinished(templateToSave);
    } else if (modalType === modalsType.COPY) {
      await onSave({...templateToUpdate,dealerId: dealerId, active: true})
    }
  }

  const disabled = getDisabled(modalType,selectedTemplate,name,isSelected);

  return(
    <Modal show={show} className={`modal-new-sale ${classModal}`} backdropClassName="backdrop-dealer">
      <Modal.Body>
        <div className="panel-container">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                    beforeInjection={ svg => svg.classList.add("icon-close")} onClick={onHide}/>
          <div className="panel-container-body">
            <div className={'templates-header'}>
              <h3>{title}</h3>
            </div>

            {
              (modalType === modalsType.NEW && !isSelected) ?
                <div className="templates-container">
                  <BodyTemplate templatesMain={templatesMain}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                  />
                </div> : null
            }

            <div className="container-button">

              {
                (modalType !== modalsType.NEW || isSelected)?
                  <input className={'form-control'}
                         value={name}
                         placeholder={'Name your theme'}
                         onChange={(evt) => setName(evt.target.value)}/> : null
              }

              <DPButton className={disabled ? "button-save button-save-disabled" : "button-save"}
                        width={'120px'}
                        onClick={onAction}
                        disabled={disabled}
                        data-test={'btn-create-template'}>
                {getActionName((isSelected || templateToUpdate) ? modalType : '')}
              </DPButton>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
};

class Templates extends Component {

  constructor() {
    super();
    this.state = {
      templates: null,
      itemsByPageQuantity: 10,
      isLoading: true,
      selectedTemplateId: null,
      showModalDelete: false,
      currentPage: 1,
      showModalNew: false,
      templatesMain: []
    }
  }

  componentDidMount = async() => {
    await this.loadTemplates();
    await this.search(null,0, 10);
  }

  loadTemplates = async () => {
    const response = await loadTemplatesMain()
    const {responseCode, responseMessage, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      this.setState({ templatesMain: data.templatesMain })
    } else {
      console.log(responseMessage)
    }
  }

  /*componentWillReceiveProps(){
  }*/

  search = async (keyword, from, size, currentPage = null) => {
    this.setState({ isLoading: true })
    const response = await searchTemplateApi(keyword, from, size)
    const {responseCode, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      const { templates, templatesQuantity } = data
      const state = {
        templates, templatesQuantity, itemsByPageQuantity: size, isLoading: false,
        showModalNew: false, showModalDelete: false, templateToUpdate: null, templateTitle: ''
      }
      if (currentPage)
        state.currentPage = currentPage
      this.setState(state)
    } else {
      this.setState({ isLoading: false })
    }
  }

  select = async (id, event) => {
    event.stopPropagation();
    //const {templateId} = this.props;
    const {itemsByPageQuantity} = this.state;
    const {responseCode, responseMessage} = await setAsMainTemplate(id);
    if (responseCode === SUCCESSFUL_CODE) {
      this.props.setTemplateId(id);
      await this.search(null, 0, itemsByPageQuantity, 1)
    } else {
      console.error(responseMessage)
    }
  }

  customize = (id, event) => {
    event.stopPropagation();
    browserHistory.push(`/main/setting/template/${id}`);
  }

  handleRequestClose = () => {
    this.setState({showModalNew:false, showModalDelete: false, selectedTemplateId: null, templateToUpdate: null})
  }

  handleItemsByPage = async (option) => {
    const itemsByPageQuantity = parseInt(option);
    await this.search(null, 0,itemsByPageQuantity,1)
  }

  handlePageChange = async (nextPage, totalPages) => {
    const {itemsByPageQuantity,templatesQuantity} = this.state;
    let from  = 0;
    if (nextPage >= 0) {
      let currentPage = nextPage;
      if (currentPage > totalPages) {
        currentPage = 1;
      } else if (currentPage === 0) {
        currentPage = totalPages;
        from = itemsByPageQuantity * (totalPages - 1);
      } else {
        if (templatesQuantity !== 0)
          from = itemsByPageQuantity * (currentPage - 1);
      }

      this.setState({currentPage});
      await this.search(null, from, itemsByPageQuantity)
    }
  }

  onEdit = (template,title,modalType,event) => {
    event.stopPropagation();
    this.setState({showModalNew: true, templateToUpdate: template, templateTitle: title,
      modalType: modalType, wrapperClass: 'modal-edit-template'})
  }

  showModalDelete = (selectedTemplateId, event) => {
    event.stopPropagation();
    this.setState({selectedTemplateId, showModalDelete: true})
  }

  showModalNew = (event) => {
    event.stopPropagation();
    this.setState({showModalNew: true, templateTitle: 'Select a template', modalType: modalsType.NEW,
      wrapperClass: 'modal-new-template'})
  }

  onSave = async (template) => {
    const response = await saveTemplateApi(template)
    const {responseCode, responseMessage, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      browserHistory.push(`/main/setting/template/${data.templateId}`)
    } else {
      console.error(responseMessage)
    }
  }

  onDelete = async () => {
    const {selectedTemplateId, itemsByPageQuantity} = this.state;
    const {responseCode, responseMessage} = await updateTemplateStatus(selectedTemplateId, false)
    if (responseCode === SUCCESSFUL_CODE) {
      await this.search(null, 0, itemsByPageQuantity, 1)
    } else {
      console.error(responseMessage)
    }
  }

  onCopy = async (template, event) => {
    event.stopPropagation();
    const {itemsByPageQuantity} = this.state;
    const {dealerId} = this.props;
    const name = template.name
    const templateToCopy = {...pick(template, ['code', 'content', 'active']), dealerId,
      name: `${name} copy`, order: 1, active: true}
    const response = await saveTemplateApi(templateToCopy)
    const {responseCode, responseMessage, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      await this.search(null, 0, itemsByPageQuantity)
    } else {
      console.error(responseMessage)
    }
  }

  render() {
    const {templates,isLoading,currentPage,itemsByPageQuantity, templatesQuantity,
      showModalNew,templatesMain, showModalDelete, templateTitle,
      modalType, templateToUpdate, wrapperClass} = this.state;

    if(!templates) return null;

    const {templateId, dealerId} = this.props;
    const totalPages = templatesQuantity ? Math.ceil(templatesQuantity / itemsByPageQuantity) : 1;

    return (
      <div className="setting-container" style={{display: 'block'}}>
        <div className="setting-template-header">
          <div className="row-message row-setting-user">
            <div className="form-setting-button" style={{width: "8%", paddingLeft: "20px"}}>
              {
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"}
                          beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                          className="icon-add-user"
                          onClick={this.showModalNew}/>
              }
            </div>
            <div className="setting-user-pagination">
              <DPPagination
                currentPage={currentPage}
                totalPages={totalPages}
                optionsQuantityByPage={USERS_BY_PAGE}
                selectedItemsByPage={itemsByPageQuantity}
                handleChangeItemsByPage={this.handleItemsByPage}
                handlePageChange={this.handlePageChange}
                siteColor={siteColorAdmin}
              />
            </div>
          </div>
        </div>
        <div className={"table-form-setting user-table"}>
          <div className="container-table-users">
            <table className="table-templates">
              <thead style={{backgroundColor: "#EFEFEF"}}>
              <tr className="">
                <th className="title-name-template">Theme name</th>
                <th className="">Create by</th>
                <th className="">Create date</th>
                <th className="">Update date</th>
                <th className="th-actions"/>
              </tr>
              </thead>
              <tbody className="tbody-user">
              {
                isLoading ?
                  <DPSpinner /> :
                  (
                    templates.length !== 0 ?
                      (
                        templates.map((template, index) => {
                          const {id, name, createdByName, createDate, modifiedDate} = template;
                          const styleWidth = id === templateId ? 105 : 20;
                          return (
                            <tr key={index + 'tr'}
                                className="rowCostumer row-templates"
                                onClick={(evt) => this.customize(id,evt)}>
                              <td className="title-name-template">
                                <div className={'title-name-ellipse'} style={{width: `calc(100% - ${styleWidth}px)`}}>
                                  {name}
                                </div>
                                {
                                  id === templateId ?
                                    <div className="container-pill">
                                      <DPPillBox displayValue='SELECTED'/>
                                    </div> : null
                                }
                              </td>
                              <td className="">{createdByName ? createdByName : 'System'}</td>
                              <td>
                                <span>{Moment(createDate).format('MM/DD/YYYY')}</span>
                              </td>
                              <td>
                                <span>{Moment(modifiedDate).format('MM/DD/YYYY')}</span>
                              </td>
                              <td className={'container-actions'}>
                                <div className="customer-actions">
                                  <div className={'template-actions'}>
                                    <ReactSVG
                                      onClick={(e) => this.onEdit(template,'Rename your theme',modalsType.EDIT, e)}
                                      src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"}
                                      beforeInjection={svg => svg.classList.add("icon-edit")}
                                    />

                                    <ReactSVG
                                      onClick={(e) => this.onEdit(template,'Name your theme',modalsType.COPY, e)}
                                      src={pathServer.PATH_IMG + "icon/ic_copy.svg"}
                                      beforeInjection={svg => svg.classList.add("icon-copy")}
                                    />

                                    <ReactSVG
                                      id="imgDelete"
                                      src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                                      beforeInjection={svg => svg.classList.add("icon-delete")}
                                      onClick={(e) => {this.showModalDelete(id, e)}}
                                    />
                                  </div>
                                  <button className={'btn btn-primary btn-select'}
                                          onClick={(e) => id === templateId ? this.customize(id, e) : this.select(id, e)}>
                                    {
                                      id === templateId ?
                                        'Customize' : 'Select'
                                    }
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      ) : null
                  )
              }
              </tbody>
            </table>
          </div>
        </div>

        {
          showModalNew ?
            <ModalTemplate show={showModalNew}
                           templatesMain={templatesMain}
                           onHide={this.handleRequestClose}
                           onFinished={this.onSave}
                           wrapperClass={wrapperClass}
                           templateTitle={templateTitle}
                           modalType={modalType}
                           templateToUpdate={templateToUpdate}
                           dealerId={dealerId}

            /> : null
        }

        {
          showModalDelete ?
            <ModalConfirmDelete show={showModalDelete}
                                showButtonDelete={true}
                                onSubmit={this.onDelete}
                                onHide={this.handleRequestClose}
                                classButtonDelete="btn-delete"
                                buttonLabelDelete="Yes, Delete"
                                message={"Do you want to delete this Template?"}
                                styleModal="modal-delete"
                                styleRight="panel-right-delete-user"/> : null
        }

      </div>
    )
  }
}



const mapStateToProps = (state, ownProps) => {
  return {
    dealerId:state.dealerId,
    templateId:state.templateId,
  }
};

export default connect(mapStateToProps,{setTemplateId})(Templates)