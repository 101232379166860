import React, { useEffect, useState } from 'react'
import {pathServer, SUCCESSFUL_CODE, TYPE_FEATURE, TYPE_STATUS} from '../../../../../common/Constants'
import { ReactSVG } from 'react-svg'
import { apiGenerateInvoice, loadLastOrder, loadLastPaidOrder} from '../../../../Api'
import Moment from 'moment'
import {unstable_batchedUpdates} from 'react-dom'
import {browserHistory} from 'react-router'

export const IncludedItem = ({ ...props }) => {
  return (
    <div className={'included-item-container'}>
      <ReactSVG
        src={pathServer.PATH_IMG + 'icon/ic_check_normal.svg'}
        beforeInjection={svg => svg.classList.add('icon-check')}
      />
      <div className={'message-text'}>{props.messageText}</div>
      {
        Number.isInteger(props.progressValue) &&
        <React.Fragment>
          <div className="progress-bar-label">{`${props.progressValue}/${props.maxProgressValue}`}</div>
          <progress
            id="file"
            max={props.maxProgressValue}
            value={props.progressValue}
            className="progress-bar-container"
          >
            {`${props.progressValue}%`}
          </progress>
        </React.Fragment>
      }
    </div>
  )
}

export const NotIncludedItem = ({ ...props }) => {
  return (
    <div className={'not-included-item-container'}>
      <div className={'icon'}>&times;</div>
      <div className={'message-text'}>{props.messageText}</div>
    </div>
  )
}

export const Header = (props) => {
  return (
    <div className={'subsection-header-container'}>
      <div className={'header-text'}><b>{props.headerText}</b></div>
      {
        props.buttonClick && props.buttonText &&
        <div className={'button-container'}>
          {!props.isUpgradeDisabled &&
            <button className="btn-theme" onClick={props.buttonClick}>
              {props.buttonText}
            </button>
          }
        </div>
      }
    </div>
  )
}

export const GraphsSection = ({ onClick, order, paymentMethodMain, isUpgradeDisabled, subscription, swapModalStatus }) => {

  const invoiceGenerator = (order) => {
    if (!order) return
    window.open(apiGenerateInvoice(order.orderId), '_blank')
  }

  return (
    <section id="graphs">
      <Header headerText={order?.subscriptions[0].name || subscription?.planName} buttonClick={onClick}
              buttonText={'Upgrade'} isUpgradeDisabled={isUpgradeDisabled}/>

      <div className={'subsection-container'}>
        <div className={'subsection-header'}>Last payment</div>
        <div className={'subsection-text'}>{Moment(order?.startDate || subscription?.startDate).format('LL')}</div>
        <div className={'subsection-link '} onClick={() => invoiceGenerator(order)}>View invoice</div>
      </div>

      <div className={'subsection-container'}>
        <div className={'subsection-header'}>Next payment due</div>
        <div
          className={'subsection-text'}>{`${Moment(order?.startDate || subscription?.startDate).add(30, 'days').format('LL')} / Monthly cycle`}</div>
      </div>

      <div className={'subsection-container'}>
        <div className={'subsection-header'}>Payment method</div>
        <div className={'subsection-credit-card-info'}>
          <ReactSVG
            src={pathServer.PATH_IMG + 'icon/icon_simple_visa.svg'}
            beforeInjection={svg => svg.classList.add('icon-credit-card-logo')}
            className={'icon-credit-card-logo'}
          />
          <div className={'subsection-text'}>{`**** ${paymentMethodMain?.cardNumber}`}</div>
        </div>
        <div className={'subsection-link '} onClick={swapModalStatus}>Update</div>
      </div>

    </section>
  )
}

const SubscriptionLayout = ({ ...props }) => {
  const [order, setOrder] = useState(null)
  const [isPaid, setIsPaid] = useState(true)
  const [subscriptions, setSubscriptions] = useState(null)
  const [paymentMethodMain, setPaymentMethodMain] = useState(null)
  const [includedFeatures, setIncludedFeatures] = useState([])
  const [notIncludedFeatures, setNotIncludedFeatures] = useState([])
  const [subscription, setSubscription] = useState(null)
  const [listings, setListings] = useState(0)
  const [teamMembers, setTeamMembers] = useState(0)
  const [justUpgraded, setJustUpgraded] = useState(false)
  const browserState = browserHistory.getCurrentLocation().state

  useEffect(() => {

    if (browserState?.upgradedPlan){
      setJustUpgraded(true)
      setTimeout(()=>{
        browserHistory.push({pathname: '/main/subscriptions', state: undefined})
        setJustUpgraded(false)
      },60000)
    }

  },[browserState])

  useEffect(() => {
    const loadData = async () => {
      const updateState = (order, subscriptions, paymentMethod, includedFeatures, notIncludedFeatures, usersQuantity, listingsQuantity) =>
        unstable_batchedUpdates(() => {
          setOrder(order)
          setSubscriptions(subscriptions)
          setPaymentMethodMain(paymentMethod)
          setIncludedFeatures(includedFeatures)
          setNotIncludedFeatures(notIncludedFeatures)
          setSubscription(subscriptions[0])
          setTeamMembers(usersQuantity || 0)
          setListings(listingsQuantity || 0)
        })

      const {responseCode, responseMessage, data} = await loadLastOrder()
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        return
      }
      const {order, paymentMethod, includedFeatures, notIncludedFeatures, subscriptions, usersQuantity, listingsQuantity} = data
      if (!order) {
        setIsPaid(false)
        const {responseCode: responseCodeOrder, responseMessage: responseMessageOrder, data: dataOrder} = await loadLastPaidOrder()
        if (responseCodeOrder !== SUCCESSFUL_CODE) {
          console.error(responseMessageOrder)
          return
        }
        const {order: orderP, paymentMethod: paymentMethodP, includedFeatures: includedFeaturesP, notIncludedFeatures: notIncludedFeaturesP} = dataOrder
        updateState(orderP, subscriptions, paymentMethodP, includedFeaturesP, notIncludedFeaturesP, 0, 0)
      } else {
        updateState(order, subscriptions, paymentMethod, includedFeatures, notIncludedFeatures, usersQuantity, listingsQuantity)
      }
    }
    loadData().then()
  }, [props.refreshSubscriptionCard])

  if (!order && !subscription) {
    return null
  }

  const handleClick = (e) => {
    browserHistory.push({pathname:"/main/setting/billingPayment"});
  }

  const closeBanner =()=>{
    setJustUpgraded(false)
    browserHistory.push({pathname: '/main/subscriptions', state: undefined})
  }

  const getLimitQuantities = (typeQuantity, typeField) => {
    if (typeField === TYPE_FEATURE) {
      return order.subscriptions[0][typeQuantity] === -1 ? '' : order.subscriptions[0][typeQuantity]
    }
    return order.subscriptions[0][typeQuantity] === -1 ? 'Unltd' : order.subscriptions[0][typeQuantity]
  }

  return (
    <React.Fragment>
      {justUpgraded &&
        <div className={'message-bar-container'}>
          <div>Congratulations! Plan upgraded successfully</div>
          <div className={'icon'} onClick={closeBanner}>&times;</div>
        </div>
      }
      {
        !isPaid &&
        <div className={'message-bar-container message-bar-container-alert'}>
          <div>
            {`
              Your last invoice is unpaid. Please attend it by
              ${Moment(order.startDate).add(30, 'days').format('LL')}
               to keep your plan features.
             `
            }
          </div>
          <div className={'right-section'}>
            <div className={'pay'} onClick={handleClick}>Pay now</div>
          </div>
        </div>
      }
      <main id="subscription-layout">

        <GraphsSection onClick={props.upgrade} order={order} paymentMethodMain={paymentMethodMain} subscription={subscription}  swapModalStatus={props.swapModalStatus}/>

        <section id="theme">
          <Header headerText={'Features included'}/>
          {order && <>
            <IncludedItem messageText={`${getLimitQuantities('teamMembersQuantity', TYPE_FEATURE)} Team members`}
                          progressValue={teamMembers}
                          maxProgressValue={getLimitQuantities('teamMembersQuantity', TYPE_STATUS)}/>
            <IncludedItem messageText={`${getLimitQuantities('listingsQuantity', TYPE_FEATURE)} Listings`}
                          progressValue={listings}
                          maxProgressValue={getLimitQuantities('listingsQuantity', TYPE_STATUS)}/>
          </>}
          {
            includedFeatures.map(feature => {
              return (
                <IncludedItem key={`feature-${feature.id}`} messageText={feature.name}/>
              )
            })
          }
          {
            notIncludedFeatures.map(feature => {
              return (
                <NotIncludedItem key={`feature-${feature.id}`} messageText={feature.name}/>
              )
            })
          }
        </section>
      </main>
    </React.Fragment>
  )
}

export default SubscriptionLayout
