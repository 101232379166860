import React, { useEffect, useState } from 'react'
import DPPage from '../../common/DPPage'
import {
    deleteFeatureFlagApi,
    getByFlagNameApi,
    loadFeatureFlags,
    saveFeatureFlag,
    updateFeatureFlag
} from "../../Api";
import {pathServer, SUCCESSFUL_CODE} from "../../../common/Constants";
import {ReactSVG} from "react-svg";
import DPButton from "../../common/DPButton";
import DPTable from "../../common/DPTable";
import {DPToggle} from "../../common/DPToggle";
import {DPInput} from "../../common/DPInput";
import {browserHistory} from "react-router";

require('./FlagsEdit.scss')

const dataFlagsEdit = [
    {flagName: 'PAYMENTS', dealerName: 'GLOBAL', dealerId: 'GLOBAL', active: true},
    {flagName: 'PAYMENTS', dealerName: 'DealerPro', dealerId: '10000', active: true},
    {flagName: 'PAYMENTS', dealerName: 'TuDealerContigo', dealerId: '10001', active: true}
]

const FlagsEdit = (props) => {
    const [flags, setFlags] = useState([])
    const [flagName, setFlagName] = useState(props?.location?.state?.flagName || '')
    const [newDealerId, setNewDealerId] = useState('')
    const [doRefresh, setDoRefresh] = useState(false)

    useEffect(() => {
        (async () => {
            const {responseCode, data} = await getByFlagNameApi(flagName)
            if (responseCode === SUCCESSFUL_CODE) {
                setFlags(data.featureFlags)
            }
        })()

    }, [doRefresh])

    const updateFlag = async (flag) => {
        const {responseCode, responseMessage} = await updateFeatureFlag(flag.flagName, !flag.active, flag.dealerId)
        if (responseCode === SUCCESSFUL_CODE) {
            const index = flags.findIndex(f => f.flagName === flag.flagName && f.dealerId === flag.dealerId)
            if (index > -1) {
                const flagsCopy = [...flags]
                flagsCopy[index] = {...flag, 'active': !flag.active}
                setFlags(flagsCopy)
            }
        } else {
            console.error(responseMessage)
        }
    }

    const saveFlag = async (flagName, dealerId, active) => {
        const featureFlagSave = {dealerId, flagName, active}
        const {responseCode, responseMessage} = await saveFeatureFlag(featureFlagSave)
        if (responseCode === SUCCESSFUL_CODE) {
            setDoRefresh(!doRefresh)
        }
        else {
            console.error(responseMessage)
        }
    }

    const deleteFlag = async (flagName, dealerId) => {
        const {responseCode, responseMessage} = await deleteFeatureFlagApi(flagName, dealerId)
        if (responseCode === SUCCESSFUL_CODE) {
            setDoRefresh(!doRefresh)
        }
        else {
            console.error(responseMessage)
        }
    }

    const addNewDealer = async () => {
        await saveFlag(flagName, newDealerId, true)
        setNewDealerId('')
    }

    return (
        <>
            <DPPage
                title={`Feature flag table > ${flagName}`}
                showContentHeader={false}
                className={'dp-page-flagsEdit'}
                showBackBtn={true}
                actionBack={() => browserHistory.goBack()}
            >
                <DPTable
                    isLoading={false}
                    data={flags}
                    config={
                        {
                            columns: [
                                {id: 'dealerName', label: 'Dealer Name', style: {width: '35%'}},
                                {id: 'dealerId', label: 'Dealer ID', style: {width: '45%'}},
                                {id: 'status', label: 'Status', style: {width: '10%'}},
                                {id: 'actions', label: 'Actions', style: {width: '10%'}},
                            ]
                        }
                    }
                    renderColumn={
                        (flag, columnConf) => {
                            const {dealerName, dealerId, active, flagName} = flag
                            switch (columnConf.id) {
                                case 'dealerName':
                                    return dealerName
                                case 'dealerId':
                                    return dealerId
                                case 'status':
                                    return (
                                        <DPToggle
                                            handleCheck={() => updateFlag(flag)}
                                            isChecked={active}
                                            id={"active"}
                                        />
                                    )
                                case 'actions':
                                    return (
                                        dealerId !== 'GLOBAL'
                                        ? <ReactSVG key={flagName + dealerId + 'trash'} id="imgTrash" src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                              className="icon-delete"
                                              beforeInjection={ svg => svg.classList.add('icon-delete-svg')}
                                              onClick={(evt) => deleteFlag(flagName, dealerId)}/> : null
                                    )
                            }
                        }
                    }
                />

                <div className={'add-dealer-container'}>
                    <DPInput
                        title={'Add a new dealer by ID'}
                        id={'new-dealer-id'}
                        type={'text'}
                        value={newDealerId}
                        onChange={(e) => setNewDealerId(e.target.value)}
                        className='control-add-new-dealerId'
                    />
                    <div className={'btn-add-dealer-container'}>
                        <DPButton
                            className={'btn-add-dealer'}
                            onClick={addNewDealer}
                        >
                            {'Add dealer'}
                        </DPButton>
                    </div>
                </div>
            </DPPage>
        </>
    )
}


export default FlagsEdit