import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash'
import Dropzone from 'react-dropzone'
import {ReactSVG} from 'react-svg'
import {EXT_PDF, pathServer} from '../../../../common/Constants'

class SaleReloadFilesPageElement extends Component {
  render() {
    const { fileCode, fileUrl, fileName, fileType, onDrop, goToPagePrint, fileCodeIsLoading } = this.props
    const textButton = !isEmpty(fileUrl) ? "Change" : "Upload"

    // Trick - To refresh last image uploaded
    const currentDate = new Date();
    const modifiedFileUrl = `${fileUrl}?${currentDate.getMilliseconds()}`
    // End Trick

    return (
      <div className="sale-file">
        <div className="container-image-file">
          {
            fileCodeIsLoading === fileCode
              ? <div className="loader-container">
                <div className="loader"/>
              </div>
              : !isEmpty(fileUrl)
                ? <img
                  className="image-sale-file"
                  src={fileType === EXT_PDF ? `${pathServer.PATH_IMG}pdfModel.jpg` : modifiedFileUrl}
                />
                : <Dropzone
                  onDrop={(file) => onDrop(fileCode, file)}
                  accept=".jpeg,.jpg,.png,.pdf"
                >
                  {({getRootProps, getInputProps}) => (
                    <div
                      {...getRootProps()}
                      className="dropzone-container dropzone-file"
                    >
                      <input {...getInputProps()}/>
                      <ReactSVG
                        src={pathServer.PATH_IMG + "icon/ic_file_upload.svg"}
                        className="icon-sales-file"
                        beforeInjection={svg => svg.classList.add('icon-upload-file')}
                      />
                    </div>
                  )}
                </Dropzone>
          }
        </div>
        <div className="container-type-buttons">
          <div className="container-type">
            <label>{fileName}</label>
          </div>
          <div className="container-buttons">
            <Dropzone
              onDrop={(file) => onDrop(fileCode, file)}
              accept=".jpeg,.jpg,.png,.pdf"
            >
              {({getRootProps, getInputProps}) => (
                <div
                  {...getRootProps()}
                  className="dropzone-container dropzone-file"
                >
                  <input {...getInputProps()} />
                  <button className="upload">
                    {
                      !isEmpty(fileUrl)
                        ? <ReactSVG
                          src={pathServer.PATH_IMG + "icon/ic_attach_file.svg"}
                          className="icon-attach-file"
                          beforeInjection={ svg => svg.classList.add('icon-attach')}
                        />
                        : <ReactSVG
                          src={pathServer.PATH_IMG + "icon/ic_file_upload.svg"}
                          className="icon-upload-file"
                          beforeInjection={ svg => svg.classList.add('icon-upload')}
                        />
                    }
                    {textButton}
                  </button>
                </div>
              )}
            </Dropzone>
            <button
              className={!isEmpty(fileUrl) ? "print" : "print-disabled"}
              onClick={() => goToPagePrint(fileCode)}
              disabled={isEmpty(fileUrl)}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    )
  }
}

SaleReloadFilesPageElement.defaultProps = {
  fileUrl: null,
  fileCodeIsLoading: null,
}

SaleReloadFilesPageElement.propTypes = {
  fileCode: PropTypes.string.isRequired,
  fileUrl: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  goToPagePrint: PropTypes.func.isRequired,
  fileCodeIsLoading: PropTypes.string,
}

export default SaleReloadFilesPageElement