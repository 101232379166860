import React from 'react'
import {useState, useEffect} from 'react'
import {ReactSVG} from 'react-svg'
import {pathServer, SUCCESSFUL_CODE} from '../../../../../common/Constants'
import {loadLastOrder, loadInvoices} from '../../../../Api'
import Payment from '../../../payment'
import {browserHistory} from 'react-router'
import DPButtonIcon from "../../../../common/DPButtonIcon";
import moment from 'moment'
import {DUE_DAYS, MONTH_DAYS} from "../../../../Constants";
export function getDaysToCurrentSubscriptionRemaining(endDate){
  if (endDate > 0) {
    const now = moment()
    const endDateMoment = moment(endDate)
    return endDateMoment.diff(now, 'days')
  }

  return 0
}

export function getCredit(currentPlanPrice, days){
  return Math.round((currentPlanPrice * days * 100) / MONTH_DAYS) / 100
}

export function calculatePrice(newPlanPrice, credit){
  return newPlanPrice > 0 ? Math.round((newPlanPrice - credit) * 100) / 100 : 0
}

const SummaryInfoLayout = (props) => {
  const [showModalPaymentForm, setShowModalPaymentForm] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [subscriptionId, setSubscriptionId] = useState(null)
  const [reload, setReload] = useState(false)

  const {nextInventoryItem: selectedPlan, order, paymentMethodMain, currentInventoryItem} =  props
  const newPlanPrice = selectedPlan?.price ?? 0
  const daysDiff = getDaysToCurrentSubscriptionRemaining(order?.endDate ?? 0)
  const credit = getCredit(currentInventoryItem?.price ?? 0, daysDiff)
  const totalAmount = calculatePrice(newPlanPrice, credit)
  const selectedPlanInfo = {...selectedPlan, amount: totalAmount > 0 ? totalAmount : 0,
    orderItemsList: [{inventoryItemName: selectedPlan?.name}], orderDueDate: moment().add(MONTH_DAYS + DUE_DAYS, 'days')}

  const loadData = async () => {
    const {responseCode, responseMessage, data} = await loadInvoices()
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      return
    }
    const {paymentMethods} = data
    const {responseCode: responseCodeOrder, responseMessage: responseMessageOrder, data: dataOrder } = await loadLastOrder()
    if (responseCodeOrder !== SUCCESSFUL_CODE) {
      setPaymentMethods(paymentMethods)
      console.error(responseMessageOrder)
      return
    }
    const {subscriptions} = dataOrder
    setPaymentMethods(paymentMethods)
    setSubscriptionId(subscriptions.length > 0 ? subscriptions[0].id : "")
  }


  useEffect(()=> {
    loadData().then()
    if (reload) browserHistory.push({ pathname: '/main/subscriptions', state: { upgradedPlan: true } })
  }, [reload])

  const handleSubmitPurchase = async () => {
    if (subscriptionId) console.log('subscription id', subscriptionId)
    setShowModalPaymentForm(current => !current)
  }

  const handleClosePaymentModal = () => {
    setShowModalPaymentForm(false)
  }

  return (
    <>
      {
        showModalPaymentForm &&
        <Payment
          show={showModalPaymentForm}
          close={handleClosePaymentModal}
          selectedPlan={selectedPlanInfo}
          selectedOrder={selectedPlanInfo}
          subscriptionId={subscriptionId}
          setReload={setReload}
          paymentMethods={paymentMethods}
          currentInventoryItem={currentInventoryItem}
        />
      }
    <section id="theme">
      <div className={'subsection-container'}>
        <div className={'subsection-header'}><b>Payment method</b></div>
        <div className={'subsection-credit-card-info'}>
          <ReactSVG
            src={pathServer.PATH_IMG + 'icon/icon_simple_visa.svg'}
            beforeInjection={svg => svg.classList.add('icon-credit-card-logo')}
            className={'icon-credit-card-logo'}
          />
          <div className={'subsection-text'}>**** {paymentMethodMain && paymentMethodMain.cardNumber}</div>
        </div>
        <div className={'subsection-link '}>Add new payment method</div>
      </div>

      <div className={'summary-info-container'}>
        <div className={'summary-info-header'}>Summary</div>
        <div className={'plan-title'}>{selectedPlan && selectedPlan.description}</div>
        {
          selectedPlan &&
          <div className={'plan-item-container'}>
            <div>USD ${newPlanPrice} x 1 Month</div>
            <div className={'subtotal'}>USD ${newPlanPrice}</div>
          </div>
        }
        <div className={'plan-item-negative-container'}>
          <div>Credit from previous purchase</div>
          <div className={'subtotal'}>-USD ${credit}</div>
        </div>
      </div>

      <div className={'total-container'}>
        <div>Total</div>
        <div className={'subtotal'}>USD ${totalAmount > 0 ? totalAmount : 0}</div>
      </div>

      <div className={'submit-info-container'}>
        <DPButtonIcon
          buttonClass={'submit-btn'}
          background={selectedPlan ? '#2194f1' : '#A4AFB7'}
          btnSaveText={"Submit purchase"}
          iconSendPath={'ic_ionic_ios_lock.svg'}
          textColor={'#FFF'}
          onClick={handleSubmitPurchase}
          disabled={!selectedPlan}
        />
        <div className={'submit-detailer-info'}>By purchasing, you accept the <span className={'link'}>Terms and conditions</span> and
          acknowledge reading the <span className={'link'}>Privacy
            police</span>. You also agree to auto renewal of your monthly subscription for USD ${newPlanPrice}, which can be
          disabled at any time through your account.
        </div>
      </div>

    </section>
    </>
  )
}

export default SummaryInfoLayout