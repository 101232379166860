import React from 'react'
import PropTypes from 'prop-types'
import {loadStaticsFiles, uploadFileSales} from '../../../Api'
import SaleReloadFilesPageElement from './SaleReloadFilesPageElement.jsx'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import {SUCCESSFUL_CODE} from '../../../../common/Constants'

const KEYS = {
  URL: 'url',
  TYPE: 'type',
}

class SaleReloadFilesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedSaleStaticFiles: {},  // Contains other objects with this structure 'code', 'url' and 'type'
      fileCodeIsLoading: '',
      dealerSaleStaticFilesToDisplay: [],
    }
  }

  componentDidMount() {
    this.loadStaticFiles()
    this.setRowElementsToDisplay()
  }

  // Load Sale Static File List (contains 'code' and 'url')
  loadStaticFiles() {
    // Hits API
    loadStaticsFiles(this.props.saleId)
      .then(response => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE) {
          const {staticFiles: {saleStaticFiles}} = data;
          //response.data.saleStaticFiles' is an array of objects
          // with this structure 'code' and 'url'
          /*
          const saleStaticFiles =
            response.data.data
            && response.data.data.saleStaticFiles
            && [...response.data.data.saleStaticFiles]
           */

          this.setState(
            {
              formattedSaleStaticFiles: this.formatSaleStaticFiles(saleStaticFiles),
              fileCodeIsLoading: '',
            }
          )
        } else {
          console.error(responseMessage)
          this.setState({ fileCodeIsLoading: '' })
        }
      })
  }

  // Returns a formatted Sale Static Files Object
  // Param: 'saleStaticFiles' is an array contains objects just with 'code' and 'url'
  formatSaleStaticFiles(saleStaticFiles) {
    return saleStaticFiles && saleStaticFiles.reduce((result, file) => {
      const lastPointOnFileUrl = file[KEYS.URL].lastIndexOf('.')
      const fileType = file[KEYS.URL].substring(lastPointOnFileUrl + 1)

      return {
        ...result,
        [file.code]: {
          ...file,
          [KEYS.TYPE]: fileType,
        }
      }
    }, {})
  }

  // Returns a formatted dealerSaleStaticFiles array to display
  // Each element on this array is an array with elements for an specific row
  // It means first array is for first row, second array for second row and go on
  setRowElementsToDisplay() {
    const { dealerSaleStaticFiles } = this.props;
    const numberItemsPerRow = 4
    const numberRows = Math.ceil(dealerSaleStaticFiles.length / numberItemsPerRow)
    const sortedDealerSaleStaticFiles = sortBy(dealerSaleStaticFiles, el => el.order)
    let dealerSaleStaticFilesToDisplay = []

    for (let index = 0; index < numberRows; index ++) {
      let numElementsToExtract = ((index + 1) === numberRows)
        ? dealerSaleStaticFiles.length
        : ((index + 1) * numberItemsPerRow)
      let temporaryArray = sortedDealerSaleStaticFiles.slice(index * numberItemsPerRow, numElementsToExtract)
      dealerSaleStaticFilesToDisplay.push(temporaryArray)
    }

    this.setState({ dealerSaleStaticFilesToDisplay })
  }

  // Used when we upload or change a file and updates on DB
  onDrop = (fileCode, files) => {
    const {saleId} = this.props;
    const data = { files: files };

    this.setState({ fileCodeIsLoading: fileCode})

    // Hits API
    uploadFileSales(saleId, fileCode, data)
      .then(response => {
        const {responseCode} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.loadStaticFiles();
        }else{
          this.setState({ fileCodeIsLoading: '' })
        }
      })
  }

  // Loads a new tab where we can print
  goToPagePrint = (fileCode) => {
    const { formattedSaleStaticFiles } = this.state
    window.open(formattedSaleStaticFiles[fileCode][KEYS.URL], "_blank")
  }

  render() {
    const { formattedSaleStaticFiles, dealerSaleStaticFilesToDisplay, fileCodeIsLoading } = this.state

    if (isEmpty(formattedSaleStaticFiles) || isEmpty(dealerSaleStaticFilesToDisplay)) {
      return null
    }

    return (
      <div className='sale-reload-files-page-container'>
        <div className='sale-reload-files-page-body'>
            {
              dealerSaleStaticFilesToDisplay.map((rows, index) => (
                <div
                  key={index}
                  className='sale-reload-files-page-first-row'
                >
                  {
                    rows.map(rowElement => {
                      const url = (
                        formattedSaleStaticFiles[rowElement.code]
                        && formattedSaleStaticFiles[rowElement.code][KEYS.URL]
                      ) || null
                      const type = (
                        formattedSaleStaticFiles[rowElement.code]
                        && formattedSaleStaticFiles[rowElement.code][KEYS.TYPE]
                      ) || ''
                      return (
                        <SaleReloadFilesPageElement
                          key={rowElement.code}
                          fileCode={rowElement.code}
                          fileName={rowElement.name}
                          fileUrl={url}
                          fileType={type}
                          onDrop={this.onDrop}
                          goToPagePrint={this.goToPagePrint}
                          fileCodeIsLoading={fileCodeIsLoading}
                        />
                      )
                    })
                  }
                </div>
              ))
            }
        </div>
      </div>
    )
  }
}

SaleReloadFilesPage.defaultProps = {
  dealerSaleStaticFiles: [],
}

SaleReloadFilesPage.propTypes = {
  saleId: PropTypes.string.isRequired,
  dealerSaleStaticFiles: PropTypes.array,
}

export default SaleReloadFilesPage