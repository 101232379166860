import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {EmptyFunc} from '../../common/Constants'

export const DPToggle = (props) => {
  const {handleCheck,isChecked,id, isShowText, testId, className} = props;
  return(
    <div className={classnames(["sec-switch", className])}>
      <label id={id} className="switch" onChange={handleCheck} data-test={testId}>
        <input type="checkbox" checked={isChecked} readOnly={true} />
        <span className={isChecked ? "slider round" : "slider round off"} />
        {
          isChecked ?
            <span className="check-label-on">{isShowText && "ON"}</span> :
            <span className="check-label-off">{isShowText && "OFF"}</span>
        }
      </label>
    </div>
  )
}

DPToggle.defaultProps = {
  isShowText: true,
  testId: 'toggle',
  handleCheck: EmptyFunc,
  isChecked: true,
  id: 'dpToggle',
  className: ''
}

DPToggle.propTypes = {
  handleCheck: PropTypes.func,
  isChecked: PropTypes.bool,
  id: PropTypes.string,
  isShowText: PropTypes.bool,
  testId: PropTypes.string,
  className: PropTypes.string
}