import React from 'react'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/ext-language_tools'
import PropTypes from 'prop-types'

export const    DPEditor = (props) => {
  const { value, onChange, placeholder, mode, fontSize} = props
  return (
    <AceEditor
        placeholder={placeholder}
        mode={mode}
        theme="github"
        name="body"
        onChange={onChange}
        fontSize={fontSize}
        showPrintMargin={false}
        showGutter={false}
        highlightActiveLine={true}
        value={value}
        width='100%'
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
    />
    )
}

DPEditor.defaultProps = {
  onChange: null,
  value: "",
  placeholder: "",
  mode: "html",
  fontSize: 12
}

DPEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  mode: PropTypes.string,
  fontSize: PropTypes.number
}