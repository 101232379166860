import React, {Component} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import {browserHistory} from 'react-router'
import {setPlaceHolder} from '../util/Utils'
import {connect} from 'react-redux'
import {SIDEBAR_WIDTH_EXPANDED, SIDEBAR_WIDTH_UNEXPANDED} from '../../Constants'

class Marketing extends Component {

  constructor() {
    super();
    this.state={
      key:'testimonials',
      widthPage: window.innerWidth
    }
  }

  componentWillMount(){
    let {pathname} = this.props.location;
    let pathBase = `/main/marketing/`;
    browserHistory.push({pathname: `${pathBase}testimonials`});
    let pathLast = pathname.substring(pathBase.length, pathname.length);
    let path = pathLast.split('/');
    let tabName = path[0];
    if(path[0]=== "")
      tabName = "testimonials";

    this.setState({key:tabName})
  }

  componentDidMount() {
    let {showSideBar} = this.props;
    setPlaceHolder("menu-search","Search User, Testimonial or Date");
    if (showSideBar) {
      let container = window.document.getElementById("container");
      container.className += ' container-collapse';
    }
  }

  onChangeTab = (key) => {
    let showIcon = {};
    showIcon[key] = true;
    this.setState({tabName: key,tabKey:key,showIcon:showIcon,tabDisplayValue:{},showPopover:{user:false,form:false}});
    browserHistory.push({pathname: `/main/marketing/${key}`});
  }

  render() {
    const {height, showSideBar} = this.props;
    const {key, widthPage}    = this.state;
    const tabHeaderStatistics = (
      <div className="tab-setting-title">
        <label onClick={() => this.onChangeTab("statistics")}>Statistics</label>
      </div>
    );
    const tabHeaderTestimonials = (
      <div className="tab-setting-title">
        <label onClick={() => this.onChangeTab("testimonials")}>Testimonials</label>
      </div>
    );
    const tabHeaderAds = (
      <div className="tab-setting-title">
        <label onClick={() => this.onChangeTab("ads")}>Ads</label>
      </div>
    );
    const tabHeaderCampaigns = (
      <div className="tab-setting-title">
        <label onClick={() => this.onChangeTab("campaigns")}>Campaigns</label>
      </div>
    );

    let left;
    if(showSideBar){
      left = SIDEBAR_WIDTH_EXPANDED;
    } else{
      left = SIDEBAR_WIDTH_UNEXPANDED;
    }

    return (
      <div id="container" className="container-component" style={{height:height}}>
        <div className="container-header" style={{left: (widthPage>1024) ? left : null}}>
          <Tabs id="tab-settings-menu"  animation={false} activeKey={key}
                onSelect={key => this.setState({key})}>
            {/*<Tab eventKey="statistics" title={tabHeaderStatistics} tabClassName="tab-general general-setting-style">
            </Tab>*/}
            <Tab eventKey="testimonials" title={tabHeaderTestimonials} tabClassName="tab-testimonials">
            </Tab>
            {/*<Tab eventKey="ads" title={tabHeaderAds} tabClassName="tab-location">
            </Tab>
            <Tab eventKey="campaigns" title={tabHeaderCampaigns} tabClassName="tab-location">
            </Tab>*/}
          </Tabs>
        </div>
        <div className="body-setting">
          {React.cloneElement(this.props.children, {height: height - 120})}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar
  }
};

export default connect(mapStateToProps)(Marketing)