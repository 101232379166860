import React, {useEffect, useState} from 'react'
import Dropzone from 'react-dropzone'
import {ReactSVG} from 'react-svg'
import {cloneDeep, isEmpty} from 'lodash'
import {DPInput} from '../../../common/DPInput'
import {DPTextarea} from '../../../common/DPTextarea'
import {EXT_IMG, EXT_OTHER_IMG, EXT_PNG, pathServer} from '../../../../common/Constants'

const KEY_LOGO = "logo";
const KEY_ICON = "icon";
const KEY_SITE = "site";
const KEY_SLOGAN = "slogan";
const MAX_SIZE_SITE_TITLE = 60
const MAX_SIZE_SITE_DESCRIPTION = 300

const styleDrop = {
  height: 70,
  borderStyle: "none"
};

const SocialMediaSection = ({id, value,iconUrl,classIcon, onChangeSocialMedia, cleanValuesSocialMedia}) => {
  return (
    <div className="btn-message-dp">
      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_menu.svg`} className="icon-menu-content"
                beforeInjection={ svg => svg.classList.add('icon-menu')}/>
      <ReactSVG src={`${pathServer.PATH_IMG}${iconUrl}`} className="icon-menu-content"
                beforeInjection={ svg => svg.classList.add(classIcon)}/>
      <div className="col-sec">
        <input id={id}
               className="form-control input-header-footer"
               value={value}
               onChange={onChangeSocialMedia}/>
        <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_delete.svg`}
                  className="icon-action-content-delete"
                  beforeInjection={ svg => svg.classList.add('icon-action')} onClick={() => cleanValuesSocialMedia(id)}/>
      </div>
    </div>
  )
}

const SettingSiteIdentity = ({dealerSiteIconUrl, generalSetting, onChange, onChangeFile, dealerName = "", metadataType}) => {
  const [files, setFiles] = useState([])
  const [fields, setFields] = useState([])
  const {site = {}, socialMedia} = generalSetting

  useEffect(() => {
    let generalSettingNew = cloneDeep(generalSetting)
    if(site.title === ""){
      generalSettingNew[KEY_SITE].title = dealerName
      onChange(generalSettingNew)
    }
  },[])

  const  onDrop = (file, field) => {
    if(field === KEY_LOGO || field === KEY_ICON){
      let generalSettingNew = cloneDeep(generalSetting)
      files.push(file[0])
      fields.push(field)
      setFiles(files)
      setFields(fields)
      generalSettingNew.site[field] = URL.createObjectURL(file[0])
      onChangeFile(generalSettingNew, files, fields)
    }
  }

  const removeImage = (field) => {
    if (field === KEY_LOGO || field === KEY_ICON) {
      let generalSettingNew = cloneDeep(generalSetting);
      const index = fields.indexOf(field);
      files.splice(index, 1);
      fields.splice(index, 1);
      generalSettingNew.site[field] = "";
      onChange(generalSettingNew, files, fields);
    }
  }

  const onChangeInput = (type, event) => {
    const id = event.target.id
    let generalSettingNew = cloneDeep(generalSetting)
    if (type === KEY_SITE) {
      generalSettingNew[type][id] = event.target.value
    } else if (type === KEY_SLOGAN) {
      generalSettingNew.banner[type] = event.target.value
    }
    onChange(generalSettingNew)
  }

  const onChangeSocialMedia = (event) => {
    event.stopPropagation();
    const id = event.target.id;
    let generalSettingNew = cloneDeep(generalSetting);
    if(generalSettingNew.socialMedia){
      generalSettingNew.socialMedia[id] = event.target.value
    }else{
      generalSettingNew.socialMedia = {[id]: event.target.value}
    }
    onChange(generalSettingNew);
  }

  const cleanValuesSocialMedia = (key) => {
    let generalSettingNew = cloneDeep(generalSetting);
    if(generalSettingNew.socialMedia?.hasOwnProperty(key))
      generalSettingNew.socialMedia[key] = '';

    onChange(generalSettingNew);
  }

  const title = metadataType.title?.metadataValue ?? ''
  const description = metadataType.description?.metadataValue ?? ''

  return (
    <div className="setting-site-identity">
      <div className="row-logo">
        <label className="title">Logotype</label>
        <div className="img-logo">
          {
            site.logo !== "" ?
              <img src={site.logo}/> :
              <Dropzone id="dropzone" onDrop={(file) => onDrop(file, KEY_LOGO)}
                        accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                        style={styleDrop}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className="upload-image-logo dropzone-container">
                    <input {...getInputProps()} />
                    <div className="">
                      <div className="container-image-upload">
                        {/*<img src={pathServer.PATH_IMG + "cloud-upload-arrow.png"} className="customer-image-upload"/>*/}
                        <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_cloud_upload_arrow.svg`}
                                  className="customer-image-upload"
                                  beforeInjection={svg => svg.classList.add('cloud-upload')}/>
                      </div>
                      <label className="label-upload-logo">
                        <span className="light-blue">Upload image</span> or Drag on Drop here</label>
                    </div>
                  </div>
                )}
              </Dropzone>
          }
        </div>
        {
          site.logo !== "" ?
            <div className="logo-info">
              <div className="logo-info-icon">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                          className="logo-action-content"
                          onClick={() => removeImage(KEY_LOGO)}
                          beforeInjection={svg => svg.classList.add('logo-action')}/>
              </div>
            </div> : null
        }
      </div>
      <div className="row-icon">
        <label className="title" style={{width: '100%'}}>Site Icon</label>
        <div>
          <div className="col-img-browser">
            <img src={pathServer.PATH_IMG + "icon_browser.png"}/>
          </div>
          <div className="col-icon-browser">
            {
              isEmpty(dealerSiteIconUrl) && site.icon === ""
                ?
                <Dropzone id="dropzone" onDrop={(file) => onDrop(file, KEY_ICON)}
                          accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                          style={{display: "inline-block"}}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className="upload-image-logo dropzone-container">
                      <input {...getInputProps()} />
                      <div className="">
                        <div className="container-image-upload">
                          <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_cloud_upload_arrow.svg`}
                                    className="customer-image-upload"
                                    beforeInjection={svg => svg.classList.add('cloud-upload')}/>
                        </div>
                        <label className="label-upload-logo">
                          <span className="light-blue">Upload</span>
                        </label>
                      </div>
                    </div>
                  )}
                </Dropzone>
                : <div>
                  <img src={!isEmpty(site.icon) ? site.icon : dealerSiteIconUrl} style={{}}/>
                </div>
            }
          </div>
          <div className="browser-icon">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                      className="logo-action-content"
                      onClick={() => removeImage(KEY_ICON)}
                      beforeInjection={svg => svg.classList.add('logo-action')}/>
          </div>
        </div>
      </div>
      <div className="row-template row-template-sm">
        <label className='social-media-title'>Social Media</label>
        <SocialMediaSection id={'facebook'}
                            value={socialMedia?.facebook}
                            iconUrl={'icon/ic_facebook.svg'}
                            classIcon={'icon-menu-facebook'}
                            onChangeSocialMedia={onChangeSocialMedia}
                            cleanValuesSocialMedia={cleanValuesSocialMedia}
        />
        <SocialMediaSection id={'instagram'}
                            value={socialMedia?.instagram}
                            iconUrl={'icon/ic_instagram.svg'}
                            classIcon={'icon-menu-instagram'}
                            onChangeSocialMedia={onChangeSocialMedia}
                            cleanValuesSocialMedia={cleanValuesSocialMedia}
        />
        <SocialMediaSection id={'twitter'}
                            value={socialMedia?.twitter}
                            iconUrl={'icon/ic_twitter.svg'}
                            classIcon={'icon-menu-twitter'}
                            onChangeSocialMedia={onChangeSocialMedia}
                            cleanValuesSocialMedia={cleanValuesSocialMedia}
        />
      </div>
      <div className="row-title">
        <DPInput
          className='f'
          id='title'
          title='Site title'
          maxLength={MAX_SIZE_SITE_TITLE}
          disable={true}
          //onChange={(e) => onChangeInput(KEY_SITE, e)}
          //rightElement={<label className={`label-size right ${title.length === MAX_SIZE_SITE_TITLE ? 'error-color' : ''}`}>{`${title.length}/${MAX_SIZE_SITE_TITLE}`}</label>}
          value={title}
          inputTitle={'To edit this field please navigate to the Themes page. Settings > Themes > Site Identify'}
        />
      </div>
      <div className="row-title">
        <DPTextarea
          id='description'
          label='Site description'
          maxLength={MAX_SIZE_SITE_DESCRIPTION}
          readOnly={true}
          //onChange={(e) => onChangeInput(KEY_SITE, e)}
          //rightElement={<label className={`label-size right ${site.description.length === MAX_SIZE_SITE_DESCRIPTION ? 'error-color' : ''}`}>{`${site.description.length}/${MAX_SIZE_SITE_DESCRIPTION}`}</label>}
          rows={4}
          styleTextarea={{resize:"none"}}
          value={description}
          title={'To edit this field please navigate to the Themes page. Settings > Themes > Site Identify'}
        />
      </div>
      <div className="row-preview">
        <label className="title">Preview on Browsers</label>
        <div className="body-preview">
          <span className="browser-preview-title">{title}</span>
          <span className="browser-preview-url">Https://www.godealergo.com/</span>
          <p className="browser-preview-description">{description}</p>
        </div>
      </div>
    </div>
  )
}

export default SettingSiteIdentity