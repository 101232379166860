import React from 'react'
import PropTypes from 'prop-types'
import {ReactSVG} from 'react-svg'
import {socialNetworks} from '../admin/Constants'
import {pathServer} from './Constants'

require('./DPProfilePicture.scss')

export const DPProfilePicture = ({imageSrc, socialNetWork}) => {
  return(
    <div className="general-box-image">
      <div className="container-image">
        <img src={imageSrc} alt=""/>
        <div className="circle-icon" style={{ background: `transparent linear-gradient(0deg, ${socialNetworks[socialNetWork].backgroundColor1} 0%, ${socialNetworks[socialNetWork].backgroundColor2} 100%) 0% 0% no-repeat padding-box`}}>
          <ReactSVG
            src={`${pathServer.PATH_IMG}icon/${socialNetworks[socialNetWork].icon}`}
            className={'icon-of-image'}
          />
        </div>
      </div>
    </div>
  )
}

DPProfilePicture.defaultProps = {
  socialNetWork:  'facebook',
  imageSrc:       'https://www.lacasadeel.net/wp-content/uploads/2020/01/Hulk-explica-la-clave-del-e%CC%81xito-de-marvel.jpg',
  iconSrc:        'ic_facebook.svg',

}

DPProfilePicture.propTypes = {
  imageSrc:      PropTypes.string,
  iconSrc:       PropTypes.string,
  socialNetWork: PropTypes.string,
}