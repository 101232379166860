import React from 'react'

import './DPPage.scss'
import classnames from 'classnames'
import {ReactSVG} from "react-svg";
import {pathServer} from "../../common/Constants";

const DPPage = ({children, title, showContentHeader = true, leftHeader, rightHeader, className,
                    showBackBtn = false, actionBack = null}) => {
  return (
    <div className={classnames('dp-page', className)}>
      <div className="dp-page-header">
        <div className="dp-page-title">
            {
                showBackBtn &&
                <ReactSVG
                    src={pathServer.PATH_IMG + "icon/ic_arrow_back.svg"}
                    beforeInjection={svg => svg.classList.add('icon-back')}
                    onClick={actionBack}
                    className="arrow-back"
                />
            }
            {title}
        </div>
      </div>
      <div className="dp-page-body">
        <div className="dp-page-body-content">
          {
            showContentHeader &&
            <div className="dp-page-body-content-header">
              <div className="dp-page-body-content-header-left">
                {
                  leftHeader
                }
              </div>
              <div className="dp-page-body-content-header-right">
                {/*<div className="superusers-buttons">*/}
                {/*</div>*/}
                {/*<div className="superusers-pagination">*/}
                {/*</div>*/}

                {
                  rightHeader
                }
              </div>
            </div>
          }
          {children}
        </div>
      </div>
    </div>
  )
}

export default DPPage
