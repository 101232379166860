import React from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import propTypes from 'prop-types'
import {pathServer} from '../../../common/Constants'

const modalConfirmDeactivate = props => {
  return (
    <div className="container-modal-car-deactivate">
      <Modal id='modalCarDeactivate' show={props.show} className={props.styleContainer ? `modal-deactivate ${props.styleContainer}` : 'modal-deactivate'} backdropClassName="backdrop-car">
        <Modal.Body>
          <div className="panel-left">
            <ReactSVG src={ props.iconQuestion ? pathServer.PATH_IMG + props.iconQuestion : pathServer.PATH_IMG + "icon/ic_question_warning.svg"} className="icon-warning" beforeInjection={ svg => svg.classList.add('icon-warning-svg')}/>
          </div>
          <div className="panel-right">
            {
              !props.viewMobile && <img src={ pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content" onClick={props.onHide}/>
            }
            <div className={props.classPanelMessage ? props.classPanelMessage : 'panel-message'}>
              {
                props.isBodyCustom ?
                  <div>
                    {props.bodyMessage}
                  </div> :
                  <div>
                    <label>{props.message}</label>
                  </div>
              }
            </div>
            { props.showButton &&
                <div className="btn-action">
                  <button id="btnActionDelete" onClick={props.onSubmit} className={props.classButtonDelete}>{props.buttonLabelDelete}</button>
                </div>
            }
            { props.showAnotherMessage &&
                <div className="delete-superuser">
                  <span>{props.anotherMessage}</span>
                </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
};

modalConfirmDeactivate.propTypes = {
  show: propTypes.bool,
  showButton: propTypes.bool,
  isBodyCustom: propTypes.bool,
  showAnotherMessage: propTypes.bool,
  viewMobile: propTypes.bool,
  styleContainer: propTypes.string,
  iconQuestion: propTypes.string,
  classButtonDelete: propTypes.string,
  buttonLabelDelete: propTypes.string,
  message: propTypes.string,
  anotherMessage: propTypes.string,
  onSubmit: propTypes.func,
  onHide: propTypes.func,
  bodyMessage: propTypes.elementType,
  classPanelMessage: propTypes.string,
}

export default modalConfirmDeactivate