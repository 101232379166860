import React, {useState} from 'react'
import DPIcon, {Icons} from './DPIcon'
import PropTypes from 'prop-types'
require('./DPExclamationMessage.scss')

const DPExclamationMessage = ({message , id = "test", floatingMessage, bottomElement }) => {

  const [showFloatingMessage, setShowFloatingMessage] = useState(false)

  return(
    <div className="container-exclamation-message">
      <div className="container-icon-exclamation">
        <DPIcon
          id={id}
          icon={Icons.EXCLAMATION}
          onMouseLeave={ floatingMessage ? () => setShowFloatingMessage(false) : null }
          onMouseEnter={ floatingMessage ? () => setShowFloatingMessage(true) : null }
        />
        {
          showFloatingMessage &&
          <div className='floating-message'>{floatingMessage}</div>
        }
      </div>
      <div className="container-message">
        <p className="text-message">
          {message}
        </p>
        { bottomElement }
      </div>
    </div>
  )
}

DPExclamationMessage.defaultProps = {
  message : 'Subscribe to automatic updates',
  floatingMessage: null,
  bottomElement: null
}

DPExclamationMessage.propTypes = {
  message: PropTypes.string,
  floatingMessage: PropTypes.string,
  bottomElement: PropTypes.object
}

export default DPExclamationMessage