import moment from 'moment'
import {isEmpty} from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {pathServer} from '../../../../../common/Constants'


function getMessageNotField(field) {
  return <p style={{fontStyle: "italic"}}>{`No ${field} yet`}</p>
}

export const ModalAccountOwnerDetails = ({accountOwner}) => {
  const {image, firstName, lastName, email, phoneNumber, address, createDate, description} = accountOwner;
  const date = moment(new Date(createDate)).format('LL');
  const name = `${firstName} ${lastName}`;

  return (
    <div className="customer-modal-body">
      <div className="customer-image">
        <img src={image ? image : pathServer.PATH_IMG + "ic_account_circle.png"}/>
      </div>
      <div className="customer-name">
        <label>{name}</label>
      </div>
      <div className="customer-info">
        <label>{!isEmpty(email) ? email : getMessageNotField("email")}</label>
        <label>{!isEmpty(phoneNumber) ? phoneNumber : getMessageNotField("phoneNumber")}</label>
        <label>{!isEmpty(address) ? address : getMessageNotField("address")}</label>
        <label>{`Account owner since ${date}`}</label>
      </div>
      <div className="customer-info">
        <label>{!isEmpty(description) ? description : getMessageNotField("description")}</label>
      </div>
    </div>
  )
};

ModalAccountOwnerDetails.propTypes = {
  accountOwner: PropTypes.object.isRequired,
}