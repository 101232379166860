import React from 'react'
import {DPInput} from '../../../../../common/DPInput'
import {PAGE_HOME_ID, SECTION_CONTACT_US_ID} from '../../../../../../common/Constants'
import {cloneDeep} from 'lodash'

const MAX_SIZE_SITE_TITLE = 45

const ContactSection = (props) => {

  const {pages, onChangePages} = props
  const homePage = pages.find(x => x.id === PAGE_HOME_ID)
  const contactSection = homePage.sections.find(x => x.id === SECTION_CONTACT_US_ID)
  const {title} = contactSection

  const onChangeTitle = (evt) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const contactSection = homePage.sections.find(x => x.id === SECTION_CONTACT_US_ID)
    contactSection.title = evt.currentTarget.value
    onChangePages(pagesNew)
  }

  return(
    <div className="container-home-contact">
      <div className="row-title">
        <DPInput
          id='title'
          title='Form title'
          rightElement={<label className={`label-size right ${title.length === MAX_SIZE_SITE_TITLE ? 'error-color' : ''}`}>{`${title.length}/${MAX_SIZE_SITE_TITLE}`}</label>}
          value={title}
          onChange={onChangeTitle}
          maxLength={MAX_SIZE_SITE_TITLE}
        />
      </div>
    </div>
  )
}

export default ContactSection