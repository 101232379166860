import React, {Component, memo, useCallback} from 'react'
import {CAR_IMAGE_HEIGHT_PERCENT, EXT_IMG, EXT_OTHER_IMG, EXT_PNG, pathServer} from '../../../common/Constants'
import {findIndex, clone, cloneDeep, chunk} from 'lodash'
import ModalUploadImage from './ModalUploadImage.jsx'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import Dropzone from 'react-dropzone'
import {scale} from '../util/ImageUploaderUtils'
import toBlob from 'canvas-to-blob'
import {ReactSVG} from 'react-svg'
import ModalInfo from '../util/ModalInfo'
import {getImageUrlWithTime} from '../util/Utils'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

const ImageItem = memo(({carImage, index, heightImageGallery,handleSelectImage, coverDisplay, imageSelected, mainImage, style }) => {
  const {url, id} = carImage
  const onSelectImage = () => {
    handleSelectImage(carImage, index)
  }

  return(
    <div id={"imageTarget"+index} className="container-total" style={{...style, height:heightImageGallery,opacity:1}}
         onClick={onSelectImage}>
      <img id={"img"+index} src={getImageUrlWithTime(url)} className="image-object-fit"
           style={(id === imageSelected.id) ? {border: "2px solid #2194F1" , boxShadow:"0 0 20px #787878"} : null}/>
      <div id={"imageCover"+index} className="image-cover" style={(url === mainImage) ? {display:coverDisplay}:{display:"none"}}>
        Cover Image
      </div>
    </div>
  )
})


class InventoryImage extends Component {

  constructor(props) {
    super(props);
    // this.delete            = this.delete.bind(this);
    // this.onDrop            = this.onDrop.bind(this);
    // this.handleHideModal   = this.handleHideModal.bind(this);
    // this.handleSelectImage = this.handleSelectImage.bind(this);
    // this.handleImageResize = this.handleImageResize.bind(this);
    // this.handleMainImage   = this.handleMainImage.bind(this);
    // this.handleModalConfirmImage = this.handleModalConfirmImage.bind(this);
    // this.updateItemStyle   = this.updateItemStyle.bind(this);

    const { carImages, mainImage, maxImageName} = props

    this.state = {
      errors: null,
      vin: "",
      showUploadFile: false,
      showUploadHistoryReport: false,
      drop: null,
      showModalConfirmImage: false,
      showMessage: false,
      errorMessage: false,
      widthImage: null,
      carImagesDelete:[],
      heightImageGallery:null,
      isHoverTarget:null,
      coverDisplay:"flex",
      showModalInfo: true,

      carImages: carImages,
      mainImage: mainImage,
      maxImageName: maxImageName,
      imageSelected: carImages[0],
      indexImageSelected : 0,
      carImageIdsToDelete:[],
      isUpdateOrderImage:false,
      hasChangeImage:false,
      isMainImageToDelete:null,
      files: [],
    };
  }

  componentWillReceiveProps(nextProps){
    //this.setImageSelected(nextProps,true);
    //this.handleImageResize();
  }

  componentDidMount(){
    this.handleImageResize();
    //window.addEventListener('resize', this.handleImageResize);
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleImageResize);
  }

  /*setImageSelected(props,hasChange){
    let {carImages,mainImage} = props;
    let imageSelected;
    let indexMainImage = null;
    if(!hasChange){
      indexMainImage = findIndex(carImages,(image) => {return image.url === mainImage});
      if (indexMainImage !== -1 && carImages.length > 0){
        imageSelected = carImages[indexMainImage];
      }
    }
    this.setState({imageSelected:imageSelected,mainImage:mainImage,indexImageSelected:indexMainImage},() => {
      this.handleImageResize();
    })
  }*/

  handleMainImage = () => {
    let {mainImage,imageSelected} = this.state;
    let isMainImageToDelete = false;

    if(imageSelected?.url !== mainImage){
      mainImage = imageSelected.url;
    }else{
      mainImage = null;
      isMainImageToDelete = true;
    }

    this.setState({mainImage,isMainImageToDelete});
    this.props.handleUpdateData("hasChangeImage");
  }

 /* handleDropElement(item,indexTarget){
    let {carImages,files,imageSelected,indexImageSelected,mainImage} = this.state;
    let carImagesNew = cloneDeep(carImages);

    const indexOrigin = findIndex(carImages,(carImage) => {return carImage.id === item.id});
    carImagesNew.splice(indexOrigin,1);
    carImagesNew.splice(indexTarget,0,item);

    let indexFile = -1;
    carImagesNew.forEach((carImage,index) => {
      carImage.imageOrder = index+1;
      indexFile = findIndex(files,(file) => {return file.id === carImage.id});
      if(indexFile !== -1)
        files[indexFile].order = carImage.imageOrder;

      const indexMain = (carImage.url === mainImage) ? index : -1

      //this.setHoverToElement(index,indexTarget,indexMain);
    });

    if(indexImageSelected !== null)
      indexImageSelected = findIndex(carImagesNew,(carImage) => {return carImage.id === imageSelected.id});

    //this.props.updateCarImages(carImagesNew,files,indexImageSelected);
    // NEW CHANGES
    const indexMainImage = findIndex(carImages,(carImage) => {return carImage.url === mainImage});
    console.log('indexMainImage ==> ', indexMainImage)
    this.setState({carImages:carImagesNew,isUpdateOrderImage:true,indexMainImage: indexMainImage,files:files,
      indexImageSelected: indexImageSelected});
    this.props.handleUpdateData("hasChangeImage");
  }*/

  /*setImageCover = (indexMain, display) => {
    if( indexMain > -1 ){
      const imageCover = document.getElementById("imageCover"+ indexMain);
      if(imageCover){
        imageCover.style.display = display
      }
    }
  }*/

  /*setHoverToElement(indexImage,indexTarget,indexMain){
    const {heightImageGallery} = this.state;
    let element = document.getElementById("img"+indexImage);
    let content = document.getElementById("imageTarget"+indexImage);
    if(indexImage === indexTarget){
      element.style.display = "none";

      content.style.borderRadius = "5px";
      content.style.border = "1px #A4AFB7 dashed";
      content.style.height = heightImageGallery + "px";
      this.setImageCover(indexMain,"none" )
        //this.setState({coverDisplay:"none"})
    }else{
      element.style.display = "flex";
      content.style.borderRadius = "0";
      content.style.border = "0";
      /!*if(isMainImage)
        this.setState({coverDisplay:"flex"})*!/
      content.style.height = heightImageGallery + "px";
      this.setImageCover(indexMain,"flex" )
    }
  }*/

  handleHideModal = (images) => {
    this.setState({
      carImages: images,
      showUploadFile: false,
      showModalDeleteDocument: false,
      showModalConfirmImage: false,
      showModalPhotoEdit: false,
      showUploadHistoryReport: false
    })
  }

  handleModalConfirmImage = () => {
    this.setState({showModalConfirmImage:!this.state.showModalConfirmImage})
  }

  delete = () => {
    let {carImages,files,carImageIdsToDelete,maxImageName,imageSelected} = this.state;
    let carImagesNew       = cloneDeep(carImages);
    let filesNew           = cloneDeep(files);
    let carImageIdsToDeleteNew = cloneDeep(carImageIdsToDelete);

    let indexImageSelected = findIndex(carImagesNew, function(image) { return image.id === imageSelected.id});

    carImages.forEach((image, index) => {
      if (index === indexImageSelected) {
        carImagesNew.splice(index, 1);
        const indexFileToDelete = findIndex(filesNew, function(file) { return file.id === image.id});

        if(indexFileToDelete === -1){
          carImageIdsToDeleteNew.push(image.id);
        }else{
          filesNew.splice(indexFileToDelete, 1);
        }

      }
    });

    maxImageName = 0;
    carImagesNew.forEach((image, index) => {
      let url = image.url.split("/");
      let imageOrder = Number(url[url.length - 1]);

      if (index === 0)
        maxImageName = imageOrder;
      else {
        if (imageOrder > maxImageName)
          maxImageName = imageOrder
      }

      image.imageOrder = (index + 1);
    });

    let carImageFound;
    filesNew.forEach(file => {
      carImageFound = carImagesNew.find((carImage) => {return carImage.id === file.id});
      if(carImageFound){
        file.order = carImageFound.imageOrder;
      }
    });

    if(carImagesNew.length > 0){
      imageSelected = carImagesNew[0];
    } else {
      imageSelected = null;
    }

    this.setState({showModalConfirmImage:false});
    this.saveImage(carImagesNew,filesNew,carImageIdsToDeleteNew,maxImageName,imageSelected,true)
  }

  saveImage = (carImages, files, carImageIdsToDelete, maxImageName, imageSelected, isUpdateOrderImage) => {
    let {mainImage} = this.state;
    let isMainImageToDelete = null;
    let mainCarImageFound = null

    if(carImages.length !== 0){
      mainCarImageFound =  carImages.find( carImage => carImage.url ===  mainImage)

      if(!mainCarImageFound){
        mainImage = null;
        isMainImageToDelete = true;
      }
    }else{
      mainImage = null;
      isMainImageToDelete = true;
    }
    this.setState({
      carImages: carImages, files: files, carImageIdsToDelete, maxImageName, imageSelected,
      mainImage, isMainImageToDelete, isUpdateOrderImage
    }, this.props.verifyIsEnableForFBMP())
    this.props.handleUpdateData("hasChangeImage");
  }

  onDrop = (uploadedImages) => {
    let {carImages,files,carImageIdsToDelete,maxImageName,imageSelected,isUpdateOrderImage} = this.state;
    const {saveImage} = this
    let imageObject;
    let imageId = 0;
    let carImagesNext = clone(carImages);
    let filesNext = cloneDeep(files);
    let order = carImagesNext.length + 1;
    let maxImageNameNew = maxImageName;

    const lengthImagesToUpload = uploadedImages.length;
    uploadedImages.forEach((file, index) => {
      scale(file, function (image, validHeight){
        if (validHeight) {
          maxImageNameNew++;
          imageId = Math.ceil(Math.random() * 100000);
          imageObject = {};
          imageObject.id  = "" + imageId;

          let blob         = toBlob(image);
          let objectURL    = URL.createObjectURL(blob);
          let fileTemp     = new File([blob], maxImageNameNew.toString(), {type: blob.type});
          fileTemp.id   = "" + imageId;
          fileTemp.preview = objectURL;
          fileTemp.order   = order + index;

          imageObject.url        = objectURL;
          imageObject.imageOrder = order + index;
          imageObject.isNew      = true;

          if(carImagesNext.length === 0){
            imageSelected = imageObject;
          }

          carImagesNext.push(imageObject);
          filesNext.push(fileTemp);

          if(index === (lengthImagesToUpload - 1)){
            saveImage(carImagesNext,filesNext,carImageIdsToDelete,maxImageNameNew,imageSelected,isUpdateOrderImage)
          }
        }
      });
    });
  }

  handleSelectImage = (imageSelected,index) => {
    this.setState({indexImageSelected: index,imageSelected});
  }

  handleImageResize = () => {
    let imagesLeft = document.getElementById('imagesLeft');
    if(imagesLeft){
      let height = imagesLeft.offsetWidth*CAR_IMAGE_HEIGHT_PERCENT;
      let imageContainer = document.getElementById('imageContainer');
      imageContainer.style.height = height+"px";

      let imageTarget = document.getElementById('imageTarget0');
      if(imageTarget){
        const heightImageGallery = imageTarget.offsetWidth*CAR_IMAGE_HEIGHT_PERCENT;
        this.setState({heightImageGallery:heightImageGallery})
      }
    }
  }

  updateItemStyle = () => {
    const {carImages,mainImage} = this.state;
    for(let i = 0; i < carImages.length; i++){
      let element = document.getElementById("img"+i);
      let content = document.getElementById("imageTarget"+i);
      if(element){
        element.style.display = "flex";
        content.style.borderRadius = "0";
        content.style.border = "0";
      }
    }

    carImages.forEach(carImage => {
      if(carImage.url === mainImage){
        this.setState({coverDisplay:"flex"})
      }
    })
  }

  handleModalMoreInfo = (evt) => {
    evt.stopPropagation();
    this.setState(prevent => ({showModalInfo: !prevent.showModalInfo}))
  }

  onDragEnd = (result) => {
    const {carImages, files} = this.state
    const {source, destination, draggableId} = result
    const carImageDragged = carImages.find(element => element.id === draggableId)
    const newCarImages = cloneDeep(carImages)
    const newFiles = cloneDeep(files)
    newCarImages.splice(source.index, 1)
    newCarImages.splice(destination.index, 0, carImageDragged)
    newCarImages.forEach((carImage, index) => {
      carImage.imageOrder = index + 1
      const fileFound = newFiles.find(file => file.id === carImage.id)
      if (fileFound) {
        fileFound.order = carImage.imageOrder
      }

    })
    this.setState({carImages: newCarImages, isUpdateOrderImage: true, files: newFiles})
    this.props.handleUpdateData("hasChangeImage");
  }

  render() {
    const {showUploadFile,showModalConfirmImage,heightImageGallery,coverDisplay,showModalInfo,carImages, imageSelected,mainImage} = this.state;

    const style = {};

    const styleDrop = {
      borderStyle: "dashed",
      borderWidth: "thin",
      borderColor: "#A4AFB7",
      padding: "15px",
      textAlign: "center",
      backgroundColor:"#f5f6fa",
      height:heightImageGallery,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width: "100%"
    };

    /*const styleDrag = {
      borderRadius : 5,
      border : "1px #A4AFB7 dashed",
      height:heightImageGallery
    };

    const styleTarget = {
      width: "100%",
      height:"100%",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      borderRadius : 5
    };

    const styleBorder = "1px #2194F1 dashed";

    let listIdImages = [];
    carImages.forEach(carImage => {
      listIdImages.push(carImage.id);
    });*/

    const sizeImagesByRow = window.innerWidth > 500 ? 4 : window.innerWidth > 400 ? 3 : 2
    const classDragImage = window.innerWidth > 500 ? 'col25' : window.innerWidth > 400 ? 'col33' : 'col50'
    return (
      <div id="car-image">
        {
          (carImages.length > 0) ?
            <div className="car-info-inventory">
              <div id='imagesLeft' className="images-left-inventory">
                <div className="options-image">
                  {
                    !imageSelected ?
                      <div className="image-alert-deleted">
                        <img src={pathServer.PATH_IMG + "inverted.png"}/>
                        <label>Image deleted</label>
                      </div> : null

                  }
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="options-inventory"
                            beforeInjection={ svg => svg.classList.add('options-upload-inventory')} onClick={imageSelected ? this.handleModalConfirmImage:null}/>
                </div>
                <div id='imageContainer' className="image-container-inventory">
                  {
                    imageSelected ?
                      <img id='currentImage' src={getImageUrlWithTime(imageSelected.url)} className="image-object-fit"/>
                      :
                      <div className="image-box-deleted">
                        Select an Image to preview
                      </div>
                  }
                </div>
                <div className="check-box-container">
                  <label className="container-check">
                    <input type="checkbox"
                           checked={(imageSelected && imageSelected.url === mainImage)}
                           id="mainImage"
                           disabled={!imageSelected}
                           onChange={this.handleMainImage}/>
                    <span className="checkmark" style={{border: "1.4px #4695d4 solid"}}/>
                    {
                      (imageSelected && imageSelected.url === mainImage) ? <i className="fa fa-check i-check"/> : null
                    }
                  </label>
                  <label className="label-check-image-main">Select as Cover Image</label>
                </div>
              </div>
              <div className="images-right-inventory">
                <div className="uploaded-images" style={{flexDirection: 'column'}}>
                  {/**** Implement drag and drop with react-dnd-beautiful ****/}
                  <DragDropContext onDragEnd={this.onDragEnd} >
                    {
                      chunk(carImages, sizeImagesByRow).map((row, indexRow) => {
                        return(
                          <Droppable key={indexRow} droppableId={`container-${indexRow}`} direction={'horizontal'}>
                            { (droppableProvided) => (
                              <ul
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                                className='droppable-container'>
                                {
                                  row.map( (carImage, index) => (
                                      <Draggable  draggableId={carImage.id} key={carImage.id} index={indexRow*sizeImagesByRow + index}>
                                        {
                                          (draggableProvided) => (
                                            <div
                                              className={`container-image ${classDragImage}`}
                                              {...draggableProvided.draggableProps}
                                              {...draggableProvided.dragHandleProps}
                                              ref={draggableProvided.innerRef}
                                            >
                                              {/*<img src={carImage.url} alt="" style={{width: `100%`}}/>*/}
                                              <ImageItem
                                                carImage={carImage}
                                                handleSelectImage={this.handleSelectImage}
                                                coverDisplay={coverDisplay}
                                                heightImageGallery={heightImageGallery}
                                                imageSelected={imageSelected}
                                                mainImage={mainImage}
                                                index={index}
                                                style={{width: `100%`}}
                                              />
                                            </div>
                                          )
                                        }
                                      </Draggable>
                                    )
                                  )
                                }
                                {droppableProvided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        )
                      })
                    }
                  </DragDropContext>
                  <div className="image-box">
                    <Dropzone id="dropzone" onDrop={this.onDrop} multiple accept={`.${EXT_OTHER_IMG},.${EXT_IMG},.${EXT_PNG}`} style={styleDrop} >
                      {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="upload-image-logo" style={{height:heightImageGallery}}>
                          <input {...getInputProps()} />
                          <div className="container-images">
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"} className="cloud-upload-content" beforeInjection={ svg => svg.classList.add('cloud-upload')}/>
                            {/*** Visible only for web view ***/}
                            <div className="label-upload-file">
                              <label className="label-upload-logo">Drag picture here or</label>
                              <button className={'btn btn-primary'}>Choose file</button>
                              <label className="label-upload-logo">.Jpg .Png Max.1200px</label>
                            </div>
                            {/*** Visible only for tablet and mobile view ***/}
                            <label className="label-upload-mobile">Upload</label>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
            :
            <Dropzone id="dropzone" onDrop={this.onDrop} multiple={true} accept={`.${EXT_OTHER_IMG},.${EXT_IMG},.${EXT_PNG}`} style={style}>
              {({getRootProps, getInputProps}) => (
                <div {...getRootProps()} className="upload-image-car-info">
                  <input {...getInputProps()} />
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_cloud_upload.svg"} className="cloud-upload-content-inventory"/>
                  <div className="upload-text">
                    <label className=" label-upload-text">Any picture yet, <label className="label-upload-text-sub">upload <label className="label-upload-text"> or drag here</label></label></label>
                  </div>
                </div>
              )}
            </Dropzone>
        }
        {
          showUploadFile ?
            <ModalUploadImage show={showUploadFile} onHide={this.handleHideModal}
                              container={this} images={carImages}/> : null
        }
        {
          showModalConfirmImage ?
            <GenericModalConfirm show={showModalConfirmImage}
                                 save={this.delete}
                                 onHide={this.handleModalConfirmImage}
                                 message="Are you sure you want to delete this image?"
                                 showButtonCancel={false}
                                 buttonLabelSave="Yes, Delete"
                                 classButtonSave="btn-delete"
                                 classNameModalButtons="modal-buttons-delete"
            /> : null
        }

        {
          showModalInfo ?
            <ModalInfo show={showModalInfo}
                       onHide={this.handleModalMoreInfo}
                       classModal='modal-images-info'>
              <>
                <img className={'img-car-info'} src={`${pathServer.PATH_IMG}carInfoView.png`} alt={''}/>
                <span className={'car-info-label'}>Hi! Quick reminder... Photos must have a maximum height of 1200px to preserve their quality and resolution.
                  If they are larger than that, the system will automatically resize them but it does not guarantee the best resolution.
                  Please adjust your photos before uploading.
                </span>
              </>
            </ModalInfo>
            : null
        }
      </div>
    )
  }
}

export default InventoryImage;