import React from 'react'
import {
  MENU_ITEM_CUSTOMER_ID,
  MENU_ITEM_DASHBOARD_ID,
  MENU_ITEM_SETTING_ID
} from '../../../common/Constants'
import {connect} from 'react-redux'
import {setMenuId, setShowSideBar} from '../../actions'

require('./ReviewHeader.scss')
const linkOptions = {
  reviews: [
    // {id: 'dashboard', displayValue: 'Dashboard'},
    {id: 'review', displayValue: 'Reviews'},
    /*{id: 'referrals', displayValue: 'Referrals'},
    {id: 'rewards', displayValue: 'Rewards'},*/
  ],
  /*setting: [
    {id: 'location', displayValue: 'Locations'},
  ]*/
};

const getHeaderTitle = (menuId) => {
  let headerTitle = ''
  switch(menuId){
    case MENU_ITEM_DASHBOARD_ID:
      headerTitle = 'Reviews Dashboard'
      break;
    case MENU_ITEM_SETTING_ID:
      headerTitle = 'Settings'
      break;
    case MENU_ITEM_CUSTOMER_ID:
      headerTitle = 'Customers'
      break;
    default:
      headerTitle = 'Reviews'
  }
  return headerTitle
}

const ReviewHeader = ({showSideBar, width, height, path, ...props}) => {
  /*const {menuId} = props

  if(menuId){
    headerTitle = getHeaderTitle(menuId)
    links = linkOptions[menuId]
  }*/
  const headerTitle = 'Reviews'
  const links = linkOptions.reviews

  return (
    <div className="navigation-header">
      <div className="header-wrapper">
        <div className="title-dashboard">
          <h2>{headerTitle}</h2>
        </div>
        {/*{ links && links.length > 0 &&*/}
        {/*  <div className="tabs-container">*/}
        {/*    <ul className="links">*/}
        {/*      {*/}
        {/*        links.map((link, index) => {*/}
        {/*          const {id, displayValue} = link*/}
        {/*          return(*/}
        {/*            <li key={`link-${link.id}`} className={`link ${props.tab === id ? 'selected-tab' : 'name-tab'} `}*/}
        {/*                onClick={() => props.onChangeTab(id)}>*/}
        {/*              <span>{displayValue}</span>*/}
        {/*            </li>*/}
        {/*          )*/}
        {/*        })*/}
        {/*      }*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    //menuId:state.menuId,
    showSideBarOnBottom: state.showSideBarOnBottom,
  }
}

export default connect(mapStateToProps,{setShowSideBar,setMenuId})(ReviewHeader)