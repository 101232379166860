import React from 'react'
import {pathServer} from "../../../../common/Constants";

require('./integrationSection.scss')

const IntegrationSection = () => {
  return (
    <div className={'section-center-aligned smart-integrations default-section-padding'}>
      <p className={'small-blue-font'}>MORE INTEGRATIONS, LESS WORK FOR YOU</p>
      <p className={'title-font'}>Smart integrations</p>
      <p className={'regular-font'} dangerouslySetInnerHTML={{
        __html: "Integrating with social media platforms and other high traffic websites" +
          " will allow you to post all your inventory and give your inventory as much" +
          " visibility as you need. Our integrations will also allow you to use our" +
          " review management platform to help your online presence thrive."
      }}/>
      <span className={'integration-icons'}>
        <img className={'google-icon'}  alt={'google-icon'} src={pathServer.PATH_IMG + "google-logo.png"}/>
        <img className={'facebook-icon'}  alt={'facebook-icon'} src={pathServer.PATH_IMG + "facebook-marketplace-horizontal.png"}/>
        <img className={'instagram-icon'} alt={'instagram-icon'} src={pathServer.PATH_IMG + "instagram-logo.png"}/>
      </span>
    </div>
  )
}
export default IntegrationSection
