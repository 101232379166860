import React from 'react'
import DPCheckbox from '../admin/common/DPCheckbox'
import classnames from 'classnames'

require('./DPCheckboxIcon.scss')
const DPCheckboxIcon = ({className, classNameCheckbox, classNameIcon, labelStyle, label, iconColor, id, onCheck, checked}) => {
  return(
    <div className={classnames(["container-checkbox",className])}>
      <DPCheckbox
        checked={checked}
        required={false}
        id={id}
        name={'checkboxIcon'}
        label={label}
        iconStyle={{marginRight: 0}}
        onCheck={onCheck}
        labelStyle={{color: '#787878', paddingLeft: '3px', fontSize: '13px', ...labelStyle}}
        className={classnames(['checkbox',classNameCheckbox])}
      />
      {
        iconColor &&
        <div className={classnames(["circle", classNameIcon])} style={{background: iconColor}}/>
      }
    </div>
  )
}

DPCheckboxIcon.defaultProps = {
  label : null,
  iconColor: null,
  id: 'checkboxIcon'
}
export default DPCheckboxIcon