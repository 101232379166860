import React, {useState, useEffect} from "react";
import map from "lodash/map";
import {Carousel} from "react-bootstrap";
import {pathServer, WIDTH_VIEW_TABLET} from "../../../../../common/Constants";
import DPIcon, {Icons} from "../../../../common/DPIcon";
import SplitSection from "../SplitSection";
import {browserHistory} from "react-router";

require('./featureSection.scss')

const features = [
  {
    id: 'inventoryManagement',
    backgroundImagePath: pathServer.PATH_IMG + '/newHomePage/inventory-management-mask.svg',
    backgroundColor: '#3B86FF',
    buttonPath: pathServer.PATH_IMG + "/newHomePage/blue-inventory.svg",
    firstName: 'Inventory',
    secondName: 'Management'
  },
  {
    id: 'websiteBuilder',
    backgroundImagePath: pathServer.PATH_IMG + '/newHomePage/website-builder-mask.svg',
    backgroundColor: '#F3A200',
    buttonPath: pathServer.PATH_IMG + "/newHomePage/orange-website.svg",
    firstName: 'Website',
    secondName: 'Builder'
  },
  {
    id: 'vehicleListings',
    backgroundImagePath: pathServer.PATH_IMG + '/newHomePage/vehicle-listings-mask.svg',
    backgroundColor: '#504AD9',
    buttonPath: pathServer.PATH_IMG + "/newHomePage/purple-listing.svg",
    firstName: 'Vehicle',
    secondName: 'Listings'
  },
  {
    id: 'salesProcess',
    backgroundImagePath: pathServer.PATH_IMG + '/newHomePage/sale-process-mask.svg',
    backgroundColor: '#4AD991',
    buttonPath: pathServer.PATH_IMG + "/newHomePage/green-sales.svg",
    firstName: 'Sales',
    secondName: 'Process'
  },
  {
    id: 'reviews',
    backgroundImagePath: pathServer.PATH_IMG + '/newHomePage/reviews-mask.svg',
    backgroundColor: '#005294',
    buttonPath: pathServer.PATH_IMG + "/newHomePage/darkblue-reviews.svg",
    firstName: 'Reviews',
    secondName: ''
}]

const featureItemsDef = [
  {
    id: 'inventoryManagement',
    title: 'INVENTORY MANAGEMENT',
    titleColor: '#3B86FF',
    subTitle: 'All your inventory</br>in one place',
    sections: [
      {
        title: 'Status updates made easy',
        description: 'Inventory Management helps you track, update, and communicate with '
          + 'your customers across your website, integrations and social media '
          + 'platforms all in one place.'
      },
      {
        title: 'Complete control',
        description: 'Our inventory management helps you keep track of titles, emission '
          + 'paperwork and other documents all in one seamless solution.'
      }],
    imageSrc: pathServer.PATH_IMG + '/newHomePage/inventory-management-home.png',
    link: '/products/inventoryManagement'
  },
  {
    id: 'websiteBuilder',
    title: 'WEBSITE BUILDER',
    titleColor: '#F3A200',
    subTitle: 'First Impressions</br>matter',
    sections: [
      {
        title: 'Design at your fingertips',
        description: 'No need to hire a disigner, with our templates and designs your websites '
          + 'will look professionally made.'
      },
      {
        title: 'Easy website setup',
        description: 'With our Website Builder, you will be able to select the best template to '
          + 'fit your branding.'
      }],
    imageSrc: pathServer.PATH_IMG + '/newHomePage/website-builder-home.png',
    link: '/products/websiteBuilder'
  },
  {
    id: 'vehicleListings',
    title: 'VEHICLE LISTED',
    titleColor: '#504AD9',
    subTitle: 'The world is your stage',
    sections: [{
      title: 'Inventory listing',
      description: 'Publish your inventory on your newly designed website with a few clicks.'
    },
    {
      title: 'Your inventory, their site',
      description: 'We integrate with major listing websites, so you can rest assured your '
        + 'inventory is being shared with the right audience'
    },
    {
      title: 'Social media advantage',
      description: 'With our proprietary software, your inventory is posted on your social '
        + 'media pages without extra effort from you, Status updates, descriptions '
        + 'and more and automatically added.'
    }],
    imageSrc: pathServer.PATH_IMG + '/newHomePage/vehicle-listed-home.png',
    link: '/products/inventoryManagement'
  },
  {
    id: 'salesProcess',
    title: 'SALES PROCESS',
    titleColor: '#4AD991',
    subTitle: 'Make sales about people,</br>not about paperwork',
    sections: [{
      title: 'Sales process management',
      description: 'Bring your A-game and manage a seamless end-to-end process '
        + 'that will help you look like a rockstar to your customers.'
    },
    {
      title: 'Documents made easy',
      description: 'No need to print any documents anymore, you have the ability to '
        + 'capture all customer data online and print only if you need to.'
    }],
    imageSrc: pathServer.PATH_IMG + 'newHomePage/sale-process-home.png',
    link: '/products/salesProcess'
  },
  {
    id: 'reviews',
    title: 'REVIEWS',
    titleColor: '#005294',
    subTitle: 'Help your business</br>shine',
    sections: [{
      title: 'Be great, magnify your greatness',
      description: 'Inventory Management helps you track, update, and communicate with '
        + 'With our Reviews Portal you will have the ability to manange your '
        + 'online presence and how future customers perceive your brand.'
    },
    {
      title: 'Automated customer reviews',
      description: 'Send review invitations after a sale and allow your customer to '
        + 'know where to go to share their awesome experience.'
    }],
    imageSrc: pathServer.PATH_IMG + '/newHomePage/reviews-home.png',
    link: '/products/reviews'
}]


const Features = ({items, activeIndex = 0, isCarousel, onSelectFeatureIdx}) => {
  return (
    <>
      {
        isCarousel
          ? <div className={'features'}>
            <Carousel controls={false} indicators={false} activeIndex={activeIndex}>
              {
                map(items, (item, idx) =>
                  <Carousel.Item key={idx}>
                    <FeatureItem
                      {...item}
                      onSelectFeatureIdx={onSelectFeatureIdx}
                      activeIndex={activeIndex}/>
                  </Carousel.Item>
                )
              }
            </Carousel>
          </div>
          : (
            map(items, (item, idx) =>
              <FeatureItem
                key={idx}
                className={
                  (idx % 2) === 0
                    ? 'even' + (idx === 0 ? ' after-intro-section-padding' : '')
                    : 'odd background-light-blue'}
                {...item}/>
            )
          )
      }
    </>
  )
}

const sectionIcons = [
  pathServer.PATH_IMG + '/newHomePage/square-circle.png',
  pathServer.PATH_IMG + '/newHomePage/square-bordered-square.png',
  pathServer.PATH_IMG + '/newHomePage/circle-square.png'
]

const FeatureItem = ({title, titleColor, subTitle, sections, imageSrc, onSelectFeatureIdx, link, activeIndex, className}) => {
  return (
    <SplitSection
      className={className}
      leftSection={
        <>
          <p className={'feature-title'} style={{color: titleColor}}>{title}</p>
          <p className = {'feature-subTitle'} dangerouslySetInnerHTML={{__html: subTitle}}/>
          {
            map(sections, (section, idx) =>
              <span className={'feature-key-point'} key={idx}>
                <div className={'key-point-icon'} >
                  <img alt='icon' src={sectionIcons[idx]}/>
                </div>
                <div>
                  <p className={'key-point-title'}>{section.title}</p>
                  <p dangerouslySetInnerHTML={{__html: section.description}}/>
                </div>
              </span>
            )
          }
          <div className={'feature-section-actions'} style={{display: 'flex'}}>
            <div className={'next-previous-arrows'}>
              <DPIcon icon={Icons.ARROW_BACK} onClick={() => onSelectFeatureIdx(activeIndex - 1)}/>
              <DPIcon icon={Icons.ARROW_NEXT} onClick={() => onSelectFeatureIdx(activeIndex + 1)}/>
            </div>
            <a onClick={() => browserHistory.push({pathname: link})}>Learn more</a>
          </div>
        </>
      }
      rightSection={
        <img alt={'img'} src={imageSrc}/>
      }/>
  )
}

function ProgressBar({selectedIdx}) {
  return (
    <div style={{display: 'flex', justifyContent: 'center', width: '95%', marginTop: '25px'}}>
      {
        map(features, ({id}, idx) => {
          const isSelected = selectedIdx === idx;
          const isAfterSelected = idx > selectedIdx
          let borderRadius;
          if (isSelected && idx === 0)
            borderRadius = '5px'
          else if (idx === 0)
            borderRadius = '5px 0 0 5px'
          else if (isSelected)
            borderRadius = '0 5px 5px 0'
          else if (idx === features.length - 1)
            borderRadius = '0 5px 5px 0'

          const backgroundColor = isAfterSelected ? '#f5f6fa' : '#3B86FF'

          return (
            <div key={idx}
                 style={{background: backgroundColor, height: '6px', width: '20%', borderRadius: borderRadius}}/>
          )
        })
      }
    </div>
  )
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  };
}

export default function FeatureSection() {
  const [selectedFeatureIdx, setSelectFeatureIdx] = useState(0)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const onSelectFeatureIdx = (idx) => {
    if (idx === features.length)
      setSelectFeatureIdx(0)
    else if (idx < 0)
      setSelectFeatureIdx(features.length - 1)
    else
      setSelectFeatureIdx(idx)
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const showCarousel = windowDimensions.width > WIDTH_VIEW_TABLET

  return (
    showCarousel
      ? <div className='features-section'>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '95%'
        }}>
          {
            map(features, ({id, backgroundImagePath, backgroundColor, buttonPath, firstName, secondName}, idx) => {
              const isSelected = selectedFeatureIdx === idx;
              return (
                <div key={idx} style={{
                  minHeight: '280px',
                  width: '100%',
                  backgroundColor: isSelected ? backgroundColor : '#fff',
                  color: isSelected ? '#fff' : '#000',
                  marginRight: '20px'
                }}>
                  <div style={{background: " url('" + backgroundImagePath + "') no-repeat 100%"}}>
                    <img style={{maxWidth: '165px', cursor: 'pointer', padding: '20px 0 0 20px'}} src={buttonPath} alt="logo"
                         onClick={() => onSelectFeatureIdx(idx)}/>
                  </div>
                  <div style={{padding: '0 0 20px 20px'}}>
                    <p
                      style={{fontSize: '2.3vw', fontWeight: '550', margin: 0}}
                      dangerouslySetInnerHTML={{__html: `${firstName} </br> ${secondName}`}}/>
                  </div>
                </div>
              )
            })
          }
        </div>

        <ProgressBar selectedIdx={selectedFeatureIdx}/>
        <Features
          classNames={'gdg-features'}
          isCarousel={showCarousel}
          items={featureItemsDef}
          activeIndex={selectedFeatureIdx}
          onSelectFeatureIdx={onSelectFeatureIdx}
        />
      </div>
      : <Features
        classNames={'gdg-features'}
        isCarousel={showCarousel}
        items={featureItemsDef}
        activeIndex={selectedFeatureIdx}
        onSelectFeatureIdx={onSelectFeatureIdx}
      />
  )
}
