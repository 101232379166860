import DPExclamationMessage from '../../common/DPExclamationMessage'
import DPButtonLoading from '../../common/DPButtonLoading'
import React, {useEffect, useState} from 'react'
import {subscribePageToWebhook, verifyWebhookSubscription} from '../../Api'
import isEmpty from 'lodash/isEmpty'
import {DPSpinner} from '../../common/DPSpinner'
import { SUCCESSFUL_CODE } from '../../../common/Constants'
require('./SubscriptionWebhook.scss')

const floatingMessage = 'By subscribing to automatic updates, Facebook will let us know when you have a new recommendation, reaction or comment.'

const SubscriptionWebhook = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [activatedSubscription, setActivatedSubscription] = useState(false)
  const [isLoadingBtnSave, setIsLoadingBtnSave] = useState(false)

  useEffect(() => {
    const response = verifySubscription()
  }, []);

  const verifySubscription = async () =>  {
    setIsLoading(true)
    const response = await(verifyWebhookSubscription())
    if (response.responseCode === SUCCESSFUL_CODE){
      const {data} = response
      if(!isEmpty(data)){
        const info = data[0]
        const {subscribed_fields: subscribedFields} = info
        if(subscribedFields.includes("ratings")){
          setActivatedSubscription(true)
        }
      }
      setIsLoading(false)
    }else{
      console.log(response.responseMessage)
      setIsLoading(false)
    }
  }

  const handleSubscribe = async () => {
    setIsLoadingBtnSave(true)
    const responseSubscription = await(subscribePageToWebhook())
    if (responseSubscription.responseCode === SUCCESSFUL_CODE){
      const {data} = responseSubscription
      const {success} = data
      if(success){
        setActivatedSubscription(true)
      }
      setIsLoadingBtnSave(false)
    }else{
      console.log(responseSubscription.responseMessage)
      setIsLoadingBtnSave(false)
    }
  }

  return(
    <div className="container-subscription">
      { isLoading && <DPSpinner/> }
      <DPExclamationMessage
        bottomElement={
        <span className="subscription-status" style={ {color: activatedSubscription ? '#4AD991' : '#FF6565'}}>
          { activatedSubscription ? 'Active' : 'Inactive' }
        </span>
      }
        floatingMessage={floatingMessage}
      />
      <DPButtonLoading buttonClass="active-subscription-button" onClick={handleSubscribe}
                       isLoading={isLoadingBtnSave} isSaved={activatedSubscription} isDisable={false} btnSaveText="Activate"
                       btnLoadingText="Activating" btnSavedText="Activated" hoverText="Activate now"/>
    </div>
  )
}

export default SubscriptionWebhook