import React, {Component} from 'react'
import {find, cloneDeep} from 'lodash'
import {Popover} from '../../util/ComponentUtils'
import {getPosition} from '../../../../common/Util'

const formViews = [
  {id:"wizard", value:"Wizard"},
  {id:"vertical", value:"Vertical"}
];

export default class SettingFinance extends Component{

  constructor(){
    super();
    this.state = {
      selectedSection:'',
      open:false,
      selectedForm:0
    }
  }

  handlePopover = (event) => {
    event.preventDefault();
    let pointTo = getPosition(event.currentTarget);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 25,
      offsetWidth: event.currentTarget.offsetWidth
    };
    this.setState({open: true, positionPopoverX: pointTo.x,positionPopoverY: pointTo.y, anchorEl: anchorEl});
  }


  handleSelect = (index,id) => {
    const {pages,selectedPage,onChange} = this.props;
    //const id = event.target.id;
    let pagesNew = cloneDeep(pages);
    pagesNew[selectedPage].formView = id;
    onChange(pagesNew);
    this.setState({open:false});
  }

  handleRequestClose = () => {
    this.setState({open: false});
  }

  renderOptions(option){
    let values = [];

    formViews.forEach((form,index) => {
      if(form.id === option.id){
        values.push(
          <li key={index} className="item-selected" onClick={() => this.handleSelect(index,form.id)} data-test={`menu-item-${form.id}`}>
            {form.value}
          </li>
        )
      } else {
        values.push(
          <li key={index} className="change-submenu-item" onClick={() => this.handleSelect(index,form.id)} data-test={`menu-item-${form.id}`}>
            {form.value}
          </li>
        )
      }
    });

    return(
      <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {values}
          </u>
        </div>
      </div>
    )
  }

  render(){
    const {pages,selectedPage} = this.props;
    const page = pages[selectedPage];
    const {open,positionPopoverY,positionPopoverX, anchorEl} = this.state;
    const form = find(formViews,(form) => { return form.id === page.formView});
    const childOptions = this.renderOptions(form);

    return(
      <div className="body-main">
        <div className="row-template-finance">
          <div className="label-form-view">
            <label>FORM VIEW</label>
          </div>
          <div className="btn-form-view">
            <button className="btn-basic btn-message-dp" onClick={this.handlePopover}>
              <span className="label-page">{form.value}</span>
              {
                open ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />
              }
            </button>
          </div>
          {
            open ?
              <Popover anchorEl={anchorEl}
                       child={childOptions}
                       classPopover="popover-box-message"
                       handleRequestClose={this.handleRequestClose}/>
              :
              null
          }
        </div>
      </div>
    )
  }
}