import React from 'react'
import {ReactSVG} from 'react-svg'
import {EmptyFunc, pathServer} from '../../../common/Constants'
import PropTypes from 'prop-types'
import {DPToggle} from '../DPToggle'

require('./DPNavigationBarItem.scss')
require('./DPActivatedItem.scss')

const DPActivatedItem = ({id, showIcon, title, active, onClickItem, handleCheck, href, showToggle, pageId}) => {
  const ref =  href ? href : ''
  return(
    <a href={`#${ref}`} className="container-navigation-item container-select-toggle" >
      <div className="navigation-box" style={{width: showToggle ? 'calc(100% - 63px)' : '100%'}} onClick={() => onClickItem(id)}>
        <div className="title-container">
          { showIcon &&
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_menu.svg"} className="icon-menu-content"
                    beforeInjection={ svg => svg.classList.add('icon-menu-svg')}/>
          }
          <span className="title">{title}</span>
          <ReactSVG src={pathServer.PATH_IMG + `icon/ic_expand_less.svg`} className="icon-arrow-content" style={{transform: 'rotate(90deg)'}}
                    beforeInjection={ svg => svg.classList.add('icon-arrow')} onClick={() => {}}/>
        </div>
      </div>
      {
        showToggle &&
        <DPToggle id={`toggle-${id}`} isChecked={active} handleCheck={() => {handleCheck(pageId)}}/>
      }
    </a>
  )
};

DPActivatedItem.defaultProps = {
  showIcon: false,
  title: '',
  onClick: EmptyFunc,
  showToggle: true,
}

DPActivatedItem.propTypes = {
  showIcon: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  showToggle: PropTypes.bool,
}

export default DPActivatedItem