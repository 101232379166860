import React, {useRef, useState} from 'react'
import NavigationBar from "../components/navigationBar/NavigationBar";
import IntroBannerSection from "../components/introBannerSection/IntroBannerSection";
import FooterSection from "../components/footer/FooterSection";
import {pathServer} from "../../../../common/Constants";
import GDGButton, {BUTTON_TYPE} from '../components/GDGButton'
import SplitSection from '../components/SplitSection'

require('../homepage.scss')
require('./websitebuilder.scss')

export default function WebsiteBuilder() {
  window.scrollTo(0, 0)
  const navigationBar = useRef();

  const demoRequest = () => {
    if(navigationBar && navigationBar.current)
      navigationBar.current.handleRequestDemo()
  }

  return (
    <div id='gdg-home-page' className='website-builder'>
      <NavigationBar ref={navigationBar}/>
      <IntroBannerSection
        blueText={'WEBSITE BUILDER'}
        title={"First impressions matter"}
        description={"Our Team has worked hard to make your job easy and convenient. Without " +
          "Website Builder you will have a set of professional templates to choose from as " +
          "your customers get a first impression of your business. Customize your website " +
          "according to your color and branding, but without the hassle of developing a " +
          "website from the ground up."}
        imgUrl={pathServer.PATH_IMG + '/newHomePage/website-builder-intro.png'}
      />

      <SplitSection
        className={'website-builder-application after-intro-section-padding'}
        leftSection={
          <div>
            <p className={'title-font'} dangerouslySetInnerHTML={{
              __html: 'Eye-catching templates'
            }}/>
            <p className={'regular-font'} dangerouslySetInnerHTML={{
              __html: 'We understand the importance of first impressions and with our templates we will make sure' +
                ' you stand out from your competition. Your website will seamlessly stand out and be easy to manage' +
                ' so your business can grow and always be up to date with your latest inventory.'
            }}/>
            <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
          </div>
        }
        rightSection={
          <div>
            <img alt={'img'} src={pathServer.PATH_IMG + '/newHomePage/website-builder-templates.png'}/>
          </div>
        }

      />

      <FooterSection/>
    </div>
  )
}
