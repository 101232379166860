import React, {useRef} from 'react'
import NavigationBar from "./components/navigationBar/NavigationBar";
import IntroBannerSection from "./components/introBannerSection/IntroBannerSection";
import FeatureSection from "./components/featureSection";
import IntegrationSection from "./components/IntegrationSection";
import FooterSection from "./components/footer/FooterSection";
import ReviewAndReferralSection from "./components/ReviewAndReferralSection";
import {pathServer} from "../../../common/Constants";

require('./homepage.scss')

const HomePage = () => {
  const navigationBar = useRef();

  const demoRequest = () => {
    if(navigationBar && navigationBar.current)
      navigationBar.current.handleRequestDemo()
  }

  return (
    <div id='gdg-home-page' className={"marketing-page-home"}>

      <NavigationBar ref={navigationBar}/>

      <IntroBannerSection
        blueText={'READY FOR THE NEXT STEP?'}
        title ={'All in one solution'}
        description={"GoDealerGo is a one stop shop for auto" +
          " dealers who are ready to take the leap into a platform that can help them manage their business and engage" +
          " with their customers seamlessly."}
        showVideo={true}
        imgUrl={pathServer.PATH_IMG + "newHomePage/inventory-management-home-intro.png"}
      />

      <FeatureSection/>

      <IntegrationSection/>

      <ReviewAndReferralSection demoRequest={demoRequest}/>

      <FooterSection/>

    </div>
  )
}
export default HomePage


