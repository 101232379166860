import React, {Component} from 'react'
import {
  APPLICANTS_ACTIONS
} from '../../Constants'
import {ReactSVG} from 'react-svg'
import ViewApplicant from './ViewApplicant.jsx'
import {getServerUrl, promoteLead} from '../../Api'
import {browserHistory} from 'react-router'
import {setMenuId} from '../../actions'
import {connect} from 'react-redux'
import {Modal} from 'react-bootstrap'
import {Popover} from '../util/ComponentUtils'
import {DPPopover} from '../../../common/DPPopover'
import isEmpty from 'lodash/isEmpty'
import {getAnchorEl} from '../util/Utils'
import {pathServer, ATTRIBUTE_APPLICANT_TYPE, SUCCESSFUL_CODE} from '../../../common/Constants'

const ModalPromote = ({show,onHide,save,message,buttonLabelSave,classButtonSave,customers,leadId,existProspectByEmail}) => {
  return (
    <div className="container-lead-promote">
      <Modal show={show} className="modal-promote" backdropClassName="backdrop-promote">
        <Modal.Body>
          <div className="panel-top">
            <div className="label-sure">
              <label>{message}</label>
            </div>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close_circle.svg"} className="icon-close-content"
                      beforeInjection={ svg => svg.classList.add("icon-close")} onClick={onHide}/>
          </div>
          <div className="panel-bottom">
            <div className="container-list-customer">
              {
                customers.map((customer, index) => {
                  let name = customer.firstName + " " + customer.lastName;
                  let city = customer.city + ", " + customer.state;
                  return(
                    <div key={index} className="list-customer" onClick={() => save(leadId, customer.id, false)}>
                      <div className="container-info-customer">
                        <div className="current-image-container">
                          <img key={index} src={customer.imageUrl ? customer.imageUrl : pathServer.PATH_IMG+"ic_account_circle.png"} className="current-image"/>
                        </div>
                        <div className="customer-data">
                          <div className="customer-name">
                            <label>{name}</label>
                          </div>
                          <div className="customer-other">
                            <label>{city}</label>
                          </div>
                          <div className="customer-other">
                            <label>{customer.email}</label>
                          </div>
                          <div className="customer-other">
                            <label>{customer.phone}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {
              !existProspectByEmail ?
                <div className="container-button">
                  <button onClick={() => save(leadId, null, true)} className={classButtonSave}>{buttonLabelSave}</button>
                </div> : null
            }
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
};

const ModalMessage = ({show,onHide,save,message,buttonLabelSave,classButtonSave}) => {
  return (
    <div className="container-lead-promote">
      <Modal show={show} className="modal-message" backdropClassName="backdrop-promote">
        <Modal.Body>
          <div className="panel-top">
            <div className="label-sure">
              <label>{message}</label>
            </div>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close_circle.svg"} className="icon-close-content"
                      beforeInjection={ svg => svg.classList.add("icon-close")} onClick={onHide}/>
          </div>
          <div className="panel-bottom">
            <div className="container-list-customer">
              <img src={pathServer.PATH_IMG+"successfully-image.png"} className="image-successfully" style={{width:"30%", height:"30%"}}/>
            </div>
            <div className="container-button">
              <button onClick={save} className={classButtonSave}>{buttonLabelSave}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
};

const Option = ({className,filterSelected,value,onSelectDropDown,displayValue,image}) => {
  return(
    <li className={className}
        onClick={() => onSelectDropDown(filterSelected,value)}>
      {
        filterSelected === ID.SALESMAN ?
          <img className={ isEmpty(image) ? "salesman-not-image" : "salesman-image"} src={isEmpty(image) ? `${pathServer.PATH_IMG}avatar.png` : image}/> : null
      }
      <span className={ filterSelected === ID.SALESMAN ? "user-name" : "text"}>{displayValue}</span>
    </li>
  )
};

const PopoverChild = ({filterSelected,options,value,onSelectDropDown,field}) => {
  let values = [];
  let className = '';
  let displayValue = '';

  options.forEach((op,index) => {
    const image = op.image ? op.image : '';
    if((op.id && op.id === value) || (op.value && op.value === value)){
      className = 'item-selected';
    }else{
      className = 'change-submenu-item';
    }
    if(field === 'id')
      displayValue = `${op.firstName} ${op.lastName}`;
    else
      displayValue = op.value;

    values.push(
      <Option key={index} className={className} filterSelected={filterSelected} value={op.id}
              displayValue={displayValue} onSelectDropDown={onSelectDropDown} image={image}/>
    )

  });

  return(
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {values}
        </u>
      </div>
    </div>
  )

};

const LeadOption = ({option, onSelectElement, index}) => {
  const onMouseOverElement = () => {
    const containerId = `lead-row-${option.leadId}`
    const containerElement = document.getElementById(containerId)
    const containerCoord = containerElement.getBoundingClientRect()
    const tagElementId = `lead-tooltip-${option.leadId}`
    let tagElement = document.getElementById(tagElementId)
    let topPosition = 10 + containerCoord.height + 'px'

    if (index > 0 ) {
      topPosition = (containerCoord.height * index) - 15 + 'px'
    }

    tagElement.style.display = 'inline-table'
    tagElement.style.top = topPosition
    tagElement.style.left = '35px'
  }
  const onMouseOutElement = () => {
    const tagElementId = `lead-tooltip-${option.leadId}`
    let tagElement = document.getElementById(tagElementId)
    tagElement.style.display = 'none'
  }

  return (
    <li
      id={`lead-row-${option.leadId}`}
      className='change-submenu-item'
      onClick={() => onSelectElement(option)}
      onMouseOver={option.customerId ? () => onMouseOverElement() : null}
      onMouseOut={() => onMouseOutElement()}
    >
      <img
        className={isEmpty(option.image) ? 'salesman-not-image' : 'salesman-image'}
        src={isEmpty(option.image) ? `${pathServer.PATH_IMG}avatar.png` : option.image}
      />
      <span className='user-name'>{`${option.firstName} ${option.lastName}`}</span>
      {
        option.customerId
          ? <ReactSVG
            src={`${pathServer.PATH_IMG}icon/ic_check.svg`}
            className='icon-check'
            beforeInjection={svg => svg.classList.add('icon-check-svg-completed')}
          />
          : null
      }
      <div id={`lead-tooltip-${option.leadId}`} className="task-user-title">
        <span>{'Already a customer'}</span>
      </div>
    </li>
  )
};

const LeadsPopoverChild = ({options, handleSelectElement}) => {
  const rows = options.map((el, index) => (
    <LeadOption
      key={`leadOptionKey-${index}`}
      index={index}
      option={el}
      onSelectElement={handleSelectElement}
    />
  ))

  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {rows}
        </u>
      </div>
    </div>
  )
};

function getDisplayValue(status) {
  let name = ""
  APPLICANTS_ACTIONS.forEach(applicant => {
    if(applicant.id === status || applicant.value === status){
      name = applicant.value
    }
  })

  return name
}

function allCustomers(leads) {
  return leads.every(el => !isEmpty(el.customerId))
}

const ID = {
  PROMOTE_BUTTON: 'promoteButtonId',
  STATUS: 'status',
  SALESMAN: 'salesmanId',
}

export function getApplicantType(dataFinance) {
  const values = dataFinance.values;
  const attributes = dataFinance.form.formAttributes.attributes;
  const attributeApplicantType = attributes.find(x => x.name === ATTRIBUTE_APPLICANT_TYPE);
  let typeOfApplicant = "";
  values.forEach((value,index) => {
    if(index === 0){
      for(let key in value){
        if(key === attributeApplicantType.id){
          if (value[key][0]) {
            const applicantType = attributeApplicantType.attributeValues.find(x => x.id === value[key][0])
            typeOfApplicant = applicantType.displayValue
          }
        }
      }
    }
  })

  return typeOfApplicant;
}

class HeaderViewApplicant extends Component {
  componentRef;

  constructor() {
    super();

    this.state = {
      dropDownSelect: ["New"],
      applicant: null,
      hideApp: true,
      financeObj: {
        financeId: "",
        financeType: null,
        values: [],
        comments: []
      },
      customerId : "",
      showLoader:false,
      showModalPromote: false,
      showModalMessage: false,
      message: "",
      customers: [],
      filterSelected:null,
      options:[],
      showPopover:false,
      anchorEl:null,
      leads: [],
      existProspectByEmail: false
    }
  }

  saveApplicant = () => {
    const {financeObj,applicant} = this.state;
    const {isViewFromSaleEdit} = this.props;
    if(!isViewFromSaleEdit)
      this.props.openModal(financeObj);
    else
      this.props.openModal(financeObj,applicant);
  }

  updateFinanceObj = (financeObj) => {
    let dataFinance = {...this.props.dataFinance}
    const dataFinanceValues = [...dataFinance.values]
    const financeObjValues = [...financeObj.values]
    dataFinance.finance.financeType = financeObj.financeType
    dataFinance['showEmploymentSubSections'] = financeObj.showEmploymentSubSections

    if (dataFinanceValues.length !== financeObjValues.length) {
      dataFinance.values = financeObjValues
    }

    this.setState({ financeObj: financeObj },
      () => this.props.onUpdateDataFinance(dataFinance)
    );
  }

  componentDidMount() {
    this.loadInfoFromDataFinance()
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataFinance.values !== prevProps.dataFinance.values) {
      this.loadInfoFromDataFinance()
    }
  }

  loadInfoFromDataFinance() {
    const {dataFinance} = this.props;

    // Get list of leads
    const leads = dataFinance
      && dataFinance.values
      && dataFinance.values.map(el => ({
        firstName: el.firstName,
        lastName: el.lastName,
        leadId: el.leadId,
        customerId: el.customerId ? el.customerId : el.objectId,
      }))

    this.setState(
      {
        applicant: dataFinance.finance,
        customerId: dataFinance.customerId,
        leads,
      }
    );
  }

  onChangeDropDownForm = (dropDown) => {
    let target = document.getElementById(dropDown);
    let anchorEl = getAnchorEl(target,2);
    let options = [];
    if (dropDown === ID.STATUS) {
      options = APPLICANTS_ACTIONS;
    } else if (dropDown === ID.SALESMAN) {
      const {users} = this.props;
      options = users;
    }
    this.setState({anchorEl:anchorEl,showPopover:true,filterSelected:dropDown,options:options})
  }

  onSelectDropDown = (dropDown, value) => {
    let {applicant} = this.state;
    const {dataFinance} = this.props;
    this.setState({showPopover:false});
    const typeOfApplicant = getApplicantType(dataFinance);
    applicant.status = value;
    applicant.financeType = typeOfApplicant;
    this.props.onChange(applicant);
  }

  onSelectSalesman = (dropDown, salesmanId) => {
    let {applicant} = this.state;
    const {dataFinance} = this.props;
    const typeOfApplicant = getApplicantType(dataFinance);
    applicant.salesmanId = salesmanId;
    applicant.financeType = typeOfApplicant;
    this.setState({applicant: applicant,showPopover:false}, () => {
      this.props.onChange(applicant);
    });
  }

  closePopover(dropDown) {
    let dropDownClose = this.state[dropDown];
    dropDownClose.render = false;
    this.setState({[dropDown]: dropDownClose})
  }

  promoteLead = async (leadId, customerId, create) => {
    let showModalPromote = false;
    let message = "";
    let customers = [];
    let showModalMessage = false;
    let leadsToUpdate = [...this.state.leads]
    let existProspectByEmail = false

    const response = await promoteLead(leadId, customerId, create)
    const {responseCode, responseMessage, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      if (data.customers && data.customers.length > 0) {
        customers = data.customers
        showModalPromote = true
        message = customers.length + " similar customer(s) found"
        existProspectByEmail = data.existProspectByEmail
      } else {
        this.setState({ showLoader: true });

        showModalMessage = true;
        message = "Successfully Added";

        if (customerId !== null && customerId) {
          message = "Successfully Updated";
        }
        if (data.customerId) {
          // Updating customerId prop on current lead
          const leadFoundIndex = leadsToUpdate.findIndex(el => el.leadId === leadId)
          let leadFound = leadsToUpdate.find(el => el.leadId === leadId)
          leadFound['customerId'] = data.customerId
          leadsToUpdate.splice(leadFoundIndex, 1, leadFound)

          this.setState(
              {
                customerId: data.customerId,
                showLoader: false,
                leads: leadsToUpdate
              }
          );
        }
      }

      this.setState(
          {
            showModalPromote: showModalPromote,
            message: message,
            customers: customers,
            showModalMessage: showModalMessage,
            showLoader: false,
            currentLeadIdToPromote: leadId,
            existProspectByEmail: existProspectByEmail
          }
      )
    }
    else {
      console.error(responseMessage);
      this.setState({ showLoader: false });
    }
  }

  goToCustomerInfo = () => {
    const {customerId} = this.state;
    this.props.setMenuId("customer");
    browserHistory.push({pathname:`/main/customer/edit/${customerId}`});
  }

  handleModalChange = () =>{
    this.setState({showModalPromote:false, showModalMessage:false});
  }

  handleRequestClose = () => {
    this.setState({showPopover: false,filterSelected:null});
  }

  generateFinanceApplicationFile = () => {
    const { applicant } = this.state;
    const financeId = applicant && applicant.id

    window.open(`${getServerUrl()}api/sales/${financeId}/financeTools/financeApplication`, '_blank');
  }

  onHandlePromote() {
    const target = document.getElementById(ID.PROMOTE_BUTTON);
    const anchorEl = getAnchorEl(target,2, 200);

    this.setState({
      anchorEl,
      showPromotePopover: true,
      promoteOptions: this.state.leads,
    })
  }

  handleHidePromotePopover() {
    this.setState({ showPromotePopover: false })
  }

  onHandleSelectLeadOption(leadOption) {
    this.handleHidePromotePopover()

    if (!leadOption.customerId) {
      this.promoteLead(leadOption.leadId, null, false)
    } else {
      window.open(`${getServerUrl()}main/customer/edit/${leadOption.customerId}`, '_blank')
    }
  }

  render() {
    const { applicant, showLoader, showModalPromote, message, existProspectByEmail, customers, showModalMessage,
            filterSelected, options,showPopover,anchorEl, currentLeadIdToPromote, leads } = this.state;
    let {users,isViewFromSales, isViewFromSaleEdit, goToSaleBoard, salesmanId, financeId, height, dataFinance, errors} = this.props;

    let displayUser = "Select Salesman";
    let image = "";
    if (applicant && applicant.salesmanId) {
      let user = users.filter(x => x.id === applicant.salesmanId)[0];
      image = user.image;
      displayUser = user.firstName + " " + user.lastName;
    }else if(applicant && salesmanId && financeId === "new"){
      let user = users.find(x => x.id === salesmanId);
      displayUser = `${user.firstName} ${user.lastName}`;
      applicant.salesmanId = salesmanId;
    }

    let id = '';
    let onSelectDropDown;
    if(filterSelected === ID.STATUS){
      onSelectDropDown = this.onSelectDropDown;
      id = 'value';
    }else if(filterSelected === ID.SALESMAN){
      onSelectDropDown = this.onSelectSalesman;
      id = 'id';
    }

    if(displayUser.length > 22)
      displayUser = `${displayUser.substr(0,22)}.`;

    let statusValue = applicant ? getDisplayValue(applicant.status) : "";

    const { isNewFinanceApplication } = this.props;

    return (
      <div className="div-container-sub">
        <div className="container-header-view-app">
          {
            !isViewFromSales ?
              <div className="header-view-app-left">
                <div className="container-change-status">
                  <a id={ID.STATUS} className="btn-message-dp large-select"
                     onClick={() => this.onChangeDropDownForm(ID.STATUS)}>
                    {
                      (statusValue) ? <span>{statusValue}</span> : <span>{APPLICANTS_ACTIONS[0].value}</span>
                    }
                    {
                      (filterSelected === ID.STATUS) ?
                        <i className="fa fa-chevron-up"/> :
                        <i className="fa fa-chevron-down"/>
                    }
                  </a>
                </div>
              </div> : null
          }
          <div className={!isViewFromSales ? "header-view-app-right" :"header-view-app-right header-view-app-right-sale"}>
            {
              !isViewFromSales ?
                <div className="label-assign">Assigned to:</div> : null
            }
            {
              !isViewFromSales ?
                <div className="container-change-salesman">
                  <a id={ID.SALESMAN} className="btn-message-dp large-select"
                     onClick={() => this.onChangeDropDownForm(ID.SALESMAN)}>
                    {
                      (displayUser !== "Select Salesman") ?
                        <img className="user-img" src={!isEmpty(image) ? image : pathServer.PATH_IMG+"avatar.png"} /> : null
                    }
                    <span className={(displayUser !== "Select Salesman") ? "select-user" : "not-select-user"}>{displayUser}</span>
                    {
                      (filterSelected === ID.SALESMAN && showPopover ) ? <i className="fa fa-chevron-up"/> : <i className="fa fa-chevron-down"/>
                    }
                  </a>
                </div> : null
            }
            <div className="icons-options">
              {
                !isNewFinanceApplication &&
                <React.Fragment>
                  <ReactSVG src={pathServer.PATH_IMG + 'icon/office-paper-printer.svg'}
                            beforeInjection={svg => svg.classList.add('icon-info')}
                            onClick={this.generateFinanceApplicationFile}
                            className="header-icon-print"/>
                  <ReactSVG src={pathServer.PATH_IMG + 'icon/download-tray.svg'}
                            beforeInjection={svg => svg.classList.add('icon-info')}
                            onClick={this.generateFinanceApplicationFile}
                            className="header-download-icon"/>
                </React.Fragment>
              }
              {
                !isViewFromSaleEdit
                  ? showLoader
                  ? <div className="loader-container">
                    <div className="loader"/>
                  </div>
                  : isViewFromSales
                    ? <button
                      className="button-customer-info"
                      onClick={this.goToCustomerInfo}
                      disabled={isViewFromSales}
                    >
                      Edit Application
                    </button>
                    : <div className="icon-user-container">
                      <button
                        id={ID.PROMOTE_BUTTON}
                        className="btn-promote"
                        onClick={() => {
                          if (leads.length === 1) {
                            if (allCustomers(leads)) {
                              this.onHandleSelectLeadOption(leads[0])
                            } else {
                              this.promoteLead(leads[0].leadId, null, false)
                            }
                          }

                          if (leads.length > 1) {
                            this.onHandlePromote()
                          }
                        }}
                      >
                        <ReactSVG
                          id="iconUser" src={pathServer.PATH_IMG + "icon/ic_user_upgrade.svg"}
                          beforeInjection={ svg => svg.classList.add("icon-info")}
                          className="header-add-user"
                        />
                        {allCustomers(leads) ? 'Customer Info' : 'Promote to Customer'}
                      </button>
                    </div>
                  : null
              }
              {
                isViewFromSaleEdit ?
                  <button  className="button-cancel" onClick={goToSaleBoard} disabled={isViewFromSales}>Cancel</button> : null
              }
            </div>
            {
              !isViewFromSales ?
                <div className="save-button" onClick={this.saveApplicant}>
                  Save
                </div> : null
            }
          </div>
        </div>
        {
          dataFinance
            ? <ViewApplicant
              updateFinanceObj={this.updateFinanceObj}
              dataFinance={dataFinance}
              hideApp={false}
              onlyRead={isViewFromSales}
              ref={el => (this.componentRef = el)}
              height={height}
              isNewFinanceApplication={isNewFinanceApplication}
              handleModalDelete={(applicantToDelete) => this.props.onHandleModalDelete(applicantToDelete)}
              errors={errors}
              userId={this.props.userId}
              users={this.props.users}
            />
            : null
        }
        {
          showModalPromote ?
            <ModalPromote show={showModalPromote}
                          message={message}
                          save={this.promoteLead}
                          onHide={this.handleModalChange}
                          buttonLabelSave="Create a new one"
                          classButtonSave="btn-save"
                          customers={customers}
                          leadId={currentLeadIdToPromote}
                          existProspectByEmail={existProspectByEmail}
            /> : null
        }
        {
          showModalMessage ?
            <ModalMessage show={showModalMessage}
                          message={message}
                          save={this.goToCustomerInfo}
                          onHide={this.handleModalChange}
                          buttonLabelSave="Customer Page"
                          classButtonSave="btn-save"
            /> : null
        }
        {
          showPopover && filterSelected !== null ?
            <Popover anchorEl={anchorEl}
                     child={<PopoverChild onSelectDropDown={onSelectDropDown} filterSelected={filterSelected}
                                          options={options} value={!isNewFinanceApplication ? applicant[filterSelected]: ""} field={id}/>}
                     classPopover="popover-box-message popover-users"
                     handleRequestClose={this.handleRequestClose}/> : null
        }

        {
          this.state.showPromotePopover
            ? <DPPopover
              anchorEl={anchorEl}
              classNameWrapper="popover-box-message popover-users"
              handleRequestClose={() => this.handleHidePromotePopover()}
            >
              <LeadsPopoverChild
                options={this.state.promoteOptions}
                handleSelectElement={(option) => this.onHandleSelectLeadOption(option)}
              />
            </DPPopover>
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId : state.userId,
  }
};

export default connect(mapStateToProps,{setMenuId})(HeaderViewApplicant)
