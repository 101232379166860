import React, {Component} from 'react'
import {STATUS_DELETE, UNDO_DELETE} from '../../../Constants.js'
import {updateStatusForm} from '../../../Api.js'
import {sortBy} from 'lodash/collection'
import ModalForm from './ModalForm.jsx'
import {browserHistory} from 'react-router'
import {searchFormApi} from '../../../Api'
import {Popover} from '../../util/ComponentUtils'
import {siteColorAdmin, USERS_BY_PAGE} from '../../../Constants'
import {ReactSVG} from 'react-svg'
import {getPosition} from '../../../../common/Util'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'
import {DPPagination} from '../../../../common/DPPagination'

const CheckBox = ({value, onChange, id, isSelected}) => {
  return (
    <label key={id} className="container-check-form">
      <input type="checkbox"
             checked={value}
             onChange={onChange}/>
      <span className="checkmark-form" style={isSelected ? {border: "1.4px #4695d4 solid"} : null}/>
      {
        value ? <i className="fa fa-check i-check"/> : null
      }
    </label>
  )
};

export default class FormSettingList extends Component {
  constructor() {
    super();
    this.state = {
      changeStatus: false,
      formData: [],
      form: null,
      value: '',
      previewDate: "",
      stateSearchDate: false,
      listName: {
        id: null,
        displayValue: "Sort By"
      },
      listOpen: false,
      columnName: "name",
      formDropDown: ["opt1", "opt2"],
      foundValue: null,
      showDropDown: false,
      showModal: false,
      optionsOrder: [
        {
          id: "id",
          displayValue: "Clear All"
        },
        {
          id: "modifiedDate",
          displayValue: "Date Modified"
        },
        {
          id: "name",
          displayValue: "Name A-Z"
        },
        {
          id: "entityType",
          displayValue: "Type"
        },
        {
          id: "createDate",
          displayValue: "Date Created"
        }],
      showInactive: false,
      showOptions: false,
      anchorEl:"",
      itemsByPageQuantity:10,
      currentPage:1,
      isLoading: true
    }
  }

  componentDidMount() {
    let {exp} = this.props;
    this.searchForm(exp)
  }

  componentWillReceiveProps(nexProps) {
    let {exp} = nexProps;
    this.searchForm(exp)
  }

  searchForm(exp) {
    searchFormApi(exp).then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        return
      }
      this.setState({formData: data.form, isLoading: false})
    })
  }

  handleChange = (event) => {
    this.setState({value: event.target.value});
  }

  onHandleChange = (event) => {
    event.preventDefault();
    if (event.target.checked) {
      this.setState({showInactive: true});
      this.props.searchForm("showinactiveforms");
    } else {
      this.setState({showInactive: false});
      this.props.searchForm("");
    }
  }

  handleClickOutside = () => {
    this.setState({listOpen: false})
  }

  viewForm = (form,e) => {
    e.stopPropagation();
    this.setState({showModal: true, form: form})
  }

  handleHideModal = () => {
    this.setState({showModal: false, form: null});
  }

  onChangeDropDown = (id) => {
    let {showDropDown} = this.state;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };

    this.setState({showDropDown: !showDropDown, anchorEl: anchorEl})
  }

  onChangeSort(op) {
    if (op.id === "modifiedDate") {
      let formSort = sortBy(this.state.formData, [function (o) {
        return o[op.id];
      }]).reverse();
      this.setState({formData: formSort, listName: op, previewDate: "Modified on: ", stateSearchDate: true, showDropDown: false});
    }
    else {
      if (op.id === "createDate") {
        let formSort = sortBy(this.state.formData, [function (o) {
          return o[op.id];
        }]).reverse();
        this.setState({formData: formSort, listName: op, previewDate: "Created on: ", stateSearchDate: true, showDropDown: false});
      }
      else {
        let formSort = sortBy(this.state.formData, [function (o) {
          return o[op.id];
        }]);
        this.setState({formData: formSort, listName: op, stateSearchDate: false, showDropDown: false});
      }
    }
  }

  sendSearch = (event) => {
    event.preventDefault();
    this.setState({showInactive: false});
    this.props.searchForm(this.state.value);
  }

  updateStatus = (formId, newStatus, e) => {
    e.stopPropagation();
    updateStatusForm(formId, newStatus).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({showInactive: false});
        this.props.searchForm("");
      }
    })
  }

  onEdit(id,e) {
    e.stopPropagation();
    browserHistory.push({pathname: `/main/setting/form/${id}`});
  }

  closeHeaderFilter = () => {
    this.setState({showDropDown: false})
  }

  handleRequestOpen = (event) =>{
    this.setState({showOptions:true,anchorEl:{offsetWidth:event.currentTarget.offsetWidth,offsetHeight:event.currentTarget.offsetHeight}})
  }

  handleRequestClose = () => {
    this.setState({showOptions:false,showPopoverStatus:false,roleSelected:"-1",showDropDown:false})
  }

  handleItemsByPage = (itemsByPageQuantity) => {
    this.setState({itemsByPageQuantity:itemsByPageQuantity,showOptions:false,currentPage:1},() => {
    })
  }

  handlePageChange = (nextPage,totalPages) => {
    let currentPage = nextPage;
    if(currentPage > totalPages) {
      currentPage = 1;
    }else if(currentPage === 0){
      currentPage = totalPages;
    }
    this.setState({currentPage:currentPage});
  }

  renderOrder(){
    let {optionsOrder, listName} = this.state;
    let options = [];

    optionsOrder.map((op, index) => {
      if(op.displayValue === listName.displayValue){
        options.push(
          <li key={index} name={op.id} className="item-selected" onClick={() => this.onChangeSort(op)}>
            {op.displayValue}
          </li>
        )
      } else {
        options.push(
          <li key={index} name={op.id} className="change-submenu-item" onClick={() => this.onChangeSort(op)}>
            {op.displayValue}
          </li>
        )
      }
    });

    return(
      <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {options}
          </u>
        </div>
      </div>
    )
  }

  render() {
    let {showDropDown, formData, listName,previewDate, stateSearchDate, showInactive,showOptions,
      anchorEl,itemsByPageQuantity,currentPage,value, isLoading} = this.state;

    let totalPages = formData ? Math.ceil(formData.length/itemsByPageQuantity) : 1;
    let firstIndex = (this.state.itemsByPageQuantity ) * (this.state.currentPage - 1);
    let lastIndex = firstIndex + (this.state.itemsByPageQuantity );

    const childOrder = this.renderOrder();

    return (
      <div className={this.state.showModal ? "setting-container container-opacity" : "setting-container"}
           style={{display: "block"}}>
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> : null
        }
        <div className="setting-form-header-header">
          <div className="row-message" style={{position: "relative"}}>
            <div className="form-setting-button" style={{width: "8%", paddingLeft: "30px"}}>
              <div onClick={(e) => this.onEdit("new",e)} className="format-setting-button-add">+</div>
            </div>
            <div className="btn-container-dp btn-container-attribute">
              <input className="form-control" value={this.state.value} onChange={this.handleChange} placeholder="Keyword"/>
            </div>
            <div className="btn-dp-form">
              <button className="btn-basic btn-message-apply" onClick={this.sendSearch}>Apply</button>
            </div>
            <div className="btn-dp-form" style={{position: 'relative', marginRight:20}}>
              <div id="orderForm" className="btn-general-message-dp" onClick={this.onChangeDropDown.bind(this,"orderForm")} style={{width: 130}}>
                  <span>{(listName.displayValue === "Sort By" || listName.displayValue === "Clear All") ?
                    "Sort By" : listName.displayValue}</span>
                  {
                    (showDropDown) ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                  }
              </div>
            </div>
            <div className="btn-dp-form" style={{width: "210", display: "flex", height: "60", paddingLeft: "11px"}}>
              <CheckBox value={showInactive} onChange={this.onHandleChange} id={"showInactive"} isSelected={true}/>
              <span style={{paddingTop: "6px", fontSize: "13px", paddingLeft: "7px", color: "#898989"}}>Show Only Inactive Forms</span>
            </div>
            <div className="inventory-pagination" style={{position: "absolute",right: "10px", padding: "14px 10px 14px", fontSize:"12px", height: "100%"}}>
              <DPPagination
                optionsQuantityByPage={USERS_BY_PAGE}
                selectedItemsByPage={itemsByPageQuantity}
                handleChangeItemsByPage={this.handleItemsByPage}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={this.handlePageChange}
                siteColor={siteColorAdmin}
              />
            </div>
          </div>
        </div>
        <div className="table-form-setting">
          <table className="table">
            <thead style={{backgroundColor: "#EFEFEF"}}>
            <tr className="title-forms-list">
              <th className="title-name-forms-list">Form Name</th>
              <th className="title-code-forms-list">Form Code</th>
              <th className="title-entity-forms-list">Entity Type</th>
              <th className="title-created-by-forms-list">Form Type</th>
              <th className="title-date-forms-list">Date</th>
              <th className="title-graph-forms-list"/>
            </tr>
            </thead>
            <tbody className="tbody-class">
            {
              formData.length !== 0 ?
                (
                  formData.slice(firstIndex,lastIndex).map((form, index) => {
                    return (
                      <tr key={index + 'tr'} className="rowCostumer row-form-setting" onClick={(e) => this.viewForm(form,e)}>
                        <td className="title-name-forms-list" style={{paddingLeft: 70}}
                            key={index + 'name'}>{form.name}</td>
                        <td className="title-code-forms-list" style={{paddingLeft: 70}}
                            key={index + 'code'}>{form.code}</td>
                        <td className="title-entity-forms-list" key={index + 'Entity_Type'}>{form.entityType}</td>
                        <td className="title-created-by-forms-list" key={index+'Created_By'}>
                          {form.createdBy === "SYSTEM" ? "System" : "User"}
                        </td>
                        <td className="title-date-forms-list" key={index + 'date'}>
                          {
                            (stateSearchDate) ?
                              (previewDate)
                              :
                              (form.createDate === form.modifiedDate) ?
                                "Created on "
                                :
                                "Modified on "
                          }
                          {new Intl.DateTimeFormat('en-US', {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit'
                          }).format((previewDate === "Created on ") ? form.createdDate : form.modifiedDate)}
                        </td>
                        <td key={index + 'td'} style={{color: "#f3f3f3",height:50}}>
                          {
                            (showInactive) ?
                              <div className="options">
                                <i style={{color: "#d9534f"}} className="fa fa-undo fa-lg"
                                   title="Undo" onClick={this.updateStatus.bind(this, form.id, UNDO_DELETE)}/>
                              </div>
                              :
                              <div className="options" style={{marginTop:4}}>
                                <i style={{color: "#4ad991"}} className="fa fa-pencil fa-lg"
                                   title="Edit" onClick={(e) => this.onEdit(form.id,e)}/>
                                {
                                  form.createdBy !== "SYSTEM" ?
                                    <i style={{color: "#d9534f"}} className="fa fa-trash fa-lg"
                                       title="Delete" onClick={this.updateStatus.bind(this, form.id, STATUS_DELETE)}/> :
                                    null
                                }

                              </div>
                          }
                        </td>
                      </tr>
                    )
                  })
                )
                :
                null
            }
            </tbody>
          </table>
        </div>
        {
          showDropDown ?
            <Popover anchorEl={anchorEl}
                     child={childOrder}
                     classPopover="popover-box-message"
                     handleRequestClose={this.handleRequestClose}/> : null
        }
        {
          (this.state.showModal) ?
            <ModalForm show={this.state.showModal}
                       form={this.state.form}
                       onHide={this.handleHideModal.bind(this)}
                       container={this}/> : null
        }
      </div>
    )
  }
}