import {
  loadMessagesApi,
  loadResponseMessageApi,
  loadFinancesApi,
  loadMakeAndModelApi,
  searchRepairsApi,
  searchInventoryApi,
  loadLocationsApi,
  loadUsersApi,
  loadCustomersApi,
  loadMakeAndModelAllApi,
  updateTestimonial,
  loadTestimonials,
  loadAllUserActive,
  loadAccountApi,
  loadActiveFeatureFlagsApi,
} from '../Api.js'
import get from 'lodash/get'
import {EmptyFunc, SUCCESSFUL_CODE} from '../../common/Constants'
import {
  CLEAR_SUBTASKS,
  DELETE_SUBTASK,
  LOAD_TASK,
  SAVE_TASK_INFO,
  SET_SUBTASKS,
  UPDATE_SUBTASK,
  UPDATE_TASK
} from "../Constants";

export const SEARCH_INVENTORY_REQUEST = 'SEARCH_INVENTORY_REQUEST';
export const SEARCH_INVENTORY_SUCCESS = 'SEARCH_INVENTORY_SUCCESS';
export const SEARCH_INVENTORY_FAILURE = 'SEARCH_INVENTORY_FAILURE';
export const LOAD_MESSAGES_REQUEST    = 'LOAD_MESSAGES_REQUEST';
export const LOAD_RESPONSE_MESSAGE_REQUEST    = 'LOAD_RESPONSE_MESSAGE_REQUEST';
export const LOAD_MESSAGES_SUCCESS    = 'LOAD_MESSAGES_SUCCESS';
export const LOAD_RESPONSE_MESSAGE_SUCCESS    = 'LOAD_RESPONSE_MESSAGE_SUCCESS';
export const LOAD_MESSAGES_FAILURE    = 'LOAD_MESSAGES_FAILURE';
export const LOAD_RESPONSE_MESSAGE_FAILURE    = 'LOAD_RESPONSE_MESSAGE_FAILURE';
export const LOAD_FINANCES_REQUEST    = 'LOAD_FINANCES_REQUEST';
export const LOAD_FINANCES_SUCCESS    = 'LOAD_FINANCES_SUCCESS';
export const LOAD_FINANCES_FAILURE    = 'LOAD_FINANCES_FAILURE';
export const LOAD_MAKES_REQUEST       = 'LOAD_MAKES_REQUEST';
export const LOAD_MAKES_SUCCESS       = 'LOAD_MAKES_SUCCESS';
export const LOAD_MAKES_FAILURE       = 'LOAD_MAKES_FAILURE';
export const SEARCH_REPAIRS_SUCCESS   = 'SEARCH_REPAIRS_SUCCESS';
export const SEARCH_REPAIRS_FAILURE   = 'SEARCH_REPAIRS_FAILURE';
export const SEARCH_REPAIRS_REQUEST   = 'SEARCH_REPAIRS_REQUEST';
export const SAVE_CAR                 = 'SAVE_CAR';
export const SAVE_PRICE               = 'SAVE_PRICE';
export const ADD_CAR                  = 'ADD_CAR';
export const REPLACE_CAR              = 'REPLACE_CAR';
export const LOAD_LOCATIONS_REQUEST   = 'LOAD_LOCATIONS_REQUEST';
export const LOAD_LOCATIONS_SUCCESS   = 'LOAD_LOCATIONS_SUCCESS';
export const LOAD_LOCATIONS_FAILURE   = 'LOAD_LOCATIONS_FAILURE';

export const ADD_LOCATION             = 'ADD_LOCATION';
export const REPLACE_LOCATION         = 'REPLACE_LOCATION';
export const SELECTED_LOCATION_ID     = 'SELECTED_LOCATION_ID';

export const LOAD_USERS_REQUEST       = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS       = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE       = 'LOAD_USERS_FAILURE';
export const LOAD_CUSTOMERS_REQUEST   = 'LOAD_CUSTOMERS_REQUEST';
export const LOAD_CUSTOMERS_SUCCESS   = 'LOAD_CUSTOMERS_SUCCESS';
export const LOAD_CUSTOMERS_FAILURE   = 'LOAD_CUSTOMERS_FAILURE';
export const SET_SIDE_BAR             = 'SET_SIDE_BAR';
export const SET_TEMPLATE_ID          = 'SET_TEMPLATE_ID';
export const SET_SHOW_LIST            = 'SET_SHOW_LIST';
export const SET_SEARCH_QUERY_INVENTORY     = 'SET_SEARCH_QUERY_INVENTORY';
export const SET_CAR_STATUS_OPTIONS   = 'SET_CAR_STATUS_OPTIONS';
export const SET_MENU_ID              = 'SET_MENU_ID';
export const SET_KEYWORD              = 'SET_KEYWORD';
export const SET_PAGE_NUMBER_INVENTORY = 'SET_PAGE_NUMBER_INVENTORY';
export const SAVE_TASK_FILTER         = 'SAVE_TASK_FILTER';
export const SAVE_REPAIR_COST_FROM_INVENTORY  = 'SAVE_REPAIR_COST_FROM_INVENTORY';
export const SET_FIRST_LOADING        = 'SET_FIRST_LOADING';
export const SET_CAR_FILTERS          = 'SET_CAR_FILTERS';
export const SET_CAR_FILTERS_INITIAL  = 'SET_CAR_FILTERS_INITIAL';
export const SET_ITEMS_BY_PAGE_QUANTITY = 'SET_ITEMS_BY_PAGE_QUANTITY';
export const SET_MIN_VALUES = 'SET_MIN_VALUES';
export const SET_MAX_VALUES = 'SET_MAX_VALUES';
export const SET_CAR_FILTERS_AND_CURRENT_PAGE  = 'SET_CAR_FILTERS_AND_CURRENT_PAGE';
export const SET_SALE_FILTERS = 'SET_SALE_FILTERS';
export const SET_SALE_FILTERS_INITIAL = 'SET_SALE_FILTERS_INITIAL';
export const SET_SALE_FILTERS_AND_CURRENT_PAGE = 'SET_SALE_FILTERS_AND_CURRENT_PAGE';
export const SET_PAGE_NUMBER_SALES = 'SET_PAGE_NUMBER_SALES';
export const SET_USER_ROLES       = 'SET_USER_ROLES';
export const SET_TAX_EXEMPT       = 'SET_TAX_EXEMPT'
export const SET_DEALER_STATE_TAX = 'SET_DEALER_STATE_TAX'
export const SET_DATA_DASHBOARD_LAYOUT = "SET_DATA_DASHBOARD_LAYOUT"
export const SET_NEW_FINANCE_APPLICATIONS_COUNTER = 'SET_NEW_FINANCE_APPLICATIONS_COUNTER'
export const SET_UNREAD_MESSAGES_COUNTER = "SET_UNREAD_MESSAGES_COUNTER"
export const LOAD_MAKES_ALL_REQUEST       = 'LOAD_MAKES_ALL_REQUEST';
export const LOAD_MAKES_ALL_SUCCESS       = 'LOAD_MAKES_ALL_SUCCESS';
export const LOAD_MAKES_ALL_FAILURE       = 'LOAD_MAKES_ALL_FAILURE';
export const SET_SIDE_BAR_BOTTOM = 'SET_SIDE_BAR_BOTTOM'
export const UPDATE_TESTIMONIALS  = 'UPDATE_TESTIMONIALS'
export const LOAD_ACTIVE_USERS_SUCCESS = 'LOAD_ACTIVE_USERS_SUCCESS'
export const LOAD_ACTIVE_USERS_REQUEST = 'LOAD_ACTIVE_USERS_REQUEST'
export const LOAD_ACTIVE_USERS_FAILURE = 'LOAD_ACTIVE_USERS_FAILURE'

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

export const UPDATE_FEATURE_FLAGS_REQUEST = 'UPDATE_FEATURE_FLAGS_REQUEST';
export const UPDATE_FEATURE_FLAGS_SUCCESS = 'UPDATE_FEATURE_FLAGS_SUCCESS';
export const UPDATE_FEATURE_FLAGS_FAILURE = 'UPDATE_FEATURE_FLAGS_FAILURE';


function handleFetchRejection(dispatch, action, error) {
  dispatch({type: action, error: error});
  if (error.status === 401 || error.status === 400) { //not authorized request
    // dispatch(logout()).then((response) => {
    //   if(response && response.data && response.data.redirectUrl){
    //     window.location.replace(response.data.redirectUrl);
    //   }else{
    //     window.location.replace(baseRouterUrl + 'login');
    //   }
    // })
    console.log('handling error');
  }
}

export function loadMessages() {
  return(dispatch) => {

    dispatch({type:LOAD_MESSAGES_REQUEST});

    return loadMessagesApi().then(({ responseCode, data, responseMessage }) => {
      if(responseCode === SUCCESSFUL_CODE){
        // Calculates amount of unread messages
        const unreadMessagesCount = get(data, ['messages'], []).filter(el => !el.read && el.active).length
        dispatch({ type: SET_UNREAD_MESSAGES_COUNTER, payload: unreadMessagesCount});
        // End section

        dispatch({type:LOAD_MESSAGES_SUCCESS, payload:data.messages});
        return data
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, LOAD_MESSAGES_FAILURE, ex);
      return ex
    });
  }

}

/* Deprecated - Not in use */
export function loadResponseMessage() {
  return(dispatch) => {

    dispatch({type:LOAD_RESPONSE_MESSAGE_REQUEST});

    return loadResponseMessageApi().then(({ responseCode, data, responseMessage }) => {
      if (responseCode === SUCCESSFUL_CODE) {
        dispatch({type:LOAD_RESPONSE_MESSAGE_SUCCESS, payload: data.responses});
        return data.responses
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, LOAD_RESPONSE_MESSAGE_FAILURE, ex);
      return ex
    })
  }
}

export function loadFinances() {
  return (dispatch) => {

    dispatch({type:LOAD_FINANCES_REQUEST});

    return loadFinancesApi().then(({ responseCode, data, responseMessage }) => {
      if (responseCode === SUCCESSFUL_CODE){
        // Calculates amount of existing finance applications which status are new
        const newFinancesCount = data.finances ? data.finances.filter(el => el.status === 'new').length : 0
        dispatch({ type: SET_NEW_FINANCE_APPLICATIONS_COUNTER, payload: newFinancesCount});
        // End section

        dispatch({type:LOAD_FINANCES_SUCCESS, payload:data.finances});
        return data
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, LOAD_FINANCES_FAILURE, ex);
      return ex
    });
  }
}

export function loadMakeAndModel() {
  return (dispatch) => {

    dispatch({type:LOAD_MAKES_REQUEST});

    return loadMakeAndModelApi().then((response) => {
      if (response.responseCode === SUCCESSFUL_CODE){
        dispatch({type:LOAD_MAKES_SUCCESS, payload:response.data});
        return response
      }else{
        return handleFetchRejection(dispatch, LOAD_MAKES_FAILURE, response.responseMessage);
      }
    })
  }
}

export function loadMakeAndModelAll() {
  return (dispatch) => {

    dispatch({type:LOAD_MAKES_ALL_REQUEST});

    return loadMakeAndModelAllApi().then((response) => {
      if (response.responseCode === SUCCESSFUL_CODE){
        dispatch({type:LOAD_MAKES_ALL_SUCCESS, payload:response.data});
        return response
      }else{
        return handleFetchRejection(dispatch, LOAD_MAKES_ALL_FAILURE, response.responseMessage);
      }
    })
  }
}

export function searchRepairs(vin) {
  return (dispatch) => {

    dispatch({type: SEARCH_REPAIRS_REQUEST});

    return searchRepairsApi(vin).then((response) => {
      dispatch({type: SEARCH_REPAIRS_SUCCESS, payload: response.data});
      return response;
    }).catch(ex => {
      handleFetchRejection(dispatch, SEARCH_REPAIRS_FAILURE, ex);
      return ex
    })
  }
}

export let updateChangeCar = (value) => ({type: SAVE_CAR, item: value});

export let updateChangePrice = (value) => ({type: SAVE_PRICE, item: value});

export function searchInventory(body) {
  return (dispatch) => {

    dispatch({type: SEARCH_INVENTORY_REQUEST});

    return searchInventoryApi(body).then((response) => {
      if (response.responseCode === SUCCESSFUL_CODE){
        dispatch({type: SEARCH_INVENTORY_SUCCESS, payload: response.data});
        return response;
      }else{
        return handleFetchRejection(dispatch, SEARCH_INVENTORY_FAILURE, response.responseMessage);
      }
    })
  }
}

export let addCar = (car) => ({type: ADD_CAR, payload: car});

export let replaceCar = (cars) => ({type: REPLACE_CAR, payload: cars});

export function loadLocations() {
  return(dispatch) => {

    dispatch({type:LOAD_LOCATIONS_REQUEST});

    return loadLocationsApi().then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        dispatch({type:LOAD_LOCATIONS_SUCCESS, payload: data.locations});
        return response
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, LOAD_LOCATIONS_FAILURE, ex);
      return ex
    })
  }

}

export let addLocation = (location) => ({type: ADD_LOCATION, payload: location});

export let replaceLocation = (locations) => ({type: REPLACE_LOCATION, payload: locations});

export let selectedLocationId = (locationID) => ({type: SELECTED_LOCATION_ID, payload: locationID});

export let setShowSideBar = (showSideBar) => ({type: SET_SIDE_BAR, payload: showSideBar});

export let setSideBarOnBottom = (showSideBarOnBottom) => ({type: SET_SIDE_BAR_BOTTOM, payload: showSideBarOnBottom});

export function loadUsers(from,size,keyword,showInactives) {
  return(dispatch) => {

    dispatch({type:LOAD_USERS_REQUEST});

    return loadUsersApi(from,size,keyword,showInactives).then((response) => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {users} = data
        dispatch({type:LOAD_USERS_SUCCESS, payload: users});
        return response
      }else{
        handleFetchRejection(dispatch, LOAD_USERS_FAILURE, {status: responseCode, message: responseMessage});
        return responseMessage
      }
    })/*.catch(ex => {
      handleFetchRejection(dispatch, LOAD_USERS_FAILURE, ex);
      return ex
    })*/
  }

}

/* Deprecated - Not in use */
export function loadCustomers(keyword, from, size) {
  return(dispatch) => {

    dispatch({type:LOAD_CUSTOMERS_REQUEST});

    return loadCustomersApi(keyword, from, size).then((response) => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        dispatch({type:LOAD_CUSTOMERS_SUCCESS, payload: data.customers});
        return response
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, LOAD_CUSTOMERS_FAILURE, ex);
      return ex
    })
  }

}

export const setTestimonialsInStore = (from,size,getAdditionalInfo = false, setIsLoading = EmptyFunc, keyword) => {
  return  async dispatch => {
    setIsLoading(true)
    //await updateTestimonial(getAdditionalInfo)
    const {responseCode, responseMessage, data} = await loadTestimonials(from, size, keyword, getAdditionalInfo)
    if (responseCode !== SUCCESSFUL_CODE) {
      setIsLoading(false)
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, LOAD_CUSTOMERS_FAILURE, ex);
      return ex
    }
    let testimonials = data.testimonials ?? []
    let testimonialsWithFormat = testimonials
    if(testimonials.length > 0){
      testimonialsWithFormat = testimonials.map( testimonial => {return {...testimonial.testimonial, comments: testimonial.comments, reactions: testimonial.reactions}})
    }
    dispatch({type:UPDATE_TESTIMONIALS, payload: testimonialsWithFormat});
    setIsLoading(false)
  }
}

export const loadAllActiveUsers = (setIsLoading = EmptyFunc) => {
  return(dispatch) => {
    setIsLoading(true)
    dispatch({type: LOAD_ACTIVE_USERS_REQUEST, payload: []});

    return loadAllUserActive().then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        dispatch({type: LOAD_ACTIVE_USERS_SUCCESS, payload:data.users});
        setIsLoading(false)
        return
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, LOAD_ACTIVE_USERS_FAILURE, ex);
      setIsLoading(false)
      return ex
    })
  }
}

export function updateAccount() {
  return(dispatch) => {

    dispatch({type:UPDATE_ACCOUNT_REQUEST});

    return loadAccountApi().then((response) => {
      const {responseCode, data, responseMessage} = response
      if(responseCode === SUCCESSFUL_CODE){
        dispatch({type:UPDATE_ACCOUNT_SUCCESS, payload: data.user});
        return response
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, UPDATE_ACCOUNT_FAILURE, ex);
      return ex
    });
  }
}

export function getActiveFeatureFlags() {
  return (dispatch) => {
    dispatch({type: UPDATE_FEATURE_FLAGS_REQUEST});

    return loadActiveFeatureFlagsApi().then(response => {
      const {responseCode, data, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE) {
        dispatch({type: UPDATE_FEATURE_FLAGS_SUCCESS, payload: data});
        return response
      }

      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, UPDATE_FEATURE_FLAGS_FAILURE, ex);
      return ex
    })
  }
}

export let setTemplateId = (templateId) => ({type: SET_TEMPLATE_ID, payload: templateId});

export let setShowList = (showList) => ({type: SET_SHOW_LIST, payload: showList});

export let setSearchQueryInventory = (searchQueryInventory) => ({type: SET_SEARCH_QUERY_INVENTORY, payload: searchQueryInventory});

export let setCarStatusOptions = (carStatusOptions) => ({type: SET_CAR_STATUS_OPTIONS, payload: carStatusOptions});

export let setMenuId = (menuId) => ({type: SET_MENU_ID, payload: menuId});

export let setKeyword = (keyword) => ({type: SET_KEYWORD, payload: keyword});

export let setPageNumberInventory = (pageNumberInventory) => ({type: SET_PAGE_NUMBER_INVENTORY, payload: pageNumberInventory});

export let setTaskFilters = (queryFilter) => ({type:SAVE_TASK_FILTER,payload : queryFilter});

export let setFirstLoading = (isFirstLoading) => ({type: SET_FIRST_LOADING, payload: isFirstLoading});

export let setCarFilters = (carFilters) => ({type: SET_CAR_FILTERS, payload: carFilters});

export let setCarFiltersInitial = (carFiltersInitial) => ({type: SET_CAR_FILTERS_INITIAL, payload: carFiltersInitial});

export let setItemsByPageQuantity = (itemsByPageQuantity) => ({type: SET_ITEMS_BY_PAGE_QUANTITY, payload: itemsByPageQuantity});

export let setMinValues = (minValues) => ({type: SET_MIN_VALUES, payload: minValues});

export let setMaxValues = (maxValues) => ({type: SET_MAX_VALUES, payload: maxValues});

export let setCarFiltersAndCurrentPage = (carFiltersAndCurrentPage) => ({type: SET_CAR_FILTERS_AND_CURRENT_PAGE, payload: carFiltersAndCurrentPage});

export let setSaleFilters = (saleFilters) => ({type: SET_SALE_FILTERS, payload: saleFilters})

export let setPageNumberSales = (pageNumberSales) => ({type: SET_PAGE_NUMBER_SALES, payload: pageNumberSales});

export const setUserRoles = userRoles => ({type: SET_USER_ROLES, payload: userRoles});

export const setDashboardLayout = (data) => ({type: SET_DATA_DASHBOARD_LAYOUT, payload: data})

export const loadTask = (task) => ({
  type: LOAD_TASK,
  payload: { task }
});

export const updateTask = (task) => ({
  type: UPDATE_TASK,
  payload: { task }
});

export const saveTaskInfo = (taskInfo) => ({
  type: SAVE_TASK_INFO,
  payload: taskInfo
});
export const setSubTasks = (subtasks) => ({
  type: SET_SUBTASKS,
  payload: subtasks
});

export const clearSubTasks = () => ({
  type: CLEAR_SUBTASKS
});

export const updateSubTask = (subTask) => ({
  type: UPDATE_SUBTASK,
  payload: subTask
});

export const deleteSubTask = (subTask) => ({
  type: DELETE_SUBTASK,
  payload: subTask
});
