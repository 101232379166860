import React, {Component} from 'react'
import {pathServer, SUCCESSFUL_CODE} from '../common/Constants'
import {changePasswordAccount, verifyUser} from './Api'
import {ReactSVG} from 'react-svg'

export default class AccountConfirmation extends Component {

  constructor() {
    super();
    this.state = {
      password:"",
      confirmPassword:"",
      isEmptyPassword:false,
      isEmptyConfirmPassword:false,
      matchPassword:false,
      meetRequirements: true,
      errorMessage:null,
      requirements: [false, false, false, false]
    }
  }

  changePassword = () => {
    const {userId} = this.props.params;
    let {password, confirmPassword, requirements} = this.state;
    if (password === "") {
      this.setState({isEmptyPassword: true, meetRequirements: true, isEmptyConfirmPassword: false, matchPassword: false});
    } else if (requirements.toString() !== "true,true,true,true") {
      this.setState({isEmptyPassword: false, meetRequirements: false, isEmptyConfirmPassword: false, matchPassword: false});
    }else if (confirmPassword === "") {
      this.setState({isEmptyPassword: false, meetRequirements: true, isEmptyConfirmPassword: true, matchPassword: false});
    } else if(password !== confirmPassword){
      this.setState({isEmptyPassword: false, meetRequirements: true, isEmptyConfirmPassword: false, matchPassword: true});
    }else{
      const data = {userId:userId,password:password,confirmPassword:confirmPassword, verify:true};
      changePasswordAccount(data).then((response) =>{
        //browserHistory.push("/main")
        const {responseCode, responseMessage} = response
        if(responseCode === SUCCESSFUL_CODE){
          window.location.href = "/login"
        }else{
          this.setState({errorMessage: responseMessage})
        }
      })
    }
  }

  handleVerifyUser = () => {
    const {userId} = this.props.params;
    verifyUser(userId).then((response) => {
      window.location.href = "/login"
    }).catch((error) => {
      console.error(error)
    })
  }

  handleChange = (e) => {
    const valueRequirementsToUpdate = [false, false, false, false];
    const password = e.target.value;
    if (password.match(/[A-Z]/g)) {
      valueRequirementsToUpdate[0] = true;
    }
    if (password.length >= 8) {
      valueRequirementsToUpdate[1] = true;
    }
    if (password.match(/[^a-zA-Z\d]/g)) {
      valueRequirementsToUpdate[2] = true;
    }
    if (password.match(/[0-9]/g)) {
      valueRequirementsToUpdate[3] = true;
    }
    this.setState({requirements: valueRequirementsToUpdate, password: password, isEmptyPassword: false, matchPassword: false});
  }

  handleConfirmPassword = (e) => {
    let confirmPassword = e.target.value;
    this.setState({confirmPassword: confirmPassword, isEmptyConfirmPassword: false, matchPassword: false});
  }

  render() {
    const {userId, verify} = this.props.params;
    const verificationOnly = verify === "verify"
    let {password, confirmPassword, isEmptyPassword, isEmptyConfirmPassword, matchPassword, requirements, meetRequirements} = this.state;
    return (
      <div className="account-confirmation">
        <div id="id-background" className="background"/>
        <div className="header">
          <img className="dealer-logo-img" src={pathServer.PATH_IMG + "godealergo.png"}/>
        </div>

        {verificationOnly
          ? (
            <>
              <p className="title">Almost there</p>
              <p className="sub-title">Please click to verify</p>
              <div className="text-center">
                <button className="btn-start" onClick={this.handleVerifyUser}>Verify</button>
              </div>
            </>
          )
          : (
            <>
              <p className="title">Almost there</p>
              <p className="sub-title">For safety, please set a new password</p>
              <div className="form-content">
                {matchPassword
                  ? <span className="message-general-error">Password not match Confirm Password</span>
                  : null
                }
                {!meetRequirements
                  ? <span className="message-general-error">Password not meet Requirements</span>
                  : null
                }
                <div style={{position: 'relative'}}>
                  <div className="box-new-password-recover">
                    <label>New Password</label>
                    <input type="password" placeholder="Type here" value={password} onChange={this.handleChange}/>
                    {isEmptyPassword ? <span className="message-error">Please provide new password</span> : null}
                  </div>
                  <img className="img-new-password-recover-new"
                       style={{display: (requirements.toString() === 'true,true,true,true') ? 'initial' : 'none'}}
                       src={pathServer.PATH_IMG + 'check.png'}
                       alt=""
                  />
                </div>
                <div style={{marginTop: '20px', position: 'relative'}}>
                  <div className="box-new-password-recover">
                    <label>Confirm Password</label>
                    <input type="password"
                           placeholder="Type here"
                           value={confirmPassword}
                           onChange={this.handleConfirmPassword}
                    />
                    {isEmptyConfirmPassword
                      ? <span className="message-error">Please provide confirm password</span>
                      : null
                    }
                  </div>
                  <img className="img-new-password-recover-new"
                       style={{display: (password !== '' && password === confirmPassword) ? 'initial' : 'none'}}
                       src={pathServer.PATH_IMG + 'check.png'} alt=""/>
                </div>
                <div className="requirements-box">
                  <div className="form-group-login"
                       style={{color: !this.state.requirements[0] ? 'black' : 'rgb(0,82,160)'}}>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_check_circle.svg'} className="icon-arrow-green"
                              style={{fill: !this.state.requirements[0] ? 'black' : 'rgb(0,82,160)'}}/>
                    A capital letter
                  </div>
                  <div className="form-group-login"
                       style={{color: !this.state.requirements[1] ? 'black' : 'rgb(0,82,160)'}}>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_check_circle.svg'} className="icon-arrow-green"
                              style={{fill: !this.state.requirements[1] ? 'black' : 'rgb(0,82,160)'}}/>
                    At least 8 characters
                  </div>
                  <div className="form-group-login"
                       style={{color: !this.state.requirements[2] ? 'black' : 'rgb(0,82,160)'}}>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_check_circle.svg'} className="icon-arrow-green"
                              style={{fill: !this.state.requirements[2] ? 'black' : 'rgb(0,82,160)'}}/>
                    A symbol (!%^&*)
                  </div>
                  <div className="form-group-login"
                       style={{color: !this.state.requirements[3] ? 'black' : 'rgb(0,82,160)'}}>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_check_circle.svg'} className="icon-arrow-green"
                              style={{fill: !this.state.requirements[3] ? 'black' : 'rgb(0,82,160)'}}/>
                    A number
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn-start" onClick={this.changePassword}>Let's get started</button>
                </div>

              </div>
            </>
          )
        }

      </div>
    )
  }
}
