import React, {useState, useRef} from 'react'
import NavigationBar from "../components/navigationBar/NavigationBar";
import IntroBannerSection from "../components/introBannerSection/IntroBannerSection";
import FooterSection from "../components/footer/FooterSection";
import {pathServer} from "../../../../common/Constants";
import SplitSection from "../components/SplitSection";
import GDGButton, {BUTTON_TYPE} from "../components/GDGButton";

require('../homepage.scss')

export default function CustomerManagement() {
  window.scrollTo(0, 0)
  const navigationBar = useRef();

  const demoRequest = () => {
    if(navigationBar && navigationBar.current)
      navigationBar.current.handleRequestDemo()
  }

  return (
    <div id='gdg-home-page' className="customer-management-page">
      <NavigationBar ref={navigationBar}/>
      <IntroBannerSection
        blueText={'CUSTOMER MANAGEMENT'}
        title={"Many customers, one place"}
        description={"Keep all customer contact information in one place, start using our " +
          "platform with simple .csv file import"}
        imgUrl={pathServer.PATH_IMG + '/newHomePage/customer-management.png'}
      />

      <SplitSection
        className={'finance-application after-intro-section-padding background-light-blue odd'}
        leftSection={
          <div>
            <p className={'title-font'} dangerouslySetInnerHTML={{
              __html: 'Finance  </br>' +
                ' Application </br>'
            }}/>
            <p className={'regular-font'} dangerouslySetInnerHTML={{
              __html: 'Add new customers with a single click when they apply for ' +
                'financing, import all customer information automatically ' +
                'to avoid double entry'
            }}/>
            <p className={'sub-title-font'}>
              Turn applicants into customers
            </p>
            <p className={'regular-font'} dangerouslySetInnerHTML={{
              __html: 'Add new customers seamlessly when they apply for ' +
                'financing on your won GoDealerGo website.'
            }}/>
            <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
          </div>
        }
        rightSection={
          <div>
            <img alt={'img'} src={pathServer.PATH_IMG + 'newHomePage/finance-application.png'}/>
          </div>
        }

      />

      <SplitSection
        className={'message-application after-intro-section-padding background-light-blue'}
        leftSection={
          <div>
            <p className={'title-font'} dangerouslySetInnerHTML={{
              __html: 'Messaging  </br>' +
                ' Platform </br>'
            }}/>
            <p className={'regular-font'} dangerouslySetInnerHTML={{
              __html: 'Manage your messages within your own GoDealerGo inbox ' +
                'and provide continuity even when team members are out ' +
                'of the office.'
            }}/>
            <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
          </div>
        }
        rightSection={
          <div>
            <img alt={'img'} src={pathServer.PATH_IMG + 'newHomePage/messaging-platform.png'}/>
          </div>
        }

      />

      <FooterSection/>
    </div>
  )
}
