import React, {Component} from 'react'
import {
  APPLICANTS_ACTIONS,
  colors,
  financeStatus,
  financeStatusValue,
  INDIVIDUAL_CREDIT_NAME,
  JOIN_CREDIT_NAME
} from '../../Constants'
import {Popover, WarningMessage} from '../util/ComponentUtils'
import {loadFinancesApi, updateStateFinances} from '../../Api'
import Moment from 'moment'
import {ReactSVG} from 'react-svg'
import {TitleHeader} from './SaleEdit.jsx'
import {Modal} from 'react-bootstrap'
import {ContentUser} from './SalePurchaser.jsx'
import {isEmpty} from 'lodash'
import {browserHistory} from 'react-router'
import {CosignerChild} from './Sales'
import DragDrop from '../dragdrop/DragDrop'
import DPDroppable from '../dragdrop/Droppable'
import DPDraggable from '../dragdrop/Draggable'
import {getPosition} from '../../../common/Util'
import {convertListToString, getAnchorEl} from '../util/Utils'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'

const Applicant = ({user, showInfo, notShowIcon, index, handleOrderPurchaser, readOnly}) => {
  const applicationTypeStyle = {
    fontSize: '9px',
    marginBottom: 0,
    backgroundColor: '#ffffff',
    border: 'none',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
  }

  const mainApplicantStyle = {
    ...applicationTypeStyle,
    color: '#4ad991',
  }
  const coSignerStyle = {
    ...applicationTypeStyle,
    color: '#2194F1',
  }
  const applicationTypeTitle = user.principal
    ? 'Main applicant' : 'Co-signer'
  const borderStyle = {
    border: '1px dashed #D7DAE2',
    padding: '4px',
    borderRadius: '5px',
  }

  return (
    <div>
      <div
        style={user.principal ? mainApplicantStyle : coSignerStyle}
      >
        {applicationTypeTitle}
      </div>
      <div style={borderStyle}>
        <ContentUser
          user={user}
          notShowIcon={notShowIcon}
          showInfo={showInfo}
          readOnly={true}
        />
      </div>
    </div>
  )
}

const BodyOptions = ({options, value, onClickPopover,name}) => {
  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {
            options.map((option, index) => {
              const className = (option.value === value || option.id === value) ? "item-selected" : "change-submenu-item";
              const optionValue = option.value ? option.value : option.displayValue ? option.displayValue : "";
              return (
                <li key={index} className={className} onClick={() => onClickPopover(option.id)} data-test={`popover-item-${index}`}>
                  {optionValue}
                </li>
              )
            })
          }
        </u>
      </div>
    </div>
  )
};

export function setColorStatus(status) {
  let color;
  if (status) {
    switch (status) {
      case financeStatusValue[financeStatus.PROCESSED] :
        color = colors.BLUE;
        break;
      case financeStatusValue[financeStatus.DELETED] :
        color = colors.RED;
        break;
      case financeStatusValue[financeStatus.IN_PROGRESS]:
        color = colors.LIGHT_BLUE;
        break;
      case financeStatusValue[financeStatus.NEW]:
        color = colors.GRAY;
        break;
      case financeStatusValue[financeStatus.REVIEW]:
        color = colors.ORANGE;
        break;
      case financeStatusValue[financeStatus.BAD]:
        color = colors.RED;
        break;
      case financeStatusValue[financeStatus.APPROVED]:
        color = colors.GREEN;
        break;
      default:
        color = "#b8bdc2"
    }
  }
  return color
}

const BodyIndividualCredit = ({customersSale, viewFinance}) => {
  return (
    <div className="container-purchasers">
      {
        customersSale.length > 0
          ? customersSale.map((customer,index) => {
            return(
              <div key={`purchaser-${index}`} className="container-content-user">
                <ContentUser user={customer} notShowIcon={true} showInfo={viewFinance}/>
              </div>
            )
          })
          : null
      }
    </div>
  )
}

const BodyJoinCredit = ({customersSale, viewFinance, onDragEnd}) => {
  const columnId = 'bodyJoinCredit'
  const draggableStyle = {
    padding: '2px',
    backgroundColor: 'white',
    userSelect: 'none',
    width: '33.3%',
  }
  return (
    <DragDrop
      onDragEnd={(result) => onDragEnd(result, columnId)}
      sections={[]}
      reorder={() => {}}
      move={() => {}}
      key={columnId}
    >
      <div className="container-purchasers">
        <DPDroppable
          droppableId={'mainApplicantId'}
          className='droppable-section'
          droppableStyle={null}
          draggingOverStyle={null}
        >
          {
            customersSale.filter(el => el.principal).map((customer, index) => {
              return (
                <DPDraggable
                  key={`customer-${index}`}
                  itemId={`customer-${index}`}
                  index={index}
                  otherDivProps={null}
                  draggableStyle={draggableStyle}
                >
                  <div
                    key={`main-applicant-${index}`}
                    className="container-content-applicant"
                  >
                    <Applicant
                      user={customer}
                      notShowIcon={true}
                      showInfo={viewFinance}
                    />
                  </div>
                </DPDraggable>
                )
              })
          }
        </DPDroppable>
        <div className="line-separator-container"/>
        <DPDroppable
          droppableId={'cosignerId'}
          className={'droppable-section'}
          droppableStyle={null}
          draggingOverStyle={null}
        >
          {
            customersSale.filter(el => !el.principal).map((customer, index) => {
              return (
                <DPDraggable
                  key={`customer-${index}`}
                  itemId={`customer-${index}`}
                  index={index}
                  otherDivProps={null}
                  draggableStyle={draggableStyle}
                >
                  <div
                    key={`co-applicant-${index}`}
                    className="container-content-applicant"
                  >
                    <Applicant
                      user={customer}
                      notShowIcon={true}
                      showInfo={viewFinance}
                    />
                  </div>
                </DPDraggable>
              )
            })
          }
        </DPDroppable>
      </div>
    </DragDrop>
  )
}

const ModalAddFinance = ({show, onHide, customersSale, viewFinance, selectApplicationType, selectedApplicationType, onDragEnd, onContinueJoinCredit}) => {
  const titleMessage = selectedApplicationType === INDIVIDUAL_CREDIT_NAME
    ? 'Select an applicant to continue'
    : 'Drag and drop the customers to assign a role'
  return(
    <Modal show={show} className="modal-add-finance">
      <Modal.Body className="modal-body">
        <div className="container-body-purchaser">
          <img src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon" onClick={onHide}/>
          <div className="container-title">
            <span className="title">{titleMessage}</span>
            <button
              className={selectedApplicationType === JOIN_CREDIT_NAME ? 'application-type-selected' : 'application-type-unselected'}
              onClick={() => selectApplicationType(JOIN_CREDIT_NAME)}
              disabled={false}
            >
              Join Credit
            </button>
            <button
              className={selectedApplicationType === INDIVIDUAL_CREDIT_NAME ? 'application-type-selected' : 'application-type-unselected'}
              onClick={() => selectApplicationType(INDIVIDUAL_CREDIT_NAME)}
              disabled={false}
            >
              Individual Credit
            </button>
          </div>
          <div className="line-separator-container"/>
          {
            selectedApplicationType === INDIVIDUAL_CREDIT_NAME
              ? <BodyIndividualCredit
                customersSale={customersSale}
                viewFinance={viewFinance}
              />
              : <React.Fragment>
                <BodyJoinCredit
                  customersSale={customersSale}
                  viewFinance={viewFinance}
                  onDragEnd={(result, columnId) => onDragEnd(result, columnId)}
                />
                <div className="line-separator-container"/>
                <div className="container-footer">
                  <button
                    className='btn-continue'
                    onClick={() => onContinueJoinCredit()}
                  >
                    Continue
                  </button>
                </div>
              </React.Fragment>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
};

export function getDisplayValue(status) {
  let name = "";
  APPLICANTS_ACTIONS.forEach(applicant => {
    if(applicant.id === status || applicant.value === status){
      name = applicant.value
    }
  });

  return name
}

function getListCustomerIds(customers){
  let listId = [];
  if(customers.length > 0)
    customers.forEach( customer => { listId.push(customer.id) });
  return listId;
}

export default class SaleFinances extends Component {
  constructor() {
    super();
    this.state = {
      purchaserFinances: [],
      isPopoverActive: false,
      popoverSelected: null,
      financeSelected: null,
      isModalAddFinanceActive: false,
      isLoading: false,
      selectedApplicationType: INDIVIDUAL_CREDIT_NAME,
      customersSale: [],
    };
  }

  componentWillMount() {
    const {customersSale} = this.props;
    this.loadFinances(customersSale);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {customersSale} = this.props;
    if(customersSale !== nextProps.customersSale){
      const {customersSale} = nextProps;
      this.loadFinances(customersSale)
    }
  }

  loadFinances(customersSale){
    const listIds = getListCustomerIds(customersSale);
    const customerIds = convertListToString(listIds);
    if(customersSale.length > 0){
      this.setState({isLoading:true});
      if(!isEmpty(customerIds)){
        loadFinancesApi(customerIds).then(({responseCode, responseMessage, data}) =>{
          if (responseCode === SUCCESSFUL_CODE) {
            const {finances} = data;
            this.setState({purchaserFinances: finances,isLoading:false, customersSale});
          } else {
            console.error(responseMessage);
            this.setState({isLoading: false})
          }
        })
      }
    }else{
      this.setState({purchaserFinances: [], customersSale: []})
    }
  }

  showPopover = (finance, event) => {
    event.stopPropagation();
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target, 2);
    this.setState({anchorEl: anchorEl, isPopoverActive: true, popoverSelected: target.id, financeSelected: finance})
  }

  closePopover = () => {
    this.setState({isPopoverActive: false, popoverSelected: null, financeSelected: null});
  }

  onClickPopover = (status) => {
    let {financeSelected} = this.state;
    let financesUpdate = [];
    financeSelected.status = status;
    financesUpdate.push(financeSelected);
    this.setState({isLoading: true})
    updateStateFinances({updateFinances: financesUpdate}).then(({ responseCode }) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({isPopoverActive: false, isLoading: false})
      }
    });
  }

  showModalAddFinance = () => {
    this.setState({isModalAddFinanceActive: true}, () => {
      this.props.hideBar();
    })
  }

  closeModal = () => {
    this.setState({isModalAddFinanceActive: false});
  }

  viewFinance = (customer) => {
    const id = financeStatus.NEW;
    const {saleId, showFinanceView} = this.props;
    browserHistory.push({
      pathname: `/main/sale/${saleId}/finance/${id}`,
      state: {
        customer: customer,
        loadFinanceApp: (finance) => this.goToFinanceView(finance),
        isNewFinanceApplication: id === financeStatus.NEW,
      }
    });
    this.closeModal();
    showFinanceView(`New Finance Application`);
  }

  goToFinanceView(finance){
    const {saleId, showFinanceView} = this.props;
    const {applicant, id} = finance;
    browserHistory.push({
      pathname: `/main/sale/${saleId}/finance/${id}`,
      state: {
        applicant: applicant,
        loadFinanceApp: (finance) => this.getMessage(finance),
        isNewFinanceApplication: id === financeStatus.NEW,
      }
    });
    const message = `${applicant} finance application`;
    showFinanceView(message);
  }

  getMessage(finance){
    const {showFinanceView} = this.props;
    const {applicant} = finance;
    const message = `${applicant} finance application`;
    showFinanceView(message);
  }

  handleShowCosigner = (cosigners, evt) => {
    evt.stopPropagation();
    let pointTo = getPosition(evt.currentTarget);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 38,
      offsetWidth: 216
    };
    this.setState({showPopoverCosigner: true, selectedCosigners: cosigners, anchorEl:anchorEl})
  }

  handleRequestClose = () => {
    this.setState({showPopoverCosigner:false, selectedCosigners:null})
  }

  selectApplicationType(selectedApplicationType) {
    this.setState({ selectedApplicationType })
  }

  handleDragEnd(result, columnId) {
    let newCustomersSale = [...this.state.customersSale]

    if (result.destination.droppableId === result.source.droppableId) {
      return
    }

    if (result.destination.droppableId === 'mainApplicantId' &&
      result.source.droppableId === 'cosignerId') {
      let newSource = {...newCustomersSale[result.source.index + 1]}
      newSource.principal = true
      let newDestination = {...newCustomersSale[result.destination.index]}
      newDestination.principal = false

      newCustomersSale.splice(0, 1, newSource)
      newCustomersSale.splice(result.source.index + 1, 1, newDestination)
    }

    if (result.destination.droppableId === 'cosignerId' &&
      result.source.droppableId === 'mainApplicantId') {
      let newSource = {...newCustomersSale[result.destination.index + 1]}
      newSource.principal = true
      let newDestination = {...newCustomersSale[result.source.index]}
      newDestination.principal = false

      newCustomersSale.splice(0, 1, newSource)
      newCustomersSale.splice(result.source.index + 1, 1, newDestination)
    }

    this.setState({
      customersSale: newCustomersSale,
    })
  }

  handleContinueJoinCredit() {
    const { customersSale } = this.state
    const id = financeStatus.NEW;
    const {saleId, showFinanceView} = this.props;
    browserHistory.push({
      pathname: `/main/sale/${saleId}/finance/${id}`,
      state: {
        customer: customersSale,
        loadFinanceApp: (finance) => this.goToFinanceView(finance),
        isNewFinanceApplication: id === financeStatus.NEW,
      }
    });
    this.closeModal();
    showFinanceView(`New Finance Application`);
  }

  render(){
    const {isPopoverActive, popoverSelected, anchorEl,financeSelected,purchaserFinances,isLoading,
      isModalAddFinanceActive,showPopoverCosigner,selectedCosigners, customersSale} = this.state;
    const {readOnly} = this.props;

    return(
      <div className="panel-financing">
        <TitleHeader classTitle="title-payment" classIcon="icon-check" classIconSvg="icon-check-svg" classIconSvgCompleted="icon-check-svg-completed"
                     icon="icon/ic_financing.svg" selectOption={false} title="Financing"
                     rightElement={
                       <div className="right-element">
                         {
                           (customersSale.length === 0) ?
                             <div id="containerIconAddDisabled" className="container-icon-disabled">
                               {
                                 !readOnly && (
                                   <ReactSVG id="iconAddSVGDisabled" src={`${pathServer.PATH_IMG}icon/ic_add.svg`} beforeInjection={ svg => svg.classList.add('icon-add-svg-disabled')}
                                             className="icon-add-trade_in"/>
                                 )
                               }
                               <div className="title-suggestion" id='titleSuggestion'>
                                 <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"}
                                           beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                                           className="icon-error"/>
                                 <span>Select a purchaser first</span>
                               </div>
                             </div> :
                             !readOnly && (
                               <div id="containerIconAdd" className="container-icon">
                                 <ReactSVG id="iconAddSVG" src={`${pathServer.PATH_IMG}icon/ic_add.svg`} beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                                           className="icon-add-trade_in" onClick={this.showModalAddFinance}/>
                               </div>
                             )
                         }
                       </div>
                     }/>
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> :
            <div className="body-payment">
              {
                customersSale.length > 0 ?
                  <div className="container-financing">
                    {
                      purchaserFinances.length > 0 ?
                        <div className="container-finances">
                          <div className="header-finances">
                            <span className="title-1">Application name</span>
                            <span className="title-2">Last Update</span>
                            <span className="title-3">Status</span>
                          </div>
                          <div className="body-purchaser-finances">
                            {
                              purchaserFinances.map((finance,index) => {
                                const {applicant, modifiedDate, status, cosigners} = finance;
                                const displayValue = getDisplayValue(status)
                                return (
                                  <div key={`finance-${index}`}
                                       className={(index % 2 === 0 ? "row-finance" : "row-finance-color") + (readOnly ? " row-readonly" : "")}
                                       onClick={(evt) => !readOnly ? this.goToFinanceView(finance,evt) : null}>
                                    <div className={'title-1-content'}>
                                      <span className="title-1">
                                        {applicant}
                                      </span>
                                      {
                                        cosigners.length > 0 ?
                                          <span id={`cosigner${index}`} className={'title-number-cosigner'}
                                                onClick={(evt) => this.handleShowCosigner(cosigners,evt)}>
                                                {`(${cosigners.length})`}
                                            </span> : null
                                      }
                                    </div>
                                    <span className="title-2">{Moment(modifiedDate).format('MM/DD/YYYY HH:mm')}</span>
                                    <span className="title-3">
                                      <div className="select" id={`status-${index}`}
                                           onClick={(evt) => !readOnly ? this.showPopover(finance, evt) : null}>
                                        <div className="container-label">
                                          <span className="label-select" style={{color: setColorStatus(displayValue)}}>{displayValue}</span>
                                        </div>
                                        {
                                          !readOnly && (
                                            isPopoverActive && popoverSelected === `status-${index}` ?
                                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                                        className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                                        className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                                          )
                                        }
                                      </div>
                                    </span>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div> :
                        <div className="content-warning">
                          <WarningMessage message={"There are no finance applications related to purchaser(s)"}/>
                        </div>
                    }
                  </div> :
                  <div className="container-not-purchaser">
                    <div className="container-icon-text">
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/getting_finance.svg`}
                                beforeInjection={ svg => svg.classList.add('icon-getting-finance-svg')}
                                className="icon-getting-finance"/>
                      <span>Add a purchaser first to see his/her finance applications.</span>
                    </div>
                  </div>
              }
            </div>
        }
        {
          isPopoverActive ?
            <Popover anchorEl={anchorEl}
                     child={<BodyOptions options={APPLICANTS_ACTIONS} value={financeSelected.status} onClickPopover={this.onClickPopover} />}
                     classPopover="popover-box-message"
                     handleRequestClose={this.closePopover}/> : null
        }
        {
          isModalAddFinanceActive
            ? <ModalAddFinance
              show={isModalAddFinanceActive}
              onHide={this.closeModal}
              customersSale={customersSale}
              viewFinance={this.viewFinance}
              selectApplicationType={(applicationType) => this.selectApplicationType(applicationType)}
              selectedApplicationType={this.state.selectedApplicationType}
              onDragEnd={(result, columnId) => this.handleDragEnd(result, columnId)}
              onContinueJoinCredit={this.handleContinueJoinCredit}
            />
            : null
        }
        { showPopoverCosigner && selectedCosigners !== null ?
          <Popover anchorEl={anchorEl}
                   child={<CosignerChild cosigners={selectedCosigners} />}
                   classPopover="popover-box-message"
                   handleRequestClose={this.handleRequestClose}
                   width="auto"/> : null
        }
      </div>
    )
  }

}