import React, {useState, useEffect} from 'react'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'
import {ReactSVG} from 'react-svg'
import {DPInput} from '../../../common/DPInput'
import DPButton from '../../../common/DPButton'
import DPDroppable from '../../dragdrop/Droppable.jsx'
import DragDrop from '../../dragdrop/DragDrop.jsx'
import DPDraggable from '../../dragdrop/Draggable.jsx'
import ReactDOM from 'react-dom'
import {loadPackages, savePackage} from '../../../Api'
import sortBy from 'lodash/sortBy'

require('./plans.scss')

const SECTION_IDS = {
  INCLUDED_FEATURES: 'included-features-id',
  AVAILABLE_FEATURES: 'available-features-id',
}

const ArrowsSection = (props) => {
  return (
    <div className="icon-arrow-container">
      <ReactSVG
        src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
        className="icon-arrow"
        beforeInjection={svg => svg.classList.add('icon-arrow-svg')}
      />
      <ReactSVG
        src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
        className="icon-arrow"
        beforeInjection={svg => svg.classList.add('icon-arrow-svg')}
      />
    </div>
  )
}

const CustomizedFeatureItem = (props) => {
  const {index, element} = props
  const otherDivProps = {}
  const draggableStyle = {
    userSelect: 'none',
    backgroundColor: 'white',
  }

  return (
    <DPDraggable
      itemId={element.featureId || element.id}
      index={index}
      otherDivProps={otherDivProps}
      draggableStyle={draggableStyle}
    >
      <div className='feature-item-container'>
        <ArrowsSection/>
        <div className="feature-item-text">{element.name}</div>
      </div>
    </DPDraggable>
  )
}

const PlanSection = (props) => {
  const {packageInfo, availableFeatures, loadData} = props
  const planId = packageInfo && packageInfo.inventoryItemId
  const [editMode, setEditMode] = useState(false)
  const [includedFeatureList, setIncludedFeatureList] = useState(packageInfo && packageInfo.includedFeatures)
  const [availableFeatureList, setAvailableFeatureList] = useState(availableFeatures)
  const [packageName, setPackageName] = useState(packageInfo && packageInfo.name)
  const [packageUnitPrice, setPackageUnitPrice] = useState(packageInfo && packageInfo.unitPrice.toString())
  const [packageAnnualPrice, setPackageAnnualPrice] = useState(packageInfo && packageInfo.annualPrice.toString())
  const [packageTeamMembers, setPackageTeamMembers] = useState(packageInfo && packageInfo.teamMembersQuantity.toString())
  const [packageListings, setPackageListings] = useState(packageInfo && packageInfo.listingsQuantity.toString())

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      setEditMode(false)
      setIncludedFeatureList(packageInfo && packageInfo.includedFeatures)
      setAvailableFeatureList(availableFeatures)
      setPackageName(packageInfo && packageInfo.name)
      setPackageUnitPrice(packageInfo && packageInfo.unitPrice.toString())
      setPackageAnnualPrice(packageInfo && packageInfo.annualPrice.toString())
      setPackageTeamMembers(packageInfo && packageInfo.teamMembersQuantity.toString())
      setPackageListings(packageInfo && packageInfo.listingsQuantity.toString())
    })
  }, [])

  const onDragEnd = (result, columnId) => {
    const {source, destination} = result

    if (source.droppableId === `${planId}-${SECTION_IDS.INCLUDED_FEATURES}`
      && destination.droppableId === `${planId}-${SECTION_IDS.AVAILABLE_FEATURES}`) {
      const featureToMove = includedFeatureList[source.index]
      let sourceListToBeUpdated = [...includedFeatureList]
      let destinationListToBeUpdated = [...availableFeatureList]

      sourceListToBeUpdated.splice(source.index, 1)
      destinationListToBeUpdated.splice(destinationListToBeUpdated.length, 0, featureToMove)

      ReactDOM.unstable_batchedUpdates(() => {
        setIncludedFeatureList(sourceListToBeUpdated)
        setAvailableFeatureList(destinationListToBeUpdated)
      })
    }

    if (source.droppableId === `${planId}-${SECTION_IDS.AVAILABLE_FEATURES}`
      && destination.droppableId === `${planId}-${SECTION_IDS.INCLUDED_FEATURES}`) {
      const featureToMove = availableFeatureList[source.index]
      let sourceListToBeUpdated = [...availableFeatureList]
      let destinationListToBeUpdated = [...includedFeatureList]

      sourceListToBeUpdated.splice(source.index, 1)
      destinationListToBeUpdated.splice(destinationListToBeUpdated.length, 0, featureToMove)

      ReactDOM.unstable_batchedUpdates(() => {
        setIncludedFeatureList(destinationListToBeUpdated)
        setAvailableFeatureList(sourceListToBeUpdated)
      })
    }
  }

  const getFormattedQuantity = (quantity) => {
    let formattedQuantity = quantity.toString()
    if (quantity === -1) {
      formattedQuantity = 'Unlimited'
    }

    return formattedQuantity
  }

  const handleCancel = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setEditMode(false)
      setIncludedFeatureList(packageInfo && packageInfo.includedFeatures)
      setAvailableFeatureList(availableFeatures)
      setPackageName(packageInfo && packageInfo.name)
      setPackageUnitPrice(packageInfo && packageInfo.unitPrice.toString())
      setPackageAnnualPrice(packageInfo && packageInfo.annualPrice.toString())
      setPackageTeamMembers(packageInfo && packageInfo.teamMembersQuantity.toString())
      setPackageListings(packageInfo && packageInfo.listingsQuantity.toString())
    })
  }

  const handleSave = async () => {
    const featureIds = includedFeatureList.map(el => el.featureId || el.id)
    const packageInfoToBeUpdated = {
      inventoryItemId: planId,
      name: packageName,
      price: parseFloat(packageUnitPrice),
      annualPrice: parseFloat(packageAnnualPrice),
      teamMembersQuantity: parseInt(packageTeamMembers),
      listingsQuantity: parseInt(packageListings),
      featureIds
    }

    const {responseCode, responseMessage} = await savePackage(packageInfoToBeUpdated)
    if (responseCode === SUCCESSFUL_CODE) {
      await loadData()
      ReactDOM.unstable_batchedUpdates(() => {
        setEditMode(false)
      })
      return
    }

    console.error(responseMessage)
  }

  return (
    <div className="panel-plans">
      {
        !editMode &&
        <React.Fragment>
          <div className="header-plan-section">
            <div className="header-text">{packageInfo && packageInfo.name}</div>
            <ReactSVG
              src={pathServer.PATH_IMG + 'icon/ic_edit-pencil.svg'}
              beforeInjection={svg => svg.classList.add('edit-icon')}
              className="edit-icon"
              onClick={() => setEditMode(true)}
            />
          </div>
          <div className="price-section">{`$${packageInfo && packageInfo.unitPrice} per month / $${packageInfo && packageInfo.annualPrice} per year`}</div>
          <div className="features-title-section">Features included</div>
          <div className="feature-item">{`${getFormattedQuantity(packageInfo && packageInfo.teamMembersQuantity)} Team members`}</div>
          <div className="feature-item">{`${getFormattedQuantity(packageInfo && packageInfo.listingsQuantity)} Listings`}</div>

          {
            packageInfo.includedFeatures
            && sortBy(packageInfo.includedFeatures, ['position']).map(el => {
              return (
                <div
                  key={el.featureId}
                  className="feature-item"
                >
                  {el.name}
                </div>
              )
            })
          }
        </React.Fragment>
      }

      {
        editMode &&
        <div className='edit-mode'>
          <div className='detailer-header-plan-section'>
            <DPInput
              className="plan-name-input"
              title="Plan name"
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
            />
            <div style={{display: 'flex'}}>
              <DPInput
                className="monthly-cost-input"
                title="Monthly cost"
                value={packageUnitPrice}
                onChange={(e) => setPackageUnitPrice(e.target.value)}
              />
              <DPInput
                className="annual-cost-input"
                title="Annual cost"
                value={packageAnnualPrice}
                onChange={(e) => setPackageAnnualPrice(e.target.value)}
              />
            </div>
          </div>

          <DragDrop
            onDragEnd={(result) => onDragEnd(result, planId)}
            sections={[]}
            reorder={() => {}}
            move={() => {}}
            key={planId}
          >
          <div className='included-features'>
            <DPDroppable
              droppableId={`${planId}-${SECTION_IDS.INCLUDED_FEATURES}`}
              droppableStyle={null}
              draggingOverStyle={null}
            >
              <div className='header-section-title'>Features included</div>
              <div className='header-section-sub-title'>Type “-1” for unlimited options</div>
              <div className='specific-feature-section'>
                <ArrowsSection/>
                <div className='specific-feature-title'>Team members</div>
                <DPInput
                  className="team-members-input"
                  value={packageTeamMembers}
                  onChange={(e) => setPackageTeamMembers(e.target.value)}
                />
              </div>
              <div className='specific-feature-section'>
                <ArrowsSection/>
                <div className='specific-feature-title'>Listings</div>
                <DPInput
                  className="listings-input"
                  value={packageListings}
                  onChange={(e) => setPackageListings(e.target.value)}
                />
              </div>
              {
                includedFeatureList.map((feature, index) => {
                  return (
                    <CustomizedFeatureItem
                      key={feature.featureId}
                      index={index}
                      element={feature}
                    />
                  )
                })
              }
            </DPDroppable>
          </div>

          <div className='available-features'>
            <DPDroppable
              droppableId={`${planId}-${SECTION_IDS.AVAILABLE_FEATURES}`}
              droppableStyle={null}
              draggingOverStyle={null}
            >
              <div className='header-section-title'>Available features</div>
              <div className='header-section-sub-title'>Drag and drop on top to add features to this plan</div>
              {
                availableFeatureList.map((feature, index) => {
                  return (
                    <CustomizedFeatureItem
                      key={feature.id}
                      index={index}
                      element={feature}
                    />
                  )
                })
              }
            </DPDroppable>
          </div>
          </DragDrop>

          <div className='footer-section'>
            <div
              className='cancel-button'
              onClick={handleCancel}
            >
              Cancel
            </div>
            <DPButton
              className="save-button"
              onClick={handleSave}
            >
              Save
            </DPButton>
          </div>
        </div>
      }
    </div>
  )
}

const PlansAndPackages = (props) => {
  const {height, left} = props;
  const [packageList, setPackageList] = useState([])
  const [featureList, setFeatureList] = useState([])

  const loadData = async () => {
    const {responseCode, responseMessage, data: packages} = await loadPackages()
    if (responseCode === SUCCESSFUL_CODE) {

      ReactDOM.unstable_batchedUpdates(() => {
        setPackageList(packages.packages)
        setFeatureList(packages.features)
      })
    } else {
      console.error(responseMessage)
    }
  }

  useEffect(() => {
    loadData().then()
  }, [])

  const getAvailableFeatures = (features, includedFeatures) => {
    let availableFeatures = []
    features.forEach(element => {
      const foundFeature = includedFeatures.find(el => el.featureId === element.id)

      if (!foundFeature) {
        availableFeatures.push(element)
      }
    })

    return availableFeatures
  }

  return (
    <div className="plans-content" style={{height:height-30}}>
      <div className='container-header' style={{left:left}}>
        <div className="left">
          <label htmlFor="">Plans and packages</label>
        </div>
      </div>
      <div className="plans-container-center" style={{minHeight:height-30}}>
        {
          packageList
          && packageList.map(el => {
            const availableFeatures = getAvailableFeatures(featureList, el.includedFeatures)
            return (
              <PlanSection
                key={el.inventoryItemId}
                packageInfo={el}
                availableFeatures={availableFeatures}
                loadData={loadData}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default PlansAndPackages