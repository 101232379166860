import GDGButton, {BUTTON_TYPE} from "./../GDGButton";
import {pathServer} from "../../../../../common/Constants";
import React, {useState} from 'react'
import DPVideoModal from '../../../../../common/DPVideoModal'
import {Icons} from "../../../../common/DPIcon";

require("./introBannerSection.scss")

const IntroBannerSection = ({
                              blueText = '', title = '', description = '',
                              showVideo = false, imgUrl = ''
                            }) => {
  const [showVideoModal, setShowVideoModal] = useState(false)

  return (
    <div  className = 'section-center-aligned intro-banner'>
      <p className={'small-blue-font'}>{blueText}</p>
      <p className={'title-font'}>{title}</p>
      <p className={'regular-font'} dangerouslySetInnerHTML={{__html: description}}/>
      {
        showVideo &&
        <>
          <GDGButton
            type={BUTTON_TYPE.BLUE}
            text={'Watch demo'}
            iconType={Icons.PLAY_VIDEO}
            hoverText={'Watch now'}
            hoverIconType={Icons.ARROW_NEXT}
            style={{marginTop: '20px'}}
            onClick={() => setShowVideoModal(true)}
          />
          {
            showVideoModal &&
            <DPVideoModal
              videoTagId={'GoDealerGoVideo'}
              showVideoModal={showVideoModal}
              closeModal={() => setShowVideoModal(false)}
            />
          }
        </>
      }
      {
        imgUrl &&
        <img
          className="section-image"
          src={imgUrl}
          alt="section-image"
        />
      }
    </div>
  )
}

export default IntroBannerSection
