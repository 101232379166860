import React, {useState, useEffect} from 'react'
import {getDealerStateTax, insertOrUpdateDealerStateTax, loadLocationsApi} from '../../../Api'
import {
  fromArrayOfObjectsToObject,
  fromObjectToArrayOfObjects,
  getOptionsSelectDropdown,
  isEmptyObject
} from '../../util/Utils'
import DPSelectionDropDown from '../../../common/DPSelectionDropDown'
import {DPSpinner} from '../../../common/DPSpinner'
import {ReactSVG} from 'react-svg'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'

const InputWithSymbol = ({value, onChange}) => {
  return(
      <div className="container-input">
        <input type="text" className="form-control" placeholder="0.00" value={value}
               aria-describedby="sizing-addon2" onChange={(e) => onChange(e)}/>
        <span className="percentage" id="sizing-addon2">%</span>
      </div>
  )
}

function LocationTax({key, locations, locationSetup,orderId, handleOnchangeLocationValue, selectedLocationIds, setSelectedLocationIds, removeLocationsSetUp, onBlur}){
  const options = getOptionsSelectDropdown(locations, "locationId", "name")
  const [selectedLocationId, setSelectedLocationId] = useState(Object.keys(locationSetup)[0])
  const [taxValue, setTaxValue] = useState(locationSetup[selectedLocationId])

  function handleOnSetSelectedLocationId(id){
    setSelectedLocationId(id)
    setSelectedLocationIds([...selectedLocationIds, id])
    handleOnchangeLocationValue({[id]:taxValue}, orderId)
  }

  function handleOnchangeValue(e){
    e.preventDefault()
    const value = e.target.value.match(/\d{0,2}(\.\d{0,2})?/)[0]
    setTaxValue(value)

    if(!!selectedLocationId){
      handleOnchangeLocationValue({[selectedLocationId]:value}, orderId)
    }
  }

  return(
    <div id={`location-tax-setting-${selectedLocationId}`} className="location-tax-setting">
      <DPSelectionDropDown
        style={{width:'calc(100% - 120px)', float:'left'}}
        selectStyle={{borderRadius: '5px'}}
        className='state-tax-select-location'
        //label={name}
        id={`location-tax-modal-${selectedLocationId}`}
        options={options}
        value={selectedLocationId}
        onClick={(id) => handleOnSetSelectedLocationId(id)}
        errorMessage={null}
        disabled={false}
      />
      <span className="container-delete-icon" id="sizing-addon2" style={{cursor:"pointer"}}
            onClick={() => removeLocationsSetUp(orderId, selectedLocationId)}>
          <ReactSVG src={pathServer.PATH_IMG + 'icon/waste-can.svg'}
                    beforeInjection={svg => svg.classList.add('delete-option')}
                    onClick={null}/>
      </span>
      <InputWithSymbol value={taxValue ? taxValue:""} onChange={handleOnchangeValue}/>
    </div>
  )
}

function StateTaxSetting(){
  const [dealerStateTax, setDealerStateTax] = useState({})
  const [locations, setLocations] = useState([])
  const [stateTax, setStateTax] = useState(null)
  const [locationsSetUp, setLocationsSetup] = useState([])
  const [selectedLocationIds, setSelectedLocationIds] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasChange, setHasChange] = useState(false)

  function fetchLocations(){
    setIsLoading(true)
    loadLocationsApi().then(({responseCode, data}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        setIsLoading(false)
        setLocations(data.locations)
      } else {
        setIsLoading(false)
      }
    })
  }

  function setStateTaxLocations(stateTaxDealerConfigValueJSON){
    const stateTaxDealerConfigValue = JSON.parse(stateTaxDealerConfigValueJSON.replace(/\\/g, ''))

    if(stateTaxDealerConfigValue && ("locations" in stateTaxDealerConfigValue) && ("stateTax" in stateTaxDealerConfigValue)){
      setStateTax(stateTaxDealerConfigValue.stateTax)
      setLocationsSetup(fromObjectToArrayOfObjects(stateTaxDealerConfigValue.locations))
      setSelectedLocationIds(Object.keys(stateTaxDealerConfigValue.locations))
    }
  }

  const fetchDealerStateTax = () => {
    setIsLoading(true)
    getDealerStateTax().then((response) => {
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {stateTaxDealerConfig} = data
        setIsLoading(false)
        if(stateTaxDealerConfig && !isEmptyObject(stateTaxDealerConfig)){
          setDealerStateTax(stateTaxDealerConfig)
          setStateTaxLocations(stateTaxDealerConfig.value)
        }
      }else{
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    fetchDealerStateTax()
    fetchLocations()
  },[])

  function handleOnchangeStateTax(e){
    e.preventDefault()
    setStateTax(e.target.value.match(/\d{0,2}(\.\d{0,2})?/)[0])
    if(!hasChange)
      setHasChange(true)
  }

  function checkIfLocationTaxHasValueAndNotEmpty(locationTax){
    return !isEmptyObject(locationTax) && Object.values(locationTax)[0] !== ""
  }

  function filteredLocationTaxes(locationsSetUp){
    return !!locationsSetUp ? locationsSetUp.filter(locationTax => checkIfLocationTaxHasValueAndNotEmpty(locationTax)) : []
  }

  function handleSave(){
    if(!!stateTax){
      const {id} = {...dealerStateTax}
      const locations = filteredLocationTaxes(locationsSetUp)
      setLocationsSetup(locations)
      const dealerStateTaxes = JSON.stringify({stateTax:stateTax,locations:fromArrayOfObjectsToObject(locations)})
      const stateTaxConfiguration = {id:id,value:dealerStateTaxes.replace(/\\/g, '')}
      setIsLoading(true)
      insertOrUpdateDealerStateTax(stateTaxConfiguration).then((response)=> {
        const {responseCode, data} = response
        if (responseCode === SUCCESSFUL_CODE){
          const {stateTaxDealerConfig} = data
          if(!isEmptyObject(stateTaxDealerConfig || {})) {
            setDealerStateTax(stateTaxDealerConfig)
            setStateTaxLocations(stateTaxDealerConfig.value)
          }
          setHasChange(false)
          setIsLoading(false)
        }else{
          setIsLoading(false)
        }
      })
    }else if(!!filteredLocationTaxes(locationsSetUp).length && !stateTax){
      setHasChange(false)
      setErrorMessage("Please enter the dealer's state tax.")
    }
  }

  function onAddLocationSetup(){
    const newLocationIndex = !!locationsSetUp.length ? locationsSetUp.length + 1 : 0
    const newLocationId = `newLocation-${newLocationIndex}`
    setLocationsSetup([...locationsSetUp, ...[{
      [newLocationId]: ""
    }]])
    if(!hasChange)
      setHasChange(true)
  }

  function handleOnchangeLocationValue(locationSetup, orderId){
    let newLocationsSetup = [...locationsSetUp]
    newLocationsSetup[orderId] = locationSetup
    setLocationsSetup(newLocationsSetup)
    if(!hasChange)
      setHasChange(true)
  }

  function removeLocationsSetUp(orderId, selectedLocationId){
    const updatedLocationTaxes = locationsSetUp.filter((location, idx) => idx !== orderId)
    setLocationsSetup(updatedLocationTaxes)

    if(selectedLocationId) {
      const filteredLocationIds = selectedLocationIds.filter(selectedId => selectedId !== selectedLocationId)
      setSelectedLocationIds(filteredLocationIds)
      if(!hasChange)
        setHasChange(true)
    }
  }

  return(
    <div style={{marginTop:"20px", float:"left", width:"100%"}} id="state-tax-wrapper">
      <hr className="simple-line" style={{marginBottom:"20px"}}/>
      { isLoading ? <DPSpinner/> :
        <div className="panel">
          <div className='column-header'>
            <div className='header-title'>
              <span className='title-desc'>Dealer State Tax</span>
            </div>
            <div className='header-options'>
              <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/union-43.svg'}
                  beforeInjection={svg => svg.classList.add('add-option')}
                  onClick={onAddLocationSetup}
              />
            </div>
          </div>
          <div className="panel-body">
            <p className="subtitle">General State tax set the percentage for all your locations, click on the "+" button to set a tax for a particular location.</p>
            {
              !!errorMessage &&
              <div className="alert alert-danger" role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                        onClick={() => setErrorMessage(null)}>
                  <span aria-hidden="true">&times;</span></button>
                <strong>State tax empty error!</strong> {errorMessage}
              </div>
            }
            <div className="container-tax">
              <div className="container-text">General State Tax</div>
              <InputWithSymbol value={stateTax ? stateTax : ""} onChange={handleOnchangeStateTax} type="stateTax"/>
            </div>
            {
              !!locationsSetUp.length && locationsSetUp.map((locationSetup, idx) =>
                <LocationTax key={Object.keys(locationSetup)[0]} locationSetup={locationSetup} orderId={idx} locations={locations}
                             selectedLocationIds={selectedLocationIds}
                             setSelectedLocationIds={setSelectedLocationIds}
                             handleOnchangeLocationValue={handleOnchangeLocationValue}
                             removeLocationsSetUp={removeLocationsSetUp}
                />
              )
            }
            <div className="container-save-button">
              <button className="save-button" onClick={handleSave} disabled={!hasChange}>Save</button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default StateTaxSetting