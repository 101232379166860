import React, {Component} from 'react'
import {differenceBy, cloneDeep} from 'lodash'
import {loadAttributesByEntity} from '../../../Api'
import SectionItem from './SectionItem.jsx'
import DragDropContext from '../../util/DragDropContext'
import {getWidthColumn} from '../../util/Utils'
import {SUCCESSFUL_CODE} from "../../../../common/Constants";

class BuildForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      attributes: null,
      attributesModel: null,
      dataSourceGroups: [],
      formBuilder: {
        sections: [],
        attributes: []
      },
      attributesSelected: [],
      attributesNotSelected: [],
      showAddSection: false,
      showModal: false,
      title: ""
    }
  }

  componentWillMount() {
    let {form} = this.props;
    this.getAttribute(form, form.formAttributes);
  }

  componentWillReceiveProps(nextProps) {
    //let {form} = nextProps;
    //this.setState({form: form});
    //this.getAttribute(form,form.formAttributes);
  }

  getNewSection(section, sectionSubs, sectionIndex) {
    return {
      id: "sec" + sectionIndex,
      status: "sec" + sectionIndex,
      attributeIds: (section.attributeIds) ? section.attributeIds : [],
      className: (section.className) ? section.className : "",
      classNameInput: (section.classNameInput) ? section.classNameInput : "",
      classNameLabel: (section.classNameLabel) ? section.classNameLabel : "",
      elementType: (section.elementType) ? "sec" : "",
      formKey: (section.formKey) ? section.formKey : "",
      header: (section.header) ? section.header : {title: "", subTitle: "", className: ""},
      isArray: (section.isArray) ? section.isArray : false,
      sectionSubs : (section.sectionSubs) ? section.sectionSubs : {isArray:true,elementType:"sub",formKey:"rows",status:"sec0_sub",rows:[]},
      title: (section.header) ? section.header.title : "Section "+sectionIndex,
      name: (section.name) ? section.name : "Section-"+sectionIndex,
      rows: sectionSubs.rows ? this.updateRows(sectionSubs.rows) : []
    }
  }

  getAttribute = (form, formAttributes) => {
    let {formBuilder} = this.state;
    let sections = formAttributes.sections;
    let attributesSelected = formAttributes.attributes;
    let entityType = form.entityType;
    loadAttributesByEntity(entityType).then(response => {
      const {responseCode, data : {groupAttributes}} = response
      if (responseCode !== SUCCESSFUL_CODE) {
        return
      }
      let dataSourceGroups = groupAttributes;
      if (sections.length === 0) {
        formBuilder.sections.push(this.getNewSection({}, {}, 0))
      } else {
        sections.forEach((section, index) => {
          formBuilder.sections.push(this.getNewSection(section, section.sectionSubs, index))
        });
        let rowsLastSection = formBuilder.sections[sections.length - 1].rows.length;
        this.setState({showAddSection:rowsLastSection > 0});
      }
      formBuilder.attributes = attributesSelected;
      let attributesModel = differenceBy(dataSourceGroups[0].options, attributesSelected, 'id');
      let attributes = differenceBy(dataSourceGroups[1].options, attributesSelected, 'id');
      this.setState({
        attributes: attributes,
        attributesModel: attributesModel,
        attributesSelected: attributesSelected,
        dataSourceGroups: dataSourceGroups,
        sections: sections
      });
    })
  }

  updateRows(rows) {
    rows.forEach((row) => {
      let col = getWidthColumn(row.columns ? row.columns.length : 1);
      let columns = row.columns;
      columns.forEach((column) => {
        column.col = col;
      })
    });
    return rows;
  }

  addSection = () => {
    let {formBuilder} = this.state;
    const indexNew = formBuilder.sections.length + 1;
    formBuilder.sections.push(this.getNewSection({}, {}, indexNew));
    this.updateFormBuilder(formBuilder);
  }

  moveSection = (dragIndex, hoverIndex) => {
    let {formBuilder} = this.state;
    let formBuilderNew = cloneDeep(formBuilder);
    formBuilderNew.sections[dragIndex] = formBuilder.sections[hoverIndex];
    formBuilderNew.sections[hoverIndex] = formBuilder.sections[dragIndex];
    this.updateFormBuilder(formBuilderNew);
  }

  updateFormBuilder = (formBuilder) => {
    let rowsLastSection = formBuilder.sections[formBuilder.sections.length - 1].rows.length;
    this.setState({showAddSection:rowsLastSection > 0,formBuilder:formBuilder},()=>{
      this.props.updateForm(formBuilder)
    })
  }

  render() {
    let {formBuilder, showAddSection, attributesSelected, attributes, attributesModel, dataSourceGroups} = this.state;
    let {form} = this.props;
    if(!attributes || !attributesModel) return null;

    return (
      <div className="build-form-container" style={{display: "block"}}>
        {
          formBuilder.sections.map((section, index) => {
            return (
              <SectionItem key={index}
                           index={index}
                           section={section}
                           formBuilder={formBuilder}
                           form={form}
                           attributes={attributes}
                           attributesModel={attributesModel}
                           attributesSelected={attributesSelected}
                           dataSourceGroups={dataSourceGroups}
                           updateFormBuilder={this.updateFormBuilder}
                           getAttribute={this.getAttribute}
                           moveSection={this.moveSection}/>
            )
          })
        }
        {
          showAddSection ?
            <div className="form-add-sec">
              <a className="btn-new-sec" onClick={this.addSection}>
                New Section
              </a>
            </div> : null
        }
      </div>
    )
  }
}

const BuildFormContainer = (props) => {
  return(
    <DragDropContext>
      <BuildForm {...props}/>
    </DragDropContext>
  )
}

export default BuildFormContainer