import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../common/Constants'

require('./DPCarousel.scss')

// CAROUSEL DEPRECATED - DON'T USE IT
const StyledDPCarousel = styled.div`
    justify-content: center;
    margin: 0 auto;
    display: flex;
    .container-children{
      display: flex;
      width:100;
      .container-icon{
        margin-top: 2rem;
        cursor: pointer;
      }
    }
     .item-no-active {
        display: none;
      }
      .icon-steps{
        svg{
            width:15px;
        }
      }
      .left{
        position:absolute;
        left: 5%;
        top: 45%;
        z-index: 200;
      }
      .right{
        position:absolute;
        right:5%;
        top: 45%;
        z-index: 200;
      }
      
      img{
        width:600px;
        height: auto;
      }
`;

const DPCarousel = React.forwardRef(({className, children, updateIndex,hasDelete,reloadDelete, iconBack, iconNext}, ref) => {

    const [currentItemActive, setCurrentItemActive] = useState(0);

    const back = () => {
        if (currentItemActive > 0) {
            setCurrentItemActive(currentItemActive - 1);
            updateIndex(currentItemActive - 1);
        } else {
            setCurrentItemActive(children.length - 1);
            updateIndex(children.length - 1);
        }
    };

    const advance = () => {
        if (currentItemActive < children.length - 1) {
            setCurrentItemActive(currentItemActive + 1);
            updateIndex(currentItemActive + 1);
        } else {
            setCurrentItemActive(0);
            updateIndex(0);
        }
    };

    useEffect(() => {
       setCurrentItemActive(0);
       updateIndex(0);
    },[]);

    useEffect(() => {
        if(hasDelete){
            if(currentItemActive !== 0){
                setCurrentItemActive(currentItemActive -1);
            }
            reloadDelete();
        }
    }, [hasDelete]);
    return (
        <StyledDPCarousel className={className} ref={ref}>
            {   children.map((item, index) => (
                    <div key={"item" + index} className={"container-children" + (index === currentItemActive ? " " : " item-no-active")}>
                        <ReactSVG onClick={back} src={`${pathServer.PATH_IMG}icon/${ iconBack ? iconBack : 'ic_arrow_back.svg'}`} className="icon-steps left"/>
                        {item}
                        <ReactSVG onClick={advance} src={`${pathServer.PATH_IMG}icon/${ iconNext ? iconNext : 'ic_arrow_next.svg'}`} className="icon-steps right"/>
                    </div>
            ))}
        </StyledDPCarousel>
    );
});

DPCarousel.displayName = 'DPCarousel';
DPCarousel.defaultProps = {
    className: null,
    children: {},
    updateIndex: () => {},
    hasDelete: false,
    reloadDelete: () => {},
};
DPCarousel.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    updateIndex: PropTypes.func,
    hasDelete : PropTypes.bool,
    reloadDelete:PropTypes.func,
};

export default DPCarousel;