import React, {Fragment, useEffect} from 'react'
import {unsubscribe} from '../../Api'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'

const Unsubscribe = ({...props}) => {

  const email = props.params?.email
  useEffect(() => {
    if(email){
      unsubscribe(email).then(({responseCode, responseMessage}) => {
        if (responseCode !== SUCCESSFUL_CODE) {
          console.log('error unsubscribe', responseMessage)
          return
        }

        console.log('unsubscribe success')
      })
    }
  },[])

  return (
    <Fragment>
      <header className="tool-bar header-unsubscribe">
        <img src={pathServer.PATH_IMG + "godealergo.png"} alt="logo" className="logo"/>
      </header>
      <div className="container-unsubscribe">
        <div className="element">
          <div className="container-img">
            <img src={`${pathServer.PATH_IMG}marketingpage/unsubscribe.png`} />
          </div>
          <p>We're sorry you're leaving</p>
          <p>You have been unsubscribed from our mailing list, you will not longer get email from us.</p>
          <p className="thank-you">Thank you</p>
          <p>Wait, I've changed my mind</p>
          <div className="container-btn-next">
            <a className="market-btn" href="/#footer-contact">Re-subscribe</a>
          </div>
        </div>
      </div>
    </Fragment>
  )

}

export default Unsubscribe