import React, {useState} from 'react'
import {
  ABOUT_US_ICON_NAMES,
  mediaTypes
} from '../../../Constants'
import {ReactSVG} from 'react-svg'
import DPDropzone from '../../../common/DPDropzone'
import {cloneDeep, findIndex} from 'lodash'
import {scale} from '../../util/ImageUploaderUtils'
import toBlob from 'canvas-to-blob'
import {DPInput} from '../../../common/DPInput'
import {DPTextarea} from '../../../common/DPTextarea'
import {Popover} from '../../util/ComponentUtils'
import {DPToggle} from '../../../common/DPToggle'
import {PopoverIconsBody} from './ThemeSettingContainer'
import {getPositionById, units} from '../../util/Utils'
import DPActivatedItem from '../../../common/themes/DPActivatedItem'
import VideoPlayerComponent from '../../../../common/UI/VideoPlayerComponent'
import {
  EmptyFunc,
  defaultDealerImages,
  pathServer,
  PAGE_ABOUT_US_ID,
  SECTION_TESTIMONIAL_ID, EXT_PNG, EXT_OTHER_IMG, EXT_IMG,
} from '../../../../common/Constants'

const NAME_IMAGE_ABOUT_US = 'media1'
const MAX_SIZE_TITLE_DESCRIPTION = 30
const MAX_SIZE_DESCRIPTION = 250
const KEY_HEADER_TITLE = 'title'
const KEY_HEADER_DESCRIPTION = 'description'
const KEY_VALUE_ONE = 'value1'
const KEY_VALUE_TWO = 'value2'
const MAX_SIZE_TITLE_VALUE_ONE = 45

const sectionsDefault = [
  {
    id: SECTION_TESTIMONIAL_ID,
    displayValue: 'Testimonies',
    title: 'What our customers say',
    showSection: false
  }
]

const SettingAboutPage = ({...props}) => {
  const {pages, onChangePages, uploadMediaFiles, dealerMedias, filesToDealerMedia, dealerMediasToDelete} = props
  const [showIconsPopover, setShowIconsPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState({})
  const [selectedValue, setSelectedValue] = useState('')

  const onChangeInput = (event) => {
    let pagesNew = cloneDeep(pages)
    let pageAboutUsIndex = pagesNew.findIndex(x => x.id === PAGE_ABOUT_US_ID)
    let value
    const key = event.target.id

    if (key === KEY_HEADER_DESCRIPTION || key === KEY_HEADER_TITLE)
      pagesNew[pageAboutUsIndex].headerText[key] = event.target.value

    /*if (key === KEY_ADDITIONAL_INFO_DESCRIPTION || key === KEY_ADDITIONAL_INFO_TITLE){
      if(!pageAboutUs.additionalInformation){
        pageAboutUs["additionalInformation"] = {titleAdditInfo: "", despAdditInfo: ""}
      }
      pageAboutUs.additionalInformation[key] = event.target.value;
    }*/


    if (key === KEY_VALUE_ONE || key === KEY_VALUE_TWO) {
      pagesNew[pageAboutUsIndex].values.forEach((value) => {
        if (value.id === key)
          value.displayValue = event.target.value
      })
    }
    /*if(key === KEY_BUTTON_CHECK){
      value = pageAboutUs.showCustomerTestimonials;
      pageAboutUs.showCustomerTestimonials = !value;
    }*/
    onChangePages(pagesNew)
  }

  const onDelete = (id, order) => {
    let dealerMediasNew = cloneDeep(dealerMedias)
    let filesToDealerMediaNew = cloneDeep(filesToDealerMedia)
    let dealerMediasToDeleteNew = cloneDeep(dealerMediasToDelete)
    let indexFoundToMedia = -1

    if (id) {
      indexFoundToMedia = findIndex(dealerMediasNew, function (media) {
        return media.id === id
      })
    }

    if (indexFoundToMedia !== -1) {
      dealerMedias.forEach((media, i) => {
        if (i === indexFoundToMedia) {
          dealerMediasNew.splice(i, 1)
          dealerMediasToDeleteNew.push(media.id)
        }
      })
    } else {
      indexFoundToMedia = findIndex(dealerMediasNew, function (media) {
        return media.order === order
      })
      dealerMedias.forEach((media, i) => {
        if (i === indexFoundToMedia) {
          if (media.mediaType === mediaTypes.IMAGE) {
            filesToDealerMediaNew.forEach((file, j) => {
              if (file.preview === media.url)
                filesToDealerMediaNew.splice(j, 1)
            })
          }
          dealerMediasNew.splice(i, 1)
        }
      })
    }

    dealerMediasNew.forEach((media, i) => {
      let order = i + 1
      media.order = order
      if (media.mediaType === mediaTypes.IMAGE) {
        filesToDealerMediaNew.forEach((file, j) => {
          if (file.preview === media.url) {
            file.order = order
            file.name = NAME_IMAGE_ABOUT_US
          }
        })
      }
    })

    uploadMediaFiles(dealerMediasNew, filesToDealerMediaNew, dealerMediasToDeleteNew)
  }

  const onDrop = (files) => {
    uploadFile(files)
  }

  const uploadFile = (files, isScale) => {
    let dealerMediasNew = cloneDeep(dealerMedias)
    let filesToDealerMediaNew = cloneDeep(filesToDealerMedia)
    let quantityMedia = dealerMedias.length
    let order
    const maxFiles = (10 - dealerMediasNew.length)
    let filesToInsert = files
    if (files.length > maxFiles) {
      filesToInsert = files.slice(0, maxFiles)
    }

    filesToInsert.forEach((file, index) => {
      if ((file.size) / (1000 * 1024) <= 5) {
        scale(file, function (image, validHeight) {
          quantityMedia += 1
          let blob = toBlob(image)
          blob.order = quantityMedia
          blob.name = NAME_IMAGE_ABOUT_US
          blob.preview = URL.createObjectURL(file)
          filesToDealerMediaNew.push(blob)
          dealerMediasNew.push({
            url: URL.createObjectURL(file),
            mediaType: mediaTypes.IMAGE,
            order: order,
            isNew: true
          })
        })
      }
    })

    setTimeout(() => {
      uploadMediaFiles(dealerMediasNew, filesToDealerMediaNew, dealerMediasToDelete)
    }, 500)
  }

  const openPopoverIcons = (id, value) => {
    let pointTo = getPositionById(id)
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y + 30,  //30 is height for component with [id]
      offsetWidth: 180
    }
    setShowIconsPopover(true)
    setAnchorEl(anchorEl)
    setSelectedValue(value)
  }

  const selectIcon = (iconName, event) => {
    event.preventDefault()

    let pagesNew = cloneDeep(pages)
    let pageAboutUsIndex = pagesNew.findIndex(x => x.id === PAGE_ABOUT_US_ID)
    let pageAboutUs = pagesNew[pageAboutUsIndex]

    pageAboutUs.values.forEach((value) => {
      if (value.id === selectedValue)
        value.icon = pathServer.PATH_IMG + 'icon/' + iconName
    })
    onChangePages(pagesNew)
    setShowIconsPopover(false)
    setSelectedValue(null)
  }

  const handleShowHours = () => {
    let pagesNew = cloneDeep(pages)
    let pageAboutUsIndex = pagesNew.findIndex(x => x.id === PAGE_ABOUT_US_ID)
    let pageAboutUs = pagesNew[pageAboutUsIndex]
    if (pageAboutUs) {
      pageAboutUs.showDealerHours = pageAboutUs.showDealerHours ? !pageAboutUs.showDealerHours : true
    }
    onChangePages(pagesNew)
  }

  const handleShowValue = (index, event) => {
    let pagesNew = cloneDeep(pages)
    let pageAboutUsIndex = pagesNew.findIndex(x => x.id === PAGE_ABOUT_US_ID)
    let pageAboutUs = pagesNew[pageAboutUsIndex]
    pageAboutUs.values[index].showValue = pageAboutUs.values[index].showValue ? !pageAboutUs.values[index].showValue : true
    onChangePages(pagesNew)
  }

  const handleCheck = (sectionId) => {
    const pagesNew = cloneDeep(pages)
    const aboutUs = pagesNew.find(x => x.id === PAGE_ABOUT_US_ID)
    if (!aboutUs.sections) {
      aboutUs.sections = sectionsDefault
    }
    const section = aboutUs.sections.find(x => x.id === sectionId)
    if (section) {
      section.showSection = !section.showSection
      onChangePages(pagesNew)
    }
  }

  const aboutUs = pages.find(x => x.id === PAGE_ABOUT_US_ID)

  if (!aboutUs.sections) {
    aboutUs.sections = sectionsDefault
  }
  const {headerText} = aboutUs
  const {title, description} = headerText
  let indexVideo = -1
  const {values, showDealerHours} = aboutUs

  return (
    <div className="container-about-us">
      <div className="body-sec-media">
        <h3>Gallery</h3>
        <span className="subtitle">Upload until 10 pictures or video to show your dealer</span>
        <div className="uploaded-images">
          {
            dealerMedias.map((media, index) => {
              if (media.mediaType === mediaTypes.VIDEO)
                indexVideo += 1
              return (
                <div key={index} className="current-image-container">
                  {
                    media.mediaType === mediaTypes.VIDEO ?
                      <VideoPlayerComponent url={media.url}/> :
                      <img key={index} src={media.url} className="current-image" alt={media.url}/>
                  }
                  <div className="icon-delete-setting">
                    <div className="row-delete">
                      <div className="icon-content-delete-setting">
                        <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_delete.svg'} className="cloud-delete-content"
                                  beforeInjection={svg => svg.classList.add('cloud-delete')}
                                  onClick={() => onDelete(media.id, media.order)}/>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
          {
            dealerMedias.length < 10 ?
              <DPDropzone
                onDrop={(file) => onDrop(file)}
                extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                dataTestInput={'dealer-media'}
              >
                <>
                  <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_cloud_upload_arrow.svg`}
                            className="customer-image-upload"
                            beforeInjection={svg => svg.classList.add('cloud-upload')}/>
                  <label className="label-upload-logo">Upload</label>
                </>
              </DPDropzone> : null
          }
        </div>
        {
          dealerMedias.length === 0 &&
          <div className="container-default-images">
            <span>Default Images</span>
            {
              defaultDealerImages.map((img, index) => {
                return (
                  <div className="container-default-image">
                    <img id={`defaultDealerImage${index}`} src={img.url} className="default-image"/>
                  </div>
                )
              })
            }
          </div>
        }
      </div>
      {
        aboutUs.sections.map((item, index) => {
          return (
            <DPActivatedItem id={index}
                             showIcon={true}
                             title={item.displayValue}
                             active={item.showSection}
                             onClickItem={EmptyFunc}
                             handleCheck={() => handleCheck(item.id)}
            />
          )
        })
      }
      <div className="body-sec-description">
        <h3>Description</h3>
        <DPInput
          className="container-input-description"
          id={KEY_HEADER_TITLE}
          title="Title"
          maxLength={MAX_SIZE_TITLE_DESCRIPTION}
          onChange={onChangeInput}
          rightElement={<label
            className={`label-size right ${title.length === MAX_SIZE_TITLE_DESCRIPTION ? 'error-color' : ''}`}>{`${title.length}/${MAX_SIZE_TITLE_DESCRIPTION}`}</label>}
          value={title}
        />
        <DPTextarea
          id={KEY_HEADER_DESCRIPTION}
          label="Description"
          maxLength={MAX_SIZE_DESCRIPTION}
          onChange={onChangeInput}
          rightElement={<label
            className={`label-size right ${description.length === MAX_SIZE_DESCRIPTION ? 'error-color' : ''}`}>{`${description.length}/${MAX_SIZE_DESCRIPTION}`}</label>}
          rows={7}
          styleTextarea={{resize: 'none'}}
          value={description}
        />
      </div>
      <div className="body-sec-details input-values">
        <div className="containers-values">
          {
            values.map((value, index) => {
              const {displayValue, id, icon, title, showValue} = value
              return (
                <div key={index} className="row-value">
                  <div className="container-value-title">
                    <h3>{title ? title : `Tag ${units(index + 1)}`}</h3>
                    <DPToggle id={`toggleValue${index}`} isChecked={!!showValue}
                              handleCheck={(evt) => handleShowValue(index, evt)}/>
                  </div>
                  <div className="container-select-icon">
                    <span>Icon</span>
                    <div id={'icon-select-' + index} className="section-icon"
                         onClick={() => openPopoverIcons('icon-select-' + index, id)}>
                      <ReactSVG src={icon} className="icon-menu-content"
                                beforeInjection={svg => svg.classList.add('icon-menu')}/>
                      <i className="fa fa-chevron-down"/>
                    </div>
                  </div>
                  <DPInput
                    className="section-value"
                    id={id}
                    title="Value title"
                    maxLength={MAX_SIZE_TITLE_VALUE_ONE}
                    onChange={onChangeInput}
                    rightElement={<label
                      className={`label-size right ${displayValue.length >= MAX_SIZE_TITLE_VALUE_ONE ? 'error-color' : ''}`}>{`${displayValue.length}/${MAX_SIZE_TITLE_VALUE_ONE}`}</label>}
                    value={displayValue}
                  />
                </div>
              )
            })
          }
        </div>
        {
          showIconsPopover ?
            <Popover anchorEl={anchorEl}
                     child={<PopoverIconsBody icons={ABOUT_US_ICON_NAMES}
                                              selectIcon={selectIcon}
                                              classname="about-us-icons-container"
                     />}
                     classPopover="popover-box-category"
                     handleRequestClose={() => setShowIconsPopover(false)}
            />
            : null
        }
      </div>
      <div className="body-dealer-hours">
        <div className="container-title-dealer-hours">
          <h3>Dealer hours</h3>
          <DPToggle id={'toggleHours'} isChecked={!!showDealerHours} handleCheck={handleShowHours}/>
        </div>
        <span className="message-hours">To change your dealer hours please go to Settings > Locations > Hours </span>
      </div>
    </div>
  )
}

export default SettingAboutPage