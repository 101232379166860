import React from 'react'
import {siteColorAdmin} from '../../Constants'
import {ReactSVG} from 'react-svg'
import Badge from 'material-ui/Badge'
import {pathServer} from '../../../common/Constants'
import {DPPagination} from '../../../common/DPPagination'

const navigationHeader = props => {
  return (
    <div className="message-header">
      <div className="header-wrapper">
        <div className="header-name">
          {
            props.addButton &&
              <div className="btn-plus-container">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"} beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                          className="icon-add-task" onClick={props.add}/>
              </div>
          }
          <div className="message-name" style={ props.tab === "finance" ? {width:"auto"} : null}>{props.name}</div>
        </div>
        <div className="links-container">
          <ul className="links">
            <li className="link">
              <a className={props.tab === "inbox" ? "selected-tab" :""}
                 onClick={() => props.onChangeTab()}>
                { props.unreadMessagesCounter > 0
                  ? <Badge className={'custom-badge'} badgeContent={props.unreadMessagesCounter}>
                    <p>Inbox</p>
                  </Badge>
                  : <span>Inbox</span>
                }
              </a>
            </li>
            {/*<li className="link">*/}
            {/*  <a className={props.tab==="sent"? "selected-tab" : "" }*/}
            {/*     onClick={() => props.onChangeTab("sent")}>*/}
            {/*    <span>Sent</span>*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li className="link">
              <a className={props.tab==="finance"? "selected-tab": ""}
                 onClick={() => props.onChangeTab("id")}>
                { props.newFinanceAppsCounter > 0
                  ? <Badge className={'custom-badge'} badgeContent={props.newFinanceAppsCounter}>
                    <p>Finances</p>
                  </Badge>
                  : <span>Finances</span>
                }
              </a>
            </li>
            {/*
              <li className="link">
                <a className={props.tab === 'chat-box' ? 'selected-tab' : ''}
                   onClick={() => props.onChangeTab('chat-box')}>
                  {props.newFinanceAppsCounter > 0
                    ? <Badge className={'custom-badge'} badgeContent={props.newFinanceAppsCounter}>
                      <p>Chat box</p>
                    </Badge>
                    : <span>Chat box</span>
                  }
                </a>
              </li>*/
            }
          </ul>
        </div>
        <div className="pagination-wrapper">
          {
            props.totalPages>0?
              <div className="customer-pagination">
                <DPPagination
                  optionsQuantityByPage={props.options}
                  selectedItemsByPage={props.itemsByPageQuantity}
                  showItemPerPage={props.showItemPerPage}
                  handleChangeItemsByPage={props.showItemPerPage ? props.handleItemsByPage : null}
                  currentPage={props.currentPage}
                  totalPages={props.totalPages}
                  handlePageChange={props.handlePageChange}
                  siteColor={siteColorAdmin}
                />
              </div>
              :null
          }
        </div>
      </div>
    </div>
  );
};

export default navigationHeader;