import React from 'react'
import PropTypes from 'prop-types'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../common/Constants'

const DPOptionsTask = React.forwardRef(({id, activeTask, deleteTask, isDisabled, save, hasChange, pageSelected = 'task'}, ref) => (
    isDisabled ?
        <div className="list-task-edit" ref={ref}>
            <div className='options-archived-removed'>
                {pageSelected === 'subTask' ?
                  <i title="Delete Task" onClick={deleteTask}>
                      <ReactSVG src={pathServer.PATH_IMG + 'icon/trash-icon.svg'}
                                beforeInjection={svg => svg.classList.add('icon-delete')}/>
                  </i> :
                  <i title="Inactive Task" onClick={activeTask}>
                      <ReactSVG src={pathServer.PATH_IMG + 'icon/cancel-button.svg'}
                                beforeInjection={svg => svg.classList.add('icon-inactive')}/>
                  </i>
                }
                <button onClick={save} className="btn-save" disabled={!hasChange}
                        style={hasChange ? { backgroundColor: '#4ad991' } : { backgroundColor: '#A4AFB7' }}>Save
                </button>
            </div>
        </div>
      :
      <div className="list-task-options" ref={ref}>
            <div className='options-archived-removed'>
                <i title='Active Task' onClick={(evt) => activeTask(evt)}>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/add-button.svg"}
                              beforeInjection={svg => svg.classList.add("icon-add")}/>
                </i>
                <i title='Delete Task' onClick={(evt) => deleteTask(evt)}>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                              beforeInjection={svg => svg.classList.add("btn-delete")}/>
                </i>
            </div>

        </div>


));

DPOptionsTask.displayName = 'DPOptionsTask';

DPOptionsTask.defaultProps = {
    id: null,
    activeTask: () => {
    },
    deleteTask: () => {
    },
    isDisabled: false,
    hasChange: false,

}

DPOptionsTask.propTypes = {
    id: PropTypes.string,
    activeTask: PropTypes.func,
    deleteTask: PropTypes.func,
    isDisabled: PropTypes.bool,
    hasChange: PropTypes.bool,
};

export default DPOptionsTask;