import React, {Component} from 'react'
import {CARS_BY_PAGE, siteColorAdmin} from '../../Constants'
import {Popover} from '../util/ComponentUtils'
import {ReactSVG} from 'react-svg'
import {loadTestimonials, updateTestimonial, updateTestimonialStatus} from '../../Api'
import Moment from 'moment'
import ModalError from '../util/ModalError.jsx'
import {browserHistory} from 'react-router'
import {getPosition} from '../../../common/Util'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import {DPPagination} from '../../../common/DPPagination'
import isEmpty from 'lodash/isEmpty'
import {connect} from "react-redux";
import {setKeyword} from "../../actions";

const options = [
  {
    code: 'hidden',
    value: 'Hidden'
  },
  {
    code: 'published',
    value: 'Published'
  }
];

const Status = ({indexStatusSelected,indexUserSelected,selectStatus}) => {
  const optionSelected = indexStatusSelected[indexUserSelected];
  return(
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {
            options.map((op,index) => {
              return(
                <li key={op.code} className={index === optionSelected ? "item-selected": "change-submenu-item"}
                    onClick={() => selectStatus(op.code)}>
                  {op.value}
                </li>
              )
            })
          }
        </u>
      </div>
    </div>
  )
};

const loadTestimonialsInfoList = async (from, size, keyword) => {
  const {responseCode, responseMessage, data} = await loadTestimonials(from, size, keyword)
  if (responseCode !== SUCCESSFUL_CODE) {
    console.log(responseMessage)
    return
  }

  const testimonials = data.testimonials ?? []
  const testimonialsWithFormat = testimonials.map( testimonial => {return {...testimonial.testimonial, comments: testimonial.comments, reactions: testimonial.reactions}})

  let lastSuccessfulUpdateDate = new Date()
  let lastTryUpdateDate =  new Date()
  let error = true;
  if (data?.testimonialUpdateHistory?.lastSuccessfulUpdateDate) {
    lastSuccessfulUpdateDate = data.testimonialUpdateHistory.lastSuccessfulUpdateDate;
    error = false;
  }

  if(data?.testimonialUpdateHistory?.lastTryUpdateDate){
    lastTryUpdateDate =  data.testimonialUpdateHistory.lastTryUpdateDate
  }

  const quantity = data?.quantity ?? 0
  const updateHistoryErrorMessage = data?.testimonialUpdateHistory?.errorMessage ?? ''
  return { testimonials: testimonialsWithFormat, lastSuccessfulUpdateDate, quantity, error, lastTryUpdateDate, updateHistoryErrorMessage }
}

const updateAndLoadTestimonials = async (from, size) => {
  try{
    const {responseCode, responseMessage} = await updateTestimonial()
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      return
    }
    
    return await loadTestimonialsInfoList(from, size)
  }catch (e) {
    console.error(e)
  }
}

class Testimonials extends Component {
  constructor() {
    super();

    this.state = {
      testimonials: [],
      showOptions:false,
      anchorElStatus:null,
      anchorElPagination:null,
      itemsByPageQuantity:16,
      currentPage:1,
      showInactive: true,
      form: null,
      lastDate:null,
      showPopover:false,
      indexSelected:null,
      indexUserSelected:null,
      query:{},
      showModalSignIn:false,
      errorMessage:'',
      tryDate:null,
      totalTestimonials:0,
      showLoader: false,
      isLoading:false,
      indexStatusSelected:[],
      errorLastSuccessfulUpdateDate : true
    }
  }

  async componentWillMount  () {
    let {itemsByPageQuantity, indexStatusSelected} = this.state;
    this.setState({isLoading:true});
    try{
      const {testimonials, lastSuccessfulUpdateDate, quantity, error} = await updateAndLoadTestimonials(0, itemsByPageQuantity)
      if (testimonials?.length > 0) {
        testimonials.forEach((testimonial, index) => {
          if (testimonial.status === "hidden") {
            indexStatusSelected[index] = 0
          } else {
            indexStatusSelected[index] = 1
          }
        });
      }
      this.setState({testimonials: testimonials, lastDate: Moment(lastSuccessfulUpdateDate).format('MMM.DD YYYY HH:mm:ss'), totalTestimonials: quantity,
        indexStatusSelected: indexStatusSelected, errorLastSuccessfulUpdateDate: error, isLoading: false})
    }catch (e) {
      console.error(e)
    }

  }

  async componentDidUpdate(prevProps) {
    const {keyword: prevKeyword} = prevProps;
    const {keyword} = this.props
    if(keyword !== prevKeyword){
      const {query} = this.state
      const {testimonials, quantity, lastSuccessfulUpdateDate} =await loadTestimonialsInfoList(0,query.size,keyword);
      this.setState({testimonials, lastDate: Moment(lastSuccessfulUpdateDate).format('MMM.DD YYYY HH:mm:ss'), totalTestimonials: quantity,
        showOptions: false, currentPage: 1})
    }

  }

  viewForm = (form) => {
    this.setState({showModal: true, form: form})
  }

  handleRequestOpen = (event) => {
    this.setState({showOptions:true,anchorElPagination:{offsetWidth:event.currentTarget.offsetWidth,offsetHeight:event.currentTarget.offsetHeight}})
  }

  handleRequestClose = () => {
    this.setState({showOptions:false,showPopover:false})
  }

  handleItemsByPage = async (evt) => {
    let {query} = this.state;
    const itemsByPageQuantity = parseInt(evt.target.id);
    query.from = 0;
    query.size = itemsByPageQuantity;

    try{
      const {testimonials, quantity, lastSuccessfulUpdateDate} = await loadTestimonialsInfoList(query.from, query.size)
      this.setState({testimonials, lastDate: Moment(lastSuccessfulUpdateDate).format('MMM.DD YYYY HH:mm:ss'), totalTestimonials: quantity,
        showOptions: false, currentPage: 1, itemsByPageQuantity})
    }catch (e) {
      console.error(e)
    }
  }

  handlePageChange = async (nextPage,totalPages) => {
    let {from,itemsByPageQuantity,query, totalTestimonials} = this.state;
    const {keyword} = this.props
    if(nextPage >= 0){
      let currentPage = nextPage;
      if(currentPage > totalPages) {
        currentPage = 1;
        query.from = 0;
      }else if(currentPage === 0){
        currentPage = totalPages;
        query.from = itemsByPageQuantity*(totalPages - 1);
      }else{
        if(totalTestimonials !== 0)
          query.from = itemsByPageQuantity*(currentPage - 1);
      }
      query.size = itemsByPageQuantity;

      try{
        const {testimonials, quantity, lastSuccessfulUpdateDate} = await loadTestimonialsInfoList(query.from, query.size, keyword)
        this.setState({testimonials, lastDate:Moment(lastSuccessfulUpdateDate).format('MMM.DD YYYY HH:mm:ss'), currentPage:currentPage, from, totalTestimonials: quantity})
      }catch (e) {
        console.error(e)
      }
    }
  }

  connectToFacebook = () => {
    //console.log("Test connect To Facebook");
  }

  goToSignIn(){
    browserHistory.push(`/main/setting/marketing`);
  }

  hideModal = () => {
    this.setState({showModalSignIn:false})
  }

  updateTestimonials = async () => {
    let {itemsByPageQuantity} = this.state;
    this.setState({isLoading:true});
    try{
      const {testimonials, lastSuccessfulUpdateDate, quantity, error, lastTryUpdateDate, updateHistoryErrorMessage} = await updateAndLoadTestimonials(0, itemsByPageQuantity)
      let lastDate = Moment(lastSuccessfulUpdateDate).format('MMM.DD YYYY HH:mm:ss');
      let showModalSignIn = false
      let tryDate;

      if(!isEmpty(updateHistoryErrorMessage)){
        showModalSignIn = true
        tryDate = Moment(lastTryUpdateDate).format('MMM.DD YYYY HH:mm:ss');
      }

      this.setState({errorMessage: updateHistoryErrorMessage ,lastDate,showModalSignIn,tryDate, totalTestimonials: quantity, testimonials, currentPage: 1, isLoading:false})
    }catch (e) {
      console.log(e);
      this.setState({isLoading:false})
    }
  }

  showPopoverStatus = (index, event) => {
    let id = "testimonial-status" + index;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 25,
      offsetWidth: target.offsetWidth
    };
    this.setState({anchorEl: anchorEl, showPopover:true,indexUserSelected:index});
  }

  selectStatus = async (option) => {
    let {testimonials,indexUserSelected,indexStatusSelected}= this.state;
    testimonials[indexUserSelected].status = option;
    if(option === "hidden"){
      indexStatusSelected[indexUserSelected] = 0
    }else{
      indexStatusSelected[indexUserSelected] = 1
    }

    this.setState({testimonials,showPopover:false, showLoader: true, indexStatusSelected: indexStatusSelected});
    try{
      const {responseCode, responseMessage} = await updateTestimonialStatus(testimonials[indexUserSelected].id, option.toLowerCase())
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        return
      }

      this.setState({showLoader: false});
    }catch (e) {
      console.error(e);
      this.setState({showLoader: false});
    }
  }

  deleteTestimonial = async (index, event) => {
    let {testimonials, itemsByPageQuantity, currentPage, totalTestimonials, indexStatusSelected} = this.state;
    //testimonials[index].status = option;
    this.setState({showPopover:false, isLoading: true});
    let from = itemsByPageQuantity*(currentPage-1);
    if (testimonials.length === 1 && totalTestimonials / itemsByPageQuantity > 1) {
      from = itemsByPageQuantity * (currentPage - 2);
      currentPage = currentPage - 1
    }
    try{
      const {responseCode, responseMessage} = await updateTestimonialStatus(testimonials[index].id, "deleted")
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        return
      }

      loadTestimonialsInfoList(from, itemsByPageQuantity).then(response => {
        const { testimonials, lastSuccessfulUpdateDate, quantity} = response
        if(testimonials && testimonials.length > 0){
          testimonials.forEach((testimonial, index) => {
            if(testimonial.status === "hidden"){
              indexStatusSelected[index] = 0
            } else{
              indexStatusSelected[index] = 1
            }
          });
        }
        this.setState({
          testimonials,
          lastDate: Moment(lastSuccessfulUpdateDate).format('MMM.DD YYYY HH:mm:ss'),
          totalTestimonials: quantity,
          currentPage,
          isLoading: false,
          indexStatusSelected: indexStatusSelected
        })
      })

    }catch (e) {
      console.error(e);
      this.setState({isLoading: false})
    }
  }

  goToMarketing(){
    browserHistory.push("/main/setting/marketing");
  }

  render() {
    const {
      showOptions, itemsByPageQuantity, currentPage, testimonials, showInactive, lastDate, showPopover,
      anchorElPagination, errorMessage, showModalSignIn, tryDate, totalTestimonials, showLoader, indexUserSelected,
      isLoading, anchorEl, errorLastSuccessfulUpdateDate, indexStatusSelected
    } = this.state;
    const totalPages = Math.ceil(totalTestimonials / itemsByPageQuantity);
    const {height} = this.props;
    return (
        <div className="testimonial-container" style={{minHeight: height}}>
          {
            isLoading ?
                <div className="loader-container">
                  <div className="loader"/>
                </div> :
            errorLastSuccessfulUpdateDate ?
                <div className="testimonial-page-error" style={{minHeight: height}}>
                  <div className="container-images-button">
                    <div className="marketing-graphics">
                      <div className="graphics-container">
                        <div className="graphic-text">
                          <img src={pathServer.PATH_IMG + "social-medias.png"}/>
                          <label>Link your social media accounts</label>
                        </div>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_next.svg"} className="arrow-marketing"/>
                        <div className="graphic-text">
                          <img src={pathServer.PATH_IMG + "admin-marketing-graphic.png"}/>
                          <label>Admin Comments, Ads and Campains</label>
                        </div>
                      </div>
                    </div>
                    <div className="container-button-link">
                      <button onClick={this.goToMarketing}>Link Social Media Account</button>
                    </div>
                  </div>
                </div> :
                <div className="testimonial-page-container">
                  <div className="row-facebook-pagination">
                    <div className="row-facebook">
                      <div className="facebook-box" onClick={this.connectToFacebook}>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_facebook.svg"} className="icon-facebook"/>
                      </div>
                      <div className="container-icon-reset" onClick={this.updateTestimonials}>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_reset.svg"} className="icon-reset"/>
                      </div>
                      <div className="container-last-update">
                        <label
                            className="label-last-update">{!errorLastSuccessfulUpdateDate ? `Last Update: ${lastDate}` : "Error"}</label>
                      </div>
                    </div>
                    <div className="inventory-pagination pagination-testimonials">
                      <DPPagination
                        optionsQuantityByPage={CARS_BY_PAGE}
                        selectedItemsByPage={itemsByPageQuantity}
                        handleChangeItemsByPage={this.handleItemsByPage}
                        currentPage={(totalTestimonials === 0) ? 0 : currentPage}
                        totalPages={totalPages}
                        handlePageChange={this.handlePageChange}
                        showItemPerPage={false}
                        siteColor={siteColorAdmin}
                      />
                    </div>
                  </div>
                  <div className="table-form-setting">
                    <table className="table">
                      <thead className="background-white">
                      <tr className="title-forms-list">
                        <th className="title-photo-users-list">Photo</th>
                        <th className="title-name-users-list">User</th>
                        <th className="title-testimonial">Testimonial</th>
                        <th className="title-posting-date">Posting date</th>
                        <th className="title-recommendation-type">Type</th>
                        <th className="title-website-status">Website status</th>
                        <th className="td-website-status"/>
                      </tr>
                      </thead>
                      <tbody className="tbody-user">
                      {
                        isLoading ?
                            <div className="loader-container">
                              <div className="loader"/>
                            </div> :
                            (testimonials.length !== 0 ?
                                (
                                    testimonials.map((testimonial, index) => {
                                      let recommendation = testimonial.recommendationType;
                                      return (
                                          <tr key={testimonial.id + 'tr'} className="rowUser">
                                            <td className="title-photo-users-list">
                                              <img className="image-user"
                                                   src={testimonial.userPicture ? testimonial.userPicture : pathServer.PATH_IMG + "ic_account_circle.png"}
                                                   alt=""/>
                                            </td>
                                            <td className="title-name-users-list">{testimonial.userName}</td>
                                            <td className="testimonials-users">
                                              <p>{testimonial.content}</p>
                                            </td>
                                            <td className="title-posting-date">
                                              {Moment(testimonial.createPostDate).format('MM/DD/YYYY')}
                                            </td>
                                            <td className="title-recommendation-type"
                                                style={{color: recommendation === "positive" ? "#4AD991" : "#FF6565"}}>
                                              {recommendation.charAt(0).toUpperCase() + recommendation.slice(1)}
                                            </td>
                                            <td className="title-website-status">
                                              {
                                                (showLoader && indexUserSelected === index) ?
                                                    <div className="loader-container-status">
                                                      <div className="loader"/>
                                                    </div> :
                                                    <div id={"testimonial-status" + index}
                                                         className="btn-dp-form-testimonials" onClick={(evt) => {
                                                      this.showPopoverStatus(index, evt)
                                                    }}>
                                                      <a className="btn-website-status">
                                                        <span
                                                            style={{color: (testimonial.status === 'hidden') ? "#FF6565" : "#4AD991"}}> {testimonial.status.charAt(0).toUpperCase() + testimonial.status.slice(1)}</span>
                                                        {
                                                          showPopover && indexUserSelected === index ?
                                                              <ReactSVG
                                                                  src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                                                  className="icon-arrow-content"
                                                                  beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                                                              <ReactSVG
                                                                  src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                                                  className="icon-arrow-content"
                                                                  beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                                                        }
                                                      </a>
                                                    </div>
                                              }
                                            </td>
                                            <td className="td-website-status">
                                              {
                                                (showInactive) ?
                                                    <div className="options-testimonial">
                                                      <i className="fa fa-trash fa-lg" title="Delete"
                                                         onClick={(evt) => {
                                                           this.deleteTestimonial(index, evt)
                                                         }}/>
                                                    </div>
                                                    : null
                                              }
                                            </td>
                                          </tr>
                                      )
                                    })
                                )
                                :
                                null)
                      }
                      </tbody>
                    </table>
                  </div>
                  <ModalError className="modal-error-message" showModal={showModalSignIn} errorMessage={errorMessage}
                              tryDate={tryDate} onClickButton={this.goToSignIn} hideModal={this.hideModal}
                              buttonText={"Sign in"}/>
                  {
                    showPopover ?
                        <Popover anchorEl={anchorEl}
                                 child={<Status indexStatusSelected={indexStatusSelected} indexUserSelected={indexUserSelected} selectStatus={this.selectStatus} />}
                                 classPopover="popover-box-message"
                                 handleRequestClose={this.handleRequestClose}/> : null
                  }
                </div>
          }
        </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    keyword:state.keyword,
  }
};

export default connect(mapStateToProps,{setKeyword})(Testimonials)