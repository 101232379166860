import React from 'react'

import { MESSAGE_WITHOUT_INVOICES } from '../../../../Constants'
import DPTable from '../../../../common/DPTable'
import DPMessage from '../../../../common/DPMessage'
import {INVOICE_STATUS} from '../../../setting/billing/BillingContainer'

const STATUS_COLOR = {
  [INVOICE_STATUS.PAID]: '#4AD991',
  [INVOICE_STATUS.UNPAID]: '#FF6565',
  [INVOICE_STATUS.PENDING]: '#F3A200',
}

const Invoices = ({invoices}) => {
  return (
    <>
      <DPTable
        data={invoices}
        config={
          {
            columns: [
              {id: 'orderNumber', label: 'Order Num'},
              {id: 'dueDate', label: 'Due Date'},
              {id: 'startDate', label: 'Start Date'},
              {id: 'endDate', label: 'End Date'},
              {id: 'status', label: 'Status'}
            ]
          }
        }
        renderColumn={
          (invoice, columnConf, rowIndex) => {
            switch (columnConf.id) {
              case 'orderNumber':
                return invoice.orderNumber
              case 'dueDate':
                return invoice.dueDate
              case 'startDate':
                return invoice.startDate
              case 'endDate':
                return invoice.endDate
              case 'status':
                return (<span style={{color: STATUS_COLOR[invoice.status]}}>{invoice.status}</span>)
            }
          }
        }
        // onRowClick={accountOwner => setOwnerSelected(accountOwner)}
        onRowClick={() => {}}
      />
      {
        invoices.length === 0 ? (
          <DPMessage text={MESSAGE_WITHOUT_INVOICES}/>
        ) : null
      }
      {/*{*/}
      {/*  ownerSelected ?*/}
      {/*    <DPModalInfo showModal={true} closeModalInfo={() => setOwnerSelected(null)}*/}
      {/*                 body={<ModalAccountOwnerDetails accountOwner={ownerSelected}/>}/> : null*/}
      {/*}*/}
    </>
  )
}

export default Invoices
