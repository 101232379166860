import React, {useState, useEffect, useRef} from 'react'
import {SketchPicker} from 'react-color'
import {cloneDeep} from 'lodash'
import DPCheckbox from '../../../common/DPCheckbox'
import {cover, pathServer} from '../../../../common/Constants'
import {ReactSVG} from 'react-svg'
import {unstable_batchedUpdates} from 'react-dom'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import DPOverlay from '../../../common/DPOverlay'
import {getPositionById} from '../../util/Utils'

const presetColors = ['#ffffff', '#000000', '#0093f7', '#00de8a', '#ff565e', '#005099', '#bd0000']
const presetTextColors = ['#000000', '#4A4A4A', '#9A9A9A', '#CECECE', '#ffffff']
const presetSiteColors = ['#ffffff', '#f4f4f4', '#c4c4c4c4', '#949494', '#646464']
const presetBoxColors = ['#ffffff', '#f4f4f4', '#c4c4c4c4', '#949494', '#646464']
const presetSiteDarkColors = ['#0c0c0c', '#2c2c2c', '#4c4c4c', '#6c6c6c', '#8c8c8c']
const presetBoxDarkColors = ['#000000', '#222222', '#2c2c2c', '#4c4c4c', '#6c6c6c']
const COLOR_PRIMARY_DEFAULT = '#d32525'
const COLOR_BUTTON_TEXT_DEFAULT = '#FFFFFF'
const COLOR_SECONDARY_DEFAULT = '#0088b4'
const COLOR_TEXT_DEFAULT_LIGHT = '#515151'
const COLOR_TEXT_DEFAULT_DARK = '#E2E2E2'
const COLOR_BACKGROUND_DEFAULT_LIGHT = '#ffffff'
const COLOR_BACKGROUND_DEFAULT_DARK = '#2C2C2C'
const COLOR_NAVIGATION_DEFAULT_LIGHT = '#ffffff'
const COLOR_NAVIGATION_DEFAULT_DARK = '#E2E2E2'
const COLOR_NAVIGATION_BAR_TEXT_DEFAULT_LIGHT = '#4A4A4A'
const KEY_COLOR_PRIMARY = 'primary'
const KEY_COLOR_SECONDARY = 'secondary'
const KEY_COLOR_BUTTON_TEXT = 'buttonText'
const KEY_COLOR_HOME_SECTIONS_TEXT = 'text'
const KEY_BACKGROUND = 'background'
const KEY_COLOR_NAVIGATION = 'navigation'
const KEY_COLOR_NAVIGATION_BAR_TEXT = 'navigationBarText'
const KEY_COLOR_NAVIGATION_BAR_BACKGROUND = 'navigationBarBackground'
const KEY_COLOR_SITE_BACKGROUND = 'siteBackground'
const KEY_COLOR_BOX_BACKGROUND = 'boxBackground'
const KEY_COLOR_CONTROL_BACKGROUND = 'controlBackground'
const KEY_COLOR_FOOTER_TEXT = 'footerText'
const KEY_COLOR_CONTROL_BORDER = 'controlBorder'
const KEY_COLOR_BOX_BORDER = 'boxBorder'

const colorArray = [
  {
    id: KEY_COLOR_PRIMARY,
    displayValue: 'Primary color',
    subtitle: 'Color used on all the elements around your website (Buttons, arrows, bars)'
  },
  {
    id: KEY_COLOR_SECONDARY,
    displayValue: 'Secondary color',
    subtitle: 'This color works as contrast of your primary color (Hover, links)'
  },
  {id: KEY_COLOR_BUTTON_TEXT, displayValue: 'Buttons text color'},
  {id: KEY_COLOR_HOME_SECTIONS_TEXT, displayValue: 'Text color'},
  {id: KEY_COLOR_NAVIGATION_BAR_TEXT, displayValue: 'Navigation Bar Text'},
  {id: KEY_COLOR_NAVIGATION_BAR_BACKGROUND, displayValue: 'Navigation Bar Background'},
  {id: KEY_COLOR_SITE_BACKGROUND, displayValue: 'Site Background'},
  {id: KEY_COLOR_BOX_BACKGROUND, displayValue: 'Box Background'},
  {id: KEY_COLOR_BOX_BORDER, displayValue: 'Box Border'},
]

const defaultColorLight = {
  [KEY_COLOR_PRIMARY]: COLOR_PRIMARY_DEFAULT,
  [KEY_COLOR_SECONDARY]: COLOR_SECONDARY_DEFAULT,
  [KEY_COLOR_BUTTON_TEXT]: COLOR_BUTTON_TEXT_DEFAULT,
  [KEY_COLOR_HOME_SECTIONS_TEXT]: COLOR_TEXT_DEFAULT_LIGHT,
  [KEY_BACKGROUND]: '#ffffff',
  [KEY_COLOR_SITE_BACKGROUND]: '#F4F4F4',
  [KEY_COLOR_NAVIGATION]: '#ffffff',
  [KEY_COLOR_NAVIGATION_BAR_BACKGROUND]: '#ffffff',
  [KEY_COLOR_NAVIGATION_BAR_TEXT]: COLOR_NAVIGATION_BAR_TEXT_DEFAULT_LIGHT,
  [KEY_COLOR_BOX_BACKGROUND]: '#ffffff',
  [KEY_COLOR_CONTROL_BACKGROUND]: '#ffffff',
  [KEY_COLOR_FOOTER_TEXT]: '#ffffff',
  [KEY_COLOR_CONTROL_BORDER]: '#CACACA',
  [KEY_COLOR_BOX_BORDER]: '#707070',
}

const defaultColorDark = {
  [KEY_COLOR_PRIMARY]: COLOR_PRIMARY_DEFAULT,
  [KEY_COLOR_SECONDARY]: COLOR_SECONDARY_DEFAULT,
  [KEY_COLOR_BUTTON_TEXT]: COLOR_BUTTON_TEXT_DEFAULT,
  [KEY_COLOR_HOME_SECTIONS_TEXT]: COLOR_TEXT_DEFAULT_DARK,
  [KEY_BACKGROUND]: '#2C2C2C',
  [KEY_COLOR_SITE_BACKGROUND]: '#222',
  [KEY_COLOR_NAVIGATION]: '#2C2C2C',
  [KEY_COLOR_NAVIGATION_BAR_BACKGROUND]: '#222222',
  [KEY_COLOR_NAVIGATION_BAR_TEXT]: COLOR_NAVIGATION_DEFAULT_DARK,
  [KEY_COLOR_BOX_BACKGROUND]: '#222222',
  [KEY_COLOR_CONTROL_BACKGROUND]: '#000000',
  [KEY_COLOR_FOOTER_TEXT]: '#E2E2E2',
  [KEY_COLOR_CONTROL_BORDER]: '#000000',
  [KEY_COLOR_BOX_BORDER]: '#707070',
}

const buildAnObjectExist = (color, defaultByTheme) => ({
  ...color,
  ...!color[KEY_COLOR_PRIMARY] && {[KEY_COLOR_PRIMARY]: defaultByTheme[KEY_COLOR_PRIMARY]},
  ...!color[KEY_COLOR_SECONDARY] && {[KEY_COLOR_SECONDARY]: defaultByTheme[KEY_COLOR_SECONDARY]},
  ...!color[KEY_COLOR_HOME_SECTIONS_TEXT] && {[KEY_COLOR_HOME_SECTIONS_TEXT]: defaultByTheme[KEY_COLOR_HOME_SECTIONS_TEXT]},
  ...!color[KEY_BACKGROUND] && {[KEY_BACKGROUND]: defaultByTheme[KEY_BACKGROUND]},
  ...!color[KEY_COLOR_SITE_BACKGROUND] && {[KEY_COLOR_SITE_BACKGROUND]: defaultByTheme[KEY_COLOR_SITE_BACKGROUND]},
  ...!color[KEY_COLOR_NAVIGATION] && {[KEY_COLOR_NAVIGATION]: defaultByTheme[KEY_COLOR_NAVIGATION]},
  ...!color[KEY_COLOR_NAVIGATION_BAR_BACKGROUND] && {[KEY_COLOR_NAVIGATION_BAR_BACKGROUND]: defaultByTheme[KEY_COLOR_NAVIGATION_BAR_BACKGROUND]},
  ...!color[KEY_COLOR_NAVIGATION_BAR_TEXT] && {[KEY_COLOR_NAVIGATION_BAR_TEXT]: defaultByTheme[KEY_COLOR_NAVIGATION_BAR_TEXT]},
  ...!color[KEY_COLOR_BOX_BACKGROUND] && {[KEY_COLOR_BOX_BACKGROUND]: defaultByTheme[KEY_COLOR_BOX_BACKGROUND]},
  ...!color[KEY_COLOR_CONTROL_BACKGROUND] && {[KEY_COLOR_CONTROL_BACKGROUND]: defaultByTheme[KEY_COLOR_CONTROL_BACKGROUND]},
  ...!color[KEY_COLOR_FOOTER_TEXT] && {[KEY_COLOR_FOOTER_TEXT]: defaultByTheme[KEY_COLOR_FOOTER_TEXT]},
  ...!color[KEY_COLOR_CONTROL_BORDER] && {[KEY_COLOR_CONTROL_BORDER]: defaultByTheme[KEY_COLOR_CONTROL_BORDER]},
  ...!color[KEY_COLOR_BOX_BORDER] && {[KEY_COLOR_BOX_BORDER]: defaultByTheme[KEY_COLOR_BOX_BORDER]}
})

const getDefaultValuesByTheme = (keyColor, color) => {
  if (keyColor === 'darkColor') {
    return buildAnObjectExist(color, defaultColorDark)
  }
  return buildAnObjectExist(color, defaultColorLight)
}

export const LIST_FONT_FAMILY = [
  {id: 'lato', displayValue: 'Lato, sans-serif'},
  {id: 'montserrat', displayValue: 'Montserrat, sans-serif'},
  {id: 'openSans', displayValue: 'Open Sans, sans-serif'},
  {id: 'roboto', displayValue: 'Roboto, sans-serif'},
  {id: 'raleway', displayValue: 'Raleway, sans-serif'},
  {id: 'robotoSlab', displayValue: 'Roboto Slab, sans-serif'}
]

const colorPaletteKeys = [KEY_COLOR_PRIMARY, KEY_COLOR_SECONDARY, KEY_COLOR_NAVIGATION_BAR_BACKGROUND]

export const DEFAULT_THEME_ID = 'light'

const themeArray = [
  {id: 'light', displayValue: 'Light theme', icon: 'ic_theme_light.svg'},
  {id: 'dark', displayValue: 'Dark theme', icon: 'ic_theme_dark.svg'},
]

/**** stateless component ****/
const DPColor = ({
                   color,
                   displayValue,
                   subTitle,
                   handleColor,
                   id,
                   showColorOption,
                   selectedColor,
                   closeColorOptions,
                   saveColorOptions,
                   selectColor,
                   presetColors = presetTextColors
                 }) => {
  return (
    <>
      <div id={id} key={id} className='row-color'>
        <div className='container-labels'>
          <label className='label-color'>{displayValue}</label>
          {subTitle && <label className='subtitle-color'>{subTitle}</label>}
        </div>
        <div className='color-button'
             style={
               color[id] ?
                 {
                   backgroundColor: color[id],
                   border: '1px solid ' + (color[id].toLowerCase() === '#ffffff' ? '#EFEFEF' : color[id])
                 } :
                 {border: '1px solid #EFEFEF'}
             }
             onClick={(event) => {
               if (colorPaletteKeys.includes(id))
                 handleColor(id, event)
               else
                 handleColor(color, id, event)
             }}
        />
      </div>
      {
        (showColorOption && selectedColor === id) &&
        <ColorOptions closeColorOptions={closeColorOptions} saveColorOptions={saveColorOptions}
                      selectColor={selectColor} presetColors={presetColors}/>
      }
    </>
  )
}

/**** stateless component ****/
const ColorOptions = ({selectColor, closeColorOptions, saveColorOptions, presetColors}) => {
  return (
    <div className='color-options-container'>
      <div className='color-options-border'>
        <div className='color-options'>
          {
            presetColors.map((color, index) => {
              return (
                <div key={index} className='color-option'
                     style={{
                       backgroundColor: color,
                       border: '1px solid #EFEFEF'
                     }}
                     onClick={(event) => selectColor(color, event)}
                />
              )
            })
          }
        </div>
        <div className='select-buttons'>
          <a className='btn color-cancel-button' onClick={closeColorOptions}>
            Cancel
          </a>
          <a className='btn color-select-button' onClick={saveColorOptions}>
            Select
          </a>
        </div>
      </div>
    </div>
  )
}

function getPresetColorByKey(key, presetSiteColors, presetBoxColors) {
  let presetColors
  if (key === KEY_COLOR_SITE_BACKGROUND) {
    presetColors = presetSiteColors
  } else if (key === KEY_COLOR_BOX_BACKGROUND || key === KEY_COLOR_BOX_BORDER) {
    presetColors = presetBoxColors
  }
  return presetColors
}

function getPresetColorsByThemeAndKey(currentTheme, key) {
  let presetColors
  if (currentTheme === DEFAULT_THEME_ID) {
    presetColors = getPresetColorByKey(key, presetSiteColors, presetBoxColors)
  } else {
    presetColors = getPresetColorByKey(key, presetSiteDarkColors, presetBoxDarkColors)
  }

  return presetColors
}

const PopoverContentBody = ({options, onClick, value, handlePopover}) => {
  const itemsToDisplay = options && options.map((option, index) => {
    let className = 'container-option-for-select'
    if (value === option.id) {
      className = 'container-option-for-select-checked'
    }
    return (
      <div
        key={index}
        id={'option' + index}
        className={className}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          handlePopover(false)
          onClick(option.id)
        }}
      >
        <label className='option-label-for-select'>
          {option.displayValue}
        </label>
      </div>
    )
  })

  return (
    <div className='popover-body-for-select'>
      <div
        id='options-make'
        className='options-for-select'
      >
        {itemsToDisplay}
      </div>
    </div>
  )
}


/*** stateful component ***/
const SettingStyle = ({generalSetting, onChange}) => {

  const [showColorPalette, setShowColorPalette] = useState(false)
  const [showColorOption, setShowColorOption] = useState(false)
  const [selectedColor, setSelectedColor] = useState('')
  const [positionY, setPositionY] = useState(0)
  const [previousColorSelected, setPreviousColorSelected] = useState('')
  const [colorOptionIsOpen, setColorOptionIsOpen] = useState(false)
  const {typography, currentTheme = DEFAULT_THEME_ID} = generalSetting
  const keyColor = currentTheme === DEFAULT_THEME_ID ? 'color' : 'darkColor'
  const [showPopover, setShowPopover] = useState(false)

  let jsonColor = getDefaultValuesByTheme(keyColor, generalSetting[keyColor] ?? {})
  const popoverOverlay = useRef(null)

  useEffect(() => {
    let generalSettingNew = {...generalSetting, [keyColor]: jsonColor, currentTheme: currentTheme}
    //if not exist dark key
    let darkColor = generalSetting.darkColor
    if (!darkColor || isEmpty(darkColor)) {
      generalSettingNew = {...generalSettingNew, darkColor: getDefaultValuesByTheme('darkColor', {})}
    }
    onChange(generalSettingNew)
  }, [])


  const popover = {
    position: 'absolute',
    zIndex: '2',
    left: 0,
    top: positionY
  }

  const handleColorDefault = (value) => {
    jsonColor.default = !value
    const generalSettingNew = {...generalSetting, [keyColor]: jsonColor}
    onChange(generalSettingNew)
  }

  const handleChangeColor = (color, key) => {
    jsonColor[key] = color.hex
    const generalSettingNew = {...generalSetting, [keyColor]: jsonColor}
    onChange(generalSettingNew)
  }

  const handleColorClose = (selectedColor) => {
    let row = document.getElementById(selectedColor)
    if (row)
      row.style.height = 'auto'
    unstable_batchedUpdates(() => {
      setShowColorPalette(false)
      setSelectedColor('')
    })
  }

  const handleColorOptions = (color, id, event) => {
    event.preventDefault()
    unstable_batchedUpdates(() => {
      setShowColorOption(!showColorOption)
      setSelectedColor(id)
      setPreviousColorSelected(color[id])
    })
    changeColorOptionIsOpen(color[id], id)
  }

  const handleColorPalette = (id, event) => {
    const pointTo = getPositionById(id)
    unstable_batchedUpdates(() => {
      setShowColorPalette(true)
      setPositionY((pointTo.y + 14))
      /*** 14 is the middle of height button color (28px) ***/
      setSelectedColor(id)
    })
  }

  const saveColorOptions = (event) => {
    event.preventDefault()
    unstable_batchedUpdates(() => {
      setShowColorOption(false)
      setSelectedColor('')
      setPreviousColorSelected('')
    })
    changeColorOptionIsOpen('')
  }

  const selectColor = (color, event) => {
    jsonColor[selectedColor] = color
    const generalSettingNew = {...generalSetting, [keyColor]: jsonColor}
    onChange(generalSettingNew)
  }

  const changeColorOptionIsOpen = (colorPreviousSelected, selectedColor) => {
    unstable_batchedUpdates(() => {
      setSelectedColor(selectedColor)
      setPreviousColorSelected(colorPreviousSelected)
      setColorOptionIsOpen(!colorOptionIsOpen)
    })
  }

  const closeColorOptions = (event) => {
    event.preventDefault()
    unstable_batchedUpdates(() => {
      selectColor(previousColorSelected)
      setShowColorOption(false)
      setSelectedColor('')
      setPreviousColorSelected('')
    })
    changeColorOptionIsOpen('')
  }

  const changeTheme = (themeId) => {
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.currentTheme = themeId
    onChange(generalSettingNew)
  }

  const selectSource = (id) => {
    const sourceSelected = LIST_FONT_FAMILY.find(x => x.id === id)
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.typography.title = sourceSelected.id
    onChange(generalSettingNew)
  }

  return (
    <div className='container-setting-style'>
      <section className='theme-section'>
        {
          themeArray.map((itemTheme, index) => {
            return (
              <div key={`theme-section-${index}`} className='container-theme'>
                <ReactSVG src={`${pathServer.PATH_IMG}icon/${itemTheme.icon}`} className='icon-theme-content'
                          beforeInjection={svg => svg.classList.add('icon-theme-svg')}/>
                <DPCheckbox
                  checked={currentTheme === itemTheme.id}
                  id={itemTheme.id}
                  onCheck={() => changeTheme(itemTheme.id)}
                  label={itemTheme.displayValue}
                  labelPlacement='end'
                />
              </div>
            )
          })
        }
      </section>
      <section className='color-section'>
        <>
          <span className='title-section'>Colors</span>
          {/*<div className="row-color-default">
            <label className="label-color-default">Default Colors</label>
            <DPToggle
              id='colorSwitch'
              handleCheck={() => handleColorDefault(jsonColor.default ?? true)}
              isChecked={jsonColor.default}
              testId={'color-switch'}
              className='color-switch'
            />
          </div>*/}
          <div className='body-colors'>
            {
              colorArray.map((itemColor, index) => {
                //console.log("itemColor*****",itemColor)
                return (
                  <DPColor color={jsonColor}
                           displayValue={itemColor.displayValue}
                           handleColor={colorPaletteKeys.includes(itemColor.id) ? handleColorPalette : handleColorOptions}
                           id={itemColor.id}
                           key={itemColor.id}
                           selectedColor={selectedColor}
                           showColorOption={showColorOption}
                           closeColorOptions={closeColorOptions}
                           saveColorOptions={saveColorOptions}
                           selectColor={selectColor}
                           subTitle={itemColor.subtitle}
                           presetColors={getPresetColorsByThemeAndKey(currentTheme, itemColor.id)}
                  />
                )
              })
            }
            {
              showColorPalette &&
              <div key='colorPalette' id='colorPalette' className='color-palette' style={popover}>
                <div style={cover} onClick={() => handleColorClose(selectedColor)}/>
                <SketchPicker
                  color={jsonColor[selectedColor]}
                  presetColors={presetColors}
                  onChangeComplete={(color, event) => handleChangeColor(color, selectedColor)}
                />
              </div>
            }
          </div>
        </>
      </section>
      <section className='typography-section'>
        <span className='title-section'>Typography</span>
        <div className='body-typography'>
          <div className='dp-select-dropdown row-typo'>
            <div className='select' ref={popoverOverlay} onClick={() => {
              setShowPopover(!showPopover)
            }}>
              <span>{typography.title ?? ''}</span>
              <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_expand_more.svg'}
                        className='icon-arrow-content'
                        beforeInjection={svg => {
                          svg.classList.add('icon-arrow-expand')
                          svg.setAttribute('style', `transform: rotate( ${showPopover ? `180deg` : '0'})`)
                        }}
              />
            </div>
          </div>
        </div>
        <DPOverlay anchorEl={popoverOverlay.current}
                   show={showPopover}
                   className={classnames('dp-tooltip', 'typography-popover')}
                   onHide={() => setShowPopover(false)}
                   triggerYToHide={84}
                   fullWidth={true}
        >
          <PopoverContentBody onClick={selectSource} options={LIST_FONT_FAMILY} value={typography.title ?? ''}
                              handlePopover={setShowPopover}/>
        </DPOverlay>
      </section>
    </div>
  )
}

export default SettingStyle