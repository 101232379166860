import {Draggable} from 'react-beautiful-dnd'
import React from 'react'
import PropTypes from 'prop-types'

const getItemStyle = (isDragging, draggableStyle, draggableStyleCustomized) => {
  return {...draggableStyleCustomized, ...draggableStyle}
};

const DPDraggable = (props) => {
  const {itemId,index,draggableStyle,children, otherDivProps} = props;
  return(
    <Draggable
      draggableId={itemId}
      index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            draggableStyle
          )}
          {...otherDivProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  )
};

DPDraggable.defaultProps = {
  draggableStyle: {
    userSelect: 'none',
    marginBottom: 20
  }
};


DPDraggable.propTypes = {
  itemId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  draggableStyle: PropTypes.object,
  otherDivProps: PropTypes.object,
};

export default DPDraggable;