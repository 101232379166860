import React from 'react'
import DPButtonIcon from '../../common/DPButtonIcon'
import {ReactSVG} from 'react-svg'
import DPCheckDeployItem from '../../../common/DPCheckDeployItem'
import DPSelectionDropDown from '../../common/DPSelectionDropDown'
import DPRatingBarCount from '../../../common/DPRatingBarCount'
import SocialNetworkReview from './SocialNetworkReview'
import DPStarsReview from '../../../common/DPStarsReview'
import {browserHistory} from 'react-router'
import {EmptyFunc, pathServer} from '../../../common/Constants'

require('./DashboardReviews.scss')

const StarIconArray = ({qualification}) => {
  let iterator = 0
  let startIconDisplay = []
  while(iterator < 5){
    if(iterator < qualification){
      startIconDisplay.push(<ReactSVG key={`star${iterator}`} src={`${pathServer.PATH_IMG}icon/ic_star_review.svg`} className='icon-star-review'
                                      beforeInjection={ svg => { svg.setAttribute('style', `fill: #D7DAE2`); }} />)
    }else{
      startIconDisplay.push(<ReactSVG key={`star${iterator}`} src={`${pathServer.PATH_IMG}icon/ic_star_review.svg`} className='icon-star-review'/>)
    }
    iterator++
  }
  return (
    <>
      {startIconDisplay}
    </>
  )
}

const deployItemList = [
  {id:'1', text:'Dealer basic information', active:true},
  {id:'2', text:'Import clients information', active: false},
  {id:'3', text:'Set up your dealer locations', active: false},
  {id:'4', text:'Set up your team', active: false},
  {id:'5', text:'Set your default messages', active: false},
]

const deployRatingList = [
  {id:'1', text:'5', progress:0},
  {id:'2', text:'4', progress:0},
  {id:'3', text:'3', progress:0},
  {id:'4', text:'2', progress:0},
  {id:'5', text:'1', progress:0},
]


const Dashboard = ({height}) => {

  const onClickButton = (id) => {
      browserHistory.push({pathname: `/main/reviews/dashboard/${id}`, state: {showSendMailModal: true}});
  }

  return (
    <div id="DashboardContainer" className="container-component container-review-page" style={{height:height, float:'left', backgroundColor:'#f3f4f8'}}>
      <div className="container-first-row" >
        <div className="welcome-section-root" >
          <div className="welcome-section" >
            <div className="info-welcome" >
              <div className="title-description">
                <label className="title-description1" >Welcome<br/> to your</label>
                <label className="title-description2" >new tool</label>
              </div>
              <div className="button-icon-container">
                <DPButtonIcon
                  background={'#2194F1'}
                  btnSaveText={"Watch demo"}
                  iconSendPath={'Icon awesome-play.svg'}
                  borderColor={'transparent'}
                  textColor={'#FFF'}
                  buttonClass={'button-watch-demo'}
                />
              </div>
            </div>
            <div className="container-image" >
              <div className="image-welcome" >
                <img src={`${pathServer.PATH_IMG}guy_with_tools.png`} />
              </div>
            </div>
          </div>
        </div>
        <div className="steps-referrals-section-root" >
          <div className="steps-referrals-section" >
            <div className="few-steps-section" >
              <div className='step-item'>
                <span className="title-step-item" >
                  <label className="title-step-item-1" >Just few step and</label>
                  <div className="description">
                    <label className="title-step-item-2">you're</label>
                    <label className="title-step-item-3"> ready.</label>
                  </div>
                </span>
                {
                  deployItemList.map((item)=> {
                    const {text, active} = item
                    return (
                      <DPCheckDeployItem
                        complete={active}
                        title={text}
                      />
                    )
                  } )
                }
              </div>
              <div className="container-help-us">
                <label className='help-text-description'>Need help?</label>
                <label className='link-help-text-description'>Text us</label>
              </div>
            </div>
            <div className="referrals-section" >
              <div className="referrals-request-root" >
                <div className="referrals-request" >
                  <div className="title-referrals-request">
                    <label className="title-referral-sent" >Referrals request sent</label>
                    <ReactSVG
                      src={`${pathServer.PATH_IMG}/icon/ic_right_arrow.svg`}
                      className='right-svg-request-container'
                    />
                  </div>

                  <div className="referral-content-request" >
                    <div className="points-request" >
                      <label className="number-request">00</label>
                      <span className="arrow-percentage-container">
                        <ReactSVG
                          src={`${pathServer.PATH_IMG}/icon/ic_arrow_upward.svg`}
                          className='up-arrow-percentage'
                        />
                        <label className="percentage-request" >00.0%</label>
                      </span>
                    </div>
                    <div className="image-request">
                      <img src={`${pathServer.PATH_IMG}Bar Chart.svg`} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="referrals-received-root" >
                <div className="referrals-received" >
                  <div className="title-referrals-reception">
                    <label className="title-referral-received" >Received</label>
                    <ReactSVG
                      src={`${pathServer.PATH_IMG}/icon/ic_right_arrow.svg`}
                      className='right-svg-received-container'
                    />
                  </div>

                  <div className="referral-content-received" >
                    <div className="points-received" >
                      <label className="number-received">00</label>
                      <span className="arrow-percentage-container-1">
                        <ReactSVG
                          src={`${pathServer.PATH_IMG}/icon/ic_arrow_upward.svg`}
                          className='up-arrow-percentage-1'
                        />
                        <label className="percentage-received">00.0%</label>
                      </span>
                    </div>
                    <div className="image-received">
                      <img src={`${pathServer.PATH_IMG}Bar Chart-1.svg`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-second-row" >
        <div className="reviews-gained-section-root" >
          <div className="reviews-gained-section" >
            <div className="reviews-gained-left-side" >
              <label className="title-gained" >Reviews gained</label>
              <img src={`${pathServer.PATH_IMG}Grupo 9988.png`} />
            </div>
            <div className="reviews-gained-right-side" >
              <div className="dropdown-container">
                <DPSelectionDropDown className="select-current-month" id='starRangeTo' placeholder='Current month' options={[{id:'january', displayValue:'January'},{id:'february', displayValue: 'February'}]} value={null} onClick={EmptyFunc}/>
              </div>
              <div className="description-gained">
                <label className="description-gained-1" >Start receiving</label>
                <div className="description-gained-sub">
                  <label className="description-gained-2" >reviews by</label>
                  <label className="description-gained-3">sharing</label>
                </div>
                <label className="description-gained-4">your review request.</label>
              </div>
              <div className="button-share-now-container">
                <DPButtonIcon
                  background={'#2194F1'}
                  btnSaveText={"Share now"}
                  iconSendPath={'ic_sharingf.svg'}
                  borderColor={'transparent'}
                  textColor={'#FFF'}
                  buttonClass={'button-share-now'}
                  onClick={() => onClickButton('review')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="reviews-summary-section-root" >
          <div className="reviews-summary-section" >
            <div className="reviews-summary-top" >
              <label className="reviews-summary-title">Reviews summary</label>
              <div className="reviews-summary-stars" >
                <div className="number-reviews"><label className="reviews-summary-stars-punctuation">0.0</label></div>
                <div className="reviews-summary-stars-container" >
                  <div className="stars" >
                    <StarIconArray qualification={5}/>
                  </div>
                  <label className="no-reviews-yet" >No reviews yet</label>
                </div>
              </div>
            </div>
            <div className="reviews-summary-bottom">
              <div className="rating-container">
                {
                  deployRatingList.map((item)=> {
                    const {text, progress} = item
                    return (
                      <DPRatingBarCount
                        text={text}
                        completed={progress}
                      />
                    )
                  } )
                }
              </div>
              <div className="social-network-container">
                  <SocialNetworkReview  pathIcon={'ic_facebook_color.svg'} text={'Facebook reviews'} showToggle={false}/>
                  <DPStarsReview/>
                  <SocialNetworkReview  pathIcon={'ic_google_color.svg'} text={'Google reviews'} showToggle={false}/>
                  <DPStarsReview/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard