import React, {Component} from 'react'
import {
  SEO_CODES,
} from '../../../Constants'
import {ReactSVG} from 'react-svg'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import CustomSEOModal from './CustomSEOModal'
import {MODAL_TYPE, META} from './CustomSEOModal'
import {
  loadDealerConfigurationByCodes,
  loadSEOInfoApi,
  saveSEOInfoAPI,
  saveSEOInfoListAPI,
  updateDealerConfigurationValues
} from '../../../Api'
import find from 'lodash/find'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'

const SECTIONS = {
  STEP01: 'step01',
  STEP02: 'step02',
}

const ITEMS = {
  IT01: 'item01',
  IT02: 'item02',
  IT03: 'item03',
}

const MODAL_TYPE_SECTIONS = {
  [SECTIONS.STEP01]: {
    [ITEMS.IT01]: MODAL_TYPE.SET_HOMEPAGE_TITLE,
    [ITEMS.IT02]: MODAL_TYPE.SET_HOMEPAGE_DESCRIPTION,
    [ITEMS.IT03]: MODAL_TYPE.OPTIMIZE_SITE,
  },
  [SECTIONS.STEP02]: {
    [ITEMS.IT01]: MODAL_TYPE.ADD_CONTACT_DETAILS,
    [ITEMS.IT02]: MODAL_TYPE.CONNECT_SOCIAL_NETWORKS,
    [ITEMS.IT03]: MODAL_TYPE.ADD_LINKS,
  }
}

const CustomHorizLine = () => {
  return (
    <hr className='custom-horiz-line'/>
  )
}

const SEOSectionHeader = (props) => {
  const {completedItemsTotal, itemsTotal} = getCompletedItemsNum(props.completedItemSection)
  const progressValue = (completedItemsTotal * (100 / itemsTotal)).toString()

  return (
    <div className='seo-section-header'>
      {
        !isEmpty(props.title)
        && <div className='header-title-container'>
          <b>{props.title}</b>
        </div>
      }
      <div className='sub-title-container'>
        <div className='sub-title-text'><b>{props.subtitle}</b></div>
        <progress
          id="file"
          max="100"
          value={progressValue}
          className='progress-bar-container'
        >
          {`${progressValue}%`}
        </progress>
        <div className='progress-bar-label'>{`${completedItemsTotal}/${itemsTotal}`}</div>
      </div>
      <div className='header-message-container'>
        {props.message}
      </div>
    </div>
  )
}

const SEOItem = (props) => {
  const leftIconSrc = props.isCompleted
    ? `${pathServer.PATH_IMG}/icon/check_icon.svg`
    : `${pathServer.PATH_IMG}/icon/error_icon.svg`

  return (
    <div
      className='seo-section-item'
      onClick={props.onClick}
    >
      <ReactSVG
        src={leftIconSrc}
        className='left-svg-image-container'
        beforeInjection={svg => svg.classList.add('left-icon-svg')}
      />
      <div className='seo-item-text'>
        {props.text}
      </div>
      <ReactSVG
        src={`${pathServer.PATH_IMG}/icon/ic_right_arrow.svg`}
        className='right-svg-image-container'
        beforeInjection={svg => svg.classList.add('right-icon-svg')}
      />
    </div>
  )
}

function getCompletedItemsNum(completedItems) {
  let itemsTotal = 0
  let completedItemsTotal = 0

  const iterator = keys(completedItems)

  for (const key of iterator) {
    if (completedItems[key]) {
      completedItemsTotal++
    }
    itemsTotal++
  }

  return ({
    itemsTotal, completedItemsTotal
  })
}


export default class SettingSEOInfo extends Component {
  constructor(){
    super()
    this.state = {
      showModal: false,
      isCompleted: {
        [SECTIONS.STEP01]: {
          [ITEMS.IT01]: false,
          [ITEMS.IT02]: false,
          [ITEMS.IT03]: false,
        },
        [SECTIONS.STEP02]: {
          [ITEMS.IT01]: false,
          [ITEMS.IT02]: false,
          [ITEMS.IT03]: false,
        }
      },
      metaInfo: {
        [META.PRE_TITLE]: '',
        [META.DESCRIPTION]: '',
        [META.KEYWORDS]: [],
        [META.DEALER_NAME]: '',
      },
      seoInfoList: [],
    }
  }

  async componentDidMount() {
    await this.loadInitialInfo()
  }

  loadInitialInfo = async () => {
    const {isCompleted} = this.state
    const {responseCode, responseMessage, data} = await loadSEOInfoApi()
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      return
    }

    const seoInfoList = data.seoInfo
    const title = find(seoInfoList, el => el.metadataType === META.TITLE)
    const description = find(seoInfoList, el => el.metadataType === META.DESCRIPTION)
    const keywords = find(seoInfoList, el => el.metadataType === META.KEYWORDS)
    const preTitleIndex = get(title, ['metadataValue'], '').indexOf('|')
    const preTitle = get(title, ['metadataValue'], '').substring(0, preTitleIndex).trim()
    const dealerName = get(title, ['metadataValue'], '').substring(preTitleIndex + 1).trim()

    const metaInfo = {
      [META.PRE_TITLE]: preTitle,
      [META.DESCRIPTION]: description && description.metadataValue,
      [META.KEYWORDS]: (keywords && keywords.metadataValue.length > 0 && keywords.metadataValue.split(',') || []),
      [META.DEALER_NAME]: dealerName,
      [META.TITLE_ID]: get(title, ['id'], ''),
      [META.DESCRIPTION_ID]: get(description, ['id'], ''),
      [META.KEYWORDS_ID]: get(keywords, ['id'], '')
    }

    const codes = `${SEO_CODES.OPTIMIZE_SITE},${SEO_CODES.ADD_CONTACT_DETAILS},${SEO_CODES.CONNECT_SOCIAL_NETWORKS},${SEO_CODES.ADD_LINKS}`
    const {responseCode: responseCodeDC, responseMessage: responseMessageDC, data: dataDC} = await loadDealerConfigurationByCodes(codes)
    if (responseCodeDC !== SUCCESSFUL_CODE) {
      this.setState({seoInfoList})
      console.error(responseMessageDC)
      return
    }
    const settingsList = dataDC.dealerConfigurations ?? []
    const getValueCode = (code) => {
      return (get(find(settingsList, el => el.code === code), ['value'], 'false') === 'true')
    }

    const isCompletedToUpdate = {
      ...isCompleted,
      [SECTIONS.STEP01]: {
        ...isCompleted[SECTIONS.STEP01],
        [ITEMS.IT01]: !!(dealerName && get(keywords, ['metadataValue'], '') && preTitle),
        [ITEMS.IT02]: !!(get(description, ['metadataValue'], '')),
        [ITEMS.IT03]: getValueCode(SEO_CODES.OPTIMIZE_SITE),
      },
      [SECTIONS.STEP02]: {
        ...isCompleted[SECTIONS.STEP02],
        [ITEMS.IT01]: getValueCode(SEO_CODES.ADD_CONTACT_DETAILS),
        [ITEMS.IT02]: getValueCode(SEO_CODES.CONNECT_SOCIAL_NETWORKS),
        [ITEMS.IT03]: getValueCode(SEO_CODES.ADD_LINKS),
      }
    }

    this.setState({
      showModal: false,
      seoInfoList,
      metaInfo,
      isCompleted: isCompletedToUpdate
    })
  }

  handleClickOnSectionItem = (section, itemOnSection) => {
    this.setState((prevState) => {
      return ({
        showModal: true,
        selectedSection: section,
        selectedItemOnSection: itemOnSection,
      })
    })
  }

  handleCloseModal = async () => {
    await this.loadInitialInfo()
  }

  handleSaveAndCloseModal = async (section, itemOnSection) => {
    const {metaInfo} = this.state
    let isCompletedSection = true

    if (section === SECTIONS.STEP01 && itemOnSection === ITEMS.IT01) {
      // Determines if current section is completed
      if (!(metaInfo[META.DEALER_NAME] && metaInfo[META.KEYWORDS].length > 0)) {
        isCompletedSection = false
      }

      const title =
        metaInfo[META.PRE_TITLE]
          ? `${metaInfo[META.PRE_TITLE]} | ${metaInfo[META.DEALER_NAME]}`
          : metaInfo[META.DEALER_NAME]

      const seoInfoToUpdateTitle = {
        id: metaInfo[META.TITLE_ID] || null,
        metadataType: META.TITLE,
        metadataValue: title,
        status: 'active',
      }

      const seoInfoToUpdateKeyword = {
        id: metaInfo[META.KEYWORDS_ID] || null,
        metadataType: META.KEYWORDS,
        metadataValue: metaInfo[META.KEYWORDS].toString(),
        status: 'active',
      }

      const seoInfoListToUpdate = {
        seoInfoData: [seoInfoToUpdateTitle, seoInfoToUpdateKeyword]
      }

      const {responseCode, responseMessage} =  await saveSEOInfoListAPI(seoInfoListToUpdate)
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
      }
    }

    if (section === SECTIONS.STEP01 && itemOnSection === ITEMS.IT02) {
      // Determines if current section is completed
      if (!metaInfo[META.DESCRIPTION]) {
        isCompletedSection = false
      }

      const seoInfoToUpdate = {
        id: metaInfo[META.DESCRIPTION_ID] || null,
        metadataType: META.DESCRIPTION,
        metadataValue: metaInfo[META.DESCRIPTION],
        status: 'active',
      }

      const {responseCode, responseMessage} =  await saveSEOInfoAPI(seoInfoToUpdate)
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
      }
    }

    if (section === SECTIONS.STEP01 && itemOnSection === ITEMS.IT03) {
      const dealerConfiguration = [
        {code: SEO_CODES.OPTIMIZE_SITE, value: 'true'}
      ]

      const {responseCode, responseMessage} =  await updateDealerConfigurationValues({dealerConfiguration})
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
      }
    }

    if (section === SECTIONS.STEP02 && itemOnSection === ITEMS.IT01) {
      const dealerConfiguration = [
        {code: SEO_CODES.ADD_CONTACT_DETAILS, value: 'true'}
      ]

      const {responseCode, responseMessage} = await updateDealerConfigurationValues({dealerConfiguration})
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
      }
    }

    if (section === SECTIONS.STEP02 && itemOnSection === ITEMS.IT02) {
      const dealerConfiguration = [
        {code: SEO_CODES.CONNECT_SOCIAL_NETWORKS, value: 'true'}
      ]

      try {
        await updateDealerConfigurationValues({dealerConfiguration})
      } catch (e) {
        console.error(e)
      }
    }

    if (section === SECTIONS.STEP02 && itemOnSection === ITEMS.IT03) {
      const dealerConfiguration = [
        {code: SEO_CODES.ADD_LINKS, value: 'true'}
      ]

      try {
        await updateDealerConfigurationValues({dealerConfiguration})
      } catch (e) {
        console.error(e)
      }
    }

    this.setState((prevState) => {
      return ({
        showModal: false,
        isCompleted: {
          ...prevState.isCompleted,
          [section]: {
            ...prevState.isCompleted[section],
            [itemOnSection]: isCompletedSection,
          }
        }
      })
    })
  }

  handleChange = (metaType, value) => {
    this.setState((prevState) => {
      return ({
        metaInfo: {
          ...prevState.metaInfo,
          [metaType]: value,
        }
      })
    })
  }

  handleDeleteKeyword = (index, e) => {
    e.stopPropagation()
    this.setState((prevState) => {
      let updatedKeywords = [...get(prevState, ['metaInfo', META.KEYWORDS], [])]
      let updatedMetaPreTitle = ''

      // Determining what Keyword we have to use as PreTitle
      if (updatedKeywords.length > 1) {
        updatedMetaPreTitle = index === 0 ? updatedKeywords[1] : updatedKeywords[0];
      }

      updatedKeywords.splice(index, 1)

      return ({
        metaInfo: {
          ...prevState.metaInfo,
          [META.KEYWORDS]: updatedKeywords,
          [META.PRE_TITLE]: updatedMetaPreTitle,
        }
      })
    })
  }

  onKeyUp = (event, func) => {
    event.persist()

    if (event.key === 'Enter' && event.keyCode === 13) {
      this.setState((prevState) => {
        const tempKeyword = get(prevState, ['metaInfo', META.TEMP_KEYWORD], '')
        let updatedKeywords = get(prevState, ['metaInfo', META.KEYWORDS], [])
        let updatedMetaPreTitle = get(prevState, ['metaInfo', META.PRE_TITLE], '')

        if (tempKeyword) {
          // Forces to use the first new keyword as PreTitle
          if (!updatedKeywords.length) {
            updatedMetaPreTitle = tempKeyword
          }

          // Added new keyword into list of keywords
          updatedKeywords = updatedKeywords.concat([tempKeyword])
        }

        return ({
          metaInfo: {
            ...prevState.metaInfo,
            [META.KEYWORDS]: updatedKeywords,
            [META.TEMP_KEYWORD]: '',
            [META.PRE_TITLE]: updatedMetaPreTitle,
          }
        })
      }, func && func())
    }
  }

  render() {
    const {isCompleted, showModal, selectedSection, selectedItemOnSection, metaInfo} = this.state

    return (
      <div>
        <div className="setting-seo-container">
          <SEOSectionHeader
            completedItemSection={isCompleted[SECTIONS.STEP01]}
            title={'SEO Setup checklist'}
            subtitle={'Step 1: Get your homepage ready for Google Search'}
            message={'Help get your site found online by completing these essential tasks.'}
          />
          <SEOItem
            text={'Set the homepage’s title for search result'}
            isCompleted={isCompleted[SECTIONS.STEP01][ITEMS.IT01]}
            onClick={() => this.handleClickOnSectionItem(SECTIONS.STEP01, ITEMS.IT01)}
          />
          <SEOItem
            text={'Add the homepage’s description for search results'}
            isCompleted={isCompleted[SECTIONS.STEP01][ITEMS.IT02]}
            onClick={() => this.handleClickOnSectionItem(SECTIONS.STEP01, ITEMS.IT02)}
          />
          <SEOItem
            text={'Optimize your site for mobile devices'}
            isCompleted={isCompleted[SECTIONS.STEP01][ITEMS.IT03]}
            onClick={() => this.handleClickOnSectionItem(SECTIONS.STEP01, ITEMS.IT03)}
          />

          <CustomHorizLine/>

          <SEOSectionHeader
            completedItemSection={isCompleted[SECTIONS.STEP02]}
            subtitle={'Step 2: Optimize your site pages for search engines'}
            message={'Complete these tasks to make your site pages easier to find in search results.'}
          />
          <SEOItem
            text={'Add contact details to your homepage'}
            isCompleted={isCompleted[SECTIONS.STEP02][ITEMS.IT01]}
            onClick={() => this.handleClickOnSectionItem(SECTIONS.STEP02, ITEMS.IT01)}
          />
          <SEOItem
            text={'Connect social networks to your homepage'}
            isCompleted={isCompleted[SECTIONS.STEP02][ITEMS.IT02]}
            onClick={() => this.handleClickOnSectionItem(SECTIONS.STEP02, ITEMS.IT02)}
          />
          <SEOItem
            text={'Add links to your homepage'}
            isCompleted={isCompleted[SECTIONS.STEP02][ITEMS.IT03]}
            onClick={() => this.handleClickOnSectionItem(SECTIONS.STEP02, ITEMS.IT03)}
          />
        </div>

        <CustomSEOModal
          showModal={showModal}
          closeModal={this.handleCloseModal}
          saveAndCloseModal={() => this.handleSaveAndCloseModal(selectedSection, selectedItemOnSection)}
          modalType={get(MODAL_TYPE_SECTIONS, [selectedSection, selectedItemOnSection], null)}
          onChange={this.handleChange}
          metaInfo={metaInfo}
          onDeletePill={this.handleDeleteKeyword}
          onKeyUp={this.onKeyUp}
        />
      </div>
    )
  }
}