import React from 'react'
import FooterSection from "./components/footer/FooterSection";

const AppMarketing = (props) => {

  return(
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'auto'}}>
      {React.cloneElement(props.children, {})}
      <FooterSection />
    </div>
  )

}

export default AppMarketing