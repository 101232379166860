import React, {Component} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import {browserHistory} from 'react-router'
import Forms from './formSetting/Forms.jsx'
import {connect} from 'react-redux'
import {ReactSVG} from 'react-svg'
import {
  MENU_SETTING_PERMISSION_ID,
  ROLES_SETTING_PERMISSION_ID,
  USERS_SETTING_PERMISSION_ID,
} from '../../Constants'
import {Popover} from '../util/ComponentUtils'
import UserHasPermission from '../UserHasPermission.jsx'
import {getPosition} from '../../../common/Util'
import {mergeArrays} from '../util/Utils'
import {pathServer, WIDTH_VIEW_TABLET} from '../../../common/Constants'
import * as FeatureFlags  from '../../FeatureFlags'

const tabIds = {
  BILLING_PAYMENT: 'billingPayment',
  FORM: 'form',
}

const optionUser = [
  {key:"user", permissionId: USERS_SETTING_PERMISSION_ID, displayValue:"Users"},
  {key:"rol", permissionId: ROLES_SETTING_PERMISSION_ID, displayValue:"Roles"},
];

const optionForm = [
  {key:"form", permissionId: "", displayValue:"Forms"},
  // {key:"contract", permissionId: "", displayValue:"Contracts"},
  {key:"attribute", permissionId: "", displayValue:"Attributes"}
];

const OPTION_MARKETING = [
  {key:"marketing", permissionId: "", displayValue:"Marketing"},
  {key:"seo", permissionId: "", displayValue:"SEO"},
]


const TabHeader = ({id,displayValue,tabRef,showIcon,onChangeTab,handlePopover,handleTabChange,options,handleRequestClose,anchorEl}) => {
  const child = (
    <div id={id} className={"popover-basic popover-setting"}>
      <ul>
        {
          options.map((option, index) => {
            return(
              <UserHasPermission key={`use-permission-${index}`} permissionId={option.permissionId}>
                <li key={option.key} onClick={(evt) => handleTabChange(id,option.key,option.displayValue,evt)}>
                  {option.displayValue}
                </li>
              </UserHasPermission>
            )
          })
        }
      </ul>
    </div>
  );

  return(
    <div key={id} className="tab-setting-title" ref={el => tabRef = el}>
      <label onClick={() => onChangeTab(id)}>
        {displayValue}
      </label>
      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"} className="icon-expand-content"/>
      <div className="container-popover">
        <Popover anchorEl={anchorEl}
                 child={child}
                 classPopover=""
                 handleRequestClose={handleRequestClose}

        />
      </div>
    </div>
  )
};

const GeneralTab = ({id, title, onChangeTab}) => {
  return (
    <div className="tab-setting-title">
      <label onClick={() => onChangeTab(id)} data-test={`tab-${id}`}>{title}</label>
    </div>
  )
}

const GeneralTabWithPermission = ({id, title, permissionId, onChangeTab}) =>  {
  const description = <UserHasPermission permissionId={permissionId}>
    <div className="tab-setting-title">
      <label onClick={() => onChangeTab(id)}>{title}</label>
    </div>
  </UserHasPermission>
  return description
}

const getClassName = (tabKeySelected, tab) => {
  let className = tab.className
  if(tabKeySelected === "user" || tabKeySelected === "rol"){
    if(tab.id === "user")
      className = "tab-user active"
  }else if( tabKeySelected === "form" || tabKeySelected === "contract" || tabKeySelected === "attribute"){
    if(tab.id === "form")
      className = "tab-form active"
  }else if( tabKeySelected === "marketing" || tabKeySelected === "seo"){
    if(tab.id === "marketing")
      className = "tab-marketing active"
  }
  return className
}

const tabsWeb = [
  // {id: "general", title: "General Settings", className:"tab-general general-setting-style", permissionId:MENU_SETTING_PERMISSION_ID},
  {id: "inventory", title: "Inventory", className:"tab-inventory", permissionId:MENU_SETTING_PERMISSION_ID},
  {id: "location", title: "Locations", className:"tab-location", permissionId:MENU_SETTING_PERMISSION_ID},
  // {id: "customer", title: "Customers", className:"tab-customer", permissionId:MENU_SETTING_PERMISSION_ID},
  {id: "user", title: "Users", className:"tab-user", permissionIds:[USERS_SETTING_PERMISSION_ID, ROLES_SETTING_PERMISSION_ID]},
  {id: tabIds.FORM, title: "Forms", className:"tab-form", permissionId:MENU_SETTING_PERMISSION_ID},
  {id: "template", title: "Themes", className:"tab-template", permissionId:MENU_SETTING_PERMISSION_ID},
  {id: "marketing", title: "Marketing", className:"tab-marketing", permissionId:MENU_SETTING_PERMISSION_ID},
  {id: 'sales', title: 'Sales', className: 'tab-sales', permissionId:MENU_SETTING_PERMISSION_ID},
  {id: tabIds.BILLING_PAYMENT, title: 'Billing and Payment', className: 'tab-general general-setting-style tab-billing-payment', permissionId:MENU_SETTING_PERMISSION_ID},
];

const tabsMobile = [
    // {id: "general", title: "General Settings", className: "tab-general general-setting-style"},
    {id: "user", title: "Users", className: "tab-user"}
];

class Setting extends Component {

  constructor() {
    super();
    this.onChangeTab        = this.onChangeTab.bind(this);
    this.handlePopover      = this.handlePopover.bind(this);
    this.handleTabChange    = this.handleTabChange.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleResize       = this.handleResize.bind(this);
    this.state = {
      showSaveDraft: false,
      tabName: "general",
      checkSettingOne: true,
      buttonFormSetting: false,
      buttonsAddFormSetting: false,
      showPopover:{
        user:false,
        form:false,
        marketing:false,
      },
      tabDisplayValue:{},
      tabKey: "general",
      showIcon:{},
      widthPage: window.innerWidth
    };

    this.tabRef = {};
  }

  UNSAFE_componentWillMount() {
    let {pathname} = this.props.location;
    let pathBase = `/main/setting/`;
    let pathLast = pathname.substring(pathBase.length, pathname.length);
    let path = pathLast.split('/');
    let tabName = path[0];
    let showIcon = {};
    let tabDisplayValue = {};
    if(path[0]=== "")
      tabName = "general";

    let tabKey = tabName;
    let optionFound;
    if(tabName === "user" || tabName === "permission" || tabName === "rol" || tabName === "task"){
      tabKey = "user";
      showIcon.user = true;
      optionFound = optionUser.find(op => { return op.key === tabName});
      tabDisplayValue.user = optionFound.displayValue
    } else if(tabName === "form" || tabName === "contract" || tabName === "attribute"){
      tabKey = "form";
      showIcon.form = true;
      optionFound = optionForm.find(op => { return op.key === tabName});
      tabDisplayValue.form = optionFound.displayValue
    } else if(tabName === "marketing" || tabName === "seo"){
      tabKey = "marketing";
      showIcon.marketing = true;
      optionFound = OPTION_MARKETING.find(op => { return op.key === tabName});
      tabDisplayValue.marketing = optionFound.displayValue
    }
    this.setState({tabName: tabName,tabKey:tabKey,showIcon:showIcon,tabDisplayValue:tabDisplayValue});
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    let {showSideBar} = this.props;
    if (showSideBar) {
      let container = window.document.getElementById("container");
      container.className += ' container-collapse';
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    let pathBase = `/main/setting`;
    if(nextProps.location.pathname === pathBase){
      this.setState({tabName: "general"});
    }
  }


  handlePopover(id){
    let {showPopover} = this.state;
    if(showPopover[id]){
      showPopover[id] = false;
    } else {
      showPopover[id] = true;
    }

    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x - 25,
      y: pointTo.y,
      offsetWidth: 170
    };

    this.setState({showPopover,anchorEl})
  }

  handleRequestClose(){
    this.setState({showPopover:{}})
  }

  onChangeTab(key) {
    let showIcon = {};
    showIcon[key] = true;
    this.setState({tabName: key,tabKey:key,showIcon:showIcon,tabDisplayValue:{},showPopover:{user:false,form:false, marketing: false}});
    browserHistory.push({pathname: `/main/setting/${key}`});
  }

  handleTabChange(id,key,displayValue,event){
    event.preventDefault();
    let {tabDisplayValue} = this.state;
    tabDisplayValue[id]   = displayValue;
    this.setState({tabName: key,tabKey:key,tabDisplayValue,showPopover:{user:false,form:false, marketing: false}});
    browserHistory.push({pathname: `/main/setting/${key}`});
  }

  handleResize() {
    this.setState({widthPage: window.innerWidth});
  }

  render() {
    const {tabKey,showPopover,tabDisplayValue,showIcon,anchorEl, widthPage, tabName} = this.state;
    const {height, search, userRoles, isAccountOwner, activeFlags} = this.props;
    const tabs = ((widthPage > WIDTH_VIEW_TABLET) ? tabsWeb : tabsMobile).filter(tab => {
      if (tab.id === tabIds.BILLING_PAYMENT) {
        return activeFlags.includes(FeatureFlags.PAYMENTS)
      }

      if (tab.id === tabIds.FORM) {
        return activeFlags.includes(FeatureFlags.FORMS)
      }

      return true
    })
    const reducer = (accumulator, currVal) => {
      let permissionIds = currVal.permissions.map(permission => permission.id)
      return mergeArrays(accumulator, permissionIds)
    };
    let userPermissionIds = Object.values(userRoles).reduce(reducer,[]);

    const tabValueUser = userPermissionIds.find(id => id === USERS_SETTING_PERMISSION_ID) ? 'user' : 'rol'

    const tabHeaderUser = (widthPage > WIDTH_VIEW_TABLET) ?
      <TabHeader id="user" displayValue={tabDisplayValue['user'] ? tabDisplayValue['user'] : 'Users'}
                 showIcon={showIcon.user}
                 elementRef={this.tabRef.user} onChangeTab={this.onChangeTab} handlePopover={this.handlePopover}
                 showPopover={showPopover.user} handleTabChange={this.handleTabChange} options={optionUser}
                 anchorEl={anchorEl} handleRequestClose={this.handleRequestClose}/> :
      <UserHasPermission permissionId={USERS_SETTING_PERMISSION_ID} >
        <GeneralTab id="user" title="Users" onChangeTab={this.onChangeTab}/>
      </UserHasPermission>

    const tabHeaderForm =
      <TabHeader id="form" displayValue={tabDisplayValue["form"] ? tabDisplayValue["form"] : "Forms"}
                 showIcon={showIcon.form}
                 elementRef={this.tabRef.form} onChangeTab={this.onChangeTab} handlePopover={this.handlePopover}
                 showPopover={showPopover.form} handleTabChange={this.handleTabChange} options={optionForm}
                 anchorEl={anchorEl} handleRequestClose={this.handleRequestClose}/>;

    const tabHeaderMarketing =
      <TabHeader id="marketing" displayValue={tabDisplayValue["marketing"] ? tabDisplayValue["marketing"] : "Marketing"}
                 showIcon={showIcon.marketing}
                 elementRef={this.tabRef.marketing} onChangeTab={this.onChangeTab} handlePopover={this.handlePopover}
                 showPopover={false} handleTabChange={this.handleTabChange} options={OPTION_MARKETING}
                 anchorEl={anchorEl} handleRequestClose={this.handleRequestClose}/>;

    let customizedHeight = height - 130
    if (this.props.location.pathname === '/main/setting/sales') {
      customizedHeight = (height * 2) - 130  // Updating height for Sales Tab
    }

    let customStyle = {
      height: customizedHeight
    }

    //reviews
    if (this.props.location.pathname === '/main/setting/reviews') {
      customStyle = {
        ...customStyle,
        padding: '60px 0px',
      }
    }


      return (
      <div id="container" className="container-component" style={{height:height}}>
        <div className="container-header">
            <Tabs id="tab-settings-menu" activeKey={tabKey} animation={false} onSelect={() => {}}>
              {
                tabs.map((tab, index) => {
                  return (isAccountOwner || userPermissionIds.find(id => id === tab.permissionId) || userPermissionIds.some(id => tab.permissionIds && tab.permissionIds.includes(id)))
                    ? <Tab key={`tab-${index}`}
                         eventKey={tab.id}
                         title={(tab.id !== "user" && tab.id !== "form" && tab.id !== "marketing" )
                           ? <GeneralTab id={tab.id} title={tab.title} onChangeTab={this.onChangeTab}/>
                           : (tab.id === "user") ? tabHeaderUser : ((tab.id === "form") ? tabHeaderForm : tabHeaderMarketing)
                         }
                         tabClassName={getClassName(tabName, tab)}/>
                    : null
                })
              }
            </Tabs>

        </div>
        <div className="body-setting" style={customStyle}>
          {React.cloneElement(this.props.children, {height: height - 130, search: search})}
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    isAccountOwner: state.isAccountOwner,
    userRoles: state.userRoles,
    activeFlags: state.activeFlags,
  }
};

export default connect(mapStateToProps)(Setting)
