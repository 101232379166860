import React, {useState} from 'react'
import {DPModalInfo} from '../../../util/ComponentUtils'
import {ModalAccountOwnerDetails} from './ModalAccountOwnerDetails'

import {MESSAGE_WITHOUT_ACCOUNT_OWNERS} from '../../../../Constants'
import {pathServer} from '../../../../../common/Constants'
import DPTable, {DPTableLogoImage} from '../../../../common/DPTable'
import DPMessage from '../../../../common/DPMessage'

const AccountOwners = ({accountOwners}) => {
  const [ownerSelected, setOwnerSelected] = useState(null)
  return (
    <>
      <DPTable
        data={accountOwners}
        rowTestId={'row-account-owner'}
        config={
          {
            columns: [
              {id: 'photo', label: 'Photo'},
              {id: 'userName', label: 'User Name'},
              {id: 'email', label: 'Email'},
              {id: 'phoneNumber', label: 'Phone Number'}
            ]
          }
        }
        renderColumn={
          (accountOwner, columnConf, rowIndex) => {
            switch (columnConf.id) {
              case 'photo':
                return (
                  <DPTableLogoImage
                    logoUrl={accountOwner.image ? accountOwner.image : pathServer.PATH_IMG + "ic_account_circle.png"}/>
                )
              case 'userName':
                return `${accountOwner.firstName} ${accountOwner.lastName}`
              case 'email':
                return accountOwner.email
              case 'phoneNumber':
                return accountOwner.phoneNumber
            }
          }
        }
        onRowClick={accountOwner => setOwnerSelected(accountOwner)}
      />
      {
        accountOwners.length === 0 ? (
          <DPMessage text={MESSAGE_WITHOUT_ACCOUNT_OWNERS}/>
        ) : null
      }
      {
        ownerSelected ?
          <DPModalInfo showModal={true} closeModalInfo={() => setOwnerSelected(null)}
                       body={<ModalAccountOwnerDetails accountOwner={ownerSelected}/>}/> : null
      }
    </>
  )
}

export default AccountOwners