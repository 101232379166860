import React, {Component} from 'react'
import Modal from 'react-modal'
import MessageReply from './MessageReply.jsx'
import {replyMessagesApi} from '../../Api'
import {saveMessage} from '../../Api'
import Outside from '../util/Outside.jsx'
import {formEmail} from '../../../common/Constants'
import {checkValidEmail} from '../../../common/ValidationUtil'

const customStyles = {
  content: {
    top: '128px',
    left: '168px',
    position: 'absolute',
    padding: '0',
    width: '761px',
    height: '454px',
    borderRadius: '10px',
    border: 'none',
    //boxShadow: '0px 2px 10px #d9d9d9',
    background: 'transparent',
    right: "40px",
    bottom: "40px",
    outline: "none",
    overflow: "hidden"
  }
};

export default class MessagesModal extends Component {
  constructor() {
    super();
    this.return = this.return.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.state = {
      sendMessageTo: [],
      emailTo: "",
      emailFrom: "",
      emailSubject: ""
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyPressFrom = this.handleKeyPressFrom.bind(this);
    this.addTo = this.addTo.bind(this);
    this.addFrom = this.addFrom.bind(this);
    this.addSubject = this.addSubject.bind(this);
    this.removeTo = this.removeTo.bind(this);
    this.focusTo = this.focusTo.bind(this);
  }

  removeTo(index) {
    let {sendMessageTo} = this.state;
    sendMessageTo.splice(index, 1);
    this.setState({sendMessageTo: sendMessageTo}, () => {
      const input = this.input;
      input.focus();
    })
  }

  addTo(evt) {
    evt.preventDefault();
    this.setState({emailTo: evt.target.value})
  }

  addFrom(evt) {
    evt.preventDefault();
    this.setState({emailFrom: evt.target.value})
  }

  addSubject(evt) {
    evt.preventDefault();
    this.setState({emailSubject: evt.target.value})
  }

  handleKeyPress(event) {
    let {sendMessageTo, emailTo} = this.state;
    if (event.key === 'Enter' || event.keyCode == 13 || event.keyCode == 32 || event.key === " ") {
      if (checkValidEmail(emailTo.trim())) {
        sendMessageTo.push(emailTo.trim());
        this.setState({sendMessageTo: sendMessageTo, emailTo: ""})
      }
    }
  }

  handleKeyPressFrom(event) {
    let {emailFrom} = this.state;
    if (event.key === 'Enter' || event.keyCode === 13 || event.keyCode === 32 || event.key === " ") {
      if (checkValidEmail(emailFrom.trim())) {
        this.setState({emailFrom: emailFrom.trim()})
      }
    }
  }

  handleOutside(){
    let {sendMessageTo, emailTo} = this.state;
    if (checkValidEmail(emailTo.trim())) {
      sendMessageTo.push(emailTo.trim());
      this.setState({sendMessageTo: sendMessageTo, emailTo: ""})
    }
  }

  return() {
    this.props.onHide(true);
  }

  focusTo(){
    let {sendMessageTo} = this.state;
    this.setState({sendMessageTo: sendMessageTo}, () => {
      const input = this.input;
      input.focus();
    })
  }

  saveEmail(message){
    let emails = [];
    let email = {
      to      : "vallejosgm@gmail.com",
      from    : message.from,
      phoneNumber: "7869082656",
      subject : message.subject,
      html    : "Your email has been received.  </br><blockquote><div>",
      text    : message.body,
      name    : message.from
    };
    emails.push(email);
    let data = {message: message,emails:emails};
    saveMessage(data).then((response) => {
      setTimeout(function () {
        console.log('Your message has been send successfully, NOTE: See your email for a copy of your message.');
      }.bind(this), 1000);
    }).catch((err) => {
      console.log('Error: Your message could not be sent.');
      alert(err);
    });
  }

  sendEmail(message,files){
    let {sendMessageTo, emailFrom, emailSubject} = this.state;
    let that = this;
    let emailToString ="";
    let i = true;
    sendMessageTo.forEach(function(receipt) {
      if (i){
        emailToString = receipt;
        i = false;
      }else{
        emailToString = emailToString + "; " + receipt;
      }
    });
    message.responsesId = '000'; //Will create un new response_id
    (emailFrom !== "")?message.name = emailFrom: message.name = formEmail.ADMIN_NAME;
    (emailFrom !== "")?message.email = emailFrom: message.email = formEmail.ADMIN_EMAIL;
    message.emailTo = emailToString;
    (emailSubject !== "")?message.title = emailSubject: message.title = "(no subject)";
    // PREPARE TO SEND MESSAGE
    message.sendTo = sendMessageTo;
    (emailFrom !== "")?message.from = emailFrom: message.from = formEmail.ADMIN_EMAIL;
    (emailSubject !== "")?message.subject = emailSubject: message.subject = "(no subject)";
    replyMessagesApi(message,files).then(({ responseCode }) => {
      if (responseCode === 200){
        this.setState({messageDisplay:"Your message has been sent"});
        //this.saveEmail(message);
        setTimeout(() => {
          this.setState({messageDisplay: null});
        },3500);
      }
    });

    setTimeout(function(){
      that.return();
    }.bind(this),2000);
  }

  render() {
    let {sendMessageTo, emailTo, messageDisplay} = this.state;
    const {dealerName} = this.props;

    return (
      <Modal isOpen={true} style={customStyles} contentLabel="New Messages"
             aria={{labelledby: "heading", describedby: "full_description"}}
             ariaHideApp={false} backdrop="static">
        <div className="content-message-arrow">
          <div className="arrow-behind">
            <div className="arrow-on">
            </div>
          </div>
        </div>
        <div className="content-message-title">
          <div className="content-message-title-left">New Message</div>
          <div className="content-message-title-right">
            <div className="new-message-trash">
              <i className="fa fa-trash"  onClick={this.return}/>
            </div>
          </div>
        </div>
        <div className="content-message-from">
          <span>From:</span>
          <input type="text" defaultValue={dealerName} onChange={this.addFrom} onKeyPress={this.handleKeyPressFrom}>
          </input>
        </div>
        <div className="content-message-subject">
          <span>Subject:</span>
          <input type="text" defaultValue="" onChange={this.addSubject} autoFocus />
        </div>
        <div className="content-message-to">
          <span className="to-title">To:</span>
          <div className="content-list-to">
            <div className="list-to" onClick={this.focusTo}>
              {
                sendMessageTo.map((to, index) => {
                  return (<div className="to" key={index}>
                    <span>{to}</span>
                    <i className="fa fa-times" onClick={this.removeTo.bind(this,index)}/>
                  </div>)
                })
              }
              <Outside onClose={this.handleOutside.bind(this)}>
                <input type="text"
                       ref={ref => this.input = ref}
                       onChange={this.addTo}
                       onKeyPress={this.handleKeyPress}
                       value={emailTo}/>
              </Outside>
            </div>
          </div>
        </div>
        <div className="content-draft-editor-rootContainer">
          <MessageReply newMessage={true} messageDisplay={messageDisplay} sendEmail={this.sendEmail}/>
        </div>
      </Modal>
    )
  }
}