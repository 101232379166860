import React, {Component} from 'react'

import find from 'lodash/find.js'
import orderBy from 'lodash/orderBy'
import {
  ATTRIBUTE_CREDIT_SCORE,
  ATTRIBUTE_TYPE_CREDIT,
  ATTRIBUTE_TYPE_ID,
  InputTypes,
  JOIN_CREDIT_NAME,
  JOIN_CREDIT_DISPLAY_VALUE,
  ATTRIBUTE_CUSTOMER_DRIVER_LICENSE_STATE,
  ATTRIBUTE_CUSTOMER_FIRST_NAME,
  ATTRIBUTE_CUSTOMER_LAST_NAME,
  ATTRIBUTE_CUSTOMER_MIDDLE_NAME,
  ATTRIBUTE_CUSTOMER_DOBMDY,
  ATTRIBUTE_CUSTOMER_CELLPHONE,
  ATTRIBUTE_CUSTOMER_SECOND_PHONE,
  ATTRIBUTE_CUSTOMER_EMAIL,
  ATTRIBUTE_CUSTOMER_ADDRESS,
  ATTRIBUTE_CUSTOMER_APT,
  ATTRIBUTE_CUSTOMER_CITY,
  ATTRIBUTE_CUSTOMER_STATE,
  ATTRIBUTE_CUSTOMER_ZIP_CODE,
  ATTRIBUTE_CUSTOMER_MONTHLY_RENT,
  ATTRIBUTE_CUSTOMER_ADDRESS_PREVIOUS,
  ATTRIBUTE_CUSTOMER_APT_PREVIOUS,
  ATTRIBUTE_CUSTOMER_CITY_PREVIOUS,
  ATTRIBUTE_CUSTOMER_STATE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_ZIP_CODE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS,
  ATTRIBUTE_CUSTOMER_MONTHLY_RENT_PREVIOUS,
  ATTRIBUTE_CUSTOMER_ADDRESS_ANOTHER,
  ATTRIBUTE_CUSTOMER_APT_ANOTHER,
  ATTRIBUTE_CUSTOMER_CITY_ANOTHER,
  ATTRIBUTE_CUSTOMER_STATE_ANOTHER,
  ATTRIBUTE_CUSTOMER_ZIP_CODE_ANOTHER,
  ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER,
  ATTRIBUTE_CUSTOMER_YEAR_ANOTHER,
  ATTRIBUTE_CUSTOMER_MONTH_ANOTHER,
  ATTRIBUTE_CUSTOMER_MONTHLY_RENT_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME,
  ATTRIBUTE_CUSTOMER_JOB_TITLE,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE,
  ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME,
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_PREVIOUS,
  ATTRIBUTE_CUSTOMER_JOB_TITLE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS,
  ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_PREVIOUS,
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_ANOTHER,
  ATTRIBUTE_CUSTOMER_JOB_TITLE_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_ANOTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_ANOTHER,
  ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_ANOTHER,
  ATTRIBUTE_CUSTOMER_OTHER_INCOME_SOURCE,
  ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SOURCE_OTHER,
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_SECOND,
  ATTRIBUTE_CUSTOMER_JOB_TITLE_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_SECOND,
  ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_THIRD,
  ATTRIBUTE_CUSTOMER_JOB_TITLE_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_THIRD,
  ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_THIRD,
  SUBSECTION,
  ATTRIBUTES_FOR_SECOND_EMPLOYMENT,
  ATTRIBUTES_FOR_THIRD_EMPLOYMENT,
} from '../../Constants'
import InputElement from 'react-input-mask'
import {isEmpty} from 'lodash'
import {
  optionState,
  ATTRIBUTE_SSN,
  pathServer,
  ATTRIBUTE_APPLICANT_TYPE,
  ATTRIBUTE_APPLICANT_RELATION,
  ATTRIBUTE_RESIDENCE_STATE,
  ATTRIBUTE_RESIDENCE_YEAR,
  ATTRIBUTE_RESIDENCE_MONTH,
  ATTRIBUTE_EMPLOYER_YEAR,
  ATTRIBUTE_EMPLOYER_MONTH,
  ATTRIBUTE_HOUSING_STATUS,
  ATTRIBUTE_EMPLOYER_STATE,
  ATTRIBUTE_EMPLOYMENT_STATUS,
  ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS,
  ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS,
  ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS,
  ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS,
  VALIDATION_REQUIRED, VALIDATION_EMAIL, VALIDATION_PHONE,
  cellphoneMask
} from '../../../common/Constants'
import {ReactSVG} from 'react-svg'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import {DPWarning} from '../../common/DPWarning'
import isNumber from 'lodash/isNumber'
import {DPSectionHeader} from '../../common/DPSectionHeader'

let optionStateWithEmptyOption = optionState
optionStateWithEmptyOption.push({id:'',name: '', displayValue: ''})

const InputValue = ({classCol, classItem, value, displayValue, onChangeInput, name, customerIndex, mask, onlyRead,
                      attributes, error}) => {
  const isRequired = getIsRequired(attributes, name);

  return (
    <div className={classCol}>
      <div className={classItem}>
        <label className="label-view-finance">{displayValue}</label>
        {isRequired ? <span className="label-error">*</span> : null}
        {mask ? <InputElement type="text"
                              className="form-control applicant-input"
                              mask={mask}
                              formatChars={
                                {
                                  '9': '[0-9]',
                                  'm': '[0-1]',
                                  'd': '[0-3]'
                                }
                              }
                              onChange={(e) => onChangeInput(name, customerIndex, e)}
                              value={value}
                              disabled={onlyRead}/> :
          <input className="form-control applicant-input" disabled={onlyRead}
                 type="text"
                 value={value}
                 onChange={(e) => onChangeInput(name, customerIndex, e)}/>
        }
        {
          !isEmpty(error) ? <p className="help-block">{getMessageError(name, attributes, error)}</p> : null
        }
      </div>
    </div>
  );
};

const SelectInput = ({classCol, classItem, values, displayValue, selectedId, onChangeValue, name, customerIndex, onlyRead, attributes,
                     error}) => {
  let options = []

  if (selectedId === '') {
    options.push([
      <option
        key={-1}
        value={null}
      >
        {'Select one'}
      </option>
    ])
  }

  options.push(values.map((value, index) => {
    return (
      <option
        key={index}
        value={value.id}
      >
        {value.displayValue}
      </option>
    )
  }))

  const isRequired = getIsRequired(attributes,name);

  return (
    <div className={classCol}>
      <div className={classItem}>
        <label className="label-view-finance">{displayValue}</label>
        {isRequired ? <span className="label-error">*</span> : null}
        <select className="form-control dropdown-item" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" disabled={onlyRead}
                value={selectedId}
                onChange={(e) => onChangeValue(name, customerIndex, e.target.value)}>
          {options}
        </select>
        {
          !isEmpty(error) ? <p className="help-block">{getMessageError(name, attributes, error)}</p> : null
        }
      </div>
    </div>
  )
};

const MoneyInput = ({classCol, classItem, value, displayValue, onChangeMoneyInput, name, customerIndex, onlyRead, attributes,
                      error}) => {
  const isRequired = getIsRequired(attributes,name);
  return (
    <div className={classCol}>
      <div className={classItem}>
        <label className="label-view-finance">{displayValue}</label>
        {isRequired ? <span className="label-error">*</span> : null}
        <input
          className="form-control applicant-input" placeholder="" disabled={onlyRead}
          value={'$' + value ? value : 0}
          onChange={(e) => onChangeMoneyInput(name, customerIndex, e)}/>
        {
          !isEmpty(error) ? <p className="help-block">{getMessageError(name, attributes, error)}</p> : null
        }
      </div>
    </div>
  )
};

const StateInput = ({classCol, classItem, stateList, displayValue, selectedState, onChangeInput, name, customerIndex, onlyRead, attributes,
                      error}) => {
  const isRequired = getIsRequired(attributes,name);
  return (
    <div className={classCol}>
      <div className={classItem}>
        <label className="label-view-finance">{displayValue}</label>
        {isRequired ? <span className="label-error">*</span> : null}
        <select className="form-control dropdown-item" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" disabled={onlyRead}
                value={selectedState}
                onChange={(e) => onChangeInput(name, customerIndex, e)}>
          {stateList.map((state, index) => {
            return (
              <option key={index}
                //selected = {state.displayValue === selectedState}
                      value={state.id}>{state.displayValue}</option>
            );
          })}
        </select>
        {
          !isEmpty(error) ? <p className="help-block">{getMessageError(name, attributes, error)}</p> : null
        }
      </div>
    </div>
  )
};

const ButtonValue = ({classCol, id, selected, displayValue, customerIndex, onChangeValue}) => {
  return (
    <div className={classCol}>
      <div className="applicant-section-content">
        <div
          className={"form-control " + (id === selected[0] ? "applicant-radio-button-selected" : "applicant-radio-button")}
          onClick={() => onChangeValue(selected[1], customerIndex, id)}>
          {displayValue}
        </div>
      </div>
    </div>
  )
};

const SectionResidence = ({
                            customerIndex, address, apt, city, state, zipCode, housingStatusList, statesList, onlyRead,
                            housingStatus, year, month, monthlyRent, onChangeInput, onChangeValue, onChangeMoneyInput,
                            attributes, errors
                          }) => {
  return (
    <div className="applicant-section-content">
      <div className="flex-row">
        <InputValue classCol="flex-colR3" classItem="item" displayValue="Address"
                    value={address[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={address[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[address[1]]}/>
        <InputValue classCol="flex-colR1" classItem="item" displayValue="APT#"
                    value={apt[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={apt[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[apt[1]]}/>
      </div>
      <div className="flex-row">
        <InputValue classCol="flex-colR1" classItem="item" displayValue="City"
                    value={city[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={city[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[city[1]]}/>
        <StateInput classCol="flex-colR1" classItem="item"
                    stateList={statesList} onlyRead={onlyRead}
                    displayValue="State"
                    selectedState={state[0]}
                    onChangeInput={onChangeInput}
                    name={state[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[state[1]]}/>
        <InputValue classCol="flex-colR1" classItem="item" displayValue="ZIP Code"
                    value={zipCode[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={zipCode[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[zipCode[1]]}/>
        <SelectInput classCol="flex-colR1" classItem="item" displayValue="Housing Status"
                     values={housingStatusList} onlyRead={onlyRead}
                     selectedId={housingStatus[0]}
                     onChangeValue={onChangeValue}
                     name={housingStatus[1]}
                     customerIndex={customerIndex}
                     attributes={attributes} error={errors[housingStatus[1]]}/>
      </div>
      <div className="flex-row">
        <InputValue classCol="flex-colR1" classItem="item" displayValue="Year"
                    value={year[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={year[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[year[1]]}/>
        <InputValue classCol="flex-colR1" classItem="item" displayValue="Month"
                    value={month[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={month[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[month[1]]}/>
        <MoneyInput classCol="flex-colR1" classItem="item" displayValue="Monthly Rent"
                    value={monthlyRent[0]} onlyRead={onlyRead}
                    onChangeMoneyInput={onChangeMoneyInput}
                    name={monthlyRent[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[monthlyRent[1]]}/>
      </div>
    </div>
  )
};

const SectionEmployment = ({
                             customerIndex, name, jobTitle, cellPhone, address, city, state, statesList, zipCode, employmentStatusList, employmentStatus
                             , year, month, monthlyInCome, onChangeInput, onChangeValue, onChangeMoneyInput, mask, onlyRead, attributes, errors,
                             otherIncomeSource, monthlyIncomeFromOtherSource, linkName, linkFunc
                           }) => {

  return (
    <div className="applicant-section-content">
      <div className="flex-row">
        <InputValue classCol="flex-colR2" classItem="item" displayValue="Employer Name"
                    value={name[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={name[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[name[1]]}/>
        <InputValue classCol="flex-colR1" classItem="item" displayValue="Title"
                    value={jobTitle[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={jobTitle[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[jobTitle[1]]}/>
        <InputValue classCol="flex-colR1" classItem="item" displayValue="Cell Phone"
                    value={cellPhone[0]}
                    onChangeInput={onChangeInput}
                    name={cellPhone[1]}
                    customerIndex={customerIndex}
                    mask={mask} attributes={attributes} error={errors[cellPhone[1]]}/>
      </div>
      <div className="flex-row">
        <InputValue classCol="flex-colR3" classItem="item" displayValue="Employer Address"
                    value={address[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={address[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[address[1]]}/>
        <InputValue classCol="flex-colR1" classItem="item" displayValue="City"
                    value={city[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={city[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[city[1]]}/>
        <StateInput classCol="flex-colR1" classItem="item"
                    stateList={statesList} onlyRead={onlyRead}
                    displayValue="State"
                    selectedState={state[0]}
                    onChangeInput={onChangeInput}
                    name={state[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[state[1]]}/>
        <InputValue classCol="flex-colR1" classItem="item" displayValue="Zip Code"
                    value={zipCode[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={zipCode[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[zipCode[1]]}/>
      </div>
      <div className="flex-row">
        {
          employmentStatus ?
            <SelectInput classCol="flex-colR3" classItem="item" displayValue="Employment Status"
                         values={employmentStatusList} onlyRead={onlyRead}
                         selectedId={employmentStatus[0]}
                         onChangeValue={onChangeValue}
                         name={employmentStatus[1]}
                         customerIndex={customerIndex} attributes={attributes} error={errors[employmentStatus[1]]}/>
            : null
        }
        <InputValue classCol="flex-colR1" classItem="item" displayValue="Year"
                    value={year[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={year[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[year[1]]}/>
        <InputValue classCol="flex-colR1" classItem="item" displayValue="Month"
                    value={month[0]} onlyRead={onlyRead}
                    onChangeInput={onChangeInput}
                    name={month[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[month[1]]}/>
        <MoneyInput classCol="flex-colR1" classItem="item" displayValue="Monthly Income"
                    value={monthlyInCome[0]} onlyRead={onlyRead}
                    onChangeMoneyInput={onChangeMoneyInput}
                    name={monthlyInCome[1]}
                    customerIndex={customerIndex} attributes={attributes} error={errors[monthlyInCome[1]]}/>
      </div>
      <div className='flex-row'>
        {
          otherIncomeSource &&
          <InputValue
            classCol="flex-colR1"
            classItem="item"
            displayValue="Other source of income"
            value={otherIncomeSource[0]}
            onlyRead={onlyRead}
            onChangeInput={onChangeInput}
            name={otherIncomeSource[1]}
            customerIndex={customerIndex}
            attributes={attributes}
            error={errors[otherIncomeSource[1]]}
          />
        }
        {
          monthlyIncomeFromOtherSource &&
          <MoneyInput
            classCol="flex-colR1"
            classItem="item"
            displayValue="Monthly Income from other source"
            value={monthlyIncomeFromOtherSource[0]}
            onlyRead={onlyRead}
            onChangeMoneyInput={onChangeMoneyInput}
            name={monthlyIncomeFromOtherSource[1]}
            customerIndex={customerIndex}
            attributes={attributes}
            error={errors[monthlyIncomeFromOtherSource[1]]}
          />
        }
      </div>
      {
        linkName &&
        <div className='flex-row'>
          <label
            className="link"
            onClick={linkFunc}
          >
            {linkName}
          </label>
        </div>
      }
    </div>
  )
};

function calculateMonths(years, months) {
  return Number(years) * 12 + Number(months);
}

function blankValuesObj(valueObj) {
  let newValueObj = {}
  for (const property in valueObj) {
    if (typeof valueObj[property] === 'string') {
      newValueObj[property] = ''
    }

    if (isArray(valueObj[property])) {
      newValueObj[property] = []
    }
  }

  return newValueObj
}

const ssnMask = "999-99-9999";
const dateMask = "m9-d9-9999";

function getIsRequired(attributes, name) {
  const attribute = attributes.find(x => x.name === name)
  let isRequired = false;
  if(attribute && attribute.validation.length > 0){
    attribute.validation.forEach(item => {
      if(item.type === VALIDATION_REQUIRED){
        isRequired = true
      }
    })
  }

  return isRequired
}

function getMessageError(name, attributes, error) {
  const attribute = attributes.find(x => x.name === name)
  let message = ""
  switch (error) {
    case VALIDATION_REQUIRED:
      message = attribute.displayValue + " is required."
      break;
    case VALIDATION_EMAIL:
    case VALIDATION_PHONE:
      message = attribute.displayValue + " is not valid."
      break;
    default:
      message = attribute.displayValue + " is empty."
  }

  return message
}

function getErrors(errors, indexApplicant) {
  let newError = {}
  errors.forEach((x,index) => {
    if(x.index === indexApplicant){
      newError = errors[index]
    }
  })

  return newError
}

const ADD_SECOND_EMPLOYMENT = 'Add second employment'
const ADD_THIRD_EMPLOYMENT = 'Add third employment'
const REMOVE_SECOND_EMPLOYMENT = 'Remove second employment'
const REMOVE_THIRD_EMPLOYMENT = 'Remove third employment'

const NEXTSUBSECTION = {
  [SUBSECTION.CURRENT]: SUBSECTION.SECOND,
  [SUBSECTION.SECOND]: SUBSECTION.THIRD,
}

const DEFAULT_LINKNAMES = {
  [SUBSECTION.CURRENT]: ADD_SECOND_EMPLOYMENT,
  [SUBSECTION.SECOND]: ADD_THIRD_EMPLOYMENT,
}

const DEFAULT_SHOW_EMPLOYMENT_SUBSECTIONS = {
  [SUBSECTION.CURRENT]: true,
  [SUBSECTION.SECOND]: false,
  [SUBSECTION.THIRD]: false
}

export default class viewApplicant extends Component {

  constructor() {
    super();
    this.state = {
      status: null,
      finance: null,
      sectionInformation: null,
      sectionResidence: null,
      sectionEmployment: null,
      sectionCredit: null,
      sectionComments: null,
      newComment: null,
      valuesWithNames: [],
      statesList: [],
      applicationTypeList: [],
      employmentStatusList: [],
      housingStatusList: [],
      attributeCreditScore: [],
      attributeTypeCredit: [],
      attributeTypeId: [],
      financeObj: {
        financeId: "",
        financeType: null,
        values: [],
        showEmploymentSubSections: {}
      },
      attributes: [],
      customerId: "",
      employmentStatusPreviousList: [],
      employmentStatusSecondList: [],
      employmentStatusThirdList: [],
      linkNames: {
        0: DEFAULT_LINKNAMES,
      },
      showEmploymentSubSections: { // Now just for Three first employment subSections
        0: DEFAULT_SHOW_EMPLOYMENT_SUBSECTIONS,
      },
    }
  }

  onChangeInput = (attributeName, customerIndex, el) => {
    let {valuesWithNames, attributes, financeObj} = this.state;
    valuesWithNames[customerIndex][attributeName] = el.target.value;
    let attribute = find(attributes, (attribute) => {
      return attribute.name === attributeName
    });

    let financeValues = financeObj["values"][customerIndex];
    financeValues[attribute.id] = el.target.value;
    financeObj.values[customerIndex] = financeValues;

    this.setState({valuesWithNames: valuesWithNames, financeObj: financeObj}, () => {
      this.props.updateFinanceObj(financeObj)
    });
  }

  onChangeMoneyInput = (attributeName, customerIndex, el) => {
    let {valuesWithNames, attributes, financeObj} = this.state;
    valuesWithNames[customerIndex][attributeName] = el.target.value.replace('$', '').replace(',', '').replace(/[^0-9]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let attribute = find(attributes, (attribute) => {
      return attribute.name === attributeName
    });
    let financeValues = financeObj["values"][customerIndex];
    financeValues[attribute.id] = el.target.value.replace('$', '').replace(',', '').replace(/[^0-9]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    financeObj.values[customerIndex] = financeValues;

    this.setState({valuesWithNames: valuesWithNames, financeObj: financeObj}, () => {
      this.props.updateFinanceObj(financeObj)
    });
  }

  isJoinCreditOnMainApplicant() {
    const {valuesWithNames} = this.state
    const applicantTypeOnMainApplicant = valuesWithNames[0][ATTRIBUTE_APPLICANT_TYPE]

    return (applicantTypeOnMainApplicant.name === JOIN_CREDIT_NAME)
  }

  onChangeValue = (attributeName, customerIndex, id) => {
    let {valuesWithNames, attributes, financeObj} = this.state;
    let attribute = find(attributes, (attribute) => {
      return attribute.name === attributeName
    });
    let attributeObj = find(attribute.attributeValues, attributeValueObj => {
      return attributeValueObj.id === id
    });
    valuesWithNames[customerIndex][attributeName] = attributeObj;
    let financeValues = financeObj["values"][customerIndex];
    financeValues[attribute.id] = [id];
    financeObj.values[customerIndex] = financeValues;

    const justOneApplicant = (valuesWithNames.length === 1)

    if(attribute.name === ATTRIBUTE_APPLICANT_TYPE){
      financeObj.financeType = attributeObj.displayValue
    }

    this.setState(
      {
        valuesWithNames: valuesWithNames,
        financeObj: financeObj,
      },
      () => {
        if (attributeObj.displayValue === JOIN_CREDIT_DISPLAY_VALUE && attributeObj.name === JOIN_CREDIT_NAME) {
          if (justOneApplicant) {
            this.handleAddNewCoSigner()
          }
        } else {
          this.props.updateFinanceObj(financeObj)
        }
      }
    );
  }

  componentDidMount() {
    let applicantFormBase = {};
    const {dataFinance} = this.props;

    if (dataFinance) {
      const {form, applicant, comments} = dataFinance;
      let {financeObj} = this.state;
      financeObj["financeId"] = dataFinance.finance?.id || null
      financeObj.comments = dataFinance.comments ?? []
      applicantFormBase = form.formAttributes;
      let attributes = applicantFormBase.attributes;
      attributes.forEach(attribute => {
        attribute.readOnly = true; //?
      });
      let attributeApplicantType = find(attributes, function (attribute) {
        return attribute.name === ATTRIBUTE_APPLICANT_TYPE
      });

      let attributeApplicantRelation = find(attributes, function (attribute) {
        return attribute.name === ATTRIBUTE_APPLICANT_RELATION
      });

      let applicationTypeList = [];
      applicationTypeList.push(attributeApplicantType.attributeValues);

      let { values } = dataFinance

      /*if(this.props.isNewFinanceApplication){
        values = [{...values}]
      }*/

      if (values.length > 1) {
        for (let i = 1; i < values.length; i++) {
          applicationTypeList.push(attributeApplicantRelation.attributeValues);
        }
      }

      let housingStatusList = this.getList(attributes, ATTRIBUTE_HOUSING_STATUS);
      let statesList = optionStateWithEmptyOption;
      let employmentStatusList = this.getList(attributes, ATTRIBUTE_EMPLOYMENT_STATUS);
      let employmentStatusPreviousList = this.getList(attributes, ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS);
      let employmentStatusSecondList = this.getList(attributes, ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND);
      let employmentStatusThirdList = this.getList(attributes, ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD);
      let attributeCreditScore = this.getList(attributes, ATTRIBUTE_CREDIT_SCORE);
      let attributeTypeCredit = this.getList(attributes, ATTRIBUTE_TYPE_CREDIT);
      let attributeTypeId = this.getList(attributes, ATTRIBUTE_TYPE_ID);
      let valuesSort = orderBy(values, [attributeApplicantType.id], ['desc']);
      this.addOption(attributes);
      let valuesWithNames = [];
      let sectionInformation = [];
      let sectionResidence = [];
      let sectionEmployment = [];
      let sectionCredit = [];
      let sectionComments = [];
      valuesSort.forEach(valueSort => {
        let valuesWithName = this.getValuesWithAttributeName(valueSort, attributes);
        valuesWithNames.push(valuesWithName);
        sectionInformation.push(false);
        sectionResidence.push(true);
        sectionEmployment.push(true);
        sectionCredit.push(true);
        sectionComments.push(true);
      });

      let showEmploymentSubSections = {...this.state.showEmploymentSubSections}
      let linkNames = {...this.state.linkNames}

      for (let index = 0; index < values.length; index++) {
        financeObj["values"][index] = values[index];
        const attributeEmployerNameSecond = attributes.find(attribute => attribute.name === ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_SECOND)
        const attributeEmployerNameThird = attributes.find(attribute => attribute.name === ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_THIRD)

        showEmploymentSubSections = {
          ...showEmploymentSubSections,
          [index]: {
            ...showEmploymentSubSections[index],
            [SUBSECTION.SECOND]: !isEmpty(values[index][attributeEmployerNameSecond.id]),
            [SUBSECTION.THIRD]: !isEmpty(values[index][attributeEmployerNameThird.id])
          }
        }

        linkNames = {
          ...linkNames,
          [index]: {
            ...linkNames,
            [SUBSECTION.CURRENT]: !isEmpty(values[index][attributeEmployerNameSecond.id])
              ? !isEmpty(values[index][attributeEmployerNameThird.id])
                ? ''
                : REMOVE_SECOND_EMPLOYMENT
              : ADD_SECOND_EMPLOYMENT,
            [SUBSECTION.SECOND]: !isEmpty(values[index][attributeEmployerNameThird.id])
              ? REMOVE_THIRD_EMPLOYMENT
              : ADD_THIRD_EMPLOYMENT
          }
        }
      }

      const housingStatusListPrevious = this.getList(attributes, ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS)
      const housingStatusListAnother = this.getList(attributes, ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER)

      financeObj['showEmploymentSubSections'] = showEmploymentSubSections

      this.setState({
        finance: dataFinance.finance || null, attributes, valuesWithNames, sectionInformation, sectionResidence, sectionEmployment,
        sectionCredit, sectionComments, applicationTypeList, housingStatusList, employmentStatusList, financeObj,
        attributeCreditScore, attributeTypeCredit, attributeTypeId, statesList, applicant, housingStatusListPrevious, housingStatusListAnother,
        employmentStatusPreviousList, employmentStatusSecondList, employmentStatusThirdList, showEmploymentSubSections, linkNames
      });
      this.props.updateFinanceObj(financeObj);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataFinance.values !== prevProps.dataFinance.values) {
      const {values, applicant} = this.props.dataFinance;
      let {attributes} = this.state;
      let financeObj = {
        financeId: "",
        financeType: null,
        values: []
      }

      financeObj["financeId"] = this.props.dataFinance.finance?.id || null;
      let attributeApplicantType = find(attributes, function (attribute) {
        return attribute.name === ATTRIBUTE_APPLICANT_TYPE
      });

      let attributeApplicantRelation = find(attributes, function (attribute) {
        return attribute.name === ATTRIBUTE_APPLICANT_RELATION
      });

      let applicationTypeList = [];
      applicationTypeList.push(attributeApplicantType.attributeValues);

      if (values.length > 1) {
        for (let i = 1; i < values.length; i++) {
          applicationTypeList.push(attributeApplicantRelation.attributeValues);
        }
      }

      let housingStatusList = this.getList(attributes, ATTRIBUTE_HOUSING_STATUS);
      let statesList = optionState;
      let employmentStatusList = this.getList(attributes, ATTRIBUTE_EMPLOYMENT_STATUS);
      let employmentStatusPreviousList = this.getList(attributes, ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS);
      let employmentStatusSecondList = this.getList(attributes, ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND);
      let employmentStatusThirdList = this.getList(attributes, ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD);
      let attributeCreditScore = this.getList(attributes, ATTRIBUTE_CREDIT_SCORE);
      let attributeTypeCredit = this.getList(attributes, ATTRIBUTE_TYPE_CREDIT);
      let attributeTypeId = this.getList(attributes, ATTRIBUTE_TYPE_ID);

      let valuesSort = orderBy(values, [attributeApplicantType.id], ['desc']);
      this.addOption(attributes);
      let valuesWithNames = [];
      let sectionInformation = [];
      let sectionResidence = [];
      let sectionEmployment = [];
      let sectionCredit = [];
      let sectionComments = [];
      valuesSort.forEach(valueSort => {
        let valuesWithName = this.getValuesWithAttributeName(valueSort, attributes);
        valuesWithNames.push(valuesWithName);
        sectionInformation.push(false);
        sectionResidence.push(true);
        sectionEmployment.push(true);
        sectionCredit.push(true);
        sectionComments.push(true);
      });

      let showEmploymentSubSections = {...this.state.showEmploymentSubSections}
      let linkNames = {...this.state.linkNames}

      for (let index = 0; index < values.length; index++) {
        financeObj["values"][index] = values[index];
        const attributeEmployerNameSecond = attributes.find(attribute => attribute.name === ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_SECOND)
        const attributeEmployerNameThird = attributes.find(attribute => attribute.name === ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_THIRD)

        showEmploymentSubSections = {
          ...showEmploymentSubSections,
          [index]: {
            ...showEmploymentSubSections[index],
            [SUBSECTION.SECOND]: !isEmpty(values[index][attributeEmployerNameSecond.id]),
            [SUBSECTION.THIRD]: !isEmpty(values[index][attributeEmployerNameThird.id])
          }
        }

        linkNames = {
          ...linkNames,
          [index]: {
            ...linkNames,
            [SUBSECTION.CURRENT]: !isEmpty(values[index][attributeEmployerNameSecond.id])
              ? !isEmpty(values[index][attributeEmployerNameThird.id])
                ? ''
                : REMOVE_SECOND_EMPLOYMENT
              : ADD_SECOND_EMPLOYMENT,
            [SUBSECTION.SECOND]: !isEmpty(values[index][attributeEmployerNameThird.id])
              ? REMOVE_THIRD_EMPLOYMENT
              : ADD_THIRD_EMPLOYMENT
          }
        }
      }

      const housingStatusListPrevious = this.getList(attributes, ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS)
      const housingStatusListAnother = this.getList(attributes, ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER)

      financeObj['showEmploymentSubSections'] = showEmploymentSubSections

      this.setState({
        finance: this.props.dataFinance.finance || null, attributes, valuesWithNames, sectionInformation, sectionResidence, sectionEmployment,
        sectionCredit, sectionComments,applicationTypeList, housingStatusList, employmentStatusList, financeObj,
        attributeCreditScore, attributeTypeCredit, attributeTypeId, statesList, applicant, housingStatusListPrevious, housingStatusListAnother,
        employmentStatusPreviousList, employmentStatusSecondList, employmentStatusThirdList, showEmploymentSubSections, linkNames
      });

      this.props.updateFinanceObj(financeObj);
    }
  }

  handleAddNewCoSigner() {
    let newFinanceObj = {...this.state.financeObj}
    const { attributes } = this.state
    const newFinanceObjValues = [...this.state.financeObj.values]
    const newCosignerValue =
      blankValuesObj(newFinanceObj.values[newFinanceObjValues.length > 1 ? 1 : 0])

    newFinanceObj.financeType = JOIN_CREDIT_DISPLAY_VALUE
    newFinanceObj.values = newFinanceObjValues
    newFinanceObj.values.push(newCosignerValue)

    const newValuesWithName = this.getValuesWithAttributeName(newCosignerValue, this.state.attributes);
    const attributeApplicantRelation = find(attributes, function (attribute) {
      return attribute.name === ATTRIBUTE_APPLICANT_RELATION
    });

    let newValuesWithNames = [...this.state.valuesWithNames]
    let newApplicationTypeList = [...this.state.applicationTypeList]
    let newSectionInformation = [...this.state.sectionInformation]
    let newSectionResidence = [...this.state.sectionResidence]
    let newSectionEmployment = [...this.state.sectionEmployment]
    let newSectionCredit = [...this.state.sectionCredit]

    newValuesWithNames.push(newValuesWithName)
    newApplicationTypeList.push(attributeApplicantRelation.attributeValues)
    newSectionInformation.push(false);
    newSectionResidence.push(true);
    newSectionEmployment.push(true);
    newSectionCredit.push(true);

    let newLinkNames = {...this.state.linkNames}
    let newShowEmploymentSubSections = {...this.state.showEmploymentSubSections}

    newLinkNames = {
      ...newLinkNames,
      [Object.keys(newLinkNames).length]: {...DEFAULT_LINKNAMES},
    }

    newShowEmploymentSubSections = {
      ...newShowEmploymentSubSections,
      [Object.keys(newShowEmploymentSubSections).length]: {...DEFAULT_SHOW_EMPLOYMENT_SUBSECTIONS},
    }

    newFinanceObj['showEmploymentSubSections'] = newShowEmploymentSubSections

    this.setState(
      {
        valuesWithNames: newValuesWithNames,
        applicationTypeList: newApplicationTypeList,
        sectionInformation: newSectionInformation,
        sectionResidence: newSectionResidence,
        sectionEmployment: newSectionEmployment,
        sectionCredit: newSectionCredit,
        financeObj: newFinanceObj,
        linkNames: newLinkNames,
        showEmploymentSubSections: newShowEmploymentSubSections,
      },
      () => {
        this.props.updateFinanceObj(newFinanceObj)
      }
    )
  }

  getList(attributes, value) {
    let obj = attributes.filter((attribute) => attribute.name === value);
    if (obj.length > 0)
      return obj[0].attributeValues;
    else
      return [];
  }

  getValuesWithAttributeName(values, attributes) {
    let valuesWithName = {};

    if (values) {
      attributes.forEach(attribute => {
        if (attribute.attributeValues && attribute.attributeValues.length > 0) {
          let displayValue = [];
          if (attribute.inputType !== InputTypes.STATE) {
            displayValue = values[attribute.id] ? attribute.attributeValues.filter(x => x.id === values[attribute.id][0]) : ""
            valuesWithName[attribute.name] = displayValue[0];
          } else {
            valuesWithName[attribute.name] = values[attribute.id] ? values[attribute.id] : "";
          }
        } else
          valuesWithName[attribute.name] = values[attribute.id] ? values[attribute.id] : "";
      });
    }
    return valuesWithName;
  }

  addOption(attributes) {
    attributes.forEach(attribute => {
      switch (attribute.name) {
        case ATTRIBUTE_RESIDENCE_STATE :
        case ATTRIBUTE_RESIDENCE_STATE + "Previous" :
        case ATTRIBUTE_RESIDENCE_STATE + "Another" :
        case ATTRIBUTE_EMPLOYER_STATE :
        case ATTRIBUTE_EMPLOYER_STATE + "Previous" :
        case ATTRIBUTE_EMPLOYER_STATE + "Another" :
          attribute.attributeValues = optionState;
          break;
      }
    });
  }

  accordionToggle = (id, index) => {
    let toggleSection = this.state[id];
    const value = !toggleSection[index];
    toggleSection[index] = value;
    this.setState({[id]: toggleSection});
  }

  onChangeNewComment = (evt) => {
    this.setState({newComment: evt.target.value});
  }

  addNewComment = () => {
    const {newComment, financeObj} = this.state;
    const {dataFinance, userId} = this.props;
    financeObj.comments.push({dealerId: dataFinance.finance.dealerId, financeId: financeObj.financeId, userId: userId ,comment: newComment});
    this.props.updateFinanceObj(financeObj);
    this.setState({newComment: ""})
  }

  getInitials = (userId) => {
    const {users} = this.props
    const userFound = users.find(x => x.id === userId)
    let initials = ""
    if(userFound){
      initials = userFound.firstName[0] + userFound.lastName[0]
    }
    return initials
  }

  renderApplicant = (valuesWithName, index, classWrapper, styleApplicantSection, isNewCoSigner = false, errors = {}) => {
    const {
      sectionInformation, sectionResidence, employmentStatusList,
      sectionEmployment, sectionCredit, attributeCreditScore, attributeTypeCredit,
      attributeTypeId, statesList, applicationTypeList, attributes, housingStatusList, housingStatusListPrevious, housingStatusListAnother,
      showEmploymentSubSections
    } = this.state;
    const {onlyRead} = this.props;

    const showResidencePrevious = !isEmpty(valuesWithName["customer.year"]) && !isEmpty(valuesWithName["customer.month"]);
    const residencePreviousTime = calculateMonths(valuesWithName["customer.year"], valuesWithName["customer.month"]);

    const showResidenceAnother = !isEmpty(valuesWithName["customer.yearPrevious"]) && !isEmpty(valuesWithName["customer.monthPrevious"]);
    const residenceAnotherTime = residencePreviousTime + calculateMonths(valuesWithName["customer.yearPrevious"], valuesWithName["customer.monthPrevious"]);

    const employmentSecondTime = calculateMonths(valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_SECOND], valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_SECOND])
    const employmentThirdTime = calculateMonths(valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_THIRD], valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_THIRD])

    const showEmploymentPrevious = !isEmpty(valuesWithName["customer.employerYear"]) && !isEmpty(valuesWithName["customer.employerMonth"]);
    const employmentPreviousTime = calculateMonths(valuesWithName["customer.employerYear"], valuesWithName["customer.employerMonth"]) +
      employmentSecondTime + employmentThirdTime;

    const showEmploymentAnother = !isEmpty(valuesWithName["customer.employerYearPrevious"]) && !isEmpty(valuesWithName["customer.employerMonthPrevious"]);
    const employmentAnotherTime = employmentPreviousTime + calculateMonths(valuesWithName["customer.employerYearPrevious"], valuesWithName["customer.employerMonthPrevious"]);

    let applicantType = ATTRIBUTE_APPLICANT_TYPE;
    let applicantTypeDisplay = "Type of application";
    let applicantSSNDisplay = "Applicant SSN";
    if (index >= 1) {
      applicantType = ATTRIBUTE_APPLICANT_RELATION;
      applicantTypeDisplay = "Relationship";
    }

    return (
      <div key={`applicant-section-wrapper-${index}`} className={`applicant-section-wrapper ${classWrapper}`}>
        {
          isNewCoSigner
            ? <DPWarning message={"Save the application to keep the co-signer information"}/>
            : null
        }
        <div className="applicant-section">
          <DPSectionHeader
            isClosed={sectionInformation[index]}
            title="Application Information"
            subTitle="Please provide 2 years of residence information"
            accordionToggle={() => this.accordionToggle("sectionInformation", index)}
          />
          {
            sectionInformation[index] ? null :
              <div className="applicant-section-content">
                <div className="flex-row">
                  <InputValue classCol="flex-colR1" classItem="item" displayValue={applicantSSNDisplay}
                              value={valuesWithName[ATTRIBUTE_SSN] ? valuesWithName[ATTRIBUTE_SSN] : ''}
                              onChangeInput={this.onChangeInput} onlyRead={onlyRead}
                              name={ATTRIBUTE_SSN}
                              customerIndex={index}
                              mask={ssnMask}
                              attributes={attributes} error={!isEmpty(errors) ? errors[ATTRIBUTE_SSN] : {}}/>
                </div>
                <div className="flex-row">
                  <SelectInput classCol="flex-colR1" classItem="item" displayValue={applicantTypeDisplay}
                               values={applicationTypeList[index]}
                               selectedId={valuesWithName[applicantType] ? valuesWithName[applicantType].id : ''}
                               onChangeValue={this.onChangeValue}
                               name={applicantType}
                               customerIndex={index}
                               onlyRead={onlyRead}
                               attributes={attributes} error={errors[applicantType]}
                  />
                  <InputValue classCol="flex-colR1" classItem="item" displayValue="Driver License State" onlyRead={onlyRead}
                              value={ valuesWithName[ATTRIBUTE_CUSTOMER_DRIVER_LICENSE_STATE] ? valuesWithName[ATTRIBUTE_CUSTOMER_DRIVER_LICENSE_STATE] : ""}
                              onChangeInput={this.onChangeInput}
                              name={ATTRIBUTE_CUSTOMER_DRIVER_LICENSE_STATE}
                              customerIndex={index} attributes={attributes} error={errors[ATTRIBUTE_CUSTOMER_DRIVER_LICENSE_STATE]}/>
                  <InputValue classCol="flex-colR1" classItem="item" displayValue="Last Name" onlyRead={onlyRead}
                              value={valuesWithName.lastName ? valuesWithName.lastName : null}
                              onChangeInput={this.onChangeInput}
                              name={ATTRIBUTE_CUSTOMER_LAST_NAME}
                              customerIndex={index} attributes={attributes} error={errors[ATTRIBUTE_CUSTOMER_LAST_NAME]}/>
                  <InputValue classCol="flex-colR1" classItem="item" displayValue="First Name" onlyRead={onlyRead}
                              value={valuesWithName.firstName ? valuesWithName.firstName : null}
                              onChangeInput={this.onChangeInput}
                              name={ATTRIBUTE_CUSTOMER_FIRST_NAME}
                              customerIndex={index} attributes={attributes} error={errors[ATTRIBUTE_CUSTOMER_FIRST_NAME]}/>
                </div>
                <div className="flex-row">
                  <InputValue classCol="flex-colR1" classItem="item" displayValue="Middle Name" onlyRead={onlyRead}
                              value={valuesWithName[ATTRIBUTE_CUSTOMER_MIDDLE_NAME] ? valuesWithName[ATTRIBUTE_CUSTOMER_MIDDLE_NAME] : null}
                              onChangeInput={this.onChangeInput}
                              name={ATTRIBUTE_CUSTOMER_MIDDLE_NAME}
                              customerIndex={index} attributes={attributes} error={errors[ATTRIBUTE_CUSTOMER_MIDDLE_NAME]}/>
                  <InputValue classCol="flex-colR1" classItem="item" displayValue="DOB" onlyRead={onlyRead}
                              value={valuesWithName[ATTRIBUTE_CUSTOMER_DOBMDY] ? valuesWithName[ATTRIBUTE_CUSTOMER_DOBMDY] : null}
                              onChangeInput={this.onChangeInput}
                              name={ATTRIBUTE_CUSTOMER_DOBMDY}
                              mask={dateMask}
                              customerIndex={index} attributes={attributes} error={errors[ATTRIBUTE_CUSTOMER_DOBMDY]}/>
                  <InputValue classCol="flex-colR1" classItem="item" displayValue="Cell Phone" onlyRead={onlyRead}
                              value={valuesWithName[ATTRIBUTE_CUSTOMER_CELLPHONE] ? valuesWithName[ATTRIBUTE_CUSTOMER_CELLPHONE] : null}
                              onChangeInput={this.onChangeInput}
                              name={ATTRIBUTE_CUSTOMER_CELLPHONE}
                              mask={cellphoneMask}
                              customerIndex={index} attributes={attributes} error={errors[ATTRIBUTE_CUSTOMER_CELLPHONE]}/>
                  <InputValue classCol="flex-colR1" classItem="item" displayValue="Second Phone Number" onlyRead={onlyRead}
                              value={valuesWithName[ATTRIBUTE_CUSTOMER_SECOND_PHONE] ? valuesWithName[ATTRIBUTE_CUSTOMER_SECOND_PHONE] : null}
                              onChangeInput={this.onChangeInput}
                              name={ATTRIBUTE_CUSTOMER_SECOND_PHONE}
                              mask={cellphoneMask}
                              customerIndex={index} attributes={attributes} error={errors[ATTRIBUTE_CUSTOMER_SECOND_PHONE]}/>
                </div>
                <div className="col100">
                  <InputValue classCol="colR50" classItem="item" displayValue="Email Address" onlyRead={onlyRead}
                              value={valuesWithName[ATTRIBUTE_CUSTOMER_EMAIL] ? valuesWithName[ATTRIBUTE_CUSTOMER_EMAIL] : ''}
                              onChangeInput={this.onChangeInput}
                              name={ATTRIBUTE_CUSTOMER_EMAIL}
                              customerIndex={index} attributes={attributes} error={errors[ATTRIBUTE_CUSTOMER_EMAIL]}/>
                </div>
              </div>
          }
        </div>
        <div className="applicant-section">
          <DPSectionHeader
            isClosed={sectionResidence[index]}
            title="Current Residence"
            subTitle="Please provide 2 years of residence"
            accordionToggle={() => this.accordionToggle("sectionResidence", index)}
          />
          {
            sectionResidence[index] ? null :
              <div>
                <SectionResidence address={[valuesWithName[ATTRIBUTE_CUSTOMER_ADDRESS], ATTRIBUTE_CUSTOMER_ADDRESS]}
                                  apt={[valuesWithName[ATTRIBUTE_CUSTOMER_APT], ATTRIBUTE_CUSTOMER_APT]}
                                  city={[valuesWithName[ATTRIBUTE_CUSTOMER_CITY], ATTRIBUTE_CUSTOMER_CITY]}
                                  state={valuesWithName[ATTRIBUTE_CUSTOMER_STATE] ?
                                    [valuesWithName[ATTRIBUTE_CUSTOMER_STATE], ATTRIBUTE_CUSTOMER_STATE] :
                                    ["", ATTRIBUTE_CUSTOMER_STATE]
                                  }
                                  statesList={statesList}
                                  zipCode={[valuesWithName[ATTRIBUTE_CUSTOMER_ZIP_CODE], ATTRIBUTE_CUSTOMER_ZIP_CODE]}
                                  housingStatus={valuesWithName[ATTRIBUTE_HOUSING_STATUS] ?
                                    [valuesWithName[ATTRIBUTE_HOUSING_STATUS].id, ATTRIBUTE_HOUSING_STATUS] :
                                    ["", ATTRIBUTE_HOUSING_STATUS]
                                  }
                                  housingStatusList={housingStatusList}
                                  year={[valuesWithName[ATTRIBUTE_RESIDENCE_YEAR], ATTRIBUTE_RESIDENCE_YEAR]}
                                  month={[valuesWithName[ATTRIBUTE_RESIDENCE_MONTH], ATTRIBUTE_RESIDENCE_MONTH]}
                                  monthlyRent={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_RENT], ATTRIBUTE_CUSTOMER_MONTHLY_RENT]}
                                  onChangeInput={this.onChangeInput}
                                  onChangeValue={this.onChangeValue}
                                  onChangeMoneyInput={this.onChangeMoneyInput}
                                  customerIndex={index}
                                  onlyRead={onlyRead}
                                  attributes={attributes}
                                  errors={errors}
                />
                {
                  (showResidencePrevious && residencePreviousTime < 24) ?
                    <div>
                      <div className="applicant-section-header-title">
                        Previous Residence
                      </div>
                      <SectionResidence
                        address={[valuesWithName[ATTRIBUTE_CUSTOMER_ADDRESS_PREVIOUS], ATTRIBUTE_CUSTOMER_ADDRESS_PREVIOUS]}
                        apt={[valuesWithName[ATTRIBUTE_CUSTOMER_APT_PREVIOUS], ATTRIBUTE_CUSTOMER_APT_PREVIOUS]}
                        city={[valuesWithName[ATTRIBUTE_CUSTOMER_CITY_PREVIOUS], ATTRIBUTE_CUSTOMER_CITY_PREVIOUS]}
                        state={valuesWithName[ATTRIBUTE_CUSTOMER_STATE_PREVIOUS] ?
                          [valuesWithName[ATTRIBUTE_CUSTOMER_STATE_PREVIOUS], ATTRIBUTE_CUSTOMER_STATE_PREVIOUS] :
                          ["", ATTRIBUTE_CUSTOMER_STATE_PREVIOUS]}
                        statesList={statesList}
                        zipCode={[valuesWithName[ATTRIBUTE_CUSTOMER_ZIP_CODE_PREVIOUS], ATTRIBUTE_CUSTOMER_ZIP_CODE_PREVIOUS]}
                        housingStatus={valuesWithName[ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS] ?
                          [valuesWithName[ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS].id, ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS] :
                          ["", ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS]}
                        housingStatusList={housingStatusListPrevious}
                        year={[valuesWithName[ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS], ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS]}
                        month={[valuesWithName[ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS], ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS]}
                        monthlyRent={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_RENT_PREVIOUS], ATTRIBUTE_CUSTOMER_MONTHLY_RENT_PREVIOUS]}
                        onChangeInput={this.onChangeInput}
                        onChangeValue={this.onChangeValue}
                        onChangeMoneyInput={this.onChangeMoneyInput}
                        customerIndex={index}
                        onlyRead={onlyRead}
                        attributes={attributes}
                        errors={errors}
                      />
                    </div> : null
                }
                {
                  (showResidenceAnother && residenceAnotherTime < 24) ?
                    <div>
                      <div className="applicant-section-header-title">
                        Second Residence
                      </div>
                      <SectionResidence
                        address={[valuesWithName[ATTRIBUTE_CUSTOMER_ADDRESS_ANOTHER], ATTRIBUTE_CUSTOMER_ADDRESS_ANOTHER]}
                        apt={[valuesWithName[ATTRIBUTE_CUSTOMER_APT_ANOTHER], ATTRIBUTE_CUSTOMER_APT_ANOTHER]}
                        city={[valuesWithName[ATTRIBUTE_CUSTOMER_CITY_ANOTHER], ATTRIBUTE_CUSTOMER_CITY_ANOTHER]}
                        state={valuesWithName[ATTRIBUTE_CUSTOMER_STATE_ANOTHER] ?
                          [valuesWithName[ATTRIBUTE_CUSTOMER_STATE_ANOTHER], ATTRIBUTE_CUSTOMER_STATE_ANOTHER] :
                          ["", ATTRIBUTE_CUSTOMER_STATE_ANOTHER]}
                        statesList={statesList}
                        zipCode={[valuesWithName[ATTRIBUTE_CUSTOMER_ZIP_CODE_ANOTHER], ATTRIBUTE_CUSTOMER_ZIP_CODE_ANOTHER]}
                        housingStatus={valuesWithName[ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER] ?
                          [valuesWithName[ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER].id, ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER] :
                          ["", ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER]}
                        housingStatusList={housingStatusListAnother}
                        year={[valuesWithName[ATTRIBUTE_CUSTOMER_YEAR_ANOTHER], ATTRIBUTE_CUSTOMER_YEAR_ANOTHER]}
                        month={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTH_ANOTHER], ATTRIBUTE_CUSTOMER_MONTH_ANOTHER]}
                        monthlyRent={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_RENT_ANOTHER], ATTRIBUTE_CUSTOMER_MONTHLY_RENT_ANOTHER]}
                        onChangeInput={this.onChangeInput}
                        onChangeValue={this.onChangeValue}
                        onChangeMoneyInput={this.onChangeMoneyInput}
                        customerIndex={index}
                        onlyRead={onlyRead}
                        attributes={attributes}
                        errors={errors}
                      />
                    </div> : null
                }
              </div>
          }
        </div>
        <div className="applicant-section">
          <DPSectionHeader
            isClosed={sectionEmployment[index]}
            title="Current Employment"
            subTitle="Please provide 2 years of employment Information"
            accordionToggle={() => this.accordionToggle("sectionEmployment", index)}
          />
          {
            sectionEmployment[index] ? null :
              <div>
                <SectionEmployment name={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_NAME], ATTRIBUTE_CUSTOMER_EMPLOYER_NAME]}
                                   jobTitle={[valuesWithName[ATTRIBUTE_CUSTOMER_JOB_TITLE], ATTRIBUTE_CUSTOMER_JOB_TITLE]}
                                   cellPhone={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE], ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE]}
                                   address={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS], ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS]}
                                   city={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CITY], ATTRIBUTE_CUSTOMER_EMPLOYER_CITY]}
                                   state={valuesWithName[ATTRIBUTE_EMPLOYER_STATE] ?
                                     [valuesWithName[ATTRIBUTE_EMPLOYER_STATE], ATTRIBUTE_EMPLOYER_STATE] :
                                     ["", ATTRIBUTE_EMPLOYER_STATE]}
                                   statesList={statesList}
                                   zipCode={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE], ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE]}
                                   employmentStatus={
                                     valuesWithName[ATTRIBUTE_EMPLOYMENT_STATUS] ?
                                       [
                                         valuesWithName[ATTRIBUTE_EMPLOYMENT_STATUS].id,
                                         ATTRIBUTE_EMPLOYMENT_STATUS
                                       ] :
                                       ["", ATTRIBUTE_EMPLOYMENT_STATUS]}
                                   employmentStatusList={employmentStatusList}
                                   year={[valuesWithName[ATTRIBUTE_EMPLOYER_YEAR], ATTRIBUTE_EMPLOYER_YEAR]}
                                   month={[valuesWithName[ATTRIBUTE_EMPLOYER_MONTH], ATTRIBUTE_EMPLOYER_MONTH]}
                                   monthlyInCome={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME], ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME]}
                                   onChangeInput={this.onChangeInput}
                                   onChangeValue={this.onChangeValue}
                                   onChangeMoneyInput={this.onChangeMoneyInput}
                                   customerIndex={index}
                                   mask={cellphoneMask}
                                   onlyRead={onlyRead}
                                   attributes={attributes}
                                   errors={errors}
                                   otherIncomeSource={[valuesWithName[ATTRIBUTE_CUSTOMER_OTHER_INCOME_SOURCE], ATTRIBUTE_CUSTOMER_OTHER_INCOME_SOURCE]}
                                   monthlyIncomeFromOtherSource={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SOURCE_OTHER], ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SOURCE_OTHER]}
                                   linkName={this.state.linkNames[index][SUBSECTION.CURRENT]}
                                   linkFunc={() => this.onClickLink(SUBSECTION.CURRENT, index) }
                />
                {
                  showEmploymentSubSections[index][SUBSECTION.SECOND] &&
                  <div>
                    <div className="applicant-section-header-title">
                      {'Second Employment'}
                    </div>
                    <SectionEmployment
                      name={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_SECOND], ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_SECOND]}
                      jobTitle={[valuesWithName[ATTRIBUTE_CUSTOMER_JOB_TITLE_SECOND], ATTRIBUTE_CUSTOMER_JOB_TITLE_SECOND]}
                      cellPhone={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_SECOND], ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_SECOND]}
                      address={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_SECOND], ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_SECOND]}
                      city={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_SECOND], ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_SECOND]}
                      state={valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_SECOND] ?
                        [valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_SECOND], ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_SECOND] :
                        ["", ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_SECOND]}
                      statesList={statesList}
                      zipCode={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_SECOND], ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_SECOND]}
                      employmentStatus={valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND] ?
                        [valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND].id,
                          ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND
                        ] :
                        ['', ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND]}
                      employmentStatusList={this.state.employmentStatusSecondList}
                      year={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_SECOND], ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_SECOND]}
                      month={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_SECOND], ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_SECOND]}
                      monthlyInCome={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SECOND], ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SECOND]}
                      onChangeInput={this.onChangeInput}
                      onChangeValue={this.onChangeValue}
                      onChangeMoneyInput={this.onChangeMoneyInput}
                      customerIndex={index}
                      mask={cellphoneMask}
                      onlyRead={onlyRead}
                      attributes={attributes}
                      errors={errors}
                      linkName={this.state.linkNames[index][SUBSECTION.SECOND]}
                      linkFunc={() => this.onClickLink(SUBSECTION.SECOND, index) }
                    />
                  </div>
                }
                {
                  showEmploymentSubSections[index][SUBSECTION.THIRD] &&
                  <div>
                    <div className="applicant-section-header-title">
                      {'Third Employment'}
                    </div>
                    <SectionEmployment
                      name={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_THIRD], ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_THIRD]}
                      jobTitle={[valuesWithName[ATTRIBUTE_CUSTOMER_JOB_TITLE_THIRD], ATTRIBUTE_CUSTOMER_JOB_TITLE_THIRD]}
                      cellPhone={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_THIRD], ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_THIRD]}
                      address={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_THIRD], ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_THIRD]}
                      city={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_THIRD], ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_THIRD]}
                      state={valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_THIRD] ?
                        [valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_THIRD], ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_THIRD] :
                        ["", ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_THIRD]}
                      statesList={statesList}
                      zipCode={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_THIRD], ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_THIRD]}
                      employmentStatus={valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD] ?
                        [valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD].id,
                          ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD
                        ] :
                        ['', ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD]}
                      employmentStatusList={this.state.employmentStatusThirdList}
                      year={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_THIRD], ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_THIRD]}
                      month={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_THIRD], ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_THIRD]}
                      monthlyInCome={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_THIRD], ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_THIRD]}
                      onChangeInput={this.onChangeInput}
                      onChangeValue={this.onChangeValue}
                      onChangeMoneyInput={this.onChangeMoneyInput}
                      customerIndex={index}
                      mask={cellphoneMask}
                      onlyRead={onlyRead}
                      attributes={attributes}
                      errors={errors}
                    />
                  </div>
                }
                {
                  (showEmploymentPrevious && employmentPreviousTime < 24) ?
                    <div>
                      <div className="applicant-section-header-title">
                        {'Previous Employment'}
                      </div>
                      <SectionEmployment
                        name={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_PREVIOUS], ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_PREVIOUS]}
                        jobTitle={[valuesWithName[ATTRIBUTE_CUSTOMER_JOB_TITLE_PREVIOUS], ATTRIBUTE_CUSTOMER_JOB_TITLE_PREVIOUS]}
                        cellPhone={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_PREVIOUS], ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_PREVIOUS]}
                        address={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_PREVIOUS], ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_PREVIOUS]}
                        city={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_PREVIOUS], ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_PREVIOUS]}
                        state={valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_PREVIOUS] ?
                          [valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_PREVIOUS], ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_PREVIOUS] :
                          ["", ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_PREVIOUS]}
                        statesList={statesList}
                        zipCode={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_PREVIOUS], ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_PREVIOUS]}
                        employmentStatus={valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS] ?
                          [valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS].id,
                            ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS
                          ] :
                          ['', ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS]}
                        employmentStatusList={this.state.employmentStatusPreviousList}
                        year={[valuesWithName[ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS], ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS]}
                        month={[valuesWithName[ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS], ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS]}
                        monthlyInCome={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_PREVIOUS], ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_PREVIOUS]}
                        onChangeInput={this.onChangeInput}
                        onChangeValue={this.onChangeValue}
                        onChangeMoneyInput={this.onChangeMoneyInput}
                        customerIndex={index}
                        mask={cellphoneMask}
                        onlyRead={onlyRead}
                        attributes={attributes}
                        errors={errors}
                      />
                    </div> : null
                }
                {
                  (showEmploymentAnother && employmentAnotherTime < 24) ?
                    <div>
                      <div className="applicant-section-header-title">
                        Another Employment
                      </div>
                      <SectionEmployment
                        name={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_ANOTHER], ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_ANOTHER]}
                        jobTitle={[valuesWithName[ATTRIBUTE_CUSTOMER_JOB_TITLE_ANOTHER], ATTRIBUTE_CUSTOMER_JOB_TITLE_ANOTHER]}
                        cellPhone={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_ANOTHER], ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_ANOTHER]}
                        address={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_ANOTHER], ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_ANOTHER]}
                        city={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_ANOTHER], ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_ANOTHER]}
                        state={valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER] ?
                          [valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER], ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER] :
                          ["", ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER]
                        }
                        statesList={statesList}
                        zipCode={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_ANOTHER], ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_ANOTHER]}
                        status={valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER] ?
                          [valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER].id, ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER] :
                          ["0", ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER]
                        }
                        year={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_ANOTHER], ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_ANOTHER]}
                        month={[valuesWithName[ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_ANOTHER], ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_ANOTHER]}
                        monthlyInCome={[valuesWithName[ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_ANOTHER], ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_ANOTHER]}
                        onChangeInput={this.onChangeInput}
                        onChangeValue={this.onChangeValue}
                        onChangeMoneyInput={this.onChangeMoneyInput}
                        customerIndex={index}
                        mask={cellphoneMask}
                        onlyRead={onlyRead}
                        attributes={attributes}
                        errors={errors}
                      />
                    </div> : null
                }
              </div>
          }
        </div>
        <div className="applicant-section" style={styleApplicantSection}>
          <DPSectionHeader
            isClosed={sectionCredit[index]}
            title="Credit Score"
            subTitle="Please select the most accurate information about your credit score since this will help us get you a better approval"
            accordionToggle={() => this.accordionToggle("sectionCredit", index)}
          />
          {
            sectionCredit[index] ? null :
              <div className="applicant-section-content">
                <div className="flex-row">
                  {
                    attributeCreditScore ?
                      attributeCreditScore.map(creditScore => {
                        return (
                          <ButtonValue key={creditScore.name} classCol="flex-colR1"
                                       id={creditScore.id}
                                       displayValue={creditScore.displayValue}
                                       selected={valuesWithName[ATTRIBUTE_CREDIT_SCORE] ?
                                         [valuesWithName[ATTRIBUTE_CREDIT_SCORE].id, ATTRIBUTE_CREDIT_SCORE] :
                                         ["", ATTRIBUTE_CREDIT_SCORE]
                                       }
                                       customerIndex={index}
                                       onChangeValue={this.onChangeValue}/>
                        )
                      }) : null
                  }
                </div>
                <div className="applicant-section-header">
                  <div className="applicant-section-header-info">
                    <div className="applicant-section-header-title">
                      Type of Credit Number
                    </div>
                    <div className="applicant-section-header-subtitle">
                      Please chose one of the following
                    </div>
                  </div>
                </div>
                <div className="flex-row">
                  {
                    attributeTypeCredit ?
                      attributeTypeCredit.map(creditType => {
                        return (
                          <ButtonValue key={creditType.name} classCol="flex-col-r15"
                                       id={creditType.id}
                                       displayValue={creditType.displayValue}
                                       selected={valuesWithName[ATTRIBUTE_TYPE_CREDIT] ?
                                         [valuesWithName[ATTRIBUTE_TYPE_CREDIT].id, ATTRIBUTE_TYPE_CREDIT] :
                                         ["", ATTRIBUTE_TYPE_CREDIT]}
                                       customerIndex={index}
                                       onChangeValue={this.onChangeValue}/>
                        )
                      }) : null
                  }
                </div>
                <div className="applicant-section-header">
                  <div className="applicant-section-header-info">
                    <div className="applicant-section-header-title">
                      Type of Identification
                    </div>
                    <div className="applicant-section-header-subtitle">
                      Please chose one of the following
                    </div>
                  </div>
                </div>
                <div className="flex-row">
                  {
                    attributeTypeId ?
                      attributeTypeId.map(idType => {
                        return (
                          <ButtonValue key={idType.name} classCol="flex-col-r18"
                                       id={idType.id}
                                       displayValue={idType.displayValue}
                                       selected={valuesWithName[ATTRIBUTE_TYPE_ID] ?
                                         [valuesWithName[ATTRIBUTE_TYPE_ID].id, ATTRIBUTE_TYPE_ID] :
                                         ["", ATTRIBUTE_TYPE_ID]}
                                       customerIndex={index}
                                       onChangeValue={this.onChangeValue}/>
                        )
                      }) : null
                  }
                </div>
              </div>
          }
        </div>
      </div>
    )
  };

  render() {
    const {valuesWithNames, financeObj} = this.state;
    const {hideApp,height, errors} = this.props;

    if (valuesWithNames.length === 0)
      return null;

    const styleApplicantSectionMain = {};
    const styleApplicantSectionCosigner = {};
    if (valuesWithNames.length > 1) {
      styleApplicantSectionMain.borderBottom = "1px solid #cacaca";
    }else{
      styleApplicantSectionCosigner.borderBottom = "1px solid #cacaca";
    }

    // Determines which are new co-signers
    const newCoSignerIndexes = financeObj.values.reduce((result, el, index) => {
      if ((el.leadId === '' && get(el, ['objectId'], null) === '')
        || (el.customerId === '' && get(el, ['objectId'], null) === '')) {
        result.push(index)
      }

      return result
    }, [])

    return (
      <div className={'body-view-application'}
           style={{display: (hideApp) ? "none" : "unset", maxHeight: isNumber(height) ? (height - 50) : height}}>
        {
          valuesWithNames.map((valuesWithName, index) => {
            let customerId;
            customerId = get(this.state.financeObj, ["values", index, 'customerId'], '')
            if(isEmpty(customerId))
              customerId = get(this.state.financeObj, ["values", index, 'objectId'], '')
            const leadId = get(this.state.financeObj, ["values", index, 'leadId'], '')

            const applicantToDelete = {
              financeId: this.state.financeObj['financeId'],
              leadId,
              customerId,
              index,
            }
            const isNewCoSigner = newCoSignerIndexes.includes(index)

            const newErrors = errors.length > 0 ? getErrors(errors,index) : {}

            return (
              index === 0
                ? this.renderApplicant(valuesWithName, index, '', styleApplicantSectionCosigner, false, newErrors)
                : this.isJoinCreditOnMainApplicant()
                  ? <div key={`applicant-section-wrapper-${index}`} className={'applicant-joint-credit'}>
                    <div className='applicant-joint-credit-title'>
                      <ReactSVG
                        src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                        className="icon-delete"
                        beforeInjection={svg => svg.classList.add('img-delete')}
                        onClick={() => this.props.handleModalDelete(applicantToDelete)}
                      />
                      {
                        isNewCoSigner
                          ? <label className={'rotate'}>{`New co-signer`}</label>
                          : <label className={'rotate'}>{`Join credit / co-signer ${index}`}</label>
                      }
                    </div>
                    {this.renderApplicant(valuesWithName, index, 'applicant-section-wrapper-joint', styleApplicantSectionMain, isNewCoSigner, newErrors)}
                  </div>
                  : null
            )
          })
        }
        <div className="applicant-section">
          <DPSectionHeader
            isClosed={this.state.sectionComments[0]}
            title="Comments"
            accordionToggle={() => this.accordionToggle("sectionComments", 0)}
          />
          <div>
            {
              !this.state.sectionComments[0] &&
                financeObj.comments.map((item) => {
                  const {comment, userId} = item
                  return (
                    <div className={this.props.userId === userId ? "comment-finance-left" : "comment-finance-right"} >
                      <div className="comment">
                        <label>{comment}</label>
                      </div>
                      <div className="initials">{this.getInitials(userId)}</div>
                    </div>
                  )
                })
            }
          </div>
          {
            !this.state.sectionComments[0] &&
            <div className="applicant-section-content">
              <div className="new-comment-container">
                <input type="text" className="input-comment" value={this.state.newComment} placeholder="Type your comment..." onChange={this.onChangeNewComment}/>
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_send_24px.svg"}
                            className="icon-send-container" onClick={this.addNewComment}/>
              </div>
            </div>
          }
        </div>
        {
          valuesWithNames.length > 1 && this.isJoinCreditOnMainApplicant()
            ? <div className='container-add-cosigner'>
              <button
                onClick={() => this.handleAddNewCoSigner()}
                className="btn-add-cosigner"
              >
                {'Add another co-signer'}
              </button>
            </div>
            : null
        }
      </div>
    )
  }

  onClickLink = (subSectionName, index) => {
    let linkNames = {...this.state.linkNames}
    let showEmploymentSubSections = {...this.state.showEmploymentSubSections}
    const linkName = this.state.linkNames[index][subSectionName]
    let newLinkName
    let showNextSubSectionFlag = true

    // These variable are used when removing a subSection
    let newFinanceObj = {...this.state.financeObj}
    let newValuesWithNames = [...this.state.valuesWithNames]

    if (linkName === ADD_SECOND_EMPLOYMENT) {
      newLinkName = REMOVE_SECOND_EMPLOYMENT
    }

    if (linkName === ADD_THIRD_EMPLOYMENT) {
      newLinkName = REMOVE_THIRD_EMPLOYMENT

      linkNames = {
        ...linkNames,
        [index]: {
          ...linkNames[index],
          [SUBSECTION.CURRENT]: '',
        }
      }
    }

    if (linkName === REMOVE_THIRD_EMPLOYMENT) {
      newLinkName = ADD_THIRD_EMPLOYMENT
      showNextSubSectionFlag = false

      linkNames = {
        ...linkNames,
        [index]: {
          ...linkNames[index],
          [SUBSECTION.CURRENT]: REMOVE_SECOND_EMPLOYMENT,
        }
      }
    }

    if (linkName === REMOVE_SECOND_EMPLOYMENT) {
      newLinkName = ADD_SECOND_EMPLOYMENT
      showNextSubSectionFlag = false
    }

    if (linkName.includes('Remove')) {
      const attributesToRemove = {
        [SUBSECTION.CURRENT]: ATTRIBUTES_FOR_SECOND_EMPLOYMENT,
        [SUBSECTION.SECOND]: ATTRIBUTES_FOR_THIRD_EMPLOYMENT,
      }

      const filteredAttributes = attributesToRemove[subSectionName].map(el => this.state.attributes.find(attribute => attribute.name === el))
      const filteredValues = filteredAttributes.reduce((result, attribute) => {
        return (
          { ...result,
            [attribute.id]: newFinanceObj.values[index][attribute.id]
          }
        )
      }, {})

      const newFinanceObjValues = [...this.state.financeObj.values]
      const newValues = blankValuesObj(filteredValues)
      newFinanceObj.values = newFinanceObjValues
      newFinanceObj.values[index] = {
        ...newFinanceObj.values[index],
        ...newValues,
      }

      const newValuesWithName = this.getValuesWithAttributeName(newValues, filteredAttributes);
      newValuesWithNames[index] = {
        ...newValuesWithNames[index],
        ...newValuesWithName
      }
    }

    linkNames = {
      ...linkNames,
      [index]: {
        ...linkNames[index],
        [subSectionName]: newLinkName,
      }
    }

    showEmploymentSubSections = {
      ...showEmploymentSubSections,
      [index]: {
        ...showEmploymentSubSections[index],
        [NEXTSUBSECTION[subSectionName]]: showNextSubSectionFlag
      }
    }

    newFinanceObj['showEmploymentSubSections'] = showEmploymentSubSections

    this.setState(
      {
        linkNames,
        showEmploymentSubSections,
        valuesWithNames: newValuesWithNames,
        financeObj: newFinanceObj,
      },
      () => {
        this.props.updateFinanceObj(newFinanceObj)
      }
    )
  }
}