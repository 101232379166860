import React, {Component} from 'react'
import Customer from './Customer.jsx'
import {connect} from 'react-redux'
import {CUSTOMERS_BY_PAGE_BY_DEFAULT, SIDEBAR_WIDTH_EXPANDED, SIDEBAR_WIDTH_UNEXPANDED} from '../../Constants'
import {loadAllActiveUsers, setKeyword} from '../../actions'
import {setPlaceHolder} from '../util/Utils'
import {loadCustomersApi} from '../../Api'
import isEmpty from 'lodash/isEmpty'
import CustomerLandingPage from '../review/customer/CustomerLandingPage'
import DPLoader from "../../common/DPLoader";
import {browserHistory} from "react-router";
import {SUCCESSFUL_CODE} from "../../../common/Constants";

class CustomerContainer extends Component {

  constructor() {
    super();
    this.loadCustomers        = this.loadCustomers.bind(this);
    this.setLoading           = this.setLoading.bind(this);
    this.setCustomerManually  = this.setCustomerManually.bind(this);
    this.state = {
      isLoading:true,
      totalPages:1,
      //users : null,
      customers: null,
      isAddCustomerManually: false
    }
  }

  /*
  componentWillMount(){
    this.loadCustomers('',0,CUSTOMERS_BY_PAGE_BY_DEFAULT);
    const {activeUsers, loadAllActiveUsers} = this.props;
    if(!activeUsers){
      loadAllActiveUsers()
    }
  }

   */

  async componentWillReceiveProps(nextProps) {
    const {keyword} = nextProps;
    let from = 0;
    let itemsByPageQuantity = CUSTOMERS_BY_PAGE_BY_DEFAULT;

    if(this.customer){
      from = this.customer.state.from;
      itemsByPageQuantity = this.customer.state.itemsByPageQuantity;
    }
    await this.loadCustomers(keyword,from,itemsByPageQuantity);
  }

  async componentDidMount() {
    await this.loadCustomers('',0,CUSTOMERS_BY_PAGE_BY_DEFAULT);
    const {activeUsers, loadAllActiveUsers, showSideBar} = this.props;
    if(!activeUsers){
      loadAllActiveUsers()
    }
    if (showSideBar) {
      let container = window.document.getElementById("container");
      container.className += ' container-collapse';
    }

    setPlaceHolder("menu-search","Search by firstName, lastName, email and phone");
  }

  loadCustomers = async (keyword,from,size) => {
    const {totalPages} = this.state;
    //const sourceId = "dealer" || "review";
    const response = await loadCustomersApi(keyword,from,size)
    const {responseCode, responseMessage, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      const {customers,customersQuantity} = data;
      let newTotalPages = customersQuantity ? Math.ceil(customersQuantity / size) : 1;
      if(customers.length === 0){
        newTotalPages = totalPages;
      }
      this.setState({customers,customersQuantity,totalPages:newTotalPages,isLoading:false});
    }else{
      console.error(responseMessage)
    }
  }

  setLoading(isLoading){
    this.setState({isLoading});
  }

  setCustomerManually(){
    this.setState({isAddCustomerManually: true});
    browserHistory.push({pathname: `/main/customer/new`})
  }

  setAddCustomerManually = (value) => {
    this.setState({isAddCustomerManually: value});
  }

  render() {
    const {height,showSideBar,optionsBody,setKeyword,keyword} = this.props;
    const {customers,customersQuantity,totalPages,isLoading,isAddCustomerManually} = this.state;

    if(!customers)
      return     <DPLoader show={isLoading}/>;

    let left;
    if(showSideBar){
      left = SIDEBAR_WIDTH_EXPANDED;
    } else{
      left = SIDEBAR_WIDTH_UNEXPANDED;
    }

    return (
      <div id="container" className="container-component" style={{height: height}}>
        {
          isEmpty(customers) && !isAddCustomerManually && isEmpty(keyword) ?
            <CustomerLandingPage setCustomerManually={this.setCustomerManually}/> :
              (this.props.children) ?
                <div>
                  {React.cloneElement(this.props.children, {height: height -130,left:left, setAddCustomerManually: this.setAddCustomerManually})}
                </div> :
                <Customer ref={el => this.customer = el} customers={customers} customersQuantity={customersQuantity} optionsBody={optionsBody} left={left}
                          keyword={keyword} setKeyword={setKeyword} loadCustomers={this.loadCustomers} height={height} totalPages={totalPages}
                          isLoading={isLoading} setLoading={this.setLoading} />
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId:state.dealerId,
    makes:state.makes,
    models:state.models,
    cars: state.cars,
    optionsBody:state.optionsBody,
    showSideBar:state.showSideBar,
    keyword:state.keyword,
    activeUsers: state.activeUsers,
  }
};

export default connect(mapStateToProps,{setKeyword, loadAllActiveUsers})(CustomerContainer)