import React from 'react'
// @ts-ignore
import classnames from 'classnames'
// @ts-ignore
import DPIcon, {Icons} from '../../../admin/common/DPIcon'

require('./DPAlertMessage.scss')
const defaultText = 'Do you want to leave without saving?'
interface AlertMessageProps {
  showIcon? : boolean
  icon? : string
  textComponent? : any
  secondMessageText? : string
  className? : string
  style? : object
}

const DPAlertMessage = ( props : AlertMessageProps) => {
  const { icon,
          textComponent = defaultText,
          secondMessageText,
          className,
          style = {},
          showIcon = true
        } = props
  return(
    <div style={style} className={classnames(['container-alert-message', className])}>
      {
        showIcon &&
        <DPIcon
          icon={icon ? icon : Icons.EXCLAMATION}
          className="icon-class"
          style={{width: 'unset', height: 'unset'}}
        />
      }
      <div>
        { textComponent
          ? textComponent
          : null
        }
        {
          secondMessageText && <span className={'second-message'}>{` (${secondMessageText})`}</span>
        }
      </div>
    </div>
  )
}

export default  DPAlertMessage