import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../common/Constants'

export default class ModalError extends Component{

  constructor(){
    super()
  }

  render(){
    const {showModal,hideModal,errorMessage,tryDate,onClickButton, buttonText, className} = this.props;
    return(
      <div className="modal fade">
        <Modal className={"modal-sig-in "+ className} show={showModal}>
          <Modal.Body>
            <div className="body-left">
              <img src={pathServer.PATH_IMG + 'error-image.png'}/>
            </div>
            <div className="body-right">
              <div className="container-icon-close" onClick={hideModal}>
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close"/>
              </div>
              <label  className="message-error">{errorMessage}</label>
              {
                tryDate ?
                  <label  className="date-error">{tryDate}</label> :
                  null
              }
              <div className="container-button-sign">
                <button className="sign-in" onClick={onClickButton}>{buttonText}</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}