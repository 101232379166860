import React, {Component} from 'react'
import Task from './Task.jsx'
import {
  FILTER_TYPE_TASK,
  SIDEBAR_WIDTH_EXPANDED,
  SIDEBAR_WIDTH_UNEXPANDED,
  TASK_TYPE, USER_ID_NOT_ASSIGNED,
  TASK_NORMAL_PLACE_HOLDER,
  TASK_REPAIR_PLACE_HOLDER
} from '../../Constants'
import {clone, findIndex, find, forEach} from 'lodash'
import {connect} from 'react-redux'
import {ReactSVG} from 'react-svg'
import {
  deleteTaskApi,
  loadBoards,
  loadTasksByFilters,
  updateReorderTask,
  updateTaskActiveApi,
  updateTaskStatus
} from '../../Api'
import {setKeyword, setMenuId, setTaskFilters} from '../../actions'
import {browserHistory} from 'react-router'
import {
  convertListToString,
  getAnchorEl,
  getComputedStyleById,
  getSubString,
  getWidthColumn,
  setPlaceHolder
} from '../util/Utils'
import ModalConfirmDelete from '../util/ModalConfirmDelete.jsx'
import Checkbox from 'material-ui/Checkbox'
import {DPArrowCircle} from '../util/ComponentUtils'
import DragDrop from '../dragdrop/DragDrop'
import DPDroppable from '../dragdrop/Droppable'
import DPDraggable from '../dragdrop/Draggable'
import ModalConfirmDeactivate from '../util/ModalConfirmDeactivate'
import {DPPopover} from '../../../common/DPPopover'
import DPCheckbox from '../../common/DPCheckbox'
import {getPosition} from '../../../common/Util'
import {elementCheck, elementUnCheck} from '../../../common/ComponentUtils'
import DPLoader from '../../common/DPLoader'
import {isMobileDevice} from '../../../common/StyledComponentUtil'
import {pathServer, WIDTH_VIEW_MOBILE, WIDTH_VIEW_TABLET, WIDTH_VIEW_WEB, SUCCESSFUL_CODE} from '../../../common/Constants'
import DPButton from '../../../common/genericComponents/DPButton'
import {BUTTON_TYPE, BUTTON_SIZE, BUTTON_COLOR, BUTTON_TEXT_CAP} from '../../../common/genericComponents/DPButton'
import './taskButtons.scss'
//import './TaskList.scss'

const styleTarget = {width: "100%", float: "left", marginTop: 15, minHeight: 80, borderRadius:8};
//const styleLine = {width: "100%", float: "left", margin: "8px 0"};
//const styleDrag = {borderRadius : 8, border : "1.5px #e7e8eb solid", height : 80, position:"relative"};
//const styleBorder = "2px #9ebbd0 solid";

const priorities = [
  {id:"all", name:"All", color:"#005294", styleBorder:"4px #005294 solid"},
  {id:"low", name:"Low", color:"#2194F1", styleBorder:"4px #2194F1 solid"},
  {id:"medium", name:"Medium", color:"#F3A200", styleBorder:"4px #F3A200 solid"},
  {id:"high", name:"High", color:"#FF6565", styleBorder:"4px #FF6565 solid"}
];

const types = [
  {id:"normal", name:"Normal"},
  {id:"repair", name:"Repair"}
];

const maxRowsPerPage = 4;

function getPriority(prioritySelected){
  let priority = null;
  if(prioritySelected !== "all")
    priority = prioritySelected;

  return priority
}

//todo: review this function
function setStyleToArrowRight(right){
  const taskEnd = document.getElementById('taskEnd');
  const el = getPosition(taskEnd);
  const dif = el.x - container.offsetWidth - container.scrollLeft;
  if(dif > 20){
    if(right)
      right.style.display = "block";
  }else{
    if(right)
      right.style.display = "block";
  }
}

function setCssToCardHeader(scrollLeft,widthPage){
  const sidebar = document.getElementById('sidebar');
  const bodyTask = document.getElementById('bodyTask');
  if(bodyTask){
    const compStyles = window.getComputedStyle(bodyTask);
    const paddingLeftString = compStyles.getPropertyValue('padding-left');
    const paddingLeft = parseInt(paddingLeftString.split('px')[0]);
    const card = document.getElementById('taskSectionHeader');

    let sidebarWidth;
    if(widthPage > 768){
      sidebarWidth = sidebar.offsetWidth;
    }else{
      sidebarWidth = 0;
    }

    // if(card){
    //   const left = sidebarWidth + paddingLeft -1*scrollLeft;
    //   card.style.left = `${left}px`
    // }
  }
}

function setHeaderBox(id,scrollTop) {
  const taskBoxHeader = document.getElementById(id);
  if(scrollTop > 0){
    if(taskBoxHeader)
      taskBoxHeader.style.backgroundColor = '#f5f6fa';
  }else{
    if(taskBoxHeader)
      // taskBoxHeader.style.backgroundColor = '#f5f6fa';
    taskBoxHeader.style.backgroundColor = '#fff';
  }
}

const Type = ({typeSelected,handleSelectChange}) => {
  return(
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {
            types.map((type, index) => {
              return(
                <li key={index} id={type.id}
                    className={type.id === typeSelected ? "item-selected" : "change-submenu-item"}
                    onClick={() => handleSelectChange(type.id)}>
                  {type.name}
                </li>
              )
            })
          }
        </u>
      </div>
    </div>
  )
};

const labelStyle = {color: "white",fontSize: 11, fontWeight: "400", width: "100%", marginBottom: 2, marginTop: 2};

const ImageUser = ({image,userName}) => {
  return(
    <div>
      <img src={image ? image : pathServer.PATH_IMG+"ic_account_circle.png"}
           style={{borderRadius: "50%", width:24, height:24, marginRight: 5}} />
      <label style={{marginBottom: 2}}>{userName}</label>
    </div>
  )
};

const User = ({users,widthPage,userIdsSelected,handleUserSelect}) => {
  let startIndex = 0;
  if(widthPage > WIDTH_VIEW_MOBILE){
    startIndex = 5
  } else {
    startIndex = 4
  }

  return(
    <div className="popover-general-message">
      <div className="popover-general-message-sub" style={{paddingTop:10, paddingLeft: 0, paddingRight: 0, paddingBottom: 12}}>
        {
          users.map((user,index) => {
            if(index > startIndex) {
              const checked = findIndex(userIdsSelected, (value) => {
                return value === user.id
              });

              const userName = getSubString(`${user.firstName} ${user.lastName}`, 18);

              return(
                <div key={user.id} className={"content-check"}>
                  <Checkbox label={<ImageUser image={user.image} userName={userName} />} checked={(checked !== -1)} id={user.id}
                            onCheck={(evt) => handleUserSelect(evt)}
                            labelStyle={checked === -1 ? {...labelStyle,color: "#a5a5a5"} : labelStyle}
                            checkedIcon={elementCheck()} uncheckedIcon={elementUnCheck()}
                            iconStyle={{borderColor: "#777", marginRight: 8, marginLeft: 4}}
                            className={checked === -1 ? "change-submenu-item" : "item-selected"}
                            name={`${user.firstName} ${user.lastName}`} data-test={`option-${index}`}/>
                </div>
              )
            }
          })
        }
      </div>
    </div>
  )
};

const PriorityFilter = ({handlePriority}) => {
  return (
    <div className="task-bars">
      <div className="left">
        Priority
      </div>
      <div className="right">
        {
          priorities.map((priority,index) => {
            return(
              <button id={priority.id} key={index} className="button-priority"
                      onClick={() => handlePriority(priority.id)}
                      style={{userFocus:{borderColor:"#D7DAE2"}}}>
                {priority.name}
              </button>
            )
          })
        }
      </div>
    </div>
  )
};

const TextUser = ({displayValue,showAllUser,marginLeft,handleShowUser}) => {
  return(
    <div id="popoverUser" className={showAllUser ? "icon-user-task-arrow":"icon-user-task-popover"} style={{marginLeft:marginLeft}}
         onClick={(event) => handleShowUser("popoverUser",event)}>
      <label>{displayValue}</label>
    </div>
  )
};

const SectionHeaderOld = ({minWidthTask,displayValue,itemsQuantity,typeSelected,image,className,backgroundColor,id,toggleDroppable, show}) => {
  const isMobileView = window.screen.width < WIDTH_VIEW_MOBILE
  return(
    <div className={`container-section-task ${className}`} style={{minWidth:minWidthTask}}>
      <div className="card" onClick={() => toggleDroppable(id)} style={isMobileView ? {cursor: "Pointer"} : {}}>
        {
          isMobileView && show ?
              <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>:
              <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
        }
        <div className="card-name" style={isMobileView ? {paddingLeft: 10} : {} }>
          <h4>{displayValue}</h4>
        </div>
        <div className="card-task" style={{backgroundColor:backgroundColor}}>
          <div className="card-number">
            <h1>{itemsQuantity}</h1>
          </div>
          {
            typeSelected === TASK_TYPE.NORMAL ?
              <div className="card-images">
                <img src={image}/>
              </div> : null
          }
        </div>
      </div>
    </div>
  )
};
const SectionHeader = ({minWidthTask,displayValue,itemsQuantity,typeSelected,image,className,backgroundColor,id,toggleDroppable, show}) => {
  const isMobileView = window.screen.width < WIDTH_VIEW_MOBILE
  return(
    <div className={`container-section-task ${className}`}>
      <div className="header-column" style={isMobileView ? {paddingLeft: 10} : {} }>
        <h4>{displayValue}</h4>
      </div>
    </div>
  )
}

const minHeight = 500;
const DroppableContainer = ({sectionIndex,id,classNameWrapper,minWidthTask,items,editTask,typeSelected,deleteTask,changeActiveTask,changeInactiveTaskSubmit, show}) => {
  const isMobileView = window.screen.width < WIDTH_VIEW_MOBILE
  const emptyMobileDroppable = 10
  const minDroppableHeight = isMobileView ? emptyMobileDroppable : minHeight
  const droppableStyle = !!!items.length ? { minHeight: minDroppableHeight,zIndex:1} : {}
  const draggableStyle = isMobileView ? {userSelect: 'none' ,marginBottom: 5} : {userSelect: 'none', marginBottom: 20}

  return(
    <div id={`section${sectionIndex}`} className={`container-section-task ${classNameWrapper}`} style={{minWidth:minWidthTask,paddingTop: 10, paddingBottom: 15}}>
      <div className="container-list-task-body">
        <DPDroppable droppableId={id} droppableStyle={droppableStyle}>
          {
            show && items.map((task, index) => {
              const indexPriority = findIndex(priorities,(priority) => {return priority.id === task.priority});
              const styleBorderLeft = priorities[indexPriority].styleBorder;

              return (
                <DPDraggable key={task.id} itemId={task.id} index={index} draggableStyle={draggableStyle}>
                  <Task task={task}
                        styleBorderLeft={{borderLeft: styleBorderLeft}}
                        editTask={editTask}
                        typeSelected={typeSelected}
                        deleteTask={deleteTask}
                        changeActiveTask={changeActiveTask}
                        changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                        taskIndex={index}
                        sectionIndex={sectionIndex}
                  />
                </DPDraggable>
              )
            })
          }
        </DPDroppable>
      </div>
    </div>
  )
};

const SectionBody = ({id,classNameWrapper,classNameSubWrapper,sections,reorder,move,minWidthTask,typeSelected,editTask,deleteTask,minWidthContainer,changeActiveTask,changeInactiveTaskSubmit}) => {
  return(
    <div id={id} className={classNameWrapper} style={{minWidth:minWidthContainer}}>
      <div id={'taskSectionBody'} className={classNameSubWrapper}>
        <DragDrop sections={sections} reorder={reorder} move={move}>
          {
            sections.map((section,sectionIndex) => {
              const {id,items,col} = section;
              return(
                <DroppableContainer key={id}
                                    sectionIndex={sectionIndex}
                                    id={id}
                                    classNameWrapper={col}
                                    minWidthTask={minWidthTask}
                                    items={items}
                                    editTask={editTask}
                                    typeSelected={typeSelected}
                                    deleteTask={deleteTask}
                                    changeActiveTask={changeActiveTask}
                                    changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                                    show={true}
                />
              )
            })
          }
        </DragDrop>
      </div>
    </div>
  )
};

const ListTaskHorizontal = ({sections,reorder,move,minWidthTask,typeSelected,editTask,deleteTask,minWidthContainer,changeActiveTask,changeInactiveTaskSubmit}) => {
  return(
    <div>
      <div id='taskBoxHeader' className='task-box-header' style={{minWidth:minWidthContainer}}>
        <div className="section-header-wrapper">
        <div id='taskSectionHeader' className='task-section-header'>
          {
            sections.map((section,index) => {
              const {id,items,col,image,color,displayValue} = section;
              return(
                <SectionHeader key={`section-header-${id}`}
                               // className={col}
                               minWidthTask={minWidthTask}
                               itemsQuantity={items.length}
                               displayValue={displayValue}
                               image={image}
                               typeSelected={typeSelected}
                               backgroundColor={color}
                               id={id}
                               toggleDroppable={() => {}}
                               show={true}
                />
              )
            })
          }
        </div>
        </div>
      </div>
      <SectionBody id={'bodyTask'}
                   classNameWrapper={'container-body-task'}
                   classNameSubWrapper={'task-section-body'}
                   sections={sections}
                   reorder={reorder}
                   move={move}
                   minWidthTask={minWidthTask}
                   typeSelected={typeSelected}
                   editTask={editTask}
                   deleteTask={deleteTask}
                   minWidthContainer={minWidthContainer}
                   changeActiveTask={changeActiveTask}
                   changeInactiveTaskSubmit={changeInactiveTaskSubmit}
      />
    </div>
  )
};

const ListTaskVertical = ({sections,reorder,move,minWidthTask,typeSelected,editTask,deleteTask,minWidthContainer,changeActiveTask,changeInactiveTaskSubmit, showSections, sectionIds, toggleSectionDroppable}) => {
  const sectionsLength = sections.length;
  return(
    <div className={'list-task-vertical'}>
      <DragDrop sections={sections} reorder={reorder} move={move}>
        {
          sections.map((section,sectionIndex) => {
            const {id,items,col,image,color,displayValue} = section;
            return(
              <div key={`listTaskVertical${sectionIndex}`} className={'col100'}>
                <div className='task-box-header-vertical' style={{minWidth:minWidthContainer}}>
                  <div className='task-section-header-vertical'>
                    <SectionHeader className={col}
                                   minWidthTask={minWidthTask}
                                   itemsQuantity={items.length}
                                   displayValue={displayValue}
                                   image={image}
                                   typeSelected={typeSelected}
                                   backgroundColor={color}
                                   id={id}
                                   toggleDroppable={toggleSectionDroppable}
                                   show={showSections[sectionIds.indexOf(id)]}
                    />
                    <DroppableContainer id={id}
                                        classNameWrapper={col}
                                        typeSelected={typeSelected}
                                        items={items}
                                        sectionIndex={sectionIndex}
                                        editTask={editTask}
                                        deleteTask={deleteTask}
                                        minWidthTask={minWidthTask}
                                        changeActiveTask={changeActiveTask}
                                        changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                                        show={showSections[sectionIds.indexOf(id)]}
                    />
                  </div>
                </div>
              </div>
            )
          })
        }
      </DragDrop>
    </div>
  )
};

const getMinWidth = (widthPage,typeSelected,sectionsQuantity) => {
  let minWidthContainer;
  let minWidthTask ;

  if(widthPage > WIDTH_VIEW_WEB){
    //minWidthContainer = typeSelected === TASK_TYPE.REPAIR ? "2780PX" : "1140px";
    minWidthContainer = typeSelected === TASK_TYPE.REPAIR ? (260*sectionsQuantity + 90) + "px" : (370*sectionsQuantity + 20) + "px";
    minWidthTask = typeSelected === TASK_TYPE.REPAIR ? "260px" : "350px";
  }else if(widthPage <= WIDTH_VIEW_WEB && widthPage > WIDTH_VIEW_TABLET) {
    minWidthContainer = typeSelected === TASK_TYPE.REPAIR ? (280*sectionsQuantity + 20) + "px" : (370*sectionsQuantity + 20) + "px";
    minWidthTask = typeSelected === TASK_TYPE.REPAIR ? "260px" : "350px";
  }else if(widthPage <= WIDTH_VIEW_TABLET && widthPage > WIDTH_VIEW_MOBILE){
    //minWidthContainer = typeSelected === TASK_TYPE.REPAIR ? "2270px" : "1280px";
    minWidthContainer = typeSelected === TASK_TYPE.REPAIR ? (280*sectionsQuantity + 20) + "px": (420*sectionsQuantity + 20) + "px";
    minWidthTask = typeSelected === TASK_TYPE.REPAIR ? "260px" : "400px";
  }else if(widthPage <= WIDTH_VIEW_MOBILE){
    minWidthContainer = typeSelected === TASK_TYPE.REPAIR ? "3350px" : "1070px";
    minWidthTask = typeSelected === TASK_TYPE.REPAIR ? "330px" : "330px";
  }
  return {minWidthContainer,minWidthTask}
};

const getLeft = (widthPage,showSideBar) => {
  let left;
  if(showSideBar && widthPage > WIDTH_VIEW_TABLET){
    // left = SIDEBAR_WIDTH_EXPANDED;
  } else if(widthPage > WIDTH_VIEW_MOBILE){
    // left = SIDEBAR_WIDTH_UNEXPANDED;
    left = 20;
  } else {
    left = 20
  }
  return left;
};

function setMinWidthTaskSpecial(minWidthTask, sections) {
  let value = parseInt(minWidthTask.split('px')[0]);
  const getProperty = getComputedStyleById('bodyTask','padding-left');
  let paddingLeft = 0;
  if(getProperty)
    paddingLeft = parseInt(getProperty.split('px')[0]);

  let result = "";
  if((value === 400 || value === 350 || value === 260) && paddingLeft === 20){
    value += 20;
    result = `${value}px`
  }else{
    result = minWidthTask;
  }

  let element;
  sections.forEach((sec,index) => {
    element = document.getElementById(`section${index}`);
    if(element)
      element.style.minWidth = result;
  })
}

function setWidthToTaskSectionHeader(widthPage,showSideBar) {
  //const bodyTask = document.getElementById('bodyTask');
  let paddingLeft = 0;
  const getProperty = getComputedStyleById('bodyTask','padding-left');
  if(getProperty)
    paddingLeft = parseInt(getProperty.split('px')[0]);
  const card = document.getElementById('taskSectionHeader');
  // if(card)
  //   card.style.width = `calc(100% - ${(40 + paddingLeft + getLeft(widthPage,showSideBar))}px)`
}

function setArrowElement(widthPage,typeSelected) {
  const content = document.getElementById('container');
  let maxScrollLeft = content.scrollWidth - content.clientWidth;

  const scrollLeft = Math.round(content.scrollLeft);
  const arrowLeft = document.getElementById('back_to_left_invert');
  const arrowRight = document.getElementById('back_to_right');
  let arrowLeftDisplay = "block";
  let arrowRightDisplay = "block";

  if(widthPage < 1420){
    if(scrollLeft === 0)
      arrowLeftDisplay = "none";

    if(scrollLeft === maxScrollLeft || scrollLeft === maxScrollLeft + 1)
      arrowRightDisplay = "none"
  }else{
    if(typeSelected === TASK_TYPE.REPAIR){
      if(scrollLeft === 0)
        arrowLeftDisplay = "none";

      if(scrollLeft === maxScrollLeft || scrollLeft === maxScrollLeft + 1)
        arrowRightDisplay = "none"
    }else if(arrowRight){
      arrowLeftDisplay = "none";
      arrowRightDisplay = "none"
    }
  }

  if(isMobileDevice()){
    if(arrowLeftDisplay){
      arrowLeftDisplay = "none";
      arrowRightDisplay = "none"
    }
  }

  if(arrowLeft)
    arrowLeft.style.display = arrowLeftDisplay;
  if(arrowRight)
    arrowRight.style.display = arrowRightDisplay;
}

class TasksList extends Component {
  constructor() {
    super();
    this.state = {
      tasks: null,
      users:[],
      showAllUser: false,
      showOptions: false,
      prioritySelected: "all",
      widthPage: window.innerWidth,
      priorityName: "",
      userIdsSelected:[],
      sections:null,
      statuses:null,
      typeSelected: TASK_TYPE.NORMAL,
      keyword:null,
      showFilterPriorities : false,
      showModal: false,
      idTask: null,
      isLoading : false,
      usersActive:[],
      selectedUsers:[],
      displayBtnLeft : true,
      showPopoverFilterType: false,
      filterSelected: null,
      taskFilters: [FILTER_TYPE_TASK[0].value],
      showModalActiveTask: false,
      sectionIds: [],
      showSections: [],
      search: null,
      showSideBar: false
    };
    this.element = [null,null,null];
  }

  loadTasks(priority,users,type){
    const {taskFilter} = this.props;
    const {taskFilters} = taskFilter;
    this.setState((state) => ({...state,isLoading:true}));
    const filterSelected = convertListToString(taskFilters ? taskFilters:[]);
    let content = document.getElementById('container');
     loadTasksByFilters(priority,users,type,filterSelected).then(({responseCode,data,responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          const tasks = data.tasks;
          let {statuses,keyword,displayBtnLeft} = this.state;
          let tasksEdit = tasks;
          keyword = this.props.search
          if(keyword && keyword !== ''){
            tasksEdit = tasks.filter(task => {
              return task.title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
            });
          }

          let taskFiltered;
          const col = getWidthColumn(statuses.length);
          let sections = statuses;
          sections.forEach((section) => {
            taskFiltered = tasksEdit.filter(task => { return task.status === section.id});
            section.col   = col;
            section.image = type === "normal" ?  pathServer.PATH_IMG + "task-"+section.id+".png" : null;
            section.items = taskFiltered;
          });
          this.setState({tasks:tasks,sections:sections,isLoading:false, sectionIds: sections.map(_ => _.id), showSections: sections.map(section => true)});
          const {search} = this.props;
          this.findByKeyWord(search);
          if(!displayBtnLeft){
            this.scrollToRight(content,1034,9);
          }
        } else {
          console.error(responseMessage);
          this.setState({isLoading:false})
        }
    });
  }

  async componentDidMount() {
    const {taskFilter,setTaskFilters, activeUsers, showRepair} = this.props;
    this.setState({isLoading:true});
    const typeSelected = showRepair ? "repair" : taskFilter.type;
    const userIdsSelected = taskFilter.userIds;
    const prioritySelected = taskFilter.priority;
    const priority = getPriority(prioritySelected);
    const usersJoin = convertListToString(userIdsSelected);
    //const usersActive = users.filter(user => {return user.active === true});
    let usersActive = [...activeUsers]
    usersActive.push({id:USER_ID_NOT_ASSIGNED,firstName: "Not", lastName:"Assigned"});
    setTaskFilters({type : "normal" ,priority: "all" ,userIds :[], taskFilters: [FILTER_TYPE_TASK[0].value]});

    const {responseCode, data} = await loadBoards()
    if (responseCode === SUCCESSFUL_CODE) {
      const statusesByType = data.statusesByType.map(st => {return {...st, statuses: JSON.parse(st.statuses) }})

     const statusByType = find(statusesByType, st => { return st.type === typeSelected })
      this.setState({statuses:statusByType?.statuses ?? [],typeSelected:typeSelected,userIdsSelected:userIdsSelected,
        prioritySelected:prioritySelected,usersActive:usersActive, statusesByType},() => {
        this.loadTasks(priority,usersJoin,typeSelected);
      });
    } else {
      this.setState({isLoading:false});
    }

    window.addEventListener('resize', this.handleResize);
    this.updateHover();
    const container = window.document.getElementById("container");
    if(container)
      container.style.overflowY = 'overlay';
  }


  static getDerivedStateFromProps(props, state) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props.search !== state.search || props.showSideBar !== state.showSideBar) {
      return {
        search: props.search,
        showSideBar: props.showSideBar
      };
    }
    // No state update necessary
    return null;
  }

  handleReceiveProps = (props) => {
    const {search,showSideBar} = this.state;
    const state = {};
    if(props.search !== search){
      state.showPopoverFilterType = false;
      state.showAllUser = false;
      const result = this.findByKeyWord(search, true);
      state.sections = result.sections
      state.keyword = result.keyword
    }

    if(props.showSideBar !== showSideBar){
      state.showPopoverFilterType = false;
      state.showAllUser = false;
    }

    this.setState(state)
  }


  /*
  UNSAFE_componentWillReceiveProps(nextProps){
    this.handleReceiveProps(nextProps)
  }

   */

  findByKeyWord = (keyword, getData = false) =>{
    let {tasks,sections, typeSelected} = this.state;
    let tasksEdit = tasks;
    if(tasks && sections){
      if(keyword && keyword !== ""){
        tasksEdit = tasks.filter(task => {
          return (typeSelected === 'rapair'
                  ? (task.title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
                      || (task.vin && task.vin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 )
                      || (task.userFirstName && task.userFirstName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ))
                  : (task.title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
                      || (task.userFirstName && task.userFirstName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ))
          )
        });
      }

      let taskFiltered;
      sections.forEach((section) => {
        taskFiltered  = tasksEdit.filter(task => { return task.status === section.id});
        section.items = taskFiltered;
      });
      if(!getData)
        this.setState({sections:sections,keyword:keyword});
      else
       return {sections,keyword}
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
    this.props.setMenuId("");
    this.props.setKeyword("");
  }

  componentDidUpdate(prevProps, prevState){
    this.updateHover();
    const {typeSelected} = this.state
    setPlaceHolder("menu-search", (typeSelected === 'repair') ? TASK_REPAIR_PLACE_HOLDER : TASK_NORMAL_PLACE_HOLDER);
    let container = document.getElementById('container');

    if(container){
      const {widthPage,typeSelected,sections} = this.state;
      /*
      let left = document.getElementById('back_to_left_invert');
      let right = document.getElementById('back_to_right');
      if(widthPage < 1420){
        setStyleToArrowRight(right);
      }else{
        if(typeSelected === TASK_TYPE.REPAIR){
          setStyleToArrowRight(right);
        }else if(right){
          left.style.display = "none";
          right.style.display = "none";
        }
      }

       */

      setHeaderBox('taskBoxHeader',container.scrollTop);

      container.onscroll = function() {
        /*
        if(container.scrollLeft > 20){
          left.style.display = "block";
        }else{
          left.style.display = "none";
        }
        if(widthPage < 1420){
          setStyleToArrowRight(right);
        }else{
          if(typeSelected === TASK_TYPE.REPAIR){
            setStyleToArrowRight(right);
          }else if(right)
            right.style.display = "none";
        }

         */

        if(container.scrollLeft > 0){
          setCssToCardHeader(container.scrollLeft,widthPage);
        }else{
          setCssToCardHeader(0,widthPage);
        }
        setHeaderBox('taskBoxHeader',container.scrollTop);
        /*
        if(isMobileDevice()){
          if(left){
            left.style.display = "none";
            right.style.display = "none";
          }
        }
         */

        setArrowElement(widthPage,typeSelected);
      };

      setArrowElement(widthPage,typeSelected);

      /*
      if(isMobileDevice()){
        if(left){
          left.style.display = "none";
          right.style.display = "none";
        }
      }

       */

      const {showSideBar} = this.props;
      if(sections){
        const {minWidthContainer,minWidthTask} = getMinWidth(widthPage,typeSelected,sections.length);
        setMinWidthTaskSpecial(minWidthTask,sections);
      }
      setWidthToTaskSectionHeader(widthPage,showSideBar);
    }

    const {search, showSideBar, isLoading} = this.state
    if ((prevProps.search !== search || prevProps.showSideBar !== showSideBar) && isLoading === false) {
      this.handleReceiveProps(prevProps)
    }
  }

  handleResize = () => {
    this.setState({widthPage:window.innerWidth, showAllUser: false, showFilterPriorities: false, showPopoverFilterType:false});
  }

  updateHover(){
    const {prioritySelected,usersActive} = this.state;
    priorities.forEach(p => {
      let idPriority = document.getElementById(p.id);
      if(idPriority){
        if(p.id === prioritySelected){
          idPriority.style.color  = "#fff";
          idPriority.style.background  = p.color;
        }else{
          idPriority.style.color = p.color;
          idPriority.style.background  = "#fff";
        }
      }
    });

    usersActive.forEach(user => {
      let el = document.getElementById("content-"+user.id);
      let image = document.getElementById(user.id);
      let userTitle = document.getElementById("user-"+user.id);

      $(el).hover(function () {
        userTitle.style.display = "inline-table";
        let width = userTitle.offsetWidth;
        let widthImage = image.offsetWidth;
        userTitle.style.left    = -(width/2 - widthImage/2) + "px";
        image.style.opacity = "0.8";
      },function () {
        userTitle.style.display = "none";
        image.style.opacity = "1";
      })
    });

  }

  setTaskFilter(){
    const {typeSelected,prioritySelected,userIdsSelected,taskFilters,statusesByType} = this.state;
    const {setTaskFilters} = this.props;

    const statuses = [...statusesByType]
    statuses.forEach(st => {
      st.statuses = st.statuses.map(s => { return {id: s.id, displayValue: s.displayValue} })
    })
    const query = {type :typeSelected ,priority: prioritySelected,userIds:userIdsSelected, taskFilters: taskFilters, statusesByType: statuses};
    setTaskFilters(query);
  }

  addTask = () => {
    this.setTaskFilter();
    browserHistory.push(`/main/task/new`);
  }

  handleDropElement = (target, item) => {
    let {sections,typeSelected} = this.state;
    let idOrigin = item.id;
    let status   = item.status;
    let indexSecInsert  = null;
    let indexSecDelete  = null;
    let indexFound      = null;
    sections.forEach((section,index) => {
      if(section.id === target)
        indexSecInsert = index;
      else if(section.id === status){
        indexSecDelete = index;
        indexFound = section.tasks.findIndex(task => {return task.id === idOrigin});
      }
    });

    item.status = target;
    sections[indexSecInsert].tasks.push(item);
    sections[indexSecDelete].tasks.splice(indexFound,1);

    this.setState({sections});
    updateTaskStatus(idOrigin,target,typeSelected).then(({responseCode,responseMessage}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
      }
    })
  }

  handleShowUser = (id,event) => {
    event.preventDefault();
    let {showAllUser,widthPage} = this.state;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {};
    if(widthPage < 789) {
      anchorEl = {x: pointTo.x - 75, y: pointTo.y - 25, offsetWidth: 190};
    } else {
      anchorEl = {x: pointTo.x, y: pointTo.y - 25, offsetWidth: 190};
    }

    this.setState({showAllUser: !showAllUser, anchorEl: anchorEl});
  }

  handleRequestOpen(evt) {
    let target = evt.currentTarget;
    let anchorEl = getAnchorEl(target,2);
    this.setState({anchorEl: anchorEl, showOptions: true, showFilterPriorities: false});
  }

  handleRequestClose = (evt) => {
    this.setState({showOptions: false, showAllUser: false, showPopoverFilterType:false})
  }

  handleSelectChange = (value) => {
    this.setState({typeSelected:value, showOptions: false});
    this.handleType(value);
  };

  handlePriority = (id) =>{
    const {userIdsSelected,typeSelected} = this.state;
    let users    = convertListToString(userIdsSelected);
    let priority = getPriority(id);
    this.setState({prioritySelected:id, showFilterPriorities:false});
    this.loadTasks(priority,users,typeSelected);
  }

  handleType = (type) =>{
    const {userIdsSelected,prioritySelected,statusesByType} = this.state;
    let users    = convertListToString(userIdsSelected);
    let priority = getPriority(prioritySelected);
    this.setState({typeSelected : type,isLoading:true});
    const statusByType = statusesByType.find(st => { return st.type === type })
    this.setState({statuses:statusByType.statuses},() => {
      this.loadTasks(priority,users,type);
    })
  }

  handleUserSelect = (evt) =>{
    const id = evt.target.id;
    let {userIdsSelected,prioritySelected,typeSelected} = this.state;
    const indexFound = findIndex(userIdsSelected,(value) => {return value === id});
    if(indexFound !== -1){
      userIdsSelected.splice(indexFound,1);
    }else{
      userIdsSelected.push(id);
    }
    let users    = convertListToString(userIdsSelected);
    let priority = getPriority(prioritySelected);

    this.setState({userIdsSelected:userIdsSelected},() => {
      this.loadTasks(priority,users,typeSelected);
    })
  }

  editTask = (id,evt) => {
    evt.preventDefault();
    this.setTaskFilter();
    browserHistory.push(`/main/task/${id}`);
  }

  handleShowFilterPriorities = () =>{
    let {showFilterPriorities} = this.state;
    this.setState({showFilterPriorities : !showFilterPriorities})
  }

  deleteTask = (idTask,evt) => {
    evt.stopPropagation();
    this.setState({showModal: true, idTask:idTask});
  }

 deleteTaskSubmit() {
    let {idTask} = this.state;
    deleteTaskApi(idTask).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.handleHideModal();
      }
    })
  }

  changeActiveTask = (idTask,evt) => {
    evt.stopPropagation();
    this.setState({showModalActiveTask: true, idTask:idTask});
  }

 changeInactiveTaskSubmit = (idTask, evt) => {
    evt.stopPropagation();
    updateTaskActiveApi(idTask).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.handleHideModal();
      }
    })
  }

 changeActiveOrInactiveTask = () => {
    let {idTask} = this.state;
    updateTaskActiveApi(idTask).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.handleHideModal();
      }
    })
  }

  handleHideModal = () => {
    let {userIdsSelected, prioritySelected, typeSelected} = this.state;
    let users    = convertListToString(userIdsSelected);
    let priority = getPriority(prioritySelected);
    this.setState({showModal: false, showModalActiveTask:false, idTask: null});
    this.loadTasks(priority,users,typeSelected);
  }

  handleSlide = (idBtn, withExpanded, evt) =>{
    if (evt) evt.stopPropagation()
    const id = idBtn
    let content = document.getElementById('container');
    let bodyTask = document.getElementById('bodyTask');
    let containerTasks = document.getElementById('containerTasks');
    let section = document.getElementById('section0');
    const sectionsQuantityToTranslate = Math.round(containerTasks.offsetWidth / (section.offsetWidth - 20)) - 1;
    let translate = sectionsQuantityToTranslate*section.offsetWidth;

    let max;
    let dif;
    const scrollInitial = content.scrollLeft;
    const {widthPage,typeSelected} = this.state;
    let widthSideBar = withExpanded ? withExpanded : SIDEBAR_WIDTH_UNEXPANDED;
    //if(widthPage <= WIDTH_VIEW_MOBILE)
    //  widthSideBar = 0;
    const maxScrollLeft = content.scrollWidth - content.clientWidth;
    if(id === 'back_to_right'){
      //--dif = bodyTask.offsetWidth - (scrollInitial + widthSideBar + 20);
      if(scrollInitial + translate < maxScrollLeft){
        max = scrollInitial + translate;
      }else{
        max = maxScrollLeft;
      }
    } else {
      if (scrollInitial > translate) {
        max = scrollInitial - translate
      } else {
        max = 0
      }
    }
    if(id === 'back_to_right'){
      // this.setState({displayBtnLeft: false});
      this.scrollToRight(content,Math.round(max),10);
    } else {
      //this.setState({displayBtnLeft: true});
      this.scrollToLeft(content,Math.round(max),10);
    }

    //--setArrowElement(widthPage,typeSelected);
  }

  scrollToRight(content,max,step){
    if(Math.round(content.scrollLeft) < max){
      content.scrollLeft += step;
      setTimeout(() => {
        this.scrollToRight(content,max,step);
      }, step - 5);
    }
  }

  scrollToLeft(content,max,step){
    if(Math.round(content.scrollLeft) > max){
      content.scrollLeft -= step;
      setTimeout(() => {
        this.scrollToLeft(content,max,step);
      }, step - 5);
    }
  }

  reorder = (sections, items) => {
    //const {userIdsSelected,typeSelected,prioritySelected} = this.state;
    this.setState({sections:sections});
    updateReorderTask({items:items}).then(response => {
      const {responseCode, responseMessage} = response
      //const users    = convertListToString(userIdsSelected);
      //const priority = getPriority(prioritySelected);
      //this.loadTasks(priority,users,typeSelected);
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
      }
    });
  };

  move = (sections, items) => {
    //const {userIdsSelected,typeSelected,prioritySelected} = this.state;
    this.setState({sections:sections});
    let taskSelected = items.find(d => d.status);
    if(taskSelected) {
      updateReorderTask({items: items, taskIdSelected: taskSelected.id, status: taskSelected.status}).then(response => {
        const {responseCode, responseMessage} = response
        //const users    = convertListToString(userIdsSelected);
        //const priority = getPriority(prioritySelected);
        //this.loadTasks(priority,users,typeSelected);
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
        }
      });
    }
  };

  handlePopover = (id, showPopoverFilterType, event) => {
    event.stopPropagation();
    const { typeSelected, displayBtnLeft } = this.state;
    const isRepair = typeSelected === TASK_TYPE.REPAIR && !displayBtnLeft
    let target = document.getElementById(id)
    let pointTo = getPosition(target)
    let section = document.getElementById('section0')
    let translate = section.offsetWidth*maxRowsPerPage
    let anchorEl = {
      x: isRepair ?  pointTo.x + translate - 2 : pointTo.x - 2,
      y: pointTo.y - 25,
      offsetWidth: 180
    }
    this.setState({ showPopoverFilterType: !showPopoverFilterType, anchorEl: anchorEl})
  }

  handleCheck = (evt) => {
    evt.stopPropagation();
    const id = evt.target.id;
    const {taskFilter, setTaskFilters} = this.props;
    let {taskFilters}  = clone(taskFilter);
    const index = taskFilters.indexOf(id);
    if( index === -1)
      taskFilters.push(id);
    else
      taskFilters.splice(index,1);

    setTaskFilters({...taskFilter, taskFilters: taskFilters});
    this.handleHideModal();
  }

  renderOptions() {
    const {taskFilter} = this.props
    let {taskFilters} = taskFilter
    let values = taskFilters ? taskFilters : [];
    let options = [];
    FILTER_TYPE_TASK.forEach(({label, value}, index) => {
      const checked = findIndex(values, (val) => {
        return val === value
      });
      options.push(
        <div key={value} className="content-check">
          <DPCheckbox
            id={value}
            checked={checked !== -1}
            iconStyle={{borderColor: "#777", marginRight: 8}}
            onCheck={(e) => this.handleCheck(e)}
            required={false}
          />
          <div className="container-label">
            <p className="check-description">{label}</p>
          </div>
        </div>
      )
    });

    return (
      <div className="popover-body-locations">
        <div id="" className="options-locations">
          {options}
        </div>
      </div>
    )
  }

  displayName(){
    const {taskFilter} = this.props
    let {taskFilters} = taskFilter
    let displayName = 'Not Selected'
    if(taskFilters && taskFilters.length === 1)
      displayName = FILTER_TYPE_TASK.find(x => x.value === taskFilters[0]).label
    else if(taskFilters && taskFilters.length > 1) {
      displayName = `${taskFilters.length} Selected`
    }
    return displayName
  }

  toggleSectionDroppable = (sectionId) =>{
    const {showSections, sectionIds} = this.state
    const selectedSectionId = sectionIds.indexOf(sectionId)
    showSections[selectedSectionId] = !showSections[selectedSectionId]

    this.setState({showSections})
  }

  render() {
    const {showAllUser, showOptions, anchorEl, widthPage,userIdsSelected,sections,typeSelected,showFilterPriorities, showModal,
      isLoading, usersActive, showPopoverFilterType, showModalActiveTask, sectionIds, showSections} = this.state;

    const {activeUsers,showSideBar,height} = this.props;

    if (!sections || !activeUsers)
      return null;

    let leftUserIcon = -10;
    let zIndex = activeUsers.length;
    let zIndexUpdate = activeUsers.length;
    let marginNext = 0;

    let widthIconUser = 200;

    if (widthPage <= 360)
      widthIconUser = 180;

    let cantUser = 0;
    if(usersActive.length > 5 && widthPage > WIDTH_VIEW_MOBILE){
      cantUser = usersActive.length - 5;
      marginNext = 120;
    } else if(usersActive.length > 4 && widthPage <= WIDTH_VIEW_MOBILE){
      cantUser = usersActive.length - 4;
      marginNext = 95;
    }
    const textUser = "+" + cantUser;
    const {minWidthContainer,minWidthTask} = getMinWidth(widthPage,typeSelected,sections.length);
    const props = {
      sections: sections,
      reorder: this.reorder,
      move: this.move,
      minWidthTask: minWidthTask,
      typeSelected: typeSelected,
      editTask: this.editTask,
      deleteTask: this.deleteTask,
      minWidthContainer: minWidthContainer,
      changeActiveTask: this.changeActiveTask,
      changeInactiveTaskSubmit: this.changeInactiveTaskSubmit,
      showSections,
      sectionIds,
      toggleSectionDroppable: this.toggleSectionDroppable
    };

    const LIST_TASK = widthPage < WIDTH_VIEW_MOBILE ?
      <ListTaskVertical {...props} /> :
      <ListTaskHorizontal {...props}/>;

    const taskFiltersSelected = this.displayName()

    return (
      <div id="containerTasks" className={'container-tasks'} style={{height:height - 30}}>
        <div className="container-header-task" style={{paddingLeft:getLeft(widthPage,showSideBar)}}>
          <div className="task-button">
            <div className="container-task-button-add">

              {/*<ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"} beforeInjection={ svg => svg.classList.add('icon-add-svg')}*/}
              {/*          className="icon-add-task" onClick={this.addTask}/>*/}

              <DPButton
                type={BUTTON_TYPE.PRIMARY}
                size={BUTTON_SIZE.SMALL}
                color={BUTTON_COLOR.ACCEPT}
                startIcon={'ic_add.svg'}
                textColor={'white'}
                onClick={this.addTask}
                iconClassName={'addIconButton'}
                />


            </div>
              {widthPage > WIDTH_VIEW_MOBILE ? <div className="task-name">Tasks</div> : null}

              <div className="container-icon-user" style={{width:widthIconUser}}>
              {
                usersActive.length > 0 ?
                  usersActive.map((user,index) => {
                    leftUserIcon += 25;
                    zIndexUpdate = zIndex - index;
                    let countUser = 0;
                    if(widthPage > WIDTH_VIEW_MOBILE){
                      countUser = 4
                    } else {
                      countUser = 3
                    }
                    const userSelected = userIdsSelected.find(value => {return value === user.id});
                    if(userSelected)
                      zIndexUpdate += 2;

                    if (index <= countUser){
                      return(
                        <div id={"content-"+user.id} key={index} className={userSelected ? "icon-user-task icon-user-task-selected":"icon-user-task"}
                             style={{left:userSelected ? (leftUserIcon - 4) : leftUserIcon,zIndex:zIndexUpdate}}>
                          <img id={user.id} src={user.image ? user.image : pathServer.PATH_IMG+"ic_account_circle.png"} onClick={this.handleUserSelect} data-test={`user-${index}`}/>
                          <div id={`user-${user.id}`} className="task-user-title">
                            <span>{`${user.firstName} ${user.lastName}`}</span>
                          </div>
                        </div>
                      )
                    }
                  })
                  : null
              }
              {
                widthPage > WIDTH_VIEW_MOBILE && usersActive.length > 5 ?
                  <TextUser displayValue={textUser} showAllUser={showAllUser} marginLeft={marginNext} handleShowUser={this.handleShowUser} /> :
                  widthPage <= WIDTH_VIEW_MOBILE && usersActive.length > 4 ?
                    <TextUser displayValue={textUser} showAllUser={showAllUser} marginLeft={marginNext} handleShowUser={this.handleShowUser} /> : null
              }
            </div>
          </div>
          <div className="task-filter-type">
            {
              widthPage > WIDTH_VIEW_MOBILE &&
                <div className="container-buttons-filter" style={{display:"flex"}}>
                  <DPButton
                    type={typeSelected === TASK_TYPE.NORMAL ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.SMALL}
                    color={typeSelected === TASK_TYPE.NORMAL ? BUTTON_COLOR.NORMAL : BUTTON_COLOR.CLASSIC}
                    label={"normal"}
                    textCap={BUTTON_TEXT_CAP.CAPITALIZE}
                    id={"normal-button"}
                    admin={true}
                    onClick={()=> this.handleType(TASK_TYPE.NORMAL)}
                    buttonClassName={'button-filter'}
                    />
                  <DPButton
                    type={typeSelected === TASK_TYPE.REPAIR ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.SMALL}
                    color={typeSelected === TASK_TYPE.REPAIR ? BUTTON_COLOR.NORMAL: BUTTON_COLOR.CLASSIC}
                    label={"repair"}
                    textCap={BUTTON_TEXT_CAP.CAPITALIZE}
                    admin={true}
                    id={"repair-button"}
                    onClick={()=> this.handleType(TASK_TYPE.REPAIR)}
                    buttonClassName={'button-filter'}
                  />
                </div>
            }
            {
              widthPage > WIDTH_VIEW_MOBILE &&
                <div className='container-select-filter'>
                  <div className='label-select'>
                    <span>Show</span>
                  </div>
                  <div id="filterTypeTask" className="select-filter" onClick={(e) => this.handlePopover("filterTypeTask",showPopoverFilterType,e)} >
                    <div>
                      {taskFiltersSelected}
                      {
                        showPopoverFilterType ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                          : <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                      className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                      }
                    </div>
                  </div>
                  {
                    showPopoverFilterType ?
                      <DPPopover anchorEl={anchorEl}
                        //child={childTaskFilter}
                                 classNameWrapper="popover-box-category"
                                 handleRequestClose={this.handleRequestClose}>
                        {this.renderOptions()}
                      </DPPopover>
                      : null
                  }
                </div>
            }
            {
              widthPage <= WIDTH_VIEW_MOBILE ?
                <div className="task-container-drop-down" >
                  <div className="btn-drop-down-task" onClick={(evt) => {this.handleRequestOpen(evt)}}>
                    <div className="btn-drop-down">
                      <label className="label-value">{typeSelected.charAt(0).toUpperCase()+typeSelected.slice(1)}</label>
                      {
                        showOptions ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                    className="icon-arrow-content"
                                    beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                    className="icon-arrow-content"
                                    beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                      }
                    </div>
                  </div>
                </div> : null
            }
            {
              widthPage <= WIDTH_VIEW_TABLET ?
                <div className="container-icon" onClick={this.handleShowFilterPriorities}>
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_sort.svg"}
                            className="icon-sort-content"
                            beforeInjection={ svg => { svg.classList.add('icon-sort'); svg.setAttribute('style', `fill: ${showFilterPriorities ? "#2194F1" : "#787878"}`); }}/>
                </div> : null
            }
          </div>
          {widthPage > WIDTH_VIEW_TABLET && (<PriorityFilter handlePriority={this.handlePriority}/>)}
          {
            showOptions ?
                <DPPopover anchorEl={anchorEl}
                         //child={<Type typeSelected={typeSelected} handleSelectChange={this.handleSelectChange} />}
                           classNameWrapper="popover-box-message"
                           handleRequestClose={this.handleRequestClose}>
                  <Type typeSelected={typeSelected} handleSelectChange={this.handleSelectChange} />
                </DPPopover>
                : null
          }
          {
            showAllUser ?
                <DPPopover anchorEl={anchorEl}
                           //child={<User users={usersActive} userIdsSelected={userIdsSelected} widthPage={userIdsSelected} handleUserSelect={this.handleUserSelect} />}
                           classNameWrapper="popover-box-message"
                           handleRequestClose={this.handleRequestClose}>
                  <User users={usersActive} userIdsSelected={userIdsSelected} widthPage={userIdsSelected} handleUserSelect={this.handleUserSelect}/>
                </DPPopover>
                : null
          }
        </div>
        {showFilterPriorities && (
          <div className="container-filter-priority">
            <PriorityFilter handlePriority={this.handlePriority} />
          </div>)}
        {isLoading ? <DPLoader show={true}/> : LIST_TASK}
        {
          showModal &&
          <ModalConfirmDelete show={showModal}
                              onHide={this.handleHideModal}
                              styleModal="modal-delete"
                              styleRight="panel-right-delete-user"
                              body={<div className="container-message-delete-task-in">
                                <span className="title">Do you want to delete this task permanently?</span>
                                <span className="sub-title">This action is irreversible</span>
                              </div>}
                              showButtonDelete={true}
                              onSubmit={() => this.deleteTaskSubmit()}
                              buttonLabelDelete="Yes, Delete"
                              classButtonDelete="btn-delete-task"
                              viewMobile={widthPage <= WIDTH_VIEW_MOBILE}
                              isLoading={isLoading}
                              isSale={false}
                             />
        }
        {
          showModalActiveTask &&
            <ModalConfirmDeactivate
              show={showModalActiveTask}
              onSubmit={this.changeActiveOrInactiveTask}
              onHide={this.handleHideModal}
              showButton={true}
              isBodyCustom={true}
              bodyMessage={<div className="container-message-activate-task-in">
                <span className="title">Do you want to activate this task?</span>
                <span className="sub-title">Once activated, it'll appear on principal task page</span>
              </div>}
              classButtonDelete="btn-delete"
              buttonLabelDelete={"Yes, activate"}
              viewMobile={false}
              iconQuestion={"icon/ic_question_warning_activate.svg" }
              showAnotherMessage = {true}/>
        }
        <DPArrowCircle id={'back_to_left_invert'} onClick={(evt) => this.handleSlide('back_to_left_invert',SIDEBAR_WIDTH_UNEXPANDED,evt)} icon={'ic_arrow_back.svg'}/>
        <DPArrowCircle id={'back_to_right'} onClick={(evt) => this.handleSlide('back_to_right',SIDEBAR_WIDTH_UNEXPANDED,evt)} icon={'ic_arrow_next.svg'}/>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    taskFilter: state.taskFilter,
    activeUsers: state.activeUsers,
  }
};

export default connect(mapStateToProps,{setMenuId,setKeyword,setTaskFilters})(TasksList);