import React from 'react'
import {fonts} from '../../../Constants'
import {Editor, EditorState, RichUtils, Modifier, ContentState} from 'draft-js'
import Outside from '../../util/Outside.jsx'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../../common/Constants'

const MAX_LENGTH = 10040;

export default class MyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: (this.props.textParagraph && this.props.textParagraph.length > 0)?
        EditorState.createWithContent(ContentState.createFromText(this.props.textParagraph)):EditorState.createEmpty(),
      fontFamily: "arial",
      fontSize: [
        {
          size: "x-small",
          name: "Small",
        },
        {
          size: "small",
          name: "Normal",
        },
        {
          size: "Large",
          name: "Large",
        },
        {
          size: "xx-Large",
          name: "Huge",
        },
      ],
      showMenuFontSize: "false",
      valueFontSize: "12px",
      boldPress: false,
      underlinePress: false,
      stkPress: false,
      italicPress: false,
      leftPress: true,
      centerPress: false,
      rightPress: false
    };
    this._getLengthOfSelectedText = this._getLengthOfSelectedText.bind(this);
    this._handleBeforeInput = this._handleBeforeInput.bind(this);
    this._handlePastedText = this._handlePastedText.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pressSelect = this.pressSelect.bind(this);
    this.pressAlignment = this.pressAlignment.bind(this);
    this.toggleFontSize = this.toggleFontSize.bind(this);
    this.closeListFontSize = this.closeListFontSize.bind(this);
    this.valueInlineStyle = this.valueInlineStyle.bind(this);
  }

  componentWillUnmount(){
    let {editorState} = this.state;
    this.props.caughtText(editorState);
  }

  onChange(editorState) {
    this.setState({editorState:editorState});
    this.props.caughtText(editorState)
  }

  _getLengthOfSelectedText() {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  }

  _handleBeforeInput() {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return 'handled';
    }
  }

  _handlePastedText(pastedText) {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      return 'handled';
    }
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  handleChange(event) {
    this.setState({fontFamily: event.target.value});
    document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.fontFamily = event.target.value;
    event.preventDefault();
  }

  onChangeSize(op) {
    this.setState({valueFontSize: op.size, showMenuFontSize: true})
    document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.fontSize = op.size;
  }

  toggleFontSize() {
    let {showMenuFontSize} = this.state;
    this.setState({showMenuFontSize: !showMenuFontSize});
  }

  closeListFontSize() {
    this.setState({showMenuFontSize: true});
  }

  valueInlineStyle(name, bool) {
    if (name === "stk") {
      this.setState({stkPress: bool});
      return 'STRIKETHROUGH';
    } else {
      if (name === "bold") {
        this.setState({boldPress: bool});
        return 'BOLD';
      } else {
        if (name === "underline") {
          this.setState({underlinePress: bool});
          return 'UNDERLINE';
        } else {
          this.setState({italicPress: bool});
          return 'ITALIC';
        }
      }
    }
  }

  pressSelect(type) {
    let {editorState} = this.state;
    let inlineStyle = this.valueInlineStyle(type, false);
    let selection = editorState.getSelection();
    let currentStyle = editorState.getCurrentInlineStyle();
    let content = editorState.getCurrentContent();
    let newContent;
    if (selection.isCollapsed()) {
      this.onChange(EditorState.setInlineStyleOverride(editorState,
        currentStyle.has(inlineStyle) ? currentStyle.remove(inlineStyle) : currentStyle.add(inlineStyle)));
    } else {
      inlineStyle = this.valueInlineStyle(type, !currentStyle.has(inlineStyle));
      (currentStyle.has(inlineStyle)) ? (newContent = Modifier.removeInlineStyle(content, selection, inlineStyle)) :
        (newContent = Modifier.applyInlineStyle(content, selection, inlineStyle));
      this.onChange(EditorState.push(editorState, newContent, 'change-inline-style'));
    }
  }

  pressAlignment(btn) {
    if (btn === "right") {
      document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.textAlign = "right";
      this.setState({rightPress: true, centerPress: false, leftPress: false});
    } else {
      if (btn === "center") {
        document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.textAlign = "center";
        this.setState({centerPress: true, rightPress: false, leftPress: false});
      } else if (btn === "left") {
        document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.textAlign = "left";
        this.setState({leftPress: true, rightPress: false, centerPress: false});
      }
    }
  }

  render() {
    let {fontSize, showMenuFontSize, valueFontSize, boldPress, stkPress, underlinePress, italicPress, rightPress, centerPress, leftPress} = this.state;
    return <div className="footer-reply">
      <div className="contentDiv">
        <div className="contentFontFamily">
          <select className="selFontFamily"
                  onChange={(e) => this.handleChange(e)}>
            {
              fonts.map(font => <option key={font.key} value={font.key}>{font.value}</option>)
            }
          </select>
        </div>

        <Outside onClose={this.closeListFontSize} style={{paddingLeft: "6px", paddingRight: "6px"}}>
          <div className="contentOfFontSize">
            <div className="contentFontSize">
              <div onClick={() => this.toggleFontSize()}>
                <img src={pathServer.PATH_IMG + "AttributeIcons/ic_text_fields_24px.png"} className="btnSizeImage"/>
                <img src={pathServer.PATH_IMG + "ic_expand_more.png"} className="btn-expand-more"/>
              </div>
              {
                !showMenuFontSize ?
                  <div className="list-fontsize list-sort">
                    <div className="list-name-fontsize">
                      <u name="ul">
                        {
                          fontSize.map((op, index) => {
                            return (
                              <li key={index} name={op.size} style={{
                                fontSize: op.size,
                                paddingLeft: "10px", display: "flex",
                                height: (op.name === "Huge") ? "45px" : "25px",
                              }}
                                  className="change-fontsize"
                                  onClick={() => this.onChangeSize(op)}>
                                {
                                  valueFontSize === op.size ?
                                    <div className="checkFontSize"
                                         style={{
                                           verticalAlign: "middle", userSelect: "none",
                                           color: "white", width: "20px", height: "auto",
                                           marginTop: (op.name === "Huge") ? "7px" : "2px",
                                           marginRight: "10px"
                                         }}>
                                    </div>
                                    :
                                    <div style={{
                                      verticalAlign: "middle", userSelect: "none",
                                      color: "white", width: "20px", height: "auto",
                                      marginTop: (op.name === "Huge") ? "7px" : "2px",
                                      marginRight: "10px"
                                    }}>
                                    </div>
                                }
                                <span style={{marginTop: "0px", marginLeft: "0px"}}>
                                  {op.name}
                                  </span>
                              </li>
                            )
                          })
                        }
                      </u>
                    </div>
                  </div>
                  :
                  null
              }
            </div>
          </div>
        </Outside>
        <div><img className="imgSeparatorImage" alt="" src={pathServer.PATH_IMG + "AttributeIcons/separator.png"}/></div>
        <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_stk.svg"}
                  className="btn-format-stk" style={{fill: (stkPress)?"#0096f8":"#939393"}}
                  onClick={() => this.pressSelect("stk")}/>
        <div><img className="imgSeparatorImage" alt=""
                  src={pathServer.PATH_IMG + "AttributeIcons/separator.png"}/></div>
        <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_bold.svg"}
                  className="btn-format-bold" style={{fill: (boldPress)?"#0096f8":"#939393"}}
                  onClick={() => this.pressSelect("bold")}/>
        <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_italic.svg"}
                  className="btn-format-italic" style={{fill: (italicPress)?"#0096f8":"#939393"}}
                  onClick={() => this.pressSelect("italic")}/>
        <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_underlined.svg"}
                  className="btn-underline" style={{fill: (underlinePress)?"#0096f8":"#939393"}}
                  onClick={() => this.pressSelect("underline")}/>
        <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_align_left.svg"}
                  className="btn-align" style={{fill: (leftPress)?"#0096f8":"#939393"}}
                  onClick={() => this.pressAlignment("left")}/>
        <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_align_center.svg"}
                  className="btn-align" style={{fill: (centerPress)?"#0096f8":"#939393"}}
                  onClick={() => this.pressAlignment("center")}/>
        <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_align_right.svg"}
                  className="btn-align" style={{fill: (rightPress)?"#0096f8":"#939393"}}
                  onClick={() => this.pressAlignment("right")}/>
      </div>
      <div>
        <Editor
          editorState={this.state.editorState}
          handleKeyCommand={this.handleKeyCommand}
          handleBeforeInput={this._handleBeforeInput}
          handlePastedText={this._handlePastedText}
          onChange={(editorState)=>this.onChange(editorState)}
        />
      </div>
    </div>;
  }
}