import React from 'react'
import PropTypes from 'prop-types'

export const DPTextarea = (props) => {
  return (
    <div className={`container-others-terms ${props.containerClassName}`} style={props.customStyleContainer ?? {}}>
      <div className="info-label">
        <label className="title" style={props.customStyleLabel ?? {}}>{props.label ?? ''}</label>
        { props.rightElement ?? null }
      </div>
      <div className="class-input">
        <textarea
          name={props.name || props.id}
          id={props.id}
          cols={props.cols}
          rows={props.rows}
          className="input-purchase form-control"
          onChange={props.onChange}
          value={props.value}
          maxLength={props.maxLength}
          style={props.styleTextarea}
          readOnly={props.readOnly}
          title={props.title}
        />
      </div>
    </div>
  )
}

DPTextarea.defaultProps = {
  readOnly: false,
  title: ''
}

DPTextarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  cols: PropTypes.number,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
  containerClassName: PropTypes.string,
  maxLength: PropTypes.number,
  rightElement: PropTypes.object,
  styleTextarea: PropTypes.object,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  customStyleContainer: PropTypes.object,
  customStyleLabel: PropTypes.object
}
