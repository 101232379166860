/**
 * Created by javierl on 3/10/16
 */

import React from 'react'
import {browserHistory, Router} from 'react-router'
import Routes from './Routes.jsx'
import configureStore from './store/configureStore'
import {Provider} from 'react-redux'
import {syncHistoryWithStore} from 'react-router-redux'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import EventBus from './EventBus'

require('../common/styles/marketing.scss');
require('../common/styles/app.scss')

const store = configureStore(window.initialState,browserHistory);
const history = syncHistoryWithStore(browserHistory, store);
window.EventBus = EventBus
export default function App(){
  const body = document.getElementsByTagName("body")[0];
  body.style.fontFamily = "'Open Sans', sans-serif";
  return(
    <Provider store={store} >
      <MuiThemeProvider>
        <Router history={history}>
          {Routes}
        </Router>
      </MuiThemeProvider>
    </Provider>
  )
}