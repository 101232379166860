import React from 'react'
import {EmptyFunc} from '../../../common/Constants'
import PropTypes from 'prop-types'
import DPTabs from './DPTabs'
import DPCheckbox from "../DPCheckbox";
import {TAB_FINANCE, TAB_MENU_ABOUT_US, TAB_MENU_CONTACT, TAB_MENU_LOCATION} from "../../Constants";

require('./DPTabNavigationItem.scss')

const DPTabNavigationItem = ({tabs, type, tabSelected, selectOptions, value, onClick, onChange, showIcon,setTypeLink, id, index}) => {
  return(
    <div className="row-menu-link">
      <DPTabs tabs={tabs}
              classNameContainer=""
              classNameTabActive="link-active"
              otherFunction={setTypeLink}
              id={id}
              index={index}
              tabActive={tabSelected}
              checkboxIncluded={true}
              containerStyle={{height: '25px', display: 'flex'}}
      />

      {/*<div className="body-link">*/}
      {/*  {*/}
      {/*    showIcon &&*/}
      {/*    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="content-delete-icon"*/}
      {/*              beforeInjection={ svg => svg.classList.add('delete-icon')}/>*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  )
}

DPTabNavigationItem.defaultProps = {
  type: 'select',
  tabSelected: null,
  selectOptions: [],
  tabs: [],
  value: '',
  showIcon: true,
  onClick: EmptyFunc,
  onChange: EmptyFunc,
  setLinkTab: EmptyFunc,
}

DPTabNavigationItem.propTypes = {
  type: PropTypes.oneOf(['select', 'input']),
  tabSelected: PropTypes.string.isRequired,
  selectOptions: PropTypes.array,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ),
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setLinkTab: PropTypes.func,
  showIcon: PropTypes.bool
}

export default DPTabNavigationItem