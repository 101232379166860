import { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import DPSelectionDropDown from '../../common/DPSelectionDropDown';
import {
  colors,
  EXT_IMG,
  EXT_OTHER_IMG,
  EXT_PNG,
  pathServer, SUCCESSFUL_CODE,
  WIDTH_VIEW_MOBILE,
} from '../../../common/Constants'
import {SUBTASK_STATUS, STATUS_PROGRESS } from '../../Constants'
import {browserHistory} from 'react-router'
import { updateReorderTask } from '../../Api'
import {getRandomId} from "../../Utils";

const SubTask = (props) => {
  const isNewSubtask = props.locationState?.subTaskId === 'new';
  const isNewParentTask = props.taskId === 'new';

  const isNewTask = isNewParentTask || isNewSubtask;

  const normalizeData = (data) => {
    return data.map((item) => item.subTask ? item : { subTask: item });
  };
  const [subtasks, setSubtasks] = useState(normalizeData(props.data));
  const [progressPercentage, setProgressPercentage] = useState(0);

  const calculateProgress = (subtasks) => {
    if (!subtasks || subtasks.length === 0) {
      return 0;
    }

    const totalProgress = subtasks.reduce((accumulator, el) => {
      const reduxStatus = el.subTask?.task?.status;
      const dbStatus = el.subTask?.status;
      const statusSelected =  reduxStatus || dbStatus || 'todo';

      const status = SUBTASK_STATUS.find(
          (statusItem) => statusItem.id === statusSelected
        );
      const progressValue = status ? status.progressValue : 0;
      return accumulator + progressValue;
    }, 0);

    const averageProgress = totalProgress / subtasks.length;

    return averageProgress % 1 === 0 ? averageProgress :  averageProgress.toFixed(2);
  };

  useEffect(() => {
    const progress = calculateProgress(subtasks);
    setProgressPercentage(progress);
  }, [subtasks]);

  const goToSubTaskPage = (id = 'new') => {
    if (id === 'new') {
      const taskInfo = {
        task: {
          id: props.taskId,
          title: props.parentTask.title,
          status: props.parentTask.status,
          priority: props.parentTask.priority,
          description: props.parentTask.description,
          typeTask: props.parentTask.typeTask,
          carId: props.parentTask.carId,
          assignedUserId: props.parentTask.assignedUserId || '',
          dueDate: props.parentTask.dueDate
        },
        comments: props.parentTask.comments || [],
        repairCost: props.parentTask.repairCost || []
      };

      props.saveTaskInfo(taskInfo);
    } else {
      if (props?.data) {
        const existingSubTask = props.data.find(st =>
          (st.task?.id === id) || (st.subTask?.id === id)
        );
        const taskData = existingSubTask?.task || existingSubTask?.subTask;
        const comments = existingSubTask.comments || [];
        const costs = existingSubTask.repairCost?.listRepair || [];

        const formattedCosts = costs.map(cost => {
          const laborCost = cost.costs?.find(c => c.costType === 'labor')?.costValue;
          const partCost = cost.costs?.find(c => c.costType === 'part')?.costValue;

          const total = (parseFloat(laborCost || 0) + parseFloat(partCost || 0)).toFixed(2);

          return {
            id: cost.id,
            description: cost.description || 'Cost description',
            labor: formatCostValue(laborCost),
            part: formatCostValue(partCost),
            total: total,
            receipts: cost.receipts || [],
            temporalId: cost.id || getRandomId()
          };
        });


        if (taskData) {
          browserHistory.push({
            pathname: `/main/task/${props.taskId}/subtask/${id}`,
            state: {
              statusValue: existingSubTask.status,
              userAssigned: existingSubTask.assignedUserId,
              priorityValue: existingSubTask.priority,
              userComment: existingSubTask.comments,
              comments: comments,
              costs: formattedCosts,
              parentTask: props.parentTask,
              subTaskId: id,
              titleTaskParent: props.parentTask.title,
              originPath: `/main/task/${props.taskId}`
            }
          });
          return;
        }
      }
    }

    browserHistory.push({
      pathname: `/main/task/${props.taskId}/subtask/${id}`,
      state: {
        statusValue: props.statusValue,
        userAssigned: props.userAssigned,
        priorityValue: props.priorityValue,
        userComment: props.userComment,
        parentTask: props.parentTask,
        subTaskId: id,
        titleTaskParent: props.parentTask.title,
        originPath: `/main/task/${props.taskId}`
      }
    })
  };

  const editSubtask = (id) => {
    browserHistory.push({pathname: `/main/task/${props.taskId}/subtask/${id}`})
  };


  const handleStatusChange = (subtask,subtaskId, newStatusId) => {
    const selectedStatusOption = SUBTASK_STATUS.find(
      (option) => option.id === newStatusId
    );

    if(isNewTask) {
      const subtaskId = subtask.subTask?.task?.id
      const updatedSubtasks = subtasks.map((el) =>
        el.subTask.task.id === subtaskId
          ? { ...el, subTask: {...el.subTask, task: {...el.subTask.task, status: newStatusId}}}
          : el
      );


      // save updated subtasks to state
      setSubtasks(updatedSubtasks);

      // Dispatch to Redux
      props.updateSubTask({...subtask, subTask: {...subtask.subTask, task: {...subtask.subTask.task, status: newStatusId}}});
    } else {
      const subtaskId = subtask.subTask.id
      const items = [
        {
          id:subtaskId,
          order: 1,
          status: newStatusId
        }
      ]

      const updatedSubtasks = subtasks.map((el) =>
        el.subTask.id === subtaskId
          ? { ...el, subTask: {...el.subTask, status: newStatusId}}
          : el
      );

      updateReorderTask({items: items, taskIdSelected: subtaskId, status: newStatusId}).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
        }else{
          setSubtasks(updatedSubtasks);
        }
      })
    }

  };
  const formatCostValue = (value) => {
    if (!value && value !== 0) return '0.00';
    const numberValue = parseFloat(value);
    return numberValue.toFixed(2);
  };

  const getSubTaskStatus = (subTask) => {
    const reduxStatus = subTask.subTask?.task?.status;
    const dbStatus = subTask.subTask?.status;
    return reduxStatus || dbStatus || 'todo';
  };

  const getDisplayValue = (el) => {
    return props.priorities.find((option) => option.id === (el.subTask.task?.priority || el.subTask.priority))?.displayValue;
  }

  const displayWithDecimals = (valueToDisplay) => {
    let formattedValue = '0.00'

    if (typeof valueToDisplay === 'string' && !isNaN(Number(valueToDisplay))) {
      formattedValue = parseFloat(valueToDisplay).toFixed(2)
    } else if (typeof valueToDisplay === 'number') {
      formattedValue = valueToDisplay.toFixed(2)
    }

    return formattedValue
  }

  const handleRemove = (evt, subTask) => {
    if(isNewTask) {
      const updatedSubtasks = subtasks.filter((el) =>
        el.subTask.task.id !== subTask.subTask.task.id
      );

      setSubtasks(updatedSubtasks);
      props.deleteSubTask(subTask)
    } else {
      props.openDeleteModal(evt, subTask.subTask.id)
    }

  }

  return (
    <>
      <div className='subtasks'>
        <div className='body-subtasks'>
          <div className='subtask-header'>
            <span className='title'>Subtasks</span>
            <progress
              id='task-completed'
              className='styled-progress'
              max='100'
              value={progressPercentage}
            ></progress>
            <span className='task-completed'>
              {progressPercentage}% Completed
            </span>
          </div>
          <div className='subtask-table'>
            <table>
              <thead>
                <tr>
                  <th width='60%'>Title</th>
                  <th width='15%'>Priority</th>
                  <th width='12%'>Status</th>
                  <th width='12%'>Costs</th>
                  <th width='5%'></th>
                </tr>
              </thead>
              <tbody>
                {subtasks.length > 0 && subtasks.map((el) => (
                  <tr>
                    <td>
                      <span onClick={()=>goToSubTaskPage(el.subTask.task?.id || el.subTask.id)}>
                        {el.subTask.task?.title || el.subTask.title}
                      </span>
                    </td>
                    <td className={getDisplayValue(el).toLowerCase()}>{getDisplayValue(el)}</td>
                    <td>
                      <DPSelectionDropDown
                        options={SUBTASK_STATUS}
                        value={
                          SUBTASK_STATUS.find(
                            (option) => option.id === getSubTaskStatus(el)
                          )?.id || 'todo'
                        }
                        heightOfSelect={'47'}
                        onClick={(newStatusId) =>
                          handleStatusChange(el,el.subTask?.task?.id, newStatusId)
                        }
                        id='from-dropdown'
                        label=''
                        errorMessage={''}
                      />
                    </td>
                    <td>${
                      displayWithDecimals(
                        el.subTask?.repairCost?.totalCost || // Redux format
                        el.totalCost ||                     // API format
                        el.task?.repairCost?.totalCost ||   // Redux fallback
                        0                                   // Default if no cost found
                      )
                    }</td>
                    <td>
                      <div className='info-icon'>
                        <ReactSVG
                          src={`${pathServer.PATH_IMG}icon/ic_delete.svg`}
                          className='icon-action-content-delete'
                          onClick={(evt)=>handleRemove(evt, el)}
                          beforeInjection={(svg) =>
                            svg.classList.add('icon-action-content-delete')
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='add-subtask'>
              <span onClick={() => goToSubTaskPage('new')}>+ Add subtask</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubTask;
