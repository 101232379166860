import React, {Component} from 'react'
import LocationSettingList from './LocationSettingList.jsx'


export default class Locations extends Component {

  constructor() {
    super();
    this.state = {
    }
  }

  render() {
    const {height} = this.props;
    return (
      (this.props.children) ? this.props.children :
        <LocationSettingList height ={height}/>
    )
  }
}