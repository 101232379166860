import React from 'react'
import PropTypes from 'prop-types'
import {ReactSVG} from 'react-svg'
import SaleSettingUpdateForm from './SaleSettingUpdateForm'
import DPDraggable from '../../dragdrop/Draggable.jsx'
import {COLUMNS} from './SaleSetting'
import {DPDynamicNumberInput} from '../../../common/DPDynamicNumberInput'
import SaleSettingColumnSectionElementForFees from './SaleSettingColumnSectionElementForFees'
import StateTaxSetting from './StateTaxSetting'
import {pathServer} from '../../../../common/Constants'


class SaleSettingColumnSectionElement extends React.Component {
  constructor(props) {
    super(props);
    this.changeToEditMode = this.changeToEditMode.bind(this)
    this.onMouseOverElement = this.onMouseOverElement.bind(this)
    this.onMouseOutElement = this.onMouseOutElement.bind(this)
    this.state = {
      isEditing: false,
    }
  }

  // Changes to Edit Mode
  changeToEditMode() {
    this.setState({isEditing: true})
  }

  // When moving the mouse pointer over 'displayed-element-row' div
  // let show icons of the 'element-options' div
  onMouseOverElement(elementId, columnName) {
    const tagElementId = `element-options-${columnName}-${elementId}`;
    let tagElement = document.getElementById(tagElementId);

    if(columnName === COLUMNS.ACCESSORY || columnName === COLUMNS.REG_FEES_AND_OTHERS){
      tagElement.style.display = 'block';
      tagElement.style.visibility = 'visible';
    }else{
      tagElement.style.visibility = 'visible';
    }
  }

  // When moving the mouse pointer out 'displayed-element-row' div
  // let show icons of the 'element-options' div
  onMouseOutElement(elementId, columnName) {
    const tagElementId = `element-options-${columnName}-${elementId}`
    let tagElement = document.getElementById(tagElementId);

    if(columnName === COLUMNS.ACCESSORY || columnName === COLUMNS.REG_FEES_AND_OTHERS){
      tagElement.style.display = 'none'
    }else{
      tagElement.style.visibility = 'hidden';
    }
  }

  handleBlurDealerDocFee = (evt) => {
    const updatedElement = {
      ...this.props.element,
      value: evt.target.value.toString()
    }

    this.props.editElement(updatedElement, this.props.columnName)
  }


  render() {
    const {element, sectionId, goUp, goDown, deleteElement, editElement, index, validateElement, columnName, handleShowPopover,
      handleShowDeleteModal
    } = this.props
    const otherDivProps = {
      className: 'displayed-element-row',
      onMouseOver: () => this.onMouseOverElement(element.id, columnName),
      onMouseOut: () => this.onMouseOutElement(element.id, columnName),
    }
    const draggableStyle = {
      userSelect: 'none',
      marginBottom: 10,
    }
    const message = 'Is a fee charged by car dealerships to process a vehicle’s paperwork. '
      + 'Essentially, a doc fee covers the cost of all the dealership’s back office employees, '
      + 'from the people who handle the money to the employees who deal with the title, '
      + 'registration and the DMV'

    return (
      !this.state.isEditing
        ? columnName === COLUMNS.MORE_SETTINGS
          ?
          <div className="container-more-settings">
            <div className='element-detailed-info'>
              <DPDynamicNumberInput
                className='col100'
                title={'Dealer documentation fee'}
                id={'dealer-doc-fee'}
                value={element.value}
                onBlur={(evt) => this.handleBlurDealerDocFee(evt)}
                thousand={true}
                separator={'.'}
                fraction={2}
                withSymbol={true}
                nextIcon={
                  <ReactSVG
                    id='questionDealerDocFee'
                    src={pathServer.PATH_IMG + "icon/ic_sign_question.svg"}
                    className="icon-question-content"
                    onClick={(evt) => handleShowPopover('questionDealerDocFee', message)}
                  />
                }
              />
            </div>
            <StateTaxSetting />
          </div>
          : columnName === COLUMNS.REG_FEES_AND_OTHERS
            ? <SaleSettingColumnSectionElementForFees
              element={element}
              index={index}
              columnName={columnName}
              handleChangeToEditMode={() => this.changeToEditMode()}
              handleDeleteElement={() => handleShowDeleteModal(element.id, columnName)}
              {...otherDivProps}
            />
            : <DPDraggable itemId={element.id} index={index} otherDivProps={otherDivProps} draggableStyle={draggableStyle}>
            <div key={element.id} className={columnName === COLUMNS.CHECKLIST ? "container-checklist" :  columnName === COLUMNS.FILES ? "container-file"
              :  (index % 2 === 0) ? "container-accessory row-color" : "container-accessory"}>
              <div className="container-icon">
                <div className="icon-arrow-content">
                  <ReactSVG
                    src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                    className="icon-arrow"
                    beforeInjection={svg => svg.classList.add('icon-arrow-svg')}
                    onClick={() => goUp(sectionId, index, columnName)}
                  />
                  <ReactSVG
                    src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                    className="icon-arrow"
                    beforeInjection={svg => svg.classList.add('icon-arrow-svg')}
                    onClick={() => goDown(sectionId, index, columnName)}
                  />
                </div>
              </div>
              {
                columnName === COLUMNS.FILES ?
                  <div className='element-detailed-info'>
                    <div className='element-title'>{element.name}</div>
                    <div className='element-subtitle'>{element.code}</div>
                  </div> : columnName === COLUMNS.CHECKLIST ?
                  <div className='element-detailed-info'>
                    <div className='element-title'>{element.description}</div>
                  </div> :
                  <div className='element-detailed-info'>
                    <div className='element-title'>{element.name}</div>
                    <div className='element-price'>{`$${element.unitPrice.toFixed(2)}  `}</div>
                  </div>
              }
              <div
                id={`element-options-${columnName}-${element.id}`}
                className='element-options'
              >
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/edit-pencil.svg'}
                  beforeInjection={svg => svg.classList.add('edit-option')}
                  onClick={this.changeToEditMode}
                />
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/waste-can.svg'}
                  beforeInjection={svg => svg.classList.add('delete-option')}
                  onClick={() => deleteElement(element.id, columnName)}
                />
              </div>
            </div>
          </DPDraggable>
        : // Loads form for editing
        <SaleSettingUpdateForm
          element={element}
          closeForm={() => this.setState({isEditing: false})}
          updateElement={editElement}
          validateElement={validateElement}
          columnName={columnName}
        />
    )
  }
}

SaleSettingColumnSectionElement.propTypes = {
  index: PropTypes.number,
  element: PropTypes.object,
  sectionId: PropTypes.string,
  goUp: PropTypes.func,
  goDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElement: PropTypes.func,
  handleShowDeleteModal: PropTypes.func,
}

export default SaleSettingColumnSectionElement