import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import {DPPillBox, Popover} from '../../util/ComponentUtils'
import Outside from '../../util/Outside.jsx'
import {findIndex, cloneDeep} from 'lodash'
import Dropzone from 'react-dropzone'
import {addRegisterUser} from '../../../Api'
import InputElement from 'react-input-mask'
import {
  WIDTH_VIEW_MOBILE,
  EXT_IMG,
  EXT_OTHER_IMG,
  EXT_PNG,
  pathServer,
  SUCCESSFUL_CODE
} from '../../../../common/Constants'
import isEmpty from 'lodash/isEmpty'
import {checkValidEmail} from '../../../../common/ValidationUtil'

export default class UserEdit extends Component {
  constructor() {
    super();
    this.state = {
      showEditActivity : true,
      showEditRole: false,
      permissions:[
        {id:"1", name: "Permission 1"},
        {id:"2", name: "Permission 2"},
        {id:"3", name: "Permission 3"},
        {id:"4", name: "Permission 4"},
        {id:"5", name: "Permission 5"},
        {id:"6", name: "Permission 6"},
      ],
      userSelected: null,
      optionsFilter: [],
      dateUser: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        phoneNumber: "",
        description: "",
        roles: [],
        image: "",
        active: true,
        verify:false,
        password: ("secret").toString(),
      },
      errorMessage: {},
      errorEmail: false,
      photo: null,
      isLoading:false,
      widthPage: window.innerWidth
    }
  }

  componentWillMount() {
    let {userSelected, photo} = this.props;
    this.setCheckedRoles(userSelected);
    this.setState({userSelected: cloneDeep(userSelected), photo: photo})
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let {userSelected, photo} = nextProps;
    this.setCheckedRoles(userSelected);
    this.setState({userSelected: cloneDeep(userSelected), photo: photo})
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setCheckedRoles(userSelected){
    let {optionsFilter} = this.props;
    let rolesTemp = cloneDeep(userSelected.roles);
    optionsFilter.map((op) => {
      let index = rolesTemp.findIndex(row => row.id === op.id);
      op.checked = index !== -1;
    });
    this.setState({optionsFilter:optionsFilter})
  }

  renderPermission = () => {
    const {permissions} = this.state;
    return(
      <ul>
        {permissions.map(function (permission,index) {
            return (
              <li key={index}><label>{permission.name}</label></li>
            );
          })}
      </ul>
    );
  }

  renderButton = (type) => {
    if(type === "add"){
      return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.504 63.504">
          <path id="ic_add_24px" d="M68.5,41.288H41.288V68.5H32.216V41.288H5V32.216H32.216V5h9.072V32.216H68.5Z" transform="translate(-5 -5)"/>
        </svg>
      )
    } else if( type === "less"){
      return (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus"
             className="svg-inline--fa fa-minus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 448 512">
          <path fill="currentColor"
                d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
        </svg>
      )
    }
  }

  backInfo = () => {
    this.props.closeModal("showEditPanelUser")
  }

  saveOrUpdateInfo = async() => {
    let {photo, errorEmail, userSelected, errorMessage} = this.state;
    if(userSelected.email && userSelected.email !=='' && userSelected.firstName && userSelected.firstName !=='' &&
      userSelected.lastName && userSelected.lastName !=='' && !errorEmail){
      this.setState({isLoading:true});
      if (userSelected.id === ''){
        delete userSelected['id']
      }
      const userSelectedToSave = { ...userSelected, roles: userSelected.roles.map(({ id, displayValue }) => id)}
      const {responseCode, responseMessage} = await addRegisterUser(userSelectedToSave, photo)
      if (responseCode === SUCCESSFUL_CODE){
        this.setState(
          {
            errorMessage: {},
            errorEmail: false,
            userSelected: null,
            photo: null,
            isLoading:false},
          (() => { this.props.save();})
        )
      }else{
        console.error(responseMessage);
        this.setState({isLoading:false});
      }

      /*addRegisterUser(userSelectedToSave, photo).then((response) => {
        this.setState({errorMessage: {}, errorEmail: false, userSelected: null, photo: null,isLoading:false}, (() => {
          this.props.save();
        }));
      }).catch(error => {
        console.error(error);
        this.setState({isLoading:false});
      });*/
    } else{
      errorMessage.firstName = (!userSelected.firstName || userSelected.firstName ==='');
      errorMessage.lastName = (!userSelected.lastName || userSelected.lastName ==='');
      errorMessage.email = (!userSelected.email || userSelected.email ==='');
      this.setState({errorMessage: errorMessage})
    }
  }

  closeHeaderFilter = () => {
    this.setState({showPopoverRolesModal: false});
  }

  stateRolModal = () => {
    let {showPopoverRolesModal} = this.state;
    this.setState({showPopoverRolesModal: !showPopoverRolesModal});
  }

  handleCheck = (e) => {
    let {userSelected, optionsFilter} = this.state;
    let rolesTemp = cloneDeep(userSelected.roles);

    optionsFilter.map((op) => {
      if(op.id === e.target.id){
        if(op.checked) {
          let index = rolesTemp.findIndex(row => row.id === op.id);
          rolesTemp.splice(parseInt(index), 1);
        } else{
          rolesTemp.push({id: op.id, displayValue:op.displayValue});
        }
        op.checked = !op.checked;
      }
    });
    userSelected.roles = rolesTemp;
    this.setState({userSelected: userSelected, optionsFilter: optionsFilter})
  }

  handleChange = (e) => {
    let {userSelected,errorMessage} = this.state;
    /*
    const name = e.target.name;
    let state = {userSelected:userSelected,errorEmail:false};
    if (name === "email"){
      state.errorMessage = errorMessage;
      if(!isValidEmail(e.target.value)){
        //this.setState({errorEmail: true})
        state.errorEmail = true;
      } else {
        //userSelected[name] = e.target.value;
        //this.setState({ userSelected: userSelected, errorEmail: false });
        //state.errorMessage.email = false;
      }
      state.userSelected[name] = e.target.value;
    } else {
      //userSelected[name] = e.target.value;
      //this.setState({ userSelected:userSelected});
      state.userSelected[name] = e.target.value;
    }
    */

    userSelected[e.target.name] = e.target.value;
    errorMessage[e.target.name] = false;
    this.setState({userSelected:userSelected,errorMessage:errorMessage})
  }

  onBlur = (e) => {
    //const {userSelected,errorMessage} = this.state;
    let errorEmail = false;
    if(e.target.value !== ''){
      if(!checkValidEmail(e.target.value))
        errorEmail = true;
    }
    this.setState({errorEmail:errorEmail})
  }

  renderAllRoles() {
    let {userSelected, optionsFilter} = this.state;
    let options = [];

    optionsFilter.map((role, index) => {
      const checked = findIndex(userSelected.roles, (roleSelected) => {
        return roleSelected.id === role.id;
      });
      if (role.id !== "clear") {
        options.push(
          <div key={index} id="id-content-check" className={"content-check"}>
            <input type="checkbox" key={index} id={role.id} name={role.value} onChange={this.handleCheck}
                   defaultChecked={(checked !== -1)}/>
            <p style={{color: "#a5a5a5", fontSize: 13, fontWeight: "400", paddingLeft: "11px"}}>{role.displayValue}</p>
          </div>
        )
      }
    });
    return (
      <div className="popover-body-status">
        <div id="" className="options-status">
          {options}
        </div>
      </div>
    )
  }

  onDrop = (images) => {
    if (images){
      this.setState({photo: images[0]});
    }
  }

  showPermissions = (op) => {
    let {optionsFilter} = this.state;
    optionsFilter.map(option=>{
      if(option.id===op.id)
        (option.btnShow==="less")?option.btnShow="add":option.btnShow="less";
    });
    this.setState({optionsFilter: optionsFilter});
  }

  handleResize = () => {
    this.setState({widthPage: window.innerWidth})
  }
  render() {
    const {photo, errorMessage, errorEmail, optionsFilter,userSelected,showPopoverRolesModal,isLoading,widthPage} = this.state;
    let {showModal, isNewUser} = this.props;
    let quantityRolesUserSelected = 0;
    if(!userSelected) return null;
    if(userSelected && userSelected.roles && !isEmpty(userSelected.roles)){
      quantityRolesUserSelected = userSelected.roles.length;
    }
    let roles="";
    if (userSelected && userSelected.roles && !isEmpty(userSelected.roles)){
      optionsFilter.map(op=> {
        userSelected.roles.map((rol)=>{
          if (op.id === rol.id) {
            if (roles==="")
              roles = "" + op.displayValue;
            else
              roles = roles + " - " + op.displayValue;
          }
        })
      })
    }

    return (
      <div>
        <Modal show={showModal} className="user-modal-container" backdropClassName="backdrop-car">
          <Modal.Body>
            {
              isLoading ?
                <div className="loader-container">
                  <div className="loader"/>
                </div> : null
            }
            <div className="user-modal" style={isLoading ? {opacity:0.4} : null}>
              <img src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon" onClick={this.props.closeModal.bind(this,"showEditPanelUser")}/>
              <div className="user-modal-body">
                <div className={ !isNewUser ? "seccion-1-edit top-bottom" :"seccion-1-edit" }>
                  {
                    isNewUser ?
                        <div id="new-user-title" className="title-new-user">New User</div> : null
                  }

                  <div className="container-dropzone-image">
                    <Dropzone id="dropzone-modal" onDrop={this.onDrop} multiple={false} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
                      {({getRootProps, getInputProps}) => (
                          <div {...getRootProps()} className="dropzone-container-user">
                             <div id="id-user-modal-image" className="user-modal-image">
                                {
                                  (!photo && (!userSelected.image || userSelected.image === "")) ?
                                      <div className="new-user-photo-container-modal">
                                        <input {...getInputProps()} />
                                        <div className="new-user-photo-circle">
                                          <i className="fa fa-camera" aria-hidden="true" style={{display: "inline-block"}}/>
                                        </div>
                                      </div> :
                                      <div className="container-image-user">
                                        <img className="photo-user-img-circle"
                                             src={(photo) ? URL.createObjectURL(photo) : (userSelected.image !== "") ? userSelected.image : pathServer.PATH_IMG + "ic_account_circle.png"}/>
                                        <input {...getInputProps()} />
                                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"} className="img-customer"/>
                                      </div>
                                }
                             </div>
                          </div>
                       )}

                    </Dropzone>
                  </div>
                  <div className="user-modal-text">
                    <div className="user-name">
                      {
                        userSelected.accountOwner ?
                          <DPPillBox displayValue='ACCOUNT OWNER' /> : null
                      }
                    </div>
                    {
                      !userSelected.accountOwner ?
                        <div className="user-detail">
                          <Outside onClose={this.closeHeaderFilter}>
                            <div id="roles" className="container-roles">
                              <span>Roles</span>
                              <div id="" className="form-control dropdown-item" data-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false" onClick={this.stateRolModal}>
                            <span className="letter-roles-modal">{quantityRolesUserSelected === 0 ? "None" :
                              quantityRolesUserSelected === 1 ? userSelected.roles[0]?.displayValue :`${quantityRolesUserSelected} selected`}</span>
                                {showPopoverRolesModal ?
                                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                            className="icon-arrow-roles" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                                  : <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                              className="icon-arrow-roles" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                                }
                              </div>
                              {
                                showPopoverRolesModal ?
                                  <Popover anchorEl={null}
                                           child={this.renderAllRoles()}
                                           classPopover="popover-roles-modal"
                                           type="userSetting"/> : null
                              }
                            </div>
                          </Outside>
                        </div> : null
                    }
                  </div>
                </div>
                {
                  widthPage <= WIDTH_VIEW_MOBILE ?
                      <div className="container-line-section-separator">
                        <div className="line-section-separator"/>
                      </div>  : null
                }
                <div className="float-right seccion-2-edit">
                  <div className="user-inputs">
                    <div className="col100">
                      <div className="col50">
                        <div className="carInfoLabel">
                          <label htmlFor="firstName">Name</label>
                        </div>
                        <div className="classInputCarInfo">
                          <input type="text" className="form-control colRequired" id="firstName" name="firstName"
                                 value={userSelected.firstName ? userSelected.firstName : ''} placeholder="Type firstName" onChange={this.handleChange}/>
                          {(errorMessage.firstName) ?
                            <span className="error-message-edit">This field must be filled.</span> : null}
                        </div>
                      </div>
                      <div className="col50">
                        <div className="carInfoLabel">
                          <label htmlFor="lastName">Last Name</label>
                        </div>
                        <div className="classInputCarInfo">
                          <input type="text" className="form-control colRequired" id="lastName" name="lastName"
                                 defaultValue={userSelected.lastName ? userSelected.lastName : ''} placeholder="Type lastName" onChange={this.handleChange}/>
                          {(errorMessage.lastName) ?
                            <span className="error-message-edit">This field must be filled.</span> : null}
                        </div>
                      </div>
                    </div>
                    <div className="col100">
                      <div className="col50">
                        <div className="carInfoLabel">
                          <label htmlFor="email">Email</label>
                        </div>
                        <div className="classInputCarInfo">
                          <input type="text" className="form-control colRequired" id="email" name="email"
                                 defaultValue={userSelected.email} placeholder="example@mail.com" onChange={this.handleChange} onBlur={this.onBlur} />
                          {(errorMessage.email) ? <span className="error-message-edit">This field must be filled.</span> :
                            (errorEmail) ? <span className="error-message-edit"> Email is not valid.</span> : null}
                        </div>
                      </div>
                      <div className="col50">
                        <div className="carInfoLabel">
                          <label htmlFor="phone">Phone number</label>
                        </div>
                        <div className="classInputCarInfo">
                          <InputElement type="text" className="form-control colRequired"
                                        mask="(999)999-9999"
                                        placeholder="(###)###-####"
                                        alwaysShowMask={false}
                                        maskChar=""
                                        defaultValue={userSelected.phoneNumber ? userSelected.phoneNumber : ''}
                                        name="phoneNumber" readOnly={false} onChange={this.handleChange}/>
                        </div>
                      </div>
                    </div>
                    <div className="col-100">
                      <div className="carInfoLabel">
                        <label htmlFor="address">Address</label>
                      </div>
                      <div className="classInputCarInfo">
                        <input type="text" className="form-control colRequired" name="address"
                               defaultValue={userSelected.address} placeholder="Street, Apt, City and ZIP Code" onChange={this.handleChange}/>
                      </div>
                    </div>
                    <div className="col-100">
                      <div className="carInfoLabel">
                        <label htmlFor="description">Description</label>
                      </div>
                      <div className="classInputCarInfo">
                        <input type="text" className="form-control colRequired" name="description"
                               defaultValue={userSelected.description} placeholder="Any extra information here" onChange={this.handleChange}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-modal-pie">
                <div className="user-modal-button">
                  {
                    userSelected.id && widthPage > WIDTH_VIEW_MOBILE ?
                      <a className="btn-back" onClick={this.backInfo}>
                        Back
                      </a> : null
                  }
                  <a className="btn-save" onClick={this.saveOrUpdateInfo}>
                    Save
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}