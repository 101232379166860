import React from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import Moment from 'moment'
import {pathServer} from '../../../common/Constants'

const {
  Component
} = React;

const GenericModalConfirm = ({show, onHide, save, message, showButtonCancel, buttonLabelSave, classButtonSave, buttonLabelCancel, showCustomIcon, info, stylePanelRight,
                              cancel, children, classNameModalButtons, modalCancelSale, isLoading, customIconLeft, classModal}) => {
  return (
      <div className="container-modal-car">
        <Modal show={show} className={modalCancelSale ? modalCancelSale : classModal ? `modal-car ${classModal}` : "modal-car"} backdropClassName="backdrop-car">
          <Modal.Body>
            {
              isLoading ?
                  <div className="loader-container">
                    <div className="loader"/>
                  </div> : null
            }
            {
              showCustomIcon ?
                  <div className="panel-left-custom" style={ isLoading ? {opacity:"0.5"} : {}}>
                    {
                      children ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-cancel-sale-left"
                                    onClick={onHide}/> : null
                    }
                    {
                      customIconLeft ?
                        <ReactSVG src={`${pathServer.PATH_IMG}icon/${customIconLeft}`} className="car-run"
                                  beforeInjection={ svg => svg.classList.add('icon-warning-svg')}/>:
                        <ReactSVG src={`${pathServer.PATH_IMG}icon/warning.svg`} className="car-run"
                                  beforeInjection={ svg => svg.classList.add('icon-warning-svg')}/>
                    }

                  </div>
                  :
                  <div className="panel-left">
                    <h2>Are you sure?</h2>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_car_run.svg"} className="car-run"
                              beforeInjection={ svg => svg.classList.add('ic-car-run')}/>
                  </div>
            }
            {
              children ?
                  children
                  :
                  <div className="panel-right" style={ stylePanelRight ? stylePanelRight : null}>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close_circle.svg"} className="icon-close-content"
                              beforeInjection={ svg => svg.classList.add('icon-close')} onClick={onHide}
                              data-test="icon-close-modal"
                    />
                    <div className="label-sure">
                      <label>{message}</label>
                    </div>
                    {
                      info && info.length > 0 ?
                          <div className="container-elements">
                            {
                              info.map((element, index) => {
                                return <div key={`${element}-${index}`}
                                            className="row-info-modal">{`${Moment(element.createDate).format('MM/DD/YYYY')} - ${element.salesmanFirstName} ${element.salesmanLastName}`}</div>
                              })
                            }
                          </div>
                          : null
                    }
                    <div className={!classNameModalButtons ? "modal-buttons" : classNameModalButtons}>
                      {showButtonCancel ?
                          <button onClick={ cancel ? cancel : onHide} className={showCustomIcon ? "btn-not-save-custom" : "btn-not-save"}
                                  data-test="btn-cancel-modal">
                            {buttonLabelCancel ? buttonLabelCancel : "Don't Save"}
                          </button> : null
                      }
                      <button onClick={save} className={showCustomIcon ? "btn-save-custom" : classButtonSave} data-test="btn-save-modal">{buttonLabelSave}</button>
                    </div>
                    {
                      !showCustomIcon ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_run.svg"} className="car-run-right"
                                    beforeInjection={ svg => svg.classList.add('ic-car-run')}/> : null
                    }
                  </div>
            }
          </Modal.Body>
        </Modal>
      </div>
  )
}

export default GenericModalConfirm;