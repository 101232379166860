import React, { useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import DPPage from '../../common/DPPage'
import DPTable from '../../common/DPTable'
import { searchWebhookEvents } from '../../Api'
import Moment from 'moment'
import { DPPagination } from '../../../common/DPPagination'
import { siteColorAdmin, SUPERUSERS_BY_PAGE } from '../../Constants'
import { SUCCESSFUL_CODE } from '../../../common/Constants'

const WebhookData = () => {

  const [webhookEvents, setWebhookEvents] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)


  useEffect(() => {
    (async () => {
      const response = await searchWebhookEvents(currentPage - 1, itemsPerPage)
      if (response.responseCode === SUCCESSFUL_CODE){
        const {data} = response
        unstable_batchedUpdates(() => {
          setWebhookEvents(data.entities)
          setTotalCount(data.totalCount)
        })
      }else{
        console.error(response.responseMessage)
      }
    })()
  }, [currentPage, itemsPerPage])

  const totalPages = totalCount > 0 ? Math.ceil(totalCount/itemsPerPage) : 1

  return (
    <DPPage
      title="Webhook Data"
      rightHeader={
        <DPPagination
          optionsQuantityByPage={[25, 50, 100]}
          selectedItemsByPage={itemsPerPage}
          handleChangeItemsByPage={value => {
            setItemsPerPage(value)
            setCurrentPage(1)
          }}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={value => setCurrentPage(value)}
          siteColor={siteColorAdmin}
        />
      }
    >
      <DPTable
        isLoading={false}
        data={webhookEvents}
        config={
          {
            columns: [
              {id: 'createDate', label: 'Event Date'},
              {id: 'eventType', label: 'Event Type'},
              {id: 'value', label: 'Value'}
            ]
          }
        }
        renderColumn={
          (webhookEvent, columnConf) => {
            const {eventType, value, createDate} = webhookEvent
            switch (columnConf.id) {
              case 'eventType':
                return eventType
              case 'value':
                return <pre>{JSON.stringify(JSON.parse(value),null,2)}</pre>
              case 'createDate':
                return Moment(new Date(createDate)).format("YYYY/MM/DD hh:mm A")
            }
          }
        }
      />
    </DPPage>
  )
}

export default WebhookData
