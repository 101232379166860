import React from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../common/Constants'

const ModalConfirmDelete = (
  { show,onHide,styleModal,styleLeft,styleRight,body,message,showButtonDelete,onSubmit, buttonLabelDelete,classButtonDelete,viewMobile, subMessage, isLoading, isSale,
    containerDeleteButtonClassName
  }) => {
    return (
      <div className="container-modal-car">
        <Modal show={show} className={"modal-car " + styleModal} backdropClassName="backdrop-car">
          {
            viewMobile ?
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                        beforeInjection={ svg => svg.classList.add("icon-close")} onClick={onHide}/> : null
          }
          <Modal.Body>
            {
              isLoading ?
                  <div className="loader-container" style={{position: 'absolute'}}>
                    <div className="loader"/>
                  </div> : null
            }
            <div className={"panel-left " + styleLeft}>
              <h2>Are you sure?</h2>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="icon-delete" beforeInjection={ svg => svg.classList.add("icon-delete-svg")}/>
            </div>
            <div className={"panel-right "+ styleRight}>
              {
                viewMobile || isLoading ?
                  null :
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                            beforeInjection={ svg => svg.classList.add("icon-close")} onClick={onHide}/>
              }
              {
                body ? body :
                  subMessage ?
                    <div className="container-message-delete">
                      <label className="label-message">{message}</label>
                      <label className="label-sub-message">{subMessage}</label>
                    </div> :
                    <div className="label-sure">
                      <label>{message}</label>
                    </div>
              }
              {
                !isSale ?
                  <div className={containerDeleteButtonClassName ? containerDeleteButtonClassName : "container-button-delete"}>
                    {
                      showButtonDelete ? <button onClick={onSubmit} className={classButtonDelete}>{buttonLabelDelete}</button> : null
                    }
                  </div> :
                  <div className="container-button-delete-sale">
                    {
                      showButtonDelete ? <button onClick={onSubmit} className={classButtonDelete}>{buttonLabelDelete}</button> : null
                    }
                  </div>
              }
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
export default ModalConfirmDelete;