import _ from 'lodash'
import {dateFormats} from '../../Constants'

function getRows(result, noHeaders){
  const list = [];
  let header = [];
  result.forEach((item,index) => {
    if(index === 0){
      if (noHeaders) {
        let indexItem = 0;
        for(let x in item){
          header.push('Column ' + indexItem);
          indexItem += 1;
        }
      } else{
        for(let x in item){
          header.push(x);
        }
      }
    }
    if(index !== 0 || noHeaders){
      let line = []
      for(let x in item){
        const value = item[x];
        line.push(value);
      }
      const jsonObj = _.zipObject(header, line);
      const itemJson = toCar(jsonObj);
      list.push(itemJson);
    }
  })
  return { header: header, rows: list};
}

function toCar(car){
  return car;
}

function CSVtoArray(text) {
  const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
  const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
  // Return NULL if input string is not well formed CSV string.
  if (!re_valid.test(text)) return null;
  const a = [];                     // Initialize array to receive values.
  text.replace(re_value, // "Walk" the string using replace with callback.
    function (m0, m1, m2, m3) {
      // Remove backslash from \' in single quoted values.
      if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      // Remove backslash from \" in double quoted values.
      else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      else if (m3 !== undefined) a.push(m3);
      return ''; // Return empty string.
    });
  // Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push('');
  return a;
}

export function parseCSVToJSON(file, noHeaders, callback){
  try{
    const reader = new FileReader();
    reader.onload = function (e) {
      let dataExcel = e.target.result
      const workbook = XLSX.read(dataExcel,{type:"binary", cellDates: true, dateNF: dateFormats.MM_DD_YYYY})
      let column = 0
      const range = noHeaders ? -1 : 0;
      workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheet],{range: range,raw: false, defval: ""}
        );
        column += 1;
        const data = getRows(rowObject, noHeaders);
        callback(data);
      });
    }
    reader.readAsBinaryString(file);
  }
  catch(e){
    console.log(e);
    return null;
  }
}