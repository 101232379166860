import {EmptyFunc, pathServer, WIDTH_VIEW_MOBILE} from '../../../../../common/Constants'
import React from "react";
import {ReactSVG} from "react-svg";
import {browserHistory} from 'react-router'
require('./footerGDG.scss')

const contactEmail = "info@goDealergo.com"
const phone= '+1 801 494 9191'
const address = '227 N. 110 W. Vineyard UT, 84059'

const deployIconList = [
  {id:'1', route:'icon/ic-facebook-homepage.svg'},
  {id:'2', route:'icon/ic-twitter-homepage.svg'},
  {id:'3', route:'icon/ic-linkedin-homepage.svg'},
  {id:'4', route:'icon/ic-instagram-homepage.svg'},
]

const deployFeaturesList = [
  {id:'1', text:'Customer management', link: '/products/customerManagement'},
  {id:'2', text:'Reviews', link: '/products/reviews'},
  {id:'3', text:'Team Tasks', link: '/products/todoTeams'},
  {id:'4', text:'Website Builder', link: '/products/websiteBuilder'},
  {id:'5', text:'End-to-end sales process', link: '/products/salesProcess'},
  {id:'6', text:'Inventory management', link: '/products/inventoryManagement' },
]

const SocialMediaLinks = () => (
  <div className="social-media-links">
    {
      deployIconList.map((item) => {
        const {route, id} = item
        return (
          <ReactSVG
            key={`svg-${id}`}
            src={`${pathServer.PATH_IMG}${route}`}
            className="icon-menu-content"
          />
        )
      })
    }
  </div>
)

const FooterSection = () => (
  <footer id="footer-section-gdg" className="footer-section-gdg">
    <div className="content">
      <section className="footer-first-column">
        <img src={`${pathServer.PATH_IMG}gdg_logo_symbol.png`} alt="go-dealer-go-logo-symbol" className="logo"/>
        <div className="social-media-section">
          <label className="description">
            GoDealerGo is a one stop shop for dealers like you who are looking for the next step in the software
            solutions you use to engage with customers and get your inventory online.
          </label>
          {
            window.innerWidth > WIDTH_VIEW_MOBILE &&
            <SocialMediaLinks/>
          }
        </div>
      </section>
      <section className="footer-second-column">
        <h4 className="contact-title">Contact</h4>
        <label className="contact-info">
          <ul>
            <li><a href={`mailto:${contactEmail}`}>{contactEmail}</a></li>
            {/*<li><a href={`https://maps.google.com/maps?q=${address}`} target="_blank">{address}</a></li>*/}
            <li><a href={`tel:${phone}`}>{phone}</a></li>
            <li><a href="/privacy" target="_blank">Privacy Policy</a></li>
          </ul>
        </label>
      </section>
      <section className="footer-third-column">
        <h4 className="feature-title">Features</h4>
        {
          deployFeaturesList.map((item) => {
            const {text, id, link} = item
            return (
              <span key={`text-${id}`}
                    onClick={link ? () => browserHistory.push({pathname: link}) : EmptyFunc}>{text}</span>
            )
          })
        }
      </section>
      {
        window.innerWidth <= WIDTH_VIEW_MOBILE && <SocialMediaLinks/>
      }
    </div>
    <hr/>
    <div className="end-content">
      <div>
        <label className="brand-details">Copyright 2022 GDG all Rights reserved</label>
        {
          window.innerWidth <= WIDTH_VIEW_MOBILE &&
          <div>
            <a>Career</a>
            <a>Terms of use</a>
            <a>Privacy policy</a>
          </div>
        }
      </div>
    </div>
  </footer>
  )


export default FooterSection
