import React, {useState} from 'react'
import DPButtonIcon from '../../common/DPButtonIcon'
import {pathServer,SUCCESSFUL_CODE} from '../../../common/Constants'
import {Modal} from "react-bootstrap";
import {ReactSVG} from "react-svg";
import {browserHistory} from "react-router";
import UserHasPermission from "../UserHasPermission";
import {loadTestimonials} from "../../Api";
import {LOAD_ACTIVE_USERS_FAILURE} from "../../actions";

require('./GifContainer.scss')

const GifContainer = (props) => {

  const {floatTitle, floatText, floatTextButton, gifPath, title, text, textButton1, textButton2, showFloatContainer, handleGotIt, sendFunction, sendFunctionReviews} = props
  const [showReviewsModal, setShowReviewModal] = useState(false)
  const [showAlertSectionInModal, setShowAlertSectionInModal] = useState(false)

  const handleShowReviewsModal = () => {
    setShowReviewModal(true)
  }
  const handleCloseReviewsModal = () => {
    setShowReviewModal(false)
  }
  const  handleGoMarketing = () => {
    loadTestimonials(0, 0,null, true).then(({responseCode,responseMessage}) =>{
      if (responseCode === SUCCESSFUL_CODE) {
        browserHistory.push({pathname: `/main/setting/marketing`})
      setShowAlertSectionInModal(false)
      } else {
        console.log("ex ==> ", responseMessage)
        setShowAlertSectionInModal(true)
      }
    })

  }

  return (
    <div className="section-box">
      <div className="section-right">
        {
          showFloatContainer &&
          <div className="container-floating">
            <div className="container-title-coming-soon">
              <label className="title-coming-soon">{floatTitle ?? ''}</label>
            </div>
            <div className="container-icon-sub-title">
              <div className="container-sub-title">
                <p className="sub-title-coming-soon">
                  {floatText ?? ''}
                </p>
              </div>
            </div>
            <div className="container-link-text">
              <label className="title-link-text"
                     onClick={() => handleGotIt('')}>{floatTextButton ?? ''}</label>
            </div>
          </div>
        }
        <div className="image-container">
          <div className="no-reviews-yet">
            <img src={`${pathServer.PATH_IMG}${gifPath}`}/>
          </div>
          <div className="container-gif-description">
            <label className="title-gif-description">{title ?? ''}</label>
            <p className="text-gif-description">{text ?? ''}</p>
            <div className="buttons-container">
              <DPButtonIcon
                background={'#2194F1'}
                btnSaveText={textButton1}
                iconSendPath={'ic-facebook-homepage.svg'}
                iconSendPath1={'ic_google.svg'}
                iconSendPath2={'ic_arrow_right.svg'}
                borderColor={'transparent'}
                textColor={'#FFF'}
                buttonClass={'button-icon-first'}
                onClick={handleShowReviewsModal}
                hoverText={'Import now'}
                width={'170px'}
                height={'36px'}
            />
              <DPButtonIcon
                background={'#2194F1'}
                btnSaveText={textButton2}
                iconSendPath={'send_icon.svg'}
                iconSendPath2={'ic_arrow_right.svg'}
                borderColor={'transparent'}
                textColor={'#FFF'}
                buttonClass={'button-icon'}
                onClick={sendFunction}
                hoverText={'Send'}
                width={'115px'}
                height={'36px'}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showReviewsModal &&
        <Modal className="modal-reviews-container" show={showReviewsModal}>
          <Modal.Body>
            {
              <ReactSVG
                src={`${pathServer.PATH_IMG}icon/ic_close_circle_red.svg`}
                className="close-modal-icon"
                onClick={handleCloseReviewsModal}
              />
            }
            <div className="body-container-reviews">
              <div className="first-row-reviews">
                <div className="first-column-reviews">
                  <span className="title">Import Reviews</span>
                  <span className="description">In order to authorize the importing of your Facebook reviews please follow the steps bellow:</span>
                  <ol>
                    <li>Go to settings > Marketing</li>
                    <li>Select Facebook or Google</li>
                    <li>Click on the "Activate" button</li>
                    <li>Download reviews / recommendations</li>
                  </ol>
                  <DPButtonIcon
                    background={'#2194F1'}
                    btnSaveText={'Go to marketing'}
                    borderColor={'transparent'}
                    textColor={'#FFF'}
                    buttonClass={'button-icon'}
                    width={'131px'}
                    height={'31px'}
                    onClick={handleGoMarketing}
                  />
                </div>
                <div className="second-column-reviews">
                  <img src={`${pathServer.PATH_IMG}reviews_modal.png`}/>
                </div>
              </div>
              {
                showAlertSectionInModal &&
                <div className="second-row-reviews">
                <ReactSVG
                  src={`${pathServer.PATH_IMG}icon/ic_baseline_error.svg`}
                  className="alert-icon"
                />
                <span className="alert-description">We're sorry, you have not access to Marketing page. Please contact your admin to change your permissions.</span>
              </div>
              }
            </div>
          </Modal.Body>
        </Modal>
      }
    </div>
  )
}

export  default GifContainer