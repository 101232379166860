import React from 'react'
import DPLoader from './DPLoader'
import {pathServer} from '../../common/Constants'

require('./DPTable.scss')

export const DPAvatarImage = ({image, wrapperStyle, style}) =>
  <div className="dp-avatar-container-image" style={wrapperStyle}>
    <img style={style} src={image ? image : `${pathServer.PATH_IMG}ic_account_circle.png`}/>
  </div>

export const DPTableLogoImage = ({logoUrl}) =>
  <div className="dp-table-logo-image">
    <img className="logo" style={!logoUrl ? {padding: '8px'} : {padding: 'unset'}}
         src={logoUrl ? logoUrl : `${pathServer.PATH_IMG}ic_dealer.png`}/>
  </div>


export const DPTableActions = ({children}) =>
  <div className="dp-table-actions">
    {children}
  </div>


const DPTable = ({isLoading, data, config, onRowClick, renderColumn, rowTestId}) => {

  const {columns} = config

  return (
    <div className="dp-table-container">
      <DPLoader show={isLoading}/>
      <table className="table" style={{opacity: isLoading ? 0.4 : 1}}>
        <thead >
        <tr>
          {columns.map((column, index) => <th key={`th_${column.id}_${index}`} style={column.style}>{column.label}</th>)}
        </tr>
        </thead>
        <tbody>
        {
          (data.length > 0) ?
            data.map((item, rowIndex) => {
                return (
                  <tr key={`tr_${rowIndex}`} onClick={event => onRowClick && onRowClick(item, event)} data-testid={`${rowTestId}-${rowIndex}`}>
                    {
                      columns.map((column, colIndex) => <td key={`td_${column.id}_${rowIndex}_${colIndex}`}>{renderColumn(item, column, rowIndex, colIndex)}</td>)
                    }
                  </tr>
                )
              }
            ) : null
        }
        </tbody>
      </table>
    </div>
  )
}

export default DPTable
