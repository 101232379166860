import React, {Component} from 'react'
import {
  ABOUT_US_ICON_NAMES,
  mediaTypes
} from '../../../Constants'
import {ReactSVG} from 'react-svg'
import {cloneDeep, findIndex} from 'lodash'
import ReactPlayer from 'react-player'
import {scale} from '../../util/ImageUploaderUtils'
import toBlob from 'canvas-to-blob'
import {Popover} from '../../util/ComponentUtils'
import {getServerUrl} from '../../../Api'
import DPDropzone from '../../../common/DPDropzone'
import {getPosition} from '../../../../common/Util'
import {EXT_IMG, EXT_OTHER_IMG, EXT_PNG, pathServer} from '../../../../common/Constants'

const KEY_HEADER_TITLE                = "title";
const KEY_HEADER_DESCRIPTION          = "description";
const KEY_VALUE_ONE                   = "value1";
const KEY_VALUE_TWO                   = "value2";
const KEY_BUTTON_CHECK                = "showButton";
const KEY_ADDITIONAL_INFO_TITLE       = "titleAdditInfo";
const KEY_ADDITIONAL_INFO_DESCRIPTION = "despAdditInfo";
const NAME_IMAGE_ABOUT_US = "media1";

export  default class SettingAboutUs extends Component{
  constructor (){
    super ();
    this.onDrop      = this.onDrop.bind(this);
    this.uploadFile  = this.uploadFile.bind(this);
    this.pressEnter  = this.pressEnter.bind(this);
    this.onReady     = this.onReady.bind(this);
    this.onChangeInputVideo = this.onChangeInputVideo.bind(this);
    this.onChangeInput      = this.onChangeInput.bind(this);
    this.closePopoverIcons      = this.closePopoverIcons.bind(this);
    this.onDelete           = this.onDelete.bind(this);
    this.state = {
      selectedSection : '',
      urlVideo : '',
      videoUrl:null,
      indexVideoSelected:null,
      showIconsPopover: false,
      selectedValue: ""
    }
  }

  handleMenu(event,key,value){
    event.preventDefault();
    let selected = this.state[key];
    if(selected !== value)
      selected = value;
    else
      selected = "";

    this.setState({[key]:selected})
  }

  onChangeInput(event){
    const {pages, onChange,selectedPage, selectedSection} = this.props;
    let pagesNew = cloneDeep(pages);
    let pageAboutUs = pagesNew[selectedPage];
    let value;
    const key = event.target.id;

    if (key === KEY_HEADER_DESCRIPTION || key === KEY_HEADER_TITLE)
      pageAboutUs.headerText[key] = event.target.value;

    if (key === KEY_ADDITIONAL_INFO_DESCRIPTION || key === KEY_ADDITIONAL_INFO_TITLE){
      if(!pageAboutUs.additionalInformation){
        pageAboutUs["additionalInformation"] = {titleAdditInfo: "", despAdditInfo: ""}
      }
      pageAboutUs.additionalInformation[key] = event.target.value;
    }


    if (key === KEY_VALUE_ONE || key === KEY_VALUE_TWO) {
      pageAboutUs.values.forEach((value) => {
        if (value.id === key)
          value.displayValue = event.target.value;
      });
    }
    if(key === KEY_BUTTON_CHECK){
      value = pageAboutUs.showCustomerTestimonials;
      pageAboutUs.showCustomerTestimonials = !value;
    }
    onChange(pagesNew);
  }

  onChangeInputVideo(event){
    this.setState({urlVideo:event.target.value});
  }

  onDrop(files){
    this.uploadFile(files);
  }

  uploadFile(files,isScale){
    const {dealerMedias,filesToDealerMedia,uploadMediaFiles,dealerMediasToDelete} = this.props;
    let dealerMediasNew = cloneDeep(dealerMedias);
    let filesToDealerMediaNew = cloneDeep(filesToDealerMedia);
    let quantityMedia = dealerMediasNew.length;
    let order;

    files.forEach((file,index) => {
      if ((file.size)/(1000*1024) <= 5){
        scale(file, function(image,validHeight) {
          quantityMedia += 1;
          let blob = toBlob(image);
          blob.order = quantityMedia;
          blob.name = NAME_IMAGE_ABOUT_US;
          blob.preview = URL.createObjectURL(file);
          filesToDealerMediaNew.push(blob);
          dealerMediasNew.push({url: URL.createObjectURL(file), mediaType: mediaTypes.IMAGE, order: order, isNew: true});
        });
      }
    });

    setTimeout(() => {
      uploadMediaFiles(dealerMediasNew, filesToDealerMediaNew, dealerMediasToDelete);
    },500);
  }

  pressEnter(event) {
    const {dealerMedias, uploadMediaFiles, filesToDealerMedia, dealerMediasToDelete} = this.props;
    let dealerMediasNew = cloneDeep(dealerMedias);
    const quantityMedia = dealerMediasNew.length;

    if ((event.which === 13 || event.keyCode === 13) && !event.shiftKey) {
      event.preventDefault();
      if(dealerMediasNew.length < 8){
        dealerMediasNew.push({url: event.target.value, mediaType: mediaTypes.VIDEO,order:(quantityMedia+1),isNew:true});
        uploadMediaFiles(dealerMediasNew, filesToDealerMedia, dealerMediasToDelete);
        this.setState({urlVideo:""});
      }
    }
  }

  onReady(media,index){
    let {videoUrl,indexVideoSelected} = this.state;
    if(media.mediaType === mediaTypes.VIDEO){
      indexVideoSelected = index;
      const playerPreview = document.getElementsByClassName("react-player__preview");
      if(playerPreview && playerPreview[index]){
        const backgroundImage = playerPreview[index].style.backgroundImage;
        const split = backgroundImage.split('"');
        videoUrl = split[1];
      }
    }else{
      videoUrl = null;
      indexVideoSelected = null;
    }

    this.setState({videoUrl,indexVideoSelected})
  }

  openPopoverIcons(id, value){
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 20,
      offsetWidth: 180
    };
    this.setState({showIconsPopover: true, anchorEl, selectedValue: value})
  }

  selectIcon(iconName, event){
    event.preventDefault();
    const {pages, onChange,selectedPage} = this.props;
    const {selectedValue} = this.state;

    let pagesNew = cloneDeep(pages);
    let pageAboutUs = pagesNew[selectedPage];

    pageAboutUs.values.forEach((value) => {
      if (value.id === selectedValue)
        value.icon = pathServer.PATH_IMG+"icon/"+iconName;
    });
    onChange(pagesNew);
  }

  closePopoverIcons(){
    this.setState({showIconsPopover: false})
  }

  renderSectionByPage(pageAboutUs,id){
    const {dealerMedias} = this.props;
    const {urlVideo,videoUrl,indexVideoSelected,showIconsPopover, anchorEl} = this.state;
    let sec = null;
    if(id === "headerText"){
      const {headerText} = pageAboutUs;
      sec = (
        <div className="body-sec-details">
          <div className="row-sec-info">
            <div className="row-sec-details">
              <label className="label-sec">TITLE</label>
              <input id={KEY_HEADER_TITLE} className="form-control" value={headerText.title} onChange={this.onChangeInput}/>
            </div>
            <div className="row-sec-details">
              <label className="label-sec">TEXT</label>
              <textarea id={KEY_HEADER_DESCRIPTION} className="form-control"  maxLength={500} rows={9} value={headerText.description} onChange={this.onChangeInput}/>
              <label className="label-sec align-right">500 Characters max</label>
            </div>
          </div>
        </div>
      )
    }else if (id === "values"){
      const {values} = pageAboutUs;

      let popoverIcons = (
        <div className="about-us-icons-container">
          {
            ABOUT_US_ICON_NAMES.map((row,indexRow) => {
              return (
                <div key={indexRow} className="icons-row-container">
                  {
                    row.map((icon,indexIcon) => {
                      return(
                        <div key={indexIcon} className="about-us-icon" onClick={this.selectIcon.bind(this,icon)}>
                          <ReactSVG src={pathServer.PATH_IMG + "icon/" + icon} className="icon-about-us" beforeInjection={ svg => svg.classList.add('icon-about-us-svg')}/>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      );

      sec = (
        <div className="body-sec-details input-values">
          <div className="container-value-header">
            <label className="label-sec label-icon">ICON</label>
            <label className="label-sec" >VALUE</label>
          </div>
          <div className="containers-values">
            {
              values.map((value,index) => {
                return(
                  <div key={index} className="row-value">
                    <div id={"icon-select-" + index} className="section-icon" onClick={this.openPopoverIcons.bind(this,"icon-select-" + index, value.id)}>
                      <ReactSVG src={value.icon} className="icon-menu-content" beforeInjection={ svg => svg.classList.add('icon-menu')}/>
                      <i className="fa fa-chevron-down" />
                    </div>
                    <div className="section-value">
                      <input key={index} id={value.id} className="form-control" value={value.displayValue} onChange={this.onChangeInput}/>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {
            showIconsPopover ?
              <Popover anchorEl={anchorEl}
                       child={popoverIcons}
                       classPopover="popover-box-category"
                       handleRequestClose={this.closePopoverIcons}
              />
              : null
          }
        </div>
      )
    }else if (id === "additionalInformation"){
      const {additionalInformation} = pageAboutUs;
      sec = (
        <div className="body-sec-details">
          <div className="row-sec-info">
            <div className="row-sec-details">
              <label className="label-sec">TITLE</label>
              <input id={KEY_ADDITIONAL_INFO_TITLE} className="form-control" value={additionalInformation ? additionalInformation.titleAdditInfo : ""}
                     onChange={this.onChangeInput}/>
            </div>
            <div className="row-sec-details">
              <label className="label-sec">TEXT</label>
              <textarea id={KEY_ADDITIONAL_INFO_DESCRIPTION} className="form-control"  maxLength={500} rows={9} value={additionalInformation ? additionalInformation.despAdditInfo : ""}
                        onChange={this.onChangeInput}/>
              <label className="label-sec align-right">500 Characters max</label>
            </div>
          </div>
        </div>
      )
    }else if (id === "media"){
      let indexVideo = -1;
      sec = (
        <div className="body-sec-media">
          <div className="container-url-video">
            <label className="label-sec">Paste a video link to add a video and click Enter</label>
            <input id="urlVideo" type="text" onKeyPress={this.pressEnter} className="input-url" value={urlVideo}
                   onChange={this.onChangeInputVideo}/>
          </div>
          <div className="uploaded-images">
            {
              dealerMedias.map((media,index) =>{
                if(media.mediaType === mediaTypes.VIDEO)
                  indexVideo += 1;
                return(
                  <div key = {index} className="current-image-container" onClick={this.onReady.bind(this,media,indexVideo)}>
                    {
                      (media.mediaType === mediaTypes.VIDEO) ?
                        (videoUrl && indexVideo === indexVideoSelected) ?
                          <img key={index} src={videoUrl} className="current-image"/> :
                          <ReactPlayer url={media.url}
                                       width="100%"
                                       height="100%"
                                       light={true}
                                       playing={false} />
                        :
                        <img key={index} src={media.url} className="current-image"/>
                    }
                    <div className="icon-delete-setting">
                      <div className="row-delete">
                        <div className="icon-content-delete-setting">
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="cloud-delete-content"
                                    beforeInjection={ svg => svg.classList.add('cloud-delete')} onClick={() => this.onDelete(media.id,media.order)}/>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {
              dealerMedias.length < 8 ?
                <DPDropzone
                  onDrop={(file) => this.onDrop(file)}
                  extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                  dataTestInput={'dealer-media'}
                >
                  <>
                    <img
                      className="cloud-upload"
                      src={`${pathServer.PATH_IMG}ic_add_photo.png`}
                    />
                    <label className="label-upload-logo">Upload or drag here a new image</label>
                  </>
                </DPDropzone> : null
            }
          </div>
          <label  id ="labelFilesMax" className="label-sec" style={{color: (dealerMedias.length >7) ? "red" :"#A4AFB7"}}>8 files max</label>
        </div>
      )
    }

    return sec;
  }

  cleanValue(event,key){
    const {pages, onChange,selectedPage} = this.props;
    let pagesNew = cloneDeep(pages);
    let pageAboutUs = pagesNew[selectedPage];
    if (key === "headerText"){
      for(let key in pageAboutUs.headerText){
        pageAboutUs.headerText[key] = "";
      }
    } else if (key === "values") {
      pageAboutUs.values.forEach((value) => {
        value.displayValue = "";
      });
    } else if(key === "additionalInformation"){
      for(let key in pageAboutUs.additionalInformation){
        pageAboutUs.additionalInformation[key] = "";
      }
    }

    onChange(pagesNew);
  }

  onDelete(id,order){
    const {dealerMedias, filesToDealerMedia ,uploadMediaFiles, dealerMediasToDelete} = this.props;
    let dealerMediasNew = cloneDeep(dealerMedias);
    let filesToDealerMediaNew = cloneDeep(filesToDealerMedia);
    let dealerMediasToDeleteNew = cloneDeep(dealerMediasToDelete);
    let indexFoundToMedia = -1;

    if(id){
      indexFoundToMedia = findIndex(dealerMediasNew, function(media) { return media.id === id});
    }

    if (indexFoundToMedia !== -1) {
      dealerMedias.forEach((media, i) => {
        if (i === indexFoundToMedia){
          dealerMediasNew.splice(i,1);
          dealerMediasToDeleteNew.push(media.id);
        }
      });
    } else {
      indexFoundToMedia = findIndex(dealerMediasNew, function(media) { return media.order === order});
      dealerMedias.forEach((media, i) => {
        if(i === indexFoundToMedia){
          if(media.mediaType === mediaTypes.IMAGE){
            filesToDealerMediaNew.forEach((file, j) => {
              if (file.preview === media.url)
                filesToDealerMediaNew.splice(j,1);
            });
          }
          dealerMediasNew.splice(i,1);
        }
      });
    }

    dealerMediasNew.forEach((media,i) => {
      let order = i + 1;
      media.order = order;
      if(media.mediaType === mediaTypes.IMAGE){
        filesToDealerMediaNew.forEach((file, j) => {
          if (file.preview === media.url){
            file.order = order;
            file.name = NAME_IMAGE_ABOUT_US;
          }
        });
      }
    });

    uploadMediaFiles(dealerMediasNew, filesToDealerMediaNew,dealerMediasToDeleteNew);
  }

  goToTestimonials = () => {
    window.open(`${getServerUrl()}main/marketing/testimonials`, '_blank')
    //browserHistory.push({pathname: `/main/marketing/testimonials`});
  }

  render () {
    let {selectedSection} = this.state;
    const {pages,selectedPage} = this.props;
    const pageAboutUs = pages[selectedPage];
    const {showCustomerTestimonials} = pageAboutUs;
    return (
      <div className="about-us-section">
        <div className="row-sec" >
          <div id="headerText" className="btn-message-dp">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_menu.svg"} className="icon-menu-content"
                      beforeInjection={ svg => svg.classList.add('icon-menu')} />
            <div className="col-sec">
              <label className="label-sec">Header Text</label>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_eraser.svg"} className="icon-action-content-delete"
                        beforeInjection={ svg => svg.classList.add('icon-action')} onClick={(event)=> this.cleanValue(event,"headerText")}/>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"} onClick={(event)=> this.handleMenu(event,"selectedSection","headerText")}
                        className="icon-action-content" beforeInjection={ svg => svg.classList.add('icon-action')}/>
            </div>
          </div>
          {("headerText" === selectedSection) ? this.renderSectionByPage(pageAboutUs,selectedSection) : null}
        </div>
        <div className="row-sec" >
          <div id="values" className="btn-message-dp">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_menu.svg"} className="icon-menu-content"
                      beforeInjection={ svg => svg.classList.add('icon-menu')}/>
            <div className="col-sec">
              <label className="label-sec">Values</label>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_eraser.svg"} className="icon-action-content-delete"
                        beforeInjection={ svg => svg.classList.add('icon-action')} onClick={(event)=> this.cleanValue(event,"values")}/>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"} className="icon-action-content"
                        beforeInjection={ svg => svg.classList.add('icon-action')} onClick={(event)=> this.handleMenu(event,"selectedSection","values")}/>
            </div>
          </div>
          {("values" === selectedSection) ? this.renderSectionByPage(pageAboutUs,selectedSection) : null}
        </div>
        <div  className="row-sec">
          <div id="additionalInformation" className="btn-message-dp">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_menu.svg"} className="icon-menu-content"
                      beforeInjection={ svg => svg.classList.add('icon-menu')}/>
            <div className="col-sec">
              <label className="label-sec">Additional Information</label>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_eraser.svg"} className="icon-action-content-delete"
                        beforeInjection={ svg => svg.classList.add('icon-action')} onClick={(event)=> this.cleanValue(event,"additionalInformation")}/>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"} className="icon-action-content"
                        beforeInjection={ svg => svg.classList.add('icon-action')} onClick={(event)=> this.handleMenu(event,"selectedSection","additionalInformation")}/>
            </div>
          </div>
          {("additionalInformation" === selectedSection) ? this.renderSectionByPage(pageAboutUs,selectedSection) : null}
        </div>
        <div  className="row-sec" >
          <div id="media" className="btn-message-dp">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_menu.svg"} className="icon-menu-content"
                      beforeInjection={ svg => svg.classList.add('icon-menu')}/>
            <div className="col-sec">
              <label className="label-sec">Media</label>

              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"} className="icon-action-content"
                        beforeInjection={ svg => svg.classList.add('icon-action')} onClick={(event)=> this.handleMenu(event,"selectedSection","media")}/>
            </div>
          </div>
          {("media" === selectedSection) ? this.renderSectionByPage(pageAboutUs,selectedSection) : null}
        </div>
        <div className="row-button">
          <label className="label-sec">Show Customer Testimonies</label>
          <div className="sec-switch">
            <label className="switch">
              <input id={KEY_BUTTON_CHECK} type="checkbox" checked={showCustomerTestimonials} onChange={this.onChangeInput} />
              <span className={showCustomerTestimonials ? "slider round" : "slider round off"} />
              {
                showCustomerTestimonials ?
                  <span className="check-label-on">ON</span> :
                  <span className="check-label-off">OFF</span>
              }
            </label>
          </div>
        </div>
        <div className="container-icon-body-title">
          <div className="container-icon-exclamation">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"}
                      beforeInjection={svg => svg.classList.add("svg-icon-error")}
                      className="icon-error"/>
          </div>
          <div className="container-message">
            <label className="title">Please click <label className="title-click" onClick={this.goToTestimonials}>here &nbsp;</label>
             or go to the testimonial page to choose testimonials being displayed</label>
          </div>
        </div>
      </div>
    )
  }
}