import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {
  SALE_FILE_DOES_NOT_EXIST,
  TEMPLATE_CODE_TAX_EXEMPTION,
  TAB_CHECKLIST,
  TAB_FILES
} from '../../Constants'
import {cloneDeep, findIndex} from 'lodash'
import DynamicNumber from 'react-dynamic-number'
import isEmpty from 'lodash/isEmpty'
import {getServerUrl, loadSalesFileById, saveKeywordValues} from '../../Api'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import Loader from '../../../common/UI/Loader.jsx'
import {checkValidZipCode} from '../../../common/ValidationUtil'
import {validateError} from '../util/Utils'
import { pathServer, SUCCESSFUL_CODE } from '../../../common/Constants'

const message = "This Sales tax Exemption may be allowed only if all names and addresses are complete and this form is signed";

const InputSection = ({classColumn, title, id, type, value, onChange, section, disable, error, isRequired, maxLength}) => {
  return (
    <div className={classColumn}>
      <div className="info-label">
        <label className="title">{title}</label>
        {isRequired ? <span className="label-error">*</span> : null}
      </div>
      <div className="classInput">
        <input id={id} type={type} maxLength={maxLength ? maxLength : null}
               className={error ? "form-control has-error" : "form-control"}
               value={value} onChange={(evt) => onChange ? onChange(evt, section) : null}
               disabled={disable ? disable : false}
        />
        {error ?
          <p className="help-block">{getErrorMessage(title)}</p> : null}
      </div>
    </div>
  )
};

const DynamicNumberElement = ({
                                classColumn, title, id, type, value, onChange, section, error,
                                isRequired, maxLength, thousand, integer, separator, fraction, placeHolder
                              }) => {

  return (
    <div className={classColumn}>
      {
        title ?
          <div className="info-label">
            <label className="title">{title}</label>
            {isRequired ? <span className="label-error">*</span> : null}
          </div> : null
      }
      <div className="inputSale">
        <DynamicNumber id={id}
                       className={error ? "form-control has-error" : "form-control"}
                       placeholder={placeHolder ? placeHolder : ""}
                       value={parseFloat(value)}
                       positive={true}
                       negative={false}
                       thousand={thousand}
                       onChange={(evt, modelValue, viewValue) => onChange({
                         section: section,
                       }, evt, modelValue, viewValue)}
                       separator={separator}
                       integer={integer}
                       fraction={fraction}
                       maxLength={maxLength}
                       disabled={false}/>
        {error ?
          <p className="help-block">{getErrorMessage(title)}</p> : null}
      </div>
    </div>
  )
};

function getErrorMessage(name) {
  return name + ' is required'
}

function getValueByKey(keywords, key) {
  const field = keywords.find(element => {
    return element.key === key
  });
  if (field)
    return field.value;
  else
    return ""
}

function validateZipCode(value) {
  return isEmpty(value) || !checkValidZipCode(value)
}

function validateValue(value) {
  return isEmpty(value) && (value === "")
}

function validateYear(value) {
  return isEmpty(value.toString()) || !(value.toString().length === 4)
}

const fieldsRequired = [{key: "dealerName", type: "required"}, {key: "dealerAddress", type: "required"},
  {key: "dealerCity", type: "required"}, {key: "dealerZipCode", type: "required"},
  {key: "dealerState", type: "required"}, {key: "purchaserName", type: "required"},
  {key: "purchaserAddress", type: "required"}, {key: "purchaserCity", type: "required"},
  {key: "purchaserState", type: "required"}, {key: "purchaserZipCode", type: "required"},
  {key: "carType", type: "required"}, {key: "carYear", type: "required"},
  {key: "carMake", type: "required"}, {key: "carModel", type: "required"},
  {key: "carStyle", type: "required"}, {key: "carVin", type: "required"}];

export default class CustomerTaxExemption extends Component {

  constructor() {
    super();
    this.inputChangeTaxForm = this.inputChangeTaxForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveSale = this.saveSale.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.printPDF = this.printPDF.bind(this);

    this.state = {
      data: null,
      errors: {},
      showModalConfirm: false,
      hasChange: false,
      fileTemplateId: null,
      saleFileId: null,
      isLoadingInitial: false,
      isLoading: false
    }
  }

  componentWillMount() {
    const {saleId} = this.props;
    this.setState({isLoadingInitial: true});
    loadSalesFileById(saleId, TEMPLATE_CODE_TAX_EXEMPTION).then((response) => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        let data = JSON.parse(response.data.sale.keywordsValues);
        if (response.data.code && response.data.code === SALE_FILE_DOES_NOT_EXIST) {
          this.setState({data, fileTemplateId: response.data.sale.id, isLoadingInitial: false});
        } else
          this.setState({data, saleFileId: response.data.sale.id, isLoadingInitial: false});
      }else{
        console.error(responseMessage);
        this.setState({isLoadingInitial: false});
      }
    })
  }

  inputChangeTaxForm(evt, section, hasChange) {
    let key = evt.target.id;
    let value = evt.target.value;
    const {errors, data} = this.state;
    let {handleChanges} = this.props;
    let newData = cloneDeep(data);
    let index = findIndex(newData[section].keywords, function (field) {
      return field.key === key;
    });
    if (index !== -1) {
      newData[section].keywords[index].value = value;
      errors[key] = false;
    }
    this.setState({data: newData, hasChange: true, errors})
    handleChanges(true);
  }

  onChangeDynamicNumber(dates, evt, modelValue, viewValue) {
    const {section} = dates;
    let key = evt.target.id;
    let value = modelValue;
    let {data, errors} = this.state;
    let {handleChanges} = this.props;
    let newData = cloneDeep(data);
    let index = findIndex(newData[section].keywords, function (field) {
      return field.key === key;
    });
    if (index !== -1) {
      newData[section].keywords[index].value = value;
      errors[key] = false;
    }
    this.setState({data: newData, hasChange: true})
    handleChanges(true);
  }

  validInputsRequired(key) {
    const {data} = this.state;
    const {car, purchaser, dealer} = data;
    switch (key) {
      case "dealerName":
      case "dealerAddress":
      case "dealerCity":
      case "dealerState":
        return validateValue(getValueByKey(dealer.keywords, key));
      case "purchaserName":
      case "purchaserAddress":
      case "purchaserCity":
      case "purchaserState":
        return validateValue(getValueByKey(purchaser.keywords, key));
      case "purchaserZipCode":
        return validateZipCode(getValueByKey(purchaser.keywords, key));
      case "dealerZipCode":
        return validateZipCode(getValueByKey(dealer.keywords, key));
      case "carType":
      case "carMake":
      case "carModel":
      case "carStyle":
      case "carVin":
        return validateValue(getValueByKey(car.keywords, key));
      case "carYear":
        return validateYear(getValueByKey(car.keywords, key));
    }
  }

  saveAndContinue() {
    this.saveSale("saveAndContinue");
  }

  saveAndBack(){
    this.saveSale("saveAndBack");
  }

  printPDF() {
    this.saveSale("printPDF");
  }

  saveSale(type) {
    const {errors, saleFileId, data, fileTemplateId, showModalConfirm} = this.state;
    let {goToCheckList,goToSaleFiles,handleChanges} = this.props;
    let error = {};
    let hasError = false;

    if (type && type === 'printPDF' && !showModalConfirm) {
      error = this.getError(errors);
      hasError = validateError(error);
    }

    if (!hasError) {
      let {saleId, customerId, showSuccessfulMessage} = this.props;
      let body = {keywordValues: JSON.stringify(data)};
      if (saleFileId)
        body["saleFileId"] = saleFileId;
      if (fileTemplateId)
        body["fileTemplateId"] = fileTemplateId;
      this.setState({isLoading: true});
      saveKeywordValues(saleId, body).then((response) => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({
            data, saleFileId: response.data.saleFileId, fileTemplateId: null, showModalConfirm: false,
            errors: error, isLoading: false,hasChange: false
          });
          showSuccessfulMessage();
          handleChanges(false);
          switch (type) {
            case "printPDF":
              window.open(`${getServerUrl()}api/sales/${saleId}/files/${response.data.saleFileId}/generate`, '_blank');
              break;
            case "saveAndContinue":
              goToCheckList(TAB_CHECKLIST);
              break;
            case "saveAndBack":
              goToSaleFiles(TAB_FILES);
              break;
          }
        }else{
          console.log("error: ", responseMessage);
          this.setState({isLoading: false});
        }
      })
    } else {
      let showModalConfirm = false;
      if (type === "printPDF")
        showModalConfirm = true;
      this.setState({errors: error, showModalConfirm: showModalConfirm});
    }
  }

  closeModal() {
    this.setState({showModalConfirm: false});
  }

  getError(errors) {
    fieldsRequired.forEach(field => {
      const {key} = field;
      errors[key] = this.validInputsRequired(key)
    });
    return errors
  }

  render() {
    const {data, showModalConfirm, errors, isLoadingInitial, isLoading, hasChange} = this.state;
    if (!data) return null;
    const {purchaser, dealer, car} = data;
    return (
      <div className="container-general-tax">
        {
          isLoadingInitial ?
            <div className="loader-container">
              <div className="loader"/>
            </div> :
            <div className="box-tax-exemption">
              {
                isLoading ?
                  <Loader/> : null
              }
              <div className="section-row-message">
                <div className="container-row-message">
                  <div className="container-icon-body-title">
                    <div className="container-icon-exclamation">
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                                className="icon-error"/>
                    </div>
                    <div className="container-message">
                      <label className="title-message">{message}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-buttons">
                {
                  hasChange ?
                    <button className="save" onClick={this.saveSale}>
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_save.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-save')}
                                className="icon-save"/>
                    </button> :
                    <div className="check-ark">
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_checking_mark_circle.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-check')}
                                className="icon-check"/>
                    </div>

                }
                <button className="print" onClick={this.printPDF}>
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_print_white.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-print')}
                            className="icon-print"/>
                </button>
              </div>
              <div className="container-tax-exemption">
                <div className="first-row">
                  <InputSection classColumn="col23" title="Type" id="carType" type="text"
                                value={getValueByKey(car.keywords, 'carType')} onChange={this.inputChangeTaxForm}
                                section="car" error={errors["carType"]} isRequired={true}/>
                  <DynamicNumberElement classColumn={"col11"} title="Year" id="carYear" type="text"
                                        value={getValueByKey(car.keywords, 'carYear')} section="car" isRequired={true}
                                        onChange={this.onChangeDynamicNumber.bind(this)} separator={","}
                                        placeHolder={"1992"}
                                        thousand={false} integer={4} fraction={0} maxLength={4}
                                        error={errors["carYear"]}/>
                  <InputSection classColumn="col12" title="Make" id="carMake" type="text"
                                value={getValueByKey(car.keywords, 'carMake')} onChange={this.inputChangeTaxForm}
                                section="car" error={errors["carMake"]} isRequired={true}/>
                  <InputSection classColumn="col12" title="Model" id="carModel" type="text"
                                value={getValueByKey(car.keywords, 'carModel')} onChange={this.inputChangeTaxForm}
                                section="car" error={errors["carModel"]} isRequired={true}/>
                  <InputSection classColumn="col12" title="Style" id="carStyle" type="text"
                                value={getValueByKey(car.keywords, 'carStyle')} onChange={this.inputChangeTaxForm}
                                section="car" error={errors["carStyle"]} isRequired={true}/>
                  <InputSection classColumn="col23" title="Identification number" id="carVin" type="text"
                                value={getValueByKey(car.keywords, 'carVin')} onChange={this.inputChangeTaxForm}
                                section="car" error={errors["carVin"]} isRequired={true} maxLength={17}/>
                </div>
                <div className="second-row">
                  <div className="container-info-purchase">
                    <label className="title-info">Purchaser</label>
                    <InputSection classColumn="col100" title="Name" id="purchaserName" type="text"
                                  value={getValueByKey(purchaser.keywords, 'purchaserName')}
                                  onChange={this.inputChangeTaxForm}
                                  section="purchaser" error={errors["purchaserName"]} isRequired={true}/>
                    <InputSection classColumn="col100" title="Address" id="purchaserAddress" type="text"
                                  value={getValueByKey(purchaser.keywords, 'purchaserAddress')}
                                  onChange={this.inputChangeTaxForm}
                                  section="purchaser" error={errors["purchaserAddress"]} isRequired={true}/>
                    <div className="col100">
                      <InputSection classColumn="col33" title="City" id="purchaserCity" type="text"
                                    value={getValueByKey(purchaser.keywords, 'purchaserCity')}
                                    onChange={this.inputChangeTaxForm}
                                    section="purchaser" error={errors["purchaserCity"]} isRequired={true}/>
                      <InputSection classColumn="col33" title="State" id="purchaserState" type="text"
                                    value={getValueByKey(purchaser.keywords, 'purchaserState')}
                                    onChange={this.inputChangeTaxForm}
                                    section="purchaser" error={errors["purchaserState"]} isRequired={true}/>
                      <InputSection classColumn="col33" title="ZIP Code" id="purchaserZipCode" type="text"
                                    value={getValueByKey(purchaser.keywords, 'purchaserZipCode')}
                                    onChange={this.inputChangeTaxForm}
                                    section="purchaser" error={errors["purchaserZipCode"]} isRequired={true}
                                    maxLength={5}/>

                    </div>
                  </div>
                  <div className="container-info-dealer">
                    <label className="title-info">Dealer</label>
                    <InputSection classColumn="col100" title="Name" id="dealerName" type="text"
                                  value={getValueByKey(dealer.keywords, 'dealerName')}
                                  onChange={this.inputChangeTaxForm}
                                  section="dealer" error={errors["dealerName"]} isRequired={true}/>
                    <InputSection classColumn="col100" title="Address" id="dealerAddress" type="text"
                                  value={getValueByKey(dealer.keywords, 'dealerAddress')}
                                  onChange={this.inputChangeTaxForm}
                                  section="dealer" error={errors["dealerAddress"]} isRequired={true}/>
                    <div className="col100">
                      <InputSection classColumn="col33" title="City" id="dealerCity" type="text"
                                    value={getValueByKey(dealer.keywords, 'dealerCity')}
                                    onChange={this.inputChangeTaxForm}
                                    section="dealer" error={errors["dealerCity"]} isRequired={true}/>
                      <InputSection classColumn="col33" title="State" id="dealerState" type="text"
                                    value={getValueByKey(dealer.keywords, 'dealerState')}
                                    onChange={this.inputChangeTaxForm}
                                    section="dealer" error={errors["dealerState"]} isRequired={true}/>
                      <InputSection classColumn="col33" title="ZIP Code" id="dealerZipCode" type="text"
                                    value={getValueByKey(dealer.keywords, 'dealerZipCode')}
                                    onChange={this.inputChangeTaxForm}
                                    section="dealer" error={errors["dealerZipCode"]} isRequired={true} maxLength={5}/>

                    </div>
                  </div>
                </div>
                <div className="third-row">
                  <div className="container-instructions">
                    <p>This exemption is only valid if the vehicle is not registered in Utah and is either:</p>
                    <p className="second-paragraph">1. not used in Utah, or</p>
                    <p className="second-paragraph">2. used in Utah, if the vehicle Is used for</p>
                    <p className="third-paragraph">a. non-business purposes, for a period not to exceed 30 days in a
                      calendar year; or</p>
                    <p className="third-paragraph">b. business purposes, for the time period needed to transport it to
                      the borders</p>
                  </div>
                </div>
              </div>
            </div>
        }
        {
          showModalConfirm ?
            <GenericModalConfirm show={showModalConfirm}
                                 onHide={this.closeModal}
                                 save={this.printPDF}
                                 message={"Not all mandatory fields have been filled. Are you sure you want to print?"}
                                 showButtonCancel={true}
                                 buttonLabelSave={"Yes, Print it"}
                                 buttonLabelCancel={"Not yet"}
                                 showCustomIcon={true}
            /> : null
        }
      </div>
    )
  }

}