import React, {Component} from 'react'
import {
  CUSTOMERS_BY_PAGE,
  CUSTOMERS_BY_PAGE_BY_DEFAULT,
  NUMBER_PAGES_SHOWN,
  ROWS_IMPORT_BY_PAGE_BY_DEFAULT,
  ALPHABETICAL_ORDER,
  CLEAR_ALL, importKey, entityTypes, ATTRIBUTE_CUSTOMER_EMAIL, siteColorAdmin, FORM_CUSTOMER_REVIEW
} from '../../Constants'
import CustomerHeader from './CustomerHeader.jsx'
import {ReactSVG} from 'react-svg'
import moment from 'moment/moment'
import {DPModalInfo, Popover} from '../util/ComponentUtils'
import {disableCustomerApi, exportSortedCustomers} from '../../Api'
import {browserHistory} from 'react-router'
import ModalConfirmDelete from '../util/ModalConfirmDelete.jsx'
import {isEmpty} from 'lodash'
import ModalUploadFile from '../util/ModalUploadFile'
import ModalViewDataImport from '../util/ModalViewDataImport'
import {getPosition} from '../../../common/Util'
import {
  pathServer,
  SUCCESSFUL_CODE,
  WIDTH_VIEW_MOBILE,
  WIDTH_VIEW_TABLET,
} from '../../../common/Constants'
import {calculateHeightDifference} from '../util/Utils'
import {DPPagination} from '../../../common/DPPagination'

const ModalCustomerDetails = ({customer,goToCustomer}) => {
  const {image, firstName, middleName, lastName, email, phone, address, state, zip, createDate,secondPhone, id} = customer;
  const date = moment(new Date(createDate)).format('LL');
  const name = !isEmpty(middleName) ? `${firstName} ${middleName}. ${lastName}` : `${firstName} ${lastName}`;

  let phoneValue;
  if(!isEmpty(phone)){
    phoneValue = phone;
  }else if(!isEmpty(secondPhone)){
    phoneValue = secondPhone;
  }else {
    phoneValue = getMessageNotField("phone")
  }

  return (
    <div className="customer-modal-body">
      <div className="customer-image">
        <img src={image ? image : pathServer.PATH_IMG + "ic_account_circle.png"}/>
      </div>
      <div className="customer-name">
        <label>{name}</label>
      </div>
      <div className="customer-info">
        <label>{!isEmpty(email) ? email : getMessageNotField("email")}</label>
        <label>{phoneValue}</label>
        <label>{getAddress(address,state,zip)}</label>
        <label>{`Customer since ${date}`}</label>
      </div>
      <div className="container-button-edit">
        <button className="btn-edit-customer" onClick={(evt) => goToCustomer(id,evt)} data-test={"btn-edit-customer"}>Edit</button>
      </div>
    </div>
  )
};

function getMessageNotField(field) {
  return <p style={{fontStyle:"italic"}}>{`No ${field} yet`}</p>
}

function getAddress(address,state,zip) {
  let addressValue = "";
  if(!isEmpty(address) || !isEmpty(state) || !isEmpty(zip)){
    if(!isEmpty(address))
      addressValue += address;

    if(!isEmpty(addressValue)){
      if(!isEmpty(state))
        addressValue += ", "+state;
    }else{
      addressValue += state;
    }

    if(!isEmpty(addressValue)){
      if(!isEmpty(zip))
        addressValue += ", "+zip;
    }else{
      addressValue += zip;
    }
  }else {
    addressValue = getMessageNotField("address")
  }
  return addressValue
}

const optionsOrder = [
  {id: "clear", value: "clear", displayValue: CLEAR_ALL},
  {id: "sortAsc",value: "sortAsc",displayValue: ALPHABETICAL_ORDER.ASC},
  {id: "sortDesc",value: "sortDesc", displayValue: ALPHABETICAL_ORDER.DESC},
];

export default class Customer extends Component {

  constructor() {
    super();
    this.handlePagination       = this.handlePagination.bind(this);
    this.closeModal             = this.closeModal.bind(this);
    this.handleRequestOpen      = this.handleRequestOpen.bind(this);
    this.handlePageChange       = this.handlePageChange.bind(this);
    this.handleItemsByPage      = this.handleItemsByPage.bind(this);
    this.delete                 = this.delete.bind(this);
    this.loadCustomers          = this.loadCustomers.bind(this);
    this.onChangeDropDown       = this.onChangeDropDown.bind(this);
    this.setSortInitial         = this.setSortInitial.bind(this);
    this.renderDropDownFilter   = this.renderDropDownFilter.bind(this);
    this.onChangeSort           = this.onChangeSort.bind(this);
    this.handleResize           = this.handleResize.bind(this);
    this.handleSelectCustomer   = this.handleSelectCustomer.bind(this);
    this.goToCustomer           = this.goToCustomer.bind(this);
    this.handleHeightDifference = this.handleHeightDifference.bind(this);
    this.showModalDeleteCustomer =this.showModalDeleteCustomer.bind(this);
    this.state = {
      numberPagesShown: NUMBER_PAGES_SHOWN,
      selectedAll: false,
      idsSelected: {},
      currentPageCheckAll: {},
      imageAdd: pathServer.PATH_IMG + "add_1.png",
      options: [
        {value: 'option1', label: 'Delete'},
        {value: 'option2', label: 'Update'},
      ],
      customersAux: [],
      countMessageSelected: null,
      showModal: false,
      customerSelected: null,
      showOptions: false,
      itemsByPageQuantity: CUSTOMERS_BY_PAGE_BY_DEFAULT,
      currentPage: 1,
      showPopover: false,
      isLoading: false,
      showModalDelete:false,
      customerIdSelected:null,
      dropDownOrder: false,
      listName: {
        id: null,
        displayValue: "Sort By"
      },
      filterSelected: null,
      widthPage: window.innerWidth,
      heightDifference: 120,
      showModalImport: false,
      showModalData: false
    };
  }

  componentWillMount() {
    this.loadCustomers(this.props);
    this.handleHeightDifference();
  }

  handleHeightDifference(){
    const { widthPage } = this.state;
    let heightDifference = calculateHeightDifference(56,32);
    if( widthPage <= WIDTH_VIEW_TABLET && widthPage > WIDTH_VIEW_MOBILE){
      heightDifference = calculateHeightDifference(56,20);
    }else if(widthPage <= WIDTH_VIEW_MOBILE){
      heightDifference = calculateHeightDifference(56,10);
    }
    this.setState({heightDifference})
  }

  componentWillReceiveProps(nextProps) {
    this.loadCustomers(nextProps);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    this.props.setKeyword("");
    window.removeEventListener('resize', this.handleResize);
  }

  loadCustomers(props) {
    const {customersQuantity, customers, totalPages} = props;
    //let salesmanId = null
    // if(users && users.length > 0){
    //  const userCreated = users.filter(user => user.email === email);
      //salesmanId = userCreated[0].id;
    //}
    this.setState({customersQuantity, customers, totalPages}, () => {
      this.setSortInitial();
    });
  }

  handleResize() {
    this.setState({widthPage: window.innerWidth}, () =>{this.handleHeightDifference()});
  }

  handlePagination(number) {
    this.setState({currentPage: number})
  }

  closeModal() {
    this.setState({showModal: false, customerSelected: null, showModalDelete:false,customerIdSelected:null})
  }

  /*
  handleSelect = (customer) => {
    this.setState({showModal: true, customerSelected: customer})
  }
  */

  handleRequestOpen(event) {
    this.setState({
      showOptions: true,
      anchorEl: {offsetWidth: event.currentTarget.offsetWidth, offsetHeight: event.currentTarget.offsetHeight}
    })
  }

  handlePageChange(nextPage, totalPages) {
    let {itemsByPageQuantity} = this.state;
    const {keyword, setLoading, loadCustomers} = this.props;
    let currentPage = nextPage;
    let from;

    if (totalPages > 1) {
      if (currentPage > totalPages) {
        currentPage = 1;
        from = 0;
      } else if (currentPage === 0) {
        currentPage = totalPages;
        from = itemsByPageQuantity * (totalPages - 1);
      } else {
        from = itemsByPageQuantity * (currentPage - 1);
      }

      setLoading(true);
      this.setState({currentPage: currentPage, from}, () => {
        loadCustomers(keyword, from, itemsByPageQuantity)
      })
    }

  }

  handleItemsByPage(itemsByPageQuantity) {
    const {keyword, setLoading, loadCustomers} = this.props;
    setLoading(true);
    this.setState({itemsByPageQuantity: itemsByPageQuantity, showOptions: false, currentPage: 1}, () => {
      loadCustomers(keyword, 0, itemsByPageQuantity)
    })
  }

  delete = async () => {
    let {from, itemsByPageQuantity, customers, currentPage,customerIdSelected} = this.state;
    const {keyword} = this.props;

    const response = await disableCustomerApi(customerIdSelected)
    const {responseCode, responseMessage} = response
    if (responseCode === SUCCESSFUL_CODE) {
      if (customers.length === 1) {
        currentPage -= 1;
        from -= itemsByPageQuantity;
        this.setState({currentPage, from});
      }
      this.setState({showModalDelete:false,customerIdSelected:null});
      this.props.loadCustomers(keyword, from, itemsByPageQuantity);
    }
    else {
      console.error(responseMessage);
      this.setState({showModalDelete:false,customerIdSelected:null});
    }

  }

  goToCustomer(customerId) {
    /*const {salesmanId}  = this.state;
    browserHistory.push({pathname: `/main/customer/edit/${customerId}`,
    state: {salesmanId: salesmanId}});*/
    browserHistory.push({pathname: `/main/customer/edit/${customerId}`})
  }

  add() {
    browserHistory.push({pathname: `/main/customer/new`});
  }

  handleSelectCustomer(id){
    this.setState({customerIdSelected:id,showModalDelete:true})
  }

  showModalDeleteCustomer(){
    const {customerSelected} = this.state;
    this.setState({customerIdSelected:customerSelected.id,showModalDelete:true, showModal: false})
  }

  onChangeDropDown() {
    const {dropDownOrder} = this.state;
    this.setState({dropDownOrder: !dropDownOrder})
  }

  renderDropDownFilter(type){
    let target = document.getElementById(type);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };
    this.setState({showPopover: true, filterSelected: type, anchorEl: anchorEl})
  }

  closeHeaderFilter(){
    this.setState({dropDownOrder: false});
  }

  onChangeSort(op) {
    let {customers,listName} = this.state;
    let listUsers = customers;
    if (op.value === "sortAsc") {
      listUsers.sort(function (a, b) {
        if (a.firstName < b.firstName) return -1;
        else if (a.firstName > b.firstName) return 1;
        return 0;
      });
      this.setState({listName: {...listName, displayValue: op.displayValue}, showPopover: false, filterSelected: null})
    } else if (op.value === "sortDesc") {
      listUsers.sort(function (a, b) {
        if (a.firstName > b.firstName) return -1;
        else if (a.firstName < b.firstName) return 1;
        return 0;
      });
      this.setState({listName: {...listName, displayValue: op.displayValue}, showPopover: false, filterSelected: null})
    } else {
      listUsers.sort(function (a, b) {
        if (a.id < b.id) return -1;
        else if (a.id > b.id) return 1;
        return 0;
      });
      this.setState({listName: {...listName, displayValue: op.displayValue}, showPopover: false, filterSelected: null})
    }
  }

  setSortInitial(){
    let {customers} = this.state;
    if(customers){
      customers.sort(function (a,b){
        if (a.firstName < b.firstName) return -1;
        else if (a.firstName > b.firstName) return 1;
        return 0;
      });
      this.setState({ listName: {...this.state.listName, displayValue : 'A-Z' }})
    }

  }

  renderOptions(type){
    let {listName} = this.state;
    let options = [];
    let values = [];
    let filterSelected = null;
    let method = null;

    switch (type){
      case 'orderUser':
        values = optionsOrder;
        filterSelected = listName.displayValue;
        method = this.onChangeSort;
        break;
    }

    values.map((order, index) => {
      if(order.displayValue === filterSelected){
        options.push(
          <li key={index} className="item-selected" onClick={() => method(order)}>
            {order.displayValue}
          </li>
        )
      } else {
        options.push(
          <li key={index} className="change-submenu-item" onClick={() => method(order)}>
            {order.displayValue}
          </li>
        )
      }
    });

    return (
      <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {options}
          </u>
        </div>
      </div>
    )
  }

  handleRequestClose = () => {
    this.setState({showOptions: false, showPopover: false, filterSelected: null})
  }

  handleImport = () => {
    const {showModalImport} = this.state;
    this.setState({showModalImport: !showModalImport});
  }

  showModalData = (totalRows,columns, nameFile) => {
    this.setState({showModalImport: false, showModalData: true, totalRows : totalRows, columns: columns, nameFile: nameFile});
  }

  closeModalData = () => {
    let {from, itemsByPageQuantity} = this.state;
    const {keyword} = this.props;
    this.setState({showModalData: false})
    this.props.loadCustomers(keyword, from, itemsByPageQuantity);
  }

  exportCustomers = () => {
    const {listName} = this.state
    const sortOrder = [CLEAR_ALL, ALPHABETICAL_ORDER.ASC].includes(listName.displayValue)
      ? ALPHABETICAL_ORDER.ASC
      : ALPHABETICAL_ORDER.DESC

    window.location.href = exportSortedCustomers(sortOrder);
  }

  render() {
    const {currentPage, showModal, customerSelected, showOptions, itemsByPageQuantity, anchorEl,customers, totalPages,showModalDelete,listName,
      showPopover, filterSelected, widthPage, heightDifference, showModalImport, showModalData, nameFile,
      columns, totalRows} = this.state;
    const {height, isLoading} = this.props;

    const {left} = this.props;
    const childOptions = this.renderOptions("orderUser");
    return (
      <div className="customer-content">
        <CustomerHeader title="Customers" left={left} classNameButton="button-add" value="+" action={this.add}
                        primaryColor={'#A4AFB7'} showOptions={showOptions} options={CUSTOMERS_BY_PAGE}
                        anchorEl={anchorEl}
                        itemsByPageQuantity={itemsByPageQuantity} handleRequestOpen={this.handleRequestOpen}
                        handleRequestClose={this.handleRequestClose}
                        showItemPerPage={true}
                        handleItemsByPage={this.handleItemsByPage} currentPage={currentPage} totalPages={totalPages}
                        handlePageChange={this.handlePageChange}
                        showIconAddCustomer={true}
                        addCustomer={this.add} hasButtonExport={true} hasButtonImport={true}
                        handleButtonImport={this.handleImport}
                        handleButtonExport={this.exportCustomers}
        />
        <div className="container-center">
          <div className="panel-customer" style={{minHeight: (height - heightDifference )}}>
            <div className="row-customer-container">
              <div className="btn-container-dp-user">
                <div id="orderUser" className="btn-general-message-dp-customer" onClick={this.renderDropDownFilter.bind(this,"orderUser")}>
                  <span>{(listName.displayValue === "Sort By") ? ALPHABETICAL_ORDER.ASC : listName.displayValue}</span>
                  {
                    (filterSelected === "orderUser") ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                  }
                </div>
              </div>
            </div>
            <div className="table-customer">
              <table className="table">
                <thead style={{backgroundColor: "#EFEFEF"}}>
                <tr>
                  <th className="th-photo">Photo</th>
                  <th className="th-user">Customer Name</th>
                  <th className="th-email">Email</th>
                  <th className="th-phone">Phone Number</th>
                  <th/>
                </tr>
                </thead>
                {
                  isLoading ?
                    <div className="loader-container">
                      <div className="loader"/>
                    </div> :
                    <tbody>
                    {
                      customers.map((customer, i) => {
                        const {id,firstName,middleName,lastName,phone,email,image} = customer;
                        const name = !isEmpty(middleName) ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`;

                        return (
                          <tr key={i + 'tr'} className="tr-customer" onClick={() => this.goToCustomer(id)}>
                            <td className="td-image">
                              <img className="image-user"
                                   src={image ? image : pathServer.PATH_IMG + "ic_account_circle.png"}/>
                            </td>
                            <td>{name}</td>
                            <td className="td-email" data-test={`email-${email}`}>
                              {email}
                            </td>
                            <td className="td-phone">{phone}</td>
                            <td className="td-actions"/>
                            {
                              widthPage > WIDTH_VIEW_TABLET ?
                                  <td className="customer-actions">
                                    <ReactSVG src={pathServer.PATH_IMG + "icon/edit-pencil.svg"}
                                              className="icon-edit"
                                              beforeInjection={ svg => svg.classList.add('img-view')}
                                    />
                                    <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                              className="icon-delete"
                                              beforeInjection={ svg => svg.classList.add('img-delete')}
                                              onClick={(event) => {
                                                event.stopPropagation()
                                                this.handleSelectCustomer(id)
                                              }} />
                                    {/*<span>*/}
                                    {/*  <img key={i + 'view'} id="imgView" src={pathServer.PATH_IMG + "img_pencil.png"}*/}
                                    {/* className="img-view" onClick={this.goToCustomer.bind(this, id)}/>*/}
                                    {/*</span>*/}
                                    {/*<span>*/}
                                    {/*  <img key={i + 'trash'} id="imgTrash"*/}
                                    {/* src={pathServer.PATH_IMG + "deleteCustomer.png"}*/}
                                    {/* className="img-delete"*/}
                                    {/* onClick={this.handleSelectCustomer.bind(this,id)}/>*/}
                                    {/*</span>*/}
                                  </td> : null
                            }
                          </tr>
                        );
                      })
                    }
                    </tbody>
                }
              </table>
            </div>
            {
              widthPage <= WIDTH_VIEW_MOBILE && customers?.length > 0 && (
                <div className="container-pagination">
                  <div className="customer-pagination">
                    <DPPagination
                      optionsQuantityByPage={CUSTOMERS_BY_PAGE}
                      selectedItemsByPage={itemsByPageQuantity}
                      handleChangeItemsByPage={this.handleItemsByPage}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={this.handlePageChange}
                      siteColor={siteColorAdmin}
                    />
                  </div>
                </div>
              )
            }
            {
              (!isLoading && customers && customers.length === 0) ?
                <div className="message-not-found">
                  <h3>No customers matched your search</h3>
                </div> : null
            }
          </div>
        </div>
        { showPopover && filterSelected !== null ? <Popover anchorEl={anchorEl}
                                                            child={childOptions}
                                                            classPopover="popover-box-message"
                                                            handleRequestClose={this.handleRequestClose}/> : null
        }
        {
          showModal ?
              <DPModalInfo showModal={showModal} closeModalInfo={this.closeModal} handleSelectCustomer={this.showModalDeleteCustomer}
                           body={<ModalCustomerDetails customer={customerSelected} goToCustomer={this.goToCustomer}/>} />
             : null
        }
        {
          showModalDelete ?
            <ModalConfirmDelete show={showModalDelete}
                                showButtonDelete={true}
                                onSubmit={this.delete}
                                onHide={this.closeModal}
                                classButtonDelete="btn-delete"
                                buttonLabelDelete="Yes, Delete"
                                message={"Do you want to delete this Customer?"}
                                styleModal="modal-delete"
                                styleRight="panel-right-delete-user"/> : null
        }
        { showModalImport ?
          <ModalUploadFile show={showModalImport} onHide={this.handleImport} showModalData={this.showModalData} title={"Import customers"}/> : null
        }
        {
          showModalData ?
            <ModalViewDataImport show={showModalData}
                                 onHide={this.closeModalData}
                                 totalRows={totalRows}
                                 rowsNumberToShow={ROWS_IMPORT_BY_PAGE_BY_DEFAULT}
                                 nameFile={nameFile}
                                 columns={columns}
                                 totalPages={totalPages}
                                 formCodeToImport={FORM_CUSTOMER_REVIEW}
                                 importKey={importKey.CUSTOMER}
                                 entityType={entityTypes.CUSTOMER}
                                 rowKey={ATTRIBUTE_CUSTOMER_EMAIL}
            />
            : null
        }
      </div>
    )
  }
}