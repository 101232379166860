import React, {useContext} from 'react'
import {pathServer} from '../../../../../common/Constants'
import {ReactSVG} from 'react-svg'
import {browserHistory} from 'react-router'
import {DashboardContext} from '../../Dashboard'

const User = props => {

  const onClickEdit = () => {
    browserHistory.push({pathname: "main/profile"})
  }
  const { user, roles, showUserExtraInfo } = useContext(DashboardContext)

  return(
    <div id="user-info" className="section-container">
        {
          !user ||user && user.image === "" ?
            <img src={pathServer.PATH_IMG + "ic_account_circle.png"} className="user-info-img" alt=""/>
            : <img src={user.image} className="user-info-img" alt=""/>
        }
      <div id="user-info-description">
        <span className="user-name">{`${user.firstName} ${user.lastName}`}</span>
        <span className="user-job">{roles}</span>
        {
          showUserExtraInfo &&
            <React.Fragment>
              <span className="user-description">{user.email}</span>
              <span className="user-description">{user.phoneNumber}</span>
            </React.Fragment>
        }
      </div>
      <ReactSVG src={pathServer.PATH_IMG + "icon/edit-pencil.svg"}
                className="icon-edit"
                onClick={ onClickEdit } />
    </div>
  )
}

export default User