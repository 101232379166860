import React, {Component} from 'react'
import {SectionHeaderAgreement} from '../util/ComponentUtils.js'
import isEmpty from 'lodash/isEmpty'
import {ReactSVG} from 'react-svg'
import {
  TEMPLATE_CODE_SALE_AGREEMENT,
} from '../../Constants'
import {DatePicker} from 'material-ui'
import Moment from 'moment/moment'
import {Popover} from '../util/ComponentUtils'
import {findIndex, find, cloneDeep} from 'lodash'
import {loadSalesFileById, saveKeywordValues, getServerUrl} from '../../Api'
import DynamicNumber from 'react-dynamic-number'
import InputElement from 'react-input-mask'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import Loader from '../../../common/UI/Loader.jsx'
import {checkValidPhone, checkValidZipCode} from '../../../common/ValidationUtil'
import {validateError} from '../util/Utils'
import {
  pathServer,
  optionBody,
  optionCondition,
  FIRST_DAY_OF_WEEK,
  cellphoneMask,
  SUCCESSFUL_CODE
} from '../../../common/Constants'

const fieldsRequired = [{key: "carTradeInVin", type: "required"}, {key: "carTradeInMake", type: "required"},
  {key: "carTradeInModel", type: "required"}, {key: "carTradeInMileage", type: "required"},
  {key: "carTradeInYear", type: "required"}, {key: "carTradeInBody", type: "required"},
  {key: "carTradeInSalesPerson", type: "required"}, {key: "carTradeInBalanceOwedOnTradeIn", type: "required"},
  {key: "carTradeInBalanceOwedTo", type: "required"}, {key: "carTradeInAllowance", type: "required"}];

const fieldsRequiredVehicle = [{key: "customerName", type: "required"}, {key: "customerAddress", type: "required"},
  {key: "customerCity", type: "required"}, {key: "customerCountry", type: "required"},
  {key: "customerState", type: "required"}, {key: "customerZipCode", type: "required"},
  {key: "customerPhone", type: "required"}, {key: "customerSecondPhone", type: "required"},
  {key: "carYear", type: "required"}, {key: "carMake", type: "required"},
  {key: "carModel", type: "required"}, {key: "carBody", type: "required"},
  {key: "carCylinder", type: "required"}, {key: "carExteriorColor", type: "required"},
  {key: "carMileage", type: "required"}, {key: "carTitle", type: "required"},
  {key: "salePerson", type: "required"}];

const purchasePrice = [
  {id: "cashPriceVehicle", displayValue: "Cash Price Vehicle",disabled:true},
  {id: "accessoryOptions", displayValue: "Accessories Options"},
  {id:"accessory1", value: "accessoryValue1"},
  {id:"accessory2", value: "accessoryValue2"},
  {id:"accessory3", value: "accessoryValue3"},
  {id:"totalCashPrice", displayValue:"Total Cash Price",disabled:true},
  {id:"rebate", displayValue:"MFR. Rebate $ (Optional)"},
  {id:"portion", displayValue:"Portion / Rebate Applied to Purchase"},
  {id:"subtotal", displayValue:"Subtotal (Lines 6 trough 8)",disabled:true},
  {id:"tradeInAllowance", displayValue:"Trade-in allowance",disabled:true},
  {id:"balanceOwed", displayValue:"BALANCED OWED ON TRADE-IN",disabled:true},
  {id:"netAllowance", displayValue:"Net allowance on trade-in (line 10 minus line 11)",disabled:true},
  {id:"depositDownPayment", displayValue:"Deposit/cash down payment (omit amt.line8)"},
  {id:"totalCredits", displayValue:"Total Credits (total lines 12 and 13)",disabled:true},
  {id:"subtotalFromLine9", displayValue:"SUB-TOTAL FROM LINE 9",disabled:true},
  {id:"serviceContract", displayValue:"SERVICE CONTRACT"},
  {id:"dealerDocFee", displayValue:"Dealer doc fee"},
  {id:"subtotalTaxableItems", displayValue:"Subtotal-taxable Items (total lines 15-17)",disabled:true},
  {id:"tradeAllowance", displayValue:"Trade Allowance (line 10)",disabled:true},
  {id:"nextTaxableAmount", displayValue:"Nex Taxable amount (Line 18 minus line 19)",disabled:true},
  {id:"salesOrUseTax", displayValue:'Utah Sales/Use tax in "Taxable Amount"'},
  {id:"licenseAndRegistrationFees", displayValue:"License and Registration Fees"},
  {id:"ageBasedOrPropertyAssessment", displayValue:"Fees: Age based/property Assessment"},
  {id:"stateInspectionOrEmissionsTest", displayValue:"State Inspection/Emissions Test"},
  {id:"stateWasteTireRecyclingFee", displayValue:"State Waste Tire Recycling Fee"},
  {id:"otherNonTaxableItems", displayValue:"Other Non-Taxable Items"},
  {id:"tempTag", displayValue:"Temp Tag"},
  {id:"GAP", displayValue:"GAP"},
  {id:"totalOfAllItems", displayValue:"Total of all items above (lines 18,21-27)",disabled:true},
  {id:"totalCredit", displayValue:"Total Credit (Line 14)",disabled:true},
  {id:"balanceDue1", displayValue:"Balance Due (Total lines 29-30)", id2: "balanceDue2",disabled:true}
];

const years = ['1990','1991','1992','1993','1994','1995','1996','1997','1998','1999','2000','2001','2002','2003','2004',
  '2005','2006','2007','2008','2009','2010','2011','2012','2013','2014','2015','2016','2017','2018','2019'];

const InputSection = ({classColumn,title,id,type,value,onChange, section, disable, useData,hasChangeTradeIn,error,isRequired,maxLength}) => {
  return (
    <div className={classColumn}>
      <div className="info-label">
        <label  className="title">{title}</label>
        {isRequired ? <span className="label-error">*</span>: null}
      </div>
      <div className="classInput">
        <input id={id} type={type} maxLength={maxLength ? maxLength : null} className={error ? "form-control has-error" : "form-control"}
               value={value} onChange={(evt) => onChange ? onChange(evt, {section,type}, useData,hasChangeTradeIn) : null}
               disabled={disable ? disable : false}
        />
        {error ?
          <p className="help-block">{getErrorMessage(title)}</p> : null}
      </div>
    </div>
  )
};

const DynamicNumberElement = ({classColumn, title, id, type, value, onChange, section, disable, useData, hasChangeTradeIn, error,
                                isRequired, maxLength, thousand, integer,separator,fraction,placeHolder}) => {

  return (
    <div className={classColumn}>
      {
        title ?
          <div className="info-label">
            <label className="title">{title}</label>
            {isRequired ? <span className="label-error">*</span> : null}
          </div> : null
      }
      <div className="inputSale">
        <DynamicNumber id={id}
                       className={ error ? "form-control has-error" : "form-control"}
                       placeholder={placeHolder ? placeHolder : ""}
                       value={type === "number" || type === "text" ? parseFloat(value) : value}
                       positive={true}
                       negative={false}
                       thousand={thousand}
                       onChange={(evt, modelValue, viewValue) => onChange({section: section, type: type, usedData: useData,hasChangeTradeIn:hasChangeTradeIn}, evt, modelValue, viewValue)}
                       separator={separator}
                       integer={integer}
                       fraction={fraction}
                       maxLength={maxLength}
                       disabled={false}/>
        {error ?
          <p className="help-block">{getErrorMessage(title)}</p> : null}
      </div>
    </div>
  )
};

const InputDate = ({id,classCol, classItem, value, displayValue, onChange, section,error,isRequired,hasChangeTradeIn}) => {
  let dateObject;
  if(value && !isEmpty(value)){
    const valueDate = Moment(value,"YYYY-MM-DD");
    dateObject = new Date(valueDate);
  } else
    dateObject = {};

  return (
    <div className={(error ? classCol + ' has-error':classCol)}>
      <div className={classItem}>
        {
          displayValue ?
            <div className="display-date">
              <p>{displayValue}</p>
              {isRequired ? <span className="label-error">*</span>: null}
            </div> : null
        }
        <DatePicker id={id} value={dateObject}
                    className="form-control finance-input"
                    onChange={(event, date) => onChange(section, id, date,hasChangeTradeIn)}
                    formatDate={(dateObject) => Moment(dateObject).format('MMM DD, YYYY')}
                    openToYearSelection={true} firstDayOfWeek={FIRST_DAY_OF_WEEK}/>
        {error ?
          <p className="help-block">{getErrorMessage(displayValue)}</p> : null}
      </div>
    </div>
  );
};

const InputMask = ({classCol, classItem, value, displayValue, onChangeInput, name, mask, isRequired, error,getErrorMessage}) => {
  return (
    <div className={(error ? classCol + ' has-error' : classCol)}>
      <div className={classItem}>
        <div>
          <p>{displayValue}</p>
          {isRequired ? <span className="label-error">*</span> : null}
        </div>
        <InputElement type="text"
                      className="form-control applicant-input"
                      mask={mask}
                      onChange={(e) => onChangeInput(name, e)}
                      value={value}/>
        {error ?
          <p className="help-block">{getErrorMessage(displayValue)}</p> : null}
      </div>
    </div>
  );

};

function getErrorMessage(name) {
  return name + ' is required'
}

const dataTradeIn = {
  keywords: [
    {
      key: "carTradeInVin",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInMake",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInModel",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInMileage",
      value: "",
      type: "NUMBER"
    },
    {
      key: "carTradeInYear",
      value: "",
      type: "DATE"
    },
    {
      key: "carTradeInBody",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInSalesPerson",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInBalanceOwedOnTradeIn",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInBalanceOwedTo",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInAllowance",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInAddress",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInPayOff",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInVerifiedBy",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInGoodUntil",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInCondition",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInDateOff",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInVerification",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInAcc",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInRegistration",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInBillOfSale",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInPowerOfAttorneys",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInOdometer",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInPropertyTAX",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInAuthorizationForPayOff",
      value: "",
      type: "TEXT"
    },
    {
      key: "carTradeInStatement",
      value: "",
      type: "TEXT"
    }
  ]
};
const dataPurchase = {
  keywords: [
    {
      key: "cashPriceVehicle",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "accessoryOptions",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "accessory1",
      value: "",
      type: "TEXT"
    },
    {
      key: "accessory2",
      value: "",
      type: "TEXT"
    },
    {
      key: "accessory3",
      value: "",
      type: "TEXT"
    },
    {
      key: "accessoryValue1",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "accessoryValue2",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "accessoryValue3",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "totalCashPrice",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "rebate",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "portion",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "subtotal",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "tradeInAllowance",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "balanceOwed",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "netAllowance",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "depositDownPayment",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "totalCredits",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "subtotalFromLine9",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "serviceContract",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "dealerDocFee",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "subtotalTaxableItems",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "tradeAllowance",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "nextTaxableAmount",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "salesOrUseTax",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "licenseAndRegistrationFees",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "ageBasedOrPropertyAssessment",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "stateInspectionOrEmissionsTest",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "stateWasteTireRecyclingFee",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "otherNonTaxableItems",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "tempTag",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "GAP",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "totalOfAllItems",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "totalCredit",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "balanceDue1",
      value: 0.00,
      type: "PRICE"
    },
    {
      key: "balanceDue2",
      value: "2018-06-15",
      type: "TEXT"
    },
    {
      key: "otherTermsAgreed",
      value: "",
      type: "TEXT"
    }
  ]
};

export default class CustomerSaleAgreement extends Component{
  constructor(){
    super();
    this.accordionToggle = this.accordionToggle.bind(this);
    this.inputChange     = this.inputChange.bind(this);
    this.inputChangeSale     = this.inputChangeSale.bind(this);
    this.inputMaskChange = this.inputMaskChange.bind(this);
    this.selectStatus    = this.selectStatus.bind(this);
    this.addTradeIn      = this.addTradeIn.bind(this);
    this.deleteTrade     = this.deleteTrade.bind(this);
    this.editTrade       = this.editTrade.bind(this);
    this.showPopoverMake = this.showPopoverMake.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.selectMake      = this.selectMake.bind(this);
    this.selectYear      = this.selectYear.bind(this);
    this.renderMakes     = this.renderMakes.bind(this);
    this.renderBodyType  = this.renderBodyType.bind(this);
    this.onChangeDate    = this.onChangeDate.bind(this);
    this.onChangeNumberDecimal  = this.onChangeNumberDecimal.bind(this);
    this.updatePriceCarToFields = this.updatePriceCarToFields.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.saveSale        = this.saveSale.bind(this);
    this.printPDF        = this.printPDF.bind(this);
    this.closeModal         = this.closeModal.bind(this);
    this.handleBlurNumber   = this.handleBlurNumber.bind(this);
    this.onChangeNumber     = this.onChangeNumber.bind(this);
    this.state = {
      data: {
        dealer: {
          keywords: [
            {
              key: "salePerson",
              value: "sale person",
              type: "TEXT"
            },
            {
              key: "dateOfSale",
              value: "14/05/2020",
              type: "TEXT"
            },
            {
              key: "dealerName",
              value: "",
              type: "TEXT"
            },
            {
              key: "dealerAddress",
              value: "",
              type: "TEXT"
            },
            {
              key: "dealerLocation",
              value: "",
              type: "TEXT"
            },
            {
              key: "dealerPhone",
              value: "4534",
              type: "TEXT"
            }
          ]
        },
        customer: {
          keywords: [
            {
              key: "customerName",
              value: "",
              type: "TEXT"
            },
            {
              key: "customerAddress",
              value: "",
              type: "TEXT"
            },
            {
              key: "customerPhone",
              value: "",
              type: "TEXT"
            },
            {
              key: "customerCity",
              value: "Johnny Apo",
              type: "TEXT"
            },
            {
              key: "customerCountry",
              value: "Johnny Apo",
              type: "TEXT"
            },
            {
              key: "customerState",
              value: "Johnny Apo",
              type: "TEXT"
            },
            {
              key: "customerZipCode",
              value: "Johnny Apo",
              type: "TEXT"
            },
            {
              key: "customerSecondPhone",
              value: "",
              type: "TEXT"
            }
          ]
        },
        car: {
          keywords: [
            {
              key: "carVin",
              value: "45432534534534",
              type: "TEXT"
            },
            {
              key: "carMake",
              value: "FORD",
              type: "TEXT"
            },
            {
              key: "carModel",
              value: "",
              type: "TEXT"
            },
            {
              key: "carYear",
              value: "2017",
              type: "NUMBER"
            },
            {
              key: "carMileage",
              value: "32433",
              type: "NUMBER"
            },
            {
              key: "carTitle",
              value: "titlecar",
              type: "TEXT"
            },
            {
              key: "carCylinder",
              value: "0",
              type: "NUMBER"
            },
            {
              key: "carExteriorColor",
              value: "green",
              type: "TEXT"
            },
            {
              key: "carSoldDate",
              value: "",
              type: "DATE"
            },
            {
              key: "carStatus",
              value: "new",
              type: "TEXT"
            },
            {
              key: "carBody",
              value: "body",
              type: "TEXT"
            }
          ]
        },
        purchase: dataPurchase,
        tradeIn: {
          hasTradeIn: true,
          key: "tradeInDisplayValue",
          value: "",
          cars: [
          ]
        },
        sellerRate: {
          hasRate: true,
          keywords: [
            {
              key: "interestRateYearFrom",
              value: "1992",
              type: "TEXT"
            },
            {
              key: "interestRateYearTo",
              value: "",
              type: "TEXT"
            },
            {
              key: "interestRateMonthFrom",
              value: "",
              type: "TEXT"
            },
            {
              key: "interestRateMonthTo",
              value: "",
              type: "TEXT"
            },
            {
              key: "monthlyPaymentsFrom",
              value: "",
              type: "TEXT"
            },
            {
              key: "monthlyPaymentsTo",
              value: "",
              type: "TEXT"
            },
            {
              key: "downPayment",
              value: "",
              type: "TEXT"
            }
          ]
        },
        insurance: {
          hasInsurance: true,
          keywords: [
            {
              key: "insuranceCompanyName",
              value: "",
              type: "TEXT"
            },
            {
              key: "insuranceCompanyPolicy",
              value: "",
              type: "TEXT"
            }
          ]
        }
      },
      data2: {
        car: {
          keywords: [{key: "carBody", value: "Coupe", typeValue: String}, {
            key: "carModel",
            value: "Mustang",
            typeValue: String
          }, {key: "carMake", value: "Ford", typeValue: String}, {
            key: "carStatus", value: "Front Line Ready",
            typeValue: String
          }]
        }, customer: {
          keywords: [{key: "customerState", value: "UT", typeValue: String}, {
            key: "customerCity",
            value: "Orem",
            typeValue: String
          }, {key: "customerAddress", value: "520 East 600 South", typeValue: String},
            {key: "customerName", value: "Javier Leiva", typeValue: String}]
        }, dealer: {keywords: [{key: "salePerson", value: "Dealer Pro", typeValue: String}]}
      },
      section : {
        motorVehicle:true,
        purchasePrice:false,
        sellerAgrees : false,
        insurance : false
      },
      errors: {},
      errorsMotorVehicle : {},
      showPopoverMake:false,
      showPopoverModel:false,
      showPopoverYear: false,
      showPopoverBodyType : false,
      showPopoverCondition : false,
      showPopoverSalesMan : false,
      arrayTradeInd : [],
      showNewTrade : false,
      currentTradeIn: 0,
      tempTradeIn: dataTradeIn,
      hasChange: false,
      fileTemplateId: null,
      saleFileId: null,
      showModalConfirm: false,
      viewValues: {},
      hasChangeTradeIn: false
    }
  }

  componentWillMount() {
    const {carSelected,saleId} = this.props;
    loadSalesFileById(saleId, TEMPLATE_CODE_SALE_AGREEMENT).then((response) => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        let data = JSON.parse(response.data.sale.keywordsValues);
        if (!data.tradeIn) {
          data.tradeIn = {
            hasTradeIn: true,
            key: "tradeInDisplayValue",
            value: "",
            cars: []
          }
        }
        if (!data.sellerRate) {
          data.sellerRate = {
            hasRate: true,
            keywords: []
          }
        }
        if(!data.insurance){
          data.insurance = {
            hasInsurance: true,
            keywords: []
          }
        }

        if(data.purchase.keywords.length === 1){
          const element = data.purchase.keywords[0];
          const elementPriceVehicle = dataPurchase.keywords.find( element => element.key === "cashPriceVehicle");
          elementPriceVehicle.type = element.type;
          elementPriceVehicle.value = element.value;
          data.purchase = dataPurchase;
          this.updatePriceCarToFields(elementPriceVehicle.value);
        }

        if(response.data.code && response.data.code === 208){
          this.setState({data, fileTemplateId: response.data.sale.id});
        }else {
          this.setState({ data, saleFileId: response.data.sale.id });
        }
      }else{
        console.error(responseMessage)
      }
    })
  }

  updatePriceCarToFields(carPrice){
    let {data,viewValues} = this.state;
    let {purchase} = data;
    let cashPriceVehicle = purchase.keywords.find((purchase) => {return purchase.key === "cashPriceVehicle"});
    let totalCashPrice = purchase.keywords.find((purchase) => {return purchase.key === "totalCashPrice"});
    let subtotal = purchase.keywords.find((purchase) => {return purchase.key === "subtotal"});
    let subtotalFromLine9 = purchase.keywords.find((purchase) => {return purchase.key === "subtotalFromLine9"});
    let subtotalTaxableItems = purchase.keywords.find((purchase) => {return purchase.key === "subtotalTaxableItems"});
    let totalOfAllItems = purchase.keywords.find((purchase) => {return purchase.key === "totalOfAllItems"});

    cashPriceVehicle.value = carPrice;
    viewValues["cashPriceVehicle"] = carPrice;
    totalCashPrice.value = carPrice;
    viewValues["totalCashPrice"] = carPrice;
    subtotal.value = carPrice;
    viewValues["subtotal"] = carPrice;
    subtotalFromLine9.value = carPrice;
    viewValues["subtotalFromLine9"] = carPrice;
    subtotalTaxableItems.value = carPrice;
    viewValues["subtotalTaxableItems"] = carPrice;
    totalOfAllItems.value = carPrice;
    viewValues["totalOfAllItems"] = carPrice;
    this.setState({data,viewValues});
  }

  accordionToggle(id){
    let {section} = this.state;
    section[id] = !section[id];
    this.setState({section:section});
  }

  validInputsRequired(key) {
    const {tempTradeIn} = this.state;
    return (this.validateValue(this.getValue(tempTradeIn.keywords, key)))
  }

  validateInputsRequiredMotorVehicle(key){
    const {data} = this.state;
    const { car,customer,dealer} = data;
    switch(key){
      case  "carTitle":
      case "carMileage":
      case "carYear":
      case "carMake":
      case "carModel":
      case "carBody":
      case "carCylinder":
      case "carExteriorColor":
        return this.validateValue(this.getValue(car.keywords, key));
      case "customerName":
      case "customerAddress":
      case "customerCity":
      case "customerCountry":
      case "customerState":
        return this.validateValue(this.getValue(customer.keywords, key));
      case "customerZipCode":
        return this.validateZipCode(this.getValue(customer.keywords, key));
      case "customerPhone":
      case "customerSecondPhone":
        return this.validPhone(this.getValue(customer.keywords, key));
      case "salePerson":
        return isEmpty(this.getValue(dealer.keywords, key));
    }
  }

  validPhone(value){
    return isEmpty(value) || !checkValidPhone(value)
  }

  validateZipCode(value){
    return isEmpty(value) || !checkValidZipCode(value)
  }

  validateValue(value){
    return isEmpty(value) && (value === "")
  }

  onChangeDate(section, key, date,hasChangeTradeIn) {
    let {data,tempTradeIn} = this.state;
    let {handleChanges} = this.props;
    let index = findIndex( section !== "tempTradeIn" ? data[section].keywords : tempTradeIn.keywords , function (field) {
      return field.key === key;
    });
    if (section !== "tempTradeIn")
      data[section].keywords[index].value = Moment(date).format("YYYY-MM-DD");
    else
      tempTradeIn.keywords[index].value = Moment(date).format("YYYY-MM-DD");
    handleChanges(true);
    this.setState({data,hasChange:true,hasChangeTradeIn : hasChangeTradeIn ? hasChangeTradeIn : false })
  }

  inputChange(evt){
    let key = evt.target.id;
    let value = evt.target.value;
    let {motorVehicle} = this.state;
    motorVehicle[key]=value;
    this.setState({motorVehicle})
  }

  inputChangeSale(evt, dataJson, usedData,hasChangeTradeIn){
    let key = evt.target.id;
    let value = evt.target.value;
    let {data, tempTradeIn} = this.state;
    const {section,type} = dataJson;
    let {handleChanges} = this.props;
    let index = findIndex(usedData ? data[section].keywords : tempTradeIn.keywords, function (field) {
      return field.key === key;
    });
    if (index === -1) {
      if (usedData)
        data[section].keywords.push({key: key, value: value, type: type});
      else
        tempTradeIn.keywords.push({key: key, value: value, type: type});
    } else {
      if(usedData)
        data[section].keywords[index].value = value;
      else
        tempTradeIn.keywords[index].value = value;
    }
    handleChanges(true);
    this.setState({data, tempTradeIn,hasChange:true,hasChangeTradeIn:hasChangeTradeIn ? hasChangeTradeIn : false})
  }

  onChangeNumberDecimal(type,evt,modelValue,viewValue){
    let tempValue = 0 ;
    let key = evt.target.id;
    let {data,viewValues} = this.state;
    let {purchase,sellerRate} = data;
    let element = purchase.keywords.find((purchase) => {return purchase.key === key});
    tempValue = Number(element.value);
    if(element){
      element.value = modelValue;
      viewValues[key] = viewValue;
    }
    this.sumTotal(key,modelValue,tempValue,viewValues)
  }

  calculate(data){
    let tradeInAllowanceTotal = 0;
    let balanceOwedOnTradeInTotal = 0;
    if(data.tradeIn.cars.length > 0){
      data.tradeIn.cars.map( (car) => {
        const carTradeInBalanceOwedOnTradeIn = car.keywords.find((car) => {return car.key === "carTradeInBalanceOwedOnTradeIn"});
        const carTradeInAllowance = car.keywords.find((car) => {return car.key === "carTradeInAllowance"});
        balanceOwedOnTradeInTotal = balanceOwedOnTradeInTotal + Number(carTradeInBalanceOwedOnTradeIn.value);
        tradeInAllowanceTotal = tradeInAllowanceTotal + Number(carTradeInAllowance.value);
      });
    }
    const indexTradeInAllowance =  data.purchase.keywords.findIndex((purchase) => {return purchase.key === "tradeInAllowance"});
    const indexBalanceOwed = data.purchase.keywords.findIndex((purchase) => {return purchase.key === "balanceOwed"});
    const indexNetAllowance = data.purchase.keywords.findIndex((purchase) => {return purchase.key === "netAllowance"});
    const indexTradeAllowance = data.purchase.keywords.findIndex((purchase) => {return purchase.key === "tradeAllowance"});
    const indexSubtotalTaxableItems = data.purchase.keywords.findIndex((purchase) => {return purchase.key === "subtotalTaxableItems"});
    const indexNextTaxableAmount = data.purchase.keywords.findIndex((purchase) => {return purchase.key === "nextTaxableAmount"});
    data.purchase.keywords[indexTradeInAllowance].value = tradeInAllowanceTotal;
    data.purchase.keywords[indexBalanceOwed].value = balanceOwedOnTradeInTotal;
    data.purchase.keywords[indexNetAllowance].value = tradeInAllowanceTotal - balanceOwedOnTradeInTotal;
    data.purchase.keywords[indexTradeAllowance].value = tradeInAllowanceTotal;
    data.purchase.keywords[indexNextTaxableAmount].value = data.purchase.keywords[indexSubtotalTaxableItems].value - tradeInAllowanceTotal;
  }

  calculateTotalCredits(data){
    const depositDownPayment = data.purchase.keywords.find((purchase) => {return purchase.key === "depositDownPayment"});
    const netAllowance = data.purchase.keywords.find((purchase) => {return purchase.key === "netAllowance"});
    const indexTotalCredits = data.purchase.keywords.findIndex((purchase) => {return purchase.key === "totalCredits"});
    const indexTotalCredit = data.purchase.keywords.findIndex((purchase) => {return purchase.key === "totalCredit"});
    data.purchase.keywords[indexTotalCredits].value = Number(depositDownPayment.value) + Number(netAllowance.value);
    data.purchase.keywords[indexTotalCredit].value  = Number(depositDownPayment.value) + Number(netAllowance.value);
  }

  calculateBalanceDue(data){
    const totalOfAllItems = data.purchase.keywords.find((purchase) => {return purchase.key === "totalOfAllItems"});
    const totalCredit = data.purchase.keywords.find((purchase) => {return purchase.key === "totalCredit"});
    const indexBalanceDue1 = data.purchase.keywords.findIndex((purchase) => {return purchase.key === "balanceDue1"});
    data.purchase.keywords[indexBalanceDue1].value = totalOfAllItems.value - totalCredit.value;
  }

  sumTotal(key,value,temp, viewValues){
    const {data}= this.state;
    const newData = cloneDeep(data);
    let {handleChanges} = this.props;
    let totalCashPrice = newData.purchase.keywords.find(purchase => {return purchase.key === "totalCashPrice"});
    let subtotal = newData.purchase.keywords.find(purchase => {return purchase.key === "subtotal"});
    let subtotalTaxableItems = newData.purchase.keywords.find(purchase => {return purchase.key === "subtotalTaxableItems"});
    let totalOfAllItems = newData.purchase.keywords.find(purchase => {return purchase.key === "totalOfAllItems"});
    let subtotalFromLine9 = newData.purchase.keywords.find(purchase => {return purchase.key === "subtotalFromLine9"});
    switch (key) {
      case "cashPriceVehicle":
      case "accessoryOptions":
      case "accessoryValue1":
      case "accessoryValue2":
      case "accessoryValue3":
        totalCashPrice.value = totalCashPrice.value + value - temp;
        viewValues["totalCashPrice"] = totalCashPrice.value;
        subtotal.value = subtotal.value + value - temp;
        viewValues["subtotal"] = subtotal.value;
        subtotalFromLine9.value = subtotalFromLine9.value + value - temp;
        viewValues["subtotalFromLine9"] = subtotalFromLine9.value;
        subtotalTaxableItems.value = subtotalTaxableItems.value + value - temp;
        viewValues["subtotalTaxableItems"] = subtotalTaxableItems.value;
        totalOfAllItems.value = totalOfAllItems.value + value - temp;
        viewValues["totalOfAllItems"] = totalOfAllItems.value;
        this.calculateBalanceDue(newData);
        this.setState({data:newData,viewValues:viewValues,hasChange:true});
        handleChanges(true);
        break;
      case "rebate":
      case "portion":
        subtotal.value = subtotal.value + value - temp;
        viewValues["subtotal"] = subtotal.value;
        subtotalFromLine9.value = subtotalFromLine9.value + value - temp;
        viewValues["subtotalFromLine9"] = subtotalFromLine9.value;
        subtotalTaxableItems.value = subtotalTaxableItems.value + value - temp;
        viewValues["subtotalTaxableItems"] = subtotalTaxableItems.value;
        totalOfAllItems.value = totalOfAllItems.value + value - temp;
        viewValues["totalOfAllItems"] = totalOfAllItems.value;
        this.calculateTotalCredits(newData);
        this.calculate(newData);
        this.calculateBalanceDue(newData);
        this.setState({data:newData,viewValues:viewValues,hasChange:true});
        handleChanges(true);
        break;
      case "depositDownPayment":
        this.calculateTotalCredits(newData);
        this.calculate(newData);
        this.calculateBalanceDue(newData);
        this.setState({data:newData,viewValues:viewValues,hasChange:true});
        handleChanges(true);
        break;
      case "serviceContract":
      case "dealerDocFee":
        subtotalTaxableItems.value = subtotalTaxableItems.value + value - temp;
        viewValues["subtotalTaxableItems"] = subtotalTaxableItems.value;
        totalOfAllItems.value = totalOfAllItems.value + value - temp;
        viewValues["totalOfAllItems"] = totalOfAllItems.value;
        this.calculate(newData);
        this.calculateBalanceDue(newData);
        this.setState({data:newData,viewValues:viewValues,hasChange:true});
        handleChanges(true);
        break;
      case "salesOrUseTax":
      case "licenseAndRegistrationFees":
      case "ageBasedOrPropertyAssessment":
      case "stateInspectionOrEmissionsTest":
      case "stateWasteTireRecyclingFee":
      case "otherNonTaxableItems":
      case "tempTag":
      case "GAP":
        totalOfAllItems.value = totalOfAllItems.value + value - temp;
        viewValues["totalOfAllItems"] = totalOfAllItems.value;
        this.calculateBalanceDue(newData);
        this.setState({data:newData,viewValues:viewValues,hasChange:true});
        handleChanges(true);
        break;
      default:
        break;
    }
  }

  handleBlurNumber(value,evt) {
    let type = evt.target.id.toString();
    let number = Number(value.replace(/,/g, ''));
    this.setState({[type]: number});
  }

  inputMaskChange(key,evt) {
    let {data} = this.state;
    let {handleChanges} = this.props;
    let index = findIndex(data.customer.keywords, function (field) {
      return field.key === key;
    });
    data.customer.keywords[index].value = evt.target.value;
    this.setState({data,hasChange:true})
    handleChanges(true);
  }

  selectStatus(evt){
    let value = evt.target.id;
    let {data} = this.state;
    let {handleChanges} = this.props;

    let index = findIndex(data.car.keywords, function (field) {
      return field.key === "carStatus";
    });
    data.car.keywords[index].value = value;
    this.setState({data,hasChange:true});
    handleChanges(true);
  }

  getPosition(id){
    let target = document.getElementById(id);
    let anchorEl = {
      x: target.offsetLeft,
      y: target.offsetTop + 34,
      offsetWidth: target.offsetWidth
    };
    return anchorEl
  }

  showPopoverMake(id, event) {
    const {showPopoverMake} = this.state;
    event.preventDefault();
    let anchorEl = this.getPosition(id);
    this.setState({showPopoverMake: !showPopoverMake, showPopoverYear: false,showPopoverBodyType:false,
      showPopoverCondition:false, showPopoverSalesMan:false,showPopoverModel:false, anchorElStatus: anchorEl})
  }

  showPopoverYear(id, event){
    const {showPopoverYear} = this.state;
    event.preventDefault();
    let anchorEl = this.getPosition(id);
    this.setState({showPopoverYear: !showPopoverYear, showPopoverMake: false,showPopoverBodyType : false,
      showPopoverCondition : false, showPopoverSalesMan:false,showPopoverModel:false, anchorElStatus: anchorEl})
  }

  showPopoverBodyType(id,event){
    const {showPopoverBodyType} = this.state;
    event.preventDefault();
    let anchorEl = this.getPosition(id);
    this.setState({showPopoverBodyType: !showPopoverBodyType,showPopoverCondition:false, showPopoverYear: false,
      showPopoverMake: false,showPopoverSalesMan:false,showPopoverModel:false, anchorElStatus: anchorEl})
  }

  showPopoverCondition(id,event){
    const {showPopoverCondition} = this.state;
    event.preventDefault();
    let anchorEl = this.getPosition(id);
    this.setState({showPopoverCondition: !showPopoverCondition, showPopoverYear: false, showPopoverMake: false,
      showPopoverBodyType:false, showPopoverSalesMan:false,showPopoverModel:false, anchorElStatus: anchorEl})
  }

  showPopoverSalesMan(id,event){
    const {showPopoverSalesMan} = this.state;
    event.preventDefault();
    let anchorEl = this.getPosition(id);
    this.setState({showPopoverSalesMan: !showPopoverSalesMan, showPopoverYear: false, showPopoverMake: false,
      showPopoverBodyType:false,showPopoverCondition:false,showPopoverModel:false, anchorElStatus: anchorEl})
  }

  handleRequestClose() {
    this.setState({showPopoverMake: false, showPopoverYear: false,showPopoverBodyType : false,
      showPopoverCondition : false, showPopoverSalesMan : false,showPopoverModel:false})
  }

  showPopoverModel(id,event){
    const {showPopoverModel} = this.state;
    event.preventDefault();
    let anchorEl = this.getPosition(id);
    this.setState({showPopoverModel: !showPopoverModel, showPopoverYear: false,showPopoverBodyType:false,
      showPopoverCondition:false, showPopoverSalesMan:false,showPopoverMake:false, anchorElStatus: anchorEl})
  }

  renderMakes() {
    const {makes} = this.props;
    let options = [];
    makes.map((make, index) => {
      options.push(
        <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectMake(make)}}>
          <label className="option-label">{make.displayValue}</label>
        </div>
      )
    });
    return (
      <div className="popover-body-status">
        <div id="options-make" className="options-status">{options}</div>
      </div>
    )
  }

  renderModels(){
    const{makeIdSelected} = this.state;
    const {models} = this.props;
    let options = [];
    if(makeIdSelected){
      const modelsByMake = models.filter((model)=>{ return model.makeId === makeIdSelected});
      modelsByMake.map((model, index) => {
        options.push(
          <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectModel(model)}}>
            <label className="option-label">{model.displayValue}</label>
          </div>
        )
      });
      return (
        <div className="popover-body-status">
          <div id="options-make" className="options-status">{options}</div>
        </div>
      )
    }

  }

  renderYears(){
    let options = [];
    years.map((year, index) => {
      options.push(
        <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectYear(year)}}>
          <label className="option-label">{year}</label>
        </div>
      )
    });
    return (
      <div className="popover-body-status">
        <div id="options-make" className="options-status">{options}</div>
      </div>
    )
  }

  renderBodyType(){
    let options = [];
    optionBody.map((body, index) => {
      options.push(
        <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectBodyType(body)}}>
          <label className="option-label">{body.value}</label>
        </div>
      )
    });
    return (
      <div className="popover-body-status">
        <div id="options-make" className="options-status">{options}</div>
      </div>
    )
  }

  renderConditions(){
    let options = [];
    optionCondition.map((condition, index) => {
      options.push(
        <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectCondition(condition)}}>
          <label className="option-label">{condition.value}</label>
        </div>
      )
    });
    return (
      <div className="popover-body-status">
        <div id="options-make" className="options-status">{options}</div>
      </div>
    )
  }

  renderSalesMan(){
    const {users} = this.props;
    let options = [];
    users.map((user, index) => {
      options.push(
        <div key={index} id={"option" + index} className="container-option" onClick={() => {this.selectSalesMan(user)}}>
          <label className="option-label">{`${user.firstName} ${user.lastName}`}</label>
        </div>
      )
    });
    return (
      <div className="popover-body-status">
        <div id="options-make" className="options-status">{options}</div>
      </div>
    )
  }

  selectMake(make) {
    let {tempTradeIn} = this.state;
    let {handleChanges} = this.props;
    let indexModel = findIndex(tempTradeIn.keywords, function (field) {
      return field.key === "carTradeInModel";
    });
    let index = findIndex(tempTradeIn.keywords, function (keyword) {
      return keyword.key === "carTradeInMake";
    });
    tempTradeIn.keywords[indexModel].value = "";
    tempTradeIn.keywords[index].value = make.displayValue;
    this.setState({tempTradeIn, showPopoverMake: false,makeIdSelected:make.id, hasChange:true, hasChangeTradeIn:true})
    handleChanges(true);
  }

  selectModel(model) {
    let {tempTradeIn} = this.state;
    let {handleChanges} = this.props;
    let index = findIndex(tempTradeIn.keywords, function (keyword) {
      return keyword.key === "carTradeInModel";
    });
    tempTradeIn.keywords[index].value = model.displayValue;
    this.setState({tempTradeIn, showPopoverModel: false, hasChange:true,hasChangeTradeIn:true  })
    handleChanges(true);
  }

  selectYear(year) {
    let {tempTradeIn} = this.state;
    let {handleChanges} = this.props;
    let index = findIndex(tempTradeIn.keywords, function (keyword) {
      return keyword.key === "carTradeInYear";
    });
    tempTradeIn.keywords[index].value = year;
    this.setState({tempTradeIn, showPopoverYear: false, hasChange:true,hasChangeTradeIn:true})
    handleChanges(true);
  }

  selectBodyType(bodyType){
    let {tempTradeIn} = this.state;
    let {handleChanges} = this.props;
    let index = findIndex(tempTradeIn.keywords, function (keyword) {
      return keyword.key === "carTradeInBody";
    });
    tempTradeIn.keywords[index].value = bodyType.name;
    this.setState({tempTradeIn, showPopoverBodyType: false, hasChange:true,hasChangeTradeIn: true})
    handleChanges(true);
  }

  selectCondition(condition){
    let {tempTradeIn} = this.state;
    let {handleChanges} = this.props;
    let index = findIndex(tempTradeIn.keywords, function (keyword) {
      return keyword.key === "carTradeInCondition";
    });
    tempTradeIn.keywords[index].value = condition.value;
    this.setState({tempTradeIn, showPopoverCondition: false, hasChange:true,hasChangeTradeIn:true})
    handleChanges(true);
  }

  selectSalesMan(saleMan){
    let {tempTradeIn} = this.state;
    let {handleChanges} = this.props;
    let index = findIndex(tempTradeIn.keywords, function (keyword) {
      return keyword.key === "carTradeInSalesPerson";
    });
    tempTradeIn.keywords[index].value = saleMan.firstName + " " + saleMan.lastName;
    this.setState({tempTradeIn, showPopoverSalesMan: false, hasChange:true, hasChangeTradeIn:true})
    handleChanges(true);
  }

  addTradeIn() {
    const {errors,data} = this.state;
    const newData = cloneDeep(data);
    const error = this.getError(errors);
    const hasError = validateError(error);
    let {tempTradeIn, hasChangeTradeIn} = this.state;
    if (hasChangeTradeIn) {
      if (!hasError) {
        let carTradeIn = cloneDeep(tempTradeIn);
        newData.tradeIn.cars.push(carTradeIn);
        for (let index in tempTradeIn.keywords) {
          tempTradeIn.keywords[index].value = ""
        }
        this.calculate(newData);
        this.calculateTotalCredits(newData);
        this.calculateBalanceDue(newData);
        this.setState({data:newData, tempTradeIn, showNewTrade: true, hasChange:true,hasChangeTradeIn:false})
      } else {
        this.setState({errors: error});
      }
    }
  }

  editTrade(index){
    let {data} = this.state;
    this.setState({showNewTrade:false, currentTradeIn: index, tempTradeIn: data.tradeIn.cars[index]})
  }

  deleteTrade(indexElement){
    const  {data} = this.state;
    let {handleChanges} = this.props;
    const newData = cloneDeep(data);
    newData.tradeIn.cars = data.tradeIn.cars.filter((element,index)=> {
      return index !== indexElement
    });
    this.calculate(newData);
    this.calculateTotalCredits(newData);
    this.calculateBalanceDue(newData);
    this.setState({data:newData, hasChange:true})
    handleChanges(true);
  }

  getValue(keywords, key){
    let field = find(keywords, function (keyword) {
      return keyword.key === key;
    });
    if(field)
      return field.value;
    else
      return ""
  }

  getError(errors){
    fieldsRequired.forEach( field => {
      const {key} = field;
      errors[key] = this.validInputsRequired(key)
    });
    return errors
  }

  getErrorMotorVehicle(errors){
    fieldsRequiredVehicle.forEach( field => {
      const {key} = field;
      errors[key] = this.validateInputsRequiredMotorVehicle(key)
    });
    return errors
  }

  closeModal(){
    this.setState({showModalConfirm: false});
  }

  printPDF(){
    this.saveSale("printPDF");
  }

  numberWithCommas(x) {
    let number = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let point = number.indexOf(".");
    let numberString = "";
    if (point !== -1){
      let decimals = number.substring(point+1,number.length);
      if (decimals.length === 1){
        numberString = number+`0`;
      } else {
        numberString = number;
      }
    } else{
      numberString = number+`.00`;
    }
    return numberString
  }

  generateHtml(data){
    let html =  "";
    if(data.tradeIn.cars && data.tradeIn.cars.length > 0){
      html +=     "<div style='float: left; width: 100%;'/>";
      data.tradeIn.cars.map( (car, index) => {
        let condition = this.getValue(car.keywords, "carTradeInCondition");
        let price = this.getValue(car.keywords, "carTradeInAllowance");

        html +=   "<div style='float: left; width: 100%;'>";
        html +=     "<p style='float: left; margin: 0px; padding-left: 29px; color: rgb(120, 120, 120); font-size: 8px;'>";
        html +=       `${this.getValue(car.keywords, "carTradeInMake")} ${this.getValue(car.keywords, "carTradeInModel")} `;
        html +=       `${this.getValue(car.keywords, "carTradeInYear")}`;
        html +=       condition === "" ? "" : (` / ${condition}`);
        html +=     "</p>";
        html +=     "<p style='float: right; margin: 0px; color: rgb(120, 120, 120); font-size: 8px;'>";
        html +=       `$${this.numberWithCommas(price)}`;
        html +=     "</p>";
        html +=   "</div>"
      });
      html +=     "</div>"
    }
    return html;
  }

  saveSale(type){
    const {errorsMotorVehicle, saleFileId, data, fileTemplateId,showModalConfirm} = this.state;
    const {goToCustomer,handleChanges} = this.props;
    let error = {};
    let hasError= false;

    if(type && type === 'printPDF' && !showModalConfirm) {
      error = this.getErrorMotorVehicle(errorsMotorVehicle);
      hasError = validateError(error);
    }

    if(!hasError){
      data.tradeIn.value = this.generateHtml(data);
      let {saleId, customerId, showSuccessfulMessage,goToSaleFiles} = this.props;
      let body = {keywordValues: JSON.stringify(data)};
      if(saleFileId)
        body["saleFileId"] = saleFileId;
      if(fileTemplateId)
        body["fileTemplateId"] = fileTemplateId;
      this.setState({isLoading: true});
      saveKeywordValues(saleId, body).then((response) => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({data, saleFileId: response.data.saleFileId, fileTemplateId: null, showModalConfirm : false,
            errorsMotorVehicle: error,hasChange:false,isLoading: false});
          showSuccessfulMessage();
          handleChanges(false);
          switch (type) {
            case "printPDF":
              window.open(`${getServerUrl()}api/sales/${saleId}/files/${response.data.saleFileId}/generate`, '_blank');
              break;
            case "saveAndContinue":
              //browserHistory.push({pathname: `/main/customer/edit/${customerId}`});
              goToSaleFiles();
              break;
            case "saveAndBack":
              goToCustomer();
              break;
          }
        }else{
          console.log(responseMessage);
          this.setState({isLoading: false});
        }
      })
    }else{
      let showModalConfirm = false;
      if(type === "printPDF")
        showModalConfirm = true;
      this.setState({errorsMotorVehicle: error, showModalConfirm:showModalConfirm});
    }
  }

  saveAndContinue(){
    this.saveSale("saveAndContinue");
  }

  onChangeNumber(dates,evt,modelValue,viewValue){
    const {section,type,usedData,hasChangeTradeIn} = dates;
    let key = evt.target.id;
    let value = modelValue;
    let {data, tempTradeIn, viewValues} = this.state;
    let {handleChanges} = this.props;
    let index = findIndex(usedData ? data[section].keywords : tempTradeIn.keywords, function (field) {
      return field.key === key;
    });
    if (index === -1) {
      if (usedData)
        data[section].keywords.push({key: key, value: value, type: type});
      else
        tempTradeIn.keywords.push({key: key, value: value, type: type});
    } else {
      if(usedData)
        data[section].keywords[index].value = value;
      else
        tempTradeIn.keywords[index].value = value;
    }
    viewValues[key] = viewValue;
    this.setState({data, tempTradeIn,hasChange:true,hasChangeTradeIn:hasChangeTradeIn ? hasChangeTradeIn : false, viewValues});
    handleChanges(true);
  }

  saveAndBack(){
    this.saveSale("saveAndBack");
  }

  render() {
    const {section,errors,showPopoverMake,showPopoverModel,showPopoverYear, showPopoverBodyType,showPopoverCondition,
      showPopoverSalesMan,anchorElStatus,showNewTrade,data, tempTradeIn,makeIdSelected,hasChange,hasChangeTradeIn,errorsMotorVehicle,
      showModalConfirm, viewValues,isLoading} = this.state;
    let {dealer, customer, car, purchase, tradeIn, sellerRate, insurance} = data;

    return(
      <div>
        <div className="box-edit-car box-sale-agreement">
          {
            isLoading ?
              <Loader/> : null
          }
          <div className="section-row-message">
            <div className="container-row-message">
              <div className="container-icon-body-title">
                <div className="container-icon-exclamation">
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                            className="icon-error"/>
                </div>
                <div className="container-message">
                  <label className="title-message">Make sure to fill all the fields and double check by clicking on "Print PDF" on right
                    top corner.</label>
                </div>
              </div>
            </div>
          </div>
          <div className="container-buttons">
            {
              hasChange ?
                <button className="save" onClick={this.saveSale}>
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_save.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-save')}
                            className="icon-save"/>
                </button> :
                <div className="check-ark">
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_checking_mark_circle.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-check')}
                            className="icon-check"/>
                </div>

            }
            <button className="print" onClick={this.printPDF}>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_print_white.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-print')}
                        className="icon-print"/>
            </button>
          </div>
          <div className="container-section-agreement">
            <SectionHeaderAgreement id="motorVehicle" isOpen={section["motorVehicle"]} title="Motor Vehicle Contract Sale"
                                    accordionToggle={this.accordionToggle} showSubTitle={true} subTitle={"Correct in necessary and fill empty fields"}/>
            <div className="body-section-agreement" style={section["motorVehicle"] ? {display: "block"} : {display: "none"}}>
              <div className="col100 section-agreement">
                <InputSection  classColumn="col22" title="Purchaser's name" id="customerName" type="text"
                               value={this.getValue(customer.keywords, "customerName")} onChange={this.inputChangeSale}
                               section="customer" useData={true} error={errorsMotorVehicle["customerName"]} isRequired={true} />
                <InputSection classColumn="col11" title="Address" id="customerAddress" type="text"
                              value={this.getValue(customer.keywords, "customerAddress")} onChange={this.inputChangeSale}
                              section="customer" useData={true} error={errorsMotorVehicle["customerAddress"]} isRequired={true} />
                <InputSection  classColumn="col11" title="City" id="customerCity" type="text"
                               value={this.getValue(customer.keywords,"customerCity")} onChange={this.inputChangeSale}
                               section="customer" useData={true} error={errorsMotorVehicle["customerCity"]} isRequired={true}/>
                <InputSection  classColumn="col11"title="Country" id="customerCountry" type="text"
                               value= {this.getValue(customer.keywords, "customerCountry")} onChange={this.inputChangeSale}
                               section= "customer" useData={true} error={errorsMotorVehicle["customerCountry"]} isRequired={true} />
                <InputSection  classColumn="col11" title="State" id="customerState" type="text"
                               value={this.getValue(customer.keywords, "customerState")} onChange={this.inputChangeSale}
                               section= "customer" useData={true} error={errorsMotorVehicle["customerState"]} isRequired={true}/>
                <InputSection  classColumn="col11"  title="ZIP code"  id="customerZipCode"  type="text"
                               value={this.getValue(customer.keywords, "customerZipCode")}  onChange={this.inputChangeSale}
                               section= "customer"  useData={true} maxLength={5} error={errorsMotorVehicle["customerZipCode"]} isRequired={true}/>
              </div>
              <div className="col100 section-agreement">
                <div className="col22">
                  <InputMask classCol="container-input-phone" classItem="item" displayValue="Res. Phone"
                             value={this.getValue(customer.keywords, "customerPhone")}
                             onChangeInput={this.inputMaskChange} name="customerPhone" mask={cellphoneMask}
                             error={errorsMotorVehicle["customerPhone"]} isRequired={true} getErrorMessage={getErrorMessage} getMessageInvalid={"customerPhone"}/>
                </div>
                <div className="col22">
                  <InputMask classCol="container-input-phone" classItem="item" displayValue="Bus. Phone"
                             value={this.getValue(customer.keywords, "customerSecondPhone")}
                             onChangeInput={this.inputMaskChange} name="customerSecondPhone" mask={cellphoneMask}
                             error={errorsMotorVehicle["customerSecondPhone"]} isRequired={true} getErrorMessage={getErrorMessage} getMessageInvalid={"customerSecondPhone"}/>
                </div>
              </div>
              <div className="col100 section-agreement  ">
                <label className="label-purchaser-agreement">Purchaser and Co-purchaser(s), if any, (hereafter referred
                  to as "purchaser") hereby agree to purchase the following vehicle from seller/dealer (hereafter referred
                  to as "seller"), subject to all terms, conditions, warranties and agreements contained herein, including
                  those printed on the reverse side hereof.
                </label>
              </div>
              <div className="col100 section-agreement">
                <div className="col22">
                  <label  className="title">Status</label>
                  <div className="container-status">
                    <button id="new" className={ (this.getValue(car.keywords, "carStatus") === "new") ? "btn-status-selected" : "btn-status"} onClick={this.selectStatus}>New</button>
                    <button id="used" className={(this.getValue(car.keywords, "carStatus") === "used") ? "btn-status-selected middle": "btn-status middle"} onClick={this.selectStatus}>Used</button>
                    <button id="demo" className={(this.getValue(car.keywords, "carStatus") === "demo") ? "btn-status-selected" : "btn-status"} onClick={this.selectStatus}>Demo</button>
                  </div>
                </div>
                <DynamicNumberElement classColumn={"col11"} title={"Year"} id={"carYear"} type={"text"}
                                      value={this.getValue(car.keywords, "carYear")} section="car" isRequired={true}
                                      onChange={this.onChangeNumber} separator={","} placeHolder={"1992"}
                                      thousand={false} integer={4} fraction={0} maxLength={4} useData={true}
                                      error={errorsMotorVehicle["carYear"]}/>
                <InputSection  classColumn="col11" title="Make" id="carMake" type="text" value={this.getValue(car.keywords,"carMake")}
                               onChange={this.inputChangeSale} section= "car" useData={true} error={errorsMotorVehicle["carMake"]} isRequired={true} />
                <InputSection  classColumn="col11" title="Series" id="carModel" type="text" value={this.getValue(car.keywords,"carModel")}
                               onChange={this.inputChangeSale} section= "car" useData={true} error={errorsMotorVehicle["carModel"]} isRequired={true} />
                <InputSection  classColumn="col11" title="Body Type" id="carBody" type="text" value={this.getValue(car.keywords, "carBody")}
                               onChange={this.inputChangeSale} section="car" useData={true} error={errorsMotorVehicle["carBody"]} isRequired={true} />
                <DynamicNumberElement classColumn={"col11"} title={"CYL"} id={"carCylinder"} type={"number"}
                                      value={this.getValue(car.keywords, "carCylinder")} section="car" isRequired={true}
                                      onChange={this.onChangeNumber} separator={"."}
                                      thousand={true} integer={8} fraction={2} maxLength={12} useData={true}
                                      error={errorsMotorVehicle["carCylinder"]} placeHolder={"0.00"}/>
                <InputSection classColumn="col11" title="Color" id="carExteriorColor" type="text" value={this.getValue(car.keywords, "carExteriorColor")}
                              onChange={this.inputChangeSale} section="car" useData={true} error={errorsMotorVehicle["carExteriorColor"]} isRequired={true} />
              </div>
              <div className="col100 section-agreement">
                <InputSection classColumn="col22" title="VIN Number" id="carVin" type="text" value={this.getValue(car.keywords, "carVin")} disable={true}/>
                <DynamicNumberElement classColumn={"col11"} title={"Odometer"} id={"carMileage"} type={"number"}
                                      value={this.getValue(car.keywords, "carMileage")} section="car" isRequired={true}
                                      onChange={this.onChangeNumber} separator={"."} placeHolder={"0.00"}
                                      thousand={true} integer={6} fraction={2} maxLength={8} useData={true}
                                      error={errorsMotorVehicle["carMileage"]}/>
                <InputSection  classColumn="col11" title="Title" id="carTitle" type="text" value={this.getValue(car.keywords,"carTitle")}
                               onChange={this.inputChangeSale} section="car" useData={true} error={errorsMotorVehicle["carTitle"]} isRequired={true}/>
                <InputSection  classColumn="col22" title="Sales Person" id="salePerson" type="text" value={this.getValue(dealer.keywords, "salePerson")}
                               onChange={this.inputChangeSale} section= "dealer" useData={true} error={errorsMotorVehicle["salePerson"]} isRequired={true} />
              </div>
            </div>
          </div>
          <div className="container-section-agreement">
            <SectionHeaderAgreement id="purchasePrice" isOpen={section["purchasePrice"]} title="Purchase Price and Other Sum"
                                    accordionToggle={this.accordionToggle} showSubTitle={true} subTitle={"Correct in necessary and fill empty fields"}/>
            <div className="body-section-agreement" style={section["purchasePrice"] ? {display:"block"} : {display: "none"}}>
              <div className="col45">
                {
                  purchasePrice.map((element,index)=>{
                    let value = viewValues[element.id] ? viewValues[element.id] : viewValues[element.value] ? viewValues[element.value] : this.getValue(purchase.keywords, element.value ? element.value : element.id);
                    return(
                      <div className="col100 row-element" key={`element-${index}`}>
                        <div className="col52">
                          <div>
                            {
                              element.displayValue ? <label ><label className="label-number">{`${index+1}.`}</label>{`${element.displayValue}`}</label> :
                                <div style={{display:"flex"}}>
                                  <label className="label-number-input">{`${index+1}.`}</label>
                                  <div className="container-input-element">
                                    <input id={element.id} className="input-purchase form-control" value={this.getValue(purchase.keywords, element.id)}
                                           onChange={(evt) => this.inputChangeSale(evt, {section:"purchase",type:"text"},true)}/>
                                  </div>
                                </div>
                            }
                          </div>
                        </div>
                        <div className="col24">
                          <span className="dollar-prefix">$</span>
                          <div className="inputSale">
                            <DynamicNumber id={element.value ? element.value : element.id}
                                           className="form-control col-sm-9"
                                           placeholder={''}
                                           value={value}
                                           positive={true}
                                           negative={false}
                                           thousand={true}
                                           onChange={this.onChangeNumberDecimal.bind(this,"number")}
                                           onBlur={this.handleBlurNumber.bind(this,"number")}
                                           separator={'.'}
                                           integer={8}
                                           fraction={2}
                                           maxLength={11}
                                           disabled={ element.disabled ?element.disabled : false }/>
                          </div>
                        </div>
                        {
                          element.id2 ?
                            <div className="col24">
                              <InputDate classCol="flex-colR1" classItem="item"
                                         value={this.getValue(purchase.keywords,"balanceDue2")}
                                         onChange={this.onChangeDate} section="purchase" id="balanceDue2"/>
                            </div> : null
                        }
                      </div>
                    )
                  })
                }
                <div className="container-others-terms">
                  <div className="info-label">
                    <label  className="title">Other Terms agreed</label>
                  </div>
                  <div className="class-input">
                    <textarea name="" id="otherTermsAgreed" cols="30" rows="3" className="input-purchase form-control "
                              onChange={(evt) => this.inputChangeSale(evt, {section:"purchase",type:"text"}, true)}
                              value={this.getValue(purchase.keywords, "otherTermsAgreed")}/>
                  </div>
                </div>
              </div>
              <div className="col48">
                <div className="container-title"><label className="title">Trade-in and/or other credit</label></div>
                {
                  tradeIn && tradeIn.cars.length > 0 ?
                    <div className="container-trades-in">
                      {
                        tradeIn.cars.map((trade, index) => {
                          let make = this.getValue(trade.keywords, "carTradeInMake");
                          let model = this.getValue(trade.keywords, "carTradeInModel");
                          let year = this.getValue(trade.keywords, "carTradeInYear");
                          let allowance = this.getValue(trade.keywords, "carTradeInAllowance");
                          let balancedOwed = this.getValue(trade.keywords, "carTradeInBalanceOwedOnTradeIn");
                          return (
                            <div key={`container-trade-${index}`} className={"container-trade"}>
                              <label>{`${index + 1}. `}</label>
                              <div className="box-trade-in">
                                <div className="trade-in">
                                  <label onClick={() => this.editTrade(index)} className="label-trade-in" title="Edit">{`${make} ${model} ${year} `} </label>
                                  <div className="container-trade-allowance">
                                    <div className="container-label-allowance">
                                      <div className="left">
                                        <span className="trade-in-span">Trade-in</span>
                                        <span className="allowance-span">Allowance</span>
                                      </div>
                                      <div className="right">
                                        <span >$ {allowance}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="container-trade-allowance">
                                    <div className="container-label-allowance">
                                      <div className="left">
                                        <span className="trade-in-span">Balance Owed</span>
                                        <span className="allowance-span">on Trade-in</span>
                                      </div>
                                      <div className="right">
                                        <span >$ {balancedOwed}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <i className="fa fa-trash-o" aria-hidden="false" style={{fontSize: 24}} onClick={() => this.deleteTrade(index)} title="Delete"/>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div> : null
                }
                {
                  showNewTrade ?
                    <div className="title-new">
                      <label>New Trade-In</label>
                    </div> : null
                }
                <div className="first-container">
                  <div className="col100 section-agreement">
                    <div className="col25-select">
                      <div>
                        <label className="label-make">Year</label>
                        <span className="label-error">*</span>
                      </div>
                      <div className={errors["carTradeInYear"] ? "select has-error" : "select"} id="selectYear" onClick={(evt) => {
                        this.showPopoverYear("selectYear", evt)
                      }}>
                        <div className="container-label">
                          <label className="label-make">{this.getValue(tempTradeIn.keywords, "carTradeInYear")}</label>
                        </div>
                        <div className="container-icon">
                          <i className="fa fa-chevron-down"/>
                        </div>
                      </div>
                      {errors["carTradeInYear"] ?
                        <p className="help-block">{getErrorMessage("Year")}</p> : null}
                    </div>
                    <div className="col25-select">
                      <div>
                        <label className="label-make">Make</label>
                        <span className="label-error">*</span>
                      </div>
                      <div className={errors["carTradeInMake"] ? "select has-error" : "select"} id="selectMake" onClick={(evt) => {
                        this.showPopoverMake("selectMake", evt)
                      }}>
                        <div className="container-label">
                          <label className="label-make">{this.getValue(tempTradeIn.keywords, "carTradeInMake")}</label>
                        </div>
                        <div className="container-icon">
                          <i className="fa fa-chevron-down"/>
                        </div>
                      </div>
                      {errors["carTradeInMake"] ?
                        <p className="help-block">{getErrorMessage("Make")}</p> : null}
                    </div>
                    <div className="col25-select">
                      <div>
                        <label className="label-make">Series</label>
                        <span className="label-error">*</span>
                      </div>
                      <div className={errors["carTradeInModel"] ? "select has-error" : "select"} id="selectModel" onClick={(evt) => {
                        this.showPopoverModel("selectModel", evt)
                      }}>
                        <div className="container-label">
                          <label className="label-make">{this.getValue(tempTradeIn.keywords, "carTradeInModel")}</label>
                        </div>
                        <div className="container-icon">
                          <i className="fa fa-chevron-down"/>
                        </div>
                      </div>
                      {errors["carTradeInModel"] ?
                        <p className="help-block">{getErrorMessage("Series")}</p> : null}
                    </div>
                    <DynamicNumberElement classColumn={"col25"} title={"Odometer"} id={"carTradeInMileage"} type={"number"}
                                          value={this.getValue(tempTradeIn.keywords, "carTradeInMileage")} section="tempTradeIn" isRequired={true}
                                          onChange={this.onChangeNumber} separator={"."} hasChangeTradeIn={true}
                                          thousand={true} integer={8} fraction={2} maxLength={12} useData={false} placeHolder={"0.00"}
                                          error={errors["carTradeInMileage"]}/>
                  </div>
                  <div className="col100 section-agreement">
                    <div className="col25-select">
                      <div>
                        <label className="label-make">Body Type</label>
                        <span className="label-error">*</span>
                      </div>
                      <div className={errors["carTradeInBody"] ? "select has-error" : "select"} id="carTradeInBody" onClick={(evt) => {
                        this.showPopoverBodyType("carTradeInBody", evt)
                      }}>
                        <div className="container-label">
                          <label className="label-make">{this.getValue(tempTradeIn.keywords, "carTradeInBody")}</label>
                        </div>
                        <div className="container-icon">
                          <i className="fa fa-chevron-down"/>
                        </div>
                      </div>
                      {errors["carTradeInBody"] ?
                        <p className="help-block">{getErrorMessage("Body Type")}</p> : null}
                    </div>
                    <div className="col25-select">
                      <div>
                        <label className="label-make">Sales Person</label>
                        <span className="label-error">*</span>
                      </div>
                      <div className={errors["carTradeInSalesPerson"] ? "select has-error" : "select"} id="carTradeInSalesPerson" onClick={(evt) => {
                        this.showPopoverSalesMan("carTradeInSalesPerson", evt)
                      }}>
                        <div className="container-label">
                          <label className="label-make">{this.getValue(tempTradeIn.keywords, "carTradeInSalesPerson")}</label>
                        </div>
                        <div className="container-icon">
                          <i className="fa fa-chevron-down"/>
                        </div>
                      </div>
                      {errors["carTradeInSalesPerson"] ?
                        <p className="help-block">{getErrorMessage("Sales Person")}</p> : null}
                    </div>
                    <InputSection  classColumn="col50" title="VIN Number" id="carTradeInVin" type="text"
                                   value={this.getValue(tempTradeIn.keywords, "carTradeInVin")} onChange={this.inputChangeSale}
                                   useData={false} hasChangeTradeIn={true} isRequired={true} error={errors["carTradeInVin"]} maxLength={17} />
                    {
                      showPopoverYear ?
                        <Popover anchorEl={anchorElStatus}
                                 child={this.renderYears()}
                                 classPopover="popover-make"
                                 handleRequestClose={this.handleRequestClose}/> : null
                    }
                    {
                      showPopoverMake ?
                        <Popover anchorEl={anchorElStatus}
                                 child={this.renderMakes()}
                                 classPopover="popover-make"
                                 handleRequestClose={this.handleRequestClose}/> : null
                    }
                    {
                      showPopoverBodyType ?
                        <Popover anchorEl={anchorElStatus}
                                 child={this.renderBodyType()}
                                 classPopover="popover-make"
                                 handleRequestClose={this.handleRequestClose}/> : null
                    }
                    {
                      showPopoverSalesMan ?
                        <Popover anchorEl={anchorElStatus}
                                 child={this.renderSalesMan()}
                                 classPopover="popover-make"
                                 handleRequestClose={this.handleRequestClose}/> : null
                    }
                    {
                      showPopoverModel && makeIdSelected ?
                        <Popover anchorEl={anchorElStatus}
                                 child={this.renderModels()}
                                 classPopover="popover-make"
                                 handleRequestClose={this.handleRequestClose}/> : null
                    }
                  </div>
                  <div className="separator-container">
                    <div className="line-separator-container"/>
                  </div>
                </div>
                <div className="second-container">
                  <div className="col100 section-agreement">
                    <DynamicNumberElement classColumn={"col50"} title={"Trade-in allowance"} id={"carTradeInAllowance"} type={"PRICE"}
                                          value={viewValues["carTradeInAllowance"] ? viewValues["carTradeInAllowance"] : this.getValue(tempTradeIn.keywords, "carTradeInAllowance")}
                                          section="tempTradeIn" isRequired={true} onChange={this.onChangeNumber} separator={"."} hasChangeTradeIn={true}
                                          thousand={true} integer={8} fraction={2} maxLength={12} useData={false} placeHolder={"0.00"}
                                          error={errors["carTradeInAllowance"]}/>
                    <InputSection classColumn="col50" title="Balanced owed to" id="carTradeInBalanceOwedTo" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInBalanceOwedTo")} onChange={this.inputChangeSale}
                                  useData={false} hasChangeTradeIn={true} isRequired={true} error={errors["carTradeInBalanceOwedTo"]}/>
                  </div>
                  <div className="col100 section-agreement">
                    <DynamicNumberElement classColumn={"col50"} title={"Balanced owed on Trade-in"} id={"carTradeInBalanceOwedOnTradeIn"} type={"PRICE"}
                                          value={viewValues["carTradeInBalanceOwedOnTradeIn"] ? viewValues["carTradeInBalanceOwedOnTradeIn"] : this.getValue(tempTradeIn.keywords, "carTradeInBalanceOwedOnTradeIn")}
                                          section="tempTradeIn" isRequired={true} onChange={this.onChangeNumber} separator={"."} hasChangeTradeIn={true}
                                          thousand={true} integer={8} fraction={2} maxLength={12} useData={false} placeHolder={"0.00"}
                                          error={errors["carTradeInBalanceOwedOnTradeIn"]}/>
                    <InputSection classColumn="col50" title="Address" id="carTradeInAddress" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInAddress")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                  </div>
                  <div className="col100 section-agreement">
                    <InputSection classColumn="col25" title="Pay off" id="carTradeInPayOff" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInPayOff")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    <InputSection classColumn="col50" title="Verified By" id="carTradeInVerifiedBy" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInVerifiedBy")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    <InputSection classColumn="col25" title="Good" id="carTradeInGoodUntil" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInGoodUntil")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                  </div>
                  <div className="col100 section-agreement">
                    <div className="col25-select">
                      <div>
                        <label className="label-make">Condition</label>
                      </div>
                      <div className="select" id="carTradeInCondition" onClick={(evt) => {
                        this.showPopoverCondition("carTradeInCondition", evt)
                      }}>
                        <div className="container-label">
                          <label className="label-make">{this.getValue(tempTradeIn.keywords, "carTradeInCondition")}</label>
                        </div>
                        <div className="container-icon">
                          <i className="fa fa-chevron-down"/>
                        </div>
                      </div>
                    </div>
                    <div className="col25">
                      <InputDate classCol="flex-colR1" classItem="item"
                                 value={this.getValue(tempTradeIn.keywords,"carTradeInDateOff")} displayValue="Date Off"
                                 onChange={this.onChangeDate} section="tempTradeIn" id="carTradeInDateOff" hasChangeTradeIn={true}/>
                    </div>
                    <InputSection classColumn="col25" title="Verification" id="carTradeInVerification" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInVerification")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    <InputSection classColumn="col25" title="Acc. #" id="carTradeInAcc" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInAcc")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    {
                      showPopoverCondition ?
                        <Popover anchorEl={anchorElStatus}
                                 child={this.renderConditions()}
                                 classPopover="popover-make"
                                 handleRequestClose={this.handleRequestClose}/> : null
                    }
                  </div>
                  <div className="section-message section-agreement">
                    <div className="container-icon-body-title">
                      <div className="container-icon-exclamation">
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                                  className="icon-error"/>
                      </div>
                      <div className="container-message">
                        <label  className="title">Warranty as to Balance owed on Trade-in vehicle :</label>
                        <label className="body-title">Purchaser warrants that he/she has given Seller a true pay-off
                          amount on any vehicle traded in, and that if it is not correct and is greater than the amounts
                          show above, Purchaser will pay the excess to Seller on demand</label>
                      </div>
                    </div>
                  </div>
                  <div className="separator-container">
                    <div className="line-separator-container"/>
                  </div>
                </div>
                <div className="third-container">
                  <div className="col100 section-agreement">
                    <InputSection classColumn="col25" title="Registration" id="carTradeInRegistration" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInRegistration")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    <InputSection classColumn="col25" title="Bill of Sale" id="carTradeInBillOfSale" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInBillOfSale")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    <InputSection classColumn="col25" title="Power of Attorneys" id="carTradeInPowerOfAttorneys" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInPowerOfAttorneys")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    <InputSection classColumn="col25" title="Odometer" id="carTradeInOdometer" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInOdometer")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                  </div>
                  <div className="col100 section-agreement">
                    <InputSection classColumn="col25" title="Property TAX" id="carTradeInPropertyTAX" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInPropertyTAX")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    <InputSection classColumn="col50" title="Authorization for Pay off" id="carTradeInAuthorizationForPayOff" type="text"
                                  value={this.getValue(tempTradeIn.keywords, "carTradeInAuthorizationForPayOff")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                    <InputSection classColumn="col25" title="Statement" id="carTradeInStatement" type="text"
                                  value= {this.getValue(tempTradeIn.keywords, "carTradeInStatement")} onChange={this.inputChangeSale} useData={false} hasChangeTradeIn={true}/>
                  </div>
                  <div className="col100 section-agreement">
                    <div className="col100">
                      <button className="btn-add-trade-in"
                              style={!hasChangeTradeIn?{color: "#fdfdfd",border: "1px solid #fdfdfd",background: "#b2b2b2",cursor: "not-allowed"}
                                : {color: "#2194f1",border: "1px solid #2194f1",background: "#ffffff",cursor: "pointer"}}
                              disabled={!hasChangeTradeIn} onClick={() => this.addTradeIn()}>Add another trade-in</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-section-agreement">
            <SectionHeaderAgreement id="sellerAgrees" isOpen={section["sellerAgrees"]} title="Seller agrees to seek arrangements for Financing"
                                    accordionToggle={this.accordionToggle} showSubTitle={true} subTitle={"All the fields are needed"}
                                    showSwitch={true} idSwitch="sellerAgrees" showSection={section["sellerAgrees"]} section={"sellerAgrees"} onChange={this.accordionToggle}/>
            <div className="body-section-agreement" style={section["sellerAgrees"] ? {display: "block"} : {display: "none"}}>
              <div className="container-input-labels">
                <label htmlFor="">The purchaser of the motor vehicle described in this contract has executed the contract in reliance upon the seller's
                  representation that seller can provide financing arrangements for the purchase of the motor vehicle The primary terms of the financing are as follows:
                  Interest rate between
                  <DynamicNumberElement classColumn={"box-input"}  id={"interestRateYearFrom"} type={"PERCENT"}
                                        value={viewValues["interestRateYearFrom"] ? viewValues["interestRateYearFrom"] : this.getValue(sellerRate.keywords, "interestRateYearFrom")}
                                        section="sellerRate" isRequired={false} onChange={this.onChangeNumber} separator={"."} hasChange={false}
                                        thousand={false} integer={3} fraction={2} maxLength={5} useData={true} placeHolder={"          %"}/>and
                  <DynamicNumberElement classColumn={"box-input"}  id={"interestRateYearTo"} type={"PERCENT"}
                                        value={viewValues["interestRateYearTo"] ? viewValues["interestRateYearTo"] : this.getValue(sellerRate.keywords, "interestRateYearTo")}
                                        section="sellerRate" isRequired={false} onChange={this.onChangeNumber} separator={"."} hasChange={false}
                                        thousand={false} integer={3} fraction={2} maxLength={5} useData={true} placeHolder={"          %"}/>per annum, term between
                  <DynamicNumberElement classColumn={"box-input"}  id={"interestRateMonthFrom"} type={"PERCENT"}
                                        value={viewValues["interestRateMonthFrom"] ? viewValues["interestRateMonthFrom"] : this.getValue(sellerRate.keywords, "interestRateMonthFrom")}
                                        section="sellerRate" isRequired={false} onChange={this.onChangeNumber} separator={"."} hasChange={false}
                                        thousand={false} integer={3} fraction={2} maxLength={5} useData={true} placeHolder={"          %"}/>months and
                  <DynamicNumberElement classColumn={"box-input"}  id={"interestRateMonthTo"} type={"PERCENT"}
                                        value={viewValues["interestRateMonthTo"] ? viewValues["interestRateMonthTo"] : this.getValue(sellerRate.keywords, "interestRateMonthTo")}
                                        section="sellerRate" isRequired={false} onChange={this.onChangeNumber} separator={"."} hasChange={false}
                                        thousand={false} integer={3} fraction={2} maxLength={5} useData={true} placeHolder={"            %"}/>Monthly payments between
                  <DynamicNumberElement classColumn={"box-input"}  id={"monthlyPaymentsFrom"} type={"PRICE"}
                                        value={viewValues["monthlyPaymentsFrom"] ? viewValues["monthlyPaymentsFrom"] : this.getValue(sellerRate.keywords, "monthlyPaymentsFrom")}
                                        section="sellerRate" isRequired={false} onChange={this.onChangeNumber} separator={"."} hasChange={false}
                                        thousand={true} integer={8} fraction={2} maxLength={11} useData={true} placeHolder={"$"}/>per month and
                  <DynamicNumberElement classColumn={"box-input"}  id={"monthlyPaymentsTo"} type={"PRICE"}
                                        value={viewValues["monthlyPaymentsTo"] ? viewValues["monthlyPaymentsTo"] : this.getValue(sellerRate.keywords, "monthlyPaymentsTo")}
                                        section="sellerRate" isRequired={false} onChange={this.onChangeNumber} separator={"."} hasChange={false}
                                        thousand={true} integer={8} fraction={2} maxLength={11} useData={true} placeHolder={"$"}/>per month based on a down payment of
                  <DynamicNumberElement classColumn={"box-input"}  id={"downPayment"} type={"PRICE"}
                                        value={viewValues["downPayment"] ? viewValues["downPayment"] : this.getValue(sellerRate.keywords, "downPayment")}
                                        section="sellerRate" isRequired={false} onChange={this.onChangeNumber} separator={"."} hasChange={false}
                                        thousand={true} integer={8} fraction={2} maxLength={10} useData={true} placeHolder={"$"}/></label>
                <label htmlFor="">If sellers is not able to arrange financing within the terms disclosed, then seller must, within seven calendar days
                  of the date sale, mail notice to the purchase that she/he has not been able to arrange financing. Purchaser then has 14 days from date of sale to elect,
                  if purchaser chooses, to rescind the contract of sale pursuant to section 41-3-401.</label>
              </div>
            </div>
          </div>
          <div className="container-section-agreement">
            <SectionHeaderAgreement id="insurance" isOpen={section["insurance"]} title="Insurance" accordionToggle={this.accordionToggle}
                                    showSubTitle={false} idSwitch="insurance" showSwitch={true} showSection={section["insurance"]}
                                    section={"insurance"} onChange={this.accordionToggle}/>
            <div className="body-section-agreement" style={section["insurance"] ? {display: "block"} : {display: "none"}}>
              <div className="col100 section-agreement">
                <InputSection classColumn="col42" title="Insurance Company name" id="insuranceCompanyName" type="text"
                              value={this.getValue(insurance.keywords, "insuranceCompanyName")} onChange={this.inputChangeSale} section="insurance" useData={true}/>
                <InputSection classColumn="col20" title="# of Policy" id="insuranceCompanyPolicy" type="text"
                              value={this.getValue(insurance.keywords, "insuranceCompanyPolicy")} onChange={this.inputChangeSale} section="insurance" useData={true}/>
              </div>
            </div>
          </div>
        </div>
        <GenericModalConfirm show={showModalConfirm}
                             onHide={this.closeModal}
                             save={this.printPDF}
                             message={"Not all mandatory fields have been filled. Are you sure you want to print?"}
                             showButtonCancel={true}
                             buttonLabelSave={"Yes, Print it"}
                             buttonLabelCancel={"Not yet"}
                             showCustomIcon={true}

        />
      </div>
    )
  }
}