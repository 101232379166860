import React from 'react'
import {getServerUrl} from '../../Api'
import propTypes from 'prop-types'
import {connect} from 'react-redux'
import {setKeyword} from '../../actions'
import {MODULE_USER} from '../../Constants'
import {modalObjectToShow} from '../util/Utils'

const messageBodyCustom = props => {
    const {hasSale,hasTaskRepair, isCostAttached} = props
    const isPageUser = props.moduleSelected === MODULE_USER;
    let isAttached = props.hasTaskRepair >= 1 || hasSale >= 1
    const objectShow = modalObjectToShow(isPageUser, isAttached, isCostAttached)
    const taskRepairURl = isPageUser ? `${getServerUrl()}main/task/` : `${getServerUrl()}main/task/repair/vin/${props.vin}`
    const saleURl = isPageUser ? `${getServerUrl()}main/sale` : `${getServerUrl()}main/sale/vin/${props.vin}`
    const attachedMessage = "This " + objectShow + " is attached to "
    const amountSales = hasSale === 1 ? ` a Sale` : ` ${hasSale} Sales`
    const amountRepairs = hasTaskRepair === 1 ? ` a Repair` : ` ${hasTaskRepair} Repairs`
    let body = null
    let footer = null
    if (isAttached) {
        footer = <footer style={{fontSize: ".8em", position: "absolute", bottom: "20px"}}>You can deactivate
            this {objectShow} until you are able to delete it instead</footer>
        if (hasTaskRepair >= 1 && hasSale >= 1) {
            body = (
                <div>
                    <div>1. {attachedMessage}<a onClick={() => {setKeyword(props.vin);window.open(taskRepairURl)
                    }}>{amountRepairs} {!isPageUser && props.repair}</a></div>
                    <div>2. {attachedMessage}<a onClick={() =>{setKeyword(props.vin); window.open(saleURl)}}>{amountSales}</a></div>
                </div>
            )
        } else {
            hasSale ? body = <div>1. {attachedMessage} <a onClick={() => { setKeyword(props.vin); window.open(saleURl)}}>{amountSales}</a></div>
                : body =
                    <div>1. {attachedMessage}<a onClick={() => {
                          setKeyword(props.vin);
                          window.open(taskRepairURl)}}
                    >{amountRepairs}
                      {!isPageUser && props.repair}</a></div>
        }
    } else {
        body = <label className="link-vin-exist">There is
            <span
                onClick={() => window.open(`${getServerUrl()}main/inventory/${props.vin}`, "_blank")}>another {objectShow}</span>
            with the same VIN Number already active</label>
    }

    const {attachedReceipts,attachedTask } = props

    if(isCostAttached) {
        isAttached = isCostAttached
        let count = 1
        const taskURL = attachedTask? `${getServerUrl()}main/task/${attachedTask.taskId}` : null
        const receiptMessage = attachedReceipts ? <div>{count}. {attachedReceipts.message}.</div> : null
        if(receiptMessage) count += 1
        const taskMessage = attachedTask ? <div>{count}. {attachedTask.message} : <a onClick={() => {window.open(taskURL, "_self")}}>Click here.</a></div> : null

        body = (
            <div>
                {receiptMessage}
                <br/>
                {taskMessage}
            </div>
          )
    }

    return (
        <div>
            <label>This {objectShow} can't be {isAttached ? "deleted" : "activated"} because the following
                reasons:</label>
            {body}
            {footer}
        </div>
    )
}

messageBodyCustom.propTypes = {
    hasTaskRepair: propTypes.number,
    hasSale: propTypes.number,
    vin: propTypes.string,
    moduleSelected: propTypes.string
}

export default connect(null, {setKeyword})(messageBodyCustom)