import React from 'react'
import User from '../dashboard/User'
import QuickActions from '../dashboard/quickActions/QuickActions'
import Calendar from '../dashboard/Calendar'
import Report from '../dashboard/reports/Report'

const SaleTechLayout = props => {
  return(
    <main id="sale-tech-layout">
      <section id="graph">
        <Report />
      </section>
      <section id="calendar">
        <Calendar />
      </section>
      <section id="quick-actions">
        <QuickActions />
      </section>
      <section id="user">
        <User />
      </section>
    </main>
  )
}

export default SaleTechLayout