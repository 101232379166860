import React, { useEffect, useState } from 'react'
import {
  getDealerInfo,
  createOrderFromDealer,
  loadSubscriptions,
  loadLastOrder,
  loadInvoices
} from '../../../../Api'
import { ReactSVG } from 'react-svg'
import {
  ORDER_NUMBER_DEFAULT,
  pathServer,
  SUCCESSFUL_CODE,
} from '../../../../../common/Constants'
import { browserHistory } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import { HeaderSectionCustomer } from './HeaderSectionCustomer'
import { BodyDealer } from './BodyDealer'
import { DPSpinner } from '../../../../common/DPSpinner'
import AccountOwners from './AccountOwners'
import FileTemplates from './FileTemplates'
import { unstable_batchedUpdates } from 'react-dom'
import Invoices from './Invoices'
import { Modal } from 'react-bootstrap'
import { DPInput } from '../../../../common/DPInput'
import Moment from 'moment'
import DPKeyboardDatePicker from '../../../../../common/UI/DPKeyboardDatePicker'
import DPIcon, { Icons } from '../../../../common/DPIcon'
import DPSelectionDropDown from '../../../../common/DPSelectionDropDown'
import DPButton from '../../../../common/DPButton'
import sortBy from 'lodash/sortBy'
import { getNewDate, roundDayDurationBtwDates } from '../../../util/Utils'
import {DUE_DAYS, MONTH_DAYS} from '../../../../Constants'
import reverse from 'lodash/reverse'
import { CreateSubscriptionModal } from '../../../setting/billing/CreateSubscriptionModal'
import { CurrentDueInformationCard } from '../../../setting/billing/BillingContainer'
import { GraphsSection } from '../../../dashboard/components/layout/SubscriptionLayout'

require('./dealerInfo.scss')


const SECTIONS = {
  GENERAL_INFO: 'dealerInformation',
  ACCOUNT_OWNERS: 'accountOwners',
  FILE_TEMPLATES: 'fileTemplates',
  INVOICES: 'invoices',
  SUBSCRIPTION: 'subscription'
}

const Index = ({ params }) => {
  const [isLoading, setLoading] = useState(false)
  const [dealer, setDealer] = useState({})
  const [accountOwners, setAccountOwners] = useState([])
  const [section, setSection] = useState({
    [SECTIONS.GENERAL_INFO]: true,
    [SECTIONS.ACCOUNT_OWNERS]: true,
    [SECTIONS.FILE_TEMPLATES]: true,
    [SECTIONS.INVOICES]: true,
    [SECTIONS.SUBSCRIPTION]: true
  })
  const [fileTemplates, setFileTemplates] = useState([])
  const [invoices, setInvoices] = useState([])
  const [showGenerateInvoiceModal, setGenerateInvoiceModal] = useState(false)

  const [startInvoiceDate, setStartInvoiceDate] = useState(Moment())
  const [endInvoiceDate, setEndInvoiceDate] = useState(Moment())
  const [dueInvoiceDate, setDueInvoiceDate] = useState(Moment())

  const [reloadPage, setReloadPage] = useState(true)
  const [inventoryItems, setInventoryItems] = useState(undefined)
  const [selectedInventoryItem, setSelectedInventoryItem] = useState(undefined)
  const [lastInvoiceNumber, setLastInvoiceNumber] = useState(ORDER_NUMBER_DEFAULT)
  const [isEnabledSaveBtn, setIsEnabledSaveBtn] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [quantitySelectedInventoryItem, setQuantitySelectedInventoryItem] = useState(1)

  const [id] = useState(params.id)
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [showModal, setModalStatus] = useState(false)
  const [currentOrder, setCurrentOrder] = useState(null)
  const [includedFeatures, setIncludedFeatures] = useState([])
  const [notIncludedFeatures, setNotIncludedFeatures] = useState([])
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [listings, setListings] = useState(0)
  const [teamMembers, setTeamMembers] = useState(0)

  useEffect(() => {
   const loadDealer = async () => {
    setLoading(true)
    const currentDate = Moment()

    const {responseCode, responseMessage, data} = await getDealerInfo(id)
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      setLoading(false)
      return
    }
    const {dealer, accountOwners, fileTemplates, invoices, subscriptions} = data
    let invoiceNumber
    let sortedInvoices = invoices
    if (invoices.length > 0) {
      sortedInvoices = reverse(sortBy(invoices.map(el => el.order), ['orderNumber']))
      invoiceNumber = sortedInvoices[0]['orderNumber']
    }
    const {responseCode: responseCodeSubs, responseMessage: responseMessageSubs, data: dataSubs} = await loadSubscriptions()
    let newInventoryItems = undefined
    if (responseCodeSubs === SUCCESSFUL_CODE) {
      newInventoryItems = dataSubs.inventoryItems.map(el => {
        return (
          {
            ...el,
            displayValue: el.description
          }
        )
      })
    }
    const {responseCode: responseCodeInvoice, responseMessage: responseMessageInvoice, data: dataInvoice} = await loadInvoices(id)
    if (responseCodeInvoice !== SUCCESSFUL_CODE) {
      console.error(responseMessageInvoice)
    }

    const {paymentMethods} = dataInvoice ?? {}

     const {
       responseCode: responseCodeOrder,
       responseMessage: responseMessageOrder,
       data: dataLO
     } = await loadLastOrder(id)

     if (responseCodeOrder !== SUCCESSFUL_CODE) {
       console.error(responseMessageOrder)
     }

     const {
       order,
       paymentMethod,
       includedFeatures,
       notIncludedFeatures,
       usersQuantity,
       listingsQuantity
     } = dataLO ?? {}
     unstable_batchedUpdates(() => {
       setLoading(false)
       setDealer(dealer)
       setAccountOwners(accountOwners)
       setFileTemplates(fileTemplates)
       setInvoices(sortedInvoices)
       setCurrentSubscription(subscriptions[0])
       setGenerateInvoiceModal(false)
       setCurrentOrder(order)
       setPaymentMethod(paymentMethod)
       setPaymentMethods(paymentMethods)
       setIncludedFeatures(includedFeatures)
       setNotIncludedFeatures(notIncludedFeatures)
       setTeamMembers(usersQuantity || 0)
       setListings(listingsQuantity || 0)

       setStartInvoiceDate(Moment(getNewDate(currentDate)))
       setEndInvoiceDate(Moment(getNewDate(currentDate)).add(MONTH_DAYS, 'days'))
       setDueInvoiceDate(Moment(getNewDate(currentDate)).add(MONTH_DAYS + DUE_DAYS, 'days'))

       setInventoryItems(newInventoryItems)
       if (invoiceNumber)
         setLastInvoiceNumber(invoiceNumber)
       setIsEnabledSaveBtn(false)
       setQuantitySelectedInventoryItem(1)
     })
   }

   if (reloadPage) {
    loadDealer().then()
   }
  }, [reloadPage])

  const goBack = () => browserHistory.push({ pathname: '/main/superusers/dealers' })

  const accordionToggle = (id) => setSection({ ...section, [id]: !section[id] })

  const goToFileTemplate = (id, dealer) => {
    browserHistory.push({
      pathname: `/main/superusers/dealers/edit/${dealer.id}/fileTemplate/${id}`,
      state: { dealerId: dealer.id, dealerName: dealer.name }
    })
    //browserHistory.push(`/main/dealers/edit/${id}`);
  }

  const save = async () => {
    let orderItems = null

    if (!selectedInventoryItem) {
      return
    }

    const inventoryItem = inventoryItems.find(el => el.id === selectedInventoryItem)

    orderItems = [{
      inventoryItemId: inventoryItem.id,
      quantity: quantitySelectedInventoryItem,
      unitPrice: inventoryItem.price,
      status: 'unpaid'
    }]

    const body = {
      order: {
        startDate: Moment(getNewDate(startInvoiceDate)).valueOf(),
        endDate: Moment(getNewDate(endInvoiceDate)).valueOf(),
        dueDate: Moment(getNewDate(dueInvoiceDate)).valueOf(),
        taxAmount: 0,
        status: 'unpaid',
        dealerId: dealer.id,
        orderItems: orderItems,
      }
    }

    unstable_batchedUpdates(() => {
      setReloadPage(false)
      setIsProcessing(true)
    })

    const response = await createOrderFromDealer(body)
    if (response.responseCode === SUCCESSFUL_CODE) {
      unstable_batchedUpdates(() => {
        setGenerateInvoiceModal(false)
        setLoading(false)
        setReloadPage(true)
        setSelectedInventoryItem(undefined)
        setIsEnabledSaveBtn(false)
        setIsProcessing(false)
      })
    }
    else {
      unstable_batchedUpdates(() => {
        setSelectedInventoryItem(undefined)
        setIsEnabledSaveBtn(false)
        setIsProcessing(false)
      })
    }
  }

  const closeModal = () => {
    if (!isProcessing) {
      unstable_batchedUpdates(() => {
        setGenerateInvoiceModal(false)
        setLoading(false)
        setSelectedInventoryItem(undefined)
        setIsEnabledSaveBtn(false)
      })
    }
  }

  const setStartDateForInvoice = (date) => {
    const startDate = Moment(getNewDate(date))
    const roundedDayDuration = roundDayDurationBtwDates(startDate, endInvoiceDate)
    const endDate = Moment(getNewDate(date)).add(roundedDayDuration, 'days')
    const dueDate = Moment(getNewDate(date)).add(roundedDayDuration + DUE_DAYS, 'days')

    try {
      unstable_batchedUpdates(() => {
        setStartInvoiceDate(startDate)
        setEndInvoiceDate(endDate)
        setDueInvoiceDate(dueDate)
        setQuantitySelectedInventoryItem(roundedDayDuration / MONTH_DAYS)
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const setEndDateForInvoice = (date) => {
    const roundedDayDuration = roundDayDurationBtwDates(startInvoiceDate, Moment(getNewDate(date)))
    const endDate = Moment(getNewDate(startInvoiceDate)).add(roundedDayDuration, 'days')
    const dueDate = Moment(getNewDate(startInvoiceDate)).add(roundedDayDuration + DUE_DAYS, 'days')

    try {
      unstable_batchedUpdates(() => {
        setEndInvoiceDate(endDate)
        setDueInvoiceDate(dueDate)
        setQuantitySelectedInventoryItem(roundedDayDuration / MONTH_DAYS)
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const handleSelectPlan = (selectedItem) => {
    unstable_batchedUpdates(() => {
      setSelectedInventoryItem(selectedItem)
      setIsEnabledSaveBtn(true)
    })
  }

  const swapModalStatus = async () => {
    setModalStatus(!showModal)
  }

  const Upgrade = () => {
    browserHistory.push({ pathname: '/main/updatePlan' })
  }

  return (
    <div className="dealer-content">
      {isLoading ? <DPSpinner/> : null}
      <div className="container-header">
        <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
                  beforeInjection={svg => svg.classList.add('icon-back')}
                  className="arrow-back" onClick={goBack}/>
        <div className="dealer-title">
          Dealer
        </div>
        <div className="arrow-right">
          <i style={{ color: '#898989' }} className="fa fa-chevron-right fa-lg"/>
        </div>
        <div className="dealer-name" data-testid="dealer-name">
          {dealer.name}
        </div>
      </div>
      <div id="bodyDealer" className="container-center">
        <div className="setting-container">
          <div className="accordion-section dealer-info">
            <HeaderSectionCustomer
              id={SECTIONS.GENERAL_INFO}
              isOpen={section[SECTIONS.GENERAL_INFO]}
              title="General Information"
              accordionToggle={accordionToggle}
            />
            <div className="section-body">
              {
                section[SECTIONS.GENERAL_INFO] && !isEmpty(dealer) ?
                  <BodyDealer
                    dbDealer={dealer}
                    setIsLoading={setLoading}
                  />
                  : null
              }
            </div>
          </div>
          <div className="accordion-section account-owner-info">
            <HeaderSectionCustomer
              id={SECTIONS.ACCOUNT_OWNERS}
              isOpen={section[SECTIONS.ACCOUNT_OWNERS]}
              title="Account owners"
              accordionToggle={accordionToggle}
            />
            <div className="section-body">
              {
                section[SECTIONS.ACCOUNT_OWNERS] ? <AccountOwners accountOwners={accountOwners}/> : null
              }
            </div>
          </div>
          <div className="accordion-section file-templates-section">
            <HeaderSectionCustomer
              id={SECTIONS.FILE_TEMPLATES}
              isOpen={section[SECTIONS.FILE_TEMPLATES]}
              title={'File Templates'}
              accordionToggle={accordionToggle}
              onAddNew={(evt) => goToFileTemplate('new', dealer, evt)}
            />
            <div className="section-body">
              {
                section[SECTIONS.FILE_TEMPLATES]
                  ? <FileTemplates
                    fileTemplates={fileTemplates}
                    dealer={dealer}
                    goToFileTemplate={goToFileTemplate}
                  />
                  : null
              }
            </div>
          </div>
          <div className="accordion-section subscriptions-section">
            <HeaderSectionCustomer
              id={SECTIONS.SUBSCRIPTION}
              isOpen={section[SECTIONS.SUBSCRIPTION]}
              title={'Plan'}
              accordionToggle={accordionToggle}
              onAddNew={currentSubscription ? null : () => swapModalStatus()}
            />
            <CreateSubscriptionModal swapModalStatus={swapModalStatus} showModal={showModal} setReload={setReloadPage}
                                     paymentMethods={paymentMethods}
                                     title="Add Plan"
                                     dealerId={id}/>
            <div className="section-body">
              {section[SECTIONS.SUBSCRIPTION] &&
                <>
                  {
                    (currentSubscription) ?
                      <div className="plan-container">
                        <GraphsSection onClick={Upgrade} order={currentOrder} paymentMethodMain={paymentMethod} isUpgradeDisabled={true}/>
                        <CurrentDueInformationCard order={currentOrder} includedFeatures={includedFeatures}
                                                   notIncludedFeatures={notIncludedFeatures} isLoading={isProcessing}
                                                   onClick={swapModalStatus} listings={listings}
                                                   teamMembers={teamMembers} isUpgradeDisabled={true}/>
                      </div> :
                      <div className="noPlanSelected-message">There is any plan selected yet</div>
                  }
                </>
              }
            </div>
          </div>
          <div className="accordion-section invoices-section">
            <HeaderSectionCustomer
              id={SECTIONS.INVOICES}
              isOpen={section[SECTIONS.INVOICES]}
              title={'Invoices'}
              accordionToggle={accordionToggle}
              onAddNew={() => setGenerateInvoiceModal(true)}
            />
            <div className="section-body">
              {section[SECTIONS.INVOICES] && invoices && invoices.length > 0
                ? <Invoices invoices={invoices}/>
                : null
              }
            </div>
          </div>
        </div>
      </div>

      {
        showGenerateInvoiceModal &&
        <Modal
          show={showGenerateInvoiceModal}
          className="modal-generate-invoice"
        >
          <Modal.Body>
            <DPIcon
              icon={Icons.CLOSE_ICON}
              onClick={closeModal}
              iconClasses={isProcessing ? 'close-disable' : ''}
            />
            <h4><b>New invoice</b></h4>
            <div className="new-invoice-row-container">
              <DPInput
                className={'input-order-number'}
                id="orderNumber"
                title="Order number"
                value={(lastInvoiceNumber + 1).toString()}
                disable={true}
              />
              <DPInput
                className={'input-order-status'}
                id="orderStatus"
                title="Order status"
                value={'Pending'}
                disable={true}
              />
            </div>
            <div className="new-invoice-row-container">
              <div className="new-invoice-row-column-container">
                <label>Start date</label>
                <DPKeyboardDatePicker
                  id="startInvoiceDate"
                  name={'startInvoiceDate'}
                  value={startInvoiceDate}
                  className="form-control finance-input"
                  onChange={(date) => setStartDateForInvoice(date)}
                />
              </div>
              <div className="new-invoice-row-column-container">
                <label>End date</label>
                <DPKeyboardDatePicker
                  id="endInvoiceDate"
                  name={'endInvoiceDate'}
                  value={endInvoiceDate}
                  className="form-control finance-input"
                  onChange={(date) => setEndDateForInvoice(date)}
                />
              </div>
            </div>
            <div className="new-invoice-row-container">
              <div className="new-invoice-row-column-container">
                <label>Due date</label>
                <DPKeyboardDatePicker
                  id="dueInvoiceDate"
                  name={'dueInvoiceDate'}
                  value={dueInvoiceDate}
                  className="form-control finance-input"
                  onChange={() => {
                  }}
                  disabled={true}
                />
              </div>
              <div className="new-invoice-row-column-container">
                <label>Plans</label>
                <DPSelectionDropDown
                  id="starRangeTo"
                  placeholder="Plans"
                  options={inventoryItems}
                  value={selectedInventoryItem}
                  onClick={(selectedItem) => handleSelectPlan(selectedItem)}
                />
              </div>
            </div>
            <div className="new-invoice-row-container">
              <div className="new-invoice-btn-container">
                <DPButton
                  className={(!isEnabledSaveBtn || isProcessing) ? 'button-green-disabled' : 'button-green'}
                  onClick={save}
                  disabled={!isEnabledSaveBtn || isProcessing}
                >
                  {isProcessing ? 'Saving...' : 'Save'}
                </DPButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
    </div>
  )
}

export default Index
