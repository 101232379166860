import React, {Component} from 'react'
import {
  LOCATION_STATUS_ACTIVE,
  LOCATION_STATUS_INACTIVE,
  LOCATION_STATUS_DELETED
} from '../../../Constants.js'
import {updateLocationStatus, loadLocationsApi} from '../../../Api'
import {ReactSVG} from 'react-svg'
import SettingLocationModal from './SettingLocationModal.jsx'
import ModalError from '../../util/ModalError.jsx'
import {find} from 'lodash'
import ModalConfirmDelete from '../../util/ModalConfirmDelete.jsx'
import {browserHistory} from 'react-router'
import {Popover} from '../../util/ComponentUtils'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'
import {getPosition} from '../../../../common/Util'

const locationStatuses = [
  {
    code: LOCATION_STATUS_ACTIVE,
    value: "Active",
    color: "#4AD991"
  },
  {
    code: LOCATION_STATUS_INACTIVE,
    value: "Inactive",
    color: "#FF6565"
  }
];

export default class LocationSettingList extends Component {
  constructor() {
    super();
    this.state = {
      allLocations: [],
      showPopover: '',
      showLoading: false,
      locationId: null,
      showErrorModal: false,
      errorMessage: "",
      tryDate: null,
      showModalConfirm: false,
      showModal : false,
      selectStatus: null
    }
  }

  componentDidMount() {
    this.searchForm()
  }

  searchForm = () => {
    this.setState({showLoading: true, locationId: null});
    loadLocationsApi().then((response) => {
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({allLocations: data.locations, showLoading: false})
      } else {
        this.setState({showLoading: false})
      }
    })
  }
  onEdit = (id,e) => {
    e.stopPropagation();
    this.setState({locationId:id,showModal: true});
  }

  changeStatus = (status) => {
    this.setState({showPopover: false, showLoading: true, selectStatus: status});
    let {locationId} = this.state;

    updateLocationStatus(locationId, status).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.searchForm();
      }
    });
  }

  delete = () => {
    let {locationId} = this.state;
    this.setState({showLoading: true});

    updateLocationStatus(locationId, LOCATION_STATUS_DELETED).then(({responseCode, message}) => {
      this.setState({showModalConfirm: false});
      if (responseCode === SUCCESSFUL_CODE) {
        this.searchForm();
      } else {
        this.setState({showErrorModal: true, errorMessage: message, showLoading: false});
      }
    });

  }

  handlePopover = (locationValues,event) =>  {
    event.stopPropagation();
    let id = "locationStatus" + locationValues.index;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };
    let selectStatus = locationValues.status;
    this.setState({anchorEl: anchorEl, showPopover: true, locationId: locationValues.value, selectStatus: selectStatus});
  }

  handleRequestClose = () => {
    this.setState({showPopover: false, locationId: null, showModal:false});
  }

  goToInventory = () => {
    browserHistory.push("/main/inventory");
  }

  hideModal = () => {
    this.setState({showErrorModal: false, errorMessage: null});
  }

  openModalConfirm = (locationId,e) => {
    e.stopPropagation();
    this.setState({showModalConfirm: true, locationId});
  }

  closeModalConfirm = () => {
    this.setState({showModalConfirm: false, locationId: null});
  }

  renderLocation(){
    let {selectStatus} = this.state;
    let options = [];
    locationStatuses.map((loc, index) => {
      if(loc.value === selectStatus){
        options.push(
          <li key={index} id={loc.code} className="item-selected" onClick={() => {this.changeStatus(loc.code)}}>
            {loc.value}
          </li>
        )
      } else{
        options.push(
          <li key={index} id={loc.code} className="change-submenu-item" onClick={() => {this.changeStatus(loc.code)}}>
            {loc.value}
          </li>
        )
      }
    });

    return(
      <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {options}
          </u>
        </div>
      </div>
    )

  }

  render() {
    let {showLoading, allLocations, anchorEl, showPopover, showErrorModal, errorMessage, showModalConfirm, locationId,showModal} = this.state;
    const {height} = this.props;

    let childLocations = this.renderLocation();

    return (
      <div className="setting-container"
           style={{display: "block", minHeight: height}}>
        <div className="setting-form-header-header">
          <div className="row-message" style={{position: "relative", display : "flex", alignItems: "center"}}>
            <div className="location-filter">
              <label  className="title-add-location">Add location</label>
            </div>
            <div className="form-setting-button" style={{width: "8%", paddingLeft: "10px"}}>
              <div onClick={(evt) => this.onEdit("new",evt)} className="format-setting-button-add">+</div>
            </div>
          </div>
        </div>
        <div id="locationsTableSettings" className="table-location-setting">
          {
            showLoading ?
              <div className="loader-container">
                <div className="loader"/>
              </div> :
              <table className="table-locations">
                <thead style={{backgroundColor: "#EFEFEF"}}>
                <tr className="title-location-list">
                  <th className="title-name-location">Location Name</th>
                  <th className="title-address-location">Address</th>
                  <th className="title-phone-location">Phone Number</th>
                  <th className="title-status-location">Status</th>
                  <th className=""/>
                </tr>
                </thead>
                <tbody>
                {
                  allLocations.length !== 0 ?
                    (
                      allLocations.map((location, index) => {
                        let locationFound = find(locationStatuses, loc => {
                          return loc.code === location.status
                        });
                        let locationValues = {index:index,value:location.locationId,status:locationFound.value};
                        return (
                          <tr key={index + 'tr'} className="rowCostumer" onClick={(evt) => this.onEdit(location.locationId,evt)}>
                            <td className="title-name-location"
                                key={index + 'name'}>{location.name}</td>
                            <td className="title-address-location"
                                key={index + 'code'}>{location.address}</td>
                            <td className="title-phone-location">{(location.info) ?
                              (JSON.parse(location.info)).phoneNumber : "-"}</td>
                            <td className="title-status-location" key={index + 'Created_By'}>
                              <div id={`locationStatus${index}`} className="select-location"
                                   onClick={(evt) => this.handlePopover(locationValues,evt)}>
                                <label style={{color: locationFound.color}}>
                                  {locationFound.value}
                                </label>
                                {
                                  showPopover && locationId === location.locationId ?
                                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                                }
                              </div>
                            </td>
                            <td key={index + 'td'} style={{color: "#f3f3f3"}} className="title-graph-location">
                              <div className="options">
                                <i style={{color: "#4AD991"}} className="fa fa-pencil fa-lg"
                                   title="Edit"/>
                                <i style={{color: "#d9534f"}} className="fa fa-trash fa-lg"
                                   title="Delete" onClick={(evt) => this.openModalConfirm(location.locationId,evt)}/>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    )
                    :
                    null
                }
                </tbody>
              </table>
          }
        </div>
        {
          showPopover ?
            <Popover anchorEl={anchorEl}
                     child={childLocations}
                     classPopover="popover-box-message"
                     handleRequestClose={this.handleRequestClose}/> : null
        }
        <ModalError className="modal-error-message" showModal={showErrorModal} errorMessage={errorMessage}
                    onClickButton={this.goToInventory} hideModal={this.hideModal} buttonText={"Go to Inventory"}/>
        <ModalConfirmDelete show={showModalConfirm} message="Do you want to delete the location?"
                            onSubmit={this.delete} onHide={this.closeModalConfirm} viewMobile={false}
                            showButtonDelete={true}
                            classButtonDelete="btn-delete" buttonLabelDelete="Yes, Delete"/>
        {
          showModal ?
            <SettingLocationModal
              showModal={showModal}
              allLocations={allLocations}
              locationId = {locationId}
              closeModal={this.handleRequestClose}
              searchForm={this.searchForm}
            /> : null
        }
      </div>
    )
  }
}