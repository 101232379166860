import React, {Component} from 'react'
import {getFileTemplateById, saveFileTemplate, updateFileTemplate} from '../../../../Api'
import Loader from '../../../../../common/UI/Loader.jsx'
import {ReactSVG} from 'react-svg'
import {DUPLICATE_FILE_TEMPLATE_CODE} from '../../../../Constants'
import {browserHistory} from 'react-router'
import {ModalSuccessfullyUpdated} from '../../../util/ComponentUtils'
import isEmpty from 'lodash/isEmpty'
import {DPEditor} from '../../../../common/DPEditor'
import {DPPopover} from '../../../../../common/DPPopover'
import {getErrorMessage} from '../index'
import {DPInput} from '../../../../common/DPInput'
import DPSelectionDropDown from '../../../../common/DPSelectionDropDown'
import {getAnchorEl, validateError} from '../../../util/Utils'
import {defaultString} from '../../../../../common/Util'
import {pathServer, SUCCESSFUL_CODE} from '../../../../../common/Constants'

const fileStatus = [
  {id: 0, displayValue: "Inactive", color: "#ff6565"},
  {id: 1, displayValue: "Active", color: "#4ad991"}
];

const fieldsTemplateRequiredUpdate = [
  {key: "name", type: "required"},
  {key: "code", type: "required"},
  {key: "body", type: "required"}
];

function getFileTemplateInitial() {
  return(
    {name: "", code: "", active: 1, body: ""}
  )
}

export default class FileTemplateEdit extends Component{
  constructor() {
    super();
    this.state = {
      isLoading: false,
      hasChange: false,
      showPopoverDropDown: false,
      selectPopoverDropDown: null,
      anchorEl: null,
      errors:{},
      accountOwnerSelected: null,
      showModalSuccessfullyUpdated: false,
      fileTemplate:{},
      dealerName:null,
      selectedFilter: null,
      fileTemplateId: null,
      fileTemplateName: null,
      errorDuplicate: false,
      showMessage: false
    }
  }

  componentDidMount() {
    const {fileTemplateId, id} = this.props.params;
    const {dealerName} = this.props.location.state || "";
    this.setState({isLoading: true});
    if(fileTemplateId !== "new"){
      getFileTemplateById(fileTemplateId, id).then(response => {
        const {responseCode, data} = response
        if (responseCode !== SUCCESSFUL_CODE) {
          this.setState({isLoading: false, fileTemplate:getFileTemplateInitial()});
          return
        }
        const fileTemplate = data;
        const active = fileTemplate.active;
        const statusSelected = fileStatus.find(x => x.id === active);
        const fileTemplateName = fileTemplate.name;
        this.setState({isLoading: false, fileTemplate: fileTemplate, dealerId: id, dealerName: dealerName, statusSelected: statusSelected,
          fileTemplateId: fileTemplateId, fileTemplateName});
      })
    } else{
      const statusSelected = fileStatus.find(x => x.id === 1);
      const fileTemplateName = "";
      this.setState({isLoading: false, fileTemplate:getFileTemplateInitial(), dealerId: id, dealerName: dealerName, statusSelected: statusSelected,
        fileTemplateId: fileTemplateId, fileTemplateName});
    }
  }

  goBack = (dealerId) => {
    browserHistory.push(`/main/superusers/dealers/edit/${dealerId}`);
  }

  closePopoverSelect = () => {
    this.setState({showPopoverDropDown: false, selectPopoverDropDown: null});
  }

  showPopoverDropDownModal = (id, event) => {
    event.preventDefault();
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target,2);
    this.setState({anchorEl: anchorEl, showPopoverDropDown: true, selectPopoverDropDown: id})
  }

  getError = (errors, object) => {
    let value;
    fieldsTemplateRequiredUpdate.forEach(field => {
      const {key, type} = field;
      if (type === "required") {
        value = object[key];
        errors[key] = isEmpty(value);
      }
    });
        return errors
  }

  closeModal = () => {
    this.setState({showModalUpdated: false, showMessage: false})
  }

  renderDropDown = (type, evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    let anchorEl = getAnchorEl(target, 2);
    this.setState({showPopover: true, selectedFilter: type, anchorEl: anchorEl})
  }

  onChange = (type, evt) => {
    const {fileTemplate} = this.state;
    fileTemplate[type] = evt.target.value;
    this.setState({fileTemplate})
  }

  onChangeEditor = (evt) => {
    const {fileTemplate} = this.state;
    fileTemplate.body = evt;
    this.setState({fileTemplate})
  }

  closePopover = () => {
    this.setState({showPopover: false, selectedFilter: null})
  }

  onClickPopover = (id) => {
    const {fileTemplate} = this.state;
    fileTemplate.active = id;
    const statusSelected = fileStatus.find(x => x.id === id);
    this.setState({statusSelected, showPopover: false, selectedFilter: "", fileTemplate})
  }

  updateFileTemplate = (type) => {
    const {fileTemplate, fileTemplateId, dealerId, errors} = this.state;
    fileTemplate.dealerId = dealerId;
    const error = this.getError(errors, fileTemplate);
    const hasError = validateError(error);
    if(!hasError){
      this.setState({isLoading: true});
      if(type === "new"){
        saveFileTemplate(fileTemplate).then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.setState({showModalUpdated: true, isLoading: false})
          }  else {
            console.error(responseMessage);
            let errorDuplicate = false;
            if(error.message === DUPLICATE_FILE_TEMPLATE_CODE){
              errorDuplicate = true
            }
            this.setState({isLoading: false, errorDuplicate})
          }
        })
      } else{
        updateFileTemplate(fileTemplateId, fileTemplate).then(response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE) {
            this.setState({showModalUpdated: true, isLoading: false})
          } else {
            console.error(responseMessage)
          }
        })
      }
    } else {
      this.setState({errors: error});
    }
  }

  showMessage = (evt) => {
    evt.stopPropagation();
    let pointTo = getAnchorEl(evt.currentTarget,0);
    let anchorEl = {
      x: pointTo.x + 20,
      y: pointTo.y - 20,
      offsetWidth: 360
    };
    this.setState({showMessage: true, anchorEl})
  }

  render(){
    const {isLoading, dealerName, showModalUpdated, fileTemplateName, errors, errorDuplicate, showMessage,
      fileTemplate, dealerId, anchorEl, statusSelected, fileTemplateId} = this.state;

    const {height} = this.props;
    const child = <label className="body-question">Click on the section you want to edit and save the changes.</label>
    return(
      <div className="dealer-content">
        {isLoading ? <Loader/> : null}
        <div className="container-header">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_back.svg"} beforeInjection={ svg => svg.classList.add("icon-back")}
                    className="arrow-back" onClick={() => this.goBack(dealerId)}/>
          <div className="dealer-title">
            Dealer
          </div>
          <div className="arrow-right">
            <i style={{color: "#787878"}} className="fa fa-chevron-right fa-lg" />
          </div>
          <div className="dealer-name">
            {dealerName}
          </div>
          <div className="arrow-right">
            <i style={{color: "#787878"}} className="fa fa-chevron-right fa-lg" />
          </div>
          <div className="template-name">
            {fileTemplateName}
          </div>
          <div className="container-button">
            <button className="btn-save" onClick={() => this.updateFileTemplate(fileTemplateId)}>
              Save
            </button>
          </div>
        </div>
        <div id="bodyDealer" className="container-center">
          <div className="setting-container" style={{minHeight: height}}>
            <div className="template-section">
              <div className="title-template-section">
                <label className="title">
                  File Template Information
                </label>
              </div>
              <div className="title-template-section-body">
                <DPInput
                  className={'file-name'}
                  title={'File Template Name'}
                  id={'name'}
                  type={'text'}
                  value={defaultString(fileTemplate.name)}
                  onChange={(evt) => this.onChange("name", evt)}
                  isRequired={false}
                  errorMessage={errors.name ? getErrorMessage("Name") : null}
                />
                <DPInput
                  className={'file-code'}
                  title={'File Template Code'}
                  id={'code'}
                  type={'text'}
                  value={defaultString(fileTemplate.code)}
                  onChange={(evt) => this.onChange("code", evt)}
                  isRequired={false}
                  errorMessage={errors.code ? getErrorMessage("Code") : errorDuplicate ? "There is another active file with that code" : null}
                  disable={fileTemplateId !== "new"}
                  style={fileTemplateId !== "new" ? {backgroundColor:"#F5F6FA"} : null}
                />
                <DPSelectionDropDown
                  className={'file-status'}
                  label={"Status"}
                  id={`status`}
                  options={fileStatus}
                  value={statusSelected ? statusSelected.id : 1}
                  onClick={this.onClickPopover}
                  labelStyle={statusSelected ? {color: statusSelected.color} : null}
                />

              </div>
            </div>
            <div className="template-section-body">
              <div className="template-section-body-title">
                <label className="title">
                  File Template
                </label>
                <div className="icon-right">
                  <div className="container-icon">
                    <ReactSVG id="userSelected" src={pathServer.PATH_IMG + "icon/ic_question_mark.svg"}
                              beforeInjection={svg => svg.classList.add("svg-icon-question")}
                              className="icon-question"
                              onClick={(evt) => {this.showMessage(evt)}}/>
                  </div>
                </div>
              </div>
              <div id={"editorHtml"} className="editor-html">
                <label className="title-html">HTML</label>
                <div className="content-html-editor" style={errors.body ? {border: "1px solid #FF6565"} : {border: "1px solid #EFEFEF"}}>
                  <DPEditor value={fileTemplate.body} onChange={this.onChangeEditor} placeholder="" mode="html"
                            fontSize={12}/>
                </div>
                {
                  errors.body ?
                    <p className="help-block">{getErrorMessage("Body")}</p> :
                    null
                }
              </div>
            </div>
          </div>
        </div>
        {
          showModalUpdated ?
              <ModalSuccessfullyUpdated show={showModalUpdated}
                                        message={fileTemplateId !== "new" ? "Successfully Updated" : "Successfully Saved"}
                                        onHide={this.closeModal}
              /> : null
        }
        {
          showMessage ?
            <DPPopover anchorEl={anchorEl}
                       children={child}
                       classNameWrapper="popover-box-message popover-trade-in"
                       handleRequestClose={this.closeModal}/> : null
        }
      </div>
    )
  }
}