import React, {Component} from 'react'
import {connect} from 'react-redux'
import {browserHistory} from 'react-router'
import {ReactSVG} from 'react-svg'
import {changePasswordApi, loadAccountApi, updateAccountApi, updateKeepLoggedIn} from '../../Api'
import {cloneDeep} from 'lodash'
import { updateAccount, setMenuId } from '../../actions'
import Switch from 'react-switch'
import Loader from '../../../common/UI/Loader.jsx'
import Dropzone from 'react-dropzone'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'


function showMenuSearch(display) {
  let menuSearchContent = document.getElementById("menuSearchContent");
  if (menuSearchContent)
    menuSearchContent.style.display = display;
}

function getCookieByName(name) {
  const cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name + '=') === 0) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

const urlRightColor = "rgb(140,231,185)";
const urlErrorColor = "rgb(205,211,216)";
const iconCircleCheck = <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="check-circle-profile" beforeInjection={ svg => svg.classList.add("icon-check-circle")}/>;

class DashboardProfile extends Component {

  constructor() {
    super();
    this.state = {
      user: {
        userName: "",
        firstName: "",
        lastName: "",
        image: pathServer.PATH_IMG + "user.png",
        email: " ",
        phoneNumber: " ",
        active: true,
        superuser: false,
        verify: false,
        password: "",
        urlFacebook: "-",
        urlInstagram: "-",
        urlLinkedin: "-",
      },
        isRightFacebook : false ,
        isRightInstagram : false,
        isRightLinkedin : false,
      optionsFilter: [
        {id: "clear", value: "clear", displayValue: "Clear All", checked: false, index: 0, btnShow: "less"},
        {id: "superAdmin", value: "superAdmin", displayValue: "Super Admin", checked: false, index: 1, btnShow: "less"},
        {id: "admin", value: "admin", displayValue: "Admin", checked: false, index: 2, btnShow: "less"},
        {id: "sales", value: "sales", displayValue: "Sales", checked: false, index: 3, btnShow: "less"},
        {id: "mechanic", value: "mechanic", displayValue: "Mechanic", checked: false, index: 4, btnShow: "less"},
        {id: "other", value: "other", displayValue: "Other", checked: false, index: 5, btnShow: "less"},
      ],
      userOriginal: {},
      templates: null,
      emailChecked: false,
      showRole: false,
      keepLoggingChecked: false,
      showChangePassword: false,
      errorMessage: false,
      errorEmail: false,
      photo: null,
      isLoading: false,
      confirmPassword: "",
      requirements: [false, false, false, false],
      preview: null
    }
  }

  getUser = (user) => {
    const {roles = []} = user
    user.roles = roles.map(rol => rol.id);
    user.arrayRoles = roles.map(rol => ({id: rol.id, name: rol.name}))
    user.userName = user?.userName ?? user?.email;
    user.password = "";
    user.urlFacebook = user?.urlFacebook ?? "";
    user.urlInstagram = user?.urlInstagram ?? "";
    user.urlLinkedin = user?.urlLinkedin ?? "";
    return user
  }
  loadUser = () => {
    this.setState({isLoading: true})
    setTimeout(() => {
      loadAccountApi().then((response) => {
        const {responseCode, responseMessage, data} = response
        if(responseCode === SUCCESSFUL_CODE){
          const {user} = data
          this.setState({user: this.getUser(user), isLoading: false})
        }else{
          console.error(responseMessage)
          this.setState({isLoading: false})
        }
      })
    }, 300)
  }


  componentDidMount() {
    this.loadUser();
    let {showSideBar} = this.props;
    if (showSideBar) {
      let container = document.getElementById("container");
      container.className += ' container-collapse';
    }
    window.addEventListener('focusout',this.urlRight);

    const keepLoggedIn = this.getKeepLoggedInFromCookie();
    this.setState({ keepLoggingChecked: keepLoggedIn });
  }

  componentWillUnmount() {
    // showMenuSearch("block");
  }

  urlRight = (event) => {
    let {isRightFacebook, isRightInstagram, isRightLinkedin} = this.state;
    let nameInput = event.target.name;
    if(event.target.value || event.target.value===""){
      let url = event.target.value.toLowerCase();
      if(nameInput ==="urlFacebook"){
        isRightFacebook= url.indexOf("facebook.com")!==-1;
      } else if(nameInput ==="urlInstagram"){
        isRightInstagram= url.indexOf("instagram.com")!==-1;
      } else if(nameInput ==="urlLinkedin"){
        isRightLinkedin= url.indexOf("linkedin.com")!==-1;
      }
      this.setState({isRightFacebook:isRightFacebook, isRightInstagram: isRightInstagram, isRightLinkedin: isRightLinkedin})
    }

  }

  handleChange = (e) => {
    let {user} = this.state;
    if (e.target.name === "phoneNumber") {
      user["phoneNumber"] = this.getFormattedPhone(e.target.value.replace(/[^0-9]+/g, ''));
    } else if(e.target.name === "password"){
      let password = e.target.value;
      let valueRequirements = [false, false, false, false];
      if (password.match(/[A-Z]/g)) {
        valueRequirements[0] = true;
      }
      if (password.length >= 8) {
        valueRequirements[1] = true;
      }
      if (password.match(/[^a-zA-Z\d]/g)) {
        valueRequirements[2] = true;
      }
      if (password.match(/[0-9]/g)) {
        valueRequirements[3] = true;
      }
      user.password = e.target.value;
      this.setState({requirements: valueRequirements});
    } else{
      user[e.target.name] = e.target.value;
    }
    this.setState({user: user});
  }

  getFormattedPhone = (phoneNumber) => {
    let formattedPhone = phoneNumber;
    if (phoneNumber.length >= 1)
      formattedPhone = '(' + phoneNumber.substring(0);
    if (phoneNumber.length > 3)
      formattedPhone = formattedPhone.substring(0, 4) + ') ' + formattedPhone.substring(4);
    if (phoneNumber.length > 6)
      formattedPhone = formattedPhone.substring(0, 9) + '-' + formattedPhone.substring(9);
    return formattedPhone;
  }

  save = () => {
    let {photo, errorEmail, user, keepLoggingChecked} = this.state;
    if (user.email !== '' && user.firstName !== '' && user.lastName !== '' && !errorEmail) {
      this.setState({isLoading: true});
      setTimeout(async () => {
        const {responseCode, responseMessage} = await updateAccountApi(user, photo)
        updateKeepLoggedIn(keepLoggingChecked)
          .then((response) => {
            const {responseCode: responseCodeUK, responseMessage: responseMessageUK} = response
            if(responseCodeUK === SUCCESSFUL_CODE){
              console.log('Keep logged in status updated successfully:', responseMessageUK)
            }else{
              console.error(`An error occurred while updating keep logged in status:`, responseMessageUK);
            }
          })
        if (responseCode !== SUCCESSFUL_CODE) {
          this.setState({isLoading: false});
          return
        }

        const {responseCode: responseCodeAccount, responseMessage: responseMessageAccount} = await this.props.updateAccount()
        if (responseCodeAccount === SUCCESSFUL_CODE) {
          this.setState({isLoading: false});
          this.props.setMenuId("dashboard");
          browserHistory.push({pathname: `/main/dashboard`});
          return
        }
        this.setState({isLoading: false});
      }, 500);
    } else {
      this.setState({errorMessage: true, errorEmail: false, isLoading: false})
    }
  }

  onDrop = (images) => {
    if (images) {
      this.setState({photo: images[0], preview: URL.createObjectURL(images[0])});
    }
  }

  matchPasswords = (event) => {
    let {user} = this.state;
    const value = event.target.value;
    let confirmPassword;
    confirmPassword = value !== user.password;
    this.setState({confirmPassword})
  }

  updateElementState = (evt) => {
    const id = evt.currentTarget.id;
    const valueState = this.state[id];
    this.setState({[id]: !valueState})
  }

  onChangeSwitch = (checked,event,id) => {
    this.setState({[id]: checked})
  }

  handleConfirmPassword = (e) => {
    let confirmPassword = e.target.value;
    this.setState({confirmPassword: confirmPassword});
  }

  saveNewPassword = () => {
    let {requirements, user, confirmPassword} = this.state;
    if((requirements.toString() === "true,true,true,true") && (user.password !== "" && user.password === confirmPassword)){
      let data = {userId:user.id,password:user.password,confirmPassword:confirmPassword};
      changePasswordApi(data).then((response) =>{
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({showChangePassword : false})
        }else{
          console.log("error change password",responseMessage)
          this.setState({showChangePassword : true, errorMessage: responseMessage})
        }
      })
    }
  }

  getKeepLoggedInFromCookie() {
    const userData = getCookieByName('userData');
    if (userData) {
      const decodedData = atob(userData)
      const parsedData = JSON.parse(decodedData);
      return parsedData.keepLoggedIn || false;
    }
    return false;
  }

  render() {
    const {user, photo, isLoading,isRightFacebook, isRightInstagram, isRightLinkedin,emailChecked ,showChangePassword,keepLoggingChecked,
      requirements, confirmPassword,preview} = this.state;
    const {height} = this.props;
    return (
      <div id="container" className="container-component" style={{height: height}}>
        {isLoading ? <Loader/> : null}
        <div className="dashboard-header">
          <div className="container-header container-header-dashboard">
            <div className="left-container">
              <div className="title tile-dashboard">Dashboard > My Profile</div>
            </div>
            <div className="right-container">
              <button onClick={this.save} className="btn btn-user">Save</button>
            </div>
          </div>
        </div>
        <div className="dashboard-body dashboard-body-profile">
          <div className="dashboard-graphic">
            <div className="dashboard-user dashboard-container-user">
              <div className="icon-user-content">
                <Dropzone id="dropzone-modal-dashboard" onDrop={this.onDrop} multiple={false} accept=".jpeg,.jpg,.png">
                  {({getRootProps, getInputProps}) => (
                      <div {...getRootProps()} className={'dropzone-container'}>
                        <input {...getInputProps()} />
                        <img
                          src={photo ? preview : (user.image !== "") ? user.image : pathServer.PATH_IMG + "user.png"}
                          className="icon-user"/>
                        <div className="new-user-photo-circle">
                          <i className="fa fa-camera" aria-hidden="true"/>
                        </div>
                      </div>
                      )
                    }
                </Dropzone>
              </div>
              <div className="col100 second-container">
                <div className="col50 container-left">
                  <div className="userInfoLabel">
                    <label htmlFor="firstName">Name</label>
                  </div>
                  <div>
                    <input type="text" className="form-control colRequired userProfileInput" id="firstName"
                           name="firstName" value={user.firstName} onChange={this.handleChange}/></div>
                </div>
                <div className="col50 container-right">
                  <div className="userInfoLabel">
                    <label htmlFor="lastName">Last Name</label>
                  </div>
                  <div>
                    <input type="text" className="form-control colRequired userProfileInput" id="lastName"
                           name="lastName" value={user.lastName} onChange={this.handleChange}/>
                  </div>
                </div>
                <div className="col100 userInfoRow">
                  <div className="userInfoLabel">
                    <label htmlFor="lastName">Email</label>
                  </div>
                  <div>
                    <input type="text" className="form-control colRequired userProfileInput" id="email"
                           name="email" value={user.email} placeholder="-" readOnly/>
                  </div>
                </div>
                <div className="col100 userInfoRow">
                  <div className="userInfoLabel">
                    <label htmlFor="lastName">Phone number</label>
                  </div>
                  <div>
                    <input maxLength="14" type="text" className="form-control colRequired userProfileInput"
                           id="phoneNumber"
                           name="phoneNumber" value={user.phoneNumber} onChange={this.handleChange}/>
                  </div>
                </div>
                {
                  (user.arrayRoles && (user.arrayRoles.length > 0)) ?
                      <div className="col50 userInfoRow" >
                        <div className="userInfoLabel">
                          <label htmlFor="lastName">Roles</label>
                        </div>
                        <div className="form-control dropdown-item dropdown-roles-user" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">
                         <span className="letter-roles-modal">
                             {
                               user.arrayRoles.map((rol)=>{
                                 return(<div key={rol.id}>{rol.name}</div>)
                               })
                             }
                         </span>
                        </div>
                      </div> : null
                }
              </div>
            </div>
            <div className="dashboard-statistic dashboard-user">
              <div className="row-statistics-user">
                <div className="col100">
                   <div className="col50 container50" >
                     <b className="userInfoTitle">User Settings</b>
                     <div className="col100 userInfoRow" >
                       <div className="userInfoLabel">
                         <label htmlFor="lastName">Username</label></div>
                       <div>
                         <input value={user.userName} type="text" className="form-control colRequired userProfileInput" id="userName"
                                name="userName"  onChange={this.handleChange}/>
                       </div>
                     </div>
                     <div className="col100 userInfoRow" >
                       <div className="userInfoLabel">
                         <label htmlFor="lastName">Alternative  Email</label></div>
                       <div>
                         <input value={user.alternativeEmail}  type="text" className="form-control colRequired userProfileInput" id="alternativeEmail"
                                name="alternativeEmail"   onChange={this.handleChange}/>
                       </div>
                     </div>
                     <div className="col100 userInfoRow" >
                       <div className="userInfoLabel">
                         <label htmlFor="lastName">Password</label></div>
                       <div>
                         <input type="password" className="form-control colRequired userProfileInput" id="lastName"
                                name="lastName" placeholder="******************"/>
                       </div>
                       {
                         !showChangePassword && (
                           <a id="showChangePassword" className="password" href="#" onClick={this.updateElementState}>Change
                             password</a>
                         )
                       }
                     </div>
                   </div>
                   <div className="col50 container50">
                     <b className="userInfoTitle">External Links</b>
                     <div className="col100 userInfoRow" >
                       <div className="userInfoLabel">
                         <label htmlFor="lastName">Facebook URL </label></div>
                       <div>
                         <ReactSVG src={pathServer.PATH_IMG + "icon/ic_facebook.svg"} className="f-icon-facebook-profile" beforeInjection={ svg => svg.classList.add("icon-facebook-profile")}/>
                         <input value={user.urlFacebook}  onChange={ this.handleChange} type="text" className="form-control colRequired userProfileInput urlIconSocial" id="urlFacebook"
                                name="urlFacebook" placeholder="Paste your link here" style={{borderColor:(isRightFacebook) ? urlRightColor:urlErrorColor}}/>
                         {(isRightFacebook) ? iconCircleCheck:null}
                       </div>
                     </div>
                     <div className="col100 userInfoRow" >
                       <div className="userInfoLabel">
                         <label htmlFor="lastName">Instagram URL </label></div>
                       <div>
                         <ReactSVG src={pathServer.PATH_IMG + "icon/ic_instagram.svg"} className="f-icon-instagram-profile" beforeInjection={ svg => svg.classList.add("icon-instagram-profile")}/>
                         <input value={user.urlInstagram}  onChange={ this.handleChange}  type="text" className="form-control colRequired userProfileInput urlIconSocial" id="urlInstagram"
                                name="urlInstagram" placeholder="Paste your link here"  style={{borderColor:(isRightInstagram) ? urlRightColor:urlErrorColor}}/>
                         {(isRightInstagram) ? iconCircleCheck:null}
                       </div>
                     </div>
                     <div className="col100 userInfoRow" >
                       <div className="userInfoLabel">
                         <label htmlFor="lastName">Linkedin URL </label></div>
                       <div>
                         <ReactSVG src={pathServer.PATH_IMG + "icon/ic_linkedin.svg"} className="f-icon-linkedin-profile" beforeInjection={ svg => svg.classList.add("icon-facebook-profile")}/>
                         <input value={user.urlLinkedin}  onChange={ this.handleChange}  type="text" className="form-control colRequired userProfileInput urlIconSocial" id="urlLinkedin"
                                name="urlLinkedin" placeholder="Paste your link here"  style={{borderColor:(isRightLinkedin) ? urlRightColor:urlErrorColor}}/>
                         {(isRightLinkedin) ? iconCircleCheck:null}
                       </div>
                     </div>

                   </div>
                </div>
                {
                  showChangePassword && (
                    <div>
                      <div className="col100 userInfoRow" >
                        <div className="userInfoLabel">
                          <label htmlFor="lastName">New Password </label></div>
                        <div>
                          <input onChange={ this.handleChange} defaultValue={user.password} type="password" className="form-control colRequired userProfilePasswordInput" id="password"
                                 name="password" placeholder="New password"/>
                          {(requirements.toString() === "true,true,true,true") ? iconCircleCheck:null}
                        </div>
                        {(requirements.toString() !== "true,true,true,true") && (
                          <span style={{color: "red", fontSize : "10px", float: 'left'}}> Password not meet Requirements</span>
                        )}
                      </div>
                      <div className="col100 userInfoRow" >
                        <div className="userInfoLabel">
                          <label htmlFor="lastName">Confirm New Password </label></div>
                        <div>
                          <input onChange={this.handleConfirmPassword}
                                 defaultValue={confirmPassword} type="password"  className="form-control colRequired userProfilePasswordInput"
                                 name="confirmPassword" placeholder="Confirm password"/>
                          {(user.password !== "" && user.password === confirmPassword)? iconCircleCheck:null}
                        </div>
                        {(user.password !== "" && user.password !== confirmPassword) && (
                          <span style={{color: "red", fontSize : "10px", float: 'left'}}> Don't match with password</span>
                        )}
                      </div>
                      <div className="section-requirements-user-option">
                        <div className="section-requirements">
                          <div className="form-group-requirements">
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="icon-arrow-green"
                                      style={{fill: !this.state.requirements[0] ? "rgb(164,175,183)" : "#8ce7b9"}}/>
                            A capital letter
                          </div>
                          <div className="form-group-requirements">
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="icon-arrow-green"
                                      style={{fill: !this.state.requirements[1] ? "rgb(164,175,183)" : "#8ce7b9"}}/>
                            At least 8 characters
                          </div>
                          <div className="form-group-requirements">
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="icon-arrow-green"
                                      style={{fill: !this.state.requirements[2] ? "rgb(164,175,183)" : "#8ce7b9"}}/>
                            A symbol (!%^&*)
                          </div>
                          <div className="form-group-requirements">
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_circle.svg"} className="icon-arrow-green"
                                      style={{fill: !this.state.requirements[3] ? "rgb(164,175,183)" : "#8ce7b9"}}/>
                            A number
                          </div>
                        </div>
                        <div className="col100 btn-password-change userInfoRow options-change-password">
                          <a className="btn btn-user" style={{background:"white", color:"#2194F1", marginRight: "5px"}} href="#" onClick={() => this.setState({showChangePassword : false})}>Cancel</a>
                          <button className="btn btn-user" onClick={this.saveNewPassword}> Save </button>
                        </div>
                      </div>
                    </div>
                  )}
                {/*
                <div className="user-settings-column margin-top">
                  <div className="col100 align-left">
                    <b className="userInfoTitle">Account Settings</b>
                  </div>
                  <div className="col100 userInfoRow">
                    <div className="col100">
                      <h6 className="userInfoSmallTitle">Receive email notifications</h6>
                      <div className='profile-switch-container'>
                        <Switch
                          id="emailChecked"
                          onChange={(checked, event) => this.onChangeSwitch(checked, event, "keepLoggingChecked")}
                          checked={emailChecked}
                          onColor="#2194F1"
                          offColor="#A4AFB7"
                          uncheckedIcon={<div className='profile-switch' style={showChangePassword ? {marginTop: -1} : null}>OFF</div>}
                          checkedIcon={<div className='profile-switch' style={showChangePassword ? {marginTop: -1} : null}>ON</div>}
                        />
                      </div>
                    </div>
                    <div className="col100 margin-top">
                      <h6 className="userInfoSmallTitle">Keep my session logged in</h6>
                      <div className='profile-switch-container'>
                        <Switch
                          id="keepLoggingChecked"
                          onChange={this.onChangeSwitch}
                          checked={keepLoggingChecked}
                          onColor="#2194F1"
                          offColor="#A4AFB7"
                          uncheckedIcon={<div className='profile-switch' style={showChangePassword ? {marginTop: -1} : null}>OFF</div>}
                          checkedIcon={<div className='profile-switch' style={showChangePassword ? {marginTop: -1} : null}>ON</div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar: state.showSideBar,
    templateId: state.templateId
  }
};

export default connect(mapStateToProps, {setMenuId, updateAccount})(DashboardProfile)