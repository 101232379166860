import React, {useState} from 'react'
import {EmptyFunc} from '../../../../../common/Constants'
import DPIcon from "../../../../common/DPIcon";
import classnames from 'classnames'
import PropTypes from 'prop-types'

require('./GDGButton.scss')

export const BUTTON_TYPE = {
  BLUE: 'blue',
  GRAY: 'gray'
}

const BUTTON_STYLES_BY_TYPE = {
  [BUTTON_TYPE.BLUE]: BUTTON_TYPE.BLUE,
  [BUTTON_TYPE.GRAY]: BUTTON_TYPE.GRAY
}

const GDGButton = ({text, hoverText, type, classNames, style, onClick, iconType, hoverIconType}) => {

  const [isHovering, setIsHovering] = useState(false)
  const hasIconLeft =  !isHovering && iconType
  const hasIconRight = isHovering && hoverText && hoverIconType

  return (
    <span
      onClick={onClick}
      onMouseLeave={hoverText ? () => setIsHovering(false) : null}
      onMouseEnter={hoverText ? () => setIsHovering(true) : null}
      style={style}
      className={'gdg-button ' + BUTTON_STYLES_BY_TYPE[type] + " " + classNames}>
      {
        hasIconLeft &&
        <DPIcon icon={iconType}/>
      }
      <a className={classnames({'has-icon-left': hasIconLeft, 'has-icon-right': hasIconRight})}>
        {
          isHovering && hoverText ? hoverText : text
        }
      </a>
      {
        hasIconRight &&
        <DPIcon icon={hoverIconType}/>
      }
    </span>
  )
}

GDGButton.defaultProps = {
  type: BUTTON_TYPE.BLUE,
  onClick: EmptyFunc,
  classnames: '',
  style: {}
}

GDGButton.propTypes = {
  text: PropTypes.string,
  hoverText: PropTypes.string,
  type: PropTypes.string,
  classNames: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  iconType: PropTypes.string,
  hoverIconType: PropTypes.string
}

export default GDGButton
