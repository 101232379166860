import React, {Component} from 'react'
import {DragSource, DropTarget} from 'react-dnd'
import {FIELD_NEGATIVE, FIELD_READ_ONLY, FIELD_VALIDATION, FIELD_WITH_SYMBOL} from '../../../Constants'
import {cloneDeep, differenceBy, findIndex, assign} from 'lodash'
import flow from 'lodash/flow'
import AttributeItem from './AttributeItem.jsx'
import each from 'lodash/each'
import ModalAttribute from './ModalAttribute.jsx'
import {loadAttributesByEntity} from '../../../Api'
import DragDropContext from '../../util/DragDropContext'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'
import {getAttributeJSONFormBuilder, getWidthColumn} from '../../util/Utils'

const SectionItemSource = {
  beginDrag(props){
    return {
      section: props,
      index: props.index
    }
  },
  endDrag(props,monitor,component) {
    //return props.handleDrop(props.attributeInfo[0])
  }

};

const SectionItemTarget = {

  hover(props, monitor, component){
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    //Don't replace items with themselves
    if(dragIndex === hoverIndex){
      return
    }
    props.moveSection(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  }

};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: "white",
  cursor: "move"
}

const RowsSection = (props) => {
  const {title, index, rows, addRow, classCol} = props;
  let box = [];
  let indexRows = rows
  while(indexRows > 0){
    box.push(
      <div key={indexRows} className={`${classCol} box-dashed-divided`}>
        <div className="box-dashed"/>
      </div>)
    indexRows -= 1
  }
  return (
    <div className="col50">
      <label>{title}</label>
      <a className="box-dashed-content" onClick={() => addRow(index, rows)}>
        {box}
      </a>
    </div>
  )
};

class SectionItem extends Component {

  constructor(props){
    super(props);
    this.buttonRef = [];
    this.menuSectionRef = [];
    this.rowContent = null;
    this.state = {
      value: "",
      dataSourceGroups: [],
      attributes: [],
      attributesModel: [],
      formBuilder: {
        sections: [],
        attributes: []
      },
      classFormSelected:"",
      attributesSelected: [],
      attributesNotSelected: [],
      showPopoverAdd: {},
      showPopoverAttribute: {},
      showModal: false,
      indexSecSelected: null,
      indexRowSelected: null,
      indexSubSectionSelected: null,
      indexColumnSelected: null,
      popoverAttributeSelected: null,
      showPopoverMenu: {},
      isOpenRename: false,
      title: "",
      allAttributesEdit:[],
      attributeEdit:null,
      rowWidth:1363
    }

  }

  componentWillMount() {
    let {formBuilder, attributes, attributesSelected, attributesModel, dataSourceGroups} = this.props;
    let allAttributesEdit = dataSourceGroups[1].options;
    this.setState({formBuilder: formBuilder,attributesSelected:attributesSelected,attributes:attributes, attributesModel:attributesModel,
      dataSourceGroups:dataSourceGroups,allAttributesEdit:allAttributesEdit});
  }

  componentWillReceiveProps(nextProps) {
    let {formBuilder, attributesSelected} = nextProps;
    this.setState({formBuilder: formBuilder, attributesSelected:attributesSelected});
  }

  onViewPopover = (type, id) => {
    let popover = this.state[type];
    each(popover, function(value, key) {
      popover[key] = false;
    });
    if(popover[id])
      delete popover[id];
    else
      popover[id] = true;
    this.setState({[type]: popover})
  }

  addRow = (indexSection, column) => {
    let {formBuilder, showPopoverAdd} = this.state;
    let columns = [];
    let index = 1;
    const indexRow = formBuilder.sections[indexSection].rows.length === null ?  formBuilder.sections[indexSection].sectionSubs.length :
      formBuilder.sections[indexSection].rows.length;
    const secId = "sec" + (indexSection + 1);
    const secIdNext = "sec" + (formBuilder.sections.length + 1);
    const rowIdNext = "row" + (indexRow + 1);
    let col = getWidthColumn(column);
    while (index <= column) {
      columns.push({id: secIdNext + "_" + rowIdNext + "_" + index, status: secIdNext + "_" + rowIdNext + "_" + index ,col: col, width:col});
      index++
    }
    if(formBuilder.sections[indexSection].rows.length === 0  && formBuilder.sections[indexSection].attributeIds.length !== 0){
      let indice=formBuilder.sections[indexSection].sectionSubs.length;
      formBuilder.sections[indexSection].sectionSubs[indice-1].rows.push({
        id: secIdNext + "_" + rowIdNext,
        elementType: "row",
        formKey: "columns",
        isArray: true,
        status: secIdNext + "_" + rowIdNext,
        columns: columns
      });
    }else{
      formBuilder.sections[indexSection].rows.push({
        id: secIdNext + "_" + rowIdNext,
        elementType: "row",
        formKey: "columns",
        isArray: true,
        status: secIdNext + "_" + rowIdNext,
        columns: columns
      });
    }

    formBuilder.sections[indexSection].sectionSubs.rows = formBuilder.sections[indexSection].rows;
    showPopoverAdd[secId] = false;
    delete showPopoverAdd[secId];
    this.setState({showPopoverAdd: showPopoverAdd}, () => {
      this.props.updateFormBuilder(formBuilder)
    })
  }

  renderPopoverAdd(index, sectionId) {
    return (
      <div id={sectionId} className="popover-basic popover-build" ref={this.setWrapperRef}>
        <div className="row-build">
          <RowsSection title={"Complete row"} index={index} rows={1} addRow={this.addRow} classCol={"col100"}/>
          <RowsSection title={"Row divided (2)"} index={index} rows={2} addRow={this.addRow} classCol={"col50"}/>
        </div>
        <div className="row-build">
          <RowsSection title={"Row divided (3)"} index={index} rows={3} addRow={this.addRow} classCol={"col33"}/>
          <RowsSection title={"Row divided (4)"} index={index} rows={4} addRow={this.addRow} classCol={"col25"}/>
        </div>
      </div>
    )
  }

  onChangePopoverAttribute = (id, indexSecSelected, indexRowSelected, indexColumnSelected,countRow,indexSubSection) => {
    let {showPopoverAttribute} = this.state;
    for (let key in showPopoverAttribute) {
      if (key !== id)
        delete showPopoverAttribute[key];
    }
    if (showPopoverAttribute[id])
      delete showPopoverAttribute[id];
    else
      showPopoverAttribute[id] = true;

    this.setState({showPopoverAttribute, indexSecSelected, indexRowSelected, indexColumnSelected, value: "",indexSubSection:indexSubSection});
    if(countRow == "colR80" || countRow == "colR50" ){
      this.setState({classFormSelected:"popover-r80"});
    }else if(countRow == "colR25"){
      this.setState({classFormSelected:"popover-r25"});
    }else if(countRow == "col25" && indexSubSection != null){
      this.setState({classFormSelected:"popover-25-4"});
    }else if(countRow == "colR16" && indexColumnSelected === 1){
      this.setState({classFormSelected:"popover-16-1"});
    }else if(countRow == "colR16" && indexColumnSelected === 2){
      this.setState({classFormSelected:"popover-16-2"});
    }else if(countRow == "col16" && indexColumnSelected === 3){
      this.setState({classFormSelected:"popover-16-3"});
    }
     else{
      let rowTotal= parseInt(countRow.substr(3,3));
      switch (rowTotal) {
        case 20:
          this.setState({classFormSelected:"popover-20"});
          break;
        case 100:
          this.setState({classFormSelected:"popover-100"});
          break;
        case 50:
          if(indexColumnSelected === 0){
            this.setState({classFormSelected:"popover-50-1"});
          }else if(indexColumnSelected === 1) {
            this.setState({classFormSelected:"popover-50-2"});
          }else {
            this.setState({classFormSelected:"popover-100"});
          }
          break;
        case 33:
          if(indexColumnSelected === 0){
            this.setState({classFormSelected:"popover-33-1"});
          }else if(indexColumnSelected === 1) {
            this.setState({classFormSelected:"popover-33-2"});
          }else if(indexColumnSelected === 2){
            this.setState({classFormSelected:"popover-33-3"});
          }else {
            this.setState({classFormSelected:"popover-100"});
          }
          break;

        case 25:
          if(indexColumnSelected === 0){
            this.setState({classFormSelected:"popover-25-1"});
          }else if(indexColumnSelected === 1) {
            this.setState({classFormSelected:"popover-25-2"});
          }else if(indexColumnSelected === 2){
            this.setState({classFormSelected:"popover-25-3"});
          }else if(indexColumnSelected === 3){
            this.setState({classFormSelected:"popover-25-4"});
          }else {
            this.setState({classFormSelected:"popover-100"});
          }
          break;
      }
    }

  }

  onSelectAttribute = (attribute,index,attributeEditNow) => {
    const attributeUpdated = {...attribute};
    let {showPopoverAttribute, indexSecSelected, indexRowSelected, indexSubSection, indexColumnSelected, formBuilder, attributesSelected, dataSourceGroups} = this.state;
    indexSubSection=index;
    let attributeNow;
    if(attributeEditNow){
      if(attributeEditNow.id === attribute.id || attributeEditNow.code === attribute.code){
        let attributeFoundNew = dataSourceGroups[1].options.find(x => x.code === attributeEditNow.code);
        if(attributeFoundNew){
          attributeEditNow.id = attributeFoundNew.id;
          attribute = attributeEditNow;
        }
      }
      attributeNow = attributeEditNow;
    }else if(indexSubSection === -1) {
      attributeNow =  formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected];
    }else{
      attributeNow =  formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected];
    }
    let foundIndex = findIndex(attributesSelected, function(a) { return a.id === attributeNow.id; });
    let foundAttribute;
    if(foundIndex !== -1){
      let foundIndex2 = findIndex(formBuilder.sections[indexSecSelected].attributeIds, function(a) { return a === attributeNow.id; });
      if(foundIndex2 !== -1){
        formBuilder.sections[indexSecSelected].attributeIds.splice(foundIndex2,1);
      }
      foundAttribute = attributesSelected[foundIndex];
      attributesSelected.splice(foundIndex,1);
    }
    if(indexSubSection === -1){
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].code = attribute.code;
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].displayValue = attribute.displayValue;
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].img = attribute.img;
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].status = attribute.id;
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].id = attribute.id;

      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].code = attribute.code;
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].displayValue = attribute.displayValue;
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].img = attribute.img;
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].status = attribute.id;
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns[indexColumnSelected].id = attribute.id;

    } else {
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].code = attribute.code;
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].displayValue = attribute.displayValue;
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].img = attribute.img;
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].status = attribute.id;
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].id = attribute.id;

      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].code = attribute.code;
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].displayValue = attribute.displayValue;
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].img = attribute.img;
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].status = attribute.id;
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns[indexColumnSelected].id = attribute.id;
    }

    formBuilder.sections[indexSecSelected].attributeIds.push(attribute.id);

    let attributeToUpdate;
    if(foundAttribute){
      attributeToUpdate = assign(
          attribute,
          {maxLength:foundAttribute.maxLength,validation:foundAttribute.validation,hasParentChild:foundAttribute.hasParentChild,parentIds:foundAttribute.parentIds}
        );
    }else{
      attributeToUpdate = attribute;
    }

    attributesSelected.push(getAttributeJSONFormBuilder(attributeToUpdate));
    for (let key in showPopoverAttribute) {
      delete showPopoverAttribute[key];
    }

    let attributes = differenceBy(dataSourceGroups[1].options,attributesSelected,'id');
    let attributesModel = differenceBy(dataSourceGroups[0].options,attributesSelected,'id');
    this.setState({formBuilder:formBuilder, showPopoverAttribute:showPopoverAttribute, attributesSelected:attributesSelected,
      value:"", attributes:attributes,attributesModel:attributesModel,attributeEdit:null},()=>{
      formBuilder.attributes = attributesSelected;

      const indexFound = formBuilder.attributes.findIndex(attr => { return attr.id === attributeUpdated.id});
      if(indexFound !== -1) {
        formBuilder.attributes[indexFound][FIELD_VALIDATION] = attributeUpdated[FIELD_VALIDATION] ? [...attributeUpdated[FIELD_VALIDATION]] : [];
        formBuilder.attributes[indexFound][FIELD_READ_ONLY] = attributeUpdated[FIELD_READ_ONLY] || false ;
        formBuilder.attributes[indexFound][FIELD_WITH_SYMBOL] = attributeUpdated[FIELD_WITH_SYMBOL] || false;
        formBuilder.attributes[indexFound][FIELD_NEGATIVE] = attributeUpdated[FIELD_NEGATIVE] || false;
      }
      this.props.updateFormBuilder(formBuilder);
    });
  }

  renderPopoverMenu(sizeSections, section, index, isOpenRename, title) {
    let classPopover = "popover-basic popover-sec-menu" + (isOpenRename ? " open" : "");
    return (
      <div ref={this.setWrapperMenuRef} id={section.id} className={classPopover}>
        <div className="row-sec-build">
          <div className="col100">
            <a onClick={() => this.onChangeRename()}>
              <i className="fa fa-pencil"/>
              <span>Rename</span>
            </a>
            {
              isOpenRename ?
                <div>
                  <img className="icon-close"
                       onClick={() => this.updateSection("title", false)}
                       src={pathServer.PATH_IMG + "ic_close.png"}/>
                  <img className="icon-success"
                       onClick={() => this.updateSection("title", true)}
                       src={pathServer.PATH_IMG + "ic_success.png"}/>
                </div> : null
            }
          </div>
          {
            isOpenRename ?
              <div className="form-input">
                <input id="title"
                       type="text"
                       className="form-control"
                       value={title}
                       onChange={(e) => this.onChange(e)}
                />
              </div> : null
          }
        </div>
        <div className="row-sec-build">
          <a onClick={() => this.onDuplicate(section, index)}>
            <i className="fa fa-pencil"/>
            <span>Duplicate</span>
          </a>
        </div>
        <div className="row-sec-build">
          <a onClick={() => this.onMoveDown(section, index)}
             style={(sizeSections - 1 === index) ? {cursor: 'not-allowed'} : {}}>
            <i className="fa fa-pencil"/>
            <span>Move down</span>
          </a>
        </div>
        <div className="row-sec-build">
          <a onClick={() => this.onMoveUp(section, index)} style={(index === 0) ? {cursor: 'not-allowed'} : {}}>
            <i className="fa fa-pencil"/>
            <span>Move up</span>
          </a>
        </div>
        <div className="row-sec-build">
          <a onClick={() => this.onDeleteSection(index)}>
            <i className="fa fa-trash"/>
            <span>delete</span>
          </a>
        </div>
      </div>
    )
  }

  onChange = (evt) => {
    let type = evt.target.id;
    this.setState({[type]: evt.target.value});
  }

  onChangeRename = () => {
    let {isOpenRename} = this.state;
    this.setState({isOpenRename: !isOpenRename});
  }

  onChangePopoverMenu(id, indexSecSelected) {
    let {showPopoverMenu, formBuilder} = this.state;
    for (let key in showPopoverMenu) {
      if (key !== id)
        delete showPopoverMenu[key];
    }
    if (showPopoverMenu[id])
      delete showPopoverMenu[id];
    else
      showPopoverMenu[id] = true;

    let title = formBuilder.sections[indexSecSelected].header.title;

    this.setState({showPopoverMenu, indexSecSelected, title})
  }

  onMoveDown(section, index) {
    let {formBuilder} = this.state;
    this.onChangePopoverMenu(section.id, index);
    formBuilder.sections.splice(index + 1, 0, formBuilder.sections.splice(index, 1)[0]);
    this.setState({formBuilder: formBuilder}, () => {
      this.props.updateFormBuilder(formBuilder)
    })
  }

  onMoveUp(section, index) {
    let {formBuilder} = this.state;
    this.onChangePopoverMenu(section.id, index);
    formBuilder.sections.splice(index - 1, 0, formBuilder.sections.splice(index, 1)[0]);
    this.setState({formBuilder: formBuilder}, () => {
      this.props.updateFormBuilder(formBuilder)
    })
  }

  onDuplicate = (section, index) => {
    let {formBuilder} = this.state;
    let sectionNew = cloneDeep(section);
    sectionNew.rows.forEach((s) => {
      s.columns.forEach((c,indexColumn) => {
        let secIdNext = "sec" + (Math.random() * 10) + 1 ;
        c.id = secIdNext + "_" + indexColumn + "_" + "duplicate";
        c.status = secIdNext + "_" + indexColumn + "_" + "duplicate";
      })
    });
    sectionNew.attributeIds = [];
    const indexNew = 'duplicate-'+(Math.random() * 10) + 1;
    const secId = "sec" + indexNew;
    let title = section.header.title;
    sectionNew.id = secId;
    sectionNew.name = title;
    sectionNew.header.title = title;
    formBuilder.sections.push(sectionNew);
    this.onChangePopoverMenu(section.id, index);
    this.setState({formBuilder: formBuilder}, () => {
      this.props.updateFormBuilder(formBuilder)
    });
  }

  updateRows(rows) {
    rows.forEach((row) => {
      let col = getWidthColumn(row.columns ? row.columns.length : 1);
      let columns = row.columns;
      columns.forEach((column) => {
        column.col = col;
      })
    });
    return rows;
  }

  getNewSection(section, sectionSubs, sectionIndex) {
    return {
      id: "sec" + sectionIndex,
      status: "sec" + sectionIndex,
      attributeIds: (section.attributeIds) ? section.attributeIds : [],
      className: (section.className) ? section.className : "",
      classNameInput: (section.classNameInput) ? section.classNameInput : "",
      classNameLabel: (section.classNameLabel) ? section.classNameLabel : "",
      elementType: (section.elementType) ? "sec" : "",
      formKey: (section.formKey) ? section.formKey : "",
      header: (section.header) ? section.header : {title: "", subTitle: "", className: ""},
      isArray: (section.isArray) ? section.isArray : false,
      sectionSubs : (section.sectionSubs) ? section.sectionSubs : {isArray:true,elementType:"sub",formKey:"rows",status:"sec0_sub",rows:[]},
      title: (section.header) ? section.header.title : "Section "+sectionIndex,
      name: (section.name) ? section.name : "Section-"+sectionIndex,
      rows: sectionSubs.rows ? this.updateRows(sectionSubs.rows) : []
    }
  }

  updateSection = (type, success) => {
    let {formBuilder, indexSecSelected} = this.state;
    if (success) {
      formBuilder.sections[indexSecSelected].header[type] = this.state[type];
      this.setState({formBuilder: formBuilder, isOpenRename: false}, () => {
        this.props.updateFormBuilder(formBuilder)
      });
    } else {
      let value = formBuilder.sections[indexSecSelected].header[type];
      this.setState({isOpenRename: false, [type]: value});
    }
  }

  onDeleteSection(index) {
    let {formBuilder, attributes, attributesModel, attributesSelected, dataSourceGroups} = this.state;
    let attributeIds = formBuilder.sections[index].attributeIds;
    attributeIds.forEach((attributeId) => {
      let attributeFound = dataSourceGroups[1].options.filter(x => x.id === attributeId);
      if (attributeFound.length > 0) {
        attributes.push(attributeFound[0])
      }
      let attributeFound2 = dataSourceGroups[0].options.filter(x => x.id === attributeId);
      if (attributeFound2.length > 0) {
        attributesModel.push(attributeFound2[0])
      }
      let foundIndex = findIndex(attributesSelected, function(a) { return a.id === attributeId; });
      if(foundIndex !== -1) {
        attributesSelected.splice(foundIndex, 1);
      }
    });
    formBuilder.sections.splice(index, 1);
    this.setState({formBuilder:formBuilder},()=>{
      this.props.updateFormBuilder(formBuilder)
    })
  }

  handleHideModal = (cancel, attribute, entityTypes, indexSecSelected) => {
    if(!cancel){
      this.updateDataSourceGroups({...attribute}, entityTypes, indexSecSelected)
    }
    this.setState({showModal: false, attributeEdit:null});
  }

  updateDataSourceGroups = (attribute, entityTypes, indexSecSelected) => {
    let {form} = this.props;
    if(entityTypes.length > 0 && form.entityType === entityTypes[0]){
      loadAttributesByEntity(form.entityType).then(response => {
        const {responseCode, responseMessage, data} = response
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
          return
        }
        const {groupAttributes} = data
        const dataSourceGroups = groupAttributes
        const allAttributesEdit = dataSourceGroups[1].options;
        //console.log("attribute",attribute)
        //console.log("attributeEdit",attributeEdit)
        //let findAttribute = (attributeEdit) ? allAttributesEdit.find(x=> x.id === attributeEdit.id) : allAttributesEdit.find(x=> x.code === attribute.code);
        this.setState({dataSourceGroups,allAttributesEdit},()=>{
          this.onSelectAttribute(attribute, indexSecSelected, attribute);
        });
      });
    }
  }

  onClosePopover = (type, id) => {
    let popover = this.state[type];
    each(popover, function(value, key) {
      popover[key] = false;
    });
    if(popover[id])
      delete popover[id];
    else
      popover[id] = false;
    this.setState({[type]: popover})
  }

  onClosePopoverSectionMenu = (id) => {
    let {showPopoverMenu} = this.state;
    for (let key in showPopoverMenu) {
      if (key !== id)
        delete showPopoverMenu[key];
    }
    if (showPopoverMenu[id])
      delete showPopoverMenu[id];
    else
      showPopoverMenu[id] = true;
    this.setState({showPopoverMenu})
  }

  onClosePopoverAttribute = (id) => {
    let {showPopoverAttribute} = this.state;
    for (let key in showPopoverAttribute) {
      if (key !== id)
        delete showPopoverAttribute[key];
    }
    if (showPopoverAttribute[id])
      delete showPopoverAttribute[id];
    else
      showPopoverAttribute[id] = true;

    this.setState({showPopoverAttribute: showPopoverAttribute})
  }

  onDeleteAttributeSelected = (id, indexSecSelected, indexRowSelected, indexColumnSelected,indexSubSection,classSection) => {
    let {formBuilder, attributesSelected, dataSourceGroups} = this.state;
    let rowSelected = indexSubSection === -1 ?  formBuilder.sections[indexSecSelected].rows[indexRowSelected]
                    : formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected];
    let columns = rowSelected.columns;
    let column = rowSelected.columns.length;
    const secIdNext = "sec" + (formBuilder.sections.length + 1);
    const rowIdNext = "row" + (indexRowSelected + 1);
    let col = getWidthColumn(column);
    columns.splice(indexColumnSelected, 1, {id: secIdNext + "_" + rowIdNext + "_" + indexColumnSelected, status: secIdNext + "_" + rowIdNext + "_" + indexColumnSelected ,col: col, width:col});
    if(indexSubSection === -1)
      formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns = columns;
    else
      formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns = columns;
    formBuilder.sections[indexSecSelected].sectionSubs.rows =  indexSubSection === -1 ? formBuilder.sections[indexSecSelected].rows : formBuilder.sections[indexSecSelected].sectionSubs.rows ;
    let foundIndex = findIndex(attributesSelected, function(a) { return a.id === id; });
    if(foundIndex !== -1) {
      attributesSelected.splice(foundIndex, 1);
    }
    let attributes = differenceBy(dataSourceGroups[1].options,attributesSelected,'id');
    let attributesModel = differenceBy(dataSourceGroups[0].options,attributesSelected,'id');
    let foundIndex2 = findIndex(formBuilder.sections[indexSecSelected].attributeIds, function(a) { return a === id; });
    if(foundIndex2 !== -1) {
      formBuilder.sections[indexSecSelected].attributeIds.splice(foundIndex2, 1);
    }
    if(indexSubSection !== -1){
      columns[indexColumnSelected].col=classSection;
    }
    if(columns.length === 0){
      if(indexSubSection === -1){
        formBuilder.sections[indexSecSelected].rows.splice(indexRowSelected, 1);
        formBuilder.sections[indexSecSelected].sectionSubs.rows = formBuilder.sections[indexSecSelected].rows;
      }else{
        formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows.splice(indexRowSelected, 1);
        //formBuilder.sections[indexSecSelected].sectionSubs.rows = formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection];
      }
    }else{
      if(indexSubSection === -1){
        formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns = columns;
        formBuilder.sections[indexSecSelected].sectionSubs.rows = formBuilder.sections[indexSecSelected].rows;
      }else{
        formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns = columns;
        //formBuilder.sections[indexSecSelected].sectionSubs.rows = formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection];
      }
    }
    this.setState({formBuilder:formBuilder, attributes:attributes, attributesModel:attributesModel},()=>{
      this.props.updateFormBuilder(formBuilder)
    });
  }

  onDeleteAttributeNotSelected = (indexSecSelected, indexRowSelected, indexColumnSelected, indexSubSection) => {
    let {formBuilder} = this.state;
    let rowSelected = indexSubSection === -1 ?  formBuilder.sections[indexSecSelected].rows[indexRowSelected]
                      : formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected];
    let columns = rowSelected.columns;
    columns.splice(indexColumnSelected, 1);
    let col = getWidthColumn(columns.length);
    columns.forEach((c, index) => {
      c.col = col;
    });
    if(columns.length === 0){
      if(indexSubSection === -1){
        formBuilder.sections[indexSecSelected].rows.splice(indexRowSelected, 1);
        formBuilder.sections[indexSecSelected].sectionSubs.rows = formBuilder.sections[indexSecSelected].rows;
      }else{
        formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows.splice(indexRowSelected, 1);
      }
    }else{
      if(indexSubSection === -1){
        formBuilder.sections[indexSecSelected].rows[indexRowSelected].columns = columns;
        formBuilder.sections[indexSecSelected].sectionSubs.rows = formBuilder.sections[indexSecSelected].rows;
      }else{
        formBuilder.sections[indexSecSelected].sectionSubs[indexSubSection].rows[indexRowSelected].columns = columns;
      }
    }
    this.setState({formBuilder:formBuilder},()=>{
      this.props.updateFormBuilder(formBuilder)
    });
  }

  moveAttribute = (dragIndexRow, dragIndexCol, hoverIndexRow, hoverIndexCol, indexSection, indexSubSection) =>{
    const {formBuilder} = this.state;
    let formBuilderNew = cloneDeep(formBuilder);
    if(indexSubSection === -1){
      //console.log("-1 data drag",formBuilderNew.sections[indexSection].rows[dragIndexRow].columns[dragIndexCol]);
      formBuilderNew.sections[indexSection].rows[dragIndexRow].columns[dragIndexCol].status = formBuilder.sections[indexSection].rows[hoverIndexRow].columns[hoverIndexCol].status;
      formBuilderNew.sections[indexSection].rows[hoverIndexRow].columns[hoverIndexCol].status = formBuilder.sections[indexSection].rows[dragIndexRow].columns[dragIndexCol].status;
      formBuilder.sections[indexSection].sectionSubs.rows = formBuilder.sections[indexSection].rows;
    }else{
      //console.log("data drag",formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[dragIndexRow].columns[dragIndexCol]);
      formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[dragIndexRow].columns[dragIndexCol].status = formBuilder.sections[indexSection].sectionSubs[indexSubSection].rows[hoverIndexRow].columns[hoverIndexCol].status;
      formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[hoverIndexRow].columns[hoverIndexCol].status = formBuilder.sections[indexSection].sectionSubs[indexSubSection].rows[dragIndexRow].columns[dragIndexCol].status;
    }
    /*let column = (indexSubSection === -1) ? formBuilder.sections[indexSection].rows[dragIndexRow].columns.length : formBuilder.sections[indexSection].sectionSubs[indexSubSection].rows[dragIndexRow].columns.length;
    let colDrag = getWidthColumn(column);
    let columnHover = (indexSubSection === -1) ? formBuilder.sections[indexSection].rows[hoverIndexRow].columns.length : formBuilder.sections[indexSection].sectionSubs[indexSubSection].rows[hoverIndexRow].columns.length;
    let colHover = getWidthColumn(columnHover);
    if(indexSubSection === -1){
      formBuilderNew.sections[indexSection].rows[dragIndexRow].columns[dragIndexCol].col = colDrag;
      formBuilderNew.sections[indexSection].rows[hoverIndexRow].columns[hoverIndexCol].col = colHover;
      formBuilder.sections[indexSection].sectionSubs.rows = formBuilder.sections[indexSection].rows;
    }else{
      //formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[dragIndexRow].columns[dragIndexCol].width = colDrag;
      formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[dragIndexRow].columns.forEach(col => {
        col.width = colDrag;
      });
      //formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[hoverIndexRow].columns[hoverIndexCol].width = colHover;
      formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[hoverIndexRow].columns.forEach(col => {
        col.width = colHover;
      });
      formBuilder.sections[indexSection].sectionSubs.rows = formBuilder.sections[indexSection].sectionSubs[indexSubSection].rows;
    }*/
    this.setState({formBuilder:formBuilderNew},()=>{
      this.props.updateFormBuilder(formBuilderNew)
    })
  }

  changeWidth = (indexRow,indexCol,indexSection,indexSubSection,newWidth) => {
    const {formBuilder} = this.state;
    let formBuilderNew = cloneDeep(formBuilder);
    let breakCondition = false;
    if(indexSubSection === -1){
      formBuilderNew.sections[indexSection].rows[indexRow].columns[indexCol].col = newWidth;
      formBuilderNew.sections[indexSection].rows[indexRow].columns[indexCol].width = newWidth;
      let quantityColumns = formBuilderNew.sections[indexSection].rows[indexRow].columns.length;
      formBuilderNew.sections[indexSection].rows[indexRow].columns.forEach((c,index) => {
        let classSection = c.col ? c.col : c.width;
        if(!breakCondition){
          switch (classSection) {
            case "col100" : return;
            case "colR80" :
            case "col75":
              if(quantityColumns === 3 && index === quantityColumns - 2){
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index-1].col = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index-1].width = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+1].col = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+1].width = "col25";
                breakCondition = true;
              } else if(quantityColumns === 2 && index === 0){
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+1].col = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+1].width = "col25";
                breakCondition = true;
              }return;
            case "col50":
            case "colR50":
              if(quantityColumns === 3 && index === quantityColumns - 2){
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index-1].col = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index-1].width = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+1].col = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+1].width = "col25";
                breakCondition = true;
              } else if(quantityColumns === 3 && index === 0){
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+1].col = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+1].width = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+2].col = "col25";
                formBuilderNew.sections[indexSection].rows[indexRow].columns[index+2].width = "col25";
                breakCondition = true;
              }
              return;
            default: break;
          }
        }
      });
    }else{
      formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[indexCol].col = newWidth;
      formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[indexCol].width = newWidth;
      let quantityColumns = formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns.length;
      formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns.forEach((c,index) => {
        let classSection = c.col ? c.col : c.width;
        if(!breakCondition){
          switch (classSection) {
            case "col100" : break;
            case "colR80" :
            case "col75":
              if(quantityColumns === 3 && index === quantityColumns - 2){
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index-1].col = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index-1].width = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].col = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].width = "col25";
                breakCondition = true;
              }else if(quantityColumns === 2 && index === 0){
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].col = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].width = "col25";
                breakCondition = true;
              } break;
            case "col33":
              if(quantityColumns === 3 && index === 0){
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].col = "col33";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].width = "col33";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+2].col = "col33";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+2].width = "col33";
                breakCondition = true;
              } break;
            case "col50":
            case "colR50":
              if(quantityColumns === 3 && index === quantityColumns - 2){
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index-1].col = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index-1].width = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].col = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].width = "col25";
                breakCondition = true;
              } else if(quantityColumns === 3 && index === 0){
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].col = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+1].width = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+2].col = "col25";
                formBuilderNew.sections[indexSection].sectionSubs[indexSubSection].rows[indexRow].columns[index+2].width = "col25";
                breakCondition = true;
              }
              break;
            default: break;
          }
        }
      });
    }
    this.setState({formBuilder:formBuilderNew},()=>{
      this.props.updateFormBuilder(formBuilderNew)
    })
  }

  viewNewAttribute = (attributeEdit, event) => {
    event.stopPropagation()
    const {allAttributesEdit,attributesSelected} = this.state;
    if(attributeEdit){
      let findAttribute = allAttributesEdit.find(x => x.id === attributeEdit.id);
      const attributeFound = attributesSelected.find(x => x.id === attributeEdit.id);
      if(findAttribute){
        //todo: it's a attribute
        findAttribute.attributeValues = attributeEdit.attributeValues;
        findAttribute.displayValue = attributeEdit.displayValue;
        findAttribute.entityTypes = attributeEdit.entityTypes;
        findAttribute.typeAttribute = attributeEdit.inputType;
        findAttribute[FIELD_VALIDATION] = attributeFound[FIELD_VALIDATION];
        findAttribute[FIELD_WITH_SYMBOL] = attributeFound[FIELD_WITH_SYMBOL];
        findAttribute[FIELD_READ_ONLY] = attributeFound[FIELD_READ_ONLY];
        findAttribute[FIELD_NEGATIVE] = attributeFound[FIELD_NEGATIVE];
      }else{
        //todo: it's a field
        findAttribute = {...attributeFound}
      }
      this.setState({showModal: true, showPopoverAttribute: {},attributeEdit: findAttribute})
    }else{
      this.setState({showModal: true, showPopoverAttribute: {}});
    }
  }

  updateDimensions = () => {
    if(this.rowContent){
      let rowWidth = this.rowContent.getBoundingClientRect().width;
      this.setState({rowWidth:rowWidth});
    }
  }

  componentDidMount() {
    if(this.rowContent){
      let rowWidth = this.rowContent.getBoundingClientRect().width;
      this.setState({rowWidth:rowWidth});
    }
    document.addEventListener("click", this.handleClickOutside);
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  setWrapperAttributeRef(node) {
    this.wrapperAttributeRef = node;
  }

  setPopoverAttributeRef(attributeId, node) {
    this.popoverAttributeRef[attributeId] = node;
  }

  handleClickOutside = (event) => {
    event.preventDefault();
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.buttonRef[this.wrapperRef.id].contains(event.target)) {
      this.onClosePopover("showPopoverAdd", this.wrapperRef.id)
    }
    if (this.wrapperMenuRef && !this.wrapperMenuRef.contains(event.target) && !this.menuSectionRef[this.wrapperMenuRef.id].contains(event.target)) {
      this.onClosePopoverSectionMenu(this.wrapperMenuRef.id)
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  setWrapperMenuRef = (node) => {
    this.wrapperMenuRef = node;
  }

  setButtonRef = (sectionId, node) => {
    this.buttonRef[sectionId] = node;
  }

  setMenuSectionRef = (sectionId, node) => {
    this.menuSectionRef[sectionId] = node;
  }

  setRowRef = (row) => {
    this.rowContent = row;
  }

  saveAttributeOnlyForm = (attribute, indexSecSelected) => {
    this.setState({showModal: false, attributeEdit:null});
    this.onSelectAttribute(attribute, indexSecSelected, attribute);
  }

  render(){
    let {showPopoverAdd, showPopoverMenu, isOpenRename, title, attributesSelected, classFormSelected, showPopoverAttribute,
      formBuilder, dataSourceGroups,attributeEdit,allAttributesEdit,rowWidth} = this.state;
    let {isDragging, index, form, section} = this.props;

    const opacity = isDragging ? 0 : 1;
    return <div key={index} className="form-sec"  style={{opacity}}>
        <div className="form-section-header">
          <label>{section.header.title}</label>
          <div className="form-sec-img">
            <i ref={this.setMenuSectionRef.bind(this, section.id)} className="fa fa-ellipsis-h"
               onClick={() => this.onChangePopoverMenu(section.id, index)}/>
            {
              showPopoverMenu[section.id] ?
                this.renderPopoverMenu(formBuilder.sections.length, section, index, isOpenRename,title) : null
            }
          </div>
        </div>
        {
          (section.rows.length > 0)?
            section.rows.map((row, indexRow) => {
              return (
                <div key={indexRow} className="col100 row-attribute-space" ref={this.setRowRef.bind(this)}>
                  {
                    row.columns.map((column, indexCol) => {
                      let isResizable = row.columns.length < 4;
                      let attributeInfo = attributesSelected.filter(x => x.id === column.status);
                      let countRow = column.col;
                      let indexSubSection=-1;
                      return (
                        <AttributeItem key={indexCol}
                                       indexCol={indexCol}
                                       column={column}
                                       index={index}
                                       indexRow={indexRow}
                                       indexSubSection={indexSubSection}
                                       countRow={countRow}
                                       attributeInfo={attributeInfo}
                                       showPopoverAttribute={showPopoverAttribute}
                                       form={form}
                                       dataSourceGroups={dataSourceGroups}
                                       attributesSelected={attributesSelected}
                                       onSelectAttribute={this.onSelectAttribute}
                                       viewNewAttribute={this.viewNewAttribute}
                                       onChangePopoverAttribute={this.onChangePopoverAttribute}
                                       classFormSelected={classFormSelected}
                                       onDeleteAttributeNotSelected={this.onDeleteAttributeNotSelected}
                                       onDeleteAttributeSelected={this.onDeleteAttributeSelected}
                                       onClosePopoverAttribute={this.onClosePopoverAttribute}
                                       moveAttribute={this.moveAttribute}
                                       isResizable={isResizable}
                                       allAttributesEdit={allAttributesEdit}
                                       rowWidth={rowWidth}
                                       quantityColumns={row.columns.length}
                                       changeWidth={this.changeWidth}/>
                      )
                    })
                  }
                </div>
              )
            }) : ((section.sectionSubs.length > 0)?
            section.sectionSubs.map((sectionSub, indexSection) => {
              return (
                <div key={indexSection} className="col100 row-attribute-space subsection">
                  <label>{sectionSub.title}</label>
                  {
                    sectionSub.rows.map((row, indexRow) => {
                      return(
                      <div key={indexRow} className="col100 row-attribute-space row" ref={this.setRowRef.bind(this)}>
                        {
                          row.columns.map((column, indexCol) => {
                            let isResizable = row.columns.length < 4;
                            let attributeInfo = attributesSelected.filter(x => x.id === column.status);
                            let countRow = column.width;
                            return (
                              <AttributeItem key={indexCol}
                                             indexCol={indexCol}
                                             column={column}
                                             index={index}
                                             indexSubSection={indexSection}
                                             indexRow={indexRow}
                                             countRow={countRow}
                                             attributeInfo={attributeInfo}
                                             showPopoverAttribute={showPopoverAttribute}
                                             form={form}
                                             dataSourceGroups={dataSourceGroups}
                                             attributesSelected={attributesSelected}
                                             onSelectAttribute={this.onSelectAttribute}
                                             viewNewAttribute={this.viewNewAttribute}
                                             onChangePopoverAttribute={this.onChangePopoverAttribute}
                                             classFormSelected={classFormSelected}
                                             onDeleteAttributeNotSelected={this.onDeleteAttributeNotSelected}
                                             onDeleteAttributeSelected={this.onDeleteAttributeSelected}
                                             onClosePopoverAttribute={this.onClosePopoverAttribute}
                                             moveAttribute={this.moveAttribute}
                                             isResizable={isResizable}
                                             allAttributesEdit={allAttributesEdit}
                                             rowWidth={rowWidth}
                                             quantityColumns={row.columns.length}
                                             changeWidth={this.changeWidth}/>
                            )
                          })
                        }
                      </div>
                      )
                      })

                  }
                </div>
              )
            }):null)
        }
        <div className="form-section-button">
          {
            showPopoverAdd[section.id] ?
              <button ref={this.setButtonRef.bind(this, section.id)}
                      onClick={() => this.onClosePopover("showPopoverAdd", section.id)}
                      className="format-setting-button-add" style={{backgroundColor: "#ff6564"}}>x</button>
              :
              <button ref={this.setButtonRef.bind(this, section.id)}
                      onClick={() => this.onViewPopover("showPopoverAdd", section.id)}
                      className="format-setting-button-add">+</button>
          }
        </div>
        {
          showPopoverAdd[section.id] ?
            this.renderPopoverAdd(index, section.id) : null
        }
        {
          (this.state.showModal) ?
            <ModalAttribute show={this.state.showModal}
                            onHide={this.handleHideModal.bind(this)}
                            container={this}
                            onSelectAttribute={this.onSelectAttribute}
                            indexSubSection={this.state.indexSubSection}
                            form={form}
                            attributeEdit={attributeEdit}
                            allAttributesEdit={allAttributesEdit}
                            saveAttributeOnlyForm={this.saveAttributeOnlyForm}/> : null
        }
      </div>
  }

}

const SectionItemContainer = (props) => {
  return(
    <DragDropContext>
      <SectionItem {...props}/>
    </DragDropContext>
  )
}

export default flow(
  DragSource("SectionItem", SectionItemSource, collect),
  DropTarget("SectionItem", SectionItemTarget, (connect) => ({
    connectDropTarget: connect.dropTarget()
  }))
)(SectionItemContainer);