import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ReactSVG} from 'react-svg'
import {FormInputTypes, siteColorAdmin, VIEW_SALE} from '../../Constants'
import {loadAttributeValuesByAttributeCode, searchInventoryApi} from '../../Api'
import {debounce} from 'lodash'
import {getFormatNumber} from '../util/Utils'
import {pathServer, ATTRIBUTE_CODE_CAR_STATUS} from '../../../common/Constants'
import {DPPagination} from '../../../common/DPPagination'
import {connect} from "react-redux";
import {setKeyword} from "../../actions";
import {SUCCESSFUL_CODE} from '../../../common/Constants'

function getQueryFilter(query) {
  let queryFilter = {...query};

  delete queryFilter.rangeMinValueYear;
  delete queryFilter.rangeMaxValueYear;
  delete queryFilter.rangeMinValueMile;
  delete queryFilter.rangeMaxValueMile;
  delete queryFilter.rangeMinValuePrice;
  delete queryFilter.rangeMaxValuePrice;

  return queryFilter;
}

class CarSearchPage extends Component {
  constructor(props) {
    super(props);
    this.handleItemsByPage = this.handleItemsByPage.bind(this);
    this.changeByKeyword   = debounce(this.search,500);

    this.state = {
      cars: [],
      optionsBody: [],
      quantityCars: 0,
      itemsByPageQuantity: 8,
      valuesToCarStatus: [],
      currentPage: 1,
      isSearching: true,
      query: {
        from: 0,
        size: 8,
        keyword: '',
        status: ['Inspect/Repair', 'Hold', 'Front Line Ready', 'Acquired', 'Coming Soon'],
      },
    }
  }

  async componentDidMount() {
    const response = await loadAttributeValuesByAttributeCode(ATTRIBUTE_CODE_CAR_STATUS)
    const { responseCode, responseMessage, data } = response
    if (responseCode === SUCCESSFUL_CODE) {
      let attributeValues = data.attributeValues;
      const index = data.attributeValues.findIndex(
        (attributeValue) => attributeValue.code === ATTRIBUTE_CODE_CAR_STATUS
      );
      let values = [];
      const colors = ["#2194F1", "#005294", "#4AD991", "#FF6565", "#AC7F62", "#F3A200", "#787878"];

      attributeValues.splice(index, 1);
      attributeValues.forEach((attribute, index) => {
        values.push({
          id: attribute.id,
          dealerId: attribute.dealerId,
          attributeId: attribute.attributeId,
          code: attribute.code,
          name: attribute.name,
          displayValue: attribute.displayValue,
          color: colors[index]
        });
      });

      this.setState({ valuesToCarStatus: values });
    } else {
      console.error(responseMessage)
    }

    const { query } = this.state;
    this.search(query);
  }

  // This is used only on this class
  async search(query) {
    const queryFilter = getQueryFilter(query);
    const response = await searchInventoryApi(queryFilter, VIEW_SALE)
    const { responseCode, responseMessage, data } = response
    if (responseCode === SUCCESSFUL_CODE) {
      this.setState(
        {
          cars: data.cars,
          optionsBody: data.optionsBody,
          quantityCars: data.quantityCars,
          query: query,
          isSearching: false
        },
        () => this.updateHover()
      );
    } else {
      console.log(responseMessage)
      this.setState({ isSearching: false });
    }
  }

  // This is used only on this class
  updateHover() {
    const {cars} = this.state;
    cars.map((car, index) => {
      let salesCar = document.getElementById(`sales-car-${index}`);
      if (salesCar) {
        let idCar = $(salesCar);
        let button = document.getElementById(`select-button-car-${index}`);
        let idButton = $(button);
        idCar.hover(function () {
          idButton.css("display", "flex");
        }, function () {
          idButton.css("display", "none");
        });
      }
    });
  }

  // This is used only on this class
    componentWillReceiveProps(nextProps, nextContext) {
     const {keyword} = nextProps
     if(this.props.keyword !== keyword){
       let newQuery = {...this.state.query};
       newQuery.keyword = keyword;
       newQuery.from = 0;
       this.setState({query: newQuery, currentPage:1, isSearching:true});
       this.changeByKeyword(newQuery)
     }
   }

  handlePageChange = (nextPage, totalPages) => {
    const {itemsByPageQuantity, query} = this.state;
    let newQuery = {...query};
    let currentPage = nextPage;

    if (currentPage > totalPages) {
      currentPage = 1;
      newQuery.from = 0;
    } else if (currentPage === 0) {
      currentPage = totalPages;
      newQuery.from = itemsByPageQuantity * (totalPages - 1);
    } else {
      newQuery.from = itemsByPageQuantity * (currentPage - 1);
    }
    newQuery.size = itemsByPageQuantity;

    this.setState({query: newQuery, currentPage,isSearching:true});
    this.search(newQuery)
  }

  handleItemsByPage(itemsByPageQuantity) {
    let newQuery = {...this.state.query};

    newQuery.from = 0;
    newQuery.size = itemsByPageQuantity;
    this.setState(
      {
        query: newQuery,
        currentPage: 1,
        itemsByPageQuantity: itemsByPageQuantity,
        isSearching:true
      })
    this.search(newQuery)
  }

  render() {
    const {cars, quantityCars, itemsByPageQuantity, query, valuesToCarStatus, isSearching, currentPage} = this.state;
    const {startSale, errorMessage, addNewCar} = this.props;
    const totalPages = quantityCars ? Math.ceil(quantityCars / itemsByPageQuantity) : 1;

    return (
      <div className='inventory-container-sales'>
        <div className='filters-container'>
          <div className='sales-error-message'>{errorMessage}</div>
          <div
            className='inventory-pagination'
            style={{float: 'right'}}
          >
            <DPPagination
              selectedItemsByPage={itemsByPageQuantity}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={this.handlePageChange}
              handleChangeItemsByPage={this.handleItemsByPage}
              optionsQuantityByPage={[8, 16, 24]}
              siteColor={siteColorAdmin}
            />
          </div>
        </div>
        <div className='cars-container'>
          {
            isSearching
              ? <div className='loader-container'>
                <div className='loader'/>
              </div>
              : (cars.length === 0
                ? <div className='message-cars-not-found'>
                  <div className='container-image'>
                    <img src={`${pathServer.PATH_IMG}not-found.png`}/>
                    <h3>No cars matched your search</h3>
                  </div>
                  <div className='container-btn-add-car'>
                    <button
                      className='btn btn-add-car'
                      onClick={addNewCar}>Add a New Car
                    </button>
                  </div>
                </div>
                : cars.map((car, index) => {
                  let mileageAndPrice = '$' + getFormatNumber(car.suggestedPrice) + ' ' + '|' + ' ' +
                    Math.round(Math.round((car.mileage / 1000) * 100) / 100) + 'k' + ' miles';
                  let heightPhoto = 277;
                  let status = valuesToCarStatus.find(value => value.displayValue === car['car.status'].attributeValue);

                  return (
                    <div
                      key={`key-sales-car-${index}`}
                      className='sales-car-container'
                    >
                      <div
                        id={`sales-car-${index}`}
                        className='sales-car'
                      >
                        <div
                          id='borderTop'
                          className='border-top'
                          style={{borderTop: '3px ' + (status && status.color ? status.color : '#333') + ' solid'}}
                        />
                        <div className='info-car'>
                          <label className='info-car-title'>
                            {`${car.year} ${car.makeId.charAt(0).toUpperCase()}${car.makeId.slice(1)} ${car.modelId.charAt(0).toUpperCase()}${car.modelId.slice(1)}`}
                          </label>
                        </div>
                        <div
                          className='car-image-container'
                          style={{height: heightPhoto}}
                        >
                          <img
                            src={(car.mainImage === '' || !car.mainImage) ? FormInputTypes.DEFAULT_IMAGE_URL : car.mainImage}
                            className='image-object-fit-no-pointer'
                          />
                        </div>
                        <div className='car-more-info-container'>
                          <div className='more-info'>
                            <label
                              className='car-status'
                              style={{color: (status && status.color ? status.color : '#333')}}>{status && status.displayValue ? status.displayValue : 'Status'}
                            </label>
                            <label className='car-price-mile'>{mileageAndPrice}</label>
                          </div>
                          <div className='sales-car-select'>
                            <div
                              id={`select-button-car-${index}`}
                              className='select-car'
                              onClick={() => startSale(car)}
                              style={{display: 'none'}}
                              data-test={`sale-select-car-${index}`}
                            >
                              {
                                car['sales'].length > 0
                                  ? <div className='container-icon-exclamation'>
                                    <ReactSVG
                                      src={pathServer.PATH_IMG + 'icon/ic_baseline_error.svg'}
                                      beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                                      className='icon-error'
                                    />
                                  </div>
                                  : null
                              }
                              Select
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              )
          }
        </div>
      </div>
    )
  }
}

CarSearchPage.propTypes = {
  startSale: PropTypes.func,
  errorMessage: PropTypes.string,
  addNewCar: PropTypes.func,
  confirmContinue: PropTypes.func, // Currently it's not used
  closeModal: PropTypes.func, // Currently it's not used
  showModal: PropTypes.bool,  // Currently it's not used
}

const mapStateToProps = (state, ownProps) => {
  return {
    keyword:state.keyword,
  }
};

export default connect(mapStateToProps,{setKeyword})(CarSearchPage)