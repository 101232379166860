import React, {useState} from 'react'
import {DPSpinner} from '../../../common/DPSpinner'
import DPTabs from '../../../common/themes/DPTabs'
import {Icons} from '../../../common/DPIcon'
import SettingContent from './SettingContent'
import SettingStyle from './SettingStyle'
import SettingSiteIdentity from './SettingSiteIdentity'
import {ReactSVG} from 'react-svg'
import {EmptyFunc, pathServer} from '../../../../common/Constants'

export const PopoverIconsBody = ({icons, selectIcon, classname = "", index}) => {
  return(
  <div className={classname}>
    {
      icons.map((row,indexRow) => {
        return (
          <div key={indexRow} className="icons-row-container">
            {
              row.map((icon,indexIcon) => {
                return(
                  <div key={indexIcon} className="about-us-icon" onClick={(event) => selectIcon(icon, event, index)}>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/" + icon} className="icon-about-us" beforeInjection={ svg => svg.classList.add('icon-about-us-svg')}/>
                  </div>
                )
              })
            }
          </div>
        )
      })
    }
  </div>
)}

const ThemeSettingContainer = ({generalSetting, onChange, save, close, onChangeFile, pages, onChangePages,uploadMediaFiles,
                                 files, fields, dealerMedias, filesToDealerMedia, dealerMediasToDelete,selectPage, templateCode,
                                 dealerName, metadataType, isLoadingSpinnerOnMainPage}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const tabs = [
    {key: 'content', title : 'CONTENT', classNameHeader: "tab-header", className: "", component: <SettingContent key={'setting-content'} pages={pages} onChangePages={onChangePages} onChange={onChange} onChangeFile={onChangeFile} generalSetting={generalSetting}
                                                                                                                 uploadMediaFiles={uploadMediaFiles} files={files} fields={fields} dealerMedias={dealerMedias}
                                                                                                                 filesToDealerMedia={filesToDealerMedia} dealerMediasToDelete={dealerMediasToDelete} selectPage={selectPage} templateCode ={templateCode} isLoadingSpinnerOnMainPage={isLoadingSpinnerOnMainPage}/>},
    {key: 'siteIdentity', title : 'SITE IDENTITY', classNameHeader: "tab-header", className: "", component: <SettingSiteIdentity key={'setting-site-identity'} generalSetting={generalSetting} onChange={onChange} onChangeFile={onChangeFile} dealerName={dealerName} metadataType={metadataType}/>},
    {key: 'style', title : 'STYLE', classNameHeader: "tab-header", className: "", component: <SettingStyle key={'setting-style'} generalSetting={generalSetting} onChange={onChange}/>}
  ]

  const options = [
    {id: 'undo', title : 'UNDO', icon: Icons.ARROW_BACK, onClick: EmptyFunc, show: false},
    {id: 'redo', title : 'REDO', icon: Icons.ARROW_NEXT, onClick: EmptyFunc, show: false},
    {id: 'save', title : '', icon: Icons.SAVE_ICON, onClick: save, show: true}
  ]

  return(
    <>
      {isLoading ? <DPSpinner classContainer={"loader-container"}/> : null}
      {
        isSuccess ?
          <div className="showMessage alert alert-success message-template">
            <h2>Success!</h2>
            <p>Your information has been saved</p>
          </div> : null
      }
      <div className="template-customize">
        <DPTabs tabs={tabs}
                classNameContainer="tab-list"
                classNameTabActive="tab-list-active"
                options={options}/>
      </div>
    </>
  )
}

export default ThemeSettingContainer