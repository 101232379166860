import React from 'react'
import PropTypes from 'prop-types'
import InputElement from 'react-input-mask'

export const DPInputElement = (props) => {
  const { className, title, id, type, value, onChange, disable, isRequired, maxLength, errorMessage, style, rightElement, placeholder, inputTitle,
    onBlur, mask, alwaysShowMask, maskChar, inputName, readOnly} = props

  return (
    <div className={className}>
      {
        title ?
          <div className="info-label">
            <label className="title">{title}</label>
            {
              isRequired
                ? <span className="label-error">*</span>
                : null
            }
            {
              rightElement ? rightElement : null
            }
          </div> : null
      }
      <div className="classInput">
        <InputElement
          type={type}
          className={errorMessage ? "form-control has-error" : "form-control"}
          mask={mask}
          placeholder={placeholder}
          alwaysShowMask={alwaysShowMask}
          maskChar={maskChar}
          name={inputName}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />

        {
          errorMessage
            ? <p className="help-block">{errorMessage}</p>
            : null
        }
      </div>
    </div>
  )
}

DPInputElement.defaultProps = {
  disable: false,
  maxLength: null,
  onChange: null,
  isRequired: false,
  errorMessage: '',
  style: null,
  rightElement: null,
  placeholder: '',
  inputTitle: ''
}

DPInputElement.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disable: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.any,
  rightElement: PropTypes.object,
  inputTitle: PropTypes.string,
}
