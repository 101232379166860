import React from 'react'
import DPButtonText from '../../../common/DPButtonText'
import DPKeyboardDatePicker from '../../common/DPKeyboardDatePicker'
import DPCheckboxIcon from '../../../common/DPCheckboxIcon'
import DPSelectionDropDown from '../../common/DPSelectionDropDown'
import DPButton from '../../common/DPButton'

const locationOptions = [
  {id: '1', displayValue: 'All locations'},
  {id: 'orem', displayValue: 'Orem'},
  {id: 'provo', displayValue: 'Provo'},
  {id: 'slc', displayValue: 'SLC'},
  {id: 'draper', displayValue: 'Draper'},
]

const facebookOptions = [
  {id: 'positive', displayValue: 'Recommended'},
  {id: 'negative', displayValue: 'Not recommended'},
]

export const tagOptions = [
  {id: 'promoter', displayValue: 'Promoter', iconColor: '#4AD991'},
  {id: 'badExperience', displayValue: 'Bad experience', iconColor: '#FF6565'},
  {id: 'referral', displayValue: 'Referral', iconColor: '#5591EF'},
]

const starOptions = [
  {id: '1', displayValue: '1 star'},
  {id: '2', displayValue: '2 star'},
  {id: '3', displayValue: '3 star'},
  {id: '4', displayValue: '4 star'},
  {id: '5', displayValue: '5 star'},
]

const ReviewsFilters = ({checkGoogle, setCheckGoogle, checkFacebook, setCheckFacebook, starRangeFrom, starRangeTo, setOptionValue,
                          handleOptionOnArray, facebookOptionsSelected, from, to, setDate, resetFilters}) => {
  return(
    <div className="box-filters">
      <div className="header-filters">
        <span className="title-filters" >Filters</span>
        <DPButtonText textButton='Reset' onClick={resetFilters}/>
      </div>
      {/*<DPSelectMultipleDropDown id='locationDropdown' displayValue='Locations' placeholder='All locations' options={locationOptions} selectedOptionsId={['provo', 'orem']}/>*/}
      <div className="container-date-range" style={{paddingBottom: '10px'}}>
        <span className="title">Date Range</span>
        <div className="box-date-range">
          <DPKeyboardDatePicker
            id={"dateId"}
            name={'inputDate'}
            value={from}
            className={"input-date-box"}
            onChange={(dateValue) => setDate(dateValue, 'from')}
           // format={"YYYY-MM-DD"}
          />
          <DPKeyboardDatePicker
            id={"dateId"}
            name={'inputDate'}
            value={to}
            className={"input-date-box"}
            onChange={(dateValue) => setDate(dateValue, 'to')}
          />
        </div>
      </div>
      {/**** DO NOT DELETE THIS COMMENT PLEASE ****/}
      {/*<div className="container-tags">
        <span className="title">Tags</span>
        <DPButtonText textButton='All tags' onClick={setAllTags} buttonClass={"btn-all-tags"}/>
        {
          tagOptions.map((option, index) => {
            const {id, displayValue, iconColor} = option
            const optionIndexFound = tagsSelected.findIndex( tag => tag === id)
            return(
              <DPCheckboxIcon key={`checkIcon${index}`} label={displayValue} iconColor={iconColor} checked={optionIndexFound !== -1} onCheck={() => handleCheck(id)}/>
            )
          })
        }
      </div>*/}
      {/**** DO NOT DELETE THIS COMMENT PLEASE ****/}
      <div className="container-platform">
        <span className="title">Platform</span>
        <div className="google-platform">
          <DPCheckboxIcon label='Google' checked={checkGoogle} onCheck={() => setCheckGoogle(!checkGoogle)}/>
          {
            checkGoogle &&
            <div className="container-select-range">
              <DPSelectionDropDown id='starRangeFrom' placeholder='1 star' label='Min. Range' options={starOptions} value={starRangeFrom} onClick={ (id) => setOptionValue(id, 'starRangeFrom')}/>
              <DPSelectionDropDown id='starRangeTo' placeholder='5 star' label='Max. Range' options={starOptions} value={starRangeTo} onClick={ (id) => setOptionValue(id, 'starRangeTo')}/>
            </div>
          }
        </div>
        <DPCheckboxIcon label='Facebook' checked={checkFacebook} onCheck={() => setCheckFacebook(!checkFacebook)}/>
        {
          checkFacebook &&
          <>
            {
              facebookOptions.map((option, index) => {
                const {id, displayValue} = option
                const selected = facebookOptionsSelected.find(optionSelected => optionSelected ===  id )
                return(
                  <DPButton
                    key={`${id}Button`}
                    className={`btn-platform ${selected && 'selected-button'}`}
                    onClick={() => handleOptionOnArray(id)}>{displayValue}
                  </DPButton>
                )
              })
            }
          </>
        }
      </div>
    </div>
  )
}

export default ReviewsFilters