import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {
  ATTRIBUTE_CODE_CAR_BODY, ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR, ATTRIBUTE_CODE_CAR_TITLE,
  ATTRIBUTE_CODE_MAKE_ID,
  ATTRIBUTE_CODE_MODEL_ID,
  ATTRIBUTE_CODE_CAR_CONDITION,
  ATTRIBUTE_CODE_CAR_TRIM,
  formCarApi,
  ATTRIBUTE_CODE_CAR_YEAR,
} from '../../Constants'

import {Popover, PopoverSearchable} from '../util/ComponentUtils'
import Moment from 'moment'
import {Modal} from 'react-bootstrap'
import {isEmpty} from 'lodash'
import DynamicNumber from 'react-dynamic-number'
import {getAnchorEl, validateError} from '../util/Utils'
import {TitleHeader} from './SaleEdit.jsx'
import {
  deleteSaleTradeIn,
  loadAttributeValuesByAttributeCodes,
  loadSaleTradeIns,
  saveSaleTradeIn,
  updateSaleTradeIn,
  getVinByCarId,
  addCarTradeIn,
  verifyVin,
  getDataFromVinNumber,
} from '../../Api'
import ModalConfirmDelete from '../util/ModalConfirmDelete.jsx'
import {cloneDeep} from 'lodash'
import {DPSpinner} from '../../common/DPSpinner'
import DPKeyboardDatePicker from '../../common/DPKeyboardDatePicker'
import {pathServer, SUCCESSFUL_CODE, TIME_FIVE_SECONDS} from '../../../common/Constants'

function loadYears(lastYear, firstYear) {
  let years = new Array();
  for (let i = lastYear; i >= firstYear; i--) {
    years.push({id: i, displayValue: i.toString(), name: i.toString()});
  }
  return years;
}

const optionYears = loadYears(new Date().getFullYear()+1, 1930);

function getErrorMessage(key,value){
  switch (key) {
    case "mileage":
    case "tradeInAllowance":
    case "balanceOwedOn" :
    case ATTRIBUTE_CODE_CAR_TITLE:
    case ATTRIBUTE_CODE_CAR_BODY:
    case ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR:
    case "year":
    case "makeId":
    case "modelId":
    case "balanceOwedTo":
    case ATTRIBUTE_CODE_CAR_CONDITION:
    case ATTRIBUTE_CODE_CAR_TRIM:
      return "This field must be filled.";
    case "vin":
      if(isEmpty(value))
        return "This field must be filled.";
      else
        return "Vin must have at least 11 characters."
  }
}

const Error = ({errorMessage})  => {
  return(
    <span className="error-class">{errorMessage}</span>
  )
};

function getStyle(error,name,value) {
  let style = {};
  if(!error && name === "vin" && !findErrorVin(value))
    style = {borderColor: "#4AD991"};

  return style
}

const InputValue = ({placeholder, name, onChange, onBlur, error, title, isRequired, value, length, readOnly, classWrapperInput, handleKeyPress}) => {
  let errorMessage = "";
  if(error)
    errorMessage = getErrorMessage(name,value);
  return (
    <div className="flex-colR1">
      <div className="item">
        <div className="container-title">
          <span>{title}</span>
          {isRequired ? <span className="label-error">*</span> : null}
        </div>
        <div className={`input-container ${classWrapperInput}`}>
          <input id={"input" + name}
                 type="text" className={ error ? "form-control has-error" : "form-control"} placeholder={placeholder}
                 onChange={(e) => !readOnly ? onChange(name, e) : null}
                 value={value ? value : ""}
                 onBlur={onBlur ? (e) => onBlur(name, isRequired, e) : () => {}}
                 maxLength={length ? length : {} }
                 style={getStyle(error,name,value)}
                 readOnly={readOnly}
                 onKeyPress={handleKeyPress ? (e) => handleKeyPress(e) : null}
                 data-test={`${name}-input`}
          />
          {
            error ?
              <Error errorMessage={errorMessage}/> : null
          }
        </div>
      </div>
    </div>
  )
};

function getClassForSelectValue(error,readOnly) {
  let className = 'select';
  if(error)
    className += ' has-error';
  else if(readOnly)
    className += ' select-disabled';
  return className;
}

const SelectValue = ({containerSelectClass,showPopover,value,id,popoverSelected,title,options,isPopoverActive,style,
                       isRequired,error,readOnly}) => {
  let inputValue = value;
  let errorMessage = "";
  if(error)
    errorMessage = getErrorMessage(id,value);
  if(value !== ""){
    const optionFound = options.find(option => {return option.id === value});
    if(optionFound){
      if(optionFound.value)
        inputValue = optionFound.value;
      else if(optionFound.displayValue)
        inputValue = optionFound.displayValue;
    }
  }
  return(
    <div className={containerSelectClass}>
      <div className="top-space">
        <label className="title-select">{title}</label>
        {isRequired ? <span className="label-error">*</span> : null}
        <div className={getClassForSelectValue(error,readOnly)} id={id}
             onClick={(evt) => !readOnly ? showPopover(id, evt) : null}
             style={style ? style : {}}
             data-test={`${id}-select`}>
          <div className="container-label">
            <label className={ (inputValue === "") ? "label-select not-selected": "label-select"}>{(inputValue === "") ? "Select one" : inputValue}</label>
          </div>
          {
            isPopoverActive && popoverSelected === id ?
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                        className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                        className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
          }
        </div>
        {
          error ?
            <Error errorMessage={errorMessage}/> : null
        }
      </div>
    </div>
  )
};

const DynamicNumberElement = ({classColumn, title, id, value, onChange, error, isRequired, maxLength, thousand, integer,
                                separator,fraction,placeHolder, tradeIn, prefix, readOnly,classWrapperInput}) => {
  let errorMessage = "";
  if(error)
    errorMessage = getErrorMessage(id,value);
  return (
    <div className={classColumn}>
      <div className="top-space">
        {
          title ?
            <div className="container-title">
              <span className="title">{title}</span>
              {isRequired ? <span className="label-error">*</span> : null}
            </div> : null
        }
        <div className="input-trade-in">
          <div className={`container-input-trade-in ${classWrapperInput}`}>
            {
              prefix ?
                <span className="dollar-prefix">$</span> : null
            }
            <DynamicNumber id={id}
                           style = { prefix ? {padding: "3px 12px 3px 19px"} : {}}
                           className={ error ? "form-control has-error" : "form-control"}
                           placeholder={placeHolder ? placeHolder : ""}
                           value={value}
                           positive={true}
                           negative={false}
                           thousand={thousand}
                           onChange={(evt, modelValue, viewValue) => onChange(tradeIn, evt, modelValue, viewValue)}
                           separator={separator}
                           integer={integer}
                           fraction={fraction}
                           maxLength={maxLength}
                           disabled={readOnly}
                           data-test={`${id}-input`}
            />
          </div>
          {
            error ?
              <Error errorMessage={errorMessage}/> : null
          }
        </div>
      </div>
    </div>
  )
};

const InputDate = ({id,classCol, contentClass, classItem, value, title, onChange,error,isRequired, readOnly}) => {
  let dateObject;
  if(value && value !== ""){
    const valueDate = Moment(value,"YYYY-MM-DD");
    dateObject = new Date(valueDate);
  } else
    dateObject = null;
  return (
    <div className={contentClass}>
      <div className={(error ? classCol + ' has-error':classCol)}>
        <div className={classItem}>
          {
            title ?
              <div className="display-date">
                <p>{title}</p>
                {isRequired ? <span className="label-error">*</span>: null}
              </div> : null
          }
          <DPKeyboardDatePicker
            id={id}
            name={id}
            className={'form-control finance-input' + (readOnly ? ' input-date-disabled' : '')}
            value={dateObject}
            onChange={(date) => onChange(id, date)}
            format={'YYYY-MM-DD'}
            placeholder={'YYYY-MM-DD'}
            disabled={readOnly}
            openTo="year"
          />
        </div>
      </div>
    </div>
  );
};

function formatDate(value) {
  let newValue = "";
  if(value){
    newValue = Moment(value).format("YYYY-MM-DD");
  }
  return newValue;
}

// Get attributeValueId from 'values' on a selected TradeIn
function getAttValueIdBasedOnCode(code, tradeIn) {
  const tradeInValues = tradeIn && tradeIn.values
  const element = tradeInValues && tradeInValues.find(el => el.attributeCode === code)
  return element ? element['attributeValueId'] : ''
}

// Get attributeValue from 'values' on a selected TradeIn
function getAttValueBasedOnCode(code, tradeIn) {
  const tradeInValues = tradeIn && tradeIn.values
  const element = tradeInValues && tradeInValues.find(el => el.attributeCode === code)
  return element ? element['attributeValue'] : ''
}

const ModalTradeIn = ({show,showPopover,tradeIn,onBlur,errors,onChange, anchorEl,closePopover, isPopoverActive,onChangeDropDown,onChangeSearch,
                        onHide,makes,models,popoverSelected, onClickPopover, onChangeNumber,viewValues,onChangeDate,addTradeIn,isEdit, backEndError,
                        attributeValuesTitle,attributeValuesBody,attributeValuesColor, readOnly, attributeValuesCondition,
                        goToInventory, addToInventory, handleKeyPress, vinNotFoundErrorMessage, foundVin, messageError, isLoading}) => {
  let optionsSearch,foundValue = null;
  let existCar = false;
  if(popoverSelected === ATTRIBUTE_CODE_MAKE_ID){
    optionsSearch = makes;
    foundValue = optionsSearch.find(op => {return op.id.toString() === tradeIn.makeId.toString()});
  } else if(popoverSelected === ATTRIBUTE_CODE_MODEL_ID){
    if(tradeIn.makeId){
      optionsSearch = models.filter(model => { return model.makeId === tradeIn.makeId});
      foundValue = optionsSearch.find(op => {return op.id.toString() === tradeIn.modelId.toString()});
    }
  }
  tradeIn.dateOfVerification = formatDate(tradeIn.dateOfVerification);
  tradeIn.goodUntil = formatDate(tradeIn.goodUntil);
  if(isEmpty(tradeIn.carId)){
    existCar = true
  }

  // Current value for Balance Owed On Trade-In
  const valueForBalanceOwedOnTradeIn =
    existsElement(viewValues.balanceOwedOn)
      ? viewValues.balanceOwedOn
      : tradeIn.balanceOwedOn

  // Determines if Balance owed to (Finance Institution) is required
  const balanceOwedToIsRequired = !!parseFloat(valueForBalanceOwedOnTradeIn)

  return(
    <Modal show={show} className="sale-modal-container modal-trade-in">
      <Modal.Body style={isLoading ? {height: 580} : null}>
        {
          isLoading ?
            <DPSpinner classContainer={"loader-container"}/>
            :
            <div className="container-trade-in">
              <img src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon" onClick={onHide}/>
              <div className="container-title">
                <span className="title" style={{fontWeight: "bold", fontSize: 21, fontFamily: "'Open Sans', sans-serif"}}>Trade-in</span>
              </div>

              { // Specific error message
                vinNotFoundErrorMessage ?
                  <div className="container-message-info">
                    <div className="message-info" style={{border: "1px solid #FF6565"}}>
                      <img src={pathServer.PATH_IMG + "advertisement.png"} className= "icon-info"/>
                      <label style={{fontWeight:500}}>{'There is no data available for the VIN number provided.'}</label>
                    </div>
                  </div> : null
              }
              {
                foundVin ?
                  <div className="container-message-info">
                    <div className="message-info" style={{border: "1px solid #FF6565"}}>
                      <img src={pathServer.PATH_IMG + "inverted.png"} className= "icon-info"/>
                      <label style={{fontWeight:700}}>WARNING:</label>
                      <label style={{fontWeight:500}}>{messageError}</label>
                    </div>
                  </div> : null
              }

              <div className="container-top">
                <span className="title">Vehicle information</span>
                <div className="flex-row">
                  <InputValue
                    placeholder="Type here"
                    error={errors.vin}
                    onBlur={onBlur}
                    name="vin"
                    onChange={onChange}
                    title="Vin Number"
                    isRequired={true}
                    classWrapperInput={readOnly ? 'input-disabled' : ''}
                    value={tradeIn.vin}
                    length={17}
                    readOnly={readOnly}
                    handleKeyPress={handleKeyPress}
                  />
                  <DynamicNumberElement id="mileage" classColumn="flex-colR1" title="Mileage" value={ existsElement(viewValues.mileage) ? viewValues.mileage : tradeIn.mileage}
                                        onChange={onChangeNumber} separator={"."} placeHolder={"0.00"}
                                        thousand={true} integer={8} fraction={0} maxLength={12} classWrapperInput={readOnly ? 'input-disabled' : ''}
                                        error={errors.mileage} tradeIn={tradeIn} isRequired={true} readOnly={readOnly}/>
                  <SelectValue id={ATTRIBUTE_CODE_CAR_CONDITION} showPopover={showPopover} value={getAttValueIdBasedOnCode(ATTRIBUTE_CODE_CAR_CONDITION, tradeIn)} title={"Condition"} isPopoverActive={isPopoverActive}
                               containerSelectClass="flex-colR1" popoverSelected={popoverSelected} options={attributeValuesCondition} isRequired={true} error={errors[ATTRIBUTE_CODE_CAR_CONDITION]}
                               readOnly={readOnly}/>
                  <SelectValue id={ATTRIBUTE_CODE_CAR_TITLE} showPopover={showPopover} value={getAttValueIdBasedOnCode(ATTRIBUTE_CODE_CAR_TITLE, tradeIn)} title={"Title type"} isPopoverActive={isPopoverActive}
                               containerSelectClass="flex-colR1" popoverSelected={popoverSelected} options={attributeValuesTitle} isRequired={true} error={errors[ATTRIBUTE_CODE_CAR_TITLE]}
                               readOnly={readOnly}/>
                  <SelectValue id={ATTRIBUTE_CODE_CAR_BODY} showPopover={showPopover} value={getAttValueIdBasedOnCode(ATTRIBUTE_CODE_CAR_BODY, tradeIn)} title={"Body type"} isPopoverActive={isPopoverActive}
                               containerSelectClass="flex-colR1" popoverSelected={popoverSelected} options={attributeValuesBody} isRequired={true} error={errors[ATTRIBUTE_CODE_CAR_BODY]}
                               readOnly={readOnly}/>
                </div>
                <div className="flex-row">
                  <SelectValue id={ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR} showPopover={showPopover} value={getAttValueIdBasedOnCode(ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR, tradeIn)} title={"Exterior color"} isPopoverActive={isPopoverActive}
                               containerSelectClass="flex-colR1" popoverSelected={popoverSelected} options={attributeValuesColor} isRequired={true} error={errors[ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR]}
                               classWrapperInput={readOnly ? 'input-disabled' : ''} readOnly={readOnly}/>
                  <SelectValue id="year" showPopover={showPopover} value={tradeIn.year} title={"Year"} isPopoverActive={isPopoverActive}
                               containerSelectClass="flex-colR1" popoverSelected={popoverSelected} options={makes} isRequired={true} error={errors.year}
                               readOnly={readOnly}/>
                  <SelectValue id="makeId" showPopover={showPopover} value={tradeIn.makeId} title={"Make"} isPopoverActive={isPopoverActive} anchorEl={anchorEl}
                               containerSelectClass="flex-colR1" popoverSelected={popoverSelected} options={makes} isRequired={true} error={errors.makeId}
                               readOnly={readOnly}/>
                  <SelectValue id="modelId" showPopover={showPopover} value={tradeIn.modelId} title={"Model"} isPopoverActive={isPopoverActive} isRequired={true}
                               containerSelectClass="flex-colR1" popoverSelected={popoverSelected} options={models} error={errors.modelId}  anchorEl={anchorEl}
                               style={isEmpty(tradeIn.makeId) ? {backgroundColor: "#eee", pointerEvents: "none"} : {}}
                               readOnly={readOnly}/>
                  <InputValue placeholder="Trim" onBlur={onBlur} name={ATTRIBUTE_CODE_CAR_TRIM} onChange={onChange} title="Trim" isRequired={true}
                              value={getAttValueBasedOnCode(ATTRIBUTE_CODE_CAR_TRIM, tradeIn)} readOnly={readOnly} classWrapperInput={readOnly ? 'input-disabled' : ''}
                              error={errors[ATTRIBUTE_CODE_CAR_TRIM]}/>
                </div>
              </div>
              <div className="container-bottom">
                <span className="title">Financial information</span>
                <div className="flex-row">
                  <DynamicNumberElement id="tradeInAllowance" classColumn="flex-colR1" title="Trade-in Allowance" value={ existsElement(viewValues.tradeInAllowance) ? viewValues.tradeInAllowance : tradeIn.tradeInAllowance}
                                        onChange={onChangeNumber} separator={"."} placeHolder={"0.00"}
                                        thousand={true} integer={8} fraction={2} maxLength={13} prefix={true}
                                        error={errors.tradeInAllowance} tradeIn={tradeIn} isRequired={true}
                                        readOnly={readOnly} classWrapperInput={readOnly ? 'input-disabled' : ''}/>
                  <DynamicNumberElement id="balanceOwedOn" classColumn="flex-colR1" title="Balance owed on Trade-in" value={valueForBalanceOwedOnTradeIn}
                                        onChange={onChangeNumber} separator={"."} placeHolder={"0.00"}
                                        thousand={true} integer={8} fraction={2} maxLength={13} prefix={true}
                                        error={errors.balanceOwedOn} tradeIn={tradeIn} isRequired={true}
                                        readOnly={readOnly} classWrapperInput={readOnly ? 'input-disabled' : ''}/>
                </div>
                <div className="flex-row">
                  <InputValue placeholder="Bank name" error={errors.balanceOwedTo} onBlur={onBlur} name="balanceOwedTo"
                              onChange={onChange} title="Balance owed to (Finance Institution)" isRequired={balanceOwedToIsRequired}
                              value={ tradeIn.balanceOwedTo}
                              readOnly={readOnly} classWrapperInput={readOnly ? 'input-disabled' : ''}/>
                  <InputValue placeholder="Ex: 345 W 096 S City, State ZIP 345688" onBlur={onBlur} name="financeAddress"
                              onChange={onChange} title="Finance Institution address" isRequired={false}
                              value={ tradeIn.financeAddress}
                              readOnly={readOnly} classWrapperInput={readOnly ? 'input-disabled' : ''}/>
                </div>
                <div className="flex-row">
                  <InputValue placeholder="Type here" onBlur={onBlur} name="payoffVerified"
                              onChange={onChange} title="Payoff verified by" isRequired={false}
                              value={ tradeIn.payoffVerified}
                              readOnly={readOnly} classWrapperInput={readOnly ? 'input-disabled' : ''}/>
                  <InputDate classCol="flex-colR1" classItem="item" value={tradeIn.dateOfVerification} contentClass="row-flex-date"
                             onChange={onChangeDate}  id="dateOfVerification" title="Date of verification" readOnly={readOnly}/>
                  <InputDate classCol="flex-colR1" classItem="item" value={tradeIn.goodUntil} contentClass="row-flex-date"
                             onChange={onChangeDate}  id="goodUntil" title="Good until" readOnly={readOnly}/>
                  <InputValue placeholder="Ex. 0000 000 000" onBlur={onBlur} name="acc"
                              onChange={onChange} title="ACC. #" isRequired={false}
                              value={tradeIn.acc}
                              readOnly={readOnly} classWrapperInput={readOnly ? 'input-disabled' : ''}/>
                </div>
              </div>
              <div className="flex-row-btn">
                <div className="content-button">
                  {
                    !readOnly ?
                      <button className="btn-green" onClick={addTradeIn} style={{width: isEdit ? "124px" : "114px"}} data-test={'save-trade-in-btn'}>{ isEdit ? `Update Trade-in` :`Add Trade-in` }</button> :
                      <button className={!existCar ? "btn-blue" : "btn-green"} style={{width: "124px"}} onClick={!existCar ? goToInventory : addToInventory}>{ !existCar ? `See on inventory` :`Add to inventory` }</button>
                  }
                </div>
              </div>
              {
                backEndError ?
                  <div className="container-error">
                    <span className="error">{backEndError}</span>
                  </div>
                  : null
              }
            </div>
        }

        {
          isPopoverActive &&  (popoverSelected !== ATTRIBUTE_CODE_MAKE_ID && popoverSelected !== ATTRIBUTE_CODE_MODEL_ID) ?
            <Popover anchorEl={anchorEl}
                     child={getOptionsBody(popoverSelected,tradeIn,onClickPopover,makes,models,attributeValuesTitle,attributeValuesBody,attributeValuesColor, attributeValuesCondition)}
                     classPopover="popover-box-message popover-box-sale-tradeIn"
                     handleRequestClose={closePopover}/> : null
        }
        {
          isPopoverActive  && (popoverSelected === ATTRIBUTE_CODE_MAKE_ID || popoverSelected === ATTRIBUTE_CODE_MODEL_ID) ?
            <PopoverSearchable id={popoverSelected} anchorEl={anchorEl} options={optionsSearch} classPopover="popover-search"
                               onChangeDropDown={onChangeDropDown} onChange={onChangeSearch} foundValue={foundValue} isTradeIn={true}/>
            : null
        }
      </Modal.Body>
    </Modal>
  )
};

const BodyOptions = ({options = [], value, onClickPopover,name,makes,tradeIn}) => {
  if(name === ATTRIBUTE_CODE_MODEL_ID && makes && tradeIn){
    if(tradeIn.makeId)
      options = options.filter(model => { return model.makeId === tradeIn.makeId });
    else
      options = []
  }
  if(isEmpty(options)) return null
  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {
            options.map((option, index) => {
              const className = (option.id === value) ? "item-selected" : "change-submenu-item";
              const optionValue = option.value ? option.value : option.displayValue ? option.displayValue : "";
              return (
                <li key={index} className={className} onClick={() => onClickPopover(option.id,name)}>
                  {optionValue}
                </li>
              )
            })
          }
        </u>
      </div>
    </div>
  )
};

function getOptionsBody(popoverSelected,tradeIn,onClickPopover,makes,models,attributeValuesTitle,attributeValuesBody,attributeValuesColor, attributeValuesCondition){
  let options = [];
  let makeValues;
  let tradeInValue;
  switch (popoverSelected) {
    case ATTRIBUTE_CODE_CAR_TITLE :
      options = attributeValuesTitle;
      break;
    case ATTRIBUTE_CODE_CAR_BODY :
      options = attributeValuesBody;
      break;
    case ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR :
      options = attributeValuesColor;
      break;
    case "year" :
      options = optionYears;
      break;
    case "makeId" :
      options = makes;
      break;
    case "modelId" :
      options = models;
      break;
    case ATTRIBUTE_CODE_CAR_CONDITION:
      options = attributeValuesCondition
      break
  }
  if(popoverSelected === "vin"){
    makeValues = makes;
    tradeInValue = tradeIn;
  }else{
    makeValues = null;
    tradeInValue = null;
  }

  // Implemented because now car.title is into 'values' property
  let selectedValue = tradeIn[popoverSelected]
  const fieldOnValuesArray = [ATTRIBUTE_CODE_CAR_TITLE, ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR, ATTRIBUTE_CODE_CAR_BODY, ATTRIBUTE_CODE_CAR_CONDITION]

  if (fieldOnValuesArray.includes(popoverSelected)) {
    selectedValue = getAttValueIdBasedOnCode(popoverSelected, tradeIn)
  }

  return <BodyOptions options={options} value={selectedValue} onClickPopover={onClickPopover} name={popoverSelected} makes={makeValues} tradeIn={tradeInValue}/>;
}

function getTradeInInitial() {
  return { vin: "", mileage: "", year: "", makeId: "", modelId: "", tradeInAllowance: "",
    balanceOwedOn: "", balanceOwedTo: "", financeAddress: "", payoffVerified: "", dateOfVerification: "", goodUntil: "", acc:"",
    values: [
      {
        attributeCode: ATTRIBUTE_CODE_CAR_TRIM,
        attributeId: '',
        attributeValueId: '',
        attributeValue: ''
      },
      {
        attributeCode: ATTRIBUTE_CODE_CAR_CONDITION,
        attributeId: '',
        attributeValueId: '',
        attributeValue: ''
      },
      {
        attributeCode: ATTRIBUTE_CODE_CAR_BODY,
        attributeId: '',
        attributeValueId: '',
        attributeValue: ''
      },
      {
        attributeCode: ATTRIBUTE_CODE_CAR_TITLE,
        attributeId: '',
        attributeValueId: '',
        attributeValue: ''
      },
      {
        attributeCode: ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR,
        attributeId: '',
        attributeValueId: '',
        attributeValue: ''
      }
    ]
  }
}

const fieldsRequiredTradeIn = [{key: "vin", type: "required"}, {key: "mileage", type: "required"}, {key: ATTRIBUTE_CODE_CAR_TITLE, type: "required"},
  {key: ATTRIBUTE_CODE_CAR_BODY, type: "required"}, {key: ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR, type: "required"}, {key: "year", type: "required"}, {key: "makeId", type: "required"},
  {key: "modelId", type: "required"}, {key: "tradeInAllowance", type: "required"}, {key: "balanceOwedOn", type: "required"},
  {key: "balanceOwedTo", type: "required"}, {key: ATTRIBUTE_CODE_CAR_CONDITION, type: 'required'}
];

const fieldsNotRequired = ["financeAddress","payoffVerified","dateOfVerification","goodUntil","acc"];

function findErrorVin(vin){
  return isEmpty(vin) || (vin.length > 17 || vin.length < 11)
}

function deleteEmptyFields(object) {
  fieldsNotRequired.forEach( field =>{
    if(isEmpty(object[field]))
      delete object[field];
  });
  return object
}

function existsElement(value){
  return typeof value !== "undefined"
}

function getAttributeValuesByCode(responseAttributeValues,code) {
  const responseAttributeValue = responseAttributeValues.find(attributeValue => { return attributeValue.code === code});
  return responseAttributeValue && responseAttributeValue.attributeValues.map(x => { return {id: x.id, value: x.displayValue} })
}

function getFirstElementAttributeValuesByCode(responseAttributeValues, code) {
  const responseAttributeValue = responseAttributeValues.find(attributeValue => { return attributeValue.code === code});
  return responseAttributeValue && (responseAttributeValue.attributeValues.length > 0 ? responseAttributeValue.attributeValues[0] : [])
}

const IconAdd = ({showModal}) => {
  return (
    <div className="right-element">
      <div className="container-icon">
        <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_add.svg`}
                  beforeInjection={svg => svg.classList.add('icon-add-svg')}
                  className="icon-add-trade_in"
                  onClick={showModal}
                  data-test='add-trade-in-btn'/>
      </div>
    </div>
  )
};

export default class SaleTradeIn extends Component{
  constructor() {
    super();

    this.state = {
      tradeIns : [],
      isModalTradeInActive : false,
      errors: {},
      tempTradeIn: getTradeInInitial(),
      popoverSelected: null,
      viewValues: {},
      width: window.innerWidth,
      isPopoverActive : null,
      isLoading: false,
      attributeValuesCondition: {},
      carToInsert: [],
      blankTempTradeIn: null, // Used to blank all fields on TradeIn modal when VIN can't be found
      vinNotFoundErrorMessage: false, // Just to show error message on Modal TradeIn
      foundVin: false,
      messageError: '',
      isLoadingVin: false,
      isLoadingDelete: false
    }
  }

  componentWillMount() {
    const {tradeIns} = this.props;
    const codes = `${ATTRIBUTE_CODE_CAR_TITLE},${ATTRIBUTE_CODE_CAR_BODY},${ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR},${ATTRIBUTE_CODE_CAR_CONDITION},${ATTRIBUTE_CODE_CAR_TRIM}`;
    loadAttributeValuesByAttributeCodes(codes).then(response => {
      const {responseCode, data} = response
      if (responseCode !== SUCCESSFUL_CODE) {
        return
      }
      const {responseAttributeValues} = data;
      const attributeValuesTitle = getAttributeValuesByCode(responseAttributeValues,ATTRIBUTE_CODE_CAR_TITLE);
      const attributeValuesBody = getAttributeValuesByCode(responseAttributeValues,ATTRIBUTE_CODE_CAR_BODY);
      const attributeValuesColor = getAttributeValuesByCode(responseAttributeValues,ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR);
      const attributeValuesCondition = getAttributeValuesByCode(responseAttributeValues,ATTRIBUTE_CODE_CAR_CONDITION);

      const attributeIdForTitle = getFirstElementAttributeValuesByCode(responseAttributeValues, ATTRIBUTE_CODE_CAR_TITLE)['attributeId']
      const attributeIdForBody = getFirstElementAttributeValuesByCode(responseAttributeValues, ATTRIBUTE_CODE_CAR_BODY)['attributeId']
      const attributeIdForExteriorColor = getFirstElementAttributeValuesByCode(responseAttributeValues, ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR)['attributeId']
      const attributeIdForCondition = getFirstElementAttributeValuesByCode(responseAttributeValues, ATTRIBUTE_CODE_CAR_CONDITION)?.['attributeId']
      const attributeIdForTrim = getFirstElementAttributeValuesByCode(responseAttributeValues, ATTRIBUTE_CODE_CAR_TRIM)['attributeId']
      const attributeIdValueIdForTrim = getFirstElementAttributeValuesByCode(responseAttributeValues, ATTRIBUTE_CODE_CAR_TRIM)['id']

      let newTempTradeIn = getTradeInInitial()
      newTempTradeIn = {
        ...newTempTradeIn,
        values: [
          {
            attributeCode: ATTRIBUTE_CODE_CAR_TRIM,
            attributeId: attributeIdForTrim,
            attributeValueId: attributeIdValueIdForTrim,
            attributeValue: ''
          },
          {
            attributeCode: ATTRIBUTE_CODE_CAR_CONDITION,
            attributeId: attributeIdForCondition,
            attributeValueId: '',
            attributeValue: ''
          },
          {
            attributeCode: ATTRIBUTE_CODE_CAR_BODY,
            attributeId: attributeIdForBody,
            attributeValueId: '',
            attributeValue: ''
          },
          {
            attributeCode: ATTRIBUTE_CODE_CAR_TITLE,
            attributeId: attributeIdForTitle,
            attributeValueId: '',
            attributeValue: ''
          },
          {
            attributeCode: ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR,
            attributeId: attributeIdForExteriorColor,
            attributeValueId: '',
            attributeValue: ''
          }
        ]
      }

      this.setState({tradeIns,attributeValuesTitle,attributeValuesBody,attributeValuesColor, attributeValuesCondition, tempTradeIn: newTempTradeIn, attributeIdForCondition,
        blankTempTradeIn: newTempTradeIn,
      });
    }).catch(error => {
      console.error(error);
      this.setState({tradeIns});
    });
  }

  loadSaleTradeIns(saleId, saleFilters) {
    loadSaleTradeIns(saleId).then( response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const tradeIns = data.saleTradeIns;
        this.setState({tradeIns,tempTradeIn: getTradeInInitial(),isModalTradeInActive: false, viewValues: {},isModalDeleteActive: false, isLoading:false},
          () => this.props.updateSaleInfo(saleId, saleFilters)
        );
      }else{
        console.error(responseMessage);
        this.setState({backEndError:responseMessage, isLoading:false});
        setTimeout(() => {
          this.setState({backEndError: null});
        }, 500);
      }
    })
  }

  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (e) =>{
    this.setState({width:window.innerWidth, isPopoverActive: false});
  }

  showModalEdit = (tradeIn,evt) =>{
    evt.stopPropagation();
    const { attributeIdForCondition }  = this.state
    let newTradeIn = {...tradeIn}
    let newTradeInValues = [...newTradeIn.values]
    const newTradeInValuesCondition = newTradeInValues.find(el => el.attributeCode === ATTRIBUTE_CODE_CAR_CONDITION)

    if (!newTradeInValuesCondition) {
      newTradeInValues.push({
        attributeCode: ATTRIBUTE_CODE_CAR_CONDITION,
        attributeId: attributeIdForCondition,
        attributeValueId: '',
        attributeValue: ''
      })

      newTradeIn = {
        ...newTradeIn,
        values: newTradeInValues,
      }
    }

    this.setState({tempTradeIn:newTradeIn,isModalTradeInActive:true, isEdit:true }, () => {
      this.props.hideBar();
    })
  }

  showModalDelete = (tradeIn,evt) => {
    this.setState({isModalDeleteActive:true,tempTradeIn:tradeIn})
  }

  deleteTradeIn = () => {
    const {tempTradeIn} = this.state;
    const {saleId, saleFilters} = this.props;
    this.setState({isLoadingDelete: true});
    deleteSaleTradeIn(saleId,tempTradeIn.id).then( response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.loadSaleTradeIns(saleId, saleFilters);
      }else{
        console.error(responseMessage);
        this.setState({backEndError:responseMessage,isModalDeleteActive: false, isLoadingDelete: false});
        setTimeout(() => {
          this.setState({backEndError: null});
        }, 5000);
      }
    })
  }

  closeModal = () =>{
    this.setState({isModalTradeInActive: false, popoverSelected: null, tempTradeIn: getTradeInInitial(),
      isPopoverActive: false, errors: {}, viewValues : {}, foundVin: false});
  }

  closeModalDelete = () => {
    this.setState({isModalDeleteActive:false})
  }

  showPopover = (id, event) => {
    event.stopPropagation();
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target, 2);
    this.setState({anchorEl: anchorEl, isPopoverActive: true, popoverSelected: id}, () => {
      if(id === ATTRIBUTE_CODE_MAKE_ID || id === ATTRIBUTE_CODE_MODEL_ID){
        const element =  document.getElementById(id);
        if(element)
          element.style.display = "none";
      }
    })
  }

  closePopover() {
    this.setState({isPopoverActive: false, popoverSelected: null, foundVin: false});
  }

  showModal = () => {
    this.setState({isModalTradeInActive: true, isEdit: false}, () => {
      this.props.hideBar();
    })
  }

  onChangeValue = (key, event) => {
    let {errors} = this.state;
    let tempTradeIn = {...this.state.tempTradeIn};

    // This is because 'car.trim' is into tradeIn 'values' property
    if (key === ATTRIBUTE_CODE_CAR_TRIM) {
      let tradeInValues = [...tempTradeIn.values]
      let tradeInValueToUpdate = tradeInValues.find(el => el.attributeCode === key)
      const tradeInValueIndex = tradeInValues.findIndex(el => el.attributeCode === key)
      tradeInValueToUpdate['attributeValue'] = event.target.value
      tradeInValues.splice(tradeInValueIndex, 1, tradeInValueToUpdate)

      tempTradeIn = {
        ...tempTradeIn,
        values: tradeInValues,
      }
    } else {
      tempTradeIn[key] = event.target.value;
    }

    errors[key] = false;
    this.setState({ tempTradeIn, errors: errors});
  }

  onClickPopover = (id,name) =>{
    let {errors} = this.state;
    let tempTradeIn = {...this.state.tempTradeIn};

    // This is because some keys are into tradeIn 'values' property
    const fieldOnValuesArray = [ATTRIBUTE_CODE_CAR_TITLE, ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR, ATTRIBUTE_CODE_CAR_BODY, ATTRIBUTE_CODE_CAR_CONDITION]
    if (fieldOnValuesArray.includes(name)) {
      let tradeInValues = [...tempTradeIn.values]
      let tradeInValueToUpdate = tradeInValues.find(el => el.attributeCode === name)
      const tradeInValueIndex = tradeInValues.findIndex(el => el.attributeCode === name)
      tradeInValueToUpdate['attributeValueId'] = id
      tradeInValues.splice(tradeInValueIndex, 1, tradeInValueToUpdate)

      tempTradeIn = {
        ...tempTradeIn,
        values: tradeInValues,
      }
    } else {
      tempTradeIn[name] = id;
    }

    errors[name] = false;
    this.setState({tempTradeIn, isPopoverActive: false, errors})
  }

  onChangeNumber = (sale,evt,modelValue,viewValue) => {
    let {viewValues,errors,tempTradeIn} = this.state;
    const tempTradeInNew = {...this.state.tempTradeIn};
    let key = evt.target.id;
    errors[key] = false;
    if(isEmpty(viewValue))
      tempTradeInNew[key] = viewValue;
    else
      tempTradeInNew[key] = modelValue;
    viewValues[key] = viewValue;
    this.setState({tempTradeIn: tempTradeInNew,viewValues});
  }

  onChangeDate = (key, date) => {
    const tempTradeIn = {...this.state.tempTradeIn};
    tempTradeIn[key] = Moment(date).format("YYYY-MM-DD");
    this.setState({tempTradeIn})
  }

  addTradeIn = () => {
    const {errors, isEdit, foundVin} = this.state;
    const {saleId, saleFilters} = this.props;
    const error = this.getError(errors);
    const hasError = validateError(error) || foundVin;
    if(!hasError){
      const {tempTradeIn} = this.state;
      const tradeInToInsert = deleteEmptyFields(tempTradeIn);
      this.setState({isLoadingVin: true});
      if(isEdit){
        updateSaleTradeIn(saleId,tempTradeIn.id,tempTradeIn).then( response => {
          const {responseCode, responseMessage, data} = response
          if (responseCode === SUCCESSFUL_CODE){
            this.loadSaleTradeIns(saleId, saleFilters);
          }else{
            this.setState({backEndError:responseMessage, isLoadingVin:false});
            setTimeout(() => {
              this.setState({backEndError: null});
            }, 5000);
            console.error(responseMessage)
          }
        })
      }else{
        saveSaleTradeIn(saleId,tradeInToInsert).then( response => {
          const {responseCode, responseMessage} = response
          if (responseCode === SUCCESSFUL_CODE){
            this.loadSaleTradeIns(saleId, saleFilters);
          }else{
            this.setState({backEndError:responseMessage, isLoadingVin: false});
            setTimeout(() => {
              this.setState({backEndError: null});
            }, 5000);
            console.error(responseMessage)
          }
        })
      }
    }else{
      this.setState({errors: error});
    }
  }

  getError = (errors) => {
    fieldsRequiredTradeIn.forEach( field => {
      const {key} = field;
      errors[key] = this.validFieldsRequired(key)
    });
    return errors
  }

  validFieldsRequired = (key) =>{
    const {tempTradeIn} = this.state;
    let value;

    // This is because some keys are into tradeIn 'values' property
    const fieldOnValuesArray = [ATTRIBUTE_CODE_CAR_TITLE, ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR, ATTRIBUTE_CODE_CAR_BODY, ATTRIBUTE_CODE_CAR_CONDITION, ATTRIBUTE_CODE_CAR_TRIM]
    if (fieldOnValuesArray.includes(key)) {
      let tradeInValues = [...tempTradeIn.values]
      let tradeInValue = tradeInValues.find(el => el.attributeCode === key)

      if (key === ATTRIBUTE_CODE_CAR_TRIM) {
        value = tradeInValue['attributeValue']
      } else {
        value = tradeInValue['attributeValueId']
      }
    } else {
      value = tempTradeIn[key];
    }

    switch(key){
      case "mileage":
      case "tradeInAllowance":
      case "balanceOwedOn" :
      case "year":
      case ATTRIBUTE_CODE_CAR_TITLE:
      case ATTRIBUTE_CODE_CAR_BODY:
      case ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR:
      case "makeId":
      case "modelId":
      case ATTRIBUTE_CODE_CAR_CONDITION:
      case ATTRIBUTE_CODE_CAR_TRIM:
        return value === "";
      case "balanceOwedTo":
        if (!!parseFloat(tempTradeIn['balanceOwedOn'])) {
          return value === "";
        }
        break
      case "vin":
        return findErrorVin(value)
    }
  }

  onBlur = (key, isRequired, e) => {
    const vin = this.state.tempTradeIn['vin']
    let errors = {...this.state.errors};
    if (isRequired) {
      errors[key] = this.validFieldsRequired(key)
    }

    if (key === 'vin' && !findErrorVin(vin) && !errors[key]) {
      this.loadCarSpecs(vin)
    }

    if (errors[key]) {
      if (key === 'vin') {
        this.setState(prevState => {
          let newTempTradeIn = {...prevState.blankTempTradeIn}
          newTempTradeIn['vin'] = vin
          return ({
            tempTradeIn: newTempTradeIn,
            errors,
          })
        })
      } else {
        this.setState({errors})
      }
    }
  }

  onChangeDropDown = (e,id) => {
    let {isPopoverActive, popoverSelected} = this.state;
    const element =  document.getElementById(popoverSelected);
    if(element)
      element.style.display = "none";
    popoverSelected = id;
    isPopoverActive = !isPopoverActive;
    this.setState({isPopoverActive,anchorEl: e.currentTarget,popoverSelected}, () => {
      if(id === ATTRIBUTE_CODE_MAKE_ID || id === ATTRIBUTE_CODE_MODEL_ID){
        const element =  document.getElementById(id);
        if(element)
          element.style.display = "flex";
      }
    });
  }

  onChange = (id,index, optionId) => {
    const errors = {...this.state.errors};
    const tempTradeIn = {...this.state.tempTradeIn};
    if(id === ATTRIBUTE_CODE_MAKE_ID) {
      tempTradeIn[id] = optionId;
      tempTradeIn.modelId = "";
      errors.makeId = false;
    } else if(id === ATTRIBUTE_CODE_MODEL_ID){
      tempTradeIn[id] = optionId;
      errors.modelId = false
    }
    this.setState({tempTradeIn, isPopoverActive:false,errors}, () => {
      if(id === ATTRIBUTE_CODE_MAKE_ID || id === ATTRIBUTE_CODE_MODEL_ID){
        const element =  document.getElementById(id);
        if(element)
          element.style.display = "flex";
      }
    })
  }

  goToInventory = () => {
    const {tempTradeIn} = this.state;
    getVinByCarId(tempTradeIn.carId).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        window.open(`/main/inventory/${data.vin}`, '_blank');
      } else {
        console.error(responseMessage)
      }
    })
  }

  addToInventory = () => {
    const {saleId, saleFilters} = this.props;
    const {tempTradeIn, carToInsert} = this.state;
    const newCar = {};
    const data = {};
    newCar.saleTradeInId = tempTradeIn.id;
    newCar.makeId = tempTradeIn.makeId;
    newCar.modelId = tempTradeIn.modelId;
    newCar.vin = tempTradeIn.vin;
    newCar.year = tempTradeIn.year;
    newCar.mileage = tempTradeIn.mileage;
    const trimValues = tempTradeIn.values.find(x => x.attributeCode === ATTRIBUTE_CODE_CAR_TRIM);
    const titleValues = tempTradeIn.values.find(x => x.attributeCode === ATTRIBUTE_CODE_CAR_TITLE);
    const bodyValues = tempTradeIn.values.find(x => x.attributeCode === ATTRIBUTE_CODE_CAR_BODY);
    const exteriorColorValues = tempTradeIn.values.find(x => x.attributeCode === ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR);
    const conditionValues = tempTradeIn.values.find(x => x.attributeCode === ATTRIBUTE_CODE_CAR_CONDITION);
    const titleValue = []
    titleValue.push(titleValues.attributeValueId)
    const bodyValue = []
    bodyValue.push(bodyValues.attributeValueId)
    const exteriorColorValue = []
    exteriorColorValue.push(exteriorColorValues.attributeValueId)
    const conditionValue = []
    if(conditionValues)
      conditionValue.push(conditionValues.attributeValueId)
    else
      conditionValue.push("0")

    newCar.carValues = {
      [trimValues.attributeId]: trimValues.attributeValue,
      [titleValues.attributeId]: titleValue,
      [bodyValues.attributeId]: bodyValue,
      [exteriorColorValues.attributeId]: exteriorColorValue,
      [conditionValues ? conditionValues.attributeId : "0"]: conditionValue
    }
    carToInsert.push(newCar)
    data.car = carToInsert
    this.setState({isLoading: true});
    addCarTradeIn(saleId, data).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.loadSaleTradeIns(saleId, saleFilters);
      }else{
        console.error(responseMessage);
        this.setState({isLoading: false});
      }
    })
  }

  loadCarSpecs(vinNumber) {
    const { attributeValuesBody, tempTradeIn } = this.state
    const { makes, models } =  this.props;
    let tempTradeInToUpdate = {...tempTradeIn}
    let tempTradeInValuesToUpdate = cloneDeep(tempTradeIn['values'])

    this.setState({ isLoadingVin: true, foundVin: false, messageError:''})

    verifyVin(vinNumber)
      .then(({responseCode, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          getDataFromVinNumber(vinNumber)
            .then((response) => {
              const {responseCode, responseMessage, data} = response
              if (responseCode === SUCCESSFUL_CODE) {
                const responseCar = data['Results'][0];
                // '0' means successful search - '6' means successful search with incomplete VIN
                if (['0', '6'].includes(responseCar[formCarApi.ERROR_CODE])) {
                  const makeFound = makes.find(make => make.name.toUpperCase() === responseCar[formCarApi.MAKE].toUpperCase());
                  const modelFound = models.find(model => model.name.toUpperCase() === responseCar[formCarApi.MODEL].toUpperCase());

                  // Updates Make, Model and Year info
                  tempTradeInToUpdate[ATTRIBUTE_CODE_MAKE_ID] = makeFound && makeFound['id']
                  tempTradeInToUpdate[ATTRIBUTE_CODE_MODEL_ID] = modelFound['id']
                  tempTradeInToUpdate[ATTRIBUTE_CODE_CAR_YEAR] = parseInt(responseCar[formCarApi.YEAR])

                  // Updates Body info
                  const bodyFound = attributeValuesBody.find(el => el.value === responseCar[formCarApi.BODY_STYLE])
                  const bodyFoundIndex = bodyFound && tempTradeInValuesToUpdate.findIndex(el => el.attributeCode === ATTRIBUTE_CODE_CAR_BODY)

                  if (bodyFoundIndex > -1) {
                    tempTradeInValuesToUpdate[bodyFoundIndex] = {
                      ...tempTradeInValuesToUpdate[bodyFoundIndex],
                      attributeValueId: bodyFound['id'],
                      attributeValue: bodyFound['value'],
                    }
                  }
                  // End of section

                  // Updated Trim info
                  const trimFound = tempTradeInValuesToUpdate.find(el => el.attributeCode === ATTRIBUTE_CODE_CAR_TRIM)
                  const trimFoundIndex = trimFound && tempTradeInValuesToUpdate.findIndex(el => el.attributeCode === ATTRIBUTE_CODE_CAR_TRIM)

                  if (trimFoundIndex > -1) {
                    tempTradeInValuesToUpdate[trimFoundIndex] = {
                      ...tempTradeInValuesToUpdate[trimFoundIndex],
                      attributeValue: responseCar[formCarApi.TRIM],
                    }
                  }
                  // End of section

                  tempTradeInToUpdate = {
                    ...tempTradeInToUpdate,
                    ['values']: [
                      ...tempTradeInValuesToUpdate,
                    ]
                  }

                  this.setState({
                    tempTradeIn: tempTradeInToUpdate,
                    isLoadingVin: false,
                  })
                } else {
                  this.setState(prevState => {
                    let newTempTradeIn = {...prevState.blankTempTradeIn}
                    newTempTradeIn['vin'] = vinNumber
                    return ({
                      tempTradeIn: newTempTradeIn,
                      vinNotFoundErrorMessage: true,
                      isLoadingVin: false,
                    })
                  })

                  setTimeout(
                      () => this.setState({vinNotFoundErrorMessage: false}),
                      TIME_FIVE_SECONDS
                  );
                }
              } else {
                console.log('error', responseMessage)
                this.setState({ isLoadingVin: false })
              }
            })
        } else {
          console.log('error', responseMessage)
          this.setState({isLoadingVin: false,foundVin: true, messageError: responseMessage})
        }
      })
  }

  // This method is only used on VIN field (Modal TradeIn)
  handleKeyPress(event) {
    const vin = event.target.value

    if (!findErrorVin(vin) && event.key === "Enter") {
      this.loadCarSpecs(vin)
    }

    if (findErrorVin(vin) && event.key === "Enter") {
      this.setState(prevState => {
        let newTempTradeIn = {...prevState.blankTempTradeIn}
        newTempTradeIn['vin'] = vin
        let errors = {...prevState.errors};
        errors['vin'] = true
        return ({
          tempTradeIn: newTempTradeIn,
          errors,
        })
      })
    }
  }

  render(){
    const { makes, models, readOnly} =  this.props;
    const {isModalTradeInActive, isPopoverActive, tempTradeIn,tradeIns,popoverSelected,viewValues,errors,anchorEl,
      isEdit,isModalDeleteActive,backEndError, isLoading, attributeValuesTitle,attributeValuesBody,attributeValuesColor,
      attributeValuesCondition, foundVin, messageError, isLoadingVin, isLoadingDelete} = this.state;
    let total = 0.00;
    return (
      <div className="panel-sale-trade-ins">
        {isLoading ? <DPSpinner classContainer={"loader-container"}/> : null}
        <TitleHeader classTitle="title-trade-ins" classIcon="icon-trade-ins" classIconSvg="icon-trade-ins-svg"
                     icon="icon/ic_trade_in_symbol.svg" selectOption={false} title="Trade-ins"
                     rightElement={!readOnly ? <IconAdd showModal={this.showModal}/> : null}
        />
        {
          tradeIns && tradeIns.length > 0 ?
            <div className="trade-ins-body">
              {
                tradeIns.map((tradeIn, index) => {
                  const {make,model,saleTradeIn} = tradeIn;
                  const {year,tradeInAllowance,balanceOwedOn, carId} = saleTradeIn;
                  total = total + (tradeInAllowance - balanceOwedOn);
                  const className = (index % 2 === 0) ? "tool-body-option" : "tool-body-option-color";
                  const classNameCheck = (isEmpty(carId)) ? "img-check" : "img-check-added";
                  return(
                    <div key={`row-${index}`} className={className}
                         onClick={(event) => readOnly ? this.showModalEdit(saleTradeIn,event) : null}
                         style={readOnly ? {cursor: 'pointer'} : null}>
                      <span className="car-title">{`${make} ${model} ${year}`}</span>
                      {
                        !readOnly ?
                            <div className="trade-actions">
                              <img key={ index + 'edit'} id="editPencil" src={`${pathServer.PATH_IMG}img_pencil.png`}
                                   className={"img-edit-pencil"} onClick={(event) => this.showModalEdit(saleTradeIn,event)}/>
                              <img key={index + 'trash'} id="imgTrash" src={`${pathServer.PATH_IMG}deleteCustomer.png`}
                                   className="img-delete" onClick={(event) => this.showModalDelete(saleTradeIn,event)}/>
                            </div> :
                            <div className="trade-actions">
                              <ReactSVG id={'viewTradeIn'} src={`${pathServer.PATH_IMG}icon/ic_check_normal.svg`}
                                        beforeInjection={ svg => svg.classList.add(classNameCheck)}/>
                            </div>
                      }

                      <span className="trade-in">{`$${(tradeInAllowance-balanceOwedOn).toFixed(2)}`}</span>
                    </div>
                  )
                })
              }
              <div className="trade-in-total">
                <span className="total">{`$${total.toFixed(2)}`}</span>
                <span className="label-total">Trade-in total</span>
              </div>
            </div>
            :
            <div className="not-trade-ins">
              <div className="container-trade-in">
                <div className="content-image-trade-ins">
                  <img className="trade-in-image" src={`${pathServer.PATH_IMG}trade_in_cars.png`}/>
                </div>
                <div className="content-label">
                  <span className="not-trade-in">Not trade-in added yet</span>
                </div>
              </div>
            </div>
        }
        {
          isModalTradeInActive && tempTradeIn ?
            <ModalTradeIn show={isModalTradeInActive} onHide={this.closeModal} isPopoverActive={isPopoverActive} tradeIn={tempTradeIn} onChangeDropDown={this.onChangeDropDown}
                          showPopover={this.showPopover} onClickPopover={this.onClickPopover} anchorEl={anchorEl} onBlur={this.onBlur} errors={errors}
                          closePopover={this.closePopover} onChange={this.onChangeValue} popoverSelected={popoverSelected} addTradeIn={this.addTradeIn}
                          makes={makes} models={models} onChangeNumber={this.onChangeNumber} viewValues={viewValues} onChangeDate={this.onChangeDate}
                          onChangeSearch={this.onChange} isEdit={isEdit} backEndError={backEndError} readOnly={readOnly}
                          attributeValuesTitle={attributeValuesTitle} attributeValuesBody={attributeValuesBody} attributeValuesColor={attributeValuesColor}
                          attributeValuesCondition={attributeValuesCondition} goToInventory={this.goToInventory} addToInventory={this.addToInventory}
                          handleKeyPress={this.handleKeyPress}
                          vinNotFoundErrorMessage={this.state.vinNotFoundErrorMessage}
                          foundVin={foundVin}
                          messageError={messageError} isLoading={isLoadingVin}
            /> :
            null
        }
        {
          isModalDeleteActive ?
            <ModalConfirmDelete show={isModalDeleteActive}
                                showButtonDelete={true}
                                onSubmit={this.deleteTradeIn}
                                onHide={this.closeModalDelete}
                                classButtonDelete="btn-delete"
                                buttonLabelDelete="Yes, Delete"
                                message={"Do you want to delete this trade-in permanently?"}
                                body = {<div className="container-message-delete-trade-in">
                                  <span className="title">Do you want to delete this trade-in permanently?</span>
                                  <span className="sub-title">This action is irreversible</span>
                                </div>}
                                styleModal="modal-delete"
                                styleRight="panel-right-delete-user"
                                isLoading={isLoadingDelete}/> : null
        }
      </div>
    )
  }
}