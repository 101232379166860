import React from 'react'
import MomentUtils from '@date-io/moment'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import PropTypes from 'prop-types'
import Moment from 'moment'

class DPKeyboardDatePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props } = this
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          id={props.id}
          name={props.name}
          autoOk={true}
          value={props.value}
          onChange={props.onChange}
          minDate={props.minDate}
          format={props.format}
          ref={props.ref} // Since this property we add for compatibility
          className={props.className}
          onBlur={props.blur}
          disabled={props.disabled}
          placeholder={props.placeholder}
          openTo={props.openTo}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

DPKeyboardDatePicker.defaultProps = {
  minDate: Moment("01-01-1900", "MM-DD-YYYY"),
  ref: undefined,
  className: '',
  onBlur: null,
  disabled: false,
  format: "MM-DD-YYYY",
  placeholder: 'MM-DD-YYYY',
  openTo: 'date',
  value: null
}

DPKeyboardDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.any,
  ref: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  openTo: PropTypes.string,
}

export default DPKeyboardDatePicker