import React, {useState, cloneElement} from 'react'
import {unstable_batchedUpdates} from 'react-dom'
import DPNavigationBarItem from '../../../common/themes/DPNavigationBarItem'
import {DPInput} from '../../../common/DPInput'
import {
  LINK_TO_PAGE,
  LINK_TO_SECTION,
  LINK_TO_URL,
} from '../../../Constants'
import {ReactSVG} from 'react-svg'
import DPActivatedItem from '../../../common/themes/DPActivatedItem'
import SettingPage from './SettingPage'
import SettingAboutPage from './SettingAboutPage'
import InventorySection from './Pages/Home/InventorySection'
import LinkContent from './Tabs/LinkContent'
import ExternalUrlContent from './Tabs/ExternalUrlContent'
import FinancingSection from './Pages/Home/FinancingSection'
import LocationSection from './Pages/Home/LocationSection'
import TestimoniesSection from './Pages/Home/TestimoniesSection'
import ContactSection from './Pages/Home/ContactSection'
import {DPToggle} from '../../../common/DPToggle'
import DPHomeBanner from './DPHomeBanner'
import {cloneDeep} from 'lodash'
import DPFooterItem2 from '../../../common/themes/DPFooterItem2'
import {getPosition, getScrollTop, scrollTo} from '../../../../common/Util'
import {getPositionById} from '../../util/Utils'
import {
  pathServer,
  PAGE_FINANCE_ID,
  PAGE_INVENTORY_ID,
  PAGE_HOME_ID,
  SECTION_LOCATION_ID,
  SECTION_CONTACT_US_ID,
  SECTION_FINANCE_ID,
  PAGE_ABOUT_US_ID,
  SECTION_TESTIMONIAL_ID,
  TAB_MENU_ABOUT_US,
  TAB_MENU_FINANCE,
  TAB_MENU_VEHICLES,
} from '../../../../common/Constants'

const MAX_SIZE_TITLE = 53

const pageHomeItems = [
  {id: 'banner', displayValue: 'Banner', isActive: true, hasSubTitle:true, subtitle:"Home", subtitle2:"Banner", component: DPHomeBanner, href: 'bannerSection', showToggle: false},
  {id: 'inventory', displayValue: 'Inventory', isActive: true, hasSubTitle:true, subtitle:"Home", subtitle2:"Inventory section", component: InventorySection, href: 'vehiclesSection', showToggle: false},
  {id: 'finance', displayValue: 'Financing', isActive: true, hasSubTitle:true, subtitle:"Home", subtitle2:"Financing section",component: FinancingSection, href: 'financeSection', showToggle: true},
  {id: 'location', displayValue: 'Locations', isActive: true, hasSubTitle:true, subtitle:"Home", subtitle2:"Locations section", component: LocationSection, href: 'locationSection', showToggle: true},
  {id: 'testimonial', displayValue: 'Testimonies', isActive: true, hasSubTitle:true, subtitle:"Home", subtitle2:"Testimonies section", component: TestimoniesSection, href: 'testimonialSection', showToggle: true},
  {id: 'contact', displayValue: 'Contact', isActive: true, hasSubTitle:true, subtitle:"Home", subtitle2:"Contact section",component: ContactSection, href: 'containerGetInTouch', showToggle: true},
]

const optionsLinkToPage = [
  {
    id: 'home',
    displayValue: 'Home'
  },
  {
    id: 'inventory',
    displayValue: 'Vehicles'
  },
  {
    id: 'finance',
    displayValue: 'Financing'
  },
  {
    id: 'aboutUs',
    displayValue: 'About us'
  }
]

const optionsLinkToSection = [
  {
    id: 'finance',
    displayValue: 'Get a finance'
  },
  {
    id: 'location',
    displayValue: 'Locations'
  },
  {
    id: 'contact',
    displayValue: 'Contact us'
  },
  {
    id: 'testimonial',
    displayValue: 'Testimonials'
  }
]

const pageFinanceItems = [
  {id: 'contact', displayValue: 'Contact', isActive: true},
  {id: 'testimonies', displayValue: 'Testimonies', isActive: false}
]

const pageVehicleItems = [
  {id: 'finance', displayValue: 'Financing', isActive: true},
  {id: 'contact', displayValue: 'Contact', isActive: false}
]


const ListNavigationBar = ({...props}) => {
  const {generalSetting, onChange} = props

  const onChangeTabNavigation = (index, type, value) => {
    const newGeneralSetting = {...generalSetting}
    newGeneralSetting.header.menu[index].link = { type: type, [type]:value}
    onChange(newGeneralSetting)
  }

  const setTypeLink = (tab, id, index)=> {
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.header.menu[index].link = { type: tab, [tab]: generalSettingNew.header.menu[index][tab]}
    onChange(generalSettingNew)
  }

  const addPropsToTab = (navItem, index) => {
    return tabs.map((tab) => {
      if(tab.key === LINK_TO_PAGE){
        let component = cloneElement(tab.component, {value: navItem?.link?.[LINK_TO_PAGE] || navItem[LINK_TO_PAGE], type: "linkToPage", index, onChangeTabNavigation})
        return {...tab, component}
      } else if(tab.key === LINK_TO_SECTION){
        let component = cloneElement(tab.component, {value: navItem?.link?.[LINK_TO_SECTION] || navItem[LINK_TO_SECTION], type: "linkToSection", index, onChangeTabNavigation})
        return {...tab, component}
      } else {
        let component = cloneElement(tab.component, {value: navItem?.link?.[LINK_TO_URL] || navItem[LINK_TO_URL], type: "linkToUrl", index, onChangeTabNavigation})
        return {...tab, component}
      }
    })
  }

  return (
    generalSetting.header.menu.map((navItem, index) => {
      const tabsWithProps = addPropsToTab(navItem, index)
      return(
        <DPNavigationBarItem key={`navigation-bar-item-${index}`}
                             id={`nav-${navItem.id}`}
                             index={index}
                             tabs={tabsWithProps}
                             tabSelected={navItem.link ? navItem.link.type : null}
                             setTypeLink={setTypeLink}
                             title={navItem.displayValue}
                             hidePage={navItem.hidePage}
        />
      )
    })
  )
}

const ListFooter = ({ generalSetting, onChange, isLoadingSpinnerOnMainPage}) => {
  const [showPopUp, setShowPopUp] = useState(false)
  const [anchorEl, setAnchorEl] = useState({})
  const [columnIdSelected, setColumnIdSelected] = useState('column1')
  const [indexIdSelected, seIndexIdSelected] = useState(0)

  const setShowSectionSubscribe =()=>{
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.footer.subscription.showSection = !generalSetting.footer.subscription.showSection;
    onChange(generalSettingNew);
  }

  const setSubscribeInput = (evt) => {
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.footer.subscription.lineText = evt.currentTarget.value
    onChange(generalSettingNew);
  }

  const showPopUpOver = (id, index) => {
    const anchorEl = getPositionById(`${id}-${index}`)
    anchorEl.y = anchorEl.y + 40
    anchorEl.x = 155
    unstable_batchedUpdates(() => {
      setColumnIdSelected(id)
      seIndexIdSelected(index)
      setAnchorEl(anchorEl)
      setShowPopUp(!showPopUp)
    })
  }

  const deleteItem= (id,index) =>{
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.footer[id].items.splice(index,1)
    onChange(generalSettingNew);
  }

  const addItem = (id) => {
    let generalSettingNew = cloneDeep(generalSetting)
    if(generalSettingNew.footer[id].items){
      generalSettingNew.footer[id].items.push({value:'', icon: "ic_text_fields.svg", link: {type : 'text', value: ''}})
    }
    onChange(generalSettingNew);
  }

  const onChangeInput = (id, key, evt) => {
    const { value } = evt.target
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.footer[id][key] = value
    onChange(generalSettingNew)
  }

  const onChangeInputItem = (id, index, evt) => {
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.footer[id].items[index].value = evt.target.value
    onChange(generalSettingNew)
  }

  const onChangeInputLinkItem = (id, index, evt) => {
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.footer[id].items[index].link.value = evt.target.value
    onChange(generalSettingNew)
  }

  const setTypeLink = (id, index, type, icon)=> {
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.footer[id].items[index].link = { value: "" , type: type }
    generalSettingNew.footer[id].items[index].icon = icon
    setShowPopUp(!showPopUp)
    onChange(generalSettingNew)
  }

  const {footer} = generalSetting
  const {links, information, column1, column2, subscription} = footer
  return (
    <div className="container-footer">
      <DPFooterItem2
        key={'column1'}
        id={`column1`}
        columnIdSelected={columnIdSelected}
        indexIdSelected={indexIdSelected}
        text={"First Column Title"}
        subText={'Column 1'}
        column={column1}
        addItem={addItem}
        onChangeInput={onChangeInput}
        showPopUpOver={showPopUpOver}
        showPopUp={showPopUp}
        anchorEl={anchorEl}
        onChangeInputItem={onChangeInputItem}
        onChangeInputLinkItem={onChangeInputLinkItem}
        setTypeLink={setTypeLink}
        deleteItem={deleteItem}
        isLoadingSpinnerOnMainPage={isLoadingSpinnerOnMainPage}
      />
      <DPFooterItem2
        key={'column2'}
        id={`column2`}
        columnIdSelected={columnIdSelected}
        indexIdSelected={indexIdSelected}
        text={"Second Column Title"}
        subText={'Column 2'}
        column={column2}
        addItem={addItem}
        onChangeInput={onChangeInput}
        showPopUpOver={showPopUpOver}
        showPopUp={showPopUp}
        anchorEl={anchorEl}
        onChangeInputItem={onChangeInputItem}
        onChangeInputLinkItem={onChangeInputLinkItem}
        setTypeLink={setTypeLink}
        deleteItem={deleteItem}
        isLoadingSpinnerOnMainPage={isLoadingSpinnerOnMainPage}
      />
      <div className="container-subscribe">
        <div className="row-toggle">
          <div className="label-toggle">Subscribe</div>
          <DPToggle isChecked={generalSetting.footer.subscription.showSection} handleCheck={setShowSectionSubscribe} id={'idToggleSubscribe'}/>
        </div>
        <DPInput
          id='subscribe'
          title='Subscribe description'
          rightElement={<label className={`label-size right ${subscription.lineText.length === MAX_SIZE_TITLE ? 'error-color' : ''}`}>{`${subscription.lineText.length}/${MAX_SIZE_TITLE}`}</label>}
          value={subscription.lineText}
          onChange={setSubscribeInput}
          maxLength={MAX_SIZE_TITLE}
          disable={isLoadingSpinnerOnMainPage}
        />
      </div>
    </div>
  )
}

const DPSection = ({title, component, href, handleClick, show}) => {

  const [showBody, setShowBody] = useState(show)

  const handleClickSection = () => {
    if(!showBody && handleClick) {
      handleClick()
    }
    setShowBody(!showBody)
  }
  return (
    <>
      <a href={`#${href ? href : ''}`}>
        <div className="title-section" onClick={() => handleClickSection()}>
          <span>{title}</span>
          <ReactSVG src={pathServer.PATH_IMG + `icon/${showBody ? 'ic_expand_less' : 'ic_expand_more'}.svg`} className="icon-arrow-content"
                    beforeInjection={ svg => svg.classList.add('icon-arrow')}/>
        </div>
      </a>
      {
        showBody &&
        <div className="body-section">
          {component}
        </div>
      }

    </>
  )
}

const DPPageHeader = ({page, onClick, onClickSubtitle, showAddIcon = false}) => {
  return (
    <div className="title-section" style={page.hasSubTitle? {display: "flex"} : {}}>
      <span className={`${page.hasSubTitle ? 'opacity-title': ''}`} onClick={()=>onClick(null)}>Pages</span>
      {page.hasSubTitle && <ReactSVG src={pathServer.PATH_IMG + `icon/ic_expand_less.svg`} className="icon-arrow-content-right" style={{transform: 'rotate(90deg)'}}
                                beforeInjection={ svg => svg.classList.add('icon-arrow-right')}/>}
      {page.hasSubTitle && <span className={`${page.subtitle2 ? 'opacity-title': 'title'}`} onClick={()=>onClickSubtitle(page.id)}>{page.subtitle}</span>}
      {page.subtitle2 && <ReactSVG src={pathServer.PATH_IMG + `icon/ic_expand_less.svg`} className="icon-arrow-content-right" style={{transform: 'rotate(90deg)'}}
                                 beforeInjection={ svg => svg.classList.add('icon-arrow-right')}/>}
      {page.subtitle2 && <span className={'title'}>{page.subtitle2}</span>}
      { !page.hasSubTitle && showAddIcon && <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_add.svg`} beforeInjection={ svg => svg.classList.add('icon-add')}
                                  className="icon-add-content"/> }
    </div>
  )

}

const DPPages = ({component, page, onClick, onClickSubtitle, ...props}) => {
  return (
    <>
      <DPPageHeader page={page || {}}
                    onClick={onClick}
                    onClickSubtitle={onClickSubtitle}/>
      <div className="body-section">
        {cloneElement(component, {...props})}
      </div>
    </>
  )
}

const PageHomeContent = ({pagesItems, onClick, pages, onChangePages, ...props}) => {
  const {generalSetting, onChange} = props
  let generalSettingNew = cloneDeep(generalSetting)
  let pagesNew = cloneDeep(pages)
  let menuNav = generalSettingNew.header.menu
  let homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
  let inventoryPage = pagesNew.find(x => x.id === PAGE_INVENTORY_ID)
  let financePage = pagesNew.find(x => x.id === PAGE_FINANCE_ID)
  let sectionsHomePage = homePage.sections
  inventoryPage.sections = inventoryPage?.sections ?? [{
    id: "finance",
    displayValue: "Financing",
    title: "Get a finance",
    showSection: true,
  }]

  const onBackContent = (id) => {
    const page = pagesItems.find(p => p.id === id)
    if(page){
      onClick(<DPPages component={<PageHomeContent pagesItems={pagesItems} onClick={onClick}/>}
                       page={{...page, subtitle2: null}}
                       onClick={onClick}
                       onClickSubtitle={onBackContent}
                       {...props}
               />)
    }
  }

  const onClickItem = (index) => {
    const {selectPage} = props
    const page = pagesItems[index]
    const NextPage = pagesItems[index].component
    const component = <DPPages
      component={<NextPage pagesItems={page.values} onClick={onClick} message={page?.message} typePage={page.type ? page.type: null}
                           icon={page?.icon} containerClass={page?.containerClass} pages={pages} onChangePages={onChangePages}
                           {...props}
      />}
      page={page}
      onClick={onClick}
      onClickSubtitle={onBackContent}
      index={index}
      {...props}
    />
    onClick(component)
    selectPage(page)
  }

  const updatePage = (id) => {
    updateSectionItem(menuNav, id, "hidePage")
    updateMenuItem(id, pagesNew, TAB_MENU_VEHICLES)
    updateSectionItem(pagesNew, id, "isActive")

    if(id === TAB_MENU_FINANCE){
      updateMenuItem(id, pagesNew,TAB_MENU_ABOUT_US)
      updateSectionsForFinance(sectionsHomePage, id, "hideSection", !financePage.isActive)
      updateSectionsForFinance(sectionsHomePage, id, "isActive", financePage.isActive)
      updateSectionsForFinance(inventoryPage.sections, id, "showSection", financePage.isActive)
    }
    if(id === TAB_MENU_ABOUT_US){
      updateMenuItem(id, pagesNew,TAB_MENU_FINANCE)
    }

    unstable_batchedUpdates(() => {
      onChangePages(pagesNew)
      onChange(generalSettingNew)
    })
  }

  const updateMenuItem = (pageId, pages, tabId) => {
    const pageSelected = pages.find( pag => pag.id === tabId)
    const pageMenuItem = pageSelected.header.menu.find( menuItem => menuItem.id === pageId)
    pageMenuItem.hidePage = !pageMenuItem.hidePage
  }

  const updateSection = (id) => {

    if(id !== SECTION_FINANCE_ID || financePage.isActive){
      updateSectionItem(sectionsHomePage, id, "hideSection")
      updateSectionItem(sectionsHomePage, id, "isActive")
    }

    if(id === SECTION_LOCATION_ID){
      updateSectionItem(menuNav, SECTION_LOCATION_ID, "hidePage")
    }
    if(id === SECTION_CONTACT_US_ID){
      updateSectionItem(menuNav, SECTION_CONTACT_US_ID, "hidePage")
    }
    unstable_batchedUpdates(() => {
      onChangePages(pagesNew)
      onChange(generalSettingNew)
    })
  }

  const updateSectionItem = (items, sectionId, field) => {
    const itemSection = items.find(x => x.id === sectionId)
    itemSection[field] = (field === "isActive") ? !(itemSection[field] ?? true) : !itemSection[field]
  }

  const updateSectionsForFinance = (items, sectionId, field, value) => {
    const itemSection = items.find(x => x.id === sectionId)
    itemSection[field] = value
  }

  const handleToggle = (id) => {
    const tabSelected = pagesItems.find( tab => tab.id === id)
    const isTabKeyPage = tabSelected.typeKey === 'page'

    if(isTabKeyPage){
      updatePage(id)
      return
    }
    updateSection(id)
  }

  return (
    pagesItems.map((page, index) => {
      const pageActive =  page.typeKey === 'page' ? pagesNew.find( item => { return item.id === page.id}) : homePage.sections.find( item => {return item.id === page.id})
      return(
        <DPActivatedItem id={index}
                         key={`activated-item-${index}`}
                         showIcon={true}
                         title={page.displayValue}
                         active={ !!pageActive ? pageActive.isActive :  page.isActive}
                         onClickItem={() => onClickItem(index)}
                         href={page.href}
                         showToggle={page.showToggle}
                         handleCheck={handleToggle}
                         pageId={page.id}
        />
      )
    })
  )
}

const messageFinance = 'The form section is settled by default and it can not be changed, to edit fields and form content please go to Settings > Forms'
const messageVehicles = 'The inventory section is set by default and it can not be modified, it’ll show all your cars on inventory.'

const pagesItems = [
  {id: 'home', typeKey: 'page', displayValue: 'Home (Default)', isActive: true, hasSubTitle:true, subtitle: "Home", values: pageHomeItems, component: PageHomeContent, showToggle: false},
  {id: 'inventory', typeKey: 'page', displayValue: 'Vehicles (Default)', isActive: true, hasSubTitle:true,subtitle: "Vehicles page", values: pageVehicleItems, message: messageVehicles,icon: 'car_not_selected.svg', containerClass: 'container-page-vehicles', component: SettingPage, type: PAGE_INVENTORY_ID, showToggle: false},
  {id: 'finance', typeKey: 'page', displayValue: 'Financing (Default)', isActive: true, hasSubTitle:true,subtitle: "Financing page", values: pageFinanceItems, message: messageFinance, icon: 'getting_finance.svg', component: SettingPage, type: PAGE_FINANCE_ID, showToggle: true},
  {id: 'aboutUs', typeKey: 'page', displayValue: 'About us (Default)', isActive: true, hasSubTitle:true,subtitle: "About us page", component: SettingAboutPage, showToggle: true},
]

const tabs = [
  {key: LINK_TO_PAGE, title : 'Link to page', classNameHeader: "header-link", className: "label-link", component: <LinkContent optionsLink={optionsLinkToPage} />},
  {key: LINK_TO_SECTION, title : 'Link to section', classNameHeader: "header-link", className: "label-link", component: <LinkContent optionsLink={optionsLinkToSection} />},
  {key: LINK_TO_URL, title : 'External URL', classNameHeader: "header-link", className: "label-link", component: <ExternalUrlContent />}
]

const SettingContent = ({onChangePages, pages, ...props}) => {
  
  const [component, setComponent] = useState(null)

  let RenderComponent = component ? component.props.component : null

  const handleClick = () => {
    let el = document.getElementById("layFooter")
    let pointTo = getPosition(el);
    let y = getScrollTop();
    let pointFrom = {
      x: 0,
      y: y
    }
    scrollTo(pointFrom, pointTo, 600)
  }

  return (
    <div className="setting-content">
      {
        component ?
          cloneElement(component, {...props, pages, onChangePages}) :
            <>
              <DPSection
                title="Navigation Bar"
                component={
                <ListNavigationBar
                  {...props}
                />}
                href='header'
              />
              <DPPages
                component={
                  <PageHomeContent
                    pagesItems={pagesItems}
                    onClick={setComponent}
                    pages={pages}
                    onChangePages={onChangePages}
                    {...props}
                  />}
                onClick={setComponent}/>
              <DPSection show={false}
                         title="Footer"
                         component={<ListFooter {...props} />}
                         handleClick={handleClick}
                         href='footerSection'
              />
            </>
      }
    </div>
  )
}

export default SettingContent