import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {clone} from 'lodash'
import DynamicNumber from 'react-dynamic-number'
import {getLabelWithStyle} from './Utils'
import {Modal} from 'react-bootstrap'
import {cover, pathServer} from '../../../common/Constants'

const coverUserSetting = {
  backgroundColor: 'white'
}

export const Popover = ({child, handleRequestClose, anchorEl, classPopover, type, id, width}) => {

  let left = 0
  let top = 0
  let offsetWidth = 0

  if (anchorEl) {
    top = anchorEl.y
    left = anchorEl.x
    offsetWidth = width ? width : anchorEl.offsetWidth
  }

  const popover = {
    zIndex: 200,
    left: left,
    top: top,
    backgroundColor: "#fff",
    transition: "0.8s",
    position: "absolute",
    width: offsetWidth,
    marginBottom: 0
  }

  return (
    <div id={id ? id : null} style={popover} className={classPopover}>
      <div
        style={type ? coverUserSetting : cover}
        onClick={
          handleRequestClose
            ? (evt) => handleRequestClose(evt)
            : null
        }
      />
      {child}
    </div>
  )
}

export const DPPill = ({id, classContainer, handleDeleteItem, index, displayValue, name, required}) => {

  return (
    <div key={id ? id : null} className={classContainer}>
      <a id={index} name={name} onClick={handleDeleteItem}>X</a>
      <span className="label-value">{displayValue}</span>
      {
        required ?
          <span className="label-error">*</span> : null
      }
    </div>
  )
}

export const DPCustomPill = ({id, classContainer, handleDeleteItem, index, displayValue, name, required, customStyleContainer = null, customStyleDeleteIcon = null, customStyleDisplayValue = null, classDeleteIcon = null, classDisplayValue = null}) => {
  return (
    <div key={id ? id : null} className={classContainer} style={customStyleContainer}>
      <div className={classDisplayValue} style={customStyleDisplayValue}>{displayValue}</div>
      <div id={index} name={name} onClick={handleDeleteItem} className={classDeleteIcon} style={customStyleDeleteIcon}>&times;</div>
    </div>
  )
}

export const InputNumber = ({id, idParent, parentStyle, displayValue, dynamicNumberStyle, placeholder, thousand, readOnly, value, onChange}) => {
  return (
    <div id={idParent} className={parentStyle}>
      {
        displayValue ?
          <div className="label-finance">
            <label>{displayValue}</label>
          </div> : null
      }
      <div className="">
        <DynamicNumber id={id} name={id} placeholder={placeholder ? placeholder : ""}
                       className={dynamicNumberStyle}
                       value={value} positive={true} negative={false} thousand={thousand ? thousand : false}
                       disabled={readOnly ? readOnly : false}
                       onChange={onChange}
                       integer={2} fraction={0}/>
      </div>
    </div>
  )
}

export const SectionHeaderAgreement = ({id, isOpen, accordionToggle, title, showSubTitle, subTitle, showSwitch, showSection, onChange, section}) => {
  return (
    <div className="car-header">
      <div className="toggle-circle" onClick={() => accordionToggle(id)}>
        {
          !isOpen ?
            <div>
              <div className="icon-toggle-horizontal-line"/>
              <div className="icon-toggle-vertical-line"/>
            </div>
            :
            <div className="icon-toggle-horizontal-line"/>
        }
      </div>
      <div className="container-tile-subtitle">
        <div className="container-title-switch">
          <div className="box-title">
            <h5>{title}</h5>
          </div>
          {
            showSwitch ?
              <div className="box-switch">
                <div className="sec-switch">
                  <label className="switch">
                    <input id={`checkbox-${section}`} type="checkbox" checked={showSection}
                           onChange={() => onChange(id)}/>
                    <span className={showSection ? "slider round" : "slider round off"}/>
                    {
                      showSection ?
                        <span className="check-label-on">ON</span> :
                        <span className="check-label-off">OFF</span>
                    }
                  </label>
                </div>
              </div> : null
          }
        </div>
        {
          showSubTitle ?
            <div className="container-sub-title">
              <div className="box-sub-title">
                <span className="label-message">{subTitle}</span>
              </div>
            </div> : null
        }
      </div>
    </div>
  )
}

export class PopoverSearchable extends Component {

  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      keyword: null,
      options: [],
      optionsAll: []
    }
  }

  componentWillMount() {
    const {options} = this.props
    this.setState({options: options, optionsAll: options})
  }

  componentDidUpdate() {
    const {id} = this.props
    const element = document.getElementById(`searchableInput${id}`)
    if (element) {
      element.focus()
    }
  }

  componentDidMount() {
    const {id} = this.props
    const element = document.getElementById(`searchableInput${id}`)
    if (element) {
      element.focus()
    }
  }

  handleChange(evt) {
    const keyword = evt.target.value
    const {optionsAll} = this.state
    let optionsFiltered = clone(optionsAll)
    optionsFiltered = optionsFiltered.filter(op => {
      return op.displayValue.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
    })
    this.setState({options: optionsFiltered, keyword: keyword})
  }

  render() {
    const {isTradeIn} = this.props
    let left = 0
    let top = 0
    let offsetWidth = 0
    const {id, classPopover, anchorEl, onChangeDropDown, onChange, foundValue} = this.props
    const {options, keyword} = this.state
    if (anchorEl) {
      top = anchorEl.y
      left = anchorEl.x
      offsetWidth = anchorEl.offsetWidth
    }

    const popover = {
      zIndex: 200,
      left: left,
      top: isTradeIn ? top - 34 : top,
      backgroundColor: "#fff",
      transition: "0.8s",
      position: "absolute",
      width: offsetWidth,
      marginBottom: 0
    }


    return (
      <div id={id ? id : null} style={popover} className={classPopover}>
        <div style={cover} onClick={(e) => onChangeDropDown(e, id)}/>
        <div style={{position: 'relative', zIndex: 200}}>
          <div className="searchable-filter">
            <div>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_search.svg"} className="search"
                        beforeInjection={svg => svg.classList.add("ic-search")}/>
              <input id={"searchableInput" + id} className="form-control" placeholder="" onChange={this.handleChange}/>
              <i className="fa fa-chevron-up ic-arrow-up"/>
            </div>
          </div>
          <div className="searchable-box">
            <div className="searchable-box-dp">
              {
                options.length > 0 ?
                  options.map((op, index) => {
                    let classNameItem = ""
                    if (foundValue && op.displayValue === foundValue.displayValue) {
                      classNameItem = "item-selected"
                    } else {
                      classNameItem = "change-submenu-item"
                    }
                    return (
                      <div key={index + op.id}>
                        <label className={classNameItem}
                               onClick={() => onChange(id, index, op.id)}>{getLabelWithStyle(op.displayValue, keyword, {color: '#2194F1'})}</label>
                      </div>
                    )
                  }) :
                  <label>No matched your search</label>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const DPModalInfo = ({showModal, classModalContainer, closeModalInfo, body, handleSelectCustomer}) => {
  return (
    <Modal show={showModal} className={`customer-modal-container ${classModalContainer ? classModalContainer : ''}`} backdropClassName="backdrop-car">
      {
        handleSelectCustomer ?
          <img key={'trash-delete-customer'} id="imgTrash" src={pathServer.PATH_IMG + "deleteCustomer.png"}
               className="img-delete" onClick={handleSelectCustomer}/> : null
      }
      <Modal.Body>
        <div className="customer-modal">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon"
                    onClick={closeModalInfo}/>
          {
            body
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const DPPillBox = ({displayValue, stylePill}) => {
  return (
    <div className="pill-box" style={stylePill ? {marginRight: 5} : {marginRight: 0}}>
      <label>{displayValue}</label>
    </div>
  )
}

export const ModalSuccessfullyUpdated = ({show, onHide, message}) => {
  return (
    <div className="container-lead-promote">
      <Modal show={show} className="modal-message" backdropClassName="backdrop-promote">
        <Modal.Body>
          <div className="panel-top">
            <div className="label-sure">
              <label>{message}</label>
            </div>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close_circle.svg"} className="icon-close-content"
                      beforeInjection={svg => svg.classList.add("icon-close")} onClick={onHide}/>
          </div>
          <div className="panel-bottom">
            <div className="container-list-customer">
              <img src={pathServer.PATH_IMG + "successfully-image.png"} className="image-successfully"/>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export const WarningMessage = ({message}) => {
  return (
    <div className="container-icon-body-title">
      <div className="container-icon-exclamation">
        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"}
                 beforeInjection={svg => svg.classList.add("svg-icon-error")}
                 className="icon-error"/>
      </div>
      <div className="container-message">
        <label className="title-message">{message}</label>
      </div>
    </div>
  )
}

export const DPArrowCircle = ({id,onClick,icon}) => {
  return(
    <a id={id} className="on" onClick={onClick}>
      <ReactSVG id="circleStack" src={`${pathServer.PATH_IMG}icon/${icon}`}/>
    </a>
  )
}
