import React, {Component} from 'react'
import Inventory from './Inventory.jsx'
import {
  searchInventory,
  setShowList,
  setSearchQueryInventory,
  loadLocations,
  setPageNumberInventory,
  setCarFilters,
  setCarFiltersInitial,
  setMinValues,
  setMaxValues,
  loadMakeAndModelAll
} from '../../actions'
import {connect} from 'react-redux'
import {
  SIDEBAR_WIDTH_EXPANDED,
  SIDEBAR_WIDTH_UNEXPANDED
} from '../../Constants'
import {setCarFiltersAndCurrentPage, setCarStatusOptions, setFirstLoading, setKeyword, setMenuId} from '../../actions'
import {setPlaceHolder} from '../util/Utils'

class ContainerInventory extends Component {

  constructor() {
    super();
    this.state = {
      quantityCars:null,
      loading:true
    }
  }

  componentWillMount(){

    const {makes, locations, loadMakeAndModelAll, loadLocations} = this.props;
    if(!makes)
      loadMakeAndModelAll().then(response => {});

    if(!locations)
      loadLocations().then(response => {});
  }

  componentDidMount() {
    let {showSideBar} = this.props;
    if (showSideBar) {
      let container = window.document.getElementById("container");
      if(container)
        container.className += ' container-collapse';
    }

    setPlaceHolder("menu-search","Search Make or Model");
  }

  componentWillUnmount(){
    const {setSearchQueryInventory,setKeyword} = this.props;
    setSearchQueryInventory({});
    setKeyword("");
  }

  render() {
    const {cars, optionsBody, makes, models, showList, setShowList, searchQueryInventory,setSearchQueryInventory,attributeValuesToCarStatus,locations} = this.props;
    const {height,showSideBar,keyword,setKeyword, pageNumberInventory, setPageNumberInventory,isFirstLoading,setFirstLoading,
      carFilters, setCarFilters, loadMakeAndModel,loadLocations,setCarStatusOptions, setCarFiltersInitial, itemsByPageQuantity,
      minValues, maxValues, setMinValues, setMaxValues,setCarFiltersAndCurrentPage} = this.props;
    const {quantityCars} = this.state;

    if(!makes || !locations) return null;

    let left;
    if(showSideBar){
      left = SIDEBAR_WIDTH_EXPANDED;
    } else{
      left = SIDEBAR_WIDTH_UNEXPANDED;
    }

    return (
      <div id="container" className="container-component" style={{height:height}}>
        {
          (this.props.children) ?
            this.props.children :
            <Inventory cars={cars} quantityCars={quantityCars} optionsBody={optionsBody} makes={makes} models={models}
                       showList={showList} setShowList={setShowList} searchQueryInventory={searchQueryInventory}
                       setSearchQueryInventory={setSearchQueryInventory} left={left} attributeValuesToCarStatus={attributeValuesToCarStatus}
                       locations={locations} keyword={keyword} setKeyword={setKeyword} showSideBar={showSideBar}
                       pageNumberInventory={pageNumberInventory} setPageNumberInventory={setPageNumberInventory}
                       isFirstLoading={isFirstLoading} setFirstLoading={setFirstLoading}
                       carFilters={carFilters} setCarFilters={setCarFilters} loadMakeAndModel={loadMakeAndModel} loadLocations={loadLocations}
                       setCarStatusOptions={setCarStatusOptions} setCarFiltersInitial={setCarFiltersInitial} itemsByPageQuantity={itemsByPageQuantity}
                       minValues={minValues} maxValues={maxValues} setMinValues={setMinValues} setMaxValues={setMaxValues}
                       setCarFiltersAndCurrentPage={setCarFiltersAndCurrentPage}
            />
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId:state.dealerId,
    makes:state.makesAll,
    models:state.modelsAll,
    cars: state.cars,
    optionsBody:state.optionsBody,
    showSideBar:state.showSideBar,
    showList:state.showList,
    searchQueryInventory:state.searchQueryInventory,
    attributeValuesToCarStatus:state.attributeValuesToCarStatus,
    locations:state.locations,
    keyword:state.keyword,
    pageNumberInventory:state.pageNumberInventory.currentPage,
    isFirstLoading:state.isFirstLoading,
    carFilters: state.carFilters,
    itemsByPageQuantity:state.itemsByPageQuantity
  }
};

export default connect(mapStateToProps,{loadMakeAndModelAll,searchInventory,setShowList,setSearchQueryInventory,setCarStatusOptions,
                      loadLocations,setMenuId,setKeyword, setPageNumberInventory,setFirstLoading, setCarFilters, setCarFiltersInitial,
                      setMinValues, setMaxValues,setCarFiltersAndCurrentPage})(ContainerInventory)