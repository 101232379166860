import React, {forwardRef,useState} from 'react'
import {ReactSVG} from 'react-svg'
import {DPTextarea} from '../../common/DPTextarea'
import SocialNetworkReview from './SocialNetworkReview'
import DPMessageInfo from '../../../common/DPMessageInfo'
import {pathServer} from '../../../common/Constants'
import DPButtonLoading from '../../common/DPButtonLoading'
import {FACEBOOK_KEY, GOOGLE_KEY} from '../../Constants'

require('./ReviewItem.scss')

const  ReviewItem = forwardRef(({ title, iconPath, messageText, secondMessageText, rowsTextArea, textAreaMaxLength, iconSendPath, btnSaveText, btnSendText,
                       textAreaMessage, showSocialReviews, prevFunction, textAreaValue, handleChangeOnTextArea, handleSaveBtn, classNameSaveBtn,
                        isLoadingBtnSave, isBtnSaved, isDisable, btnLoadingText, btnSavedText, handleCheck, showPreviewFacebook, showPreviewGoogle },ref) => {
    return(
      <div className='review-module'>
        <div className="container-top-content">
          <div className={'title-review'}><b>{title ?? 'Title'}</b></div>
          <DPMessageInfo messageText={messageText} secondMessageText={secondMessageText}/>
          <DPTextarea
            label={textAreaMessage ?? 'Review request default message'}
            rows={rowsTextArea ?? 3}
            customStyleContainer={{marginBottom: '19px'}}
            customStyleLabel={{fontSize: '10px', color: '#787878', fontWeight: '100'}}
            maxLength={textAreaMaxLength ?? 100}
            rightElement={<span className={'font-max'} style={{fontSize: '10px', color: '#787878', fontWeight: '100', float: 'right'}}>{`${textAreaValue.length}/${textAreaMaxLength ?? 100}`}</span>}
            value={textAreaValue}
            onChange={handleChangeOnTextArea}
          />
          {
            showSocialReviews &&
            <div className={'container-social-reviews'}>
              <SocialNetworkReview
                pathIcon={'ic_facebook_color.svg'}
                text={'Facebook reviews'}
                showToggle={true}
                handleCheck={() => handleCheck(!showPreviewFacebook, FACEBOOK_KEY)}
                checked={showPreviewFacebook}
              />
              <SocialNetworkReview
                pathIcon={'ic_google_color.svg'}
                text={'Google reviews'}
                showToggle={true}
                handleCheck={() => handleCheck(!showPreviewGoogle, GOOGLE_KEY)}
                checked={showPreviewGoogle}
              />
              <span className={'text-indications'}>To connect your accounts please go to Setting > Marketing</span>
            </div>
          }
        </div>
        <div className={'container-buttons'}>
          <div className={'btn-send-now'} onClick={prevFunction} >
            {
              iconSendPath &&
              <ReactSVG
                src={`${pathServer.PATH_IMG}icon/${iconSendPath}`}
                className={'icon-send'}
              />
            }
            {btnSendText ?? 'Send now'}
          </div>
          <DPButtonLoading buttonClass={classNameSaveBtn} width="90px" height='32px' onClick={handleSaveBtn} isLoading={isLoadingBtnSave}
                            isSaved={isBtnSaved} isDisable={isDisable} btnSaveText={btnSaveText} btnLoadingText={btnLoadingText}
                           btnSavedText={btnSavedText}/>
        </div>
      </div>
    )
})

export default ReviewItem