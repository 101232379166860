import React from 'react'
import {ReactSVG} from 'react-svg'
import {pathServer} from './Constants'

require('./DPStarsReview.scss')

const StarIconArray = ({qualification}) => {
  let iterator = 0
  let startIconDisplay = []
  while(iterator < 5){
    if(iterator < qualification){
      startIconDisplay.push(<ReactSVG key={`star${iterator}`} src={`${pathServer.PATH_IMG}icon/ic_star_review.svg`} className='icon-star-social'
                                      beforeInjection={ svg => { svg.setAttribute('style', `fill: #D7DAE2`); }} />)
    }else{
      startIconDisplay.push(<ReactSVG key={`star${iterator}`} src={`${pathServer.PATH_IMG}icon/ic_star_review.svg`} className='icon-star-social'/>)
    }
    iterator++
  }
  return (
    <>
      {startIconDisplay}
    </>
  )
}

const DPStarsReview = () => {

  return(
    <div className="stars-review-container">
      <div className="number-stars-container">
        <label className="number-stars">0.0</label>
      </div>
      <div className="stars-social-container">
        <StarIconArray qualification={5}/>
      </div>
      <div className="label-no-reviews-container">
        <label className="label-no-reviews">No reviews yet</label>
      </div>
    </div>
  )
}

export default DPStarsReview