import {ReactSVG} from 'react-svg'
import {pathServer} from '../../common/Constants'
import React from 'react'
import PropTypes from 'prop-types'

export const DPSectionHeader = (
  { isClosed, title, subTitle, accordionToggle, subTitleWhenIsOpened, questionMarkId,
    handleShowPopover }) => {
  return (
    <div
      className="applicant-section-header"
      onClick={accordionToggle}
    >
      <div className="applicant-section-header-icon" >
        {
          isClosed ?
            <div>
              <div className="icon-toggle-horizontal-line"/>
              <div className="icon-toggle-vertical-line"/>
            </div>
            :
            <div className="icon-toggle-horizontal-line"/>
        }
      </div>
      <div className="applicant-section-header-info">
        <span className="applicant-section-header-title">
          {title}
        </span>
        {
          questionMarkId
            ? <ReactSVG
              id={questionMarkId}
              src={pathServer.PATH_IMG + "icon/ic_sign_question.svg"}
              className="icon-question-content"
              onClick={handleShowPopover}
              wrapper={'span'}
            />
            : null
        }
        <div className="applicant-section-header-subtitle">
          {
            subTitleWhenIsOpened
              ? !isClosed && subTitleWhenIsOpened
              : subTitle
          }
        </div>
      </div>
    </div>
  )
}

DPSectionHeader.defaultProps = {
  isClosed: false,
  title: '',
  subTitle: '',
  accordionToggle: () => {},
  subTitleWhenIsOpened: '',
  questionMarkId: '',
  handleShowPopover: () => {}
}

DPSectionHeader.propTypes = {
  isClosed: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  accordionToggle: PropTypes.func,
  subTitleWhenIsOpened: PropTypes.string,
  questionMarkId: PropTypes.string,
  handleShowPopover: PropTypes.func,
}