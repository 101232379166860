import React, {PureComponent} from 'react'
import {createPortal} from 'react-dom'
import DPOverlayInnerContent, {PLACEMENTS} from './DPOverlayInnerContent'

export { PLACEMENTS } from "./DPOverlayInnerContent";

export default class DPOverlay extends PureComponent {

  static id = 'dp-overlay';

  static propTypes = DPOverlayInnerContent.propTypes;

  static defaultProps = {
    placement: PLACEMENTS.BOTTOM,
    show: false,
    fullWidth: false,
    addPadding: true
  };

  componentWillUnmount () {
    const node = document.getElementById(DPOverlay.id);

    if (Boolean(node)) {
      document.body.removeChild(node);
    }

    this.node = null;
  }

  render() {
    this.node = document.getElementById(DPOverlay.id);

    if (!Boolean(this.props.node) && !Boolean(this.node)) {
      this.node = document.createElement('div');
      this.node.setAttribute('id', DPOverlay.id);
      document.body.appendChild(this.node)
    }

    const { props } = this;

    return !props.show
      ? null
      : createPortal(<DPOverlayInnerContent {...props} />, this.node)
  }
}