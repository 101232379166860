import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {
  LINK_TO_PAGE,
  LINK_TO_SECTION
} from '../../../Constants'
import Dropzone from 'react-dropzone'
import {SketchPicker} from 'react-color'
import {findIndex, cloneDeep, isEmpty} from 'lodash'
import Slider from 'material-ui/Slider'
import DynamicNumber from 'react-dynamic-number'
import {getDynamicNumberValue, parseDynamicNumberToNumber, parseDynamicNumberValue} from '../../util/Utils'
import DPSelectionDropDown from '../../../common/DPSelectionDropDown'
import {DPDynamicNumberInput} from '../../../common/DPDynamicNumberInput'
import {getPosition} from '../../../../common/Util'
import {
  cover,
  EXT_IMG,
  EXT_OTHER_IMG,
  EXT_PNG,
  pathServer, TEMPLATE_CODE_EASY_NAVIGATION_B, TEMPLATE_CODE_MODERN_VIEW, TEMPLATE_CODE_MODERN_VIEW_2,
  TEMPLATE_CODE_MODERN_VIEW_3
} from '../../../../common/Constants'

const presetColors = ['#ffffff', '#000000', '#0093f7', '#00de8a', '#ff565e', '#005099', '#bd0000']
const presetTextColors = ['#000000', '#4A4A4A', '#9A9A9A', '#CECECE', '#ffffff']
const COLOR_PRIMARY_DEFAULT = '#e60000'
const COLOR_SECONDARY_DEFAULT = '#0088b4'
const COLOR_TEXT_DEFAULT = '#7f7f7f'
const COLOR_BACKGROUND_DEFAULT = '#ffffff'
const COLOR_NAVIGATION_DEFAULT = '#ffffff'

const KEY_COLOR_PRIMARY = 'primary'
const KEY_COLOR_SECONDARY = 'secondary'
const KEY_COLOR_HOME_SECTIONS_TEXT = 'homeSectionsText'
const KEY_COLOR_NAVIGATION_BAR_TEXT = 'navigationBarText'
const KEY_COLOR_NAVIGATION_BAR_BACKGROUND = 'navigationBar'

const KEY_LOGO = 'logo'
const KEY_ICON = 'icon'
const KEY_BACKGROUND_MAIN = 'backgroundMain'
const KEY_BACKGROUND_SECOND = 'backgroundSecond'
const KEY_SITE = 'site'
const KEY_SLOGAN = 'slogan'
const KEY_SOCIAL_CHECK = 'openOnNewWindow'
const KEY_BACKGROUND_FINANCE = 'backgroundFinance'

const BodyUploadImage = ({
                           title,
                           imgUpload,
                           type,
                           validateImage,
                           styleDropZone,
                           banner,
                           id,
                           removeImage,
                           onChangeNumber,
                           onDrop,
                           handleSlider,
                           existSubTitle,
                           heightSecond,
                           classNameImg,
                           templateCode
                         }) => {

  const {opacity, maxHeight} = banner
  const overlap = (templateCode === TEMPLATE_CODE_MODERN_VIEW || templateCode === TEMPLATE_CODE_MODERN_VIEW_2 || templateCode === TEMPLATE_CODE_MODERN_VIEW_3 || templateCode === TEMPLATE_CODE_EASY_NAVIGATION_B)
  return (
    <div className='row-banner'>
      <label className='label-banner'>{title}</label>
      {
        existSubTitle ?
          <span className='span-banner'>This image is shown over the Background Image. Use PNG files</span> :
          null
      }
      <div className={overlap && type !== KEY_BACKGROUND_MAIN ? 'img-banner-main-not-available' : 'img-banner-main'}>
        {
          overlap && type !== KEY_BACKGROUND_MAIN ?
            <div>
              <img className='icon-option'
                   style={{width: '30px', height: '30px', marginTop: '5px', cursor: 'pointer'}}
                   src={pathServer.PATH_IMG + 'inverted.png'}/>
              <label className='overlapped-not-available'>Not available with this theme.</label>
            </div>
            :
            (imgUpload
                ? <img id={id} className={classNameImg} src={imgUpload}
                       style={heightSecond ? {height: heightSecond} : null}/>
                : <Dropzone id='dropzone' onDrop={(file) => onDrop(file, type)}
                            accept={type !== KEY_BACKGROUND_SECOND ? `.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}` : `.${EXT_PNG}`}
                            style={styleDropZone}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className='upload-image-logo dropzone-container'>
                      <div className=''>
                        <input {...getInputProps()} />
                        <div className='container-image-upload'>
                          <img src={pathServer.PATH_IMG + 'cloud-upload-arrow.png'} className='customer-image-upload'/>
                        </div>
                        <label className='label-upload-logo'>Upload image or Drag on Drop here</label>
                      </div>
                    </div>
                  )}
                </Dropzone>
            )
        }
      </div>
      {
        imgUpload ?
          <div>
            <div className='banner-info'>
              {
                !validateImage ?
                  <label className='image-invalid'> Image not valid </label> : null
              }
              <div className='logo-info-height'>
                <label>Height</label>
                <div className='height-change-row'>
                  <div className='height-change'>
                    <DynamicNumber id='maxHeight' className='form-control'
                                   value={maxHeight ? parseDynamicNumberToNumber(maxHeight) : 100}
                                   positive={true} negative={false} thousand={false}
                                   onChange={(evt, modelValue, viewValue) => onChangeNumber(type, evt, modelValue, viewValue)}
                                   integer={3} fraction={0}/>
                    <span className='icon-px'>px</span>
                  </div>
                </div>
              </div>
              <div className='logo-info-icon'>
                <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_delete.svg'} className='logo-action-content'
                          onClick={() => removeImage(type)} beforeInjection={svg => svg.classList.add('logo-action')}/>
                <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_import_export.svg'} className='logo-action-content'
                          beforeInjection={svg => svg.classList.add('logo-action')}/>
              </div>
            </div>
            {
              type !== KEY_BACKGROUND_SECOND ?
                <div className='banner-info'>
                  <div className='logo-info-height'>
                    <label>Opacity</label>
                    <div className='btn-message-dp'>
                      {opacity ?
                        (Math.round(opacity * 100)) + '%' : '100%'}
                    </div>
                  </div>
                  <div className='banner-info-opacity'>
                    <Slider value={opacity ? opacity : 1}
                            sliderStyle={{margin: '5px 0 0 0'}}
                            onChange={(event, value) => handleSlider(value, type, event)}/>
                  </div>
                </div> : null
            }
          </div> : null
      }
    </div>
  )
}

const LIST_FONT_FAMILY = [
  {id: 'lato', displayValue: 'Lato, sans-serif'},
  {id: 'montserrat', displayValue: 'Montserrat, sans-serif'},
  {id: 'openSans', displayValue: 'Open Sans, sans-serif'},
  {id: 'roboto', displayValue: 'Roboto, sans-serif'},
  {id: 'raleway', displayValue: 'Raleway, sans-serif'},
  {id: 'robotoSlab', displayValue: 'Roboto Slab, sans-serif'}
]

const SocialMediaSection = ({id, value, iconUrl, classIcon, onChangeSocialMedia, cleanValuesSocialMedia}) => {

  return (
    <div className='btn-message-dp'>
      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_menu.svg`} className='icon-menu-content'
                beforeInjection={svg => svg.classList.add('icon-menu')}/>
      <ReactSVG src={`${pathServer.PATH_IMG}${iconUrl}`} className='icon-menu-content'
                beforeInjection={svg => svg.classList.add(classIcon)}/>
      <div className='col-sec'>
        <input id={id}
               className='form-control input-header-footer'
               value={value}
               onChange={onChangeSocialMedia}/>
        <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_delete.svg`}
                  className='icon-action-content-delete'
                  beforeInjection={svg => svg.classList.add('icon-action')} onClick={() => cleanValuesSocialMedia(id)}/>
      </div>
    </div>
  )
}

export default class GeneralSetting extends Component {

  constructor() {
    super()
    this.onChangeInput = this.onChangeInput.bind(this)
    this.handleColorPalette = this.handleColorPalette.bind(this)
    this.handleColorClose = this.handleColorClose.bind(this)
    this.handleColorDefault = this.handleColorDefault.bind(this)
    this.handleLinkToPage = this.handleLinkToPage.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.validateImage = this.validateImage.bind(this)
    this.handleColorOptions = this.handleColorOptions.bind(this)
    this.selectColor = this.selectColor.bind(this)
    this.closeColorOptions = this.closeColorOptions.bind(this)
    this.saveColorOptions = this.saveColorOptions.bind(this)
    this.state = {
      template: null,
      files: [],
      fields: [],
      showColorPalette: false,
      positionY: 0,
      selectedColor: '',
      selectedMenu: '',
      open: false,
      options: [],
      linkTab: LINK_TO_PAGE,
      selectedSection: '',
      imgMain: null,
      validateImage: true,
      showColorOption: false,
      previousColorSelected: '',
      selectedFooterSection: '',
      imgFinance: null,
      openModalTypography: false
    }
  }

  //UNSAFE_componentWillMount() {
  //  this.loadImage(this.props);
  //}

  componentWillUnmount() {
    //document.getElementById("dropzone").removeEventListener("dragleave", this._onDragLeave);
    //document.getElementById("dropzone").removeEventListener("dragenter", this._onDragEnter);
    //document.getElementById("dropzone").removeEventListener("dragover", this._onDragOver);
    //window.removeEventListener("drop", this._onDrop);
  }

  componentDidMount() {
    this.loadImage(this.props)
    //window.addEventListener("drop", this._onDrop);
  }

  componentDidUpdate() {
    const {selectedColor} = this.state
    const palette = document.getElementById('colorPalette')
    if (palette) {
      let row = document.getElementById(selectedColor)
      //if (row)
      // row.style.height = (palette.offsetHeight + 40) + "px";
    }

    //--this.loadImage(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.loadImage(nextProps)
  }

  _onDragLeave(e) {
    this.removeClass(e)
  }

  _onDragEnter(e) {
    this.addClass(e)
  }

  _onDragOver(e) {
    this.addClass(e)
  }

  _onDrop(e) {
    //this.removeClass(e);
  }

  handleSelectSection = (id) => {
    let {onChangeSection} = this.props
    onChangeSection(id)
  }

  handleChangeColor = (color, key) => {
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.color[key] = color.hex
    onChange(generalSettingNew)
  }

  cleanValuesFooter(type) {
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    for (let key in generalSettingNew.footer[type]) {
      if (generalSettingNew.footer[type].hasOwnProperty(key))
        if (key !== 'showSection')
          generalSettingNew.footer[type][key] = ''
    }
    onChange(generalSettingNew)
  }

  cleanValuesSocialMedia = (key) => {
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    if (generalSettingNew.socialMedia?.hasOwnProperty(key))
      generalSettingNew.socialMedia[key] = ''

    onChange(generalSettingNew)
  }

  handleCheckSection(type) {
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    let value
    value = generalSettingNew.footer[type].showSection
    generalSettingNew.footer[type].showSection = !value
    onChange(generalSettingNew)
  }

  onChangeFieldValue = (type, modelValue, viewValue, event) => {
    event.stopPropagation()
    const {generalSetting, onChange} = this.props
    const id = event.target.id
    let generalSettingNew = cloneDeep(generalSetting)
    if (type === KEY_SITE) {
      generalSettingNew[type][id] = getDynamicNumberValue(modelValue, viewValue)
    }
    onChange(generalSettingNew)
  }

  onBlurFieldValue = (type, event) => {
    event.stopPropagation()
    const {generalSetting, onChange} = this.props
    const id = event.target.id
    let generalSettingNew = cloneDeep(generalSetting)
    if (type === KEY_SITE) {
      generalSettingNew[type][id] = parseDynamicNumberValue(event.target.value)
    }
    onChange(generalSettingNew)
  }

  renderSection(generalSetting, id, templateId, templateCode) {
    let view
    let styleDrop
    const {selectedFooterSection} = this.state
    const {header, banner, site, color, typography, footer, socialMedia} = generalSetting
    const {dealerSiteIconUrl} = this.props
    switch (id) {
      case '':
        view = (
          <div className='body-main'>
            <div className='row-template'>
              <a className='menu-list' onClick={() => this.handleSelectSection('header')}>
                <label>{header.displayValue}</label>
                <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'} className='icon-arrow'
                          beforeInjection={svg => svg.classList.add('arrow-next')}/>
              </a>
            </div>
            <div className='row-template'>
              <a className='menu-list' onClick={() => this.handleSelectSection('banner')}>
                <label>{banner.displayValue}</label>
                <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'} className='icon-arrow'
                          beforeInjection={svg => svg.classList.add('arrow-next')}/>
              </a>
            </div>
            <div className='row-template'>
              <a className='menu-list' onClick={() => this.handleSelectSection('site')}>
                <label>{site.displayValue}</label>
                <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'} className='icon-arrow'
                          beforeInjection={svg => svg.classList.add('arrow-next')}/>
              </a>
            </div>
            <div className='row-template'>
              <a className='menu-list' onClick={() => this.handleSelectSection('color')}>
                <label>{color.displayValue}</label>
                <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'} className='icon-arrow'
                          beforeInjection={svg => svg.classList.add('arrow-next')}/>
              </a>
            </div>
            <div className='row-template'>
              <a className='menu-list' onClick={() => this.handleSelectSection('typography')}>
                <label>{typography.displayValue}</label>
                <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'} className='icon-arrow'
                          beforeInjection={svg => svg.classList.add('arrow-next')}/>
              </a>
            </div>
            <div className='row-template'>
              <a className='menu-list' onClick={() => this.handleSelectSection('footer')}>
                <label>{footer.displayValue}</label>
                <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'} className='icon-arrow'
                          beforeInjection={svg => svg.classList.add('arrow-next')}/>
              </a>
            </div>
            <div className='row-template row-template-sm'>
              <label className={'social-media-title'}>{socialMedia ? socialMedia.displayValue : 'Social Media'}</label>
              <SocialMediaSection id={'facebook'}
                                  value={socialMedia?.facebook}
                                  iconUrl={'icon/ic_facebook.svg'}
                                  classIcon={'icon-menu-facebook'}
                                  onChangeSocialMedia={this.onChangeSocialMedia}
                                  cleanValuesSocialMedia={this.cleanValuesSocialMedia}
              />
              <SocialMediaSection id={'instagram'}
                                  value={socialMedia?.instagram}
                                  iconUrl={'icon/ic_instagram.svg'}
                                  classIcon={'icon-menu-instagram'}
                                  onChangeSocialMedia={this.onChangeSocialMedia}
                                  cleanValuesSocialMedia={this.cleanValuesSocialMedia}
              />
              <SocialMediaSection id={'twitter'}
                                  value={socialMedia?.twitter}
                                  iconUrl={'icon/ic_twitter.svg'}
                                  classIcon={'icon-menu-twitter'}
                                  onChangeSocialMedia={this.onChangeSocialMedia}
                                  cleanValuesSocialMedia={this.cleanValuesSocialMedia}
              />
              <div className='row-social'>
                <label className='label-new-window'>Open links in new windows</label>
                <div className='window-switch'>
                  <label className='switch' onChange={() => this.handleCheck(KEY_SOCIAL_CHECK)}>
                    <input type='checkbox' checked={socialMedia?.openOnNewWindow}/>
                    <span className={socialMedia?.openOnNewWindow ? 'slider round' : 'slider round off'}/>
                    {
                      socialMedia?.openOnNewWindow ?
                        <span className='check-label-on'>ON</span> :
                        <span className='check-label-off'>OFF</span>
                    }
                  </label>
                </div>
              </div>
            </div>
          </div>
        )
        break

      case 'site':
        styleDrop = {
          height: 70,
          borderStyle: 'none'
        }

        view = (
          <div className=''>
            <div className='row-logo'>
              <label className='logo-title'>Logotype</label>
              <div className='img-logo'>
                {
                  site.logo !== '' ?
                    <img src={site.logo} style={{height: 35}}/> :
                    <Dropzone id='dropzone' onDrop={(file) => this.onDrop(file, KEY_LOGO)}
                              accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                              style={styleDrop}>
                      {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className='upload-image-logo dropzone-container'>
                          <input {...getInputProps()} />
                          <div className=''>
                            <div className={'container-image-upload'}>
                              <img src={pathServer.PATH_IMG + 'cloud-upload-arrow.png'}
                                   className='customer-image-upload'/>
                            </div>
                            <label className='label-upload-logo'>Upload image or Drag on Drop here</label>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                }
              </div>
              {
                site.logo !== '' ?
                  <div className='logo-info'>
                    <div className='logo-info-height'>
                      <DPDynamicNumberInput
                        id={'maxHeight'}
                        //className={'col25'}
                        title={'Max height'}
                        value={site.maxHeight}
                        onChange={(evt, modelValue, viewValue) => this.onChangeFieldValue(KEY_SITE, modelValue, viewValue, evt)}
                        onBlur={(evt) => this.onBlurFieldValue(KEY_SITE, evt)}
                        thousand={true}
                        integer={3}
                        separator={'.'}
                        fraction={0}
                        placeHolder={'0'}
                        classWrapperInput={'info-box'}
                      />
                      <span>px</span>
                    </div>
                    <div className='logo-info-icon'>
                      <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_delete.svg'}
                                className='logo-action-content'
                                onClick={() => this.removeImage(KEY_LOGO)}
                                beforeInjection={svg => svg.classList.add('logo-action')}/>
                      <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_import_export.svg'} className='logo-action-content'
                                beforeInjection={svg => svg.classList.add('logo-action')}/>
                    </div>
                  </div> : null
              }
            </div>
            <div className='row-title'>
              <label>SITE TITLE</label>
              <input id='title' className='form-control' value={site.title}
                     onChange={(e) => this.onChangeInput(KEY_SITE, e)}/>
            </div>
            <div className='row-title'>
              <label>SHORT DESCRIPTION</label>
              <input id='description' className='form-control' value={site.description}
                     onChange={(e) => this.onChangeInput(KEY_SITE, e)}/>
            </div>
            <div className='row-icon'>
              <label>Site Icon</label>
              <span>
                Site icons are these found on browser tabs, bookmarks bars and WorkPress mobile applications.
                <Dropzone onDrop={(file) => this.onDrop(file, KEY_ICON)}
                          accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                          style={{display: 'inline-block'}}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className='dropzone-container'>
                      <input {...getInputProps()} />
                      <a className='upload-icon'>Upload one here!</a>
                    </div>
                  )}
                </Dropzone>
              </span>
              <span>
                The icons on the site must be square and less than 512 x 512 pixels.
              </span>
              <div>
                <div className='col-img-browser'>
                  <img src={pathServer.PATH_IMG + 'icon_browser.png'} style={{}}/>
                </div>
                <div className='col-icon-browser'>
                  {
                    isEmpty(dealerSiteIconUrl) && site.icon === ''
                      ? <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_photo.svg'} className='icon-photo-content'
                                  beforeInjection={svg => svg.classList.add('icon-photo')}/>
                      : <div>
                        <img src={!isEmpty(site.icon) ? site.icon : dealerSiteIconUrl} style={{}}/>
                        <div className='browser-icon'>
                          <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_delete.svg'}
                                    className='logo-action-content'
                                    onClick={() => this.removeImage(KEY_ICON)}
                                    beforeInjection={svg => svg.classList.add('logo-action')}/>
                          <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_import_export.svg'}
                                    className='logo-action-content'
                                    beforeInjection={svg => svg.classList.add('logo-action')}/>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>

        )
        break
      case 'color' :
        const popover = {
          position: 'absolute',
          zIndex: '2',
          left: 0,
          top: this.state.positionY
        }

        const colorOptions = (
          <div className='color-options-container'>
            <div className='color-options-border'>
              <div className='color-options'>
                {
                  presetTextColors.map((color, index) => {
                    return (
                      <div key={index} className='color-option'
                           style={{
                             backgroundColor: color,
                             border: '1px solid #EFEFEF'
                           }}
                           onClick={(event) => this.selectColor(color, event)}
                      />
                    )
                  })
                }
              </div>
              <div className='select-buttons'>
                <a className='btn color-cancel-button' onClick={this.closeColorOptions}>
                  Cancel
                </a>
                <a className='btn color-select-button' onClick={this.saveColorOptions}>
                  Select
                </a>
              </div>
            </div>
          </div>
        )
        let {showColorPalette, showColorOption, selectedColor} = this.state

        view = (
          <div>
            <div className='row-color-default'>
              <label className='label-color-default'>Default Colors</label>
              <div className='color-switch'>
                <label className='switch' onChange={() => this.handleColorDefault(color.default)}>
                  <input type='checkbox' checked={color.default}/>
                  <span className={color.default ? 'slider round' : 'slider round off'}/>
                  {
                    color.default ?
                      <span className='check-label-on'>ON</span> :
                      <span className='check-label-off'>OFF</span>
                  }
                </label>
              </div>
            </div>
            <div className='body-colors'>
              <div id='primary' className='row-color'>
                <label className='label-color'>Primary</label>
                <div className='color-button'
                     style={{
                       backgroundColor: color[KEY_COLOR_PRIMARY],
                       border: '1px solid ' + (color[KEY_COLOR_PRIMARY] === '#ffffff' ? '#999999' : color[KEY_COLOR_PRIMARY])
                     }}
                     onClick={(event) => this.handleColorPalette(KEY_COLOR_PRIMARY, event)}/>
              </div>
              <div id='secondary' className='row-color'>
                <label className='label-color'>Secondary</label>
                <div className='color-button'
                     style={{
                       backgroundColor: color[KEY_COLOR_SECONDARY],
                       border: '1px solid ' + (color[KEY_COLOR_SECONDARY] === '#ffffff' ? '#999999' : color[KEY_COLOR_SECONDARY])
                     }}
                     onClick={(event) => this.handleColorPalette(KEY_COLOR_SECONDARY, event)}/>
              </div>
              <div id='text' className='row-color'>
                <label className='label-color'>Home Sections Text</label>
                <div className='color-button'
                     style={{
                       backgroundColor: color[KEY_COLOR_HOME_SECTIONS_TEXT],
                       border: '1px solid ' + (color[KEY_COLOR_HOME_SECTIONS_TEXT] === '#ffffff' ? '#999999' : color[KEY_COLOR_HOME_SECTIONS_TEXT])
                     }}
                     onClick={(event) => this.handleColorOptions(color, KEY_COLOR_HOME_SECTIONS_TEXT, event)}/>
              </div>
              {
                (showColorOption && selectedColor === KEY_COLOR_HOME_SECTIONS_TEXT) ?
                  colorOptions : null
              }
              <div id='text' className='row-color'>
                <label className='label-color'>Navigation Bar Text</label>
                <div className='color-button'
                     style={{
                       backgroundColor: color[KEY_COLOR_NAVIGATION_BAR_TEXT],
                       border: '1px solid ' + (color[KEY_COLOR_NAVIGATION_BAR_TEXT] === '#ffffff' ? '#999999' : color[KEY_COLOR_NAVIGATION_BAR_TEXT])
                     }}
                     onClick={(event) => this.handleColorOptions(color, KEY_COLOR_NAVIGATION_BAR_TEXT, event)}/>
              </div>
              {
                (showColorOption && selectedColor === KEY_COLOR_NAVIGATION_BAR_TEXT) ?
                  colorOptions : null
              }
              <div id='background' className='row-color'>
                <label className='label-color'>Navigation Bar Background</label>
                <div className='color-button'
                     style={{
                       backgroundColor: color[KEY_COLOR_NAVIGATION_BAR_BACKGROUND],
                       border: '1px solid ' + (color[KEY_COLOR_NAVIGATION_BAR_BACKGROUND] === '#ffffff' ? '#999999' : color[KEY_COLOR_NAVIGATION_BAR_BACKGROUND])
                     }}
                     onClick={(event) => this.handleColorPalette([KEY_COLOR_NAVIGATION_BAR_BACKGROUND], event)}/>
              </div>
              {
                showColorPalette ?
                  <div id='colorPalette' className='color-palette' style={popover}>
                    <div style={cover} onClick={() => this.handleColorClose(selectedColor)}/>
                    <SketchPicker
                      color={color[selectedColor]}
                      presetColors={presetColors}
                      onChangeComplete={(color, event) => this.handleChangeColor(color, selectedColor)}
                    />
                  </div> : null
              }
            </div>
          </div>
        )
        break

      case 'typography' :
        view = (
          <div className='body-typography'>
            <DPSelectionDropDown
              className={'row-typo'}
              style={{width: '100%', float: 'left', padding: '15px 20px 0'}}
              labelStyle={{color: '#a4afb7', fontSize: 12}}
              label={'FONT FAMILY'}
              id={`fontFamily`}
              options={LIST_FONT_FAMILY}
              value={typography ? typography.title : ''}
              onClick={this.selectSource}
            />
          </div>
        )
        break

      case 'header' :
        const {selectedMenu, open, linkTab} = this.state
        const {pages, selectedPage} = this.props
        view = (
          <div className='body-header'>
            <div className='title-sec'>
              <label>Main Menu</label>
            </div>
            {
              header.menu.map((me, index) => {
                let pageFound
                const showDetails = selectedMenu === me.id
                if (showDetails) {
                  if (linkTab === LINK_TO_PAGE)
                    pageFound = pages.find(page => {
                      return page.id === me[linkTab]
                    })
                  else if (linkTab === LINK_TO_SECTION)
                    pageFound = pages[selectedPage].sections.find(sec => {
                      return sec.id === me[linkTab]
                    })
                }

                return (
                  <div key={index} className='row-menu'>
                    <div className={showDetails ? 'btn-message-dp border-link' : 'btn-message-dp'}
                         onClick={(event) => this.handleMenu('selectedMenu', me.id, event)}>
                      <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_menu.svg'} className='icon-menu-content'
                                beforeInjection={svg => svg.classList.add('icon-menu')}/>
                      <div className='col-sec'>
                        <label className='label-sec'>{me.displayValue}</label>
                      </div>
                    </div>
                    {
                      showDetails ?
                        <div className='row-menu-link'>
                          <div className='header-link'>
                            <label className={linkTab === LINK_TO_PAGE ? 'label-link link-active' : 'label-link'}
                                   onClick={() => this.setState({linkTab: LINK_TO_PAGE})}>
                              LINK TO PAGE
                            </label>
                            <label className={linkTab === LINK_TO_SECTION ? 'label-link link-active' : 'label-link'}
                                   onClick={() => this.setState({linkTab: LINK_TO_SECTION})}>
                              LINK TO SECTION
                            </label>
                            <label className='label-link'>LINK TO URL</label>
                          </div>
                          <div className='body-link'>
                            <div className='btn-menu-link' onClick={(event) => this.handlePopover(linkTab, event)}>
                              <button className='btn-message-dp'>
                                <span>{pageFound.displayValue}</span>
                                <i className='fa fa-chevron-down'/>
                              </button>
                            </div>
                            <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_delete.svg'}
                                      className='icon-action-content-delete'
                                      beforeInjection={svg => svg.classList.add('icon-delete')}/>
                          </div>
                        </div> : null
                    }
                  </div>
                )
              })
            }
            {
              header.socialMedia.map((social, index) => {
                return (
                  <div key={index} className='row-social'>
                    <div className='btn-message-dp' onClick={(event) => {
                    }}>
                      <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_menu.svg'} className='icon-menu-content'
                                beforeInjection={svg => svg.classList.add('icon-menu')}/>
                      <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_' + social.id + '.svg'}
                                className='icon-social-content' svgClassName={'icon-' + social.id}/>
                      <div className='col-social'>
                        <label className='label-social'>{social.url}</label>
                      </div>
                      <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_delete.svg'} className='icon-delete-content'
                                beforeInjection={svg => svg.classList.add('icon-action')}/>
                    </div>
                  </div>
                )
              })
            }
            {open ? this.renderPopover(this.handleLinkToPage) : null}
          </div>
        )
        break

      case 'banner' :
        const heightMain = 100
        const heightSecond = 100
        const {imgMain, validateImage, imgFinance} = this.state
        const styleDropMain = {
          height: heightMain,
          borderStyle: 'none'
        }

        const styleDropSecond = {
          height: heightSecond,
          borderStyle: 'none'
        }
        view = (
          <div className='body-banner'>
            <BodyUploadImage title={'Background Image'} id={'imgMain'} onDrop={this.onDrop} type={KEY_BACKGROUND_MAIN}
                             banner={banner.backgroundMain ? banner.backgroundMain : {}} classNameImg={'img-main'}
                             existSubTitle={false}
                             handleSlider={this.handleSlider} heightSecond={null} imgUpload={imgMain}
                             onChangeNumber={this.onChangeNumber}
                             removeImage={this.removeImage} styleDropZone={styleDropMain} validateImage={validateImage}
                             templateCode={templateCode}/>
            <BodyUploadImage title={'Overlapped Image'} id={'imgSecond'} onDrop={this.onDrop}
                             type={KEY_BACKGROUND_SECOND}
                             banner={banner.backgroundSecond ? banner.backgroundSecond : {}} classNameImg={'img-second'}
                             existSubTitle={true}
                             handleSlider={this.handleSlider} heightSecond={heightSecond}
                             imgUpload={banner.backgroundSecond.url !== '' ? banner.backgroundSecond.url : null}
                             onChangeNumber={this.onChangeNumber}
                             removeImage={this.removeImage} styleDropZone={styleDropSecond} validateImage={true}
                             templateCode={templateCode}/>
            <BodyUploadImage title={'Finance section background'} id={'imgFinance'} onDrop={this.onDrop}
                             type={KEY_BACKGROUND_FINANCE}
                             banner={banner.backgroundFinance ? banner.backgroundFinance : {}} classNameImg={'img-main'}
                             existSubTitle={false}
                             handleSlider={this.handleSlider} heightSecond={null} imgUpload={imgFinance}
                             onChangeNumber={this.onChangeNumber}
                             removeImage={this.removeImage} styleDropZone={styleDropMain} validateImage={true}
                             templateCode={templateCode}/>
            <div className='row-banner'>
              <label className='label-banner'>SLOGAN</label>
              <input id='slogan' className='form-control' value={banner.slogan} maxLength='45'
                     onChange={(e) => this.onChangeInput(KEY_SLOGAN, e)}/>
            </div>
          </div>
        )
        break

      case 'footer':
        view = (
          <div className='container-footer'>
            <div className='first-column'>
              <div className='container-label'>
                <label>Column 1: Information</label>
                <div className='sec-switch'>
                  <label className='switch' onChange={() => this.handleCheckSection('information')}>
                    <input type='checkbox' checked={footer.information.showSection}/>
                    <span className={footer.information.showSection ? 'slider round' : 'slider round off'}/>
                    {
                      footer.information.showSection ?
                        <span className='check-label-on'>ON</span> :
                        <span className='check-label-off'>OFF</span>
                    }
                  </label>
                </div>
              </div>
              <div className='row-sec'>
                <div id='header-information' className='btn-message-dp'>
                  <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_menu.svg'} className='icon-menu-content'
                            beforeInjection={svg => svg.classList.add('icon-menu')}/>
                  <div className='col-sec'>
                    <input id='header' className='form-control input-header-footer' value={footer['information'].header}
                           onChange={(event) => this.onChangeInputFooter(event, 'information')}/>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_eraser.svg'} className='icon-action-content-delete'
                              beforeInjection={svg => svg.classList.add('icon-action')}
                              onClick={() => this.cleanValuesFooter('information')}/>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_edit-pencil.svg'}
                              onClick={(event) => this.handleMenu('selectedFooterSection', 'information', event)}
                              className='icon-action-content'
                              beforeInjection={svg => svg.classList.add('icon-action')}/>
                  </div>
                </div>
                {('information' === selectedFooterSection) ? this.renderBodySectionFooter(footer, selectedFooterSection) : null}
              </div>
            </div>
            <div className='second-column'>
              <div className='container-label'>
                <label>Column 2: Links</label>
                <div className='sec-switch'>
                  <label className='switch' onChange={() => this.handleCheckSection('links')}>
                    <input type='checkbox' checked={footer.links.showSection}/>
                    <span className={footer.links.showSection ? 'slider round' : 'slider round off'}/>
                    {
                      footer.links.showSection ?
                        <span className='check-label-on'>ON</span> :
                        <span className='check-label-off'>OFF</span>
                    }
                  </label>
                </div>
              </div>
              <div className='row-sec'>
                <div id='header-information' className='btn-message-dp'>
                  <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_menu.svg'} className='icon-menu-content'
                            beforeInjection={svg => svg.classList.add('icon-menu')}/>
                  <div className='col-sec'>
                    <input id='header' className='form-control input-header-footer' value={footer['links'].header}
                           onChange={(event) => this.onChangeInputFooter(event, 'links')}/>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_eraser.svg'} className='icon-action-content-delete'
                              beforeInjection={svg => svg.classList.add('icon-action')}
                              onClick={() => this.cleanValuesFooter('links')}/>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_edit-pencil.svg'}
                              onClick={(event) => this.handleMenu('selectedFooterSection', 'links', event)}
                              className='icon-action-content'
                              beforeInjection={svg => svg.classList.add('icon-action')}/>
                  </div>
                </div>
                {('links' === selectedFooterSection) ? this.renderBodySectionFooter(footer, selectedFooterSection) : null}
              </div>
            </div>
            <div className='third-column'>
              <div className='container-label'>
                <label>Column 3: Subscription</label>
                <div className='sec-switch'>
                  <label className='switch' onChange={() => this.handleCheckSection('subscription')}>
                    <input type='checkbox' checked={footer.subscription.showSection}/>
                    <span className={footer.subscription.showSection ? 'slider round' : 'slider round off'}/>
                    {
                      footer.subscription.showSection ?
                        <span className='check-label-on'>ON</span> :
                        <span className='check-label-off'>OFF</span>
                    }
                  </label>
                </div>
              </div>
              <div className='row-sec'>
                <div id='header-information' className='btn-message-dp'>
                  <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_menu.svg'} className='icon-menu-content'
                            beforeInjection={svg => svg.classList.add('icon-menu')}/>
                  <div className='col-sec'>
                    <input id='header' className='form-control input-header-footer'
                           value={footer['subscription'].header}
                           onChange={(event) => this.onChangeInputFooter(event, 'subscription')}/>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_eraser.svg'} className='icon-action-content-delete'
                              beforeInjection={svg => svg.classList.add('icon-action')}
                              onClick={() => this.cleanValuesFooter('subscription')}/>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_edit-pencil.svg'}
                              onClick={(event) => this.handleMenu('selectedFooterSection', 'subscription', event)}
                              className='icon-action-content'
                              beforeInjection={svg => svg.classList.add('icon-action')}/>
                  </div>
                </div>
                {('subscription' === selectedFooterSection) ? this.renderBodySectionFooter(footer, selectedFooterSection) : null}
              </div>
            </div>
          </div>
        )
    }

    return view
  }

  handleSlider = (value, field, event) => {
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.banner[field].opacity = Math.round(value * 100) / 100
    onChange(generalSettingNew)
  }

  handlePopover = (key, event) => {
    event.preventDefault()
    const {pages, selectedPage} = this.props
    let options = []

    if (key === LINK_TO_PAGE) {
      pages.forEach((page) => {
        options.push({id: page.id, value: page.displayValue})
      })
    } else if (key === LINK_TO_SECTION) {
      pages[selectedPage].sections.forEach((sec) => {
        options.push({id: sec.id, value: sec.displayValue})
      })
    }

    let pointTo = getPosition(event.currentTarget)
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 25,
      offsetWidth: event.currentTarget.offsetWidth
    }
    this.setState({
      open: true,
      positionPopoverX: pointTo.x,
      positionPopoverY: pointTo.y,
      options: options,
      anchorEl: anchorEl
    })
  }


  handleRequestClose = () => {
    this.setState({open: false, openModalTypography: false})
  }

  handleLinkToPage(id) {
    let {selectedMenu, linkTab} = this.state
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    const indexOfMenu = findIndex(generalSettingNew.header.menu, function (me) {
      return me.id === selectedMenu
    })
    generalSettingNew.header.menu[indexOfMenu][linkTab] = id
    this.setState({open: false})
    onChange(generalSettingNew)
  }

  handleCheck(key) {
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    let value
    if (key === KEY_SOCIAL_CHECK) {
      value = generalSettingNew.socialMedia ? generalSettingNew.socialMedia[key] : false
      if (generalSettingNew.socialMedia)
        generalSettingNew.socialMedia[key] = !value
      else
        generalSettingNew.socialMedia = {[key]: !value}
    }
    onChange(generalSettingNew)
  }

  renderPopover(onSelect) {
    const {options, anchorEl, selectedMenu} = this.state
    let {generalSetting} = this.props
    const {header} = generalSetting

    let optionSelected = header.menu.find(value => {
      return value.id === selectedMenu
    })

    let left = 0
    let top = 0
    let offsetWidth = 0
    let values = []

    if (anchorEl) {
      top = anchorEl.y
      left = anchorEl.x
      offsetWidth = anchorEl.offsetWidth
    }

    const popover = {
      zIndex: 200,
      left: left,
      top: top,
      backgroundColor: '#fff',
      transition: '0.8s',
      position: 'absolute',
      width: offsetWidth,
      marginBottom: 0
    }

    options.forEach((option, index) => {
      if (option.id === optionSelected.linkToPage) {
        values.push(
          <li key={index} className='item-selected' onClick={(event) => onSelect(option.id)}>
            {option.value}
          </li>
        )
      } else {
        values.push(
          <li key={index} className='change-submenu-item' onClick={(event) => onSelect(option.id)}>
            {option.value}
          </li>
        )
      }
    })

    return (
      <div id='headerOptions' style={popover} className='popover-box-message'>
        <div style={cover} onClick={() => this.handleRequestClose()}/>
        <div className='popover-general-message'>
          <div className='popover-general-message-sub'>
            <u>
              {values}
            </u>
          </div>
        </div>
      </div>
    )
  }

  handleColorDefault(value) {
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    if (!value) {
      generalSettingNew.color[KEY_COLOR_PRIMARY] = COLOR_PRIMARY_DEFAULT
      generalSettingNew.color[KEY_COLOR_SECONDARY] = COLOR_SECONDARY_DEFAULT
      generalSettingNew.color[KEY_COLOR_HOME_SECTIONS_TEXT] = COLOR_TEXT_DEFAULT
      generalSettingNew.color[KEY_COLOR_NAVIGATION_BAR_BACKGROUND] = COLOR_BACKGROUND_DEFAULT
      generalSettingNew.color[KEY_COLOR_NAVIGATION_BAR_TEXT] = COLOR_NAVIGATION_DEFAULT
    }
    generalSettingNew.color.default = !value
    onChange(generalSettingNew)
  }

  handleColorClose(selectedColor) {
    let row = document.getElementById(selectedColor)
    if (row)
      row.style.height = 'auto'
    this.setState({showColorPalette: false, selectedColor: ''})
  }

  handleColorPalette(id, event) {
    let pointTo = getPosition(event.currentTarget)
    this.setState({showColorPalette: true, positionY: (pointTo.y - 285), selectedColor: id})
  }

  handleColorOptions(color, id, event) {
    const {changeColorOptionIsOpen} = this.props
    event.preventDefault()
    this.setState({showColorOption: true, selectedColor: id, previousColorSelected: color[id]})
    changeColorOptionIsOpen(color[id], id)
  }

  closeColorOptions(event) {
    event.preventDefault()
    let {changeColorOptionIsOpen} = this.props
    let {previousColorSelected} = this.state
    this.selectColor(previousColorSelected)
    this.setState({showColorOption: false, selectedColor: '', previousColorSelected: ''})
    changeColorOptionIsOpen('')
  }

  saveColorOptions(event) {
    event.preventDefault()
    let {changeColorOptionIsOpen} = this.props
    this.setState({showColorOption: false, selectedColor: '', previousColorSelected: ''})
    changeColorOptionIsOpen('')
  }

  selectColor(color, event) {
    //this.setState({showColorOption: true, selectedColor: id})
    let {selectedColor} = this.state
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.color[selectedColor] = color
    onChange(generalSettingNew)
  }

  onDrop = (file, field) => {
    const {generalSetting, onChangeFile, files, fields} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    if (field === KEY_BACKGROUND_MAIN) {
      this.setState({imgMain: URL.createObjectURL(file[0])}, () => {
        setTimeout(() => {
          const validateImage = this.validateImage(file)
          if (validateImage) {
            files.push(file[0])
            fields.push(field)
            generalSettingNew.banner[field].url = URL.createObjectURL(file[0])
            onChangeFile(generalSettingNew, files, fields)
          } else {
            this.setState({validateImage: validateImage})
          }
        }, 300)

      })
    } else {
      files.push(file[0])
      fields.push(field)
      if (field === KEY_LOGO || field === KEY_ICON) {
        generalSettingNew.site[field] = URL.createObjectURL(file[0])
      } else if (field === KEY_BACKGROUND_SECOND) {
        generalSettingNew.banner[field].url = URL.createObjectURL(file[0])
      } else if (field === KEY_BACKGROUND_FINANCE) {
        if (generalSettingNew.banner[field]) {
          generalSettingNew.banner[field].url = URL.createObjectURL(file[0])
        } else {
          generalSettingNew.banner = {
            ...generalSettingNew.banner,
            [KEY_BACKGROUND_FINANCE]: {
              url: URL.createObjectURL(file[0]),
              maxHeight: 900,
              opacity: 1
            }
          }
        }
        this.setState({imgFinance: URL.createObjectURL(file[0])})
      }

      onChangeFile(generalSettingNew, files, fields)
    }

  }

  removeImage = (field) => {
    const {generalSetting, onChangeFile, files, fields} = this.props
    const {validateImage} = this.state
    let generalSettingNew = cloneDeep(generalSetting)
    const index = fields.indexOf(field)
    files.splice(index, 1)
    fields.splice(index, 1)

    if (field === KEY_BACKGROUND_MAIN) {
      if (validateImage) {
        generalSettingNew.banner[field].url = ''
        this.setState(
          {imgMain: null},
          () => onChangeFile(generalSettingNew, files, fields)
        )
      } else {
        this.setState({imgMain: null, validateImage: true})
      }
    } else {
      if (field === KEY_LOGO || field === KEY_ICON) {
        generalSettingNew.site[field] = ''
      } else if (field === KEY_BACKGROUND_SECOND) {
        generalSettingNew.banner[field].url = ''
        if (generalSettingNew.banner[field].opacity)
          generalSettingNew.banner[field].opacity = 1
      } else if (field === KEY_BACKGROUND_FINANCE) {
        generalSettingNew.banner[field].url = ''
        this.setState({imgFinance: null})
      }
      onChangeFile(generalSettingNew, files, fields)
    }
  }

  onChangeInput(type, event) {
    const {generalSetting, onChange} = this.props
    const id = event.target.id
    let generalSettingNew = cloneDeep(generalSetting)
    if (type === KEY_SITE) {
      generalSettingNew[type][id] = event.target.value
    } else if (type === KEY_SLOGAN) {
      generalSettingNew.banner[type] = event.target.value
    }
    onChange(generalSettingNew)
  }

  onChangeInputFooter(event, type) {
    const {generalSetting, onChange} = this.props
    const key = event.target.id
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.footer[type][key] = event.target.value
    onChange(generalSettingNew)
  }

  handleMenu = (key, value, event) => {
    event.preventDefault()
    let selected = this.state[key]
    if (selected !== value)
      selected = value
    else
      selected = ''
    this.setState({[key]: selected})
  }

  onChangeNumber = (type, evt, modelValue, viewValue) => {
    const {generalSetting, onChange} = this.props
    let generalSettingNew = cloneDeep(generalSetting)
    const id = evt.target.id
    if (type === KEY_BACKGROUND_MAIN) {
      generalSettingNew.banner.backgroundMain[id] = viewValue
    } else if (type === KEY_BACKGROUND_SECOND) {
      generalSettingNew.banner.backgroundSecond[id] = viewValue
    } else if (type === KEY_BACKGROUND_FINANCE) {
      generalSettingNew.banner.backgroundFinance[id] = viewValue
    }
    onChange(generalSettingNew)
  }

  validateImage(file) {
    if (file && file.length > 0) {
      let imgMain = document.getElementById('imgMain')

      if (imgMain) {
        let imgMainHeight = imgMain.naturalHeight
        let imgMainWidth = imgMain.naturalWidth

        return (imgMainHeight >= 650)
      }

      return false
    }
  }

  loadImage = (props) => {
    const {generalSetting} = props
    const {imgMain, imgFinance} = this.state
    const newImgMain = generalSetting.banner.backgroundMain.url !== '' ? generalSetting.banner.backgroundMain.url : imgMain
    const newImgFinance = generalSetting.banner.backgroundFinance ? generalSetting.banner.backgroundFinance.url : imgFinance
    /*if (generalSetting.banner.backgroundMain.url !== "") {
      this.setState({imgMain: generalSetting.banner.backgroundMain.url});
    }*/
    this.setState({imgMain: newImgMain, imgFinance: newImgFinance})
  }

  onChangeSocialMedia = (event) => {
    event.stopPropagation()
    const {generalSetting, onChange} = this.props
    const id = event.target.id
    let generalSettingNew = cloneDeep(generalSetting)
    if (generalSettingNew.socialMedia) {
      generalSettingNew.socialMedia[id] = event.target.value
    } else {
      generalSettingNew.socialMedia = {[id]: event.target.value}
    }
    onChange(generalSettingNew)
  }

  renderBodySectionFooter(footer, id) {
    let body = null
    const {information} = footer
    switch (id) {
      case 'information':
        body = (
          <div className='body-sec-details'>
            <div className='row-sec-info'>
              <div className='row-sec-details'>
                <label className='label-sec label-line-footer'>line 1</label>
                <input id='title1' className='form-control input-left' value={information.title1}
                       onChange={(event) => this.onChangeInputFooter(event, 'information')}/>
                <input id='content1' className='form-control input-right' value={information.content1}
                       onChange={(event) => this.onChangeInputFooter(event, 'information')}/>
              </div>
              <div className='row-sec-details'>
                <label className='label-sec label-line-footer'>Line 2</label>
                <input id='title2' className='form-control input-left' value={information.title2}
                       onChange={(event) => this.onChangeInputFooter(event, 'information')}/>
                <input id='content2' className='form-control input-right' value={information.content2}
                       onChange={(event) => this.onChangeInputFooter(event, 'information')}/>
              </div>
            </div>
          </div>
        )
        break
      case 'links':
        const {links} = footer
        body = (
          <div className='body-sec-details'>
            <div className='row-sec-info'>
              <div className='first-bodySection'>
                <div className='row-sec-details'>
                  <label className='label-sec'>Title 1</label>
                  <input id='title1' className='form-control' value={links.title1}
                         onChange={(event) => this.onChangeInputFooter(event, 'links')}/>
                </div>
                <div className='row-sec-details'>
                  <label className='label-sec'>link 1</label>
                  <input id='link1' className='form-control' value={links.link1}
                         onChange={(event) => this.onChangeInputFooter(event, 'links')}/>
                </div>
              </div>
              <div className='second-bodySection'>
                <div className='row-sec-details'>
                  <label className='label-sec'>Title 2</label>
                  <input id='title2' className='form-control' value={links.title2}
                         onChange={(event) => this.onChangeInputFooter(event, 'links')}/>
                </div>
                <div className='row-sec-details'>
                  <label className='label-sec'>link 2</label>
                  <input id='link2' className='form-control' value={links.link2}
                         onChange={(event) => this.onChangeInputFooter(event, 'links')}/>
                </div>
              </div>
            </div>
          </div>
        )
        break
      case 'subscription':
        const {subscription} = footer
        body = (
          <div className='body-sec-details'>
            <div className='row-sec-info'>
              <div className='row-sec-details'>
                <label className='label-sec'>Bottom line text</label>
                <input id='lineText' className='form-control' value={subscription.lineText}
                       onChange={(event) => this.onChangeInputFooter(event, 'subscription')}/>
              </div>
            </div>
          </div>
        )
        break
    }
    return body
  }

  selectSource = (id) => {
    const {generalSetting, onChange} = this.props
    const sourceSelected = LIST_FONT_FAMILY.find(x => x.id === id)
    let generalSettingNew = cloneDeep(generalSetting)
    generalSettingNew.typography.title = sourceSelected.id
    this.setState({sourceSelected, openModalTypography: false})
    onChange(generalSettingNew)
  }

  render() {
    const {selectedSection, generalSetting, templateId, templateCode} = this.props

    return (
      this.renderSection(generalSetting, selectedSection, templateId, templateCode)
    )
  }
}