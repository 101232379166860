import {DPToggle} from '../../common/DPToggle'
import React from 'react'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../common/Constants'

require('./SocialNetWorkReview.scss')

const SocialNetworkReview = ({ pathIcon, text, showToggle, handleCheck, checked, id, ...props}) => {
  return(
    <div className="social-network-review-container">
      <div className="container-icon-text">
        {
          pathIcon &&
          <ReactSVG src={`${pathServer.PATH_IMG}icon/${pathIcon}`} className="content-icon-social"
                    beforeInjection={ svg => svg.classList.add('icon-social-network')}/>
        }
        <span className="text">{text ?? ''}</span>
      </div>
      {
        showToggle &&
        <DPToggle id={`toggle-${id}`} isChecked={checked} handleCheck={handleCheck}/>
      }
    </div>
  )
}

export default SocialNetworkReview