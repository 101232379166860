import React from 'react'
import PropTypes from 'prop-types'
import {ReactSVG} from 'react-svg'
import {pathServer} from './Constants'

require('./DPMessageInfo.scss')
const DPMessageInfo = ({iconPath, messageText, secondMessageText}) => {
  return(
    <div className='container-message-info'>
      <ReactSVG
        src={ `${pathServer.PATH_IMG}${iconPath ?? 'icon/ic_baseline_error.svg'}`}
        className={'icon-class'}
      />
      <div>
        {messageText ?? ''}
        {
          secondMessageText && <span className={'second-message'}>{` (${secondMessageText})`}</span>
        }
      </div>
    </div>
  )
}

DPMessageInfo.propTypes = {
  iconPath: PropTypes.string,
  messageText: PropTypes.string,
  secondMessageText: PropTypes.string
}

DPMessageInfo.defaultProps = {
  iconPath: 'icon/ic_baseline_error.svg',
  messageText: '',
  secondMessageText: null
}

export default  DPMessageInfo