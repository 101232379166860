import React from 'react'
import {MESSAGE_WITHOUT_FILE_TEMPLATES} from '../../../../Constants'
import DPTable from '../../../../common/DPTable'
import DPMessage from '../../../../common/DPMessage'

const FileTemplates = ({fileTemplates, dealer, goToFileTemplate}) => {

  return (
    <>
      <DPTable
        data={fileTemplates}
        rowTestId={'row-file-template'}
        config={
          {
            columns: [
              {id: 'name', label: 'Name'},
              {id: 'code', label: 'Code'},
              {id: 'status', label: 'Status'}
            ]
          }
        }
        renderColumn={
          (fileTemplate, columnConf, rowIndex) => {
            switch (columnConf.id) {
              case 'name':
                return fileTemplate.name
              case 'code':
                return fileTemplate.code
              case 'status':
                return (
                  <span
                    className={fileTemplate.active ? 'status-active' : 'status-inactive'}
                    style={{color: fileTemplate.active ? '#4ad991' : '#ff6565'}}
                  >
                      {fileTemplate.active ? 'Active' : 'Inactive'}
                  </span>
                )
            }
          }
        }
        onRowClick={fileTemplate => goToFileTemplate(fileTemplate.id, dealer)}
      />
      {
        fileTemplates.length === 0 ? (
          <DPMessage text={MESSAGE_WITHOUT_FILE_TEMPLATES}/>
        ) : null
      }
    </>
  )
}

export default FileTemplates