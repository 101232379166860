import React, {useState} from 'react'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

const Calendar = () => {
  const [date, changeDate] = useState(new Date())
  return(
    <React.Fragment>
      <header>Calendar</header>
      <section>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            orientation="portrait"
            variant="static"
            openTo="date"
            value={date}
            onChange={changeDate}
          />
        </MuiPickersUtilsProvider>
      </section>
    </React.Fragment>
  )
}

export default Calendar