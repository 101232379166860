import React, {forwardRef, Fragment, useImperativeHandle, useRef, useState, useEffect, createElement} from 'react'
import {pathServer, WIDTH_VIEW_TABLET} from '../../../../../common/Constants'
import GDGButton, {BUTTON_TYPE} from "../GDGButton";
import DPIcon, {Icons} from "../../../../common/DPIcon";
import DPOverlay from "../../../../common/DPOverlay";
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import {browserHistory} from "react-router";
import {Modal} from 'react-bootstrap'
import ContactUs from "./../contactUs/ContactUs";
import Drawer from '@material-ui/core/Drawer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DemoForm from '../demoRequest/DemoForm'
import ConfirmSent from '../demoRequest/ConfirmSent'
import {getServerUrl} from "../../../../Api";

require('./navigationBar.scss')

const navItems = [{
  label: "Solutions",
  menu: {
    title: 'Products',
    items: [{
      label: 'Customer management',
      link: '/products/customerManagement'
    }, {
      label: 'Reviews',
      link: '/products/reviews'
    }, {
      label: 'Team Tasks',
      link: '/products/todoTeams'
    }, {
      label: 'Website Builder',
      link: '/products/websiteBuilder'
    }, {
      label: 'End-to-end sales process',
      link: '/products/salesProcess'
    }, {
      label: 'Inventory management',
      link: '/products/inventoryManagement'
    }]
  }
}
// , {
//   label: "Company",
//   link: '/login'
// }
/*
, {
  label: "Pricing",
  link: '/pricing'
}
*/
, {
  label: "Contact Us",
  link:  '/contactus',
  modalBody: <ContactUs isModal={true} />
}]

const GDGLogoImage = () => (
  <div className={'logo-container'}>
    <a onClick={() => browserHistory.push({pathname: ''})}>
      <img className="logo" src={pathServer.PATH_IMG + "godealergo_white.png"} alt="logo"/>
    </a>
  </div>
)

const NavBarRightActions = ({handleRequestDemo}) => (
  <ul className={'navbar-action-list'}>
    <li>
      <GDGButton type={BUTTON_TYPE.GRAY} text={"Sign in"} onClick={() => window.open(`${getServerUrl()}login`, '_blank')}/>
    </li>
    <li>
      <GDGButton type={BUTTON_TYPE.BLUE} text={'Try it for free'} hoverText={'Request Demo'}
                 onClick={handleRequestDemo}/>
    </li>
  </ul>
)


const MenuOverlay = ({menu : {title, items}, show, onHide, anchorEl}) => {
  return (
    <DPOverlay anchorEl={anchorEl} show={show} onHide={onHide}>
        <p>{title}</p>
        <ul>
          {
            map(items, ({label, link}, idx) => (
              <li key={idx}>
                <a onClick={() => browserHistory.push({pathname: link})}>
                  {label}
                </a>
                <DPIcon icon={Icons.NAVIGATE_NEXT}/>
              </li>
            ))
          }
        </ul>
    </DPOverlay>
  )
}

const NavElement = ({props, label, menu, hasMenu, navItemEl, show, onItemDismiss}) => {
  const {onClick, ...rest} = props
  return (
    <>
      <li onClick={onClick} {...rest}>
        <p>{label}</p>
        {hasMenu && <DPIcon icon={Icons.KB_ARROW_DOWN_ROUNDED}/>}
      </li>
      {
        hasMenu &&
        <MenuOverlay
          menu={menu}
          anchorEl={navItemEl && navItemEl.current ? navItemEl.current : null}
          show={show}
          onHide={onItemDismiss}
        />
      }
    </>
  )
}

const NavDrawerElement = ({label, hasMenu, menu, props}) => {

  const {onClick, link, ...rest} = props

  return (
    hasMenu
      ? <Accordion>
        <AccordionSummary>
          <p className="menu-item-text">{label}</p>
          {<DPIcon icon={Icons.KB_ARROW_DOWN_ROUNDED}/>}
        </AccordionSummary>
        <AccordionDetails>
         <p className="title-menu">{menu.title ?? ""}</p>
          <ul>
            {
              map(menu.items, ({label,link}, idx) => (
                <li key={idx}>
                  <a onClick={() => browserHistory.push({pathname: link})}>
                    {label}
                  </a>
                  <DPIcon icon={Icons.NAVIGATE_NEXT}/>
                </li>
              ))
            }
          </ul>
        </AccordionDetails>
      </Accordion>
      : <p className="menu-item-text" onClick={onClick}>{label}</p>
  )
}

const NavItemElements = ({navItems, isDrawerNav}) => {
  const navItemEl = useRef(null)
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState(null)

  const onOpenModal = (modalBody) => {
    if (modalBody) {
      setModalBody(modalBody)
      setShowModal(true)
    }
  }

  const onCloseModal = () => {
    setShowModal(false)
    setModalBody(null)
  }


  const onItemClick = (e, hasMenu, link, modalBody) => {
    if (hasMenu) {
      navItemEl.current = e.currentTarget
      setShow(true)
    } else {
        if (isDrawerNav) {
        browserHistory.push({pathname: `/marketing${link}`})
      } else {
          if(modalBody){
            onOpenModal(modalBody)
          }else{
            browserHistory.push({pathname: `/marketing${link}`})
          }

      }
    }
  }

  const onItemDismiss = () => {
    navItemEl.current = null
    setShow(false)
  }

  return (
    <>
      {
        map(navItems, ({label, menu, link, modalBody}, idx) => {
          const hasMenu = !isEmpty(menu);
          const props = hasMenu ? {
            className: 'nav-item-solutions',
            style: {marginRight: '15px', marginLeft: '15px', display: 'flex'},
            onClick: (e) => onItemClick(e, hasMenu, link, modalBody),
            link
          } : {
            style: {marginRight: '15px', marginLeft: '15px', whiteSpace: 'nowrap'},
            onClick: (e) => onItemClick(e, hasMenu, link, modalBody),
            link
          }
          return (
            isDrawerNav
              ? <NavDrawerElement
                  key={idx+'-drawerNavItem'}
                  label={label}
                  menu={menu}
                  props={props}
                  hasMenu={hasMenu}/>
              :
              <NavElement
                  key={idx+'-navItem'}
                  label={label}
                  menu={menu}
                  hasMenu={hasMenu}
                  props={props}
                  onItemDismiss={onItemDismiss}
                  navItemEl={navItemEl}
                  show={show}/>
          )
        })
      }
      <Modal show={showModal} onHide={onCloseModal} className={'container-modal'}>
        <Modal.Body>
          {modalBody && React.cloneElement(modalBody, {closeModal: () => onCloseModal()})}
        </Modal.Body>
      </Modal>
    </>
  )
}

const NavItems = ({isDrawerNav = false}) => {
  const Wrapper = isDrawerNav ? 'div' : 'ul'

  return (
    <div className={'nav-items-container'}>
      <Wrapper className={'nav-items'}>
        <NavItemElements navItems={navItems} isDrawerNav={isDrawerNav}/>
      </Wrapper>
    </div>
  )
}

const NavBarDrawer = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={'nav-drawer'} key={"right"}>
      <DPIcon icon={Icons.HAMBURGER_ICON} onClick={() => setIsOpen(true)}/>
      <Drawer className={'gdg-drawer'} anchor={"right"} open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="container-icon-close">
          <DPIcon icon={Icons.CANCEL} onClick={() => setIsOpen(false)}/>
        </div>
        <GDGLogoImage/>
        <div className={'home-nav-drawer'}>
          <NavItems isDrawerNav={true}/>
        </div>
        <NavBarRightActions handleRequestDemo={() => browserHistory.push({pathname: '/marketing/requestDemo'})}/>
      </Drawer>
    </div>
  )
}

const NavigationBar =  forwardRef((props, ref) => {
  const [showBlurModal, setShowBlurModal] = useState(false)
  const [modalBody, setModalBody] = useState(<></>)

  const showModalSent = () => {
    setModalBody(<ConfirmSent onClickButton={() => setShowBlurModal(false)}/>)
  }

  useImperativeHandle(ref, () => ({
    handleRequestDemo
  }));

  const handleRequestDemo = (plan) => {
    if(window.innerWidth > 992){
      setModalBody(<DemoForm setShowBlurModal={setShowBlurModal} showConfirmSent={showModalSent} plan={plan || ''}/>)
      setShowBlurModal(true)
    }else{
      browserHistory.push({pathname: '/marketing/requestDemo', state: {plan: plan || ''}})
    }
  }

  return (
    <div className={'home-navigation'}>
      <div className={'home-navigation-container'}>
        <GDGLogoImage/>
        <NavItems/>
        <div className={'navbar-right-actions'}>
          <NavBarRightActions handleRequestDemo={() => handleRequestDemo()}/>
          <NavBarDrawer/>
        </div>
      </div>
      {
        showBlurModal &&
        <Modal show={showBlurModal} onHide={() => setShowBlurModal(false)} className={'container-modal request-demo-modal'}>
          <Modal.Body>
            {modalBody}
          </Modal.Body>
        </Modal>
      }
    </div>
  )
}
)
export default NavigationBar
