import React, {useState} from 'react'
import DemoForm from './DemoForm'
import ConfirmSent from './ConfirmSent'
import {browserHistory} from 'react-router'
require('./RequestDemoPage.scss')

const RequestDemoPage = (props) => {
  const [showConfirmSent, setShowConfirmSent] = useState(false)
  const {plan} = props.location.state || {}
  window.scrollTo(0, 0)

  return(
    <div className='container-request-demo'>
      {
        !showConfirmSent ?
          <DemoForm heightOfSelect={36} showConfirmSent={() => setShowConfirmSent(true)} plan={plan}/> :
          <ConfirmSent onClickButton={() => browserHistory.push({pathname: ''})}/>
      }
    </div>
  )
}

export default RequestDemoPage