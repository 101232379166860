import React, {useEffect, useState} from 'react'
import DPDocument from './DPDocument'
import isEmpty from 'lodash/isEmpty'

const TYPE_DOCUMENTS = {
  TITLE : 'title',
  EMISSION : 'emission',
  OTHER : 'other'
}

const InventoryDocument = ({ documents = [], addDocument, deleteDocument}) => {

  const [ hasTitleDocument, setHasTitleDocument ] = useState(false)
  const [ hasEmissionDocument, setHasEmissionDocument ] = useState(false)
  const [ hasOtherDocument, setHasOtherDocument ] = useState(false)
  const [ allDocuments, setAllDocuments ] = useState({})


  const onSwitch = (documentType, newValue = false) => {
    if(documentType === TYPE_DOCUMENTS.TITLE){
      setHasTitleDocument(newValue)
    } else if(documentType === TYPE_DOCUMENTS.EMISSION){
      setHasEmissionDocument(newValue)
    } else {
      setHasOtherDocument(newValue)
    }
  }

  const onDelete = (documentsByType, deleteDocumentIds, documentType) => {
    allDocuments[documentType] = documentsByType
    deleteDocument([...allDocuments[TYPE_DOCUMENTS.TITLE],
      ...allDocuments[TYPE_DOCUMENTS.EMISSION],
      ...allDocuments[TYPE_DOCUMENTS.OTHER]], deleteDocumentIds)
  }

  const onDrop = (documentsByType, documentType) => {
    allDocuments[documentType] = documentsByType
    addDocument([...allDocuments[TYPE_DOCUMENTS.TITLE],...allDocuments[TYPE_DOCUMENTS.EMISSION],
      ...allDocuments[TYPE_DOCUMENTS.OTHER]])
  }

  useEffect(() => {
    allDocuments[TYPE_DOCUMENTS.TITLE] = documents.filter(doc => doc.documentType === TYPE_DOCUMENTS.TITLE)
    setHasTitleDocument(!isEmpty(allDocuments[TYPE_DOCUMENTS.TITLE]))
    allDocuments[TYPE_DOCUMENTS.EMISSION] = documents.filter(doc => doc.documentType === TYPE_DOCUMENTS.EMISSION)
    setHasEmissionDocument(!isEmpty(allDocuments[TYPE_DOCUMENTS.EMISSION]))
    allDocuments[TYPE_DOCUMENTS.OTHER] = documents.filter(doc => doc.documentType === TYPE_DOCUMENTS.OTHER)
    setHasOtherDocument(!isEmpty(allDocuments[TYPE_DOCUMENTS.OTHER]))
    setAllDocuments({...allDocuments})
  }, [documents])

  return (
    <div className='body-documents'>
      <DPDocument
        documents={allDocuments[TYPE_DOCUMENTS.TITLE]}
        hasDocument={hasTitleDocument}
        documentType={'title'}
        label={'Do you have the Title?'}
        onDrop={onDrop}
        onDelete={onDelete}
        onSwitch={onSwitch}
      />
      <DPDocument
        documents={allDocuments[TYPE_DOCUMENTS.EMISSION]}
        hasDocument={hasEmissionDocument}
        documentType={'emission'}
        label={'Do you have the Emissions paperwork?'}
        onDrop={onDrop}
        onDelete={onDelete}
        onSwitch={onSwitch}
      />
      <DPDocument
        documents={allDocuments[TYPE_DOCUMENTS.OTHER]}
        hasDocument={hasOtherDocument}
        documentType={'other'}
        label={'Do you have any other document?'}
        onDrop={onDrop}
        onDelete={onDelete}
        onSwitch={onSwitch}
      />
    </div>
  )

}

export default InventoryDocument