import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {findIndex, clone} from 'lodash'
import {saveRoleApi} from '../../../Api'
import {validateError} from '../../util/Utils'
import {connect} from 'react-redux'
import {setUserRoles} from '../../../actions'
import ListPermission from './Permission'
import isEmpty from 'lodash/isEmpty'
import {
  ADMIN_DASHBOARD_PERMISSION_CODE,
  SALES_DASHBOARD_PERMISSION_CODE,
  TECH_DASHBOARD_PERMISSION_CODE
} from '../../../Constants'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'

function getError(field) {
  let error = {};
  for (let key in field) {
    if (field[key] === '') {
      error[key] = true;
    }
  }
  return error;
}

class Rol extends Component {
  constructor() {
    super();
    this.handleCheck        = this.handleCheck.bind(this);
    this.handleChange       = this.handleChange.bind(this);
    this.handleResize       = this.handleResize.bind(this);
    this.handleCheckAll     = this.handleCheckAll.bind(this);
    this.handleCheck        = this.handleCheck.bind(this);
    this.save               = this.save.bind(this);
    this.state = {
      permissions:[],
      role: null,
      isLoading:false,
      widthPage: window.innerWidth,
      error: {},
      selectedPermissions: [],
      isCheckedAll: false,
      rolName: '',
      disabledDashboardOptions: []
    }
  }

  componentWillMount() {
    const {role,permissions} = this.props;
    const newRole = {
      id: role.id,
      name: role.name,
      description: role.description,
    };
    const selectedPermissions = role.permissions;
    this.setState({role: newRole, selectedPermissions:selectedPermissions,permissions:permissions,rolName:role.name})
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleChange(e) {
    let {role,error} = this.state;
    role[e.target.name] = e.target.value;
    error[e.target.name] = false;
    this.setState({role:role,error:error})
  }

  handleResize(){
    this.setState({widthPage: window.innerWidth})
  }

  handleCheckAll(){
    let {isCheckedAll,permissions, disabledDashboardOptions} = this.state;
    let selectedPermissions;
    const notAdminDashboards = !isCheckedAll ? [SALES_DASHBOARD_PERMISSION_CODE, TECH_DASHBOARD_PERMISSION_CODE] : []
    if(!isCheckedAll){
      selectedPermissions = clone(permissions.map(per => { if(!notAdminDashboards.includes(per.code)) return per.id }))
      selectedPermissions.push.apply(selectedPermissions,clone(permissions.flatMap(per => per.subPermissions).map(per => { return per.id })))
    }else{
      selectedPermissions = [];
    }
    this.setState({isCheckedAll: !isCheckedAll,selectedPermissions, disabledDashboardOptions: notAdminDashboards})
  }

  handleDashboardCheck = (permission) => {
    const { selectedPermissions } = this.state

    switch (permission.code){
      case ADMIN_DASHBOARD_PERMISSION_CODE:
        if(selectedPermissions && selectedPermissions.includes(ADMIN_DASHBOARD_PERMISSION_CODE))
          return []
        else
         return [SALES_DASHBOARD_PERMISSION_CODE, TECH_DASHBOARD_PERMISSION_CODE]
      case SALES_DASHBOARD_PERMISSION_CODE:
        if(selectedPermissions && selectedPermissions.includes(SALES_DASHBOARD_PERMISSION_CODE))
          return []
        else
          return [ADMIN_DASHBOARD_PERMISSION_CODE, TECH_DASHBOARD_PERMISSION_CODE]
      case TECH_DASHBOARD_PERMISSION_CODE:
        if(selectedPermissions && selectedPermissions.includes(TECH_DASHBOARD_PERMISSION_CODE))
          return []
        else
        return [SALES_DASHBOARD_PERMISSION_CODE, ADMIN_DASHBOARD_PERMISSION_CODE]
    }
  }

  handleCheck(permission){
    let {selectedPermissions, disabledDashboardOptions} = this.state;
    disabledDashboardOptions = this.handleDashboardCheck(permission)

    const indexFound = findIndex(selectedPermissions, (per) => { return per === permission.id})
    if(indexFound !== -1){
      selectedPermissions.splice(indexFound,1);
      if(!isEmpty(permission.subPermissions)){
        permission.subPermissions.forEach(subPermission => {
          const indexFound = findIndex(selectedPermissions, (per) => { return per === subPermission.id})
          if(indexFound !== -1){
            selectedPermissions.splice(indexFound,1)
          }
        })
      }
    }else{
      selectedPermissions.push(permission.id)
      if(permission.parentPermission){
        const indexFound = findIndex(selectedPermissions, (per) => { return per === permission.parentPermission})
        if(indexFound === -1){
          selectedPermissions.push(permission.parentPermission)
        }
      }
    }
    this.setState({...selectedPermissions, disabledDashboardOptions: disabledDashboardOptions })
  }

  save(){
    const {role,selectedPermissions, permissions} = this.state;
    const {save, userRoles} = this.props;
    //let error = {...this.state.error};
    let error = getError({name:role.name, description: role.description});
    if(!validateError(error)){
      saveRoleApi({role:role, permissionIds: selectedPermissions.filter(x => x !== undefined)}).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          if(userRoles[role.id]){
            let permissionsByUserRol = clone(permissions.filter(permission => selectedPermissions.includes(permission.id)))
            permissionsByUserRol.push.apply(permissionsByUserRol,clone(permissions.flatMap(per => per.subPermissions).map(per => { return per }).filter(permission => selectedPermissions.includes(permission.id))))
            userRoles[role.id].permissions = permissionsByUserRol
            this.props.setUserRoles(userRoles)
          }
          save();
        }else{
          const ex = {status: responseCode, message: responseMessage}
          console.error("error:", ex);
        }
      })
    }else{
      this.setState({error:error})
    }
  }

  render() {
    const {role,isLoading,selectedPermissions,permissions,isCheckedAll,error,rolName, disabledDashboardOptions} = this.state;
    let {showModal, close} = this.props;

    if(!role) return null;

    return (
      <div>
        <Modal show={showModal} className="rol-permission-modal" backdropClassName="backdrop-setting-rol">
          <Modal.Body>
            {
              isLoading ?
                <div className="loader-container">
                  <div className="loader"/>
                </div> : null
            }
            <div className="user-modal" style={isLoading ? {opacity:0.4} : null}>
              <img src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon" onClick={close}/>
              <div className="rol-modal-body">
                <div className="rol-title">
                  <label>
                    {role.id ? rolName : 'New Name'}
                  </label>
                </div>
                <div className="left">
                  <div className="col100">
                    <div className="rol-label">
                      <label>Name</label>
                    </div>
                    <div className="input-rol-name">
                      <input type="text" className={error.name ? "form-control has-error" : "form-control"} name="name"
                             defaultValue={role.name} placeholder="Name" onChange={this.handleChange}/>
                      {
                        error.name ?
                          <span className="label-error">This field must be filled.</span> : null
                      }
                    </div>
                  </div>
                  <div className="col100">
                    <div className="rol-label">
                      <label>Description</label>
                    </div>
                    <div className="input-rol-desc">
                      <textarea className={error.description ? "form-control has-error" : "form-control"} name="description"
                             defaultValue={role.description} placeholder="Type here" onChange={this.handleChange}/>
                      {
                        error.description ?
                          <span className="label-error">This field must be filled.</span> : null
                      }
                    </div>
                  </div>
                  <div className="col100 rol-permission-container">
                    <div className="col100 rol-permission">
                      <label className="col100 title">Permissions</label>
                      <label className="col100 sub-title">(Mark permissions from the list)</label>
                    </div>
                    <div>
                      {
                        selectedPermissions.map(permission => {
                          let permissionFound = permissions.find(per => { return per.id === permission})
                          if(!permissionFound){
                            permissionFound = permissions.flatMap(p => p.subPermissions).find(per => { return per.id === permission})
                          }
                          return(
                            <label key={permission} className="permission-selected">{permissionFound?.name}</label>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>

                <div className="right">
                  <div className="col100 permission-all-container">
                    <label className="permission-list">Permissions List</label>
                    <div className="content-check">
                      <label className="container-check">
                        <input type="checkbox" checked={isCheckedAll} onChange={this.handleCheckAll}/>
                        <span className="checkmark" style={{border: "1.4px #4695d4 solid"}}/>
                        {
                          isCheckedAll ? <i className="fa fa-check i-check"/> : null
                        }
                      </label>
                      <label className="label-check-image-main">Select all</label>
                    </div>
                  </div>
                  {
                    permissions.length > 0 ?
                      <ListPermission permissions={permissions}
                                      disabledDashboardOptions={disabledDashboardOptions}
                                      selectedPermissions={selectedPermissions}
                                      handleCheck={this.handleCheck}/> : null
                  }
                </div>
                <div className="user-modal-pie">
                  <div className="user-modal-button">
                    <a className="btn-save" onClick={this.save}>
                      Save
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userRoles: state.userRoles
  }
};

export default connect(mapStateToProps,{setUserRoles})(Rol)