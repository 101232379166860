import React from 'react'
import {DragSource} from 'react-dnd'
import {clone, assign} from 'lodash'

const formControlSource = {
  beginDrag(props) {
    return props.form
  },
  endDrag(props, monitor, component) {
    const {updateItemStyle} = props;
    if(updateItemStyle)
      updateItemStyle()
  }
};

const controlSourceType = (props) => { return props.status};

const {Component} = React;

class ElementDragDrop extends Component {

  constructor(){
    super();
  }

  render(){
    const {isDragging,connectDragSource, value, style, labelDrag,styleDrag,classDrag} = this.props;
    let newStyle = style ? clone(style) : {};

    if(isDragging && labelDrag){
      newStyle = assign(newStyle,styleDrag);
    }

    return connectDragSource(
      <div className={classDrag} style={newStyle}>
        {
          isDragging ?
            <div className="label-drag">
              {
                labelDrag ?
                  <label>{labelDrag}</label>
                  : null
              }
              <div style={{opacity:0}}>
                {value}
              </div>
            </div>: value
        }
      </div>
    );
  }

}

ElementDragDrop = DragSource(controlSourceType, formControlSource,  (connect, monitor) =>{
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
})(ElementDragDrop);

export default ElementDragDrop;