import {ReactSVG} from 'react-svg'
import DPButton from '../admin/common/DPButton'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {pathServer} from './Constants'

require('./DPButtonText.scss')

const DPButtonText = (props) => {
  const {buttonClass, iconPath, textButton, width, height, onClick, textColor, fontSizeText, classIcon, id} =  props

  return(
    <DPButton id={id} className={classnames( 'button-text', buttonClass)} width={width} heigth={height} onClick={onClick}
              style={{color: textColor, display: 'flex', border: 'none', boxShadow: 'none'}}>
      {
        iconPath &&
        <ReactSVG
          src={`${pathServer.PATH_IMG}icon/${iconPath}`}
          className={classnames(['icon-class', classIcon ])}
        />
      }
      <span style={{zIndex: '4', color: textColor, fontSize: fontSizeText}}>{textButton}</span>
    </DPButton>
  )
};

DPButtonText.defaultProps = {
  background: '#FFF',
  textColor: '#2194F1',
  width: 'auto',
  height: 'auto',
  textButton: 'Next',
  fontSizeText: '10px',
  id: 'buttonText'
}

DPButtonText.propTypes = {
  background: PropTypes.string,
  textColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  iconPath: PropTypes.string,
  buttonClass: PropTypes.string,
  textButton: PropTypes.string,
  fontSizeText: PropTypes.string,
  classIcon: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
}

export default DPButtonText