import React, {Component} from 'react'
import { CAR_IMAGE_HEIGHT_PERCENT, pathServer, SUCCESSFUL_CODE } from '../../../common/Constants'
import {ReactSVG} from 'react-svg'
import Checkbox from 'material-ui/Checkbox'
import {loadCheckListBySaleId, saveSaleCheck} from '../../Api'
import Loader from '../../../common/UI/Loader.jsx'
import {elementCheck, elementUnCheck} from '../../../common/ComponentUtils'

export default class CustomerCheckList extends Component {

  constructor() {
    super();
    this.state = {
      data: null,
      isLoadingInitial: false,
      isLoading: false
    }
  }

  async componentWillMount() {
    const { saleId } = this.props;
    this.setState({ isLoadingInitial: true });
    const response = await loadCheckListBySaleId(saleId)
    const { responseCode, responseMessage, data } = response
    if (responseCode === SUCCESSFUL_CODE) {
      this.setState({ data: data, isLoadingInitial: false });
    } else {
      this.setState({ isLoadingInitial: false });
      console.error(responseMessage);
    }
  }

  async handleCheck(evt) {
    const { data } = this.state;
    const { saleId } = this.props;
    const checked = evt.target.checked;
    const checklistId = evt.target.id;
    let checkData = { checklistId: checklistId, checked: checked };
    this.setState({ isLoading: true });
    const response = await saveSaleCheck(saleId, checkData)
    const { responseCode, responseMessage, data: result } = response
    if (responseCode === SUCCESSFUL_CODE) {
      const indexSaleCheckList = data.saleChecklist.findIndex(element => {
        return element.saleChecklistId === result.saleCheckListId;
      });
      if (indexSaleCheckList !== -1)
        data.saleChecklist[indexSaleCheckList].checked = checked;
      else
        data.saleChecklist.push({
          saleChecklistId: result.saleCheckListId,
          checklistId: checklistId,
          checked: checked
        });
      this.setState({ data, isLoading: false })
    } else {
      console.error(responseMessage);
      this.setState({ isLoading: false })
    }
  }

  render() {
    const {data, isLoadingInitial, isLoading} = this.state;
    if (!data) return null;
    const {saleCar, saleChecklist, checklist} = data;
    let height ;
    const element = window.document.getElementById("containerCheckListLeft");
    if (element)
      height = element.offsetWidth * CAR_IMAGE_HEIGHT_PERCENT;

    return (
      <div className="box-check-list">
        {
          isLoadingInitial ?
            <div className="loader-container">
              <div className="loader"/>
            </div> :
            <div>
              <div id="containerCheckListLeft" className="container-left">
                {
                  isLoading ?
                    <Loader/> : null
                }
                <div className="container-car-info">
                  <div className="container-title">
                    <label>{`${saleCar.make} ${saleCar.model} ${saleCar.year}`}</label>
                  </div>
                  <div className="container-image">
                    <img style={{height: height}} src={saleCar.carImage}/>
                  </div>
                  <div className="container-price">
                    <label>{`$${saleCar.suggestedPrice}`}</label>
                  </div>
                </div>
                <div className="container-selling">
                  <ReactSVG src={pathServer.PATH_IMG + "icon/getting_finance.svg"}
                            beforeInjection={ svg => svg.classList.add('svg-icon-getting-finance')}
                            className="icon-getting-finance"/>
                  <label>{saleCar.typeFinance}</label>
                </div>
              </div>
              <div className="container-right">
                {
                  checklist.map((element) => {
                    const index = saleChecklist.findIndex((saleCheck) => {
                      return element.id === saleCheck.checklistId;
                    });
                    const checkedItem = (index !== -1) && (saleChecklist[index].checked === true)
                    return (
                      <div key={element.order} className="content-check">
                        <Checkbox checked={checkedItem} id={element.id}
                                  onCheck={this.handleCheck.bind(this)}
                                  checkedIcon={elementCheck()} uncheckedIcon={elementUnCheck()}
                                  iconStyle={{borderColor: "#777", marginRight: 8}}
                                  disabled={false}
                                  className="checkbox"
                                  required={true}
                                  name={"name"}/>
                        <div className="container-label">
                          <p className="check-description">{element.description}</p>
                          {
                            element.required ?
                              <p className="check-required">*</p> : null
                          }
                        </div>
                      </div>
                    )
                  })
                }
                {
                  checklist.length > 0 ?
                    <div className="container-required-items">
                      <label className="required-items">*Required items</label>
                    </div> : null
                }
              </div>
            </div>

        }
      </div>
    )
  }
}