import React from 'react'
import GDGButton, {BUTTON_TYPE} from "./GDGButton";
import {EmptyFunc, pathServer} from '../../../../common/Constants'
import SplitSection from '../components/SplitSection'

require('./reviewAndReferralSection.scss')

const ReviewAndReferralSection = ({demoRequest = EmptyFunc}) => {
  return (
    <SplitSection
      className={'reviews-and-referrals default-section-padding'}
      leftSection={
        <div>
          <p className={'title-font'} dangerouslySetInnerHTML={{
            __html: 'Reviews and </br>' +
              ' Referral ' +
              ' campaigns<span class="asterisk">*</span>'
          }}/>
          <p className={'regular-font'} dangerouslySetInnerHTML={{
            __html: 'Our platform allows you to manage your online ' +
              'presence and how your future customer perceive '  +
              'your brand. Get ahead of the competition and allow ' +
              'your reviews to work for you.'
          }}/>
          <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
          <p className={'small-blue-font'}>{'*Referrals feature coming soon'}</p>
        </div>
      }
      rightSection={
        <div>
          <img alt={'img'} src={pathServer.PATH_IMG + 'newHomePage/reviews-referral-home.png'}/>
        </div>
      }
    />
  )
}

export default ReviewAndReferralSection
