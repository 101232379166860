import React from 'react'
import {ReactSVG} from 'react-svg'
import PropTypes from 'prop-types'
import {pathServer} from '../../../../../common/Constants'

export const HeaderSectionCustomer = ({id, isOpen, accordionToggle, title, showMessage, message, onAddNew}) => {
  return (
    <div className="section-title">
      <div className="toggle-circle" onClick={() => accordionToggle(id)}>
        {
          !isOpen ?
            <div>
              <div className="icon-toggle-horizontal-line"/>
              <div className="icon-toggle-vertical-line"/>
            </div>
            :
            <div className="icon-toggle-horizontal-line"/>
        }
      </div>
      <h5 className="section-title-label">{title}</h5>
      {
        showMessage ?
          <span className="label-message-to-save">{message}</span> : null
      }
      {
        onAddNew
          ? <ReactSVG
              src={`${pathServer.PATH_IMG}icon/ic_add.svg`}
              beforeInjection={ svg => svg.classList.add('icon-add-svg')}
              className="icon-add-file-template"
              onClick={onAddNew}
            />
          : null
      }
    </div>
  )
};

HeaderSectionCustomer.defaultProps = {
  accordionToggle: null,
  onAddNew: null,
  showMessage: false,
  message: '',
}

HeaderSectionCustomer.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  accordionToggle: PropTypes.func.isRequired,
  onAddNew: PropTypes.func,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
}