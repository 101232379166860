import React, {Component} from 'react'
import {
  CAR_STATUS_PUBLIC_CODE,
  CAR_STATUS_CODE_ACQUIRED,
  CAR_STATUS_CODE_INSPECT_REPAIR,
  CAR_STATUS_CODE_HOLD,
  CAR_STATUS_CODE_COMING_SOON, carStatus, INVENTORY_DELETE_PASSWORD_CODE
} from '../../../Constants'
import {ReactSVG} from 'react-svg'
import {Popover} from '../../util/ComponentUtils'
import {findIndex} from 'lodash'
import Checkbox from 'material-ui/Checkbox'
import {
  loadAttributeValuesByAttributeCode,
  loadDealerConfigurationByCodes,
  saveDealerConfiguration
} from '../../../Api'
import {connect} from 'react-redux'
import {getAnchorEl} from '../../util/Utils'
import {pathServer, ATTRIBUTE_CODE_CAR_STATUS, SUCCESSFUL_CODE} from '../../../../common/Constants'

const optionStatus = [{id: "acquired", displayValue: "Acquired"}, {id: "inspectRepair", displayValue: "Inspect / Repair"},
  {id: "hold", displayValue: "Hold"}, {id: "comingSoon", displayValue: "Coming Soon"}];

const elementCheck = (
  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_box.svg"} beforeInjection={ svg => svg.classList.add("icon-check")}/>
);

const elementUnCheck = (
  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_uncheck_box.svg"} beforeInjection={ svg => svg.classList.add("icon-uncheck")}/>
);

class InventorySetting extends Component {
  constructor() {
    super();
    this.handlePopover        = this.handlePopover.bind(this);
    this.closePopover         = this.closePopover.bind(this);
    this.onSwitch             = this.onSwitch.bind(this);
    this.onChangePassword     = this.onChangePassword.bind(this);
    this.saveDeletePassword   = this.saveDeletePassword.bind(this);
    this.state = {
      showPopover: false,
      selectedStatus: null,
      optionStatus:null,
      showSoldImage:false,
      password:"",
      confirmPassword:"",
      passwordDelete:null,
      isEmptyPassword:false,
      isEmptyConfirmPassword:false,
      notMatchPassword:false,
      errorMessage:null
    }
  }

  componentWillMount(){
    const {attributeValuesToCarStatus} = this.props;
    if(!attributeValuesToCarStatus){
      loadAttributeValuesByAttributeCode(ATTRIBUTE_CODE_CAR_STATUS).then(response => {
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE) {
          const attributeValues = data.attributeValues;
          const optionStatus = attributeValues.filter(attributeValue => {return attributeValue.code === CAR_STATUS_CODE_ACQUIRED ||
            attributeValue.code === CAR_STATUS_CODE_INSPECT_REPAIR || attributeValue.code === CAR_STATUS_CODE_HOLD ||
            attributeValue.code === CAR_STATUS_CODE_COMING_SOON
          });
          this.setState({optionStatus});
        } else {
          console.error(responseMessage)
        }
      });
    }else{
      const optionStatus = attributeValuesToCarStatus.filter(attributeValue => {return attributeValue.code === CAR_STATUS_CODE_ACQUIRED ||
        attributeValue.code === CAR_STATUS_CODE_INSPECT_REPAIR || attributeValue.code === CAR_STATUS_CODE_HOLD ||
        attributeValue.code === CAR_STATUS_CODE_COMING_SOON
      });
      this.setState({optionStatus});
    }

    loadDealerConfigurationByCodes(`${CAR_STATUS_PUBLIC_CODE},${INVENTORY_DELETE_PASSWORD_CODE}`).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        const {dealerConfigurations} = data;
        let dealerConfigurationCarStatus = dealerConfigurations.find(x => x.code === CAR_STATUS_PUBLIC_CODE);
        if(dealerConfigurationCarStatus){
          const selectedStatus = JSON.parse(dealerConfigurationCarStatus.value);
          this.setState({selectedStatus});
        }
        let dealerConfigurationDeleteInventory = dealerConfigurations.find(x => x.code === INVENTORY_DELETE_PASSWORD_CODE);
        if(dealerConfigurationDeleteInventory){
          this.setState({passwordDelete:dealerConfigurationDeleteInventory.value});
        }
      } else {
        console.error(responseMessage);
        this.setState({selectedStatus:[]});
      }
    });
  }

  handlePopover(evt) {
    const {showPopover} = this.state;
    const target = evt.currentTarget;
    const anchorEl = getAnchorEl(target,2);
    this.setState({anchorEl, showPopover: !showPopover});
  }

  onChangePassword(field, evt) {
    this.setState({[field]:evt.target.value,isEmptyPassword:false,isEmptyConfirmPassword:false,notMatchPassword:false});
  }

  renderStatus() {
    const {selectedStatus,optionStatus} = this.state;
    const values = selectedStatus;
    let options = [];

    optionStatus.forEach(status => {
      const checked = findIndex(values, (element) => {
        return element === status.displayValue;
      });

      options.push(
        <div key={status.id} className="content-check">
          <Checkbox label={status.displayValue} checked={(checked !== -1)} id={status.id}
                    onCheck={(evt) => this.handleCheckStatus(evt)}
                    labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                    checkedIcon={elementCheck} uncheckedIcon={elementUnCheck}
                    iconStyle={{borderColor: "#777", marginRight: 8}}
                    name={status.displayValue}/>
        </div>
      )
    });
    return (
      <div className="popover-body-status">
        <div id="" className="options-status">
          {options}
        </div>
      </div>
    )
  }

  handleCheckStatus(evt) {
    const name = evt.target.name;
    let {selectedStatus} = this.state;
    this.save(selectedStatus,name);
  }

  closePopover() {
    this.setState({showPopover: false})
  }

  getDisplayValue(id) {
    let displayValue = "";
    const index = findIndex(optionStatus, (status) => {
      return status.id === id
    });
    if (index !== -1) {
      displayValue = optionStatus[index].displayValue;
    }
    return displayValue;
  }

  onSwitch(){
    let {selectedStatus} = this.state;
    this.save(selectedStatus,carStatus.SOLD);
  }

  save(values,value){
    const index = values.indexOf(value);
    if (index === -1)
      values.push(value);
    else
      values.splice(index, 1);

    saveDealerConfiguration({code:CAR_STATUS_PUBLIC_CODE,value:JSON.stringify(values)}).then(({responseCode, responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({selectedStatus: values});
      } else {
        console.error(responseMessage);
      }
    });
  }

  saveDeletePassword(){
    let {password, confirmPassword} = this.state;
    if (password === "") {
      this.setState({isEmptyPassword: true});
    } else if (confirmPassword === "") {
      this.setState({isEmptyConfirmPassword: true});
    } else if(password !== confirmPassword){
      this.setState({notMatchPassword: true});
    }else{
      saveDealerConfiguration({code:INVENTORY_DELETE_PASSWORD_CODE,value:password}).then(response => {
        this.setState({passwordDelete: password});
      }).catch(error => {
        console.error(error);
      });
    }
  }

  render() {
    const {height} = this.props;
    const {showPopover, anchorEl, selectedStatus,optionStatus,passwordDelete,isEmptyPassword,isEmptyConfirmPassword,notMatchPassword} = this.state;

    if(!optionStatus || !selectedStatus) return null;

    const childStatus = this.renderStatus();
    let labelStatus = "Select Status";
    if (selectedStatus.length === 1 && selectedStatus[0] !== carStatus.SOLD)
      labelStatus = selectedStatus[0];
    else {
      if (selectedStatus.length > 1){
        const selectedStatusComingSoon = selectedStatus.filter(status => {return status !== carStatus.SOLD});
        labelStatus = ` ${selectedStatusComingSoon.length} Selected`
      }
    }

    const showSoldImage = selectedStatus.indexOf(carStatus.SOLD);

    return (
      <div className="setting-inventory-container" style={{minHeight: height + 10}}>
        <div className="inventory-body">
          <div className="section-box">
            <div className="section-left-inventory">
              <div className="container-title-coming-soon">
                <label className="title-coming-soon">Coming Soon Public visibility</label>
              </div>
              <div className="container-icon-sub-title">
                <div className="container-icon-exclamation">
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"} beforeInjection={ svg => svg.classList.add("svg-icon-error")}
                            className="icon-error"/>
                </div>
                <div className="container-sub-title">
                  <p className="sub-title-coming-soon">
                    By selecting an option or options, you are selecting the statuses
                    you would like the car to appear on the Public view.
                  </p>
                </div>
              </div>
              <div className="container-select-status">
                <div className="label-select-status">Select the Status(es)</div>
                <button id="selectStatusComing" className="box-select-status" onClick={this.handlePopover}>
                  <label className="label-select">{labelStatus}</label>
                  {showPopover ?
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                              className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                  }
                </button>
              </div>
            </div>
          </div>
          <div className="section-box-delete-inventory">
            <div className="section-delete-inventory">
              <div className="container-title-coming-soon">
                <label className="title-coming-soon">Delete Inventory Password</label>
              </div>
              <div className="container-icon-sub-title">
                <div className="container-icon-exclamation">
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"} beforeInjection={ svg => svg.classList.add("svg-icon-error")}
                            className="icon-error"/>
                </div>
                <div className="container-sub-title">
                  <p className="sub-title-coming-soon">
                    Just as a confirmation, you'll be asked for this password every time you try to delete a car in the inventory.
                  </p>
                </div>
              </div>
              {
                !passwordDelete ? <div>
                  {notMatchPassword ? <span className="message-general-error">Password not match Confirm Password</span> : null}
                  <div className="container-new-password">
                    <div className="label-select-status">New Password</div>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_open_padlock.svg"} className="icon-padlock" beforeInjection={ svg => svg.classList.add("icon-open-padlock")}/>
                    <input id="newPasswordDelete" type="password" className="input-password" onChange={this.onChangePassword.bind(this,"password")} />
                    { isEmptyPassword ? <span className="message-error">Please provide password</span> : null}
                  </div>
                  <div className="container-new-password">
                    <div className="label-select-status">Confirm Password</div>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_open_padlock.svg"} className="icon-padlock" beforeInjection={ svg => svg.classList.add("icon-open-padlock")}/>
                    <input id="confirmPasswordDelete" type="password" className="input-confirm-password" onChange={this.onChangePassword.bind(this,"confirmPassword")}/>
                    { isEmptyConfirmPassword ? <span className="message-error">Please provide confirm password</span> : null}
                  </div>
                  <div className="container-btn-create">
                    <button className="btn-create-password" onClick={this.saveDeletePassword}>Create</button>
                  </div>
                </div> : <div>
                  <div className="container-new-password">
                    <div className="label-select-status">Password</div>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_open_padlock.svg"} className="icon-padlock" beforeInjection={ svg => svg.classList.add("icon-open-padlock")}/>
                    <input id="newPasswordDelete" type="text" value={passwordDelete} disabled={true} className="input-password" onChange={() => {}} />
                  </div>
                  <div className="container-btn-create">
                    <button className="btn-change-password" onClick={() => this.setState({passwordDelete:null})}>Change Password</button>
                  </div>
                </div>
              }


            </div>
          </div>
            <div className="section-box-sold-image" style={passwordDelete ? {marginTop:-32}:{}}>
            <div className="section-left-inventory">
              <div className="container-title-sold">
                <label className="title-coming-soon">SOLD Image</label>
                <div className="box-switch">
                  <label className="switch">
                    <input type="checkbox" checked={(showSoldImage !== -1)} onChange={this.onSwitch} />
                    <span className={(showSoldImage !== -1) ? "slider round" : "slider round off"} />
                    {
                      (showSoldImage !== -1) ?
                        <span className="check-label-on">ON</span> :
                        <span className="check-label-off">OFF</span>
                    }
                  </label>
                </div>
              </div>
              <div className="container-icon-sub-title">
                <div className="container-icon-exclamation">
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"} beforeInjection={ svg => svg.classList.add("svg-icon-error")}
                            className="icon-error"/>
                </div>
                <div className="container-sub-title">
                  <p className="sub-title-coming-soon">
                    When a car has "SOLD" as status, a image will appear over the car cover picture in your public inventory page.
                  </p>
                </div>
              </div>
              <div className="container-sold-image">
                <img src={pathServer.PATH_IMG + 'sold-image.png'} />
              </div>
            </div>
          </div>
        </div>
        {
          showPopover ?
            <Popover anchorEl={anchorEl}
                     child={childStatus}
                     classPopover="popover-box-inventory-location"
                     handleRequestClose={this.closePopover}/> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    attributeValuesToCarStatus:state.attributeValuesToCarStatus,
  }
};

export default connect(mapStateToProps,{})(InventorySetting)