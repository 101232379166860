import React, {Component} from 'react'
import {CARS_BY_PAGE, siteColorAdmin, WIDTH_INVENTORY_TABLET} from '../../Constants'
import {ReactSVG} from 'react-svg'
import {clone} from 'lodash'
import DPOptionsTask from '../../common/DPOptionsTask'
import DPButton from '../../common/DPButton'
import {pathServer, WIDTH_VIEW_MOBILE} from '../../../common/Constants'
import {DPPagination} from '../../../common/DPPagination'
import { isDisabled } from '@testing-library/user-event/dist/utils'

export default class ContainerHeader extends Component {

  constructor() {
    super()
    this.handleItemsByPage = this.handleItemsByPage.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.handleRequestOpen = this.handleRequestOpen.bind(this)
    this.handleResize = this.handleResize.bind(this)

    this.state = {
      showOptions: false,
      itemsByPageQuantity: 20,
      showPopover: false,
      from: 0,
      widthPage: window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleRequestOpen(event) {
    this.setState({
      showOptions: true,
      anchorEl: {offsetWidth: event.currentTarget.offsetWidth, offsetHeight: event.currentTarget.offsetHeight}
    })
  }

  handlePageChange(nextPage, totalPages) {
    let {carFilters} = this.props
    let query = clone(carFilters)
    let itemsByPageQuantity = query.size
    let currentPage = nextPage
    if (currentPage > totalPages) {
      currentPage = 1
      query.from = 0
    } else if (currentPage === 0) {
      currentPage = totalPages
      query.from = itemsByPageQuantity * (totalPages - 1)
    } else {
      query.from = itemsByPageQuantity * (currentPage - 1)
    }
    this.props.search(query, 'currentPage', currentPage)
  }

  handleItemsByPage(itemsByPageQuantity) {
    let {carFilters} = this.props
    let query = clone(carFilters)

    query.from = 0
    query.size = itemsByPageQuantity
    this.props.search(query, 'currentPage', 1)
    this.setState({showOptions: false})
  }

  handleRequestClose() {
    this.setState({showOptions: false})
  }

  handleResize() {
    this.setState({widthPage: window.innerWidth})
  }

  render() {
    let {
      title, action, isEdit, actionView, showList, data, actionBack,
      showPopoverAdd, save, hasChange, left, handleShowFilterSection, showFilterSection,
      pageNumberInventory, showOnMobile, showItemPerPage, showPaginationArrow, carFilters, customerId,
      isNewTask, isEditTask, deleteTask, activeTask, archivedTask, pageSelected,
      hasButtonExport, handleButtonExport, children, id, showIconAddCar,
      hasButtonImport, handleButtonImport, confirmText = 'Save'
    } = this.props

    const {widthPage} = this.state
    let totalPages, itemsByPageQuantity
    if (!isEdit) {
      itemsByPageQuantity = carFilters.size
      totalPages = carFilters.quantityCars ? Math.ceil(carFilters.quantityCars / itemsByPageQuantity) : 1
    }

    return (
      <div id={id ? id : 'containerHeaderGeneral'} className='container-header'
           style={{left: (widthPage > WIDTH_INVENTORY_TABLET) ? left : null}}>

        <div className='left-container'>
          {
            showIconAddCar &&
            <div className='action'>
              <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_circle.svg'}
                        beforeInjection={svg => {
                          svg.classList.add('icon-add-svg')
                        }}
                        className='icon-add-task' onClick={() => action()}/>
            </div>
          }
          {
            isEdit ?
              <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
                        beforeInjection={svg => svg.classList.add('icon-back')} onClick={actionBack}
                        className='arrow-back'/> : <div className='arrow-back-edit'/>
          }
          <div id='titleHeaderCar' className='title' style={this.props.style ? this.props.style : null}>
            {title}
          </div>
          {
            (isEdit) &&
            <div className="arrow-right">
              <i style={{color: "#898989",padding: "0 2px",fontSize: "0.8em"}} className="fa fa-chevron-right fa-lg"/>
            </div>
          }
          {
            (isEdit) ? <div id='subtitleHeaderCar' className='subtitle'
                            style={isEditTask ? {fontWeight: 'bold'} : {}}>{data}</div> : null
          }
          {
            !isEdit ?
              showPopoverAdd &&
              <div className='action'>
                <button onClick={action} className='button-add span' style={{backgroundColor: '#ff6564'}}>x</button>
              </div>
              : null
          }
        </div>
        <div className='right-container'>
          {hasButtonImport && (
            <div className='export-inventory'>
              <DPButton className='button-white' width='100%' onClick={handleButtonImport}
                        data-test={`import-customer`}>Import Inventory</DPButton>
            </div>
          )}
          {hasButtonExport && (
            <div className='export-inventory'>
              <DPButton className='button-blue' width='100%' onClick={handleButtonExport} data-test={`export-customer`}>Export
                Inventory</DPButton>
            </div>
          )}
          {
            (isEdit) ?
              <div className='option-edit' style={(showOnMobile && widthPage <= 768) ? {display: 'block'} : null}>
                {['task','subTask'].includes(pageSelected) ?
                  isNewTask ?
                    <div>
                      <button onClick={actionBack} className='btn-cancel'>Cancel
                      </button>
                      <button onClick={save} className='btn-save' disabled={!hasChange}
                              style={hasChange ? {backgroundColor: '#4ad991'} : {backgroundColor: '#A4AFB7'}}
                              data-test='btn-save'>Save
                      </button>
                    </div> :
                    isEditTask ?
                      <DPOptionsTask isDisabled={isEditTask} deleteTask={deleteTask} activeTask={archivedTask}
                                     save={save} hasChange={hasChange} pageSelected={pageSelected}/>
                      :
                      <DPOptionsTask isDisabled={isEditTask} deleteTask={deleteTask} activeTask={activeTask}/>
                  : customerId && customerId !== null ? null :
                    <button onClick={save} className='btn-save' disabled={!hasChange}
                            style={hasChange ? {backgroundColor: '#4ad991'} : {
                              backgroundColor: '#A4AFB7',
                              width: 'auto',
                              padding: '0 10px 0 10px'
                            }}
                            data-test='btn-save'>{confirmText}</button>
                }
              </div> :
              <div className='right-container-no-edit'>
                <div className='inventory-pagination'>
                  <DPPagination
                    optionsQuantityByPage={CARS_BY_PAGE}
                    selectedItemsByPage={itemsByPageQuantity}
                    handleChangeItemsByPage={this.handleItemsByPage}
                    currentPage={pageNumberInventory}
                    totalPages={totalPages}
                    handlePageChange={this.handlePageChange}
                    showItemPerPage={showItemPerPage}
                    showPaginationArrow={showPaginationArrow}
                    siteColor={siteColorAdmin}
                  />
                </div>
                <div className='mode-view'>
                  <div className='boxes-view' onClick={() => actionView('thumbnails')}>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic-display-blocks.svg'}
                              beforeInjection={svg => {
                                !showList ? svg.classList.add('header-menu-option-active') : svg.classList.add('header-menu-option')
                              }}
                              className='icon-option'/>
                  </div>
                  <div className='list-view' onClick={() => actionView('list')}>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic-display-rows.svg'}
                              beforeInjection={svg => {
                                showList ? svg.classList.add('header-menu-option-active') : svg.classList.add('header-menu-option')
                              }}
                              className='icon-option'/>
                  </div>
                  <div id='buttonOption' onClick={handleShowFilterSection}>
                    <ReactSVG src={pathServer.PATH_IMG + 'icon/ic-filter-menu.svg'}
                              beforeInjection={svg => {
                                showFilterSection ? svg.classList.add('header-menu-option-active') : svg.classList.add('header-menu-option')
                              }}
                              className='icon-option'/>
                  </div>
                </div>
              </div>
          }
        </div>
        {
          children && showFilterSection ?
            children : null
        }
      </div>
    )
  }
}
