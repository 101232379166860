/**
 * Created by javierl on 3/23/16
 */

import {createStore, applyMiddleware} from 'redux'
import {routerMiddleware} from 'react-router-redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducers'

export default function configureStore(initialState,history){

  const middleware = routerMiddleware(history);

  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(middleware,thunk),
  )
}