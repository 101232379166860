import React, {Component} from 'react'
import {formStatus} from '../../Constants'
import Checkbox from 'material-ui/Checkbox'
import {Popover, DPPill} from '../util/ComponentUtils'
import {ReactSVG} from 'react-svg'
import InputRange from 'react-input-range'
import {difference, findIndex, isEmpty, find, cloneDeep, clone} from 'lodash'
import {capitalFirstLetter} from '../util/Utils'
import {getPosition} from '../../../common/Util'
import {elementCheck, elementUnCheck} from '../../../common/ComponentUtils'
import {pathServer, WIDTH_VIEW_MOBILE} from '../../../common/Constants'

const STATUS_ARCHIVED_NAME = "Archived";

function getFilterInitial(min, max) {
  return {min: min, max: max}
}

const DPFilter = ({id,disabledFilter,handlePopover,previousLabel,nextLabel,showRange,values,displayValue,filterSelected, carFilters}) => {
  return(
    <div id={id} className={!disabledFilter ? "category" : "category-disabled"} onClick={(event) => !disabledFilter ? handlePopover(id,event,carFilters) : null}>
      <label>{(showRange) ? (`${previousLabel}${values ? values.min: ""} ${nextLabel} - ${previousLabel}${values ? values.max: ""} ${nextLabel}`) : displayValue }</label>
      {filterSelected === id ?
        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
      }
    </div>
  )
};

const ChildRangeTablet = ({category,className,carFilters,step,cleanFilterRangesExpanded,handleYearMilePriceOkTablet,handleRangeChange,values}) =>{

  if(!category || !carFilters.minValues)
    return null;

  let valueNew = values ? values : {min: carFilters.minValues[category], max: carFilters.maxValues[category]};

  return (
    <div className={className}>
      <div>
        <div className="btn-content-filter-pie">
          <a id={category} onClick={(event) => cleanFilterRangesExpanded(event, carFilters)}>Clear Filter</a>
          <button className="btn-primary" onClick={() => handleYearMilePriceOkTablet(category)}>Ok</button>
        </div>
        <label>{capitalFirstLetter(category)}</label>
        <div className="slider-container">
          <InputRange maxValue={category !== "" ? carFilters.maxValues[category] : 20}
                      minValue={category !== "" ? carFilters.minValues[category] : 0}
                      step={step}
                      value={values ? values : {min: carFilters.minValues[category], max: carFilters.maxValues[category]}}
                      onChange={(valueNew) => handleRangeChange(category, valueNew)}/>
        </div>
        <div className="slider-value">
          <label>{valueNew.min}</label>
          <label className="value-max">{valueNew.max}</label>
        </div>
      </div>
    </div>
  )
};

export default class InventoryFilter extends Component {

  constructor() {
    super();
    this.state = {
      keyword: '',
      rangeMileageFrom: null,
      rangeMileageTo: null,
      rangePriceFrom: null,
      rangePriceTo: null,
      rangeYearFrom: null,
      rangeYearTo: null,
      showPopover: false,
      filterSelected: null,
      year: getFilterInitial(2000,2025),
      mile: getFilterInitial(0,250000),
      price: getFilterInitial(0,50000),
      showPopoverStatus: false,
      showMakePopover: false,
      showModelPopover: false,
      makeOptions: [],
      options: [],
      selectedMakes: [],
      modelsByMakeIds: [],
      selectedModels: [],
      selectedStatus: [],
      statusArchived:null,
      hasChangeMakeAndModel: false,
      isFilteredByMakeAndModel: false,
      showRangeYear: false,
      showRangeMile: false,
      showRangePrice: false,
      lastFilterSelected: null,
      showPopoverLocation : false,
      selectedLocations:[],
      hasChangeStatusOrLocation:false,
      tempRangeYear: null,
      tempRangeMile: null,
      tempRangePrice: null,
      tempRangeYearTablet: null,
      tempRangeMileTablet: null,
      tempRangePriceTablet: null,
      showOnlyInactive: false
    }
  }

  componentWillMount() {
    this.getModelsFromMakes()
  }

  componentWillReceiveProps(nextProps) {
  }

  componentWillUnmount(){
    const {selectedMakes} = this.state;
    if(selectedMakes && selectedMakes.length > 0){
      this.props.changeFirstLoading(true);
    }
  }

  handlePagination = (number) => {
    this.props.changePagination(number);
  }

  search = (hasChangeMakeOrModel) => {
    this.props.search();
  }

  handlePressEnter = (evt) => {
    if (evt.key === 'Enter') {
      this.search();
    }
  }

  handlePressEnterNumber = (evt) => {
    this.validate(evt);
    this.handlePressEnter(evt);
  }

  showAll() {
    window.location.href = `/main/inventory`
  }

  handlePopover = (id, event, carFilters) => {
    event.preventDefault();
    let widthPage = window.innerWidth;
    let {filterSelected, tempRangeYear, tempRangeMile, tempRangePrice} = this.state;
    const {left} = this.props;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x - left ,
      y: pointTo.y - 25,
      offsetWidth: 254
    };

    if(widthPage < 1481 && widthPage >768){
      switch (id){
        case 'price':
          anchorEl.x = pointTo.x-80;
          break;
        case 'mobile':
          anchorEl.x = pointTo.x-60;
          break;
        case 'mile':
          anchorEl.x = pointTo.x-60;
          break;
      }
    }
    if(id !== 'mileMobile'){
      if( widthPage < 769){
        if( widthPage < 401) {
          anchorEl.offsetWidth = 205
        }
        anchorEl.x = pointTo.x - 2;
      }
    } else {
      if (widthPage < 769 && widthPage > 400){
        anchorEl.x = widthPage - 10 - 254;
      } else if(widthPage < 401){
        anchorEl.offsetWidth = 205;
        anchorEl.x = widthPage - 10 - 205;
      }
    }

    let step = 1;
    let lastFilterSelected = "";
    if (id === "year" || id === "yearMobile") {
      filterSelected = "year";
      lastFilterSelected = "year";
      tempRangeYear = carFilters.year;
    } else if (id === "mile" || id === "mileMobile") {
      step = 5000;
      filterSelected = "mile";
      lastFilterSelected = "mile";
      tempRangeMile = carFilters.mile;
    } else if (id === "price" || id === "priceMobile" ) {
      step = 100;
      filterSelected = "price";
      lastFilterSelected = "price";
      tempRangePrice = carFilters.price;
    }

    this.setState({anchorEl: anchorEl, showPopover: true, step, filterSelected, lastFilterSelected,
      tempRangeYear, tempRangeMile, tempRangePrice});
  }

  handlePopoverStatus = (id, event) => {
    event.preventDefault();
    const {left} = this.props;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: this.props.showOnlyFastFilters? pointTo.x : pointTo.x - left,
      y: pointTo.y - 25,
      offsetWidth: 190
    };

    this.setState({anchorEl: anchorEl, showPopoverStatus: true, filterSelected: "status", lastFilterSelected: "status"});
  }

  handlePopoverLocations = (id,event) => {
    event.preventDefault();
    const {left} = this.props;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: this.props.showOnlyFastFilters? pointTo.x : pointTo.x - left,
      y: pointTo.y - 25,
      offsetWidth: 190
    };
    this.setState({ showPopoverLocation: true, filterSelected: "locations", lastFilterSelected: "locations",
      anchorEl: anchorEl});
  }


  handleMultiselectPopover = (id, event) => {
    event.preventDefault();
    const {left} = this.props;
    let widthPage = window.innerWidth;
    let target = document.getElementById(id);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x - left,
      y: pointTo.y - 25,
      offsetWidth:  id === 'selectedModels' ? 230 : 254
    };
    if(widthPage < 941){
      anchorEl.offsetWidth =210;
    }
    if (widthPage < 1361 && widthPage > 940 && id === 'selectedModels') {
      anchorEl.x = pointTo.x - 75;
    }
    if (widthPage < 941 && id === 'selectedModels') {
      anchorEl.x = pointTo.x - 55;
    }
    if(widthPage < 769){
      if(id !== 'selectedMakesMobile'){
        anchorEl.x = pointTo.x - 2
      } else if(id === 'selectedMakesMobile'){
        anchorEl.x = widthPage - 10 - 210
      }
    }

    const {makes} = this.props;

    let filterSelected;
    let options = [];

    filterSelected = id;
    let lastFilterSelected = id;

    if (id === "selectedMakes" || id === 'selectedMakesMobile') {
      makes.forEach(make => {
        options.push({id: make.id, value: make.name})
      });
      this.setState({showMakePopover: true, makeOptions: options});
    } else {
      const {modelsByMakeIds} = this.state;
      let showModelPopover;
      if (modelsByMakeIds && modelsByMakeIds.length === 0) {
        showModelPopover = false;
      } else {
        showModelPopover = true;
      }
      this.setState({showModelPopover});
    }
    this.setState({anchorEl: anchorEl, filterSelected, lastFilterSelected});
  }

  handleRequestClose = () => {
    let {carFilters} = this.props;
    let carFiltersCopy = clone(carFilters);
    let tempRangeYearTablet = carFiltersCopy.year ? carFiltersCopy.year : null;
    let tempRangeMileTablet = carFiltersCopy.mile ? carFiltersCopy.mile : null;
    let tempRangePriceTablet = carFiltersCopy.price ? carFiltersCopy.price : null;

    this.setState({
      showPopover: false,
      filterSelected: null,
      showPopoverStatus: false,
      showMakePopover: false,
      showModelPopover: false,
      showPopoverLocation: false,
      tempRangeYear: null,
      tempRangeMile: null,
      tempRangePrice: null,
      tempRangeYearTablet,
      tempRangeMileTablet,
      tempRangePriceTablet
    });
  }

  renderStatusOptions() {
    const {attributeValuesToCarStatus,carFilters} = this.props;
    let values = carFilters.status ? carFilters.status : [] ;
    let options = [];

    const statusArchived = find(values, (value) => {
      return value === STATUS_ARCHIVED_NAME
    });

    attributeValuesToCarStatus.forEach(status => {
      const checked = findIndex(values, (value) => {
        return value === status.displayValue
      });

      options.push(
        <div key={status.id} className={(status.code !== "archived") ? "content-check" : "content-check line-separator-status"}>
          <Checkbox label={status.displayValue} checked={(checked !== -1)} id={status.displayValue}
                    onCheck={(evt) => this.handleCheck("status", evt)}
                    labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                    checkedIcon={elementCheck('icon/ic_check_box.svg')} uncheckedIcon={elementUnCheck('icon/ic_uncheck_box.svg')}
                    iconStyle={{borderColor: "#777", marginRight: 8}}
                    disabled={(statusArchived && statusArchived === STATUS_ARCHIVED_NAME) && (status.displayValue !== STATUS_ARCHIVED_NAME)}
                    className={(statusArchived && statusArchived === STATUS_ARCHIVED_NAME) && (status.displayValue !== STATUS_ARCHIVED_NAME) ? "check-disabled":null}
                    name={status.displayValue}/>
        </div>
      )
    });

    return (
      <div className="popover-body-status">
        {
          values.length !== 0 ?
            <div className="header">
              <a id="clearFilters" className="clearFilters" onClick={this.clearStatusFilter}>
                <label>Clear Filter</label>
              </a>
            </div> : null
        }
        <div id="" className="options-status">
          {options}
        </div>
      </div>
    )
  }

  renderLocationsOptions() {
    const {locations,carFilters} = this.props;
    let values = carFilters.locations ? carFilters.locations : [];
    let options = [];

    locations.forEach(location => {
      const checked = findIndex(values, (value) => {
        return value === location.locationId
      });

      options.push(
        <div key={location.locationId} className={"content-check"}>
          <Checkbox label={location.name} checked={(checked !== -1)} id={location.locationId}
                    onCheck={this.handleCheckLocations}
                    labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                    checkedIcon={elementCheck('icon/ic_check_box.svg')} uncheckedIcon={elementUnCheck('icon/ic_uncheck_box.svg')}
                    iconStyle={{borderColor: "#777", marginRight: 8}}
                    name={location.name}/>
        </div>
      )
    });

    return (
      <div className="popover-body-locations">
        {
          values.length !== 0 ?
            <div className="header">
              <a id="clearFilters" className="clearFilters" onClick={this.clearLocationsFilter}>
                <label>Clear Filter</label>
              </a>
            </div> : null
        }
        <div id="" className="options-locations">
          {options}
        </div>
      </div>
    )
  }

  inputChange = (type, event) => {
    this.setState({[type]: event.target.value})
  }

  onChangeNumberPriceFrom = (evt, modelValue, viewValue) => {
    this.validatePriceFrom(modelValue)
  };

  onBlurPriceFrom = (value) => {
    this.validatePriceFrom(value)
  }

  onChangeNumberPriceTo = (evt, modelValue, viewValue) => {
    this.validatePriceTo(modelValue)
  }

  onChangeNumberMileageFrom = (evt, modelValue, viewValue) => {
    this.validateMileageFrom(modelValue)
  }

  onChangeNumberMileageTo = (evt, modelValue, viewValue) => {
    this.validateMileageTo(modelValue)
  }

  onBlurPriceTo = (value) => {
    this.validatePriceTo(value)
  }

  validatePriceTo(modelValue) {
    let rangePriceFrom = this.state.rangePriceFrom;
    if (rangePriceFrom) {
      if (modelValue >= rangePriceFrom) {
        this.setState({rangePriceTo: modelValue})
      } else {
        this.setState({rangePriceTo: ''})
      }
    } else {
      this.setState({rangePriceTo: (modelValue !== 0) ? modelValue : ''})
    }
  }

  validatePriceFrom(modelValue) {
    let rangePriceTo = this.state.rangePriceTo;
    if (rangePriceTo) {
      if (modelValue <= rangePriceTo) {
        this.setState({rangePriceFrom: modelValue})
      } else {
        this.setState({rangePriceFrom: ''})
      }
    } else {
      this.setState({rangePriceFrom: (modelValue !== 0) ? modelValue : ''})
    }
  }

  onBlurMileageFrom = (value) => {
    this.validateMileageFrom(value)
  }

  onBlurMileageTo = (value) => {
    this.validateMileageTo(value)
  }

  validateMileageFrom(modelValue) {
    let rangeMileageTo = this.state.rangeMileageTo;
    if (rangeMileageTo) {
      if (modelValue <= rangeMileageTo) {
        this.setState({rangeMileageFrom: modelValue})
      } else {
        this.setState({rangeMileageFrom: ''})
      }
    } else {
      this.setState({rangeMileageFrom: (modelValue !== 0) ? modelValue : ''})
    }
  }

  validateMileageTo(modelValue) {
    let rangeMileageFrom = this.state.rangeMileageFrom;
    if (rangeMileageFrom) {
      if (modelValue >= rangeMileageFrom) {
        this.setState({rangeMileageTo: modelValue})
      } else {
        this.setState({rangeMileageTo: ''})
      }
    } else {
      this.setState({rangeMileageTo: (modelValue !== 0) ? modelValue : ''})
    }
  }

  validate(evt) {
    let theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    let regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  renderMakeOptions() {

    let {makeOptions} = this.state;
    let {carFilters} = this.props;
    let values = carFilters.makes ? carFilters.makes : [];
    let notCheckedMakes = [];
    let checkedMakes = [];
    let checked = null;

    makeOptions.forEach(option => {
      checked = findIndex(values, (value) => {
        return value === option.id
      });
      if (checked === -1) {
        notCheckedMakes.push(
          <div key={option.id} className="content-check">
            <Checkbox label={option.value} checked={false} id={option.id}
                      onCheck={(evt) => this.handleCheck("makes", evt)}
                      labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                      checkedIcon={elementCheck('icon/ic_check_box.svg')} uncheckedIcon={elementUnCheck('icon/ic_uncheck_box.svg')}
                      iconStyle={{borderColor: "#777", marginRight: 8}}
                      name={option.value}/>
          </div>
        );
      } else {
        checkedMakes.push(
          <div key={option.id} className="content-check">
            <Checkbox label={option.value} checked={true} id={option.id}
                      onCheck={(evt) => this.handleCheck("makes", evt)}
                      labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                      iconStyle={{borderColor: "#777", marginRight: 8}}
                      checkedIcon={elementCheck('icon/ic_check_box.svg')} uncheckedIcon={elementUnCheck('icon/ic_uncheck_box.svg')}
                      name={option.value}/>
          </div>
        );
      }
    });

    return (
      <div className="popover-box">
        <div className="popover-box-scroll">
          {
            checkedMakes.length !== 0 ?
              <div className="header">
                <a id="clearFilters" className="clearFilters" onClick={this.clearMakeFilter}>
                  <label>Clear Filter</label>
                </a>
                <div id="checked" className="checked">
                  {checkedMakes}
                </div>
                <div className="separator">
                </div>
              </div> : null
          }
          <div id="notChecked" className="notChecked">
            {notCheckedMakes}
          </div>
        </div>
      </div>

    )
  }

  renderModelOptions() {
    let {modelsByMakeIds} = this.state;
    let {carFilters} = this.props;
    let values = carFilters.models ? carFilters.models : [];
    let notCheckedModels = [];
    let checkedModels = [];
    let notCheckedXModel = [];
    let checkedXModel = [];

    modelsByMakeIds.forEach((make, index) => {
      make.models.forEach(model => {

        const checked = !!values.find(val => {
          return val === model.id
        });
        if (checked) {
          checkedXModel.push(
            <div key={model.id} className="content-check">
              <Checkbox label={model.displayValue} checked={true} id={model.id}
                        onCheck={(evt) => this.handleCheck("models", evt)}
                        labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                        iconStyle={{borderColor: "#777", marginRight: 8}} name={model.name}
                        checkedIcon={elementCheck('icon/ic_check_box.svg')} uncheckedIcon={elementUnCheck('icon/ic_uncheck_box.svg')}/>
            </div>
          )
        } else {
          notCheckedXModel.push(
            <div key={model.id} className="content-check">
              <Checkbox label={model.displayValue} checked={false} id={model.id}
                        onCheck={(evt) => this.handleCheck("models", evt)}
                        labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                        iconStyle={{borderColor: "#777", marginRight: 8}} name={model.name}
                        checkedIcon={elementCheck('icon/ic_check_box.svg')} uncheckedIcon={elementUnCheck('icon/ic_uncheck_box.svg')}/>
            </div>
          )
        }
      });
      if (checkedXModel.length !== 0) {
        checkedModels.push(
          <div key={"checked-" + index}>
            <label className="title">{make.makeName.toUpperCase()}</label>
            {checkedXModel}
          </div>
        );
      }
      if (notCheckedXModel.length !== 0) {
        notCheckedModels.push(
          <div key={"notchecked-" + index}>
            <label className="title">{make.makeName.toUpperCase()}</label>
            {notCheckedXModel}
          </div>
        );
      }
      notCheckedXModel = [];
      checkedXModel = [];
    });

    return (
      <div className="popover-box">
        <div className="popover-box-scroll">
          {
            checkedModels.length !== 0 ?
              <div className="header">
                <a id="clearFilters" className="clearFilters" onClick={this.clearModelFilter}>
                  <label>Clear Filter</label>
                </a>
                <div id="checked" className="checked">
                  {checkedModels}
                </div>
                <div className="separator">
                </div>
              </div> : null
          }
          <div id="notchecked" className="notchecked">
            {notCheckedModels}
          </div>
        </div>
      </div>
    )
  }

  getModelsFromMakes = () => {
    let {modelsByMakeIds} = this.state;
    let {carFilters, makes} = this.props;

    if (isEmpty(makes)) {
      return
    }

    let values = carFilters.makes;
    let checkedMakes = [];
    modelsByMakeIds = [];

    makes.forEach(make => {
      findIndex(values, (value) => {
        if (value === make.id)
          checkedMakes.push({id: make.id, name: make.name})
      });
    });
    checkedMakes.forEach(make => {
      const {models} = this.props;
      const modelsFound = models.filter(model => {
        return model.makeId === make.id
      });
      modelsByMakeIds.push({makeId: make.id, makeName: make.name, models: modelsFound});
    });
    this.setState({modelsByMakeIds: modelsByMakeIds})

  }

  handleCheck = (type, evt) => {

    let {carFilters, setCarFilters} = this.props;
    let {filterSelected, modelsByMakeIds} = this.state;

    const id = evt.target.id;
    const name = evt.target.name;
    let selectedType = null;
    if(type === "selectedMakesMobile"){
      selectedType ="makes";
    }else if(type === "selectedModelsMobile"){
      selectedType ="models";
    }else if(type === "selectedStatusMobile"){
      selectedType ="status";
    }else {
      selectedType = type;
    }

    let carFiltersNew = clone(carFilters);
    let values = carFiltersNew[selectedType] ? carFiltersNew[selectedType] : [];
    const index = values.indexOf(id);

    if (type === "makes" || type === "models") {
      if (index === -1) { // si no esta chequeado
        values.push(id);
        const {models} = this.props;
        const modelsFound = models.filter(model => {
          return model.makeId === id
        });
        modelsByMakeIds.push({makeId: id, makeName: name, models: modelsFound});
      } else {
        values.splice(index, 1);
        const modelsId = modelsByMakeIds[index].models.map(model => {
          return model.id
        });
        let selectedModels = difference(carFiltersNew.models, modelsId);
        modelsByMakeIds.splice(index, 1);
        carFiltersNew.models = selectedModels;
      }
    } else {
      if((type === "status" || type === "selectedStatusMobile")){
        if(name === STATUS_ARCHIVED_NAME){
          if (index === -1){
            values = [];
            values.push(id);
          }else
            values.splice(index, 1);
        }else {
          if (index === -1)
            values.push(id);
          else
            values.splice(index, 1);
        }
      }else{
        if (index === -1)
          values.push(id);
        else
          values.splice(index, 1);
      }
    }
    this.setState({modelsByMakeIds: modelsByMakeIds});
    carFiltersNew[selectedType] = values;
    carFiltersNew.from = 0;
    setCarFilters(carFiltersNew);
    this.props.search(carFiltersNew,filterSelected);
  }

  handleCheckLocations = (evt) => {
    const id = evt.target.id;
    const {carFilters,setCarFilters} = this.props;
    let carFiltersNew  = clone(carFilters);
    let locations = carFiltersNew.locations ? carFiltersNew.locations : [];
    const index = locations.indexOf(id);
    if( index === -1)
      locations.push(id);
    else
      locations.splice(index,1);

    carFiltersNew.locations = locations;
    carFiltersNew.from = 0;
    //--setCarFilters(carFiltersNew);
    this.props.search(carFiltersNew)
  }

  clearMakeFilter = (e) => {
    e.stopPropagation();
    let {carFilters, setCarFilters} = this.props;
    let carFiltersNew = clone(carFilters);
    carFiltersNew.makes = [];
    carFiltersNew.models = [];
    this.props.search(carFiltersNew);
    setCarFilters(carFiltersNew);
    this.setState({modelsByMakeIds: []});
  }

  clearModelFilter = (e) => {
    e.stopPropagation();

    let {carFilters, setCarFilters} = this.props;
    let carFiltersNew = clone(carFilters);
    carFiltersNew.models = [];

    this.props.search(carFiltersNew);
    setCarFilters(carFiltersNew);
  }

  clearStatusFilter = (e) => {
    e.stopPropagation();
    let {carFilters, setCarFilters} = this.props;
    let carFiltersNew = clone(carFilters);
    carFiltersNew.status = [];
    setCarFilters(carFiltersNew);
    this.props.search(carFiltersNew);
  }

  clearLocationsFilter = (e) => {
    e.stopPropagation();
    let {carFilters, setCarFilters} = this.props;
    let carFiltersNew = clone(carFilters);
    carFiltersNew.locations = [];
    setCarFilters(carFiltersNew);
    this.props.search(carFiltersNew);
  }

  cleanFilterRanges = (e) => {
    e.stopPropagation();
    let {filterSelected, showRangeYear, showRangeMile, showRangePrice} = this.state;
    const {carFilters} = this.props;
    let carFiltersCopy = cloneDeep(carFilters);
    let tempRangeYear = {};
    let tempRangeMile = {};
    let tempRangePrice = {};
    let tempRangeYearTablet = {};
    let tempRangeMileTablet = {};
    let tempRangePriceTablet = {};

    if(filterSelected === "year"){
      tempRangeYear.min = carFiltersCopy.minValues[filterSelected];
      tempRangeYear.max = carFiltersCopy.maxValues[filterSelected];
      tempRangeYearTablet = tempRangeYear;
    }else if(filterSelected === "mile"){
      tempRangeMile.min = carFiltersCopy.minValues[filterSelected];
      tempRangeMile.max = carFiltersCopy.maxValues[filterSelected];
      tempRangeMileTablet = tempRangeMile;
    } else if(filterSelected === "price"){
      tempRangePrice.min = carFiltersCopy.minValues[filterSelected];
      tempRangePrice.max = carFiltersCopy.maxValues[filterSelected];
      tempRangePriceTablet = tempRangePrice;
    }
    this.setState({tempRangeYear, tempRangeMile, tempRangePrice, tempRangeYearTablet, tempRangeMileTablet, tempRangePriceTablet,
      showRangeYear, showRangeMile, showRangePrice});
  }

  cleanFilterRangesExpanded = (event, carFilters) => {
    event.stopPropagation();
    let filterSelected = event.currentTarget.id;
    let state = {};
    let tempRangeYear = {};
    let tempRangeMile = {};
    let tempRangePrice = {};
    if(filterSelected === "year"){
      tempRangeYear.min = carFilters.minValues[filterSelected];
      tempRangeYear.max = carFilters.maxValues[filterSelected];
      state.tempRangeYear = tempRangeYear;
      state.tempRangeYearTablet = tempRangeYear;
    }else if(filterSelected === "mile"){
      tempRangeMile.min = carFilters.minValues[filterSelected];
      tempRangeMile.max = carFilters.maxValues[filterSelected];
      state.tempRangeMile = tempRangeMile;
      state.tempRangeMileTablet = tempRangeMile;
    } else if(filterSelected === "price"){
      tempRangePrice.min = carFilters.minValues[filterSelected];
      tempRangePrice.max = carFilters.maxValues[filterSelected];
      state.tempRangePrice = tempRangePrice;
      state.tempRangePriceTablet = tempRangePrice;
    }
    this.setState(state);
  }

  clearCarFilters = () => {
    const {carFilters, setCarFilters} = this.props;
    const carFiltersNew = clone(carFilters);
    carFiltersNew.status = [];
    carFiltersNew.locations = [];
    carFiltersNew.makes = [];
    carFiltersNew.models = [];
    setCarFilters(carFiltersNew);
  }

  cleanAllFilters = (e) => {
    this.setState({
      modelsByMakeIds: [],
      hasChangeMakeAndModel: false,
      isFilteredByMakeAndModel: false,
      filterSelected: null,
      showPopover: false,
      showRangeYear: false,
      showRangeMile: false,
      showRangePrice: false,
      tempRangeYearTablet: null,
      tempRangeMileTablet: null,
      tempRangePriceTablet: null
    }, () => {
      this.clearCarFilters()
      const {setPageNumberInventory, carFilters} = this.props;
      const query = {from : 0, size:carFilters.size};
      setPageNumberInventory(1);
      this.props.search(query,"initial");
    });
  }

  handleDeleteItem = (evt) => {
    //evt.preventDefault();
    let {carFilters, setCarFilters} = this.props;

    const index = Number(evt.target.id);
    const name = evt.target.name;
    let carFiltersNew = clone(carFilters);
    let object = carFiltersNew[name];
    let {modelsByMakeIds} = this.state;

    if (name === "makes"){
      object.splice(index, 1);
      const modelsId = modelsByMakeIds[index].models.map(model => {
        return model.id
      });
      let selectedModels = difference(carFiltersNew.models, modelsId);
      modelsByMakeIds.splice(index, 1);
      carFiltersNew.makes = object;
      carFiltersNew.models = clone(selectedModels);
    } else {
      let indexToDelete = index;
      if (name === "status"){
        const statusIndex = object.findIndex((val) => {
          return val === STATUS_ARCHIVED_NAME
        });
        if (statusIndex !== -1){
          indexToDelete = statusIndex;
        }
      }
      object.splice(indexToDelete,1);
      carFiltersNew[name] = object;
    }
    this.setState({modelsByMakeIds: modelsByMakeIds});
    setCarFilters(carFilters);
    this.props.search(carFiltersNew);
  }

  handleYearMilePriceOk = () => {
    let {filterSelected, tempRangeYear, tempRangeMile, tempRangePrice} = this.state;
    const {carFilters, setCarFilters,search} = this.props;

    let carFiltersNew = cloneDeep(carFilters);
    let state = {};
    if (filterSelected === "year" && tempRangeYear !== null) {
      carFiltersNew.year = cloneDeep(tempRangeYear);
      if((carFiltersNew.year.min === carFiltersNew.minValues.year && carFiltersNew.year.max === carFiltersNew.maxValues.year))
        state.showRangeYear = false;
      else
        state.showRangeYear = true;
    } else if (filterSelected === "mile" && tempRangeMile !== null) {
      carFiltersNew.mile = cloneDeep(tempRangeMile);
      if(carFiltersNew.mile && carFiltersNew.mile.min === carFiltersNew.minValues.mile && carFiltersNew.mile.max === carFiltersNew.maxValues.mile)
        state.showRangeMile = false;
      else
        state.showRangeMile = true;
    } else if (filterSelected === "price" && tempRangePrice !== null) {
      carFiltersNew.price = cloneDeep(tempRangePrice);
      if(carFiltersNew.price && carFiltersNew.price.min === carFiltersNew.minValues.price && carFiltersNew.price.max === carFiltersNew.maxValues.price)
        state.showRangePrice = false;
      else
        state.showRangePrice = true;
    }
    state.filterSelected = null;
    state.tempRangeYear = null;
    state.tempRangeMile = null;
    state.tempRangePrice = null;
    state.showPopover    = false;

    this.setState(state);
    setCarFilters(carFiltersNew);
    search(carFiltersNew,filterSelected);
  }

  handleYearMilePriceOkTablet = (category) => {
    this.setState({filterSelected:category}, () => {
      this.handleYearMilePriceOk();
    });
  }

  onChangeCategory = (value,category) => {
    this.setState({[category]: value})
  }

  handleRangeChange = (type,value) => {
    let typeRange = "";
    let typeRangeTablet = "";

    if(type === "year"){
      typeRange = "tempRangeYear";
      typeRangeTablet = "tempRangeYearTablet";
    }else if(type === "mile"){
      typeRange = "tempRangeMile";
      typeRangeTablet = "tempRangeMileTablet";
    } else if(type === "price"){
      typeRange = "tempRangePrice";
      typeRangeTablet = "tempRangePriceTablet";
    }
    this.setState({[typeRange]: value, [typeRangeTablet]: value});
  }


  searchInactiveInventory = (showActive) => {
    let query = clone(this.props.carFilters);
    query.active = !showActive

    this.setState({showOnlyInactive: showActive})
    this.props.search(query)
  }

  getPillStatus = (statusList) => {
    let pillStatus = null
    let statusPillSelected = null
    pillStatus = statusList.map((statusSelected, index) => {
      const statusFound = formStatus.find(val => {
        return val.value === statusSelected
      });
      if (statusFound && statusFound.value && statusFound.value.length > 5 && window.innerWidth > WIDTH_VIEW_MOBILE) {
        statusPillSelected = statusFound.value.substring(0, 5) + "...";
      } else {
        statusPillSelected = statusFound.value;
      }
      return (
        <DPPill key={statusSelected} id={statusSelected} index={index} classContainer="pill-container"
                handleDeleteItem={this.handleDeleteItem}
                displayValue={statusPillSelected} name="status"/>
      )
    })
    return  pillStatus
  }

  render() {
    let {
      showPopover, anchorEl, step, filterSelected, showPopoverStatus, showMakePopover, showModelPopover, selectedMakes,
      selectedModels,selectedLocations, showRangeYear, showRangeMile, showRangePrice, lastFilterSelected, showPopoverLocation,
      tempRangeYear, tempRangeMile, tempRangePrice, tempRangeYearTablet, tempRangeMileTablet, tempRangePriceTablet, showOnlyInactive, modelsByMakeIds} = this.state;
    const {models, makes, disabledFilterProps,showFilterSection, locations, carFilters,action, showOnlyFastFilters, showCopiedRow} = this.props;
    let disabledFilter = disabledFilterProps &&
      ((carFilters.makes && carFilters.makes.length > 0) ||
        (carFilters.status && carFilters.status.length > 0) ||
        (carFilters.locations && carFilters.locations.length > 0)) &&
      (lastFilterSelected !== "year" && lastFilterSelected !== "mile" && lastFilterSelected !== "price");
    let childRange = null;
    let rangeValues = null;

    if (filterSelected) {
      let category = "";
      if (filterSelected === "year" || filterSelected === "yearMobile" ) {
        rangeValues = tempRangeYear ? tempRangeYear : carFilters.year;
        category = "year";
      } else if (filterSelected === "mile" || filterSelected === "mileMobile") {
        rangeValues = tempRangeMile ? tempRangeMile : carFilters.mile;
        category = "mile";
      } else if (filterSelected === "price" || filterSelected === "priceMobile" ) {
        rangeValues = tempRangePrice ? tempRangePrice : carFilters.price;
        category = "price";
      }

      if(!isEmpty(category)){
        childRange = (
          <div>
            <div className="slider-container">
              <InputRange maxValue={category !== "" ? carFilters.maxValues[filterSelected] : 20}
                          minValue={category !== "" ? carFilters.minValues[filterSelected] : 0}
                          step={step}
                          value={rangeValues ? rangeValues : {min: carFilters.minValues[filterSelected], max: carFilters.maxValues[filterSelected]}}
                          onChange={value => this.handleRangeChange(filterSelected, value)}/>
            </div>
            <div className="slider-value">
              <label>{rangeValues ? rangeValues.min : carFilters.minValues[filterSelected]}</label>
              <label className="value-max">{rangeValues ? rangeValues.max : carFilters.maxValues[filterSelected]}</label>
            </div>
            <div className="btn-content-filter-pie">
              <a onClick={this.cleanFilterRanges}>Clear Filter</a>
              <button className="btn-primary" onClick={this.handleYearMilePriceOk}>Ok</button>
            </div>
          </div>
        );
      }
    }

    const childStatus = this.renderStatusOptions();
    const childMake   = this.renderMakeOptions();
    const childModel  = this.renderModelOptions();
    const childLocations = this.renderLocationsOptions();

    let statusPillSelected = null;
    let makePillSelected = null;
    let modelPillSelected = null;
    let locationPillSelected = null;
    let statusList = carFilters.status ? carFilters.status : [];

    return (
      <div>
        {
          showFilterSection ?
            <div className="panel-filter">
              <div className="rowFilter">
                <div className="col-left">
                  <div className="box-left">
                    <div id="status" className="category-status" onClick={(e) => this.handlePopoverStatus("status", e)}>
                      {
                        (statusList.length > 0) ?
                          (window.innerWidth > WIDTH_VIEW_MOBILE) ?
                            (statusList.length <= 2) ?
                              this.getPillStatus(statusList)
                              :
                              <DPPill id="Status" index={1} classContainer="pill-container-group"
                                      handleDeleteItem={this.clearStatusFilter}
                                      displayValue={statusList.length + " Selected"} name="selectedStatus"
                              />
                            :
                            <div className="container-pills">
                              {this.getPillStatus(statusList)}
                            </div>
                          :
                          <label>Select a Status</label>
                      }
                      {filterSelected === "status" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                      }
                    </div>
                  </div>
                  <div className="box-right">
                    <div id="locations" className="category-locations" onClick={(e) => this.handlePopoverLocations("locations", e)}>
                      {
                        (carFilters.locations && carFilters.locations.length > 0 && carFilters.locations.length <= 2) ?
                          carFilters.locations.map((locationSelected, index) => {
                            const locationFound = locations.find(val => {return val.locationId === locationSelected});
                            if (locationFound.name.length > 5) {
                              locationPillSelected = locationFound.name.substring(0, 5) + "...";
                            } else {
                              locationPillSelected = locationFound.name;
                            }
                            return (
                              <DPPill id={locationSelected} index={index} classContainer="pill-container"
                                      handleDeleteItem={this.handleDeleteItem}
                                      displayValue={locationPillSelected} name="locations"/>
                            )
                          })
                          : (carFilters.locations && carFilters.locations.length > 2) ?
                          <DPPill key="Locations" index={1} classContainer="pill-container-group pill-locations-group"
                                  handleDeleteItem={this.clearLocationsFilter}
                                  displayValue={carFilters.locations.length + " Locations"} name="selectedLocations"/>
                          :
                          <label>All Locations</label>
                      }
                      {filterSelected === "locations" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                      }
                    </div>
                  </div>
                  {
                    showOnlyFastFilters &&
                    <div onClick={() => this.searchInactiveInventory(!showOnlyInactive)} className={showOnlyInactive ? "hide-inactive" : "show-inactive"}>
                      {
                        showOnlyInactive ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_hide_eye.svg"} beforeInjection={ svg => { svg.classList.add("ic-hide-inactive")}} />
                          : <ReactSVG src={pathServer.PATH_IMG + "icon/eye-view.svg"} beforeInjection={ svg => { svg.classList.add("ic-show-inactive")}} />
                      }
                      {showOnlyInactive ?  "Hide" : "Show"} inactive cars
                    </div>
                  }
                  { showOnlyFastFilters && showCopiedRow && <div className="copied-row-alert">Row copied!</div>}
                </div>
                {!showOnlyFastFilters && window.innerWidth >= 950 &&
                <div className="col-middle">
                  <div className="box-left">
                    <div id="selectedMakes" className="category"
                         onClick={(e) => this.handleMultiselectPopover("selectedMakes", e)}>
                      {
                        (carFilters.makes && carFilters.makes.length > 0 && carFilters.makes.length <= 2) ?
                          carFilters.makes.map((selectedMake, index) => {
                            const makeFound = makes.find(val => {
                              return val.id === selectedMake
                            });
                            if (makeFound.displayValue.length > 5) {
                              makePillSelected = makeFound.displayValue.substring(0, 4) + "...";
                            } else {
                              makePillSelected = makeFound.displayValue;
                            }
                            return (
                              <DPPill id={selectedMake} index={index} classContainer="pill-container"
                                      handleDeleteItem={this.handleDeleteItem}
                                      displayValue={makePillSelected} name="makes"/>
                            )
                          })
                          : (carFilters.makes && carFilters.makes.length > 2) ?
                          <DPPill index={0} classContainer="pill-container-group"
                                  handleDeleteItem={this.clearMakeFilter}
                                  displayValue={carFilters.makes.length + " Makes"} name="selectedMakes"/>
                          :
                          <label className="cursor-pointer">Make</label>
                      }
                      {filterSelected === "selectedMakes" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                      }
                    </div>
                  </div>
                  <div className="box-right">
                    <div id="selectedModels" className={!!!modelsByMakeIds.length ? "category disabled" : "category"}
                         onClick={(e) => this.handleMultiselectPopover("selectedModels", e)}>
                      {
                        (carFilters.models && carFilters.models.length > 0 && carFilters.models.length <= 2) ?
                          carFilters.models.map((selectedModel, index) => {
                            const modelFound = models.find(val => {
                              return val.id === selectedModel
                            });
                            if (modelFound.displayValue.length > 5) {
                              modelPillSelected = modelFound.displayValue.substring(0, 4) + "...";
                            } else {
                              modelPillSelected = modelFound.displayValue;
                            }
                            return (
                              <DPPill id={selectedModel} index={index} classContainer="pill-container"
                                      handleDeleteItem={this.handleDeleteItem}
                                      displayValue={modelPillSelected} name="models"/>
                            )
                          })
                          : (carFilters.models && carFilters.models.length > 2) ?
                          <DPPill key="Models" index={1} classContainer="pill-container-group"
                                  handleDeleteItem={this.clearModelFilter}
                                  displayValue={carFilters.models.length + " Models"} name="selectedModels"/>
                          :
                          <label>Model</label>
                      }
                      {filterSelected === "model" ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                      }
                    </div>
                  </div>
                </div>
                }
                {!showOnlyFastFilters &&
                <div className="col-right">
                  { !showOnlyFastFilters && showCopiedRow && <div className="copied-row-alert">Row copied!</div>}
                  <div className="reset-all-filters">
                    <a id="reset-all-filters" onClick={this.cleanAllFilters}>
                      <label>Clear Filters</label>
                    </a>
                  </div>
                </div>
                }
              </div>
              {
                (window.innerWidth > WIDTH_VIEW_MOBILE && window.innerWidth < 950) && !showOnlyFastFilters &&
                <div className="rowFilter second-row-filter">
                  <div className="col-middle">
                    <div className="box-left">
                      <div id="selectedMakes" className="category"
                           onClick={(e) => this.handleMultiselectPopover("selectedMakes", e)}>
                        {
                          (carFilters.makes && carFilters.makes.length > 0 && carFilters.makes.length <= 2) ?
                            carFilters.makes.map((selectedMake, index) => {
                              const makeFound = makes.find(val => {
                                return val.id === selectedMake
                              });
                              if (makeFound.displayValue.length > 5) {
                                makePillSelected = makeFound.displayValue.substring(0, 4) + "...";
                              } else {
                                makePillSelected = makeFound.displayValue;
                              }
                              return (
                                <DPPill id={selectedMake} index={index} classContainer="pill-container"
                                        handleDeleteItem={this.handleDeleteItem}
                                        displayValue={makePillSelected} name="makes"/>
                              )
                            })
                            : (carFilters.makes && carFilters.makes.length > 2) ?
                            <DPPill index={0} classContainer="pill-container-group"
                                    handleDeleteItem={this.clearMakeFilter}
                                    displayValue={carFilters.makes.length + " Makes"} name="selectedMakes"/>
                            :
                            <label className="cursor-pointer">Make</label>
                        }
                        {filterSelected === "selectedMakes" ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                        }
                      </div>
                    </div>
                    <div className="box-right">
                      <div id="selectedModels" className="category"
                           onClick={(e) => this.handleMultiselectPopover("selectedModels", e)}>
                        {
                          (carFilters.models && carFilters.models.length > 0 && carFilters.models.length <= 2) ?
                            carFilters.models.map((selectedModel, index) => {
                              const modelFound = models.find(val => {
                                return val.id === selectedModel
                              });
                              if (modelFound.displayValue.length > 5) {
                                modelPillSelected = modelFound.displayValue.substring(0, 4) + "...";
                              } else {
                                modelPillSelected = modelFound.displayValue;
                              }
                              return (
                                <DPPill id={selectedModel} index={index} classContainer="pill-container"
                                        handleDeleteItem={this.handleDeleteItem}
                                        displayValue={modelPillSelected} name="models"/>
                              )
                            })
                            : (carFilters.models && carFilters.models.length > 2) ?
                            <DPPill key="Models" index={1} classContainer="pill-container-group"
                                    handleDeleteItem={this.clearModelFilter}
                                    displayValue={carFilters.models.length + " Models"} name="selectedModels"/>
                            :
                            <label>Model</label>
                        }
                        {filterSelected === "model" ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                    className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }

              {!showOnlyFastFilters &&
              <div>
                <div className="row-filters-expanded">
                  <ChildRangeTablet category={"price"} className={"container-filter-price"} carFilters={carFilters} handleRangeChange={this.handleRangeChange}
                                    step={100} cleanFilterRangesExpanded={this.cleanFilterRangesExpanded} handleYearMilePriceOkTablet={this.handleYearMilePriceOkTablet}
                                    values={tempRangePriceTablet}/>
                  <ChildRangeTablet category={"year"} className={"container-filter-year"} carFilters={carFilters} handleRangeChange={this.handleRangeChange}
                                    step={1} cleanFilterRangesExpanded={this.cleanFilterRangesExpanded} handleYearMilePriceOkTablet={this.handleYearMilePriceOkTablet}
                                    values={tempRangeYearTablet}/>
                  <ChildRangeTablet category={"mile"} className={"container-filter-mile"} carFilters={carFilters} handleRangeChange={this.handleRangeChange}
                                    step={5000} cleanFilterRangesExpanded={this.cleanFilterRangesExpanded} handleYearMilePriceOkTablet={this.handleYearMilePriceOkTablet}
                                    values={tempRangeMileTablet}/>
                </div>
                <div className="row-first-filters-mobile">
                  <div id="statusMobile" className="category" onClick={(e) => this.handlePopoverStatus("statusMobile", e)}>
                    {
                      (statusList.length > 0 && statusList.length <= 2) ?
                        statusList.map((statusSelected, index) => {
                          const statusFound = formStatus.find(val => {
                            return val.value === statusSelected
                          });
                          if (statusFound.value.length > 5 && window.innerWidth > WIDTH_VIEW_MOBILE) {
                            statusPillSelected = statusFound.value.substring(0, 5) + "...";
                          } else {
                            statusPillSelected = statusFound.value;
                          }
                          return (
                            <DPPill key={statusSelected} id={`${statusSelected}-mobile`} index={index} classContainer="pill-container"
                                    handleDeleteItem={this.handleDeleteItem}
                                    displayValue={statusPillSelected} name="status"/>
                          )
                        })
                        : statusList.length > 2 ?
                        <DPPill id="Status" index={1} classContainer="pill-container-group"
                                handleDeleteItem={this.clearStatusFilter}
                                displayValue={statusList.length + " Selected"} name="selectedStatus"/>
                        :
                        <label>Status</label>
                    }
                    {filterSelected === "status" ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                    }
                  </div>
                  <div id="locationsMobile" className="category" onClick={(e) => this.handlePopoverLocations("locationsMobile", e)}>
                    {
                      (selectedLocations.length > 0 && selectedLocations.length <= 2) ?
                        selectedLocations.map((locationSelected, index) => {
                          const locationFound = locations.find(val => {
                            return val.locationId === locationSelected
                          });
                          if (locationFound.name.length > 5) {
                            locationPillSelected = locationFound.name.substring(0, 5) + "...";
                          } else {
                            locationPillSelected = locationFound.name;
                          }
                          return (
                            <DPPill id={`${locationSelected}-mobile`} index={index} classContainer="pill-container"
                                    handleDeleteItem={this.handleDeleteItem}
                                    displayValue={locationPillSelected} name="locations"/>
                          )
                        })
                        : selectedLocations.length > 2 ?
                        <DPPill key="Locations" index={1} classContainer="pill-container-group"
                                handleDeleteItem={this.clearStatusFilter}
                                displayValue={selectedLocations.length + " Locations"} name="selectedLocations"/>
                        :
                        <label>All Locations</label>
                    }
                    {filterSelected === "locations" ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                    }
                  </div>
                  <div id="selectedMakesMobile" className="category"
                       onClick={(e) => this.handleMultiselectPopover("selectedMakesMobile",e)}>
                    {
                      (selectedMakes.length > 0 && selectedMakes.length <= 2) ?
                        selectedMakes.map((selectedMake, index) => {
                          const makeFound = makes.find(val => {
                            return val.id === selectedMake
                          });
                          if (makeFound.displayValue.length > 5) {
                            makePillSelected = makeFound.displayValue.substring(0, 4) + "...";
                          } else {
                            makePillSelected = makeFound.displayValue;
                          }
                          return (
                            <DPPill id={`${selectedMake}-mobile`} index={index} classContainer="pill-container"
                                    handleDeleteItem={this.handleDeleteItem}
                                    displayValue={makePillSelected} name="makes"/>
                          )
                        })
                        : selectedMakes.length > 2 ?
                        <DPPill index={0} classContainer="pill-container-group" handleDeleteItem={this.clearMakeFilter}
                                displayValue={selectedMakes.length + " Makes"} name="selectedMakes"/>
                        :
                        <label className="cursor-pointer">Make</label>
                    }
                    {filterSelected === "selectedMakes" || filterSelected === "selectedMakesMobile" ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                    }
                  </div>
                </div>
                <div className="row-second-filters-mobile">
                  <div id="selectedModelsMobile" className="category"
                       onClick={(e) => this.handleMultiselectPopover("selectedModelsMobile", e)}>
                    {
                      (selectedModels.length > 0 && selectedModels.length <= 2) ?
                        selectedModels.map((selectedModel, index) => {
                          const modelFound = models.find(val => {
                            return val.id === selectedModel
                          });
                          if (modelFound.displayValue.length > 5) {
                            modelPillSelected = modelFound.displayValue.substring(0, 4) + "...";
                          } else {
                            modelPillSelected = modelFound.displayValue;
                          }
                          return (
                            <DPPill id={`${selectedModel}-mobile`} index={index} classContainer="pill-container"
                                    handleDeleteItem={this.handleDeleteItem}
                                    displayValue={modelPillSelected} name="models"/>
                          )
                        })
                        : selectedModels.length > 2 ?
                        <DPPill key="Models" index={1} classContainer="pill-container-group"
                                handleDeleteItem={this.clearModelFilter}
                                displayValue={selectedModels.length + " Models"} name="selectedModels"/>
                        :
                        <label>Model</label>
                    }
                    {(filterSelected === "selectedModels"  || filterSelected === "selectedModelsMobile") && showModelPopover?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                    }
                  </div>
                  <DPFilter id="yearMobile" disabledFilter={disabledFilter} handlePopover={this.handlePopover}
                            showRange={showRangeYear} values={carFilters.year ? carFilters.year : null}
                            displayValue="Year" filterSelected={filterSelected} previousLabel={''} nextLabel={''} carFilters={carFilters}/>
                  <DPFilter id="mileMobile" disabledFilter={disabledFilter} handlePopover={this.handlePopover}
                            showRange={showRangeMile} values={carFilters.mile ? carFilters.mile : null}
                            displayValue="Mileage (mi)" filterSelected={filterSelected} previousLabel={''} nextLabel={'mi'} carFilters={carFilters}/>
                </div>
                <div className="row-clean-filter">
                  <DPFilter id="priceMobile" disabledFilter={disabledFilter} handlePopover={this.handlePopover}
                            showRange={showRangePrice} values={carFilters.price ? carFilters.price : null}
                            displayValue="Price ($)" filterSelected={filterSelected} previousLabel={'$'} nextLabel={''} carFilters={carFilters}/>
                  <a onClick={this.cleanAllFilters}>
                    <label>Clear Filters</label>
                  </a>
                </div>
              </div>
              }
            </div> : null
        }
        {
          showMakePopover ?
            <Popover anchorEl={anchorEl}
                     width={245}
                     child={childMake}
                     classPopover="popover-box-category"
                     handleRequestClose={this.handleRequestClose}/>
            : null
        }
        {
          showModelPopover ?
            <Popover anchorEl={anchorEl}
                     child={childModel}
                     width={245}
                     classPopover="popover-box-category"
                     handleRequestClose={this.handleRequestClose}/>
            : null
        }
        {
          showPopover && !disabledFilter ?
            <Popover anchorEl={anchorEl}
                     child={childRange}
                     classPopover="popover-box-category"
                     handleRequestClose={this.handleRequestClose}/>
            : null
        }
        {
          showPopoverStatus ?
            <Popover anchorEl={anchorEl}
                     child={childStatus}
                     width={showOnlyFastFilters ? 190 : 245}
                     classPopover="popover-box-category"
                     handleRequestClose={this.handleRequestClose}/>
            : null
        }
        {

          showPopoverLocation ?
            <Popover anchorEl={anchorEl}
                     child={childLocations}
                     width={showOnlyFastFilters ? 190 : 245}
                     classPopover="popover-box-category"
                     handleRequestClose={this.handleRequestClose}/>
            : null
        }
      </div>
    )
  }
}