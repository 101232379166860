import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {
  saleStatus,
  ATTRIBUTE_CODE_CAR_TRIM,
  ATTRIBUTE_CODE_CAR_BODY,
  ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR,
  ATTRIBUTE_CODE_CAR_TITLE,
  FILE_TEMPLATE_BUYER_AGREEMENT_CODE,
  ATTRIBUTE_CODE_CAR_CONDITION,
  FILE_TEMPLATE_TAX_EXEMPTION_CODE,
  FILE_TEMPLATE_TITLE_AGREEMENT_CODE,
  FORM_SALE_FINANCE_APPLICATION,
  FILE_TEMPLATE_BUYER_GUIDE_CODE,
  FORM_SALE_BUYER_AGREEMENT,
  FORM_SALE_TITLE_AGREEMENT,
  FORM_BUYER_GUIDE,
  COST_REPAIR_PART,
  COST_REPAIR_LABOR,
  GOOGLE_REVIEW_URL,
  CODE_NUMBER_PHONE_EEUU,
  FILE_TEMPLATE_RETAIL_INSTALLMENT_CONTRACT_CODE,
  FORM_RETAIL_INSTALLMENT_CONTRACT,
  CODE_ODOMETER_CONTRACT,
  CODE_CERTIFIED_OF_INSPECTION,
  CODE_AGREEMENT_TO_PROVIDE_INSURANCE, CODE_NOTICE_TO_COSIGNER,
  CODE_REFERENCE_PROVIDER, CODE_AUTHORIZATION_FOR_PAYOFF,
  CODE_WHOLESALE_DEALER, CODE_STATEMENT_OF_ACCEPTANCE, CODE_TRANSACTION_DISCLOSURE_FORM,
  CODE_NOTICE_OF_SALVAGE_OR_BRANDED_TITLE
} from '../../Constants'
import {Popover} from '../util/ComponentUtils'
import {browserHistory} from 'react-router'
import {
  loadSaleConfigurations,
  cancelSale,
  deleteSale,
  loadSaleById,
  loadAllUserActive,
  updateSale,
  loadBalanceSummary,
  startCompletion,
  endCompletion,
  getServerUrl, updateTemporaryCar,
  findFileTemplateAndForm,
  loadCarByVin,
  updateCheckFromFinancier,
  updateSalesmanId
} from '../../Api'
import {connect} from 'react-redux'
import {
  setSaleFilters,
  setMenuId,
  setCarStatusOptions,
  loadLocations,
  loadMakeAndModelAll
} from '../../actions'
import SaleTradeIn from './SaleTradeIn.jsx'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import isEmpty from 'lodash/isEmpty'
import SalePurchaser from './SalePurchaser.jsx'
import {
  calculateHeightDifference,
  getInputValueString,
  parseDynamicNumberValue,
  formatDecimal,
  getDynamicNumberValue, getAnchorEl
} from '../util/Utils'
import {BodyReason, renderReason, BodyOptions, getFirstText} from './Sales.jsx'
import ModalConfirmDelete from '../util/ModalConfirmDelete.jsx'
import CarSearchPage from '../customer/CarSearchPage.jsx'
import SaleFinances from './SaleFinances.jsx'
import SaleBalance from './SaleBalance.jsx'
import SaleFiles from './SaleFiles'
import SaleReloadFilesPage from './SaleReloadFilePage/SaleReloadFilesPage.jsx'
import SaleChecklist from './SaleChecklist'
import SaleCar from './SaleCar'
import {DPMessageBar} from '../../common/DPMessageBar'
import {cloneDeep, findIndex} from 'lodash'
import {ModalEndSale} from './ModalEndSale.jsx'
import {DPPopover} from '../../../common/DPPopover'
import Moment from 'moment/moment'
import {ModalGeneratePreviousPDF} from './ModalGeneratePreviousPDF.jsx'
import Form from '../form/FormRender'
import DPCheckbox from '../../common/DPCheckbox'
import InventoryCar from '../inventory/InventoryCar'
import {setTaskFilters} from '../../actions'
import {usdNumberToFormat} from '../../Utils'
import isNumber from 'lodash/isNumber'
import {DPMessageDelete} from '../../common/DPMessageDelete'
import {TITLE_DELETE_SALE} from './Sales'
import DPKeyboardDatePicker from '../../common/DPKeyboardDatePicker'
import {getPosition} from '../../../common/Util'
import {
  pathServer, SUCCESSFUL_CODE,
  WIDTH_VIEW_MOBILE,
  WIDTH_VIEW_TABLET,
} from '../../../common/Constants'

const defaultNewCost = {taskId:"", description: "", labor: '', part: '', receipts: []}

const FIELDS_NAME = {
  TEMPORARY: 'temporary',
};

const listTools = [
  {id:"0", code: FILE_TEMPLATE_BUYER_AGREEMENT_CODE, name:"Generate Contract Of Sale", form: FORM_SALE_BUYER_AGREEMENT},
  {id:"1", code: FILE_TEMPLATE_TAX_EXEMPTION_CODE, name:"Generate Sales and Tax Exemption", form: ""},
  {id:"2", code: FILE_TEMPLATE_TITLE_AGREEMENT_CODE, name:"Generate Title Agreement", form: FORM_SALE_TITLE_AGREEMENT},
  {id:"3", code: FILE_TEMPLATE_BUYER_GUIDE_CODE, name:"Generate Buyer Guide", form: FORM_BUYER_GUIDE},
  {id:"4", code: FILE_TEMPLATE_RETAIL_INSTALLMENT_CONTRACT_CODE, name:"Generate Retail Installment Contract", form: FORM_RETAIL_INSTALLMENT_CONTRACT},
  {id:"5", code: CODE_ODOMETER_CONTRACT, name:"Generate Odometer Contract", form: CODE_ODOMETER_CONTRACT},
  {id:"6", code: CODE_CERTIFIED_OF_INSPECTION, name:"Generate Certificate Of Inspection"},
  {id:"7", code: CODE_AGREEMENT_TO_PROVIDE_INSURANCE, name:"Generate Agreement to Provide Insurance"},
  {id:"8", code: CODE_NOTICE_TO_COSIGNER, name:"Generate Notice to Cosigner"},
  {id:"9", code: CODE_REFERENCE_PROVIDER, name:"Generate Agreement to Provide References", form: CODE_REFERENCE_PROVIDER},
  {id:"10", code: CODE_AUTHORIZATION_FOR_PAYOFF, name:"Generate Authorization for payoff"},
  {id:"11", code: CODE_WHOLESALE_DEALER, name:"Generate Dealer Wholesale Agreement"},
  {id:"12", code: CODE_STATEMENT_OF_ACCEPTANCE, name:"Generate Statement of Acceptance, Waiver and Declination"},
  {id:"13", code: CODE_TRANSACTION_DISCLOSURE_FORM, name:"Generate Transaction Disclosure Form"},
  {id:"14", code: CODE_NOTICE_OF_SALVAGE_OR_BRANDED_TITLE, name:"Generate Notice of Salvage Vehicle or Branded Title"},
];

export const PAGES = {
  MAIN: 'mainPage',
  SELECT_CAR: 'selectCarFromInventory',
  NOT_IN_INVENTORY_YET: 'notInInventoryYet',
  RELOAD_FILES_PAGE: 'reloadFilesPage',
  BALANCE_DETAIL: 'balanceDetail'
};

export const TitleHeader = ({classTitle, icon, classIcon, classIconSvg, classIconSvgCompleted, onclick, title, selectOption, titleRight,
                              classTitleRight, classButton, rightElement, mainDivOnClick, readOnly}) => {
  return(
    <div className={classTitle} onClick={mainDivOnClick}>
      <ReactSVG src={`${pathServer.PATH_IMG}${icon}`} className={classIcon}
                beforeInjection={svg => !selectOption ? svg.classList.add(classIconSvg) : svg.classList.add(classIconSvgCompleted)}/>
      <label>{title}</label>
      {
        selectOption && onclick && !readOnly ?
          <button className={classTitleRight} onClick={onclick ? onclick : null} disabled={readOnly}>
            <label className={classButton}>{titleRight}</label>
          </button> : null
      }
      {
        rightElement ?
          rightElement
          : null
      }
    </div>
  )
};

const Spinner = ({style}) => {
  return(
    <div className="salesman-assigned">
      <div className="loader-container">
        <div className="loader" style={style}/>
      </div>
    </div>
  )
};

function getHeaderTitle(contentPage) {
  switch (contentPage) {
    case PAGES.MAIN :
      return '';
    case PAGES.SELECT_CAR :
      return 'Inventory';
    case PAGES.NOT_IN_INVENTORY_YET :
      return 'Car not in inventory';
    case PAGES.BALANCE_DETAIL :
      return 'Balance';
    default :
      return '';
  }
}

const Button = ({classWrapper,classButton,onClick,disabled,displayValue,testId = 'button'}) => {
  return(
    <div className={classWrapper}>
      <button
        className={classButton}
        onClick={onClick}
        disabled={disabled}
        data-test={testId}>
        {displayValue}
      </button>
    </div>
  )
};

const RowSaleHistory = ({displayValue,value, id}) => {
  return(
    <div>
      <label className={'sale-history-title'}>{displayValue}</label>
      <label id={id} className={'value'}>{value}</label>
    </div>
  )
};

const BodyRightModal = ({save, onHide, isLoading}) => {
  return(
    <div className="container-panel">
      <div className="panel-right panel-add-car-to-inventory" style={ isLoading ? {opacity: "0.5"} : {}}>
        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                  onClick={onHide}/>
        <div className="titles">
          <span className="title">Do you want to add this car to the inventory?</span>
          <span className="subtitle">You can edit/remove this car anytime on inventory</span>
        </div>
        <button onClick={save} className={"btn-save-custom"}>Add to inventory</button>
      </div>
    </div>
  )
};

export const FormNewBuyerAgreement = Form({
  getTextAreaInput(input,value,onChange,onBlurInput,index){
    const {id, showRow, displayValue,placeholder} = input;
    return (
      <div key={'field_'+id}>
        <div className={showRow} >
          {
            displayValue ?
              <div className="saleInfoLabelArea">
                <label>
                  {displayValue}
                </label>
              </div>: null
          }
          <div className="classSaleHeaderInput">
            <textarea className="form-control"
                      id={id}
                      ref={id}
                      name={id}
                      onBlur={() => onBlurInput(id)}
                      onChange={() => onChange(id,index)}
                      value={getInputValueString(value)}
                      rows={3} style={{resize:"none"}}
                      placeholder={placeholder ? placeholder : "Ex. No money back. No return. No warranty"}/>
          </div>
        </div>
      </div>
    )
  },
  getTextInput(attribute, value, elementHasValidation, onChange, onBlur, index, error, getValidationMessages) {
    const {displayValue, inputType, id, maxLength, readOnly} = attribute;
    return (
      <div key={id}>
        <div className={"carInfoLabel"}>
          {
            displayValue ?
              <div className="label-finance">
                <label>{displayValue}</label>
                {elementHasValidation ? <span className="label-error">*</span>: null}
              </div> : null
          }
          <div className="classInputSaleInfo">
            <input type={inputType.toLowerCase()}
                   className="form-control"
                   id={id} ref={id} name={id} value={getInputValueString(value)}
                   onChange={() => onChange(id, index)}
                   onBlur={() => onBlur(id)}
                   placeholder={"Type Here"}
                   disabled={readOnly}
                   maxLength={maxLength ? maxLength : null}/>
            {error ?
              <p className="help-block">{getValidationMessages(attribute, error)}</p> : null}
          </div>
        </div>
      </div>
    )
  },
  getSelectInput(input,classNameLabel,elementHasValidation,
                 classNameInput,idSelected,showDropDown,onChangeDropDown,index,error,
                 getValidationMessages,options,_onChange,anchorEl){
    const foundValue = options.find(op => {return op.id.toString() === idSelected.toString()});
    const {id, displayValue, showRow, showLabel, defaultSelect, name} = input;
    let popover;

    let classNameItem = "";
    const child = (
      <div className="popover-general-dp">
        <div className="popover-general-dp-sub">
          <u>
            {
              options.map((op,index) => {
                if(foundValue && op.displayValue === foundValue.displayValue){
                  classNameItem = "item-selected";
                } else {
                  classNameItem = "change-submenu-item";
                }
                return(
                  <li key={index} onClick={() => _onChange(id,index,op.id)} className={classNameItem}>
                    {op.displayValue}
                  </li>
                )
              })
            }
          </u>
        </div>
      </div>
    );

    popover = (
      <Popover anchorEl={anchorEl}
               child={child}
               classPopover="popover-box-modal"
               handleRequestClose={onChangeDropDown}/>
    );

    return (
      <div key={'field_'+id} className={(error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className={classNameLabel ? classNameLabel:" "}>
                <label htmlFor={id}
                       className={showLabel}>
                  {displayValue}
                </label>
              </div>: null
          }
          <div className={(classNameInput ? classNameInput:" ")}>
            <a className="btn-basic btn-select-option" onClick={(e) => onChangeDropDown(e,id)}>
              <span>{foundValue ? foundValue.displayValue:defaultSelect}</span>
              {
                (showDropDown) ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />
              }
            </a>
            {
              showDropDown ? popover : null
            }
          </div>
        </div>
      </div>
    )
  },
  getDateInput(attribute, dateObject, elementHasValidation, onChange, onBlur, index, error, getValidationMessages) {
    const {displayValue, showRow, id, readOnly} = attribute;
    const defaultDate = new Date()
    const newDate = (defaultDate.valueOf() === dateObject.valueOf()) ? null : dateObject

    return (
      <div key={id} >
        <div className={"saleInfoLabel"}>
          {
            displayValue ?
              <div className="label-finance">
                <label>{displayValue}</label>
                {elementHasValidation ? <span className="label-error">*</span>: null}
              </div> : null
          }
          <div className="">
            <DPKeyboardDatePicker
              id={"dateId"}
              ref={id}
              name={id}
              className="form-control"
              value={newDate}
              onChange={(date) => onChange(id, index, null, date)}
              format={'MM/DD/YYYY'}
            />
            {error ?
              <p className="help-block">{getValidationMessages(attribute, error)}</p> : null}
          </div>
        </div>
      </div>
    )
  },
  getMultiSelectInput(input,classNameLabel,elementHasValidation, classNameInput,value,index,error,
                 getValidationMessages,options,_onChange){

    const {id, displayValue, showRow, showLabel, inputType} = input;

    return (
      <div key={'field_'+id} className={(error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className={"label-multi-select"}>
                <label htmlFor={id}
                       className={showLabel}>
                  {displayValue}
                </label>
              </div>: null
          }
          <div className={(classNameInput ? classNameInput:" ")}>
            {
              options.map((op,index) => {
                const checked = findIndex(value, (val) => { return val === op.id });
                return (
                  <div key={index}
                       className="content-check">
                    <DPCheckbox
                      checked={checked !== -1}
                      id={op.id}
                      onCheck={() => _onChange(id,op.id,inputType)}
                    />
                    <div className="container-label">
                      <p className="check-description">{op.displayValue}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
});

export const FileEmpty = ({title,onClick}) => {
  return (
    <div>
      <label className={'file-empty-title'}>{title}</label>
      <span className={'file-empty-link'}>Please go to <a onClick={onClick}>Sale settings</a> to add items</span>
    </div>
  )
}

export function calculateBalanceDue(balanceInfo) {
  const {carPrice, cashPayment, tradeInTotal, rebates, checkFromFinancier} = balanceInfo;

  const total = parseDynamicNumberValue(carPrice) - (parseDynamicNumberValue(cashPayment) + parseDynamicNumberValue(tradeInTotal) +
    parseDynamicNumberValue(rebates) + parseDynamicNumberValue(checkFromFinancier))

  let totalValue = "";
  if(total === 0){
    totalValue = "0.00";
  } else{
    totalValue = formatDecimal(total);
  }

  return totalValue
}

function getNameCustomers(customersSale) {
  let customersName = ""
  const numberOfCustomers = customersSale.length;
  let phoneNumbers = [];
  let emails = []
  if(numberOfCustomers === 1){
    customersName = customersSale[0].firstName + " " + customersSale[0].lastName;
    const phoneNumber = CODE_NUMBER_PHONE_EEUU + customersSale[0].phoneNumber;
    phoneNumbers.push(phoneNumber);
    if(!isEmpty(customersSale[0].email))
      emails.push(customersSale[0].email)
  } else{
    customersSale.forEach((customer,index) => {
      const name = customer.firstName + " " + customer.lastName;
      const phoneNumber = CODE_NUMBER_PHONE_EEUU + customer.phoneNumber;
      phoneNumbers.push(phoneNumber)
      if(!isEmpty(customer.email))
        emails.push(customer.email)

      if(index === 0){
        customersName = name
      } else if(index === (numberOfCustomers - 1)){
        customersName = customersName + " and " + name
      } else{
        customersName = name + ", " + customersName
      }
    })
  }
  return {
    customersName: customersName,
    phoneNumbers: phoneNumbers,
    emails: emails
  }
}

class SaleEdit extends Component{
  constructor(){
    super();
    this.state = {
      selectOpened: "",
      paymentMethodSelected: "cash",
      selectCustomer: false,
      selectCoCustomer: false,
      isLoading: false,
      saleCar: {}, // Car selected from inventory
      saleChecklist: [],  // Current Sale's checklist
      checklist: null,
      dealerFiles: null,
      saleNotes: [],
      showModalCancel: false,
      existReason: false,
      isRequired: false,
      optionsCancelSale: null,
      reasonSelected: null,
      isLoadingModal: false,
      sale: null,
      showInfoCustomer: false,
      customerSelected: {},
      editCredit: false,
      saleId: null, // Current Sale ID
      widthPage: window.innerWidth,
      contentPage: PAGES.MAIN,
      tradeIns: [],
      saleFilters: {},
      users: null,
      isLoadingSalesman: false,
      purchaserFinances: [],
      isFinanceView: false,
      customersSale: [], // Contains purchaser and cosigners if the latter exist
      isLoadingCustomers: false,
      balance: {},
      isComplete: true,
      existPurchaser: true,
      existCar: true,
      isCarSold: true,
      existFiles: true,
      existChecklist: true,
      messageBar: "",
      showModalEndSale: false,
      finalComments: null,
      financeSelected: [],
      carToInsert: [],
      tradeInSelected: [],
      showMessage: false,
      showPopoverFinished: false,
      showModalGeneratedPDF: false,
      isLoadingModalGenerate: false,
      templates: [],
      saleInfoAttributes: [],
      isLoadingAddCarToInventory: false,
      isLoadingCarBox: false,
      hasChangeCar: false, // It is used on InventoryCar
      carFilters: {}, // It is used on InventoryCar
      hasChangeImage: false, // It is used on InventoryCar
      hasChangeCost: false, // It is used on InventoryCar
      hasLinkToTask: false, // It is used on InventoryCar
      openTaskReceipt: false, // It is used on InventoryCar
      costs: [], // It is used on InventoryCar
      indexCost: 0, // It is used on InventoryCar
      error: null,
      isEmptyPassword: false,
      passwordToDelete: null,
      editCheckFromFinancier: false,
      balanceDue: "0.00",
      sentSMS: true,
      sentEmail: true,
      customersName: "Javier Leiva, Rafael Maldonado and Aditya Mishra",
      urlGoogle: ""
    };
    this.myRef = React.createRef();
  }

  componentWillMount() {
    const {saleId} = this.props.params;
    this.handleHeightDifference();
    this.setState({isLoading: true, isLoadingSalesman : true});
    loadSaleConfigurations().then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        const checklist = data.checklist;
        const dealerFiles = data.files;
        const cancelReasons = data.reasons;
        let templates = data.templates;
        const dealerConfigurations = data.dealerConfigurations;
        let optionsCancelSale = [];
        if(cancelReasons){
          const values = JSON.parse(cancelReasons.value);
          values.forEach((reason,index) => {
            optionsCancelSale.push({index:index,value:reason});
            if(index === values.length - 1){
              optionsCancelSale.push({index:index + 1,value:"Other (Describe Below)"});
            }
          });
        }
        const existReason = (optionsCancelSale.length > 0);
        templates = templates.filter(template => template.code !== FORM_SALE_FINANCE_APPLICATION)
        const urlDealerConfiguration = dealerConfigurations.filter(dealerConfig => { return dealerConfig.code === GOOGLE_REVIEW_URL})
        let urlGoogle = ""
        if(!isEmpty(urlDealerConfiguration)){
          urlGoogle = urlDealerConfiguration[0].value;
        }
        let sentSMS = true
        let sentEmail = true
        if (isEmpty(urlGoogle)){
          sentSMS = false
          sentEmail = false
        }
        this.setState({dealerFiles, checklist, optionsCancelSale, existReason, saleId, templates, urlGoogle, sentSMS, sentEmail});
      }else{
        console.error(responseMessage);
      }
    })

    this.loadSaleInfo()

    this.loadingLocationsInfo();

    const {makesAll, loadMakeAndModelAll} = this.props;
    if(!makesAll)
      loadMakeAndModelAll().then(response => {});
  }

  loadCar = (vin) => {
    if(vin) {
      loadCarByVin(vin)
        .then(({responseCode, responseMessage, data}) => {
          if (responseCode === SUCCESSFUL_CODE){
            this.loadCost(data.costs);
          }else{
            console.error(responseMessage)
          }
        })
    }
  }

  loadCost = (repairs) => {
    let arrCost = []
    if (repairs.length > 0) {
      repairs.map(r => {
        const objCost = {}
        objCost.description = r.repair.description
        objCost.id = r.repair.id
        objCost.carId = r.repair.carId
        objCost.receipts = r.receipts
        objCost.taskId = r.taskId
        objCost.total = 0
        if (r.costs.length > 0) {
          let total = 0
          r.costs.map(c => {
            switch (c.type) {
              case COST_REPAIR_PART:
                objCost.part = usdNumberToFormat(c.value)
                objCost.partId = c.id
                break
              case COST_REPAIR_LABOR:
                objCost.labor = usdNumberToFormat(c.value)
                objCost.laborId = c.id
                break
              default :
                objCost.part = c.value
                objCost.partId = c.id
            }
            total += c.value
          })
          objCost.total = total
        }
        else {
          objCost.part = ''
          objCost.labor = ''
        }
        arrCost.push(objCost)
      })
    } else {
      arrCost = [defaultNewCost]
    }
    this.setState({costs: arrCost})
  }

  // This is an internal method
  // Loads sale info based on sale ID.
  loadSaleInfo() {
    const { saleId } = this.props.params;
    const { saleFilters } = this.props;

    this.updateSaleInfo(saleId, saleFilters)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {children} = this.props;
    const state = {};
    state.isComplete = true;
    if(children !== nextProps.children){
      state.isFinanceView = false
    }
    this.setState(state)
  }

  // Just in case there is no locations on redux store
  loadingLocationsInfo = () => {
    if (isEmpty(this.props.locations)) {
      this.props.loadLocations()
        .catch(error => console.error(error));
    }
  }

  updateOnlyCarSale = (saleId) => {
    this.setState({isLoadingCarBox: true});
    loadSaleById(saleId).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        const { sale: { saleCar } } = data
        this.setState({saleCar,isLoadingCarBox:false})
      }else {
        console.error("error:", responseMessage);
        this.setState({isLoadingCarBox: false})
      }
    })
  };

  updateSaleInfo = async (saleId, saleFilters) => {
    this.startLoadingPage()
    // this.setState({isLoading: true});
    const {responseCode, responseMessage, data} = await loadSaleById(saleId)
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      this.setState({ isLoading: false, saleId })
      return
    }
    const {
      sale,
      sale: { saleCar },
      saleNotes,
      tradeIns,
      saleChecklist,
      balance,
      saleHistory,
      customers
    } = data
    const customersSale = cloneDeep(customers)
    const selectCustomer = !isEmpty(customersSale)
    const selectCoCustomer = (customersSale.length > 1)
    this.loadCar(saleCar && saleCar.vin)
    const state = {
      sale,
      saleCar,
      saleNotes,
      tradeIns,
      saleChecklist,
      customersSale,
      selectCustomer,
      selectCoCustomer,
      isLoading: false,
      saleFilters,
      isLoadingSalesman: false,
      balance,
      isComplete: !(saleCar?.isSold && sale.status === saleStatus.IN_PROGRESS),
      messageBar: saleCar?.isSold ? 'Please complete the required items, vehicle was sold.' : ''
    }

    const {responseCode: responseCodeUser, responseMessage: responseMessageUser, data: dataUser} = await loadAllUserActive()
    if (responseCodeUser !== SUCCESSFUL_CODE) {
      console.error(responseMessageUser)
      this.setState(state)
      return
    }
    const {users} = dataUser
    let userSelected = {}
    const saleHistoryToSave = {}
    if (sale.status === saleStatus.CANCELLED) {
      const saleHistoryFound = saleHistory.find(sh => {
        return sh.statusTo === saleStatus.CANCELLED
      })
      const saleHistoryFoundCompleted = saleHistory.find(sh => {
        return sh.statusTo === saleStatus.COMPLETED
      })
      if (saleHistoryFound) {
        saleHistoryToSave.createDate = saleHistoryFound.createDate
        saleHistoryToSave.modifiedDate = saleHistoryFound.modifiedDate
        saleHistoryToSave.reason = saleHistoryFound.reason.trim()
        saleHistoryToSave.userId = saleHistoryFound.submitterId
        saleHistoryToSave.statusFrom = saleHistoryFound.statusFrom
        saleHistoryToSave.statusTo = saleHistoryFound.statusTo
      }
      if (saleHistoryFoundCompleted){
        saleHistoryToSave.createDateCompleted = saleHistoryFoundCompleted.createDate
        saleHistoryToSave.modifiedDateCompleted = saleHistoryFoundCompleted.modifiedDate
        saleHistoryToSave.reasonCompleted = sale.finalComments ? sale.finalComments.trim() : ''
        saleHistoryToSave.userIdCompleted = saleHistoryFoundCompleted.submitterId
        saleHistoryToSave.statusFromCompleted = saleHistoryFoundCompleted.statusFrom
      }
    } else if (sale.status === saleStatus.COMPLETED) {
      //todo: adding sale history when the sale is completed
      saleHistoryToSave.createDate = sale.modifiedDate
      saleHistoryToSave.reason = sale.finalComments ? sale.finalComments.trim() : ''
      saleHistoryToSave.userId = sale.createdBy
    }
    users.forEach(user => {
      if (user.id === sale.salesmanId)
        userSelected = user
      if (saleHistoryToSave.userId && user.id === saleHistoryToSave.userId)
        saleHistoryToSave.user = `${user.firstName} ${user.lastName}`
      if (saleHistoryToSave.userIdCompleted === user.id)
        saleHistoryToSave.userCompleted = `${user.firstName} ${user.lastName}`
    })

    this.setState({...state, users, userSelected, saleHistory: saleHistoryToSave,})
  }

  startLoadingPage () {
    const { isLoading } = this.state;

    if (!isLoading) {
      this.setState({ isLoading: true })
    }
  }

  componentDidMount() {
    const {pathname} = this.props.location;
    const isUrlBalance = pathname.includes('balance');
    if(isUrlBalance)
      this.setState({contentPage: PAGES.BALANCE_DETAIL});
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    const {pathname} = this.props.location
    const isUrlBalance = pathname.includes('balance');
    const {contentPage} = this.state

    if (contentPage === PAGES.BALANCE_DETAIL && !isUrlBalance) {
      this.setState({contentPage: PAGES.MAIN})
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({widthPage: window.innerWidth}, () =>{this.handleHeightDifference()});
  }

  goToSale = () => {
    browserHistory.push({pathname: `/main/sale`});
  }

  goBack = () => {
    const { saleId, sale } = this.state;
    const { sourcePage } = this.props.location.state || {}

    switch (this.state.contentPage) {
      case PAGES.MAIN:
        this.setState({ contentPage: PAGES.MAIN })
        if ( sourcePage === 'customerPage') {
          this.props.setMenuId("customer");
          browserHistory.goBack()
        } else {
          browserHistory.push({ pathname: `/main/sale` });
        }
        break;
      default:
        this.setState({ contentPage: PAGES.MAIN })
        break;
    }
  }

  showModalCancelSale = () => {
    this.setState({showModalCancel: true})
  }

  handleCheckReason = (index, checked, evt) =>{
    const reasonSelected = (checked === -1) ? evt.target.id : null;
    const indexSelected = index;
    this.setState({reasonSelected, indexSelected, isRequired: false});
  }

  onChangeInput = (e) => {
    const otherReason = e.target.value;
    this.setState({otherReason});
  }

  onBlurInput = (e) => {
    const otherReason = e.target.value;
    this.setState({otherReason});
  }

  confirmCancelSale = () => {
    const {optionsCancelSale, reasonSelected, indexSelected, otherReason} = this.state;
    if(indexSelected === optionsCancelSale.length - 1 || optionsCancelSale.length === 0){
      this.cancelSale(otherReason);
    } else {
      this.cancelSale(reasonSelected);
    }
  }

  cancelSale = (reason) => {
    const {saleId} = this.state;
    if(isEmpty(reason) || isEmpty(reason.trim())){
      this.setState({isRequired:true});
    } else {
      this.setState({isLoadingModal: true})
      const reasonSaleCancel = {reason:reason};
      cancelSale(saleId,reasonSaleCancel).then(({responseCode, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({showModalCancel: false, reasonSelected: null, indexSelected: null, isLoadingModal:false}, () => {
            browserHistory.push({pathname: `/main/sale`});
          });
        }else{
          console.error(responseMessage);
          this.setState({isLoadingModal:false});
        }
      })
    }
  }

  closeModalCancelSale = () => {
    this.setState({showModalCancel: false, showModalDelete: false, reasonSelected: null, indexSelected: null, error: null,
      isEmptyPassword: false, passwordToDelete: null})
  }

  deleteSale = () => {
    const {saleId, passwordToDelete} = this.state;
    if(saleId && passwordToDelete && !isEmpty(passwordToDelete)){
      this.setState({isLoadingModal: true})
      deleteSale(saleId, passwordToDelete).then(({responseCode, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({showModalDelete: false, reasonSelected: null, indexSelected: null, isLoadingModal: false,
            isEmptyPassword: false, error: null, passwordToDelete: null}, () => {
            browserHistory.push({
              pathname: `/main/sale`,
              state:{isDeleted: true}
            });
          });
        }else{
          console.error(responseMessage);
          this.setState({isLoadingModal:false, error: responseMessage});
        }
      })
    } else{
      this.setState({isEmptyPassword:true})
    }
  }

  showInfoCustomer = (customer) => {
    this.setState({showInfoCustomer: true, customerSelected: customer})
  }

  handleEditCredit = () => {
    const {editCredit} = this.state;
    this.setState({editCredit:!editCredit})
  }

  onChangeCreditScore = (name, e) => {
    let {customerSelected} = this.state;
    customerSelected[name] = e.target.value;
    this.setState({customerSelected})
  }

  closeInfoCustomer = () => {
    this.setState({showInfoCustomer: false, customerSelected: {}})
  }

  showModalDelete = () => {
    this.setState({showModalCancel: false, showModalDelete: true})
  }

  // This is an internal method
  // Returns an array of current customers
  getCustomersFromState() {
    const { customersSale } = this.state
    const purchaser = customersSale && customersSale.find(el => el.principal)
    const cosigners = customersSale && customersSale.filter(el => !el.principal)
    const purchaserData = purchaser
      ? [{
        customerId: purchaser.id,
        principal: purchaser.principal,
        creditScore: purchaser.creditScore,
        order: purchaser.order
      }]
      : []

    const cosignersData = cosigners.map(el => (
      {
        customerId: el.id,
        principal: el.principal,
        creditScore: el.creditScore,
        order: el.order
      }
    ))

    return ([ ...purchaserData, ...cosignersData ])
  }

  // This is an internal method
  // Updates current sale based on sale ID and some data
  updateCurrentSale(data) {
    const { sale } = this.state

    this.startLoadingPage()

    updateSale(sale.id, data)
      .then(({responseCode, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          this.setState(
            {
              contentPage: PAGES.MAIN,
            },
            () => this.loadSaleInfo(sale.id)
          )
        }else{
          console.error(responseMessage)
          this.setState({ isLoading: false })
        }
      })
  }

  // Updates current sale based on a car selected from Inventory List
  handleSelectCar = (selectedCar) => {
    const { sale } = this.state
    const { salesmanId } = sale

    const data = {
      customers: this.getCustomersFromState(),
      salesmanId,
      carId: selectedCar.id,
    }

    this.updateCurrentSale(data)
  }

  handleHeightDifference = () => {
    const { widthPage } = this.state;
    let heightDifference = calculateHeightDifference(50,30);
    if( widthPage <= WIDTH_VIEW_TABLET && widthPage > WIDTH_VIEW_MOBILE){
      heightDifference = calculateHeightDifference(50,20);
    }else if(widthPage <= WIDTH_VIEW_MOBILE){
      heightDifference = calculateHeightDifference(50,10);
    }
    this.setState({heightDifference})
  }

  renderDropDownFilter = (type, event) => {
    event.preventDefault();
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target, 2);
    this.setState({showPopover: true, selectOpened: type, anchorEl: anchorEl})
  }

  handlePopoverClose = () => {
    this.setState({showPopover: false, selectOpened: "", showPopoverFinished: false});
  }

  onClickPopover = (id) => {
    let {userSelected, users, saleId} = this.state;

    users.forEach(user => {
      if (user.id === id){
        userSelected = user;
      }
    });

    const data = {salesmanId:userSelected.id};
    this.setState({isLoadingSalesman: true, showPopover: false});
    updateSalesmanId(saleId,data).then(({responseCode, responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({userSelected: userSelected, selectOpened: "", isLoadingSalesman: false});
      }else{
        console.error(responseMessage);
        this.setState({showPopover: false,isLoadingSalesman:false});
      }
    })
  }

  showFinanceView = (message) => {
    this.setState({isFinanceView: true, headerMessage: message })
  }

  updatePurchaserAndCosigners = (customersSale) => {
    this.setState({customersSale});
  }

  goToSaleBoard = (from) => {
    const {saleId} = this.state;
    //const {saleFilters} = this.props;
    this.setState({contentPage: PAGES.MAIN});
    browserHistory.push({pathname:`/main/sale/${saleId}`});
    if(from && from === PAGES.BALANCE_DETAIL){
      loadBalanceSummary(saleId).then(({responseCode, responseMessage, data}) => {
        if (responseCode === SUCCESSFUL_CODE){
          this.setState({balance: data.balance})
        }else{
          console.error(responseMessage)
        }
      })
    }
    //--this.updateSaleInfo(saleId,saleFilters)
  }

  showBalanceDetail = (readOnly) => {
    const {saleId} = this.state;
    this.setState({contentPage: PAGES.BALANCE_DETAIL});
    browserHistory.push({pathname: `/main/sale/${saleId}/balance`, state:{readOnly}});
  };

  saveBalanceDetail = (evt) => {
    evt.stopPropagation();
    if(this.myRef.current){
      this.myRef.current.save();
    }
  };

  showReloadFilesPage = () => {
    this.setState({
      contentPage: PAGES.RELOAD_FILES_PAGE,
    })
  }

  setPage = (page) =>{
    this.setState({contentPage: page, isComplete: true})
  };

  startCompletion = () => {
    const {saleId, customersSale} = this.state;

    this.setState({isLoading: true});
    startCompletion(saleId).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        const carInfo = data.completeSale.car;
        const balanceInfo = data.completeSale.balance;
        const tradeInInfo = data.completeSale.tradeIn;
        const financeInfo = data.completeSale.finance;
        const balanceDue = calculateBalanceDue(balanceInfo);
        const {customersName,phoneNumbers,emails} = getNameCustomers(customersSale);
        this.setState({isLoading: false, showModalEndSale: true, isComplete: true, carInfo, balanceInfo, tradeInInfo, financeInfo, balanceDue,
          customersName: customersName, phoneNumbers: phoneNumbers, emails: emails});
      }else{
        this.setState({isLoading: false, isComplete: false, existCar: data.completeSale.car, existPurchaser: data.completeSale.purchaser, isCarSold: data.completeSale.carSold,
          existFiles: data.completeSale.files, existChecklist: data.completeSale.checklist, messageBar: responseMessage});
      }
    })
  }

  closeMessageBar = () => {
    this.setState({isComplete: true, existPurchaser: true, existCar: true, existFiles: true, existChecklist: true})
  }

  closeModalEndSale = () => {
    this.setState({showModalEndSale: false, financeSelected: [], carToInsert: [], tradeInSelected: [], finalComments:"", showMessage: false,
      showModalGeneratedPDF: false})
  }

  handleCheckFinance = (financeId) => {
    let {financeSelected} = this.state;
    let financeSelectedUpdated = [...financeSelected]
    const indexFound = findIndex(financeSelected, (id) => { return id === financeId});
    if(indexFound === -1){
      financeSelectedUpdated.push(financeId)
    } else{
      financeSelectedUpdated.splice(indexFound,1);
    }
    this.setState({financeSelected: financeSelectedUpdated})
  }

  selectCar = (tradeIn) => {
    let {carToInsert, tradeInSelected} = this.state;
    let newCar = {};
    const tradeInSelectedUpdate = [...tradeInSelected];
    const carToInsertUpdate = [...carToInsert]
    const indexFound = findIndex(tradeInSelectedUpdate, (id) => { return id === tradeIn.id});
    if(indexFound === -1){
      tradeInSelectedUpdate.push(tradeIn.id);
      newCar.saleTradeInId = tradeIn.id;
      newCar.makeId = tradeIn.makeId;
      newCar.modelId = tradeIn.modelId;
      newCar.vin = tradeIn.vin;
      newCar.year = tradeIn.year;
      newCar.mileage = tradeIn.mileage;
      const trimId = tradeIn.values[ATTRIBUTE_CODE_CAR_TRIM].attributeId;
      const titleId = tradeIn.values[ATTRIBUTE_CODE_CAR_TITLE].attributeId;
      const bodyId = tradeIn.values[ATTRIBUTE_CODE_CAR_BODY].attributeId;
      const exteriorColorId = tradeIn.values[ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR].attributeId;
      const titleValue = [];
      titleValue.push(tradeIn.values[ATTRIBUTE_CODE_CAR_TITLE].attributeValueId);
      const bodyValue = [];
      bodyValue.push(tradeIn.values[ATTRIBUTE_CODE_CAR_BODY].attributeValueId);
      const exteriorColorValue = [];
      exteriorColorValue.push(tradeIn.values[ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR].attributeValueId);
      let condition = "";
      const conditionValue = []
      if(tradeIn.values[ATTRIBUTE_CODE_CAR_CONDITION]){
        condition = tradeIn.values[ATTRIBUTE_CODE_CAR_CONDITION].attributeId;
        conditionValue.push(tradeIn.values[ATTRIBUTE_CODE_CAR_CONDITION].attributeValueId);
      } else{
        conditionValue.push("0");
      }
      newCar.carValues = {
        [trimId]: tradeIn.values[ATTRIBUTE_CODE_CAR_TRIM].attributeValue,
        [titleId]: titleValue,
        [bodyId]: bodyValue,
        [exteriorColorId]: exteriorColorValue,
        [condition ? condition : "0"]: conditionValue
      }
      carToInsertUpdate.push(newCar)
    } else{
      tradeInSelectedUpdate.splice(indexFound,1);
      carToInsertUpdate.splice(indexFound,1);
    }

    this.setState({carToInsert : carToInsertUpdate, tradeInSelected: tradeInSelectedUpdate})
  }

  onChangeComment = (e) => {
    const finalComments = e.target.value;
    this.setState({finalComments});
  }

  endCompletion = () => {
    let {carToInsert, financeSelected, finalComments, saleId, sentSMS, sentEmail, customersName, urlGoogle,
      phoneNumbers, emails} = this.state;
    const data = {};
    if(!isEmpty(carToInsert)){
      data.car = carToInsert;
    }
    if(!isEmpty(financeSelected)){
      data.financeId = financeSelected;
    }
    if(!isEmpty(finalComments)){
      data.finalComments = finalComments;
    }
    const message = "Thank you " + customersName + ", for getting your car with us. Please rate our service at " + urlGoogle
    if(sentSMS){
      const requestMessage = {};
      requestMessage.message = message;
      requestMessage.phoneNumbers = phoneNumbers
      data.sms = requestMessage
    }
    if(sentEmail && emails.length > 0){
      const requestEmail = {};
      requestEmail.message = message;
      requestEmail.emails = emails
      data.email = requestEmail
    }
    this.setState({isLoadingModal: true});
    endCompletion(saleId, data).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({showModalEndSale: false, isComplete: true, isLoadingModal:false}, () => {
          browserHistory.push({pathname: `/main/sale`});
        });
      }else{
        this.setState({isLoadingModal: false, isComplete: false, existCar: data.completeSale.car, existPurchaser: data.completeSale.purchaser,
          isCarSold: data.completeSale.carSold, existFiles: data.completeSale.files, existChecklist: data.completeSale.checklist, messageBar: responseMessage});
      }
    })
  }

  showMessage = (evt) => {
    evt.stopPropagation();
    let pointTo = getAnchorEl(evt.currentTarget,0);
    let anchorEl = {
      x: pointTo.x + 20,
      y: pointTo.y - 45,
      offsetWidth: 300
    };
    this.setState({showMessage: true, anchorEl})
  }

  handleRequestClose = () => {
    this.setState({showMessage: false})
  }

  generateFile = (evt,fileCode) => {
    if(fileCode === FILE_TEMPLATE_BUYER_AGREEMENT_CODE){
      const {saleId} = this.state;
      window.open(`${getServerUrl()}api/sales/${saleId}/tools/${fileCode}`, '_blank');
    } else {
      // work editable call toolsEditable when we will finish all documents we can remove if above.
      const {saleId} = this.state;
      window.open(`${getServerUrl()}api/sales/${saleId}/toolsEditable/${fileCode}`, '_blank');
    }
  };

  showPopoverFinished = (evt) => {
    evt.stopPropagation();
    let pointTo = getAnchorEl(evt.currentTarget,0);
    let anchorEl = {
      x: pointTo.x - 180,
      y: pointTo.y + 5,
      offsetWidth: 300
    };
    this.setState({anchorEl,showPopoverFinished: true})
  }

  openModalGeneratePDF = async (code, formCode, name, evt) => {
    evt.stopPropagation();
    if (code === FILE_TEMPLATE_BUYER_AGREEMENT_CODE) {
      let formRender = FormNewBuyerAgreement;
      let classModal = 'modal-generate'
      const {responseCode, responseMessage, data} = await findFileTemplateAndForm(code, formCode ? formCode : '_noForm')
      if (responseCode === SUCCESSFUL_CODE) {
        if (data.formExists) {
          if (code === FILE_TEMPLATE_BUYER_GUIDE_CODE) {
            classModal = 'modal-buyer-guide'
          }
          this.setState({
            showModalGeneratedPDF: true, formCode: formCode, formRender: formRender, templateCode: code,
            classModal: classModal, templateName: name
          });
        }
      } else {
        console.error(responseMessage)
      }
    } else {
      this.generateFile(evt, code)
    }
  }

  hideBar = () => {
    this.setState({isComplete: true})
  };

  addCarTemporaryToInventory = () => {
    const { saleId } = this.props.params;
    this.setState({isLoadingAddCarToInventory : true});
    const {saleCar} = this.state;
    updateTemporaryCar(saleCar.carId, false).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({isLoadingAddCarToInventory : false,showModalAddCarToInventory: false}, () => {
          this.updateOnlyCarSale(saleId)
        });
      } else {
      this.setState({isLoadingAddCarToInventory : false});
      }
    })
  };

  showModalAddCarToInventory = () => {
    this.setState({showModalAddCarToInventory: true})
  };

  closeModalModalAddCarToInventory = () => {
    this.setState({showModalAddCarToInventory: false})
  };

  goToSaleSetting = () => {
    window.open(`${getServerUrl()}main/setting/sales`, '_blank');
  };

  addNewCar = () => {
    const { saleId, sale } = this.state;
    const { salesmanId } = sale;
    const customers = this.getCustomersFromState();
    this.props.setMenuId("inventory");
    browserHistory.push({pathname: `/main/inventory/new`, state:{saleId: saleId, customers: customers, salesmanId: salesmanId}});
  }

  handleUpdateData = (id, newValue = true) => {
    this.setState({ [id]: newValue })
  }

  handleSaveOnInventoryCar = async () => {
    await this.inventoryCar.validationField();

    if(this.inventoryCar.state.showModalSave){
      this.setState({ showModalCarForm:true });
    } else {
      const el = document.getElementById('container');
      if(this.inventoryCar.state.errorCarInformation){
        el.scrollTo(0,0)
      } else if(this.inventoryCar.state.errorCarPricing){
        const sectionPricing = document.getElementById('containerPricing');
        const pointTo = getPosition(sectionPricing);
        if(!this.inventoryCar.state.section["carPricing"]){
          this.inventoryCar.accordionToggle("carPricing");
        }
        el.scrollTo(pointTo.x,pointTo.y)
      }
      this.setState({ showModalCarForm:false });
    }
  }

  hideModalCarFormChange = () => {
    this.setState({ showModalCarForm: false })
  }

  // This is an internal method used only on 'saveCarForm' method
  // Formats 'dataToSave'  as is expected before saves into DB
  formatDataToSaveBeforeSave = (dataToSave) => {
    const dataToSaveOriginalFormatKeys = Object.keys(dataToSave)
    let newDataToSave = {...dataToSave}
    return (
      dataToSaveOriginalFormatKeys.reduce((result, key) => {
        const specialKeys = ['order', 'mileage', 'suggestedPrice', 'retailPrice']
        let updatedValue = newDataToSave[key]

        if (specialKeys.includes(key) && isNumber(updatedValue)) {
          updatedValue = (key === 'order')
            ? updatedValue
            : updatedValue.toString()
        }

        if (specialKeys.includes(key) && isEmpty(updatedValue)) {
          updatedValue = (key === 'order') ? 0 : "0"
        }

        return {
          ...result,
          [key]: updatedValue,
        }
      }, newDataToSave)
    );
  }

  saveCarForm = () => {
    this.setState({showModalCarForm:false},async () => {
      if (!this.inventoryCar.state.hasError) {
        if (isEmpty(this.inventoryCar.state.values.id)) {
          let values = this.formatDataToSaveBeforeSave(this.inventoryCar.state.values)

          if (!values.hasOwnProperty(FIELDS_NAME.TEMPORARY)) {
            values[FIELDS_NAME.TEMPORARY] = true
          }

          const { salesmanId } = this.state.sale
          const data = {
            customers: this.getCustomersFromState(),
            salesmanId,
            car: values,
          }
          this.updateCurrentSale(data)
        } else{
          await this.inventoryCar.validationForms();
          this.loadSaleInfo(this.state.sale.id)
        }
      }
      //this.back();
    });
  }

  handleIndexToCost = (index) => {
    this.setState({ indexCost: index })
  }

  onChangePassword = (field,evt) => {
    this.setState({[field]:evt.target.value,isEmptyPassword:false,error:null});
  }

  changeCheckFromFinancier = (type,modelValue,viewValue,evt) => {
    const {balanceInfo} = this.state;
    balanceInfo[type] = getDynamicNumberValue(modelValue,viewValue);
    this.setState({balanceInfo: balanceInfo});
  }

  showEditCheck = () => {
    this.setState({editCheckFromFinancier: true});
  }

  updateCheckFromFinancier = () => {
    const {saleId, balanceInfo} = this.state;
    const {checkFromFinancier} = balanceInfo;
    updateCheckFromFinancier(saleId, checkFromFinancier).then(({responseCode, responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        const balanceDue = calculateBalanceDue(balanceInfo);
        this.setState({editCheckFromFinancier: false, balanceDue: balanceDue});
      } else {
        console.error(responseMessage)
      }
    })
  }

  handleCheckSection = (evt) => {
    let {sentSMS, sentEmail} = this.state;
    const id = evt.currentTarget.id;
    if(id === "sms"){
      sentSMS = !sentSMS
    } else{
      sentEmail = !sentEmail
    }
    this.setState({sentSMS, sentEmail})
  }

  render() {
    const {left,makesAll,modelsAll,height,saleFilters, carFilters, setMenuId, locations,
      attributeValuesToCarStatus, setCarStatusOptions, setTaskFilters, taskFilter,
    } = this.props;
    const {selectOpened, selectCustomer, selectCoCustomer, checklist, isLoading, dealerFiles,showModalCancel,
      existReason, isRequired, isLoadingModal, sale, optionsCancelSale, reasonSelected, otherReason,
      indexSelected, showModalDelete, heightDifference, tradeIns, saleId, contentPage, saleCar, customersSale,
      showPopover, anchorEl, users, userSelected, isLoadingSalesman, isFinanceView,headerMessage, balance, isComplete,
      existCar, existPurchaser, isCarSold, existFiles, existChecklist, messageBar, showModalEndSale,
      carInfo, balanceInfo, tradeInInfo, financeInfo, finalComments, financeSelected, tradeInSelected,
      showMessage, showPopoverFinished,saleHistory, showModalGeneratedPDF, formCode, formRender,
      templateCode, classModal,templateName, showModalAddCarToInventory, isLoadingAddCarToInventory, isLoadingCarBox,
      hasChangeCar, showModalCarForm, hasChangeImage, hasChangeCost, hasLinkToTask, openTaskReceipt, costs,
      isEmptyPassword, error, editCheckFromFinancier, balanceDue, sentSMS, sentEmail, customersName, urlGoogle} = this.state;
    if(!sale || !checklist)
      return null;
    const salesmanId = sale && sale.salesmanId ? sale.salesmanId : "";
    const saleTradeIns = [...tradeIns];
    const childReason = renderReason(optionsCancelSale, reasonSelected, otherReason, indexSelected, this.onChangeInput, this.handleCheckReason, this.onBlurInput);

    const messageBodyCustom = <DPMessageDelete isEmptyPassword={isEmptyPassword} title={TITLE_DELETE_SALE}
                                               onDelete={this.deleteSale} subMessage={"This action is irreversible"}
                                               onChangePassword={this.onChangePassword} error={error}/>;

    const isFinished = (sale.status === saleStatus.CANCELLED || sale.status === saleStatus.COMPLETED);
    const isCancelledSale = sale.status === saleStatus.CANCELLED

    return (
        <div className="sale-content sale-content-edit">
          <div className='container-header' style={{left:left}}>
            <div className="left">
              <ReactSVG
                src={pathServer.PATH_IMG + "icon/ic_arrow_back.svg"}
                beforeInjection={ svg => svg.classList.add('icon-back')}
                className="arrow-back"
                onClick={(isFinanceView || contentPage === PAGES.BALANCE_DETAIL) ? this.goToSaleBoard : this.goBack}
              />
              {
                contentPage === PAGES.SELECT_CAR &&
                <div className='action-add-car'>
                  <ReactSVG
                    src={pathServer.PATH_IMG + 'icon/ic_circle.svg'}
                    svgClassName='icon-add-svg'
                    className='icon-add-task'
                    onClick={this.addNewCar}
                  />
                </div>
              }
              {isFinanceView ? <label>{headerMessage}</label> : <label>{getHeaderTitle(contentPage)}</label>}
            </div>
            <div className="container-button-next">
              {
                !isFinanceView && contentPage !== PAGES.RELOAD_FILES_PAGE?
                  <div className="container-salesman">
                    <label className="title-salesman">Salesman:</label>
                    {
                      isLoadingSalesman ?
                        <Spinner style={{width: 25, height:25, border: "3px solid #f3f3f3", borderTop: "3px solid #3498db"}}/> :
                        <button id="assignedSalesmanId"
                                className="salesman-assigned"
                                onClick={(evt) => this.renderDropDownFilter("assignedSalesmanId", evt)}
                                aria-readonly={isFinished}
                                disabled={isFinished}>
                          <img className="salesman-img" src={userSelected.image ? userSelected.image : `${pathServer.PATH_IMG}avatar.png`} />
                          <label>{getFirstText(userSelected.firstName)} {getFirstText(userSelected.lastName)}</label>
                          {
                            !isFinished && (
                              selectOpened === "assignedSalesmanId" ?
                                <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                                          className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                                <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                                          className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                            )
                          }
                        </button>
                    }
                    {
                      showPopover ?
                        <Popover anchorEl={anchorEl}
                                 child={<BodyOptions users ={users} userSelected={userSelected} onClickPopover={this.onClickPopover}/>}
                                 classPopover="popover-box-message popover-users"
                                 handleRequestClose={this.handlePopoverClose}/> : null
                    }
                  </div> : null
              }
              {
                !isFinanceView &&
                !isFinished && contentPage !== PAGES.RELOAD_FILES_PAGE && contentPage !== PAGES.BALANCE_DETAIL
                && contentPage !== PAGES.SELECT_CAR  ?
                  <div className="container-button-cancel">
                    <Button classWrapper={'container-label-reject'}
                            classButton={'btn-cancel-sale'}
                            disabled={contentPage === PAGES.NOT_IN_INVENTORY_YET}
                            onClick={this.showModalCancelSale}
                            displayValue={'Cancel Sale'}
                            testId={'btn-cancel-sale'}/>
                    <Button classWrapper={'box-button'}
                            classButton={contentPage === PAGES.NOT_IN_INVENTORY_YET ? 'btn-done-disabled' : 'btn-done'}
                            disabled={contentPage === PAGES.NOT_IN_INVENTORY_YET}
                            onClick={this.startCompletion}
                            displayValue={'Complete Sale'}
                            testId={'btn-complete-sale'}/>
                  </div> :
                  isFinished && (
                    <div className="container-button-cancel">
                      <Button classWrapper={'box-button'}
                              classButton={'btn-done' + (sale.status === saleStatus.COMPLETED ? ' btn-completed' : ' btn-cancelled')}
                              disabled={false}
                              onClick={this.showPopoverFinished}
                              displayValue={sale.status === saleStatus.COMPLETED ? 'Sale completed' : 'Sale canceled'}/>
                      {
                        showPopoverFinished && (
                          <div className={'popover-container'}>
                            <DPPopover anchorEl={anchorEl}
                                       classNameWrapper="popover-box-message"
                                       handleRequestClose={this.handlePopoverClose}>
                              <div>
                                {(saleHistory.statusFrom === saleStatus.COMPLETED && saleHistory.statusTo === saleStatus.CANCELLED) && (
                                  <div id={'canceledCompleted'} className={'popover-body-sale-finished'}>
                                    <RowSaleHistory
                                      displayValue={'Completed on'}
                                      value={Moment(saleHistory.createDateCompleted).format('MM/DD/YYYY')}/>
                                    <RowSaleHistory
                                      displayValue={'Completed by'}
                                      value={saleHistory.userCompleted ? saleHistory.userCompleted : 'No User Found'}/>
                                    {
                                      !isEmpty(saleHistory.reason) && (
                                        <RowSaleHistory
                                          displayValue={'Final comments'}
                                          value={sale.finalComments ? sale.finalComments.trim() : 'No comments'}
                                          id={'completedCanceledReason'}/>
                                      )
                                    }
                                  </div>
                                )}
                              </div>
                              <div className={'popover-body-sale-finished'}>
                                <RowSaleHistory
                                  displayValue={sale.status === saleStatus.COMPLETED ? 'Completed on' : 'Cancelation date'}
                                  value={Moment(saleHistory.createDate).format('MM/DD/YYYY')}/>
                                <RowSaleHistory
                                  displayValue={sale.status === saleStatus.COMPLETED ? 'Completed by' : 'Canceled by'}
                                  value={saleHistory.user}/>
                                {
                                  !isEmpty(saleHistory.reason) && (
                                    <RowSaleHistory
                                      displayValue={sale.status === saleStatus.COMPLETED ? 'Final comments' : 'Cancelation reason'}
                                      value={saleHistory.reason}/>
                                  )
                                }
                              </div>
                            </DPPopover>
                          </div>
                        )
                      }
                    </div>
                  )
              }
              {
                contentPage === PAGES.BALANCE_DETAIL && !isFinished ?
                  <div className="container-button-cancel">
                    <Button classWrapper={'box-button'} classButton={'btn-done'} disabled={false} displayValue={'Save'}
                            onClick={this.saveBalanceDetail} testId="save-balance-btn"/>
                  </div> : null
              }
            </div>
          </div>
          {
            !isComplete ?
              <DPMessageBar label={messageBar} containerClassName={`message-bar-complete`}
                            icon={true} onClick={this.closeMessageBar}/> : null
          }
          {
            contentPage === PAGES.NOT_IN_INVENTORY_YET
              ? <InventoryCar
                vin={saleCar && saleCar.temporary ? saleCar.vin : "new"}
                car={saleCar && saleCar.temporary ? saleCar : ""}
                costs={costs}
                ref={el => this.inventoryCar = el}
                handleUpdateData={this.handleUpdateData}
                hasChangeCar={hasChangeCar}
                hasChangeImage={hasChangeImage}
                hasChangeCost={hasChangeCost}
                hasLinkToTask={hasLinkToTask}
                handleIndexToCost={this.handleIndexToCost}
                openTaskReceipt={openTaskReceipt}
                locations={locations}
                back={this.goBack}
                makes={makesAll}
                models={modelsAll}
                isEdit={true}
                hasChange={hasChangeCar || hasChangeImage || hasChangeCost}
                save={this.handleSaveOnInventoryCar}
                attributeValuesToCarStatus={attributeValuesToCarStatus}
                carFilters={null}
                setCarFilters={() => null}
                setCarStatusOptions={setCarStatusOptions}
                customerId={null}
                customerName={null}
                financeIdSelected={null}
                setTaskFilters={setTaskFilters}
                taskFilter={taskFilter}
                showFixedOptions={true}
                sale={sale}
                currentCustomers={this.getCustomersFromState}
                temporary={true}
              />
              : null
          }

        {
          contentPage === PAGES.RELOAD_FILES_PAGE
            ? <SaleReloadFilesPage
              saleId={sale.id}
              dealerSaleStaticFiles={dealerFiles}
            />
            : null
        }

          {
            this.props.children ?
              <div>
                {React.cloneElement(this.props.children, {height: height, left: left, users: users, ref: this.myRef,
                  showFinanceView: this.showFinanceView, goToSaleBoard: this.goToSaleBoard, salesmanId: salesmanId, readOnly: isFinished})}
              </div> :
              <div className="container-center" style={!isComplete ? {padding: "114px 25px 0 25px"} : {padding: "80px 25px 0 25px"}}>
                {
                  isLoading
                    ? <div className="loader-container">
                      <div className="loader"/>
                    </div>
                    : contentPage === PAGES.MAIN
                      ? <div className="panel-sale" style={{height:height - heightDifference}}>
                        <div className="panel-sale-left">
                          <div className="panel-sale-left-top">
                            <div className="content-panel">
                              <SalePurchaser
                                selectCoCustomer={selectCoCustomer}
                                selectCustomer={selectCustomer}
                                saleId={sale.id}
                                salesmanId={sale.salesmanId}
                                customersSale={customersSale}
                                updatePurchaserAndCosigners={this.updatePurchaserAndCosigners}
                                existPurchaser={existPurchaser}
                                isComplete={isComplete}
                                readOnly={isFinished}
                                setMenuId={setMenuId}
                              />
                            </div>
                            <div className="content-panel">
                              <div className="panel-sale-car-tools">
                                <SaleCar saleCar={saleCar} setPage={this.setPage} existCar={existCar} isComplete={isComplete}
                                         isCarSold={isCarSold} readOnly={isFinished} showModalAddCarToInventory={this.showModalAddCarToInventory}
                                         isLoadingCarBox={isLoadingCarBox}/>
                                <SaleFinances customersSale={customersSale} saleId={sale.id}
                                              showFinanceView={this.showFinanceView} readOnly={isFinished} hideBar={this.hideBar}/>
                              </div>
                            </div>
                            <div className="content-panel">
                              <div className="panel-sale-car-tools">
                                <SaleBalance balance={balance} saleId={saleId} saleCar={saleCar}
                                             showBalanceDetail={this.showBalanceDetail} readOnly={isFinished}/>
                                <SaleTradeIn tradeIns={saleTradeIns} makes={makesAll} models={modelsAll} saleId={saleId}
                                             updateSaleInfo={this.updateSaleInfo} saleFilters={saleFilters} readOnly={isFinished}
                                             carFilters={carFilters} setMenuId={setMenuId} hideBar={this.hideBar}/>
                              </div>
                            </div>
                            {/*<div className="content-panel-sale-notes">*/}
                            {/*  <SaleNote saleNotes={saleNotes} saleId={saleId}/>*/}
                            {/*</div>*/}
                          </div>
                          <div className="panel-sale-left-bottom">
                            <div className="content-panel">
                              <div className="panel-sale-tools">
                                <TitleHeader classTitle="title-tools" classIcon="icon-tools" classIconSvg="icon-tools-svg" icon="icon/ic_tools.svg" selectOption={false} title="Tools" />
                                <div className="tools-body">
                                  {
                                    listTools.map((template, index) => {
                                      return (
                                        <div
                                          key={`template${index}`}
                                          className={index % 2 === 0 ? 'tool-body-option' : 'tool-body-option-color'}
                                        >
                                          <label
                                            onClick={
                                              isCancelledSale
                                                ? null
                                                : (evt) => this.openModalGeneratePDF(template.code, template.form, template.name, evt)
                                            }
                                            className={`${isCancelledSale ? '' : 'selectable-label'}`}
                                          >
                                            {template.name}
                                          </label>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="content-panel">
                              <SaleFiles
                                  dealerFiles={dealerFiles}
                                  saleId={sale.id}
                                  showReloadFilesPage={this.showReloadFilesPage}
                                  existFiles={existFiles}
                                  isComplete={isComplete}
                                  goToSaleSetting={this.goToSaleSetting}
                                  isCancelledSale={isCancelledSale}
                              />
                            </div>
                            <div className="content-panel-check-list">
                              <SaleChecklist checklist={checklist}
                                             saleId={sale.id}
                                             existChecklist={existChecklist}
                                             isComplete={isComplete}
                                             goToSaleSetting={this.goToSaleSetting}
                                             isCancelledSale={isCancelledSale}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      : contentPage === PAGES.SELECT_CAR
                        ? <div className={'sales-container select-car-page-container'}>
                          <div className={'sales-body'}>
                            <CarSearchPage
                              startSale={(selectedCar) => this.handleSelectCar(selectedCar)}
                              errorMessage={''}
                              addNewCar={this.addNewCar}
                            />
                          </div>
                        </div>
                        : null
                }
              </div>
          }
          {
            showModalCancel ?
              <GenericModalConfirm show={showModalCancel}
                                   showCustomIcon={true}
                                   onHide={this.closeModalCancelSale}
                                   modalCancelSale={"modal-cancel-sale"}
                                   isLoading={isLoadingModal}>
                <BodyReason stylePanelRight={existReason ? {padding: "30px 20px"} : {padding: "55px 20px"}} childReason={childReason}
                            message={"Why are you canceling this Sale?"} isRequired={isRequired} onHide={this.closeModalCancelSale}
                            save={this.confirmCancelSale} buttonLabelSave={"Cancel Sale"} labelRequired={"Reason is required"}
                            buttonDelete="Delete Sale" deleteSale={this.showModalDelete} />
              </GenericModalConfirm>
              : null
          }
          {
            showModalDelete ?
              <ModalConfirmDelete show={showModalDelete}
                                  showButtonDelete={false}
                                  onSubmit={this.deleteSale}
                                  onHide={this.closeModalCancelSale}
                                  classButtonDelete="btn-delete"
                                  buttonLabelDelete="Yes, Delete"
                                  styleModal="modal-delete"
                                  styleRight="panel-right-delete"
                                  isLoading={isLoadingModal}
                                  isSale={true}
                                  body={messageBodyCustom}
                                  styleLeft="panel-left-delete"/> : null
          }
          { showModalEndSale ?
              <ModalEndSale show={showModalEndSale} onHide={this.closeModalEndSale} isLoading={isLoadingModal}
                            carInfo={carInfo} balanceInfo={balanceInfo} tradeInInfo={tradeInInfo} financeInfo={financeInfo}
                            finalComments={finalComments} financeSelected={financeSelected} handleCheckFinance={this.handleCheckFinance}
                            selectCar={this.selectCar} tradeInSelected={tradeInSelected} endCompletion={this.endCompletion}
                            onChange={this.onChangeComment} showMessageMethod={this.showMessage} showMessage={showMessage}
                            handleRequestClose={this.handleRequestClose} anchorEl={anchorEl} editCheckFromFinancier={editCheckFromFinancier}
                            changeCheckFromFinancier={this.changeCheckFromFinancier} showEditCheck={this.showEditCheck} balanceDue={balanceDue}
                            updateCheckFromFinancier={this.updateCheckFromFinancier} handleCheckSection={this.handleCheckSection}
                            sentSMS={sentSMS} sentEmail={sentEmail} customers={customersName} url={urlGoogle}/> : null
          }
          { showModalGeneratedPDF ?
            <ModalGeneratePreviousPDF show={showModalGeneratedPDF}
                                      onHide={this.closeModalEndSale}
                                      saleId={saleId}
                                      formCode={formCode}
                                      FormRender={formRender}
                                      templateCode={templateCode}
                                      classModal={classModal}
                                      templateName={templateName}
                                      balance={balance}
            /> : null
          }
          {
            showModalAddCarToInventory ?
              <GenericModalConfirm show={showModalAddCarToInventory}
                                   message={"Do you want to add this car to the inventory?"}
                                   onHide={this.closeModalModalAddCarToInventory}
                                   showButtonCancel={false}
                                   showCustomIcon={true}
                                   customIconLeft={"ic_question_warning.svg"}
                                   isLoading={isLoadingAddCarToInventory}
                                   classModal="modal-add-car-to-inventory"
              >
                <BodyRightModal save={this.addCarTemporaryToInventory} onHide={this.closeModalModalAddCarToInventory} isLoading={isLoadingAddCarToInventory}/>
              </GenericModalConfirm> : null
          }
          {
            showModalCarForm ?
              <GenericModalConfirm show={showModalCarForm}
                                   message={"Are you sure you want to save these changes?"}
                                   save={this.saveCarForm}
                                   onHide={this.hideModalCarFormChange}
                                   showButtonCancel={true}
                                   buttonLabelSave="Save"
                                   classButtonSave="btn-save"
              /> : null
          }
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    makesAll: state.makesAll,
    modelsAll: state.modelsAll,
    saleFilters: state.saleFilters,
    pageNumberSales: state.pageNumberSales,
    carFilters: state.carFilters,
    locations: state.locations,
    attributeValuesToCarStatus: state.attributeValuesToCarStatus,
    taskFilter: state.taskFilter,
  }
};

export default connect(mapStateToProps, {setSaleFilters, setMenuId, setCarStatusOptions, loadLocations, setTaskFilters, loadMakeAndModelAll})(SaleEdit);
