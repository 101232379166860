import React from 'react'
import {IndexRoute, Route} from 'react-router'
import Container from '../newComponents/superusers/Container'
import Users from '../newComponents/superusers/users'
import DealerInfo from '../newComponents/superusers/dealers/DealerInfo'
import FileTemplateEdit from '../newComponents/superusers/dealers/DealerInfo/FileTemplateEdit'
import Dealers from '../newComponents/superusers/dealers'
import Flags from '../newComponents/superusers/Flags'
import WebhookData from '../newComponents/superusers/WebhookData'
import PlansAndPackages from '../newComponents/superusers/plans'
import FlagsEdit from '../newComponents/superusers/FlagsEdit'

export default [
  <Route key={'superusers'} path="superusers" component={Container}>
    <Route path="users" component={Users}/>
    <Route path="dealers">
      <Route path="edit/:id" component={DealerInfo} />
      <Route path="edit/:id/fileTemplate/:fileTemplateId" component={FileTemplateEdit} />
      <IndexRoute component={Dealers}/>
    </Route>
    <Route path="flags">
      <Route path="edit/:flagName" component={FlagsEdit}/>
      <IndexRoute component={Flags}/>
    </Route>
    <Route path="webhookData" component={WebhookData}/>
    <Route path="plans" component={PlansAndPackages}/>
  </Route>
]
