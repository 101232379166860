import React from 'react'
import {ReactSVG} from 'react-svg'
import classnames from 'classnames'
import {pathServer} from './Constants'

require('./DPCheckDeployItem.scss')

const DPCheckDeployItem = (props) => {

  const {complete,title} = props
  const leftIconPath =  complete ? 'ic_checkmark-circle.svg' : 'ic_checkmark-circle-outline.svg'
  const activeClass = complete ? 'active' : null

  return(
    <div className="section-item-content">
      <div className={classnames(["left-part", activeClass])}>
        <ReactSVG
          src={`${pathServer.PATH_IMG}/icon/${leftIconPath}`}
          className='left-svg-image-container'
        />
        <div className='title-item-text'>{title}</div>
      </div>
      <div className="right-part">
        <ReactSVG
          src={`${pathServer.PATH_IMG}/icon/ic_right_arrow.svg`}
          className='right-svg-image-container'
        />
      </div>
    </div>
)
}

export default DPCheckDeployItem