import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  deleteCarImages,
  loadFormByCode,
  processFormApi,
  setAsMainImageApi,
  updateOrderImage,
  uploadCarImage,
  getDataFromVinNumber,
  loadAttributeValuesByAttributeCode,
  verifyVin,
  startSale,
  saveTaskCost,
  addReceiptRepair,
  deleteReceiptRepair,
  deleteTaskCostApi,
  updateSale,
  uploadCarDocument,
  savePost,
  getPostIdByCarId,
  updatePost,
  loadCredentialsByTypes,
} from '../../Api'
import {getRandomId, usdNumberToFormat} from '../../Utils'
import {
  ATTRIBUTE_CODE_SUGGESTED_PRICE,
  CAR_SECTION_CAR_PRICING,
  CAR_STATUS_CODE_FRONT_LINE_READY,
  EXTERIOR_COLOR_CODE,
  FORM_CAR_NEW,
  INTERIOR_COLOR_CODE,
  SUGGESTED_PRICE_REQUIRED,
  ATTRIBUTE_CODE_MAKE_ID,
  ATTRIBUTE_CODE_MODEL_ID,
  ATTRIBUTE_CODE_CAR_FUEL,
  ATTRIBUTE_CODE_CAR_BODY,
  ATTRIBUTE_CODE_CAR_INTERIOR_COLOR,
  ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR,
  ATTRIBUTE_CODE_CAR_CYLINDER,
  ATTRIBUTE_CODE_CAR_YEAR,
  ATTRIBUTE_CODE_CAR_TRIM,
  ATTRIBUTE_CODE_CAR_DOOR,
  ATTRIBUTE_CODE_CAR_TRANSMISSION,
  ATTRIBUTE_CODE_CAR_DRIVE,
  formCarApi,
  TAB_AGREEMENT,
  TASK_TYPE,
  COST_REPAIR_LABOR,
  COST_REPAIR_PART,
  CAR_TOTAL_COST,
  PURCHASED_PRICE, COST_SECTION_PERMISSION_ID, CAR_SECTION_INFORMATION,
  ATTRIBUTE_CODE_CAR_MARKETING_FACEBOOK,
  LOGO, ATTRIBUTE_CODE_CAR_MARKETING_INSTAGRAM,
  CAR_FIELDS_NAME, ATTRIBUTE_CODE_CAR_MARKETING_FBMARKETPLACE, ATTRIBUTE_CODE_CAR_CONDITION
} from '../../Constants'
import {map, cloneDeep, isArray, findIndex, isEmpty, clone, orderBy} from 'lodash'
import Form from '../form/FormRender.jsx'
import InventoryImage from './InventoryImage.jsx'
import {PopoverSearchable, Popover} from '../util/ComponentUtils'
import {browserHistory} from 'react-router'
import DPCost from '../../common/cost/DPCost'
import ModalConfirmDeactivate from '../util/ModalConfirmDeactivate'
import MessageBodyCustom from './MessageBodyCustom'
import UserHasPermission from '../UserHasPermission'
import Moment from 'moment/moment'
import Checkbox from 'material-ui/Checkbox'
import InventoryDocument from './InventoryDocument.js'
import {ReactSVG} from 'react-svg'
import {DPSectionHeader} from '../../common/DPSectionHeader'
import {DPSpinner} from '../../common/DPSpinner'
import get from 'lodash/get'
import {getPosition, parseObjectForm, parseValues, setErrors, validate} from '../../../common/Util'
import {elementCheck, elementUnCheck} from '../../../common/ComponentUtils'
import {cleanArray, formatMoney, formatToNumber, getErrorsInCosts} from '../util/Utils'
import {
  pathServer,
  VALIDATION_REQUIRED,
  ATTRIBUTE_CODE_CAR_STATUS,
  TIME_FIVE_SECONDS,
  RESPONSE_CODE_SUCCESS,
  SUCCESSFUL_CODE
} from '../../../common/Constants'
import {checkValidVIN} from '../../../common/ValidationUtil'

const NEW_CAR = 'new';

const OPTION = {
  ENABLE: 'enable',
  DISABLE: 'disable',
}

const SECTIONS = {
  INFO: "carInformation",
  MECHANICAL: "carMechanical",
  PRICING: "carPricing",
  COST: "carCost",
  IMAGES: "images",
  DOCS: "documents",
  MARKETING: "marketing",
}

const FormNew = Form({getSelectInput(input,classNameLabel,elementHasValidation,
                                     classNameInput,idSelected,showDropDown,onChangeDropDown,index,error,
                                     getValidationMessages,options,_onChange,anchorEl){

    const foundValue = options.find(op => {return op.id.toString() === idSelected.toString()});
    const {id, displayValue, showRow, showLabel, defaultSelect, name} = input;
    let popover;
    if(name === ATTRIBUTE_CODE_MAKE_ID || name === ATTRIBUTE_CODE_MODEL_ID || name === ATTRIBUTE_CODE_CAR_FUEL ||
      name === ATTRIBUTE_CODE_CAR_BODY || name === ATTRIBUTE_CODE_CAR_INTERIOR_COLOR || name === ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR){
      popover = <PopoverSearchable id={id} anchorEl={anchorEl} options={options} classPopover="" onChangeDropDown={onChangeDropDown} onChange={_onChange} foundValue={foundValue}/>
    }else{
      let classNameItem = "";
      const child = (
        <div className="popover-general-dp">
          <div className="popover-general-dp-sub">
            <u>
              {
                options.map((op,index) => {
                  if(foundValue && op.displayValue === foundValue.displayValue){
                    classNameItem = "item-selected";
                  } else {
                    classNameItem = "change-submenu-item";
                  }
                  return(
                    <li key={index} onClick={() => _onChange(id,index,op.id)} className={classNameItem}>
                      {op.displayValue}
                    </li>
                  )
                })
              }
            </u>
          </div>
        </div>
      );

      popover = (
        <Popover anchorEl={anchorEl}
                 child={child}
                 classPopover="popover-box-general"
                 handleRequestClose={onChangeDropDown}/>
      );
    }

    return (
      <div key={'field_'+id} className={(error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className={classNameLabel ? classNameLabel:" "}>
                <label htmlFor={id}
                       className={showLabel}>
                  {displayValue}
                </label>
                {elementHasValidation ? <span className="label-error">*</span>: null}
              </div>: null
          }
          <div className={(classNameInput ? classNameInput:" ")}>
            <a className="btn-basic btn-inventory-dp" onClick={(e) => onChangeDropDown(e,id)}>
              {
                (input.name === INTERIOR_COLOR_CODE || input.name === EXTERIOR_COLOR_CODE) ?
                  <div className="inv-dot" style={foundValue ? {backgroundColor:foundValue.name}:{display:"none"}} /> : null
              }
              <span>{foundValue ? foundValue.displayValue:defaultSelect}</span>
              {
                (showDropDown) ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />
              }
            </a>
            {error ?
              <p className="help-block">{getValidationMessages(input, error)}</p> : null}
            {
              showDropDown ? popover : null
            }
          </div>
        </div>
      </div>
    )
  },
  getTextAreaInput(input,value,onChange,onBlurInput,index){
    const {id, displayValue, showRow, showLabel} = input;
    return (
      <div key={'field_'+id}>
        <div className={showRow} >
          {
            displayValue ?
              <div className="carInfoLabel">
                <label htmlFor={''}
                       className={showLabel}>
                  {displayValue}
                </label>
              </div>: null
          }
          <div className="classCarHeaderInput">
            <textarea className="form-control"
                      id={id}
                      ref={id}
                      name={id}
                      onBlur={() => onBlurInput(id)}
                      onChange={() => onChange(id,index)}
                      value={value}
                      rows={3} style={{resize:"none"}}/>
          </div>
        </div>
      </div>
    )
  }});

const CAR_SUGGESTED_PRICE = "suggestedPrice";

const defaultNewCost = {taskId:"", description: "", labor: 0, part: 0, receipts: [], total:0}
function getCostInitial(){
  return {description: 'New brakes', labor:0 , part: 0, total: 0,receipts: []}
}

export const calcSubTotal = (list = []) => {
  let subTotalCost = 0
  if(!isEmpty(list)){
    subTotalCost = (list.map(c => {
      let subTotal = 0
      if(c.part === '') {
        subTotal += 0
      } else {
        subTotal += formatMoney(c.part)
      }
      if(c.labor === '') {
        subTotal += 0
      } else {
        subTotal += formatMoney(c.labor)
      }
      return subTotal
    }).reduce((acc, c) => c + acc))
  }
  return subTotalCost
};

const MarketingLogo = (props) => {
  const { isConnected, isSelected, handleClick, handleIntegrateFB, isEnableForFBMP, handleShowFBMPInfo, logo } = props
  return (
    <div className='container-logo'>
      <div className='container-icon'>
        <ReactSVG
          src={`${pathServer.PATH_IMG}${logo.fileName}`}
          beforeInjection={svg => svg.classList.add('unselected-marketing-icon-svg')}
          afterInjection={(_err, svg) => {
            if (isConnected) {
              if (isSelected) {
                svg.classList.remove('unselected-marketing-icon-svg')
                svg.classList.add('selected-marketing-icon-svg')
              } else {
                svg.classList.remove('selected-marketing-icon-svg')
                svg.classList.add('unselected-marketing-icon-svg')
              }
            }
          }}
          onClick={isConnected ? handleClick : null}
        />
      </div>
      <div className="upload-text">
        {
          !isEnableForFBMP && logo.logoName === LOGO.FBMARKETPLACE
            ? <React.Fragment>
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/icon.svg'}
                beforeInjection={svg => svg.classList.add('icon-info')}
                wrapper='span'
                onClick={handleShowFBMPInfo}
              />
              <label className="label-upload-text">
                {`Info needed to activate this option`}
              </label>
            </React.Fragment>
            : !isConnected
              ? <React.Fragment>
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/icon.svg'}
                  beforeInjection={svg => svg.classList.add('icon-info')}
                  wrapper='span'
                />
                <label className="label-upload-text">
                  <label
                    className="sub-text"
                    onClick={handleIntegrateFB}
                  >
                    Integrate
                  </label>
                  {` your ${logo.accountName} account`}
                </label>
              </React.Fragment>
              : isSelected
                ? <label className="label-upload-text active">Activated</label>
                : <label className="label-upload-text">Available</label>
        }
      </div>
    </div>
  )
}

const getSocialMedia = (carImages, mainImage, attribute) => {
  let socialNetwork = ""
  let mediaForPostBody = []
  if(attribute === ATTRIBUTE_CODE_CAR_MARKETING_FACEBOOK){
    socialNetwork = LOGO.FACEBOOK;
    mediaForPostBody = carImages.map(el => {
      return (
        { "id": el.id,
          "url": el.url,
          "description": "",
          "attributeType": "IMAGE",
          "entityType": "CAR_IMAGE"
        }
      )
    })
  } else if(attribute === ATTRIBUTE_CODE_CAR_MARKETING_INSTAGRAM){
    socialNetwork = LOGO.INSTAGRAM;
    const imageInstagram = carImages.find(carImage => carImage.url === mainImage);
    if(isEmpty(imageInstagram)){
      const firstImage = carImages.find(carImage => carImage.imageOrder === 1);
      if(!isEmpty(firstImage)){
        mediaForPostBody.push({"id": firstImage.id,
          "url": firstImage.url,
          "description": "",
          "attributeType": "IMAGE",
          "entityType": "CAR_IMAGE"})
      }
    } else{
      mediaForPostBody.push({"id": imageInstagram.id,
        "url": imageInstagram.url,
        "description": "",
        "attributeType": "IMAGE",
        "entityType": "CAR_IMAGE"})
    }
  }

  return(
    { socialNetwork: socialNetwork,
      mediaForPostBody: mediaForPostBody
    }
  )
}

export default class InventoryCar extends Component {

  constructor(){
    super();
    this.inventoryImage = React.createRef();
    this.state = {
      errors: null,
      vin: "",
      groupCar: 5,
      car: null,
      carResponse:null,
      hasChange: false,
      isLoading: false,
      showMessage: false,
      isBack: false,
      makeId:null,
      carImages: [],
      attributes:[],
      startIndex: 0,
      initialize: true,
      carForms :{},
      values:[],
      showPopoverAddImage:false,
      showPopoverAddRepair:false,
      showModal:false,
      errorShow:false,
      errorMessage:'',
      section:{
        carInformation:true,
        carMechanical:false,
        carPricing:false,
        images:false,
        marketing: false,
      },
      maxImageName:null,
      mainImage:null,
      hasChangeCar:false,
      hasChangeDocument:false,
      hasChangeCost:false,
      showModalSave:false,
      carStatusDB: "",
      messageVin:"",
      foundVin:false,
      errorVin:false,
      errorCarInformation: false,
      errorCarPricing: false,
      costs:[],
      receipts:[],
      carId:null,
      isCostAttached: false,
      attachedReceipts: null,
      attachedTask: null,
      isAddCarToInventoryClicked: true,
      documents:[],
      documentIds:[],
      selectedLogos: {
        [LOGO.FACEBOOK]: false,
        [LOGO.KSL]: false,
        [LOGO.ZOOM_AUTOS]: false,
        [LOGO.FBMARKETPLACE]: false,
      },
      connectedToSocialNetwork: {
        [LOGO.FACEBOOK]: false,
        [LOGO.INSTAGRAM]: false,
        [LOGO.KSL]: false,
        [LOGO.ZOOM_AUTOS]: false,
        [LOGO.FBMARKETPLACE]: false,
      },
      marketingLogosToShow: [
        // { logoName: LOGO.KSL, fileName: 'logo_KSL.svg'},
        { logoName: LOGO.FACEBOOK, fileName: 'logo_facebook.svg', accountName: LOGO.FACEBOOK},
        { logoName: LOGO.INSTAGRAM, fileName: 'logo_instagram.svg', accountName: LOGO.INSTAGRAM},
        // { logoName: LOGO.FBMARKETPLACE, fileName: 'logo_facebook_marketplace.svg', accountName: LOGO.FACEBOOK},
      ],
      postIds: [],
      isEnableForFBMP: false,
    }
  }

  componentDidMount = async () => {
    const {car,vin,costs,documents} = this.props;
    const objectId = car?.id ?? car?.carId ?? vin ?? NEW_CAR;

    const connectedToSocialNetwork =  await this.loadSocialNetworkCredential()

    await this.setCarStatusOptions()

    const postIds = await this.getPostByObjectId(objectId)

    const formState = await this.getInfoFromForm(objectId)

    this.setState({ connectedToSocialNetwork,postIds:[...postIds],...formState, costs, documents},
      () => this.setState({ isEnableForFBMP: this.isEnableForFBMP() }))
  }

  getInfoFromForm = async (objectId) => {
    const response = await loadFormByCode(FORM_CAR_NEW,objectId)
    const {responseCode, data} = response
    if(responseCode === SUCCESSFUL_CODE){
      const {makes,models,locations,temporary, car, costs} = this.props
      const {selectedLogos} = this.state
      const isCarTemporary = car?.temporary ?? false
      const {form, values} = data
      const {attributes, sections} = form.formAttributes
      const locationsForm = map(locations, location => {
        return{id:location.locationId, name:location.name, selected:false, displayValue: location.name}
      })

      this.updateAttributeValues(attributes, "locationId",locationsForm)
      this.updateAttributeValues(
        attributes,
        "year",
        this.loadYears(new Date().getFullYear()+1, 1930)
        )

      const carForms = parseObjectForm(sections)
      // Format purchaseDate
      const formatPurchaseDate = values['purchaseDate'];
      const dateNow = new Date();
      const purchaseDate = formatPurchaseDate && formatPurchaseDate !== ""
        ? formatPurchaseDate
        : dateNow;
      values['purchaseDate'] = Moment(purchaseDate).format("MM-DD-YYYY");

      const images = values.images;
      const mainImage = values.mainImage;
      const maxImageName = this.getMaxImageName(images);

      const makeId = values[ATTRIBUTE_CODE_MAKE_ID];
      const carStatusAttribute = attributes.find(x => x.name === ATTRIBUTE_CODE_CAR_STATUS) ?? {};
      const carStatusDB = carStatusAttribute?.id && values[carStatusAttribute?.id]
        ? values[carStatusAttribute.id][0]
        : ""
      const indexSuggestedPrice = attributes.findIndex(x => x.id === ATTRIBUTE_CODE_SUGGESTED_PRICE);
      attributes[indexSuggestedPrice].validation = this.validateStatus(values, attributes)

      const isAddCarToInventoryClicked = objectId !== NEW_CAR
        ? isCarTemporary
        : temporary

      const selectedLogosValue = {
        ...selectedLogos,
        [LOGO.FACEBOOK]: this.isSelectedMarketingOption(attributes, values, ATTRIBUTE_CODE_CAR_MARKETING_FACEBOOK),
        [LOGO.INSTAGRAM]: this.isSelectedMarketingOption(attributes, values, ATTRIBUTE_CODE_CAR_MARKETING_INSTAGRAM),
        [LOGO.KSL]: this.isSelectedMarketingOption(attributes, values),
        [LOGO.ZOOM_AUTOS]: this.isSelectedMarketingOption(attributes, values),
        [LOGO.FBMARKETPLACE]: this.isSelectedMarketingOption(attributes, values, ATTRIBUTE_CODE_CAR_MARKETING_FBMARKETPLACE),
      }

      this.updateAttributeValues(attributes, ATTRIBUTE_CODE_MAKE_ID, makes)
      if (objectId !== "new")
        this.updateAttributeValues(attributes, ATTRIBUTE_CODE_MODEL_ID, this.findModelsByMakeId(models,makeId[0]))

      const totalCost = this.calculateTotalCost(values, attributes, costs)
      if(values[totalCost?.id] === "")
        values[totalCost.id] = totalCost.value

      return {
        values, carForms, attributes, sections, formCode:form.code, objectId,
        carStatusDB, isAddCarToInventoryClicked, selectedLogos: selectedLogosValue,
        carImages:images,mainImage,mainImageInitial:mainImage,maxImageName,
        car: parseValues(attributes,sections)
      }
    }else {
      return {}
    }

  }

  updateAttributeValues = (attributes, attributeCode, newValues) => {
    const indexFound = this.getIndexByAttributeCode(attributes, attributeCode);
    if (indexFound !== -1) {
      attributes[indexFound].attributeValues = newValues;
    }
  }

  setCarStatusOptions = async () => {
    const {attributeValuesToCarStatus, setCarStatusOptions} = this.props
    if(!attributeValuesToCarStatus){
      const {responseCode, responseMessage, data} = await loadAttributeValuesByAttributeCode(ATTRIBUTE_CODE_CAR_STATUS)
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        return
      }

      setCarStatusOptions(data.attributeValues)
    }
  }

  loadSocialNetworkCredential = async () => {
    // This function let us know if the user is connected to FB
    // TODO: Implement for other social networks
    const types = [LOGO.FACEBOOK, LOGO.INSTAGRAM]
    const {responseCode, responseMessage, data} = await loadCredentialsByTypes(types.join(','))
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      return
    }

    const {credentials} = data

    const getSocialNetworkStatus = (type) => {
      const userFound = credentials.find((el) => el.type === type)
      const userId = userFound?.objectId ?? '';
      const pageId = userFound?.pageId ?? '';
      return !!(userId && pageId);
    }

    const isConnectedToFacebook = getSocialNetworkStatus(LOGO.FACEBOOK)

    return {
      ...this.state.connectedToSocialNetwork,
      [LOGO.FACEBOOK]: isConnectedToFacebook,
      [LOGO.INSTAGRAM]: getSocialNetworkStatus(LOGO.INSTAGRAM),
      [LOGO.FBMARKETPLACE]: isConnectedToFacebook,
    }
  }

   getPostByObjectId = async(objectId) => {
     const {responseCode, responseMessage, data} = await getPostIdByCarId(objectId)
     if (responseCode === SUCCESSFUL_CODE) {
       const {postIds} = data
       return postIds
     } else {
       console.error(responseMessage)
       return []
     }
  }

  validateStatus = (values, attributes) => {
    const {attributeValuesToCarStatus} = this.props;
    let validation = [];
    if(attributeValuesToCarStatus){
      let carStatusAttribute = attributes.find(x=> x.name === ATTRIBUTE_CODE_CAR_STATUS);
      let flrStatus = attributeValuesToCarStatus.find(x=> x.code === CAR_STATUS_CODE_FRONT_LINE_READY);
      if(carStatusAttribute){
        if(values[carStatusAttribute.id][0] === flrStatus.id){
          validation= [SUGGESTED_PRICE_REQUIRED];
        }
      }
    }
    return validation;
  }

  getMaxImageName = (images) => {
    let maxImageName = 0;
    let imageOrder;
    images.forEach(image => {
      let url = image.url.split("/");
      imageOrder = Number(url[url.length - 1]);

      if(imageOrder > maxImageName)
        maxImageName = imageOrder
    });

    return maxImageName
  }

  getIndexByAttributeCode = (attributes, code) => {
    return findIndex(attributes,function(attribute){ return attribute.name === code});
  }

  loadYears = (lastYear, firstYear) => {
    let years = new Array();
    for (let i = lastYear; i >= firstYear; i--) {
      years.push({id: i, displayValue: i.toString(), name: i.toString()});
    }
    return years;
  }

  findModelsByMakeId = (models, makeId) => {
    return models.filter(function (model) {
      return model.makeId === makeId;
    });
  }

  setIdAttributes = () => {
    const doorsId = this.getAttributeId(ATTRIBUTE_CODE_CAR_DOOR);
    const trimId = this.getAttributeId(ATTRIBUTE_CODE_CAR_TRIM);
    const cylinderId = this.getAttributeId(ATTRIBUTE_CODE_CAR_CYLINDER);
    const driveId = this.getAttributeId(ATTRIBUTE_CODE_CAR_DRIVE);
    const transmissionId = this.getAttributeId(ATTRIBUTE_CODE_CAR_TRANSMISSION);
    const bodyId = this.getAttributeId(ATTRIBUTE_CODE_CAR_BODY);
    const fuelId = this.getAttributeId(ATTRIBUTE_CODE_CAR_FUEL);
    this.setState({doorsId,trimId,cylinderId,driveId,transmissionId,bodyId,fuelId})
  }

  getAttributeId = (name) => {
    const {attributes} = this.state;
    const attribute = attributes.find( attribute => {return attribute.name === name});
    if(attribute && attribute.id)
      return attribute.id
  }

  setValues = () => {
    let {values,doorsId,trimId,cylinderId,driveId,transmissionId,bodyId,fuelId} = this.state;
    values[ATTRIBUTE_CODE_MAKE_ID] = [];
    values[ATTRIBUTE_CODE_MODEL_ID] = [];
    values[ATTRIBUTE_CODE_CAR_YEAR] = [];
    values[doorsId]= [];
    values[cylinderId] = [];
    values[driveId] = [];
    values[transmissionId] = [];
    values[bodyId] = [];
    values[fuelId] = [];
    values[trimId] = "";
    this.setState({values,errorShow:true,errorMessage:"There is no data available for the VIN Number provided."});
    // setTimeout(function () {
    //   this.setState({errorShow:false});
    // }.bind(this), TIME_FIVE_SECONDS);
  }

  loadCarSpecs = (vinNumber) => {
    let {values, attributes,doorsId,trimId,cylinderId,driveId,transmissionId,bodyId,fuelId} = this.state;
    this.setState({isLoading: true, foundVin:false, messageVin: "", errorShow:false});
    verifyVin(vinNumber).then(({responseCode, responseMessage}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        this.setState({foundVin: true, messageVin: responseMessage, isLoading: false });
        return
      }

      getDataFromVinNumber(vinNumber).then((response) => {
        const responseCar = response["Results"][0];
        const makeAttribute = attributes.find(x => x.id === ATTRIBUTE_CODE_MAKE_ID);
        const modelAttribute = attributes.find(x => x.id === ATTRIBUTE_CODE_MODEL_ID);
        const makeFound = makeAttribute.attributeValues.find(make => { return (make.name.toUpperCase() === responseCar[formCarApi.MAKE].toUpperCase())});
        if(makeFound){
          let modelByMakeId = this.props.models.filter(function (model) {
            return model.makeId === makeFound.id;
          });
          let attributeFound = attributes.find( attribute => attribute.id === ATTRIBUTE_CODE_MODEL_ID);
          if(attributeFound)
            attributeFound.attributeValues = modelByMakeId;
        }
        const modelFound = modelAttribute.attributeValues.find(model => { return (model.name.toUpperCase() === responseCar[formCarApi.MODEL].toUpperCase())});
        if(makeFound && modelFound){
          for (let key in values) {
            switch (key) {
              case ATTRIBUTE_CODE_MAKE_ID:
                if(makeFound){
                  values[key].length = 0;
                  values[key].push(makeFound.id);
                }
                break;
              case ATTRIBUTE_CODE_MODEL_ID:
                if(modelFound){
                  values[key].length = 0;
                  values[key].push(modelFound.id);
                }
                break;
              case ATTRIBUTE_CODE_CAR_YEAR:
                values[key].length = 0;
                values[key].push(parseInt(responseCar[formCarApi.YEAR]));
                break;
              case trimId :
                values[key] = responseCar[formCarApi.TRIM];
                break;
              case doorsId:
                const doorsAttribute = attributes.find(x => x.id === key);
                const doorsFound = doorsAttribute.attributeValues.find( door => {return door.name === responseCar[formCarApi.DOORS]});
                if(doorsFound){
                  values[key] = [];
                  values[key].push(doorsFound.id);
                }
                break;
              case cylinderId:
                const cylinderAttribute = attributes.find(x => x.id === key);
                const cylinderFound = cylinderAttribute.attributeValues.find( cylinder => { return cylinder.name === responseCar[formCarApi.CYLINDERS]});
                if(cylinderFound){
                  values[key] = [];
                  values[key].push(cylinderFound.id);
                }
                break;
              case driveId:
                const driveAttribute = attributes.find(x => x.id === key);
                const driveFound =  driveAttribute.attributeValues.find(drive => { return drive.name === responseCar[formCarApi.DRIVE]});
                if(driveFound){
                  values[key] = [];
                  values[key].push(driveFound.id);
                }
                break;
              case transmissionId:
                const transmissionAttribute = attributes.find(x => x.id === key);
                const transmissionFound = transmissionAttribute.attributeValues.find(transmission => {return transmission.name === responseCar[formCarApi.TRANSMISSION]});
                if(transmissionFound){
                  values[key] = [];
                  values[key].push(transmissionFound.id);
                }
                break;
              case bodyId:
                const bodyAttribute = attributes.find(x => x.id === key);
                const bodyFound =  bodyAttribute.attributeValues.find(body => { return body.name === responseCar[formCarApi.BODY_STYLE]});
                if(bodyFound){
                  values[key] = [];
                  values[key].push(bodyFound.id);
                }
                break;
              case fuelId:
                const fuelAttribute = attributes.find(x => x.id === key);
                const fuelFound = fuelAttribute.attributeValues.find(fuel => {return fuel.name === responseCar[formCarApi.FUEL]});
                if(fuelFound){
                  values[key] = [];
                  values[key].push(fuelFound.id);
                }
                break;
            }
          }
        }else{
          this.setValues();
        }
        this.setState({values: values, isLoading: false })
      }).catch((error) => {
        console.log(error);
        this.setState({ isLoading: false })
      });
    })
  }

  onFormChange = (formType, data, index) => {
    const {values, attributes} = this.state
    let attributesToUpdate = [...attributes]
    let valuesToUpdate = cloneDeep(values)
    for(let key in valuesToUpdate) {
      if(isArray(valuesToUpdate[key])) {
        if(typeof data[key] !== 'undefined' && valuesToUpdate[key]) {
          valuesToUpdate[key].length = 0;
          valuesToUpdate[key].push(data[key]);
          if(formType === 'carInformation' && key === ATTRIBUTE_CODE_MAKE_ID){
            let modelByMakeId = this.props.models.filter(function (model) {
              return model.makeId === data[key];
            });
            attributesToUpdate.forEach(attribute => {
              if(attribute.id === ATTRIBUTE_CODE_MODEL_ID)
                attribute.attributeValues = modelByMakeId;
            });
            if (values[key][0] !== data[key]) {
              valuesToUpdate[ATTRIBUTE_CODE_MODEL_ID].length = 0
            }
          }
          let attributeFoundStatus = attributesToUpdate.find(x=> x.name === ATTRIBUTE_CODE_CAR_STATUS);
          let indexSuggestedPrice = attributesToUpdate.findIndex(x=> x.id === ATTRIBUTE_CODE_SUGGESTED_PRICE);
          if(formType === 'carPricing' && key === attributeFoundStatus.id){
            attributesToUpdate[indexSuggestedPrice].validation = this.validateStatus(valuesToUpdate, attributesToUpdate);
          }
        }
      }else{
        if (typeof data[key] !== 'undefined') {
          valuesToUpdate[key] = data[key];
          if(key === PURCHASED_PRICE){
            const {attributes, costs} = this.state
            const totalCost = this.calculateTotalCost(valuesToUpdate, attributes, costs)
            valuesToUpdate[totalCost.id] = totalCost.value
          }
        }
      }
    }
    this.setState({values:valuesToUpdate, attributes: attributesToUpdate, errors: null,errorShow:false},
      () => this.setState({ isEnableForFBMP: this.isEnableForFBMP() })
    );
    this.props.handleUpdateData("hasChangeCar");
  }

  calculateTotalCost = (values, attributes, costs) => {
    const attributesTotalCost = attributes.find(attribute => {
      return attribute.name === CAR_TOTAL_COST
    })
    if(attributesTotalCost)
      return {id: attributesTotalCost.id, value : usdNumberToFormat(calcSubTotal(costs) + formatMoney(values[PURCHASED_PRICE]))}

    return {}
  }

  validateFilters = (carFiltersNew, values, attributes, attributeValuesToCarStatus) => {
    let carStatusAttribute = attributes.find(x=> x.name === ATTRIBUTE_CODE_CAR_STATUS);

    //status
    if(carFiltersNew.status && carFiltersNew.status.length > 0){
      let statusId = values[carStatusAttribute.id][0];
      let statusSelected = findIndex(attributeValuesToCarStatus,(carStatus) => {return carStatus.id === statusId});
      let statusName = attributeValuesToCarStatus[statusSelected].name;
      let indexStatus = findIndex(carFiltersNew.status,(status) => {return status === statusName});
      if(indexStatus !== -1)
        return true;
    }

    //location
    if(carFiltersNew.locations && carFiltersNew.locations.length > 0){
      let indexLocation = findIndex(carFiltersNew.locations,(location) => {return location === values.locationId[0]});
      if(indexLocation !== -1)
        return true;
    }

    //makes & model
    if(carFiltersNew.makes && carFiltersNew.makes.length > 0){
      let indexMake = findIndex(carFiltersNew.makes,(make) => {return make === values.makeId[0]});
      let indexModel = -1;

      if(carFiltersNew.models && carFiltersNew.models.length > 0)
        indexModel = findIndex(carFiltersNew.models,(model) => {return model === values.modelId[0]});
      else
        indexModel = 0;
      if(indexMake !== -1 && indexModel !== -1)
        return true;
    }
    //neither filter selected
    if( (!carFiltersNew.status || carFiltersNew.status.length === 0) &&
      (!carFiltersNew.locations || carFiltersNew.locations.length === 0) &&
      (!carFiltersNew.makes || carFiltersNew.makes.length === 0) &&
      (!carFiltersNew.models || carFiltersNew.makes.models === 0))
      return true;

    return false;
  }

  updateFilterRangers = (id, step, type, minValue, maxValue) => {
    let max = Math.max(clone(type.max));
    let min = Math.min(clone(type.min));

    let rest = 0;
    let difference = 0;

    if (id === 'mile' || id === 'price'){
      rest = max % step;
      if (rest > 0){
        difference = step - rest;
        max = max + difference
      }
      rest = min % step;
      if (rest > 0){
        min = min - rest;
      }
    }
    if (max === min)
      max = min + step;

    minValue[id] = min;
    maxValue[id] = max;

    type.min = min;
    type.max = max;
  }

  validationForms = async () => {
    const { attributes, objectId, formCode, indexCost, isAddCarToInventoryClicked} = this.state
    let valuesToUpdate = {...this.state.values}
    let requestForm = { formCode: formCode };
    const {hasChangeCar,hasChangeImage,hasChangeCost,hasLinkToTask,openTaskReceipt,attributeValuesToCarStatus,
      carFilters, setCarFilters, customerId, customerName, financeIdSelected, saleId, hasChangeDocument} = this.props;

    if(hasChangeCar){
      if(objectId !== "new"){
        if(isEmpty(valuesToUpdate.suggestedPrice)){
          valuesToUpdate.suggestedPrice = "0";
        }
        requestForm.objectId = objectId;
      }else{
        if(isEmpty(valuesToUpdate.order)) {
          valuesToUpdate.order = 0;
        }
        if(isEmpty(valuesToUpdate.mileage)) {
          valuesToUpdate.mileage = "0";
        }
        if(isEmpty(valuesToUpdate.suggestedPrice)){
          valuesToUpdate.suggestedPrice = "0";
        }
        if(isEmpty(valuesToUpdate.retailPrice)) {
          valuesToUpdate.retailPrice = "0";
        }

        let attributeFoundStatus = attributes.find(x => x.name === ATTRIBUTE_CODE_CAR_STATUS);
        if(isEmpty(valuesToUpdate[attributeFoundStatus.id])){
          cleanArray(valuesToUpdate[attributeFoundStatus.id]);
          valuesToUpdate[attributeFoundStatus.id].push(attributeFoundStatus.attributeValues[0].id);
        }
        let attributeFoundShow = attributes.find(x=> x.name === "car.show");
        cleanArray(valuesToUpdate[attributeFoundShow.id]);
        valuesToUpdate[attributeFoundShow.id].push(attributeFoundShow.attributeValues[0].id);

      }
      valuesToUpdate.temporary = isAddCarToInventoryClicked;

      let valuesBack = cloneDeep(valuesToUpdate);
      cleanArray(valuesBack.images);
      requestForm.values = valuesBack;

      // This was added to let save purchaseDate correctly
      const valueArray = requestForm.values.purchaseDate.split("-");
      if (valueArray[0].length === 4) {
        requestForm.values.purchaseDate = Moment(requestForm.values.purchaseDate).format("MM-DD-YYYY");
      }

      this.setState({isLoading: true});

      const {responseCode, responseMessage, data} = await processFormApi({requestForms: [requestForm]})
      if (responseCode === SUCCESSFUL_CODE) {
        let newCarId = data[0]
        if (isEmpty(customerId) && carFilters && isEmpty(saleId)) {
          let carFiltersNew = cloneDeep(carFilters)
          let updateMaxYear = false
          let updateMaxMile = false
          let updateMaxPrice = false
          let year = {min: carFiltersNew.minValues?.year, max: carFiltersNew.maxValues?.year}
          let mileage = {min: carFiltersNew.minValues?.mile, max: carFiltersNew.maxValues?.mile}
          let price = {min: carFiltersNew.minValues?.price, max: carFiltersNew.maxValues?.price}

          if (valuesBack.year[0] > carFiltersNew.maxValues?.year) {
            year.max = valuesBack.year[0]
            updateMaxYear = true
          }

          /*
                                FYI
              The minimum is not recalculated because you cannot know if it is the only car with that minimum value
           */
          if (parseInt(valuesBack.mileage.replace(",", "")) > carFiltersNew.maxValues?.mile) {
            mileage.max = parseInt(valuesBack.mileage.replace(",", ""))
            updateMaxMile = true
          }
          if (parseInt(valuesBack.suggestedPrice.replace(",", "")) > carFiltersNew.maxValues?.price) {
            price.max = parseInt(valuesBack.suggestedPrice.replace(",", ""))
            updateMaxPrice = true
          }
          let updateMax = this.validateFilters(carFiltersNew, valuesToUpdate, attributes, attributeValuesToCarStatus)
          if ((updateMaxYear || updateMaxMile || updateMaxPrice) && updateMax) {
            this.updateFilterRangers("year", 1, year, carFiltersNew.minValues, carFiltersNew.maxValues)
            this.updateFilterRangers("mile", 5000, mileage, carFiltersNew.minValues, carFiltersNew.maxValues)
            this.updateFilterRangers("price", 100, price, carFiltersNew.minValues, carFiltersNew.maxValues)
            if (updateMaxYear)
              carFiltersNew.year.max = carFiltersNew.maxValues.year

            if (updateMaxMile)
              carFiltersNew.mile.max = carFiltersNew.maxValues.mile

            if (updateMaxPrice)
              carFiltersNew.price.max = carFiltersNew.maxValues.price
          }
          setCarFilters(carFiltersNew)
        }

        //Note: this code creates a task and a repair if the car status is change to Inspect and Repair. Tasks and Repairs should not be created until needed
        // let carStatusAttribute = attributes.find(x=> x.name === ATTRIBUTE_CODE_CAR_STATUS);
        // let repairInspectStatus = attributeValuesToCarStatus.find(x=> x.code === CAR_STATUS_CODE_INSPECT_REPAIR);
        // if(carStatusAttribute){
        //   if(requestForm.values[carStatusAttribute.id][0] !== carStatusDB && requestForm.values[carStatusAttribute.id][0] === repairInspectStatus.id){
        //     let carTitle = "";
        //     let makeFound = find(makes,function (make) {return make.id === requestForm.values.makeId[0]});
        //     carTitle += makeFound.displayValue + " ";
        //     let modelFound = find(models,function (model) {return model.id === requestForm.values.modelId[0]});
        //     carTitle += modelFound.displayValue + " ";
        //     carTitle += requestForm.values.year.toString();
        //     let task = DEFAULT_TASK;
        //     let repair = DEFAULT_REPAIR;
        //     task.title = carTitle;
        //     repair.description = carTitle;
        //     repair.carId = data[0];
        //     saveTaskAndRepair({task: task, repair: repair }).then((response) => {
        //     }).catch((error) => {
        //       console.log(error)
        //     });
        //   }
        // }
        if (hasLinkToTask) {
          const newRepairId = await this.saveAllCost(newCarId, indexCost)
          this.setState({hasLinkToTask: false})
          browserHistory.push(`/main/task/new/repair/${newRepairId}/car/${newCarId}`)
        } else if (openTaskReceipt) {
          await this.saveAllCost(newCarId, indexCost)
          this.setState({openTaskReceipt: false})
          browserHistory.push(`/main/inventory/${valuesToUpdate['vin']}`)
        } else if (hasChangeImage) {
          if (hasChangeCost) {
            await this.saveAllCost(newCarId, indexCost)
          }

          await this.processCarImage(newCarId, objectId === 'new')
        } else {
          if (hasChangeCost) {
            await this.saveAllCost(newCarId, indexCost)
          }
          if (isEmpty(customerId)) {
            if (isEmpty(saleId)) {
              this.setState({isLoading: false})
              this.props.back()
            } else {
              this.updateSale(newCarId)
            }
          } else {
            let body = {
              customers: [{customerId: customerId, principal: true, creditScore: 0}],
              carId: newCarId
            }
            if (!isEmpty(financeIdSelected)) {
              body['financeId'] = financeIdSelected
            }
            startSale(body).then(({responseCode, responseMessage, data}) => {
              if(responseCode === SUCCESSFUL_CODE){
                const saleId = data.message
                this.setState({isLoading: false})
                browserHistory.push({
                  pathname: `/main/customer/edit/${customerId}/sales/${saleId}/${TAB_AGREEMENT}`,
                  state: {name: customerName}
                })
              }else{
                this.setState({isLoading: false, errorMessage: responseMessage})
                setTimeout(() => {
                  this.setState({errorMessage: ''})
                }, 1500)
              }
            })
          }
        }
        this.processCarDocument(newCarId)
        await this.publishPostOnOfficialPage(newCarId)
      }
      else {
        this.setState({errorShow: true, errorMessage: responseMessage, isLoading: false, errorVin: true})
        setTimeout(function () {
          this.setState({errorShow: false, errorVin: false})
        }.bind(this), TIME_FIVE_SECONDS)
      }
    } else if (hasChangeImage && (objectId !== 'new')) {
      await this.processCarImage(objectId, false)
      await this.publishPostOnOfficialPage(objectId)
    } else if (hasChangeDocument && (objectId !== 'new')) {
      this.processCarDocument(objectId)
    } else if (hasChangeCost && (objectId !== 'new')) {
      await this.saveAllCost(objectId, indexCost)
      if (!openTaskReceipt) {
        this.props.back()
      }
    }

  }

  processCarImage = async (carId, isNew) => {
    const {vin, customerId, customerName, financeIdSelected} = this.props;
    let deleteCarImage = {};
    if(this.inventoryImage?.current){
      const {mainImageInitial} = this.state
      const {state} = this.inventoryImage.current
      const {carImages, files, isMainImageToDelete, isUpdateOrderImage, carImageIdsToDelete, mainImage} = state
      const indexMainImage = findIndex(carImages,(carImage) => {return carImage.url === mainImage});

      if(isNew){
        if(files.length > 0){
          this.setState({isLoading: true});
          await uploadCarImage(carId, files, -1).then(async ({responseCode, responseMessage, data}) => {
            if (responseCode !== SUCCESSFUL_CODE) {
              console.error(responseMessage);
              this.setState({isLoading: false, showMessage: true});
              return
            }

            const imagesResponse = data.images;
            await this.updateOrderImage(carImages,imagesResponse,isUpdateOrderImage,indexMainImage,mainImageInitial,carId);
            if(isEmpty(customerId)){
              this.setState({isLoading: false});
              this.props.back();
            } else {
              let data = {customerId: customerId,
                carId: carId};
              if(!isEmpty(financeIdSelected)){
                data["financeId"] = financeIdSelected
              }
              startSale(data).then(({responseCode, responseMessage, data}) => {
                if (responseCode === SUCCESSFUL_CODE){
                  const saleId = data.message;
                  this.setState({isLoading: false});
                  browserHistory.push({pathname: `/main/customer/edit/${customerId}/sales/${saleId}/${TAB_AGREEMENT}`,
                    state:{name: customerName}});
                }else{
                  this.setState({isLoading: false, errorMessage: responseMessage});
                  setTimeout(() => {
                    this.setState({errorMessage: ""});
                  }, 1500);
                }
              })
            }
          })
        }else{
          this.setState({isLoading: false});
          this.props.back();
        }
      }else if(files.length > 0){
        this.setState({isLoading: true});
        await uploadCarImage(carId,files, -1).then( async ({responseCode, responseMessage, data}) => {
          if (responseCode !== SUCCESSFUL_CODE) {
            console.error(responseMessage);
            this.setState({isLoading: false, showMessage: true});
            return
          }

          const imagesResponse = data.images;
          if(carImageIdsToDelete.length > 0 || isMainImageToDelete){
            deleteCarImage.carImageIds = carImageIdsToDelete;
            if(isMainImageToDelete)
              deleteCarImage.isMainImageToDelete = isMainImageToDelete;

            await deleteCarImages(deleteCarImage,vin).then(async ({responseCode, responseMessage}) => {
              if (responseCode !== SUCCESSFUL_CODE) {
                console.error(responseMessage)
                return
              }

              await this.updateOrderImage(carImages,imagesResponse,isUpdateOrderImage,indexMainImage,mainImageInitial,carId)
            })
          }else{
            await this.updateOrderImage(carImages,imagesResponse,isUpdateOrderImage,indexMainImage,mainImageInitial,carId)
          }
        }).catch(error => {
          console.error(error);
          this.setState({isLoading: false, showMessage: true});
        })
      }else{
        if(carImageIdsToDelete.length > 0 || isMainImageToDelete){
          deleteCarImage.carImageIds = carImageIdsToDelete;
          if(isMainImageToDelete)
            deleteCarImage.isMainImageToDelete = isMainImageToDelete;

          await deleteCarImages(deleteCarImage,vin).then(async ({responseCode, responseMessage}) => {
            if (responseCode !== SUCCESSFUL_CODE) {
              console.error(responseMessage)
              return
            }

            await this.updateOrderImage(carImages,carImages,isUpdateOrderImage,indexMainImage,mainImageInitial,carId)
          })
        }else{
          await this.updateOrderImage(carImages,carImages,isUpdateOrderImage,indexMainImage,mainImageInitial,carId)
        }
      }
    }
  }

  processCarDocument = (carId) => {
    let { documents, documentIds = [] } = this.state
    let documentsToInsert = [];
    documents.forEach(doc => {
      if(!doc.id)
        documentsToInsert.push(doc)
    });
    if (documentsToInsert.length > 0 || documentIds.length > 0) {
      this.setState({isLoading: true})
      uploadCarDocument({carId: carId, documents: documentsToInsert, documentIds: documentIds})
          .then(({responseCode, responseMessage}) => {
            if (responseCode !== SUCCESSFUL_CODE) {
              console.error(responseMessage)
              this.setState({isLoading: false})
              return
            }

            this.setState({isLoading: false})
            this.props.back();
          })
    }
  }

  addCostToRepair = (cost) => {
    let arrCost = [];
    const fields = [COST_REPAIR_LABOR, COST_REPAIR_PART];
    const costInitial = getCostInitial();
    fields.map( f => {
      const objCost = {};
      if (cost.laborId && f === COST_REPAIR_LABOR) {
        objCost.id = cost.laborId;
      }
      if (cost.partId && f === COST_REPAIR_PART) {
        objCost.id = cost.partId;
      }
      objCost.costType = f;
      objCost.costValue = f === COST_REPAIR_LABOR ? formatToNumber(cost.labor) : formatToNumber(cost.part);
      if (cost.labor !== costInitial.labor || cost.part !== costInitial.part) {
        arrCost.push(objCost);
      }
    });
    return arrCost;
  }

  saveOnlyOneCostSelected = async (cost, carId) => {
    let payload = {}
    if(cost?.id){
      payload = {costs: this.addCostToRepair(cost), carId: carId, description: cost.description, id: cost?.id}
    }else{
      payload = {costs: this.addCostToRepair(cost), carId: carId, description: cost.description}
    }
    const {responseCode, responseMessage, data} = await saveTaskCost({ listRepair: [payload]})
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      return
    }
    return data.repairs[0].id
  }

  saveAllCost = async (carId, index) => {
    const {costs, values, attributes} = this.state;
    const payload = costs.map(cost => {
      if(cost?.id){
        return {costs: this.addCostToRepair(cost), carId: carId, description: cost.description, id: cost?.id}
      }else{
        return {costs: this.addCostToRepair(cost), carId: carId, description: cost.description}
      }
    });
    const totalCost = this.calculateTotalCost(values, attributes, costs)
    const {responseCode, responseMessage, data} = await saveTaskCost({listRepair: payload,totalCost: totalCost.value})
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      return
    }

    const indexRepair = !isNaN(index) ? index : 0
    const newRepairId = data.repairs[indexRepair].id
    this.updateCosts(indexRepair, newRepairId)
    return newRepairId
  }

  deleteReceipt = (id, index, repairId, countRecipes) => {
    deleteReceiptRepair(id).then(({responseCode, responseMessage}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        console.log('error delete receipt', responseMessage);
        return
      }

      if(countRecipes === 0){
        this.updateCosts(index, repairId, [])
      }
    })
  }

  updateOrderImage = async (carImages, imagesResponse, isUpdateOrderImage, indexMainImage, mainImageInitial, carId) => {
    let carImagesToUpdate = [];
    carImages.forEach(carImage => {
      if(!carImage.isNew)
        carImagesToUpdate.push(carImage);
    });
    if(isUpdateOrderImage){
      await updateOrderImage({carImages:carImagesToUpdate,carId:carId}).then(async ({responseCode, responseMessage, data}) => {
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage);
          this.setState({isLoading: false});
          return
        }

        await this.setAsMainImage(data.carImages,indexMainImage,mainImageInitial,carId)
      });
    }else{
      await this.setAsMainImage(imagesResponse,indexMainImage,mainImageInitial,carId)
    }
  }

  setAsMainImage = async (images, indexMainImage, mainImageInitial, carId) => {
    let imageMain;

    if(indexMainImage !== -1)
      imageMain = images[indexMainImage];

    if(imageMain && mainImageInitial !== imageMain.url){
      await setAsMainImageApi(carId, imageMain.id).then(({responseCode, responseMessage, data}) => {
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage);
          this.setState({isLoading: false});
          return
        }

        const mainImage = data.mainImage;
        this.setState({isLoading: false,mainImage:mainImage,mainImageInitial:mainImage,carImages:images});
        this.props.back();
      })
    }else{
      this.setState({isLoading: false, carImages:images});
      this.props.back();
    }
  }

  // This method is called here and also outside this class component
  accordionToggle = (id) => {
    let {section} = this.state;
    section[id] = !section[id];
    this.setState({section:section});
  }

  // This method is called outside this class component
  validationField = (showModal) => {
    const { attributes, values, foundVin, costs} = this.state
    let { car } = this.state;
    const {hasChangeCar,hasChangeImage,hasChangeCost,hasChangeDocument,attributeValuesToCarStatus} = this.props;
    if(hasChangeCar || hasChangeImage || hasChangeCost || hasChangeDocument){

      let errors = {};
      for (let section in car) {
        for (let key in car[section]) {
          car[section][key] = values[key];
        }
      }

      for (let key in this.refs) {
        if(key !== "inventoryImage"){
          errors[key] = this.refs[key].state.errors;
          let attributeFoundStatus = attributes.find(x => x.name === ATTRIBUTE_CODE_CAR_STATUS);
          let flrStatus = attributeValuesToCarStatus.find(x=> x.code === CAR_STATUS_CODE_FRONT_LINE_READY);
          errors[key].suggestedPrice = null;
          if(key === CAR_SECTION_CAR_PRICING && car[CAR_SECTION_CAR_PRICING].suggestedPrice === "0" &&
            car[CAR_SECTION_INFORMATION][attributeFoundStatus.id] && car[CAR_SECTION_INFORMATION][attributeFoundStatus.id][0] === flrStatus.id){
            errors[key].suggestedPrice = [VALIDATION_REQUIRED]
          }
          setErrors(attributes, car[key], errors[key]);
        }
      }

      const costErrors = getErrorsInCosts(costs)
      if(costErrors.length > 0)
        errors.costs = costErrors

      let hasError = validate(errors) || foundVin;

      let showModalSave;
      let errorCarInformation = false;
      let errorCarPricing = false;
      if(!hasError || showModal)
        showModalSave = true;
      else{
        showModalSave = false;
        for(let key in errors){
          let errorBySection = errors[key];
          for(let sectionKey in errorBySection){
            if(errorBySection[sectionKey]){
              if(sectionKey === CAR_SUGGESTED_PRICE){
                errorCarPricing = true
              } else{
                errorCarInformation = true
              }
            }
          }
        }
      }

      this.setState({showModalSave:showModalSave,hasError:hasError, errorCarInformation: errorCarInformation, errorCarPricing: errorCarPricing, costErrors});
    }
  }

  handlePressEnter = (data) => {
    const {vin} = this.props;
    if (checkValidVIN(data.vin) && vin === "new") {
      this.loadCarSpecs(data.vin);
    }
  }

  onBlurVin = (data) => {
    const {vin} = this.props;
    if (checkValidVIN(data.vin)) {
      this.loadCarSpecs(data.vin);
    }
  }

  onChangeCostAlter = (costState, index) => {
    let {car} = this.props;
    const {attributes, values} = this.state
    let objectId = (car && car.id) ? car.id : undefined
    let costs = [...this.state.costs];
    let cost = cloneDeep(costs[index])
    const previewValue = formatMoney(costState.name)
    const currentValue = formatMoney(costState.value)
    if (cost) {
      let total = cost.total + (currentValue - previewValue) || 0
      cost = {...costState, carId: objectId, total: total}
      costs.splice(index, 1, cost)
      this.setState({costs : costs}, () => {
        const totalCost = this.calculateTotalCost(values, attributes, costs)
        values[totalCost.id] = totalCost.value
        this.props.handleUpdateData("hasChangeCost");
      });
    } else {
      cost = {...defaultNewCost, carId: objectId}
      costs.splice(index, 0, cost)
      this.setState({costs}, () => {
        this.props.handleUpdateData("hasChangeCost");
      });
    }
  }

  costOnBlur = (index, e) => {
    let costs = [...this.state.costs];
    let cost = cloneDeep(costs[index])
    const name = e.target.name;
    const value = e.target.value;

    if(value){
      cost = {...cost, [name] : usdNumberToFormat(value)}
      costs.splice(index, 1, cost)
      this.setState({costs : costs}, () => {
        this.props.handleUpdateData("hasChangeCost");
      });
    }
  }

  addNewCost = () => {
    const defaultCost = {
      description: '',
      part: '',
      labor: '',
      total: '',
      receipts: [],
      temporalId: getRandomId(),
    };

    this.setState({costs: [...this.state.costs, defaultCost]})
  }

  onDropPhoto = (file, url) => {
    file.url = url;
    this.setState({receipts: [...this.state.receipts, file]})
  }

  addDocument = (newDocuments) => {
    this.setState({documents: newDocuments})
    this.props.handleUpdateData("hasChangeDocument");
  }

  deleteDocument = (allDocuments, documentIds) => {
    this.setState({documents: [...allDocuments],documentIds: [...new Set([...this.state.documentIds ,...documentIds])]});
    this.props.handleUpdateData("hasChangeDocument");
  }

  onSaveReceipts = async (receipts, repairId, index) => {
    if(repairId){
      this.setState({isLoading: true})
      const {responseCode, responseMessage, data} = await addReceiptRepair(receipts,repairId)
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        return
      }

      if(data.receipts){
        this.updateCosts(index, repairId, data.receipts)
      }
    }
  }

  openTaskRepair = async (index, repairId, taskId) => {
    const { setTaskFilters, taskFilter, car, handleUpdateData, save, hasChangeCost, handleIndexToCost} = this.props
    const query = {...taskFilter,type :TASK_TYPE.REPAIR}
    setTaskFilters(query)
    const costFound = this.state.costs[index]
    const repairSelected = {labor: costFound?.labor, part: costFound?.part, description: costFound?.description, receipts: costFound?.receipts}
    const taskIdToSend = taskId ? taskId : 'new'
    if (car?.id) {
      if(!repairId) {
        const newRepairId = await this.saveAllCost(car?.id, index)
        browserHistory.push(`/main/task/${taskIdToSend}/repair/${newRepairId}/car/${car?.id}`);
      } else {
        browserHistory.push({ pathname: `/main/task/${taskIdToSend}/repair/${repairId}/car/${car?.id}`, state: { repairSelected }});
      }
    } else {
      handleUpdateData('hasLinkToTask')
      handleIndexToCost(index)
      await save()
    }
  }

  openRecipes = async (index, repairId) =>{
    const { car, handleUpdateData, save, handleIndexToCost, hasChangeCost} = this.props
    if (car?.id) {
      if(!repairId) {
        let costFound = cloneDeep(this.state.costs)[index]
        const newRepairId = await this.saveOnlyOneCostSelected(costFound, car?.id)
        this.updateCosts(index, newRepairId)
      }
    } else {
      handleUpdateData('openTaskReceipt', true)
      handleIndexToCost(index)
      await save()
    }
  }

  updateCosts = (index, newRepairId, receipts) => {
    let { costs } = this.state
    let costsToUpdate = cloneDeep(costs)
    let costFound = costsToUpdate[index]
    costFound = {...costFound, id: newRepairId, receipts: (receipts) ? receipts : costFound.receipts}
    costsToUpdate[index] = costFound
    this.props.handleUpdateData("openTaskReceipt", false);
    this.setState({costs: costsToUpdate, isLoading: false})
  }

  removeByIndex = (list, indexToRemove) => {
    return [...list.slice(0, indexToRemove), ...list.slice(indexToRemove + 1)]
  }

  removeTaskRepair = (index, repairId) => {
    let { costs, attributes, values } = this.state
    let costsNew = cloneDeep(costs)
    if(repairId) {
      deleteTaskCostApi(repairId).then(({responseCode, responseMessage, data}) => {
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
          return
        }

        const {isAttached, receipts, task} = data
        if(!isAttached){
          const newCosts = this.removeByIndex(costs, index)
          const totalCost = this.calculateTotalCost(values, attributes, newCosts)
          values[totalCost.id] = totalCost.value
          this.setState({costs: newCosts})
        }else{
          this.setState({
            isCostAttached: isAttached,
            attachedReceipts: receipts ? receipts : null,
            attachedTask: task ? task : null,
          })
        }
      })
    } else {
      costsNew.splice(index, 1)
      this.setState({costs: costsNew})
    }
    this.props.handleUpdateData("hasChangeCost");
  }

  hideModal = () => {
    this.setState({isCostAttached: false})
  }

  handleCheck = () => {
    const { isAddCarToInventoryClicked, values } = this.state;
    let valuesToUpdate = {...values}
    valuesToUpdate[CAR_FIELDS_NAME.TEMPORARY] = !isAddCarToInventoryClicked
    this.setState({ isAddCarToInventoryClicked: !isAddCarToInventoryClicked, values: valuesToUpdate })
    this.props.handleUpdateData("hasChangeCar");
  }

  updateSale = (carId) => {
    const {saleId,customers,salesmanId} = this.props;
    const data = {
      customers: customers,
      salesmanId,
      carId: carId,
    }

    updateSale(saleId, data).then(({responseCode, responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({isLoading: false})
        browserHistory.push({pathname: `/main/sale/${saleId}`});
      }else{
        console.error(responseMessage)
        this.setState({isLoading: false, errorMessage: responseMessage})
      }
    })
  }

  // It lets show informational popover
  handleShowPopover = (elementId, message = null) => {
    const target = document.getElementById(elementId)
    const pointTo = getPosition(target)
    let anchorEl = {
      x: pointTo.x - 135,
      y: pointTo.y - 166,
      offsetWidth: 330,
    }

    this.setState(
      {
        messageOnPopover: message,
        showPopover: true,
        anchorEl: anchorEl
      }
    )
  }

  publishPostOnOfficialPage = async (carId) => {
    const { attributes, values, selectedLogos, postIds, carImages, mainImage, connectedToSocialNetwork } = this.state
    let listCurrentAttributeCode = [];
    const isSaveFacebook  = selectedLogos[LOGO.FACEBOOK] && connectedToSocialNetwork[LOGO.FACEBOOK]
    const isSaveInstagram = selectedLogos[LOGO.INSTAGRAM] && connectedToSocialNetwork[LOGO.INSTAGRAM]
    if(isSaveFacebook){
      listCurrentAttributeCode.push(ATTRIBUTE_CODE_CAR_MARKETING_FACEBOOK)
    }
    if(isSaveInstagram){
      listCurrentAttributeCode.push(ATTRIBUTE_CODE_CAR_MARKETING_INSTAGRAM)
    }

    async function updatingPost(postBody) {
      const {responseCode, responseMessage} = await updatePost(carId, {facebook: postBody})
      if (responseCode !== SUCCESSFUL_CODE) {
        console.log(responseMessage)
      }
    }

    if(listCurrentAttributeCode.length > 0){
      let socialPostBody = {};
      listCurrentAttributeCode.forEach(attribute => {
        const marketingAttribute = attributes.find(el => el.name === attribute)
        const marketingAttributeValues = marketingAttribute.attributeValues
        const previousMarketingAttributeValue =
          marketingAttributeValues.find(el => el.id === values[marketingAttribute.id][0])

        if (previousMarketingAttributeValue.name === OPTION.ENABLE) {
          const {socialNetwork,mediaForPostBody} = getSocialMedia(orderBy(carImages, ['imageOrder'], ['desc']), mainImage, attribute);

          const postBody = {
            "objectId" : carId,
            "description": values.description,
            "socialNetwork": socialNetwork,
            "entityType": "CAR",
            "media": mediaForPostBody
          }

          if(attribute === ATTRIBUTE_CODE_CAR_MARKETING_FACEBOOK){
            socialPostBody.facebook = postBody
          }
          if(attribute === ATTRIBUTE_CODE_CAR_MARKETING_INSTAGRAM){
            socialPostBody.instagram = postBody
          }
        }
      })
      if(!isEmpty(socialPostBody)){
        let currentPostFacebookId = ""
        let currentPostInstagramId = ""
        if(postIds.length > 0){
          postIds.forEach(postId => {
            if(postId.socialNetwork.toLowerCase() === LOGO.FACEBOOK){
              currentPostFacebookId = postId.id
            } else if(postId.socialNetwork.toLowerCase() === LOGO.INSTAGRAM){
              currentPostInstagramId = postId.id
            }
          })
          if(isSaveFacebook && isSaveInstagram){
            if(currentPostFacebookId === "" && currentPostInstagramId === ""){
              await this.savePost(socialPostBody)
            } else if(currentPostFacebookId !== "" && currentPostInstagramId === ""){
              socialPostBody.facebook.postId = currentPostFacebookId
              await updatingPost(socialPostBody.facebook);
              const socialPostBodyInstagram = {}
              socialPostBodyInstagram.instagram = socialPostBody.instagram
              await this.savePost(socialPostBodyInstagram)
            } else if(currentPostFacebookId === "" && currentPostInstagramId !== ""){
              const socialPostBodyFacebook = {}
              socialPostBodyFacebook.facebook = socialPostBody.facebook
              await this.savePost(socialPostBodyFacebook)
            } else{
              socialPostBody.facebook.postId = currentPostFacebookId
              await updatingPost(socialPostBody.facebook);
            }
          } else if(isSaveFacebook && !isSaveInstagram){
            if(currentPostFacebookId !== ""){
              const postBody = socialPostBody.facebook;
              postBody.postId = currentPostFacebookId;
              await updatingPost(postBody);
            } else{
              await this.savePost(socialPostBody)
            }
          } else if(!isSaveFacebook && isSaveInstagram){
            if(currentPostInstagramId === ""){
              await this.savePost(socialPostBody)
            }
          }
        } else{
          await this.savePost(socialPostBody)
        }
      }
    }
  }

  savePost = async (socialPostBody) => {
    let {postIds} = this.state;
    this.setState({ isLoading: true })

    const {responseCode, data, responseMessage} = await savePost(socialPostBody)
    if (responseCode === SUCCESSFUL_CODE) {
      const facebook = data.facebook;
      const instagram = data.instagram;
      if(facebook.code === RESPONSE_CODE_SUCCESS){
        const existPostId = postIds.findIndex(post => post.socialNetwork.toLowerCase() === LOGO.FACEBOOK)
        if(existPostId === -1)
          postIds.push({id: facebook.id, socialNetwork: LOGO.FACEBOOK})
      }
      if(instagram.code === RESPONSE_CODE_SUCCESS){
        const existPostId = postIds.findIndex(post => post.socialNetwork.toLowerCase() === LOGO.INSTAGRAM)
        if(existPostId === -1)
          postIds.push({id: instagram.id, socialNetwork: LOGO.INSTAGRAM})
      }
      this.setState({ postIds: postIds, isLoading: false })
    }
    else {
      console.log(responseMessage)
      this.setState({ isLoading: false })
    }
  }

  handleClickForMarketingLogo = (logoName) => {
    const { attributes, values } = this.state
    let valuesToUpdate = {...values}
    let currentAttributeCode = ''

    if (logoName === LOGO.FACEBOOK) {
      currentAttributeCode = ATTRIBUTE_CODE_CAR_MARKETING_FACEBOOK
    } else if (logoName === LOGO.INSTAGRAM) {
      currentAttributeCode = ATTRIBUTE_CODE_CAR_MARKETING_INSTAGRAM
    } else if (logoName === LOGO.FBMARKETPLACE) {
      currentAttributeCode = ATTRIBUTE_CODE_CAR_MARKETING_FBMARKETPLACE

      if (!this.isEnableForFBMP()) {
        return
      }
    }

    if (currentAttributeCode) {
      const marketingAttribute = attributes.find(el => el.name === currentAttributeCode)
      const marketingAttributeValues = marketingAttribute.attributeValues
      const previousMarketingAttributeValue =
        marketingAttributeValues.find(el => el.id === valuesToUpdate[marketingAttribute.id][0])
      let currentMarketingAttributeValue

      // First condition is just in case there is no value for current Marketing Logo
      if (!previousMarketingAttributeValue) {
        currentMarketingAttributeValue =
          marketingAttributeValues.find(el => el.name === OPTION.ENABLE)
      }
      else {
        currentMarketingAttributeValue =
          marketingAttributeValues.find(el =>
            el.name === (previousMarketingAttributeValue.name === OPTION.ENABLE ? OPTION.DISABLE : OPTION.ENABLE)
          )
      }

      valuesToUpdate[marketingAttribute.id] = [currentMarketingAttributeValue.id]

      this.setState((state) => ({
          selectedLogos: {
            ...state.selectedLogos,
            [logoName]: !state.selectedLogos[logoName],
          },
          values: valuesToUpdate
        }),
        () => this.props.handleUpdateData("hasChangeCar")
      )
    }
  }

  isSelectedMarketingOption = (attributes, values, attributeCode = '') => {
    if (!attributeCode) {
      return false
    }

    const marketingAttribute = attributes.find(el => el.name === attributeCode)
    const marketingAttributeValues = marketingAttribute?.attributeValues
    const lastMarketingAttributeValue = marketingAttributeValues?.find(el => el.id === values[marketingAttribute.id][0])

    if (!lastMarketingAttributeValue) {
      return false
    }

    return lastMarketingAttributeValue && (lastMarketingAttributeValue.name === OPTION.ENABLE)
  }

  handleIntegrateFB = () => {
    const {setMenuId} = this.props
    if (typeof setMenuId === 'function') {
      setMenuId("setting")
    }
    browserHistory.push({pathname: "/main/setting/marketing"})
  }

  isEnableForFBMP = () => {
    const { values, attributes, carImages  } = this.state

    const attributesForFBMP = [
      'id',
      'vin',
      'makeId',
      'modelId',
      'year',
      'mileage',
      'description',
      'suggestedPrice',
      'images'
    ]

    const conditionOnAttributes = attributesForFBMP.every(attribute => {
      if (isEmpty(values[attribute])) {
        return false
      }

      if (attribute === 'mileage' && parseInt(values[attribute]) <= 500) {
        return false
      }

      if (attribute === 'images' && (values[attribute].length <= 1 || carImages.length <= 1)) {
        return false
      }

      return true
    })

    const attributeNamesForFMBP = [
      ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR,
      ATTRIBUTE_CODE_CAR_BODY,
      ATTRIBUTE_CODE_CAR_DRIVE,
      ATTRIBUTE_CODE_CAR_TRANSMISSION,
      ATTRIBUTE_CODE_CAR_FUEL,
      ATTRIBUTE_CODE_CAR_CONDITION,
    ]

    const conditionOnAttributeNames = attributeNamesForFMBP.every(attributeName => {
      const attributeId = get(attributes.find(x => x.name === attributeName), ['id'], '');
      return !isEmpty(values[attributeId])
    })

    return conditionOnAttributes && conditionOnAttributeNames
  }

  verifyIsEnableForFBMP = () => {
    this.setState({ isEnableForFBMP: this.isEnableForFBMP() })
  }

  render () {
    let {showMessage, errorMessage, carForms,attributes,car, values,carImages,isLoading,errorShow,section,maxImageName,
      mainImage, foundVin, messageVin, errorVin, costs, isCostAttached, attachedReceipts, attachedTask,
      isAddCarToInventoryClicked,documents,marketingLogosToShow,connectedToSocialNetwork,selectedLogos, isEnableForFBMP, costErrors
    } = this.state;
    let {cars, vin, hasChange, isEdit, save, customerId, showFixedOptions, saleId, handleShowFBMPInfo, temporary} = this.props;
    if (!carForms || !car) return null;
    const element = <MessageBodyCustom vin={car.vin} isCostAttached={isCostAttached} attachedReceipts={attachedReceipts} attachedTask={attachedTask} />
    return (
      <div className="body-edit-car">
        {isLoading ?
          <DPSpinner classContainer={"loader-container"}/> : null}
        <div className={showMessage ? "showMessage alert alert-success" : "hideMessage fadeOut"}>
          <h2>Success!</h2>
          <p>Your information has been saved</p>
        </div>
        {
          showFixedOptions
            ? <div className='cnic-container-buttons'>
              <div
                id="add-car-to-inventory-id"
                className={`add-car-to-inventory`}
                // TODO: Enable functionality on 'Add car to inventory' button
                onClick={null}
              >
                <div
                  key={"0"}
                  className="content-check"
                >
                  <Checkbox
                    checked={!isAddCarToInventoryClicked} id={"car-not-inventory"}
                    onCheck={() => {this.handleCheck()}}
                    checkedIcon={elementCheck()}
                    uncheckedIcon={elementUnCheck()}
                    iconStyle={{borderColor: "#777", marginRight: 2}}
                    disabled={false}
                    className="checkbox"
                    required={true}
                    name={"name"}
                  />
                  <div className="container-label">
                    <p className="check-description">Add car to inventory</p>
                  </div>
                </div>
              </div>
              <button
                className='button-save'
                onClick={() => this.props.save(this.inventoryImage)}
              >
                Save
              </button>
            </div>
            : null
        }
        <div className="box-edit-car">
          {/*** Temporarily commented. ***/}
          <div className="car-info">
            {
              errorShow ?
                <div className={!showFixedOptions ? "container-message-info" : "container-message-info-not-inventory"}>
                  <div className={!showFixedOptions ? "message-info": "message-info-not-inventory"} style={{border: "1px solid #FF6565"}}>
                    <img src={pathServer.PATH_IMG + "advertisement.png"} className= "icon-info"/>
                    {
                      errorVin ?
                        <label style={{fontWeight:700}}>ERROR:</label> : null
                    }
                    <label style={{fontWeight:500}}>{errorMessage}</label>
                  </div>
                </div> : null
            }
            {
              foundVin ?
                <div className={!showFixedOptions ? "container-message-info" : "container-message-info-not-inventory"}>
                  <div className={!showFixedOptions ? "message-info": "message-info-not-inventory"} style={{border: "1px solid #FF6565"}}>
                    <img src={pathServer.PATH_IMG + "inverted.png"} className= "icon-info"/>
                    <label style={{fontWeight:700}}>WARNING:</label>
                    <label style={{fontWeight:500}}>{messageVin}</label>
                  </div>
                </div> : null
            }
            <DPSectionHeader
              isClosed={!section[SECTIONS.INFO]}
              title="Car Information"
              accordionToggle={() => this.accordionToggle(SECTIONS.INFO)}
            />
            <div className="body-car-info" style={section[SECTIONS.INFO] ? {display: "block"} : {display: "none"}}>
              <FormNew ref="carInformation" section={carForms["carInformation"]}
                       attributes={attributes}
                       data={values}
                       onChange={(data, index) => this.onFormChange("carInformation", data, index)}
                       onKeyPressEnter={this.handlePressEnter}
                       onBlur={this.onBlurVin}
                       errorShow={errorShow}
                       foundVin={foundVin}/>
            </div>
          </div>
          <div className="body-car-mechanical">
            <DPSectionHeader
              isClosed={!section[SECTIONS.MECHANICAL]}
              title="Mechanical Specs"
              accordionToggle={() => this.accordionToggle(SECTIONS.MECHANICAL)}
            />
            <div className="body-car-mechanical-info" style={section[SECTIONS.MECHANICAL] ? {display: "block"} : {display: "none"}}>
              <FormNew ref="mechanicalSpecsCar" section={carForms["mechanicalSpecsCar"]}
                       attributes={attributes}
                       data={values}
                       onChange={(data, index) => this.onFormChange("mechanicalSpecsCar", data, index)}
                       errorShow={errorShow}
                       foundVin={foundVin}/>
            </div>
          </div>
          <div id="containerPricing" className="car-pricing">
            <DPSectionHeader
              isClosed={!section[SECTIONS.PRICING]}
              title="Pricing"
              accordionToggle={() => this.accordionToggle(SECTIONS.PRICING)}
            />
            <div className="body-car-pricing-info" style={section[SECTIONS.PRICING] ? {display: "block"} : {display: "none"}}>
              <FormNew ref="carPricing" section={carForms["carPricing"]}
                       attributes={attributes}
                       data={values}
                       onChange={(data, index) => this.onFormChange("carPricing", data, index)}
                       errorShow={errorShow}
                       foundVin={foundVin}/>
            </div>
          </div>
          <UserHasPermission permissionId={COST_SECTION_PERMISSION_ID}>
            <div id="containerCost" className="car-cost">
              <DPSectionHeader
                isClosed={!section[SECTIONS.COST]}
                title="Repairs"
                accordionToggle={() => this.accordionToggle(SECTIONS.COST)}
              />
              <div className="body-car-cost-info" style={section[SECTIONS.COST] ? {display: "block"} : {display: "none"}}>
                <DPCost
                  ref={el => this.inventoryCost = el}
                  costList={costs}
                  onDropPhoto={this.onDropPhoto}
                  receipts={[]}
                  showTitleHeader={false}
                  showActionFooter={true}
                  onChange={this.onChangeCostAlter}
                  openRecipes={this.openRecipes}
                  openTaskRepair={this.openTaskRepair}
                  showActionRow={true}
                  isCarOrTask='C'
                  removeTaskRepair={this.removeTaskRepair}
                  deleteReceipt={this.deleteReceipt}
                  onSaveReceipts={this.onSaveReceipts}
                  costOnBlur={this.costOnBlur}
                  addNewCost={this.addNewCost}
                  errors={costErrors}
                />
              </div>
            </div>
          </UserHasPermission>
          <div className="car-images">
            <DPSectionHeader
              isClosed={!section[SECTIONS.IMAGES]}
              title="Images"
              accordionToggle={() => this.accordionToggle(SECTIONS.IMAGES)}
              subTitleWhenIsOpened="Changes will be saved until clicking on SAVE"
            />
            {
              section[SECTIONS.IMAGES] ?
                <InventoryImage cars={cars}
                                vin={vin}
                                ref={this.inventoryImage}
                                carImages={carImages}
                                maxImageName={maxImageName}
                                mainImage={mainImage}
                                handleUpdateData={this.props.handleUpdateData}
                                verifyIsEnableForFBMP={this.verifyIsEnableForFBMP}
                /> : null
            }
          </div>
          {/*** Temporarily commented. ***/}
          <div className="car-documents">
            <DPSectionHeader
              isClosed={!section[SECTIONS.DOCS]}
              title="Documents"
              accordionToggle={() => this.accordionToggle(SECTIONS.DOCS)}
            />
            {
              section[SECTIONS.DOCS] ?
                <InventoryDocument
                  documents={documents}
                  addDocument={this.addDocument}
                  deleteDocument={this.deleteDocument}
                /> : null
            }
            {
              isEmpty(customerId) ?
                null : <div className="container-btn-save">
                  <button className="btn btn-save-car" onClick={save} disabled={!hasChange}
                          style={hasChange ? {backgroundColor: "#2194F1"} : {backgroundColor: "#A4AFB7"}}>Save and Continue</button>
                </div>
            }
          </div>
          { !temporary
            ? <div className="car-marketing">
              <DPSectionHeader
                isClosed={!section[SECTIONS.MARKETING]}
                title="Marketing"
                accordionToggle={() => this.accordionToggle(SECTIONS.MARKETING)}
                questionMarkId='marketingQuestionMark'
                handleShowPopover={() => this.handleShowPopover(
                  'marketingQuestionMark',
                  "In this section you will be able to select the marketing pages you would like to push this car to."
                )}
              />
              {
                section[SECTIONS.MARKETING]
                  ? <div className='container-icons'>
                    {
                      marketingLogosToShow.map((el, index) => {
                        return (
                          <MarketingLogo
                            key={index}
                            isConnected={connectedToSocialNetwork[el.logoName]}
                            isSelected={selectedLogos[el.logoName]}
                            handleClick={() => this.handleClickForMarketingLogo(el.logoName)}
                            handleIntegrateFB={this.handleIntegrateFB}
                            isEnableForFBMP={isEnableForFBMP}
                            handleShowFBMPInfo={handleShowFBMPInfo}
                            logo={el}
                          />
                        )
                      })
                    }
                  </div>
                  : null
              }
            </div>
            : null
          }
          {
            (isEdit && isEmpty(customerId)) ?
              <div className="save-mobile-view">
                <div className="option-edit">
                  <button onClick={save} className="btn-save-mobile" disabled={!hasChange}
                          style={hasChange ? {backgroundColor: "#4ad991"} : {backgroundColor: "#A4AFB7"}}>Save
                  </button>
                </div>
              </div> : null
          }
        </div>
        {
          isCostAttached && <ModalConfirmDeactivate show={isCostAttached}
                                                    onSubmit={() => {}}
                                                    onHide={this.hideModal}
                                                    showButton={false}
                                                    isBodyCustom={isCostAttached}
                                                    bodyMessage={element}
                                                    message=""
                                                    iconQuestion={"icon/ic_warning.svg"}
                                                    classButtonDelete="btn-delete"
                                                    buttonLabelDelete={""}
                                                    viewMobile={false} />
        }
        {
          this.state.showPopover
            ? <Popover
              anchorEl={this.state.anchorEl}
              child={
                <div>{this.state.messageOnPopover}</div>
              }
              classPopover='sale-setting-column-title-popover'
              handleRequestClose={() => this.setState({showPopover: false})}
            />
            : null
        }
        </div>
    )
  }

}

InventoryCar.defaultProps = {
  showFixedOptions: false,
  sale: null,
  currentCustomers: null,
  temporary: false
}

InventoryCar.propTypes = {
  showFixedOptions: PropTypes.bool,
  sale: PropTypes.object,
  currentCustomers: PropTypes.object,
  temporary: PropTypes.bool
}
