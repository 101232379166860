import React from 'react'
import Switch from '@material-ui/core/Switch'

require('./styleGuide.scss')

const StyleGuide = () => {
  return (
    <div className="style-guide-container">
      <div className="sg-top">
        <span className="sg-title">
          Style Guide GoDealerGo 2.0
        </span>
        <div className="sg-switch-mode">
          <span>Dark</span>
          <Switch color={"primary"}/>
          <span>Light</span>
        </div>
      </div>
      <div className="sg-section sg-fonts">
        <span className="sg-section-title">
          Font and text hierarchy
        </span>
      </div>
      <div className="sg-section sg-color">
        <span className="sg-section-title">
          Color system
        </span>
      </div>
      <div className="sg-section sg-ui-elements">
        <span className="sg-section-title">
          UI Elements
        </span>
      </div>
    </div>
  )
}

export default StyleGuide
