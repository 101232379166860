import React, {Component} from 'react'
import {USERS_BY_PAGE, USERS_BY_PAGE_BY_DEFAULT} from '../../../Constants.js'
import {Popover, DPPillBox} from '../../util/ComponentUtils'
import UserEdit from './UserEdit.jsx'
import {ReactSVG} from 'react-svg'
import {findIndex} from 'lodash'
import Checkbox from 'material-ui/Checkbox'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import {
  loadUsersApi,
  deleteUserApi,
  loadAllRoles,
  updateUserStatus,
  userHasSaleorTasks
} from '../../../Api'
import UserInfo from './UserInfo.jsx'
import ModalConfirmDeactivate from '../../util/ModalConfirmDeactivate.jsx'
import {MODULE_USER, siteColorAdmin} from '../../../Constants'
import {connect} from 'react-redux'
import {setKeyword} from '../../../actions'
import ModalConfirmDelete from '../../util/ModalConfirmDelete.jsx'
import DragDropContext from '../../util/DragDropContext'
import MessageBodyCustom from '../../inventory/MessageBodyCustom'
import DPOverlay from '../../../common/DPOverlay'
import {getAnchorEl} from '../../util/Utils'
import {pathServer, SUCCESSFUL_CODE, WIDTH_VIEW_MOBILE, WIDTH_VIEW_TABLET} from '../../../../common/Constants'
import {DPPagination} from '../../../../common/DPPagination'

const elementCheck = (
  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_box.svg"} beforeInjection={ svg => svg.classList.add('icon-check')}/>
);

const elementUnCheck = (
  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_uncheck_box.svg"} beforeInjection={ svg => svg.classList.add('icon-uncheck')}/>
);

function getMessage(active){
  return active ?
    <div>
      <label>Do you want to deactivate this user?</label>
      <span>This action will block access and credentials until activated again.</span>
    </div> :
    <div>
      <label>Do you want to activate this user?</label>
      <span>This action will allow access and credentials to this user.</span>
    </div>
}

function updateUsersRoles(users){
  let values = [];
  if (users !== [] || users.roles !== undefined) {
    users.forEach((user) => {
      user.roles.forEach((rol) => {
        values.push(rol.id);
      });
      user.roles = values;
      values = [];
    });
  }
  return users;
}

function getInitialUser() {
  return {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    phoneNumber: "",
    description: "",
    roles: [],
    image: "",
    active: true,
    verify:false,
    accountOwner:false,
    superuser:false,
    password: ""
  }
}

class SettingUserList extends Component {
  constructor() {
    super();
    this.popover = [];

    this.state = {
      users: [],
      anchorEl: "",
      showModal: false,
      showPopoverStatus: false,
      roleSelected: "",
      userSelected: getInitialUser(),
      showInactive: false,
      showOptions: false,
      itemsByPageQuantity: USERS_BY_PAGE_BY_DEFAULT,
      currentPage: 1,
      currentLetter: '',
      showPopover: false,
      from: 0,
      listName: {
        id: null,
        displayValue: "Clear All"
      },
      listFilterUser: {
        id: null,
        displayValue: "All Roles"
      },
      optionsOrder: [
        {id: "clear", value: "clear", displayValue: "Clear All"},
        {id: "sortAsc", value: "sortAsc", displayValue: "A-Z"},
        {id: "sortDesc", value: "sortDesc", displayValue: "Z-A"},
      ],
      optionsFilter: [{id: "clear", displayValue: "Clear All", checked: false, index: 0}],
      showAddUserPopover: false,
      showPopoverRolesModal: false,
      showModalInfo: false,
      showEditPanelUser: false,
      filterSelected: false,
      query: {},
      showModalActive:false,
      widthPage: window.innerWidth,
      showFiltersMobile: false,
      isLoading: true,
      rolId:null,
      isNewUser: false,
      showModalDelete: false,
      showModaUserHasSaleorTasks : false,
      userHasSale:false,
      userHasTasks:false,
      selectedIndex: 0
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    const {optionsFilter,itemsByPageQuantity} = this.state;
    this.loadUsers(0,itemsByPageQuantity,null,false,null);
    loadAllRoles().then(response => {
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {roles} = data;
        const optionsRolesFilter = roles.map( (rol, index) => ({
          id: rol.id,
          displayValue: rol.name,
          description: rol.description,
          checked: false,
          index: index + 1
        }))
        this.setState({optionsFilter: [...optionsFilter, ...optionsRolesFilter]})
      }else{
        const ex = {status: response.responseCode, message: response.responseMessage}
        console.error("error:", ex);
      }
    })
  }

  componentWillReceiveProps(nextProps){
    const {search} = nextProps;
    this.findByKeyWord(search);
  }

  findByKeyWord = (keyword) => {
    const {itemsByPageQuantity,showInactive,rolId} = this.state;
    this.setState({isLoading:true, currentPage:1,listName:{id: null, displayValue: "Clear All"}});
    this.loadUsers(0,itemsByPageQuantity,keyword,showInactive,rolId);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.props.setKeyword("");
  }

  loadUsers(from,size,keyword,showInactive,rolId) {
    loadUsersApi(from,size,keyword,showInactive,rolId).then((response) => {
      const {responseCode, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {users, usersQuantity} = data;
        let updateUsers = updateUsersRoles(users);
        this.setState(
          {
            users: updateUsers,
            usersQuantity: usersQuantity,
            currentPage: 1,
            showModalActive:false,
            showEditPanelUser:false,
            showModal:false,
            isLoading:false},
          (() => {}))
      }

    });
  }

  loadUsersFromApi = async (from, size, keyword, showInactive, rolId) => {
    const response = await loadUsersApi(from, size, keyword, showInactive, rolId)
    const {responseCode, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      return data
    } else {
      return {'users': [], 'usersQuantity': 1}
    }
  }

  onChangeDropDown = (type) => {
    let target = document.getElementById(type);
    let anchorEl = getAnchorEl(target,2);
    this.setState({
      [type]: !this.state[type],
      showPopoverStatus: false,
      showPopover: true,
      filterSelected: type,
      anchorEl: anchorEl
    })
  }

  onChangeSort = (op) => {
    let {users,listName} = this.state;
    let listUsers = users;
    if (op.value === "sortAsc") {
      listUsers.sort(function (a, b) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1;
        else if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
        return 0;
      });
      this.setState({listName: {...listName, displayValue: op.displayValue}, showPopover: false})
    } else if (op.value === "sortDesc") {
      listUsers.sort(function (a, b) {
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return -1;
        else if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return 1;
        return 0;
      });
      this.setState({listName: {...listName, displayValue: op.displayValue}, showPopover: false})
    } else {
      listUsers.sort(function (a, b) {
        if (a.createDate > b.createDate) return -1;
        else if (a.createDate < b.createDate) return 1;
        return 0;
      });
      this.setState({listName: {...listName, displayValue: op.displayValue}, showPopover: false})
    }
  }

  onChangeFilter = (op) => {
    const {showInactive,itemsByPageQuantity,listName} = this.state;
    let rolId = op.id;
    if(rolId === 'clear')
      rolId = null;

    this.setState({
      listFilterUser: {...listName, displayValue: op.displayValue}, showPopover: false, filterSelected: null ,rolId:rolId, isLoading:true, showFiltersMobile: false
    }, () => {
      this.loadUsers(0,itemsByPageQuantity,null,showInactive,rolId)
    });
  }

  handlePopoverStatus = (userValue, index, event) => {
    const {showPopoverStatus} = this.state;
    event.stopPropagation();
    const user = userValue.value;
    this.setState({
      showPopoverStatus: !showPopoverStatus,
      dropDownOrder: false,
      userSelected: user,
      roleSelected: user.id,
      selectedIndex: index,
      isActiveOverlay : true
    });
  }

  renderRoleOptions = () => {
    let {userSelected, optionsFilter} = this.state;
    let values = userSelected.roles;
    let options = [];
    optionsFilter.forEach(role => {
      const checked = findIndex(values, (nameRol) => {
        return nameRol === role.id;
      });
      if (role.id !== "clear") {
        options.push(
          <div key={role.index} className={"content-check"}>
            <Checkbox label={role.displayValue} id={role.id} checked={(checked !== -1)}
                      labelStyle={{color: "#a5a5a5", fontSize: 13, fontWeight: "400"}}
                      checkedIcon={elementCheck} uncheckedIcon={elementUnCheck}
                      iconStyle={{borderColor: "#777", marginRight: 8}}
                      name={role.id}/>
          </div>
        )
      }
    });
    return (
      <div className="popover-body-status">
        <div id="" className="options-status">
          {options}
        </div>
      </div>
    )

  }

  handleRequestOpen = (event) => {
    const {widthPage} = this.state;
    let offsetHeight = event.currentTarget.offsetHeight;
    if(widthPage <= WIDTH_VIEW_TABLET)
      offsetHeight = -(17 + USERS_BY_PAGE.length*22);
    this.setState({
      showOptions: true,
      anchorEl: {offsetWidth: event.currentTarget.offsetWidth, offsetHeight: offsetHeight}
    })
  }

  handlePageChange = async(nextPage, totalPages) => {
    const {itemsByPageQuantity, query, usersQuantity, showInactive} = this.state;
    if (nextPage >= 0) {
      let currentPage = nextPage;
      if (currentPage > totalPages) {
        currentPage = 1;
        query.from = 0;
      } else if (currentPage === 0) {
        currentPage = totalPages;
        query.from = itemsByPageQuantity * (totalPages - 1);
      } else {
        if (usersQuantity !== 0)
          query.from = itemsByPageQuantity * (currentPage - 1);
      }
      query.size = itemsByPageQuantity;
      this.setState({isLoading: true});
      const {users, usersQuantity: totalUsers} = await this.loadUsersFromApi(query.from, query.size, '', showInactive)
      const usersUpdated = updateUsersRoles(users)
      this.setState(
        {
          users: usersUpdated,
          usersQuantity: totalUsers,
          currentPage,
          isLoading: false
        })
    }
  }

  handleItemsByPage = async (itemsByPageQuantity) => {
    let {query, showInactive,rolId} = this.state;
    query.from = 0;
    query.size = itemsByPageQuantity;
    this.setState({isLoading: true});
    const {users, usersQuantity} = await this.loadUsersFromApi(query.from, query.size, '', showInactive,rolId)
    const usersUpdated = updateUsersRoles(users)
    this.setState(
      {
        itemsByPageQuantity,
        showOptions: false,
        users: usersUpdated,
        usersQuantity,
        currentPage: 1,
        isLoading: false
      })
  }

  handleRequestClose = () => {
    this.setState({
      showOptions: false,
      showPopoverStatus: false,
      roleSelected: "-1",
      showPopover: false,
      filterSelected: null
    })
  }

  closePopoverModal = () => {
    this.setState({showPopoverRolesModal: false})
  }

  openModal = (user, e) => {
    e.stopPropagation();
    const {isActiveOverlay} = this.state;
    if(!isActiveOverlay) {
      let newFormatUser = this.getRolesUserSelected(user)
      this.setState({userSelected: newFormatUser, showModalInfo: true});
    } else {
      this.setState({isActiveOverlay: null})
    }
  }

  openEdit = (user, e) => {
    e.stopPropagation();
    let newFormatUser = this.getRolesUserSelected(user)
    this.setState({userSelected: newFormatUser, showEditPanelUser: true, showModalInfo: false, isNewUser: false})
  }

  getRolesUserSelected = (user) => {
    const {optionsFilter} = this.state;
    const roles = user.roles
    let newFormatRoles = []
    if(!isEmpty(roles) && !roles[0].id) {
      optionsFilter.forEach((op) => {
        if (roles.includes(op.id))
          newFormatRoles.push({id: op.id, displayValue: op.displayValue})
      })
    } else {
      newFormatRoles = user.roles
    }
    return {...user, roles: newFormatRoles}
  }

  closeModal = (modal) => {
    this.setState({[modal]: false, userSelected: getInitialUser()});
  }

  toggleAddUserPopover = () => {
    let {optionsFilter} = this.state;
    optionsFilter.forEach((op) => {
      op.checked = false
    });
    this.setState({
      photo: null,
      optionsFilter: optionsFilter,
      showEditPanelUser: true,
      isNewUser : true
    });
  }

  handleCheck(e){
    let {newUser, optionsFilter} = this.state;
    let rolesTemp = newUser.roles;

    optionsFilter.map((op) => {
      if(op.id === e.target.id){
        op.checked = !op.checked;
        if(op.checked) {
          rolesTemp.push(op.id);
        }
        else{
          let index = rolesTemp.findIndex(row=> row === op.id);
          rolesTemp.splice(parseInt(index), 1);
        }
      }
    });
    newUser.roles = rolesTemp;
    this.setState({newUser: newUser, optionsFilter: optionsFilter})
  }

  updateStatus = (e) => {
    e.stopPropagation();
    const {userSelected,itemsByPageQuantity,showInactive} = this.state;
    updateUserStatus(userSelected.id, !userSelected.active).then(() => {
      this.loadUsers(0,itemsByPageQuantity,'',showInactive);
      this.hideModalDelete();
    })
  }

  deleteUser = (e) => {
    e.stopPropagation();
    const {userSelected,itemsByPageQuantity,showInactive} = this.state;
    deleteUserApi(userSelected.id).then((response) => {
      const {responseCode} = response
      if(responseCode === SUCCESSFUL_CODE){
        this.loadUsers(0,itemsByPageQuantity,'',showInactive);
      }
      this.hideModalDelete();
    })
  }

  renderValues(type) {
    let {optionsFilter, optionsOrder, listName, listFilterUser} = this.state;
    let options = [];
    let values = [];
    let filterSelected = null;
    let method = null;

    switch (type) {
      case 'orderUser':
        values = optionsOrder;
        filterSelected = listName.displayValue;
        method = this.onChangeSort;
        break;
      case 'rolesUser':
        values = optionsFilter;
        filterSelected = listFilterUser.displayValue;
        method = this.onChangeFilter;
        break;
    }

    values.forEach((order, index) => {
      if (order.displayValue === filterSelected) {
        options.push(
          <li key={index} className="item-selected" onClick={() => method(order)}>
            {order.displayValue}
          </li>
        )
      } else {
        options.push(
          <li key={index} className="change-submenu-item" onClick={() => method(order)}>
            {order.displayValue}
          </li>
        )
      }
    });

    return (
      <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {options}
          </u>
        </div>
      </div>
    )
  }

  showInactive = async() => {
    const {itemsByPageQuantity,rolId} = this.state;
    let {showInactive} = this.state;
    const {search} = this.props;

    this.setState({isLoading: true});
    const {users, usersQuantity} = await this.loadUsersFromApi(0, itemsByPageQuantity, search, !showInactive,rolId)
    const usersUpdated = updateUsersRoles(users);
    this.setState(
      {
        showInactive: !showInactive,
        users: usersUpdated,
        usersQuantity,
        isLoading: false,
        currentPage: 1
      })
  }

  showModalActive = (user,e) => {
    e.stopPropagation();
    this.setState({showModalActive: true,userSelected:user})
  }

  hideModalActive = () => {
    this.setState({showModalActive: false, userSelected:getInitialUser(),showModaUserHasSaleorTasks:false})
  }

  handleResize = () => {
    this.setState({widthPage: window.innerWidth});
  }

  handleShowFiltersMobile = () => {
    let {showFiltersMobile} = this.state;
    this.setState({showFiltersMobile : !showFiltersMobile})
  }

  save = () => {
    const {itemsByPageQuantity,showInactive} = this.state;
    // todo get roles and permission if user's role logged has been changed
    this.setState({showModal:true,showEditPanelUser:false,isLoading:true,userSelected:getInitialUser()});
    this.loadUsers(0,itemsByPageQuantity,'',showInactive)
  }

  showModalDelete(e, userSelected){
    e.stopPropagation();
    userHasSaleorTasks(userSelected.id).then((response) => {
      const {userHasSale, tasks} = response.data;
      if(userHasSale || tasks > 0 )
        this.setState({showModalDelete: false, showModaUserHasSaleorTasks: true, userHasSale : userHasSale , userHasTasks : tasks})
      else
        this.setState({showModalDelete:true, userSelected })
    })
  }

  hideModalDelete = ()=> {
    this.setState({showModalDelete:false})
  }

  render() {

    let {
      optionsFilter, listName, listFilterUser, showInactive, users, showModalInfo, roleSelected,
      userSelected, showEditPanelUser, showPopover, filterSelected, showPopoverStatus, showModalActive,
      widthPage, showFiltersMobile, isLoading, showModal, isNewUser, showModalDelete, showModaUserHasSaleorTasks,
      userHasSale, userHasTasks, selectedIndex
    } = this.state;
    const {currentPage, itemsByPageQuantity, anchorEl, usersQuantity} = this.state;
    const totalPages = usersQuantity ? Math.ceil(usersQuantity / itemsByPageQuantity) : 1;
    let childStatus = roleSelected ? this.renderRoleOptions() : null;

    let childUsers = [];
    if (filterSelected === "orderUser") {
      childUsers = this.renderValues("orderUser");
    } else if (filterSelected === "rolesUser") {
      childUsers = this.renderValues("rolesUser");
    }

    const element = <MessageBodyCustom hasTaskRepair={userHasTasks} hasSale={userHasSale} moduleSelected={MODULE_USER}/>;
    return (
      <div className="setting-container" style={{display: "block"}}>
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> : null
        }
        <div className="setting-form-header-header">
          {
            (widthPage <= WIDTH_VIEW_MOBILE ) ?
                <div className="container-filters-mobile" style={showFiltersMobile ?  {maxHeight:  119} : {maxHeight: 0}}>
                  <div className="panel-filters">
                    <div className="container-roles-permissions">
                      <div className="btn-container-dp-user">
                        <div id="rolesUser" className="btn-general-message-dp"
                             onClick={this.onChangeDropDown.bind(this, "rolesUser")}>
                          <span>{listFilterUser.displayValue}</span>
                          {
                            filterSelected === 'rolesUser' ? <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                                                       className="icon-arrow-content"
                                                                       beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}
                                                                       /> :
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                          className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')} />
                          }
                        </div>
                      </div>
                      <div className="btn-container-dp-user btn-filter">
                        <div id="permissionsUser" className="btn-general-message-dp" onClick={this.onChangeDropDown.bind(this,"permissionsUser")}>
                          <span>All Permissions</span>
                          {
                            filterSelected === 'permissionsUser' ? <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                                                             className="icon-arrow-content"
                                                                             beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                          className="icon-arrow-content"
                                          beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}
                                />
                          }
                        </div>
                      </div>
                    </div>
                    <div className="container-buttons">
                      <div className="panel-right">
                        <button className="btn-cancel" onClick={this.handleShowFiltersMobile}>Cancel</button>
                        <button className="btn-filter">Filter</button>
                      </div>
                    </div>
                  </div>
                </div> : null
          }
          <div className="row-message row-setting-user">
            {
              (widthPage > WIDTH_VIEW_TABLET) ?
                  <div className="form-setting-button" style={{width: "8%", paddingLeft: "20px"}}>
                    {
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"}
                                beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                                className="icon-add-user"
                                onClick={this.toggleAddUserPopover}/>
                    }
                  </div> : null
            }
            {
              (widthPage > WIDTH_VIEW_TABLET) ?
                  <div className="btn-container-dp-user">
                    <div id="orderUser" className="btn-general-message-dp btn-setting-filter-user"
                         onClick={this.onChangeDropDown.bind(this, "orderUser")}>
                      <span>{listName.displayValue}</span>
                      {
                        filterSelected === 'orderUser' ?
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                      className="icon-arrow-content"
                                      beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}
                            /> :
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                      className="icon-arrow-content"
                                      beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}
                            />
                      }
                    </div>
                  </div> : null
            }
            {
              (widthPage > WIDTH_VIEW_MOBILE) ?
                  <div className="container-filters">
                    <div className="container-roles-permissions">
                      <div className="btn-container-dp-user">
                        <div id="rolesUser" className="btn-general-message-dp"
                             onClick={this.onChangeDropDown.bind(this, "rolesUser")}>
                          <span>{listFilterUser.displayValue}</span>
                          {
                            filterSelected === 'rolesUser' ? <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                                                       className="icon-arrow-content"
                                                                       beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}
                                                                       /> :
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                          className="icon-arrow-content"
                                          beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}
                                />
                          }
                        </div>
                      </div>
                      <div className="btn-container-dp-user btn-filter">
                        <div id="permissionsUser" className="btn-general-message-dp" onClick={this.onChangeDropDown.bind(this,"permissionsUser")}>
                          <span>All Permissions</span>
                          {
                            filterSelected === 'permissionsUser' ? <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                                                             className="icon-arrow-content"
                                                                             beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                          className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                          }
                        </div>
                      </div>
                      <div className="user-active-buttons">
                        <button id='inactive' className={showInactive ? "inactive " : "active"} onClick={() => this.showInactive()}>
                          <ReactSVG key='inactive' className='icon-inactive' id="imgInactive"
                                    src={pathServer.PATH_IMG + "icon/ic_user_inactive.svg"}
                                    beforeInjection={ svg => svg.classList.add(showInactive ? "svg-inactive" : null)}
                          />
                          {!showInactive ?
                              <label>Show Inactives </label> :
                              <label>Hide Inactives</label>}
                        </button>
                      </div>
                    </div>
                  </div> : null
            }

            <div className="rounded-button"/>
            {
              (widthPage > WIDTH_VIEW_TABLET) ?
                  <div className="setting-user-pagination">
                    <DPPagination
                      optionsQuantityByPage={USERS_BY_PAGE}
                      selectedItemsByPage={itemsByPageQuantity}
                      handleChangeItemsByPage={this.handleItemsByPage}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={this.handlePageChange}
                      siteColor={siteColorAdmin}
                    />
                  </div> : null
            }
            {
              (widthPage <= WIDTH_VIEW_MOBILE) ?
                  <div className="panel-right-mobile">
                    <div className="container-icon">
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_sort.svg"}
                                className="icon-sort-content"
                                beforeInjection={ svg => { svg.classList.add("icon-sort");  svg.setAttribute('style', `fill: ${showFiltersMobile ? "#2194F1" : "#787878"}`);}}
                                onClick={this.handleShowFiltersMobile}/>
                    </div>
                  </div> :  null
            }
          </div>
        </div>
        <div className={showModal ? "table-form-setting user-table container-opacity" : "table-form-setting user-table"}>
          <div className="container-table-users">
            <table className="table">
              <thead className="thead-setting-user">
              <tr className="title-forms-list">
                <th className="title-photo-users-list">Photo</th>
                <th id="id-title-name-users-list" className="title-name-users-list">User name</th>
                {
                  (widthPage > WIDTH_VIEW_MOBILE) ?
                      <th className="title-email-users-list">Email</th> : null
                }
                {
                  (widthPage > WIDTH_VIEW_MOBILE) ?
                      <th id="id-title-phone-users-list" className="title-phone-users-list">Phone number</th> : null
                }
                {
                  (widthPage > WIDTH_VIEW_TABLET) ?
                      <th className="title-role-users-list">Roles</th> : null
                }
                <th className='th-status-superuser'/>
                <th className="title-graph-forms-list"/>
              </tr>
              </thead>
              <tbody className="tbody-user">
              {/*
              (widthPage > WIDTH_VIEW_TABLET) ?
                  <div className="filter-letter">
                    {letters.map((letter,index) => {
                      return (
                          <a key={index}
                             className={currentLetter === letter ? 'activeLetter':''}
                             onClick={() => this.selectLetter(letter)}>{letter}</a>
                      );
                    })}
                  </div> : null
            */}
              {
                users.length !== 0 ?
                    (
                        users.map((user, index) => {
                          let rol = "";
                          const quantityRoles = user.roles.length;
                          if (quantityRoles === 1) {
                            optionsFilter.forEach(op => {
                              if (user.roles.includes(op.id)) {
                                rol = op.displayValue
                              }
                            });
                          }
                          const userValue = {id: index, value: user};
                          return (
                              <tr key={index + 'tr'} className="rowUser" onClick={(e) => {this.openModal(user,e)}}>
                                <td className="title-photo-users-list" key={index + 'name'}>
                                  <img className="photo-user-img-circle" src={user.image ? user.image : pathServer.PATH_IMG + "ic_account_circle.png"} width="35px" alt=""/></td>
                                <td id="id-title-name-users-list" className="title-name-users-list" key={index + 'code'}>{user.firstName + " "} {user.lastName}</td>
                                {
                                  (widthPage > WIDTH_VIEW_MOBILE) ?
                                      <td className="title-email-users-list" key={index + 'Entity_Type'}>
                                        <b>{user.email}</b>
                                      </td> : null
                                }
                                {
                                  (widthPage > WIDTH_VIEW_MOBILE) ?
                                      <td id="id-title-phone-users-list" className="title-phone-users-list" key={index+'Created_By'}>
                                        {user.phoneNumber}
                                      </td> : null
                                }
                                {
                                  (widthPage > WIDTH_VIEW_TABLET) ?
                                      <td className="title-role-users-list" key={index + 'date'}>
                                        {
                                          user.accountOwner ?
                                              <div className="container-pill">
                                                <DPPillBox displayValue='ACCOUNT OWNER'/>
                                              </div>
                                              :
                                              <div id={"status"+ index} className="category-status"
                                                   ref={ el => this.popover[index] = el}
                                                   onClick={(e) =>this.handlePopoverStatus(userValue,index,e)} >
                                                {
                                                  <label >
                                                    {quantityRoles > 0 ?
                                                        quantityRoles === 1 ? rol : `${quantityRoles} Selected`
                                                        : 'None'}
                                                  </label>
                                                }
                                                {
                                                  showPopoverStatus && roleSelected === user.id  ?
                                                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                                                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                                                }
                                              </div>
                                        }
                                      </td> :
                                      null
                                }
                                <td className="td-status-superuser" style={(!user.active && user.accountOwner && widthPage <= WIDTH_VIEW_TABLET) ? {padding:"7px 0 7px 10px"} : null}>
                                  {
                                    (widthPage <= WIDTH_VIEW_TABLET && user.accountOwner) ?
                                        <DPPillBox displayValue='ACCOUNT OWNER' /> : null
                                  }
                                  {
                                    !user.active ?
                                      <div className="icon-left">
                                        <span>
                                          <ReactSVG
                                            key={index + 'inactive'}
                                            id="imgInactive"
                                            src={pathServer.PATH_IMG + "icon/ic_inactive.svg"}
                                            beforeInjection={ svg => svg.classList.add("ic-inactive")}
                                          />
                                        </span>
                                      </div> : null
                                  }
                                </td>
                                <td className="td-actions-user" key={index + 'td'}>
                                  {
                                    !user.active ?
                                      <div className="superuser-actions">
                                        <div className="icon-right">
                                          <span>
                                            <ReactSVG
                                              key={index + 'edit'}
                                              id="imgEdit"
                                              onClick={(e) => this.openEdit(user, e)}
                                              src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"}
                                              beforeInjection={svg => svg.classList.add("icon-edit")}
                                            />
                                          </span>
                                          <span>
                                            <ReactSVG
                                              key={index + 'check'}
                                              id="imgCheck"
                                              src={pathServer.PATH_IMG + "icon/ic_user_active.svg"}
                                              beforeInjection={svg => svg.classList.add("icon-check")}
                                              onClick={(e) => {this.showModalActive(user, e) }}
                                            />
                                          </span>
                                          <span>
                                            <ReactSVG
                                              key={index + 'delete'}
                                              id="imgDelete"
                                              src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                                              beforeInjection={svg => svg.classList.add("icon-delete")}
                                              onClick={(e) => {this.showModalDelete(e, user)}}
                                            />
                                          </span>
                                        </div>
                                      </div> :
                                      <div className="superuser-actions">
                                        <div className="icon-right">
                                          <span>
                                            <ReactSVG
                                              key={index + 'edit'}
                                              id="imgEdit"
                                              onClick={(e) => this.openEdit(user, e)}
                                              src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"}
                                              beforeInjection={svg => svg.classList.add("icon-edit")}
                                            />
                                          </span>
                                          <span>
                                            <ReactSVG key={index + 'cancel'} id="imgCancel"
                                              src={pathServer.PATH_IMG + "icon/ic_user_inactive.svg"}
                                              beforeInjection={svg => svg.classList.add("icon-cancel")}
                                              onClick={(e) => {this.showModalActive(user, e)}}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                  }
                                </td>
                              </tr>
                          )
                        })
                    ) : null
              }
              </tbody>
            </table>
          </div>
          {
            widthPage <= WIDTH_VIEW_TABLET ?
                <div className="container-user-pagination">
                  <div className="setting-user-pagination">
                    <DPPagination
                      optionsQuantityByPage={USERS_BY_PAGE}
                      selectedItemsByPage={itemsByPageQuantity}
                      handleChangeItemsByPage={this.handleItemsByPage}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={this.handlePageChange}
                      siteColor={siteColorAdmin}
                    />
                  </div>
                </div> : null
          }
        </div>
        {showPopover && filterSelected !== null ? <Popover anchorEl={anchorEl}
                                                           child={childUsers} classPopover="popover-box-message"
                                                           handleRequestClose={this.handleRequestClose}/> : null}

        <DPOverlay anchorEl={this.popover && this.popover[selectedIndex] ? this.popover[selectedIndex] : null}
                   show={showPopoverStatus}
                   className={classnames('dp-tooltip', 'user-popover-roles')}
                   onHide={this.handleRequestClose}
                   customMaxWidth={180}
                   triggerYToHide={84}
        >
          {childStatus}
        </DPOverlay>
        {showModalInfo ? <UserInfo userSelected={userSelected} showModal={showModalInfo} closeModal={this.closeModal}
                                   openEdit={this.openEdit} optionsFilter={optionsFilter}/> : null}
        {showEditPanelUser ?
          <UserEdit userSelected={userSelected} showModal={showEditPanelUser} closeModal={this.closeModal}
                    optionsFilter={optionsFilter} save={this.save} isNewUser={isNewUser}/> : null}

        {
          showModalActive || showModaUserHasSaleorTasks ?
            <ModalConfirmDeactivate show={showModalActive || showModaUserHasSaleorTasks}
                                    onSubmit={this.updateStatus}
                                    onHide={this.hideModalActive}
                                    showButton={!showModaUserHasSaleorTasks}
                                    bodyMessage={element}
                                    message={userSelected ?  getMessage(userSelected.active) : null}
                                    isBodyCustom={showModaUserHasSaleorTasks}
                                    classButtonDelete="btn-delete"
                                    buttonLabelDelete={ userSelected && userSelected.active ? "Yes, deactivate." : "Yes, activate."}
                                    viewMobile={false}
                                    iconQuestion={ showModaUserHasSaleorTasks ? "icon/ic_warning.svg" :  !userSelected.active ? "icon/ic_question_warning_activate.svg" : null}
                                    showAnotherMessage = {showModaUserHasSaleorTasks  ?  false : userSelected.active}
                                    anotherMessage = "Delete user permanently"
            /> : null
        }
        {
          showModalDelete ?
              <ModalConfirmDelete show={showModalDelete}
                                  showButtonDelete={true}
                                  onSubmit={this.deleteUser}
                                  onHide={this.hideModalDelete}
                                  styleModal="modal-delete"
                                  classButtonDelete="btn-delete"
                                  buttonLabelDelete="Yes, Delete"
                                  message={"Do you want to delete this User?"}
                                  isBodyCustom={true}
                                  styleRight="panel-right-delete-user"/> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.email,
    userRoles: state.userRoles
  }
};

const Container = (props) => {
  return(
    <DragDropContext>
      <SettingUserList {...props}/>
    </DragDropContext>
  )
};

export default connect(mapStateToProps,{setKeyword})(Container)