import React, {useState} from "react";
import {pathServer, TIME_FIVE_SECONDS, WIDTH_VIEW_MOBILE} from "../../../../../common/Constants";
import NavigationBar from "../navigationBar/NavigationBar";
import {buildMessages, emailData, messageEmailWithSelectedCar} from "../../../../../common/Util";
import {sendContactUsEmail} from "../../../../Api";
import {checkValidEmail, checkValidPhoneNumber} from "../../../../../common/ValidationUtil";
require('./contactus.scss')

const inputStyles = {
}

const initialFormContactUs = {
  name: '',
  email: '',
  text: ''
}

const ContactUs = ({isModal = false, ...props}) => {

  const [contactUsForm, setContactUsForm] = useState(initialFormContactUs)
  const [errors, setErrors] = useState([])
  const [message, setMessage] = useState('')

  const sendMessage = (e) => {
    e.preventDefault()
    setErrors([])
    setMessage('')
    if (isFormValid()) {
      sendContactUsEmail(contactUsForm).then(response => {
        setMessage('Your message was send')
        setTimeout(() => {
          setContactUsForm(initialFormContactUs)
          setMessage('')
          if(isModal) {
            props.closeModal()
          }
        }, 5000)
      }).catch(error => {
        console.error('error:', error)
      })
    }
  }

  const isFormValid = () => {
    const { name, email, text } = contactUsForm
    const isInvalidEmail = !email || !(email.trim()) || !checkValidEmail(email.toLowerCase())

    let errors = []
    if (!name || !(name.trim()))
      errors.push('Incorrect name')
    if (!text || !(text.trim()))
      errors.push('Incorrect text')
    if (isInvalidEmail)
      errors.push('Incorrect email')

    if (errors.length > 0) {
      setErrors(errors)
      return false
    }

    return true
  }

  const onInputChange = (prop, value) => {
    setContactUsForm({... contactUsForm, [prop]: value ?? ''})
    setErrors([])
  }

  const {email = '', name = '', text = ''} = contactUsForm

  return (
    <>
      {
        !isModal &&
        <NavigationBar/>
      }
      <div className={`container-body ${!isModal ? 'content-center' : ''}`} >
        <div className={'container-logo'}>
          <img className="logo" src={pathServer.PATH_IMG + "godealergo.png"} alt="logo"/>
        </div>
        <div className="title">
          {'Contact us'}
        </div>
        {buildMessages(errors, message)}
        <div className="form">
          <form>
            <div className='form-first-row'>
              <div className="input-text form-first-row-element">
                <span className="title-input-get-in">Name</span>
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => onInputChange('name', e.target.value)}
                  name="clientName"
                  placeholder="Name"
                  style={inputStyles}
                />
              </div>
              <div className="input-text form-first-row-second-element">
                <span className="title-input-get-in">Email</span>
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  value={email}
                  onChange={(e) => onInputChange('email', e.target.value)}
                  name="email"
                  placeholder={'Email'}
                  style={inputStyles}
                />
              </div>
            </div>
            <div className="input-message">
              <span className="title-input-get-in">Message</span>
              <input
                className="form-control"
                type="text"
                id="text"
                value={text}
                onChange={(e) => onInputChange('text', e.target.value)}
                name="text"
                placeholder="Leave us a message"
                style={inputStyles}
              />
            </div>
            <div className="btn-row">
              <div className="btn-send-content">
                <a id="btnSend" className="btn-send" onClick={(e) => sendMessage(e)}>Send</a>
              </div>
            </div>
            <div className="info-row">
              <div className="phone">
                <span>(801) 494 - 9191</span>
              </div>
              <div className="email">
                <span>info@godealergo.com</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ContactUs
