import React, {Component} from 'react'
import {connect} from 'react-redux'
import {sendMailRecoverPasswordApi} from '../../Api.js'
import {maskEmail} from '../util/Utils'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'

class RecoverPassword extends Component {

  constructor() {
    super();
    this.state = {
      maskEmail:""
    };
  }

  componentDidMount() {
    const {email} = this.props;
    this.sendEmailRecoverPassword();
    this.setState({maskEmail:maskEmail(email)})
  }

  sendEmailRecoverPassword = () => {
    const {email} = this.props;
    sendMailRecoverPasswordApi(email).then(response => {
      const {responseCode, responseMessage} = response
      if(responseCode === SUCCESSFUL_CODE){
        console.log("send email recover")
      }else{
        console.error("Error send email",responseMessage)
        this.props.back()
      }
    })
  }

  render() {
    let {maskEmail} = this.state;
    console.log('recoverPass')
    return (
      <div className="form-content form-recover-password">
        <div className="logo-godealergo-recover">
          <img className="logo-godealergo" src={pathServer.PATH_IMG + "godealergo.png"} alt=""/>
        </div>
        <div className="recover-password-img">
          <img src={pathServer.PATH_IMG + "recover-password.png"}/>
        </div>
        <div className="text-forgot-password text-center">
          <p>We've sent a recovery link to your</p>
          <p>verified email: <span>{maskEmail}</span></p>
          <p>please check your inbox</p>
        </div>
        <div className="text-center">
          <button className="btn-back-login" onClick={() => this.props.backLogin()}>Sign in</button>
        </div>
        <div className="text-center label-send-again" onClick={this.sendEmailRecoverPassword}>
          Send email again
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state,ownProps) => {
  return {
    dealerId:state.dealerId
  }
};

export default connect(mapStateToProps)(RecoverPassword)