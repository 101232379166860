import React from 'react'
import DPSelectionDropDown from '../../../../common/DPSelectionDropDown'

const defaultOption = [
  {
    id: 'home',
    displayValue: 'Home'
  },
  {
    id: 'inventory',
    displayValue: 'Vehicles'
  },
  {
    id: 'finance',
    displayValue: 'Financing'
  },
  {
    id: 'aboutUs',
    displayValue: 'About us'
  }
]

const LinkContent = ({optionsLink = defaultOption, ...props}) => {
  const {value, index, type, onChangeTabNavigation} = props
  return (
    <DPSelectionDropDown
      style={{width: '100%'}}
      textStyle={{
        marginBottom: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }}
      className='select-dropdown'
      options={optionsLink}
      value={value}
      onClick={ value => onChangeTabNavigation(index, type, value)}
      errorMessage={null}
      disabled={false}
      selectStyle={{borderColor: '#EFEFEF'}}
      id={'selectNavBar'}
    />
  )
}

export default LinkContent