import React from 'react'
import PropTypes from 'prop-types'

const bodyCarsHeader = props => (
  <div className="body-car-header">
    <div className="content">
      {props.children}
    </div>
  </div>
)

bodyCarsHeader.propTypes = {
  action: PropTypes.func
}

export default bodyCarsHeader