import React, {Component} from 'react'
import {FileEmpty, TitleHeader} from './SaleEdit'
import {loadCheckListBySaleId, saveSaleCheck} from '../../Api'
import DPCheckbox from '../../common/DPCheckbox'
import {DPSpinner} from '../../common/DPSpinner'
import {SUCCESSFUL_CODE} from "../../../common/Constants";

class SaleChecklist extends Component{
  constructor() {
    super();
    this.state = {
      saleChecklist: [],
      isLoading: false
    }
  }

  componentDidMount() {
    const {saleId} = this.props;
    this.startLoadingPage();
    loadCheckListBySaleId(saleId).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        const {saleCarAndCheckList} = data;
        const {saleChecklist} = saleCarAndCheckList;
        this.setState({saleChecklist, isLoading: false})
      } else {
      console.error("error:", responseMessage);
      this.setState({isLoading: false})
      }
    })
  }

  handleCheck (itemToSave) {
    const { saleId } = this.props;
    this.startLoadingPage();

    saveSaleCheck(saleId, itemToSave)
      .then(({responseCode, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          this.updateSaleCheckListBySaleId(saleId)
        } else {
          console.error(responseMessage)
          this.setState({isLoading: false})
        }
      })
  }

  updateSaleCheckListBySaleId (saleId) {
    this.startLoadingPage();

    loadCheckListBySaleId(saleId)
      .then(({responseCode, responseMessage, data}) => {
        if (responseCode === SUCCESSFUL_CODE){
          const {saleCarAndCheckList} = data;
          const {saleChecklist} = saleCarAndCheckList;
          this.setState({saleChecklist, isLoading: false})
        } else {
          console.error(responseMessage);
          this.setState({isLoading: false})
        }
      })
  }

  startLoadingPage () {
    const { isLoading } = this.state;

    if (!isLoading) {
      this.setState({ isLoading: true })
    }
  }


  render() {
    const {checklist, existChecklist, isComplete, goToSaleSetting, isCancelledSale} = this.props;
    const {saleChecklist, isLoading} = this.state;
    if(!saleChecklist) return null;

    return(
      <div className="panel-sale-check-list" style={{border: !existChecklist && !isComplete ?  "1px solid #EAA734" : "1px solid #D7DAE2"}}>
        <TitleHeader classTitle="title-check-list" classIcon="icon-check-list" classIconSvg="icon-check-list-svg" icon="icon/ic_notepad.svg" selectOption={false}
                     title="Check List" />
        {
          isLoading ?
            <DPSpinner classContainer={"loader-container"}/> :
            <div className={"check-list-body" + (checklist && checklist.length === 0 ? " check-list-body-empty":"")} data-test={'check-list-body'}>
              {
                checklist && checklist.length > 0
                  ? checklist.map((element, index) => {
                    const foundItem = saleChecklist.find(el => el.checklistId === element.id)
                    const isItemChecked = foundItem ? foundItem.checked : false

                    return (
                      <div
                        key={element.order}
                        className="content-check"
                        data-test={`sale-content-check-${index}`}
                      >
                        <DPCheckbox
                          checked={isItemChecked}
                          id={element.id}
                          onCheck={() => {
                            const itemToSave = {checklistId: element.id, checked: !isItemChecked};
                            this.handleCheck(itemToSave)
                          }}
                          testid={`checkbox-sale-${index}`}
                          disabled={isCancelledSale}
                        />
                        <div className="container-label" data-test={`sale-check-label-${index}`}>
                          <p className="check-description">{element.description}</p>
                          {
                            element.required
                              ? <p className="check-required">*</p>
                              : null
                          }
                        </div>
                      </div>
                    )
                  })
                  :
                  <FileEmpty title={'No items on check list yet'} onClick={goToSaleSetting}/>
              }
              {
                checklist.length > 0
                  ? <div className="container-required-items">
                    <label className="required-items">*Required items</label>
                  </div>
                  : null
              }
            </div>
        }
      </div>
    )
  }
}

export default SaleChecklist;
