import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import {
  InputTypes,
  FORM_SALE_BUYER_AGREEMENT,
  saleAttribute,
  FORM_RETAIL_INSTALLMENT_CONTRACT,
} from '../../Constants'
import {parseObjectForm, parseValues, setErrors, validate} from '../../../common/Util'
import {loadFormByCode, processFormApi, getServerUrl} from '../../Api'
import {isArray, findIndex} from 'lodash'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'

const TitleModal = (props) => {
  const {text} = props;
  return(
    <div className="title-modal">
      <label>
        {text}
      </label>
    </div>
  )
}
const SubTitleModal = (props) => {
  const {text} = props
  return(
    <div className="subtitle-modal">
      <label>
        {text}
      </label>
    </div>
  )
}

const OTHER_TERMS_DEFAULT = 'NO MONEY BACK\n' +
  'NO RETURNS\n' +
  'NO WARRANTY\n' +
  'NO VEHICLE EXCHANGE';

const getTitle = (formCode) => {
  let title = 'Additional information'
  if(formCode === FORM_RETAIL_INSTALLMENT_CONTRACT)
    title = 'Retail Installment Contract'
  return title
}

const sectionForms = {
  firstSection: 'Section-0'
}

export class ModalGeneratePreviousPDF extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      formBase: [],
      FormRender: null,
      showDropDown:{},
      sections:[],
      saleForms:null,
      contractForm: {},
      errors:{}
    }
  }

  componentDidMount() {
    const {saleId, formCode} = this.props;
    this.setState({isLoading: true});
    loadFormByCode(formCode, saleId).then(response => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {form, values: originalValues} = data;
        const {formAttributes:formBase} = form
        const {sections, attributes} = formBase
        const sectionsForm = sections;
        const saleForms = parseObjectForm(sectionsForm);
        const contractForm = parseValues(attributes,sectionsForm);
        const values = this.getValues(originalValues, attributes, formCode);
        this.setState({formCode, formBase, sections: sectionsForm, saleForms, contractForm,
          values, attributes, isLoading: false, saleId})
      }else{
        console.error(responseMessage)
        this.setState({isLoading: false})
      }
    })
  }

  getValues = (values, attributes, formCode) => {
    const newValues = {...values};
    const {balance} = this.props;
    if(formCode === FORM_SALE_BUYER_AGREEMENT){
      const attributeFound = attributes.find(attribute => { return attribute.name === saleAttribute.OTHER_TERMS})
      if(attributeFound && newValues[attributeFound.id] === '')
        newValues[attributeFound.id] = OTHER_TERMS_DEFAULT
    }else if(formCode === FORM_RETAIL_INSTALLMENT_CONTRACT){
      const attributeFound = attributes.find(attribute => { return attribute.name === saleAttribute.AMOUNT_FINANCED})
      if(attributeFound )
        newValues[attributeFound.id] = balance.subTotal
    }
    return newValues
  }

  onFormChange = (data,index,inputType) => {
    let {values, attributes} = this.state;
    for(let key in values) {
      if(isArray(values[key])) {
        if(typeof data[key] !== 'undefined' && values[key]) {
          if(!inputType || (inputType && inputType !== InputTypes.MULTI_SELECT))
           values[key].length = 0;

          if(inputType && inputType === InputTypes.MULTI_SELECT){
            const indexFound = findIndex(values[key],(value) => {return value === data[key]});
            if(indexFound === -1)
              values[key].push(data[key]);
            else
              values[key].splice(indexFound,1);
          }else{
            values[key].push(data[key]);
          }
        }
      }else{
        if (typeof data[key] !== 'undefined') {
          values[key] = data[key];
        }
      }
    }

    this.setState({values:values, attributes: attributes});
  }

  saveAttributeValues = async () => {
    const {onHide, templateCode} = this.props;
    const {saleId, formCode, values, sections, attributes, contractForm} = this.state;
    let errors = {}
    for (let section in contractForm) {
      for (let key in contractForm[section]) {
        contractForm[section][key] = values[key];
      }
    }
    const ref = this.refs[sectionForms.firstSection];
    errors[sectionForms.firstSection] = {...ref.state.errors};
    setErrors(attributes, contractForm[sectionForms.firstSection], errors[sectionForms.firstSection]);
    let hasError = validate(errors);

    let path = 'tools'
    //if(templateCode === FILE_TEMPLATE_TITLE_AGREEMENT_CODE)
    //  path = 'toolsEditable'
    // We have to call here to avoid pop-up blockers
    const newWindow = window.open(`${getServerUrl()}api/sales/${saleId}/${path}/${templateCode}`, '_blank');

    if(!hasError){
      let requestForm = {formCode:formCode,
        objectId:saleId,
        values:values};

      this.setState({ isLoading: true })
      const {responseCode} = await processFormApi({ requestForms: [requestForm] })
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({ isLoading: false }, () => {
          newWindow.focus()
          onHide()
        })
      } else {
        this.setState({ isLoading: false })
      }
    }else{
      ref.state.errors = errors[sectionForms.firstSection]
      this.setState({errors})
    }
  }

  render() {
    const {show, onHide, FormRender, classModal, templateName, formCode} = this.props;
    const {values, attributes, saleForms, isLoading, sections} = this.state;

    if (this.state.formBase === [] || !saleForms) {
      return null
    }

    return (
      <Modal show={show} className={"modal-new-sale "+ (classModal ? classModal : '')} backdropClassName="backdrop-dealer">
        <Modal.Body>
          {
            isLoading ?
              <div className="loader-container" style={{position: 'absolute'}}>
                <div className="loader"/>
              </div> :
              <div className="panel-container">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close-content"
                          beforeInjection={ svg => svg.classList.add('icon-close')} onClick={onHide}/>
                <div className="panel-container-body">
                  <TitleModal text={getTitle(formCode)} />
                  { formCode === FORM_RETAIL_INSTALLMENT_CONTRACT && <SubTitleModal text={'Please fill the following fields'}/> }
                  <div className="panel-container-body-sections">
                    {
                      sections ?
                          sections.map(section => {
                            const {name} = section;
                            return (
                                <FormRender key={`section-${name}`}
                                            ref={name}
                                            section={saleForms[name]}
                                            attributes={attributes}
                                            data={values}
                                            onChange={this.onFormChange}/>
                            )
                          }) : null
                    }
                  </div>
                  <div className="container-button-done">
                    <button className="btn-done" onClick={this.saveAttributeValues}>
                      {templateName}
                    </button>
                  </div>
                </div>
              </div>
          }
        </Modal.Body>
      </Modal>
    )
  }
}