import React, {Component, useState} from 'react'
import {
  FACEBOOK_PAGE_ID_CODE,
  FACEBOOK_TOKEN_CODE,
  FACEBOOK_USER_ID_CODE,
  LOGO,
  GOOGLE_REVIEW_URL,
  INSTAGRAM_USER_ID_CODE,
  responseStatus, CARFAX_LINK
} from '../../Constants'
import {ReactSVG} from 'react-svg'
import {Modal} from 'react-bootstrap'
import {
  loadDealerConfigurationByCodes,
  saveDealerConfiguration,
  saveSocialNetworkLoginHistory, // check
  loadCredentialByType,
  saveCredentials,
  loadCredentialsByTypes,
  subscribePageToWebhook, // check
  sendAuthorizationCode,
  updateCredentialLocationId,
  getAccountData,
  updateGoogleTestimonials,
} from '../../Api'
import Loader from '../../../common/UI/Loader.jsx'
import {DPInput} from '../../common/DPInput'
import {get, isEmpty} from 'lodash'
import ModalConfirmDeactivate from '../util/ModalConfirmDeactivate'
import {DPTextarea} from '../../common/DPTextarea'
import {EmptyFunc, pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import WebhookFacebookMarketing from "./WebhookFacebookMarketing";
import SubscriptionWebhook from './SubscriptionWebhook'
import DPMessageInfo from '../../../common/DPMessageInfo'
import DPIcon, {Icons} from '../../common/DPIcon'

const scope = "public_profile, pages_show_list, pages_manage_posts, pages_read_engagement, pages_read_user_content, pages_manage_engagement, pages_manage_metadata";
const instagramScope = 'instagram_basic, instagram_content_publish, business_management, ads_management';
// instagramScope = instagram_manage_insights
//const scope = "public_profile, manage_pages, publish_pages";

const ModalConnect = ({show,closeModal}) => {
  return (
    <Modal show={show} className="marketing-modal-container" backdropClassName="backdrop-car">
      <Modal.Body>
        <div className="marketing-modal">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon"
                    onClick={closeModal}/>
          <div className="marketing-modal-title">
            Successfully linked
          </div>
          <div className="marketing-modal-body">
            <div className="marketing-modal-image">
              <img src={pathServer.PATH_IMG + "admin-marketing-graphic.png"}/>
            </div>
            <div className="marketing-modal-text">
              <label>Now you can manage Testimonial, Ads and Campaigns from Marketing Manager</label>
            </div>
            <div className="marketing-modal-button">
              <a className="btn">
                Marketing Manager
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
};

const ModalSelectItem = ({show,handleSelectItem,closeModal, title = "Select Page", items, type = "page"}) => {
  return(
    <Modal show={show} className="marketing-modal-container" backdropClassName="backdrop-car">
      <Modal.Body>
        <div className="marketing-modal">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon"
                    onClick={closeModal}/>
          <div className="marketing-modal-title" style={ items.length === 0 ? {background: '#e60000d1'} : {}}>
            {title}
          </div>
          <div className="marketing-modal-body" style={items.length <= 4 ? {justifyContent:"center"}:null}>
            {
              items.map((item,index) => {
                return(
                  <div key={index}>
                    <button className="btn-primary btn-facebook-page" onClick={ () => handleSelectItem(item)}>
                      {type === 'page' ? item.name :  item.title}
                    </button>
                  </div>
                )
              })
            }
            {
              items.length === 0 &&
              <DPMessageInfo
                messageText={`No ${type === 'page' ? 'Pages' : 'Locations'} found. Please create one to start managing it.`}
              />
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const ModalLocations = ({
                          title = 'Select a business location',
                          items = [],
                          handleSelectItem = EmptyFunc,
                          type = 'location',
                          closeModal = EmptyFunc,
                          show = false,
}) => {
  const [itemSelected, setItemSelected] = useState({})

  return(
    <Modal show={show} className="location-modal-container" backdropClassName="backdrop-car">
      <Modal.Body>
        <div className="modal-location-box">
          <DPIcon
            icon={Icons.CLOSE_CIRCLE_RED}
            onClick={closeModal}
            className={"close-modal-icon"}
          />
          <span className="title">{title}</span>
          <div className="container-locations">
            <div className="locations-box">
              {
                items.length > 0 && items.map((item,index) => {
                  const isSelected = item.name === itemSelected.name
                  return(
                    <div key={index}>
                      <div className={`item-container ${isSelected ? 'item-selected': ''}`} onClick={ () => setItemSelected(item)}>
                        {type === 'page' ? item.name :  item.title}
                        {
                          isSelected && <DPIcon icon={Icons.CHECK_CIRCLE}/>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {
              items.length > 0 &&
              <div className="container-button">
                <button
                  disabled={isEmpty(itemSelected)}
                  onClick={ () => handleSelectItem(itemSelected)}
                  className={`${isEmpty(itemSelected) ? 'disabled-button' : ''}`}
                >
                  Continue
                </button>
              </div>
            }
            {
              items.length === 0 &&
              <DPMessageInfo
                messageText={`No ${type === 'page' ? 'Pages' : 'Locations'} found. Please create one to start managing it.`}
              />
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const FacebookLoginContainer = (props) => {
  const { facebookPagePicture, facebookPageName, handleFacebookSignOut, handleChangeSwitch,
    testimonials, ads, campaigns, userName} = props
  return (
    <div className="social-media-login">
      <div className="facebook-login-container">
        <label className="facebook-title">Facebook</label>
        <div className="user-info">
          <img src={facebookPagePicture}/>
        </div>
        <div className='facebook-info-container'>
          <DPInput
            title={'Facebook account'}
            id={'facebook-account'}
            type={'text'}
            value={userName}
          />

          <DPInput
            title={'Facebook Page'}
            id={'facebook-page'}
            type={'text'}
            value={facebookPageName}
          />
        </div>
        {/* This part was hiding temporarily */}
        {/*<div className="facebook-switch-options">*/}
        {/*  <div className="facebook-switch-option">*/}
        {/*    <label>Testimonials</label>*/}
        {/*    <label className="switch" onChange={() => handleChangeSwitch("testimonials")}>*/}
        {/*      <input type="checkbox" checked={testimonials}/>*/}
        {/*      <span className={testimonials ? "slider round" : "slider round off"}/>*/}
        {/*      {*/}
        {/*        testimonials ?*/}
        {/*          <span className="check-label-on">ON</span> :*/}
        {/*          <span className="check-label-off">OFF</span>*/}
        {/*      }*/}
        {/*    </label>*/}
        {/*  </div>*/}
        {/*  <div className="facebook-switch-option">*/}
        {/*    <label>Ads</label>*/}
        {/*    <label className="switch" onChange={() => handleChangeSwitch("ads")}>*/}
        {/*      <input type="checkbox" checked={ads}/>*/}
        {/*      <span className={ads ? "slider round" : "slider round off"}/>*/}
        {/*      {*/}
        {/*        ads ?*/}
        {/*          <span className="check-label-on">ON</span> :*/}
        {/*          <span className="check-label-off">OFF</span>*/}
        {/*      }*/}
        {/*    </label>*/}
        {/*  </div>*/}
        {/*  <div className="facebook-switch-option">*/}
        {/*    <label>Campaigns</label>*/}
        {/*    <label className="switch" onChange={() => handleChangeSwitch("campaigns")}>*/}
        {/*      <input type="checkbox" checked={campaigns}/>*/}
        {/*      <span className={campaigns ? "slider round" : "slider round off"}/>*/}
        {/*      {*/}
        {/*        campaigns ?*/}
        {/*          <span className="check-label-on">ON</span> :*/}
        {/*          <span className="check-label-off">OFF</span>*/}
        {/*      }*/}
        {/*    </label>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <SubscriptionWebhook/>
        <WebhookFacebookMarketing
          buttonClass={'recommendation-button'}
          description={'Download Recommendations'}
          subDescription={'Last Update:'}
        />
        <div className="fb-btn-content">
          <a
            className="btn button-face sign-out"
            onClick={handleFacebookSignOut}
          >
            Sign Out
          </a>
        </div>
      </div>
    </div>
  )
}

const GoogleLoginContainer = (props) => {
  const { googleLogoSource, handleGoogleSignOut, urlValue, googleAccountPicture = `${pathServer.PATH_IMG}/user.png`, userName, locationName } = props
  return (
    <div className="social-media-login">
      <div className="google-settings-container">
        <ReactSVG
          src={googleLogoSource}
          className='google-logo'
        />
        <div className="user-info">
          <img
            src={googleAccountPicture}
          />
        </div>
        <div className='google-info-container'>
          <DPInput
            title={'Google account'}
            id={'google-account'}
            data-test={'google-account'}
            type={'text'}
            value={userName}
          />
          <DPInput
            title={'Location'}
            id={'google-location'}
            data-test={'google-location'}
            type={'text'}
            value={locationName}
          />
        </div>
        <WebhookFacebookMarketing
          buttonClass={'recommendation-button'}
          description={'Download Reviews'}
          subDescription={'Last Update:'}
          updateTestimonials={updateGoogleTestimonials}
        />
        <div className="google-btn-content">
          <a
            className="sign-out"
            onClick={handleGoogleSignOut}
            data-test={'btn-google-reviews'}
          >
            Sign Out
          </a>
        </div>
      </div>
    </div>
  )
}

const InstagramLoginContainer = (props) => {
  const { instagramLogoSource, handleInstagramSignOut, facebookPageName, instagramUserId } = props
  return (
    <div className="social-media-login">
      <div className="instagram-settings-container">
        <div className="user-info">
          <ReactSVG
            src={instagramLogoSource}
            className='instagram-logo'
          />
        </div>
        {
          instagramUserId
          ? <React.Fragment>
            <div className='instagram-info-container'>
              <DPInput
                title={'Facebook page associated to this account'}
                id={'fb-page-associated'}
                type={'text'}
                value={facebookPageName}
              />
            </div>
            <div className="instagram-btn-content">
              <a
                className="btn button-sign-out"
                onClick={handleInstagramSignOut}
              >
                Sign out
              </a>
            </div>
          </React.Fragment>
          : <div className='instagram-error-container'>
              <img src={pathServer.PATH_IMG + "ooops.png"}/>
              <p>There is not any Facebook page Associated to this Instagram Account</p>
            </div>
        }
      </div>
    </div>
  )
}

const CarFaxContainer = (props) => {
  const { carfaxLogoSource, handleCarFaxSignOut, urlValue, handleChangeCarFaxUrl } = props
  return (
    <div className="social-media-login">
      <div className="carfax-settings-container">
        <div className="user-info">
          <ReactSVG
            src={carfaxLogoSource}
            className='google-logo'
          />
        </div>
        <div className='carfax-review-url-container'>
          <p>Carfax allows users to see cars history from your inventory in one click. Please paste the HTML provided by Carfax below. To get the code please log in Carfax page and go to > dealers > integrations and select one.</p>
          <DPTextarea
            label={'CarFax HTML provided'}
            rows={5}
            value={urlValue}
            onChange={event => handleChangeCarFaxUrl(event)}
          />
        </div>
        <div className='carfax-review-url-container'>
          <label className="title">Example</label>
          <img src={pathServer.PATH_IMG + "/carfax_sample.png"}/>
        </div>
        <div className="carfax-btn-content">
          <a
            className="btn button-save"
            onClick={handleCarFaxSignOut}
            data-test={'btn-google-reviews'}
          >
            Save
          </a>
        </div>
      </div>
    </div>
  )
}

const RECTANGLE_ICON = 'rectangle';

const SocialMediaItem = (props) => {
  const {handleClickItem, imageSource, itemText, isConnectedToSocialMedia, testId, iconForm, isLoading = false} = props
  return (
    <div
      className='social-media-item'
      onClick={!isLoading ? handleClickItem : EmptyFunc}
      data-test={testId}
    >
      {
        !isLoading
          ? <>
              <ReactSVG
                src={imageSource}
                className={iconForm === RECTANGLE_ICON ? 'social-media-icon-rectangle' : 'social-media-icon'}
              />
              <div className='social-media-text'>{itemText}</div>
              {
                isConnectedToSocialMedia
                  ? <ReactSVG
                      src={`${pathServer.PATH_IMG}icon/ic_check_normal.svg`}
                      className='check-icon'
                    />
                  : null
            }
          </>
         : <div className="loader-container">
            <div className="loader"/>
           </div>
      }
    </div>
  )
}

const client_id = "1601758376804649";
const client_secret = "fb2246c8541a7a3d229b3149e4dfd5c7";
const DEFAULT_ERROR_ON_CONNECTION = {
  [LOGO.FACEBOOK]: false,
  [LOGO.GOOGLE]: false,
  [LOGO.INSTAGRAM]: false,
  [LOGO.KSL]: false,
  [LOGO.ZOOM_AUTOS]: false,
}

export default class SettingMarketing extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      showLogin: false,
      showConnectedModal: false,
      testimonials: false,
      ads: false,
      campaigns: false,
      isLoggedIn: false,
      connecting: false,
      userFacebookName: null,
      userFacebookPicture: null,
      limit: 100,
      showModalPage:false,
      facebookPages:[],
      facebookPageName:null,
      pageFacebookPicture:null,
      isLoadingLogout:false,
      userName:null,
      isConnectedTo: {
        [LOGO.FACEBOOK]: false,
        [LOGO.GOOGLE]: false,
        [LOGO.INSTAGRAM]: false,
        [LOGO.KSL]: false,
        [LOGO.ZOOM_AUTOS]: false,
      },
      showSettingsFor: '',
      currentSettings: {
        facebookPageId: '',
        facebookAccessToken: '',
        facebookUserId: '',
        instagramUserId: '',
      },
      errorOnConnection: DEFAULT_ERROR_ON_CONNECTION,
      carfaxHTMLToSave: '',
      //Google variables
      googleLocations : [],
      googleAccountName : "",
      googleLocationTitle : '',
      googleAccountId : null,
      isLoadingGoogle: false,
      locationProfileUrl: ''
    }
  }

  componentDidMount() {
    window.fbAsyncInit = () => {
      FB.init({
        appId: client_id,
        cookie: true,  // enable cookies to allow the server to access
        status: true,  // the session
        xfbml: true,  // parse social plugins on this page
        version: 'v3.2' // use graph api version 2.5
      });


      FB.getLoginStatus(async (response) => {
        let isConnect = false;
        if (response.status === 'connected') {
          isConnect = true;
        }
        await this.loadCredential(isConnect);
      });
    };

    if (!document.getElementById('facebook-jssdk')) {
      // Load the SDK asynchronously
      (function (d, s, id) {
        let js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    } else {
      this.determineIfFBConnected().then()
    }

    this.determineIfIGConnected().then()
    this.getCarfaxSettings()
    this.initGoogleSignIn()
    this.loadGoogleCredential().then()
  }

  initGoogleSignIn = () => {
    let client
    client = google.accounts.oauth2.initCodeClient({
      //todo handle this variable in variable configuration
      client_id: '700788306903-mv603533q6hefbdcv3tmuqhlseqbhl6p.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/business.manage',
      ux_mode: 'popup',
      callback: (response) => {
        this.handleSignInGoogle(response.code).then()
      },
    })
    this.setState({googleClient: client})
  }
  determineIfIGConnected = async () => {
    const types = `${LOGO.FACEBOOK},${LOGO.INSTAGRAM}`
    const response = await loadCredentialsByTypes(types)
    const {responseCode, responseMessage, data} = response
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      return
    }

    const credentials = data.credentials
    const facebookCredential = credentials.find(c => c.type === LOGO.FACEBOOK)
    const instagramCredential = credentials.find(c => c.type === LOGO.INSTAGRAM)

    const fbPageId = get(facebookCredential, ['pageId'], '')
    const fbUserId = get(facebookCredential, ['objectId'], '')
    const fbToken = get(facebookCredential, ['token'], '')
    const instagramUserId = get(instagramCredential, ['objectId'], '')

    const isConnectedTo = {
      ...this.state.isConnectedTo,
      [LOGO.INSTAGRAM]: !!(instagramUserId),
    }

    const currentSettings = {
      facebookPageId: fbPageId,
      facebookAccessToken: fbToken,
      facebookUserId: fbUserId,
      instagramUserId,
    }

    this.setState(
      {
        isConnectedTo,
        isLoading: false,
        showSettingsFor: '',
        currentSettings,
      }
    )

    if (instagramUserId) {
      const facebookParams = `${fbPageId}?fields=name&access_token=${fbToken}`

      FB.api(facebookParams, (response) => {
        const {name} = response;
        this.setState( { facebookPageName: name })
      })
    }
  }

  determineIfFBConnected = async () => {
    const response = await loadCredentialByType(LOGO.FACEBOOK)
    const {responseCode, responseMessage, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      const credential = data.credential

      const userId = get(credential, ['objectId'], '')
      const token = get(credential, ['token'], '')
      const pageId = get(credential, ['pageId'], '')

      const isConnectedTo = {
        ...this.state.isConnectedTo,
        [LOGO.FACEBOOK]: !!(userId && token && pageId),
      }

      const currentSettings = {
        facebookPageId: pageId,
        facebookAccessToken: token,
        facebookUserId: userId,
      }

      this.setState({isConnectedTo, isLoading: false, currentSettings})
    } else {
      console.error(responseMessage)
    }
  }

  loadCredential = async (isConnect = false, showSettingsFor = '') => {
    const errorOnConnection = DEFAULT_ERROR_ON_CONNECTION
    const {responseCode, responseMessage, data} = await loadCredentialByType(LOGO.FACEBOOK)
    if (responseCode === SUCCESSFUL_CODE) {
      const {credential} = data
      let userId = null;
      let token  = null;
      let pageId = null;
      if(credential?.objectId !== ""){
        userId = credential?.objectId
      }

      if(credential?.token !== ""){
        token = credential?.token
      }

      if(credential?.pageId !== ""){
        pageId = credential?.pageId
      }

      if(userId && token && pageId)
        this.getFacebookData(userId,token,pageId,false,false, showSettingsFor);
      else {
        if(isConnect){
          FB.logout((response) => {
            this.setState({isLoading:false, errorOnConnection})
          })
        }else{
          this.setState({isLoading:false, errorOnConnection})
        }
      }
    } else {
      console.error(responseMessage)
    }
  }

  loadGoogleCredential = async () => {
    this.setState({isLoadingGoogle: true})
    const {responseCode, responseMessage, data} = await loadCredentialByType(LOGO.GOOGLE)
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
      this.setState({isLoadingGoogle: false})
      return
    }

    const {credential} = data
    if (!credential) {
      this.setState({isLoadingGoogle: false})
      return
    }

    const { objectId, token, pageId } = credential
    if(objectId && token && pageId){
      const {responseCode: responseCodeAccount, responseMessage: responseMessageAccount, data: dataAccount} =  await getAccountData()
      if (responseCodeAccount !== SUCCESSFUL_CODE) {
        console.error(responseMessageAccount)
        this.setState({isLoadingGoogle: false})
        return
      }
      const {accountInfo} = dataAccount
      const {accountName, locationTitle, locationProfileUrl} = accountInfo
      const isConnectedTo = {
        ...this.state.isConnectedTo,
        [LOGO.GOOGLE]: true
      }
      this.setState({isConnectedTo: isConnectedTo, googleAccountName: accountName, googleLocationTitle: locationTitle, isLoadingGoogle: false, locationProfileUrl })
    }else{
      this.setState({isLoadingGoogle: false})
    }
  }

  // This is called with the results from FB.getLoginStatus().
  statusChangeCallback = (response, callBack) => {
    if (response.status === 'connected') {
      //this.getFacebookData(null,null,null,false,false);
      callBack(false);
    } else {
      callBack(false);
    }
  }

  checkLoginState = (callBack) => {
    let that = this;
    FB.getLoginStatus(function (response) {
      that.statusChangeCallback(response, function (data) {
        callBack(data)
      });
    });
  }

  facebookLogin = (connectToIG = false) => {
    let that = this;
    this.setState({connecting: true});
    this.checkLoginState(function (response) {
      if (!response) {
        FB.login(function (response) {
          if (response.status === "connected") {
            const userId = response.authResponse.userID;
            const token  = response.authResponse.accessToken;
            const showSettingsFor = connectToIG ? LOGO.INSTAGRAM : ''
            that.getAccessTokenExtended(token,userId, showSettingsFor, connectToIG)
            //--that.getFacebookData(userId,null,null,true,true);
          } else {
            that.setState({connecting: false});
          }
        }, {scope: `${scope},${instagramScope}`})
      }
    })
  }

  getAccessTokenExtended(token,userId, showSettingsFor = '', connectToIG = false){
    const that = this;
    FB.api(`oauth/access_token?grant_type=fb_exchange_token&client_id=${client_id}&client_secret=${client_secret}&fb_exchange_token=${token}`, function (response) {
      const {access_token}  = response;
      that.getFacebookData(userId,access_token,null,true,true, showSettingsFor, connectToIG);
    });
  }

  facebookLogout = () => {
    this.setState({isLoadingLogout:true});
    FB.getLoginStatus(async (response) => {
      if (response.status === "connected") {
        FB.logout(async (response) => {
          await this.resetCredential()
        })
      } else {
        await this.resetCredential()
      }
    })
  }

  resetCredential = async () => {
    let data = [{objectId: '', pageId: '', token: '', type: LOGO.FACEBOOK}];
    data = this.state.isConnectedTo[LOGO.INSTAGRAM]
      ? [
        ...data,
        {objectId: '', pageId: '', token: '', type: LOGO.INSTAGRAM}
      ]
      : data

    const {responseCode, responseMessage} = await saveCredentials({credentials: data})
    if (responseCode === SUCCESSFUL_CODE) {
      const isConnectedTo = {
        ...this.state.isConnectedTo,
        [LOGO.FACEBOOK]: false,
        [LOGO.INSTAGRAM]: false,
      }

      this.setState({isLoggedIn: false, isConnectedTo, isLoadingLogout:false})
    } else  {
      console.error(responseMessage)
      this.setState({isLoadingLogout:false})
    }
  }

  getUserName = (userId,token) => {
    const that = this;
    FB.api(`${userId}?fields=name&access_token=${token}`, function (response) {
      that.setState({userName:response.name})
    });
  }

  getFacebookData(userId,token,pageId,showModalPage,isNewSession, showSettingsFor = '', connectToIG = false) {
    const that = this;
    this.getUserName(userId,token);
    let isConnectedTo = {
      ...this.state.isConnectedTo,
    }

    FB.api(`/${userId}/accounts?access_token=${token}`, function (response) {
      const responseString = JSON.stringify(response);
      const responseJSON = JSON.parse(responseString);
      const {data} = responseJSON;
      const errorOnConnection = DEFAULT_ERROR_ON_CONNECTION
      if (data && data.length > 0) {
        if(isNewSession){
          that.setState({facebookPages: data,showModalPage:showModalPage,userId:userId, showSettingsFor: showSettingsFor});
        }else{
          const pageFound = data.find(d => { return d.id === pageId });

          if(pageFound){
            isConnectedTo[LOGO.FACEBOOK] = true

            FB.api(`${pageId}?fields=name,picture&access_token=${token}`, function (response) {
              const {name,picture} = response;
              that.setState({pageFacebookPicture:picture.data.url,facebookPageName:name,facebookPages: data,showModalPage:showModalPage,
                userId:userId, isConnectedTo, isLoading:false,isLoggedIn:true, showSettingsFor, errorOnConnection})
            });
          }else{
            isConnectedTo[LOGO.FACEBOOK] = false

            that.setState({facebookPages: data,showModalPage:showModalPage,userId:userId,
              facebookPageName:null, isConnectedTo, isLoading:false, showSettingsFor, errorOnConnection});
          }
        }

      }else{
        that.setState({isLoading:false, errorOnConnection});
      }
    });
  }

  closeModal = () => {
    const isConnectedTo = {
      ...this.state.isConnectedTo,
      [LOGO.FACEBOOK]: false,
      [LOGO.GOOGLE]: false,
    }

    this.setState({showConnectedModal: false,showModalPage:false,showLocationsModal: false, isConnectedTo,connecting:false,isLoggedIn: false,userId:null});
  }

  onChangeSwitch = (type) => {
    const value = this.state[type];
    this.setState({[type]: !value})
  }

  handleFacebookPageSelect = (page, isConnectToIG = false) => {
    const {userId} = this.state;
    let data = [{objectId: userId, pageId: page.id, token: page.access_token, type: LOGO.FACEBOOK}]

    const isConnectedTo = {
      ...this.state.isConnectedTo,
      [LOGO.FACEBOOK]: true,
      [LOGO.INSTAGRAM]: isConnectToIG,
    }

    const facebookParams = isConnectToIG
      ? `${page.id}?fields=name,picture,instagram_business_account`
      : `${page.id}?fields=name,picture`


    FB.api(facebookParams, async function (response) {
      const {name, picture, instagram_business_account} = response;
      const instagramUserId = get(instagram_business_account, ['id'], '')

      data = isConnectToIG
        ? [...data,
          {objectId: instagramUserId, pageId: page.id, token: page.access_token, type: LOGO.INSTAGRAM}
        ]
        : data

      const currentSettings = {
        facebookPageId: page.id,
        facebookAccessToken: page.access_token,
        facebookUserId: userId,
        instagramUserId,
      }

      const errorOnConnection = {
        ...this.state.errorOnConnection,
        [LOGO.INSTAGRAM]: isConnectToIG ? !instagramUserId : false
      }

      const snlHistory = {
        userId: isConnectToIG ? instagramUserId : userId,
        pageId: page.id,
        name: name,
        socialNetwork: isConnectToIG ? LOGO.INSTAGRAM : LOGO.FACEBOOK,
        status: responseStatus.SUCCESS
      }

      const {responseCode: responseCodeCredentials, responseMessage: responseMessageCredentials} = await saveCredentials({credentials: data})
      if (responseCodeCredentials !== SUCCESSFUL_CODE) {
        console.error(responseMessageCredentials)
      }
        // await generateCSVUrlForFBMP()
      const {responseCode: responseCodeSocialNetwork, responseMessage: responseMessageSocialNetwork} = await saveSocialNetworkLoginHistory(snlHistory)
      if (responseCodeSocialNetwork !== SUCCESSFUL_CODE) {
        console.error(responseMessageSocialNetwork)
      }
      const {responseCode: responseCodeSubscribe, responseMessage: responseMessageSubscribe} = await subscribePageToWebhook()
      if (responseCodeSubscribe !== SUCCESSFUL_CODE) {
        console.error(responseMessageSubscribe)
      }

      this.setState({
        pageFacebookPicture: picture.data.url,
        facebookPageName: name,
        isConnectedTo,
        connecting: false,
        showModalPage: false,
        isLoggedIn:true,
        currentSettings,
        showSettingsFor: '',
        errorOnConnection,
      })
    }.bind(this));
  }

  handleClickAfterFBConnected = async () => {
    await this.loadCredential(true, LOGO.FACEBOOK)
  }

  handleClickAfterGoogleConnected = () => {
    const errorOnConnection = DEFAULT_ERROR_ON_CONNECTION
    this.setState({ showSettingsFor: LOGO.GOOGLE, errorOnConnection })
  }

  handleClickAfterIGConnected = () => {
    this.setState({ showSettingsFor: LOGO.INSTAGRAM })
  }

  /*** No delete this function***/
  setDisplaySettingsFor = (socialNetwork = '') => {
    this.setState({ showSettingsFor: socialNetwork })
  }

  handleUpdateGoogleReviewUrl = (evt) => {
    evt.stopPropagation();
    const urlToSave = evt.target.value || '';
    this.setState({ urlToSave })
  }

  handleInstagramSignOut = async () => {
    const isConnectedTo = {
      ...this.state.isConnectedTo,
      [LOGO.INSTAGRAM]: false,
    }

    //const {currentSettings} = this.state;
    const data = [{objectId: '', pageId: '' , token: '', type: LOGO.INSTAGRAM}]
    //const data = [{objectId: '', pageId: currentSettings.facebookPageId , token: currentSettings.facebookAccessToken, type: LOGO.INSTAGRAM}]
    const errorOnConnection = {
      ...this.state.errorOnConnection,
      [LOGO.INSTAGRAM]: false,
    }

    const {responseCode, responseMessage} = await saveCredentials({credentials: data})
    if (responseCode === SUCCESSFUL_CODE) {
      this.setState({ isConnectedTo, showSettingsFor: '', errorOnConnection, showIGSignOutModal: true})
    } else  {
      console.error(responseMessage)
    }

  }

  handleClickOnIGItem = () => {
    const {isConnectedTo} = this.state

    if (!isConnectedTo[LOGO.FACEBOOK]) {
      this.facebookLogin(true)
    }
    else if (!isConnectedTo[LOGO.INSTAGRAM]) {
      this.justConnectingToIG()
    }
    else {
      this.handleClickAfterIGConnected()
    }
  }

  justConnectingToIG = () => {
    const { currentSettings } = this.state;
    //const facebookParams = `${currentSettings.facebookPageId}/instagram_accounts?access_token=${currentSettings.facebookAccessToken}`
    const facebookParams = `${currentSettings.facebookPageId}?fields=name,instagram_business_account&access_token=${currentSettings.facebookAccessToken}`
    FB.api(facebookParams, async (response) => {

      const { instagram_business_account } = response;

      if (instagram_business_account?.id){

        const {id:instagramUserId } = instagram_business_account
        /*const instagram_business_account = data[0]
              const instagramUserId = get(instagram_business_account, ['id'], '')*/

        if(!isEmpty(instagramUserId)){
          const data = [{objectId: instagramUserId, pageId: currentSettings.facebookPageId , token: currentSettings.facebookAccessToken, type: LOGO.INSTAGRAM}]
          const updatedCurrentSettings = {
            ...currentSettings,
            instagramUserId,
          }

          const isConnectedTo = {
            ...this.state.isConnectedTo,
            [LOGO.FACEBOOK]: true,
            [LOGO.INSTAGRAM]: !!instagramUserId,
          }

          const errorOnConnection = {
            ...this.state.errorOnConnection,
            [LOGO.INSTAGRAM]: !instagramUserId
          }

          const {responseCode, responseMessage } = await saveCredentials({credentials: data})
          if (responseCode === SUCCESSFUL_CODE) {
            this.setState({
              isConnectedTo,
              connecting: false,
              showModalPage: false,
              isLoggedIn:true,
              currentSettings: updatedCurrentSettings,
              showSettingsFor: '',
              //facebookPageName: name,
              errorOnConnection,
            })
          } else {
            console.error(responseMessage)
          }
        }else{
          console.error('Instagram UserId is empty')
        }
      }
      else{
        const errorOnConnection = {
          ...this.state.errorOnConnection,
          [LOGO.INSTAGRAM]: true
        }

        const isConnectedTo = {
          ...this.state.isConnectedTo,
          [LOGO.INSTAGRAM]: true,
        }

        this.setState({
          isConnectedTo,
          errorOnConnection,
          showSettingsFor: [LOGO.INSTAGRAM]
        })
      }
    });
  }

  handleClickOnCFItem = () => {
    const errorOnConnection = DEFAULT_ERROR_ON_CONNECTION

    this.setState({
      showSettingsFor: LOGO.CARFAX,
      errorOnConnection
    })
  }

  handleUpdateCarfaxHTML = (evt) => {
    evt.stopPropagation();
    const carfaxHTMLToSave = evt.target.value || '';
    this.setState({ carfaxHTMLToSave })
  }

  handleSaveCarfaxSettings = () => {
    const data = {
      code: CARFAX_LINK,
      value: this.state.carfaxHTMLToSave,
    }

    saveDealerConfiguration(data)
      .then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE) {
          this.getCarfaxSettings()
        } else {
          console.error(responseMessage)
        }
      })
  }

  getCarfaxSettings = () => {
    const codes = `${CARFAX_LINK}`
    loadDealerConfigurationByCodes(codes)
      .then(response => {
        const {responseCode, responseMessage, data} = response
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
          return
        }
        const {dealerConfigurations} = data;
        const linkUrlSetting = dealerConfigurations.find(dc => dc.code === CARFAX_LINK)
        const linkUrl = linkUrlSetting && linkUrlSetting.value ? linkUrlSetting.value : ''
        const isConnectedTo = {
          ...this.state.isConnectedTo,
          [LOGO.CARFAX]: !!linkUrl
        }

        this.setState( { carfaxHTMLToSave: linkUrl, isConnectedTo, showSettingsFor: '' })
      })
  }

  connectToGoogle = () => {
    let {googleClient} = this.state
    if(!!googleClient){
      googleClient.requestCode();
    }
  }

  handleSignInGoogle = async (authCode) => {
    this.setState({isLoadingGoogle: true})
    const responseToken = await sendAuthorizationCode({code: authCode})
    const {responseCode, responseMessage, data} = responseToken
    if(responseCode === SUCCESSFUL_CODE) {
      const {accountInfo} = data
      const {accountName, locations} = accountInfo

      this.setState({googleLocations: locations, googleAccountName: accountName, isLoadingGoogle: false}, () => {
        this.setState({showLocationsModal: true})
      })
    } else {
      console.error(responseMessage)
      this.setState({isLoadingGoogle: false})
    }
  }

  handleGoogleLocationSelect = async (location) => {
    const locationValues = (location?.name ?? "").split("/")
    const locationId = locationValues[1]
    const isConnectedTo = {
      ...this.state.isConnectedTo,
      [LOGO.GOOGLE]: true
    }
    this.setState({isLoadingLogout: true})
    const {responseCode, responseMessage, data} = await updateCredentialLocationId(locationId)
    if (responseCode === SUCCESSFUL_CODE) {
      const { locationProfileUrl } = data
      this.setState({
        isConnectedTo,
        googleLocationTitle: location.title,
        showLocationsModal: false,
        isLoadingLogout: false,
        locationProfileUrl })

    } else {
      console.error(responseMessage)
      this.setState({isLoadingLogout: false})
    }
  }

  googleLogout = async () => {
    //google.accounts.oauth2.revoke('ya29.a0Aa4xrXOfnr0VIrf6DS8RntLJuDwVUz5SJakqgMUkVK_X8muWa7ReZkZymyAWEVTepKXsQJSG3L9ePo32wsffZzHz2-ohfqXCskwHj_VUzxWu8DWkr4-Q6ezeP27wjqke87Ovp5TanCnxrrEdRNSnH6IBH9gMaCgYKATASARMSFQEjDvL9zpmqltspreUJ7QEqIFHnEw0163')
    await this.resetGoogleCredential()
  }

  resetGoogleCredential =  async () => {
    let data = [{objectId: '', pageId: '', token: '', type: LOGO.GOOGLE, refreshToken: ''}];
    this.setState({isLoadingGoogle: true})
    const {responseCode, responseMessage} = await saveCredentials({credentials: data})
    if (responseCode === SUCCESSFUL_CODE) {
      const isConnectedTo = {
        ...this.state.isConnectedTo,
        [LOGO.GOOGLE]: false,
      }
      this.setState({
        isConnectedTo,
        isLoadingGoogle:false,
        googleLocations : [],
        googleAccountName : "",
        googleLocationTitle : '',
        googleAccountId : null,
        locationProfileUrl: ''})
    } else {
      console.error(responseMessage)
      this.setState({isLoadingGoogle: false})
    }
  }

  render() {
    const {height} = this.props;
    const {showConnectedModal, isConnectedTo, testimonials, ads, campaigns, connecting, isLoggedIn,
      isLoading,showModalPage,facebookPages,facebookPageName,pageFacebookPicture,isLoadingLogout,userName,
      currentSettings, errorOnConnection, showIGSignOutModal, showSettingsFor, urlToSave, carfaxHTMLToSave,
      showLocationsModal, googleLocations, googleAccountName, googleLocationTitle, isLoadingGoogle, locationProfileUrl} = this.state;

    return (
      <div className="setting-container" style={{minHeight: height}}>
        {isLoadingLogout ? <Loader className="loader-marketing"/> : null}
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> :
            <div className="setting-marketing">
              <div className="social-media-container-items">
                <SocialMediaItem
                  handleClickItem={isConnectedTo[LOGO.FACEBOOK] ? this.handleClickAfterFBConnected : () => this.facebookLogin(false)}
                  imageSource={`${pathServer.PATH_IMG}icon/ic_facebook_color.svg`}
                  itemText={'Connect Facebook'}
                  isConnectedToSocialMedia={isConnectedTo[LOGO.FACEBOOK]}
                  testId={`social-network-${LOGO.FACEBOOK}`}
                />
                <SocialMediaItem
                  handleClickItem={this.handleClickOnIGItem}
                  imageSource={pathServer.PATH_IMG + "icon/ic_instagram_color.svg"}
                  itemText={'Connect Instagram'}
                  isConnectedToSocialMedia={isConnectedTo[LOGO.INSTAGRAM]}
                  testId={`social-network-${LOGO.INSTAGRAM}`}
                />
                <SocialMediaItem
                  handleClickItem={isConnectedTo[LOGO.GOOGLE] ? this.handleClickAfterGoogleConnected : this.connectToGoogle}
                  imageSource={pathServer.PATH_IMG + "icon/ic_google_color.svg"}
                  itemText={'Connect Google'}
                  isConnectedToSocialMedia={isConnectedTo[LOGO.GOOGLE]}
                  testId={`social-network-${LOGO.GOOGLE}`}
                  isLoading={isLoadingGoogle}
                />
                <SocialMediaItem
                  handleClickItem={this.handleClickOnCFItem}
                  imageSource={pathServer.PATH_IMG + "icon/carfax_logo_icon.svg"}
                  itemText={'Connect Carfax'}
                  isConnectedToSocialMedia={isConnectedTo[LOGO.CARFAX]}
                  testId={`social-network-${LOGO.CARFAX}`}
                  iconForm={RECTANGLE_ICON}
                />
                {/* This section is temporarily hidden */}
                {/*<SocialMediaItem*/}
                {/*  handleClickItem={this.setDisplaySettingsFor}*/}
                {/*  imageSource={pathServer.PATH_IMG + "icon/ic_ksl_color.svg"}*/}
                {/*  itemText={'Connect KSL.com'}*/}
                {/*  isConnectedToSocialMedia={isConnectedTo[LOGO.KSL]}*/}
                {/*/>*/}
                {/*<SocialMediaItem*/}
                {/*  handleClickItem={this.setDisplaySettingsFor}*/}
                {/*  imageSource={pathServer.PATH_IMG + "icon/ic_zoom_autos_color.svg"}*/}
                {/*  itemText={'Connect ZoomAutos'}*/}
                {/*  isConnectedToSocialMedia={isConnectedTo[LOGO.ZOOM_AUTOS]}*/}
                {/*/>*/}
              </div>
              {
                isConnectedTo[LOGO.FACEBOOK] && showSettingsFor === LOGO.FACEBOOK
                  ? <FacebookLoginContainer
                    facebookPagePicture={pageFacebookPicture}
                    facebookPageName={facebookPageName}
                    handleFacebookSignOut={this.facebookLogout}
                    handleChangeSwitch={this.onChangeSwitch}
                    testimonials={testimonials}
                    ads={ads}
                    campaigns={campaigns}
                    userName={userName}
                  />
                  : null
              }
              {
                isConnectedTo[LOGO.GOOGLE] && showSettingsFor === LOGO.GOOGLE
                  ? <GoogleLoginContainer
                    googleLogoSource={pathServer.PATH_IMG + "icon/google_logo_color.svg"}
                    handleGoogleSignOut={this.googleLogout}
                    urlValue={urlToSave}
                    handleChangeGoogleUrl={this.handleUpdateGoogleReviewUrl}
                    userName={googleAccountName}
                    locationName={googleLocationTitle}
                    googleAccountPicture={!!locationProfileUrl ? locationProfileUrl : `${pathServer.PATH_IMG}/user.png`}
                  />
                  : null
              }
              {
                (isConnectedTo[LOGO.INSTAGRAM] && showSettingsFor === LOGO.INSTAGRAM) || errorOnConnection[LOGO.INSTAGRAM]
                  ? <InstagramLoginContainer
                    instagramLogoSource={pathServer.PATH_IMG + "icon/instagram_logo_color.svg"}
                    handleInstagramSignOut={this.handleInstagramSignOut}
                    facebookPageName={facebookPageName}
                    instagramUserId={currentSettings && currentSettings.instagramUserId}
                  />
                  : null
              }
              {
                showSettingsFor === LOGO.CARFAX
                  ? <CarFaxContainer
                    carfaxLogoSource={pathServer.PATH_IMG + "icon/carfax_logo.svg"}
                    handleCarFaxSignOut={this.handleSaveCarfaxSettings}
                    urlValue={carfaxHTMLToSave}
                    handleChangeCarFaxUrl={this.handleUpdateCarfaxHTML}
                  />
                  : null
              }
              <div className="marketing-graphics">
                <div className="graphics-container">
                  <div className="graphic-text">
                    <img src={pathServer.PATH_IMG + "social-medias.png"}/>
                    <label>Link your social media accounts</label>
                  </div>
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_next.svg"} className="arrow-marketing"/>
                  <div className="graphic-text">
                    <img src={pathServer.PATH_IMG + "admin-marketing-graphic.png"}/>
                    <label>Admin Comments, Ads and Campains</label>
                  </div>
                </div>
              </div>

            </div>

        }
        <ModalConnect show={showConnectedModal} closeModal={this.closeModal}/>
        {
          showModalPage &&
          <ModalSelectItem
            show={showModalPage}
            handleSelectItem={(item) => this.handleFacebookPageSelect(item, showSettingsFor === LOGO.INSTAGRAM)}
            closeModal={this.closeModal}
            items={facebookPages}
          />
        }
        {
          showLocationsModal &&
          <ModalLocations
            show={showLocationsModal}
            items={googleLocations}
            closeModal={this.closeModal}
            handleSelectItem={ this.handleGoogleLocationSelect}
          />
        }

        <ModalConfirmDeactivate
          show={showIGSignOutModal}
          onHide={() => this.setState({ showIGSignOutModal: false })}
          showButton={false}
          isBodyCustom={true}
          bodyMessage={'You have been logged out from Instagram'}
          iconQuestion={"icon/ic_bye.svg"}
          classPanelMessage={'panel-message-custom'}
          viewMobile={false}
        />
      </div>
    )
  }
}