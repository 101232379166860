import React, {Component} from 'react'
import {
  ENTITY_TYPES,
  RENDER_TYPES,
  FORM_TYPES,
  FORM_TYPE_SYSTEM,
  FORM_TYPE_USER,
  FORM_APPLICANT_CODE_ADMIN
} from '../../../Constants'
import {Tab, Tabs} from 'react-bootstrap'
import BuildForm from './BuildForm.jsx'
import {saveFormApi} from '../../../Api.js'
import {loadFormByIdApi, resetFormAttributes} from '../../../Api'
import {browserHistory} from 'react-router'
import DPButton from '../../../common/DPButton'
import {ReactSVG} from 'react-svg'
import {pathServer, FORM_APPLICANT_CODE_PUBLIC, SUCCESSFUL_CODE} from '../../../../common/Constants'

const Popover = (props) => {
  const {values,dropDown,type,onSelectDropDown,selectedItem,styleItemSelected} = props;
  return(
    <div className="popover-inventory-dp-new" style={{top: "30px"}}>
      <div className="popover-inventory-dp-sub">
        <u>
          {
            values.map((op, index) => {
              return (
                <li key={index} className="change-submenu-item"
                    onClick={() => onSelectDropDown(dropDown, type, op.value)}>
                  {op.value}
                  {
                    (selectedItem === op.value) ?
                      <span style={styleItemSelected}/>
                      : null
                  }
                </li>
              )
            })
          }
        </u>
      </div>
    </div>
  )
};

const Menu = ({tabSelected,name,icon,iconSelected,displayValue}) => {
  return(
    <div className="form-tab-new">
      {
        tabSelected === name ?
          <img src={`${pathServer.PATH_IMG}${iconSelected}`}/> :
          <img src={`${pathServer.PATH_IMG}${icon}`}/>
      }
      <span>{displayValue}</span>
    </div>
  )
};

export default class FormSetting extends Component {

  constructor() {
    super();
    this.state = {
      tabSelected: "properties",
      formDropDown: ["opt1", "opt2"],
      value: '',
      listRender: "",
      listEntity: "",
      listForm:"",
      showNotify: false,
      errorMessage: false,
      errorsBack: [],
      errors: {},
      showMessage: false,
      attributes: [],
      sections: [],
      editForm: false,
      form: {
        id: null,
        code: "",
        name: "",
        entityType: "",
        renderType: "",
        formType: "",
        createdBy:"",
        formAttributes: {
          attributes: [],
          sections: []
        },
        status: "",
        newAttributesIds: []
      },
      dropDownAudience: {
        render: false,
        values: ENTITY_TYPES
      },
      dropDownAudience2: {
        render: false,
        values: RENDER_TYPES
      },
      dropDownAudience3: {
        render: false,
        values: FORM_TYPES
      },
      attributeIdsNew: [],
      attributesSelectedSelectType: [],
      activeArrow: false
    }
  }

  componentDidMount() {
    const {id} = this.props.params;
    if (id && id !== "new") {
      this.setState({isLoading: true})
      loadFormByIdApi(id).then((response) => {
        const {responseCode} = response
        if(responseCode === SUCCESSFUL_CODE){
          const formDB = response.data.form;
          formDB.formAttributes = JSON.parse(formDB.formAttributes);
          this.setState({form: formDB, editForm: true, activeArrow: true, isLoading:false});
        }
      })
    } else {
      this.setState({activeArrow: false});
    }
  }

  handleSelect = (tabSelected) => {
    let {editForm} = this.state;
    if (editForm) {
      const {form} = this.state;
      if (form) {
        this.save(false);
        this.setState({tabSelected: tabSelected})
      }
    } else {
      this.save(false);
      this.setState({tabSelected: tabSelected});
    }
  }

  onChangeDropDownForm = (dropDown) => {
    let dropDownForm = this.state[dropDown];
    dropDownForm.render = !dropDownForm.render;
    this.setState({[dropDown]: dropDownForm})
  }

  onSelectDropDown = (dropDown, type, value) => {
    let {form} = this.state;
    form[type] = value;
    if(form.name === "" || form.code === "" || form.entityType === "" || form.renderType === "" || form.formType === "") {
      this.setState({activeArrow: false})
    }
    else {
      this.setState({activeArrow: true})
    }
    let that = this;
    this.onSelect(type, value);
    if(value === FORM_TYPE_SYSTEM){
      form.createdBy= FORM_TYPE_SYSTEM;
    }else if(value === FORM_TYPE_USER){
      form.createdBy= FORM_TYPE_USER;
    }
    this.setState({form: form}, () => {
      that.onChangeDropDownForm(dropDown)
    })
  }

  onSelect(type, value) {
    if (type === "renderType") {
      this.setState({listRender: value});
    } else if (type === "entityType") {
      this.setState({listEntity: value});
    } else if (type === "formType") {
      this.setState({listForm: value});
    }
  }

  handleChange = (event) => {
    let {form} = this.state;
    let formEdit = form;
    formEdit[event.target.name] = event.target.value;
    this.setState({form: formEdit});
    if(form.name==="" || form.code==="" || form.entityType==="" || form.renderType==="" || form.formType==="") {
      this.setState({activeArrow: false})
    } else {
      this.setState({activeArrow: true})
    }
  }

  save = (back) => {
    let {form, editForm, attributeIdsNew} = this.state;
    form.newAttributesIds = attributeIdsNew;
    let that = this;
    saveFormApi(form, (editForm) ? "update" : null).then((response) => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {formId} = data
        form.id = formId;
        this.setState({activeArrow: true, showNotify: true, showMessage: true});
        setTimeout(function () {
          if (back) {
            that.backListForm();
          }
        }.bind(this), 500);
      }else{
        this.setState({
          activeArrow: false,
          errorsBack: [responseMessage],
          errorMessage: true
        })
        setTimeout(function () {
          this.setState({showMessage: false})
        }.bind(this), 2000);
      }
    })
  }

  backListForm(){
    browserHistory.push({pathname: `/main/setting/form`});
  }

  updateFormFromBuilder = (formBuilder) => {
    let {form} = this.state;
    form.formAttributes.sections = formBuilder.sections;
    form.formAttributes.attributes = formBuilder.attributes;
    this.setState({form: form});
  }

  resetForm = async (event) => {
    try {
      event.stopPropagation();
      const {form} = this.state;
      const response = await resetFormAttributes(form.id);
      browserHistory.push({pathname: `/main/setting/form`});
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    let {tabSelected, form, dropDownAudience, dropDownAudience2, dropDownAudience3, activeArrow, editForm, showNotify, listRender, listEntity, listForm} = this.state;
    const {onChange} = this.props;
    listRender = form.renderType !== "" ? form.renderType : "";
    listEntity = form.entityType !== "" ? form.entityType : "";
    listForm = form.formType !== "" ? form.formType : "";

    return (
      <div className="setting-container" style={{display: "block"}}>
        <div className="form-header-left">
          <h4>{editForm ? 'Edit Form' : 'New Form'}</h4>
        </div>
        <div className="form-header-right">
          <div className="form-button-header">
            <img src={pathServer.PATH_IMG + "ic_close.png"}
                 className="icon-close"
                 onClick={() => this.backListForm()}/>
          </div>
          <div className="form-button-header">
            <img name="imgSave" src={pathServer.PATH_IMG + "ic_save.png"}
                 className="icon-save"
                 onClick={this.save.bind(this, true)}/>
          </div>
          <div className="form-button-header">
            <img src={pathServer.PATH_IMG + "ic_disable.png"}
                 className="icon-disable"
                 onClick={() => onChange("forms")}/>
          </div>
        </div>
        <Tabs id="tab-setting-form" activeKey={this.state.tabSelected} onSelect={this.handleSelect}
              animation={false}>
          <Tab eventKey="properties"
               title={<Menu name={'properties'} tabSelected={tabSelected} icon={'ic_extension_24px.png'} iconSelected={'ic_extension.png'} displayValue="Properties"/>}
               tabClassName="tab-general tab-form"
               disabled={(form.entityType === "")}>
            <div className="form-property" style={{paddingTop: "0"}}>
              <div className="row-form">
                {
                  showNotify ?
                    <input className="showNotify" value="Form successfully saved" disabled/> :
                    <input className="hiddenNotify" value=""
                           style={{opacity: "1", height: "30px", color: "#35c8c3", border: "none", float: "right"}}
                           disabled/>
                }
              </div>
              <div className="row-form">
                <div className="form-input-content">
                  <span>Form name</span>
                  <div className="form-input">
                    <input className="form-control" name="name"
                           placeholder="Form name" value={form.name}
                           onChange={this.handleChange} disabled={form.createdBy === FORM_TYPE_SYSTEM}
                    style={form.createdBy === FORM_TYPE_SYSTEM ? {background:"#80808066"}: { background:"#FFF"}}/>
                  </div>
                </div>
                <div className="form-input-content">
                  <span>Form code</span>
                  <div className="form-input">
                    <input className="form-control" name="code"
                           placeholder="Form code" value={form.code}
                           onChange={this.handleChange} disabled={form.createdBy === FORM_TYPE_SYSTEM}
                           style={form.createdBy === FORM_TYPE_SYSTEM ? {background:"#80808066"}: { background:"#FFF"}}/>
                  </div>
                </div>
                <div className="form-input-content">
                  <span>Form Render Type</span>
                  <div className="attribute-field">
                    <div className="btn-form-dp" style={form.createdBy === FORM_TYPE_SYSTEM ? { pointerEvents: "none",cursor: "not-allowed"}:{ pointerEvents: "auto",position: "relative", cursor:"pointer"}}>
                      <a className={form.createdBy === FORM_TYPE_SYSTEM ? "btn-basic btn-inventory-dp-new disabled-select" : "btn-basic btn-inventory-dp-new"}
                         style={(dropDownAudience2.render) ? {borderRadius: '5px 5px 0 0', borderBottom: 0} : {}}
                         onClick={() => this.onChangeDropDownForm("dropDownAudience2")}>
                        {
                          form.renderType !== "" ? <span>{form.renderType}</span> : <span>Select</span>
                        }
                        {
                          (dropDownAudience2.render) ? <i className="fa fa-chevron-up"/> :
                            <i className="fa fa-chevron-down"/>
                        }
                      </a>
                      {
                        dropDownAudience2.render ?
                          <Popover values={dropDownAudience2.values}
                                   dropDown={'dropDownAudience2'}
                                   type={'renderType'}
                                   onSelectDropDown={this.onSelectDropDown}
                                   selectedItem={listRender}
                                   styleItemSelected={null}/>
                          : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-form">
                <div className="form-input-content">
                  <span>Form EntityType</span>
                  <div className="attribute-field">
                    <div className="btn-form-dp" style={form.createdBy === FORM_TYPE_SYSTEM ? { pointerEvents: "none",cursor: "not-allowed"}:{ pointerEvents: "auto",position: "relative", cursor:"pointer"}}>
                      <a className={form.createdBy === FORM_TYPE_SYSTEM ? "btn-basic btn-inventory-dp-new disabled-select" : "btn-basic btn-inventory-dp-new"}
                         style={(dropDownAudience.render) ? {borderRadius: '5px 5px 0 0', borderBottom: 0} : {}}
                         onClick={() => this.onChangeDropDownForm("dropDownAudience")}>
                        {
                          form.entityType !== "" ? <span>{form.entityType}</span> : <span>Select</span>
                        }
                        {
                          (dropDownAudience.render) ? <i className="fa fa-chevron-up"/> :
                            <i className="fa fa-chevron-down"/>
                        }
                      </a>
                      {
                        dropDownAudience.render ?
                          <Popover values={dropDownAudience.values}
                                   dropDown={'dropDownAudience'}
                                   type={'entityType'}
                                   onSelectDropDown={this.onSelectDropDown}
                                   selectedItem={listEntity}
                                   styleItemSelected={{
                                     height: "7px",
                                     width: "7px",
                                     backgroundColor: "#2193f1",
                                     borderRadius: "50%",
                                     float: "right",
                                     marginTop: "7px"
                                   }}/>: null
                      }
                    </div>
                  </div>
                </div>
                <div className="form-input-content">
                  <span>Form Type</span>
                  <div className="attribute-field">
                    <div className="btn-form-dp" style={{position: "relative"}}>
                      <a className="btn-basic btn-inventory-dp-new cursor-default"
                         style={editForm? {backgroundColor:'#80808066'} : (dropDownAudience3.render) ? {borderRadius: '5px 5px 0 0', borderBottom: 0} : {}}
                         onClick={editForm ? () => {} : () => this.onChangeDropDownForm("dropDownAudience3")}>
                        { editForm ?
                          form.createdBy === FORM_TYPE_SYSTEM ?  <span>SYSTEM</span> : <span>USER</span>
                          :
                          form.formType !== "" ? <span>{form.formType}</span> : <span>Select</span>
                        }
                        {
                          (dropDownAudience3.render) ? <i className="fa fa-chevron-up"/> :
                            <i className="fa fa-chevron-down"/>
                        }
                      </a>
                      {
                        dropDownAudience3.render ?
                          <Popover values={dropDownAudience3.values}
                                   dropDown={'dropDownAudience3'}
                                   type={'formType'}
                                   onSelectDropDown={this.onSelectDropDown}
                                   selectedItem={listForm}
                                   styleItemSelected={{
                                     height: "7px",
                                     width: "7px",
                                     backgroundColor: "#2193f1",
                                     borderRadius: "50%",
                                     float: "right",
                                     marginTop: "7px"
                                   }}/> : null
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="row-form-img">
                <div className="form-img-content">
                  <img src={pathServer.PATH_IMG + "group_extension.png"}/>
                  <div className="line-dashed"/>
                </div>
                <div className="form-img-content">
                  <img src={pathServer.PATH_IMG + "group_dvr.png"}/>
                  <div className="line-dashed"/>
                </div>
                <img src={pathServer.PATH_IMG + "group_tune.png"}/>
              </div>
              {
                (activeArrow) ?
                  (
                    <div className="form-setting-button" style={{float: "right"}}>
                      <button className="format-setting-button-add"
                              style={{backgroundColor: "#2194F1"}}
                              onClick={() => this.handleSelect("build")}>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_next.svg"} className="icon-next" beforeInjection={ svg => svg.classList.add('icon-next-edit-form')}/>
                      </button>
                    </div>
                  )
                  :
                  (
                    <div className="form-setting-button" style={{float: "right"}}>
                      <button className="format-setting-button-add"
                              style={{backgroundColor: "#EFEFEF"}}>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_next.svg"} className="icon-next" beforeInjection={ svg => svg.classList.add('icon-next-edit-form')}/>
                      </button>
                    </div>
                  )
              }
            </div>
          </Tab>
          <Tab eventKey="build"
               title={<Menu name={'build'} tabSelected={tabSelected} icon={'ic_dvr_24px.png'} iconSelected={'ic_dvr.png'} displayValue="Build Form"/>}
               disabled={(form.entityType === "" && showNotify)}
               tabClassName="tab-general tab-form">
            {
              (tabSelected === "build") ? <BuildForm form={form} updateForm={this.updateFormFromBuilder}/> : null
            }
          </Tab>
          <Tab eventKey="actions"
               title={<Menu name={'actions'} tabSelected={tabSelected} icon={'ic_tune_24px.png'} iconSelected={'ic_tune.png'} displayValue="Actions"/>}
               disabled={(form.entityType === "")}
               tabClassName="tab-general tab-form">
            <div style={{padding: 20}}>
              {
                (form.code === FORM_APPLICANT_CODE_PUBLIC || form.code === FORM_APPLICANT_CODE_ADMIN) ?
                  <DPButton onClick={this.resetForm} width={'180px'} className={'btn-primary'}>
                    Reset Form Attributes
                  </DPButton> : null
              }
              <h1>Hello</h1>
            </div>
          </Tab>
        </Tabs>
      </div>
    )
  }
}