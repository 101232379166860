import React, {Component} from 'react'
import {
  CAR_STATUS_CODE_FRONT_LINE_READY,
  FIELD_FILTERS_STORAGE,
} from '../../Constants'
import {getFilterCar, getFormatNumber, getValueOrDash, groupByStatusCar} from '../util/Utils'
import {ReactSVG} from 'react-svg'
import {DPPopover} from '../../../common/DPPopover'
import Checkbox from 'material-ui/Checkbox'
import DPDragAndDrop from '../../common/DPDragAndDrop'
import copy from 'copy-to-clipboard'
import isEmpty from 'lodash/isEmpty'
import {elementCheck, elementUnCheck} from '../../../common/ComponentUtils'
import {pathServer, CAR_IMAGE_COMING_SOON, WIDTH_VIEW_MOBILE} from '../../../common/Constants'

const anchorEl = {
  x: "85%",
  y: 50,
  offsetWidth: 190
};

const renderData = [
  {id:1, value: 'image', html :(car,make,model)=> car.mainImage },
  {id:2, value: 'year', html :(car,make,model)=> car.year },
  {id:3, value: 'make', html :(car,make,model)=> make },
  {id:4, value: 'model', html :(car,make,model)=> model},
  {id:5, value: 'trim', html :(car,make,model)=> getValueOrDash(car["car.trim"] ? car["car.trim"].attributeValue : null)},
  {id:6, value: 'color', html :(car,make,model)=> getValueOrDash(car["car.exteriorColor"] ? car["car.exteriorColor"].attributeValue : null)},
  {id:7, value: 'miles', html :(car,make,model)=>  getFormatNumber(car.mileage)},
  {id:8, value: 'vinNumber', html :(car,make,model)=> getValueOrDash(car.vin)},
  {id:9, value: 'suggPrice', html :(car,make,model)=> getValueOrDash(car.suggestedPrice, true)},
  {id:10, value: 'rPrice', html :(car,make,model)=> getValueOrDash(car.retailPrice, true)},
];

const columnsInitial = [
  {id: 1 ,width:"6%",  value: 'image', displayHeader: 'Image',  displayFilter: 'Image', show: true},
  {id: 2 ,width:"5%", value: 'year', displayHeader: 'Year', displayFilter: 'Year', show: true},
  {id: 3 ,width:"9%", value: 'make', displayHeader: 'Make', displayFilter: 'Make', show: true},
  {id: 4 ,width:"9%", value: 'model', displayHeader: 'Model', displayFilter: 'Model', show: true},
  {id: 5 ,width:"9%", value: 'trim', displayHeader: 'Trim', displayFilter: 'Trim', show: true},
  {id: 6 ,width:"7%", value: 'color', displayHeader: 'Color', displayFilter: 'Color', show: true},
  {id: 7 ,width:"9%", value: 'miles', displayHeader: 'Miles', displayFilter: 'Miles', show: true},
  {id: 8 ,width:"15%", value: 'vinNumber', displayHeader: 'VIN number', displayFilter: 'VIN number', show: true},
  {id: 9 ,width:"11%", value: 'suggPrice', displayHeader: 'Sugg. price', displayFilter: 'Suggested price',show: true},
  {id: 10 ,width:"11%", value: 'rPrice', displayHeader: 'R. price',  displayFilter: 'Retail price',show: true},
];

const getMake = (makes, makeId) => {
  let makeName = ``
  if(makes && makeId){
    makes.forEach((make) => {
      if (make.id === makeId)
        makeName =  make.name;
    });
  }
  return makeName
}

const getModel = (models, makeId, modelId ) => {
  if(models && makeId && modelId){
    return models.find((model) => model.id === modelId && model.makeId === makeId)?.name
  }
  return ''
}

export default class InventoryTable extends Component {

  constructor() {
    super();
    this.state = {
      selectedAll: false,
      idsSelected: {},
      countCarSelected: null,
      showSettingsTable: false,
      columns : columnsInitial,
    }
  }

  componentDidMount() {
    const {columns} = this.state;
    const filtersTableStorage = localStorage.getItem(FIELD_FILTERS_STORAGE);
    if(filtersTableStorage){
      this.setState({columns: JSON.parse(filtersTableStorage) || columns})
    }
  }

  onChangeCheckAll = () => {
    this.setState({selectedAll: !this.state.selectedAll})
  }

  onHandleReorder = (orderList) => {
    this.setState({columns: orderList}, () =>{
      localStorage.setItem(FIELD_FILTERS_STORAGE, JSON.stringify(orderList));
    });
  }

  onSelectFilterTable = (value) => {
    const {columns} = this.state;
    let newColumns = columns.map(c => {
      if (c.value === value){
        c.show = !c.show;
      }
      return c;
    });
    this.setState({columns : newColumns}, () => {
      localStorage.setItem(FIELD_FILTERS_STORAGE, JSON.stringify(newColumns));
    });
  }

  onChangeCheck = (id) => {
    let {idsSelected} = this.state;
    if (!idsSelected[id]) {
      idsSelected[id] = true;
    } else {
      delete idsSelected[id];
    }

    let countCarSelected = 0;
    for (let key in idsSelected) {
      countCarSelected++;
    }

    this.setState({idsSelected: idsSelected, countCarSelected: countCarSelected});
  }

  edit = (data,evt) => {
    evt.stopPropagation()
    if (data.active){
      this.props.edit(data.vin);
    }
  }

  delete = (carId,evt) =>{
    evt.stopPropagation()
    if(carId)
      this.props.delete(carId);
  }

  copy = (index, id,car, evt) => {
    evt.stopPropagation()
    const {columns} = this.state
    const {makes,models} = this.props
    let carInformation = ``

    columns.forEach( column => {
      const show = column.show
      if(show){
        switch (column.value) {
          case "image":
            break
          case "year":
            carInformation += `${car.year} `
            break
          case "make":
            carInformation += `${getMake(makes, car.makeId)} `
            break
          case "model":
            carInformation += `${getModel(models, car.makeId, car.modelId) || ''} `
            break
          case "color":
            carInformation += car["car.exteriorColor"] ? `${car["car.exteriorColor"].attributeValue} ` : ``
            break
          case "miles":
            carInformation += `${getFormatNumber(car.mileage)} `
            break
          case "vinNumber":
            carInformation += `${car.vin} `
            break
          case "suggPrice":
            carInformation += `$${ car.suggestedPrice + `.00`} `
            break
          case "rPrice":
            carInformation += `$${ car.retailPrice + `.00`} `
            break
          case "trim":
            carInformation += car["car.trim"] ? `${car["car.trim"].attributeValue} ` : ``
            break
        }
      }
    })
    copy(carInformation)
    this.props.showCopiedRowAlert()
  }

  render() {
    let {cars, makes, models,filters, totalVehiclesByStatus} = this.props;
    const {showSettingsTable, columns} = this.state;
    let makeName = "";
    let modelName = "";
    let carsGroupByStatus = cars.length !== 0 ? groupByStatusCar(cars, car => car["car.status"] ? car["car.status"].attributeValue : null ) : [cars];

    const filtersTableStorage = localStorage.getItem(FIELD_FILTERS_STORAGE);
    return (
      <div className="table-data table-cars">
        <table className="table">
          <thead>
          <tr>
            <th/>
            {columns.map(c => {
              if (c.show)
                return (<th id={`${c.value}-${c.id}`} width={c.width}>{c.displayHeader}</th>)
            })}
            <th className="th-container-setting" width="3%">
              <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_setting.svg'}
                        onClick={() => this.setState({showSettingsTable: true})}
                        className="icon-settings"
                        beforeInjection={svg => svg.classList.add('icon-settings')}
              />
            </th>
          </tr>
          </thead>
          <tbody>
          {
            cars.length !== 0 &&
            carsGroupByStatus.map((carsFilter, index) => {
              const quantityCarsByStatus = carsGroupByStatus[index].length
              const nameFilter = carsFilter[0]["car.status"] ? carsFilter[0]["car.status"].attributeValue : CAR_STATUS_CODE_FRONT_LINE_READY;
              const filterObj = getFilterCar(nameFilter);
              return (
                <>
                  <tr id={`${nameFilter}-${index}`} className="filters-table">
                    <td colSpan={12} style={{backgroundColor: filterObj.color}} >
                      <div className="container-number-value">
                        <span>{`${filterObj.value}   (${quantityCarsByStatus}/${totalVehiclesByStatus[filterObj.value] || "0"})`}</span>
                      </div>
                    </td>
                  </tr>
                      {
                        carsFilter.map((car, index) => {
                          makeName = getMake(makes, car.makeId)
                          modelName = getModel(models, car.makeId, car.modelId) || ''
                          if(window.innerWidth > WIDTH_VIEW_MOBILE){
                            return(
                              <tr key={index} id={`car-${filterObj.id}-${index}`} style={{cursor: "pointer", backgroundColor: (car.read ? "#ffffff" : null)}}
                                  onClick={this.edit.bind(null, car)}>
                                <td  style={{padding: "10px 0", float: "left", width: "100%"}}>
                                  <div className="border-left-div" style={{borderLeftColor: filterObj.color}}/>
                                </td>
                                {columns.map((column,index) => {
                                  const isImage = column.value === 'image';
                                  const renderColumn = renderData.find( r => r.value === column.value);
                                  if(column.show) {
                                    if(isImage)
                                      return (
                                          <td style={{paddingTop: 10, paddingBottom: 10}}>
                                            <img alt="" style={{width: "70%"}}
                                                 src={renderColumn.html(car,makeName, modelName) ? renderColumn.html(car,makeName, modelName) : CAR_IMAGE_COMING_SOON}/>
                                          </td>
                                      );
                                    else
                                      return <td key={index} className={column.value}>{renderColumn.html(car,makeName, modelName)}</td>

                                  }
                                })}
                                <td className="td-options" style={{color: "#f3f3f3"}}>
                                  <div className="options">
                                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_copy.svg"}
                                              className="icon-copy"
                                              beforeInjection={ svg => svg.classList.add('icon-copy-svg')}
                                              onClick={(evt) => this.copy(index,filterObj.id,car, evt)}
                                    />
                                    <ReactSVG src={pathServer.PATH_IMG + "icon/edit-pencil.svg"}
                                              className="icon-edit"
                                              beforeInjection={ svg => svg.classList.add('icon-edit-svg')}
                                              onClick={(evt) => this.edit(car, evt)}
                                    />
                                    <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                              className="icon-delete"
                                              beforeInjection={ svg => svg.classList.add('icon-delete-svg')}
                                              onClick={(evt) => this.delete(car.id, evt)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          }else{
                            return (
                              <tr className={"tr-list-view-mobile"} id={`car-${filterObj.id}-${index}`} onClick={ (evt) => this.edit(car, evt)}>
                                <td>
                                  <div className="container-car-list-view">
                                    <div className="container-vertical-line">
                                      <div className="vertical-line-car" style={{background: filterObj.color}}/>
                                    </div>
                                    <div className="container-image-car">
                                      <img src={ !isEmpty(car.mainImage) ? car.mainImage : CAR_IMAGE_COMING_SOON } alt=""/>
                                    </div>
                                    <div className={"container-attributes-car"}>
                                      <span className="title">
                                        {`${makeName} ${modelName} ${car.year} ${ !isEmpty(car["car.trim"]) ? `, ${car["car.trim"].attributeValue}` : `` }` }
                                      </span>
                                      <div className="second-row-attributes">
                                        <span>
                                          {`${car.vin} / ${car.mileage} Miles ${car["car.exteriorColor"] ? `/ ${car["car.exteriorColor"].attributeValue}`: ``}`}
                                        </span>
                                      </div>
                                      <div className="container-prices">
                                        <span>{`Sugg. Price: `}
                                        <span className="suggested-price">{`$ ${car.suggestedPrice === 0 ? ` - `: `${car.suggestedPrice}`}`}</span>
                                          {`R. Price: `}
                                          <span className="retail-price">{`$ ${car.retailPrice === 0 ? ` - `: `${car.retailPrice}`}`}</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="options">
                                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_copy.svg"}
                                                className="icon-copy"
                                                beforeInjection={ svg => svg.classList.add('icon-copy-svg')}
                                                onClick={(evt) => this.copy(index,filterObj.id,car, evt)}
                                      />
                                      <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                                className="icon-delete"
                                                beforeInjection={ svg => svg.classList.add('icon-delete-svg')}
                                                onClick={(evt) => this.delete(car.id, evt)}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                        })
                      }

                  </>
              )
            })
          }
          </tbody>
        </table>
        {showSettingsTable && (
            <DPPopover
                anchorEl={anchorEl}
                classNameWrapper="popover-table"
                handleRequestClose={()=> this.setState({showSettingsTable: false})}>
              <div className='popover-body-locations'>
                <DPDragAndDrop onReorder={this.onHandleReorder} listData={columns}
                               children={(c) => {
                                  return (
                                      <div style={{display:'grid', gridTemplateColumns: '20px auto' }}>
                                        <div>
                                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_dragandDrop.svg"}
                                                    className="icon-fields"
                                                    beforeInjection={svg => svg.classList.add('icon-fields')}/>
                                        </div>
                                        <Checkbox label={c.displayFilter} checked={c.show} id={c.id}
                                                  onCheck={() => this.onSelectFilterTable(c.value)}
                                                  labelStyle={{
                                                    color: "#787878",
                                                    fontSize: 13,
                                                    fontWeight: "400"
                                                  }}
                                                  iconStyle={{borderColor: "#3B86FF", marginRight: 8, marginLeft: 0}}
                                                  checkedIcon={elementCheck('icon/ic_check_box.svg')} uncheckedIcon={elementUnCheck('icon/ic_uncheck_box.svg')}
                                                  name={c.value}
                                                  data-test={`${c.value}-${c.id}`}
                                        />
                                      </div>
                                  )
                               }}>
                </DPDragAndDrop>
              </div>
            </DPPopover>


        )}

      </div>
    )
  }

}