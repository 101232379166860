import React from 'react'
import {DPInput} from '../../../../../common/DPInput'
import {PAGE_HOME_ID, SECTION_LOCATION_ID} from '../../../../../../common/Constants'
import {cloneDeep} from 'lodash'

const MAX_SIZE_SITE_TITLE = 45

const LocationSection = (props) => {

  const {pages, onChangePages} = props
  const homePage = pages.find(x => x.id === PAGE_HOME_ID)
  const locationSection = homePage.sections.find(x => x.id === SECTION_LOCATION_ID)
  const {title, showButton} = locationSection

  const onChangeTitle = (evt) => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const locationSection = homePage.sections.find(x => x.id === SECTION_LOCATION_ID)
    locationSection.title = evt.currentTarget.value
    onChangePages(pagesNew)
  }

  const handleShowButton = () => {
    const pagesNew = cloneDeep(pages);
    const homePage = pagesNew.find(x => x.id === PAGE_HOME_ID)
    const locationSection = homePage.sections.find(x => x.id === SECTION_LOCATION_ID)
    locationSection.showButton = locationSection.showButton ? !locationSection.showButton : true
    onChangePages(pagesNew)
  }

  return(
    <div className="container-home-location">
      <div className="row-title">
        <DPInput
          id='title'
          title='Section title'
          rightElement={<label className={`label-size right ${title.length === MAX_SIZE_SITE_TITLE ? 'error-color' : ''}`}>{`${title.length}/${MAX_SIZE_SITE_TITLE}`}</label>}
          value={title}
          onChange={onChangeTitle}
          maxLength={MAX_SIZE_SITE_TITLE}
        />
      </div>
      {/*todo ==> comment for now, do not delete. It needs to be implemented later.*/}
      {/*<div className="row-toggle">
        <div className="label-toggle">Show get directions button</div>
        <DPToggle id='locationToggle' isChecked={!!showButton} handleCheck={handleShowButton}/>
      </div>*/}
    </div>
  )
}

export default LocationSection