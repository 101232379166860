import React, {Component} from 'react'
import {pathServer} from '../../../../common/Constants'

export default class AttributeHeader extends Component {

  constructor() {
    super();
  }

  render(){
    let {onSave, onClose, left} = this.props;
    return(
      <div className={(left)?"attribute-header-left":"attribute-header-right"}>
        <div style={{width: "86%"}}>
          <h4>Attribute's properties</h4>
        </div>
        <div className="form-button-header">
          <img src={pathServer.PATH_IMG + "ic_close.png"}
               className="icon-close"
               onClick={() => onClose()}/>
        </div>
        <div className="form-button-header">
          <img src={pathServer.PATH_IMG + "ic_save.png"}
               className="icon-save"
               onClick={() => onSave()}/>
        </div>
        <div className="form-button-header">
          <img src={pathServer.PATH_IMG + "ic_disable.png"}
               className="icon-disable"
               onClick={() => onClose()}/>
        </div>
      </div>
    )
  }


}