import React, {useEffect, useState} from 'react'
import Dropzone from 'react-dropzone'
import {ReactSVG} from 'react-svg'
import isEmpty from 'lodash/isEmpty'
import {downloadFile, fileStyles, getFileNameAndType} from '../util/Utils'
import {EXT_IMG, EXT_OTHER_IMG, EXT_PDF, EXT_PNG, EXT_WORD, pathServer} from '../../../common/Constants'

const DPDocument = React.forwardRef(({documents = [],hasDocument, documentType, label, onDrop, onDelete, onSwitch }, ref) => {

  const [ documentsInventory, setDocumentsInventory] = useState([]);
  const [ deleteDocumentIds, setDeleteDocumentIds] = useState([]);

  const deleteFile = (index, evt) => {
    evt.stopPropagation()
    if(documentsInventory[index].id){
      deleteDocumentIds.push(documents[index].id);
    }
    documentsInventory.splice(index,1);
    setDocumentsInventory([...documentsInventory])
    setDeleteDocumentIds([...deleteDocumentIds])
    onDelete(documentsInventory, deleteDocumentIds, documentType)
  }

  const onDropDocument = (file) => {
    const imageUrl = URL.createObjectURL(file[0])
    let newDocuments = [...documentsInventory, {path: file[0].path, documentType: documentType, url: imageUrl ,name: file[0].name, file: file[0]}]
    setDocumentsInventory(newDocuments)
    onDrop(newDocuments, documentType)
  };

  useEffect(() => {
    setDocumentsInventory(documents || [])
  }, [documents])


  return (
    <div className="col-100">
      <div className="upload-left">
        <div className="container-title-document">
          <div className="title">{label}</div>
          <div className="box-switch">
            <label className="switch">
              <input type="checkbox" checked={hasDocument} onChange={() => onSwitch(documentType, !hasDocument)} />
              <span className={hasDocument ? "slider round" : "slider round off"} />
              {
                hasDocument ?
                  <span className="check-label-on">YES</span> :
                  <span className="check-label-off">NO</span>
              }
            </label>
          </div>
        </div>
        {
          hasDocument ?
          <Dropzone id="dropzone" onDrop={onDropDocument} multiple={true} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_WORD},.${EXT_PDF},.${EXT_PNG}`}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="upload-document-car">
                <input {...getInputProps()} />
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_cloud_upload_arrow.svg"} className="cloud-upload-document-inventory"/>
                <div className="upload-text">
                  <label className=" label-upload-text"><label className="sub-text">Upload</label> your file or drag and drop here</label>
                </div>
              </div>
            )}
          </Dropzone> :
            !isEmpty(documentsInventory) ?
              <div className="upload-document-car">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_text-line-form.svg"}
                          className="document-inventory-disabled"/>
              </div> :null
        }
      </div>
      <div className="upload-right">
        {
          !isEmpty(documentsInventory) ?
            <div className="files-container">
              {
                documentsInventory.map((document, index) => {
                  const {name,type} = getFileNameAndType(document.name);
                  let styles = fileStyles[type];

                  return (
                    <div key={"file"+index} id={"file"+index} className="document-item"
                         onClick={(evt) => {}}>
                      <div className="file-info-container">
                        <div className="file-info-container-text">
                          {
                            type === 'pdf' ?
                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_pdf.svg"}
                                        className="icon-type-document" beforeInjection={ svg => svg.classList.add("icon-type-document")}/>:
                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_photo.svg"}
                                        className="icon-type-photo" beforeInjection={ svg => svg.classList.add("icon-type-photo")}/>
                          }

                          <label className="title">{name.substring(0,17) + "." + type}</label>
                        </div>
                        <div className="file-info-container-icon">
                          <ReactSVG src={pathServer.PATH_IMG + "icon/download-tray.svg"}
                                    className="icon-delete-cont" beforeInjection={ svg => svg.classList.add("icon-download")}
                                    onClick={(evt) => downloadFile(document, evt)}/>
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                                    className="icon-delete-cont" beforeInjection={ svg => svg.classList.add("icon-delete")}
                                    onClick={(evt) => deleteFile(index, evt)}/>
                        </div>
                      </div>
                      <div className="icon-file-container">
                        {
                          type === EXT_IMG || type === EXT_OTHER_IMG || type === EXT_PNG ?
                            <img src={document.url} /> :
                            <ReactSVG src={pathServer.PATH_IMG + "icon/" + styles.icon}
                                      className="icon-file-parent"
                                      beforeInjection={svg => { svg.classList.add('icon-file');
                                        type === EXT_PDF ?
                                          svg.setAttribute('style', `fill: red`) : svg.setAttribute('style', `fill: #4F7CFF`)
                                      }}
                            />
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div> : null
        }
      </div>
    </div>
  )

})

export default DPDocument