import React, {Component} from 'react'
import {connect} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {mergeArrays} from './util/Utils'

class UserHasPermission extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    let {isAccountOwner, userRoles, permissionId, permissionIds = []} = this.props
    const reducer = (accumulator, currVal) => {
      let permissionIds = currVal.permissions.map(permission => permission.id)
      return mergeArrays(accumulator, permissionIds)
    };
    let userPermissionIds = Object.values(userRoles).reduce(reducer,[]);
    if (isAccountOwner || isEmpty(permissionId)  || userPermissionIds.find(id => id === permissionId) || userPermissionIds.some(id => permissionIds.includes(id))){
      return this.props.children
    } else {
      return null
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isAccountOwner: state.isAccountOwner,
    userRoles: state.userRoles
  }
};

export default connect(mapStateToProps)(UserHasPermission)