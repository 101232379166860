import React, {Component} from 'react'
import Wrapper from './layout/Wrapper.jsx'
import {connect} from 'react-redux'
import {getActiveFeatureFlags, loadFinances, loadMessages} from '../actions'
import {loadInvoices} from '../Api'
import filter from 'lodash/filter'
import Payment from './payment'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import {SUCCESSFUL_CODE} from '../../common/Constants'

class App extends Component {

  constructor() {
    super();
    this.handleResize = this.handleResize.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.state={
      height: window.innerHeight,
      width: window.innerWidth,
      search: "",
      showPayCurrentBillModal: false,
      selectedInvoice: null,
      paymentMethods: [],
    }
  }

  async componentDidMount() {
   const {responseCode, responseMessage, data} = await loadInvoices()
    if (responseCode === SUCCESSFUL_CODE) {
      const {invoices, paymentMethods} = data
      const sortedInvoices = reverse(sortBy(invoices, ['orderNumber']))
      const filteredInvoices = filter(sortedInvoices, el => el.orderStatus === 'unpaid')
      const selectedInvoice = (filteredInvoices.length > 0) ? filteredInvoices[0] : null

      this.setState({
        selectedInvoice,
        paymentMethods,
      })
      // Call this API to calculate new finance applications
      await this.props.loadFinances()
      // Call this API to calculate unread messages
      await this.props.loadMessages()
      // Call this API to get active feature flags
      await this.props.getActiveFeatureFlags()
    } else {
      console.error(responseMessage)
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(){
    $(document).unbind('click', this.clickDocument);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e){
    this.setState({height:window.innerHeight,width:window.innerWidth});
  }

  handleSearch(search){
    this.setState({search:search})
  }

  handleShowModal = () => {
    this.setState(prevState => {
      return (
        { showPayCurrentBillModal: !prevState.showPayCurrentBillModal }
      )
    })
  }

  handleSetReload = async (value) => {
    const {responseCode, responseMessage, data} = await loadInvoices()
    if (responseCode === SUCCESSFUL_CODE) {
      const {invoices, paymentMethods} = data
      const sortedInvoices = reverse(sortBy(invoices, ['orderNumber']))
      const filteredInvoices = filter(sortedInvoices, el => el.orderStatus === 'unpaid')
      const selectedInvoice = (filteredInvoices.length > 0) ? filteredInvoices[0] : null

      this.setState({
        selectedInvoice,
        paymentMethods,
      })

    } else {
      console.error(responseMessage)
    }
  }

  render() {
    const {pathname} = this.props.location;
    const { vin } = this.props.params
    const { height, width, search, selectedInvoice, showPayCurrentBillModal, paymentMethods } = this.state;
    const keyword = vin ? vin : null;

    let calculatedHeight = height - 60

    if (pathname === '/main/updatePlan' && window.innerWidth <= 412) {
      calculatedHeight = height - 120
    }

    return (
      <div>
        <Wrapper path={pathname.substring(6)} height={height} width={width} handleSearch={this.handleSearch}
                 filter={search} keywordParameter={keyword}
                 handleShowModal={this.handleShowModal} selectedInvoice={selectedInvoice}
                 hideSearchSection={['/main', '/main/dashboard'].includes(pathname)}
        />
        {React.cloneElement(this.props.children, {
          height: calculatedHeight, search: search
        })}
        {
          showPayCurrentBillModal
          && <Payment
            show={showPayCurrentBillModal}
            close={this.handleShowModal}
            selectedOrder={selectedInvoice}
            setReload={this.handleSetReload}
            paymentMethods={paymentMethods}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
};

export default connect(mapStateToProps, {loadFinances, loadMessages, getActiveFeatureFlags})(App)
