import { Modal } from 'react-bootstrap'
import { ReactSVG } from 'react-svg'
import { pathServer, SUCCESSFUL_CODE, TIME_FIVE_SECONDS } from '../../../../common/Constants'
import { PaymentMethod, TabHeader, TierPage } from '../../superusers/dealers'
import React, { useEffect, useState } from 'react'
import { TAB_NEW_DEALER_PAYMENT_INFO, TAB_NEW_DEALER_TIER } from '../../../Constants'
import { createSubscription } from '../../../Api'
import { PaymentMethodItem } from './BillingContainer'
import { unstable_batchedUpdates } from 'react-dom'
import {ADD_NEW_CARD} from '../../payment/braintree'

require('./PaymentModal.scss')

const CREDIT_CARD_OPTION = "Credit Card"

export const CreateSubscriptionModal = ({
                                          swapModalStatus,
                                          showModal,
                                          setReload,
                                          dealerId = null,
                                          title,
                                          paymentMethods
                                        }) => {

  const [currentTab, setCurrentTab] = useState(TAB_NEW_DEALER_TIER)
  const [selectedPlan, setSelectedPlan] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState(null)
  const [addCreditCard, setCreditCard] = useState(false)
  const [selectedToken, setSelectedToken] = useState(null)

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(CREDIT_CARD_OPTION)
  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (!showModal) {
        setCurrentTab(TAB_NEW_DEALER_TIER)
        setSelectedPlan(undefined)
        setSelectedToken(null)
        setCreditCard(false)
      }
      if (paymentMethods?.length === 0)
        setCreditCard(true)
    })
  }, [showModal])

  const handleSubscription = async (nonce, selectedPlan, requiredElements) => {
    if (nonce === null && requiredElements) {
      setErrorMessage('Some fields are empty or wrong. Please complete all.')
      setTimeout(() => {
        setErrorMessage(null)
      }, TIME_FIVE_SECONDS)
      return
    }

    const data = selectedToken ?
      { inventoryItemId: selectedPlan.id, dealerId, token: selectedToken } :
      { nonce, makeDefault: true, inventoryItemId: selectedPlan.id, dealerId }

    createSubscription(data).then(response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        swapModalStatus()
        setReload(reload => !reload)
      }else{
        setErrorMessage(responseMessage)
        setTimeout(() => {
          setErrorMessage(null)
        }, TIME_FIVE_SECONDS)
      }
      })
  }

  let child = null

  const handlePaymentType = () => {
    unstable_batchedUpdates(() => {
      setCreditCard(true)
      setSelectedToken(null)
    })
  }

  const handleSelectItem = (data) => {
    unstable_batchedUpdates(() => {
      setSelectedToken(data)
      setCreditCard(false)
    })
  }

  switch (currentTab) {
    case TAB_NEW_DEALER_TIER:
      child =
        <TierPage
          changeTab={() => setCurrentTab(TAB_NEW_DEALER_PAYMENT_INFO)}
          setSelectedPlan={(plan) => setSelectedPlan(plan)}
          selectedPlan={selectedPlan}
          showBackBtn={false}
        />
      break
    case TAB_NEW_DEALER_PAYMENT_INFO:
      child =
        <div className="payment-container">
          <hr/>
          <div className="saved-credit-card-container">
          {
            paymentMethods && paymentMethods.map(el => {
              const creditCardNumber = `XXXX - ${el.cardNumber}`
              return (
                <div key={`payment-method-${el.token}`} className='payment-method-item-container'>
                  <PaymentMethodItem
                    token={el.token}
                    classPaymentMethodItem={selectedToken === el.token ? 'payment-method-selected-item' : 'payment-method-item'}
                    creditCardNumber={creditCardNumber}
                    creditCardType={el.cardType}
                    showOptions={false}
                    onClick={handleSelectItem}
                  />
                </div>
              )
            })
          }
            <div className='payment-method-item-container'>
              <div className={selectedToken === ADD_NEW_CARD ? 'payment-method-selected-item' : 'payment-method-item'}>
                <div className='credit-card-number'
                     onClick={
                       () => setCreditCard(true)
                     }
                >Add new card</div>
              </div>
            </div>
          </div>
          {
            addCreditCard ?
              <PaymentMethod
                onClick={handleSubscription}
                selectedPlan={selectedPlan}
                showBackBtn={false}
                errorMessage={errorMessage}
                customPayMessage="Pay"
              />
              :
              selectedToken &&
              <div onClick={() => handleSubscription(null, selectedPlan, false)}
                   className="btn btn-primary manual-goBack-btn-modal payment-btn">Pay
              </div>
          }
          <div onClick={() => setCurrentTab(TAB_NEW_DEALER_TIER)}
               className="btn btn-primary manual-goBack-btn-modal">Back
          </div>
        </div>
      break
  }

  return (
    <Modal show={showModal} className={`modal-select-tier new-subscription payment-modal ${currentTab == TAB_NEW_DEALER_TIER ? "modal-large" : "modal-medium"}`}>
      <Modal.Body>
        <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_close.svg'} className="close-modal-icon"
                  onClick={swapModalStatus}/>
        <div className="panel-content">
          <div className="panel-top">
            <div className="panel-top-left">
              <label onClick={() => setReload(reload => !reload)}>{title}</label>
            </div>
            <div className="panel-top-right">
              <TabHeader
                isSelected={currentTab === TAB_NEW_DEALER_TIER}
                headerText={'Tier'}
              />
              <TabHeader
                isSelected={currentTab === TAB_NEW_DEALER_PAYMENT_INFO}
                headerText={'Payment method'}
              />
            </div>
          </div>
          {child}
        </div>
      </Modal.Body>
    </Modal>
  )
}