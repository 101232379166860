import React, {useState} from 'react'
import {EmptyFunc, pathServer} from './Constants'
import {ReactSVG} from 'react-svg'
import {Modal} from 'react-bootstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import DPIcon, {Icons} from '../admin/common/DPIcon'
require('./DPVideoModal.scss')

const randomId = Math.random ().toString (16) .substring(2, 9);

const DPVideoModal = (props) => {
  const { videoTagId,showVideoModal , closeModal, videoSource , showDefaultControls , autoplay , className } = props

  const [playIcon, setPlayIcon] = useState(Icons.PAUSE_VIDEO)
  const [soundIcon, setSoundIcon] = useState(Icons.SOUND_ACTIVE)

  const handlePlay = () => {
    const video = document.getElementById(videoTagId)
    if(video){
      if(video.paused){
        setPlayIcon(Icons.PAUSE_VIDEO)
        video.play()
      }else{
        setPlayIcon(Icons.PLAY_VIDEO)
        video.pause()
      }
    }
  }

  const handleMute = () => {
    const video = document.getElementById(videoTagId)
    if(video){
      if(video.muted){
        setSoundIcon(Icons.SOUND_ACTIVE)
      }else{
        setSoundIcon(Icons.MUTED_VIDEO)
      }
      video.muted = !video.muted
    }
  }

  const handleFullScreen = () => {
    const video = document.getElementById(videoTagId)
    if(video){
      if (video.requestFullScreen) {
        video.requestFullScreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
      }
    }
  }

  return(
    <Modal show={showVideoModal} className={classnames("video-modal", className)}>
      <Modal.Body>
        <div className="container-video">
          <ReactSVG
            src={`${pathServer.PATH_IMG}icon/ic_cancel.svg`}
            className="close-video"
            onClick={closeModal}
          />
          <video id={videoTagId} src={videoSource} width='100%' controls={showDefaultControls} autoPlay={autoplay} disablePictureInPicture={true}/>
          {
            !showDefaultControls &&
            <div className="video-controls">
              <div className="left-controls">
                <div className="circle-container" style={{marginRight: '15px'}} onClick={handlePlay}>
                  <DPIcon icon={playIcon} />
                </div>
                <div className="circle-container" onClick={handleMute}>
                  <DPIcon icon={soundIcon} />
                </div>
              </div>
              <div className="circle-container" onClick={handleFullScreen}>
                <DPIcon icon={Icons.FULL_SCREEN_VIDEO} />
              </div>
            </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

DPVideoModal.defaultProps = {
  videoTagId: randomId,
  showVideoModal: false,
  closeModal: EmptyFunc,
  videoSource: 'https://dealer-pro-assets.s3.us-west-2.amazonaws.com/videos/Godealer11.mp4',
  showDefaultControls: false,
  autoplay: true,
  className: '',
}

DPVideoModal.propTypes = {
  videoTagId: PropTypes.string,
  showVideoModal: PropTypes.bool,
  closeModal: PropTypes.func,
  videoSource: PropTypes.string,
  showDefaultControls: PropTypes.bool,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
}

export default DPVideoModal