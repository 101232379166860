import {IndexRoute, Route} from 'react-router'
import React from 'react'
import App from '../newComponents/review/App.js'
import Dashboard from '../newComponents/review/Dashboard'
import ReviewsTabSetting from '../newComponents/review/ReviewsTabSetting'
import CustomerLandingPage from '../newComponents/review/customer/CustomerLandingPage'
import ContainerGeneralReviewSetting from '../newComponents/review/settings/ContainerGeneralReviewSetting'
import Customer from '../newComponents/customer/CustomerContainer'
import CustomerInfo from '../newComponents/customer/CustomerInfo'
import CustomerNew from '../newComponents/customer/CustomerNew'
import CustomerSale from '../newComponents/customer/CustomerSale'

export default [

  <>
    <Route path="reviews">
    {/*  <Route path="reviews" component={App}>*/}
      {/*<Route path="dashboard" component={Dashboard}/>*/}
      <Route path="dashboard/review" component={ReviewsTabSetting} />
      <Route path="dashboard/referrals" component={Dashboard} />
      <Route path="setting" component={ContainerGeneralReviewSetting}>
        <Route path="location" component={ContainerGeneralReviewSetting}/>
      </Route>
      <Route path="customer" component={CustomerLandingPage}>
        <Route path="list" component={() => (<Customer />)} />
        <Route path="edit/:id" component={CustomerInfo}/>
        <Route path="new" component={CustomerNew}/>
        <Route path="edit/:customerId/sales/:saleId/:tab" component={CustomerSale}/>
      </Route>
      {
        /*
         <Route path="customer" component={CustomerLandingPage} >
          <Route path="list" component={CustomerReview} />
         </Route>
         */
      }
      <IndexRoute component={ReviewsTabSetting} />
    </Route>
  </>

]