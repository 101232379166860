import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import DPIcon, {Icons} from '../../../common/DPIcon'
import DPLoader from '../../../common/DPLoader'
import Dropzone from 'react-dropzone'
import {ReactSVG} from 'react-svg'
import {isEmpty} from 'lodash'
import {InputMask, InputValue} from './commons'
import {addRegisterSuperuser, updateSuperuserWithImage} from '../../../Api'
import {EXT_IMG, EXT_OTHER_IMG, EXT_PNG, pathServer} from '../../../../common/Constants'
import {checkValidEmail, checkValidPhone} from '../../../../common/ValidationUtil'


// const fieldsRequired = [{key:"firstName",type:"required"},
//   {key:"lastName",type:"required"},
//   {key:"email",type:"required"},
//   {key:"active",type:"active"},
//   {key:"password",type:"password"}];
//
// const fieldsEditRequired = [{key:"firstName",type:"required"},{key:"lastName",type:"required"}, {key:"email",type:"required"}];

const fieldsToValidate = [{key:'firstName', required: true},{ key: 'lastName', required: true},{key:'email', required: true}, {key: 'phoneNumber'}]

const EmptyNewUser = {"firstName":"","lastName":"","email":"","phoneNumber":"","address":"","description":"","image":"","active":true,"verify":false,"password":"thispasswordismeaningless"}


const AddEditModal = ({
                        show,
                        selectedUser = EmptyNewUser,
                        onSaveUser,
                        // errorEmail,
                        // errorEmailDuplicate,
                        onHideModal
                      }) => {

  const [user, setUser] = useState({...selectedUser})
  const [errors, setErrors] = useState({})
  const [file, setFile] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  console.log('user', user, selectedUser)

  const handleChange = (name, value) => {
    setUser({...user, [name]: value})
    const { [name]:_, ...otherErrors} = errors
    setErrors(otherErrors)
  }

  const onDropEdit = (file) => {
    setUser({...user, image: URL.createObjectURL(file[0])})
    setFile(file)
  }

  const onBlur = (name, isRequired, value) => {
    const error = validateField(name, isRequired, value)
    if (!isEmpty(error))
      setErrors({...errors, [name]: error})
  }

  const validateField = (name, isRequired, value) => {
    if (isRequired && isEmpty(value))
      return 'Field can not be empty.'

    if (name === 'email' && !checkValidEmail(value))
      return 'Email is not valid.'

    if (name === 'phoneNumber' && !isEmpty(value) && !checkValidPhone(value))
      return 'Phone number is not a valid format.'
  }

  const validateFields = () => {
    const validationErrors = {}
    fieldsToValidate.forEach(({key, required}) => {
      const error = validateField(key, required, user[key])
      if (!isEmpty(error))
        validationErrors[key] = error
    })
    return validationErrors
  }

  const saveUser = async () => {
    const errors = validateFields()
    if(!isEmpty(errors))
      return setErrors(errors)

    try{
      setIsLoading(true)
      if(user.id){
        await updateSuperuserWithImage(user.id, {
          files: file,
          superuser: user
        })
      }else{
        await addRegisterSuperuser(user, file[0])
      }

      onSaveUser && onSaveUser()

    }
    catch (error){
      console.log('error', error)

      if(error?.message?.indexOf('Duplicate')  > -1){
        errors['email'] = 'User’s email already exists.'
        setErrors(errors)
      }
      //what should we do if there is an error from the backend?
    }
    finally {
      setIsLoading(false)
    }
  }

  const createUser = () => {
    // let {newSuperuser, file, errorEmail, errors, itemsByPageQuantity, showInactive} = this.state;
    // const error = getError(errors,newSuperuser, fieldsRequired);
    // const hasError = validateError(error);
    // if(!hasError && !errorEmail && !errors["phoneNumber"]){
    //   this.setState({isLoading: true});
    //   addRegisterSuperuser(newSuperuser, file[0]).then(response => {
    //     this.setState({showAddSuperuser: false, newSuperuser: getSuperuserInitial(), errorEmailDuplicate: false, errorEmail: false, errors: {}, file:[]}, () => {
    //       setTimeout(function () {
    //         this.setState({isLoading: false});
    //       }.bind(this), 300);
    //     });
    //     this.loadSuperusers(0, itemsByPageQuantity, "",showInactive);
    //   }).catch((error) => {
    //     if (error.message.substr(0,9) === "Duplicate"){
    //       this.setState({errorEmailDuplicate: true, isLoading: false})
    //     }
    //   })
    // } else {
    //   this.setState({errors:error});
    // }
  }


  const imageUrl = user && !isEmpty(user.image) ? user.image : pathServer.PATH_IMG + "ic_account_circle.png"
  return (
    <Modal show={show} className="modal-edit-user">
      <Modal.Body>
        <DPIcon icon={Icons.CLOSE_ICON} onClick={onHideModal}/>
        <DPLoader show={isLoading}/>
        <div className="container-panels" style={{opacity: isLoading ? 0.4 : 1}}>
          <div className="panel-left">
            <div className="container-photo">
              <div className="container-customer-image">
                <Dropzone onDrop={onDropEdit} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className="dropzone-container">
                      <input {...getInputProps()} />
                      <img src={imageUrl} className="customer-image"/>
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"} className="img-customer"/>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
          <div className="panel-right">
            <div className="form-edit-user">
              <div className="first-row">
                <InputValue placeholder="Type Name" value={user.firstName} errorMessage={errors["firstName"]}
                            onBlur={onBlur} name="firstName" onChange={handleChange} title="First Name"
                            isRequired={true} testId={'new-user-first-name'}/>
                <InputValue placeholder="Type Last Name" value={user.lastName} errorMessage={errors["lastName"]}
                            onBlur={onBlur} name="lastName" onChange={handleChange} title="Last Name"
                            isRequired={true} testId={'new-user-last-name'}/>
              </div>
              <div className="second-row">
                <InputValue placeholder="Type Email" value={user.email} errorMessage={errors["email"]} onBlur={onBlur}
                            name="email" onChange={handleChange} title="Email" isRequired={true}
                            testId={'new-user-email'}
                            />
                <InputMask placeholder="(###)###-####" value={user.phoneNumber} errorMessage={errors["phoneNumber"]}
                           onBlur={onBlur} name="phoneNumber" onChange={handleChange} title="Phone number"
                           isRequired={false} testId={'new-user-phone-number'}/>
              </div>
              <div className="third-row">
                <InputValue placeholder="Type Address" value={user.address} errorMessage={errors["address"]}
                            name="address" onChange={handleChange} title="Address" isRequired={false}
                            testId={'new-user-address'}
                />
              </div>
              <div className="fourth-row">
                <InputValue placeholder="Type Name" value={user.description} errorMessage={errors["description"]}
                            name="description" onChange={handleChange} title="Description" isRequired={false}
                            testId={'new-user-description'}
                />
              </div>
              <div className="fifth-row">
                <div className="container-button-save">
                  <button className="btn-save" onClick={saveUser} data-testid={'new-user-button-save'}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddEditModal