import React, {Component} from 'react'
import {connect} from 'react-redux'
import RecoverPassword from './RecoverPassword.jsx'
import {signInApi} from '../../Api.js'
import {pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import {checkValidEmail} from '../../../common/ValidationUtil'

//todo: not in use: delete!!
class LoginAccount extends Component {

  constructor() {
    super();
    this.state = {
      email:"",
      password:"",
      showRecover:false,
      showResetPassword: false,
      isValidEmail:true,
      isEmptyPassword:false,
      errorMessage:null,
      backChangePassword: false
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.location.state){
      if (nextProps.location.state.changePassword==="changePassword")
        this.setState({backChangePassword: true});
      else
        this.setState({backChangePassword: false});
    }
  }

  recoverPassword = () => {
    let {email} = this.state;
    if(checkValidEmail(email)){
      this.setState({showRecover:true})
    }else{
      this.setState({isValidEmail:false})
    }
  }

  resetPassword = () => {
    this.setState({isValidEmail:true, showResetPassword:true})
  }

  goSignIn = () => {
    this.setState({showRecover:false,isValidEmail:false})
  }

  login = () => {
    let {email,password} = this.state;
    if(!checkValidEmail(email)){
      this.setState({isValidEmail:false})
    }else if(password === ""){
      this.setState({isEmptyPassword:true})
    }else{
      console.log("go to login");
      signInApi({email:email,password:password}).then(({responseCode, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          console.log("user logged");
          window.location.href = '/main';
        } else {
        console.error("error login",responseMessage);
        this.setState({errorMessage: responseMessage})
        }
      });
    }
  }

  backChangePassword = () => {
    setTimeout(function() {
      this.setState({showRecover: false, backChangePassword:false});
    }.bind(this),15000);
    return(
      <div>
        <span className="message-back-change-password">"Your password has been successfully changed, please login now"</span>
      </div>
    )
  }

  render() {
    let {email,password,showRecover,isValidEmail,isEmptyPassword,errorMessage, showResetPassword, backChangePassword} = this.state;
    return (
      <div className="account-confirmation">
        <div id="id-background" className="background"/>
        <div className="header"/>
        {(backChangePassword)?this.backChangePassword():(!showRecover)?<img className="logo-godealergo" src={pathServer.PATH_IMG + "godealergo.png"} alt=""/>: null}
        {(this.props.children)?React.cloneElement(this.props.children):
          showResetPassword ?
              (showRecover)?
                  (<RecoverPassword email={email} back={this.goSignIn}/>):
                  (
                      <div className="form-container">
                        <div id="id-form-content" className="form-content">
                          <div className="id-form-content-reset">
                            Reset Password
                          </div>
                          <div className="id-form-content-email">
                            <div>
                              <label>Email</label>
                              <input type="text" placeholder="Type here" value={email}
                                     onKeyUp={e => e.keyCode === 13 ? this.recoverPassword() : null}
                                     onChange={(e) => this.setState({
                                       email: e.target.value,
                                       isValidEmail: true,
                                       errorMessage: null
                                     })}
                              />
                              {!isValidEmail ? <span className="message-error">We could'nt find this email, please provide a verified email.</span> : null}
                            </div>
                          </div>
                          <div className="btn-form-content-reset">
                            <button id="id-btn-login" className="btn-login" onClick={this.recoverPassword}>Reset</button>
                          </div>
                        </div>
                      </div>
                  )
              :
              <div className="form-container">
                <div id="id-form-content" className="form-content">
                  <div className="id-form-content-reset">
                    Sign in
                  </div>
                  <div className="id-form-content-email">
                    {errorMessage ? <span className="message-general-error">{errorMessage}</span> : null}
                    <div>
                      <label>User name / email</label>
                      <input type="text" placeholder="Type here" value={email}
                             onChange={(e) => this.setState({
                               email: e.target.value,
                               isValidEmail: true,
                               errorMessage: null
                             })}/>
                      { !isValidEmail ? <span className="message-error">Please provide a username or email</span> : null}
                    </div>
                    <div className="form-content-password">
                      <label>Password</label>
                      <input type="password" placeholder="Type here" value={password}
                             onKeyUp={ e => e.keyCode === 13 ? this.login() : null}
                             onChange={(e) => this.setState({
                               password: e.target.value,
                               isEmptyPassword: false,
                               errorMessage: null
                             })}/>
                      { isEmptyPassword ? <span className="message-error">Please provide password</span> : null}
                    </div>
                    <div className="form-forgot-password">
                      <div onClick={this.resetPassword} >I forgot my password</div>
                    </div>
                  </div>
                  <div className="form-btn-sign-in">
                    <button id="id-btn-login" className="btn-login" onClick={this.login}>Sign in</button>
                  </div>
                </div>
              </div>
        }

      </div>
    )
  }
}
const mapStateToProps = (state,ownProps) => {
  return {
    dealerId:state.dealerId
  }
};

export default connect(mapStateToProps)(LoginAccount)
