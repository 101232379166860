import React from 'react'
import classnames from "classnames";
require('./splitSection.scss')

export default function SplitSection({leftSection, rightSection, className, floatingSection}) {
  return (
    <div className={classnames(['slitSection', className])}>
      <div className = {'section-content'}>
        <div className={'section-wrapper'} >
          {leftSection}
        </div>
      </div>
      <div className={'section-divider'}/>
      <div className = {'section-content'}>
        <div className={'section-wrapper'}>
          {rightSection}
        </div>
      </div>
      {floatingSection}
    </div>
  )
}
