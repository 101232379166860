import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'


const DPDropzone = (props) => (
  <Dropzone
    onDrop={(files) => props.onDrop(files)}
    accept={props.extensionFiles}
    className={props.dropZoneClassName}
    multiple={props.multiple}
  >
    {({getRootProps, getInputProps}) => (
      <section className={props.sectionClassName}>
        <div {...getRootProps({className: props.divClassName})}>
          <input {...getInputProps()} data-test={props.dataTestInput} />
          {props.children}
        </div>
      </section>
    )}
  </Dropzone>
)

DPDropzone.defaultProps = {
  dataTestInput:'file-input',
  dropZoneClassName: '',
  sectionClassName: 'container-drop-zone',
  divClassName: 'dropzone',
  multiple: true,
}

DPDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  extensionFiles: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  dataTestInput: PropTypes.string,
  dropZoneClassName: PropTypes.string,
  sectionClassName: PropTypes.string,
  divClassName: PropTypes.string,
  multiple: PropTypes.bool,
}

export default DPDropzone;