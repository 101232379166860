import React, {Component} from 'react'
import {
  FORM_CUSTOMER,
  ATTRIBUTE_CUSTOMER_STATE,
  ATTRIBUTE_CUSTOMER_DOBMDY
} from '../../Constants'
import {ReactSVG} from 'react-svg'
import {browserHistory} from 'react-router'
import Dropzone from 'react-dropzone'
import {isArray, isEmpty} from 'lodash'
import CustomerHeader from './CustomerHeader.jsx'
import {loadFormByCode, processFormApi, saveCustomersImage} from '../../Api'
import Loader from '../../../common/UI/Loader.jsx'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import Form from '../form/FormRender'
import {parseObjectForm, parseValues, setErrors, validate} from '../../../common/Util'
import {Popover} from '../util/ComponentUtils'
import DPKeyboardDatePicker from '../../common/DPKeyboardDatePicker'
import Moment from 'moment'
import {
  pathServer,
  optionState,
  CAR_IMAGE_HEIGHT_PERCENT,
  EXT_PNG,
  EXT_OTHER_IMG,
  EXT_IMG, WIDTH_VIEW_MOBILE, SUCCESSFUL_CODE
} from '../../../common/Constants'

export const FormRender = Form({
  getDateInput(attribute, dateObject, elementHasValidation, onChange, onBlur, index, error, getValidationMessages) {
    const {displayValue, showRow, id, readOnly} = attribute;

    const defaultDate = new Date()
    const newDate = defaultDate.valueOf() === dateObject.valueOf() ? null : dateObject;

    return (
      <div key={id} className={(error ? 'has-error' : '')}>
        <div>
          {
            displayValue ?
              <div>
                <label>{displayValue}</label>
                {elementHasValidation ? <span className="label-error">*</span>: null}
              </div> : null
          }
          <div>
            <DPKeyboardDatePicker
              id={"dateId"}
              ref={id}
              name={id}
              value={dateObject}
              className={"form-control" + (elementHasValidation ? " colRequired":"")}
              onChange={(date) => onChange(id, index, null, date)}
            />
            {error ?
              <p className="help-block">{getValidationMessages(attribute, error)}</p> : null}
          </div>
        </div>
      </div>
    )
  },
  getSelectInput(input,classNameLabel,elementHasValidation,
                 classNameInput,idSelected,showDropDown,onChangeDropDown,index,error,
                 getValidationMessages,options,_onChange,anchorEl){

    const foundValue = options.find(op => {return op.id.toString() === idSelected.toString()});
    const {id, displayValue, showRow, showLabel, defaultSelect, name} = input;
    let popover;

    let classNameItem = "";
    const child = (
      <div className="popover-general-dp">
        <div className="popover-general-dp-sub">
          <u>
            {
              options.map((op,index) => {
                if(foundValue && op.displayValue === foundValue.displayValue){
                  classNameItem = "item-selected";
                } else {
                  classNameItem = "change-submenu-item";
                }
                return(
                  <li key={index} onClick={() => _onChange(id,index,op.id)} className={classNameItem}>
                    {op.displayValue}
                  </li>
                )
              })
            }
          </u>
        </div>
      </div>
    );

    popover = (
      <Popover anchorEl={anchorEl}
               child={child}
               classPopover="popover-box-modal-state"
               handleRequestClose={onChangeDropDown}/>
    );

    return (
      <div key={'field_'+id} className={(error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className={classNameLabel ? classNameLabel:" "}>
                <label htmlFor={id}
                       className={showLabel}>
                  {displayValue}
                </label>
              </div>: null
          }
          <div className={(classNameInput ? classNameInput:" ")} style={{position: "relative"}}>
            <a className="btn-basic btn-select-option" onClick={(e) => onChangeDropDown(e,id)}>
              <span style={{color: "#2194F1"}}>{foundValue ? foundValue.displayValue:defaultSelect}</span>
              {
                (showDropDown) ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />
              }
            </a>
            {
              showDropDown ? popover : null
            }
          </div>
        </div>
      </div>
    )
  }
});

export function addOption(attributes) {
  attributes.forEach(attribute => {
    switch (attribute.name) {
      case ATTRIBUTE_CUSTOMER_STATE :
        attribute.attributeValues = optionState;
        break;
    }
  });
}

export default class CustomerNew extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      section: {
        customerInformation: true,
        financeApplications: false,
        customerCars: false
      },
      isLoading: false,
      file:[],
      errors:{},
      hasChange:false,
      showModal:false,
      goBack:false,
      preview: null
    }
  }

  componentDidMount() {
    this.setState({isLoading: true})
    loadFormByCode(FORM_CUSTOMER, "new").then(response => {
      const {responseCode, responseMessage, data} = response
      if(responseCode === SUCCESSFUL_CODE){
        const {form, values} = data
        const {formAttributes: formBase} = form
        const {sections, attributes} = formBase
        addOption(attributes)
        const customerForms = parseObjectForm(sections)
        const customer = parseValues(attributes,sections)
        this.setState({formCode: FORM_CUSTOMER, formBase, sections, customerForms, values,
          attributes, isLoading: false, customer})
      }else {
        console.error(responseMessage)
      }
    })
  }

  componentWillUnmount(){
    const {setAddCustomerManually} = this.props
    setAddCustomerManually(false)
  }

  save = async () => {
    let {customer, file, values, attributes, formCode} = this.state;
    const errors = {}

    // Updating Date of Birth field value just when it's empty
    const dobAttribute = attributes.find(el => el.name === ATTRIBUTE_CUSTOMER_DOBMDY)
    if (dobAttribute && isEmpty(values[dobAttribute.id])) {
      values[dobAttribute.id] = Moment().format('MM-DD-YYYY');
    }
    // End Section

    for (let section in customer) {
      for (let key in customer[section]) {
        customer[section][key] = values[key];
      }
    }
    for (let key in this.refs) {
      if (key !== "inventoryImage") {
        errors[key] = this.refs[key].state.errors;
        setErrors(attributes, customer[key], errors[key]);
      }
    }

    let hasError = validate(errors);

    if(!hasError){
      let requestForm = {formCode:formCode,
                         values: values};
      this.setState({isLoading: true,errors});

      const responsePFA = await processFormApi({requestForms: [requestForm]})
      const {responseCode, responseMessage, data} = responsePFA
      if (responseCode === SUCCESSFUL_CODE) {
        const customerId = data
        if(file.length > 0){
          let data = {files:file};
          const responseSCI = await saveCustomersImage(customerId[0], data)
          if (responseSCI.responseCode === SUCCESSFUL_CODE) {
            this.setState({isLoading: false, showModal: false});
            browserHistory.push({pathname: `/main/customer/edit/${responseSCI.data.customerId}`});
          }
          else {
            this.setState({isLoading: false, showModal: false});
            console.error(responseSCI.responseMessage);
          }
        } else{
          this.setState({isLoading: false, showModal: false});
          browserHistory.push({pathname: `/main/customer/edit/${customerId[0]}`});
        }
      }
      else {
        this.setState({isLoading: false, showModal: false});
        console.error(responseMessage)
      }
    }else{
      this.setState({errors});
    }
  }

  onDrop = (files) => {
    const preview = URL.createObjectURL(files[0]);
    this.setState({file:files, preview:preview})
  }

  backValidate = () => {
    const {customer, hasChange, values, attributes} = this.state;
    const errors = {}
    for (let section in customer) {
      for (let key in customer[section]) {
        customer[section][key] = values[key];
      }
    }
    for (let key in this.refs) {
      if (key !== "inventoryImage") {
        errors[key] = this.refs[key].state.errors;
        setErrors(attributes, customer[key], errors[key]);
      }
    }
    let hasError = validate(errors);

    if(hasChange){
      if(!hasError)
        this.setState({showModal: true, goBack:true});
      else
        this.setState({errors});
    }else{
      browserHistory.push({pathname: `/main/customer`});
    }
  }

  handleModalChange = () => {
    const {goBack} = this.state;
    if(!goBack)
      this.setState({showModal:false});
    else
      browserHistory.push({pathname: `/main/customer`});
  }

  onFormChange = (data) => {
    let {values, attributes} = this.state;
    for(let key in values) {
      if(isArray(values[key])) {
        if(typeof data[key] !== 'undefined' && values[key]) {
          values[key].length = 0;
          values[key].push(data[key]);
        }
      }else{
        if (typeof data[key] !== 'undefined') {
          values[key] = data[key];
        }
      }
    }

    this.setState({values:values, attributes: attributes, hasChange:true});
  }

  render(){
    const {height,left} = this.props;
    const {section, isLoading,hasChange,showModal,preview,
      attributes, customerForms, values} = this.state;

    if (!customerForms) return null;

    let width;
    let heightImage;
    const element = window.document.getElementById("bodySettingCustomer");
    if (element) {
      width = (element.offsetWidth - 177) / 4;
      heightImage = width * CAR_IMAGE_HEIGHT_PERCENT;
    }

    return (
      <div className="customer-content">
        {isLoading ? <Loader/> : null}
        <CustomerHeader title="Customer" left={left} classNameButton="button-close" value="x" action={this.backValidate}
                        showButtonAdd={true} save={this.save} isEdit={true} actionBack={this.backValidate} hasChange={hasChange}/>
        <div id="bodySettingCustomer" className="body-setting-customer">
          <div className="setting-container" style={{display: "block", minHeight: height}}>
            <div className="customer-info-new">
              {
                window.innerWidth > WIDTH_VIEW_MOBILE &&
                <div>
                  <h4 className="title">Customer Information</h4>
                </div>
              }
              <div className="image-customer-info">
                {
                  preview ?
                    <img src={preview} className="img-customer img-customer-selected"/>
                    :
                    <Dropzone id="dropZone" onDrop={this.onDrop} multiple={false} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
                      {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="dropzone-content dropzone-container">
                          <input {...getInputProps()} />
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"} className="img-customer"/>
                        </div>
                      )}
                    </Dropzone>
                }
              </div>
              {
                window.innerWidth <= WIDTH_VIEW_MOBILE &&
                <div className="title-container">
                  <h4 className="title">Customer Information</h4>
                </div>
              }
              {
                section["customerInformation"] ?
                  <div className="body-customer-info">
                    <FormRender ref={"Section-0"} section={customerForms["Section-0"]}
                                attributes={attributes}
                                data={values}
                                onChange={this.onFormChange}/>
                  </div> : null
              }
            </div>
          </div>
        </div>
        {
          showModal ?
            <GenericModalConfirm show={showModal}
                                 message={"Are you sure you want to save these changes?"}
                                 save={this.save}
                                 onHide={this.handleModalChange}
                                 showButtonCancel={true}
                                 buttonLabelSave="Save"
                                 classButtonSave="btn-save"
            /> : null
        }
      </div>
    )
  }
}
