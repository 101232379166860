import React, {useEffect, useState} from 'react'
import Wrapper from './layout/Wrapper'

const barSearchHeight = window.innerWidth > 768 ? 60 : 40

const Index = ({showSideBar, children, props}) => {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
   /* if(showSideBar && width > WIDTH_VIEW_TABLET){
      setWidth(width);
    } else{
      setWidth(width);
    }*/

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[])

  const handleResize = (e) => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  return(
    <>
      <div style={{display: 'flex', flexDirection: 'column', height: height - barSearchHeight, width: width}}>
        <Wrapper height={height} path={window.location.pathname}/>
        {
          children ?
            <>
              {React.cloneElement(children, {height: (height - 2*barSearchHeight)})}
            </>
            :
            null
        }
      </div>
    </>
  )
}

export default Index