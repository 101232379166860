import React from 'react'
import CustomerPanel from '../CustomerPanel'
import {connect} from 'react-redux'

require('./CustomerLandingPage.scss')

const CustomerLandingPage = ({height, ...props}) => {
  const {showSideBar, setCustomerManually, children} = props
  const paddingLeft = showSideBar ? '215px' : '65px'

  return (
    children ?
      children :
      <div className={'container-reviews-landing'} style={{paddingLeft: paddingLeft}}>
        <div className={'column-reviews-landing'}>
          <CustomerPanel
            title1={'Welcome to your'}
            title2={'customers panel'}
            text={<span>Keep your customer information in one place and add more<br/>
                        whenever you need. If you have a customer list already, import it<br/>
                        here by clicking on the button below.</span>}
          textButton={'Import customers'}
          gifPath={'41234-contact-info.gif'}
          helpText={'Need help?'}
          linkHelpText={'Text us'}
          setCustomerManually={setCustomerManually}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar: state.showSideBar,
  }
}
export default connect(mapStateToProps, {})(CustomerLandingPage)