import React, {Component} from 'react'
import {
  STATUS_SOLD,
  VISIBILITY_HIDE,
  VISIBILITY_SHOW,
  CAR_STATUS_CODE_FRONT_LINE_READY,
  CAR_STATUS_CODE_INSPECT_REPAIR,
  DEFAULT_TASK,
  DEFAULT_REPAIR,
  carStatusColors,
  carStatus,
  CAR_FIELDS_NAME
} from '../../Constants'
import {DragSource} from 'react-dnd'
import Popover from 'material-ui/Popover'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import {ReactSVG} from 'react-svg'
import {updateCarAttributeValue, getServerUrl, saveTaskAndRepair} from '../../Api'
import ModalConfirmDeactivate from '../util/ModalConfirmDeactivate'
import {pathServer, CAR_IMAGE_COMING_SOON, WIDTH_VIEW_MOBILE, SUCCESSFUL_CODE} from '../../../common/Constants'
import {getFormatNumber, getImageUrlWithTime, modalChangeCarStatus} from '../util/Utils'

let photoSource = {
  beginDrag: function (props) {
    props.onDragEvent(true);
    return {currentOrder: props.release.order};
  },

  endDrag: function (props, monitor, component) {
    props.onDragEvent(false);
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class InventoryPhotoDraggable extends Component {

  constructor() {
    super();
    this.state = {
      release: {},
      showOptions: false,
      showLoader: false,
      showUpdatedMessage: false,
      showMileageAndPrice: true,
      valuesToCarStatus: [],
      widthPage: window.innerWidth,
      showStatusChangeModal: false,
      isChangeStatusCarBlocked: false,
      changeStatusTo: null,
      linkedSaleId: null,
      changeStatusFrom: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleHover();
  }

  componentWillMount() {
    const{attributeValuesToCarStatus} = this.props;
    const values =[];
    this.getData(this.props);
    if(attributeValuesToCarStatus){
      attributeValuesToCarStatus.forEach((attribute,index) => {
        const color = carStatusColors.find(value => { return value.id === attribute.code});
        if(color){
          values.push({id:attribute.id,dealerId:attribute.dealerId,attributeId:attribute.attributeId,
            code:attribute.code,name:attribute.name,displayValue:attribute.displayValue,order:attribute.order,
            modifiedDate:attribute.modifiedDate,createDate:attribute.createDate,color:color.value});
        }
      });
    }
    this.setState({valuesToCarStatus: values});
  }

  componentDidUpdate() {
    this.handleHover();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({widthPage: window.innerWidth});
  }

  componentWillReceiveProps(nextProps) {
    this.getData(nextProps);
  }

  getData(props) {
    this.setState({release: props.release});
  }


  edit = (release) => {
    if (release.active)
      this.props.edit(release.vin)
  }

  deactivate = (vin) => {
    this.props.deactivate(vin)
  }

  activate = (vin, carId) => {
    this.props.activate(vin,carId)
  }

  delete = (carId, vin) => {
    this.props.delete(carId,vin);
  }

  handleRequestOpen = (evt) => {
    this.setState({anchorEl: evt.currentTarget, showOptions: true});
  }

  handleRequestClose = () => {
    this.setState({showOptions: false})
  }

  handleCarStatusChange = (value, blocked) => {
    const { release } = this.props
    const keyStatus = CAR_FIELDS_NAME.TITLE_STATUS
    if (release[keyStatus] && release[keyStatus].attributeValue === value)
      this.setState({showOptions: false})
    else{
      if(!release[keyStatus] || release[keyStatus].attributeValue !== carStatus.SOLD)
        this.handleSelectChange(value, blocked)
      else
        this.updateFromSoldToAnyState(release, release[keyStatus].attributeValue, value, blocked)
    }
  }

  updateFromSoldToAnyState = (car, actualState, newState, blocked) => {
    this.setState({
        showOptions: false,
        isChangeStatusCarBlocked: blocked,
        changeStatusFrom: actualState,
        changeStatusTo: newState,
        linkedSaleId: car.saleId
      }, () => this.toggleFromSoldToOtherStatusModal()
    )
  }

  toggleFromSoldToOtherStatusModal = () => {
    const {showStatusChangeModal} = this.state
    this.setState({showStatusChangeModal: !showStatusChangeModal})
  }

  handleSelectChange(value, blocked) {
    let {release, attributeValuesToCarStatus, updateCarsAfterChangeStatus} = this.props;
    const {widthPage} = this.state;
    const keyStatus = CAR_FIELDS_NAME.TITLE_STATUS
    let infoStatus;
    const actualStatus = !release[keyStatus] ? "Status" : release[keyStatus].attributeValue
    if (blocked && widthPage < 1025) {
      this.showMessage(release.id)
    } else {
      if(!release[keyStatus]){
        infoStatus = { attributeCode: keyStatus, attributeValue: 'Status' }
        release[keyStatus] = infoStatus
      } else {
        infoStatus = release[keyStatus];
      }
      if (release[keyStatus] && release[keyStatus].attributeValue !== value) {
        infoStatus.attributeValue = value;
        this.setState({showOptions: false, showLoader: true}, () => {
          updateCarAttributeValue({carId: release.id, infoAttributes: [infoStatus]}).then(({responseCode, responseMessage}) => {
            if (responseCode !== SUCCESSFUL_CODE) {
              console.error(responseMessage)
              return
            }

            this.setState({showLoader: false, showUpdatedMessage: true, showMileageAndPrice: false});

            let repairInspectStatus = attributeValuesToCarStatus.find(x => x.code === CAR_STATUS_CODE_INSPECT_REPAIR);
            if (release[keyStatus].attributeValue === repairInspectStatus.displayValue) {
              let carTitle = release.makeName + " " + release.modelName + " " + release.year.toString();
              let task = DEFAULT_TASK;
              let repair = DEFAULT_REPAIR;
              task.title = carTitle;
              repair.description = carTitle;
              repair.carId = release.id;
              saveTaskAndRepair({task: task, repair: repair}).then(({responseCode, data, responseMessage}) => {
                if (responseCode === SUCCESSFUL_CODE) {
                  this.props.handleUpdateToInspectRepair(data.taskRepairId, actualStatus, value)
                } else {
                  console.log(responseMessage)
                }
              })
            }
            updateCarsAfterChangeStatus()
          });
        });
      } else {
        this.setState({showOptions: false});
      }

      setTimeout(() => {
        this.setState({showUpdatedMessage: false, showMileageAndPrice: true});
      }, 3000)
    }
  }

  handleHover() {
    const {release} = this.props;
    let el = document.getElementById("flr-" + release.id);
    let titleSuggestion = document.getElementById("titleSuggestion-" + release.id);

    $(el).hover(function () {
      titleSuggestion.style.display = "flex";
      el.style.backgroundColor = "#0000001a";
    }, function () {
      titleSuggestion.style.display = "none";
      el.style.backgroundColor = "white";
    })
  }

  showMessage(id) {
    const {release} = this.props;

    let titleSuggestion = document.getElementById("titleSuggestion-" + id);
    let el = document.getElementById("flr-" + release.id);
    titleSuggestion.style.display = "flex";
    el.style.backgroundColor = "#0000001a";
    setTimeout(function () {
      titleSuggestion.style.display = "none";
      el.style.backgroundColor = "white";
    }, 3000)
  }

  goToSale = (saleId) => {
    const url = `${getServerUrl()}main/sale/${saleId}`
    window.open(url, "_blank")
  }

  render() {
    const styles = {
      opacity: this.props.opacity
    };

    const stylesInactive = {
      backgroundColor: "#DEDEDE",
      opacity: 0.8
    };

    const {showOptions, anchorEl, showLoader, showUpdatedMessage, showMileageAndPrice,
      valuesToCarStatus, widthPage, isChangeStatusCarBlocked, changeStatusTo, showStatusChangeModal,
      linkedSaleId,changeStatusFrom} = this.state;

    let {connectDragSource, width, height, release, attributeValuesToCarStatus, isUpdatingToRepairStatus, newTaskRepairId} = this.props;

    const status = release["car.status"] ? release["car.status"].attributeValue : "";
    const carName = `${release.year} ${release.makeName} ${release.modelName}`;
    let mileageAndPrice = "$" + getFormatNumber(release.suggestedPrice) + " " + "|" + " " +
      Math.round(Math.round((release.mileage / 1000) * 100) / 100) + "k" + " miles";

    const optionFound = attributeValuesToCarStatus.find(option => {
      return option.displayValue.toLowerCase() === status.toLowerCase()
    });
    let colorStatusSelected = "";

    if (optionFound) {
      const valueColor = valuesToCarStatus.find(value => value.displayValue === optionFound.displayValue);
      if (valueColor)
        colorStatusSelected = valueColor.color;
    }

    const bodyMessageUpdateCarStatus = modalChangeCarStatus(changeStatusFrom, changeStatusTo, linkedSaleId)

    let bodyMessageUpdateInspectRepair = null

    if(isUpdatingToRepairStatus)
      bodyMessageUpdateInspectRepair = modalChangeCarStatus(changeStatusFrom, changeStatusTo, null,newTaskRepairId)

    return connectDragSource(
      <div ref={el => this.carImageColumn = el}
           className={showUpdatedMessage ? "car-image-column-no-hover" : "car-image-column"} style={release.active ? styles: stylesInactive}
           key={release.id}>
        <div id="borderTop" className="border-top" style={{borderTop: "3px " + colorStatusSelected + " solid"}}/>
        <div className="info-car" style={!release.active ? {backgroundColor: "#DEDEDE"} : {}}>
          <div className="label-car-information">{carName}</div>
        </div>
        <div className="data-mileage-car">{mileageAndPrice}</div>
        <div id="mainCarImage" className="main-car-image" style={{height: height,opacity:release.active ? 1 : 0.5}}>
          {
            (release.mainImage === '' || !release.mainImage) ?
              <img src={CAR_IMAGE_COMING_SOON}
                   className="image-object-fit"
                   onClick={() => this.edit(release)}/>
              :
              <img src={getImageUrlWithTime(release.mainImage)}
                   className="image-object-fit"
                   onClick={() => this.edit(release)}/>
          }
          {
            (status.toLocaleUpperCase() === STATUS_SOLD) ?
              <div>
                {
                  /*<div className="text-sold-main">{LABEL_SOLD}</div>*/
                }
              </div> : null
          }
        </div>
        <div className="bottom-car" style={!release.active ? stylesInactive : {}}>
          <div className="select">
            {
              showLoader ?
                <div className="loader-container">
                  <div className="loader"/>
                </div>
                :
                <div className={'btn-drop-down-container'} onClick={release.active ? this.handleRequestOpen : () => {}}>
                  <div className={'btn-drop-down'}>
                    <label id="labelValue" style={{color: colorStatusSelected}}
                      className="label-value">{optionFound ? optionFound.displayValue : "Status"}</label>
                    {
                      showOptions ?
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                  className="icon-arrow-content"
                                  beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                  className="icon-arrow-content"
                                  beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
                    }
                  </div>
                </div>
            }
          </div>
          {
            release.active?
              <div className="options">
                {
                  window.innerWidth > WIDTH_VIEW_MOBILE &&
                  <ReactSVG src={pathServer.PATH_IMG + "icon/eye-view.svg"}
                            beforeInjection={svg => { svg.classList.add("ic-deactivate")}}
                            onClick={() => this.deactivate(release.vin)}
                            className="icon-option"/>
                }
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                            beforeInjection={svg => { svg.classList.add("ic-delete")}}
                            onClick={() => this.delete(release.id, release.vin)}
                            className="icon-option"
                />
                {
                  !!release.saleId &&
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_link_sale.svg"}
                          beforeInjection={svg => { svg.classList.add("ic-link-sale")}}
                          onClick={() => this.goToSale(release.saleId)}
                          className="icon-option"/>
                }

              </div>:
              <div className="options">
                <ReactSVG src={pathServer.PATH_IMG + "icon/eye-view.svg"}
                          beforeInjection={svg => { svg.classList.add("ic-deactivate")}}
                          onClick={() => this.activate(release.vin, release.id)}
                          className="icon-option"/>
              </div>
          }

          {
            showUpdatedMessage ?
              <div className="message-status">
                <label>Updated successfully</label>
              </div> : null
          }
        </div>
        {
          showOptions ?
            <Popover open={showOptions}
                     anchorEl={anchorEl}
                     anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                     targetOrigin={{horizontal: 'left', vertical: 'top'}}
                     onRequestClose={this.handleRequestClose}
                     className="Popover-status-inventory-photo">
              <Menu>
                {
                  valuesToCarStatus.map((op) => {
                    let blocked = (op.code === CAR_STATUS_CODE_FRONT_LINE_READY && release.suggestedPrice === 0)
                    return (
                      <MenuItem key={op.id} id={`${op.code}-${release.id}`} className={blocked ? "block-status" : ""}
                                primaryText={op.displayValue} style={{
                        minHeight: 30,
                        height: 34,
                        fontSize: 14,
                        lineHeight: "34px",
                        color: '#4D4F5C',
                        fontWeight: "400",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                                rightIcon={blocked ?
                                  <div className="container-icon-exclamation">
                                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"}
                                              beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                                              className="icon-error"/>
                                    <div className="title-suggestion" id={`titleSuggestion-${release.id}`}>
                                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"}
                                                beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                                                className="icon-error"/>
                                      <label>Suggested price is required</label>
                                    </div>
                                  </div> : null}
                                onClick={() => this.handleCarStatusChange(op.displayValue, blocked)}
                                disabled={(widthPage < 1025) ? false : blocked}/>
                    )
                  })
                }
              </Menu>
            </Popover> : null
        }
        <ModalConfirmDeactivate show={showStatusChangeModal}
                                onSubmit={() => this.handleSelectChange(changeStatusTo, isChangeStatusCarBlocked)}
                                onHide={this.toggleFromSoldToOtherStatusModal}
                                showButton={!!!linkedSaleId}
                                isBodyCustom={showStatusChangeModal}
                                bodyMessage={bodyMessageUpdateCarStatus}
                                message={null}
                                iconQuestion={!!linkedSaleId ? "icon/ic_warning.svg": "icon/ic-update-inventory-status.svg"}
                                classButtonDelete="btn-delete"
                                buttonLabelDelete="Confirm action"
                                viewMobile={false} />
      </div>
    );

  }

  onFormChange = (car, data) => {
    if (typeof car["car.status"] === 'object' || typeof car["car.show"] === 'object') {
      if (typeof data["car.status"] !== 'undefined' && car["car.status"].attributeValue !== data["car.status"]) {
        car["car.status"].attributeValue = data["car.status"];
        if (data["car.status"].toLocaleUpperCase() === STATUS_SOLD) {
          car["car.show"].attributeValue = VISIBILITY_HIDE;
          updateCarAttributeValue({
            carId: car.id,
            infoAttributes: [car["car.status"], car["car.show"]]
          }).then(({responseCode, responseMessage}) => {
            if (responseCode !== SUCCESSFUL_CODE) {
              console.error(responseMessage)
              return
            }

            this.props.search({});
          });
        } else if (data["car.status"].toLocaleUpperCase() !== STATUS_SOLD && car["car.show"] !== VISIBILITY_SHOW) {
          car["car.show"].attributeValue = VISIBILITY_SHOW;
          updateCarAttributeValue({
            carId: car.id,
            infoAttributes: [car["car.status"], car["car.show"]]
          }).then(({responseCode, responseMessage}) => {
            if (responseCode !== SUCCESSFUL_CODE) {
              console.error(responseMessage)
              return
            }

            this.props.search({});
          });
        } else {
          updateCarAttributeValue({carId: car.id, infoAttributes: [car["car.status"]]})
              .then(({responseCode, responseMessage}) => {
                if (responseCode !== SUCCESSFUL_CODE) {
                  console.error(responseMessage)
                  return
                }

                this.props.search({});
              });
        }

      }
      if (typeof data["car.show"] !== 'undefined' && car["car.show"].attributeValue !== data["car.show"]) {
        car["car.show"].attributeValue = data["car.show"];
        updateCarAttributeValue({carId: car.id, infoAttributes: [car["car.show"]]})
            .then(({responseCode, responseMessage}) => {
              if (responseCode !== SUCCESSFUL_CODE) {
                console.error(responseMessage)
                return
              }

              this.props.search({});
            });
      }
      this.setState({release: car});
    }
  }

}

export default DragSource('inventoryPhoto', photoSource, collect)(InventoryPhotoDraggable);