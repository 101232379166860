import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import moment from 'moment'
import {isEmpty, clone} from 'lodash'
import {capitalFirstLetter} from '../../util/Utils'
import {findIndex} from 'lodash'
import {DPPillBox} from '../../util/ComponentUtils'
import {checkValidEmail} from '../../../../common/ValidationUtil'
import {pathServer} from '../../../../common/Constants'

export default class UserInfo extends Component {
  constructor() {
    super();
    this.showPanelEdit      = this.showPanelEdit.bind(this);
    this.renderPermission   = this.renderPermission.bind(this);
    this.closeModal         = this.closeModal.bind(this);
    this.showPermissions    = this.showPermissions.bind(this);
    this.modeEdit           = this.modeEdit.bind(this);

    this.state = {
      showEditActivity : true,
      showEditRole: false,
      recentActivity:[
        {id:"1", date : "Feb 04", underline: "Mustang 2015" , description : "marked as SOLD"},
        {id:"2", date : "Jan 31", underline: "" , description : "New user added: Luis Fonsi"},
        {id:"3", date : "Jan 29", underline: "Honda Civic Coupe 2018 " , description : "marked as SOLD"},
        {id:"4", date : "Jan 27", underline: "" , description : "New Repair added to Accord 2016"},
      ],
      permissions:[
        {id:"1", name: "Permission 1"},
        {id:"2", name: "Permission 2"},
        {id:"3", name: "Permission 3"},
        {id:"4", name: "Permission 4"},
        {id:"5", name: "Permission 5"},
        {id:"6", name: "Permission 6"},
      ],
      userSelected: null,
      optionsFilter: [],
      sections : {}
    }
  }

  componentWillMount() {
    let {userSelected, photo,optionsFilter} = this.props;
    let {sections} = this.state;
    if(userSelected){
      userSelected.roles.map((role) => {
        sections[role] = false;
      });
      this.setState({optionsFilter,sections})
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let {userSelected, photo} = nextProps;
    this.setState({userSelected: userSelected,photo: photo})
  }

  showPanelEdit(type){
    let showEditActivity,showEditRole = false;
    if(type === "activity"){
      showEditActivity = true;
      showEditRole = false;
    }else{
      showEditRole = true;
      showEditActivity = false;
    }
    this.setState({showEditActivity,showEditRole});
  }

  renderPermission(){
    const {permissions} = this.state;
    return(
      <ul>
        {permissions.map(function (permission,index) {
            return (
              <li key={index}><label>{permission.name}</label></li>
            );
          })}
      </ul>
    );
  }

  renderButton(type){
    if(type === "add"){
      return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.504 63.504">
          <path id="ic_add_24px" d="M68.5,41.288H41.288V68.5H32.216V41.288H5V32.216H32.216V5h9.072V32.216H68.5Z" transform="translate(-5 -5)"/>
        </svg>
      )
    } else if( type === "less"){
      return (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus"
             className="svg-inline--fa fa-minus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 448 512">
          <path fill="currentColor"
                d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
        </svg>
      )
    }
  }

  closeHeaderFilter(){
    this.setState({showPopoverRolesModal: false});
  }

  stateRolModal(){
    let {showPopoverRolesModal} = this.state;
    this.setState({showPopoverRolesModal: !showPopoverRolesModal});
  }

  handleCheck(e){
    let {updateUser, optionsFilter} = this.state;
    let rolesTemp = updateUser.roles;
    optionsFilter.map((op) => {
      if(op.id === e.target.id){
        op.checked = !op.checked;
        if(op.checked) {
          rolesTemp.push(op.id);
        }
        else{
          let index = rolesTemp.findIndex(row => row === op.id);
          rolesTemp.splice(parseInt(index), 1);
        }
      }
    });
    updateUser.roles = JSON.parse(JSON.stringify(rolesTemp));
    this.setState({updateUser: JSON.parse(JSON.stringify(updateUser)), optionsFilter: optionsFilter})
  }

  handleChange(e) {
    let {updateUser} = this.state;
    if (e.target.name === "email"){
      if(!checkValidEmail(e.target.value)){
        this.setState({errorEmail: true})
      } else {
        updateUser[e.target.name] = JSON.parse(JSON.stringify(e.target.value));
        this.setState({ updateUser: JSON.parse(JSON.stringify(updateUser)), errorEmail: false });
      }
    } else {
      updateUser[e.target.name] = JSON.parse(JSON.stringify(e.target.value));
      this.setState({ updateUser: JSON.parse(JSON.stringify(updateUser))});
    }
  }

  renderAllRoles() {
    let {updateUser, optionsFilter} = this.state;
    let values = updateUser.roles;
    let options = [];

    optionsFilter.map((role, index) => {
      const checked = findIndex(values, (nameRol) => {
        return nameRol === role.id;
      });
      if (role.value !== "clear") {
        options.push(
          <div key={index} id="id-content-check" className={"content-check"}>
            <input type="checkbox" key={index} id={role.id} name={role.value} onChange={this.handleCheck}
                   defaultChecked={(checked !== -1)}/>
            <p style={{color: "#a5a5a5", fontSize: 13, fontWeight: "400", paddingLeft: "11px"}}>{role.displayValue}</p>
          </div>
        )
      }
    });
    return (
      <div className="popover-body-status">
        <div id="" className="options-status">
          {options}
        </div>
      </div>
    )
  }

  onDrop(images) {
    if (images){
      this.setState({photo: images[0]});
    }
  }

  closeModal(modal){
    this.props.closeModal(modal);
  }

  showPermissions(op){
    let {sections} = this.state;
    const {userSelected} = this.props;
    if(sections[op]){
      sections[op] = false;
    }else{
      userSelected.roles.map(roleSelected =>{
        sections[roleSelected.id] = roleSelected.id === op;
      })
    }
    this.setState({sections});
  }

  modeEdit(userSelected,e){
    this.props.openEdit(userSelected,e);
  }

  render() {
    let {showEditActivity,showEditRole, optionsFilter, sections} = this.state;
    let {userSelected, showModal} = this.props;
    if(!userSelected) return null;
    let roles="";
    let filterOp = clone(userSelected.roles);
    if (userSelected!==[] && userSelected && userSelected.roles && userSelected.roles!==[]){
      optionsFilter.map(op=> {
        userSelected.roles.map((rol)=>{
          if (op.id === rol.id) {
            if (roles==="")
              roles = "" + op.displayValue;
            else
              roles = roles + " - " + op.displayValue;
          }
        })
      })
    }
    const date = moment(new Date(userSelected.createDate)).format('LL');
    const {recentActivity} = this.state;
    return (
      <div>
        <Modal show={showModal} className="user-modal-container" backdropClassName="backdrop-car">
          <Modal.Body>
            <div className="user-modal">
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon" onClick={this.closeModal.bind(this,"showModalInfo")}/>
              <div className="user-modal-body user-info-body">
                <div className="float-left seccion-1">
                  <div className="user-modal-image">
                    <img className="photo-user-img-circle" src={userSelected.image ? userSelected.image : pathServer.PATH_IMG + "ic_account_circle.png"}/>
                  </div>
                  <div className="user-modal-text">
                    <div className="user-name">
                      <label className="title-main">{userSelected.firstName} {userSelected.lastName}</label> <br/>
                      {
                        userSelected.accountOwner ?
                          <DPPillBox displayValue='ACCOUNT OWNER' /> : null
                      }
                      {
                        !userSelected.accountOwner ?
                          <label className="title-secondary">{roles}</label> : null
                      }
                    </div>
                    <div>
                      {
                        !isEmpty(userSelected.urlFacebook) || !isEmpty(userSelected.urlInstagram) || !isEmpty(userSelected.urlLinkedin) ?
                            <div className="row">
                              <ul  className="user-info-social-icon">
                                {(userSelected.urlFacebook || userSelected.urlFacebook!=="")?(
                                    <li style={{padding: "0 5px"}}>
                                      <a title="Facebook" href={userSelected.urlFacebook} target="_blank">
                                        <i className="fa fa fa-facebook-f" style={{color: "rgb(22,127,247)"}}/>
                                      </a>
                                    </li>
                                ):null}
                                {(userSelected.urlInstagram || userSelected.urlInstagram!=="")?(
                                    <li style={{padding: "0 5px", color: "red"}}>
                                      <a title="twitter" href={userSelected.urlInstagram} target="_blank">
                                        <i className="fa fa fa-instagram" style={{color: "#e741df"}}/>
                                      </a>
                                    </li>
                                ):null}
                                {(userSelected.urlLinkedin || userSelected.urlLinkedin!=="")?(
                                    <li style={{padding: "0 5px"}}>
                                      <a title="rss-feed" href={userSelected.urlLinkedin} target="_blank">
                                        <i className="fa fa fa-linkedin" style={{color: "rgb(114,191,254)"}}/>
                                      </a>
                                    </li>
                                ):null}
                              </ul>
                            </div> : null
                      }
                    </div>
                    <div className="user-detail">
                      <h6>{userSelected.email !== "" ? userSelected.email : "No email yet"}<br/>
                        {userSelected.phoneNumber !== "" ? userSelected.phoneNumber : "No phone number yet"}<br/>
                        {userSelected.address !== "" ? userSelected.address : "No address yet"}<br/>
                        {`User since ${date}`}
                      </h6>
                    </div>
                    <div className="user-modal-button">
                      <a className="btn" onClick={(e) => this.modeEdit(userSelected,e)}>
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
                <div className="float-right seccion-2">
                  <div className="user-options">
                    <a onClick={() => this.showPanelEdit("activity")} className={showEditActivity ? "selected" : null}
                       href="#">Recent Activity</a>
                    <a onClick={() => this.showPanelEdit("role")} className={showEditRole ? "selected" : null}
                       href="#">Roles</a>
                  </div>
                  {showEditActivity ?
                    <div className="user-description">
                      <ul>
                        {recentActivity.map(function (activity, index) {
                          return (
                            <li key={index}>
                              <b>{activity.date}</b> <label> <em>{activity.underline}</em> {activity.description}
                            </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    : null}
                  {showEditRole ?
                    <div className="user-permission">
                      {
                        filterOp.map((roleSelected, index) => {
                          return (
                            <div key={index} className="option">
                              <div className="header-section">
                                <ReactSVG id={`circle${index}`} src={ sections[roleSelected.id] ? pathServer.PATH_IMG + "icon/ic_circle_less2.svg" :
                                    pathServer.PATH_IMG + "icon/plus_circle_solid.svg" } beforeInjection={ svg => svg.classList.add('circle-header')}
                                          className="circe-class" onClick={() => this.showPermissions(roleSelected.id)}/>
                                <b className="title-main"> {capitalFirstLetter(roleSelected.displayValue)} permissions</b>
                              </div>
                              {(sections[roleSelected.id]) ?
                                this.renderPermission() : null}
                            </div>)
                        })
                      }
                    </div> : null
                  }
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}