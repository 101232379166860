import React, {Component} from 'react'
import {find, eachRight, forEach, sortBy, slice, groupBy} from 'lodash'
import InventoryPhotoDropTarget from './InventoryPhotoDropTarget.jsx'
import {updateOrderCar} from '../../Api.js'
import {connect} from 'react-redux'
import {getFilterCar} from '../util/Utils'
import {SUCCESSFUL_CODE} from "../../../common/Constants";

const IMAGE_SIZE = 230;
let SCALE_FACTOR = 1.338;
let IMAGE_MARGIN = 5;
let IMAGE_HEIGHT_PERCENT = 0.66;

function updateOrderImages(cars, oldOrder, newOrder) {
  cars = sortByOrder(cars);
  let imageToMove = find(cars, function(car) {
    return car.order === oldOrder;
  });
  if (oldOrder < newOrder) {
    eachRight(cars, function(car, index) {
      let orderOfCurrent = car.order;
      if (oldOrder < orderOfCurrent && orderOfCurrent <= newOrder) {
        car.order = cars[index - 1].order;
      }
    });
  } else if (oldOrder > newOrder) {
    forEach(cars, function(car, index) {
      let orderOfCurrent = car.order;
      if (newOrder <= orderOfCurrent && orderOfCurrent < oldOrder) {
        car.order = cars[index + 1].order;
      }
    });
  }
  imageToMove.order = newOrder;
  return cars;
}

function sortByOrder(cars) {
  return sortBy(cars, function(car) {
    return car.order;
  });
}

class InventoryPhotoGrid extends Component {

  constructor() {
    super();
    this.state = {
      cars:[],
      isDragging: false,
      viewportWidth: 0,
      currentImage: null,
      errors: [],
      message: '',
      groupCar: 4,
      vin: null,
      makes:[],
      models:[]
    };
  }

  componentDidMount() {
    this.setState({viewportWidth: this.getViewportWidth()});
    window.addEventListener('resize', () => {
      this.setState({viewportWidth: this.getViewportWidth()});
      this.handleResize()
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      this.setState({viewportWidth: this.getViewportWidth()});
      this.handleResize()
    })
  }

  getViewportWidth() {
    let photoList = document.querySelector("#photoList");
    if(photoList)
    return photoList.offsetWidth;
  }

  calcPhotosPerRow() {
    let fullWidth = (IMAGE_SIZE * SCALE_FACTOR) + (IMAGE_MARGIN * 2);
    return Math.floor(this.state.viewportWidth / fullWidth)
  }

  renderRow(index, groupCar, cars) {
    let imagesSlice = slice(cars, index * groupCar, (cars.length > index * groupCar + groupCar) ? index * groupCar + groupCar : cars.length);
    let items = imagesSlice.map(this.renderPhoto);
    return (
      <div key={index} className="col-sm-12" style={{padding:0,paddingBottom:20}}>
        {items}
      </div>
    )
  }

  onHover = (initialOrder, targetOrder) => {
    const {cars,search} = this.props;
    this.setState({cars: updateOrderImages(cars, initialOrder, targetOrder)},() => {
      const {cars} = this.state;
      updateOrderCar({cars:cars}).then(({responseCode, responseMessage}) =>{
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
          return
        }

        search({});
      })
    });
  }

  onDragEvent = (isDragging) => {
    this.setState({isDragging: isDragging});
  }
  handleResize(){
    let widthPage = window.innerWidth;
    if(widthPage > 768){
      this.setState({groupCar: 4});
    }else if(widthPage < 769 && widthPage > 380 ){
      this.setState({groupCar: 2});
    }else if(widthPage < 381 ){
      this.setState({groupCar: 1});
    }
  }


  renderPhoto = (release) => {
    const {search,edit,active,query,makes,models,attributeValuesToCarStatus,updateCarsAfterChangeStatus, handleUpdateToInspectRepair} = this.props;
    const {isDragging,viewportWidth} = this.state;
    let width = null;
    //let imageContainer = '';
    if(window.innerWidth >768){
      width = Math.floor((viewportWidth - 60) / 4);
    }else {
      let imageContainer = document.getElementById('mainCarImage')
      if(imageContainer)
      width = imageContainer.offsetWidth
    }

    let height = Math.floor(width * IMAGE_HEIGHT_PERCENT);

    let makeFind  = makes.filter(make => {return make.id === release.makeId});
    let modelFind = models.filter(model => {return model.id === release.modelId});

    release.makeName    = makeFind.length > 0 ? makeFind[0].name:'';
    release.modelName   = modelFind.length > 0 ? modelFind[0].name:'';

    return (<InventoryPhotoDropTarget key={release.id}
                                      release={release}
                                      onHover={this.onHover}
                                      isDragging={isDragging}
                                      onDragEvent={this.onDragEvent}
                                      width={width}
                                      height={height}
                                      search={search}
                                      edit={edit}
                                      delete={this.props.delete}
                                      deactivate={this.props.deactivate}
                                      activate={this.props.activate}
                                      //active={active}
                                      query={query}
                                      attributeValuesToCarStatus={attributeValuesToCarStatus}
                                      updateCarsAfterChangeStatus={updateCarsAfterChangeStatus}
                                      handleUpdateToInspectRepair={handleUpdateToInspectRepair}
            />)
  }

  sortByStatus = (cars, groupCar, message, totalVehiclesByStatus) => {
    const statusGroup =  groupBy(cars,"status")
    const carsByStatus = []
    for (let key in statusGroup) {
      carsByStatus.push(
        <div key={key}>
          <div className="header-status">
            <div style={{backgroundColor: getFilterCar(key).color}} className="vertical-bar"/>
            <span className="title-status">{`${key}   (${statusGroup[key].length}/${totalVehiclesByStatus[key] || "0"})`}
            </span>
          </div>
          <div className="container-cars-grid">
            <div id="photoList" className="photoList">
              {message ? (<div className="alert alert-success">{message}</div>) : ''}
              {this.renderImagesElements(statusGroup[key])}
            </div>
          </div>
        </div>
      )
    }
    return carsByStatus
  }

  renderImagesElements = (cars) => {
    let imageElements = [];
    const {groupCar} = this.state;
    for (let i = 0, j = 0; i < cars.length; i += groupCar, j++) {
      imageElements.push(
        <div key={j} >
          {this.renderRow(j, groupCar, cars)}
        </div>
      );
    }
    return imageElements
  }
  render() {
    const {cars,makes,models,query, totalVehiclesByStatus} = this.props;
    if (!cars || !makes || !models)
      return (<div id="photoList"/>);

    let imageElements = [];
    const {groupCar,message} = this.state;
    for (let i = 0, j = 0; i < cars.length; i += groupCar, j++) {
      imageElements.push(
        <div key={j} >
          {this.renderRow(j, groupCar, cars)}
        </div>
      );
    }

    return  this.sortByStatus(cars, imageElements, message, totalVehiclesByStatus)

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    makes:state.makesAll,
    models:state.modelsAll,
    attributeValuesToCarStatus:state.attributeValuesToCarStatus
  }
};

export default connect(mapStateToProps,{})(InventoryPhotoGrid)