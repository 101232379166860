import React, {Component} from 'react'
import {ENTITY_TYPES, FIELD_READ_ONLY, FIELD_WITH_SYMBOL, INPUT_TYPES} from '../../../Constants.js'
import Outside from '../../util/Outside.jsx'
import {FIELD_VALIDATION, FIELD_NEGATIVE, InputTypes} from '../../../Constants'
import {VALIDATION_REQUIRED} from '../../../../common/Constants'

function getValidationRequiredMessage(validation) {
  let message = "";
  if(validation){
    const validationRequired = validation.find(val => { return val.type === VALIDATION_REQUIRED});
    if(validationRequired)
      message = validationRequired.message;
  }
  return message;
}

function getValidationRequiredCheck(validation) {
  let isChecked = false;
  if(validation){
    const validationRequired = validation.find(val => { return val.type === VALIDATION_REQUIRED});
    if(validationRequired)
      isChecked = true;
  }
  return isChecked;
}

function getValidationInitial(field) {
  return {type: VALIDATION_REQUIRED, message: `${field} is required`}
}


const AttributeField =  ({value, displayValue, updateCheck, fieldName }) => {
  return(
    <div className="attribute-field attribute-name-form-new">
      <div className="content-check">
        <label className="container-check">
          <input type="checkbox" defaultChecked={value} onClick={() => updateCheck(fieldName)}/>
          <span className="checkmark" style={{border: "1.4px #4695d4 solid"}}/>
          {
            value && <i className="fa fa-check i-check"/>
          }
        </label>
        <label className="label-check-image-main">{displayValue}</label>
      </div>
    </div>
  )
}

export default class ModalNewAttributeProperties extends Component {

  constructor() {
    super();
    this.onChangeSetting = this.onChangeSetting.bind(this);
    this.onChangeDropDownForm = this.onChangeDropDownForm.bind(this);
    this.onSelectDropDown = this.onSelectDropDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      checkSettingOne: true,
      checkSettingTwo: true,
      showCommonFields: false,
      showAttributeOptions: false,
      nameAttributeOptions: '',
      attribute: {
        code: "",
        name: "",
        displayValue: "",
        typeAttribute: "",
        entityTypes: []
      },
      dropDownTypeAttribute: {
        render: false,
        values: INPUT_TYPES
      },
      dropDownAudience: {
        render: false,
        values: ENTITY_TYPES
      },
      menuTabSelected: 'attributes'
    }
  }

  componentWillMount() {
    let {attribute} = this.props;
    if (attribute) {
      this.setState({attribute: attribute})
    }
  }

  componentWillReceiveProps(nextProps) {
    let {attribute} = nextProps;
    if (attribute) {
      this.setState({attribute: attribute})
    }
  }

  onChangeDropDownForm(dropDown) {
    let dropDownForm = this.state[dropDown];
    dropDownForm.render = !dropDownForm.render;
    this.setState({[dropDown]: dropDownForm})
  }

  closePopover(dropDown) {
    let dropDownClose = this.state[dropDown];
    dropDownClose.render = false;
    this.setState({[dropDown]: dropDownClose})
  }

  onSelectDropDown(dropDown, type, value) {
    let {attribute} = this.state;
    if (type === "typeAttribute") {
      attribute[type] = value;
    } else {
      attribute[type] = [value];
    }
    let that = this;
    this.setState({attribute: attribute}, () => {
      that.onChangeDropDownForm(dropDown)
    })
  }

  handleChange(event) {
    let {attribute,fieldToUpdate} = this.state;
    const attributeEdit = {...attribute};
    attributeEdit[event.target.name] = event.target.value;
    this.setState({attribute: attributeEdit});
    this.props.updateAttribute(attributeEdit,fieldToUpdate);
  }

  onChangeSetting(type) {
    const value = this.state[type];
    this.setState({[type]: !value})
  }

  handleValidationChange = (event) => {
    const {attribute} = this.state;
    const attributeEdit = {...attribute};
    const indexFound = attributeEdit.validation.findIndex(val => { return val.type === VALIDATION_REQUIRED });
    if(indexFound !== -1){
      attributeEdit.validation[indexFound][event.target.name] = event.target.value;
      this.setState({attribute: attributeEdit});
      this.props.updateAttribute(attributeEdit,FIELD_VALIDATION);
    }
  };

  onCheck = () => {
    //evt.stopPropagation();
    const {attribute} = this.state;
    const attributeEdit = {...attribute};
    if(attributeEdit.validation && attributeEdit.validation.length > 0){
      attributeEdit.validation = [];
    }else{
      attributeEdit.validation = [getValidationInitial(attributeEdit.displayValue)];
    }
    this.setState({attribute: attributeEdit});
    this.props.updateAttribute(attributeEdit);
  };

  updateOnCheckAttributes = (key) => {
    const {attribute} = this.state;
    const attributeEdit = {...attribute};
    attributeEdit[key] = !(attributeEdit[key] && attributeEdit[key] === true);
    this.setState({attribute: attributeEdit})
    this.props.updateAttribute(attributeEdit)
  }

  render() {
    const {attribute,dropDownAudience} = this.state;
    let {form, disabled} = this.props;
    const isChecked =  getValidationRequiredCheck(attribute.validation);
    const readOnly = attribute.readOnly ? attribute.readOnly : false
    const withSymbol = attribute.withSymbol? attribute.withSymbol : false
    const negative = attribute.negative ? attribute.negative : false

    return (
      <div className="form-property form-modal-new-attribute" >
        <div className="row-form" style={{width: "100%"}}>
          <div className="attribute-field attribute-name-form-new">
            <span className="text-attribute-option">ATTRIBUTE NAME</span>
            <div className="form-input">
              <input className="form-control" style={{height:"30px", fontSize: "11px"}}
                     name="name"
                     placeholder="New attribute"
                     onChange={this.handleChange}
                     disabled={disabled}
                     defaultValue={attribute.name}/>
            </div>
          </div>
          <div className="attribute-field attribute-value-form-new">
            <span className="text-attribute-option">DISPLAY VALUE</span>
            <div className="form-input">
              <input className="form-control"
                     style={{height:"30px", fontSize: "11px", backgroundColor:(this.props.attribute.typeAttribute==="PARAGRAPH")?"#f3f3f3":"rgb(255, 255, 255)"}}
                     name="displayValue"
                     placeholder={(this.props.attribute.typeAttribute==="PARAGRAPH")?"":"New Value"}
                     onChange={(this.props.attribute.typeAttribute==="PARAGRAPH")?null:this.handleChange}
                     //style={{backgroundColor:(this.props.attribute.typeAttribute==="PARAGRAPH")?"#f3f3f3":"rgb(255, 255, 255)"}}
                     value={(this.props.attribute.typeAttribute==="PARAGRAPH")?"":attribute.displayValue} />
            </div>
          </div>
        </div>
        <div className="row-form">
          <div className="attribute-field attribute-name-form-new">
            <span className="text-attribute-option">AUDIENCE</span>
            <div className="btn-form-dp" style={{position: "relative"}}>
              <a className="btn-basic btn-inventory-dp-new" style={{height:"30px", paddingTop: "5px", cursor:(disabled)?"not-allowed":""}}
                 onClick={() => (!disabled) ? this.onChangeDropDownForm("dropDownAudience"): {}}>
                {
                  attribute.entityTypes.length !== 0 ? <span>{attribute.entityTypes.join(",")}</span> :
                    <span style={{fontSize: "11px"}} >{form.entityType}</span>
                }
                {
                  (dropDownAudience.render) ? <i className="fa fa-chevron-up"/> : <i className="fa fa-chevron-down"/>
                }
              </a>
              {
                dropDownAudience.render ?
                  <Outside onClose={this.closePopover.bind(this, "dropDownAudience")}>
                    <div className="popover-inventory-dp-new-modal">
                      <div className="popover-inventory-dp-sub">
                        <u>
                          {
                            dropDownAudience.values.map((op, index) => {
                              return (
                                <li key={index} className="change-submenu-item"
                                    onClick={() => this.onSelectDropDown("dropDownAudience", "entityTypes", op.value)}>
                                  {op.value}
                                  {
                                    (attribute.entityTypes.filter(x => x === op.value).length > 0) ? <span/> : null
                                  }
                                </li>
                              )
                            })
                          }
                        </u>
                      </div>
                    </div>
                  </Outside>
                  : null
              }
            </div>
          </div>
          <div className="attribute-field attribute-value-form-new" style={{width:"33%"}}>
            <span className="text-attribute-option">ATTRIBUTE CODE</span>
            <div className="form-input">
              <input className="form-control" style={{height:"30px", fontSize: "11px"}}
                     name="code"
                     placeholder="New code"
                     disabled={disabled}
                     onChange={this.handleChange}
                     defaultValue={attribute.code}/>
            </div>
          </div>
        </div>
        <div className="row-form">
          <AttributeField
            value={isChecked}
            displayValue={'Required Question'}
            updateCheck={this.onCheck}
            fieldName=""
          />
          <div className="attribute-field attribute-value-form-new">
            <span className="text-attribute-option">DISPLAY VALUE</span>
            <div className="form-input">
              <input className="form-control"
                     style={{height:"30px", fontSize: "11px", backgroundColor:"white"}}
                     name="message"
                     placeholder={"Question Name"}
                     onChange={this.handleValidationChange}
                     value={getValidationRequiredMessage(attribute.validation)} />
            </div>
          </div>
        </div>
        <div className="row-form">
          {
            attribute.inputType === InputTypes.DYNAMIC_NUMBER ?
              <AttributeField
                value={withSymbol}
                displayValue={'Show dollar symbol'}
                updateCheck={this.updateOnCheckAttributes}
                fieldName={FIELD_WITH_SYMBOL}
              /> : null
          }
          <AttributeField
            value={readOnly}
            displayValue={'Read Only'}
            updateCheck={this.updateOnCheckAttributes}
            fieldName={FIELD_READ_ONLY}
          />
          {
            attribute.inputType === InputTypes.DYNAMIC_NUMBER || attribute.inputType === InputTypes.NUMBER ?
              <AttributeField
                value={negative}
                displayValue={'Negative'}
                updateCheck={this.updateOnCheckAttributes}
                fieldName={FIELD_NEGATIVE}
              /> : null
          }
        </div>
      </div>
    )
  }

}