import React, {useCallback, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import {
  createSubscription,
  deletePaymentMethod,
  loadOrderByStatus,
  processOrder,
  updateSubscription
} from '../../../Api'
import {cleanUpBraintree, loadScripts} from '../../util/Utils'
import PaymentModal from '../../setting/billing/PaymentModal'
import {orderStatus, paymentTypes} from '../../../Constants'
import {generateOrderBody} from '../../../Utils'
import {useSelector} from 'react-redux'

export const ADD_NEW_CARD = 'AddNewCard'
const outstandingBalance = 100.00

export const getBillAddress = () => {
  let streetAddress = null;
  const el = window.document.getElementById('streetAddress')
  if(el){
    streetAddress = el.value
  }
  return {billingAddress: {streetAddress: streetAddress}}
}


const BraintreeIntegration = ({config, selectedOrder, selectedPlan, subscriptionId, show, close, paymentMethods, setReload, currentInventoryItem}) => {

  const [isLoaded, setLoaded] = useState(false)
  const [instance, setInstance] = useState(null)
  const [deviceData, setDeviceData] = useState('')
  const [selectedAmountOption, setSelectedAmountOption] = useState('')
  const [amount, setAmount] = useState(0.00)
  const [isProcessing, setIsProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedToken, setSelectedToken] = useState(null)
  const [saveCardMode, setSaveCardMode] = useState(false)

  const dealerId = useSelector( (state) => state.dealerId)
  const isUpdateSubscription = (typeof selectedPlan !== "undefined")

  const initializeWithHostedFields = async (selectedToken) => {
    // const token = (await clientToken()).clientToken
    const token = config.clientToken

    try {
      const clientInstance = await braintree.client.create({
        // Insert your tokenization key here
        authorization: token
      })

      let instance = null

      if (selectedToken === ADD_NEW_CARD) {
        instance = await braintree.hostedFields.create({
          client: clientInstance,
          // Customize the Hosted Fields.
          // More information can be found at:
          // https://developers.braintreepayments.com/guides/hosted-fields/styling/javascript/v3
          styles: {
            'input': {
              'font-size': '14px'
            },
            'input.invalid': {
              'color': 'red'
            },
            'input.valid': {
              'color': 'green'
            }
          },
          // Configure which fields in your card form will be generated by Hosted Fields instead
          fields: {
            cardholderName: {
              container: '#card-holder',
              placeholder: 'Card holder'
            },
            number: {
              container: '#card-number',
              placeholder: '4111 1111 1111 1111'
            },
            cvv: {
              container: '#cvv',
              placeholder: '123',
              type: 'password'
            },
            expirationDate: {
              // container: '#expiration-date',
              container: '#exp-date',
              placeholder: '10/2022'
            },
            postalCode: {
              container: '#postal-code',
              placeholder: '11111'
            }
          }
        })
      }

      const dataCollectorInstance = await braintree.dataCollector.create({client: clientInstance})

      ReactDOM.unstable_batchedUpdates(() => {
        setDeviceData(dataCollectorInstance.deviceData)
        setInstance(instance)
      })

    } catch (error) {
      console.log('braintree:', error)
    }
  }

  useEffect(() => {
    const loadBraintreeScript = async () => {
      await loadScripts(
        [
          'https://js.braintreegateway.com/web/3.81.0/js/client.min.js',
          'https://js.braintreegateway.com/web/3.81.0/js/hosted-fields.min.js',
          'https://js.braintreegateway.com/web/3.81.0/js/data-collector.min.js'
        ]
      )
      setLoaded(true)
    }
    loadBraintreeScript().then()
  }, [])

  useEffect(() => {
    if(!instance && show && isLoaded && selectedToken === ADD_NEW_CARD) {
      initializeWithHostedFields(selectedToken).then()
    }

    return () => cleanUpBraintree(instance)
  }, [config, show, isLoaded, selectedToken])

  const handleProcessOrder = async (e) => {

    e.preventDefault();
    e.stopPropagation();

    if (isUpdateSubscription){
      if ((!instance && selectedToken === ADD_NEW_CARD) || !selectedPlan.id)
        return
    } else {
      if ((!instance && selectedToken === ADD_NEW_CARD) || !selectedOrder.orderId )
        return
    }

    setIsProcessing(true)
    try {
      if (instance && selectedToken === ADD_NEW_CARD) {
        instance.tokenize(getBillAddress(), async (tokenizeErr, payload) => {
          if (tokenizeErr) {
            console.error('Error tokenizer:', tokenizeErr)
            ReactDOM.unstable_batchedUpdates(() => {
              setIsProcessing(false)
              setErrorMessage(tokenizeErr.message)
            })
            return
          }

          if (isUpdateSubscription) {
            const data = {
              subscriptionId: subscriptionId,
              inventoryItemId: selectedPlan.id,
              nonce: payload.nonce,
              makeDefault: true,
              paymentMethodToken: selectedToken,
              currentInventoryItemId: currentInventoryItem.id
            }
            await updateSubscription(data)
          } else {
            await processOrder(selectedOrder.orderId, payload.nonce, selectedOrder.amount, deviceData, paymentTypes.BILL, 0.00, null, saveCardMode)
            // await processOrder({paymentMethodNonce: payload.nonce})
          }

          instance.teardown(function (teardownErr) {
            if (teardownErr) {
              console.error('Could not tear down the Hosted Fields form!')
              ReactDOM.unstable_batchedUpdates(() => {
                setIsProcessing(false)
                setErrorMessage('Could not tear down the Hosted Fields form!')
              })
            } else {
              console.info('Hosted Fields form has been torn down!')
              ReactDOM.unstable_batchedUpdates(() => {
                // Remove the 'Submit payment' button
                setInstance(null)
                setIsProcessing(false)
                setErrorMessage(null)
                setSelectedToken(null)
                close()
                setReload(prevent => !prevent)
              })
            }
          })

        })
      }

      if (selectedToken !== ADD_NEW_CARD) {
        if (isUpdateSubscription) {
          const data = {
            subscriptionId: subscriptionId,
            inventoryItemId: selectedPlan.id ,
            makeDefault: true,
            paymentMethodToken: selectedToken,
            currentInventoryItemId: currentInventoryItem.id
          }
          await updateSubscription(data)
        } else {
          await processOrder(selectedOrder.orderId, null, selectedOrder.amount, deviceData, paymentTypes.BILL, 0.00, selectedToken, saveCardMode)
        }
        ReactDOM.unstable_batchedUpdates(() => {
          // Remove the 'Submit payment' button
          setInstance(null)
          setIsProcessing(false)
          setErrorMessage(null)
          setSelectedToken(null)
          close()
          setReload(prevent => !prevent)
        })
      }
    } catch (error) {
      console.error(error)
      setIsProcessing(false)
    }
  }

  const handleAmountOption = (value) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedAmountOption(value)
      if (selectedOrder.amount > 0) {
        setAmount(selectedOrder.amount)
      }
    })
  }

  const handleCloseModal = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      // Remove the 'Submit payment' button
      setInstance(null)
      setSelectedAmountOption('')
      setSelectedToken(null)
      close()
    })
  }

  if (!isLoaded)
    return null

  return (
    <>
      {
        show &&
        <PaymentModal show={show}
                      outstandingBalance={selectedOrder?.amount ?? 0}
                      selectedAmountOption={selectedAmountOption}
                      selectedAmountOptionHandler={handleAmountOption}
                      paymentHandler={handleProcessOrder}
                      errorMessage={errorMessage}
                      isProcessing={isProcessing}
                      onClick={handleCloseModal}
                      paymentMethods={paymentMethods}
                      setSelectedToken={setSelectedToken}
                      selectedToken={selectedToken}
                      setInstance={setInstance}
                      setSaveCardMode={setSaveCardMode}
                      saveCardMode={saveCardMode}
                      selectedOrder={selectedOrder}
        />
      }
    </>

    // <form id="hosted-fields-form" method="post">
    //   <label htmlFor="card-number">Card Number</label>
    //   <div id="card-number"/>
    //
    //   <label htmlFor="cvv">CVV</label>
    //   <div id="cvv"/>
    //
    //   <label htmlFor="expiration-date">Expiration Date</label>
    //   <div id="expiration-date"/>
    //
    //   <div id="checkout-message"/>
    //
    //   <input type="button" onClick={handleProcessOrder} value="Pay" disabled={!instance}/>
    // </form>
  )
}

export default BraintreeIntegration
