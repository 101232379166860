import React, {useEffect, useState} from 'react'
import {SIDEBAR_WIDTH_EXPANDED, SIDEBAR_WIDTH_UNEXPANDED} from '../../../Constants.js'
import WrapperSidebar from './WrapperSidebar.js'
import {connect} from 'react-redux'
import ReviewHeader from '../ReviewHeader'
import {browserHistory} from 'react-router'
import {
  MENU_ITEM_DASHBOARD_ID,
  MENU_ITEM_SETTING_ID,
  TAB_DASHBOARD_ID,
  TAB_LOCATION_ID,
  TAB_REFERRAL_ID,
  TAB_REVIEW_ID, TAB_REWARDS_ID,
  WIDTH_VIEW_TABLET,
  pathServer, MENU_ITEM_REVIEW_ID,
} from '../../../../common/Constants'
import {setMenuId} from '../../../actions'

const userDefault = {
  "firstName" : "Richard",
  "middleName" : "P.",
  "image" :pathServer.PATH_IMG+"user.png"
}

const Wrapper = ({showSideBar, width, height, path, ...props}) => {

  const [tabSelected, setTabSelected] = useState(TAB_DASHBOARD_ID)
  /***const [showUserMenu, setShowUserMenu] = useState("");
  const [user, setUser] = useState(userDefault);***/

  /*** const onClick = (e) => {
    if(showUserMenu === ""){
      setShowUserMenu("open")
    } else {
      setShowUserMenu("")
    }
  } ***/

  useEffect(() => {
    if(showSideBar && width > WIDTH_VIEW_TABLET){
      width = width - SIDEBAR_WIDTH_EXPANDED;
    } else{
      width = width - SIDEBAR_WIDTH_UNEXPANDED;
    }

  },[])

  useEffect(() => {
    updateTabSelected(path);
  }, [path])

  const updateTabSelected = (path) => {
    const paths = path.split("/")
    /*** if(paths.length === 4){
      setTabSelected(paths[3])
    } else if(paths.length === 3){
      if(paths[2] === MENU_ITEM_SETTING_ID){
        setTabSelected(TAB_LOCATION_ID)
      }else if(paths[2] === MENU_ITEM_DASHBOARD_ID){
        setTabSelected(TAB_DASHBOARD_ID)
      }
    } ***/
    switch (paths[paths.length - 1]){
      case MENU_ITEM_DASHBOARD_ID:
      case MENU_ITEM_REVIEW_ID:
        setTabSelected(TAB_DASHBOARD_ID)
        break;
      case TAB_REVIEW_ID:
        setTabSelected(TAB_REVIEW_ID)
        break
      default:
        setTabSelected(TAB_DASHBOARD_ID)
    }
  }
  const onChangeTab = (id) => {
    setTabSelected(id)
    let pathname
    switch (id){
      case TAB_LOCATION_ID:
        pathname = `/main/reviews/setting/${id}`
        break;
      case TAB_REVIEW_ID:
      case TAB_REFERRAL_ID:
      case TAB_REWARDS_ID:
        pathname = `/main/reviews/dashboard/${id}`
        break;
      default:
        pathname = `/main/reviews/${id}`
    }
    browserHistory.push({pathname: pathname});
  }

  const paddingLeft = showSideBar ? '200px' : '50px'

  return (
    <div className="wrapper-reviews"  style={{paddingLeft: paddingLeft}}>
      <div className="wrapper-header">
        <ReviewHeader
          showSideBar={showSideBar}
          width={width}
          height={height}
          path={path}
          tab={tabSelected}
          onChangeTab={onChangeTab}
        />
        {
          /*
          <div className="menu-user" style={{marginLeft:5}}>
          <ul className="menu-user-u">
            <li className={"dropdown "+showUserMenu}>
              <div className="menu-user-a">
                <span id="userName" className="user-name">{`${user.firstName} ${user.middleName}`}</span>
                <a onClick={onClick}>
                  <i id="userIcon" className="fa fa-chevron-down"/>
                </a>
                <img id="userImg" src={`${pathServer.PATH_IMG}user.png`} className="icon-user-profile" onClick={onClick}/>
              </div>
            </li>
          </ul>
        </div>
        <div className="menu-notification">
          <img src={pathServer.PATH_IMG+"notification-icon.png"} />
        </div>
           */
        }
      </div>
      {/*<WrapperSidebar showSideBar={showSideBar} height={height} path={path}/>*/}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    firstName: state.firstName,
    lastName: state.lastName,
    urlImage: state.urlImage
  }
};

export default connect(mapStateToProps,{setMenuId})(Wrapper)