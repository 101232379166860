import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setTestimonialsInStore} from '../../actions'
import ReviewContainer from './ReviewContainer'
import isEmpty from 'lodash/isEmpty'
import GifContainer from './GifContainer'
import ReviewPage from './ReviewPage'

const maxQuantityFacebookReviews = 1000

const ReviewsSection = ({handleReview, overlayActive, handleShowSendMailModal, height, reviewRequest, reviewPage}) => {
  const dispatch = useDispatch()
  const testimonials = useSelector( (state) => state.testimonials)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(  () => {
    dispatch(setTestimonialsInStore(0, maxQuantityFacebookReviews, true , setIsLoading))
  }, [])

  return(
    <div className={'column-reviews'} style={{display: 'flex', flexDirection: 'column', width: '67%', overflow: 'auto'}}>
      <>
        {
          isLoading ?
            <div className="general-container-loader">
              <div className="loader-container">
                <div className="loader"/>
              </div>
            </div>
             :
            <>
              {
                 !testimonials || testimonials.length === 0 ?
                  <ReviewContainer
                    id='noReviewsYet'
                    handleReview={handleReview}
                    showOverlay={ ( isEmpty(reviewRequest) || isEmpty(reviewPage) ) && overlayActive === 'noReviewsYet'}
                    bodyComponent={
                      <GifContainer
                        showFloatContainer={( isEmpty(reviewRequest) || isEmpty(reviewPage) ) && overlayActive === 'noReviewsYet'}
                        floatTitle={<span>All reviews in<br/>one place</span>}
                        floatText={<span>Easily manage and reply all your<br/>reviews here</span>}
                        floatTextButton={'Got it!'}
                        gifPath={'56790-review.gif'}
                        title={'You have no reviews yet'}
                        text={<span>Start sharing your review request to your <br/>clients easily and gain better reputation</span>}
                        textButton1={"Import Reviews"}
                        textButton2={'Send now'}
                        handleGotIt={handleReview}
                        sendFunction={handleShowSendMailModal}
                      />
                    }
                  /> :
                  <ReviewPage height={height}/>
              }
            </>
        }
      </>
    </div>
  )
}

export default ReviewsSection