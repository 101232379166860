import React from 'react'
import PropTypes from 'prop-types'
import DynamicNumber from 'react-dynamic-number'

/*
 onChange is a function with 3 attributes:
   evt - react event
   modelValue - correct javascript number
   viewValue - string value visible in input
 */
const selectText = (id) => {
  const input = document.getElementById(id);
  input.focus();
  input.select();
}

export const DPDynamicNumberInput = (props) => {
  const {
    className, title, id, value, onChange, isRequired, maxLength,
    thousand, integer, separator, fraction, placeHolder, disabled, errorMessage,
    onBlur,classWrapperInput,withSymbol,classWrapperSymbol,positive,negative,
    iconElement, classTitle, styleInput, nextIcon, canSelectText, dataTest
  } = props

  return (
    <div className={className}>
      {
        title ?
          <div className="info-label">
            <label className={classTitle ? `title ${classTitle}` : 'title'}>{title}</label>
            {isRequired ? <span className="label-error">*</span> : null}
            {iconElement}
          </div>
          : null
      }
      <div className={`inputSale ${classWrapperInput}`}>
        {withSymbol ? <span className={`dollar-icon ${classWrapperSymbol}`}>$</span> : null}
        <DynamicNumber
          id={id}
          className={errorMessage ? "form-control has-error" : "form-control"}
          placeholder={placeHolder}
          value={value}
          positive={positive}
          negative={negative}
          thousand={thousand}
          onChange={onChange}
          separator={separator}
          integer={integer}
          fraction={fraction}
          maxLength={maxLength}
          onBlur={onBlur}
          disabled={disabled}
          style={styleInput}
          onClick={() => canSelectText ? selectText(id) : null}
          data-test={dataTest}
        />
        {nextIcon ? nextIcon : null}
        {
          errorMessage
            ? <p className="help-block">{errorMessage}</p>
            : null
        }
      </div>
    </div>
  )
}

DPDynamicNumberInput.defaultProps = {
  disabled: false,
  maxLength: null,
  onChange: null,
  placeHolder: '',
  isRequired: false,
  errorMessage: '',
  onBlur: null,
  classWrapperInput: '',
  withSymbol: false,
  classWrapperSymbol: '',
  positive: true,
  negative: false,
  iconElement: null,
  styleInput: null,
  nextIcon: null,
  canSelectText: false,
  dataTest: 'number-input'
}

DPDynamicNumberInput.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  thousand: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  integer: PropTypes.number,
  separator: PropTypes.string,
  fraction: PropTypes.number,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  classWrapperInput: PropTypes.string,
  withSymbol: PropTypes.bool,
  classWrapperSymbol: PropTypes.string,
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  iconElement: PropTypes.object,
  styleInput: PropTypes.object,
  nextIcon: PropTypes.object,
  canSelectText: PropTypes.bool,
  dataTest: PropTypes.string,
}