import React, {useRef, useState} from 'react'
import NavigationBar from "../components/navigationBar/NavigationBar";
import IntroBannerSection from "../components/introBannerSection/IntroBannerSection";
import FooterSection from "../components/footer/FooterSection";
import {pathServer} from "../../../../common/Constants";
import GDGButton, {BUTTON_TYPE} from "../components/GDGButton";
import SplitSection from "../components/SplitSection";

require('../homepage.scss')
require('./reviews.scss')

export const handleLineBreak = () => {
  return window.innerWidth < 561 ? '' : '</br>'
}


export default function Reviews() {
  window.scrollTo(0, 0)
  const navigationBar = useRef();

  const demoRequest = () => {
    if(navigationBar && navigationBar.current)
      navigationBar.current.handleRequestDemo()
  }

  return (
      <div id='gdg-home-page' className='reviews'>
        <NavigationBar ref={navigationBar}/>
        <IntroBannerSection
          blueText={'REVIEWS'}
          title={"Let's grow your reputation"}
          description={"With our Review Management platform you will unlock the power of your online " +
            "presence. Let your reviews speak for themselves and get ready to experience the " +
            "power of word of mouth online."}
          imgUrl={pathServer.PATH_IMG + '/newHomePage/reviews-header.png'}
        />
        <SplitSection
          className={'reviews-application after-intro-section-padding background-light-blue'}
          leftSection={
            <div>
              <p className={'title-font'} dangerouslySetInnerHTML={{
                __html: `A new way to manage ${handleLineBreak()}` +
                  ' all your reviews </br>'
              }}/>
              <p className={'regular-font'}>
                Our Review Management platform allows you to have a global presence
                with all major social media platforms and Google searches to ensure
                you are always on the driver seat when it comes to your online presence.
              </p>
              <p className={'sub-title-font'}>
                Review Feature
              </p>
              <p className={'regular-font'} dangerouslySetInnerHTML={{
                __html: 'Contact us so we can show you a better way to let your customers know how awesome you are'
              }}/>
              <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
            </div>
          }
          rightSection={
            <div>
              <img alt={'img'} src={pathServer.PATH_IMG + 'newHomePage/reviews-new-way.png'}/>
            </div>
          }

        />

        <SplitSection
          className={'reviews-request after-intro-section-padding background-light-blue odd'}
          leftSection={
            <div>
              <p className={'title-font'} dangerouslySetInnerHTML={{
                __html: `Ask your customers for ${handleLineBreak()}` +
                  ' reviews </br>'
              }}/>
              <p className={'regular-font'}>
                Easily select the customers you want to send review request using your existing customer list.
              </p>
              <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
            </div>
          }
          rightSection={
            <div>
              <img alt={'img'} src={pathServer.PATH_IMG + 'newHomePage/reviews-ask-customers.png'}/>
            </div>
          }

        />

        <SplitSection
          className={'reviews-track after-intro-section-padding background-light-blue'}
          leftSection={
            <div>
              <p className={'title-font'} dangerouslySetInnerHTML={{
                __html: `Track the progress and see your reputation ${handleLineBreak()}` +
                  ' improvement </br>'
              }}/>
              <p className={'regular-font'}>
                Track your progress and feedback from customers to allow your business to thrive.
              </p>
            </div>
          }
          rightSection={
            <div>
              <img alt={'img'} src={pathServer.PATH_IMG + 'newHomePage/reviews-track.png'}/>
            </div>
          }
          floatingSection={<img className='floating-section' alt={'img'} src={pathServer.PATH_IMG + 'newHomePage/reviews-animation-background.gif'}/>}
        />
        <FooterSection/>

      </div>
  )
}
