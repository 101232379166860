import React, {Component} from 'react'
import {pathServer} from '../../../common/Constants'

export default class ModalSearch extends Component{

  constructor() {
    super();


    this.tasks = [
      {date:"5/23",name:"Air C. Maintenance",color:"#e27271"},
      {date:"5/23",name:"Send Email to...",color:"#398dd3"},
      {date:"5/23",name:"Call to Mac Support",color:"#63cb96"},
      {date:"5/23",name:"Pay May's",color:"#e27271"},
      {date:"5/23",name:"Call Marcus",color:"#63cb96"}
    ];

    this.cars = [
      {model:"Mazda 6",year:2018,mainImage:pathServer.PATH_IMG+"2004 Mazda RX8 Gray.jpg"},
      {model:"Malibu",year:2016,mainImage:pathServer.PATH_IMG+"2004 Mazda RX8 Gray.jpg"},
      {model:"Mazda 4",year:2015,mainImage:pathServer.PATH_IMG+"2004 Mazda RX8 Gray.jpg"},
      {model:"Camaro",year:2017,mainImage:pathServer.PATH_IMG+"2004 Mazda RX8 Gray.jpg"}
    ];

    this.users = [
      {firstName:"Rafael",lastName:"Maldonado",image:pathServer.PATH_IMG+"react-logo.png"},
      {firstName:"Jaren",lastName:"Malan",image:pathServer.PATH_IMG+"react-logo.png"}
    ];

    this.customers = [
      {firstName:"Maggie",lastName:"Royerts"},
      {firstName:"Rock",lastName:"Mackensy"},
      {firstName:"Mujer",lastName:"Maravilla"},
      {firstName:"Super",lastName:"Man"},
      {firstName:"Rafael",lastName:"Maldonado"},
      {firstName:"Malcon",lastName:"Sanchez"}
    ];

    this.histories = [
      {name:"Honda Civic 2"},
      {name:"Javier Leiva"},
      {name:"February Rent"},
      {name:"Toyota Camry"}
    ];
  }

  componentDidMount(){
    let {showSidebar} = this.props;
    let modal = window.document.getElementById("modalSearch");
    if(showSidebar){
      modal.className += ' modal-collapse';
    } else {
      modal.className = 'modal-search';
    }

    //
  }


  getWordHTML(word,filter){
    let wordHTML;
    let indexFound = word.toLowerCase().indexOf(filter.toLowerCase());

    let filterFound = "";
    let wordInitial = "";
    let wordEnd = "";
    if(indexFound !== -1){
      if(indexFound === 0){
        filterFound = word.substr(0, filter.length);
        wordEnd = word.substr(filter.length);
      }else if(indexFound !== word.length){
        wordInitial = word.substr(0,indexFound);
        filterFound = word.substr(indexFound, filter.length);
        wordEnd = word.substr(indexFound+filter.length);
      }else{
        wordInitial = word.substr(0,indexFound);
        filterFound = word.substr(indexFound);
      }

      wordHTML = (
        <label>
          <span>{wordInitial}</span>
          <span style={{color:"#5287b1",textDecoration:"underline"}}>{filterFound}</span>
          <span>{wordEnd}</span>
        </label>
      )
    }else{
      wordHTML = <label>{word}</label>
    }

    return wordHTML
  }


  render(){
    const {filter} = this.props;
    return(
      <div id="modalSearch" className="modal-search">
        <div className="row-modal-search" style={{display:"flex"}}>
          {
            this.tasks ?
              <div className="search-item" style={{borderRight:"1px #f6f6f8 solid"}}>
                <div className="search-task-title">
                  <h5>Tasks</h5>
                </div>
                {
                  this.tasks.map((task,index) => {

                    let wordHTML = this.getWordHTML(task.name,filter);

                    return(
                      <div key={index} className="row-search">
                        <div className="dot" style={{backgroundColor:task.color}}/>
                        <label>{task.date}</label>
                        {wordHTML}
                        <i className="icon-search-arrow fa fa-arrow-right" />
                      </div>
                    )
                  })
                }
              </div> : null
          }
          {
            this.cars ?
              <div className="search-item" style={{borderRight:"1px #f6f6f8 solid"}}>
                <div className="search-car-title">
                  <h5>Inventory</h5>
                </div>
                {
                  this.cars.map((car,index) => {
                    let wordHTML = this.getWordHTML(car.model,filter);
                    return(
                      <div key={index} className="row-search">
                        <img src={car.mainImage} style={{width:36,height:36,borderRadius:"50%"}}/>
                        {wordHTML}
                        <label>{car.year}</label>
                        <i className="icon-search-arrow fa fa-arrow-right" />
                      </div>
                    )
                  })
                }
              </div> : null
          }
          {
            this.users ?
              <div className="search-item" style={{borderRight:"1px #f6f6f8 solid"}}>
                <div className="search-user-title">
                  <h5>Users</h5>
                </div>
                {
                  this.users.map((user,index) => {
                    let wordFirstNameHTML = this.getWordHTML(user.firstName,filter);
                    let wordLastNameHTML = this.getWordHTML(user.lastName,filter);
                    return(
                      <div key={index} className="row-search">
                        <img src={user.image} style={{width:36,height:36,borderRadius:"50%"}}/>
                        {wordFirstNameHTML}
                        {wordLastNameHTML}
                        <i className="icon-search-arrow fa fa-arrow-right" />
                      </div>
                    )
                  })
                }
              </div> : null
          }
          {
            this.customers ?
              <div className="search-item">
                <div className="search-customer-title">
                  <h5>Customers</h5>
                  <button className="btn btn-category">
                    <span>Edit Categories</span>
                  </button>
                </div>
                {
                  this.customers.map((customer,index) => {
                    let wordFirstNameHTML = this.getWordHTML(customer.firstName,filter);
                    let wordLastNameHTML = this.getWordHTML(customer.lastName,filter);
                    return(
                      <div key={index} className="row-search">
                        {wordFirstNameHTML}
                        {wordLastNameHTML}
                        <i className="icon-search-arrow fa fa-arrow-right" />
                      </div>
                    )
                  })
                }
              </div> : null
          }
        </div>
        <div className="row-modal-search" style={{padding:"15px 10px"}}>
          {
            this.histories.map((history,index) => {
              return(
                <div key={index} className="row-history">
                  <img src={pathServer.PATH_IMG+"icon-history.png"}/>
                  <label>{history.name}</label>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}