import React, { useEffect, useState, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'
import { DPModalInfo } from '../../util/ComponentUtils'
import Receipt from './Receipt'
import PrintInvoice from './PrintInvoice'
import {
  apiGenerateInvoice, deletePaymentMethod, initializePaymentProcessor,
  loadInvoices,
  loadLastOrder,
  savePaymentMethod
} from '../../../Api'
import Payment from '../../payment'
import { pathServer, TYPE_FEATURE, TYPE_STATUS, SUCCESSFUL_CODE } from '../../../../common/Constants'
import { unstable_batchedUpdates } from 'react-dom'
import Moment from 'moment'

import './BillingContainer.scss'
import { PaymentMethod } from '../../superusers/dealers'
import ModalConfirmDeactivate from '../../util/ModalConfirmDeactivate'
import { CreateSubscriptionModal } from './CreateSubscriptionModal'
import { IncludedItem, NotIncludedItem } from '../../dashboard/components/layout/SubscriptionLayout'
import { browserHistory } from 'react-router'
import BraintreeIntegration from '../../payment/braintree'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import {formatDateToString} from '../../util/Utils'


export const ADD_NEW_CARD_MODE = 'AddNewCardMode'

const INVOICE_TYPE = {
  RECURRING: 'sale-recurring',
  BILL: 'bill',
  PAYMENT: 'payment',
}

export const INVOICE_STATUS = {
  PAID: 'paid',
  PENDING: 'pending',
  UNPAID: 'unpaid',
}

const dataInvoices = [
  {
    id: '1',
    date: '01/04/2021',
    type: 'Bill',
    typeInvoice: INVOICE_TYPE.BILL,
    amount: '$115',
    balance: '$115',
    status: INVOICE_STATUS.PAID,
    currentDue: '$100',
  },
  {
    id: '2',
    date: '07/03/2021',
    type: 'Bill',
    typeInvoice: INVOICE_TYPE.BILL,
    amount: '($100)',
    balance: '$0',
    status: INVOICE_STATUS.PENDING,
    currentDue: '$110',
  },
  {
    id: '3',
    date: '06/03/2021',
    type: 'Bill',
    typeInvoice: INVOICE_TYPE.BILL,
    amount: '($100)',
    balance: '$100',
    status: INVOICE_STATUS.UNPAID,
    currentDue: '$120',
  }
]

// const authData = {
//   clientKey : '2qkYuPHNg7C9FYg93e4ev4vSCqp2q6BuYqwg8d3QhJx8J56H7SMx7Y85s7c95EY6',
//   apiLoginID: '6A6uZZtw9N'
// }

// function loadAcceptScript(link) {
//   const script = document.createElement('script')
//   script.src = link
//
//   document.body.appendChild(script)
// }

export const CARD_TYPE = {
  VISA: 'Visa',
  MASTERCARD: 'MasterCard',
  AMEX: 'amex',
}

const OPTION_TYPE = {
  EDIT: 'edit',
  DELETE: 'delete'
}

const OptionItem = ({ optionType = OPTION_TYPE.EDIT, onClick }) => {
  const typeOptionItem = {
    [OPTION_TYPE.EDIT]: 'icon/ic_edit-pencil.svg',
    [OPTION_TYPE.DELETE]: 'icon/ic_delete.svg',
  }

  return (
    <ReactSVG
      src={`${pathServer.PATH_IMG}${typeOptionItem[optionType]}`}
      beforeInjection={svg => svg.classList.add('icon-payment-method-option')}
      className={'icon-payment-method-option'}
      onClick={onClick}
    />
  )
}

const cardType = {
  [CARD_TYPE.VISA]: 'icon/icon_simple_visa.svg',
  [CARD_TYPE.MASTERCARD]: 'icon/icon_simple_mastercard.svg'
}

export const PaymentMethodItem = memo(({
                                         token,
                                         creditCardNumber,
                                         creditCardType = CARD_TYPE.VISA,
                                         showOptions = true,
                                         onClick = null,
                                         classPaymentMethodItem = 'payment-method-item',
                                         deleteItem = null
                                       }) => {
  const handleSelectItem = () => {
    onClick(token)
  }

  const deleteCreditCard = () => {
    deleteItem(token)
  }

  return (
    <div className={classPaymentMethodItem} onClick={onClick && handleSelectItem}>
      <ReactSVG
        src={`${pathServer.PATH_IMG}${cardType[creditCardType]}`}
        beforeInjection={svg => svg.classList.add('icon-credit-card-logo')}
        className={'icon-credit-card-logo'}
      />
      <div className="credit-card-number">{creditCardNumber}</div>
      {
        showOptions
        && <div className="payment-methods-options-list">
          {/*<OptionItem optionType={OPTION_TYPE.EDIT}/>*/}
          <OptionItem optionType={OPTION_TYPE.DELETE} onClick={deleteItem && deleteCreditCard}/>
        </div>
      }
    </div>
  )
})

function useHover() {
  const [hovered, setHovered] = useState({})

  const mouseEnter = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.currentTarget
    const key = target.getAttribute('aria-rowindex')
    const tagElementId = `${key}tr`
    let tagElement = document.getElementById(tagElementId)
    tagElement.style.backgroundColor = '#F5F6FA'

    const tagElementOptionsId = `row-options-${key}`
    let tagElementOptions = document.getElementById(tagElementOptionsId)
    if (tagElementOptions) {
      tagElementOptions.style.visibility = 'visible'
    }
    setHovered((curState) => ({ ...curState, [key]: true }))
  }, [])

  const mouseLeave = useCallback((event) => {
    const target = event.currentTarget
    const key = target.getAttribute('aria-rowindex')
    if (key !== -1) {
      const tagPreviousElementId = `${key}tr`
      let tagPreviousElement = document.getElementById(tagPreviousElementId)
      tagPreviousElement.style.backgroundColor = '#FFFFFF'

      const tagElementOptionsId = `row-options-${key}`
      let tagElementOptions = document.getElementById(tagElementOptionsId)

      if (tagElementOptions) {
        tagElementOptions.style.visibility = 'hidden'
      }
    }
    setHovered((curState) => ({ ...curState, [key]: false }))
  }, [])

  return { mouseEnter, mouseLeave, hovered }
}

const Invoice = ({ invoices, showLoading, paramPaymentType, paymentMethods, setReload, setAddPaymentMethodMode }) => {
  const [showInvoice, setShowInvoice] = useState(false)
  const [showReceipt, setShowReceipt] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const { mouseEnter, mouseLeave } = useHover()

  const handlePaymentModal = (invoice) => {
    unstable_batchedUpdates(() => {
      setAddPaymentMethodMode(false)
      setSelectedInvoice(invoice)
      setShowPaymentModal(prevState => !prevState)
    })
  }

  const handleOpenRecipes = (typeInvoice, invoice) => {
    if (typeInvoice === INVOICE_TYPE.PAYMENT) {
      unstable_batchedUpdates(() => {
        setSelectedInvoice(invoice)
        setShowReceipt(prevState => !prevState)
      })
    } else {
      window.open(apiGenerateInvoice(invoice.orderId), '_blank')
    }
  }

  const handleClosePaymentModal = () => {
    unstable_batchedUpdates(() => {
      setSelectedInvoice(null)
      setShowPaymentModal(false)
    })
  }

  const titleContainerClass = paramPaymentType === 'hosted' ? 'title-container-hosted' : 'title-container'

  return (
    <>
      <div className="container-invoices">
        <div className={titleContainerClass}>
          <div className="left-container">
            <label className="title-label-invoice">Invoices</label>
          </div>
          <div className="right-container">
            {/*{*/}
            {/*  paramPaymentType === 'hosted' &&*/}
            {/*  <div className="container-payment-options">*/}
            {/*    <PaymentAmountContainer*/}
            {/*      selectedAmountOption={selectedAmountOption}*/}
            {/*      outstandingBalance={outstandingBalance}*/}
            {/*      selectedAmountOptionHandler={selectedAmountOptionHandler}*/}
            {/*      otherAmount={otherAmount}*/}
            {/*      setOtherAmount={setOtherAmount}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*}*/}
            {/*{*/}
            {/*  paramPaymentType === 'own' && <button className="btn-save" onClick={togglePaymentModal}>Pay</button>*/}
            {/*}*/}
            {/*{*/}
            {/*  paramPaymentType === 'hosted'&& <AuthorizeForm classButton='btn-save' amount={amountToPay}/>*/}
            {/*}*/}
          </div>
        </div>
        <div id="table-invoices-setting" className="table-invoices-setting">
          {
            showLoading ?
              <div className="loader-container">
                <div className="loader"/>
              </div> :
              <table className="table-invoices">
                <thead style={{ backgroundColor: '#EFEFEF' }}>
                <tr className="title-invoice-list">
                  <th className="title-date-invoice">Date</th>
                  {/*<th className="title-type-invoice">Type</th>*/}
                  <th className="title-invoice-number">Invoice No.</th>
                  <th className="title-invoice-description">Description</th>
                  <th className="title-payment-due-date">Due Date</th>
                  <th className="title-amount-invoice">Amount Due</th>
                  {/*<th className="title-balance-invoice">Balance</th>*/}
                  <th className="title-balance-invoice">Status</th>
                  <th className=""/>
                </tr>
                </thead>
                <tbody>
                {
                  invoices.map((invoice, index) => {
                    const classBasedOnInvoiceStatus =
                      invoice.orderStatus === INVOICE_STATUS.PAID
                        ? 'title-paid-invoice-status'
                        : invoice.orderStatus === INVOICE_STATUS.UNPAID
                          ? 'title-unpaid-invoice-status'
                          : 'title-pending-invoice-status'

                    return (
                      <tr key={index + 'tr'}
                          aria-rowindex={index}
                          className="rowCostumer"
                          id={`${index}tr`}
                          onClick={(evt) => {
                          }}
                          onMouseEnter={mouseEnter}
                          onMouseLeave={mouseLeave}
                      >
                        <td className="title-date-invoice"
                            key={index + 'date'}>{formatDateToString(invoice.orderStartDate)}</td>
                        {/*<td className="title-type-invoice"*/}
                        {/*    key={index + 'type'}>{invoice.type}</td>*/}
                        <td className="title-invoice-number">{invoice.orderNumber}</td>
                        <td className="title-invoice-description">{invoice.orderItemsList[0].inventoryItemName}</td>
                        <td
                          className="title-payment-due-date">{formatDateToString(invoice && invoice.orderDueDate)}</td>
                        <td className="title-amount-invoice">{invoice.paymentCurrentDue ?? invoice.amount}</td>
                        {/*<td className="title-balance-invoice">{invoice.balance}</td>*/}
                        <td className={classBasedOnInvoiceStatus}>{invoice.orderStatus}</td>
                        <td key={index + 'td'} style={{ color: '#f3f3f3' }} className="title-icon-invoice">
                          <div className="option-invoice">
                            {
                              invoice.paymentType && (invoice.paymentType === INVOICE_TYPE.BILL || invoice.paymentType === INVOICE_TYPE.RECURRING)
                              && invoice.orderStatus === INVOICE_STATUS.PAID
                              && <div
                                id={`row-options-${index}`}
                                style={{ display: 'flex', visibility: 'hidden' }}
                              >
                                <ReactSVG
                                  src={pathServer.PATH_IMG + 'icon/download-tray.svg'}
                                  onClick={() => handleOpenRecipes(invoice.paymentType, invoice)}
                                  beforeInjection={svg => svg.classList.add('download-icon')}
                                  className="download-icon"
                                />
                                {/*<ReactSVG*/}
                                {/*  src={pathServer.PATH_IMG + "icon/ic_ticket.svg"}*/}
                                {/*  className={"icon-receipt"}*/}
                                {/*  onClick={() => handleOpenRecipes(INVOICE_TYPE.PAYMENT, invoice)}*/}
                                {/*  beforeInjection={svg => svg.classList.add("icon-receipt")}*/}
                                {/*/>*/}
                              </div>
                            }

                            {
                              invoice.orderStatus === INVOICE_STATUS.UNPAID
                              && <div
                                id={`row-options-${index}`}
                                style={{ display: 'flex', visibility: 'hidden' }}
                              >
                                <div style={{ color: '#2194F1', marginRight: '10px' }}
                                     onClick={() => handlePaymentModal(invoice)}>Pay now
                                </div>
                                <ReactSVG
                                  src={pathServer.PATH_IMG + 'icon/download-tray.svg'}
                                  onClick={() => handleOpenRecipes(invoice.typeInvoice, invoice)}
                                  beforeInjection={svg => svg.classList.add('download-icon')}
                                  className="download-icon"
                                />
                              </div>
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
          }
        </div>
      </div>
      {/*<div className="container-payment">*/}
      {/*  <PaymentMethod />*/}
      {/*</div>*/}
      {
        showReceipt ?
          <DPModalInfo showModal={showReceipt} closeModalInfo={() => setShowReceipt(prevState => !prevState)}
                       body={<Receipt invoiceId={selectedInvoice.orderId}/>}
                       classModalContainer="container-general-receipt"/> : null
      }
      {
        showInvoice ?
          <DPModalInfo showModal={showInvoice} closeModalInfo={() => handleOpenRecipes('Bill')}
                       body={<PrintInvoice invoiceId={selectedInvoice.orderId}/>}/> : null
      }

      <Payment show={showPaymentModal}
               close={handleClosePaymentModal}
               selectedOrder={selectedInvoice}
               setReload={setReload}
               paymentMethods={paymentMethods}
      />
    </>
  )
}

export const CurrentDueInformationCard = ({
                                            order,
                                            includedFeatures,
                                            notIncludedFeatures,
                                            isLoading,
                                            onClick,
                                            listings,
                                            teamMembers,
                                            isUpgradeDisabled
                                          }) => {

  const Upgrade = () => {
    browserHistory.push({ pathname: '/main/updatePlan' })
  }

  const getLimitQuantities = (typeQuantity, typeField) => {
    if (typeField === TYPE_FEATURE) {
      return order.subscriptions[0][typeQuantity] === -1 ? '' : order.subscriptions[0][typeQuantity]
    }
    return order.subscriptions[0][typeQuantity] === -1 ? 'Unltd' : order.subscriptions[0][typeQuantity]
  }

  return (
    <div className="current-due-information-card">
      {includedFeatures.length > 0 ?
        <div className="current-features-section">
          <div className="current-due-header-information">
            <div className="header-text">Plan features</div>
            {!isUpgradeDisabled && <div className="header-button" onClick={Upgrade}>Upgrade</div>}
          </div>
          {order && <>
            <IncludedItem messageText={`${getLimitQuantities('teamMembersQuantity', TYPE_FEATURE)} Team members`}
                          progressValue={teamMembers}
                          maxProgressValue={getLimitQuantities('teamMembersQuantity', TYPE_STATUS)}/>
            <IncludedItem messageText={`${getLimitQuantities('listingsQuantity', TYPE_FEATURE)} Listings`}
                          progressValue={listings}
                          maxProgressValue={getLimitQuantities('listingsQuantity', TYPE_STATUS)}/>
          </>}
          {
            includedFeatures.map(feature => {
              return (
                <IncludedItem key={`feature-${feature}`} messageText={feature.name}/>
              )
            })
          }
          {
            notIncludedFeatures.map(feature => {
              return (
                <NotIncludedItem key={`feature-${feature}`} messageText={feature.name}/>
              )
            })
          }
        </div>
        :
        <>
          <div>
            <div className="title">Plan</div>
            <div className="subtitle">There is no plan yet</div>
          </div>
          <div className="select-plan-btn" style={isLoading ? { display: 'none' } : null}
               onClick={onClick}
          >Select a plan
          </div>
        </>
      }
    </div>
  )
}

const BillingContainer = React.forwardRef((props, ref) => {
  const { search, height } = props
  const [invoices, setInvoices] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [invoiceDueSection, setInvoiceDueSection] = useState(null)
  const [reload, setReload] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState(null)
  // const [outstandingBalance, setOutstandingBalance] = useState(119.50)
  // const [otherAmount, setOtherAmount] = useState("")
  // const [selectedAmountOption, setSelectedAmountOption] = useState(FULL_BALANCE)
  // const [errorMessage, setErrorMessage] = useState({})
  const [isAddPaymentMethodMode, setAddPaymentMethodMode] = useState(false)
  const [isDeletePaymentMethodMode, setDeletePaymentMethodMode] = useState(false)
  const [selectedPaymentMethodToken, setPaymentMethodToken] = useState(null)
  const [isExtendedCardsContainer, setExtendedCardsContainer] = useState(false)
  const [order, setOrder] = useState(null)
  const [includedFeatures, setIncludedFeatures] = useState([])
  const [notIncludedFeatures, setNotIncludedFeatures] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const [showPaymentModal, setPaymentModal] = useState(false)
  const [currentInvoice, setCurrentInvoice] = useState(false)
  const [config, setConfig] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [usersAndListings, setUsersAndListings] = useState(null)

  const paramPaymentType = props.location?.query?.formType || 'own'

  const loadData = async () => {
    const { responseCode, responseMessage, data} = await loadInvoices()
    if (responseCode === SUCCESSFUL_CODE) {
      const {invoices, paymentMethods} = data
      const sortedInvoices = reverse(sortBy(invoices, ['orderNumber']))
      unstable_batchedUpdates(() => {
        setInvoices([...sortedInvoices])
        setInvoiceDueSection({ ...sortedInvoices[0] })
        setPaymentMethods(paymentMethods)
      })
    } else {
      console.error(responseMessage)
    }
  }

  useEffect(() => {
    loadData().then()
  }, [reload])

  useEffect(() => {
    const loadData = async () => {
      const {
        responseCode,
        responseMessage,
        data
      } = await loadLastOrder()
      if (responseCode === SUCCESSFUL_CODE) {
        const {
          order,
          includedFeatures,
          notIncludedFeatures,
          subscriptions,
          usersQuantity,
          listingsQuantity
        } = data
        setLoading(false)
        unstable_batchedUpdates(() => {
          setOrder(order)
          setIncludedFeatures(includedFeatures)
          setNotIncludedFeatures(notIncludedFeatures)
          setSubscription(subscriptions[0])
          setUsersAndListings({usersQuantity, listingsQuantity})
        })
      } else {
        console.error(responseMessage)
      }
    }
    loadData().then()
  }, [reload])

  useEffect(() => {
   async function initialize() {
      const {responseCode, responseMessage, data} = await initializePaymentProcessor()
      if (responseCode === SUCCESSFUL_CODE) {
        setConfig(data)
      } else {
        console.error(responseMessage)
      }
    }

    initialize().then()
  }, [])

  const savingNewCard = async (nonce, selectedPlan, requiredElements, isMainCard = false) => {
    const data = {
      nonce: nonce,
      makeDefault: isMainCard,
    }

    setReload(false)

    const {responseCode, responseMessage} = await savePaymentMethod(data)
    if (responseCode === SUCCESSFUL_CODE) {
      unstable_batchedUpdates(() => {
        setAddPaymentMethodMode(false)
        setReload(true)
      })
    } else {
      console.error(responseMessage)
    }
  }

  const handleDeletePaymentMethod = useCallback(
    (token) => {
      unstable_batchedUpdates(() => {
        setDeletePaymentMethodMode(true)
        setPaymentMethodToken(token)
      })
    },
    []
  )

  const memoizedDelete = useCallback(
    async (id) => {
      setReload(false)
      const {responseCode, responseMessage} = await deletePaymentMethod(id)
      if (responseCode === SUCCESSFUL_CODE) {
        setReload(true)
        setDeletePaymentMethodMode(false)
      } else {
        console.error(responseMessage)
      }
    },
    [],
  )

  // function togglePaymentModal() {
  //   setShowPaymentModal(!showPaymentModal)
  // }

  // function selectedAmountOptionHandler(amountOption){
  //   if(selectedAmountOption !== amountOption) {
  //     setSelectedAmountOption(amountOption)
  //   }
  // }

  // function paymentTransaction(token, amount) {
  //   const data = {
  //     refId: "123abc", transactionRequest :{amount: amount, payment :{token: token}}
  //   }
  //
  //   testTransaction(data).then( response => {
  //     setErrorMessage( { message : response?.transactionResponse?.code === "Ok" ? response?.transactionResponse?.description :  response?.transactionResponse?.message,
  //       status: response?.transactionResponse?.code})
  //     setTimeout(() => {setErrorMessage({})}, 4000)
  //   }).catch(err => {
  //     console.log({err})
  //   })
  // }

  // function paymentHandler(e) {
  //   e.preventDefault()
  //
  //   const amount = selectedAmountOption === FULL_BALANCE ? outstandingBalance : +(parseFloat(otherAmount).toFixed(2))
  //   if(amount > 0) {
  //     const formData = new FormData(e.target)
  //     const cardHolder = formData.get('card-holder')
  //     const cardNumber = formData.get('card-number')
  //     const cardCode = formData.get('cvv')
  //     const expDate = formData.get('exp-date')
  //     const address = formData.get('address')
  //     const zip = formData.get('zip')
  //
  //     const cardData = {
  //       cardNumber,
  //       month: expDate.slice(0, 2),
  //       year: expDate.slice(2, expDate.length),
  //       cardCode,
  //       fullName: cardHolder,
  //       zip
  //     }
  //
  //     const secureData = {
  //       authData: authData,
  //       cardData: cardData
  //     }
  //
  //     Accept.dispatchData(secureData, (response) => {
  //       if(!!response.opaqueData){
  //         paymentTransaction(response.opaqueData.dataValue, amount)
  //       }else {
  //         response.messages.message.map(message => {
  //           console.error(message.text)
  //           setErrorMessage({message: message.text, status: "Error"})
  //         })
  //         setTimeout(() => {setErrorMessage({})}, 4000)
  //       }
  //     })
  //   }
  // }

  //--const titleContainerClass = paramPaymentType === 'hosted' ? 'title-container-hosted' : 'title-container'
  // const amountToPay = selectedAmountOption === FULL_BALANCE ? outstandingBalance : +(parseFloat(otherAmount).toFixed(2))

  const handlePayment = () => {
    unstable_batchedUpdates(() => {
      setAddPaymentMethodMode(false)
      setPaymentModal(!showPaymentModal)
    })
  }

  const CurrentDueCard = ({ order }) => {

    const currentInvoice = order ? order : invoices.filter(invoice => invoice.orderEndDate > Moment().valueOf())[0]

    setCurrentInvoice(currentInvoice)

    const getDate = (date) => {
      return Moment(date).add(30, 'days').format('LL')
    }

    const hasInfo = (...args) => {
      return args.some(el => !!el)
    }

    const data =
      {
        'price': subscription?.price || order?.subscriptions[0].unitPrice || currentInvoice?.amount || 0,
        'date': hasInfo(subscription, order, currentInvoice) ? getDate(subscription?.startDate || order?.startDate || currentInvoice?.orderStartDate) : 'Select a plan first',
        'amountTitle': hasInfo(subscription) && 'Tier amount' || hasInfo(order) && 'Amount due' || '',
        'function': hasInfo(subscription, order) && handlePayment || swapModalStatus,
        'button': hasInfo(subscription) && 'Paid' || hasInfo(order) && 'Pay now' || 'Select a plan',
      }

    const buttonStatus = (isLoading || hasInfo(subscription, order)) ? { display: 'none' } : null

    return (
      <div className="current-due-card">
        <div>
          <div>{data.amountTitle}</div>
          <div className="due-price">{`$${data.price}`}</div>
          <div className="subtext">Nex payment due</div>
          <div>{data.date}</div>
        </div>
        <div className="btn select-plan-btn" style={buttonStatus}
             onClick={data.function}>{data.button}</div>
        {hasInfo(subscription, order) &&
          <div className="select-plan-btn" style={{ backgroundColor: '#2C78BA' }}>
            {data.button}
          </div>}
      </div>

    )
  }

  const swapModalStatus = () => {
    unstable_batchedUpdates(() => {
      setAddPaymentMethodMode(false)
      setShowModal(!showModal)
    })
  }

  return (
    <div style={{ display: 'flex' }} ref={ref}>
      <CreateSubscriptionModal swapModalStatus={swapModalStatus} showModal={showModal} setReload={setReload}
                               title="Upgrade Plan"
                               paymentMethods={paymentMethods}/>
      <div className="setting-billing-container" style={{ minHeight: height }}>
        <Invoice invoices={invoices}
                 showLoading={showLoading}
                 paramPaymentType={paramPaymentType}
                 paymentMethods={paymentMethods}
                 setReload={setReload}
                 setAddPaymentMethodMode={setAddPaymentMethodMode}
        />
      </div>
      <BraintreeIntegration
        show={showPaymentModal}
        config={config}
        selectedOrder={currentInvoice}
        setReload={setReload}
        close={handlePayment}
        paymentMethods={paymentMethods}
      />
      <div className="setting-billing-right-panel">
        {/*{*/}
        {/*  !isAddPaymentMethodMode*/}
        {/*  && <div className="current-due-container" style={{minHeight: 0.4 * height}}>*/}
        {/*    <div className="current-due-section">*/}
        {/*      <div className="current-due-section-first-column">*/}
        {/*        <div className="current-due-title">Current due</div>*/}
        {/*        <div className="current-due-value">*/}
        {/*          {*/}
        {/*            (invoiceDueSection && invoiceDueSection.paymentCurrentDue === 0)*/}
        {/*              ? `PAID`*/}
        {/*              : invoiceDueSection?.paymentCurrentDue*/}
        {/*          }*/}
        {/*        </div>*/}
        {/*        <div className="current-due-period">*/}
        {/*          Period: {formatDateToString(invoiceDueSection && invoiceDueSection.orderStartDate)} - {formatDateToString(invoiceDueSection && invoiceDueSection.orderEndDate)}*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      {*/}
        {/*        (invoiceDueSection && invoiceDueSection.paymentCurrentDue > 0)*/}
        {/*          ? <div className="current-due-section-second-column">*/}
        {/*            <Payment selectedOrder={invoiceDueSection} paymentMethods={paymentMethods}/>*/}
        {/*          </div>*/}
        {/*          : null*/}
        {/*      }*/}
        {/*    </div>*/}
        {/*    <div className="payment-due-section">*/}
        {/*      <div>Payment due</div>*/}
        {/*      <div*/}
        {/*        className="payment-due-date">{formatDateToString(invoiceDueSection && invoiceDueSection.orderDueDate)}</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*}*/}

        <CurrentDueCard order={order}/>

        <div className="payment-methods-container">
          {/*style={isAddPaymentMethodMode ? { minHeight: height } : { minHeight: height }}>*/}
          <div className="payment-methods-section">
            <div className="payment-methods-header">
              <div className="payment-method-title">Payment Method</div>

              <ReactSVG
                src={`${pathServer.PATH_IMG}icon/ic_${isAddPaymentMethodMode ? 'less' : 'add'}.svg`}
                beforeInjection={svg => svg.classList.add('icon-add-svg')}
                className="icon-add-new-payment-method"
                onClick={() => setAddPaymentMethodMode(!isAddPaymentMethodMode)}
              />
            </div>

            <div className={`payment-methods-list ${isExtendedCardsContainer && 'extended'}`}>
              {
                paymentMethods && paymentMethods.map(el => {
                  const { token, cardNumber, cardType } = el
                  return (
                    <PaymentMethodItem key={`key-${token}`}
                                       token={token}
                                       creditCardNumber={`XXXX - XXXX - XXXX - ${cardNumber}`}
                                       creditCardType={cardType}
                                       deleteItem={handleDeletePaymentMethod}
                    />
                  )
                })
              }
            </div>
            {paymentMethods && paymentMethods.length > 2 &&
              <div
                className="show-more-option"
                onClick={() => setExtendedCardsContainer(!isExtendedCardsContainer)}
              >{isExtendedCardsContainer ? 'Show less' : 'Show more'}</div>
            }
          </div>

          {
            isAddPaymentMethodMode
            && <PaymentMethod
              onClick={savingNewCard}
              selectedPlan={null}
              showBackBtn={false}
              introMessage={null}
              classCardNumber={'form-group'}
              classExpDate={'form-group col-md-6'}
              classCVV={'form-group col-md-6'}
              classBillingAddress={'form-group col-md-9'}
              classZipCode={'form-group col-md-3'}
              showCheckbox={false}
              checkBoxMessage={'Set as my main card'}
            />
          }

          {
            isDeletePaymentMethodMode
            && <ModalConfirmDeactivate
              show={isDeletePaymentMethodMode}
              onSubmit={async () => await memoizedDelete(selectedPaymentMethodToken)}
              onHide={() => setDeletePaymentMethodMode(false)}
              showButton={true}
              bodyMessage={
                <div>
                  <label>Do you want to delete this Payment Method?</label>
                  <span>Any payment related to this Card will be suspended until you update the payment method.</span>
                </div>
              }
              isBodyCustom={true}
              classButtonDelete={'btn-delete btn-delete-payment'}
              buttonLabelDelete={'Yes, delete'}
              viewMobile={false}
              styleContainer={'modal-delete-payment-method'}
            />
          }
        </div>

        <CurrentDueInformationCard order={order} includedFeatures={includedFeatures}
                                   notIncludedFeatures={notIncludedFeatures} isLoading={isLoading}
                                   onClick={swapModalStatus} teamMembers={usersAndListings?.usersQuantity}
                                   listings={usersAndListings?.listingsQuantity}/>

      </div>
      {/*<PaymentModal show={showPaymentModal}*/}
      {/*              outstandingBalance={outstandingBalance}*/}
      {/*              otherAmount={otherAmount}*/}
      {/*              setOtherAmount={setOtherAmount}*/}
      {/*              selectedAmountOption={selectedAmountOption}*/}
      {/*              selectedAmountOptionHandler={selectedAmountOptionHandler}*/}
      {/*              paymentHandler={paymentHandler}*/}
      {/*              errorMessage={errorMessage}*/}
      {/*              onClick={togglePaymentModal}/>*/}

      {
        /*
        <Payment show={showPaymentModal}
                 close={handleClosePaymentModal}
                 selectedOrder={selectedInvoice}
                 setReload={setReload}
                 paymentMethods={paymentMethods}
        />
        * */
      }

    </div>
  )
})

BillingContainer.propTypes = {
  search: PropTypes.string
}

export default BillingContainer
