import React, {useEffect, useState} from 'react'
import {testTransaction} from '../../../Api'
import Loader from '../../../../common/UI/Loader.jsx'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import {SUCCESSFUL_CODE} from '../../../../common/Constants'

function responseHandler(response) {
  console.log('Response Handler:', response)
  if (response.messages.resultCode === 'Error') {
    let i = 0
    while (i < response.messages.message.length) {
      console.log(
        response.messages.message[i].code + ': ' +
        response.messages.message[i].text
      )
      i = i + 1
    }
  } else {
    paymentFormUpdate(response.opaqueData)
  }
}

function paymentFormUpdate(opaqueData) {
  document.getElementById('dataDescriptor').value = opaqueData.dataDescriptor
  document.getElementById('dataValue').value = opaqueData.dataValue
  EventBus.emit('onPaymentToken', opaqueData)
}

const AcceptUIForm = (props) => {
  const {classButton, amount, config } = props
  const [isLoading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState(null)
  const payment = (data) => {
    const request = {
      refId: "test-Authorize-10",
      transactionRequest: {
        amount: amount || 99,
        payment: {
          token: data?.dataValue
        }
      }
    }
    setLoading(true)
    testTransaction(request).then(response => {
      if (reponse.responseCode === SUCCESSFUL_CODE) {
        setResponseMessage(
          { message: response?.data.transactionResponse?.code === "Ok" ? response?.data.transactionResponse?.description :  response?.data.transactionResponse?.message ,
            status: response?.data.transactionResponse?.code
          })
        setTimeout(() => {setResponseMessage(null)}, 3000)
        setLoading(false)
      } else {
        console.error('error:', response.responseMessage)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    EventBus.on('onPaymentToken', payment)
    window.responseHandler = responseHandler
    return () => EventBus.removeListener('onPaymentToken', payment)
  },[] )
  // clientKey : '5h982Age32RnvBGFsxQLX9YC9s7RyrTw6A6R2TC6tPYsfyCk65X2QfWq49s6f62h',
  //   apiLoginID: '9hTt6A7D2'

  if(isEmpty(config))
    return null

  return(
    <div className='container-form-message'>
      {isLoading && <Loader/>}
      <form id="paymentForm" method="POST" action="">
        <input type="hidden" name="dataValue" id="dataValue"/>
        <input type="hidden" name="dataDescriptor" id="dataDescriptor"/>
        <button type="button"
                className={`AcceptUI ${classButton}`}
                data-billingaddressoptions='{"show":true, "required":false}'
                data-apiloginid={config.apiLoginId}
                data-clientkey={config.clientKey}
                data-acceptuiformbtntxt="Submit"
                data-acceptuiformheadertxt="Card Information"
                data-paymentoptions='{"showCreditCard": true, "showBankAccount": false}'
                data-responsehandler={'responseHandler'}>Pay
        </button>
      </form>
      <div className={responseMessage ? 'container-message' : ''}>
        {responseMessage && <span className={`response-message ${responseMessage.status === "Error" ? 'error' : 'successful'}`}>{responseMessage.message}</span>}
      </div>
    </div>
  )
}

AcceptUIForm.defaultProps = {
  classButton: "",
  config: {apiLoginId: '', clientKey: ''}
}

AcceptUIForm.propTypes = {
  classButton: PropTypes.string,
  config: PropTypes.shape({
    apiLoginId: PropTypes.string,
    clientKey: PropTypes.string
  })
}
export default AcceptUIForm