import React from 'react'
import {DragSource} from 'react-dnd'
import {clone,assign} from 'lodash'

const style = {};

const formControlSource = {
  beginDrag(props) {
    return props.form
  }
};

const controlSourceType = (props) => { return props.status};

const {Component} = React;

class ElementDragDropTask extends Component {

  constructor(){
    super();
  }

  render(){
    const {isDragging,connectDragSource, value, style, labelDrag,styleDrag,classDrag} = this.props;
    let newStyle = style ? clone(style) : {};

    if(isDragging && labelDrag){
      newStyle = assign(newStyle,styleDrag);
    }

    //

    return connectDragSource(
      <div className={"col100 " + (classDrag ? classDrag:"")} style={newStyle}>
        {
          isDragging ?
            <div className="label-drag">
              { labelDrag ? <label>{labelDrag}</label> : null}
              <div style={{opacity:0}}>
                {value}
              </div>
            </div>: value
        }
      </div>
    );
  }

}

ElementDragDropTask = DragSource(controlSourceType, formControlSource,  (connect, monitor) =>{
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
})(ElementDragDropTask);

export default ElementDragDropTask;