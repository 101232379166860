import React, {useEffect, useState} from "react"
import {loadTestimonials, updateTestimonial} from "../../Api"
import Moment from 'moment'
import TestimonialsUpdateButton from "./TestimonialsUpdateButton.jsx"
import {SUCCESSFUL_CODE} from "../../../common/Constants";

const WebhookFacebookMarketing = ({ buttonClass, description, subDescription, updateTestimonials = null}) => {

  const [isLoadingBtnSave, setIsLoadingBtnSave] = useState(false)
  const [isBtnSaved, setIsBtnSaved] = useState(false)
  const [currentDate, setCurrentDate] = useState("")
  const [errorFlag, setErrorFlag] = useState(true)

  const handleDownloadBtn = async () => {
    setCurrentDate(Moment(new Date()).format("MM/DD/YYYY HH:mm"))
    setIsLoadingBtnSave(true)
    try {
      updateTestimonials ? await updateTestimonials() : await updateTestimonial(true)
      setIsLoadingBtnSave(false)
      setIsBtnSaved(true)
      setTimeout(() => setIsBtnSaved(false), 1000)
    } catch (error) {
      setIsLoadingBtnSave(false)
      console.log(error)
    }
  }

  useEffect(async() => {
    const testimonialsResponse =  await loadTestimonials(0, 16)
    const {responseCode, data} = testimonialsResponse
    if (responseCode !== SUCCESSFUL_CODE) {
      return
    }
    let lastSuccessfulUpdateDate = new Date()
    let error = true;
    if (data?.testimonialUpdateHistory?.lastSuccessfulUpdateDate) {
      lastSuccessfulUpdateDate = data.testimonialUpdateHistory.lastSuccessfulUpdateDate;
      error = false;
    }
    setCurrentDate(Moment(lastSuccessfulUpdateDate).format("MM/DD/YYYY HH:mm"))
    setErrorFlag(error)
  }, [])

  return(
    <TestimonialsUpdateButton
      description={description}
      subDescription={subDescription}
      errorFlag={errorFlag}
      currentDate={currentDate}
      buttonClass={buttonClass}
      handleDownloadBtn={handleDownloadBtn}
      isLoadingBtnSave={isLoadingBtnSave}
      isBtnSaved={isBtnSaved}
    />
    )
}

export default WebhookFacebookMarketing

