import React, {useEffect, useState} from 'react'
import {isEmpty} from 'lodash'
import classnames from 'classnames'
import DPIcon from '../DPIcon'

require('./DPTabs.scss')
require('./DPTabNavigationItem.scss')

const DPTab = ({tab, title, classNameHeader, className, onClickTab, isActive = false, checkboxIncluded = false}) => {

  const tabSection = classnames(
    'tab-list-item',
    'tab-pointer',
    className
  )

  const tabHeader = classnames(
    classNameHeader,
    className
  )

  return (
    <div className={tabSection} data-test="tab-section" onClick={() => onClickTab(tab)}>
      <div className={tabHeader}>
        {
          checkboxIncluded &&
          <label className="container-check" style={{marginLeft: '17px'}}>
            <input
              type="checkbox"
              checked={isActive}
              style={{
                width: '15px',
                height: '15px',
              }}
            />
            <span
              className="checkmark"
              style={{
                border: "1.4px #3B86FF solid",
                opacity: isActive ? 1 : 0.3,
                height: '15px',
                width: '15px',
                left: '-15px',
                top: '2px',
                borderRadius: '4px',
              }}
            />
            {
              isActive
                ? <i className="fa fa-check i-check"
                     style={{
                       fontSize: '12px',
                       top: '4px',
                       left: '-14px',
                     }}
                />
                : null
            }
          </label>
        }
        <span style={{marginLeft: '3px'}}>{title}</span>
      </div>
    </div>
  )
}

const DPTabs = ({tabs = [], classNameContainer, classNameTabActive, options = [], otherFunction = null,  id, index, tabActive, checkboxIncluded = false, containerStyle = {}}) => {
  const [activeTab, setActiveTab] = useState("")

  const setCurrentTab = (tab) =>{
    setActiveTab(tab)
    if(otherFunction)
      otherFunction(tab, id, index)
  }

  useEffect(() => {
    if(tabActive){
      setActiveTab(tabActive)
    }else if (!isEmpty(tabs)) {
      setActiveTab(tabs[0].key)
    }
  }, [])

  return (
    <div className="tabs">
      <div key='tab-header' className={classNameContainer} style={containerStyle}>
        {
          tabs.map(( tab, index) => {
            const {key, title, className, classNameHeader} = tab
            const isActive = (activeTab === key)
            return(
              <DPTab key={"test"+index}
                     tab={key}
                     title={title}
                     classNameHeader={classNameHeader}
                     className={isActive ? classNameTabActive : className}
                     onClickTab={setCurrentTab}
                     isActive={isActive}
                     checkboxIncluded={checkboxIncluded}
              />
            )
          })
        }
        <div key='tab-options' className="tab-options">
          {
            options.map(( option, index) => {
              const {key, icon, onClick, show} = option
              if (!show) return  null
              return(
                <DPIcon key={`dp-icon-${index}`}
                        id={`dp-icon-${index}`}
                        icon={icon}
                        onClick={onClick}
                />
              )
            })
          }
        </div>
      </div>
      <div key='tab-content' className="tab-content">
        {tabs.map((tab) => {
          if (tab.key !== activeTab) return undefined;
          return tab.component;
        })}
      </div>
    </div>
  )

}

export default DPTabs