import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setKeyword} from '../../../actions'
import {ReactSVG} from 'react-svg'
import {optionsOrder, SORT_ASC, SORT_DESC} from '../../../Constants'
import Rol from './Rol.jsx'
import ModalConfirmDeactivate from '../../util/ModalConfirmDeactivate.jsx'
import {deleteRolApi, loadPermissions, searchRolApi, verifyCanDeleteRole} from '../../../Api'
import {Popover} from '../../util/ComponentUtils'
import DragDropContext from '../../util/DragDropContext'
import {getPosition} from '../../../../common/Util'
import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'

function getPermissionLabel(permissionQuantity, permissionQuantityAll) {
  let permission = 'Not Selected';
  if(permissionQuantity === permissionQuantityAll){
    permission = 'All Permissions';
  } else if(permissionQuantity > 0){
    permission = `${permissionQuantity} Selected`;
  }
  return permission
}

function getRolInitial() {
  return {
    name: '',
    description: '',
    canDelete: false,
    permissions: []
  }
}

function getMessage(canDelete){
  return canDelete ? "Do you want to delete this role? Once deleted, you won't be able to get it back" :
    "Warning - There is users assigned to this role, please assigned them a different role before deleting this one. "
}

const BodyOption = ({options,selectedOptionOrderId,onChangeSort}) => {
  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {
            options.map((option,index) => {
              return(
                <li key={index} className={option.id === selectedOptionOrderId ? "item-selected" : "change-submenu-item"}
                    onClick={() => onChangeSort(option)}>
                  {option.displayValue}
                </li>
              )
            })
          }
        </u>
      </div>
    </div>
  )
};

function getRolesOrdered(all,selectedOptionOrderId,orderBy){
  let roles = [...all];
  if(selectedOptionOrderId === SORT_ASC)
    roles = roles.sort((a, b) => a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase()));
  else if (selectedOptionOrderId === SORT_DESC)
    roles = roles.sort((a, b) => b[orderBy].toLowerCase().localeCompare(a[orderBy].toLowerCase()));

  return roles
}

class RolList extends Component {
  constructor() {
    super();
    this.edit               = this.edit.bind(this);
    this.handleModalDelete  = this.handleModalDelete.bind(this);
    this.hideModal          = this.hideModal.bind(this);
    this.delete             = this.delete.bind(this);
    this.load               = this.load.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.onChangeSort       = this.onChangeSort.bind(this);
    this.state = {
      isLoading: false,
      listName: {
        id: null,
        displayValue: "Sort By"
      },
      selectedFilter: '',
      roles: [],
      selectedRole: getRolInitial(),
      showModalSave: null,
      showModalDelete: null,
      permissionsQuantityAll: 6,
      permissions: null,
      quantityAll: null,
      canDelete: null,
      showPopover: null,
      selectedOptionOrder: {id:'', displayValue:'Sort By'},
      orderBy: 'name'
    }
  }

  componentWillMount() {
    this.load();
  }

  load(){
    loadPermissions().then(({responseCode, data, responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({permissions: data.permissions, permissionsQuantityAll: data.permissions.length})
      } else {
        console.error(responseMessage)
      }
    })

    this.setState({isLoading: true,showModalSave:false,showModalDelete:false});
    searchRolApi().then(response => {
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {roles,quantityAll} = data;
        const {selectedOptionOrder, orderBy} = this.state;
        const rolesOrdered = getRolesOrdered(roles,selectedOptionOrder.id,orderBy);
        this.setState({roles: rolesOrdered,all:roles,quantityAll:quantityAll,isLoading: false})
      }else{
        this.setState({isLoading: false});
      }
    })
  }

  edit(role, evt){
    evt.stopPropagation();
    this.setState({selectedRole:role, showModalSave:true})
  }

  handleModalDelete(role, evt){
    evt.stopPropagation();
    verifyCanDeleteRole(role.id).then(response => {
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({selectedRole:role, showModalDelete:true, canDelete:data.canDelete})
      }else{
        this.setState({selectedRole:role, showModalDelete:true, canDelete:false})
      }
    });
  }

  hideModal(){
    this.setState({showModalSave:false,showModalDelete:false})
  }

  delete(evt){
    evt.stopPropagation();
    const {selectedRole, canDelete} = this.state;
    if(canDelete){
      deleteRolApi(selectedRole.id).then(response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          this.load();
        }else{
          const ex = {status: responseCode, message: responseMessage}
          console.error(ex);
          this.setState({showModalDelete:false})
        }
      })
    }else{
      this.setState({showModalDelete:false})
    }
  }

  handleRequestClose(){
    this.setState({showPopover: false, filterSelected: null})
  }

  onChangeSort(option){
    const {orderBy,all} = this.state;
    let roles = getRolesOrdered(all,option.id,orderBy);
    this.setState({roles:roles,selectedOptionOrder:option,showPopover:false})
  }

  renderDropDownFilter(type){
    let target = document.getElementById(type);
    let pointTo = getPosition(target);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 28,
      offsetWidth: target.offsetWidth
    };
    this.setState({showPopover: true, anchorEl: anchorEl})
  }

  render() {
    const {roles, isLoading, selectedRole, showModalSave,permissionsQuantityAll, showModalDelete, permissions,
      canDelete,showPopover,selectedOptionOrder,anchorEl} = this.state;
    const {height} = this.props;

    return (
      <div className="setting-container" style={{display: "block", minHeight: height}}>
        <div className="row-rol">
          <div className="form-setting-button">
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"} beforeInjection={ svg => svg.classList.add('icon-add-svg')}
                      className="icon-add-user" onClick={(evt) => this.edit(getRolInitial(),evt)}/>
          </div>

          <div className="btn-container-dp-user">
            <div id="orderRole" className="btn-general-message-dp-customer" onClick={this.renderDropDownFilter.bind(this,"orderRole")}>
              <span>
                {
                  (selectedOptionOrder.id === SORT_ASC || selectedOptionOrder.id === SORT_DESC) ?
                    selectedOptionOrder.displayValue : 'Sort By'
                }
              </span>
              {
                showPopover ?
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                            className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/> :
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                            className="icon-arrow-content" beforeInjection={ svg => svg.classList.add('icon-arrow-expand')}/>
              }
            </div>
          </div>
        </div>

        <div className="table-rol">
          <table className="table">
            <thead style={{backgroundColor: "#EFEFEF"}}>
            <tr>
              <th className="th-user">Name</th>
              <th className="th-user">Description</th>
              <th className="th-phone">Permissions</th>
              <th className="th-phone">Created by</th>
              <th/>
            </tr>
            </thead>
            {
              isLoading ?
                <div className="loader-container">
                  <div className="loader"/>
                </div> :
                <tbody>
                {
                  roles.map((rol, index) => {
                    const {id, name, description, permissions, createdBy} = rol;

                    return (
                      <tr key={index + 'tr'} className="tr-customer" onClick={(e) => this.edit(rol, e)}>
                        <td className="td-user">{name}</td>
                        <td className="td-user">
                          <div className="truncate-desc">{description}</div>
                        </td>
                        <td className="td-phone">{getPermissionLabel(permissions.length,permissionsQuantityAll)}</td>
                        <td className="td-phone">{createdBy}</td>
                        <td className="td-actions">
                          <div className="container-actions">
                            <span>
                            <ReactSVG key={index + 'edit'} id="imgEdit" onClick={(e) => this.edit(rol, e)}
                                      src={pathServer.PATH_IMG + "icon/ic_edit-pencil.svg"}
                                      beforeInjection={ svg => svg.classList.add('img-view')}/>
                          </span>
                            <span>
                            <ReactSVG key={index + 'delete'} id="imgDelete"
                                      src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                                      beforeInjection={ svg => svg.classList.add('img-delete')}
                                      onClick={(e) => this.handleModalDelete(rol, e)}/>
                          </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
                </tbody>
            }
          </table>
        </div>
        {showPopover ? <Popover anchorEl={anchorEl}
                                child={<BodyOption options={optionsOrder}
                                                   selectedOptionOrderId={selectedOptionOrder.id}
                                                   onChangeSort={this.onChangeSort}/>
                                }
                                classPopover="popover-box-message"
                                handleRequestClose={this.handleRequestClose}/> : null
        }
        {
          showModalSave ?
            <Rol role={selectedRole}
                 permissions={permissions}
                 showModal={showModalSave}
                 save={this.load}
                 close={this.hideModal}/> : null
        }
        {
          showModalDelete ?
            <ModalConfirmDeactivate show={showModalDelete}
                                    onSubmit={this.delete}
                                    onHide={this.hideModal}
                                    showButton={true}
                                    message={getMessage(canDelete)}
                                    classButtonDelete={canDelete ? "btn-delete btn-delete-absolute" : "btn-delete"}
                                    buttonLabelDelete={canDelete ? "Yes, delete" : "Go to roles"}
                                    viewMobile={false}
                                    iconQuestion={!canDelete ? "icon/ic_warning.svg" : null}
                                    styleContainer={'modal-deactivate-rol'}
            /> : null
        }
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.email,
  }
};

const Container = (props) => {
  return(
    <DragDropContext>
      <RolList {...props}/>
    </DragDropContext>
  )
};

export default connect(mapStateToProps, {setKeyword})(Container)