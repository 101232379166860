import React from 'react'
import {MESSAGE_REPLY, fonts} from '../../Constants'
import {Editor, EditorState, RichUtils, Modifier} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'
import Outside from '../util/Outside.jsx'
import {ReactSVG} from 'react-svg'
import Dropzone from 'react-dropzone'
import {pathServer} from '../../../common/Constants'
import {checkValidEmail} from '../../../common/ValidationUtil'

const MAX_LENGTH = 540;

export default class MessageReply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      fontFamily: "arial",
      fontSize: [
        {
          size: "x-small",
          name: "Small",
        },
        {
          size: "small",
          name: "Normal",
        },
        {
          size: "Large",
          name: "Large",
        },
        {
          size: "xx-Large",
          name: "Huge",
        },
      ],
      showMenuFontSize: false,
      valueFontSize: "12px",
      boldPress: false,
      underlinePress: false,
      stkPress: false,
      italicPress: false,
      leftPress: true,
      centerPress: false,
      rightPress: false,
      sendMessageTo: [],
      emailTo: "",
      files: []
    };
    this._getLengthOfSelectedText = this._getLengthOfSelectedText.bind(this);
    this._handleBeforeInput = this._handleBeforeInput.bind(this);
    this._handlePastedText = this._handlePastedText.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pressSelect = this.pressSelect.bind(this);
    this.pressAlignment = this.pressAlignment.bind(this);
    this.toggleFontSize = this.toggleFontSize.bind(this);
    this.closeListFontSize = this.closeListFontSize.bind(this);
    this.valueInlineStyle = this.valueInlineStyle.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.addTo = this.addTo.bind(this);
    this.removeTo = this.removeTo.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.sendEmail = this.sendEmail.bind(this);

    this.onChange = (editorState) => this.setState({editorState});
  }

  componentWillMount(){
    let {typeReply, messageSelected} = this.props;
    let {sendMessageTo} = this.state;
    if(typeReply === MESSAGE_REPLY){
      sendMessageTo.push(messageSelected.email);
      this.setState({sendMessageTo:sendMessageTo})
    }
  }

  _getLengthOfSelectedText() {

    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  }

  _handleBeforeInput() {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this._getLengthOfSelectedText();
    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return 'handled';
    }
  }

  _handlePastedText(pastedText) {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      return 'handled';
    }
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({fontFamily: event.target.value});
    document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.fontFamily = event.target.value;
  }

  onChangeSize(op) {
    this.setState({valueFontSize: op.size, showMenuFontSize: false});
    document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.fontSize = op.size;
  }

  toggleFontSize() {
    let {showMenuFontSize} = this.state;
    this.setState({showMenuFontSize: !showMenuFontSize});
  }

  closeListFontSize() {
    this.setState({showMenuFontSize: false});
  }

  valueInlineStyle(name, bool) {
    if (name === "stk") {
      this.setState({stkPress: bool});
      return 'STRIKETHROUGH';
    } else {
      if (name === "bold") {
        this.setState({boldPress: bool});
        return 'BOLD';
      } else {
        if (name === "underline") {
          this.setState({underlinePress: bool});
          return 'UNDERLINE';
        } else {
          this.setState({italicPress: bool});
          return 'ITALIC';
        }
      }
    }
  }

  pressSelect(type) {
    let {editorState} = this.state;
    let inlineStyle = this.valueInlineStyle(type, false);
    let selection = editorState.getSelection();
    let currentStyle = editorState.getCurrentInlineStyle();
    let content = editorState.getCurrentContent();
    let newContent;
    if (selection.isCollapsed()) {
      this.onChange(EditorState.setInlineStyleOverride(editorState,
        currentStyle.has(inlineStyle) ? currentStyle.remove(inlineStyle) : currentStyle.add(inlineStyle)));
    } else {
      inlineStyle = this.valueInlineStyle(type, !currentStyle.has(inlineStyle));
      (currentStyle.has(inlineStyle)) ? (newContent = Modifier.removeInlineStyle(content, selection, inlineStyle)) :
        (newContent = Modifier.applyInlineStyle(content, selection, inlineStyle));
      this.onChange(EditorState.push(editorState, newContent, 'change-inline-style'));
    }
  }

  pressAlignment(btn) {
    if (btn === "right") {
      document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.textAlign = "right";
      this.setState({rightPress: true, centerPress: false, leftPress: false});
    } else {
      if (btn === "center") {
        document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.textAlign = "center";
        this.setState({centerPress: true, rightPress: false, leftPress: false});
      } else if (btn === "left") {
        document.getElementsByClassName("notranslate public-DraftEditor-content").item(0).style.textAlign = "left";
        this.setState({leftPress: true, rightPress: false, centerPress: false});
      }
    }
  }

  handleKeyPress(event) {
    let {sendMessageTo, emailTo} = this.state;
    if (event.key === 'Enter' || event.keyCode === 13 || event.keyCode === 32 || event.key === " ") {
      if (checkValidEmail(emailTo.trim())) {
        sendMessageTo.push(emailTo.trim());
        this.setState({sendMessageTo: sendMessageTo, emailTo: ""})
      }
    }
  }

  handleOutside(){
    let {sendMessageTo, emailTo} = this.state;
    if (checkValidEmail(emailTo.trim())) {
      sendMessageTo.push(emailTo.trim());
      this.setState({sendMessageTo: sendMessageTo, emailTo: ""})
    }
  }

  removeTo(index) {
    let {sendMessageTo} = this.state;
    sendMessageTo.splice(index, 1);
    this.setState({sendMessageTo: sendMessageTo}, () => {
      const input = this.input;
      input.focus();
    })
  }

  addTo(evt) {
    evt.preventDefault();
    this.setState({emailTo: evt.target.value})
  }

  sendEmail() {
    let {sendMessageTo, editorState, files} = this.state;
    let message = {
      sendTo: sendMessageTo,
      body: stateToHTML(editorState.getCurrentContent()).toString()
    };
    this.props.sendEmail(message,files)
  }

  onDrop(filesDrop){
    let {files} = this.state;
    this.setState({uploadFile: true});
    filesDrop.forEach((file) =>{
      files.push({name:file.name,size:Math.round(file.size / 100000)/10,file:file})
    });
    this.setState({files:files});
  }

  render() {
    let {fontSize, showMenuFontSize, valueFontSize, boldPress, stkPress, underlinePress, italicPress,
      rightPress, centerPress, leftPress, editorState, sendMessageTo, emailTo, files
    } = this.state;
    let {newMessage, messageDisplay, showOriginalSize, typeReply} = this.props;
    let messageValue;
    if (messageDisplay) {
      messageValue = (<div style={{marginBottom:10, color:"green"}}>{messageDisplay}</div>)
    }

    const isEmailBodyEmpty = !!editorState.getCurrentContent().getPlainText('\u0001') || !!files.length

    return (
      <div className="row-message message-writer-wrapper">
        {
          (newMessage)?
            <div className="DraftEditor-rootContainer">
              <Editor editorState={editorState}
                      handleKeyCommand={this.handleKeyCommand}
                      handleBeforeInput={this._handleBeforeInput}
                      handlePastedText={this._handlePastedText}
                      onChange={this.onChange} />
            </div> :
            <div id="content-reply" className="message-content-new">
              <div className="send-header">
                <label>{ typeReply === MESSAGE_REPLY ? "Replay to:":"Forward to:"}</label>
                {
                  sendMessageTo.map((to, index) => {
                    return (<div className="message-email" key={index}>
                      <span>{to}</span>
                      <i className="fa fa-times" onClick={this.removeTo.bind(this,index)}/>
                    </div>)
                  })
                }
                <Outside onClose={this.handleOutside.bind(this)}>
                  <input type="email"
                         ref={ref => this.input = ref}
                         autoFocus
                         onChange={this.addTo}
                         onKeyPress={this.handleKeyPress}
                         value={emailTo}/>
                </Outside>
              </div>
              <div className="send-body">
                <Editor editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        handleBeforeInput={this._handleBeforeInput}
                        handlePastedText={this._handlePastedText}
                        onChange={this.onChange} />
              </div>
            </div>
        }
        {
          files.length > 0 ?
            <div className={(newMessage) ? "content-files-new" : "content-files"}>
              {
                files.map((file, index) => {
                  return (
                    <div key={index} className="message-download">
                      <div>
                        <i className="fa fa-file"/>
                        <label>{file.name}</label>
                      </div>
                      <span>{file.size} mb</span>
                      { /*<a>Download</a>*/}
                    </div>
                  )
                })
              }
            </div>: null
        }
        <div className={(newMessage) ? "footer-new" : "footer-reply"} >
          <div className="options-reply">
            <div className="font-family">
              <select className="select-font-family"
                      onChange={(e) => this.handleChange(e)}>
                {
                  fonts.map(font => <option key={font.key} value={font.key}>{font.value}</option>)
                }
              </select>
            </div>

            <Outside onClose={this.closeListFontSize} style={{paddingLeft: "6px", paddingRight: "6px"}}>
              <div className="contentOfFontSize">
                <div className="contentFontSize">
                  <div style={{display: "-webkit-box"}} onClick={() => this.toggleFontSize()}>
                    <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_text_fields.svg"}
                              className="btnSizeImage" style={{fill: (stkPress)?"#0096f8":"#939393"}}/>
                    <img src={pathServer.PATH_IMG + "ic_expand_more.png"} className="btn-expand-more" />
                  </div>
                  {
                    showMenuFontSize ?
                      <div id="list-name-font-size" className="list-fontsize list-sort">
                        <div className="list-name-fontsize">
                          <u name="ul">
                            {
                              fontSize.map((op, index) => {
                                return (
                                  <li key={index} name={op.size} style={{
                                    fontSize: op.size,
                                    paddingLeft: "10px", display: "flex",
                                    height: (op.name === "Huge") ? "45px" : "25px",
                                  }}
                                      className="change-fontsize"
                                      onClick={() => this.onChangeSize(op)}>
                                    {
                                      valueFontSize === op.size ?
                                        <div className="checkFontSize"
                                             style={{
                                               verticalAlign: "middle", userSelect: "none",
                                               color: "white", width: "20px", height: "auto",
                                               marginTop: (op.name === "Huge") ? "7px" : "2px",
                                               marginRight: "10px"
                                             }}>
                                        </div>
                                        :
                                        <div style={{
                                          verticalAlign: "middle", userSelect: "none",
                                          color: "white", width: "20px", height: "auto",
                                          marginTop: (op.name === "Huge") ? "7px" : "2px",
                                          marginRight: "10px"
                                        }}>
                                        </div>
                                    }
                                    <span style={{marginTop: "0px", marginLeft: "0px"}}>
                                  {op.name}
                                  </span>
                                  </li>
                                )
                              })
                            }
                          </u>
                        </div>
                      </div> : null
                  }
                </div>
              </div>
            </Outside>

            <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_stk.svg"}
                      className="btn-format-stk" style={{fill: (stkPress)?"#0096f8":"#939393"}}
                      onClick={() => this.pressSelect("stk")}/>
            <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_bold.svg"}
                      className="btn-format-bold" style={{fill: (boldPress)?"#0096f8":"#939393"}}
                      onClick={() => this.pressSelect("bold")}/>
            <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_italic.svg"}
                      className="btn-format-italic" style={{fill: (italicPress)?"#0096f8":"#939393"}}
                      onClick={() => this.pressSelect("italic")}/>
            <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_underlined.svg"}
                      className="btn-underline" style={{fill: (underlinePress)?"#0096f8":"#939393"}}
                      onClick={() => this.pressSelect("underline")}/>
            <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_align_left.svg"}
                      className="btn-align" style={{fill: (leftPress)?"#0096f8":"#939393"}}
                      onClick={() => this.pressAlignment("left")}/>
            <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_align_center.svg"}
                      className="btn-align" style={{fill: (centerPress)?"#0096f8":"#939393"}}
                      onClick={() => this.pressAlignment("center")}/>
            <ReactSVG src={pathServer.PATH_IMG + "AttributeIcons/ic_format_align_right.svg"}
                      className="btn-align" style={{fill: (rightPress)?"#0096f8":"#939393"}}
                      onClick={() => this.pressAlignment("right")}/>
            <Dropzone onDrop={this.onDrop} className="drop-image" accept=".jpeg,.jpg,.png">
              {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className={'dropzone-container'}>
                    <input {...getInputProps()} />
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_attach_file.svg"}
                              className="btn-attach-file" style={{fill: "#939393"}}/>
                  </div>
              )
              }
            </Dropzone>
            <Dropzone onDrop={this.onDrop} accept=".jpeg,.jpg,.png" className="drop-image">
              {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className={'dropzone-container'}>
                    <input {...getInputProps()} />
                    <ReactSVG src={pathServer.PATH_IMG + "icon/photography-frame.svg"}
                              className="btn-image-photography" style={{fill: "#939393"}}/>
                  </div>
              )
              }
            </Dropzone>
            {
              ((newMessage || (!newMessage && !showOriginalSize) && messageValue)) ?
                <div className="message-display">
                  {messageValue}
                </div>: null
            }
          </div>
          <div className="btn-reply">
            <button className={`btn-message-send ${!isEmailBodyEmpty ? "disabled" :""}`} onClick={this.sendEmail.bind(this)} disabled={!isEmailBodyEmpty}>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_send_24px.svg"}
                        beforeInjection={ svg => svg.classList.add('btn-message-send-icon')}/>
            </button>
          </div>
        </div>
        {(!newMessage && showOriginalSize)?messageValue:null}
      </div>
    );
  }
}