import React, {useState} from 'react'
import {DPProfilePicture} from '../../../common/DPProfilePicture'
import {FACEBOOK_KEY, GOOGLE_KEY, reactions} from '../../Constants'
import {ReactSVG} from 'react-svg'
import DPButtonText from '../../../common/DPButtonText'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import {unstable_batchedUpdates} from 'react-dom'
import {isEmpty} from 'lodash'
import {getPosition} from '../../../common/DPSelectMultipleDropDown'
import {DPPopover} from '../../../common/DPPopover'
import Moment from 'moment'
import {EmptyFunc, pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import {editTestimonialComment, replyGoogleReview, replyTestimonial} from '../../Api'

require('./Review.scss')

export const reactionIcons = {
  [reactions.LIKE]: 'icon/ic_like_reaction.svg',
  [reactions.LOVE]: 'icon/ic_love_reaction.svg',
  [reactions.WOW]: 'icon/ic_wow_reaction.svg',
  [reactions.SAD]: 'icon/ic_sad_reaction.svg',
  [reactions.CARE]: 'icon/ic_care_reaction.svg',
  [reactions.ANGRY]: 'icon/ic_angry_reaction.svg',
  [reactions.HAHA]: 'icon/ic_haha_reaction.svg',
}

export const reactionColors = {
  [reactions.LIKE]: '#3E6DC1',
  [reactions.LOVE]: '#FF3E6C',
  [reactions.WOW]: '#f7b125',
  [reactions.SAD]: '#f7b125',
  [reactions.CARE]: '#f7b125',
  [reactions.ANGRY]: '#e9710f',
  [reactions.HAHA]: '#FFDE75',
}
const EditItem = ({onChange, id, text, cancel, send, objectId}) => {
  return (
    <div className="container-edit-reply">
      <TextareaAutosize
        maxRows={4}
        aria-label="maximum height"
        value={text}
        style={{
          width: '100%',
          resize: 'none',
          outline: 'none',
          border: '1px solid #D7DAE2',
          borderRadius: '4px',
          padding: '2px 10px',
          fontSize: '13px',
          color: '#333333'
        }}
        onChange={(evt) => onChange(evt)}
        id={id}
      />
      <div className="container-reply-buttons">
        <DPButtonText buttonClass="cancel-button" textButton="Cancel" textColor={'#A4AFB7'} onClick={cancel}/>
        <DPButtonText buttonClass="send-button" textButton="Send" textColor={'#A4AFB7'}
                      onClick={() => send(objectId, text)}/>
      </div>
    </div>
  )
}

const StarIconArray = ({qualification}) => {
  let iterator = 0
  let startIconDisplay = []
  while (iterator < 5) {
    if (iterator < qualification) {
      startIconDisplay.push(<ReactSVG key={`star${iterator}`} src={`${pathServer.PATH_IMG}icon/ic_star_review.svg`}
                                      className="icon-star-review"
                                      beforeInjection={svg => {svg.setAttribute('style', `fill: #F3A200`)}}/>)
    } else {
      startIconDisplay.push(<ReactSVG key={`star${iterator}`} src={`${pathServer.PATH_IMG}icon/ic_star_review.svg`}
                                      className="icon-star-review"/>)
    }
    iterator++
  }
  return (
    <>
      {startIconDisplay}
    </>
  )
}

const ReactionsPopoverBody = ({reactions, reactionTypeWithQuantity, setShowPopover}) => {
  const [filterSelected, setFilterSelected] = useState('')
  let reactionsFiltered = reactions
  if (!isEmpty(filterSelected)) {
    reactionsFiltered = reactions.filter(reaction => reaction.reactionType === filterSelected)
  }

  const selectFilter = (event, reactionType) => {
    event.stopPropagation()
    event.preventDefault()
    setFilterSelected(reactionType)
    setShowPopover(true)
  }

  return (
    <div className="popover-body" style={{zIndex: '100', position: 'relative'}}>
      <div className="reactions-head">
        <div className="all-option">
          <span onClick={() => {setFilterSelected('')}}>All</span>
          {
            isEmpty(filterSelected) &&
            <div className={'bottom-line'} style={{borderBottom: `2px solid black`}}/>
          }
        </div>
        {
          reactionTypeWithQuantity.length > 0 &&
          <div className="container-reactions-types">
            {
              reactionTypeWithQuantity.map((item, index) => {
                const {key, quantity} = item
                return (
                  <div key={`reaction-${index}`} className={'reaction-quantity'}
                       onClick={(event) => selectFilter(event, key)}>
                    <ReactSVG
                      src={`${pathServer.PATH_IMG}${reactionIcons[key]}`}
                      className={'icon-of-image'}
                    />
                    <span className="quantity" style={{color: reactionColors[key]}}>{quantity}</span>
                    {
                      filterSelected === key &&
                      <div className={'bottom-line'} style={{borderBottom: `2px solid ${reactionColors[key]}`}}/>
                    }
                  </div>
                )
              })
            }
          </div>
        }
      </div>
      {
        reactionsFiltered.length > 0 &&
        <div className="container-reactions-user">
          {
            reactionsFiltered.map((reaction, index) => {
              const {reactionType, externalUserName, externalUserPicture} = reaction
              return (
                <div key={`reaction-${index}`} className={'reaction-user'}>
                  <div className="container-picture">
                    <img src={externalUserPicture}/>
                    <ReactSVG
                      src={`${pathServer.PATH_IMG}${reactionIcons[reactionType]}`}
                      className={'icon-of-image'}
                    />
                  </div>
                  <span className={'user-name'}>{externalUserName}</span>
                </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}

const getReactionQuantityByType = (reactionList) => {
  let reactionListOrdered = reactionList.sort()
  let counter = 1
  let elementsWithCounter = []

  reactionListOrdered.forEach((item, index) => {
    if (item === reactionListOrdered[index + 1]) {
      counter++
    } else {
      elementsWithCounter.push({key: item, quantity: counter})
      counter = 1
    }
  })

  return elementsWithCounter
}

export const getRatingInNumber = (ratingString) => {
  switch (ratingString){
    case 'FIVE': return 5;
    case 'FOUR': return 4;
    case 'THREE': return 3;
    case 'TWO': return 2;
    case 'ONE': return 1;
    default: return 1;
  }
}

const Review = ({review, archiveReview, handleCheckTag, pageId, getTestimonials, setIsLoading}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState({})
  const [showComments, setShowComments] = useState(false)
  const [objectIdSelected, setObjectIdSelected] = useState(null)
  const [temporalText, setTemporalText] = useState('')
  const [isReplying, setIsReplying] = useState(false)
  const [isReplyingTestimonial, setIsReplyingTestimonial] = useState(false)

  let {
    socialNetwork = FACEBOOK_KEY,
    userPicture,
    userName = '',
    id = 'review',
    tags = [],
    reactions = [],
    comments = [],
    socialNetworkReviewId = '',
  } = review

  const comment = review.comment ?? review.content
  const qualification = review.qualification ?? review.recommendationType

  let commentsTotal = 0
  if (comments.length > 0) {
    commentsTotal = comments.length
    comments.forEach(comment => {
      commentsTotal += comment.subComments.length ?? 0
    })
  }

  const handlePopover = (event) => {
    event.preventDefault()
    event.stopPropagation()

    //const anchorEl = getPosition(`buttonTags${id}`)
    const anchorEl = getPosition(`reaction-${id}`)
    anchorEl.y = anchorEl.y - 4
    setShowPopover(!showPopover)
    setAnchorEl(anchorEl)
  }

  const onChangeTextArea = (evt) => {
    setTemporalText(evt.currentTarget.value)
  }

  const cancelTypeReply = () => {
    unstable_batchedUpdates(() => {
      setIsEditing(false)
      setIsReplying(false)
      setIsReplyingTestimonial(false)
    })
  }

  const handleEditComment = (objectId, temporalText) => {
    unstable_batchedUpdates(() => {
      setTemporalText(temporalText)
      setIsEditing(true)
      setObjectIdSelected(objectId)
    })
  }

  const editComment = async (objectId, newComment) => {
    setIsLoading(true)
    if(socialNetwork === GOOGLE_KEY){
      const data = {
        comment: newComment,
        reviewId: objectId
      }
      replyGoogleReview(data).then( ({responseCode, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE){
          unstable_batchedUpdates(() => {
            setIsEditing(false)
            setObjectIdSelected(null)
            setIsLoading(false)
          })
        }else{
          setIsLoading(false)
          console.error(responseMessage)
        }
      })
    }else{
      const messageObject = {message: newComment}
      const {responseCode, responseMessage} = await editTestimonialComment(objectId, messageObject)
      if (responseCode === SUCCESSFUL_CODE) {
        unstable_batchedUpdates(() => {
          setIsEditing(false)
          setObjectIdSelected(null)
          setIsLoading(false)
        })
        getTestimonials(objectId)
      }
      else {
        setIsLoading(false)
        console.error(responseMessage)
      }
    }
  }

  const handleReply = (objectId) => {
    unstable_batchedUpdates(() => {
      setTemporalText('')
      setIsReplying(true)
      setObjectIdSelected(objectId)
    })
  }

  const sendReplyText = async (objectId, replyText) => {
    setIsLoading(true)
    if(socialNetwork === GOOGLE_KEY){
      const data = {
        comment: replyText,
        reviewId: objectId
      }
      replyGoogleReview(data).then( response => {
        const {responseCode, responseMessage} = response
        if (responseCode === SUCCESSFUL_CODE){
          unstable_batchedUpdates(() => {
            setIsReplying(false)
            setIsReplyingTestimonial(false)
            setObjectIdSelected(null)
            setTemporalText('')
            setIsLoading(false)
          })
        }else{
          setIsLoading(false)
          console.error(responseMessage)
        }
      })
    }else{
      const messageObject = {message: replyText}

      const {responseCode, responseMessage} = await replyTestimonial(objectId, messageObject)
      if (responseCode === SUCCESSFUL_CODE) {
        unstable_batchedUpdates(() => {
          setIsReplying(false)
          setIsReplyingTestimonial(false)
          setObjectIdSelected(null)
          setTemporalText('')
        })
        getTestimonials(objectId)
      }
      else {
        setIsLoading(false)
        console.error(responseMessage)
      }
    }
  }

  const handleReplyTestimonial = () => {
    unstable_batchedUpdates(() => {
      setIsReplyingTestimonial(true)
    })
  }

  let listReactionsUnique = []
  let listReactionsWithQuantity = []
  if (reactions.length > 0) {
    let listReactions = reactions.map(reaction => {
      return reaction.reactionType
    })
    listReactionsWithQuantity = getReactionQuantityByType(listReactions)
    listReactionsUnique = listReactions.filter((reaction, index, reactionsArray) => {
      return reactionsArray.indexOf(reaction) === index
    })
  }

  const getElapsedTime = ( previousTime) => {
    const currentDate = new Date()
    let elapsedTime = ""
    const previousDate = new Date(previousTime)
    previousDate.setHours(previousDate.getHours())
    const milliseconds = currentDate.getTime() - previousDate.getTime()
    const seconds = milliseconds/1000
    const minutes = seconds/60
    if(0 <= minutes  && minutes < 60){
      if( 0<= minutes && minutes < 2 ){
        elapsedTime = "1 minute"
      }else {
        const minutesTrunc = Math.trunc(seconds/60)
        elapsedTime = `${minutesTrunc} minute`
      }
    }else if( 60 <= minutes && minutes < 60*24){
      const hoursTrunc = Math.trunc(minutes/60)
      elapsedTime = `${hoursTrunc} hour`
    } else if( 60*24 <= minutes && minutes < 60*24*7){
      const daysTrunc = Math.trunc(minutes/(60*24))
      elapsedTime = `${daysTrunc} day`
    }else if( 60*24*7 <= minutes && minutes < 60*24*7*365){
      const weeksTrunc = Math.trunc(minutes/(60*24*7))
      elapsedTime = `${weeksTrunc} week`
    }else if(minutes >= 60*24*7*365){
      const yearsTrunc = Math.trunc(minutes/(60*24*365))
      elapsedTime = `${yearsTrunc} year`
    }

    return elapsedTime
  }

  return (
    <div className="container-review">
      <DPProfilePicture socialNetWork={socialNetwork} imageSrc={userPicture}/>
      <div className="box-right">
        <div className="container-title">
          <span className="title">{userName}</span>
          {/**** DO NOT DELETE THIS COMMENT PLEASE ****/}
          {/*<div className="container-tags">
            {
              tags.length > 0 && tags.map((tagElement, index) => {
                const tagOptionFound = tagOptions.find( tag => tag.id === tagElement)
                return(
                  <DPButton style={{ backgroundColor: tagOptionFound.iconColor ?? ''}}>
                    <span>{ tagOptionFound.displayValue ?? ''}</span>
                  </DPButton>
                )
              })
            }
          </div>*/}
          {/**** DO NOT DELETE THIS COMMENT PLEASE ****/}
        </div>
        <div className="container-qualification">
          <div className="qualification">
            {
              socialNetwork === FACEBOOK_KEY ?
                <div className="recommended">
                  {
                    qualification === 'positive' &&
                    <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_recommended.svg`}
                              className="icon-recommended"/>
                  }
                  <span
                    style={qualification !== 'positive' ? {color: '#ff6565'} : {}}>{qualification === 'positive' ? 'Recommended' : 'Not recommended'}</span>
                </div> :
                <StarIconArray qualification={getRatingInNumber(qualification)}/>
            }
          </div>
          <span className="date">{Moment(review.createPostDate).format('MM/DD/YY HH:mm')}</span>
        </div>
        <span className="comment">
          {
            comment ? comment : '(No comment)'
          }
        </span>
        <div className="container-reactions-show-comments">
          <DPButtonText buttonClass="comments-button" textButton={`${commentsTotal} comments`} textColor={'#A4AFB7'}
                          onClick={ commentsTotal > 0 ? () => setShowComments(!showComments) : EmptyFunc}/>
          {
            socialNetwork === FACEBOOK_KEY &&
            <div id={`reaction-${id}`} style={{display: 'flex', alignItems: 'center'}}>
              {
                reactions.length > 0 &&
                <div className="container-reactions"
                     style={{width: `${20 * listReactionsUnique.length - 5 * (listReactionsUnique.length - 1)}px`}}>
                  {
                    listReactionsUnique.length > 0 &&
                    listReactionsUnique.map((reaction, index) => {
                      return (
                        <ReactSVG key={`reaction-${index}`} src={`${pathServer.PATH_IMG}${reactionIcons[reaction]}`}
                                  className="icon-reaction"
                                  beforeInjection={svg => {
                                    svg.setAttribute('style', `left: ${-5 * index}px; z-index:${10 - index}`)
                                  }}/>
                      )
                    })
                  }
                </div>
              }
              <span className="reaction-text"
                    onClick={reactions.length > 0 ? (evt) => handlePopover(evt) : EmptyFunc}>{`${reactions.length} reactions`}</span>
            </div>
          }

        </div>
        {
          showComments &&
          <>
            {
              (comments.length > 0 &&
                comments.map((comment, index) => {
                  const {content, externalUserName, externalUserId, id, externalObjectId, createPostDate, referenceId} = comment.comment ?? {}
                  const subCommentsToDisplay = comment.subComments ?? []
                  const isOwnerPage = externalUserId === pageId
                  const sendFunction = isOwnerPage || socialNetwork === GOOGLE_KEY ? handleEditComment : handleReply
                  const textButton = isOwnerPage || socialNetwork === GOOGLE_KEY  ? 'Edit' : 'Reply'
                  const commentUserText = isOwnerPage || socialNetwork === GOOGLE_KEY
                                          ? `Replied by ${isEmpty(externalUserName) ? "owner" : externalUserName}`
                                          : `${externalUserName} on Facebook`
                  return (
                    <div key={`comment-${index}`} className={'container-comments'}>
                      {
                        isEditing && objectIdSelected === id ?
                          <EditItem
                            id={id}
                            onChange={onChangeTextArea}
                            text={temporalText}
                            cancel={cancelTypeReply}
                            objectId={ socialNetwork === GOOGLE_KEY ? referenceId : externalObjectId}
                            send={editComment}
                          />
                          :
                          <div className="reply-container" style={{borderColor: isOwnerPage ? '#4AD991' : '#A4AFB7'}}>
                            <span className="comment-reply">{content}</span>
                            <div className="container-reply-by">
                              <span>{`${commentUserText} - ${getElapsedTime(createPostDate)} ago`}</span>
                              <DPButtonText className="edit-reply" textButton={textButton}
                                            onClick={() => sendFunction(id, content)}/>
                            </div>
                          </div>
                      }
                      {
                        <div className={'container-sub-comments'}>
                          {
                            subCommentsToDisplay.length > 0 &&
                            subCommentsToDisplay.map((subComment, index) => {
                              const {content, externalUserName, externalUserId, id, externalObjectId, createPostDate} = subComment ?? {}
                              const isOwnerPage = externalUserId === pageId
                              const commentUserText = isOwnerPage ? `Replied by ${externalUserName}` : `${externalUserName} on Facebook`
                              return (
                                <div key={`subComment-${id}`}>
                                  {
                                    isEditing && objectIdSelected === id ?
                                      <EditItem
                                        id={id}
                                        onChange={onChangeTextArea}
                                        text={temporalText}
                                        cancel={cancelTypeReply}
                                        objectId={externalObjectId}
                                        send={editComment}
                                      />
                                      :
                                      <div className="reply-container"
                                           style={{borderColor: isOwnerPage ? '#4AD991' : '#A4AFB7'}}>
                                        <span className="comment-reply">{content}</span>
                                        <div className="container-reply-by">
                                          <span>{`${commentUserText} - ${getElapsedTime(createPostDate)} ago`}</span>
                                          {
                                            isOwnerPage &&
                                            <DPButtonText className="edit-reply" textButton="Edit"
                                                          onClick={() => handleEditComment(id, content)}/>
                                          }
                                        </div>
                                      </div>
                                  }
                                </div>
                              )
                            })
                          }
                          {
                            isReplying && id === objectIdSelected &&
                            <EditItem
                              id={'id'}
                              onChange={onChangeTextArea}
                              text={temporalText}
                              cancel={cancelTypeReply}
                              objectId={externalObjectId}
                              send={sendReplyText}
                            />
                          }
                        </div>
                      }
                    </div>
                  )
                })
              )
            }
          </>
        }
        {
          isReplyingTestimonial &&
          <EditItem
            id={id}
            onChange={onChangeTextArea}
            text={temporalText}
            cancel={cancelTypeReply}
            objectId={socialNetworkReviewId}
            send={sendReplyText}
          />
        }
        <div className="options-container">
          {
            (socialNetwork !== GOOGLE_KEY || comments.length !== 1) &&
            <DPButtonText textButton="Reply" iconPath="ic_reply_review.svg" textColor="#535353"
                          onClick={() => handleReplyTestimonial()}/>
          }
          <DPButtonText textButton={!!review.archive ? 'Unarchive' : 'Archive'} iconPath="ic_archive.svg"
                        textColor="#535353" onClick={() => archiveReview(id)}/>
          {/**** DO NOT DELETE THIS COMMENT PLEASE ****/}
          {/*<DPButtonText id={`buttonTags${id}`} textButton='Tags' iconPath='ic_tag.svg' textColor='#535353' onClick={(evt) => handlePopover(id, evt)}/>*/}
          {/*{
            showPopover &&
            <DPPopover
              styleWrapper={{ width: '156px', padding: '5px', border: '1px solid #F5F6FA', borderRadius: '4px', boxShadow: '0px 6px 6px #F5F6FA'}}
              anchorEl={anchorEl}
              classNameWrapper="popover-for-select"
              handleRequestClose={() => setShowPopover(false)}>
              {<RenderBody options={tagOptions}  selectedOptionsId={tags} onCheck={(optionId) => handleCheckTag(optionId, id)}/>}
            </DPPopover>
          }*/}
          {/**** DO NOT DELETE THIS COMMENT PLEASE ****/}
          {
            showPopover &&
            <DPPopover
              styleWrapper={{
                width: 'auto',
                padding: '0',
                border: '1px solid #F5F6FA',
                borderRadius: '4px',
                boxShadow: '0px 6px 6px #F5F6FA'
              }}
              anchorEl={anchorEl}
              classNameWrapper="popover-for-select"
              handleRequestClose={() => setShowPopover(false)}>
              {<ReactionsPopoverBody reactions={reactions} reactionTypeWithQuantity={listReactionsWithQuantity}
                                     setShowPopover={setShowPopover}/>}
            </DPPopover>
          }
        </div>
      </div>
    </div>
  )
}

Review.defaultProps = {
  archiveReview: EmptyFunc,
}

Review.propTypes = {
  review: PropTypes.shape({
    userName: PropTypes.string,
    socialNetwork: PropTypes.string,
    comment: PropTypes.string,
    commentReply: PropTypes.string,
    userProfilePicture: PropTypes.string,
    qualification: PropTypes.number,
    tags: PropTypes.array,
    recommendationType: PropTypes.string,
    content: PropTypes.string,
    comments: PropTypes.array,
    reactions: PropTypes.array
  }),
  archiveReview: PropTypes.func,
  handleCheckTag: PropTypes.func,
  updateCommentReply: PropTypes.func,
}

export default Review