import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {
  LOCATION_STATUS_ACTIVE,
  LOCATION_STATUS_INACTIVE
} from '../../../Constants'
import {Popover} from '../../util/ComponentUtils'
import InputElement from 'react-input-mask'
import {saveLocationApi} from '../../../Api'
import isEmpty from 'lodash/isEmpty'
import {find, reduce} from 'lodash'
import {checkValidEmail, checkValidPhone, checkValidZipCode} from '../../../../common/ValidationUtil'
import {getAnchorEl, validateError} from '../../util/Utils'
import {
  optionState,
  cellphoneMask,
  VALIDATION_REQUIRED,
  VALIDATION_PHONE_NUMBER,
  VALIDATION_EMAIL,
  VALIDATION_NOT_REQUIRED,
  SUCCESSFUL_CODE
} from '../../../../common/Constants'
import LocationUpdate from './LocationUpdate'
import {getRandomId} from '../../../Utils'
import {
  LOCATION_ADDRESS,
  LOCATION_CITY,
  LOCATION_NAME,
  LOCATION_STATE,
  LOCATION_ZIP
} from '../../../../common/models/Location'

require('./SettingLocalModal.scss')

export const locationStatuses = [
  {
    id: LOCATION_STATUS_ACTIVE,
    displayValue: "Active",
    color: "#4AD991"
  },
  {
    id: LOCATION_STATUS_INACTIVE,
    displayValue: "Inactive",
    color: "#FF6565"
  }
];

export const fieldsRequired = [
  {key: VALIDATION_PHONE_NUMBER, type: VALIDATION_REQUIRED, validator: checkValidPhone},
  {key: LOCATION_ZIP, type: VALIDATION_REQUIRED, validator: checkValidZipCode},
  {key: VALIDATION_EMAIL, type: VALIDATION_NOT_REQUIRED, validator: checkValidEmail},
  {key: LOCATION_ADDRESS, type: VALIDATION_REQUIRED},
  {key: LOCATION_NAME, type: VALIDATION_REQUIRED},
  {key: LOCATION_CITY, type: VALIDATION_REQUIRED},
  {key: LOCATION_STATE, type: VALIDATION_REQUIRED},
]

const getError = (location) => {
  return reduce( fieldsRequired, (errors, fieldRequired ) => {
    const {key, type, validator} = fieldRequired
    const value = [VALIDATION_PHONE_NUMBER,VALIDATION_EMAIL].includes(key) ? location.info[key] : location[key]
    if( type === VALIDATION_REQUIRED){
      errors[key] = validator ? !validator(value) : isEmpty(value)
    }else if( type === VALIDATION_NOT_REQUIRED){
      errors[key] =  !isEmpty(value) && validator ? !validator(value) : false
    }
    return errors
  }, {})
}

const InputMask = ({classCol, classItem, value, displayValue, onChangeInput, name, mask, isRequired, error}) => {
  return (
    <div className={(error ? classCol + ' has-error' : classCol)}>
      <div className={classItem}>
        <div>
          <label>{displayValue}
          {isRequired ? <span className="label-error">*</span> : null}
          </label>
        </div>
        <InputElement type="text"
                      className="form-control applicant-input"
                      mask={mask}
                      onChange={(e) => onChangeInput(name, e)}
                      value={value}/>
        {error ?
          isEmpty(value) ?
            <p className="help-block">{getErrorMessage(displayValue)}</p> :
            <p className="help-block">{getMessageInvalid(displayValue)}</p>
          : null}
      </div>
    </div>
  );

};

const getErrorMessage = (name) => {
  return name + ' is required'
}

const getMessageInvalid = (name) => {
  return 'Please enter a valid ' + name
}

//todo Don't delete yet.This component [BodyLocation] is being used in Dashboard.jsx.
export const BodyLocation = ({errors, location, inputChange, anchorElStatus, showPopoverSelected, showOptionSelected,
                               saveAndUpdateLocation, handleRequestClose, renderHours, disabled, showPopoverModal, renderObject, style}) => {

  const {name, address, address2, city, zip, info, state, status} = location;
  const {phoneNumber, email, hoursOfOperation} = info;
  let locationFound = find(locationStatuses, loc => {
    return loc.id === location.status
  });
  return(
    <div className="container-body" style={{padding: style ? 15:0}}>
      <div className="left-section">
        <div className="first-section">
          <div className="container-title">
            <label className="title">Location address</label>
          </div>
          <div className="first-row">
            <div className="colR27">
              <div className="col-finance">
                <div>
                  <div><label>Location name</label></div>
                  <span className="label-error">*</span>
                  <div className="">
                    <input type="text" className={errors["name"] ? "form-control finance-input has-error" : "form-control finance-input"}
                           id="nameLocation" value={name} placeholder=""
                           onChange={(evt) => inputChange('name', evt)}/>
                    {errors["name"] ?
                        <p className="help-block">{getErrorMessage("name")}</p> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-status">
              <div id="statusLocation" className="select" onClick={(evt) => {showPopoverModal("statusLocation", evt)}}>
                <div className="container-label">
                  <label className="label-status-location"
                         style={{color: locationFound ? locationFound.color : ""}}>
                    {locationFound ? locationFound.displayValue : ''}</label>
                </div>
                <div className="container-icon">
                  <i className="fa fa-chevron-down"/>
                </div>
              </div>
            </div>
            { !!location.id &&
              <div className="colR27">
                <div className="col-finance">
                  <div>
                    <div><label>Location id</label></div>
                    <div className="">
                      <input type="text"
                             className="form-control finance-input"
                             id="locationId"
                             value={location.id}
                             readOnly/>
                      {errors["name"] ?
                        <p className="help-block">{getErrorMessage("name")}</p> : null}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="second-row">
            <div className="colR27">
              <div className="col-finance">
                <div>
                  <div className="label-finance"><label>Address</label></div>
                  <span className="label-error">*</span>
                  <div className="">
                    <input type="text" className={errors["address"] ? "form-control finance-input has-error" : "form-control finance-input"}
                           id="addressLocation" value={address} placeholder=""
                           onChange={(evt) => inputChange('address', evt)}/>
                    {errors["address"] ?
                        <p className="help-block">{getErrorMessage("Address")}</p> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="colR27">
              <div className="col-finance">
                <div>
                  <div className="label-finance"><label>Address</label></div>
                  <div className="">
                    <input type="text" className="form-control finance-input" id="address2Location"
                           value={address2} placeholder=""
                           onChange={(evt) => inputChange('address2', evt)}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="colR16">
              <div className="col-finance">
                <div>
                  <div className="label-finance"><label>City</label></div>
                  <span className="label-error">*</span>
                  <div className="">
                    <input type="text"
                           className={errors["city"] ? "form-control finance-input has-error" : "form-control finance-input"}
                           id="cityLocation"
                           value={city} placeholder="" onChange={(evt) => inputChange('city', evt)}/>
                    {errors["city"] ?
                      <p className="help-block">{getErrorMessage("City")}</p> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-select">
              <div>
                <label className="label-city">State</label>
                <span className="label-error">*</span>
              </div>
              <div className="select" id="selectState" onClick={(evt) => showPopoverModal("selectState", evt)}>
                <div className="container-label">
                  <label className="label-status-location">{state}</label>
                </div>
                <div className="container-icon">
                  <i className="fa fa-chevron-down"/>
                </div>
              </div>
              {errors["state"] ?
                <p className="help-block">{getErrorMessage("State")}</p> : null}
            </div>
            <div className="colR12">
              <div className="col-finance">
                <div>
                  <div className="label-finance"><label>ZIP code</label></div>
                  <span className="label-error">*</span>
                  <div className="">
                    <input type="text"
                           className={errors["zip"] ? "form-control finance-input has-error" : "form-control finance-input"}
                           id="zipLocation" value={zip} placeholder="" maxLength={5}
                           onChange={(evt) => inputChange('zip', evt)}/>
                    {errors["zip"] ?
                      <p className="help-block">{getMessageInvalid("ZIP code")}</p> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          showOptionSelected === "statusLocation" && showPopoverSelected ?
              <Popover anchorEl={anchorElStatus}
                       child={renderObject(locationStatuses,"status")}
                       classPopover="popover-status-location"
                       handleRequestClose={handleRequestClose}/> : null
        }
        {
          showOptionSelected === "selectState" && showPopoverSelected ?
              <Popover anchorEl={anchorElStatus}
                       child={renderObject(optionState,"state")}
                       classPopover="popover-state-location"
                       handleRequestClose={handleRequestClose}/> : null
        }
        <div className="second-section setting-location">
          <div className="container-title">
            <label className="title">Dealer contact information</label>
          </div>
          <div className="first-row">
            <InputMask classCol="colR27" classItem="item" displayValue="Main phone number"
                       value={phoneNumber} onChangeInput={inputChange} name="phoneNumber" mask={cellphoneMask}
                       error={errors["phoneNumber"]} isRequired={true}/>
            <div className="colR40">
              <div className="col-finance">
                <div>
                  <div className="label-finance"><label>Main e-mail address</label></div>
                  <div className="">
                    <input type="text" className={errors["email"] ? "form-control finance-input has-error" : "form-control finance-input"}
                           id="emailLocation"
                           value={email} placeholder="" onChange={(evt) => inputChange('email', evt)}/>
                    {errors["email"] ?
                        <p className="help-block">{getMessageInvalid("Main e-mail address")}</p> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="container-title">
          <label className="title">Work hours</label>
        </div>
        <div className="first-section">
          <div className="body-sec-hours-locations">
            <div className="titles">
              <span className="label-sec-days">Days</span>
              <span className="label-sec-days">From</span>
              <span className="label-sec-days">To</span>
            </div>
            {
              hoursOfOperation ?
                hoursOfOperation.map((hour, index) => {
                  return (
                      <div key={index} className="row-days">
                        <div className="days">
                          <label className="label-sec">{hour.displayValue}</label>
                        </div>
                        <div className="days" id={'from' + index} onClick={(evt) => {
                          showPopoverModal(`${index}from`, evt)
                        }}>
                          <label className="label-sec" style={disabled[index] ? {color:"#FF6565"} : null}>{hour.from}</label>
                          <i className="fa fa-chevron-down"/>
                        </div>
                        <div className={!disabled[index] ? "days":"days-disabled"} id={'to' + index}
                             onClick={!disabled[index] ? (evt) => {showPopoverModal(`${index}to`, evt)}: null}>
                          <label className="label-sec">{hour.to}</label>
                          <i className="fa fa-chevron-down"/>
                        </div>
                      </div>
                  )
                }) : null
            }
          </div>
        </div>
        <div className="second-section">
          <div className="first-container">
            <button className="btn-save" onClick={saveAndUpdateLocation}>Save</button>
          </div>
        </div>
        {
          showOptionSelected === "hour" && showPopoverSelected ?
              <Popover anchorEl={anchorElStatus}
                       child={renderHours()}
                       classPopover="popover-hour-location"
                       handleRequestClose={handleRequestClose}/> : null
        }
      </div>
    </div>
  )
};

export function locationDefault() {
  return{
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    info: {
      phoneNumber: '',
      email: '',
      openingHours: [
        {
          days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
          from: "09:00",
          to: "17:00",
          format24: true,
          optionSelected: 'specificHours'
        },
      ]
    },
    status: 'active',
    temporalId:  getRandomId()
  }
}

const getDaysFromString = (value) => {
  return value === "Mon-Fri" ? ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']: [value.toLowerCase()]
}

const getOptionSelected = (value) => {
  return value === "Closed" ? "closed" : "specificHours"
}

const getNewFormForHoursOperation = (hoursOfOperation) => {
  return hoursOfOperation.map( ({displayValue, from, to}) => ({
      from: from === "Closed" ? "09:00" : from.length === 5 ? from : `0${from}`,
      to: to,
      format24: true,
      optionSelected: getOptionSelected(from),
      days: getDaysFromString(displayValue)
    })
  )
}

export const addOpeningHourToLocation = (location) => {
  const {info} = location
  let {hoursOfOperation = []} = info
  info.hoursOfOperation = [...hoursOfOperation,
    { days: [],
      optionSelected: 'specificHours',
      format24: true,
      from: '08:00',
      to: '17:00'
    }
  ]
  return location
}
export const updateLocationOpeningHourOption = (location, value, key, index) => {
  const {info} = location
  const {hoursOfOperation = []} = info
  hoursOfOperation[index][key] = value
  return location
}

export const deleteOpeningHourAtIndex = (location, index) => {
  const {info} = location
  let {hoursOfOperation = []} = info
  const updatedInfo = {
    ...info,
    hoursOfOperation: hoursOfOperation.filter((item, indexItem) => indexItem !== index)
  };
  return {
    ...location,
    info: updatedInfo
  };
  // if(hoursOfOperation.length > 0){
  //   info.hoursOfOperation = hoursOfOperation.filter((item, indexItem) => indexItem !== index)
  // }
  // return location
}

export default class SettingLocationModal extends Component {

  constructor() {
    super();
    this.state = {
      anchorElStatus: null,
      showLoading: false,
      location: locationDefault(),
      keyHour: '',
      indexHour: '',
      errors: {},
      disabled: [],
      showOptionSelected: false,
      showPopoverSelected: null
    }
  }

  componentDidMount() {
    let {locationId, allLocations} = this.props;
    let {location,disabled} = this.state;
    if (locationId !== "new") {
      const locationSelected = allLocations.find((location) => {
        return location.locationId === locationId;
      });
      
      if (locationSelected) {
        location = {...locationSelected}

        if (locationSelected.info && locationSelected.info !== "") {
          location.info = JSON.parse(locationSelected.info);
          const { hoursOfOperation = [] } = location.info
          if(hoursOfOperation && hoursOfOperation.length > 0){
            if(hoursOfOperation[0].displayValue){
              location.info.hoursOfOperation = getNewFormForHoursOperation(hoursOfOperation)
            }
          }
        }
        this.setState({location,disabled})
      }
    }
  }

  showPopover = (id,event) =>{
    event.preventDefault();
    let {keyHour, indexHour, showPopoverSelected, showOptionSelected} = this.state;
    const target = event.currentTarget;
    let anchorEl = getAnchorEl(target,2);
    if(id === "statusLocation"){
      showOptionSelected = id
    } else if(id === "selectState") {
      showOptionSelected = id
    } else{
      showOptionSelected = "hour";
      indexHour = id.substring(0,1);
      keyHour = id.substring(1,(id.length))
    }
    this.setState({anchorElStatus: anchorEl, indexHour, keyHour, showOptionSelected: showOptionSelected, showPopoverSelected: !showPopoverSelected})
  }

  handleRequestClose = () => {
    this.setState({showPopoverSelected: false, showOptionSelected: null})
  }

  selectObject = (object,type) =>{
    let {location} = this.state;
    location[type] = object;
    this.setState({location, showPopoverSelected: false, showOptionSelected: null})
  }

  selectHour = (hour, key, index) => {
    const {location} = this.state
    const {info} = location
    const {hoursOfOperation = []} = info
    hoursOfOperation[index][key] = hour
    this.setState({location})
  }

  selectOptionInOpeningHour = (value, key, index) => {
    const {location} = this.state
    this.setState({location: updateLocationOpeningHourOption(location, value, key, index)})
  }

  inputChange = (key, event) => {
    let {location} = this.state;
    let value = event.target.value;
    if (key === VALIDATION_PHONE_NUMBER || key === VALIDATION_EMAIL) {
      location.info[key] = value;
    } else {
      location[key] = value;
    }
    this.setState(location);
  }

  saveAndUpdateLocation = () => {
    let {closeModal, searchForm} = this.props;
    const {location, errors} = this.state;
    const error = getError(location);
    const hasError = validateError(error);
    if (!hasError) {
      location.info = JSON.stringify(location.info);
      location.id = location.locationId
      const addressForGeoCoord = `${location.address}, ${location.city}, ${location.state} ${location.zip}, ${location.country}`
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({'address': addressForGeoCoord}, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const latitude = results[0].geometry.location.lat();
          const longitude = results[0].geometry.location.lng();

          location['latitude'] = latitude.toString()
          location['longitude'] = longitude.toString()

          this.setState({showLoading: true});
          saveLocationApi(location).then(({responseCode}) => {
           if (responseCode === SUCCESSFUL_CODE) {
             closeModal();
             searchForm();
             this.setState({showLoading: false, location: locationDefault()});
           } else {
             location.info = JSON.parse(location.info);
             this.setState({showLoading: false, location: location});
             closeModal();
           }
          });
        } else {
          const geoCoordinateError = {}
          geoCoordinateError['geocoord'] = 'Error on Geo Coordinates'
          this.setState({errors: geoCoordinateError});
        }
      }.bind(this))
    } else {
      this.setState({errors: error});
    }
  }

  addOpeningHour = () => {
    const {location} = this.state
    this.setState({location: addOpeningHourToLocation(location)})
  }

  deleteOpeningHour = (index) => {
    const {location} = this.state
    this.setState({ location: deleteOpeningHourAtIndex(location, index) });
  }

  render() {
    const {showModal, closeModal, locationId} = this.props;
    const {errors, location, showLoading} = this.state;

    return (
      <div>
        {
          showLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> :
            <Modal show={showModal} onHide={closeModal} className="location-modal-container setting-location">
              <Modal.Body>
                <LocationUpdate
                  location={location}
                  inputChange={this.inputChange}
                  selectObject={this.selectObject}
                  selectHour={this.selectHour}
                  addOpeningHour={this.addOpeningHour}
                  selectOptionInOpeningHour={this.selectOptionInOpeningHour}
                  deleteOpeningHour={this.deleteOpeningHour}
                  saveAndUpdateLocation={this.saveAndUpdateLocation}
                  isNew={locationId === 'new'}
                  errors={errors}
                />
              </Modal.Body>
            </Modal>
        }
      </div>
    )
  }
}