import React from 'react'
import PropTypes from 'prop-types'
import {DPInput} from '../../../common/DPInput'
import {COLUMNS} from './SaleSetting'
import {DPDynamicNumberInput} from '../../../common/DPDynamicNumberInput'
import {getDynamicNumberValue} from '../../util/Utils'
import SaleSettingUpdateFormBodyForFees from './SaleSettingUpdateFormBodyForFees'

export const PROPERTY = {
  NAME: 'name',
  CODE: 'code',
  DESCRIPTION: 'description',
  NAME_ACCESSORY: 'name',
  PRICE: 'unitPrice',
  FEE_DESCRIPTION: 'description',
  FEE_COST: 'value',
}

export const initialErrorMessagesObject = {
  [PROPERTY.NAME]: '',
  [PROPERTY.CODE]: '',
  [PROPERTY.DESCRIPTION]: '',
  [PROPERTY.FEE_DESCRIPTION]: '',
}

export const TITLES = {
  [PROPERTY.NAME]: 'File name',
  [PROPERTY.CODE]: 'Code',
  [PROPERTY.DESCRIPTION]: 'Description',
  //[PROPERTY.NAME_ACCESSORY]: 'Accessory name',
  [PROPERTY.PRICE]: 'Unit price',
  [PROPERTY.FEE_DESCRIPTION]: 'Other non-taxable item description',
  [PROPERTY.FEE_COST]: 'Cost',
}

class SaleSettingUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.state = {
      isEditing: false,
      element: {
        ...this.props.element
      },
      errorMessages: {...initialErrorMessagesObject}
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.element !== prevProps.element) {
      this.setState(
        {
          element: this.props.element,
          errorMessages: {...initialErrorMessagesObject}
        }
      )
    }
  }

  // Updates an element
  onChange(property, value) {
    let updatedElement = {...this.state.element}
    updatedElement = {
      ...updatedElement,
      [property]: value,
    }

    this.setState({ element: updatedElement })
  }

  onChangePrice(property,modelValue,viewValue,evt){
    let updatedElement = {...this.state.element}
    updatedElement = {
      ...updatedElement,
      [property]: getDynamicNumberValue(modelValue,viewValue),
    };
    this.setState({ element: updatedElement })
  }

  // Save an element
  onSave() {
    const currentElement = {...this.state.element}
    const { isValid, errorMessages } = this.props.validateElement(currentElement, this.props.columnName);
    if (isValid) {
      this.props.closeForm()
      this.props.updateElement(this.state.element, this.props.columnName)
    } else {
      this.setState({ errorMessages })
    }
  }

  render() {
    const {element} = this.state;
    return (
      <div className="container-edit-form">
        <div className='edit-form'>
          {
            this.props.columnName === COLUMNS.FILES
              ? <React.Fragment>
                <DPInput
                  className='form-field'
                  title={TITLES[PROPERTY.NAME]}
                  value={this.state.element.name}
                  onChange={evt => this.onChange(PROPERTY.NAME, evt.target.value)}
                  errorMessage={this.state.errorMessages[PROPERTY.NAME]}
                />
                <DPInput
                  className='form-field'
                  title={TITLES[PROPERTY.CODE]}
                  value={this.state.element.code}
                  onChange={evt => this.onChange(PROPERTY.CODE, evt.target.value)}
                  errorMessage={this.state.errorMessages[PROPERTY.CODE]}
                />
              </React.Fragment>
              : this.props.columnName === COLUMNS.CHECKLIST
                ? <DPInput
                  className='form-field'
                  title={TITLES[PROPERTY.DESCRIPTION]}
                  value={this.state.element.description}
                  onChange={evt => this.onChange(PROPERTY.DESCRIPTION, evt.target.value)}
                  errorMessage={this.state.errorMessages[PROPERTY.DESCRIPTION]}
                />
                : this.props.columnName === COLUMNS.REG_FEES_AND_OTHERS
                  ? <SaleSettingUpdateFormBodyForFees
                    element={this.state.element}
                    errorMessages={this.state.errorMessages}
                    onChange={(property, value) => this.onChange(property, value)}
                    onChangePrice={(property, modelValue, viewValue, evt) => this.onChangePrice(property, modelValue, viewValue, evt)}
                  />
                  : <React.Fragment>
                  <DPInput
                    className='form-field'
                    title={'Accessory Name'}
                    value={this.state.element.name}
                    onChange={evt => this.onChange(PROPERTY.NAME_ACCESSORY, evt.target.value)}
                    errorMessage={this.state.errorMessages[PROPERTY.NAME_ACCESSORY]}
                  />
                  <DPDynamicNumberInput
                    className={'form-field'}
                    title={TITLES[PROPERTY.PRICE]}
                    id={'inputPrice'}
                    value={this.state.element.unitPrice}
                    onChange={(evt, modelValue, viewValue) => this.onChangePrice(PROPERTY.PRICE, modelValue,viewValue,evt)}
                    thousand={true}
                    integer={8}
                    separator={'.'}
                    fraction={2}
                    placeHolder={'0.00'}
                    disabled={false}
                    withSymbol={true}
                    classWrapperSymbol={'dollar-prefix'}
                    styleInput={{paddingLeft: "20px"}}
                    errorMessage={this.state.errorMessages[PROPERTY.PRICE]}
                  />
                </React.Fragment>
          }
          <div className='edit-form-footer'>
            <button
              type='button'
              className='cancel-button'
              onClick={this.props.closeForm}
            >
              Cancel
            </button>
            <button
              type='button'
              className='save-button'
              onClick={this.onSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }
}

SaleSettingUpdateForm.propTypes = {
  element: PropTypes.object,
  closeForm: PropTypes.func,
  updateElement: PropTypes.func,
}

export default SaleSettingUpdateForm