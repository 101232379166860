import React, {useRef, useState} from 'react'
import NavigationBar from "../components/navigationBar/NavigationBar";
import IntroBannerSection from "../components/introBannerSection/IntroBannerSection";
import FooterSection from "../components/footer/FooterSection";
import {pathServer} from "../../../../common/Constants";
import SplitSection from "../components/SplitSection";
import GDGButton, {BUTTON_TYPE} from "../components/GDGButton";

require('../homepage.scss')
require('./todoteams.scss')

export default function Reviews() {
  window.scrollTo(0, 0)
  const navigationBar = useRef();

  const demoRequest = () => {
    if(navigationBar && navigationBar.current)
      navigationBar.current.handleRequestDemo()
  }

  return (
    <div id='gdg-home-page' className='to-do-teams'>
      <NavigationBar ref={navigationBar}/>
      <IntroBannerSection
        blueText={'TO DO TEAMS'}
        title={"Manage all your team tasks"}
        description={"With our Task Management platform you can easily add, assign, manage, " +
          "complete and receive real-time updates on your team's task. Make sure " +
          "everything your team needs to do, gets done right."}
        imgUrl={pathServer.PATH_IMG + '/newHomePage/todo-teams-intro.png'}
      />

      <SplitSection
        className={'team-work-application after-intro-section-padding'}
        leftSection={
          <div>
            <p className={'title-font'} dangerouslySetInnerHTML={{
              __html: 'Mobile team work done right'
            }}/>
            <p className={'regular-font'} dangerouslySetInnerHTML={{
              __html: 'Allow your team to manage all their tasks within their own personal devices. ' +
                'No need to meet and check in with your team, with Task Management work is continuous and clear.'
            }}/>
            <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
          </div>
        }
        rightSection={
          <div>
            <img alt={'img'} src={pathServer.PATH_IMG + '/newHomePage/todo-teams-mobile-work.png'}/>
          </div>
        }

      />

      <SplitSection
        className={'task-notification-application default-section-padding background-light-blue odd'}
        leftSection={
          <div>
            <p className={'title-font'} dangerouslySetInnerHTML={{
              __html: 'Never miss a task again'
            }}/>
            <p className={'regular-font'} dangerouslySetInnerHTML={{
              __html: 'Receive email and SMS notifications every time a task is either ' +
                'created or updated.'
            }}/>
            <GDGButton type={BUTTON_TYPE.BLUE} text={"Try it for free"} hoverText={"Request demo"} onClick={demoRequest}/>
          </div>
        }
        rightSection={
          <div>
            <img alt={'img'} src={pathServer.PATH_IMG + '/newHomePage/todo-teams-task-notification.png'}/>
          </div>
        }

      />

      <div className="container-message">
        Team work will never be the same.
      </div>

      <FooterSection/>

    </div>
  )
}
