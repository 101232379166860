import React, {Component} from 'react'
import {chunk, differenceBy} from 'lodash'
import {pathServer} from '../../../../common/Constants'
import {getWidthColumn} from '../../util/Utils'

export default class FormSearchAttribute extends Component {

  constructor(props){
    super(props);
    this.handlePressEnter = this.handlePressEnter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchAttribute = this.searchAttribute.bind(this);
    this.state = {
      value: "",
      attributes:[],
      attributesTemp:[],
      attributesModel:[],
      attributesModelTemp:[],
      attributeInfo:[]
    }

    this.isEditClicked = false
  }

  componentWillMount(){
    let {dataSourceGroups, attributesSelected, attributeInfo} = this.props;
    let attributes = differenceBy(dataSourceGroups[1].options,attributesSelected,'id');
    let attributesModel = differenceBy(dataSourceGroups[0].options,attributesSelected,'id');
    this.setState({attributes:attributes, attributesTemp:attributes,attributesModel:attributesModel,attributesModelTemp:attributesModel,attributeInfo:attributeInfo})
  }

  componentWillUnmount() {
    document.getElementById("root").style = "opacity: 1.0"

    if(this.isEditClicked) {
      document.getElementById("root").style = "opacity: 0.3"
    }
  }

  searchAttribute() {
    let {value, attributes, attributesModel} = this.state;
    let findAttributes1 = attributes.filter(x => x.code.toUpperCase().includes(value) || x.name.toUpperCase().includes(value));
    let findAttributes2 = attributesModel.filter(x => x.code.toUpperCase().includes(value) || x.name.toUpperCase().includes(value));
    this.setState({attributesTemp: findAttributes1, attributesModelTemp: findAttributes2})
  }

  handleChange(event) {
    this.setState({value: event.target.value.toUpperCase()});
  }

  handlePressEnter(evt) {
    if (evt.key === 'Enter') {
      this.searchAttribute();
    }
  }

  render(){
    let {form, attributeByRow, onSelectAttribute, viewNewAttribute,indexSubSection,attributeInfo,allAttributesEdit} = this.props;
    let {attributesTemp, attributesModelTemp} = this.state;
    let col = getWidthColumn(attributeByRow);
    const attributeGroupsModel = chunk(attributesModelTemp, attributeByRow);
    const attributeGroups = chunk(attributesTemp, attributeByRow);
    let attributeEdit = null;
    if(attributeInfo.length > 0){
      attributeEdit = allAttributesEdit.find(x => x.id === attributeInfo[0].id);
    }

    return(
      <div>
        <div className="row-attribute" id="id-row-attribute-search">
          <div className="form-search-attribute form-input">
            <input className="form-control" onChange={this.handleChange} onKeyUp={this.searchAttribute} onKeyPress={this.handlePressEnter}/>
            <div className="form-search-img">
              <img src={pathServer.PATH_IMG + "ic_search.png"} onClick={this.searchAttribute} alt=""/>
            </div>
          </div>
        </div>
        <div className="row-attribute" id="id-row-field">
          <div className="content">
            <label className="form-field-label">{form.entityType + " Fields"}</label>
            <div className="row-attribute" id="id-row-attribute-search-button">
              <div className="options-btn">
                {
                  attributeInfo.length > 0 &&
                    <button type="button" className="btn btn-secondary btn-sm btn-edit-atr"
                            onClick={(e) => {
                              this.isEditClicked = true
                              viewNewAttribute(attributeInfo[0], e)
                            }}>
                      {
                        attributeInfo[0].name.includes(".") ?
                          'Edit Existing ' : 'Edit only the form'
                      }
                    </button>
                }
                <button type="button" className="btn btn-secondary btn-sm"
                        style={{backgroundColor: "black", color: "white", float: "right"}}
                        onClick={(e) => {
                          this.isEditClicked = true
                          viewNewAttribute(null, e)
                        }}>Create new
                </button>
              </div>
            </div>
          </div>
          {
            attributeGroupsModel.map((fields, index) => {
              return (
                <div key={index} className="form-new-attribute-group">
                  {
                    fields.map((field, j) => {
                      return (
                        <div key={j} className={col + " box-at-content"}>
                          <a className="box-at" onClick={() => onSelectAttribute(field,indexSubSection,attributeEdit)}>
                            <img src={pathServer.PATH_IMG + "ic_room.png"} alt=""/>
                            <label>{field.name}</label>
                          </a>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
        <div className="row-attribute" id="id-row-attribute">
          <div>
            <label className="form-attribute-label">{form.entityType + " Attributes"}</label>
          </div>
          {
            attributeGroups.map((fields, index) => {
              return (
                <div key={index} className="form-new-attribute-group">
                  {
                    fields.map((field, j) => {
                      return (
                        <div key={j} className={col + " box-at-content"}>
                          <a className="box-at" onClick={() => onSelectAttribute(field,indexSubSection,attributeEdit)}>
                            <img src={pathServer.PATH_IMG + "ic_room.png"} alt=""/>
                            <label>{field.name}</label>
                          </a>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }


}