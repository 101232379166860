import React, { useEffect, useState } from 'react'
import { initializePaymentProcessor } from '../../Api'
import isEmpty from 'lodash/isEmpty'
import AuthorizeNet from './authorizenet'
import BraintreeIntegration from './braintree'
import {SUCCESSFUL_CODE} from '../../../common/Constants'

const Payment = (props) => {

  const [config, setConfig] = useState()

  useEffect(() => {
   async function initialize() {
     const {responseCode, responseMessage, data} = await initializePaymentProcessor()
     if (responseCode === SUCCESSFUL_CODE) {
       setConfig(data)
     } else {
       console.error(responseMessage)
     }
    }

    initialize().then()
  }, [])


  if (isEmpty(config))
    return null

  return config.processor === 'authorizeNet'
    ? <AuthorizeNet config={config}/>
    : <BraintreeIntegration
      config={config}
      selectedOrder={props.selectedOrder}
      selectedPlan={props.selectedPlan}
      subscriptionId={props.subscriptionId}
      setReload={props.setReload}
      show={props.show}
      close={props.close}
      paymentMethods={props.paymentMethods}
      currentInventoryItem={props.currentInventoryItem}
    />


}

export default Payment
