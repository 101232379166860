import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import DPCheckbox from '../../../common/DPCheckbox'
import {DPCustomPill} from '../../util/ComponentUtils'
import {pathServer} from '../../../../common/Constants'
import {MAX_LENGTH_KEYWORDS} from '../../../Constants'

export const MODAL_TYPE = {
  SET_HOMEPAGE_TITLE: 'setHomePageTitle',
  SET_HOMEPAGE_DESCRIPTION: 'setHomePageDescription',
  OPTIMIZE_SITE: 'optimizeSiteForMobileDevices',
  ADD_CONTACT_DETAILS: 'addContactDetailsToHomePage',
  CONNECT_SOCIAL_NETWORKS: 'connectSocialNetworksToHomePage',
  ADD_LINKS: 'addLinksToHomePage',
}

export const META = {
  TITLE: 'title',
  PRE_TITLE: 'preTitle',
  DESCRIPTION: 'description',
  KEYWORDS: 'keywords',
  DEALER_NAME: 'dealerName',
  TEMP_KEYWORD: 'tempKeyword',
  TITLE_ID: 'titleId',
  DESCRIPTION_ID: 'descriptionId',
  KEYWORDS_ID: 'keywordsId'
}

const CustomOption = ({textContent}) => {
  return (
    <div className='option-container'>
      <ReactSVG
        src={`${pathServer.PATH_IMG}/icon/check_icon.svg`}
        className='svg-image-container'
        beforeInjection={svg => svg.classList.add('left-icon-svg')}
      />
      <div className='option-text-container'>
        <div className='option-text'>{textContent}</div>
      </div>
    </div>
  )
}

class SetHomePageTitleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false
    }
  }

  handlePopover = (e) => {
    e.preventDefault()
    if (this.props.metaInfo[META.KEYWORDS].length < 3) {
      this.setState({showInput: true})
    }
  }

  getPills = () => {
    return(
      this.props.metaInfo[META.KEYWORDS].map((kw, index) => {
        const widthKeyword = kw.length * 10
        const widthContainer = widthKeyword + 25
        return (
          <DPCustomPill
            key={kw}
            id={kw}
            index={index}
            handleDeleteItem={(e) => this.props.onDeletePill(index, e)}
            displayValue={kw}
            classContainer='pill-container'
            customStyleContainer={{width: `${widthContainer}px`}}
            classDisplayValue='pill-label'
            customStyleDisplayValue={{width: `${widthKeyword}px`}}
            classDeleteIcon='pill-delete-icon'
          />
        )
      })
    )
  }

  render() {
    const {props, state} = this
    const currentTitleTag =
      props.metaInfo[META.PRE_TITLE]
        ? `${props.metaInfo[META.PRE_TITLE]} | ${props.metaInfo[META.DEALER_NAME]}`
        : props.metaInfo[META.DEALER_NAME]

    return (
      <Modal show={props.showModal} className='set-homepage-title-modal-container'>
        <Modal.Body>
          <ReactSVG
            src={`${pathServer.PATH_IMG}icon/ic_close.svg`}
            className="close-modal-icon"
            onClick={props.closeModal}
          />
          <div className='body-container'>
            <div className='title'>
              Homepage’s title
            </div>

            <div className='title-message'>
              <div><b>Why it’s important</b></div>
              <div>A clear and concise title can help explain what you have to offer and can bring more traffic to your site.</div>
            </div>

            <div className='label-input'>Go ahead and fill in your dealer name so it’s easier for people to find you on Google</div>
            <input
              className='dealer-name-input'
              type="text"
              value={props.metaInfo[META.DEALER_NAME]}
              onChange={(e) => props.onChange(META.DEALER_NAME, e.target.value)}
            />

            {/* Keywords Section */}
            <div className='keywords-message'>
              <div><b>How would you describe your business?</b></div>
              <div>Add up to 3 keywords phrases that clearly describe your dealer or the services you offer. You can
                change these anytime.</div>
            </div>

            <div
              className='pills-container'
              style={{marginBottom: `${state.showInput ? '0px' : '9px'}`}}
              id='keywordContainerId'
              onClick={e => this.handlePopover(e)}
            >
              {
                props.metaInfo[META.KEYWORDS].length > 0
                  ? this.getPills()
                  : null
              }
            </div>

            {
              state.showInput
                ? <input
                  id='keywordInput'
                  className='keyword-input'
                  type="text"
                  value={props.metaInfo[META.TEMP_KEYWORD]}
                  onChange={(e) => props.onChange(META.TEMP_KEYWORD, e.target.value)}
                  onKeyUp={(e) => props.onKeyUp(e, () => this.setState({showInput: false}))}
                  autoFocus={true}
                  placeholder={'Press enter to exit'}
                  maxLength={MAX_LENGTH_KEYWORDS}
                />
                : null
            }

            {/* Choose a title Section */}
            <div className='choose-title-message'>
              <div><b>Choose a title</b></div>
              <div>{`Current title tag: ${currentTitleTag}`}</div>
            </div>

            <div className='choose-title-container'>
              <div className='first-column'>
                {
                props.metaInfo[META.KEYWORDS].length > 0
                  ? props.metaInfo[META.KEYWORDS].map(el => {
                    return (
                      <div className='title-option-container'>
                        <DPCheckbox
                          className='checkbox-title-option'
                          checked={props.metaInfo[META.PRE_TITLE] === el}
                          onCheck={() => props.onChange(META.PRE_TITLE, el)}
                        />
                        <div className='label-title-option'>
                          {`${el} | ${props.metaInfo[META.DEALER_NAME]}`}
                        </div>
                      </div>
                    )
                  })
                  : null
              }
              </div>
              <div className='second-column'>
                <div className='title-container'>Preview</div>
                <div className='preview-container'>
                  <div className='title-preview'>{currentTitleTag}</div>
                  <div className='url-preview'>Https://www.godealergo.com/</div>
                  <div className='desc-preview'>Site description goes here. Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem, vel </div>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className='footer-container'>
              <div
                className='save-btn'
                onClick={props.save}
              >
                Save
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

const SetHomePageDescriptionModal = (props) => {
  const RequirementOption = ({labelText}) => {
    return (
      <div className='requirement-option'>
        <ReactSVG
          src={`${pathServer.PATH_IMG}/icon/error_icon.svg`}
          className='svg-container'
          beforeInjection={svg => svg.classList.add('left-icon-svg')}
        />
        <div className='requirement-label'>{labelText}</div>
      </div>
    )
  }

  return (
    <Modal show={props.showModal} className='set-homepage-description-modal-container'>
      <Modal.Body>
        <ReactSVG
          src={`${pathServer.PATH_IMG}icon/ic_close.svg`}
          className="close-modal-icon"
          onClick={props.closeModal}
        />
        <div className='body-container'>
          <div className='title'>
            Homepage’s description
          </div>

          <div className='title-message'>
            <div><b>Why it’s important</b></div>
            <div>A meta description helps potential visitors learn more about your site before they visit. It’s important to keep this text relevant and concise.</div>
          </div>

          <div className='label-textarea'>Tell people about your site</div>
          <textarea
            className='description-textarea'
            id="metaDescription"
            type="text"
            rows="5"
            value={props.metaInfo[META.DESCRIPTION]}
            maxLength={300}
            onChange={e => props.onChange(META.DESCRIPTION, e.target.value)}
          />

          {/* Requirements Section */}
          <div className='requirements-container'>
            <div><b>Description requirements</b></div>
            <RequirementOption labelText='At least on keyword'/>
            <RequirementOption labelText='Your business or site name'/>
            <RequirementOption labelText='Text length with maximum 300 characters'/>
          </div>

          {/* Footer */}
          <div className='footer-container'>
            <div
              className='save-btn'
              onClick={props.save}
            >
              Save
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const OptimizeSiteModal = (props) => {
  return (
    <Modal show={props.showModal} className='optimize-site-modal-container'>
      <Modal.Body>
        <ReactSVG
          src={`${pathServer.PATH_IMG}icon/ic_close.svg`}
          className="close-modal-icon"
          onClick={props.closeModal}
        />
        <div className='body-container'>
          <div className='title-message-container'>
            <ReactSVG
              className='svg-image-container'
              src={`${pathServer.PATH_IMG}/icon/check_icon.svg`}
              beforeInjection={svg => svg.classList.add('left-title-icon-svg')}
            />
            <div className='title-message'>
              <b>Your site has been optimized for mobile devices</b>
            </div>
          </div>
          <div className='sub-title-message'>
            Why mobile is important?
          </div>
          <CustomOption textContent={<div>Mobile-friendly sites show up higher in search results</div>}/>
          <CustomOption textContent={<div>Mobile searches make up more than half of searches on <a href='https://www.google.com' target='_blank'>Google.com</a></div>}/>
          <CustomOption textContent={<div>If your site isn't mobile-friendly, visitors are 5 times more likely to leave</div>}/>
        </div>

        {/* Footer section */}
        <div className='footer-section'>
          <div
            className='got-it-btn'
            onClick={props.gotIt}
          >
            Got it
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const AddHomePageContactDetailsModal = (props) => {
  return (
    <Modal
      show={props.showModal}
      className='add-homepage-contacts-details-modal-container'
    >
      <Modal.Body>
        <ReactSVG
          src={`${pathServer.PATH_IMG}icon/ic_close.svg`}
          className="close-modal-icon"
          onClick={props.closeModal}
        />
        <div className='body-container'>
          <div className='title-message-container'>
            <b>Add contact details</b>
          </div>
          <div className='columns-container'>
            <div className='add-contacts-first-column'>
              <div className='important-message-container'>
                <div><b>Why it’s important</b></div>
                <div>By clearly displaying your contact info, you’re making it easier for potential customers to get in
                  touch with you.</div>
              </div>
              <div className='sub-title-container'><b>How to do it</b></div>
              <div className='how-to-do-container'>
                <ReactSVG
                  src={`${pathServer.PATH_IMG}icon/steps.svg`}
                  className='svg-image-container'
                  beforeInjection={svg => svg.classList.add('steps-icon-svg')}
                />
                <div className='how-to-do-steps'>
                  <div>Open your <span className='template-editor-text'>Template editor</span> following this route Settings > Templates > Edit selected > Footer</div>
                  <div>Click on Save icon to keep your changes</div>
                </div>
              </div>
            </div>

            <div className='add-contacts-second-column'>
              <ReactSVG
                src={`${pathServer.PATH_IMG}icon/footer.svg`}
                className='svg-image-container'
                beforeInjection={svg => svg.classList.add('footer-icon-svg')}
              />
            </div>
          </div>

          {/* Footer */}
          <div className='footer-section'>
            <div
              className='got-it-btn'
              onClick={props.gotIt}
            >
              Got it
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const ConnectHomePageSocialNetworksModal = (props) => {
  return (
    <Modal
      show={props.showModal}
      className='connect-homepage-social-networks-modal-container'
    >
      <Modal.Body>
        <ReactSVG
          src={`${pathServer.PATH_IMG}icon/ic_close.svg`}
          className="close-modal-icon"
          onClick={props.closeModal}
        />
        <div className='body-container'>
          <div className='title-message-container'>
            <b>Connect social media</b>
          </div>
          <div className='columns-container'>
            <div className='add-contacts-first-column'>
              <div className='important-message-container'>
                <div><b>Why it’s important</b></div>
                <div>By clearly displaying your social media accounts, you’re making it easier for potential customers to get in touch with you and your content</div>
              </div>
              <div className='sub-title-container'><b>How to do it</b></div>
              <div className='how-to-do-container'>
                <ReactSVG
                  src={`${pathServer.PATH_IMG}icon/steps.svg`}
                  className='svg-image-container'
                  beforeInjection={svg => svg.classList.add('steps-icon-svg')}
                />
                <div className='how-to-do-steps'>
                  <div>Open your <span className='template-editor-text'>Template editor</span> following this route Settings > Templates > Edit selected > Site identity > Social media</div>
                  <div>Click on Save icon to keep your changes</div>
                </div>
              </div>
            </div>
            <div className='add-contacts-second-column'>
              <ReactSVG
                src={`${pathServer.PATH_IMG}icon/social_media.svg`}
                className='svg-image-container'
                beforeInjection={svg => svg.classList.add('social-media-icon-svg')}
              />
            </div>
          </div>

          {/* Footer section */}
          <div className='footer-section'>
            <div
              className='got-it-btn'
              onClick={props.gotIt}
            >
              Got it
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const AddHomePageLinksModal = (props) => {
  return (
    <Modal
      show={props.showModal}
      className='add-homepage-links-modal-container'
    >
      <Modal.Body>
        <ReactSVG
          src={`${pathServer.PATH_IMG}icon/ic_close.svg`}
          className="close-modal-icon"
          onClick={props.closeModal}
        />
        <div className='body-container'>
          <div className='title-message-container'>
            <ReactSVG
              src={`${pathServer.PATH_IMG}/icon/check_icon.svg`}
              className='svg-image-container'
              beforeInjection={svg => svg.classList.add('title-icon-svg')}
            />
            <div className='title-message'>
              <b>Add links to your homepage</b>
            </div>
          </div>
          <div className='sub-title-message'>
            Your homepage is now optimized with hyperlinks.
          </div>
          <CustomOption textContent={<div><b>FROM</b> another page on your site <b>TO</b> your homepage.</div>}/>
          <CustomOption textContent={<div><b>FROM</b> your homepage <b>TO</b> another page on your site.</div>}/>
        </div>

        {/* Footer section */}
        <div className='footer-section'>
          <div
            className='got-it-btn'
            onClick={props.gotIt}
          >
            Got it
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default class CustomSEOModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoading: false,
    }
  }

  render() {
    const {showLoading} = this.state
    const {showModal, closeModal, modalType, onChange, metaInfo, onDeletePill, onKeyUp, saveAndCloseModal} = this.props

    let modal = null
    switch (modalType) {
      case MODAL_TYPE.SET_HOMEPAGE_TITLE:
        modal = <SetHomePageTitleModal
          showModal={showModal}
          closeModal={closeModal}
          onChange={onChange}
          metaInfo={metaInfo}
          onDeletePill={onDeletePill}
          onKeyUp={onKeyUp}
          save={saveAndCloseModal}
        />
        break
      case MODAL_TYPE.SET_HOMEPAGE_DESCRIPTION:
        modal = <SetHomePageDescriptionModal
          showModal={showModal}
          closeModal={closeModal}
          onChange={onChange}
          metaInfo={metaInfo}
          save={saveAndCloseModal}
        />
        break
      case MODAL_TYPE.OPTIMIZE_SITE:
        modal = <OptimizeSiteModal
          showModal={showModal}
          closeModal={closeModal}
          gotIt={saveAndCloseModal}
        />
        break
      case MODAL_TYPE.ADD_CONTACT_DETAILS:
        modal = <AddHomePageContactDetailsModal
          showModal={showModal}
          closeModal={closeModal}
          gotIt={saveAndCloseModal}
        />
        break
      case MODAL_TYPE.CONNECT_SOCIAL_NETWORKS:
        modal = <ConnectHomePageSocialNetworksModal
          showModal={showModal}
          closeModal={closeModal}
          gotIt={saveAndCloseModal}
        />
        break
      case MODAL_TYPE.ADD_LINKS:
        modal = <AddHomePageLinksModal
          showModal={showModal}
          closeModal={closeModal}
          gotIt={saveAndCloseModal}
        />
      default:
        break
    }

    return (
      <div>
        {
          showLoading
            ? <div className="loader-container">
              <div className="loader"/>
            </div>
            : modal
        }
      </div>
    )
  }
}