import React from 'react'
import User from '../dashboard/User'
import QuickActions from '../dashboard/quickActions/QuickActions'
import Theme from '../dashboard/Theme'
import Report from '../dashboard/reports/Report'

const adminLayout = ({...props}) => {
  return(
    <main id="admin-layout">
      <section id="graphs">
        <div className="graph-up">
          <Report containerId="up"/>
        </div>
        <hr/>
        <div className="graph-down">
          <Report containerId="down"/>
        </div>
      </section>
      <section id="theme">
        <Theme />
      </section>
      <section id="quick-actions">
        <QuickActions />
      </section>
      <section id="user">
        <User />
      </section>
    </main>
  )
}

export default adminLayout