import React, {useContext} from 'react'
import ActionLink from './ActionLink'
import {DashboardContext} from '../../../Dashboard'

const QuickActions = props => {
  const { quickActions } = useContext(DashboardContext)
  return(
    <div id="quick-actions-info">
      <header>Quick Actions</header>
      <div className="actions-container">
        {
          quickActions.map( action => <ActionLink key={action.name} quickAction={action}/> )
        }
      </div>
  </div>
  )
}


export default QuickActions