import React, {useCallback, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ReactSVG} from 'react-svg'
import {PaymentAmountContainer} from './PaymentAmountContainer'
import {pathServer} from '../../../../common/Constants'
import DPCheckbox from '../../../common/DPCheckbox'
import {META} from '../marketingSetting/CustomSEOModal'
import {CARD_TYPE, PaymentMethodItem} from './BillingContainer'
import {ADD_NEW_CARD} from '../../payment/braintree'
import ReactDOM from "react-dom";
import {formatDateToString} from '../../util/Utils'

require('./PaymentModal.scss')

const userCreditCards = [
  {
    cardHolderName: "JOSE M. RAMIREZ",
    cardNumber: "4242424242424242",
    expirationDate: "10/26",
    cvv: "333",
    billingAddress: "794 Schipluidenlaan Amsterdam",
    zipCode: "1062HE",
    company: "visa"
  },
  {
    cardHolderName: "JOSE M. RAMIREZ",
    cardNumber: "5555555555554444",
    expirationDate: "10/26",
    cvv: "444",
    billingAddress: "794 Schipluidenlaan Amsterdam",
    zipCode: "1062HE",
    company: "mastercard"
  },
]

const CREDIT_CARD_OPTION = "Credit Card"
const ACH_OPTION = "ACH"
const noPaddingRight = {
  paddingRight: 0
}

const styleInput = {height:34, padding: '6px 12px', borderRadius: 4, border: '1px solid #ccc'}

export const PaymentModalForm = (
  { paymentHandler = () => {}, isDisabled, isProcessing, showBackBtn= false,
    handleClickOnBackBtn = null, textForSubmitBth = 'Pay', requiredElements = true,
    classCardNumber = 'form-group col-md-6', classExpDate = 'form-group col-md-3',
    classCVV = 'form-group col-md-3', classBillingAddress = 'form-group col-md-10',
    classZipCode = 'form-group col-md-2', showCheckbox = true, checkBoxMessage = 'Set as my main card',
    isCheckedOnForm = false, handleClickOnCheckBox = null, skipHandler = () => {},  showSkipBtn = false, isProcessingSkip
  }) => {
  return (
    <form onSubmit={paymentHandler}>
      <div className="form-group">
        <label htmlFor="card-holder">Card Holder name</label>
        <div style={styleInput} id="card-holder"/>
        {/*<input type="text" className="form-control" id="card-holder" placeholder="Name on the card" name="card-holder" required={requiredElements}/>*/}
      </div>
      <div className="form-row">
        <div className={classCardNumber}>
          <label htmlFor="card-number">Card number</label>
          {/*<input type="text" className="form-control" id="card-number" name="card-number" required/>*/}
          <div style={styleInput} id="card-number"/>
        </div>
        <div className={classExpDate}>
          <label htmlFor="exp-date">Expiration date</label>
          {/*<input type="text" className="form-control" id="exp-date" name="exp-date" required/>*/}
          <div style={styleInput} id="exp-date"/>
        </div>
        <div className={classCVV} style={noPaddingRight}>
          <label htmlFor="cvv">CVV</label>
          {/*<input type="text" className="form-control" id="cvv" name="cvv" required/>*/}
          <div style={styleInput} id="cvv"/>
        </div>
      </div>
      <div className="form-row">
        <div className={classBillingAddress}>
          <label htmlFor="address">Billing address</label>
          <input type="text" className="form-control" id="streetAddress" name="streetAddress" />
        </div>
        <div className={classZipCode} style={noPaddingRight}>
          <label htmlFor="zip">Zip code</label>
          <div style={styleInput} id="postal-code"/>
          {/*<input type="text" className="form-control" id="postal-code" name="postal-code" required={requiredElements}/>*/}
        </div>
      </div>
      <div className="form-row">
        {/*<div className="content-check">*/}
        {/*  <DPCheckbox checked={true} id="save-card-check" onCheck={() => {}} />*/}
        {/*  <div className="container-label">*/}
        {/*    <span className="check-description">Save card</span>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {
          showBackBtn
          && <button
            className="back"
            onClick={handleClickOnBackBtn}
          >
            <ReactSVG
              src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
              className="img-back"
            />
            {'Back'}
          </button>
        }

        {
          showCheckbox
          && <div style={{display: 'flex', alignItems: 'center', marginBottom: '7px', minHeight: '18px', fontSize: '13px', height: '18px', clear: 'both'}}>
            <DPCheckbox
              style={{width: '18px !important', height: '18px !important', cursor: 'pointer'}}
              required={false}
              checked={isCheckedOnForm}
              onCheck={() => handleClickOnCheckBox(!isCheckedOnForm)}
            />
            <div>{checkBoxMessage}</div>
          </div>
        }

        <button type="submit"
                style={isDisabled ? {backgroundColor: '#8F979D'} : null}
                className="btn-save"
                disabled={isDisabled}>
          {isProcessing ? 'Processing...' : textForSubmitBth}
        </button>

        {
            showSkipBtn
            && <button
                className="btn-skip-create"
                onClick={skipHandler}
                disabled={isDisabled}
            >
              {isProcessingSkip ? 'Processing...' : 'Skip and create'}
            </button>
        }
      </div>
    </form>
  )
}

export default function PaymentModal(props) {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(CREDIT_CARD_OPTION)
  const [showNewCCForm, setShowNewCCForm] = useState(true)

  const { outstandingBalance,
    selectedAmountOption,
    errorMessage,
    isProcessing,
    paymentHandler,
    paymentMethods,
    setSelectedToken,
    selectedToken,
    setInstance,
    setSaveCardMode,
    saveCardMode,
    selectedOrder = null,
  } = props

  const handleSelectItem = useCallback(
    (token) => {
      setInstance(null)
      setSelectedToken(token)
    },
    [],
  );

  const addNewCard = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setInstance(null)
      setSelectedToken(ADD_NEW_CARD)
    })
  }

  // const isDisabled = outstandingBalance === 0 || selectedAmountOption === '' || isProcessing
  const isDisabled =  isProcessing

  return(
    <div className="modal fade">
      <Modal show={props.show}  className={`payment-modal ${showNewCCForm ? "open-new-credit-card" : "" }`} >
        <Modal.Body>
          <div className="container-icon-close" onClick={props.onClick}>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="icon-close"/>
          </div>
          <div className="modal-title">Payment</div>
          {/*<PaymentAmountContainer*/}
          {/*  selectedAmountOption={selectedAmountOption}*/}
          {/*  outstandingBalance={outstandingBalance}*/}
          {/*  selectedAmountOptionHandler={selectedAmountOptionHandler}*/}
          {/*/>*/}
          <div className='container-current-bill-details'>
            {
              selectedOrder && selectedOrder.orderStatus && (
                <div className='current-bill-row'>
                  <div className='current-bill-row-header'>Status</div>
                  <div className='current-bill-row-order-status'>{selectedOrder.orderStatus.toUpperCase()}</div>
                </div>
              )
            }
            <div className='current-bill-row'>
              <div className='current-bill-row-header'>Plan</div>
              <div className='current-bill-row-text'>{selectedOrder && selectedOrder.orderItemsList && selectedOrder.orderItemsList[0].inventoryItemName}</div>
            </div>
            <div className='current-bill-row'>
              <div className='current-bill-row-header'>Due amount</div>
              <div className='current-bill-row-text'>{`$${selectedOrder && selectedOrder.amount}`}</div>
            </div>
            <div className='current-bill-row'>
              <div className='current-bill-row-header'>Deadline</div>
              <div className='current-bill-row-text'>{formatDateToString(selectedOrder && selectedOrder.orderDueDate)}</div>
            </div>
          </div>
          <div className="payment-type-options">
            <div className={`btn-credit-card-option ${selectedPaymentOption === CREDIT_CARD_OPTION ? "active" : ""}`}
                 //onClick={() => setSelectedPaymentOption(CREDIT_CARD_OPTION)}
            >
              { CREDIT_CARD_OPTION }
            </div>
            {/*<div className={`btn-ach-option ${selectedPaymentOption === ACH_OPTION ? "active" : ""}`}*/}
            {/*     onClick={() => setSelectedPaymentOption(ACH_OPTION)}*/}
            {/*>*/}
            {/*  { ACH_OPTION }*/}
            {/*</div>*/}
          </div>
          <hr/>
          <div className="saved-credit-card-container">
            {/*{*/}
            {/*  userCreditCards.map(creditCard =>*/}
            {/*    <SavedCreditCard key={creditCard.cardNumber}*/}
            {/*                     onClick={onClickSavedCard}*/}
            {/*                     cardNumber={creditCard.cardNumber}*/}
            {/*                     companyName={creditCard.company}*/}
            {/*    />*/}
            {/*  )*/}
            {/*}*/}
            {/*<div className="btn-add-new-card" onClick={addNewCardHandler}>Add new card</div>*/}
            {
              paymentMethods && paymentMethods.map(el => {
                const creditCardNumber = `XXXX - ${el.cardNumber}`
                return (
                  <div key={`payment-method-${el.token}`} className='payment-method-item-container'>
                    <PaymentMethodItem
                      token={el.token}
                      classPaymentMethodItem={selectedToken === el.token ? 'payment-method-selected-item' : 'payment-method-item'}
                      creditCardNumber={creditCardNumber}
                      creditCardType={el.cardType}
                      showOptions={false}
                      onClick={handleSelectItem}
                    />
                  </div>
                )
              })
            }
            <div className='payment-method-item-container'>
              <div className={selectedToken === ADD_NEW_CARD ? 'payment-method-selected-item' : 'payment-method-item'}
                   onClick={addNewCard}
              >
                <div className='credit-card-number'
                >Add new card</div>
              </div>
            </div>
          </div>
          {
            selectedToken === ADD_NEW_CARD
            && <PaymentModalForm
              paymentHandler={paymentHandler}
              isDisabled={isDisabled}
              isProcessing={isProcessing}
              checkBoxMessage={'Save this card'}
              handleClickOnCheckBox={setSaveCardMode}
              isCheckedOnForm={saveCardMode}
            />
          }
          {
            !!errorMessage && <p className={'message error'}>{errorMessage}</p>
          }
          {
            selectedToken !== null && selectedToken !== ADD_NEW_CARD
            && <div className='footer-container'>
              <button
                className='pay-btn'
                onClick={paymentHandler}
                disabled={isDisabled}
                style={isDisabled ? {backgroundColor: '#8F979D', cursor: 'not-allowed'} : null}
              >
                {
                  isProcessing ? 'Processing...' : 'Pay'
                }
              </button>
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}
