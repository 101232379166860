import React from 'react'
import {browserHistory} from 'react-router'
import InventoryPhotoGrid from './InventoryPhotoGrid.jsx'
import {updateActiveCar} from '../../Api.js'
import {SORT_ICON_DEFAULT, CAR_STATUS_CODE_FRONT_LINE_READY, FORM_CAR_NEW} from '../../Constants.js'
import {clone, cloneDeep} from 'lodash'
import InventoryFilter from './InventoryFilter.jsx'
import ContainerHeader from '../util/ContainerHeader.jsx'
import InventoryTable from './InventoryTable.jsx'
import {
  entityTypes, importKey, siteColorAdmin,
  ROWS_IMPORT_BY_PAGE_BY_DEFAULT,
  VIEW_INVENTORY,
  WIDTH_INVENTORY_TABLET
} from '../../Constants'
import ModalConfirmDelete from '../util/ModalConfirmDelete.jsx'
import isEmpty from 'lodash/isEmpty'
import BodyCarsHeader from './BodyCarsHeader'
import {
  searchInventoryApi,
  deleteInventoryApi,
  exportCarInventory,
  carHasRepairOrSale,
  loadAttributeValuesByAttributeCode,
  validateExistVinActive,
  validateExistVinToSale
} from '../../Api'
import ModalConfirmDeactivate from '../util/ModalConfirmDeactivate.jsx'
import {ReactSVG} from 'react-svg'
import DragDropContext from '../util/DragDropContext'
import MessageBodyCustom from './MessageBodyCustom'
import ModalUploadFile from '../util/ModalUploadFile.jsx'
import ModalViewDataImport from '../util/ModalViewDataImport.jsx'
import {DPMessageDelete} from '../../common/DPMessageDelete'
import {getView, modalChangeCarStatus} from '../util/Utils'
import {
  pathServer,
  ATTRIBUTE_CODE_CAR_STATUS,
  WIDTH_VIEW_MOBILE,
  SUCCESSFUL_CODE
} from '../../../common/Constants'
import {DPPagination} from '../../../common/DPPagination'


const {
  Component
} = React;

const messageDeactivate = "Do you want to deactivate this vehicle? This action will hide the vehicle " +
                          "in your public inventory. You can activate it again anytime.";

const messageDeactivateOps = "Are you sure you want to deactivate this vehicle? There is a sell pending with it.";

const TITLE_DELETE_CAR = "Please Provide the confirmation password to delete this car";

const MessageBodyDelete = (props) => {
  return (
    <div className="panel-body-delete">
      <div className="text-confirmation">
        Please Provide the confirmation password to delete this car
      </div>
      <div>
        { props.error ? <span className="message-error">{props.error}</span> : null}
        <div className="container-new-password">
          <div className="label-select-status">Confirmation Password</div>
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_open_padlock.svg"} className="icon-padlock" beforeInjection={ svg => svg.classList.add('icon-open-padlock')}/>
          <input id="newPasswordDelete" type="password" className="input-password" onChange={props.onChangePassword.bind(this,"passwordToDelete")} />
          { props.isEmptyPassword ? <span className="message-error">Please provide password</span> : null}
        </div>
        <button onClick={props.onDelete} className="btn-save-custom" style={{float:"left"}}>Delete</button>
      </div>
      <div className="container-icon-sub-title">
        <div className="container-icon-exclamation">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"} beforeInjection={ svg => svg.classList.add('svg-icon-error')}
                    className="icon-error"/>
        </div>
        <div className="container-sub-title">
          <p className="sub-title-coming-soon">
            Create / Change confirmation password in <span>Settings</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const child = <label>In this section you can add the expenses derived from having the car ready to sell.
  Any added expense on Task Repair related to this car will also appear in this section once Task Repair is completed.</label>


class Inventory extends Component {

  constructor() {
    super();
    this.state = {
      type: '',
      car: null,
      carId: null,
      cars: [],
      optionsBody: [],
      arrayCars: [],
      dataPages: [],
      showModal: false,
      showModalOverlay: false,
      showModalDeactivate: false,
      sortIcon: SORT_ICON_DEFAULT,
      advanceSearch: false,
      allCar: false,
      showPopoverAdd: false,
      isLoading:true,
      disabledFilter: false,
      showFilterSection: false,
      showOptions:false,
      showPagination : false,
      viewMobile: false,
      activeCar: false,
      isFirstLoading:true,
      isCarSellPending:false,
      isCarAlreadyExist:false,
      passwordToDelete:null,
      error:null,
      queryFilters:{},
      keyword: "",
      hasTaskRepair:0,
      isCarAttached: false,
      hasSale: false,
      changeStatusTo: null,
      changeStatusFrom: null,
      newTaskRepairId: null,
      isUpdatingToRepairStatus: false,
      showCopiedRow: false,
      showModalImport:false
    }
  }

  componentWillMount(){
    const {attributeValuesToCarStatus, carFilters, setCarFiltersInitial,keyword} = this.props;

    if(!attributeValuesToCarStatus){
      loadAttributeValuesByAttributeCode(ATTRIBUTE_CODE_CAR_STATUS).then(({responseCode, responseMessage, data}) => {
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
          return
        }

        let attributeValues = data.attributeValues;
        const index = data.attributeValues.findIndex((attributeValue) => {return attributeValue.code === ATTRIBUTE_CODE_CAR_STATUS});
        attributeValues.splice(index,1);

        const carStatusFrontLineReady = data.attributeValues.find((carStatus) => {return carStatus.code === CAR_STATUS_CODE_FRONT_LINE_READY});
        carFilters.status = [];
        carFilters.status.push(carStatusFrontLineReady.displayValue);
        setCarFiltersInitial({carFilters:carFilters,attributeValuesToCarStatus:attributeValues});
        this.handleSearch(carFilters,'initial');
      })
    }
    this.findByKeyWord(keyword)
    this.setState({view:getView(window.innerWidth)});
  }

  componentDidMount(){
    const {attributeValuesToCarStatus,carFilters} = this.props;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if(attributeValuesToCarStatus)
      this.handleSearch(carFilters, "resize");
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    let {cars} = this.state;
    const {keyword} = nextProps;
    //if (cars.length === 0)
    //  this.setCar(nextProps);
    //else
    this.findByKeyWord(keyword);
  }

  componentDidUpdate() {
    if(this.state.showCopiedRow)
      setTimeout(() => this.toggleShowCopiedRow(), 1000)
  }

  exportCars = () => {
    const {queryFilters} = this.state;

    const exportUrl = exportCarInventory(queryFilters);
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function() {
      if(xhr.readyState === 4 && xhr.status === 200) {
        window.location.href = exportUrl;
      }

      if (xhr.readyState === 4 && xhr.status === 400) {
        console.log('Inventory CSV File has an error')
      }
    }

    xhr.open('head', exportUrl);
    xhr.send(null);
  }

  findByKeyWord(keywordProps){
    let {carFilters} = this.props;
    const{keyword} = this.state;

    if(keywordProps !== "" && keyword !== keywordProps){
      this.setState({keyword:keywordProps}, () => {
        this.search(carFilters, "initial");
      });
    } else if(keyword !== "" && keyword !== keywordProps){
      this.setState({keyword:keywordProps}, () => {
        this.search(carFilters);
      });
    }
  }

  setCar(props) {

    let {cars} = props;
    if (cars) {
      let arrayCars = this.carsTable(cars);
      //this.setState({cars: cars, arrayCars: arrayCars, optionsBody: optionsBody, quantityCars: quantityCars,isLoading:false,carsAll:cars})
    }
  }

  buildPages(data) {
    let totalPages;
    let dataPages = [];
    const {quantityCars} = this.state;
    if (data.length !== 0) {
      let page = [];
      totalPages = data.length / quantityCars;
      if (data.length % quantityCars !== 0)
        totalPages++;
      totalPages = Math.floor(totalPages);

      data.map((item, index) => {
        page.push(item);
        if ((index !== 0 && (index + 1) % quantityCars === 0) || (index === (data.length - 1))) {
          dataPages.push(page);
          page = [];
        }
      });
    } else {
      totalPages = 0;
    }
    this.setState({dataPages: dataPages, totalPages: totalPages});
  }

  getYearPriceMile(){
    const {searchQueryInventory} = this.props;

    const year  = {min:parseInt(searchQueryInventory.rangeYearFrom), max:parseInt(searchQueryInventory.rangeYearTo)};
    const price = {min:parseInt(searchQueryInventory.rangePriceFrom), max:parseInt(searchQueryInventory.rangePriceTo)};
    const mile  = {min:parseInt(searchQueryInventory.rangeMileageFrom), max:parseInt(searchQueryInventory.rangeMileageTo)};
    return {year,price,mile}
  }

  search = (carFilters, filterSelected, currentPage) => {
    let {setCarFilters,setCarFiltersAndCurrentPage} = this.props;
    let keyword = this.state.keyword;
    this.setState({isLoading:true});
    let carFiltersNew = clone(carFilters);
    const query = {
      "keyword": keyword !== '' ? keyword : null,
    };

    if (carFilters.makes && carFilters.makes.length > 0)
      query.makes = carFilters.makes;

    if(filterSelected === "year" || filterSelected === "mile" || filterSelected === "price" || filterSelected === "resize" && carFilters.year && carFilters.year !== null){
      query.rangeYearFrom = carFilters.year.min ? carFilters.year.min.toString() : null;
      query.rangeYearTo = carFilters.year.max ? carFilters.year.max.toString() : null;
    }

    if(filterSelected === "year" || filterSelected === "mile" || filterSelected === "price" || filterSelected === "resize" && carFilters.mile&& carFilters.mile !== null){
      query.rangeMileageFrom = (carFilters.mile.min && carFilters.mile.min >= 0) ? carFilters.mile.min : null;
      query.rangeMileageTo = carFilters.mile.max ? carFilters.mile.max : null;
    }

    if(filterSelected === "year" || filterSelected === "mile" || filterSelected === "price" || filterSelected === "resize" && carFilters.price && carFilters.price !== null){
      query.rangePriceFrom = (carFilters.price.min && carFilters.price.min >= 0) ? carFilters.price.min.toString() : null;
      query.rangePriceTo = carFilters.price.max ? carFilters.price.max.toString() : null;
    }

    if (carFilters.models && carFilters.models.length > 0)
      query.models = carFilters.models;

    if (carFilters.status && carFilters.status.length > 0)
      query.status = carFilters.status;

    if(carFilters.locations && carFilters.locations.length > 0){
      query.locations = carFilters.locations;
    }

    query.size = carFilters.size;
    query.from = carFilters.from;
    query.active = carFilters.active

    this.setState({queryFilters:query});
    searchInventoryApi(query).then(({responseCode, responseMessage, data}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        console.log(responseMessage);
        this.setState({isLoading:false})
        return
      }

      const cars = data.cars;
      carFiltersNew.quantityCars = data.quantityCars;
      let disabledFilter = false;
      if(cars.length === 0)
        disabledFilter = true;

      cars.forEach( car => {
        if(car["car.status"])
          car.status = car["car.status"].attributeValue
      })

      const valuesMinMax = cloneDeep(data.valuesMinMax);
      let {year, mileage, price} = valuesMinMax;

      let state = {
        cars: cars,
        optionsBody: data.optionsBody,
        isLoading:false,
        disabledFilter:disabledFilter,
        searchCompleted: true,
        carsAll:cars,
        totalCars: data.total,
        quantityCars: data.quantityCars,
        totalVehiclesByStatus: data.totalVehiclesByStatus
      };
      let maxValues, minValues;

      if(filterSelected === "initial" ||
        (filterSelected !== "year" && filterSelected !== "price" && filterSelected !== "mile" && filterSelected !== "resize") ||
         filterSelected === "currentPage"){

        maxValues = {year: year.max, price: price.max, mile: mileage.max};
        minValues = {year: year.min, price: price.min, mile: mileage.min};

        if (cars.length > 0){
          this.updateFilterRangers("year",1, year, minValues, maxValues);
          this.updateFilterRangers("mile",5000, mileage, minValues, maxValues);
          this.updateFilterRangers("price",100, price, minValues, maxValues);
        }

        carFiltersNew.year = {min: minValues.year, max: maxValues.year};
        carFiltersNew.mile = {min: minValues.mile, max: maxValues.mile};
        carFiltersNew.price = {min: minValues.price, max: maxValues.price};
        carFiltersNew.minValues = minValues;
        carFiltersNew.maxValues = maxValues;
        carFiltersNew.keyword = keyword;
        if(filterSelected !== "currentPage")
          setCarFilters(carFiltersNew);
        else
          setCarFiltersAndCurrentPage({carFilters:carFiltersNew,currentPage:currentPage});
      }
      this.setState(state);
    })
  }

  carsTable = (cars) => {
    let arrayCars = [];
    if (cars) {
      cars.forEach(function (car) {
        let object = new Object();
        for (let key in car) {
          if (typeof car[key] === 'object' && car[key]) {
            object[key] = car[key].attributeValue;
          } else {
            object[key] = car[key];
          }
        }
        arrayCars.push(object);
      });
    }
    this.buildPages(arrayCars);
    return arrayCars;
  }

  onChangeCheckAllCar = () => {
    let {allCar} = this.state;
    this.setState({allCar: !allCar});
  }

  viewEditFormatter(cell, row) {
    return (
      (row.active) ?
        <div>
          <a onClick={() => this.edit(row.vin)}>
            <i className="glyphicons glyphicons-edit row-edit" style={{fontSize: 24}}/>
          </a>
          <a onClick={() => this.delete(null, row.vin)}>
            <i className="glyphicons glyphicons-bin row-delete" style={{paddingLeft: 2, color: "#d9534f"}}/>
          </a>
        </div> : null
    )
  }

  edit = (vin) => {
    this.setState({showModalOverlay: true});
    const {carFilters} = this.props;
    browserHistory.push({pathname:`/main/inventory/${vin}`,
    state:{carFilters: carFilters}});
  }

  delete = (carId,vin) => {
    carHasRepairOrSale(carId).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        if(data.sales || data.repairs)
          this.setState({isCarAttached: true, hasTaskRepair: data.repairs, hasSale:data.sales, vinExist:vin})
        else
          this.setState({showModal: true, carId: carId});
      }else{
        console.error(responseMessage)
      }
    })
  }

  onSubmitDeleteInventory = () => {
    let {carId, passwordToDelete} = this.state;
    if(carId && passwordToDelete && !isEmpty(passwordToDelete)){
      deleteInventoryApi(carId,passwordToDelete).then(({responseCode, responseMessage}) => {
        if(responseCode !== SUCCESSFUL_CODE){
          this.setState({error: responseMessage});
          console.error(responseMessage)
          return
        }

        this.handleModal();
      })
    }else{
      this.setState({isEmptyPassword:true})
    }
  }

  deactivate = (vin) => {
    validateExistVinToSale(vin).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        if(data.carSaleExist){
          this.setState({showModalDeactivate: true, vin: vin, carId: data.carId, isCarSellPending: true});
        }else{
          this.setState({showModalDeactivate: true, vin: vin, carId: data.carId});
        }
      }else{
       console.error(responseMessage)
      }
    })
  }

  activate = (vin,carId) => {
    validateExistVinActive(vin,carId).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        if(data.carActiveExist){
          this.setState({showModalDeactivate: true, vin: vin, carId: data.carId, isCarAlreadyExist: true, vinExist:data.carActiveExist.vin});
        }else{
          this.changeActiveCar(data.carId);
        }
      }else{
        console.error(responseMessage)
      }
    })
  }

  changeActiveCar = (carId) => {
    updateActiveCar(carId).then(({responseCode, responseMessage}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        return
      }

      this.handleModal();
    })
  }

  handleModal = () => {
    let {carFilters} = this.props;
    this.setState({isCarAttached: false, showModal: false, vin: null, carId:null, showModalOverlay: false, showModalDeactivate: false,
      activeCar: false, isCarSellPending: false, isCarAlreadyExist:false, error:null, isEmptyPassword:false});
    this.search(carFilters);
  }

  /*activeFormatter(active, row) {
    return (
      <div className="icon-user">
        {(active) ?
          <a className="iconActive">
            <i className="fa fa-check-circle fa-2x"/>
          </a> :
          <a className="iconInactive" href="#" onClick={this.active.bind(null, row.vin)}>
            <i className="fa fa-times-circle fa-2x"/>
          </a>
        }
      </div>
    )
  }

  active(vin) {
    this.setState({activeCar: true});
    this.delete(vin);
  }*/

  onChangePassword = (field,evt) => {
    this.setState({[field]:evt.target.value,isEmptyPassword:false,error:null});
  }

  addCar = () => {
    let {showPopoverAdd} = this.state;
    this.setState({showPopoverAdd: !showPopoverAdd});
    const {carFilters} = this.props;
    browserHistory.push({pathname:`/main/inventory/new`,
      state:{carFilters: carFilters}});
  }

  actionView = (show) => {
    this.props.setShowList(show === "list");
  }

  changePagination = (number) => {
    this.props.setPageNumberInventory(number);
  }

  updateFilterRangers(id,step,type,minValue,maxValue){
    let max = Math.max(clone(type.max));
    let min = Math.min(clone(type.min));

    let rest = 0;
    let difference = 0;

    if (id === 'mile' || id === 'price'){
      rest = max % step;
      if (rest > 0){
        difference = step - rest;
        max = max + difference
      }
      rest = min % step;
      if (rest > 0){
        min = min - rest;
      }
    }
    if (max === min)
      max = min + step;

    minValue[id] = min;
    maxValue[id] = max;

    type.min = min;
    type.max = max;
  }

  handleShowFilterSection = () => {
    let {showFilterSection} = this.state;
    this.setState({showFilterSection: !showFilterSection})
  }

  activeFilterSection = (value) => {
    this.setState({showFilterSection:value})
  }

  handleRequestOpen = (event) => {
    this.setState({showOptions:true,anchorEl:{offsetWidth:event.currentTarget.offsetWidth,offsetHeight:event.currentTarget.offsetHeight}})
  }

  handleRequestClose = () => {
    this.setState({showOptions:false})
  }

  handleResize = () => {
    const {attributeValuesToCarStatus,carFilters} = this.props;
    const {view} = this.state;
    const currentView = getView(window.innerWidth);
    if(view !== currentView){
      this.setState({view: currentView}, () => {
        if(attributeValuesToCarStatus)
          this.handleSearch(carFilters, "resize");
      })
    }
  }

  //todo: before handleSearch(query,isFirstLoading)
  handleSearch = (carFilters,filterSelected) => {
    const {pageNumberInventory} = this.props;
    let query = clone(carFilters);

    let showPagination = false;
    let withPage = window.innerWidth;
    let quantityItems = query.size;
    let viewMobile = false;
    let showFilterSection = false;
    if(withPage < WIDTH_INVENTORY_TABLET){
      showFilterSection = false;
    }
    if (withPage > 768 && withPage < WIDTH_INVENTORY_TABLET){
      quantityItems = 20;
      showPagination = true;
    }else if( withPage <= 768){
      quantityItems = 10;
      showPagination = true;
      viewMobile = true;
    }

    query.from = 0;
    query.size = quantityItems;

    this.setState({showPagination,viewMobile,showFilterSection});
    if(pageNumberInventory !== 1) {
      this.handlePageChange(pageNumberInventory, carFilters.quantityCars ? Math.ceil(carFilters.quantityCars/quantityItems) : 1)
    }else
      this.search(query,filterSelected);
  }

  handleItemsByPageMobile = (quantity) => {
    let {query} = this.state;
    query.from = 0;
    query.size = quantity;
    this.search(query);
  }

  handlePageChange = (nextPage, totalPages) => {
    let {carFilters, setCarFilters, itemsByPageQuantity} = this.props;
    //let itemsByPageQuantity = carFilters.size;

    let query = clone(carFilters);
    let currentPage = nextPage;

    if(currentPage > totalPages) {
      currentPage = 1;
      query.from = 0;
    }else if(currentPage === 0){
      currentPage = totalPages;
      query.from = itemsByPageQuantity * (totalPages - 1);
    }else{
      query.from = itemsByPageQuantity * (currentPage - 1);
    }
    query.size = itemsByPageQuantity;
    //this.props.setPageNumberInventory(currentPage);
    //setCarFilters(query);
    this.search(query,"currentPage",currentPage);
  }

  changeFirstLoading = (isFirstLoading) => {
    this.setState({isFirstLoading:isFirstLoading});
  }

  toggleShowCopiedRow = () => {
    this.setState({showCopiedRow: !this.state.showCopiedRow})
  }

  showCopiedRowAlert = () => {
    this.toggleShowCopiedRow()
  }

  handleUpdateToInspectRepair = (repairId, from, to) => {
    this.setState({
      newTaskRepairId: repairId,
      changeStatusFrom: from,
      changeStatusTo: to,
      isUpdatingToRepairStatus: true
    })
  }

  closeInspectRepairUpdateModal = () => {
    this.setState({isUpdatingToRepairStatus: false })
  }

  handleImport = () => {
    const {showModalImport} = this.state;
    this.setState({showModalImport: !showModalImport});
  }

  showModalData = (totalRows,columns, nameFile) => {
    this.setState({showModalImport: false, showModalData: true, totalRows : totalRows, columns: columns, nameFile: nameFile});
  }

  closeModalData = () => {
    this.setState({showModalData: false});
  }

  render() {
    const {cars, optionsBody, advanceSearch, showPopoverAdd, query, showModal,showModalDeactivate,isLoading,
    disabledFilter,showFilterSection,showPagination, viewMobile, activeCar, changeMakeOrModel, totalCars, quantityCars,
      carId, isCarSellPending, isCarAlreadyExist,vinExist,isEmptyPassword, error,hasTaskRepair, hasSale, isCarAttached,
      showCopiedRow, newTaskRepairId, changeStatusFrom, changeStatusTo, isUpdatingToRepairStatus,
      showModalImport, showModalData, totalRows, columns, nameFile,totalVehiclesByStatus} = this.state;

    const {makes, models, showList, searchQueryInventory, setSearchQueryInventory,left,attributeValuesToCarStatus,
      locations, showSideBar, pageNumberInventory, setPageNumberInventory, isFirstLoading, setFirstLoading,
      carFilters,setCarFilters,setCarFiltersAndCurrentPage} = this.props;

    let itemsByPageQuantity = carFilters.size;
    let quantityCarsBD = carFilters.quantityCars;
    const totalPages = quantityCarsBD ? Math.ceil(quantityCarsBD/itemsByPageQuantity) : 1;

    if(!makes || !attributeValuesToCarStatus || carFilters.quantityCars === null || !optionsBody) return null;

    let message = (isCarSellPending) ? "Are you sure you want to deactivate this vehicle? There is a sell pending with it." : messageDeactivate;
    const element = <MessageBodyCustom vin={vinExist} hasTaskRepair={hasTaskRepair} hasSale={hasSale} />;

    const messageBodyCustom = <DPMessageDelete isEmptyPassword={isEmptyPassword} title={TITLE_DELETE_CAR}
                                                 onDelete={this.onSubmitDeleteInventory}
                                                 onChangePassword={this.onChangePassword} error={error}/>;

    let bodyResultHeader = null;

    if(!showFilterSection || window.innerWidth <= WIDTH_VIEW_MOBILE){
      bodyResultHeader = <BodyCarsHeader action={this.addCar}>
                            <InventoryFilter advanceSearch={advanceSearch}
                                            search={this.search}
                                            totalPages={totalPages}
                                            pageNumberInventory={pageNumberInventory}
                                            setPageNumberInventory={setPageNumberInventory}
                                            changePagination={this.changePagination}
                                            makes={makes}
                                            models={models}
                                            query={query}
                                            searchQueryInventory={searchQueryInventory}
                                            setSearchQueryInventory={setSearchQueryInventory}
                                            attributeValuesToCarStatus={attributeValuesToCarStatus}
                                            cars={cars}
                                            disabledFilterProps={disabledFilter}
                                            isLoading={isLoading}
                                            showFilterSection={true}
                                            showSideBar={showSideBar}
                                            locations={locations}
                                            isFirstLoading={isFirstLoading}
                                            changeFirstLoading={setFirstLoading}
                                            changeMakeOrModel={changeMakeOrModel}
                                            carFilters={carFilters}
                                            setCarFilters={setCarFilters}
                                            left={left}
                                            showOnlyFastFilters={!showFilterSection}
                                            showCopiedRow={showCopiedRow}
                          />
      </BodyCarsHeader>
    }

    let updateVehicleToInspectRepairModal

    if(isUpdatingToRepairStatus) {
      const bodyMessageUpdateInspectRepair = modalChangeCarStatus(changeStatusFrom, changeStatusTo, null,newTaskRepairId)

      updateVehicleToInspectRepairModal = (
        <ModalConfirmDeactivate show={isUpdatingToRepairStatus}
                                onSubmit={() => this.closeInspectRepairUpdateModal()}
                                onHide={this.closeInspectRepairUpdateModal}
                                showButton={isUpdatingToRepairStatus}
                                isBodyCustom={isUpdatingToRepairStatus}
                                bodyMessage={ bodyMessageUpdateInspectRepair }
                                message={null}
                                iconQuestion="icon/ic_alright.svg"
                                classButtonDelete="btn-delete"
                                buttonLabelDelete= "Got it!"
                                viewMobile={false}/>
      )
    }

    return (
      <div className="inventory-content">
        <ContainerHeader title="Inventory"
                         action={this.addCar}
                         showPopoverAdd={showPopoverAdd}
                         actionView={this.actionView}
                         search={this.search}
                         ref={el => this.containerHeader = el}
                         query={query}
                         left={left}
                         showList={showList}
                         locations={locations}
                         showItemPerPage={true}
                         showPaginationArrow={window.innerWidth > WIDTH_VIEW_MOBILE}
                         handleShowFilterSection={this.handleShowFilterSection}
                         activeFilterSection={this.activeFilterSection}
                         showFilterSection={showFilterSection}
                         pageNumberInventory={pageNumberInventory}
                         setPageNumberInventory={setPageNumberInventory}
                         carFilters={carFilters}
                         setCarFiltersAndCurrentPage={setCarFiltersAndCurrentPage}
                         totalCars={totalCars}
                         quantityCars={quantityCars}
                         from={VIEW_INVENTORY}
                         hasButtonExport={true}
                         handleButtonExport={this.exportCars}
                         hasButtonImport={true}
                         handleButtonImport={this.handleImport}
                         showIconAddCar={true}
        >
          <div className="container-filters">
            <InventoryFilter advanceSearch={advanceSearch}
                             action={this.addCar}
                             search={this.search}
                             totalPages={totalPages}
                             pageNumberInventory={pageNumberInventory}
                             setPageNumberInventory={setPageNumberInventory}
                             changePagination={this.changePagination}
                             makes={makes}
                             models={models}
                             query={query}
                             searchQueryInventory={searchQueryInventory}
                             setSearchQueryInventory={setSearchQueryInventory}
                             attributeValuesToCarStatus={attributeValuesToCarStatus}
                             cars={cars}
                             disabledFilterProps={disabledFilter}
                             isLoading={isLoading}
                             showFilterSection={showFilterSection}
                             showSideBar={showSideBar}
                             locations={locations}
                             isFirstLoading={isFirstLoading}
                             changeFirstLoading={setFirstLoading}
                             changeMakeOrModel={changeMakeOrModel}
                             carFilters={carFilters}
                             setCarFilters={setCarFilters}
                             left={left}
                             showCopiedRow={showCopiedRow}
            />
          </div>
        </ContainerHeader>
        <div className={showPopoverAdd ? "container-center container-opacity" : "container-center"}>
          <div className="body-car-inventory">
            {bodyResultHeader}
            <div className="container-cars" onClick={() => {this.setState({advanceSearch: false})}}>
              {
                isLoading ?
                  <div className="loader-container">
                    <div className="loader"/>
                  </div> :
                  (cars.length > 0) ?
                    ((!showList) ?
                        <InventoryPhotoGrid cars={cars}
                                            search={this.search}
                                            edit={this.edit}
                                            delete={this.delete}
                                            deactivate={this.deactivate}
                                            activate={this.activate}
                                            //active={this.active.bind(this)}
                                            query={query}
                                            attributeValuesToCarStatus={attributeValuesToCarStatus}
                                            updateCarsAfterChangeStatus={this.handleModal}
                                            handleUpdateToInspectRepair={this.handleUpdateToInspectRepair}
                                            totalVehiclesByStatus={totalVehiclesByStatus}
                        /> :
                        <InventoryTable cars={cars}
                                        pageNumberInventory={pageNumberInventory}
                                        edit={this.edit}
                                        delete={this.delete}
                                        makes={makes}
                                        models={models}
                                        filters = {carFilters}
                                        showCopiedRowAlert={this.showCopiedRowAlert}
                                        totalVehiclesByStatus={totalVehiclesByStatus}
                        />
                    ) :
                    <div className="message-not-found">
                      <h3>No cars matched your search</h3>
                    </div>
              }
            </div>
            {
              showPagination ?
                <div className="car-pagination">
                  <DPPagination
                    selectedItemsByPage={itemsByPageQuantity}
                    currentPage={pageNumberInventory}
                    totalPages={totalPages}
                    handlePageChange={this.handlePageChange}
                    showItemPerPage={false}
                    siteColor={siteColorAdmin}
                  />
                </div> : null
            }
          </div>
          {
            (showModal) ?
              <ModalConfirmDelete show={showModal}
                                  styleModal="modal-delete"
                                  styleLeft="panel-left-delete"
                                  styleRight="panel-right-delete"
                                  body={messageBodyCustom}
                                  showButtonDelete={false}
                                  onSubmit={this.onSubmitDeleteInventory}
                                  onHide={this.handleModal}
                                  classButtonDelete="btn-delete"
                                  buttonLabelDelete="Yes, Delete"
                                  viewMobile={viewMobile} />
              : null
          }
          {
            showModalDeactivate || isCarAttached ?
              <ModalConfirmDeactivate show={showModalDeactivate || isCarAttached}
                                      onSubmit={() => this.changeActiveCar(carId)}
                                      onHide={this.handleModal}
                                      showButton={isCarAttached ? false : !isCarAlreadyExist}
                                      isBodyCustom={isCarAlreadyExist || isCarAttached}
                                      bodyMessage={element}
                                      message={message}
                                      iconQuestion={isCarAttached ? "icon/ic_warning.svg" : null}
                                      classButtonDelete="btn-delete"
                                      buttonLabelDelete={isCarSellPending ? "Deactivate anyways" : "Yes, deactivate"}
                                      viewMobile={viewMobile} />
              : null
          }
          { updateVehicleToInspectRepairModal }
        </div>
        <ModalUploadFile show={showModalImport} onHide={this.handleImport} showModalData={this.showModalData} title={"Import Inventory"}/>
        {
          showModalData ?
            <ModalViewDataImport show={showModalData}
                                 onHide={this.closeModalData}
                                 totalRows={totalRows}
                                 rowsNumberToShow={ROWS_IMPORT_BY_PAGE_BY_DEFAULT}
                                 nameFile={nameFile}
                                 child={child}
                                 columns={columns}
                                 totalPages={totalPages}
                                 formCodeToImport={FORM_CAR_NEW}
                                 importKey={importKey.CAR}
                                 entityType={entityTypes.CAR}
                                 rowKey={'vin'}
            />
            : null
        }
      </div>
    )
  }

  inventoryCar() {
    browserHistory.push(`/main/inventory/new`);
  }
}

const Container = (props) => {
  return(
    <DragDropContext>
      <Inventory {...props}/>
    </DragDropContext>
  )
};

export default Container;